"use strict";

const defaultLanguage = 'en';
const availableLanguages = ['en', 'de', 'es', 'fr', 'it'];

const locale = require('browser-locale');

const log = require('loglevel');

const queryString = require('query-string');

const initialSearchQuery = typeof window !== 'undefined' && window.location.search;

const getSearchQuery = () => {
  const {
    language,
    locale: searchQueryLocale
  } = queryString.parse(initialSearchQuery);
  return language || searchQueryLocale;
};

let hasLogged = false;

const logDetectedLanguage = languageResult => {
  if (!hasLogged) {
    log.info('Detected language', languageResult);
  }

  hasLogged = true;
};
/**
 * Detect the browser language
 */


module.exports = function detectLanguage(codeInput) {
  const browserCode = typeof window.globalLanguage !== 'undefined' ? window.globalLanguage : locale();
  const languageCode = codeInput || getSearchQuery() || browserCode || defaultLanguage;
  const code = languageCode.toLowerCase();
  const code2digit = code.substr(0, 2);
  const lowerCaseAvailable = {};
  availableLanguages.forEach(lang => {
    lowerCaseAvailable[lang.toLowerCase()] = lang;

    if (lang.length > 2) {
      lowerCaseAvailable[lang.toLowerCase().substr(0, 2)] = lang;
    }
  });
  const languageResult = lowerCaseAvailable[code] || lowerCaseAvailable[code2digit] || defaultLanguage;
  logDetectedLanguage(languageResult);
  return languageResult;
};