import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import * as selectors from '../state/ChecklistSelectors';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import { setCloneChecklist, multiCloneSaga } from '../state/ChecklistActions';
import { setSearchQuery } from '../../../../actions/globalActions';
import ChecklistCloneContainer from './ChecklistCloneContainer';

function mapStateToProps() {
  const memo = selectorsGlobal.selectSearchData(
    'properties',
    'checklist_clone_search',
    selectorsGlobal.selectProperties,
  );
  const currentUser = selectorsGlobal.selectCurrentUser();
  return (state, props) => {
    const { searchQuery, result } = memo(state);
    const cloneChecklistState = selectors.selectCloneChecklistJS(state, props) || {};
    const properties = selectorsGlobal.selectProperties()(state, props);

    return {
      searchQuery,
      searchRes: result,
      isOpen: cloneChecklistState.isOpen,
      isSubmitting: cloneChecklistState.mode !== 0,
      isLoading: !properties || !properties.size,

      checklistId: cloneChecklistState.selectedChecklistId,
      checklistTitle: cloneChecklistState.checklistTitle,
      selectedChecklists: cloneChecklistState.selected,

      properties,
      currentUser: currentUser(state, props),
    };
  };
}

export default connect(
  mapStateToProps,
  {
    setCloneChecklist,
    setSearchQuery,
    multiCloneSaga,
  },
)(ChecklistCloneContainer);
