import React from 'react';
import { connect } from 'react-redux';
import { ModalError } from '@properly/components';
import t from '@properly/localization';
import { PageFlexOuter, PagePadding, PageFlexInner } from '../../../../components/PageElements/index';
import { trackCreateNewProperty } from '../../../../actions/trackingEvents';
import ErrorComponent from '../../../../components/ErrorComponent/index';
import PropertyForm from './PropertyForm';
import { goToProperty, goToProperties, newEditPropertySaga } from '../state/PropertyActions';
import { selectProperty, selectGlobalModalResolved } from '../../../../selectors/globalSelector';
import { ROUTES } from '../../../../paths';
import { mapClassDataToFormData } from './mapper';
import { modals } from '../../../../dataConstants';
import { setModal } from '../../../../actions/globalActions';

class PropertyNewContainer extends React.PureComponent {
  componentDidMount() {
    if (this.props.editMode === 'new') {
      trackCreateNewProperty();
    }
  }

  onSubmit = () => {
    this.props.newEditPropertySaga(this.props.editMode);
  };

  goCancel = mode => () => {
    if (mode === 'edit') {
      this.props.goToProperty(this.props.params.id);
    }
    if (mode === 'new') {
      this.props.goToProperties();
    }
  };

  closeEdit = () => this.props.setModal(false, modals.EDIT_PROPERTY_ERROR_MODAL);

  closeCreate = () => this.props.setModal(false, modals.CREATE_PROPERTY_ERROR_MODAL);

  render() {
    const { mode, editMode } = this.props;
    return (
      <PageFlexOuter>
        <PageFlexInner maxWidth>
          <PagePadding type="absolute-scroll">
            {mode === 'error' && (
              <ErrorComponent is404 headline={t('error.404error')} subHeadline={t('error.looks_like_smth_wrong')} />
            )}
            {mode === 'content' && (
              <PropertyForm
                mode={editMode}
                initialValues={mapClassDataToFormData(this.props.property || {})}
                onSubmit={this.onSubmit}
                onCancelAction={this.goCancel(editMode)}
              />
            )}
            <ModalError
              message1={t('properties.edit_fail_property_head')}
              message2={t('properties.edit_fail_property')}
              show={!!this.props.modalEdit.isOpen}
              onClose={this.closeEdit}
            />
            <ModalError
              message1={t('properties.create_fail_property_head')}
              message2={t('properties.create_fail_property')}
              show={!!this.props.modalCreate.isOpen}
              onClose={this.closeCreate}
            />
          </PagePadding>
        </PageFlexInner>
      </PageFlexOuter>
    );
  }
}

function mapStateToProps() {
  const editSelect = selectGlobalModalResolved(modals.EDIT_PROPERTY_ERROR_MODAL);
  const createSelect = selectGlobalModalResolved(modals.CREATE_PROPERTY_ERROR_MODAL);
  return (state, props) => {
    const isNew = props.location.pathname === ROUTES.newProperties;
    const modalEdit = editSelect(state);
    const modalCreate = createSelect(state);
    if (isNew) {
      return {
        modalEdit,
        modalCreate,
        mode: 'content',
        editMode: 'new',
      };
    }
    const property = selectProperty(props.params.id)(state, props);
    const propertyExists = !!property;
    return {
      modalEdit,
      modalCreate,
      property,
      mode: propertyExists ? 'content' : 'error',
      editMode: 'edit',
    };
  };
}

export default connect(
  mapStateToProps,
  {
    goToProperty,
    goToProperties,
    newEditPropertySaga,
    setModal,
  },
)(PropertyNewContainer);
