import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LoadingSplash } from '@properly/components';
import uniqBy from 'lodash/uniqBy';
import classNames from 'classnames/bind';
import styles from './index.module.css';
import NavContainer from '../NavContainer';
import { selectPreloadStatus, selectLoadingState } from '../../selectors/globalSelector';
import { selectJobDetailsJS } from '../../modules/desktopApp/jobDetails/state/JobDetailsSelectors';
import { RELOAD_STATUS } from '../../dataConstants';
import HasPermission from '../../hoc/HasPermission';
import LoadingModal from '../LoadingModal';

import PricingCalendarNotificationContainer from '../../modules/desktopApp/pricing/PricingCalendarNotificationContainer';

// modals
import JobRequestModal from '../../modules/desktopApp/jobRequest/containers/JobRequestModal';
import JobDetailsContainerModal from '../../modules/desktopApp/jobDetails/containers/JobDetailsContainerModal';
import AccountOAuthContainerModal from '../../modules/desktopApp/account/components/AccountOAuthContainerModal';
import PropertyCloneContainer from '../../modules/desktopApp/property/containers/PropertyCloneContainer';
import PhoneOverlay from '../../components/PhoneOverlay';
import OnBoardingVideoContainer from '../OnBoardingVideoContainer';
import AccountWarningModals from '../../modules/desktopApp/account/components/AccountWarningModals';
import PricingModals from '../../modules/desktopApp/pricing/PricingModals';
import CalendarModalContainer from '../../modules/desktopApp/calendar/containers/CalendarModalContainer';
import WarningDeletedProperty from '../WarningDeletedProperty';
import ChecklistContainerModal from '../../modules/desktopApp/checklists/containers/ChecklistContainerModal';
import PermissionLeaveModal from '../../modules/desktopApp/permissions/PermissionLeaveModal';
import CannotSendPropertyJobsModal from '../CannotSendPropertyJobsModal';

const cx = classNames.bind(styles);

class MainWrapper extends PureComponent {
  static propTypes = {
    hideMobilePopover: PropTypes.bool,
    hidePricewarning: PropTypes.bool,
    disablePreloadLoader: PropTypes.bool,
    disableWrapper: PropTypes.bool,
    color: PropTypes.string,
  };

  popoverMenu = ['user', 'notifications', 'password', 'help', 'logout'];

  mainSectionsStandAlone = ['todos', 'calendar', 'library', 'properties', 'contacts'];

  mainSectionsNotStandAloneTodos = ['todos', 'calendar', 'library', 'properties', 'contacts'];

  mainSectionsNotStandAlone = ['calendar', 'properties', 'contacts'];

  mapAccess(canSeeFullNav, canSendJobsGlobal, canSeeReportsIconSidebar) {
    const section1 = this.popoverMenu;
    let section2 = this.mainSectionsStandAlone;

    if (!canSeeFullNav) {
      section2 = this.mainSectionsNotStandAlone;

      if (canSendJobsGlobal) {
        section2 = this.mainSectionsNotStandAloneTodos;
      }
    }

    if (canSeeReportsIconSidebar) {
      section2.push('reports');
    }

    if (canSeeFullNav) {
      section2.push('settings');
    }

    return { section1, section2: uniqBy(section2) };
  }

  renderContent = (children, isLoading, isLoadingLogout) => {
    const { disablePreloadLoader } = this.props;
    if (isLoadingLogout) {
      return <LoadingSplash data-key-is-main-wrapper-loading="true" />;
    }

    if (isLoading && !disablePreloadLoader) {
      return <LoadingSplash data-key-is-main-wrapper-loading="true" hasRandomMsg />;
    }

    return children;
  };

  renderGlobalModal() {
    const { hideMobilePopover } = this.props;
    const jobDetailsJS = this.props.jobDetails;
    const { selectedJobRequestId } = jobDetailsJS;
    return (
      <>
        {!hideMobilePopover && <PhoneOverlay />}
        <OnBoardingVideoContainer />
        <JobRequestModal />
        <JobDetailsContainerModal jobRequestId={selectedJobRequestId} />
        <AccountOAuthContainerModal />
        <PropertyCloneContainer />
        <AccountWarningModals />
        <PricingModals />
        <CalendarModalContainer />
        <WarningDeletedProperty />
        <ChecklistContainerModal />
        <PermissionLeaveModal />
        <LoadingModal />
        <CannotSendPropertyJobsModal />
      </>
    );
  }

  render() {
    const { disableWrapper } = this.props;

    if (disableWrapper) {
      return (
        <>
          {this.renderContent(this.props.children, this.props.loading, this.props.loadingLogout)}
          {this.renderGlobalModal()}
        </>
      );
    }

    return (
      <div
        data-key-is-main-wrapper-loading={this.props.loading}
        className={cx('main-wrapper', {
          'main-wrapper--color-grey': this.props.color === 'grey',
        })}
      >
        <div className={cx('main-wrapper__sidebar')}>
          <HasPermission
            hasAccessFormatter={hasAccessRes => ({
              canSeeFullNav: hasAccessRes.canSeeFullNav,
              canSendJobsGlobal: hasAccessRes.canSendJobsGlobal,
              settingsUandP: hasAccessRes.settingsUandP,
              canSeeReportsIconSidebar: hasAccessRes.canSeeReportsIconSidebar,
            })}
            render={accessObj => {
              const { section1, section2 } = this.mapAccess(
                accessObj.canSeeFullNav,
                accessObj.canSendJobsGlobal,
                accessObj.canSeeReportsIconSidebar,
              );
              return (
                <NavContainer
                  popoverSections={section1}
                  mainSections={section2}
                  currentPage={this.props.currentPage}
                  showCompanyInfo={!accessObj.canSeeFullNav}
                />
              );
            }}
          />
        </div>

        <div className={cx('main-wrapper__content')}>
          {!this.props.hidePricewarning && <PricingCalendarNotificationContainer />}

          <div className={cx('main-wrapper__content-inner')}>
            {this.renderContent(this.props.children, this.props.loading, this.props.loadingLogout)}
          </div>
        </div>

        {this.renderGlobalModal()}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const mainLoad = selectPreloadStatus(state) === RELOAD_STATUS.NOTLOADED;
  const loading = props.overWriteLoading !== undefined ? props.overWriteLoading : mainLoad;

  return {
    loading,
    loadingLogout: selectLoadingState('logout')(state).isLoadingFull,
    jobDetails: selectJobDetailsJS()(state, props),
  };
}

export default connect(mapStateToProps, {})(MainWrapper);
