import gql from 'graphql-tag';

export const JOB_REQUESTS_QUERY = gql`
  query joblist(
    $role: RoleType!
    $propertyId: String
    $languageCode: String
    $forHistory: Boolean
    $scheduledToEndAfter: DateTime
    $updatedAfter: DateTime
    $offset: Int
    $limit: Int
    $cleanerId: String
    $upcomingJobs: Boolean
  ) {
    JobList(
      role: $role
      propertyId: $propertyId
      languageCode: $languageCode
      forHistory: $forHistory
      scheduledToEndAfter: $scheduledToEndAfter
      updatedAfter: $updatedAfter
      offset: $offset
      limit: $limit
      cleanerId: $cleanerId
      upcomingJobs: $upcomingJobs
    ) {
      status
      id
      connections {
        cleanerId
      }
      sender {
        ...senderFields
      }
      property {
        ...propertyFields
      }
      job {
        ...jobFields
      }
      cleaners {
        ...cleanerFields
      }
      checklists {
        ...checklistFields
      }
      progress {
        ...progressSummaryFields
      }
      problems {
        ...problemFields
      }
      additionalInformationReport {
        ...additionalReportFields
      }
      lastUpdatedAt
      backwardCompatibility {
        jobRequestId
      }
    }
  }

  fragment senderFields on senderFields {
    id
    companyName
    userData {
      ...userFields
    }
  }

  fragment propertyFields on propertyFields {
    id
    title
    type
    timeZone
    city
    state
    country
    apartment
    street
    zip
    countryCode
  }

  fragment jobFields on jobFields {
    id
    title
    duration
    scheduledStartTime
    scheduledEndTime
    startTimeType
  }

  fragment progressSummaryFields on progressSummaryFields {
    id
    actualStartTime
    actualEndTime
    completedTaskCount
    problemCount
    additionalReportCount
    verificationPictureCount
    hasNewFeedback
  }

  fragment problemFields on problemFields {
    id #stepId
    stepTitle
    checklistId
    checklistTitle
    note
    pictureIdentifiers
    severity
    date
    status
  }

  fragment additionalReportFields on additionalReportFields {
    report {
      ...jobReportItem
    }
  }

  fragment jobReportItem on jobReportItem {
    note
    pictureIdentifier
  }

  fragment checklistFields on checklistFields {
    id
    type
    title
    description
    pictureIdentifier
    totalTaskCount
    totalVerificationPictureCount
    steps {
      ...stepFields
    }
    doneTaskCount
    doneStepCount
    propertyIndependent
    verificationPhotos {
      ...verificationPhotoFields
    }
  }

  fragment stepFields on stepFields {
    id
    pictureIdentifier
    note
    room
    section
    title
    isComplete
    isVerificationRequired
    isVerificationComplete
    incompleteTaskCount
  }

  fragment verificationPhotoFields on verificationPhotoFields {
    id
    stepTitle
    pictureIdentifier
    date
    stepId
  }

  fragment cleanerFields on cleanerFields {
    id
    status
    contactId
    note
    date
    companyName
    userData {
      ...userFields
    }
  }

  fragment userFields on userFields {
    id
    firstName
    lastName
    profileId
    pictureIdentifier
    countryCode
  }
`;

export const GET_PROMARKETPLACE_JOB_COUNT_QUERY = gql`
  query GetActiveProMarketplaceJobCount($languageCode: String, $roleType: RoleType) {
    GetActiveProMarketplaceJobCount(languageCode: $languageCode, roleType: $roleType) {
      pendingJobCount
      acceptedJobCount
      inprogressJobCount
      finishedUnpaidJobCount
    }
  }
`;
