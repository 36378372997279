import { fromJS } from 'immutable';
import * as types from '../../../../types/index';
import { changeHelper } from '../../../../helper/herbert';
import User from '../../../../model/user';

const initWarning = {
  isOpen: false,
  meta: {},
};

const initState = fromJS({
  tmpUser: undefined,
  updateUserState: 0, // 0=ok, 1=loading, 2=error, 2.1=error (phone_not_unique)
  updateNotificationState: 0, // 0=ok, 1=loading, 2=error
  passwordState: {
    oldPassword: '',
    newPassword1: '',
    newPassword2: '',
  },
  states: {
    updatePw: 0, // 0=ok, 1=loading, 2=error, 3=done
    sendPw: 0, // 0=closed, 1=open, 2=loading (open), 3=error (open), 4=done
    phoneVerify: 0, // 0=closed, 1=open, 2=loading (open)
    disconnect: 0, // 0=closed, 1=loading, 2=error
    oauth: {},
    imageFile: null,
  },
  warningModal: initWarning,

  connectionSettingsCount: null,

  connectedAccount: [],
  connectedAccountLoading: false,
  connectedAccountError: null,

  disconnectAccountLoading: false,
  disconnectAccountError: null,

  syncAccountLoading: false,
  syncAccountError: null,
});

export default function AccountReducer(state = initState, action = {}) {
  switch (action.type) {
    case types.LOGIN_SAGA: // on login reset
    case types.GLOBAL_LOGOUT: // on logout reset
      return initState;
    case types.ACCOUNT_SNAP_USER_SET:
      return state.set('tmpUser', action.user);
    case types.ACCOUNT_SET_WARNING_MODAL:
      if (action.reset) return state.set('warningModal', fromJS(initWarning));
      return state.set('warningModal', fromJS(action.data));
    case types.ACCOUNT_SET_UPDATE_USER_STATE:
      return state.set('updateUserState', action.val);
    case types.ACCOUNT_SET_PASSWORD_STORE:
      return state.mergeIn(['passwordState'], action.changeSet);
    case types.ACCOUNT_SET_STATE:
      return state.setIn(['states', action.where], fromJS(action.val));
    case types.ACCOUNT_UPDATE_TMP_USER:
      return state.update('tmpUser', oldData => changeHelper(User, oldData, action.changeSet));

    case types.ACCOUNT_GET_CONNECTED_ACCOUNT_REQUEST:
      return state.merge({
        connectedAccountLoading: true,
        connectedAccountError: null,
      });
    case types.ACCOUNT_GET_CONNECTED_ACCOUNT_SUCCESS:
      return state.merge({
        connectedAccountLoading: false,
        connectedAccount: action.connectedAccounts,
        connectionSettingsCount: action.connectionSettingsCount,
      });
    case types.ACCOUNT_GET_CONNECTED_ACCOUNT_FAILURE:
      return state.merge({
        connectedAccountLoading: false,
        connectedAccountError: action.error,
      });

    case types.ACCOUNT_DISMISS_DISCONNECT_ERROR:
      return state.merge({ disconnectAccountError: null });

    case types.ACCOUNT_DISCONNECT_CONNECTED_ACCOUNT_REQUEST:
      return state.merge({
        disconnectAccountLoading: true,
        disconnectAccountError: null,
      });
    case types.ACCOUNT_DISCONNECT_CONNECTED_ACCOUNT_SUCCESS:
      return state.merge({
        disconnectAccountLoading: false,
      });
    case types.ACCOUNT_DISCONNECT_CONNECTED_ACCOUNT_FAILURE:
      return state.merge({
        disconnectAccountLoading: false,
        disconnectAccountError: action.error.message,
      });

    case types.ACCOUNT_DISMISS_SYNC_ERROR:
      return state.merge({ syncAccountError: null });

    case types.ACCOUNT_SYNC_CONNECTED_ACCOUNT_REQUEST:
      return state.merge({
        syncAccountLoading: true,
        syncAccountError: null,
      });
    case types.ACCOUNT_SYNC_CONNECTED_ACCOUNT_SUCCESS:
      return state.merge({
        syncAccountLoading: false,
      });
    case types.ACCOUNT_SYNC_CONNECTED_ACCOUNT_FAILURE:
      return state.merge({
        syncAccountLoading: false,
        syncAccountError: action.error.message,
      });

    default:
      return state;
  }
}
