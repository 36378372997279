import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, LoadingSpinner, Regular } from '@properly/components';
import t from '@properly/localization';
import classNames from 'classnames/bind';
import styles from './checkoutStyles.module.css';

const cx = classNames.bind(styles);

class CheckoutModal extends PureComponent {
  renderMobile() {
    if (!this.props.show) return null;
    return (
      <div className={cx('checkout__modalwrapmobile')}>
        {this.renderContent(this.mapModeToData(this.props.mode))}
        <div className={cx('checkout__modal-btn')}>
          <Button
            onClick={this.props.onClose}
            types={['type-flat-white', 'width-flex', 'size-large', 'minwidth-no', 'buttonnew--fontw-normal']}
          >
            {t('checkout.ok', {})}
          </Button>
        </div>
      </div>
    );
  }

  renderContent({ text, icon, isLoading }) {
    return (
      <div className={cx('checkout__modalwrap')}>
        <img alt="Properly Logo" src={icon} />
        <div className={cx('checkout__modal-txt')}>
          <Regular>{text}</Regular>
        </div>
        <div className={cx('checkout__modal-loading')}>{isLoading && <LoadingSpinner />}</div>
      </div>
    );
  }

  render() {
    return this.renderMobile();
  }
}

CheckoutModal.propTypes = {
  show: PropTypes.bool,
  mode: PropTypes.string,
  onClose: PropTypes.func,
};

export default CheckoutModal;
