import React from 'react';
import PropTypes from 'prop-types';
import { Title3, Button } from '@properly/components';
import t from '@properly/localization';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const TitleBar = ({ isValid, label, activeTxt, onClickCancel, onClickMain }) => (
  <div className={cx('title-bar')}>
    <div>
      <Title3 type="nomargin">{label}</Title3>
    </div>
    <div className={cx('flex')}>
      <Button
        type="button"
        types={['type-border-grey', 'size-large']}
        data-key="property_edit_cancel"
        onClick={onClickCancel}
      >
        {t('properties.cancel')}
      </Button>
      <div className={cx('space')} />
      <Button
        type="button"
        types={['type-full-primary', 'size-large']}
        data-key="property_edit_save"
        fakeDisable={!isValid}
        onClick={onClickMain}
      >
        {activeTxt}
      </Button>
    </div>
  </div>
);

TitleBar.propTypes = {
  isValid: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  activeTxt: PropTypes.string.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  onClickMain: PropTypes.func.isRequired,
};

export default TitleBar;
