import { roundNext15Min } from '@properly/common';
import path from 'ramda/src/path';
import either from 'ramda/src/either';
import log from 'loglevel';
import moment from 'moment-timezone';
import { put, fork, call, takeEvery } from 'redux-saga/effects';
import { fixJobRequestStartTime } from '@properly/common/src';
import * as types from '../../../../types';
import { handleEventButtonSaga as handleEventButtonSagaAction, setModalData } from './CalendarActions';
import { parseSkipEvent } from '../../data';
import {
  openJobRequestDetails,
  setJobRequestDetails,
  editSaga,
  moreCleanersSaga,
} from '../../jobDetails/state/JobDetailsActions';
import { prefilledDurationNewJobRequest, prefilledJobRequestStartTime } from '../../../../config';
import { trackEventSkip } from '../../../../actions/trackingEvents';
import { updateCollectionEntryBatch, setModal } from '../../../../actions/globalActions';
import { cancelJobRequest } from '../../../../graphql/api/jobRequest';
import { getSpecificJobrequest } from '../../../../graphql/api/jobList';

const getEventId = path(['event', 'id']);
const getJobRequestId = path(['event', 'jobRequest', 'jobSnapshotId']);
const getBackwardCompatibleJobId = path(['event', 'jobRequest', 'id']);
const getPropertyId = path(['event', 'property', 'id']);
const getChecklistId = path(['event', 'reminder', 'checklistId']);
const getReminderTime = path(['event', 'reminder', 'time']);
const getEventStartTime = path(['event', 'startTime']);
const getEventReminderTime = either(getReminderTime, getEventStartTime);
const getEventTimezone = path(['event', 'timeZone']);

function* openNewJobRequestModal(propertyId, time, event, eventId, preSetChecklist) {
  const getEventJobRequestSettings = path(['jobRequestSettings']);
  const getEventReminderTitle = path(['reminder', 'title']);
  const getEventReminderStartTime = path(['trigger', 'startTime']);
  const getEventReminderEndTime = path(['trigger', 'endTime']);

  const { time: timeSettings, offeredPrice, offeredPriceCurrency, message } = getEventJobRequestSettings(event) || {};

  // get the computed default start time
  const computedjobStartTime = fixJobRequestStartTime(time, prefilledJobRequestStartTime);

  const { endTime: endTimeSettings, startTime: startTimeSettings, duration: durationSetting, type } =
    timeSettings || {};

  const title = getEventReminderTitle(event);

  const startTime = startTimeSettings || computedjobStartTime || time || getEventReminderStartTime(event);

  const endTime =
    endTimeSettings ||
    moment(computedjobStartTime).add(durationSetting || prefilledDurationNewJobRequest, 'minutes') ||
    moment(time).add(durationSetting || prefilledDurationNewJobRequest, 'minutes') ||
    getEventReminderEndTime(event);

  yield put(
    setModal(true, 'jobrequest', {
      mode: 'normal',
      source: 'event',
      event,
      eventId,
      data: {
        message,
        price: offeredPrice,
        currency: offeredPriceCurrency,
        title,

        startTime: (startTime && roundNext15Min(startTime)) || time,
        endTime: (endTime && roundNext15Min(endTime)) || time,
        duration: durationSetting || prefilledDurationNewJobRequest,
        jobStartTimeType: type || 'fixed',

        propertyId,
        checklistId: preSetChecklist ? [preSetChecklist] : undefined,
      },
    }),
  );
}

function* loadJobRequestForModal(jobRequestId) {
  try {
    const res = yield call(getSpecificJobrequest, jobRequestId);

    yield put(
      updateCollectionEntryBatch(
        'jobRequests',
        {
          [res.jobId]: res,
        },
        true,
      ),
    );

    yield put(
      setJobRequestDetails({
        selectedJobRequestId: res.jobId,
        jobProgress: res.jobProgress,
        state: 0,
      }),
    );
  } catch (e) {
    log.error(loadJobRequestForModal, e);
  }
}

function getToolTipStateDirty() {
  const scrollSelector = '[data-key="properly-tooltip-scroll"]';
  const ele = document.querySelector('.js-properly-tooltip');
  const eleScroll = document.querySelector(scrollSelector);
  let idOfTrigger;
  let scrollPos = 0;
  if (ele) {
    const attr = ele.getAttribute('aria-labelledby');
    idOfTrigger = attr ? attr.replace('label_for_', '#trigger_') : undefined;
    scrollPos = eleScroll ? eleScroll.scrollTop : scrollPos;
  }
  return {
    idOfTrigger,
    scrollPos,
    scrollSelector,
  };
}

function resetToolTipStateDirty({ idOfTrigger, scrollPos, scrollSelector }) {
  const ele = idOfTrigger && document.querySelector(idOfTrigger);
  if (ele) {
    ele.click();
    setTimeout(() => {
      const eleScroll = scrollPos && document.querySelector(scrollSelector);
      if (eleScroll) {
        eleScroll.scrollTop = scrollPos;
      }
    }, 200);
  }
}

function closePopover() {
  document.getElementById('app').click();
}

function* handleEventSkip({ event, mode }) {
  const popoverState = getToolTipStateDirty();
  closePopover();
  yield put(
    setModalData({
      mode,
      continue: handleEventButtonSagaAction.bind(null, event, 'skipreal'),
      restore: () => resetToolTipStateDirty(popoverState),
    }),
  );
}

function* handleEventCancel({ event, mode }) {
  const popoverState = getToolTipStateDirty();
  closePopover();
  yield put(
    setModalData({
      mode,
      continue: handleEventButtonSagaAction.bind(null, event, 'cancelreal'),
      restore: () => resetToolTipStateDirty(popoverState),
    }),
  );
}

function* handleEventButtonSaga(action) {
  const eventId = getEventId(action);
  const jobRequestId = getJobRequestId(action);
  const backwardCompatibleJobId = getBackwardCompatibleJobId(action);
  const propertyId = getPropertyId(action);

  const checklistId = getChecklistId(action);

  const reminderTime = getEventReminderTime(action);
  const reminderTimeZone = getEventTimezone(action);
  const reminderLocalTime = moment(reminderTime).tz(reminderTimeZone);

  const jobRequestStartTime = reminderLocalTime.isValid() ? reminderLocalTime : null;

  const mode = action.mode;
  log.info('handleEventButtonSaga', eventId, mode, action, jobRequestId);

  try {
    switch (mode) {
      // Skip event
      case 'skip':
        yield handleEventSkip(action);
        break;

      // Confirm skip event
      case 'skipreal':
        yield call(parseSkipEvent, eventId);
        trackEventSkip(eventId);

        break;

      // Cancel event
      case 'cancel':
        yield handleEventCancel(action);
        break;

      // Confirm cancelation
      case 'cancelreal':
        yield call(cancelJobRequest, jobRequestId);
        break;

      // Job Request detail
      case 'jobrequest':
        closePopover();
        yield put(openJobRequestDetails(jobRequestId, undefined, backwardCompatibleJobId));
        break;

      // Send to more cleaners
      case 'sendmore':
        yield loadJobRequestForModal(jobRequestId);
        yield put(moreCleanersSaga(true));
        break;

      // Edit job request
      case 'edit':
        yield loadJobRequestForModal(jobRequestId);
        yield put(editSaga(true, { event: action.event }));
        break;

      // Schedule from a reminder
      case 'schedule':
        closePopover();
        yield openNewJobRequestModal(propertyId, jobRequestStartTime, action.event, eventId, checklistId);
        break;

      default:
        log.error('handleEventButtonSaga - dont know how to handle', action);
    }
    action.resolve();
  } catch (e) {
    log.error('skipEventSaga', e);
    action.reject();
  }
}

function* saga() {
  yield fork(takeEvery, types.CALENDAR_HANDLE_EVENT_BTN_SAGA, handleEventButtonSaga);
}

export default saga;
