"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var styles = {
  "default": {
    color: '#29333D',
    boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.16)',
    marginTop: '16px'
  },
  darkBackground: {
    backgroundColor: 'rgb(41, 51, 61)',
    color: 'white',
    marginLeft: '50px',
    marginTop: '0'
  },
  arrow: {
    overflowY: 'inherit',
    overflowX: 'inherit',
    '&:before': {
      content: '""',
      display: 'block',
      border: '10px solid transparent',
      left: 'calc( 50% - 10px )',
      position: 'absolute',
      borderRadius: '2px'
    }
  },
  topArrow: {
    marginTop: '12px',
    '&:before': {
      top: '-20px',
      borderBottom: '10px solid #29333d'
    }
  },
  bottomArrow: {
    marginTop: '-5px',
    '&:before': {
      bottom: '-20px',
      borderTop: '10px solid #29333d'
    }
  },
  topArrowAtRightSide: {
    '&:before': {
      right: '41px',
      left: 'auto'
    }
  },
  leftSideArrow: {
    '&:before': {
      left: '-19px',
      bottom: '15px',
      borderRight: '10px solid #29333d'
    }
  }
};
exports.styles = styles;