"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isCompleteAddress = isCompleteAddress;
exports.isIncompleteAddress = isIncompleteAddress;
exports.displayStreetWithApt = displayStreetWithApt;
exports.displayCityStateZip = displayCityStateZip;
exports.isPropertyOwner = isPropertyOwner;
exports.isSharedProperty = isSharedProperty;
exports.isSharingProperty = isSharingProperty;
exports.isSanctionedProperty = isSanctionedProperty;

function isCompleteAddress(address = {}, property = {}) {
  const location = property.location || {};
  const validLocationObject = location && typeof location.latitude === 'number' || typeof location[0] === 'number';
  return validLocationObject && address && address.street && address.zip && address.country;
}

function isIncompleteAddress(address, property) {
  return !isCompleteAddress(address, property);
}

function displayStreetWithApt(address) {
  const {
    apt,
    street
  } = address || {};
  const maybeApt = apt ? ` / ${String(apt).trim()}` : '';
  const streetString = street ? String(street).trim() : '';
  return streetString + maybeApt;
}

function displayCityStateZip(address) {
  const state = String(address && address.state || '').trim();
  const zip = String(address && address.zip || '').trim();
  const city = String(address && address.city || '').trim();
  const duplicateCity = city === state ? '' : city;
  const items = [duplicateCity, state].map(item => String(item || '').trim()).filter(item => !!item);
  const result = `${items.join(', ')} ${zip}`;
  return String(result || '').trim();
}

function isPropertyOwner(propertyOwnerRole, user) {
  return !!user && (user.defaultRole === propertyOwnerRole || user.personalRole === propertyOwnerRole);
}

function isSharedProperty(propertyOwnerRole, user) {
  return !isPropertyOwner(propertyOwnerRole, user);
}

function isSharingProperty(propertyId, collaboratorData) {
  return !!collaboratorData && (!!collaboratorData.all || !!collaboratorData[propertyId]);
}

function isSanctionedProperty(property) {
  // check if property is a sanctioned property in Booking.com source system
  return property && property.bookingComSanctionedProperty;
}