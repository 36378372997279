import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LoadingSpinner, Icon, Regular, SpaceMedium, SpaceSmallMid } from '@properly/components';
import t from '@properly/localization';
import classNames from 'classnames/bind';
import styles from '../components/accountStyles.module.css';
import * as selectorsAccount from '../state/AccountSelectors';
import { oAuthSaga, setState, goToAccountConntections, reImportProperties } from '../state/AccountActions';

const cx = classNames.bind(styles);

class AccountOAuthContainer extends Component {
  get generalStatesJS() {
    return this.props.generalStates.toJS();
  }

  mapError(code) {
    switch (code) {
      case 1:
        return t('account.error_oauth');
      case 2:
        return t('account.error_profile_import');
      case 3:
        return t('account.error_listing_import');
      case 4:
        return t('account.error_rev_import');
      case 5:
        return t('account.error_already_connected');
      default:
        return t('account.error_oauth');
    }
  }

  mapSections(data) {
    const { step } = data;
    if (data.error) {
      return (
        <div style={{ display: 'flex', height: '100%' }}>
          <div onClick={() => this.props.onClose()} className={cx('account__loading-error-close')}>
            <Icon.IcClose />
          </div>
          <div className={cx('account__loading-error-txt')}>
            <Regular type="orange">{this.mapError(data.error)}</Regular>
          </div>
        </div>
      );
    }

    if (step === 'getproperties' || step === 'syncproperty') {
      const showStat = step === 'syncproperty' && data.stat && data.stat[0] > 0;
      return (
        <div className={cx('account__loading-inner')}>
          <Icon.IcBigProperties2 />
          <SpaceMedium />
          {!showStat && <Regular>{t('account.importing_listing')}</Regular>}
          {showStat && <Regular>{t('account.importing_listing_x_of_y', { x: data.stat[0], y: data.stat[1] })}</Regular>}
          <SpaceSmallMid />
          <LoadingSpinner />
        </div>
      );
    }
    if (step === 'connect') {
      return (
        <div className={cx('account__loading-inner')}>
          <Icon.IcBigProfile />
          <SpaceMedium />
          <Regular>{t('account.importing_profile')}</Regular>
          <SpaceSmallMid />
          <LoadingSpinner />
        </div>
      );
    }
    if (step === 'booking') {
      return (
        <div className={cx('account__loading-inner')}>
          <Icon.IcBigBookings />
          <SpaceMedium />
          <Regular>{t('account.importing_revs')}</Regular>
          <SpaceSmallMid />
          <LoadingSpinner />
        </div>
      );
    }
    return '';
  }

  render() {
    return <div className={cx('account__loading')}>{this.mapSections(this.generalStatesJS.oauth)}</div>;
  }
}

function mapStateToProps(state, props) {
  return {
    generalStates: selectorsAccount.selectStates()(state, props),
  };
}

AccountOAuthContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  {
    oAuthSaga,
    setState,
    goToAccountConntections,
    reImportProperties,
  },
)(AccountOAuthContainer);
