import React, { Component } from 'react';
import { connect } from 'react-redux';
import log from 'loglevel';

import filter from 'lodash/filter';
import lodashResult from 'lodash/result';
import map from 'lodash/map';
import assign from 'lodash/assign';
import { DividerLine, Title3, Regular, SpaceBase, SpaceXLarge, Icon } from '@properly/components';
import t from '@properly/localization';
import { bindActionCreators } from 'redux';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import { refetchUserSaga } from '../../../../actions/globalActions';
import { PagePadding } from '../../../../components/PageElements';
import AccountProviderSection from '../../../../components/AccountProviderSection';
import {
  goToConnectionEdit,
  oAuthSwitchFull,
  oAuthSaga,
  setState,
  setWarningModal,
  redirectToProviderLegacySaga,
} from '../state/AccountActions';
import { setCloneProperty } from '../../property/state/PropertyActions';
import { trackPartnerConnectPageOpen } from '../../../../actions/trackingEvents';
import { AccountHeadSection } from '../../../../components/AccountElements';

class AccountConnectionsLegacyContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    trackPartnerConnectPageOpen();
    this.props.refetchUserSaga();
    const params = this.props.location.query;
    if (params.code && params.oAuth) {
      this.props.oAuthSaga(params.code, params.oAuth, oAuthSwitchFull, 'account');
    }
  }

  get sections() {
    const { partner: partnerConfig } = this.props.config || {};

    return map(filter(partnerConfig, partner => partner && partner.enabled), partner =>
      assign({}, partner, {
        text: partner.title,
        redirectUrl: this.genRedirectUrl(partner.link),
        filterKey: partner.id,
        fireAction: partner.fireAction,
      }),
    );
  }

  genRedirectUrl(baseUrl) {
    // current location without url parameters
    const currentLocation = window.location.href.slice(0, window.location.href.indexOf('?'));
    return `${baseUrl}?redirect=${encodeURIComponent(currentLocation)}`;
  }

  redirectToProvider(goal, partnerTitle, partnerData) {
    if (!goal) return alert('could not load oauth provider from config');
    if (partnerData.fireAction) {
      this.props.dispatch(partnerData.fireAction);
      return '';
    }
    const loginWarningBlacklist = lodashResult(this.props.config, ['partnerLoginWarningBlacklist']);
    this.props.redirectToProviderLegacySaga({
      goal,
      partnerTitle,
      connectedAccount: partnerData,
      loginWarningBlacklist,
    });
    return '';
  }

  renderSections(sections) {
    return map(sections, section => (
      <div key={section.filterKey}>
        <AccountProviderSection
          accounts={section.connections}
          onButtonClick={() => this.redirectToProvider(section.redirectUrl, section.text, section)}
          onAdd={() => this.redirectToProvider(section.redirectUrl, section.text, section)}
          onEdit={account =>
            this.props.goToConnectionEdit(
              section.id,
              account.partnerUserId,
              account.connectionIssue,
              'account/connections-legacy',
            )
          }
          icon={section.icon}
          text={section.text}
        />
        <SpaceBase />
      </div>
    ));
  }

  mapUserToSection(user, sections) {
    const mapped = map(user.connectedAccounts, item => ({
      ...item,
      resolvedName: `${item.name} (${item.contact})`,
    }));
    return map(sections, section => ({
      ...section,
      connections: filter(mapped, { partner: section.filterKey }),
    }));
  }

  handleMergeClick = () => {
    this.props.setCloneProperty({
      isOpen: true,
    });
  };

  render() {
    const { currentUser, config } = this.props;
    const { partner: partnerConfig } = config || {};
    const sections = this.sections;

    if (!currentUser.user) return null;
    const finalData = this.mapUserToSection(currentUser.user, sections);

    log.info('AccountConnectionsLegacyContainer render', {
      finalData,
      currentUser,
      partnerConfig,
      sections,
    });
    return (
      <PagePadding type="absolute-scroll">
        <AccountHeadSection>
          <Title3 type="nomargin">{t('account.connect_accounts')}</Title3>
          <DividerLine type={['bottom']} />
        </AccountHeadSection>

        <SpaceBase />
        <Regular type="greyloading">{t('account.connect_txt')}</Regular>
        <SpaceXLarge />

        <div>
          {this.renderSections(finalData)}

          <AccountProviderSection.Box
            isMerge
            onClick={this.handleMergeClick}
            text={t('account.merge')}
            isFirst
            isLast
            icon={Icon.IcMerge}
          />
        </div>
      </PagePadding>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    currentUser: state.currentUser,
    config: selectorsGlobal.selectConfig(state, props),
  };
}

export default connect(
  mapStateToProps,
  dispatch => ({
    ...bindActionCreators(
      {
        refetchUserSaga,
        goToConnectionEdit,
        oAuthSaga,
        setState,
        setCloneProperty,
        setWarningModal,
        redirectToProviderLegacySaga,
      },
      dispatch,
    ),
    dispatch,
  }),
)(AccountConnectionsLegacyContainer);
