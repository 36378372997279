import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import t from '@properly/localization';
import { Input, Icon, ButtonList, PopoverDefault } from '@properly/components';
import lodashResult from 'lodash/result';
import map from 'lodash/map';
import { getCurrencyFromCountryShortCode } from '@properly/common';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import styles from './index.module.css';
import ReportsInput from '../../components/ReportsInput';
import ReportsBox from '../../components/ReportsBox';
import Sidebar from '../../components/Sidebar';
import { MasterDetailCell } from '../../components/MasterDetail';
import { selectJobs } from '../../selectors/globalSelector';
import { createChecklistSaga } from '../../modules/desktopApp/checklists/state/ChecklistActions';
import {
  editEventSaga,
  deleteEventSaga,
  setEditEventData,
  cancelEditEventSaga,
  saveEditTrigger,
} from '../../modules/desktopApp/property/state/PropertyActions';
import {
  propertiesEventDataMeta,
  propertiesTriggerResolved,
} from '../../modules/desktopApp/property/state/PropertySelectors';
import Property from '../../model/property';
import Trigger from '../../model/trigger';
import { setModal, updateInBackgroundSaga } from '../../actions/globalActions';
import { prefilledDurationNewJobRequest } from '../../config';

const cx = classNames.bind(styles);

const alexaActive = { settings: { hasAlexaActions: true } };
const alexaInctive = { settings: { hasAlexaActions: false } };

class EventContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      modalOpen: false,
      arn: '',
      resultAlexa: '',
      alexaSetupLoading: false,
    };
  }

  isOpen(section) {
    return this.state[section];
  }

  toggleSection = (section, val) => () => {
    const valGen = state => (val !== undefined ? val : !state[section]);
    this.setState(state => ({ ...state, [section]: valGen(state) }));
  };

  componentWillReceiveProps(nextProps) {
    const next = lodashResult(nextProps, ['resolvedTrigger', 'jobId']);
    if (next !== this.resolveJob) {
      this.toggleSection('checklist', false)();
    }
  }

  get resolveJob() {
    return lodashResult(this, ['props', 'resolvedTrigger', 'jobId']);
  }

  get resolveAlexaActions() {
    return lodashResult(this, ['props', 'resolvedTrigger', 'settings', 'hasAlexaActions']);
  }

  get resolveType() {
    return lodashResult(this, ['props', 'resolvedTrigger', 'type']);
  }

  get resolveName() {
    return lodashResult(this, ['props', 'resolvedTrigger', 'title']);
  }

  get resolveId() {
    return lodashResult(this, ['props', 'resolvedTrigger', 'objectId']);
  }

  get resolvedChecklistTitle() {
    const checklist = lodashResult(this.props.checklists.get(this.resolveJob), ['title']);
    if (checklist) {
      return this.getChecklistType(this.props.checklists.get(this.resolveJob));
    }
    if (this.resolveAlexaActions) {
      return this.getAlexaType();
    }
    return undefined;
  }

  get resolvedChecklist() {
    return this.props.checklists.get(this.resolveJob);
  }

  get isActive() {
    return this.props.isActive;
  }

  get isValid() {
    return this.resolveName && this.resolveType;
  }

  hasError(key) {
    if (!this.state.showError) return false;
    switch (key) {
      case 'name': {
        return !this.resolveName;
      }
      case 'type': {
        return !this.resolveType;
      }
      default:
        return undefined;
    }
  }

  handleOnSave = isValid => () => {
    if (!isValid) {
      this.setState({ showError: true });
      return;
    }
    this.setState({ showError: false });

    this.props.saveEditTrigger(this.props.resolvedTrigger);
  };

  handleOnCancel = () => {
    this.setState({ showError: false });
    this.props.cancelEditEventSaga(this.props.resolvedTrigger);
  };

  handleCreateChecklist = () => () => {
    this.props.createChecklistSaga({
      role: this.props.propertyData.role,
      user: this.props.propertyData.userId,
      propertyId: this.props.propertyData.objectId,
      title: t('checklist.checklist_untitled'),
      openInModal: true,
    });
  };

  handleDefaultSettings = () => {
    const checklistObj = this.resolvedChecklist;

    if (!checklistObj) {
      return;
    }

    const { propertyData } = this.props;
    const { objectId, defaultJob } = propertyData || {};

    this.props.setModal(true, 'jobrequest', {
      mode: 'prefill',
      source: 'property',
      checklistObj,
      data: {
        currency: getCurrencyFromCountryShortCode(propertyData) || 'USD',
        propertyId: objectId,
        checklistId: [checklistObj.objectId],
        isDefault: defaultJob === checklistObj.objectId,
        startTime: undefined,
        endTime: undefined,
        time: undefined,
        duration: prefilledDurationNewJobRequest,
        jobStartTimeType: 'fixed',
      },
    });
  };

  renderBtn() {
    return (
      <PopoverDefault
        popoverKey="reminder"
        hasArrow
        arrowPosition={{
          horizontal: 'right',
          vertical: 'top',
        }}
        width={150}
        popoverContent={PopoverState => (
          <ButtonList
            items={[
              {
                'data-key': 'edit_reminder',
                name: t('properties.edit_trigger'),
                onClick: () => this.props.editEventSaga(this.props.trigger),
              },
              {
                'data-key': 'delete_reminder',
                name: t('properties.delete_trigger'),
                onClick: () => this.props.deleteEventSaga(this.props.trigger),
              },
            ]}
            closePopover={PopoverState.closePopup}
          />
        )}
      >
        <div data-key="edit_reminders" className={cx('event-container__btn')}>
          {!this.isActive && <Icon.IcMorePrimary />}
          {this.isActive && <Icon.IcMoreGrey />}
        </div>
      </PopoverDefault>
    );
  }

  mapTypeToTranslatedTxt(kind) {
    switch (kind) {
      case 'checkIn':
        return t('properties.kind.checkin');
      case 'checkOut':
        return t('properties.kind.checkout');
      default:
        return undefined;
    }
  }

  renderWhen() {
    const key = 'when';
    const types = ['checkIn', 'checkOut'];

    const { isNewTrigger } = this.props;
    const canEditTrigger = !!isNewTrigger;
    const onClick = canEditTrigger ? this.toggleSection(key) : () => {};
    // We are only able to edit this drop down when it is a new trigger.
    // If we are editing the trigger, we must disable changing its type
    // as there is no way for this to be updated via calendar events etc.

    const isActive = this.isActive && canEditTrigger;
    const isNotActive = !this.isActive || !canEditTrigger;

    return (
      <div>
        <ReportsInput.Dropdown
          data-key="reminder_when_dropdown"
          onClick={onClick}
          isOpen={this.isOpen(key)}
          pretxt={t('properties.when')}
          error={this.hasError('type')}
          greyPart={isNotActive}
          greyAll={isActive}
          disabled={!canEditTrigger}
          input={this.mapTypeToTranslatedTxt(this.resolveType)}
          placeholder={t('properties.when')}
        />
        {this.isOpen(key) && (
          <ReportsBox onClose={this.toggleSection(key)}>
            <Sidebar.List style={{ minHeight: 140 }}>
              <Sidebar.ListInner>
                <Sidebar.ListInnerInner>
                  {map(types, kind => (
                    <MasterDetailCell
                      data-key={`reminder_when_${kind}`}
                      type="status"
                      key={kind}
                      noHover
                      onClick={() => {
                        this.toggleSection(key)();
                        this.props.setEditEventData({ type: kind });
                      }}
                      active={this.resolveType === kind}
                      title={this.mapTypeToTranslatedTxt(kind)}
                    />
                  ))}
                </Sidebar.ListInnerInner>
              </Sidebar.ListInner>
            </Sidebar.List>
          </ReportsBox>
        )}
      </div>
    );
  }

  getChecklistType(checklist) {
    return `(${t('properties.prefill_checklist')}) ${checklist.title}`;
  }

  getAlexaType() {
    return `(${t('amazon_alexa.wipe_alexa')})`;
  }

  handleAlexaClick = key => () => {
    if (!this.props.propertyData.hasAlexaSetup) return;
    this.toggleSection(key)();
    this.props.setEditEventData({ jobId: null, ...alexaActive });
  };

  renderChecklist(checklists) {
    const key = 'checklist';
    const checklistsJS = checklists.toJS();

    return (
      <div>
        <ReportsInput.Dropdown
          data-key="reminder_checklist_dropdown"
          isOpen={this.isOpen(key)}
          onClick={this.toggleSection(key)}
          input={this.resolvedChecklistTitle}
          pretxt="Action"
          greyPart={!this.isActive}
          error={this.hasError('checklist')}
          greyAll={this.isActive}
          placeholder={t('checklist.select_action')}
        />
        {this.isOpen(key) && (
          <ReportsBox onClose={this.toggleSection(key)}>
            <Sidebar.List style={{ minHeight: 140 }}>
              <Sidebar.ListInner>
                <Sidebar.ListInnerInner>
                  <MasterDetailCell
                    type="status"
                    data-key="reminder_alexa_dropdown"
                    noHover
                    disabled={!this.props.propertyData.hasAlexaSetup}
                    onClick={this.handleAlexaClick(key)}
                    active={this.resolveAlexaActions}
                    title={this.getAlexaType()}
                  />
                  {Object.keys(checklistsJS).map(checklistKey => {
                    const checklist = checklistsJS[checklistKey];
                    return (
                      <MasterDetailCell
                        type="status"
                        data-key="reminder_checklist_item"
                        key={checklist.objectId}
                        noHover
                        onClick={() => {
                          this.toggleSection(key)();
                          this.props.setEditEventData({
                            jobId: checklist.objectId,
                            ...alexaInctive,
                          });
                        }}
                        active={this.resolveJob === checklist.objectId}
                        title={this.getChecklistType(checklist)}
                      />
                    );
                  })}
                </Sidebar.ListInnerInner>
              </Sidebar.ListInner>
            </Sidebar.List>
          </ReportsBox>
        )}
      </div>
    );
  }

  renderName() {
    return (
      <ReportsInput
        error={this.hasError('name')}
        onChange={e => this.props.setEditEventData({ title: e.target.value })}
        value={this.resolveName}
        placeholder={t('properties.name')}
      />
    );
  }

  get checklistsFiltered() {
    return this.props.checklists.filter(
      job => job.defaultProperty === this.props.propertyData.objectId && !job.deleted,
    );
  }

  render() {
    const key = `trigger-${this.props.trigger.objectId}`;

    return (
      <div>
        <div
          data-key={key}
          className={cx('event-container', {
            'event-container--active': this.isActive,
          })}
        >
          {!this.isActive && <div className={cx('event-container__overlay')} onClick={e => e.stopPropagation()} />}
          {this.renderBtn(this.isActive, key)}
          <div className={cx('event-container__boxwrap')}>
            <div className={cx('event-container__box')}>{this.renderName()}</div>
            <div className={cx('event-container__box')}>
              <div className={cx('event-container__box-inner')}>{this.renderWhen()}</div>
            </div>
          </div>
          <div className={cx('event-container__boxwrap')}>
            <div className={cx('event-container__box')}>
              <div className={cx('event-container__box-inner')}>{this.renderChecklist(this.checklistsFiltered)}</div>
            </div>
            <div className={cx('event-container__box')}>
              {this.resolveJob && (
                <div className={cx('event-container__prefillbtn')} onClick={this.handleDefaultSettings}>
                  {t('properties.prefill_event')}
                </div>
              )}
            </div>
          </div>
        </div>
        {this.isActive && (
          <Input.CanSave
            onCancel={this.handleOnCancel}
            onSave={this.handleOnSave(this.isValid)}
            canSave={this.props.meta.get('savingState')}
          />
        )}
      </div>
    );
  }
}

EventContainer.propTypes = {
  isNewTrigger: PropTypes.bool.isRequired,
  propertyData: PropTypes.instanceOf(Property).isRequired,
  trigger: PropTypes.instanceOf(Trigger).isRequired,
};

function mapStateToProps(state, props) {
  const res = propertiesTriggerResolved()(state, props);
  return {
    checklists: selectJobs()(state),
    meta: propertiesEventDataMeta()(state),
    isActive: res.isActive,
    resolvedTrigger: res.val,
    isNewTrigger: res.val.objectId === 'new',
  };
}

export default connect(mapStateToProps, {
  createChecklistSaga,
  editEventSaga,
  deleteEventSaga,
  setEditEventData,
  cancelEditEventSaga,
  saveEditTrigger,
  setModal,
  updateInBackgroundSaga,
})(EventContainer);
