import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import log from 'loglevel';
import merge from 'lodash/merge';
import { CalendarListItem, CalendarCellRow, CalendarCellProperty, CalendarCellColumn } from '@properly/components';
import { isToday, isSharedProperty, isSharingProperty, isSanctionedProperty } from '@properly/common';
import moment from 'moment-timezone';
import * as selectors from '../state/CalendarSelectors';
import { goToProperty, setModal } from '../../../../actions/globalActions';
import { selectCurrentUser, selectPropertyCollaborators } from '../../../../selectors/globalSelector';
import { handleEventButtonSaga, setBackdrop } from '../state/CalendarActions';
import { goNewProperty } from '../../property/state/PropertyActions';
import { canSeeCleanerNames, canSendPropertyJobs } from '../../../../hoc/HasPermission/hasAccess';

class CalendarListItemContainer extends PureComponent {
  static propTypes = {
    index: PropTypes.number.isRequired,
    onExpand: PropTypes.func,
  };

  renderEmptyRow(dates) {
    return (
      <CalendarCellRow>
        <CalendarCellProperty key="calendar-property-col-empty" />
        {dates.map(date => {
          const isTodaysDate = isToday(date);
          const key = date;
          return <CalendarCellColumn key={key} isToday={isTodaysDate} />;
        })}
      </CalendarCellRow>
    );
  }

  openJobRequest(day, propertyId, propertyTimezone) {
    const duration = this.props.preFilledDurationNewJobRequest || '60';

    const startTime = moment
      .tz(day, propertyTimezone)
      .hours(10)
      .minutes(0)
      .seconds(0);

    const endTime = startTime.clone().add(duration, 'm');

    const data = {
      propertyId,
      startTime,
      endTime,
      duration,
      jobStartTimeType: 'fixed',
    };

    log.info('Open job request', day, propertyId, propertyTimezone, data);

    this.props.setCalendarModal(true, 'jobrequest', {
      mode: 'normal',
      source: 'calendar',
      data,
    });
  }

  getPropertyEventsByIndex() {
    const { dateBucketIds, propertyEvents } = this.props;

    if (!propertyEvents) {
      return {};
    }

    return dateBucketIds.reduce((acc, currentDateBucketId) => {
      const currentPropertyEvents = propertyEvents.get(currentDateBucketId);
      const events = (currentPropertyEvents && currentPropertyEvents.toJS()) || {};

      const otherEventsAcc = (acc && acc.otherEvents) || {};
      const bookingEventsAcc = (acc && acc.bookingEvents) || {};

      // Need to merge other Events at the date level also
      const otherEvents = merge(otherEventsAcc, events.otherEvents || {});

      return acc
        ? {
            bookingEvents: { ...bookingEventsAcc, ...(events.bookingEvents || {}) },
            otherEvents,
          }
        : events;
    }, null);
  }

  handleEventAction = (...args) => {
    log.info('CalendarListItemContainer handleEventAction', ...args);
    return this.props.handleEventButtonSaga(...args);
  };

  render() {
    const { index, currentUser, filterDays, width, days, property, collaboratorData, isCanceledFiltered } = this.props;

    if (!property) {
      return this.renderEmptyRow((days && days.toJS()) || []);
    }

    const propertyObject = property.toJS();
    const { ownerRole, ownerName, id: propertyId } = propertyObject || {};

    const { bookingEvents, otherEvents } = this.getPropertyEventsByIndex();

    const sharingProperty = isSharingProperty(propertyId, this.props.collaboratorData);
    const sharedProperty = isSharedProperty(ownerRole, this.props.currentUser);
    const sanctionedProperty = isSanctionedProperty(propertyObject);

    const seeCleanerNamesPermission = canSeeCleanerNames({ propertyOwner: ownerRole, user: currentUser });
    const canSendJobsPermission = canSendPropertyJobs({ propertyOwner: ownerRole, user: currentUser });

    return (
      <CalendarListItem
        index={index}
        width={width}
        dates={days.toJS()}
        filterDays={filterDays}
        property={propertyObject}
        propertyBookings={bookingEvents || {}}
        propertyOtherEvents={otherEvents || {}}
        sharedProperty={sharedProperty}
        sharingProperty={sharingProperty}
        sanctionedProperty={sanctionedProperty}
        collaboratorData={collaboratorData}
        propertyOwnerName={ownerName}
        seeCleanerNamesPermission={seeCleanerNamesPermission}
        canSendJobsPermission={canSendJobsPermission}
        currentUser={currentUser}
        openJobRequest={(day, selectedPropertyId, selectedPropertyTimezone) =>
          this.openJobRequest(day, selectedPropertyId, selectedPropertyTimezone)
        }
        goToProperty={this.props.goToProperty}
        onEventAction={this.handleEventAction}
        expandSize={this.props.expandSize}
        onExpand={this.props.onExpand}
        shouldFilterCanceledEvents={!isCanceledFiltered}
        onBookingClick={this.props.onBookingClick ? this.props.onBookingClick : null}
      />
    );
  }
}

function mapStateToProps() {
  const selectUser = selectCurrentUser();
  const selectCollaborator = selectPropertyCollaborators();
  return (state, props) => {
    const { index } = props || {};
    return {
      index,
      filterDays: selectors.selectFilterDays(state),
      days: selectors.selectDatesDays(state),
      dateBucketIds: selectors.selectDateBucketIds(state),
      property: selectors.selectPropertyByIndex(index)(state),
      propertyEvents: selectors.selectPropertyEventsByIndex(index)(state),
      currentUser: selectUser(state),
      collaboratorData: selectCollaborator(state),
      isCanceledFiltered: selectors.selectShowCanceledEvents(state),
    };
  };
}

const mapDispatchToProps = {
  goNewProperty,
  goToProperty,
  setCalendarModal: setModal,
  handleEventButtonSaga,
  setBackdrop,
};

export default connect(
  mapStateToProps(),
  mapDispatchToProps,
)(CalendarListItemContainer);
