import gql from 'graphql-tag';

export const GetReminderOptions = gql`
  {
    reminderOptions {
      label
      value
    }
  }
`;

export const GetCalendarFilterSchema = gql`
  {
    calendarFilterSchema {
      filters {
        categoryId
        categoryLabel
        categoryIcon
        fields {
          label
          id
          type
          options {
            label
            value
          }
          autocomplete {
            graphqlSchema
            graphqlKey
          }
        }
      }
    }
  }
`;

export const GetCalendarPropertyEvents = gql`
  query calendarPropertiesQuery(
    $offset: Int
    $limit: Int
    $eventStartDate: DateTime
    $eventEndDate: DateTime
    $propertySearch: String
    $timeZone: String
    $filters: [Filter]
  ) {
    calendarProperties(
      offset: $offset
      timeZone: $timeZone
      limit: $limit
      propertySearch: $propertySearch
      filters: $filters
    ) {
      info {
        total
        limit
        offset
      }
      result {
        id
        title
        type
        timeZone
        location
        ownerRole
        ownerName
        displayAddress
        bookingComSanctionedProperty
        address {
          street
          apt
          city
          state
          zip
          country
        }
        events(startDate: $eventStartDate, endDate: $eventEndDate) {
          id
          type
          startTime
          endTime
          timeZone
          serviceProviderIds
          days
          property {
            id
          }
          booking {
            id
            title
            statusCode
            status
            partner
            guestName
            numberOfGuests
            arrivalDate
            departureDate
            arrivalLocalDay
            departureLocalDay
            bookingDurationDays
          }
          jobRequest {
            id
            jobSnapshotId
            seen
            title
            cleanerName
            status
            statusCode
            incomplete
            problem
            jobStartTimeType
            duration
          }
          jobRequestSettings {
            message
            offeredPrice
            offeredPriceCurrency
            time {
              duration
              endTime
              startTime
              type
            }
          }
          reminder {
            reminderId
            time
            title
            type
            needsCTA
            checklistId
          }
        }
      }
    }
  }
`;
