import moment from 'moment-timezone';
import { jobRequestStatus, bookingStatus } from '@properly/config';
import t from '@properly/localization';
import memoize from 'lodash/memoize';
import { capitalizeFirstLetter } from '../string/index';
import { formatMinHours } from '../time/index';

const calendarEventDateFormat = 'YYYYMMDD';

const memoizeDateUnix = memoize(date => moment(date).unix());

/**
 * Types
 */

export function hasCalendarEventJobRequest(calendarEvent = {}) {
  return !!getCalendarEventJobRequestId(calendarEvent);
}

export function hasOnlyCalendarEventJobRequest(calendarEvent = {}) {
  return hasCalendarEventJobRequest(calendarEvent) && !hasCalendarEventReminder(calendarEvent);
}

export function hasCalendarEventReminder(calendarEvent = {}) {
  return !!getCalendarEventReminderId(calendarEvent);
}

export function hasOnlyCalendarEventReminder(calendarEvent = {}) {
  return hasCalendarEventReminder(calendarEvent) && !hasCalendarEventJobRequest(calendarEvent);
}

export function hasCalendarEventReminderAndJobRequest(calendarEvent = {}) {
  return hasCalendarEventReminder(calendarEvent) && hasCalendarEventJobRequest(calendarEvent);
}

export function getCalendarEventJobRequestType(calendarEvent = {}) {
  return getCalendarEventJobRequest(calendarEvent).jobStartTimeType;
}
export function isCalendarEventJobRequestTypeFlexible(calendarEvent = {}) {
  return getCalendarEventJobRequestType(calendarEvent) === 'flexible';
}

export function getCalendarEventType(calendarEvent = {}) {
  return calendarEvent.type;
}

export function getCalendarEventServiceProviders(calendarEvent = {}) {
  return calendarEvent.serviceProviderIds;
}

export function getCalendarEventNumberOfServiceProviders(calendarEvent = {}) {
  return (getCalendarEventServiceProviders(calendarEvent) || []).length;
}

/**
 * Job Request
 */

export function getCalendarEventJobRequestId(calendarEvent = {}) {
  return getCalendarEventJobRequest(calendarEvent).id;
}

export function getCalendarEventJobRequest(calendarEvent = {}) {
  return calendarEvent.jobRequest || {};
}

export function isCalendarEventDelayed(calendarEvent = {}) {
  const status = getCalendarEventJobRequestStatus(calendarEvent);
  const isAccepted = status === jobRequestStatus.StateAccepted;

  if (!isAccepted) {
    return false;
  }

  // If the job is a flexible start time, then it is not delayed until (endtime - duration < currentTime)
  const isFlexible = isCalendarEventJobRequestTypeFlexible(calendarEvent);
  if (isFlexible) {
    const endMoment = getCalendarEventEndMoment(calendarEvent);
    const duration = getCalendarEventJobRequestDuration(calendarEvent);
    return endMoment.subtract(duration, 'mins').isBefore();
  }

  const startDate = getCalendarEventStartDate(calendarEvent);
  return new Date() - startDate > 0;
}

export function getCalendarEventJobRequestTitle(calendarEvent = {}) {
  return getCalendarEventJobRequest(calendarEvent).title;
}

export function isCalendarEventSeen(calendarEvent = {}) {
  return getCalendarEventJobRequest(calendarEvent).seen;
}

export function getCalendarEventJobRequestStatus(calendarEvent = {}) {
  return getCalendarEventJobRequest(calendarEvent).statusCode;
}

export function isCalendarEventJobRequestCanceled(calendarEvent = {}) {
  return getCalendarEventJobRequest(calendarEvent).statusCode === jobRequestStatus.StateCancelled;
}

export function hasCalendarEventProblem(calendarEvent = {}) {
  return getCalendarEventJobRequest(calendarEvent).problem;
}

export function hasCalendarEventIncomplete(calendarEvent = {}) {
  return getCalendarEventJobRequest(calendarEvent).incomplete;
}

export function getCalendarEventJobRequestDuration(calendarEvent = {}) {
  return getCalendarEventJobRequest(calendarEvent).duration;
}

export function isCalendarEventStarted(calendarEvent = {}) {
  const statusCode = getCalendarEventJobRequestStatus(calendarEvent);
  return statusCode === jobRequestStatus.StateStarted || statusCode === jobRequestStatus.StatePaused;
}

export function isCalendarEventFinished(calendarEvent = {}) {
  const statusCode = getCalendarEventJobRequestStatus(calendarEvent);
  return statusCode === jobRequestStatus.StateFinished;
}

export function getCalendarEventJobRequestNumberOfCleaners(calendarEvent = {}) {
  const numberFromJobRequest = getCalendarEventJobRequest(calendarEvent).numberOfCleaners;
  const numberFromServiceProviders = getCalendarEventNumberOfServiceProviders(calendarEvent);
  const asNumber = value => (Number.isNaN(Number(value)) ? 0 : Number(value));

  return Math.max(asNumber(numberFromServiceProviders), asNumber(numberFromJobRequest));
}

export function getCalendarEventJobRequestDisplayTime(calendarEvent = {}) {
  const startMoment = getCalendarEventStartMoment(calendarEvent);
  const endMoment = getCalendarEventEndMoment(calendarEvent);

  const currentTime = moment();
  const isAfter = currentTime.isAfter(startMoment);
  const startedLocalizationKey = isAfter ? 'calendar.started_at' : 'calendar.starting_at';

  const durationInMinutes = getCalendarEventJobRequestDuration(calendarEvent);
  const isFlexible = isCalendarEventJobRequestTypeFlexible(calendarEvent);

  if (startMoment && !endMoment) {
    return t(startedLocalizationKey, {
      time: startMoment.format('LT'),
    });
  }

  const jobStartTimeMoment = isFlexible ? endMoment : startMoment;
  const flexibleLabel = isFlexible ? 'Flex.' : '';

  return `${flexibleLabel} ${jobStartTimeMoment.format('LT')} (${formatMinHours(durationInMinutes)})`;
}

/**
 * Reminder
 */

export function getCalendarEventReminder(calendarEvent = {}) {
  return calendarEvent.reminder || {};
}

export function getCalendarEventReminderId(calendarEvent = {}) {
  const reminder = getCalendarEventReminder(calendarEvent);
  return reminder.reminderId || reminder.id;
}

export function getCalendarEventReminderTitle(calendarEvent = {}) {
  return getCalendarEventReminder(calendarEvent).title;
}

export function hasCalendarEventCTA(calendarEvent = {}) {
  return !!getCalendarEventReminder(calendarEvent).needsCTA;
}

export function getCalendarEventReminderUUID(calendarEvent = {}) {
  const ids = [
    getCalendarEventType(calendarEvent),
    getCalendarEventPropertyId(calendarEvent),
    getCalendarEventPropertyAddress(calendarEvent),
    // Reminder
    getCalendarEventReminderTitle(calendarEvent),
    // Booking details
    getCalendarEventBookingPartner(calendarEvent),
    getCalendarEventBookingDatesFormatted(calendarEvent),
    getCalendarEventBookingStatus(calendarEvent),
    getCalendarEventBookingGuestCount(calendarEvent),
    getCalendarEventBookingGuestName(calendarEvent),
    getCalendarEventBookingTitle(calendarEvent),
  ];
  return ids.join('--');
}

/**
 * Booking
 */

export function getCalendarEventBooking(calendarEvent = {}) {
  return calendarEvent.booking || {};
}

export function getCalendarEventBookingId(calendarEvent = {}) {
  return getCalendarEventBooking(calendarEvent).id;
}

export function getCalendarEventBookingPartner(calendarEvent = {}) {
  const { partner } = getCalendarEventBooking(calendarEvent);
  return partner === 'iCal' ? partner : capitalizeFirstLetter(partner);
}

export function getCalendarEventBookingInfoFormatted(calendarEvent = {}, renderGuestString) {
  const partner = getCalendarEventBookingPartner(calendarEvent);

  const partnerString = (partner && `${partner}: `) || '';
  const dateString = getCalendarEventBookingDatesFormatted(calendarEvent);
  const guestString = getCalendarEventBookingGuestFormatted(calendarEvent, renderGuestString);

  return [[partnerString, dateString].join(''), guestString]
    .filter(val => !!val)
    .join(' • ')
    .trim();
}

export function getCalendarEventBookingGuestFormatted(calendarEvent = {}, renderGuestString) {
  const numberOfGuests = getCalendarEventBookingGuestCount(calendarEvent);
  const guestName = getCalendarEventBookingGuestName(calendarEvent);

  const guestCountString = renderGuestString && numberOfGuests > 0 ? `${renderGuestString(numberOfGuests)}` : '';
  return [guestCountString, guestName]
    .filter(val => !!val)
    .join(' • ')
    .trim();
}

export function getCalendarEventBookingDatesFormatted(calendarEvent = {}) {
  const bookingArrivalDate = getCalendarEventArrivalMoment(calendarEvent);
  const bookingDepartureDate = getCalendarEventDepartureMoment(calendarEvent);

  return (
    (bookingArrivalDate &&
      bookingDepartureDate &&
      `${bookingArrivalDate.format('L')} – ${bookingDepartureDate.format('L')}`) ||
    ''
  );
}

export function getCalendarEventBookingGuestCount(calendarEvent = {}) {
  return getCalendarEventBooking(calendarEvent).numberOfGuests;
}

export function getCalendarEventBookingGuestName(calendarEvent = {}) {
  return getCalendarEventBooking(calendarEvent).guestName;
}

export function getCalendarEventBookingStatus(calendarEvent = {}) {
  return getCalendarEventBooking(calendarEvent).statusCode;
}

export function getCalendarEventBookingDuration(calendarEvent = {}) {
  return getCalendarEventBooking(calendarEvent).bookingDurationDays;
}

export function getCalendarEventBookingTitle(calendarEvent = {}) {
  const fallback = [getCalendarEventBookingPartner(calendarEvent), getCalendarEventBookingGuestName(calendarEvent)]
    .filter(value => !!value)
    .join(': ');
  return getCalendarEventBooking(calendarEvent).title || fallback || 'Booking';
}

export function isCalendarEventBookingCanceled(calendarEvent = {}) {
  const calendarEventBookingStatus = getCalendarEventBookingStatus(calendarEvent);
  return calendarEventBookingStatus === bookingStatus.Canceled;
}

export function isCalendarEventBookingChanged(calendarEvent = {}) {
  const calendarEventBookingStatus = getCalendarEventBookingStatus(calendarEvent);
  return calendarEventBookingStatus === bookingStatus.Changed;
}

export function getCalendarEventStartMoment(calendarEvent = {}) {
  return moment.tz(calendarEvent.startTime, calendarEvent.timeZone);
}

export function getCalendarEventEndMoment(calendarEvent = {}) {
  return calendarEvent.endTime && moment.tz(calendarEvent.endTime, calendarEvent.timeZone);
}

export function getCalendarEventStartDate(calendarEvent = {}) {
  return getCalendarEventStartMoment(calendarEvent).toDate();
}

export function getCalendarEventStartLocalTime(calendarEvent = {}) {
  return getCalendarEventStartMoment(calendarEvent).format('LT');
}

export function getCalendarEventEndLocalTime(calendarEvent = {}) {
  const endMoment = getCalendarEventEndMoment(calendarEvent);
  return endMoment && endMoment.format('LT');
}

export function getCalendarEventBookingArrivalTime(calendarEvent = {}) {
  return getCalendarEventBooking(calendarEvent).arrivalDate;
}

export function getCalendarEventBookingArrivalLocalDay(calendarEvent = {}) {
  const { arrivalLocalDay } = getCalendarEventBooking(calendarEvent);
  if (!arrivalLocalDay) {
    const arrivalTimeDayMoment = getCalendarEventArrivalMoment(calendarEvent);
    return arrivalTimeDayMoment && arrivalTimeDayMoment.format(calendarEventDateFormat);
  }
  return arrivalLocalDay && `20${arrivalLocalDay}`;
}

export function isCalendarEventBookingArrivalLocalDay(calendarEvent = {}, day) {
  const arrivalLocalDay = getCalendarEventBookingArrivalLocalDay(calendarEvent);
  return day === arrivalLocalDay;
}

export function getCalendarEventArrivalMoment(calendarEvent = {}) {
  const arrivalTime = getCalendarEventBookingArrivalTime(calendarEvent);
  return arrivalTime && moment.tz(arrivalTime, calendarEvent.timeZone);
}

export function getCalendarEventArrivalUnix(calendarEvent = {}) {
  return memoizeDateUnix(getCalendarEventBookingArrivalTime(calendarEvent));
}

export function getCalendarEventBookingDepartureTime(calendarEvent = {}) {
  return getCalendarEventBooking(calendarEvent).departureDate;
}

export function getCalendarEventBookingDepartureLocalDay(calendarEvent = {}) {
  const { departureLocalDay } = getCalendarEventBooking(calendarEvent);
  if (!departureLocalDay) {
    const departureTimeDayMoment = getCalendarEventDepartureMoment(calendarEvent);
    return departureTimeDayMoment && departureTimeDayMoment.format(calendarEventDateFormat);
  }
  return departureLocalDay && `20${departureLocalDay}`;
}

export function isCalendarEventBookingDepartureLocalDay(calendarEvent = {}, day) {
  const departureLocalDay = getCalendarEventBookingDepartureLocalDay(calendarEvent);
  return day === departureLocalDay;
}

export function getCalendarEventDepartureMoment(calendarEvent = {}) {
  const departureTime = getCalendarEventBookingDepartureTime(calendarEvent);
  return departureTime && moment.tz(departureTime, calendarEvent.timeZone);
}

export function getCalendarEventDepartureUnix(calendarEvent = {}) {
  return memoizeDateUnix(getCalendarEventBookingDepartureTime(calendarEvent));
}

export function isCalendarEventBookingPartnerICal(calendarEvent = {}) {
  return getCalendarEventBookingPartner(calendarEvent) === 'iCal';
}

export function getCalendarEventICalBookingUUID(calendarEvent = {}) {
  const ids = [
    getCalendarEventPropertyId(calendarEvent),
    getCalendarEventBookingPartner(calendarEvent),
    getCalendarEventBookingDatesFormatted(calendarEvent),
    getCalendarEventBookingStatus(calendarEvent),
    getCalendarEventBookingGuestCount(calendarEvent),
    getCalendarEventBookingGuestName(calendarEvent),
    getCalendarEventBookingTitle(calendarEvent),
  ];
  return ids.join('--');
}

/**
 * Property
 */

export function getCalendarEventProperty(calendarEvent = {}) {
  return calendarEvent.property || {};
}

export function getCalendarEventPropertyId(calendarEvent = {}) {
  return getCalendarEventProperty(calendarEvent).id;
}

export function getCalendarEventPropertyAddress(calendarEvent = {}) {
  return getCalendarEventProperty(calendarEvent).displayAddress;
}
