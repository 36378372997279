import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import log from 'loglevel';
import t from '@properly/localization';
import { jobRequestStatus } from '@properly/config';
import contains from 'ramda/src/contains';
import { connect } from 'react-redux';
import {
  handleClickSaga,
  selectChecklist,
  selectSkill,
  selectChecklistViewType,
  changeJobTitle,
  changeJobType,
  changeTaskTitle,
  changeTaskDescription,
  unsetJobRequestCleaners,
} from '../state/JobRequestActions';
import { setSearchQuery, setSliderSaga, setGlobalLoadingState } from '../../../../actions/globalActions';
import {
  JobRequestTopBar,
  JobRequestContentWrap,
  JobRequestSidebarBox,
  JobRequestSidebarBoxEmpty,
} from '../../../../components/JobRequestElements/index';

// bad import
import { createChecklistSaga, setModalObj } from '../../checklists/state/ChecklistActions';

const idsWithLargeSection = ['property', 'time'];

class JobRequestSection extends PureComponent {
  click = meta => e => {
    // e.persist();
    this.props.handleClickSaga(e, meta);
  };

  isDisabledController = () => {
    const { id, isEditMode, isProblemScheduleMode, isSectionDisabled, jobStatus } = this.props;

    // make the property field disabled if its in edit mode or problem-scheduled mode.
    const isPropertySectionDisabled = (isEditMode || isProblemScheduleMode) && id === 'property';

    // cleaners section is disabled if the job is accepted and is in edit mode
    const isCleanersSectionDisabled = isEditMode && id === 'cleaners' && jobStatus === jobRequestStatus.StateAccepted;
    return isPropertySectionDisabled || isSectionDisabled || isCleanersSectionDisabled;
  };

  render() {
    const ContentTop = this.props.contentTop;
    const ContentMain = this.props.contentMain;
    const open = this.props.open && this.props.canOpen;
    if (!ContentTop || !ContentMain) {
      log.error('JobRequestSection - no main or top', this.props.id, this.props);
      return null;
    }
    const backObj = {};
    if (!this.props.showReset) {
      backObj.canBack = this.props.prevSection && !this.props.prevSectionDisabled;
      backObj.onClickBack = this.click({ action: 'clickback', prevSection: this.props.prevSection });
      backObj.backText = t('job_request.back');
    } else {
      backObj.canBack = true;
      backObj.onClickBack = this.click({ action: 'clickreset', section: this.props.id });
      backObj.backText = t('job_request.reset');
    }
    const isLarge = contains(this.props.id, idsWithLargeSection);
    if (!this.props.canOpen) {
      return (
        <JobRequestSidebarBox
          id={this.props.id}
          isSmall={!isLarge}
          isDisabled={this.isDisabledController()}
          onClick={this.click({ action: 'click', sender: this.props.id })}
          isActive={this.props.open}
          title={this.props.sectionTitle}
          isTitleRequired={this.props.isTitleRequired}
        >
          {!this.props.isEmpty && <ContentTop {...this.props} />}
          {this.props.isEmpty && <JobRequestSidebarBoxEmpty />}
        </JobRequestSidebarBox>
      );
    }

    return (
      <JobRequestContentWrap>
        {open && (
          <JobRequestTopBar
            {...backObj}
            canNext={this.props.nextSection && !this.props.nextSectionDisabled}
            title={this.props.sectionTitle}
            nextText={this.props.nextText}
            disabledNext={!this.props.isValid}
            onClickNext={this.click({ action: 'next', nextSection: this.props.nextSection })}
          />
        )}
        {open && <ContentMain {...this.props} />}
      </JobRequestContentWrap>
    );
  }
}

function mapStateToProps(state, props) {
  return props.mapStateToProps ? props.mapStateToProps(state, props) : {};
}

JobRequestSection.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  hideTopWhenOpen: PropTypes.bool, // eslint-disable-line
  nameWidthAuto: PropTypes.bool, // eslint-disable-line
  canNext: PropTypes.bool, // eslint-disable-line
  canOpen: PropTypes.bool,
  showReset: PropTypes.bool,
  nextText: PropTypes.string,
  infoTextMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.func]), // eslint-disable-line
  active: PropTypes.bool.isRequired, // eslint-disable-line
  isValid: PropTypes.bool,
};

JobRequestSection.defaultProps = {
  isValid: false,
};

export default connect(mapStateToProps, {
  handleClickSaga,
  selectChecklist,
  selectSkill,
  changeJobTitle,
  changeJobType,
  selectChecklistViewType,
  setSearchQuery,
  createChecklistSaga,
  setModalObj,
  setSliderSaga,
  setGlobalLoadingState,
  changeTaskTitle,
  changeTaskDescription,
  unsetJobRequestCleaners,
})(JobRequestSection);
