"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setLogging = setLogging;
exports.getLogging = getLogging;

var _store = _interopRequireDefault(require("store"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function setLogging(val) {
  _store.default.set('properlylogging', val);
}

function getLogging() {
  return _store.default.get('properlylogging') || 0;
}