import React, { Component } from 'react';
import PropTypes from 'prop-types';
import t from '@properly/localization';
import log from 'loglevel';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import groupBy from 'lodash/groupBy';
import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import Grid from '@material-ui/core/Grid';
import {
  Regular,
  Large,
  SearchField,
  SpaceXLarge,
  LoadingSplash,
  ChecklistItem,
  LibraryHeaders,
  BrandingAds,
} from '@properly/components';
import MainWrapper from '../../../../containers/MainWrapper/index';
import { PageFlexOuter, PageTopBar, PagePadding, PageFlexInner } from '../../../../components/PageElements';
import {
  loadIndependentChecklistsRequest,
  loadPublishedChecklistsRequest,
  removeChecklistFromLibraryRequest,
  updateLibrarySearchQuery,
  createNewChecklistInLibraryRequest,
  updateLibraryTabsValue,
} from '../state/library.actions';
import {
  selectUserChecklistsError,
  selectPublishedChecklistsError,
  selectChecklistsLoading,
  selectChecklistSearchQuery,
  selectFilteredUserChecklistsArray,
  selectFilteredPublishedChecklistsArray,
  selectLibraryTabsValue,
} from '../state/library.selectors';
import { ROUTES } from '../../../../paths';
import { setModal } from '../../../../actions/globalActions';
import { selectChecklistViewType } from '../../jobRequest/state/JobRequestActions';
import { prefilledDurationNewJobRequest } from '../../../../config';
import {
  selectPartnerWebsiteUrl,
  selectIsPartnerDomain,
  selectAdStyles,
  selectChecklistLibraryAdFromAds,
} from '../../../branding/BrandingSelector';
import LibraryChecklistsToggle from './LibraryChecklistsToggle';
import { LibraryTabs, TABS } from '../components/LibraryTabs';
import { LibraryTabPanel } from '../components/LibraryTabPanel';
import LibrarySkillsContainer from './LibrarySkillsContainer';
import { LibrarySearch } from '../components/LibrarySearch';

const getJobInstructionsIdFromChecklist = checklist =>
  checklist && checklist.jobInstructions && checklist.jobInstructions.objectId;

class LibraryContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    checklistLibraryAd: PropTypes.shape({}),
    adStyles: PropTypes.shape({}),
    isPartnerDomain: PropTypes.bool,
    partnerWebsiteUrl: PropTypes.string,
    tabsValue: PropTypes.number.isRequired,
  };

  static defaultProps = {
    checklistLibraryAd: {},
    adStyles: {},
    isPartnerDomain: false,
    partnerWebsiteUrl: '',
  };

  componentDidMount() {
    const { loadChecklists, tabsValue } = this.props;
    if (tabsValue === TABS.CHECKLIST_LIBRARY) {
      loadChecklists();
    }
  }

  handleSendJobRequest = checklistId => {
    const { sendChecklistAsJobRequest } = this.props;
    return sendChecklistAsJobRequest(checklistId);
  };

  handleDuplicateIntoProperties = (checklistId, checklist) => {
    const { cloneChecklistIntoProperty } = this.props;
    cloneChecklistIntoProperty(checklistId, getJobInstructionsIdFromChecklist(checklist), !!checklist.published);
  };

  handleDuplicate = () => {
    log.warn('There is no cloud function to support this operation');
  };

  handleRemoveChecklist = checklistId => {
    const { removeChecklistFromLibrary } = this.props;
    removeChecklistFromLibrary(checklistId);
  };

  handleNewChecklist = () => {
    const { createNewChecklistInLibrary } = this.props;
    createNewChecklistInLibrary();
  };

  handleOnChangeTab = (_, newValue) => {
    const { updateTabsValue } = this.props;
    updateTabsValue(newValue);
  };

  renderChecklists(letter, checklists) {
    const { openChecklist } = this.props;
    const checklistHasTasks = checklist => !!checklist?.totalTaskCount;
    return (checklists || []).map((checklist, index) => (
      <Grid container key={checklist.objectId} wrap="nowrap" alignItems="center">
        <Grid item style={{ width: '24px' }}>
          <Regular type="grey">{index === 0 ? letter : ''}</Regular>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <ChecklistItem
            canSend={checklistHasTasks(checklist)}
            canRemove={!checklist.published}
            onSend={() => this.handleSendJobRequest(checklist.objectId, checklist)}
            onDuplicate={() => this.handleDuplicate(checklist.objectId, checklist)}
            onDuplicateIntoProperties={() => this.handleDuplicateIntoProperties(checklist.objectId, checklist)}
            onRemove={() => this.handleRemoveChecklist(checklist.objectId)}
            onClick={() =>
              openChecklist(checklist.objectId, getJobInstructionsIdFromChecklist(checklist), checklist.published)
            }
            slidesCount={checklist.totalStepCount}
            tasksCount={checklist.totalTaskCount}
            {...checklist}
          />
        </Grid>
      </Grid>
    ));
  }

  renderLibrary() {
    const { orderedLetters, checklistsGroupedByLetter } = this.props;

    return (
      <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
        <div style={{ marginLeft: '24px' }}>
          <LibraryHeaders type="checklist" />
        </div>
        {(orderedLetters || []).map(letter => (
          <div key={letter}>{this.renderChecklists(letter, checklistsGroupedByLetter[letter])}</div>
        ))}
      </div>
    );
  }

  renderChecklistsSearch() {
    const { checklistSearchQuery, updateSearchQuery } = this.props;
    const handleOnUpdateSearchQuery = searchValue => updateSearchQuery(searchValue);

    return (
      <LibrarySearch>
        <SearchField
          fitInBox
          onGrey
          data-key="library_search_checklist"
          placeholder={t('job_request.search_checklist')}
          value={checklistSearchQuery}
          iconLocation="left"
          onChange={handleOnUpdateSearchQuery}
        />
        <LibraryChecklistsToggle />
      </LibrarySearch>
    );
  }

  renderNoChecklists() {
    const { checklistSearchQuery } = this.props;

    return (
      <Grid container alignItems="center" justify="center">
        <Grid item>
          {!checklistSearchQuery && <Large type="bold">{t('checklist.nothing_here')}</Large>}
          {!!checklistSearchQuery && (
            <Large type="bold">
              {t('checklist.no_results')} {checklistSearchQuery}
            </Large>
          )}
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      loading,
      hasChecklists,
      publishedChecklistsError,
      userChecklistsError,
      checklistLibraryAd,
      adStyles,
      isPartnerDomain,
      partnerWebsiteUrl,
      tabsValue,
    } = this.props;

    const error = !!userChecklistsError && !!publishedChecklistsError;
    const showLoader = loading && !hasChecklists;
    const showChecklists = !showLoader && !error;
    const showNoChecklists = !loading && !error && !hasChecklists;

    return (
      <MainWrapper currentPage="library">
        <PageFlexOuter grey>
          <PageTopBar>
            <LibraryTabs
              handleNewChecklist={this.handleNewChecklist}
              handleOnChangeTab={this.handleOnChangeTab}
              value={tabsValue}
            />
          </PageTopBar>

          <PageFlexInner>
            <PagePadding variation="property" type="absolute-scroll">
              <LibraryTabPanel value={tabsValue} index={0}>
                {showLoader && <LoadingSplash />}
                {showChecklists && this.renderChecklistsSearch()}
                {showChecklists && this.renderLibrary()}
                {showNoChecklists && this.renderNoChecklists()}
              </LibraryTabPanel>
              <LibraryTabPanel value={tabsValue} index={1}>
                <LibrarySkillsContainer />
              </LibraryTabPanel>

              <SpaceXLarge />
              {isPartnerDomain && (
                <BrandingAds
                  brandingAd={checklistLibraryAd}
                  partnerWebsiteUrl={partnerWebsiteUrl}
                  adStyles={adStyles}
                  typeTwoAd
                />
              )}
            </PagePadding>
          </PageFlexInner>
        </PageFlexOuter>
      </MainWrapper>
    );
  }
}

const selectAllChecklists = createSelector(
  selectFilteredUserChecklistsArray,
  selectFilteredPublishedChecklistsArray,
  (userChecklists, publishedChecklists) => [...userChecklists, ...publishedChecklists],
);
const selectHasChecklists = createSelector(selectAllChecklists, checklists => !!checklists && !!checklists.length);
const selectGroupedChecklists = createSelector(selectAllChecklists, checklists => {
  const checklistsGroupedByLetter = groupBy(checklists, checklist =>
    String(checklist.title || '?')
      .substr(0, 1)
      .toUpperCase(),
  );
  const orderedLetters = Object.keys(checklistsGroupedByLetter).sort();

  const getTitle = checklist => checklist && checklist.title && checklist.title.toUpperCase();
  const sortChecklists = (a, b) => (getTitle(a) < getTitle(b) ? -1 : 1);
  return {
    orderedLetters,
    checklistsGroupedByLetter: orderedLetters.reduce(
      (acc, currentLetter) => ({
        ...acc,
        [currentLetter]: checklistsGroupedByLetter[currentLetter].sort(sortChecklists),
      }),
      {},
    ),
  };
});

function mapStateToProps(state) {
  const loading = selectChecklistsLoading(state);

  // Group checklists by the first letter in the title
  const { orderedLetters, checklistsGroupedByLetter } = selectGroupedChecklists(state);

  return {
    hasChecklists: selectHasChecklists(state),
    orderedLetters,
    checklistsGroupedByLetter,
    loading,
    userChecklistsError: selectUserChecklistsError(state),
    publishedChecklistsError: selectPublishedChecklistsError(state),
    checklistSearchQuery: selectChecklistSearchQuery(state),
    checklistLibraryAd: selectChecklistLibraryAdFromAds()(state) && selectChecklistLibraryAdFromAds()(state).toJS(),
    adStyles: selectAdStyles()(state)?.toJS(),
    isPartnerDomain: selectIsPartnerDomain()(state),
    partnerWebsiteUrl: selectPartnerWebsiteUrl()(state),
    tabsValue: selectLibraryTabsValue(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadChecklists: () => {
      dispatch(loadIndependentChecklistsRequest());
      dispatch(loadPublishedChecklistsRequest());
    },
    cloneChecklistIntoProperty: (checklistId, jobInstructionsId, published) =>
      dispatch(push(ROUTES.libraryChecklistClone(checklistId, jobInstructionsId, published))),
    openChecklist: (checklistId, jobInstructionsId, published) =>
      dispatch(push(ROUTES.libraryChecklist(checklistId, jobInstructionsId, published))),
    sendChecklistAsJobRequest: checklistId => {
      dispatch(openJobRequestModalAction(checklistId));
      dispatch(selectChecklistViewType('library'));
    },
    removeChecklistFromLibrary: checklistId => dispatch(removeChecklistFromLibraryRequest(checklistId)),
    updateSearchQuery: query => dispatch(updateLibrarySearchQuery(query)),

    createNewChecklistInLibrary: () => dispatch(createNewChecklistInLibraryRequest()),
    updateTabsValue: value => dispatch(updateLibraryTabsValue(value)),
  };
}

function openJobRequestModalAction(checklistId) {
  return setModal(true, 'jobrequest', {
    mode: 'normal',
    source: 'new',
    data: {
      checklistId: [checklistId],
      startTime: moment()
        .add(2, 'day')
        .hours(10)
        .minutes(0)
        .seconds(0), // eslint-disable-line
      endTime: moment()
        .add(2, 'day')
        .hours(11)
        .minutes(0)
        .seconds(0), // eslint-disable-line
      duration: prefilledDurationNewJobRequest,
      jobStartTimeType: 'fixed',
    },
  });
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(LibraryContainer);
