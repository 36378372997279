import reduce from 'lodash/reduce';
import concat from 'lodash/concat';

const setAllSee = (map, selection, val) => reduce(selection, (acc, name) => acc.setIn([name], val), map);

const allPermissions = [
  'seeCleanerNames',
  'seePrice',
  'seeProblems',
  'seeVerificationPhotos',
  'createChecklists',
  'sendJobs',
  'doJob',
];

function guardPermissions(prev, next, forceFalse) {
  function run() {
    const seePer = ['seeCleanerNames', 'seePrice', 'seeProblems', 'seeVerificationPhotos'];
    const editPath = ['createChecklists'];
    const sendPath = ['sendJobs'];
    const doJobPath = ['doJob'];
    const allSeeSelected = map => {
      const counted = reduce(
        seePer,
        (acc, name) => {
          if (map.getIn([name])) return acc + 1;
          return acc;
        },
        0,
      );
      return counted === seePer.length;
    };

    // unsetter
    if (allSeeSelected(prev) && !allSeeSelected(next)) {
      return next
        .setIn(editPath, false)
        .setIn(sendPath, false)
        .setIn(doJobPath, false);
    }

    if (prev.getIn(sendPath) && !next.getIn(sendPath)) {
      return setAllSee(next, concat(seePer, ['createChecklists']), false);
    }

    // setter
    if (next.getIn(editPath)) {
      return setAllSee(next, seePer, true);
    }
    if (next.getIn(sendPath)) {
      return setAllSee(next, concat(seePer, ['createChecklists']), true);
    }
    if (next.getIn(doJobPath)) {
      return setAllSee(next, concat(seePer), true);
    }
    return next;
  }
  let newNext = run();
  if (forceFalse) {
    newNext = setAllSee(newNext, forceFalse, false);
  }
  return newNext;
}

export { guardPermissions, setAllSee, allPermissions };
