import React, { Component } from 'react';
import LoginWrapContainer from '../../modules/loginSignUp/LoginWrapContainer';

function lazyLoadComponent(bundleFunc, type) {
  class LazyWrapper extends Component {
    state = {
      component: null,
    };

    componentWillMount() {
      this.setState({ component: bundleFunc });
    }

    renderLoading() {
      if (type === 'login') {
        return <LoginWrapContainer loading />;
      }
      return null;
    }

    render() {
      const { component: LazyComponent } = this.state;
      if (!LazyComponent) {
        return this.renderLoading();
      }
      return <LazyComponent {...this.props} />;
    }
  }

  return LazyWrapper;
}

export default lazyLoadComponent;
