import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { createSelector } from 'reselect';
import lodashResult from 'lodash/result';
import { connect } from 'react-redux';
import hasAccess from './hasAccess';
import { selectCurrentUser, selectProperty, selectJobRequest } from '../../selectors/globalSelector';

const getMeta = () => (state, props) => lodashResult(props, ['meta']) || {};

function mapStateToProps() {
  const selector = createSelector(
    selectCurrentUser(),
    getMeta(),
    (state, props) => {
      const meta = getMeta()(state, props);
      return selectProperty(meta.propertyId)(state);
    },
    (state, props) => {
      const meta = getMeta()(state, props);
      return selectJobRequest(meta.jobRequestId)(state);
    },
    (user, meta, property, jobrequest) => ({
      ...meta,
      user,
      property,
      jobrequest,
    }),
  );
  return (state, props) => ({
    meta: selector(state, props),
  });
}

class HasPermission extends React.Component {
  static propTypes = {
    render: PropTypes.func,
    renderWithPermission: PropTypes.func,
    hasAccessFormatter: PropTypes.func,
    renderCallee: PropTypes.func,
    // access: PropTypes.arrayOf(PropTypes.string).isRequired,
    meta: PropTypes.shape({}),
  };

  static defaultProps = {
    meta: {},
    hasAccessFormatter: () => false,
  };

  hasAccess(meta) {
    return hasAccess(meta);
  }

  compute(meta) {
    const hasAccessRaw = this.hasAccess(meta);
    const hasAccessRes = this.props.hasAccessFormatter(hasAccessRaw);
    return { hasAccessRaw, hasAccessRes };
  }

  calleeFunc = meta => {
    const { hasAccessRes } = this.compute({ ...this.props.meta, ...meta });
    return hasAccessRes;
  };

  render() {
    if (this.props.renderWithPermission) {
      const { hasAccessRes, hasAccessRaw } = this.compute(this.props.meta);
      if (hasAccessRes) {
        return this.props.renderWithPermission(hasAccessRaw);
      }
    }
    if (this.props.render) {
      const { hasAccessRes } = this.compute(this.props.meta);
      return this.props.render(hasAccessRes);
    }
    if (this.props.renderCallee) {
      return this.props.renderCallee(this.calleeFunc);
    }
    return null;
  }
}

export default compose(
  connect(
    mapStateToProps,
    null,
  ),
)(HasPermission);
