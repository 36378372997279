import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import log from 'loglevel';
import { shutdownIntercom } from '@properly/common';
import { redirectToProviderSaga } from '../state/StripeSPActions';
import { stripeOAuthLinkGen } from '../../../../config/apiLinkGen';
import StripeSPLanding from '../components/StripeSPLanding';
import StripeSPConnectSuccess from '../components/StripeSPConnectSuccess';
import StripeSPConnectFailure from '../components/StripeSPConnectFailure';

class StripeSPContainer extends Component {
  redirectToProvider = () => {
    const stripeOAuthLink = stripeOAuthLinkGen();
    if (!stripeOAuthLink) {
      log.error('could not get oauth link');
      return;
    }
    this.props.redirectToProviderSaga({
      stripeOAuthLink,
    });
  };

  componentWillMount = () => {
    const { mobile } = this.props;

    if (mobile) {
      shutdownIntercom(true);
    }
  };

  render() {
    const { location } = this.props;
    const { query } = location;

    switch (
      query.error // rendering components based on query parameters of url
    ) {
      case 'true':
        return <StripeSPConnectFailure errorMsg={query.message} redirectToProvider={this.redirectToProvider} />;

      case 'false':
        return <StripeSPConnectSuccess />;

      default:
        return <StripeSPLanding redirectToProvider={this.redirectToProvider} />;
    }
  }
}
StripeSPContainer.propTypes = {
  location: PropTypes.shape({}).isRequired,
  redirectToProviderSaga: PropTypes.func.isRequired,
  mobile: PropTypes.bool,
};

StripeSPContainer.defaultProps = {
  mobile: false,
};

export default connect(null, {
  redirectToProviderSaga,
})(StripeSPContainer);
