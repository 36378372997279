import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import map from 'lodash/map';
import { withStyles } from '@material-ui/core/styles';
import {
  DividerLine,
  Icon,
  LoadingSpinner,
  Modal,
  Title3,
  Regular,
  Small,
  SpaceBase,
  SpaceXLarge,
} from '@properly/components';
import { shutdownIntercom } from '@properly/common';
import t from '@properly/localization';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import { PagePadding } from '../../../../components/PageElements';
import { AccountHeadSection } from '../../../../components/AccountElements';
import SettingsPaymentMethod from '../components/SettingsPaymentMethod';
import PaymentInterceptWizard from '../../../../containers/PaymentInterceptWizard';
import {
  getStripeCustomer,
  removeStripeCustomerSource,
  removeStripeCustomerPaymentMethod,
} from '../../../../containers/PaymentInterceptWizard/paymentInterceptActions';
import { getUserFeaturesRequest, getUserFeatureFlagsRequest } from '../state/SettingsActions';
import { selectUserFeaturesLoading, selectUserFeaturesError } from '../state/SettingsSelectors';

import * as selectors from '../../../../containers/PaymentInterceptWizard/paymentInterceptSelectors';

const PAYMENT_METHODS_TYPE_CARDS = 0;
const PAYMENT_METHODS_TYPE_BANK_ACCOUNTS = 1;

const styles = () => ({
  addPaymentButton: {
    display: 'inline-block',
    cursor: 'pointer',
  },
  h4: {
    marginBottom: '1rem',
  },
});

class SettingsPaymentMethodsContainer extends Component {
  state = {
    showCardModal: false,
    showBankAccountModal: false,
  };

  componentWillMount = () => {
    const { mobile, dispatch } = this.props;

    if (mobile) {
      shutdownIntercom(true);
    }

    dispatch(getStripeCustomer());
    dispatch(getUserFeaturesRequest());
    dispatch(getUserFeatureFlagsRequest());
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { showCardModal, showBankAccountModal } = this.state;

    if (!(showCardModal || showBankAccountModal)) {
      // modal is not open
      return true;
    }

    if (showCardModal && !nextState.showCardModal) {
      // modal is open, but we're closing it
      return true;
    }

    if (showBankAccountModal && !nextState.showBankAccountModal) {
      // modal is open, but we're closing it
      return true;
    }

    // Don't let this component re-render as the modal is staying open
    return false;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps = nextProps => {
    const { stripeCustomerLoading } = nextProps;
    const { showCardModal, showBankAccountModal } = this.state;

    // Close the open modal if we are loading
    if ((showCardModal || showBankAccountModal) && stripeCustomerLoading) {
      this.setState({
        showCardModal: false,
        showBankAccountModal: false,
      });
    }
  };

  get stripeCustomer() {
    const { stripeCustomer } = this.props;
    return stripeCustomer?.toJS();
  }

  onClose = lastStep => {
    let response = true;
    if (lastStep) {
      response = window.confirm(t('settings.confirm_leave')); // eslint-disable-line no-alert
    }

    if (response === true) {
      this.setState({
        showCardModal: false,
        showBankAccountModal: false,
      });
    }

    return response;
  };

  onFinish = () => {
    this.setState({
      showCardModal: false,
      showBankAccountModal: false,
    });
  };

  handleAddPaymentMethod = paymentMethodsType => () =>
    this.setState({
      showCardModal: paymentMethodsType === PAYMENT_METHODS_TYPE_CARDS,
      showBankAccountModal: paymentMethodsType === PAYMENT_METHODS_TYPE_BANK_ACCOUNTS,
    });

  handleRemovePaymentMethod = (paymentMethodsType, stripePaymentMethodId) => () => {
    const { dispatch } = this.props;
    const r = window.confirm(t('settings.really_delete_payment_method')); // eslint-disable-line no-alert

    if (r === true) {
      if (paymentMethodsType === PAYMENT_METHODS_TYPE_CARDS) {
        dispatch(removeStripeCustomerPaymentMethod(stripePaymentMethodId));
      } else {
        dispatch(removeStripeCustomerSource(stripePaymentMethodId));
      }
    }
  };

  renderPaymentMethods = (paymentMethods = [], paymentMethodsType) =>
    paymentMethods.length
      ? this.renderPaymentMethodsActive(paymentMethods, paymentMethodsType)
      : this.renderPaymentMethodsInactive(paymentMethodsType);

  renderH4 = label => {
    const H4 = withStyles(styles)(({ classes }) => <div className={`${classes.h4} text-l4m`}>{label}</div>);

    return <H4 />;
  };

  renderAddButton = paymentMethodsType => {
    const label =
      paymentMethodsType === PAYMENT_METHODS_TYPE_CARDS
        ? t('settings.add_credit_card_payment_method')
        : t('settings.add_bank_account_payment_method');
    const AddButton = withStyles(styles)(({ classes }) => (
      <div onClick={this.handleAddPaymentMethod(paymentMethodsType)} className={classes.addPaymentButton}>
        <Small type="orange">{label}</Small>
      </div>
    ));

    return <AddButton />;
  };

  renderPaymentMethodsActive = (paymentMethods, paymentMethodsType) => {
    const { defaultPaymentMethod, defaultSource } = this.stripeCustomer;
    return (
      <>
        {map(paymentMethods, (paymentMethod, index) => {
          const buttonText =
            paymentMethodsType === PAYMENT_METHODS_TYPE_CARDS
              ? t('settings.remove_credit_card_payment_method')
              : t('settings.remove_bank_account_payment_method');

          return (
            <Fragment key={paymentMethod.id}>
              <SettingsPaymentMethod
                isLast={index === paymentMethods.length - 1}
                isFirst={index === 0}
                onButtonClick={this.handleRemovePaymentMethod(paymentMethodsType, paymentMethod.id)}
                icon={Icon.IcActive}
                text={t('settings.active')}
                buttonText={buttonText}
                defaultPaymentMethod={defaultPaymentMethod}
                defaultSource={defaultSource}
                paymentMethod={paymentMethod}
                isCard={paymentMethodsType === PAYMENT_METHODS_TYPE_CARDS}
                isBankAccount={paymentMethodsType === PAYMENT_METHODS_TYPE_BANK_ACCOUNTS}
              />
              <SpaceBase />
            </Fragment>
          );
        })}
        <SpaceBase />
      </>
    );
  };

  renderPaymentMethodsInactive = paymentMethodsType => (
    <>
      <SettingsPaymentMethod isLast isFirst icon={Icon.AlertRed} text={t('settings.inactive')} />
      <SpaceBase />
      {this.renderAddButton(paymentMethodsType)}
    </>
  );

  render() {
    const { mobile, stripeCustomerLoading, useMarketplace, useAch, useBankAccounts, useCards } = this.props;
    const { showCardModal, showBankAccountModal } = this.state;
    const height = useBankAccounts ? 450 : 350;
    const Wrapper = ({ children }) =>
      mobile ? <>{children}</> : <PagePadding type="absolute-scroll">{children}</PagePadding>;

    return (
      <Wrapper>
        {!mobile && (
          <>
            <AccountHeadSection>
              <Title3 type="nomargin">{t('settings.payment_methods')}</Title3>
              <DividerLine type={['bottom']} />
            </AccountHeadSection>
            <SpaceBase />
          </>
        )}

        {useMarketplace && (
          <>
            <div style={{ maxWidth: 900 }}>
              <Regular type="greyloading">{t('settings.payment_methods_txt')}</Regular>
            </div>
            <SpaceXLarge />
          </>
        )}

        {stripeCustomerLoading && (
          <div style={{ display: 'flex', width: '100%', height: 300, alignItems: 'center', justifyContent: 'center' }}>
            <LoadingSpinner />
          </div>
        )}

        {!stripeCustomerLoading && (
          <>
            {useCards || this?.stripeCustomer?.cards?.length ? (
              <>
                {this.renderH4(t('settings.credit_cards'))}
                <div style={{ marginBottom: 60 }}>
                  {this.renderPaymentMethods(this?.stripeCustomer?.cards, PAYMENT_METHODS_TYPE_CARDS)}
                </div>
                <Modal
                  id="payment-intercept-wizard-modal"
                  height={height}
                  show={showCardModal}
                  onClose={this.onClose}
                  block
                >
                  <PaymentInterceptWizard
                    useMarketplace={useMarketplace}
                    useCards={useCards}
                    onClose={this.onClose}
                    onFinish={this.onFinish}
                    textAlign="center"
                  />
                </Modal>
              </>
            ) : null}

            {useAch || useBankAccounts || this?.stripeCustomer?.bankAccounts?.length ? (
              <>
                {this.renderH4(t('settings.bank_accounts'))}
                <div style={{ marginBottom: 60 }}>
                  {this.renderPaymentMethods(this?.stripeCustomer?.bankAccounts, PAYMENT_METHODS_TYPE_BANK_ACCOUNTS)}
                </div>
                <Modal
                  id="payment-intercept-wizard-modal"
                  height={height}
                  show={showBankAccountModal}
                  onClose={this.onClose}
                  block
                >
                  <PaymentInterceptWizard
                    useMarketplace={useMarketplace}
                    useAch={useAch}
                    useBankAccounts={useBankAccounts}
                    onClose={this.onClose}
                    onFinish={this.onFinish}
                    textAlign="center"
                  />
                </Modal>
              </>
            ) : null}
          </>
        )}
      </Wrapper>
    );
  }
}

SettingsPaymentMethodsContainer.propTypes = {
  mobile: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  stripeCustomer: PropTypes.shape({}).isRequired,
  stripeCustomerLoading: PropTypes.bool.isRequired,
  useMarketplace: PropTypes.bool,
  useBankAccounts: PropTypes.bool.isRequired,
};

SettingsPaymentMethodsContainer.defaultProps = {
  mobile: false,
  useMarketplace: true,
};

function mapStateToProps(state, props) {
  // marketplace

  // we remove this temporary flag FEATURE_MARKETPLACE_USER_HOST , which was added to distinguish between performance pay and marketplace
  // in future if we want to enable such distinction we can use this flag
  // const useMarketplace = selectIsFeatureEnabledForUser(FEATURE_MARKETPLACE_USER_HOST)(state, props);

  return {
    config: selectorsGlobal.selectConfig(state, props),
    stripeCustomer: selectors.selectStripeCustomer()(state, props),
    stripeCustomerLoading: selectors.selectStripeCustomerLoading()(state, props),
    loading: selectUserFeaturesLoading(state, props),
    error: selectUserFeaturesError(state, props),
    useBankAccounts: false,
    useCards: true,
  };
}

export default connect(mapStateToProps, dispatch => ({
  dispatch,
}))(SettingsPaymentMethodsContainer);
