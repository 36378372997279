import { push } from 'react-router-redux';
import * as types from '../../../../types/index';

export function goToSettingDetail(route) {
  return push(route);
}

// features
export const getUserFeaturesRequest = () => ({
  type: types.SETTINGS_GET_USER_FEATURES_REQUEST,
});

export const setUserFeaturesSuccess = userFeatures => ({
  type: types.SETTINGS_SET_USER_FEATURES_SUCCESS,
  userFeatures,
});

export const setUserFeaturesFailure = error => ({
  type: types.SETTINGS_SET_USER_FEATURES_FAILURE,
  error,
});

// feature flags
export const getUserFeatureFlagsRequest = () => ({
  type: types.SETTINGS_GET_USER_FEATURE_FLAGS_REQUEST,
});

export const setUserFeatureFlagsSuccess = userFeatureFlags => ({
  type: types.SETTINGS_SET_USER_FEATURE_FLAGS_SUCCESS,
  userFeatureFlags,
});

export const setUserFeatureFlagsFailure = error => ({
  type: types.SETTINGS_SET_USER_FEATURE_FLAGS_FAILURE,
  error,
});
