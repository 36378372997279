"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCostAccordingToCostType = exports.fixDecimals = exports.getColumnsCountForImages = exports.getDistanceUnitAccordingToCountryCode = exports.convertMinutesToHoursMinutes = exports.countryCodes = void 0;

var _config = require("@properly/config");

var _localization = _interopRequireDefault(require("@properly/localization"));

var _locale = require("../locale");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const countryCodes = ['UK', 'US', 'GB', 'MM'];
exports.countryCodes = countryCodes;

const convertMinutesToHoursMinutes = minutes => minutes > 60 ? `${Math.floor(minutes / 60)} hrs ${minutes % 60} mins` : `${minutes} mins`;

exports.convertMinutesToHoursMinutes = convertMinutesToHoursMinutes;

const getDistanceUnitAccordingToCountryCode = countryCode => {
  if (countryCodes.includes(countryCode)) {
    return (0, _localization.default)('jobdetails.costs.miles');
  }

  return (0, _localization.default)('jobdetails.costs.kms');
};

exports.getDistanceUnitAccordingToCountryCode = getDistanceUnitAccordingToCountryCode;

const getColumnsCountForImages = pictureIdentifiers => {
  let columns;

  if (pictureIdentifiers) {
    if (pictureIdentifiers.length === 1) {
      columns = 1;
    } else if (pictureIdentifiers.length === 2) {
      columns = 2;
    } else {
      columns = 2.5;
    }
  }

  return columns;
};

exports.getColumnsCountForImages = getColumnsCountForImages;

const fixDecimals = value => {
  let convertedValue;

  if (value) {
    const hasDecimals = value % 1 !== 0;

    if (hasDecimals) {
      return value.toFixed(2);
    }

    convertedValue = value;
  } else {
    convertedValue = 0;
  }

  return convertedValue;
};

exports.fixDecimals = fixDecimals;

const getCostAccordingToCostType = (type, value, countryCode) => {
  switch (type) {
    case _config.costCapture.MILEAGE:
      return `${fixDecimals(value)} ${getDistanceUnitAccordingToCountryCode(countryCode)}`;

    case _config.costCapture.COST:
      return `${(0, _locale.getCurrencyFromCountryShortCode)(countryCode)} ${fixDecimals(value)}`;

    case _config.costCapture.DURATION:
      return convertMinutesToHoursMinutes(fixDecimals(value));

    default:
      return null;
  }
};

exports.getCostAccordingToCostType = getCostAccordingToCostType;