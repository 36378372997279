import log from 'loglevel';

export default {
  index: {},
  setIndex(indexName, index) {
    this.index[indexName] = index;
  },
  getIndex(indexName) {
    const res = this.index[indexName];
    if (!res) {
      log.error('index not found', indexName);
      return { search: () => [] }; // mock
    }
    return this.index[indexName];
  },
};
