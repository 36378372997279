"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapTaskIconToIconAndTitle = mapTaskIconToIconAndTitle;
exports.keepInBoundsWithPinPosition = keepInBoundsWithPinPosition;

var _localization = _interopRequireDefault(require("@properly/localization"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function mapTaskIconToIconAndTitle(taskIcon) {
  switch (taskIcon) {
    case 'lock':
    case 'lockoff':
      return {
        icon: 'IcPinLock',
        title: (0, _localization.default)('checklist.lock'),
        symbol: 'lock'
      };

    case 'unlockoff':
    case 'unlock':
      return {
        icon: 'IcPinUnlock',
        title: (0, _localization.default)('checklist.unlock'),
        symbol: 'unlock'
      };

    case 'look':
      return {
        icon: 'IcPinFind',
        title: (0, _localization.default)('checklist.find'),
        symbol: 'look'
      };

    case 'moreoff': // check again

    case 'custom':
      // check again
      return {
        icon: 'IcPinCustom',
        title: (0, _localization.default)('checklist.custom'),
        symbol: 'custom'
      };

    case 'stripandreplace':
      return {
        icon: 'IcPinStripreplace',
        title: (0, _localization.default)('checklist.strip'),
        symbol: 'stripandreplace'
      };

    case 'laundry':
    case 'launder':
      return {
        icon: 'IcPinWash',
        title: (0, _localization.default)('checklist.wash'),
        symbol: 'launder'
      };

    case 'iron':
      return {
        icon: 'IcPinIron',
        title: (0, _localization.default)('checklist.iron'),
        symbol: 'iron'
      };

    case 'vacuum':
      return {
        icon: 'IcPinVacuum',
        title: (0, _localization.default)('checklist.vacuum'),
        symbol: 'vacuum'
      };

    case 'sweep':
      return {
        icon: 'IcPinSweep',
        title: (0, _localization.default)('checklist.sweep'),
        symbol: 'sweep'
      };

    case 'wipe':
      return {
        icon: 'IcPinWipe',
        title: (0, _localization.default)('checklist.wipe'),
        symbol: 'wipe'
      };

    case 'mop':
      return {
        icon: 'IcPinMop',
        title: (0, _localization.default)('checklist.mop'),
        symbol: 'mop'
      };

    case 'dust':
      return {
        icon: 'IcPinDust',
        title: (0, _localization.default)('checklist.dust'),
        symbol: 'dust'
      };

    case 'lighton':
      return {
        icon: 'IcPinLighton',
        title: (0, _localization.default)('checklist.lighton'),
        symbol: 'lighton'
      };

    case 'water':
      return {
        icon: 'IcPinWater',
        title: (0, _localization.default)('checklist.water'),
        symbol: 'water'
      };

    case 'cleanglass':
      return {
        icon: 'IcPinCleanglass',
        title: (0, _localization.default)('checklist.cleanglass'),
        symbol: 'cleanglass'
      };

    case 'disinfect':
      return {
        icon: 'IcPinDisinfect',
        title: (0, _localization.default)('checklist.disinfect'),
        symbol: 'disinfect'
      };

    case 'scrub':
      return {
        icon: 'IcPinScrub',
        title: (0, _localization.default)('checklist.scrub'),
        symbol: 'scrub'
      };

    case 'emptytrash':
      return {
        icon: 'IcPinEmptytrash',
        title: (0, _localization.default)('checklist.emptytrash'),
        symbol: 'emptytrash'
      };

    case 'seal':
      return {
        icon: 'IcPinSeal',
        title: (0, _localization.default)('checklist.seal'),
        symbol: 'seal'
      };

    case 'refill':
      return {
        icon: 'IcPinRefill',
        title: (0, _localization.default)('checklist.refill'),
        symbol: 'refill'
      };

    case 'stage':
      return {
        icon: 'IcPinStage',
        title: (0, _localization.default)('checklist.stage'),
        symbol: 'stage'
      };

    default:
      return {
        icon: 'IcPinCustom',
        title: (0, _localization.default)('checklist.custom'),
        symbol: 'custom'
      };
  }
}

function keepInBoundsWithPinPosition(base, x, y) {
  let finalX = x;
  let finalY = y;
  let position = 'top';
  const triggerTop = base.top + 100;
  const triggerLeft = base.left + 20;
  const triggerRight = base.right - 20;

  if (x < base.left) {
    finalX = base.left;
  } else if (x > base.right) {
    finalX = base.right;
  }

  if (y < base.top) {
    finalY = base.top;
  } else if (y > base.bottom) {
    finalY = base.bottom;
  }

  if (y < triggerTop) {
    position = 'bottom';
  } else if (x < triggerLeft) {
    position = 'right';
  } else if (x > triggerRight) {
    position = 'left';
  }

  return {
    x: finalX,
    y: finalY,
    position
  };
}