import gql from 'graphql-tag';

export const QUERY_GET_PARTNER_PROPERTY_IDS = gql`
  query PartnerPropertyIds($id: String!) {
    PartnerPropertyIds(id: $id) {
      results {
        id
        partner
        partnerPropertyId
      }
    }
  }
`;
