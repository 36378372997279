"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDomainName = getDomainName;

function getDomainName(isPartnerDomain) {
  const hostName = window.location.hostname;
  return isPartnerDomain ? hostName : null;
}