import React, { Component } from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';

export default () => {
  const { USER_IS_TOUCHING } = window || {};
  const isTouchEnabled = !('ontouchstart' in document.documentElement);
  const touchBackend = USER_IS_TOUCHING && isTouchEnabled;
  const backend = touchBackend ? TouchBackend({ delayTouchStart: 200 }) : HTML5Backend;

  return DragDropContext(backend)(
    class Card extends Component {
      render() {
        const { children } = this.props;
        return React.Children.only(children);
      }
    },
  );
};
