"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "App", {
  enumerable: true,
  get: function get() {
    return _App["default"];
  }
});
Object.defineProperty(exports, "LoadGoogle", {
  enumerable: true,
  get: function get() {
    return _LoadGoogle["default"];
  }
});
Object.defineProperty(exports, "WithHover", {
  enumerable: true,
  get: function get() {
    return _WithHover["default"];
  }
});
Object.defineProperty(exports, "WithReCaptcha", {
  enumerable: true,
  get: function get() {
    return _WithReCaptcha["default"];
  }
});
Object.defineProperty(exports, "LoginForm", {
  enumerable: true,
  get: function get() {
    return _LoginForm["default"];
  }
});
Object.defineProperty(exports, "JobTimeForm", {
  enumerable: true,
  get: function get() {
    return _JobTimeForm["default"];
  }
});
Object.defineProperty(exports, "JobTimeFormSimple", {
  enumerable: true,
  get: function get() {
    return _JobTimeFormSimple["default"];
  }
});
Object.defineProperty(exports, "PropertyForm", {
  enumerable: true,
  get: function get() {
    return _PropertyForm["default"];
  }
});
Object.defineProperty(exports, "PasswordResetForm", {
  enumerable: true,
  get: function get() {
    return _PasswordResetForm["default"];
  }
});
Object.defineProperty(exports, "SignupForm", {
  enumerable: true,
  get: function get() {
    return _SignupForm["default"];
  }
});
Object.defineProperty(exports, "TaskForm", {
  enumerable: true,
  get: function get() {
    return _TaskForm["default"];
  }
});
Object.defineProperty(exports, "CostForm", {
  enumerable: true,
  get: function get() {
    return _CostForm["default"];
  }
});
Object.defineProperty(exports, "CalendarEventFilterForm", {
  enumerable: true,
  get: function get() {
    return _CalendarEventFilterForm["default"];
  }
});
Object.defineProperty(exports, "BookingDetails", {
  enumerable: true,
  get: function get() {
    return _BookingDetails["default"];
  }
});
Object.defineProperty(exports, "BookingDetailsModal", {
  enumerable: true,
  get: function get() {
    return _BookingDetailsModal["default"];
  }
});
Object.defineProperty(exports, "TasklistItem", {
  enumerable: true,
  get: function get() {
    return _TasklistItem["default"];
  }
});
Object.defineProperty(exports, "ChecklistItem", {
  enumerable: true,
  get: function get() {
    return _ChecklistItem["default"];
  }
});
Object.defineProperty(exports, "LibraryHeaders", {
  enumerable: true,
  get: function get() {
    return _LibraryHeaders.LibraryHeaders;
  }
});
Object.defineProperty(exports, "SkillItem", {
  enumerable: true,
  get: function get() {
    return _SkillItem.SkillItem;
  }
});
Object.defineProperty(exports, "CalendarFilter", {
  enumerable: true,
  get: function get() {
    return _CalendarFilter["default"];
  }
});
Object.defineProperty(exports, "CalendarNav", {
  enumerable: true,
  get: function get() {
    return _CalendarNav["default"];
  }
});
Object.defineProperty(exports, "CalendarBooking", {
  enumerable: true,
  get: function get() {
    return _CalendarBooking["default"];
  }
});
Object.defineProperty(exports, "CalendarCellRow", {
  enumerable: true,
  get: function get() {
    return _CalendarCellRow["default"];
  }
});
Object.defineProperty(exports, "CalendarCellRowHeader", {
  enumerable: true,
  get: function get() {
    return _CalendarCellRowHeader["default"];
  }
});
Object.defineProperty(exports, "CalendarCellColumn", {
  enumerable: true,
  get: function get() {
    return _CalendarCellColumn["default"];
  }
});
Object.defineProperty(exports, "CalendarCellProperty", {
  enumerable: true,
  get: function get() {
    return _CalendarCellProperty["default"];
  }
});
Object.defineProperty(exports, "CalendarCellEventList", {
  enumerable: true,
  get: function get() {
    return _CalendarCellEventList["default"];
  }
});
Object.defineProperty(exports, "CalendarTile", {
  enumerable: true,
  get: function get() {
    return _CalendarTile["default"];
  }
});
Object.defineProperty(exports, "CalendarTileButtons", {
  enumerable: true,
  get: function get() {
    return _CalendarTileButtons["default"];
  }
});
Object.defineProperty(exports, "CalendarTileJobRequest", {
  enumerable: true,
  get: function get() {
    return _CalendarTileJobRequest["default"];
  }
});
Object.defineProperty(exports, "CalendarList", {
  enumerable: true,
  get: function get() {
    return _CalendarList["default"];
  }
});
Object.defineProperty(exports, "CalendarListItem", {
  enumerable: true,
  get: function get() {
    return _CalendarListItem["default"];
  }
});
Object.defineProperty(exports, "CalendarJobRequest", {
  enumerable: true,
  get: function get() {
    return _CalendarJobRequest["default"];
  }
});
Object.defineProperty(exports, "PropertySelect", {
  enumerable: true,
  get: function get() {
    return _PropertySelect["default"];
  }
});
Object.defineProperty(exports, "PropertyDetail", {
  enumerable: true,
  get: function get() {
    return _PropertyDetail["default"];
  }
});
Object.defineProperty(exports, "PropertyDetailIcon", {
  enumerable: true,
  get: function get() {
    return _PropertyDetailIcon["default"];
  }
});
Object.defineProperty(exports, "PropertyInformation", {
  enumerable: true,
  get: function get() {
    return _PropertyInformation["default"];
  }
});
Object.defineProperty(exports, "PropertyGallery", {
  enumerable: true,
  get: function get() {
    return _PropertyGalleryVirtual["default"];
  }
});
Object.defineProperty(exports, "EditableTagList", {
  enumerable: true,
  get: function get() {
    return _EditableTagList["default"];
  }
});
Object.defineProperty(exports, "JobDetailsExpansion", {
  enumerable: true,
  get: function get() {
    return _JobDetailsExpansionPanel["default"];
  }
});
Object.defineProperty(exports, "ProblemReportsExpansion", {
  enumerable: true,
  get: function get() {
    return _ProblemReportsExpansion["default"];
  }
});
Object.defineProperty(exports, "Pony", {
  enumerable: true,
  get: function get() {
    return _pony["default"];
  }
});
Object.defineProperty(exports, "AutoComplete", {
  enumerable: true,
  get: function get() {
    return _AutoComplete["default"];
  }
});
Object.defineProperty(exports, "SearchSelect", {
  enumerable: true,
  get: function get() {
    return _SearchSelect["default"];
  }
});
Object.defineProperty(exports, "PropertyImage", {
  enumerable: true,
  get: function get() {
    return _PropertyImage["default"];
  }
});
Object.defineProperty(exports, "DividerLine", {
  enumerable: true,
  get: function get() {
    return _DividerLine["default"];
  }
});
Object.defineProperty(exports, "Button", {
  enumerable: true,
  get: function get() {
    return _Button["default"];
  }
});
Object.defineProperty(exports, "Modal", {
  enumerable: true,
  get: function get() {
    return _Modal["default"];
  }
});
Object.defineProperty(exports, "ModalError", {
  enumerable: true,
  get: function get() {
    return _Modal.ModalError;
  }
});
Object.defineProperty(exports, "TabBar", {
  enumerable: true,
  get: function get() {
    return _TabBar["default"];
  }
});
Object.defineProperty(exports, "DynamicField", {
  enumerable: true,
  get: function get() {
    return _DynamicField["default"];
  }
});
Object.defineProperty(exports, "DynamicFieldInput", {
  enumerable: true,
  get: function get() {
    return _DynamicFieldInput["default"];
  }
});
Object.defineProperty(exports, "SearchGeoField", {
  enumerable: true,
  get: function get() {
    return _SearchGeoField["default"];
  }
});
Object.defineProperty(exports, "SearchField", {
  enumerable: true,
  get: function get() {
    return _SearchField["default"];
  }
});
Object.defineProperty(exports, "Input", {
  enumerable: true,
  get: function get() {
    return _Input["default"];
  }
});
Object.defineProperty(exports, "ProfilePicture", {
  enumerable: true,
  get: function get() {
    return _ProfilePicture["default"];
  }
});
Object.defineProperty(exports, "LoadingSplash", {
  enumerable: true,
  get: function get() {
    return _LoadingSplash["default"];
  }
});
Object.defineProperty(exports, "LoadingSpinner", {
  enumerable: true,
  get: function get() {
    return _LoadingSpinner["default"];
  }
});
Object.defineProperty(exports, "SpacedHeadline", {
  enumerable: true,
  get: function get() {
    return _SpacedHeadline["default"];
  }
});
Object.defineProperty(exports, "Icon", {
  enumerable: true,
  get: function get() {
    return _Icon["default"];
  }
});
Object.defineProperty(exports, "SquareSelect", {
  enumerable: true,
  get: function get() {
    return _SquareSelect["default"];
  }
});
Object.defineProperty(exports, "RoundSelect", {
  enumerable: true,
  get: function get() {
    return _RoundSelect["default"];
  }
});
Object.defineProperty(exports, "RadioSelect", {
  enumerable: true,
  get: function get() {
    return _RadioSelect["default"];
  }
});
Object.defineProperty(exports, "JobRequest", {
  enumerable: true,
  get: function get() {
    return _JobRequest["default"];
  }
});
Object.defineProperty(exports, "JobRequestIcons", {
  enumerable: true,
  get: function get() {
    return _JobRequestIcons["default"];
  }
});
Object.defineProperty(exports, "Tag", {
  enumerable: true,
  get: function get() {
    return _Tag["default"];
  }
});
Object.defineProperty(exports, "Badge", {
  enumerable: true,
  get: function get() {
    return _Badge["default"];
  }
});
Object.defineProperty(exports, "DurationInput", {
  enumerable: true,
  get: function get() {
    return _DurationInput["default"];
  }
});
Object.defineProperty(exports, "TimeField", {
  enumerable: true,
  get: function get() {
    return _TimeField["default"];
  }
});
Object.defineProperty(exports, "MediaQuery", {
  enumerable: true,
  get: function get() {
    return _MediaQuery["default"];
  }
});
Object.defineProperty(exports, "Layout", {
  enumerable: true,
  get: function get() {
    return _Layout["default"];
  }
});
Object.defineProperty(exports, "QtyPicker", {
  enumerable: true,
  get: function get() {
    return _QtyPicker["default"];
  }
});
Object.defineProperty(exports, "PopoverDefault", {
  enumerable: true,
  get: function get() {
    return _PopoverDefault["default"];
  }
});
Object.defineProperty(exports, "HoverTriggeredTooltip", {
  enumerable: true,
  get: function get() {
    return _Tooltip["default"];
  }
});
Object.defineProperty(exports, "Box", {
  enumerable: true,
  get: function get() {
    return _Box["default"];
  }
});
Object.defineProperty(exports, "Hint", {
  enumerable: true,
  get: function get() {
    return _Hint["default"];
  }
});
Object.defineProperty(exports, "MoreLess", {
  enumerable: true,
  get: function get() {
    return _MoreLess["default"];
  }
});
Object.defineProperty(exports, "PhoneNumber", {
  enumerable: true,
  get: function get() {
    return _PhoneNumber["default"];
  }
});
Object.defineProperty(exports, "Title1", {
  enumerable: true,
  get: function get() {
    return _TextType.Title1;
  }
});
Object.defineProperty(exports, "Title2", {
  enumerable: true,
  get: function get() {
    return _TextType.Title2;
  }
});
Object.defineProperty(exports, "Title3", {
  enumerable: true,
  get: function get() {
    return _TextType.Title3;
  }
});
Object.defineProperty(exports, "XLarge", {
  enumerable: true,
  get: function get() {
    return _TextType.XLarge;
  }
});
Object.defineProperty(exports, "Large", {
  enumerable: true,
  get: function get() {
    return _TextType.Large;
  }
});
Object.defineProperty(exports, "Regular", {
  enumerable: true,
  get: function get() {
    return _TextType.Regular;
  }
});
Object.defineProperty(exports, "Small", {
  enumerable: true,
  get: function get() {
    return _TextType.Small;
  }
});
Object.defineProperty(exports, "Micro", {
  enumerable: true,
  get: function get() {
    return _TextType.Micro;
  }
});
Object.defineProperty(exports, "SpaceMicro", {
  enumerable: true,
  get: function get() {
    return _Space.SpaceMicro;
  }
});
Object.defineProperty(exports, "SpaceTiny", {
  enumerable: true,
  get: function get() {
    return _Space.SpaceTiny;
  }
});
Object.defineProperty(exports, "SpaceSmall", {
  enumerable: true,
  get: function get() {
    return _Space.SpaceSmall;
  }
});
Object.defineProperty(exports, "SpaceSmallMid", {
  enumerable: true,
  get: function get() {
    return _Space.SpaceSmallMid;
  }
});
Object.defineProperty(exports, "SpaceBase", {
  enumerable: true,
  get: function get() {
    return _Space.SpaceBase;
  }
});
Object.defineProperty(exports, "SpaceMedium", {
  enumerable: true,
  get: function get() {
    return _Space.SpaceMedium;
  }
});
Object.defineProperty(exports, "SpaceMediumBase", {
  enumerable: true,
  get: function get() {
    return _Space.SpaceMediumBase;
  }
});
Object.defineProperty(exports, "SpaceLarge", {
  enumerable: true,
  get: function get() {
    return _Space.SpaceLarge;
  }
});
Object.defineProperty(exports, "SpaceXLarge", {
  enumerable: true,
  get: function get() {
    return _Space.SpaceXLarge;
  }
});
Object.defineProperty(exports, "SpaceFlexi", {
  enumerable: true,
  get: function get() {
    return _Space.SpaceFlexi;
  }
});
Object.defineProperty(exports, "DayPicker", {
  enumerable: true,
  get: function get() {
    return _DatePicker.DayPicker;
  }
});
Object.defineProperty(exports, "DayRangePicker", {
  enumerable: true,
  get: function get() {
    return _DatePicker.DayRangePicker;
  }
});
Object.defineProperty(exports, "START_DATE", {
  enumerable: true,
  get: function get() {
    return _DatePicker.START_DATE;
  }
});
Object.defineProperty(exports, "END_DATE", {
  enumerable: true,
  get: function get() {
    return _DatePicker.END_DATE;
  }
});
Object.defineProperty(exports, "Carousel", {
  enumerable: true,
  get: function get() {
    return _Carousel["default"];
  }
});
Object.defineProperty(exports, "ButtonList", {
  enumerable: true,
  get: function get() {
    return _ButtonList["default"];
  }
});
Object.defineProperty(exports, "Pill", {
  enumerable: true,
  get: function get() {
    return _Pill["default"];
  }
});
Object.defineProperty(exports, "Wizard", {
  enumerable: true,
  get: function get() {
    return _Wizard["default"];
  }
});
Object.defineProperty(exports, "CardShow", {
  enumerable: true,
  get: function get() {
    return _CardShow["default"];
  }
});
Object.defineProperty(exports, "Chip", {
  enumerable: true,
  get: function get() {
    return _Chip["default"];
  }
});
Object.defineProperty(exports, "DownloadAppPageDesktop", {
  enumerable: true,
  get: function get() {
    return _DownloadAppPage.DownloadAppPageDesktop;
  }
});
Object.defineProperty(exports, "DownloadAppPageMobile", {
  enumerable: true,
  get: function get() {
    return _DownloadAppPage.DownloadAppPageMobile;
  }
});
Object.defineProperty(exports, "BrandingAds", {
  enumerable: true,
  get: function get() {
    return _Ads["default"];
  }
});

var _App = _interopRequireDefault(require("./components/dumb/App"));

var _LoadGoogle = _interopRequireDefault(require("./hoc/LoadGoogle"));

var _WithHover = _interopRequireDefault(require("./hoc/WithHover"));

var _WithReCaptcha = _interopRequireDefault(require("./hoc/WithReCaptcha"));

var _LoginForm = _interopRequireDefault(require("./components/form/LoginForm"));

var _JobTimeForm = _interopRequireDefault(require("./components/form/JobTimeForm"));

var _JobTimeFormSimple = _interopRequireDefault(require("./components/form/JobTimeFormSimple"));

var _PropertyForm = _interopRequireDefault(require("./components/form/PropertyForm"));

var _PasswordResetForm = _interopRequireDefault(require("./components/form/PasswordResetForm"));

var _SignupForm = _interopRequireDefault(require("./components/form/SignupForm"));

var _TaskForm = _interopRequireDefault(require("./components/form/TaskForm"));

var _CostForm = _interopRequireDefault(require("./components/form/CostForm"));

var _CalendarEventFilterForm = _interopRequireDefault(require("./components/form/CalendarEventFilterForm"));

var _BookingDetails = _interopRequireDefault(require("./components/domain/booking/BookingDetails"));

var _BookingDetailsModal = _interopRequireDefault(require("./components/domain/booking/BookingDetailsModal"));

var _TasklistItem = _interopRequireDefault(require("./components/domain/checklist/TasklistItem"));

var _ChecklistItem = _interopRequireDefault(require("./components/domain/library/ChecklistItem"));

var _LibraryHeaders = require("./components/domain/library/LibraryHeaders");

var _SkillItem = require("./components/domain/library/SkillItem");

var _CalendarFilter = _interopRequireDefault(require("./components/domain/calendar/CalendarFilter"));

var _CalendarNav = _interopRequireDefault(require("./components/domain/calendar/CalendarNav"));

var _CalendarBooking = _interopRequireDefault(require("./components/domain/calendar/CalendarBooking"));

var _CalendarCellRow = _interopRequireDefault(require("./components/domain/calendar/CalendarCellRow"));

var _CalendarCellRowHeader = _interopRequireDefault(require("./components/domain/calendar/CalendarCellRowHeader"));

var _CalendarCellColumn = _interopRequireDefault(require("./components/domain/calendar/CalendarCellColumn"));

var _CalendarCellProperty = _interopRequireDefault(require("./components/domain/calendar/CalendarCellProperty"));

var _CalendarCellEventList = _interopRequireDefault(require("./components/domain/calendar/CalendarCellEventList"));

var _CalendarTile = _interopRequireDefault(require("./components/domain/calendar/CalendarTile"));

var _CalendarTileButtons = _interopRequireDefault(require("./components/domain/calendar/CalendarTileButtons"));

var _CalendarTileJobRequest = _interopRequireDefault(require("./components/domain/calendar/CalendarTileJobRequest"));

var _CalendarList = _interopRequireDefault(require("./components/domain/calendar/CalendarList"));

var _CalendarListItem = _interopRequireDefault(require("./components/domain/calendar/CalendarListItem"));

var _CalendarJobRequest = _interopRequireDefault(require("./components/domain/calendar/CalendarJobRequest"));

var _PropertySelect = _interopRequireDefault(require("./components/domain/property/PropertySelect"));

var _PropertyDetail = _interopRequireDefault(require("./components/domain/property/PropertyDetail"));

var _PropertyDetailIcon = _interopRequireDefault(require("./components/domain/property/PropertyDetailIcon"));

var _PropertyInformation = _interopRequireDefault(require("./components/domain/property/PropertyInformation"));

var _PropertyGalleryVirtual = _interopRequireDefault(require("./components/domain/property/PropertyGalleryVirtual"));

var _EditableTagList = _interopRequireDefault(require("./components/domain/tag/EditableTagList"));

var _JobDetailsExpansionPanel = _interopRequireDefault(require("./components/domain/jobRequest/JobDetailsExpansionPanel"));

var _ProblemReportsExpansion = _interopRequireDefault(require("./components/domain/jobRequest/ProblemReportsExpansion"));

var _pony = _interopRequireDefault(require("./components/domain/pony"));

var _AutoComplete = _interopRequireDefault(require("./components/dumb/AutoComplete"));

var _SearchSelect = _interopRequireDefault(require("./components/dumb/SearchSelect"));

var _PropertyImage = _interopRequireDefault(require("./components/dumb/PropertyImage"));

var _DividerLine = _interopRequireDefault(require("./components/dumb/DividerLine"));

var _Button = _interopRequireDefault(require("./components/dumb/Button"));

var _Modal = _interopRequireWildcard(require("./components/dumb/Modal"));

var _TabBar = _interopRequireDefault(require("./components/dumb/TabBar"));

var _DynamicField = _interopRequireDefault(require("./components/dumb/DynamicField"));

var _DynamicFieldInput = _interopRequireDefault(require("./components/dumb/DynamicFieldInput"));

var _SearchGeoField = _interopRequireDefault(require("./components/dumb/SearchGeoField"));

var _SearchField = _interopRequireDefault(require("./components/dumb/SearchField"));

var _Input = _interopRequireDefault(require("./components/dumb/Input"));

var _ProfilePicture = _interopRequireDefault(require("./components/dumb/ProfilePicture"));

var _LoadingSplash = _interopRequireDefault(require("./components/dumb/LoadingSplash"));

var _LoadingSpinner = _interopRequireDefault(require("./components/dumb/LoadingSpinner"));

var _SpacedHeadline = _interopRequireDefault(require("./components/dumb/SpacedHeadline"));

var _Icon = _interopRequireDefault(require("./components/dumb/Icon"));

var _SquareSelect = _interopRequireDefault(require("./components/dumb/SquareSelect"));

var _RoundSelect = _interopRequireDefault(require("./components/dumb/RoundSelect"));

var _RadioSelect = _interopRequireDefault(require("./components/dumb/RadioSelect"));

var _JobRequest = _interopRequireDefault(require("./components/dumb/JobRequest"));

var _JobRequestIcons = _interopRequireDefault(require("./components/dumb/JobRequestIcons"));

var _Tag = _interopRequireDefault(require("./components/dumb/Tag"));

var _Badge = _interopRequireDefault(require("./components/dumb/Badge"));

var _DurationInput = _interopRequireDefault(require("./components/dumb/DurationInput"));

var _TimeField = _interopRequireDefault(require("./components/dumb/TimeField"));

var _MediaQuery = _interopRequireDefault(require("./components/dumb/MediaQuery"));

var _Layout = _interopRequireDefault(require("./components/dumb/Layout"));

var _QtyPicker = _interopRequireDefault(require("./components/dumb/QtyPicker"));

var _PopoverDefault = _interopRequireDefault(require("./components/dumb/PopoverDefault"));

var _Tooltip = _interopRequireDefault(require("./components/dumb/Tooltip"));

var _Box = _interopRequireDefault(require("./components/dumb/Box"));

var _Hint = _interopRequireDefault(require("./components/dumb/Hint"));

var _MoreLess = _interopRequireDefault(require("./components/dumb/MoreLess"));

var _PhoneNumber = _interopRequireDefault(require("./components/dumb/PhoneNumber"));

var _TextType = require("./components/dumb/TextType");

var _Space = require("./components/dumb/Space");

var _DatePicker = require("./components/dumb/DatePicker");

var _Carousel = _interopRequireDefault(require("./components/dumb/Carousel"));

var _ButtonList = _interopRequireDefault(require("./components/dumb/ButtonList"));

var _Pill = _interopRequireDefault(require("./components/dumb/Pill"));

var _Wizard = _interopRequireDefault(require("./components/dumb/Wizard"));

var _CardShow = _interopRequireDefault(require("./components/dumb/CardShow"));

var _Chip = _interopRequireDefault(require("./components/dumb/Chip"));

var _DownloadAppPage = require("./components/domain/branding/DownloadAppPage");

var _Ads = _interopRequireDefault(require("./components/domain/branding/Ads"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }