import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import t from '@properly/localization';
import { CalendarCellRowHeader, CalendarNav, DividerLine, Button, LoadingSpinner } from '@properly/components';
import * as actions from '../state/CalendarActions';
import * as selectors from '../state/CalendarSelectors';
import { openJobRequestDetails } from '../../jobDetails/state/JobDetailsActions';
import CalendarFilterContainer from './CalendarFilterContainer';

const baseHeaderRowStyle = {
  minHeight: '50px',
  position: 'relative',
  display: 'flex',
  width: '100%',
};

class CalendarHeaderContainer extends Component {
  static propTypes = {
    dates: PropTypes.arrayOf(PropTypes.string),
    onHeightChange: PropTypes.func,
    isCalendarLoading: PropTypes.bool,
  };

  static defaultProps = {
    dates: [],
    onHeightChange: () => {},
    isCalendarLoading: false,
  };

  isLoading = () => {
    const { isCalendarLoading, loading, propertiesCount } = this.props;
    return !isCalendarLoading && loading && propertiesCount !== 0;
  };

  handleCalendarFilterHeightChange = height => {
    const { onHeightChange } = this.props;

    const navHeightPlusHeaderRowHeight = 105;
    const entireHeaderHeight = height + navHeightPlusHeaderRowHeight;
    onHeightChange(entireHeaderHeight);
  };

  renderCalendarFilters = () => {
    const { reminderOptions } = this.props;
    return (
      <div style={{ ...baseHeaderRowStyle, padding: '4px 8px' }}>
        <CalendarFilterContainer
          onHeightChange={this.handleCalendarFilterHeightChange}
          reminderOptions={reminderOptions}
        />
        <DividerLine type={['bottom', 'light']} />
      </div>
    );
  };

  renderNav = (currentStartDate, currentEndDate) => {
    const loading = this.isLoading();
    const { isCanceledFiltered } = this.props;
    return (
      <div style={{ position: 'relative' }}>
        <div style={{ ...baseHeaderRowStyle, padding: '12px 15px', justifyContent: 'space-between' }}>
          <div
            style={{
              marginRight: '160px',
              opacity: loading ? 1 : 0,
              transition: 'opacity 200ms ease',
              position: 'relative',
              pointerEvents: 'none',
            }}
            data-key-is-calendar-loading={loading}
          >
            <LoadingSpinner />
          </div>
          <CalendarNav
            loading={loading}
            goLeft={() => this.props.setCalendarPlus(currentStartDate, -1)}
            goLeftFull={() => this.props.setCalendarPlus(currentStartDate, -7)}
            goRight={() => this.props.setCalendarPlus(currentStartDate, 1)}
            goRightFull={() => this.props.setCalendarPlus(currentStartDate, 7)}
            fromDate={currentStartDate}
            toDate={currentEndDate}
          />
          <Grid container style={{ width: 'auto' }}>
            <Grid item>
              <Button
                data-key="button_open_today_calendar"
                onClick={() => this.props.setCalendarToday()}
                types={['type-border-primary', 'size-small', 'fontw-normal']}
              >
                {t('calendar.today')}
              </Button>
            </Grid>
            <Grid item style={{ marginLeft: '6px' }}>
              <Button
                styleOverwrite={{ width: '180px' }}
                data-key="button_toggle_canceled"
                onClick={() => this.props.toggleCanceledEventsFilter()}
                types={[isCanceledFiltered ? 'type-full-primary' : 'type-border-primary', 'size-small', 'fontw-normal']}
              >
                {isCanceledFiltered ? t('calendar.show_canceled_by_host') : t('calendar.hide_canceled_by_host')}
              </Button>
            </Grid>
          </Grid>
        </div>
        <DividerLine type={['bottom', 'light']} />
      </div>
    );
  };

  render() {
    const { startDate, endDate, filterDays, loading, propertiesCount, hasVerticalScroll } = this.props;
    const propertiesCountText = !propertiesCount && loading ? '...' : String(propertiesCount || 0);

    return (
      <>
        {this.renderNav(startDate, endDate)}

        {this.renderCalendarFilters()}

        <CalendarCellRowHeader
          hasVerticalScroll={hasVerticalScroll}
          filterDays={filterDays}
          loading={this.isLoading()}
          searchQuery={this.props.searchQuery}
          setSearchQuery={this.props.setSearchQuery}
          propertiesCountText={propertiesCountText}
          dates={this.props.days.toJS()}
        />
      </>
    );
  }
}

function mapStateToProps() {
  return state => ({
    filterDays: selectors.selectFilterDays(state),
    loading: selectors.selectPropertiesLoading(state),
    searchQuery: selectors.selectSearchQuery(state),
    startDate: selectors.selectStartDate(state),
    endDate: selectors.selectEndDate(state),
    days: selectors.selectDatesDays(state),
    propertiesCount: selectors.selectCurrentPropertyTotal(state),
    isCanceledFiltered: selectors.selectShowCanceledEvents(state),
  });
}

const mapDispatchToProps = {
  setSearchQuery: actions.setSearchQuery,
  toggleCanceledEventsFilter: actions.toggleCanceledEventsFilter,
  openJobRequestDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarHeaderContainer);
