import { createSelector } from 'reselect';

const selectAccount = state => state.account;

export const selectTmpUser = () => createSelector(selectAccount, account => account.get('tmpUser'));

export const selectUpdateUserState = () => createSelector(selectAccount, account => account.get('updateUserState'));

export const selectUpdateNotificationState = () =>
  createSelector(selectAccount, account => account.get('updateNotificationState'));

export const selectPasswordState = () => createSelector(selectAccount, account => account.get('passwordState'));

export const selectStates = () => createSelector(selectAccount, account => account.get('states'));

export const selectStatesSingle = field => createSelector(selectAccount, account => account.getIn(['states', field]));

export const selectWarningModal = () => createSelector(selectAccount, account => account.getIn(['warningModal']));

export const selectConnectedAccounts = createSelector(
  createSelector(selectAccount, account => account.get('connectedAccount')),
  connectedAccounts => connectedAccounts.toJS(),
);

export const selectHasConnectionIssue = createSelector(selectConnectedAccounts, connectedAccounts =>
  connectedAccounts.some(account =>
    account?.connectionSettings?.some(connectionSetting => connectionSetting?.connectionIssue),
  ),
);

export const selectConnectedAccountsLoading = createSelector(selectAccount, account =>
  account.get('connectedAccountLoading'),
);
export const selectConnectedAccountsError = createSelector(selectAccount, account =>
  account.get('connectedAccountError'),
);
export const selectConnectedAccountsDisconnecting = createSelector(selectAccount, account =>
  account.get('disconnectAccountLoading'),
);
export const selectConnectedAccountsDisconnectError = createSelector(selectAccount, account =>
  account.get('disconnectAccountError'),
);

export const selectConnectedAccountsSyncing = createSelector(selectAccount, account =>
  account.get('syncAccountLoading'),
);
export const selectConnectedAccountsSyncError = createSelector(selectAccount, account =>
  account.get('syncAccountError'),
);
