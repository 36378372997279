import React, { Component } from 'react';
import { DividerLine, Modal, Regular, Button, Title3, SpaceBase, SpaceSmall } from '@properly/components';
import t from '@properly/localization';
import { capitalizeFirstLetter } from '@properly/common';
import { connectedAccounts as ConnectedAccounts, reconnectConnections } from '@properly/config';
import log from 'loglevel';
import { connect } from 'react-redux';
import lodashResult from 'lodash/result';
import classNames from 'classnames/bind';
import { PagePadding } from '../../../../components/PageElements';
import styles from '../components/accountStyles.module.css';
import * as selectorsAccount from '../state/AccountSelectors';
import {
  goToAccountConnectionsMobile,
  goToAccountConntections,
  getConnectedAccountRequest,
  disconnectConnectedAccountRequest,
  dismissDisconnectError,
  redirectToProviderSaga,
  dismissSyncError,
  syncConnectedAccountRequest,
  redirectToMobileSaga,
} from '../state/AccountActions';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import { AccountHeadSection } from '../../../../components/AccountElements';
import AccountSyncPropertiesModal from '../components/AccountSyncPropertiesModal';
import { mobileExternalInterface } from '../../../../helper/mobileExternalInterface';
import InfoMessage from '../../../../components/InfoMessage';
import AdaptorConnectionMessageModal from '../components/AdaptorConnectionMessageModal';

const cx = classNames.bind(styles);

class AccountConnectionsAdaptorEditContainer extends Component {
  constructor(props) {
    super(props);

    const params = props.location?.query;
    const { error } = params || {};

    const reConnectionSuccess = error ? error === 'false' : undefined;
    this.state = {
      reConnectionSuccess,
    };
  }

  componentWillMount() {
    const { getConnectedAccount } = this.props;
    getConnectedAccount();
  }

  componentDidUpdate() {
    const {
      redirectToMobileSaga, // eslint-disable-line no-shadow
      externalMobileBrowserConnected,
      onboardingMobile,
      mobile,
    } = this.props;
    const { reConnectionSuccess } = this.state;

    if (!mobile || !(externalMobileBrowserConnected || onboardingMobile)) {
      return;
    }

    if (reConnectionSuccess) {
      redirectToMobileSaga(
        mobileExternalInterface.methodTypes.SUCCESS,
        externalMobileBrowserConnected,
        onboardingMobile,
        reConnectionSuccess,
        true,
      );
    }
  }

  redirectToProvider = () => {
    const { currentAccount, connectionSettingId, currentPartner, mobile, onboardingMobile } = this.props;
    const { oAuthLink, title } = currentAccount;
    if (!oAuthLink) {
      // eslint-disable-next-line no-alert
      alert('could not gadoodle the foodle');
      return;
    }
    const newOAuthLink = `${oAuthLink}&redirectPath=/settings/connections/${currentPartner}/${connectionSettingId}&connectionSettingsId=${connectionSettingId ||
      null}`;
    const loginWarningBlacklist = lodashResult(this.props.config, ['partnerLoginWarningBlacklist']);
    log.info('loginWarningBlacklist', loginWarningBlacklist);
    this.props.redirectToProviderSaga({
      oAuthLink: newOAuthLink,
      partnerTitle: title,
      connectedAccount: currentAccount,
      loginWarningBlacklist,
      mobile,
      onboardingMobile,
      connectionSettingId,
    });
  };

  dismissReConnectError = () => this.setState({ reConnectionSuccess: undefined });

  handleForceRedirect = (connectionSuccess, e) => {
    const {
      redirectToMobileSaga, // eslint-disable-line no-shadow
      externalMobileBrowserConnected,
      onboardingMobile,
    } = this.props;

    redirectToMobileSaga(
      connectionSuccess ? mobileExternalInterface.methodTypes.SUCCESS : mobileExternalInterface.methodTypes.FAILURE,
      externalMobileBrowserConnected,
      onboardingMobile,
      connectionSuccess,
    );
    e.preventDefault();
  };

  dismissSuccessfulConnectModal = () => this.setState({ reConnectionSuccess: undefined });

  render() {
    const {
      loading,
      connectionSettingId,
      connectionSettingLabel,
      connectionIssue,
      currentPartner,
      syncing,
      syncError,
      disconnecting,
      disconnectError,
      mobile,
      routeParams,
      externalMobileBrowserConnected,
      connectionMigrationDone,
      currentAccount,
      location,
    } = this.props;
    const { message = {} } = location?.query || {};
    const { reConnectionSuccess } = this.state;
    const { partner } = routeParams || {};
    if (loading) {
      return <Modal type="loading" show />;
    }

    return (
      <PagePadding type="absolute-scroll">
        <AccountHeadSection>
          <Title3 type="nomargin">{t('account.edit_account')}</Title3>
          <DividerLine type={['bottom']} />
        </AccountHeadSection>

        <SpaceBase />

        {/* TODO: this is temporary code , remove it once guesty migration is complete  */}
        {!connectionMigrationDone && currentAccount?.id === ConnectedAccounts.GUESTY && (
          <div style={{ marginBottom: '14px' }}>
            <InfoMessage
              text={t('account.edit_migration_guesty', {
                linkToGuesty: (
                  <a
                    className={cx('account__connectionlink--deepLink')}
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://support.guesty.com/en/article/properly-1081807"
                  >
                    {t('account.here')}
                  </a>
                ),
              })}
              icon="warning"
              color="red"
              isPrompt
            />
          </div>
        )}

        <div className={cx('account__flex')}>
          <img src={currentAccount?.icon} className={cx('accountAdapterEdit__vendor--icon')} alt="" />
          <Regular>{connectionSettingLabel}</Regular>
        </div>

        <div className={cx('account__edit')}>
          <SpaceBase />
          {!connectionIssue && (
            <Button
              types={['type-full-primary', 'size-large', 'width-flex']}
              onClick={() => this.props.syncConnectedAccountRequest(connectionSettingId, partner)}
            >
              {t('account.re_import')}
            </Button>
          )}
          {reconnectConnections?.includes(currentPartner) && (
            <>
              <SpaceSmall />
              <Button types={['type-reimport', 'size-large', 'width-flex']} onClick={() => this.redirectToProvider()}>
                {t('account.re_connect')}
              </Button>
            </>
          )}
          <SpaceSmall />

          <Button
            types={['type-border-grey', 'size-large', 'width-flex']}
            onClick={() => this.props.disconnectConnectedAccountRequest(connectionSettingId, mobile)}
          >
            {t('account.delete_account')}
          </Button>

          <Button
            types={['size-large', 'width-flex']}
            onClick={() => (mobile ? this.props.goToAccountConnectionsMobile() : this.props.goToAccountConntections())}
          >
            {t('permissions.modal.back')}
          </Button>
        </div>

        <Modal type="loading" show={disconnecting} />

        <AdaptorConnectionMessageModal
          errorCode={message}
          syncError={syncError}
          disconnectError={disconnectError}
          dismissDisconnectError={this.props.dismissDisconnectError}
          reConnectionSuccess={reConnectionSuccess}
          externalMobileBrowserConnected={externalMobileBrowserConnected}
          handleForceRedirect={this.handleForceRedirect}
          dismissReConnectError={this.dismissReConnectError}
          dismissSuccessfulConnectModal={this.dismissSuccessfulConnectModal}
          currentPartner={capitalizeFirstLetter(currentPartner)}
        />

        <AccountSyncPropertiesModal show={syncing} />
      </PagePadding>
    );
  }
}

function mapStateToProps(state, props) {
  const { location, routeParams } = props;
  const { query } = location || {};
  const { connectionIssue } = query || {};

  const { partner, id } = routeParams || {};
  const connectedAccounts = selectorsAccount.selectConnectedAccounts(state, props);

  const currentAccount = (connectedAccounts || []).find(({ id: currentId }) => currentId === partner);
  const { connectionSettings } = currentAccount || {};
  const currentConnectionSetting = (connectionSettings || []).find(({ id: currentId }) => currentId === id);

  return {
    currentUser: state.currentUser,
    disconnecting: selectorsAccount.selectConnectedAccountsDisconnecting(state, props),
    disconnectError: selectorsAccount.selectConnectedAccountsDisconnectError(state, props),
    currentAccount,
    currentPartner: partner,
    connectionSettingId: id,
    connectionSettingLabel: (currentConnectionSetting && currentConnectionSetting.label) || '',
    connectionMigrationDone: currentConnectionSetting?.migrated,

    connectionIssue: connectionIssue === '1',
    config: selectorsGlobal.selectConfig(state, props),
    generalStates: selectorsAccount.selectStates()(state, props),
    loading: selectorsAccount.selectConnectedAccountsLoading(state, props),
    error: selectorsAccount.selectConnectedAccountsError(state, props),
    syncError: selectorsAccount.selectConnectedAccountsSyncError(state, props),
    syncing: selectorsAccount.selectConnectedAccountsSyncing(state, props),
  };
}

export default connect(mapStateToProps, {
  goToAccountConnectionsMobile,
  goToAccountConntections,
  redirectToProviderSaga,
  disconnectConnectedAccountRequest,
  dismissDisconnectError,
  syncConnectedAccountRequest,
  dismissSyncError,
  getConnectedAccount: getConnectedAccountRequest,
  redirectToMobileSaga,
})(AccountConnectionsAdaptorEditContainer);
