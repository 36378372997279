import { fromJS } from 'immutable';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import * as types from '../types';
import { RELOAD_STATUS } from '../dataConstants';

let initState = fromJS({
  preloadStatus: RELOAD_STATUS.NOTLOADED,

  searchQueries: {
    properties: '',
    contacts: '',
  },
  modals: {
    // key: {
    //  value: value,
    // meta: {}
    // }
  },
  errorModal: {
    isVisible: false,
    meta: null,
    location: null,
  },
  parseConfig: {},
  generalConfig: {},
  sliderState: {
    isOpen: false,
    images: [],
  },
  loadingStates: {
    // check 'selectLoadingState' selector for different global loading states
    // key: { value: '', meta: {}}
  },
});
initState = initState.set('parseConfig', {});

export default function moreReducer(state = initState, action = {}) {
  switch (action.type) {
    case types.GLOBAL_LOGOUT: // on logout reset
      return initState.set('parseConfig', state.get('parseConfig')).set('generalConfig', state.get('generalConfig'));
    case types.GLOBAL_SET_PRELOAD_STATUS:
      return state.setIn(['preloadStatus'], action.val);
    case types.SET_SLIDER_STATE:
      return state.mergeIn(['sliderState'], fromJS(action.changeSet));
    case types.SET_PARSE_CONFIG:
      return state.setIn(['parseConfig'], action.val);
    case types.SET_GENERAL_CONFIG:
      return state.setIn(['generalConfig'], action.val);
    case types.GLOBAL_SET_GLOBAL_SEARCH_QUERY:
      return state.setIn(['searchQueries', action.index], action.val);
    case types.SET_GLOBAL_LOADING_STATE:
      return state.mergeIn(['loadingStates', action.key], fromJS(pick(action, ['value', 'meta'])));
    case types.GLOBAL_SET_MODAL:
      return state.setIn(
        ['modals', action.id],
        fromJS({
          value: action.value,
          meta: action.meta,
        }),
      );
    case types.SET_ERROR_MODAL:
      return state.setIn(['errorModal'], {
        isVisible: true,
        meta: omit(action, ['type', 'location']),
        location: action.location,
      });
    case types.RESET_ERROR_MODAL:
      return state.updateIn(['errorModal'], data => ({ ...data, ...action.data }));
    default:
      return state;
  }
}
