import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose, mapProps, flattenProp } from 'recompose';
import t from '@properly/localization';
import { SpacedHeadline } from '@properly/components';
import filter from 'lodash/filter';
import pick from 'lodash/pick';
import noop from 'lodash/noop';
import { mapToMainState } from './permissionhelper';
import { JobRequestSpacerWrap, JobRequestPropertyList } from '../../../components/JobRequestElements';
import { handlePermissionActionSaga } from './PermssionsActions';

class PropSection extends PureComponent {
  get teamOwnerRole() {
    return this.props.sectionValue.get('ownerRole');
  }

  get properties() {
    const all = this.props.filteredProperties.toArray();
    return filter(all, item => item.ownerRole === this.teamOwnerRole);
  }

  render() {
    return (
      <JobRequestSpacerWrap left="24px" top="20px" bottom="20px" right="24px" flexGrow>
        <SpacedHeadline size="small">{t('permissions.modal.shared_properties')}</SpacedHeadline>
        <JobRequestPropertyList
          height={456}
          width={544}
          properties={this.properties}
          handleClick={noop}
          isActiveFunc={() => false}
          isHoverableFunc={() => false}
          hasClickHoleFunc={() => false}
          isClickableFunc={() => true}
        />
      </JobRequestSpacerWrap>
    );
  }
}

export default compose(
  connect(
    mapToMainState,
    { handlePermissionActionSaga },
  ),
  mapProps(props =>
    pick(props, ['activeSectionData', 'handlePermissionActionSaga', 'filteredProperties', 'currentUser']),
  ),
  flattenProp('activeSectionData'),
)(PropSection);
