import map from 'lodash/map';
import { fetchParseCloudFunction } from './data.http';
import { requestHandler } from './data.requestHandler';
import { _mapParseTeamToTeam, _mapParseCollaboratorTCollaborator } from './data.map';

export function getTeamsSubscriptionInfo() {
  return requestHandler(() =>
    fetchParseCloudFunction('getTeamsSubscriptionInfo').then(result => map(result, _mapParseTeamToTeam)),
  );
}

export function getTeams() {
  return requestHandler(() => fetchParseCloudFunction('getTeams').then(result => map(result, _mapParseTeamToTeam)));
}

export function getCollaborators() {
  return requestHandler(() =>
    fetchParseCloudFunction('getCollaborators').then(result => map(result, _mapParseCollaboratorTCollaborator)),
  );
}
