import React, { PureComponent } from 'react';
import { Modal } from '@properly/components';
import t from '@properly/localization';
import { connect } from 'react-redux';
import { setModal } from '../../actions/globalActions';
import { selectGlobalModalResolved } from '../../selectors/globalSelector';

const name = 'warningdeletedproperty';

class WarningDeletedProperty extends PureComponent {
  close = () => this.props.setModal(false, name, {});

  render() {
    const isOpen = !!this.props.data.isOpen;

    return (
      <Modal height={200} onClose={this.close} show={isOpen}>
        <div>
          <Modal.H1>{t('jobdetails.warning')}</Modal.H1>
          <Modal.Text>{t('jobdetails.cannot_be_edited')}</Modal.Text>
          <Modal.Options>
            <Modal.Ok onClick={this.close}>{t('modal.ok')}</Modal.Ok>
          </Modal.Options>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps() {
  const memo = selectGlobalModalResolved(name);
  return (state, props) => ({
    data: memo(state, props),
  });
}

export default connect(
  mapStateToProps,
  {
    setModal,
  },
)(WarningDeletedProperty);
