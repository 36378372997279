import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@properly/components';
import { connect } from 'react-redux';
import { closePaywallModal } from '../state/JobRequestActions';
import { selectIsPaywallModalOpen } from '../state/JobRequestSelectors';
import { setModal, goUpgrade } from '../../../../actions/globalActions';
import { selectGlobalModalResolved } from '../../../../selectors/globalSelector';
import JobRequestContainer from './JobRequestContainer';
import JobRequestPaywallModal from '../components/JobRequestPaywallModal';
import lazyLoadComponent from '../../../../containers/LazyLoadComponent/index';

const JobRequestContainerResolved = lazyLoadComponent(JobRequestContainer);

class JobRequestModal extends PureComponent {
  static propTypes = {
    isPaywallModalOpen: PropTypes.bool,
  };

  static defaultProps = {
    isPaywallModalOpen: false,
  };

  closeJobRequestModal = () => this.props.setModal(false, 'jobrequest', {});

  render() {
    const { meta, isOpen } = this.props.jobRequestModal;
    const { isPaywallModalOpen } = this.props;
    return (
      <>
        <Modal
          type="jobsend"
          id="jobrequestmodal"
          style={{ zIndex: '100 !important' }}
          onClose={this.closeJobRequestModal}
          show={!!isOpen}
        >
          <JobRequestContainerResolved onClose={this.closeJobRequestModal} startupData={meta} />
        </Modal>
        <JobRequestPaywallModal
          onUpgrade={() => {
            this.closeJobRequestModal();
            this.props.closePaywallModal();
            this.props.goUpgrade();
          }}
          onClose={this.props.closePaywallModal}
          isPaywallModalOpen={isPaywallModalOpen}
        />
      </>
    );
  }
}

function mapStateToProps() {
  const selectGlobalJobRequestModal = selectGlobalModalResolved('jobrequest');
  return (state, props) => ({
    jobRequestModal: selectGlobalJobRequestModal(state, props),
    isPaywallModalOpen: selectIsPaywallModalOpen(state),
  });
}

export default connect(mapStateToProps, {
  goUpgrade,
  setModal,
  closePaywallModal,
})(JobRequestModal);
