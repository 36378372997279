import * as Sentry from '@sentry/react';
import Parse from 'parse';
import log from 'loglevel';
import forOwn from 'lodash/forOwn';
import result from 'lodash/result';
import map from 'lodash/map';
import each from 'lodash/each';
import isArray from 'lodash/isArray';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import isObject from 'lodash/isObject';
import { getUtmParamsFromCookie, redirectPage, restartIntercom } from '@properly/common';
import axios from 'axios';
import { error as errorMessage, getConfigSync } from '@properly/config';
import { AppStoreUrls } from '../../config';
import ProperlyError from '../../helper/properlyError';
import { requestHandler } from './data.requestHandler';
import { trackSendToMobileAction } from '../../actions/trackingEvents';

import {
  _mapParseUserToUser,
  _mapParseSubscriptionToSubscription,
  _mapParseReportToReport,
  _mapParsePlanToPlan,
  _mapParseCalendarEventToCalendarEvent,
  _mapParseIcalToIcal,
  _mapParseTeamToTeam,
  _mapParseProfileToProfile,
  _mapParsePhotoFeedbackToPhotoFeedback,
  _mapParseContactToContact,
  _mapParsePropertyToProperty,
  _mapParseTriggerToTrigger,
  _mapParseCollaboratorTCollaborator,
  _mapParseJobToJob,
  _mapParseJobInstructionsToJobInstructions,
} from './data.map';
import { fetchUrl } from './data.http';

const config = getConfigSync();

Parse.initialize(config.APP_ID, config.JS_KEY);
Parse.serverURL = config.PARSE_SERVER_URL;
Parse.liveQueryServerURL = config.PARSE_SERVER_LIVE_QUERY_URL;
Parse.CoreManager.set('REQUEST_ATTEMPT_LIMIT', 0);

// This is a scary function - but we should ensure that a Parse object is NOT a singleton if we
// are to migrate away from this magical parse library feature.
//
// https://getproperly.atlassian.net/browse/WEB-826
//
// https://parseplatform.org/Parse-SDK-JS/api/2.1.0/Parse.Object.html#.disableSingleInstance
//
// Parse.Object.disableSingleInstance();
//
//

Parse.LiveQuery.on('error', error => {
  log.info('Error in websocket connection', error);
});

export function getSessionToken() {
  return Parse.User.current().getSessionToken();
}

export function getCurrentUser() {
  const parseUser = Parse.User.current();
  if (parseUser) {
    const user = _mapParseUserToUser(parseUser);
    user.setSessionToken(getSessionToken());
    return user;
  }
  return null;
}

export function setContextSentry(userId) {
  Sentry.configureScope(scope => {
    scope.setUser({
      id: userId,
    });
  });
}

export function downloadReport({
  format,
  columns,
  title,
  startDate,
  endDate,
  properties,
  cleaners,
  problemsReported,
  cleanerRequestStatus,
  jobRequestStatus,
  jobType,
}) {
  return requestHandler(() =>
    Parse.Cloud.run('downloadReport', {
      format,
      columns,
      title,
      startDate,
      endDate,
      properties,
      cleaners,
      problemsReported,
      cleanerRequestStatus,
      jobRequestStatus,
      jobType,
    }),
  );
}

export function fetchReport({
  startDate,
  endDate,
  properties,
  cleaners,
  problemsReported,
  cleanerRequestStatus,
  jobRequestStatus,
  jobType,
  skip,
  limit,
}) {
  return requestHandler(() =>
    Parse.Cloud.run('fetchReport', {
      skip,
      limit,
      startDate,
      endDate,
      properties,
      cleaners,
      problemsReported,
      cleanerRequestStatus,
      jobRequestStatus,
      jobType,
    }),
  );
}

export function downloadStatus({ reportProcessingId }) {
  return requestHandler(() =>
    Parse.Cloud.run('downloadStatus', {
      reportProcessingId,
    }),
  );
}

export function resetPassword(username) {
  return requestHandler(() =>
    Parse.Cloud.run('resetPasswordForUsername', {
      username,
    }),
  );
}

export function toggleRole(role) {
  return requestHandler(() =>
    Parse.Cloud.run('toggleRole', {
      role,
    }),
  );
}

export function setNewPassword(password, token) {
  return requestHandler(() =>
    Parse.Cloud.run('setNewPassword', {
      password,
      token,
    }),
  );
}

export function hostFRESeen(userId) {
  return requestHandler(() =>
    Parse.Cloud.run('hostFRESeen', {
      userId,
    }),
  );
}

export function webFRESeen(userId) {
  return requestHandler(() =>
    Parse.Cloud.run('webFRESeen', {
      userId,
    }),
  );
}

export function syncBookings(waitForResult) {
  return requestHandler(() =>
    Parse.Cloud.run('syncBookings', {
      waitForResult: !waitForResult,
    }),
  );
}

export function getParseTrigger(propertyId) {
  return requestHandler(() => {
    const query1 = new Parse.Query('Trigger');
    query1.limit(100);
    query1.notEqualTo('deleted', true);
    query1.equalTo('propertyId', propertyId);
    return query1.find().then(results => results.map(jobRequest => _mapParseTriggerToTrigger(jobRequest)));
  });
}

export function parseLogoutUser() {
  return Parse.User.logOut();
}

export function getParseReports(timestamp) {
  return requestHandler(() => {
    const query = new Parse.Query('Report');
    query.limit(1000);
    query.notEqualTo('deleted', true);

    if (timestamp) {
      query.greaterThan('updatedAt', new Date(timestamp));
    }
    return query.find().then(results => results.map(report => _mapParseReportToReport(report)));
  });
}

export function getParsePlans() {
  return requestHandler(() => {
    const query = new Parse.Query('Plan');
    return query.find().then(results => results.map(plan => _mapParsePlanToPlan(plan)));
  });
}

export function getParseContact(id) {
  return requestHandler(() => {
    const query = new Parse.Query('Contact');
    query.include('profile');
    return query.get(id).then(contactResult => _mapParseContactToContact(contactResult));
  });
}

export function getParseProperty(id) {
  return requestHandler(() => {
    const query = new Parse.Query('Property');
    return query.get(id).then(propertyResult => _mapParsePropertyToProperty(propertyResult));
  });
}

export function getParseJobs(timestamp) {
  return requestHandler(() => {
    const query = new Parse.Query('Job');
    query.notEqualTo('deleted', true);
    query.limit(100000);

    if (timestamp) {
      query.greaterThan('updatedAt', new Date(timestamp));
    }

    return query.find().then(results => results.map(job => _mapParseJobToJob(job)));
  });
}

export function getParseJobsForProperty(propertyId) {
  return requestHandler(async () => {
    const results = [];
    const recursiveFunc = async (skip = 0) => {
      const limit = 100;
      const offset = skip * limit;
      const ParseProperty = Parse.Object.extend('Property');
      const myProperty = new ParseProperty();
      myProperty.id = propertyId;
      const query = new Parse.Query('Job');
      query.notEqualTo('deleted', true);
      query.equalTo('defaultProperty', myProperty);
      query.skip(offset);
      query.limit(limit);
      const resultsTemp = await query.find().then(apiResults => apiResults.map(job => _mapParseJobToJob(job)));
      results.push(...resultsTemp);
      if (resultsTemp?.length >= limit) {
        await recursiveFunc(skip + 1);
      }
    };
    await recursiveFunc();
    return results;
  });
}

export function parseSkipEvent(eventId) {
  return requestHandler(() =>
    Parse.Cloud.run('skipEvent', {
      eventId,
    }),
  );
}

export function parseGetInvoicesByUser() {
  return requestHandler(() => Parse.Cloud.run('getInvoicesByUser', {}));
}

export function mapClassToMapper(className) {
  switch (className) {
    case '_User':
    case 'User':
      return _mapParseUserToUser;
    case 'Subscription':
      return _mapParseSubscriptionToSubscription;
    case 'Event':
      return _mapParseCalendarEventToCalendarEvent;
    default:
      throw Error('mapClassToMapper - mapper not defined', className);
  }
}

export function mapParseLiveQueryObjectToLocalClass(className, emitter, parseObject) {
  try {
    const localClass = mapClassToMapper(className)(parseObject);
    emitter(localClass);
  } catch (e) {
    log.error('mapParseLiveQueryObjectToLocalClass', e);
  }
}

export async function liveQueryGenerator(className, emitter, listenOnArray = [], queryModifierFunc) {
  let query = new Parse.Query(className);
  query = queryModifierFunc ? queryModifierFunc(query) : query;
  const subscription = query.subscribe();

  each(listenOnArray, listenOnWhat => {
    subscription.on(listenOnWhat, mapParseLiveQueryObjectToLocalClass.bind(null, className, emitter));
  });

  subscription.on('error', error => {
    log.error('Error in live query subscription', error, className);
  });

  return subscription;
}

export function getParseSuggestedCleanersNew(property) {
  return requestHandler(() =>
    Parse.Cloud.run('getSuggestedCleaners', {
      longitude: property.getLong(),
      latitude: property.getLat(),
    }).then(suggestedCleanersResult => suggestedCleanersResult.map(profile => _mapParseProfileToProfile(profile))),
  );
}

export function generateToken(length) {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return `${text}`;
}

export function cloneJobAndJobInstruction(job, jobInstruction) {
  const currentUserId = Parse.User.current().id;
  const JobInner = Parse.Object.extend('Job');
  const UserInner = Parse.Object.extend('User');
  const PropertyInner = Parse.Object.extend('Property');
  const JobInsInner = Parse.Object.extend('JobInstructions');
  const RoleInner = Parse.Object.extend('Role');
  const jobIn = new JobInner();
  const userIn = new UserInner();
  const jobInsIn = new JobInsInner();
  each(keys(omit(job, ['jobInstructionId'])), key => {
    jobIn.set(key, job[key]);
  });
  each(keys(jobInstruction), key => {
    jobInsIn.set(key, jobInstruction[key]);
  });
  const roleIn = new RoleInner();
  const propertyIn = new PropertyInner();
  propertyIn.id = job.defaultProperty;
  roleIn.id = job.role;
  userIn.id = job.user || currentUserId;
  jobInsIn.set('user', userIn);
  jobIn.set('jobInstructions', jobInsIn);
  jobIn.set('user', userIn);
  jobIn.set('defaultProperty', propertyIn);
  jobIn.set('role', undefined); // reset will be set by server

  return requestHandler(() => jobIn.save().then(res => _mapParseJobToJob(res)));
}

const getAxiosResult = res => Promise.resolve(res.data.result);
const becomeUserFromRes = res =>
  Parse.User.become(res.sessionToken).then(() => _mapParseUserToUser(Parse.User.current()));
const transformError = reason => {
  const parseError = {
    code: result(reason, ['response', 'data', 'code']),
    message: result(reason, ['response', 'data', 'error']),
  };
  return Promise.reject(ProperlyError.errorFromParse(parseError));
};

export function awsLogin(username, password) {
  return axios
    .request({
      url: config.LOGIN_URL,
      method: 'post',
      timeout: 25000,
      data: {
        username: username?.trim(),
        password,
        _ApplicationId: config.APP_ID,
        _JavaScriptKey: config.JS_KEY,
      },
    })
    .then(getAxiosResult)
    .then(becomeUserFromRes)
    .catch(transformError);
}

export function awsSignup(
  firstName,
  lastName,
  phoneRegionalNumber,
  phoneCountryCode,
  email,
  password,
  reCaptchaToken,
  reCaptchaVersion,
  language,
  campaign,
  referrer,
  content,
  medium,
  source,
  term,
  utm_source, // eslint-disable-line
) {
  return axios
    .request({
      url: config.SIGNUP_URL,
      method: 'post',
      timeout: 25000,
      data: {
        firstName,
        lastName,
        phoneRegionalNumber,
        phoneCountryCode: phoneRegionalNumber && phoneCountryCode,
        password,
        email,
        reCaptchaToken,
        reCaptchaVersion,
        role: 1,
        language,
        campaign,
        referrer,
        content,
        medium,
        source,
        term,
        utm_source, // eslint-disable-line
        _ApplicationId: config.APP_ID,
        _JavaScriptKey: config.JS_KEY,
      },
    })
    .then(getAxiosResult)
    .then(becomeUserFromRes)
    .catch(transformError);
}

export function createJobAndJobInstruction(propertyId, title, userId, roleId) {
  const JobInner = Parse.Object.extend('Job');
  const UserInner = Parse.Object.extend('_User');
  const PropertyInner = Parse.Object.extend('Property');
  const JobInsInner = Parse.Object.extend('JobInstructions');
  const RoleInner = Parse.Object.extend('_Role');
  const jobIn = new JobInner();
  const userIn = new UserInner();
  const jobInsIn = new JobInsInner();
  const roleIn = new RoleInner();
  const propertyIn = new PropertyInner();
  propertyIn.id = propertyId;
  roleIn.id = roleId;
  userIn.id = userId;
  jobInsIn.set('user', userIn);
  jobIn.set('jobInstructions', jobInsIn);
  jobIn.set('title', title);
  jobIn.set('user', userIn);
  jobIn.set('defaultProperty', propertyIn);

  jobInsIn.set('user', userIn);
  jobInsIn.set('role', roleIn);
  jobInsIn.set('steps', []);

  return requestHandler(() => jobIn.save().then(res => _mapParseJobToJob(res)));
}

export function createIcal(propertyId, title, link) {
  const PropertyInner = Parse.Object.extend('Property');
  const IcalInner = Parse.Object.extend('iCal');
  const propertyIn = new PropertyInner();
  const icalIn = new IcalInner();
  propertyIn.id = propertyId;
  icalIn.set('property', propertyIn);
  icalIn.set('title', title);
  icalIn.set('link', link);

  return requestHandler(() => icalIn.save().then(res => _mapParseIcalToIcal(res)));
}

export async function uploadImage(file) {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await fetchUrl(`${config.API_BASE_URL}/upload/image`, 'POST', formData, true, true);
    return {
      pictureUrl: response?.pictureUrl,
    };
  } catch (error) {
    log.error('[uploadImage] Could not upload file to cloudinary', error);
    throw error;
  }
}

export function parseChangeUserPassword(oldPassword, newPassword) {
  return requestHandler(() =>
    Parse.Cloud.run('updatePassword', {
      oldPassword,
      newPassword,
    }),
  );
}

export function parseGenerateTrackingLink(params) {
  return Parse.Cloud.run('generateTrackingLinkNew', params).then(trackingLinkResult => trackingLinkResult.link);
}

export function goToAppStore(platform, branchIOLink) {
  trackSendToMobileAction(platform);
  if (branchIOLink) return redirectPage(branchIOLink);
  return parseGenerateTrackingLink(getUtmParamsFromCookie()).then(
    link => redirectPage(link),
    () => {
      const url = platform === 'iOS' ? AppStoreUrls.ios : AppStoreUrls.android;
      redirectPage(url);
    },
  );
}

export function disconnectPartner(partner, partnerUserId) {
  return requestHandler(() =>
    Parse.Cloud.run('disconnectPartner', {
      partner,
      partnerUserId,
    }).then(res => _mapParseUserToUser(res.user)),
  );
}

export function updateParseJob(jobId, data) {
  // eslint-disable-next-line
  const ParseJob = new Parse.Object.extend('Job');
  const parseJob = new ParseJob();

  parseJob.id = jobId;
  forOwn(data, (value, key) => {
    parseJob.set(key, value);
  });
  return requestHandler(() => parseJob.save().then(job => _mapParseJobToJob(job)));
}

export function updateParseJobInstructions(jobInsId, data) {
  // eslint-disable-next-line
  const ParseJobInstructions = new Parse.Object.extend('JobInstructions');
  const parseJobInstructions = new ParseJobInstructions();

  parseJobInstructions.id = jobInsId;
  forOwn(data, (value, key) => {
    if (key === 'steps' && !isArray(value)) {
      parseJobInstructions.set(key, []); // safe quard
    } else {
      parseJobInstructions.set(key, value);
    }
  });
  return requestHandler(() => parseJobInstructions.save().then(job => _mapParseJobInstructionsToJobInstructions(job)));
}

export function updateParseContactTags(contactId, tags) {
  // eslint-disable-next-line
  const ContactProperty = new Parse.Object.extend('Contact');
  const contact = new ContactProperty();

  contact.id = contactId;

  contact.set('tags', tags);

  return requestHandler(() =>
    contact.save().then(updatedParseContact => _mapParseContactToContact(updatedParseContact)),
  );
}

export function updateParseProperty(propertyId, data, userId) {
  // eslint-disable-next-line
  const ParseProperty = new Parse.Object.extend('Property');
  const parseProperty = new ParseProperty();

  try {
    if (!userId) {
      parseProperty.id = propertyId;
    }

    if (!parseProperty.attributes.pictureUrls) {
      parseProperty.set('pictureUrls', []);
    }

    forOwn(data, (value, key) => {
      if (key === 'pictureUrl' && !value) {
        // fix fore IOS bug (crash because imageurl null and not '')
        parseProperty.set(key, '');
      } else if (key === 'location') {
        parseProperty.set(key, new Parse.GeoPoint(data.location.latitude, data.location.longitude));
      } else if (key === 'defaultJob') {
        // eslint-disable-next-line
        const JobObj = new Parse.Object.extend('Job');
        let newJob = null;
        if (value) {
          newJob = new JobObj();
          newJob.id = value;
        }
        parseProperty.set(key, newJob);
      } else {
        parseProperty.set(key, value);
      }
    });
  } catch (err) {
    log.error('Error updating parse property', err);
    throw err;
  }

  // only for create
  if (userId) {
    const parseUser = new Parse.User();
    parseUser.id = userId;
    parseProperty.set('user', parseUser);
  }

  return requestHandler(() =>
    parseProperty.save().then(updatedParseProperty => _mapParsePropertyToProperty(updatedParseProperty)),
  );
}

export function updateParseUser(data) {
  log.info('updateParseUser', data);
  return requestHandler(() => Parse.Cloud.run('updateUser2', data).then(result1 => _mapParseUserToUser(result1)));
}

export function sendPasswordLink() {
  return requestHandler(() =>
    Parse.Cloud.run('resetPassword', {
      type: 'email',
    }),
  );
}

export function getSharedWithMe() {
  const setDoJobRightForTeams = teams => {
    // TODO: Remove this setter after backend sets doJob right to all users,teams
    // this unorthodox permission set should this should cover us until then
    Object.values(teams).forEach(team => {
      if (!(typeof team.rights?.doJob === 'boolean')) {
        team.rights.doJob = team.rights?.sendJobs;
      }
    });
    return teams;
  };

  return requestHandler(() => Parse.Cloud.run('getSharedWithMe').then(res => setDoJobRightForTeams(res)));
}

export function leaveCollaboration(teamId) {
  return requestHandler(() => Parse.Cloud.run('leaveCollaboration', { teamId }));
}

export function removeCollaborator(userId, teamId) {
  return requestHandler(() => Parse.Cloud.run('removeCollaborator', { teamId, userId }));
}

export function requestPhoneVerificationCode() {
  return requestHandler(() => Parse.Cloud.run('requestPhoneVerificationCode'));
}

export function redeemPhoneVerificationCode(code) {
  return requestHandler(() =>
    Parse.Cloud.run('redeemPhoneVerificationCode', {
      code,
    }),
  );
}

export function setReferrerFromWebapp(params) {
  return requestHandler(() =>
    Parse.Cloud.run('setReferrerFromWebapp', {
      ...params,
    }),
  );
}

export function updateParseTrigger(triggerId, data) {
  const ParseTrigger = Parse.Object.extend('Trigger');
  const parseTrigger = new ParseTrigger();

  if (!data.isUnsaved) {
    parseTrigger.id = triggerId;
  }

  forOwn(data, (value, key) => {
    if (key === 'role') {
      const RoleInner = Parse.Object.extend('_Role');
      const roleIn = new RoleInner();
      roleIn.id = value;
      parseTrigger.set(key, roleIn);
    } else if (key !== 'objectId' && value) {
      parseTrigger.set(key, value);
    }
    if (key === 'jobId' && value === null) {
      parseTrigger.unset('jobId');
    }
  });

  return requestHandler(() => parseTrigger.save().then(updatedTrigger => _mapParseTriggerToTrigger(updatedTrigger)));
}

export function addTeamMember(obj) {
  return requestHandler(() =>
    Parse.Cloud.run('addCollaborator', obj).then(addTeamMemberResult =>
      _mapParseCollaboratorTCollaborator(addTeamMemberResult),
    ),
  );
}

export function editTeamMember(obj) {
  return requestHandler(() =>
    Parse.Cloud.run('editCollaborator', obj).then(editTeamMemberResult =>
      _mapParseCollaboratorTCollaborator(editTeamMemberResult),
    ),
  );
}

export function editTeam(obj) {
  return requestHandler(() =>
    Parse.Cloud.run('editTeam', obj).then(editTeamResult => ({
      team: _mapParseTeamToTeam(editTeamResult.team),
      emailSend: editTeamResult.emailSend,
    })),
  );
}

export function addContact(addContactObj) {
  return requestHandler(() =>
    Parse.Cloud.run('addContact', addContactObj).then(addContactResult =>
      _mapParseContactToContact(addContactResult.contact),
    ),
  );
}

export function resendInvite(addContactObj) {
  return requestHandler(() =>
    Parse.Cloud.run('resendInvite', addContactObj).then(resendInviteResult =>
      _mapParseContactToContact(resendInviteResult),
    ),
  );
}

export function parseReportHandler(action, params) {
  return requestHandler(() =>
    Parse.Cloud.run('reportHandler', {
      action,
      params,
    }).then(reportHandlerResult => _mapParseReportToReport(reportHandlerResult)),
  );
}

export function becomeUser(token) {
  return Parse.User.become(token)
    .then(() => _mapParseUserToUser(Parse.User.current()))
    .catch(() => [errorMessage.UNKNOWN]);
}

export function parseLogin(username, password) {
  return requestHandler(() =>
    Parse.Cloud.run('logIn', {
      username,
      password,
    }).then(response => becomeUser(response.sessionToken)),
  );
}

export function oAuthConnectBooking() {
  return requestHandler(() => Parse.Cloud.run('syncBookings', {}));
}

export function oAuthConnectPropertyPhoto(propertyId) {
  return requestHandler(() =>
    Parse.Cloud.run('syncProperty', {
      propertyId,
    }),
  );
}

export function oAuthConnectPropertyList(partner, partnerUserId) {
  return requestHandler(() =>
    Parse.Cloud.run('syncProperties', {
      partner,
      partnerUserId,
    }).then(success => success.properties),
  );
}

export function oAuthConnectProfile(partner, code) {
  return requestHandler(() =>
    Parse.Cloud.run('connectPartner', {
      partner,
      code,
    }).then(success => ({
      userId: success.partnerAccount.partnerUserId,
    })),
  );
}

export function parseSendToMobileHost(phoneCountryCode, phoneRegionalNumber) {
  return requestHandler(() =>
    Parse.Cloud.run('sendToMobileHost', {
      phoneCountryCode,
      phoneRegionalNumber,
    }),
  );
}

export function parseHostSignUp(
  firstName,
  lastName,
  email,
  password,
  language,
  campaign,
  referrer,
  content,
  medium,
  source,
  term,
) {
  return requestHandler(() =>
    Parse.Cloud.run('signUpNewUser', {
      firstName,
      lastName,
      password,
      email,
      role: 1,
      language,
      campaign,
      referrer,
      content,
      medium,
      source,
      term,
    }).then(response => becomeUser(response.sessionToken)),
  );
}

export function isLoggedIn() {
  return Parse.User.current() !== null;
}

export function createSubscriptionFromToken({
  companyName,
  quantity,
  planCode,
  subscriptionId,
  token,
  email,
  couponCode,
  vatNumber,
}) {
  return requestHandler(() =>
    Parse.Cloud.run('createSubscriptionFromToken', {
      companyName,
      quantity,
      planCode,
      couponCode,
      subscriptionId,
      token,
      email: email?.trim(),
      vatNumber,
    }),
  );
}

export function updateBillingInfo({
  firstName,
  lastName,
  address1,
  address2,
  city,
  state,
  country,
  zip,
  companyName,
  vatNumber,
  email,
}) {
  return requestHandler(() =>
    Parse.Cloud.run('updateBillingInfo', {
      firstName: firstName?.trim(),
      lastName: lastName?.trim(),
      address1,
      address2,
      city,
      state,
      country,
      zip,
      companyName,
      vatNumber,
      email: email?.trim(),
    }),
  );
}

export function getBillingInfoForForm() {
  return requestHandler(() => Parse.Cloud.run('getBillingInfoForForm', {}));
}

export function getVerificationPhotoFeedback(jobRequestId, pictureUrl) {
  return requestHandler(() =>
    Parse.Cloud.run('getVerificationPhotosFeedback', {
      jobRequestId,
      pictureUrl,
    }).then(verificationPhotoFeedbackResult =>
      map(verificationPhotoFeedbackResult, _mapParsePhotoFeedbackToPhotoFeedback),
    ),
  );
}

export function resolveNestedArray(target) {
  if (isArray(target)) {
    return resolveNestedArray(target[0]);
  }
  return target;
}

export function notifyVerificationPhotoFeedback(jobRequestId, pictureUrl, enitity, action, value, adminUpdate) {
  return requestHandler(() =>
    Parse.Cloud.run('notifyVerificationPhotosFeedback', {
      jobRequestId,
      pictureUrl,
      [enitity]: { [action]: value },
      adminUpdate,
    }),
  )
    .then(notificationResult => {
      const resolvedRes = resolveNestedArray(notificationResult);
      if (!resolvedRes || !isObject(resolvedRes)) {
        throw { message: 'unknown' }; // eslint-disable-line
      }
      if (resolvedRes.error) {
        throw resolvedRes.error;
      }
      return _mapParsePhotoFeedbackToPhotoFeedback(resolvedRes);
    })
    .catch(err => {
      const errorRes = { ...err };
      if (err.latestVersion) {
        errorRes.latestVersion = _mapParsePhotoFeedbackToPhotoFeedback(errorRes.latestVersion);
      }
      throw errorRes;
    });
}

export function generateAlexaAccessKeys() {
  return requestHandler(() => Parse.Cloud.run('generateAlexaAccessKeys', {}));
}

export function checkAlexaDevice(deviceSerialNumber) {
  return requestHandler(() => Parse.Cloud.run('checkAlexaDevice', { deviceSerialNumber }));
}

export function setupDevice(deviceArn, propertyId) {
  return requestHandler(() => Parse.Cloud.run('setupDevice', { deviceArn, propertyId }));
}

export function triggerLiveQuery(eventId) {
  return requestHandler(() => Parse.Cloud.run('triggerEventLiveQueryUpdate', { objectId: eventId }));
}

export async function fetchBrandingConfig(utmSource) {
  try {
    const res = await axios.get(config.BRANDING_CONFIG_API, {
      params: {
        utm_source: utmSource,
      },
    });
    if (res.data.error) {
      throw res.data.error;
    }
    return res.data;
  } catch (error) {
    log.info(`fetchBrandingConfig API not found ${error}`);
    error.apiError = true;
    return error;
  }
}

export async function brandingPilotSignUpNewUser(utmSource, externalSessionToken) {
  try {
    const res = await axios.post(config.BRANDING_PILOT_SIGNUP_NEW_USER, {
      externalSessionToken,
      utm_source: utmSource,
    });
    if (res.data.errorCode) {
      throw res.data;
    }
    const userObject = await becomeUser(res.data.session);
    restartIntercom(res.data.objectId);
    return { branchIOLink: res.data.branchIOLink, userObject };
  } catch (error) {
    log.error(`brandingPilotSignUpNewUser API ${JSON.stringify(error)}`);
    const returnError = { error, apiError: true };
    return returnError;
  }
}
