import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  PropertyImage,
  DividerLine,
  Modal,
  ModalError,
  Input,
  Title3,
  SpaceBase,
  SpaceTiny,
  SpaceMedium,
  Button,
  LoadingSplash,
  SpacedHeadline,
} from '@properly/components';
import {
  splitCountryCode,
  countryPhoneOptions,
  pictureSizes,
  generatePictureUrl,
  isValidEmail,
  isValidPhone,
} from '@properly/common';
import pick from 'lodash/pick';
import t from '@properly/localization';
import classNames from 'classnames/bind';
import { PagePadding } from '../../../../components/PageElements/index';
import styles from '../components/accountStyles.module.css';
import * as selectorsAccount from '../state/AccountSelectors';
import {
  triggerSnapShotUser,
  updateTmpUser,
  updateUserSaga,
  setUpdateUserState,
  setState,
  phoneVerifySaga,
  uploadProfilePic,
} from '../state/AccountActions';
import { setGlobalLoadingState } from '../../../../actions/globalActions';
import UploadArea from '../../../../components/UploadArea/index';
import { trackOpenSettings } from '../../../../actions/trackingEvents';
import HasPermission from '../../../../hoc/HasPermission/index';
import { AccountHeadSection } from '../../../../components/AccountElements/index';
import PhoneNumberLoader from '../../../../containers/PhoneNumberLoader/index';
import { selectLoadingState } from '../../../../selectors/globalSelector';
import { mediaUploadLoadingKey } from '../../../../dataConstants';

const cx = classNames.bind(styles);

// refactor ideas
// - get rid of cx
// - create abstraction for image upload
// - use redux form
// - outsource big verification modal

class AccountProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpload: false, // eslint-disable-line
      codeValue: '',
      validate: false,
    };
  }

  componentDidMount() {
    this.props.triggerSnapShotUser();
    trackOpenSettings();
  }

  renderName(tmpUser, validation, onChange) {
    return (
      <div>
        <SpacedHeadline>{t('account.name')}</SpacedHeadline>
        <Input
          isFirst
          isLast
          error={validation.firstName}
          value={tmpUser.firstName}
          styleOverwrite={{ backgroundColor: '#FFF' }}
          type="text"
          onChange={e => onChange('firstName', e.target.value)}
          placeholder={t('account.first_name')}
        />
        <SpaceTiny />
        <Input
          isFirst
          isLast
          styleOverwrite={{ backgroundColor: '#FFF' }}
          error={validation.lastName}
          value={tmpUser.lastName}
          type="text"
          onChange={e => onChange('lastName', e.target.value)}
          placeholder={t('account.last_name')}
        />
      </div>
    );
  }

  renderContact(tmpUser, validation, onChange) {
    return (
      <PhoneNumberLoader currentPhoneCode={tmpUser.phoneCountryCode} setter={val => onChange('phoneCountryCode', val)}>
        {() => (
          <div>
            <SpacedHeadline>{t('account.contact')}</SpacedHeadline>
            <Input
              isFirst
              isLast
              value={tmpUser.email}
              error={validation.email}
              styleOverwrite={{ backgroundColor: '#FFF' }}
              type="text"
              onChange={e => onChange('email', e.target.value)}
              placeholder={t('account.email')}
            />
            <SpaceTiny />
            <Input.TwoSplit type="bigger">
              <Input
                twoSplit="leftsmall"
                type="select"
                style={{ color: '#36ACB2', backgroundColor: '#FFF' }}
                options={countryPhoneOptions}
                onChange={e => onChange('phoneCountryCode', e.target.value)}
                error={false}
                isFirst
                isLast
                value={tmpUser.phoneCountryCode}
                transformSelectTitle={splitCountryCode}
                styleOverwrite={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, backgroundColor: '#FFF' }}
              />
              <Input
                twoSplit="rightbig"
                type="text"
                onChange={e => onChange('phoneRegionalNumber', e.target.value)}
                placeholder={t('account.phone')}
                value={tmpUser.phoneRegionalNumber}
                isFirst
                isLast
                styleOverwrite={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, backgroundColor: '#FFF' }}
              />
            </Input.TwoSplit>
          </div>
        )}
      </PhoneNumberLoader>
    );
  }

  renderBio(tmpUser, validation, onChange) {
    return (
      <div>
        <SpacedHeadline>{t('account.bio')}</SpacedHeadline>
        <Input
          onChange={e => onChange(e.target.value)}
          isLast
          isFirst
          textAreaHeight={336}
          value={tmpUser.bio}
          type="textarea"
          placeholder={t('account.bio_info')}
        />
      </div>
    );
  }

  setModal(modal, val) {
    this.setState({ [modal]: val });
  }

  setImage = data => {
    if (data && data[0]) {
      this.props.uploadProfilePic(data[0]);
    }
  };

  getImageState(file, userPic) {
    if (file) return { text: '', state: 'loading', image: file.preview };
    if (userPic) return { text: t('account.change_image'), state: 'set', image: this.genImage(userPic) };
    return { text: t('account.set_image'), state: 'dropable', image: '' };
  }

  setCodeValue(val) {
    this.setState({ codeValue: val });
  }

  genImage(imageId) {
    if (!imageId) return '';
    return generatePictureUrl(imageId, pictureSizes.PROFILE_PICTURE);
  }

  get generalStatesJS() {
    return this.props.generalStates.toJS();
  }

  showUpload(val) {
    this.setState({ showUpload: val }); // eslint-disable-line
  }

  get validation() {
    const { firstName, lastName, email, phoneRegionalNumber } = this.props.tmpUser;
    const canShow = obj => (this.state.validate ? obj : pick(obj, ['valid']));

    const errors = { valid: true };
    if (!firstName) {
      errors.firstName = true;
    }
    if (!lastName) {
      errors.lastName = true;
    }
    if (!email || !isValidEmail(email)) {
      errors.email = true;
    }
    if (phoneRegionalNumber && !isValidPhone(phoneRegionalNumber)) {
      errors.phoneRegionalNumber = true;
    }
    if (errors.email || errors.lastName || errors.firstName || errors.phoneRegionalNumber) {
      errors.valid = false;
    }

    return canShow(errors);
  }

  updateOrValidate() {
    if (this.validation.valid) {
      this.props.updateUserSaga(this.props.tmpUser);
    } else {
      this.setState({ validate: true });
    }
  }

  closeVerifyModal = () => {
    this.setCodeValue('');
    this.props.setState('phoneVerify', 0);
  };

  sendVerifyModal = () => {
    this.props.phoneVerifySaga(`${this.state.codeValue}`);
    this.setCodeValue('');
  };

  render() {
    const { updateUserState, imageUploadErrored, setGlobalLoadingState: setGlobalLoadingAction } = this.props;

    if (!this.props.tmpUser) return <LoadingSplash />;
    const imageState = this.getImageState(this.generalStatesJS.imageFile, this.props.tmpUser.pictureUrl);

    return (
      <PagePadding type="absolute-scroll">
        <AccountHeadSection>
          <Title3 type="nomargin">{t('account.profile')}</Title3>
          <DividerLine type={['bottom']} />
        </AccountHeadSection>
        <SpaceBase />
        <div className={cx('account__flex')}>
          <div className={cx('account__flex-1')}>
            {this.renderName(this.props.tmpUser, this.validation, (key, val) =>
              this.props.updateTmpUser({
                [key]: val,
              }),
            )}
            <HasPermission
              hasAccessFormatter={hasAccessRes => !hasAccessRes.isStandalone}
              renderWithPermission={hasAccessRes => (
                <div>
                  <SpacedHeadline>{t('account.team')}</SpacedHeadline>
                  <Input
                    isFirst
                    isLast
                    disabled
                    value={hasAccessRes.teamName}
                    styleOverwrite={{ backgroundColor: '#FFF' }}
                    type="text"
                  />
                </div>
              )}
            />
            <SpaceBase />
            {this.renderContact(this.props.tmpUser, this.validation, (key, val) =>
              this.props.updateTmpUser({
                [key]: val,
              }),
            )}
            <SpaceBase />
            {this.renderBio(this.props.tmpUser, this.validation, val =>
              this.props.updateTmpUser({
                bio: val,
              }),
            )}
            <SpaceMedium />
            <Button
              types={['type-full-primary', 'size-large']}
              fakeDisable={!this.validation.valid}
              onClick={() => this.updateOrValidate()}
            >
              {t('account.save_changes')}
            </Button>
          </div>
          <div className={cx('account__flex-1', 'account__flex-end')}>
            <div
              onMouseEnter={() => this.showUpload(true)}
              onMouseLeave={() => this.showUpload(false)}
              className={cx('account__profilepic')}
            >
              <PropertyImage type={['image--round']} image={imageState.image}>
                <UploadArea
                  type="small"
                  text={imageState.text}
                  onDropAccepted={this.setImage}
                  state={imageState.state}
                  imageUploadErrored={imageUploadErrored}
                  setGlobalLoadingAction={setGlobalLoadingAction}
                />
              </PropertyImage>
            </div>
          </div>
        </div>
        <Modal type="loading" show={this.props.updateUserState === 1} />
        <ModalError
          message1={t('account.edit_fail_account_head')}
          // TODO: Define 'updateUserState' multiple states in constants in '@properly/config'. eg: USER_STATE_ERROR = 2
          message2={updateUserState === 2 ? t('account.edit_fail_account') : t('account.edit_fail_phone_registered')}
          show={updateUserState === 2 || updateUserState === 2.1}
          onClose={() => this.props.setUpdateUserState(0)}
          height={updateUserState === 2.1 && 172}
        />
        <Modal
          id="account-profile"
          height={240}
          onClose={() => this.props.setState('phoneVerify', 0)}
          onClick={() => this.props.setState('phoneVerify', 0)}
          show={
            this.generalStatesJS.phoneVerify === 1 ||
            this.generalStatesJS.phoneVerify === 2 ||
            this.generalStatesJS.phoneVerify === 3
          }
          centerContent
        >
          <div style={{ height: '100%' }}>
            {(this.generalStatesJS.phoneVerify === 1 || this.generalStatesJS.phoneVerify === 3) && (
              <div>
                <Modal.H1>{t('account.verify_phone')}</Modal.H1>
                <Modal.Text>{t('account.enter_code')}</Modal.Text>
                <SpaceBase />
                <Input
                  isFirst
                  isLast
                  value={this.state.codeValue}
                  type="text"
                  onChange={e => this.setCodeValue(e.target.value)}
                  placeholder={t('account.enter_code_short')}
                />
                {this.generalStatesJS.phoneVerify === 3 && <Input.ErrorMsg>{t('account.code_invalid')}</Input.ErrorMsg>}
                <Modal.Options>
                  <Modal.Cancel onClick={this.closeVerifyModal} />
                  <Modal.Ok onClick={this.sendVerifyModal} />
                </Modal.Options>
              </div>
            )}
            {(this.generalStatesJS.phoneVerify === 2 || this.generalStatesJS.phoneVerify === 0) && (
              <div style={{ height: '100%' }}>
                <LoadingSplash static />
              </div>
            )}
          </div>
        </Modal>
      </PagePadding>
    );
  }
}

function mapStateToProps() {
  return (state, props) => ({
    tmpUser: selectorsAccount.selectTmpUser()(state, props),
    updateUserState: selectorsAccount.selectUpdateUserState()(state, props),
    generalStates: selectorsAccount.selectStates()(state, props),
    imageUploadErrored: selectLoadingState(mediaUploadLoadingKey)(state)?.isErrored,
  });
}

export default connect(mapStateToProps, {
  triggerSnapShotUser,
  updateTmpUser,
  updateUserSaga,
  setUpdateUserState,
  setState,
  phoneVerifySaga,
  uploadProfilePic,
  setGlobalLoadingState,
})(AccountProfileContainer);
