export default class Ical {
  constructor(objectId, title, property, ownerRole, propertyRole, link, partner) {
    this.objectId = objectId;
    this.title = title;
    this.property = property;
    this.ownerRole = ownerRole;
    this.propertyRole = propertyRole;
    this.link = link;
    this.partner = partner;
  }
}
