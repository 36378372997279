import moment from 'moment-timezone';
import t from '@properly/localization';
import log from 'loglevel';
import uniq from 'lodash/uniq';
import memoize from 'lodash/memoize';
import once from 'lodash/once';
import times from 'lodash/times';
import map from 'lodash/map';

export const getDefaultCalendarStart = once(() =>
  moment()
    .subtract(1, 'd')
    .startOf('d')
    .toDate(),
);

export const getDefaultCalendarEnd = once(() =>
  moment(getDefaultCalendarStart())
    .add(6, 'd')
    .endOf('d')
    .toDate(),
);

export const getDefaultCalendarStartLimit = once(() =>
  moment(getDefaultCalendarStart())
    .subtract(12, 'w')
    .startOf('d')
    .toDate(),
);

const BUCKET_ADDED_SIZE = 1;

export const isToday = (() => {
  let todayMoment = moment();
  const isTodayFn = (day, format) => moment(day, [format]).isSame(todayMoment, 'day');
  let lastCache = Date.now();
  let memoizedIsToday = memoize(isTodayFn);
  return (day, format) => {
    try {
      if (Date.now() - lastCache > 5 * 60 * 1000) {
        lastCache = Date.now();
        todayMoment = moment();
        memoizedIsToday = memoize(isTodayFn);
      }
      return memoizedIsToday(day, format);
    } catch (err) {
      log.error('Error calculating date isToday', day, format);
      log.error('Error calculating date isToday', err);
      return false;
    }
  };
})();

export const isWeekend = (() => {
  const isWeekendFn = (day, format) =>
    moment(day, [format]).format('dddd') === 'Sunday' || moment(day, [format]).format('dddd') === 'Saturday';
  let lastCache = Date.now();
  let memoizedIsWeekend = memoize(isWeekendFn);
  return (day, format) => {
    try {
      if (Date.now() - lastCache > 5 * 60 * 1000) {
        lastCache = Date.now();
        memoizedIsWeekend = memoize(isWeekendFn);
      }
      return memoizedIsWeekend(day, format);
    } catch (err) {
      log.error('Error calculating date isWeekend', day, format);
      log.error('Error calculating date isWeekend', err);
      return false;
    }
  };
})();

export const generateDays = memoize((startDate, endDate) => {
  const dateFormat = 'YYYYMMDD';
  const dates = [];
  let mutatedStartDate = moment(startDate);
  const finalEndDate = moment(endDate).toDate();
  finalEndDate.setHours(0, 0, 0, 0); // need to revert 23:59:59
  dates.push(mutatedStartDate.format(dateFormat)); // first

  let limit = 0;

  while (mutatedStartDate.format(dateFormat) !== moment(finalEndDate).format(dateFormat)) {
    mutatedStartDate = moment(mutatedStartDate).add(1, 'days');
    dates.push(mutatedStartDate.format(dateFormat));

    // Ensure we never loop forever
    limit += 1;
    if (limit > 365) {
      return dates;
    }
  }
  return dates;
});

export const getDateBucket = (() => {
  const dateFormat = 'YYYYMMDD';
  const getDateOverscrollStart = once(() => moment(getDefaultCalendarStart()).subtract(BUCKET_ADDED_SIZE, 'days'));
  const getDateOverscrollEnd = once(() => moment(getDefaultCalendarEnd()).add(BUCKET_ADDED_SIZE, 'days'));
  const getDateOverscrollColumnCount = once(() => getDateOverscrollEnd().diff(getDateOverscrollStart(), 'days'));

  return memoize(date => {
    const dateOverscrollStart = getDateOverscrollStart();
    const dateOverscrollColumnCount = getDateOverscrollColumnCount();

    const dateMoment = moment(date);
    const diff = dateMoment.diff(getDefaultCalendarStart(), 'd');

    const overScanBucketId = Math.floor(diff / dateOverscrollColumnCount);
    const overScanBucket = overScanBucketId * dateOverscrollColumnCount;
    const overScanBucketStart =
      overScanBucket === 0 ? dateOverscrollStart : dateOverscrollStart.clone().add(overScanBucket, 'd');

    const overScanBucketEnd =
      overScanBucket === 0
        ? dateOverscrollStart.clone().add(dateOverscrollColumnCount, 'd')
        : dateOverscrollStart.clone().add(dateOverscrollColumnCount + overScanBucket, 'd');

    const stringStart = overScanBucketStart.format(dateFormat);
    const stringEnd = overScanBucketEnd.format(dateFormat);

    return {
      id: overScanBucketId,
      diff: overScanBucket,
      start: stringStart,
      end: stringEnd,
      bucket: `${stringStart}-${stringEnd}`,
    };
  });
})();

export const getDateBucketRange = (() => {
  const getDateOverscrollStart = once(() => moment(getDefaultCalendarStart()).subtract(1, 'days'));
  const getDateOverscrollEnd = once(() => moment(getDefaultCalendarEnd()).add(1, 'days'));
  const getDateOverscrollColumnCount = once(() => getDateOverscrollEnd().diff(getDateOverscrollStart(), 'days'));

  return (start, end) => {
    // while difference is big, keep looping

    const startMoment = moment(start);
    const endMoment = moment(end || start);

    const difference = endMoment.diff(startMoment, 'days');

    if (difference <= 0) {
      return [getDateBucket(start).bucket];
    }
    const { bucket: startBucket } = getDateBucket(startMoment);
    const { bucket: endBucket } = getDateBucket(endMoment);

    if (startBucket === endBucket) {
      return [startBucket];
    }
    const buckets = [startBucket];
    let currentBucket = startBucket;
    let count = 0;
    while (count < 100 && currentBucket !== endBucket) {
      count += 1;
      const currentOffset = count * getDateOverscrollColumnCount();
      currentBucket = getDateBucket(startMoment.clone().add(currentOffset, 'days')).bucket;
      buckets.push(currentBucket);
    }

    return buckets;
  };
})();

export const generateDateBucket = (() => {
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  return memoize((start, end) => {
    const startDate = moment(start);
    const endDate = moment(end);
    const startDateOverscan = moment(start).subtract(1, 'w');
    const endDateOverscan = moment(end).add(1, 'w');
    const buckets = uniq([
      getDateBucket(startDate),
      getDateBucket(endDate),
      getDateBucket(startDateOverscan),
      getDateBucket(endDateOverscan),
    ]);
    const ids = buckets.map(({ bucket }) => bucket).filter(onlyUnique);

    return {
      dateBucketIds: ids,
    };
  });
})();

export function mapTimeToColor(minutes) {
  const minutesNumber = minutes && Number(minutes);
  if (minutesNumber <= 15) {
    return '#43A047';
  }
  if (minutesNumber > 15 && minutesNumber < 60 * 4) {
    return '#FFA726';
  }
  if (minutesNumber >= 60 * 4) {
    return '#FF5722';
  }
  return '#000';
}

export function formatMinHours(val) {
  const valNumber = Number(val);
  const hasMins = valNumber % 60 !== 0;
  const hasHours = valNumber >= 60;
  const mins = valNumber % 60;
  const hrs = Math.floor(valNumber / 60);
  const hrsPart = hrs === 1 ? `${hrs} ${t('job_request.just_hour')}` : `${hrs} ${t('job_request.just_hours')}`;
  const minsPart = t('job_request.just_minutes', {
    mins: Math.floor(mins),
  });
  if (hasMins && hasHours) {
    return `${hrsPart} ${minsPart}`;
  }
  if (hasMins) {
    return minsPart;
  }
  if (hasHours) {
    return hrsPart;
  }
  if (valNumber === 0) {
    return t('job_request.just_minutes', {
      mins: 0,
    });
  }
  return '';
}

export function generateOptionsTime(stepper) {
  const array = times((60 * 24) / stepper, val => {
    const formatString = userHas24hClock() ? 'HH:mm' : 'hh:mm A';
    const min = val * stepper;
    return {
      value: `${min}`,
      title: addMinToDate(undefined, min).format(formatString),
    };
  });

  return array;
}

export function generateOptionsDuration(stepMin, maxMin) {
  const array = times(maxMin / stepMin, val => stepMin * (val + 1));
  return map(array, val => ({
    value: `${val}`,
    title: formatMinHours(val),
  }));
}

export function userHas24hClock() {
  const nowString = moment().format('LT');
  return nowString.indexOf('AM') === -1 && nowString.indexOf('PM') === -1;
}

export function addMinToDate(date, min) {
  return moment(date)
    .clone()
    .hours(Math.floor(min / 60))
    .minutes(min % 60);
}

export function getMomentZone(timezone) {
  return moment.tz.zone(timezone);
}

export function getTimezoneOffset(targetTimeZone, currentTimeZone = moment().tz() || moment.tz.guess()) {
  // Update ISO Start Time to be in the property timezone

  if (targetTimeZone && currentTimeZone !== targetTimeZone) {
    const currentTime = moment();

    const currentZone = getMomentZone(currentTimeZone);
    const currentOffset = currentZone.utcOffset(currentTime);

    const targetZone = getMomentZone(targetTimeZone);
    const targetOffset = targetZone.utcOffset(currentTime);

    return targetOffset - currentOffset;
  }
  return 0;
}

// fix for moment dates on wrong years (parse problem)
export const fixMomentDates = date => {
  const momentFormattedDate = moment(date).millisecond(0);
  if (momentFormattedDate.year() < 2000) {
    const dateString = date._i; // eslint-disable-line
    return moment(dateString, 'YYYY-MM-DD[T]HH:mm:ss.zzz[Z]');
  }
  return momentFormattedDate;
};

export const isWithinRange = ({ rangeStartDate, rangeEndDate, startDate, endDate }) => {
  const isStartWithinRange = moment(startDate).isSameOrBefore(moment(rangeEndDate));
  const isEndWithinRange = moment(endDate).isSameOrAfter(moment(rangeStartDate));

  return isStartWithinRange && isEndWithinRange;
};

export const prettyPrintDuration = duration => {
  const durationNumber = Number.isNaN(Number(duration)) ? 0 : Number(duration);
  const durationHours = Math.floor(durationNumber / 60);
  const durationMinutes = durationNumber % 60;
  const prettyDuration = durationHours
    ? t('job_request.hours_minutes', {
        hrs: durationHours,
        mins: durationMinutes,
      })
    : t('job_request.just_minutes', { mins: durationMinutes || 0 });

  return prettyDuration;
};

export const timezonesAreEqual = (timeZoneA, timeZoneB) =>
  timeZoneA === timeZoneB || moment.tz(timeZoneA).format('Z') === moment.tz(timeZoneB).format('Z');

export const roundNext15Min = date => {
  if (!date) {
    return date;
  }

  const momentDate = moment(date).clone();

  if (!momentDate.isValid()) {
    return date;
  }

  let intervals = Math.floor(momentDate.minutes() / 15);
  if (momentDate.minutes() % 15 !== 0) {
    intervals += 1;
  }
  if (intervals === 4) {
    momentDate.add('hours', 1);
    intervals = 0;
  }
  momentDate.minutes(intervals * 15);
  momentDate.seconds(0);
  return momentDate;
};

export const fixJobRequestStartTime = (time, prefilledJobRequestStartTime) => {
  // this block computes the default checkout time
  const computedTimeResult = time.clone();
  computedTimeResult.set({
    hour: prefilledJobRequestStartTime.hour,
    minute: prefilledJobRequestStartTime.minute,
  });

  return computedTimeResult;
};

export const doesJobRequestDurationFit = ({ startTime, endTime, nowTime, duration }) => {
  const diffStartEndTime = endTime && moment.duration(endTime.diff(startTime));
  const diffStartEndMinutes = (endTime && diffStartEndTime.asMinutes()) || 0;

  const diffCurrentTimeAndEndTime = endTime && moment.duration(endTime.diff(nowTime));
  const diffCurrentTimeAndEndTimeMinutes = (endTime && diffCurrentTimeAndEndTime.asMinutes()) || 0;

  return +duration <= Math.round(diffCurrentTimeAndEndTimeMinutes) && +duration <= Math.round(diffStartEndMinutes);
};
