import React from 'react';
import { DynamicFieldInput } from '@properly/components';
import HasPermission from '../../../../hoc/HasPermission';
import PropertyDetailContainerBlock from '../../../../containers/PropertyDetailContainerBlock/index';
import InfoMessage from '../../../../components/InfoMessage/index';
import Sidebar from '../../../../components/Sidebar/index';

import {
  JobRequestTextSub,
  JobRequestContentWrap,
  JobRequestSpacerWrap,
} from '../../../../components/JobRequestElements/index';

function PropertyDetails({ finalPropertyId, setSliderSaga, otherAttributes, infoTextMsg }) {
  const openPictures = pictures => {
    const mappedPictures = (pictures || []).map(image => ({ image }));
    setSliderSaga(true, mappedPictures);
  };

  return (
    <JobRequestContentWrap id="job-request-property-details-form">
      <Sidebar.List>
        <Sidebar.ListInner>
          <Sidebar.ListInnerInner enableOverflow>
            <JobRequestSpacerWrap left="24px" top="12px" bottom="12px" right="24px">
              <HasPermission
                meta={{ propertyId: finalPropertyId }}
                hasAccessFormatter={hasAccessRes => hasAccessRes.canEditDeleteProperty}
                render={hasPermission => (
                  <PropertyDetailContainerBlock isSingleColumn canEdit={hasPermission} propertyId={finalPropertyId} />
                )}
              />
              <DynamicFieldInput openPictures={openPictures} otherAttributes={otherAttributes} />
            </JobRequestSpacerWrap>
          </Sidebar.ListInnerInner>
        </Sidebar.ListInner>
      </Sidebar.List>
      <InfoMessage text={infoTextMsg} isPrompt isVcenter isHcenter icon="light" color="white" />
    </JobRequestContentWrap>
  );
}
PropertyDetails.content = ({ propertyDetailTags }) => <JobRequestTextSub>{propertyDetailTags}</JobRequestTextSub>;

export default PropertyDetails;
