import { takeEvery } from 'redux-saga';
import log from 'loglevel';
import { fork } from 'redux-saga/effects';
import * as types from '../../../../types/index';

function* redirectToProviderSaga(action) {
  log.info('AccountSagas redirectToProviderSaga - Is first connect', action.data);
  const redirectFunc = () => {
    window.location.href = action.data.stripeOAuthLink;
  };
  yield redirectFunc();
}

function* saga() {
  yield fork(takeEvery, types.STRIPE_SP_REDIRECT_PROVIDER_SAGA, redirectToProviderSaga);
}

export default saga;
