export default class ConnectedAccountClass {
  constructor({ connectionSettings, icon, id, label, oAuthLink, partner, proRequired }) {
    this.connectionSettings = connectionSettings;
    this.icon = icon;
    this.id = id;
    this.label = label;
    this.oAuthLink = oAuthLink;
    this.partner = partner;
    this.proRequired = proRequired;
  }
}
