import * as types from '../../types';

export function jobRequestLoadPhoto({ propertyId, page, pagination }) {
  return {
    type: types.JOB_REQUEST_LOAD_PHOTO,
    propertyId,
    page,
    pagination,
  };
}
