import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { mapProps, compose } from 'recompose';

import pick from 'lodash/pick';
import { mapToMainState } from './permissionhelper';
import NoProperties from '../../../components/NoProperties';
import Sidebar from '../../../components/Sidebar';

class PermissionModalContentInner extends PureComponent {
  static propTypes = {};

  resolveComponent() {
    const Component = this.props.activeSectionData && this.props.activeSectionData.sectionMainComponent;
    if (this.props.submitIsError) {
      return <NoProperties type="jobrequesterror" />;
    }
    if (Component) return <Component />;
    return null;
  }

  render() {
    return (
      <Sidebar.List>
        <Sidebar.ListInner>
          <Sidebar.ListInnerInner>{this.resolveComponent()}</Sidebar.ListInnerInner>
        </Sidebar.ListInner>
      </Sidebar.List>
    );
  }
}

export default compose(
  connect(
    mapToMainState,
    {},
  ),
  mapProps(props => pick(props, ['activeSectionData', 'submitIsError'])),
)(PermissionModalContentInner);
