import React from 'react';
import PropTypes from 'prop-types';
import { countryToDialCode, getCurrentCountryByIp } from '@properly/common';

class PhoneNumberLoader extends React.Component {
  static propTypes = {
    currentPhoneCode: PropTypes.string,
    setter: PropTypes.func.isRequired,
  };

  update() {
    if (!this.props.currentPhoneCode) {
      getCurrentCountryByIp()
        .then(country => {
          this.props.setter(countryToDialCode(country, '+1'));
        })
        .catch(() => {
          this.props.setter(countryToDialCode(undefined, '+1'));
        });
    }
  }

  componentWillMount() {
    this.update();
  }

  componentWillReceiveProps() {
    this.update();
  }

  render() {
    return this.props.children();
  }
}

export default PhoneNumberLoader;
