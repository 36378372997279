import * as types from '../../../types';

export function initPermissionsSaga(meta) {
  return {
    type: types.PERMISSION_INIT,
    meta,
  };
}

export function initModalSaga(meta) {
  return {
    type: types.PERMISSION_INIT_MODAL,
    meta,
  };
}

export function setListData(name, data) {
  return {
    type: types.PERMISSION_SET_LIST_DATA,
    name,
    data,
  };
}

export function mergeListData(name, data) {
  return {
    type: types.PERMISSION_MERGE_LIST_DATA,
    name,
    data,
  };
}

export function handlePermissionActionSaga(action, meta) {
  return {
    type: types.PERMISSION_HANDLE_ACTION,
    action,
    meta,
  };
}

export function setSubmitData(data) {
  return {
    type: types.PERMISSION_SET_SUBMIT_DATA,
    data,
  };
}

export function resetSubmitData() {
  return {
    type: types.PERMISSION_RESET_SUBMIT_DATA,
  };
}
