import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import t from '@properly/localization';
import log from 'loglevel';
import { SpaceSmallMid, SpaceBase, Button, LoadingSplash } from '@properly/components';
import { cleanerStatus as CleanerStatus } from '@properly/config';
import noop from 'lodash/noop';
import keys from 'lodash/keys';
import flow from 'lodash/flow';
import values from 'lodash/values';
import last from 'lodash/last';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import orderBy from 'lodash/orderBy';
import moment from 'moment-timezone';
import JobRequestCell from '../JobRequestCell';
import styles from './index.module.css';
import JobRequestGraphql from '../../model/jobRequestGraphql';
import CleanerRequestGraphql from '../../model/cleanerRequestGraphql';
import DateLabel from '../DateLabel';

const cx = classNames.bind(styles);

const mapCleanerStatusOnJobList = status => {
  switch (status) {
    case CleanerStatus.Pending:
      return t('jobdetails.pending');
    case CleanerStatus.PendingChanges:
      return t('jobdetails.pendingChanges');
    case CleanerStatus.Declined:
      return t('jobdetails.declined');
    case CleanerStatus.DeclinedChanges:
      return t('jobdetails.declined');
    case CleanerStatus.Accepted:
      return t('jobstatus.accepted');
    case CleanerStatus.InProgress:
      return t('jobstatus.started');
    case CleanerStatus.Finished:
      return t('jobstatus.completed');
    case CleanerStatus.Viewed:
      return t('jobstatus.seen');
    case CleanerStatus.CanceledByHost:
      return t('jobdetails.canceled');
    case CleanerStatus.CanceledByCleaner:
      return t('jobdetails.canceled');
    default:
      return '';
  }
};

function renderRequests(requests, onClick, mode, contactId) {
  return map(requests, jobRequest => {
    try {
      const addWhat = {};
      let status;

      const location =
        jobRequest.getPropertyLines() && jobRequest.getPropertyLines().length
          ? jobRequest.getPropertyLines().join(', ')
          : '';

      if (mode === 'cleaner') {
        addWhat.cleaner = jobRequest.getCleanerString();
      }

      if (mode === 'property') {
        addWhat.location = location;

        const cleanerStatus = jobRequest.getCleanerStatusByContactId(jobRequest.requestedCleaners, contactId);

        status = mapCleanerStatusOnJobList(cleanerStatus);
      } else {
        status = t(jobRequest.getStatusString());
      }

      const { propertyData, scheduledStartTime, scheduledEndTime, duration } = jobRequest || {};
      const { timeZone } = propertyData || {};

      if (!timeZone) {
        log.warn('There is no timezone information for the current job request', jobRequest);
      }

      const startMoment = moment(scheduledStartTime).tz(timeZone);
      const endMoment = scheduledEndTime
        ? moment(scheduledEndTime).tz(timeZone)
        : startMoment.clone().add(duration, 'm');

      const timeFormat = 'LT';
      const dateFormat = 'L';

      const startTime = startMoment.format(timeFormat);
      const startDate = startMoment.format(dateFormat);
      const endTime = endMoment.format(timeFormat);
      const endDate = endMoment.format(dateFormat);

      const handleClick = onClick
        ? e => {
            log.info('Handle JobRequestCell click', { jobRequest }, 'event', e);
            return onClick(jobRequest, e);
          }
        : undefined;

      return (
        <JobRequestCell
          startTime={startTime}
          startDate={startDate}
          endTime={endTime}
          endDate={endDate}
          onClick={handleClick}
          status={status}
          jobRequest={jobRequest}
          {...addWhat}
          id={jobRequest.jobId}
          key={jobRequest.jobId}
        />
      );
    } catch (err) {
      log.error('Error rendering job request', err);
      log.error('Job request', jobRequest);
      return null;
    }
  });
}

function getDate(item) {
  return item.getScheduledStartTime() || item.getActualStartTime();
}

function renderPagination(reduced, onLoadMore, isLoading, isArchive) {
  const getItemsLast = obj => {
    if (!obj) {
      return {
        scheduledStartTime: moment()
          .subtract(2, 'month')
          .toDate(),
      };
    }
    return last(obj.items);
  };
  const getLastFinal = flow([values, last, getItemsLast]);
  const onLoadMoreFinal = onLoadMore ? e => onLoadMore(getLastFinal.bind(null, reduced), e) : noop;
  const txt = isArchive ? t('properties.try_to_fetch') : t('properties.load_more');
  return (
    <div>
      <SpaceBase />
      {isLoading && <LoadingSplash static />}
      {!isLoading && (
        <Button
          onClick={onLoadMoreFinal}
          types={['type-flat-primary', 'size-medium', 'fontw-normal', 'fonts-12', 'width-flex', 'height-24']}
        >
          {txt}
        </Button>
      )}
    </div>
  );
}

const JobRequestList = ({
  ascending,
  jobRequestsArray,
  isLoading,
  hasError,
  onClick,
  mode,
  onLoadMore,
  isLoadingPagination,
  hasMore,
  contactId,
}) => {
  if (isLoading) {
    return (
      <div className={cx('job-request-list__no-result')}>
        <LoadingSplash static />
      </div>
    );
  }
  if (hasError) {
    return (
      <div className={cx('job-request-list__no-result')}>
        <div>{t('properties.jobs_error_loading_l1')}</div>
        <div>{t('properties.jobs_error_loading_l2')}</div>
      </div>
    );
  }

  // changed here to from the saga layer
  const rawJobRequests = jobRequestsArray;

  const groupDateFormat = 'DDMMYYYY';

  const jobRequestsGrouped = {};

  function jobRequestsGroupedFunc() {
    rawJobRequests.forEach(item => {
      const getDateData = moment(getDate(item))
        .tz(item.getTimeZone())
        .format(groupDateFormat);

      if (getDateData in jobRequestsGrouped) {
        jobRequestsGrouped[getDateData].push(item);
      } else {
        jobRequestsGrouped[getDateData] = new Array(item);
      }
    });
  }
  jobRequestsGroupedFunc();

  const order = ascending ? 'asc' : 'desc';

  // order months
  const jobRequestKeys = orderBy(keys(jobRequestsGrouped), item => moment(item, groupDateFormat).unix(), [order]);
  const keysMapped = map(jobRequestKeys, item => jobRequestsGrouped[item]);
  const reduced = reduce(
    keysMapped,
    (total, items, key) => {
      const month = moment(getDate(items[0])).tz(items[0].getTimeZone());
      return {
        ...total,
        [key]: {
          month,
          isFutureDate: month.isAfter(),
          items: orderBy(items, item => new Date(item.scheduledStartTime).getTime(), [order]),
        },
      };
    },
    {},
  );
  // no result
  if (rawJobRequests.length === 0 && !isLoading) {
    return (
      <div>
        <div className={cx('job-request-list__no-result')}>{t('properties.no_job_results')}</div>
        {hasMore && renderPagination(reduced, onLoadMore, isLoadingPagination, true)}
      </div>
    );
  }

  const res = (
    <div>
      <SpaceSmallMid />
      {map(reduced, item => (
        <div key={item.month}>
          <SpaceSmallMid />
          <DateLabel border>{moment(item.month).format('ddd, Do MMM YYYY')}</DateLabel>
          {renderRequests(item.items, onClick, mode, contactId)}
        </div>
      ))}
    </div>
  );
  return (
    <div>
      {res}
      {hasMore && renderPagination(reduced, onLoadMore, isLoadingPagination)}
    </div>
  );
};

JobRequestList.propTypes = {
  jobRequestsArray: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.instanceOf(JobRequestGraphql)).isRequired,
    PropTypes.arrayOf(PropTypes.instanceOf(CleanerRequestGraphql)).isRequired,
  ]),
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  ascending: PropTypes.bool,
  hasMore: PropTypes.bool,
  isLoadingPagination: PropTypes.bool,
  mode: PropTypes.oneOf(['cleaner', 'property']),
  contactId: PropTypes.string,
  onClick: PropTypes.func,
  onLoadMore: PropTypes.func,
};

JobRequestList.defaultProps = {
  ascending: false,
  hasMore: false,
  isLoadingPagination: false,
  mode: 'property',
  contactId: '',
  onClick: () => {},
  onLoadMore: () => {},
};

export default JobRequestList;
