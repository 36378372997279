import { fromJS } from 'immutable';
import {
  generateDays,
  generateDateBucket,
  getDefaultCalendarStartLimit,
  getDefaultCalendarStart,
  getDefaultCalendarEnd,
  getDateBucketRange,
} from '@properly/common';
import log from 'loglevel';
import keyBy from 'lodash/keyBy';
import moment from 'moment-timezone';
import * as types from '../../../../types';

export const getInitState = () =>
  fromJS({
    dates: {
      outsideRange: false,
      outsideRangeDayCount: 0,
      startDate: getDefaultCalendarStart(),
      endDate: getDefaultCalendarEnd(),
      dates: generateDays(getDefaultCalendarStart(), getDefaultCalendarEnd()),
      ...generateDateBucket(getDefaultCalendarStart(), getDefaultCalendarEnd()),
    },

    showCanceledEvents: true,

    error: false,
    errorCount: 0,

    resetOnNextSuccess: true,

    property: {},
    propertyEvents: {},
    propertyViewportStart: 0,
    propertyViewportEnd: 10,

    propertyTotal: 0,
    propertyOrder: [],
    propertyLoaded: {},

    propertyTotalFiltered: 0,
    propertyOrderFiltered: [],
    propertyLoadedFiltered: {},

    currentEventsRequestId: null,
    eventsLoading: {},

    filters: [],
    filtersLoading: false,
    searchQuery: '',
    searchQueryLoaded: true,

    backdropOpen: false,
    backdropData: {},
    modalData: {},
  });

function isBookingEvent(event) {
  return [1, 2].indexOf(event.type) !== -1;
}

function getEventId(calendarEvent) {
  return isBookingEvent(calendarEvent) ? calendarEvent.booking.id : calendarEvent.id;
}

function getEventTypePath(currentEvent) {
  return isBookingEvent(currentEvent) ? 'bookingEvents' : 'otherEvents';
}

export default function(state = getInitState(), action = {}) {
  switch (action.type) {
    case types.GLOBAL_LOGOUT:
    case types.CALENDAR_RESET:
      return getInitState();

    case types.CALENDAR_INIT:
      return (
        state
          // We need to reset the property Events here as it is
          // possible there have been deleted events since they last visited
          .set('resetOnNextSuccess', true)
          .set('propertyLoaded', fromJS({}))
          .set('propertyLoadedFiltered', fromJS({}))
      );

    case types.CALENDAR_SET_BACKDROP:
      return state.set('backdropOpen', action.isOpen).set('backdropData', fromJS(action.data));

    case types.CALENDAR_TOGGLE_CANCELED_EVENTS_FILTER:
      return state.set('showCanceledEvents', !state.get('showCanceledEvents'));

    case types.CALENDAR_SET_MODALDATA:
      return state.set('modalData', fromJS(action.data));

    case types.CALENDAR_DELETE_LIVE_CALENDAR_EVENTS: {
      const { calendarEvents } = action;

      try {
        const propertyEvents = calendarEvents.reduce((accPropertyEventState, currentEvent) => {
          const eventId = getEventId(currentEvent);

          const currentPropertyEventStateJS =
            (accPropertyEventState &&
              accPropertyEventState.get(currentEvent.property.id) &&
              accPropertyEventState.get(currentEvent.property.id).toJS()) ||
            {};

          const flatMap = (acc, current) => [...acc, ...current];

          const matches = isBookingEvent(currentEvent)
            ? // Find all matches to bookings
              Object.keys(currentPropertyEventStateJS)
                .map(dateBucketId => {
                  const eventTypePath = getEventTypePath(currentEvent);
                  const eventMap = currentPropertyEventStateJS[dateBucketId][eventTypePath] || {};
                  const eventIds = Object.keys(eventMap);
                  const matchingEvents = eventIds.filter(id => id === eventId);
                  const path = [currentEvent.property.id, dateBucketId, eventTypePath];
                  return matchingEvents.map(matchId => ({ matchId, path }));
                })
                .reduce(flatMap, [])
            : // Find all matches to other events
              Object.keys(currentPropertyEventStateJS)
                .map(dateBucketId => {
                  const eventTypePath = getEventTypePath(currentEvent);
                  const eventDayMap = currentPropertyEventStateJS[dateBucketId][eventTypePath] || {};

                  return Object.keys(eventDayMap)
                    .map(eventDay => {
                      const eventMap = eventDayMap[eventDay];
                      const eventIds = Object.keys(eventMap);
                      const matchingEvents = eventIds.filter(id => id === eventId);
                      const path = [currentEvent.property.id, dateBucketId, eventTypePath, eventDay];
                      return matchingEvents.map(matchId => ({ matchId, path }));
                    })
                    .reduce(flatMap, []);
                })
                .reduce(flatMap, []);

          let resultState = accPropertyEventState;

          matches.forEach(({ matchId, path }) => {
            const currentEvents = resultState.getIn(path) || fromJS({});
            log.info('DELETE EVENT', matchId, path);
            const deletedEvent = currentEvents.delete(eventId);
            resultState = resultState.setIn(path, deletedEvent);
          });

          return resultState;
        }, state.get('propertyEvents'));
        return state.set('propertyEvents', propertyEvents);
      } catch (err) {
        log.error('Error in CALENDAR_DELETE_LIVE_CALENDAR_EVENTS action', err);
        return state;
      }
    }

    case types.CALENDAR_ADD_LIVE_CALENDAR_EVENTS: {
      const { calendarEvents } = action;

      const propertyEvents = calendarEvents.reduce((accPropertyEventState, currentEvent) => {
        const immutableCurrentEvent = fromJS(currentEvent);

        const startTime = isBookingEvent(currentEvent) ? currentEvent.booking.arrivalDate : currentEvent.startTime;
        const endTime = isBookingEvent(currentEvent) ? currentEvent.booking.departureDate : currentEvent.endTime;

        return getDateBucketRange(startTime, endTime).reduce((currentPropertyEvents, dateBucket) => {
          const propertyId = currentEvent.property.id;
          const eventTypePath = getEventTypePath(currentEvent);

          const dayId = String(currentEvent.days[0]);

          const mergePath = isBookingEvent(currentEvent)
            ? [propertyId, String(dateBucket), eventTypePath, getEventId(currentEvent)]
            : [propertyId, String(dateBucket), eventTypePath, dayId, getEventId(currentEvent)];

          return currentPropertyEvents.mergeIn(mergePath, immutableCurrentEvent);
        }, accPropertyEventState);
      }, state.get('propertyEvents'));

      return state.set('propertyEvents', propertyEvents);
    }

    case types.CALENDAR_SET_SEARCH_QUERY:
      return state
        .set('searchQuery', action.query)
        .set('searchQueryLoaded', false)
        .set('propertyLoadedFiltered', fromJS({}))
        .set('propertyTotalFiltered', fromJS(0))
        .set('propertyOrderFiltered', fromJS([]));

    case types.CALENDAR_SET_DATES: {
      const outsideRange = action.startDate < getDefaultCalendarStartLimit();
      const outsideRangeDayCount = outsideRange
        ? moment(getDefaultCalendarStartLimit()).diff(moment(action.startDate), 'days')
        : 0;

      return state.mergeIn(
        ['dates'],
        fromJS({
          outsideRange,
          outsideRangeDayCount,
          ...generateDateBucket(action.startDate, action.endDate),
          dates: generateDays(action.startDate, action.endDate),
          startDate: action.startDate,
          endDate: action.endDate,
        }),
      );
    }

    case types.CALENDAR_SET_EVENT_FILTERS: {
      // Ensure our calendar dates are in view

      return state
        .set('filtersLoading', true)
        .setIn(['filters'], fromJS(action.filters))
        .set('propertyLoadedFiltered', fromJS({}))
        .set('propertyTotalFiltered', fromJS(0))
        .set('propertyOrderFiltered', fromJS([]));
    }

    case types.CALENDAR_SET_PROPERTY_VIEWPORT:
      return state
        .setIn(['propertyViewportStart'], fromJS(action.overscrollStart))
        .setIn(['propertyViewportEnd'], fromJS(action.overscrollEnd));

    case types.CALENDAR_LOAD_EVENTS_REQUEST: {
      const { requestId, hasFilters, dateBucketId, offset, limit } = action;
      const loadedStateName = hasFilters ? 'propertyLoadedFiltered' : 'propertyLoaded';
      const eventsLoaded = keyBy([...new Array(limit)].map((empty, index) => offset + index));

      return state
        .set('error', false)
        .setIn(['currentEventsRequestId'], fromJS(requestId))
        .setIn(['eventsLoading', requestId], fromJS(true))
        .mergeIn([loadedStateName, dateBucketId], fromJS(eventsLoaded));
    }

    case types.CALENDAR_LOAD_EVENTS_SUCCESS: {
      const { requestId, calendarPropertyEvents, searchQuery, dateBucketId, total, hasFilters, offset } = action;

      const propertyStateName = 'property';
      const propertyEventStateName = 'propertyEvents';
      const propertyTotalStateName = hasFilters ? 'propertyTotalFiltered' : 'propertyTotal';
      const propertyOrderStateName = hasFilters ? 'propertyOrderFiltered' : 'propertyOrder';

      const shouldReset = state.get('resetOnNextSuccess');
      const eventsState = shouldReset ? fromJS({}) : state.get(propertyEventStateName);

      // Get event information
      const calendarPropertyEventsState = (calendarPropertyEvents || []).reduce((accPropertyState, currentProperty) => {
        const { events = [], id: propertyId, displayAddress, timeZone } = currentProperty;

        return events.reduce((accBooking, currentEvent) => {
          const currentEventProperty = (currentEvent && currentEvent.property) || {};
          const immutableCurrentEvent = fromJS({
            ...currentEvent,
            timeZone,
            property: { ...currentEventProperty, displayAddress },
          });

          const dayId = String(currentEvent.days[0]);
          const mergePath = isBookingEvent(currentEvent)
            ? [propertyId, String(dateBucketId), 'bookingEvents', currentEvent.booking.id]
            : [propertyId, String(dateBucketId), 'otherEvents', dayId, currentEvent.id];
          return accBooking.mergeIn(mergePath, immutableCurrentEvent);
        }, accPropertyState);
      }, eventsState);

      // Get property information
      const calendarPropertyState = (calendarPropertyEvents || []).reduce((accPropertyState, currentProperty) => {
        const propertyId = currentProperty.id;
        const { events, ...rest } = currentProperty;
        const propertyExists = typeof accPropertyState.get(propertyId) !== 'undefined';
        return propertyExists ? accPropertyState : accPropertyState.mergeIn([propertyId], fromJS(rest));
      }, state.get(propertyStateName));

      // Get property order info
      const propertyIds = calendarPropertyEvents.map(({ id }) => id);

      // Check to see if there is a change to our property ids
      let propertyOrderState = state.get(propertyOrderStateName);
      const alreadyHasProperties = propertyOrderState.get(offset) === propertyIds[0];
      if (!alreadyHasProperties) {
        const currentPropertyOrder = state.get(propertyOrderStateName).toJS();
        const getFiller = () => {
          const count = propertyOrderState.count();
          if (count < offset) {
            return [...new Array(offset - count)];
          }
          return [];
        };
        propertyOrderState = fromJS([
          ...currentPropertyOrder.slice(0, offset),
          ...getFiller(),
          ...propertyIds,
          ...currentPropertyOrder.slice(offset).filter(id => !!id),
        ]);
      }

      return state
        .set('error', false)
        .set('errorCount', 0)
        .set('resetOnNextSuccess', false)
        .setIn([propertyTotalStateName], fromJS(total))
        .setIn([propertyEventStateName], calendarPropertyEventsState)
        .setIn([propertyStateName], calendarPropertyState)
        .setIn([propertyOrderStateName], propertyOrderState)
        .set('searchQueryLoaded', searchQuery === state.get('searchQuery'))
        .set('filtersLoading', false)
        .setIn(['eventsLoading', requestId], fromJS(false));
    }

    case types.CALENDAR_LOAD_EVENTS_FAILURE: {
      const errorCount = state.get('errorCount');
      const newCount = (errorCount || 0) + 1;
      return getInitState()
        .set('error', true)
        .set('errorCount', newCount);
    }

    case types.CALENDAR_LOAD_EVENTS_FAILURE_RESET: {
      return (
        getInitState()
          // We need to reset the property Events here as it is
          // possible there have been deleted events since they last visited
          .set('resetOnNextSuccess', true)
          .set('propertyLoaded', fromJS({}))
          .set('propertyLoadedFiltered', fromJS({}))
          .set('error', false)
          .set('errorCount', 0)
      );
    }

    default:
      return state;
  }
}
