import gql from 'graphql-tag';

export const MUTATION_JOB_REQUEST = gql`
  mutation JobRequestEvent($input: JobRequestEventInput!) {
    JobRequestEvent(input: $input) {
      JobRequestEventResponse {
        id
        transactionId
        status
      }
      clientMutationId
    }
  }
`;

export const UPDATE_PROBLEM = gql`
  mutation UpdateProblem($input: UpdateProblemInput!) {
    UpdateProblem(input: $input) {
      UpdateProblemEventResponse {
        id
        transactionId
        appliedOn
        status
        name
      }
    }
  }
`;

export const ADD_COST = gql`
  mutation ReportCost($input: ReportCostInput!) {
    ReportCost(input: $input) {
      JobEventResponse {
        id
        status
        transactionId
        appliedOn
      }
    }
  }
`;
