import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import t from '@properly/localization';
import { List, AutoSizer } from 'react-virtualized';
import { SearchSelect, SpacedHeadline, DividerLine, SpaceTiny, Input } from '@properly/components';
import map from 'lodash/map';
import filter from 'lodash/filter';
import keys from 'lodash/keys';
import keyBy from 'lodash/keyBy';
import chunk from 'lodash/chunk';
import classNames from 'classnames/bind';
import LoadingSplash from '@properly/components/lib/components/dumb/LoadingSplash';
import styles from '../components/checklistsStyles.module.css';
import ChecklistOverlay from '../../../../components/ChecklistOverlay/index';
import ChecklistHeader from '../../../../components/ChecklistHeader/index';
import PropertyChecklistClone from '../../../../components/PropertyChecklistClone/index';
import { filterPropertySidebar, rowRenderMapper } from '../../../../helper/herbert';
import hasAccess from '../../../../hoc/HasPermission/hasAccess';

const cx = classNames.bind(styles);

export default class ChecklistCloneContainer extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    navHidden: PropTypes.bool,
    isOpen: PropTypes.bool,

    checklistId: PropTypes.string,

    checklistTitle: PropTypes.string.isRequired,
    selectedChecklists: PropTypes.shape({}).isRequired,

    onClose: PropTypes.func,
  };

  static defaultProps = {
    isLoading: false,
    isSubmitting: false,
    navHidden: false,
    isOpen: true,

    checklistId: null,

    onClose: () => {},
  };

  componentWillMount = () => {
    const { loadChecklists } = this.props;
    if (typeof loadChecklists === 'function') {
      loadChecklists();
    }
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.isSubmitting && !nextProps.isSubmitting) {
      const { push, redirectOnSuccessRoute } = this.props;
      if (!!redirectOnSuccessRoute && push) {
        push(redirectOnSuccessRoute);
      }
    }
  };

  onClose = () => {
    this.props.setCloneChecklist({
      checklistTitle: '',
      isOpen: false,
      mode: 0,
      selectedChecklistId: undefined,
      selected: {},
    });
    this.setSearchQuery('');
    const { onClose } = this.props;
    onClose();
  };

  onDone = () => {
    const { checklistId, checklistTitle, selectedChecklists } = this.props;
    this.props.multiCloneSaga(keys(selectedChecklists), checklistId, checklistTitle);
  };

  toggleSingleProperty = id => e => {
    this.props.setCloneChecklist({
      selected: {
        ...keyBy(this.selectedItems),
        [id]: !e.target.checked ? undefined : id,
      },
    });
  };

  toggleAllProperty = () => {
    const isSelectedAll = this.isSelectedAll;

    const filteredProperties = this.filteredProperties;
    const currentSelection = filteredProperties.reduce(
      (all, property) => ({ ...all, [property.objectId]: property.objectId }),
      {},
    );

    const selected = isSelectedAll
      ? keyBy(this.selectedItems.filter(selectedPropertyId => !currentSelection[selectedPropertyId]))
      : { ...keyBy(this.selectedItems), ...currentSelection };

    this.props.setCloneChecklist({ selected });
  };

  get isSelectedAll() {
    const filteredProperties = this.filteredProperties;
    const selectedItems = this.selectedItems;

    const selectedFilteredProperties = filteredProperties.filter(
      property => selectedItems.indexOf(property.objectId) === -1,
    );

    return filteredProperties.length > 0 && selectedFilteredProperties.length === 0;
  }

  get allProps() {
    return this.filterWithAccess(filterPropertySidebar(this.props.properties.toJS()));
  }

  get selectedItems() {
    const { selectedChecklists } = this.props;
    return filter(selectedChecklists, item => !!item);
  }

  filterWithAccess(input) {
    const user = this.props.currentUser;
    return filter(input, property => hasAccess({ property, user }).canCreateChecklists);
  }

  get filteredProperties() {
    return this.filterWithAccess(filterPropertySidebar(this.props.searchRes));
  }

  renderProperties = (properties, selected) => {
    if (properties.length === 0) {
      return <div className={cx('checklist__clone-noresult')}>{t('checklist.no_properties_found')}</div>;
    }

    const data = chunk(properties, 3);
    const isSelected = property => selected && !!selected[property.objectId];

    return (
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            width={width}
            rowHeight={78}
            rowCount={data.length}
            rowRenderer={rowRenderMapper({
              data,
              handler: this.renderRow,
              ctx: {
                toggleSingleProperty: this.toggleSingleProperty,
                isSelected,
              },
            })}
          />
        )}
      </AutoSizer>
    );
  };

  renderRow({ row, style, key, ctx: { isSelected, toggleSingleProperty } }) {
    return (
      <ChecklistOverlay.GridRow key={key} spacing="8" style={style}>
        {map(row, property => (
          <ChecklistOverlay.GridItem key={property.objectId} spacing="8" itemPerRow="3">
            <PropertyChecklistClone
              onClick={toggleSingleProperty(property.objectId)}
              property={property}
              selected={isSelected(property)}
            />
          </ChecklistOverlay.GridItem>
        ))}
      </ChecklistOverlay.GridRow>
    );
  }

  setSearchQuery = query => {
    this.props.setSearchQuery('checklist_clone_search', query);
  };

  renderChecklistTitleInput = () => {
    const { checklistTitle } = this.props;
    return (
      <>
        <SpacedHeadline>{t('checklist.clone_checklist_name')}</SpacedHeadline>
        <ChecklistOverlay.GridRow spacing="8">
          <ChecklistOverlay.GridItem spacing="8" itemPerRow="3">
            <Input
              isLast
              isFirst
              type="text"
              styleOverwrite={{ backgroundColor: '#FFF' }}
              error={false}
              value={checklistTitle}
              onChange={e =>
                this.props.setCloneChecklist({
                  checklistTitle: e.target.value,
                })
              }
            />
          </ChecklistOverlay.GridItem>
        </ChecklistOverlay.GridRow>
      </>
    );
  };

  render() {
    const { isOpen, navHidden, checklistTitle, selectedChecklists, isSubmitting, isLoading } = this.props;

    if (!isOpen) {
      return null;
    }

    if (isLoading) {
      return <LoadingSplash />;
    }

    const filteredProperties = this.filteredProperties;
    const allProperties = this.allProps;
    const selectedProperties = this.selectedItems;

    const selectedCount = selectedProperties.length;
    const totalCount = allProperties.length;
    const queryCount = filteredProperties.length;

    const width = navHidden ? '100%' : 'calc(100% - 64px)';

    return (
      <ChecklistOverlay show>
        <ChecklistOverlay.Header>
          <div style={{ height: '48px', width }}>
            <ChecklistHeader
              type="multi"
              isLoading={isSubmitting}
              onClose={this.onClose}
              onDone={this.onDone}
              text={t('checklist.clone_checklist')}
            />
          </div>
          <DividerLine type={['bottom']} />
        </ChecklistOverlay.Header>
        <ChecklistOverlay.ContentWrap>
          <ChecklistOverlay.Content type="grey" style={{ overflow: 'hidden', width }}>
            <ChecklistOverlay.ContentInner style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              {checklistTitle && this.renderChecklistTitleInput()}

              <SpacedHeadline>{t('checklist.clone_properties')}</SpacedHeadline>
              <ChecklistOverlay.GridRow spacing="8">
                <ChecklistOverlay.GridItem spacing="8" itemPerRow="1">
                  <SearchSelect
                    selectedCount={selectedCount}
                    queryCount={queryCount}
                    totalCount={totalCount}
                    placeholder={t('checklist.search_for_address')}
                    value={this.props.searchQuery}
                    onChange={this.setSearchQuery}
                    onSelect={this.toggleAllProperty}
                    isSelected={this.isSelectedAll}
                  />
                </ChecklistOverlay.GridItem>
              </ChecklistOverlay.GridRow>
              <SpaceTiny />
              <div style={{ height: 'calc(100% - 200px)' }}>
                {this.renderProperties(filteredProperties, selectedChecklists)}
              </div>
              {isSubmitting && <div className={cx('checklist__clone-overlay')} />}
            </ChecklistOverlay.ContentInner>
          </ChecklistOverlay.Content>
        </ChecklistOverlay.ContentWrap>
      </ChecklistOverlay>
    );
  }
}
