import gql from 'graphql-tag';

export const MUTATION_STRIPE_CREATE_SETUP_INTENT = gql`
  mutation createStripeSetupIntentMutation {
    createStripeSetupIntent {
      id
      clientSecret
    }
  }
`;

export const MUTATION_STRIPE_CREATE_CUSTOMER_WITH_PAYMENT_METHOD = gql`
  mutation createStripeCustomerMutation($stripePaymentMethod: String!) {
    createStripeCustomer(stripePaymentMethod: $stripePaymentMethod) {
      id
      description
      email
      defaultSource
      defaultPaymentMethod
      cards
      bankAccounts
    }
  }
`;

export const MUTATION_STRIPE_ADD_CUSTOMER_PAYMENT_METHOD = gql`
  mutation addStripeCustomerPaymentMethodMutation($stripePaymentMethod: String, $setAsDefault: Boolean) {
    addStripeCustomerPaymentMethod(stripePaymentMethod: $stripePaymentMethod, setAsDefault: $setAsDefault) {
      id
      description
      email
      defaultSource
      defaultPaymentMethod
      cards
      bankAccounts
    }
  }
`;

export const MUTATION_STRIPE_REMOVE_CUSTOMER_PAYMENT_METHOD = gql`
  mutation removeStripeCustomerPaymentMethodMutation($stripePaymentMethodId: String) {
    removeStripeCustomerPaymentMethod(stripePaymentMethodId: $stripePaymentMethodId) {
      id
      description
      email
      defaultSource
      defaultPaymentMethod
      cards
      bankAccounts
    }
  }
`;

export const MUTATION_STRIPE_CREATE_CUSTOMER_WITH_SOURCE = gql`
  mutation createStripeCustomerMutation($stripeSourceToken: String!) {
    createStripeCustomer(stripeSourceToken: $stripeSourceToken) {
      id
      description
      email
      defaultSource
      defaultPaymentMethod
      cards
      bankAccounts
    }
  }
`;

export const MUTATION_STRIPE_ADD_CUSTOMER_SOURCE = gql`
  mutation addStripeCustomerSourceMutation($stripeSourceToken: String) {
    addStripeCustomerSource(stripeSourceToken: $stripeSourceToken) {
      id
      description
      email
      defaultSource
      defaultPaymentMethod
      cards
      bankAccounts
    }
  }
`;

export const MUTATION_STRIPE_REMOVE_CUSTOMER_SOURCE = gql`
  mutation removeStripeCustomerSourceMutation($stripeSourceId: String) {
    removeStripeCustomerSource(stripeSourceId: $stripeSourceId) {
      id
      description
      email
      defaultSource
      defaultPaymentMethod
      cards
      bankAccounts
    }
  }
`;

export const MUTATION_STRIPE_CREATE_PLAID_CUSTOMER_WITH_SOURCE = gql`
  mutation createStripeCustomerMutation($plaidPublicToken: String!, $plaidAccountId: String!) {
    createStripeCustomer(plaidPublicToken: $plaidPublicToken, plaidAccountId: $plaidAccountId) {
      id
      description
      email
      defaultSource
      defaultPaymentMethod
      cards
      bankAccounts
    }
  }
`;

export const MUTATION_STRIPE_ADD_PLAID_CUSTOMER_SOURCE = gql`
  mutation addStripeCustomerSourceMutation($plaidPublicToken: String!, $plaidAccountId: String!) {
    addStripeCustomerSource(plaidPublicToken: $plaidPublicToken, plaidAccountId: $plaidAccountId) {
      id
      description
      email
      defaultSource
      defaultPaymentMethod
      cards
      bankAccounts
    }
  }
`;
