import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@properly/components';
import filterReactDomProps from 'filter-react-dom-props';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const InfoMessage = ({
  children,
  text,
  color,
  onClose,
  icon,
  isMergeInfo,
  isSmall,
  isSmallFullWidth,
  isPrompt,
  isVcenter,
  isHcenter,
  spaceFunc,
  ...more
}) => {
  const isPromptInfoMessage = isMergeInfo || isPrompt;
  let FinalIcon;
  if (icon === 'light') FinalIcon = Icon.IcLightbulb;
  if (icon === 'warning') FinalIcon = Icon.IcWarning;
  return (
    <div
      {...filterReactDomProps(more)}
      className={cx('info-message', {
        'info-message--red': color === 'red',
        'info-message--white': color === 'white',
        'info-message--bg-jobrequest': color === 'bg-jobrequest',
        'info-message--small': isSmall,
        'info-message--small-fullwidth': isSmallFullWidth,
        'info-message--vcenter': isVcenter,
        'info-message--hcenter': isHcenter,
      })}
    >
      <div className={cx('info-message__row')}>
        {FinalIcon && (
          <div className={cx('info-message__icon', 'info-message__icon--base')}>
            <FinalIcon />
          </div>
        )}
        <div
          data-key="info-message__text"
          className={cx('info-message__text', {
            'info-message__text--small': isSmall || isSmallFullWidth,
          })}
        >
          {text}
        </div>
        {!isPromptInfoMessage && (
          <div onClick={onClose} className={cx('info-message__icon', 'info-message__icon--close')}>
            <Icon.IcSmCloseOrange />
          </div>
        )}
      </div>
      {children && (
        <div className={cx('info-message__row', 'info-message__row--merge')}>
          {spaceFunc && spaceFunc()}
          <div className={cx('info-message__row', 'info-message__row--mergeinner')}>{children}</div>
        </div>
      )}
    </div>
  );
};

InfoMessage.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  isMergeInfo: PropTypes.bool,
  isSmall: PropTypes.bool,
  isSmallFullWidth: PropTypes.bool,
  isPrompt: PropTypes.bool,
  isVcenter: PropTypes.bool,
  isHcenter: PropTypes.bool,
  icon: PropTypes.oneOf(['light', 'warning', undefined]),
  color: PropTypes.oneOf(['red', 'white', 'bg-jobrequest']).isRequired,
};

InfoMessage.defaultProps = {
  children: undefined,
  onClose: () => {},
  isMergeInfo: false,
  isSmall: false,
  isSmallFullWidth: false,
  isPrompt: false,
  isVcenter: false,
  isHcenter: false,
  icon: undefined,
};

export default InfoMessage;
