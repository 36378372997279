import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, SpaceBase, Input, Button, SpacedHeadline } from '@properly/components';
import { setModal, updateInBackgroundSaga } from '../../../../actions/globalActions';
import { checkAlexaDevice, setupDevice } from '../../data';
import { selectGlobalModalResolved } from '../../../../selectors/globalSelector';

export const PROPERTY_ALEXA_MODAL = 'PROPERTY_ALEXA_MODAL';

class PropertyAlexaModal extends PureComponent {
  static propTypes = {
    propertyId: PropTypes.string.isRequired,
    setModal: PropTypes.func.isRequired,
    updateInBackgroundSaga: PropTypes.func.isRequired,
  };

  state = { arn: '', resultAlexa: '', alexaSetupLoading: false };

  setResultAlex(res) {
    this.setState({ resultAlexa: res, alexaSetupLoading: false });
  }

  close = () => {
    this.props.setModal(false, PROPERTY_ALEXA_MODAL, {});
    this.setResultAlex('');
  };

  verifyDevice = () => {
    this.setState({ alexaSetupLoading: true });
    checkAlexaDevice(this.state.arn)
      .then(res => {
        if (!res.DeviceArn) throw new Error('invalid');
        return setupDevice(res.DeviceArn, this.props.propertyId);
      })
      .then(res => {
        if (!res.deviceArn || !res.roomArn) throw new Error('invalid');
        this.close();
        this.props.updateInBackgroundSaga('properties', this.props.propertyId);
      })
      .catch(() => {
        this.setResultAlex('error');
      });
  };

  changeArn = e => {
    this.setState({ arn: e.target.value });
  };

  render() {
    return (
      <Modal height={330} onClose={this.close} show={!!this.props.alexaModal.isOpen}>
        <div>
          <Modal.H1>Connect Amazon Echo</Modal.H1>
          <Modal.Text>
            After connecting your device you can trigger the the wipe of your device with a Reminder.
          </Modal.Text>
          <SpaceBase />
          <SpacedHeadline>Device Serial Number</SpacedHeadline>
          <Input isFirst isLast value={this.state.arn} type="text" onChange={this.changeArn} />
          {this.state.resultAlexa === 'error' && (
            <Input.ErrorMsg>There was an error connecting your device.</Input.ErrorMsg>
          )}
          <Modal.Options centerV>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Modal.Cancel onClick={this.close} />
            </div>
            <Button
              onClick={this.verifyDevice}
              isLoading={this.state.alexaSetupLoading}
              disabled={this.state.alexaSetupLoading}
              types={['type-full-primary', 'size-medium']}
            >
              Connect
            </Button>
          </Modal.Options>
        </div>
      </Modal>
    );
  }
}

export default connect(
  () => {
    const alexaModal = selectGlobalModalResolved(PROPERTY_ALEXA_MODAL);
    return (state, props) => ({
      alexaModal: alexaModal(state, props),
    });
  },
  {
    setModal,
    updateInBackgroundSaga,
  },
)(PropertyAlexaModal);
