import React, { Component } from 'react';
import { connect } from 'react-redux';
import { error } from '@properly/config';
import { Input, SpaceTiny, SpaceBase, Button, Small } from '@properly/components';
import t from '@properly/localization';
import classNames from 'classnames/bind';
import { selectUserEmailAutoPopulate } from '../../selectors/globalSelector';
import { resetUserEmailAutoPopulate } from '../../actions/globalActions';
import { trackLoginScreenShown } from '../../actions/trackingEvents';
import LoginWrapContainer from './LoginWrapContainer';
import { loginSaga, goToSignup, goToPwReset, resetErrors } from './LoginSignUpActions';
import styles from './loginSignUpStyles.css';

const cx = classNames.bind(styles);

class LoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '', // eslint-disable-line
      password: '', // eslint-disable-line
    };
  }

  componentDidMount() {
    const { userEmailAutoPopulate } = this.props;
    trackLoginScreenShown();
    if (userEmailAutoPopulate) {
      this.setState({ username: userEmailAutoPopulate });
    }
  }

  componentWillUnmount() {
    this.props.resetErrors();
    this.props.resetUserEmailAutoPopulate();
  }

  onUpdateUsername = e => {
    const { userEmailAutoPopulate } = this.props;
    if (!userEmailAutoPopulate) this.setState({ username: e.target.value }); // eslint-disable-line
  };

  onUpdatePassword = e => {
    this.setState({ password: e.target.value }); // eslint-disable-line
  };

  handleOnSubmit() {
    return e => {
      e.preventDefault();

      const data = {
        username: this.state.username,
        password: this.state.password,
      };

      this.props.loginSaga(data, this.props.location.query);
    };
  }

  handleGoSignup() {
    if (window.location.search === '?from=frontpage') {
      window.location.href = 'https://www.getproperly.com/en?from=webapp';
    } else {
      this.props.goToSignup(this.props.location);
    }
  }

  render() {
    const { errors } = this.props.loginSignUpReducers;
    const { userEmailAutoPopulate } = this.props;

    let usernameHasError = false;
    let passwordErrorMessage = null;
    if (errors && errors.indexOf) {
      if (errors.indexOf(error.UNKNOWN) > -1) {
        passwordErrorMessage = t('login.error', {});
      } else {
        if (errors.indexOf(error.EMAIL_NOT_FOUND) > -1) {
          usernameHasError = true;
          passwordErrorMessage = t('login.error_user_not_found', {});
        }
        if (errors.indexOf(error.INVALID_USERNAME) > -1) {
          usernameHasError = true;
        }
        if (errors.indexOf(error.COULD_NOT_LOGIN) > -1) {
          usernameHasError = true;
          passwordErrorMessage = t('login.error_wrong_login', {});
        }
        if (errors.indexOf(error.ACCOUNT_DISABLED) > -1) {
          usernameHasError = true;
          passwordErrorMessage = t('login.account_disabled', {});
        }
        if (errors.indexOf(error.PASSWORD_MISSING) > -1) {
          passwordErrorMessage = t('login.error', {});
        }
      }
    }

    return (
      <LoginWrapContainer showSignUp location={this.props.location} title={t('login.title', {})}>
        <form onSubmit={this.handleOnSubmit()}>
          <Input
            isFirst
            isLast
            error={usernameHasError}
            data-key="username"
            placeholder={t('login.email', {})}
            type="text"
            value={userEmailAutoPopulate || this.state.username}
            onChange={this.onUpdateUsername}
          />
          <SpaceTiny />
          <Input
            isFirst
            isLast
            error={passwordErrorMessage}
            showErrorMsg
            data-key="password"
            placeholder={t('login.password', {})}
            type="password"
            onChange={this.onUpdatePassword}
          />
          <SpaceBase />
          <Button
            type="submit"
            data-key="login_button"
            onClick={this.handleOnSubmit()}
            types={['type-full-primary', 'width-flex', 'size-large']}
          >
            {t('login.login', {})}
          </Button>
          <SpaceBase />
        </form>
        <Button
          data-key="forgot-password-link"
          onClick={() => this.props.goToPwReset()}
          types={['type-flat-black', 'width-flex', 'size-medium', 'fontw-normal']}
        >
          {t('login.forgot_pw')}
        </Button>
        <div className={cx('signup__text')}>
          <Small type="grey">
            {t('signup.no_account')} <br />
            <span onClick={() => this.props.goToSignup(this.props.location)} className={cx('signup__link')}>
              {t('signup.form.cta', {})}
            </span>
          </Small>
        </div>
      </LoginWrapContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginSignUpReducers: state.loginSignUpReducers,
    userEmailAutoPopulate: selectUserEmailAutoPopulate()(state),
  };
}

export default connect(mapStateToProps, {
  loginSaga,
  goToSignup,
  goToPwReset,
  resetErrors,
  resetUserEmailAutoPopulate,
})(LoginComponent);
