import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import filter from 'ramda/src/filter';
import JobRequestList from '../../components/JobRequestList';
import { jobCleanerRequestListSaga } from './actions';
import { selectCleanerRequestsByContact } from './selectors';
import { loadingKey } from './saga';
import {
  selectLoadingState,
  selectLoadingStateMeta,
  selectJobRequestsWithCleaners,
} from '../../selectors/globalSelector';

function hashParams(props) {
  return `${props.contactId}${props.onlyUpcoming}`;
}

const LIMIT = 30;

const MAP_MODE = {
  contact: 'property',
  property: 'cleaner',
};

const mapModeToMode = mode => MAP_MODE[mode];

function mapModeToSelector(mode, state, props) {
  if (mode === 'contact') {
    return selectCleanerRequestsByContact()(state, props);
  }
  if (mode === 'property') {
    return selectJobRequestsWithCleaners()(state, props);
  }
  return [];
}

class JobCleanerRequestList extends PureComponent {
  static propTypes = {
    contactId: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(['contact', 'property']).isRequired,
    onClick: PropTypes.func.isRequired,
    isUntouched: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isLoadingFull: PropTypes.bool.isRequired,
    jobCleanerRequestListSaga: PropTypes.func.isRequired,
    isLoadingPartial: PropTypes.bool.isRequired,
    isErrored: PropTypes.bool.isRequired,
    onlyUpcoming: PropTypes.bool,
    page: PropTypes.number,
    loading: PropTypes.bool,
    ascending: PropTypes.bool,
    hasMore: PropTypes.bool,
    filter: PropTypes.bool,
  };

  static defaultProps = {
    onlyUpcoming: false,
    filter: false,
    loading: false,
    ascending: false,
    page: 0,
    hasMore: false,
  };

  componentDidMount() {
    this.load(this.page);
  }

  componentDidUpdate(prevProps) {
    const { isUntouched } = this.props;
    if (
      hashParams(prevProps) !== hashParams(this.props) || // if hash changed
      isUntouched // is initial load
    ) {
      this.load(this.page);
    }
  }

  get page() {
    const { page } = this.props;
    return page || 0;
  }

  get data() {
    const { contactId, mode, filter: requestFilter, data } = this.props;

    if (mapModeToMode(mode) === MAP_MODE.contact) {
      // Filter all accepted jobs that are not equal to the contactId
      const targetCleanerJobs = filter(item => {
        const { jobRequestModel } = item || {};
        const { assignedToContactId } = jobRequestModel || {};
        return !assignedToContactId || assignedToContactId === contactId;
      }, data);

      return requestFilter ? filter(requestFilter, targetCleanerJobs) : targetCleanerJobs;
    }
    return requestFilter ? filter(requestFilter, data) : data;
  }

  get loading() {
    const { isLoadingFull, loading } = this.props;
    return isLoadingFull || loading;
  }

  load = (page, pagination) => {
    const { contactId, onlyUpcoming, mode } = this.props;
    return this.props.jobCleanerRequestListSaga({
      contactId,
      onlyUpcoming,
      page: pagination ? page : 0, // Resetting page i.e offset to 0 when there is no pagination i.e when we switch from property page to contact page and vice versa
      pagination,
      mode,
      limit: LIMIT,
    });
  };

  loadMore = () => {
    this.load(this.page + LIMIT, true);
  };

  render() {
    const { ascending, mode, isLoadingPartial, onClick, isErrored, hasMore, contactId } = this.props;
    return (
      <JobRequestList
        ascending={!!ascending}
        mode={mapModeToMode(mode)}
        isLoadingPagination={isLoadingPartial}
        onLoadMore={this.loadMore}
        onClick={onClick}
        jobRequestsArray={this.data}
        isLoading={!!this.loading}
        hasError={isErrored}
        hasMore={hasMore}
        contactId={contactId}
      />
    );
  }
}

function mapStateToProps(state, props) {
  const loading = selectLoadingState(loadingKey)(state);
  return {
    data: mapModeToSelector(props.mode, state, props),
    isErrored: loading.isErrored,
    isLoadingFull: loading.isLoadingFull,
    isLoadingPartial: loading.isLoadingPartial,
    isUntouched: loading.isUntouched,
    page: selectLoadingStateMeta(loadingKey, 'page')(state),
    hasMore: selectLoadingStateMeta(loadingKey, 'hasMore')(state),
  };
}

export default compose(
  connect(
    mapStateToProps,
    {
      jobCleanerRequestListSaga,
    },
  ),
)(JobCleanerRequestList);
