"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isValidEmail = isValidEmail;
exports.isValidPhone = isValidPhone;
exports.isValidPassword = void 0;

var _config = require("@properly/config");

function isValidEmail(email) {
  const re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return re.test(email === null || email === void 0 ? void 0 : email.trim());
}

function isValidPhone(phone) {
  var _String;

  const trimmedPhone = (_String = String(phone || '')) === null || _String === void 0 ? void 0 : _String.trim();
  return (trimmedPhone === null || trimmedPhone === void 0 ? void 0 : trimmedPhone.length) >= 6 && (trimmedPhone === null || trimmedPhone === void 0 ? void 0 : trimmedPhone.length) <= 15 && /^[0-9\+\-\(\) ]*$/.test(trimmedPhone) // eslint-disable-line
  ;
}

const isValidPassword = password => String(password).length >= _config.passwordSpecification.passwordLength;

exports.isValidPassword = isValidPassword;