import locale from 'browser-locale';
import { fetchParseCloudFunction } from '../../data.http';

export const getChecklists = () => fetchParseCloudFunction('loadIndependentChecklists', null, true);
export const getPublishedChecklists = () =>
  fetchParseCloudFunction('listPublishedChecklists', { languageCode: locale() }, true);

export const getPublishedChecklist = checklistId =>
  fetchParseCloudFunction('getPublishedChecklist', { checklistId }, true);
export const getPublishedChecklistInstructions = checklistInstructionsId =>
  fetchParseCloudFunction('getPublishedChecklistInstructions', { checklistInstructionsId }, true);

export const cloneChecklistIntoProperties = (properties, checklistId) =>
  fetchParseCloudFunction('copyIntoProperties', { properties, checklistId }, true);

export const removeChecklistFromLibrary = checklistId =>
  fetchParseCloudFunction('deleteIndependentChecklist', { checklistId }, true);

export const createNewChecklistInLibrary = () => fetchParseCloudFunction('createIndependentChecklist', null, true);
