import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import map from 'lodash/map';
import noop from 'lodash/noop';
import findIndex from 'lodash/findIndex';
import { RoundSelect, Icon } from '@properly/components';
import { mapTaskIconToIconAndTitle } from '@properly/common';
import t from '@properly/localization';
import classNames from 'classnames/bind';
import styles from '../components/checklistsStyles.module.css';
import {
  stepMutatorSaga,
  taskMutatorSaga,
  setUploadState,
  checklistDirectTaskImageUpload,
} from '../state/ChecklistActions';
import ChecklistTopBar from '../components/ChecklistTopBar';
import ChecklistImage from '../components/ChecklistImage';
import ChecklistImageListItem from '../../../../components/ChecklistImageListItem/index';
import { trackChecklistAddPin, trackChecklistAddPinNote } from '../../../../actions/trackingEvents';
import * as selectors from '../state/ChecklistSelectors';

const cx = classNames.bind(styles);

class ChecklistImageList extends Component {
  constructor(props) {
    super(props);
    this.state = { activePinId: undefined };
  }

  getPictureUrl(arr) {
    return arr && arr[0] ? arr[0] : undefined;
  }

  onChangeActiveTask = (realIndex, value) => {
    this.setState({ activePinId: undefined });
    this.updateTaskNote(realIndex, value);
  };

  removeTaskImage = index => {
    this.props.taskMutatorSaga('update', this.props.jobInstructionId, this.props.step.objectId, {
      index,
      changeSet: {
        pictureUrls: [],
      },
    });
  };

  updateTaskNote = (index, val) => {
    this.props.taskMutatorSaga('update', this.props.jobInstructionId, this.props.step.objectId, {
      index,
      changeSet: {
        note: val,
      },
    });
    trackChecklistAddPinNote(this.props.jobId);
  };

  onDeleteTask = index => {
    this.props.taskMutatorSaga('delete', this.props.jobInstructionId, this.props.step.objectId, {
      index,
    });
  };

  onTrashPin = item => {
    this.props.taskMutatorSaga('delete', this.props.jobInstructionId, this.props.step.objectId, {
      index: item.index,
    });
  };

  setPinPos = (index, changeSet) => {
    this.props.taskMutatorSaga('update', this.props.jobInstructionId, this.props.step.objectId, {
      index,
      changeSet: {
        ...changeSet,
      },
    });
  };

  setNewPinPos = (type, xy) => {
    this.props.taskMutatorSaga('addimage', this.props.jobInstructionId, this.props.step.objectId, {
      changeSet: {
        icon: type,
        ...xy,
      },
    });
    trackChecklistAddPin(this.props.jobId);
  };

  onDelete = () => {
    this.props.stepMutatorSaga('deleteStep', {
      jobInstructionId: this.props.jobInstructionId,
      deleteSteps: {
        [this.props.step.objectId]: true,
      },
    });
  };

  onDuplicate = () => {
    this.props.stepMutatorSaga('duplicateStep', {
      jobInstructionId: this.props.jobInstructionId,
      steps: [this.props.step.objectId].slice(0),
      index: this.props.index,
    });
  };

  onBlurTitle = e => {
    this.props.stepMutatorSaga('update', {
      jobInstructionId: this.props.jobInstructionId,
      stepId: `${this.props.step.objectId}`,
      changeSet: {
        title: e.target.value,
      },
    });
  };

  updateVerify = val => () => {
    this.props.stepMutatorSaga('update', {
      jobInstructionId: this.props.jobInstructionId,
      stepId: `${this.props.step.objectId}`,
      changeSet: {
        isVerificationNeeded: val,
      },
    });
  };

  setActivePin = activePinId => {
    this.setState({ activePinId });
  };

  onReplaceImage = () => {
    this.props.setUploadState({
      isOpen: true,
      do: {
        mode: 'replace',
        stepId: `${this.props.step.objectId}`,
      },
    });
  };

  renderTasksImage(tasks, activeTaskId, isReadOnly) {
    const that = this;
    const indexActive = findIndex(tasks, { objectId: activeTaskId });
    const activeTask = tasks[indexActive];
    return (
      <div>
        {activeTask && this.renderSingleTaskImage(activeTask, indexActive, true, isReadOnly)}
        {map(tasks, (task, realIndex) => {
          if (task.objectId == activeTaskId) return null; // eslint-disable-line
          return that.renderSingleTaskImage(task, realIndex, false, isReadOnly);
        })}
      </div>
    );
  }

  renderSingleTaskImage(task, realIndex, active, isReadOnly) {
    const iconObj = mapTaskIconToIconAndTitle(task.icon);
    const handleLeave = active
      ? e => this.onChangeActiveTask(realIndex, e.target.value)
      : e => this.updateTaskNote(realIndex, e.target.value);
    return (
      <ChecklistImageListItem
        key={`${task.objectId}${task.note ? task.note.length : ''}`}
        iconBg="orange"
        isReadOnly={isReadOnly}
        active={active}
        onRemoveImage={() => this.removeTaskImage(realIndex)}
        onDelete={() => this.onDeleteTask(realIndex)}
        onChange={handleLeave}
        defaultValue={task.note}
        pictureUrl={this.getPictureUrl(task.pictureUrls)}
        setUploadState={this.props.setUploadState}
        open={!!this.getPictureUrl(task.pictureUrls)}
        canHaveImg
        title={iconObj.title}
        iconImage={task.icon}
        directUpload={!this.props.propertyId}
        stepId={this.props.step?.objectId}
        realIndex={realIndex}
        checklistDirectTaskImageUpload={this.props.checklistDirectTaskImageUpload}
        openErrorModal={this.props.openErrorModal}
      />
    );
  }

  render() {
    const { step } = this.props;
    const { pictureUrl, title, isVerificationNeeded, tasks } = step || {};
    return (
      <div>
        <ChecklistTopBar
          title={title}
          isReadOnly={this.props.isReadOnly}
          onDelete={this.onDelete}
          onDuplicate={this.onDuplicate}
          onBlur={this.onBlurTitle}
        />
        <ChecklistImage
          isReadOnly={this.props.isReadOnly}
          index={this.props.index}
          activePinId={this.state.activePinId}
          onTrashPin={this.onTrashPin}
          pinDropped={this.setPinPos}
          newPinDropped={this.setNewPinPos}
          onSetActivePin={this.setActivePin}
          onReplace={this.onReplaceImage}
          image={pictureUrl}
          tasks={tasks}
        />
        <div className={cx('checklist__tasks')}>
          {this.renderTasksImage(tasks, this.state.activePinId, this.props.isReadOnly)}
        </div>
        <div data-key="verification-Photo" className={cx('checklist__needsverify')}>
          <span className={cx('checklist__needsverify-left')}>
            <Icon.IcCameraSolid />
            <span className={cx('checklist__needsverify-txt')}>{t('checklist.ask_a_cleaner')}</span>
          </span>
          <RoundSelect
            data-key="verification-photo-checkbox"
            style={{ height: 18, width: 18 }}
            type="quarter"
            disabled={this.props.isReadOnly}
            selected={isVerificationNeeded}
            onChange={(!this.props.isReadOnly && this.updateVerify(!isVerificationNeeded)) || noop}
          />
        </div>
      </div>
    );
  }
}

ChecklistImageList.propTypes = {
  jobInstructionId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  step: PropTypes.shape({}).isRequired,
  jobId: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool,

  // connect
  stepMutatorSaga: PropTypes.func.isRequired,
  taskMutatorSaga: PropTypes.func.isRequired,
  setUploadState: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    lastTimeUpdated: selectors.selectLastTimeUpdate()(state, props),
  };
}

export default connect(mapStateToProps, {
  stepMutatorSaga,
  taskMutatorSaga,
  setUploadState,
  checklistDirectTaskImageUpload,
})(ChecklistImageList);
