"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setWindowTitle = setWindowTitle;
exports.getClosestElement = getClosestElement;
exports.openInNewWindow = openInNewWindow;
exports.openInNewWindowBlank = openInNewWindowBlank;
exports.redirectPage = redirectPage;
exports.reloadPage = reloadPage;
exports.scrollbarSize = scrollbarSize;
exports.injectScript = injectScript;

var _localization = _interopRequireDefault(require("@properly/localization"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function setWindowTitle(title) {
  return () => {
    setTimeout(() => {
      document.title = title ? `${(0, _localization.default)(title)} | Properly` : 'Properly';
    }, 0);
  };
}

function getClosestElement(inputElem, inputSelector) {
  // Variables
  let selector = inputSelector;
  let elem = inputElem;
  let value;
  let attribute;
  const firstChar = selector.charAt(0);
  const supports = ('classList' in document.documentElement); // If selector is a data attribute, split attribute from value

  if (firstChar === '[') {
    selector = selector.substr(1, selector.length - 2);
    attribute = selector.split('=');

    if (attribute.length > 1) {
      value = true;
      attribute[1] = attribute[1].replace(/"/g, '').replace(/'/g, '');
    }
  } // Get closest match


  for (; elem && elem !== document && elem.nodeType === 1; elem = elem.parentNode) {
    // If selector is a class
    if (firstChar === '.') {
      if (supports) {
        if (elem.classList.contains(selector.substr(1))) {
          return elem;
        }
      } else if (new RegExp(`(^|\\s)${selector.substr(1)}(\\s|$)`).test(elem.className)) {
        return elem;
      }
    } // If selector is an ID


    if (firstChar === '#') {
      if (elem.id === selector.substr(1)) {
        return elem;
      }
    } // If selector is a data attribute


    if (firstChar === '[') {
      if (elem.hasAttribute(attribute[0])) {
        if (value) {
          if (elem.getAttribute(attribute[0]) === attribute[1]) {
            return elem;
          }
        } else {
          return elem;
        }
      }
    } // If selector is a tag


    if (elem.tagName.toLowerCase() === selector) {
      return elem;
    }
  }

  return null;
}

function openInNewWindow(url) {
  window.open(url);
}

function openInNewWindowBlank(url) {
  window.open(url, '_blank');
}

function redirectPage(url) {
  window.location = url;
}

function reloadPage() {
  window.location.reload();
}

let size;

function scrollbarSize(recalc) {
  if (!size && size !== 0 || recalc) {
    const scrollDiv = document.createElement('div');
    scrollDiv.style.position = 'absolute';
    scrollDiv.style.top = '-9999px';
    scrollDiv.style.width = '50px';
    scrollDiv.style.height = '50px';
    scrollDiv.style.overflow = 'scroll';
    document.body.appendChild(scrollDiv);
    size = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
  }

  return size;
}

function injectScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = false;
    script.src = src;
    script.addEventListener('load', resolve);
    script.addEventListener('error', () => reject(new Error('Error loading script.')));
    script.addEventListener('abort', () => reject(new Error('Script loading aborted.')));
    document.head.appendChild(script);
  });
}