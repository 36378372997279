import React from 'react';
import PropTypes from 'prop-types';
import { DividerLine } from '@properly/components';
import t from '@properly/localization';
import classNames from 'classnames/bind';
import styles from './jobDetailsStyles.module.css';

const cx = classNames.bind(styles);

export const JobDetailsFeedback = ({ feedback, verificationDoneCount }) => (
  <div
    className={cx('jobdetails__summary-section', {
      'jobdetails__summary-section--active': true,
    })}
  >
    <DividerLine type={['top', 'light']} />
    <div className={cx('jobdetails__summary-section-head')}>
      <div className={cx('jobdetails__summary-icon')}>{feedback.icon}</div>
      <span>{feedback.text}</span>
    </div>
    <div className={cx('jobdetails__canorange')}>
      {feedback.count ? `${feedback.count} ${t('jobdetails.of')} ${verificationDoneCount}` : '-'}
    </div>
  </div>
);

JobDetailsFeedback.propTypes = {
  feedback: PropTypes.shape({}).isRequired,
  verificationDoneCount: PropTypes.number.isRequired,
};
