import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Input, SpacedHeadline } from '@properly/components';
import t from '@properly/localization';
import styles from './index.module.css';
import PropertyInputDetailContainer from '../../modules/desktopApp/property/containers/PropertyInputDetailContainer';

const cx = classNames.bind(styles);

const PropertyDetailContainerBlock = ({ canEdit, propertyId, isSingleColumn }) => {
  if (!propertyId) {
    return null;
  }

  const commonInputProps = {
    hover: !!canEdit,
    disabled: !canEdit,
  };

  const commonTextAreaProps = {
    ...commonInputProps,
    type: 'textarea',
    isFirst: true,
    isLast: true,
    editMode: !!canEdit,
    canSave: !!canEdit,
  };

  const commonTextProps = {
    ...commonInputProps,
    type: 'text',
    styleOverwrite: { background: 'white' },
  };

  return (
    <div
      className={cx('property-detail-wrap', {
        'property-detail-wrap--column': isSingleColumn,
      })}
    >
      <div className={cx('property-detail-flex')}>
        <div data-key="property-details-access" className={cx('property-detail-flex-1')}>
          <SpacedHeadline>{t('properties.access')}</SpacedHeadline>
          <PropertyInputDetailContainer
            watch={['detailsAccess']}
            editPropertyId={propertyId}
            content={(onChange, onCancel, onSave, changed, canSave, getter) => (
              <Input
                {...commonTextAreaProps}
                forceOutline={changed}
                onSave={onSave}
                onCancel={onCancel}
                onChange={onChange('detailsAccess')}
                savingState={canSave}
                value={getter('detailsAccess')}
                placeholder={t('properties.note_access')}
              />
            )}
          />
        </div>
        <div data-key="property-details-garbage" className={cx('property-detail-flex-1')}>
          <SpacedHeadline>{t('properties.garbage')}</SpacedHeadline>
          <PropertyInputDetailContainer
            watch={['detailsGarbage']}
            editPropertyId={propertyId}
            content={(onChange, onCancel, onSave, changed, canSave, getter) => (
              <Input
                {...commonTextAreaProps}
                forceOutline={changed}
                onSave={onSave}
                onCancel={onCancel}
                onChange={onChange('detailsGarbage')}
                savingState={canSave}
                value={getter('detailsGarbage')}
                placeholder={t('properties.note_garbage')}
              />
            )}
          />
        </div>
        <div data-key="property-details-parking" className={cx('property-detail-flex-1')}>
          <SpacedHeadline>{t('properties.parking')}</SpacedHeadline>
          <PropertyInputDetailContainer
            watch={['detailsParking']}
            editPropertyId={propertyId}
            content={(onChange, onCancel, onSave, changed, canSave, getter) => (
              <Input
                {...commonTextAreaProps}
                forceOutline={changed}
                onSave={onSave}
                onCancel={onCancel}
                onChange={onChange('detailsParking')}
                savingState={canSave}
                value={getter('detailsParking')}
                placeholder={t('properties.note_parking')}
              />
            )}
          />
        </div>
        <div data-key="property-details-wifi" className={cx('property-detail-flex-1')}>
          <SpacedHeadline>{t('properties.wifi')}</SpacedHeadline>
          <PropertyInputDetailContainer
            watch={['detailsWifiName', 'detailsWifiPassword', 'detailsWifiDescription']}
            editPropertyId={propertyId}
            content={(onChange, onCancel, onSave, changed, canSave, getter) => (
              <div>
                <Input
                  data-key="property-details-wifi-name"
                  {...commonTextProps}
                  isFirst
                  placeholder={t('properties.wifi_name')}
                  isGrouped
                  forceOutline={changed}
                  value={getter('detailsWifiName')}
                  onChange={onChange('detailsWifiName')}
                />
                <Input
                  data-key="property-details-wifi-password"
                  {...commonTextProps}
                  placeholder={t('properties.wifi_password')}
                  isGrouped
                  forceOutline={changed}
                  value={getter('detailsWifiPassword')}
                  onChange={onChange('detailsWifiPassword')}
                />
                <Input
                  {...commonTextAreaProps}
                  isLast
                  isFirst={false}
                  forceOutline={changed}
                  isGrouped
                  onSave={onSave}
                  onCancel={onCancel}
                  onChange={onChange('detailsWifiDescription')}
                  savingState={canSave}
                  textAreaHeight={106}
                  value={getter('detailsWifiDescription')}
                  placeholder={t('properties.wifi_note')}
                />
              </div>
            )}
          />
        </div>
        <div data-key="property-details-important-information" className={cx('property-detail-flex-1')}>
          <SpacedHeadline>{t('properties.important_note')}</SpacedHeadline>
          <PropertyInputDetailContainer
            watch={['detailsInformation']}
            stateId="detailsInformation"
            editPropertyId={propertyId}
            content={(onChange, onCancel, onSave, changed, canSave, getter) => (
              <Input
                {...commonTextAreaProps}
                forceOutline={changed}
                onSave={onSave}
                onCancel={onCancel}
                onChange={onChange('detailsInformation')}
                savingState={canSave}
                value={getter('detailsInformation')}
                placeholder={t('properties.important_note_note')}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

PropertyDetailContainerBlock.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  propertyId: PropTypes.string.isRequired,
  isSingleColumn: PropTypes.bool,
};

PropertyDetailContainerBlock.defaultProps = {
  isSingleColumn: false,
};

export default PropertyDetailContainerBlock;
