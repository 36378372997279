import * as types from '../../types/index';

export function stripeSetupIntent() {
  return {
    type: types.STRIPE_PM_SETUP_INTENT_SAGA,
  };
}

export function updateCustomerLoadingState(loading) {
  return {
    type: types.STRIPE_PM_SET_CUSTOMER_LOADING_STATE,
    loading,
  };
}

export function updateModalState(data, meta) {
  return {
    type: types.STRIPE_PM_SET_MODAL_STATE,
    data,
    meta,
  };
}

export function resetModalState() {
  return {
    type: types.STRIPE_PM_RESET_MODAL_STATE,
  };
}

export function resetIntentState() {
  return {
    type: types.STRIPE_PM_RESET_INTENT_STATE,
  };
}

export function updateIntentState(data) {
  return {
    type: types.STRIPE_PM_SET_INTENT_STATE,
    data,
  };
}

export function getStripeCustomer() {
  return {
    type: types.STRIPE_PM_GET_CUSTOMER_SAGA,
  };
}

export function setStripeCustomer(data) {
  return {
    type: types.STRIPE_PM_SET_CUSTOMER,
    data,
  };
}

export function getHostStripeStatus() {
  return {
    type: types.STRIPE_PM_GET_HOST_STATUS_SAGA,
  };
}

export function setHostStripeStatus(status) {
  return {
    type: types.STRIPE_PM_SET_HOST_STRIPE_STATUS,
    status,
  };
}

export function addStripeCustomerPaymentMethod(stripePaymentMethod, setAsDefault) {
  return {
    type: types.STRIPE_PM_ADD_CUSTOMER_PAYMENT_METHOD_SAGA,
    stripePaymentMethod,
    setAsDefault,
  };
}

export function removeStripeCustomerPaymentMethod(stripePaymentMethodId) {
  return {
    type: types.STRIPE_PM_REMOVE_CUSTOMER_PAYMENT_METHOD_SAGA,
    stripePaymentMethodId,
  };
}

export function addStripeCustomerSource(stripeSourceToken) {
  return {
    type: types.STRIPE_PM_ADD_CUSTOMER_SOURCE_SAGA,
    stripeSourceToken,
  };
}

export function removeStripeCustomerSource(stripeSourceId) {
  return {
    type: types.STRIPE_PM_REMOVE_CUSTOMER_SOURCE_SAGA,
    stripeSourceId,
  };
}

export function addPlaidStripeCustomerSource(plaidPublicToken, plaidAccountId) {
  return {
    type: types.STRIPE_PM_ADD_PLAID_CUSTOMER_SOURCE_SAGA,
    plaidPublicToken,
    plaidAccountId,
  };
}
