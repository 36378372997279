import React from 'react';
import { connect } from 'react-redux';
import { ModalError } from '@properly/components';
import t from '@properly/localization';
import { setModal } from '../../actions/globalActions';
import { selectGlobalModalResolved } from '../../selectors/globalSelector';
import { modals } from '../../dataConstants';

const CannotSendPropertyJobsModal = props => {
  const { dispatchSetModal, data } = props;

  const isOpen = !!data.isOpen;

  const onClose = () => dispatchSetModal(false, modals.CANNOT_SEND_PROPERTY_JOBS_PERMISSION, {});

  return <ModalError message2={t('permissions.modal.cannot_send_jobs_property')} show={isOpen} onClose={onClose} />;
};

function mapStateToProps() {
  const memo = selectGlobalModalResolved(modals.CANNOT_SEND_PROPERTY_JOBS_PERMISSION);
  return (state, props) => ({
    data: memo(state, props),
  });
}

export default connect(mapStateToProps, {
  dispatchSetModal: setModal,
})(CannotSendPropertyJobsModal);
