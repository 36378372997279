import gql from 'graphql-tag';

export const MUTATION_ADD_COMMENT = gql`
  mutation comment($input: CommentsInput!) {
    Comments(input: $input) {
      CommentResponse {
        commentId
        isSuccessful
        clientMutationId
      }
    }
  }
`;
