"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getJobType = getJobType;
exports.getJobTypeKey = exports.getTitleFromJobRequest = exports.isNoChecklistTitle = void 0;

var _head = _interopRequireDefault(require("ramda/src/head"));

var _map = _interopRequireDefault(require("lodash/map"));

var _localization = _interopRequireDefault(require("@properly/localization"));

var _config = require("@properly/config");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const isNoChecklistTitle = title => String(title || '').toLowerCase().trim() === 'no checklist';

exports.isNoChecklistTitle = isNoChecklistTitle;

const getTitleFromJobRequest = jobRequest => {
  // Get it from the title value,
  const {
    title,
    event,
    jobProgress,
    jobData
  } = jobRequest || {};
  const {
    title: eventTitle
  } = event || {};
  const {
    jobSteps
  } = jobProgress || {};

  if (title && !isNoChecklistTitle(title)) {
    return title;
  } // If it is null or equal to 'No Checklist'
  // Check to see if there are any checklists attached to the job request
  // 1. Checklists title from multiple jobSteps


  const firstChecklist = (0, _head.default)(jobSteps || []);
  const {
    title: firstChecklistTitle
  } = firstChecklist || {};

  if (firstChecklistTitle && !isNoChecklistTitle(firstChecklistTitle)) {
    const checklistCount = (jobSteps || []).length;
    const checklistCountText = checklistCount > 1 ? ` + ${checklistCount - 1}` : '';
    return String(firstChecklistTitle + checklistCountText).trim();
  } // 2. Checklist title from jobData


  if (jobData && jobData.title && !isNoChecklistTitle(jobData.title)) {
    return jobData.title;
  } // 3. Reminder title


  if (eventTitle && !isNoChecklistTitle(eventTitle)) {
    return eventTitle;
  }

  return title || 'No Checklist';
};

exports.getTitleFromJobRequest = getTitleFromJobRequest;

const getJobTypeKey = jobType => Object.keys(_config.mapJobTypeToBackendJobType).find(key => _config.mapJobTypeToBackendJobType[key] === jobType);

exports.getJobTypeKey = getJobTypeKey;

function getJobType() {
  return (0, _map.default)(_config.mapJobTypeToBackendJobType, (value, key) => ({
    value,
    title: (0, _localization.default)(`reports.jobTypes.${key}`)
  }));
}