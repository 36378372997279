import React from 'react';
import PropTypes from 'prop-types';
import { PropertyImage, Box, RoundSelect } from '@properly/components';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const PropertyChecklistClone = ({ property, selected, onClick }) => {
  const { title, city } = property || {};
  const firstAddressArray = property.address && property.address.AddressArray && property.address.AddressArray[0];
  return (
    <Box isFirst noPadding styleOverwrite={{ height: 72 }} isLast>
      <div className={cx('property-cclone')}>
        <div className={cx('property-cclone__inner')}>
          <PropertyImage.Small pic={property.pictureUrl} />
          <div className={cx('property-cclone__text')}>
            <div className={cx('property-cclone__text-top')}>
              <span className={cx('property-cclone__cut-text')}>{title || firstAddressArray}</span>
            </div>
            <div className={cx('property-cclone__text-bottom')}>{firstAddressArray}</div>
            <div className={cx('property-cclone__text-bottom')}>{city}</div>
          </div>
          <div className={cx('property-cclone__check')}>
            <RoundSelect style={{ height: 22, width: 22 }} type="quarter" selected={selected} onChange={onClick} />
          </div>
        </div>
      </div>
    </Box>
  );
};

PropertyChecklistClone.propTypes = {
  property: PropTypes.shape({}).isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default PropertyChecklistClone;
