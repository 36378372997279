import React from 'react';
import PropTypes from 'prop-types';
import { pictureSizes, generatePictureUrl } from '@properly/common';
import indexOf from 'lodash/indexOf';
import map from 'lodash/map';
import chunk from 'lodash/chunk';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const genImage = image => ({
  backgroundImage: `url(${generatePictureUrl(image, pictureSizes.PROFILE_PICTURE)})`,
});

function handleClick(images, onClick, needle) {
  if (!onClick) return undefined;
  return e => {
    const index = indexOf(images, needle);
    const newIndex = index !== -1 ? index : 0;
    onClick(newIndex, e);
  };
}

const ContactImageGrid = ({ images, onClick, itemsPerRow = 5, height = 58 }) => {
  const calcWidthPercent = `${100 / itemsPerRow}%`;
  return (
    <div>
      {map(chunk(images, itemsPerRow), (imageRow, key) => (
        <div key={key} className={cx('contact__imagegrid-row')}>
          {map(imageRow, (image, keyImg) => (
            <div
              key={`${image}${keyImg}`}
              data-key={`${image}${keyImg}`}
              onClick={handleClick(images, onClick, image)}
              style={{ height, maxWidth: calcWidthPercent, width: calcWidthPercent }}
              className={cx('contact__imagegrid-item')}
            >
              <div className={cx('contact__imagegrid-image')} style={genImage(image)} />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

ContactImageGrid.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ContactImageGrid;
