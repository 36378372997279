import React, { PureComponent } from 'react';
import t from '@properly/localization';
import { CalendarTileButtons } from '@properly/components';
import { jobRequestStatus } from '@properly/config';
import lodashResult from 'lodash/result';
import memoize from 'lodash/memoize';
import {
  TodosElementMain,
  TodosElementMarker,
  TodosElementMainInner,
  TodosElementSection,
  TodosTextEle,
  TodosElementMainMain,
} from '../../../components/TodosElements';

export const MODES = {
  edit: 'edit',
  sendmore: 'sendmore',
  cancel: 'cancel',
  schedule: 'schedule',
};

export const getBtnMode = memoize(
  (type, status, eventStatus) => {
    const matchButtonMode = (x, y, z) => type === x && status === y && eventStatus === z;
    // Pending
    if (matchButtonMode(4, 1, 1)) return MODES.edit;
    if (matchButtonMode(3, 1, 1)) return MODES.edit;
    if (matchButtonMode(3, 1, 2)) return MODES.edit;
    if (matchButtonMode(3, 1, 3)) return MODES.cancel;
    // Declined Job
    if (matchButtonMode(4, 4, 1)) return MODES.sendmore;
    if (matchButtonMode(3, 4, 1)) return MODES.sendmore;
    if (matchButtonMode(3, 4, 2)) return MODES.edit;
    if (matchButtonMode(3, 4, 3)) return MODES.cancel;
    // Cancelled By Cleaner
    if (matchButtonMode(4, 8, 1)) return MODES.edit;
    if (matchButtonMode(3, 8, 1)) return MODES.edit;
    if (matchButtonMode(3, 8, 2)) return MODES.edit;
    if (matchButtonMode(3, 8, 3)) return MODES.cancel;
    // Cancelled By Host
    if (matchButtonMode(3, 3, 2)) return MODES.edit;
    if (matchButtonMode(3, 3, 3)) return MODES.cancel;
    // Cancelled By Host
    if (matchButtonMode(3, 2, 2)) return MODES.edit;
    if (matchButtonMode(3, 2, 3)) return MODES.cancel;
    if (status === 3 || status === 8) return MODES.edit;
    return 'schedule';
  },
  (type, status, eventStatus) => `${type}-${status}-${eventStatus}`,
);

function changeBtnText(statusRaw, eventStatusRaw) {
  let changedTitle = false;
  if (statusRaw === jobRequestStatus.StateDeclined && eventStatusRaw === 1)
    changedTitle = t('calendartile.modes.sendmore');
  if (statusRaw === jobRequestStatus.StateCleanerCancelled && eventStatusRaw === 1)
    changedTitle = t('calendartile.modes.sendnew');
  if (statusRaw === jobRequestStatus.StateCancelled && eventStatusRaw === 1)
    changedTitle = t('calendartile.modes.sendnew');
  return changedTitle;
}

class TodosEle extends PureComponent {
  static defaultProps = {
    handleClickBtn: () => {},
  };

  get title() {
    return lodashResult(this.props.calendarEvent, ['triggerResolved', 'title']) || t('todos.job');
  }

  render() {
    const { isRed, onClick, calendarEvent, isClickable, handleClickBtn } = this.props;
    const btnMod = isRed ? 'type-full-cancelled' : 'type-full-primary';
    const changedTitle = changeBtnText(calendarEvent.status, calendarEvent.eventStatus);
    return (
      <TodosElementMain onClick={isClickable && onClick} isRed={isRed} isClickable={isClickable}>
        <TodosElementMainInner>
          <TodosElementMainMain>
            <TodosElementSection width="76px">
              <TodosTextEle modifier={['txt--l6m', 'color--black']}>{this.title}</TodosTextEle>
              <TodosTextEle modifier={['txt--l7', 'color--red', 'mtop-6']}>
                {calendarEvent.isCanceled && <span>{t('calendartile.cancelled')}</span>}
                {calendarEvent.isChanged && <span>{t('calendartile.changed')}</span>}
              </TodosTextEle>
            </TodosElementSection>
            <TodosElementSection width="250px">
              <TodosTextEle modifier={['txt--l7m', 'color--black']}>{calendarEvent.propertyTitle}</TodosTextEle>
              <TodosTextEle modifier={['txt--l7', 'color--black', 'mtop-6']}>
                {calendarEvent.propertyAddress}
              </TodosTextEle>
              <TodosTextEle modifier={['txt--l7', 'color--grey', 'mtop-6']}>
                {calendarEvent.bookingInfoFormatted}
              </TodosTextEle>
            </TodosElementSection>
            {isRed && calendarEvent.jobRequestResolved && (
              <TodosElementSection width="130px">
                <TodosTextEle modifier={['txt--l7m', 'color--red']}>
                  {t(calendarEvent.jobRequestStatusString)}
                </TodosTextEle>
                <TodosTextEle modifier={['txt--l7', 'color--red', 'mtop-6']}>
                  {calendarEvent.startMoment.format('LT')} - {calendarEvent.endMoment.format('LT')}
                </TodosTextEle>
                <TodosTextEle modifier={['txt--l7', 'color--red', 'mtop-6']}>
                  {calendarEvent.jobRequestResolved.cleanerName}
                </TodosTextEle>
              </TodosElementSection>
            )}
          </TodosElementMainMain>
          <TodosElementSection width="226px" noPadding>
            <CalendarTileButtons
              handleClick={handleClickBtn}
              mode={getBtnMode(calendarEvent.type, calendarEvent.status, calendarEvent.eventStatus)}
              size="medium"
              title={changedTitle}
              data={calendarEvent}
              mainBtnConfig={[btnMod, 'size-medium', 'flex-1', 'miwidth-130']}
              subBtnConfig={['type-border-grey', 'size-medium', 'flex-1', 'miwidth-0']}
            />
          </TodosElementSection>
        </TodosElementMainInner>
        {isRed && <TodosElementMarker />}
      </TodosElementMain>
    );
  }
}

export { TodosEle };
