import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, mapProps, flattenProp } from 'recompose';
import t from '@properly/localization';
import { SpaceFlexi, SpacedHeadline } from '@properly/components';
import pick from 'lodash/pick';
import noop from 'lodash/noop';
import indexOf from 'lodash/indexOf';
import { mapToMainState } from './permissionhelper';
import {
  JobRequestSpacerWrap,
  JobRequestTextMain,
  JobRequestPermissionBox,
} from '../../../components/JobRequestElements';
import { handlePermissionActionSaga } from './PermssionsActions';
import { permissionMap } from './PermissionsConstants';
import PermissionList from './PermissionList';

const spacing = '2px';
const defaultSpacingExtra = {
  left: '38px',
  top: '12px',
  bottom: '12px',
  right: '18px',
};

class PermissionSection extends PureComponent {
  static propTypes = {
    sectionValue: PropTypes.shape({}),
  };

  selectOption = val => () => {
    this.props.handlePermissionActionSaga('changeSubmitPermission', {
      value: this.props.sectionValue.set('type', val),
    });
  };

  selectChild = path => e => {
    this.props.handlePermissionActionSaga('changeSubmitPermission', {
      value: this.props.sectionValue.setIn(path, e.target.checked),
    });
  };

  isSelected = val => this.props.sectionValue.get('type') === val;

  genOptions = val => ({
    selected: this.isSelected(val),
    onClick: this.selectOption(val),
    disabled: indexOf(this.props.sectionDisabledSection, val) !== -1,
  });

  renderPer(selectAll) {
    const genPath = permissionId => ['permissions', permissionId];
    return (
      <PermissionList
        permissionMap={permissionMap}
        spaceAll
        blackList={selectAll ? [] : this.props.sectionBlackListenPermissions}
        keyPrefix="mainmodal"
        onChangeFunc={permissionId => (selectAll ? noop : this.selectChild(genPath(permissionId)))}
        isDisabled={() => selectAll}
        isSelected={permissionId => (selectAll ? true : this.props.sectionValue.getIn(genPath(permissionId)))}
      />
    );
  }

  render() {
    const option1 = this.genOptions('option1');
    const option2 = this.genOptions('option2');
    return (
      <JobRequestSpacerWrap left="24px" top="20px" bottom="20px" right="24px" flexGrow>
        <SpacedHeadline size="small">{t('permissions.modal.full_access')}</SpacedHeadline>
        <JobRequestPermissionBox {...option1}>
          <div>
            <JobRequestTextMain>{t('permissions.modal.full_access_info')}</JobRequestTextMain>
            <SpaceFlexi type="vertical" size={spacing} />
            <JobRequestTextMain type="sub">{t('permissions.modal.full_access_info_l2')}</JobRequestTextMain>
          </div>
        </JobRequestPermissionBox>
        {option1.selected && (
          <JobRequestSpacerWrap {...defaultSpacingExtra}>
            <JobRequestTextMain type="sub">{t('permissions.modal.full_access_info_full')}</JobRequestTextMain>
            {this.renderPer(true)}
          </JobRequestSpacerWrap>
        )}
        <SpacedHeadline size="small">{t('permissions.modal.specific_access')}</SpacedHeadline>
        <JobRequestPermissionBox {...option2}>
          <div>
            <JobRequestTextMain>{t('permissions.modal.specific_access_info')}</JobRequestTextMain>
            <SpaceFlexi type="vertical" size={spacing} />
            <JobRequestTextMain type="sub">{t('permissions.modal.specific_access_info_l2')}</JobRequestTextMain>
          </div>
        </JobRequestPermissionBox>
        {option2.selected && (
          <JobRequestSpacerWrap {...defaultSpacingExtra}>
            <JobRequestTextMain type="sub">{t('permissions.modal.info_select_send_etc')}</JobRequestTextMain>
            {this.renderPer()}
          </JobRequestSpacerWrap>
        )}
      </JobRequestSpacerWrap>
    );
  }
}

export default compose(
  connect(
    mapToMainState,
    { handlePermissionActionSaga },
  ),
  mapProps(props =>
    pick(props, ['activeSectionData', 'handlePermissionActionSaga', 'sectionBlackListenPermissions', 'isInternalColl']),
  ),
  flattenProp('activeSectionData'),
)(PermissionSection);
