import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import t from '@properly/localization';
import map from 'ramda/src/map';
import filter from 'ramda/src/filter';
import { SpaceBase, Button } from '@properly/components';
import { isPropertyOwner, isCompleteAddress } from '@properly/common';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import { filterPropertySidebar } from '../../../../helper/herbert';
import InfoMessage from '../../../../components/InfoMessage/index';
import { trackMergeDialogShow } from '../../../../actions/trackingEvents';
import Property from '../../../../model/property';

class PropertyMessages extends React.PureComponent {
  static propTypes = {
    clickEdit: PropTypes.func,
    clickShowMerge: PropTypes.func,
    clickNoMerge: PropTypes.func,
    propertyData: PropTypes.instanceOf(Property),
    propertyId: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      isMergeAble: undefined,
      mergeAbles: [],
    };
  }

  componentDidMount() {
    this.checkIfMergeable(this.props);
  }

  checkIfMergeable(props) {
    if (props.propertyData && this.calledMergeable !== props.propertyData.objectId) {
      const res = map(
        property => property.objectId,
        filter(
          property =>
            property.address &&
            property.address.AddressArray &&
            props.propertyData &&
            props.propertyData.address &&
            props.propertyData.address.AddressArray &&
            !!props.propertyData.address.AddressArray[0]?.trim() &&
            property.address.AddressArray[0] === props.propertyData.address.AddressArray[0] &&
            property.objectId !== props.propertyData.objectId &&
            isPropertyOwner(property.ownerRole, this.props.currentUser),
          filterPropertySidebar(props.properties.toArray()),
        ),
      );
      if (res.length > 0) {
        this.setState({
          isMergeAble: props.propertyData.objectId,
          mergeAbles: res,
        });
        trackMergeDialogShow();
      }
      this.calledMergeable = props.propertyData.objectId;
    }
  }

  showMergeDialog = () => {
    const currentProperty = this.props.propertyData.objectId;
    const mapped = filter(property => property.objectId !== currentProperty, this.state.mergeAbles);
    const concatted = [currentProperty, ...mapped];
    this.props.clickShowMerge(concatted);
  };

  componentWillReceiveProps(nextProps) {
    this.checkIfMergeable(nextProps);
  }

  clickEdit = () => {
    this.props.clickEdit(this.props.propertyData.objectId);
  };

  clickNoMerge = () => {
    this.props.clickNoMerge(this.props.propertyData.objectId);
  };

  render() {
    if (!this.props.propertyData) return null;
    return (
      <div>
        {this.state.isMergeAble === this.props.propertyData.objectId && !this.props.propertyData.noMerge && (
          <div>
            <InfoMessage text={t('properties.merge_info')} icon="warning" color="red" isMergeInfo>
              <Button onClick={this.showMergeDialog} types={['type-full-cancelled', 'size-medium', 'height-32']}>
                {t('properties.view_dups')}
              </Button>
              <SpaceBase />
              <Button
                onClick={this.clickNoMerge}
                types={['type-border-grey', 'size-medium', 'height-32', 'background-trans', 'fontw-normal']}
              >
                {t('properties.no_thanks')}
              </Button>
            </InfoMessage>
            <SpaceBase />
          </div>
        )}
        {!isCompleteAddress(this.props.propertyData.address, this.props.propertyData) && (
          <div>
            <InfoMessage text={t('properties.incomplete_address_info')} icon="warning" color="red" isPrompt>
              <Button onClick={this.clickEdit} types={['type-full-cancelled', 'size-medium', 'height-32']}>
                {t('properties.edit_property')}
              </Button>
            </InfoMessage>
            <SpaceBase />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const currentUser = selectorsGlobal.selectCurrentUser()(state, props);
  const propertyData = selectorsGlobal.selectProperty(props.propertyId)(state, props);
  const properties = selectorsGlobal.selectProperties()(state, props);
  return {
    currentUser,
    propertyData,
    properties,
  };
}

export default connect(mapStateToProps, {})(PropertyMessages);
