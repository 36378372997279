import React from 'react';
import { connect } from 'react-redux';
import { Modal } from '@properly/components';
import keys from 'lodash/keys';
import AccountOAuthContainer from '../containers/AccountOAuthContainer';
import * as globalSelectors from '../../../../selectors/globalSelector';
import * as selectorsAccount from '../state/AccountSelectors';
import * as AccountActions from '../state/AccountActions';

const AccountOAuthContainerModal = ({ user, oauthState, closeErrorModal }) => {
  const isAuthed = !!user;
  const show = isAuthed && keys(oauthState.toJS()).length > 0;
  return (
    <Modal onClose={() => {}} show={show}>
      <div style={{ height: '100%' }}>
        <AccountOAuthContainer onClose={closeErrorModal} />
      </div>
    </Modal>
  );
};

function mapStateToProps(state, props) {
  return {
    user: globalSelectors.selectCurrentUser()(state, props),
    oauthState: selectorsAccount.selectStatesSingle('oauth')(state, props),
  };
}

export default connect(mapStateToProps, {
  closeErrorModal: AccountActions.closeErrorModal,
})(AccountOAuthContainerModal);
