"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _result = _interopRequireDefault(require("lodash/result"));

var _keys = _interopRequireDefault(require("lodash/keys"));

var _memoize = _interopRequireDefault(require("lodash/memoize"));

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

var _config = require("@properly/config");

var _common = require("@properly/common");

var _localization = _interopRequireDefault(require("@properly/localization"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const memoizeMomentDate = (0, _memoize.default)((date, timezone) => (0, _momentTimezone.default)(date).tz(timezone));
const memoizeJsDate = (0, _memoize.default)(date => (0, _momentTimezone.default)(date).toDate());
const memoizeDateFormat = (0, _memoize.default)((date, outputFormat) => (0, _momentTimezone.default)(date).format(outputFormat));

function mapJobRequestStatusToColor(status) {
  if (status === 'jobstatus.delayed') return 'delayed';
  if (status === 'jobstatus.seen' || status === 'jobstatus.pending') return 'pending';
  if (status === 'jobstatus.accepted') return 'accepted';
  if (status === 'jobstatus.cancelled' || status === 'jobstatus.declined') return 'cancelled';
  if (status === 'jobstatus.started') return 'progress';
  if (status === 'jobstatus.completed') return 'finished';
  return 'accepted';
}

class ParseCalendarEvent {
  constructor(objectId, type, jobRequestId, bookingId, triggerId, propertyId, timeZone, propertyTitle, startTime, endTime, status, eventStatus, needsCTA, deleted, payload, days, propertyAddress, createdAt, jobSnapshotId) {
    this.objectId = objectId;
    this.type = type;
    this.jobRequestId = jobRequestId;
    this.jobSnapshotId = jobSnapshotId;
    this.bookingId = bookingId;
    this.triggerId = triggerId;
    this.propertyId = propertyId;
    this.timeZone = timeZone;
    this.propertyTitle = propertyTitle;
    this.propertyAddress = propertyAddress;
    this.startTime = startTime;
    this.endTime = endTime;
    this.status = status;
    this.eventStatus = eventStatus;
    this.needsCTA = needsCTA;
    this.deleted = !!deleted;
    this.payload = payload;
    this.days = days;
    this.dateFormat = 'YYYYMMDD';
    this.createdAt = createdAt;
  }

  get jobRequest() {
    return this.jobRequestResolved;
  }

  get booking() {
    return this.bookingResolved;
  }

  get reminder() {
    return this.triggerResolved;
  }

  get jobRequestResolved() {
    return (0, _result.default)(this, ['payload', 'jobRequest']);
  }

  get jobRequestIsDelayed() {
    return this.status === _config.jobRequestStatus.StateAccepted && (0, _momentTimezone.default)().toDate() - this.startMomentDate > 0;
  }

  get startMoment() {
    return memoizeMomentDate(this.startTime, this.timeZone);
  }

  get startMomentDate() {
    return memoizeJsDate(this.startMoment);
  }

  get triggerTime() {
    const time = (0, _result.default)(this.triggerResolved, ['time', 'iso']) || (0, _result.default)(this.triggerResolved, ['time']);
    return memoizeMomentDate(time, this.timeZone);
  }

  get endMoment() {
    return memoizeMomentDate(this.endTime, this.timeZone);
  }

  get bookingResolved() {
    return (0, _result.default)(this, ['payload', 'booking']);
  }

  get triggerResolved() {
    return (0, _result.default)(this, ['payload', 'trigger']);
  }

  get triggerJobId() {
    return (0, _result.default)(this, ['triggerResolved', 'jobId']) || (0, _result.default)(this, ['triggerResolved', 'id']);
  }

  get bookingArrival() {
    return (0, _result.default)(this.bookingResolved, ['arrivalDate', 'iso']) || this.bookingResolved.arrivalDate;
  }

  get bookingDeparture() {
    return (0, _result.default)(this.bookingResolved, ['departureDate', 'iso']) || this.bookingResolved.departureDate;
  }

  get bookingDurationDays() {
    return (0, _result.default)(this.bookingResolved, ['bookingDurationDays']);
  }

  get bookingArrivalMoment() {
    return memoizeMomentDate(this.bookingArrival, this.timeZone);
  }

  get bookingDepartureMoment() {
    return memoizeMomentDate(this.bookingDeparture, this.timeZone);
  }

  get bookingArrivalFormat() {
    return this.bookingResolved && this.bookingResolved.arrivalLocalDay && `20${this.bookingResolved.arrivalLocalDay}` || memoizeDateFormat(this.bookingArrivalMoment, this.dateFormat);
  }

  get bookingDepartureFormat() {
    return this.bookingResolved && this.bookingResolved.departureLocalDay && `20${this.bookingResolved.departureLocalDay}` || memoizeDateFormat(this.bookingDepartureMoment, this.dateFormat);
  }

  get id() {
    return this.objectId;
  }

  get firstDay() {
    return `20${this.days[0].toString()}`;
  }

  isBookingStartDay(date) {
    return this.bookingArrivalFormat === date;
  }

  isBookingEndDay(date) {
    return this.bookingDepartureFormat === date;
  }

  get isTrigger() {
    return !!this.triggerResolved;
  }

  get isJobRequest() {
    return !!this.jobRequestResolved;
  }

  get triggerTitle() {
    return (0, _result.default)(this.triggerResolved, ['title']);
  }

  get isTriggerAndJob() {
    return this.isTrigger && this.isJobRequest;
  }

  get isTriggerOnly() {
    return this.isTrigger && !this.isJobRequest;
  }

  get isJobRequestOnly() {
    return !this.isTrigger && this.isJobRequest;
  }

  get bookingInfo() {
    if (!this.bookingResolved) return {};
    const partner = this.bookingResolved.partner === 'iCal' ? this.bookingResolved.partner : (0, _common.capitalizeFirstLetter)(this.bookingResolved.partner);
    const guest = this.bookingResolved.guestName;
    return {
      partner,
      guest,
      arrivalDate: this.bookingArrivalMoment,
      departureDate: this.bookingDepartureMoment,
      numberOfGuests: this.bookingResolved.numberOfGuests
    };
  }

  get isCanceled() {
    return this.eventStatus === 3;
  }

  get isChanged() {
    return this.eventStatus === 2;
  }

  get jobRequestStatusString() {
    if (this.jobRequestIsDelayed) {
      return 'jobstatus.delayed';
    }

    if (this.status === _config.jobRequestStatus.StateCreated && this.jobRequestResolved.seen) {
      return 'jobstatus.seen';
    }

    switch (this.status) {
      case _config.jobRequestStatus.StateCreated:
        return 'jobstatus.pending';

      case _config.jobRequestStatus.StateAccepted:
        return 'jobstatus.accepted';

      case _config.jobRequestStatus.StateCancelled:
      case _config.jobRequestStatus.StateCleanerCancelled:
        return 'jobstatus.cancelled';

      case _config.jobRequestStatus.StateDeclined:
        return 'jobstatus.declined';

      case _config.jobRequestStatus.StateStarted:
      case _config.jobRequestStatus.StatePaused:
        return 'jobstatus.started';

      case _config.jobRequestStatus.StateFinished:
        return 'jobstatus.completed';

      default:
        return null;
    }
  }

  matchSignature(x, y, z) {
    return this.type === x && this.status === y && this.eventStatus === z;
  } // TODO: this translation should not be apart of the model


  get bookingInfoFormatted() {
    const obj = this.bookingInfo;
    if ((0, _keys.default)(obj).length === 0) return '';
    const guests = obj.numberOfGuests > 0 ? ` • ${(0, _localization.default)('calendartile.guest', {
      context: obj.numberOfGuests
    })}` : '';
    return `${obj.partner}: ${obj.arrivalDate.format('L')} – ${obj.departureDate.format('L')}${guests} • ${obj.guest}`;
  }

  get jobRequestColor() {
    return mapJobRequestStatusToColor(this.jobRequestStatusString);
  }

}

exports.default = ParseCalendarEvent;