import React from 'react';
import PropTypes from 'prop-types';
import { Box, Icon, Small, Micro } from '@properly/components';
import map from 'lodash/map';
import t from '@properly/localization';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const MyBox = ({
  icon: BoxIcon,
  text,
  isClean,
  isConnect,
  isAdd,
  isEdit,
  isLast,
  isFirst,
  onConnect,
  onAdd,
  isMerge,
  onClick,
  onEdit,
  data,
  hasError,
  onboardingMobile,
}) => {
  const style = isClean
    ? { backgroundColor: 'transparent', border: '1px solid transparent' }
    : {
        ...(onboardingMobile && { boxShadow: '0 2px 6px 0 rgba(146, 146, 146, 0.3)' }),
        backgroundColor: '#fff',
      };
  if (isMerge) {
    style.cursor = 'pointer';
  }

  const myBoxIcon =
    typeof BoxIcon === 'string' ? (
      <Icon src={BoxIcon} byWidth height="22px" width="22px" />
    ) : (
      <BoxIcon height="22px" width="22px" />
    );

  return (
    <Box isLast={isLast} isFirst={isFirst} onClick={onClick} focus={hasError} styleOverwrite={style}>
      <div data-key={text} className={cx('section__inner')}>
        {!isEdit && myBoxIcon}
        {!isEdit && !isAdd && (
          <div className={cx('section__left')}>
            {!isMerge && <Small>{text}</Small>}
            {isMerge && <Small type="orange">{text}</Small>}
          </div>
        )}
        {isEdit && !hasError && (
          <div className={cx('section__left', 'section__left--nomargin')}>
            <Small>{text}</Small>
          </div>
        )}
        {isEdit && hasError && (
          <div className={cx('section__left', 'section__left--nomargin')}>
            <div className={cx('section__left-inner')}>
              <div className={cx('section__left-icon')}>
                <Icon.IcError1 byWidth />
              </div>
              <Small style={{ float: 'left' }}>{text}</Small>
              <div className={cx('section__left-icon-txt')}>{t('account.connected_account_warning')}</div>
            </div>
          </div>
        )}
        {isEdit && (
          <div onClick={() => onEdit(data)} className={cx('section__right', 'pointer')}>
            <Small type="orange">{t('accountprovidersection.edit')}</Small>
          </div>
        )}
        {isConnect && (
          <div onClick={onConnect} className={cx('section__right', 'pointer')}>
            {onboardingMobile ? (
              <Icon.IcPlusOrange onClick={onConnect} byWidth height="12px" width="12px" />
            ) : (
              <Small type="orange">{t('accountprovidersection.connect')}</Small>
            )}
          </div>
        )}
        {isAdd && (
          <div onClick={onAdd} className={cx('section__left', 'pointer')}>
            <Small type="orange">{t('accountprovidersection.add_account')}</Small>
          </div>
        )}
      </div>
    </Box>
  );
};

const AccountProviderSection = ({ text, icon, accounts, onConnect, onAdd, onEdit, onboardingMobile }) => (
  <>
    {accounts.length === 0 && (
      <MyBox
        icon={icon}
        isLast
        isFirst
        onEdit={onEdit}
        onConnect={onConnect}
        onAdd={onAdd}
        isConnect
        text={text}
        onboardingMobile={onboardingMobile}
      />
    )}
    {accounts.length !== 0 && (
      <>
        <MyBox icon={icon} text={text} isClean />
        {map(accounts, (account, key) => (
          <MyBox
            key={key}
            hasError={account.connectionIssue}
            data={account}
            icon={Icon.IcPlus2}
            isEdit
            text={account.resolvedName || account.label}
            onEdit={onEdit}
            onConnect={onConnect}
            onAdd={onAdd}
            isFirst={key === 0}
            onboardingMobile={onboardingMobile}
          />
        ))}
        <MyBox
          onEdit={onEdit}
          icon={Icon.IcPlus2}
          isAdd
          isLast
          onConnect={onConnect}
          onAdd={onAdd}
          onboardingMobile={onboardingMobile}
        />
      </>
    )}
  </>
);

const Light = ({ icon, id, children, text }) => (
  <div className={cx('section__light')}>
    <div className={cx('section__light__txt')}>
      <Small>{text}</Small>
      <Micro>
        {t('account.property_id')} {id}
      </Micro>
    </div>
    <img src={icon} className={cx('section__light-icon')} alt="" />
    {children}
  </div>
);

AccountProviderSection.Box = MyBox;
AccountProviderSection.Light = Light;

AccountProviderSection.defaultProps = {
  accounts: [],
};

AccountProviderSection.propTypes = {
  text: PropTypes.string.isRequired,
  onAdd: PropTypes.func,
  onConnect: PropTypes.func,
  onEdit: PropTypes.func,
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      resolvedName: PropTypes.string,
    }),
  ),
  icon: PropTypes.string.isRequired,
};

export default AccountProviderSection;
