import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@properly/components';
import classNames from 'classnames/bind';
import { pictureSizes, generatePictureUrl } from '@properly/common';
import styles from './index.module.css';
import SmallStepsList from '../SmallStepsList';

const cx = classNames.bind(styles);

const ChecklistSidebarItem = ({
  image,
  active,
  needsVerify,
  title,
  steps,
  noPadding,
  children,
  color,
  stepsSize,
  onClick,
  identifier,
  isRawImage,
  style,
  ...more
}) => {
  const genImage = (imageInner, raw) => {
    const finalImage = raw ? imageInner : generatePictureUrl(imageInner, pictureSizes.SMALL_LARGE);
    return {
      backgroundImage: `url(${finalImage})`,
    };
  };
  const obj = {};
  if (identifier) obj['data-ident'] = identifier;
  if (style) obj.style = style;

  const handleClick = onClick || function() {};

  return (
    <div
      {...obj}
      data-key={more['data-key']}
      onClick={handleClick}
      className={cx('item', {
        'item--actionable': !!onClick,
        'item--active': active,
        'item--nopadding': noPadding,
        'item--grey': color === 'grey',
      })}
    >
      <div className={cx('item__inner')}>
        <div className={cx('item__inner-spacer')} />
        <div className={cx('item__inner-inner')}>
          {image && (
            <div className={cx('item__image')} style={genImage(image, isRawImage)}>
              {needsVerify && (
                <div className={cx('item__icon')}>
                  <Icon.IcSmVerify2 />
                </div>
              )}
            </div>
          )}
          {!image && <SmallStepsList size={stepsSize} steps={steps} title={title} />}
          {children}
        </div>
      </div>
    </div>
  );
};

ChecklistSidebarItem.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  identifier: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  active: PropTypes.bool,
  isRawImage: PropTypes.bool,
  noPadding: PropTypes.bool,
  stepsSize: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      note: PropTypes.string,
    }),
  ),
  needsVerify: PropTypes.bool,
};

export default ChecklistSidebarItem;
