import { Component } from 'react';
import PropTypes from 'prop-types';

class ContactEmailPhoneWrap extends Component {
  constructor(props) {
    super(props);
    this.state = { mode: 'phone' };
  }

  shouldComponentUpdate() {
    return true;
  }

  setMode(mode) {
    this.setState({ mode });
  }

  render() {
    return this.props.render(this.state.mode, this.setMode.bind(this));
  }
}

ContactEmailPhoneWrap.propTypes = {
  render: PropTypes.func.isRequired,
};

export default ContactEmailPhoneWrap;
