import React from 'react';
import PromiseProps from 'promise-props';

import reduce from 'lodash/reduce';
import map from 'lodash/map';
import PropTypes from 'prop-types';

function funcToPromise(func) {
  return new Promise(resolve => {
    if (func) func(res => resolve(res));
  });
}

function reduceToObj(array) {
  return reduce(
    array,
    (acc, item) => {
      acc[item.key] = funcToPromise(item.import);
      return acc;
    },
    {},
  );
}

const propTypes = {
  sagas: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      import: PropTypes.func.isRequired,
    }),
  ),
  reducers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      import: PropTypes.func.isRequired,
    }),
  ),
  dataLoaders: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      mode: PropTypes.string.isRequired,
    }),
  ),
};

const wrapper = params => {
  PropTypes.checkPropTypes(propTypes, params, 'prop', 'hoc/PageLoad');
  const {
    sagas, // [{ sagaKey, sagaImport }]
    reducers, // [{ reducerKey, reducerImport }]
    dataLoaders, // [{ mode, key }]
  } = params;
  return Component => {
    class PageLoad extends React.Component {
      static contextTypes = {
        store: PropTypes.shape({}).isRequired,
      };

      state = {
        loading: false,
        error: false, // eslint-disable-line
      };

      componentWillMount() {
        this.setState({ loading: true });

        const { injectSagas, injectReducer, dispatch } = this.context.store;

        const promise = PromiseProps({
          sagaImport: PromiseProps(reduceToObj(sagas)),
          reducerImport: PromiseProps(reduceToObj(reducers)),
          dataLoad: new Promise((resolve, reject) => {
            dispatch({
              type: 'PAGE_LOAD',
              pro: { resolve, reject },
              dataLoaders,
            });
          }),
        });
        promise.then(
          res => {
            // import sagas
            map(res.sagaImport, (item, key) => injectSagas(key, item));

            // import reducers
            map(res.reducerImport, (item, key) => injectReducer(key, item));
            this.setState({ loading: false });
          },
          () => {
            this.setState({ loading: false, error: true }); // eslint-disable-line
          },
        );
      }

      render() {
        return <Component loading={this.state.loading} {...this.props} />;
      }
    }
    return PageLoad;
  };
};

export default wrapper;
