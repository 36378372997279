import React, { Component } from 'react';
import t from '@properly/localization';
import log from 'loglevel';
import { connect } from 'react-redux';
import { Input, Regular, Small, SpaceTiny, SpaceBase, SpaceMedium, Button } from '@properly/components';
import { isMobileUser } from '@properly/common';
import { setNewPassword } from '../desktopApp/data';
import { becomeUserSaga } from './LoginSignUpActions';
import LoginWrapContainer from './LoginWrapContainer';

class NewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pw: '',
      pwRetype: '', // eslint-disable-line
      validToken: false,
      email: '',
      token: '',
      showErrors: false,
      isSubmitting: false,
      showSuccessMobile: false,
      errorCode: 1, // 0 is no error, 1 is password too short, 2 is passwords don't match, 3 is server error
    };

    this.errorMsgs = {
      0: '',
      1: t('newpassword.password_short'),
      2: t('newpassword.nomatch'),
      3: t('newpassword.server_error'),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeRetype = this.handleChangeRetype.bind(this);
  }

  componentWillMount() {
    const { token, email } = this.props.location.query;
    if (!token) {
      log.error('No token provided from route', this.props.location);
      this.setState({ validToken: false });
    } else {
      this.setState({
        validToken: true,
        email,
        token,
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.errorCode) {
      this.setState({
        showErrors: true,
      });
    } else {
      this.setState({
        showErrors: false,
        errorCode: 0,
        isSubmitting: true,
      });
      setNewPassword(this.state.pw, this.state.token)
        .then(res => {
          if (!isMobileUser()) {
            this.props.becomeUserSaga(res.sessionToken);
          } else {
            this.setState({ showSuccessMobile: true, isSubmitting: false });
          }
        })
        .catch(() => {
          this.setState({
            errorCode: 3,
            showErrors: true,
            isSubmitting: false,
          });
          setTimeout(() => {
            this.setState({
              errorCode: 0,
              showErrors: false,
            });
          }, 10000);
          log.error('handleSubmit - newpassword', e);
        });
    }
  }

  handleChange(event) {
    this.setState({ pw: event.target.value });
  }

  handleChangeRetype(event) {
    this.setState({ pwRetype: event.target.value }); // eslint-disable-line
    this.checkPasswords(this.state.pw, event.target.value);
  }

  checkPasswords(pw1, pw2) {
    const isPasswordTooShort = pw1.length < 6 || pw2.length < 6;
    const doPasswordsMatch = pw1 === pw2;
    let errorCode = 0;

    if (isPasswordTooShort) {
      errorCode = 1;
    } else if (!doPasswordsMatch) {
      errorCode = 2;
    }

    this.setState({
      errorCode,
    });
  }

  render() {
    const error = this.state.showErrors ? this.errorMsgs[this.state.errorCode] : false;
    const { isWaiting } = this.props.loginSignUpReducers;
    return (
      <LoginWrapContainer
        center
        withMaxWidth
        withSubHeadline
        loading={isWaiting || this.state.isSubmitting}
        title={t('login.create_new', {})}
      >
        <Regular type={this.state.validToken ? 'red' : ''}>
          {this.state.validToken ? (
            <span>
              {t('login.enter_new_password', {})} <Regular type="bold">{this.state.email}</Regular>{' '}
              {t('login.account', {})}
            </span>
          ) : (
            t('login.invalid_token')
          )}
        </Regular>
        <SpaceMedium />
        <form onSubmit={this.handleSubmit}>
          {!this.state.showSuccessMobile && (
            <div>
              <span style={{ textAlign: 'left' }}>
                <Input
                  placeholder={t('login.new_password', {})}
                  styleOverwrite={{ borderRadius: 4 }}
                  type="password"
                  onChange={this.handleChange}
                />
                <SpaceTiny />
                <Input
                  isFirst
                  isLast
                  error={error}
                  showErrorMsg // !!this.state.errorCode}
                  placeholder={t('login.retype_password', {})}
                  type="password"
                  onChange={this.handleChangeRetype}
                />
              </span>
              <SpaceBase />
              <Button
                fakeDisable={!this.state.token || !!this.state.errorCode}
                onClick={this.handleSubmit}
                types={['type-full-primary', 'width-flex', 'size-large']}
              >
                {t('login.submit', {})}
              </Button>
              <SpaceBase />
            </div>
          )}
          {this.state.showSuccessMobile && (
            <div>
              <Small>{t('account.password_was_updated')}</Small>
            </div>
          )}
        </form>
      </LoginWrapContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginSignUpReducers: state.loginSignUpReducers,
  };
}

export default connect(mapStateToProps, { becomeUserSaga })(NewPassword);
