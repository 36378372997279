const fullAccess = {
  maxCountSetupSupport: 1,
  maxCountOwnerAccess: -1,
  maxCountServiceProviderAccess: -1,
  partnerModule: true,
};
const basicAccess = {
  maxCountSetupSupport: 1,
  maxCountOwnerAccess: 0,
  maxCountServiceProviderAccess: 0,
  partnerModule: true,
};

function mapFeaturesToRestrictions({ isFullPartnerMode, companyModule }) {
  if (isFullPartnerMode) {
    return { ...fullAccess, companyModule };
  }
  return { ...basicAccess, companyModule };
}

export default mapFeaturesToRestrictions;
