import { createSelector } from 'reselect';
import lodashResult from 'lodash/result';

export const selectProperty = () => state => state.properties;

export const isLoading = () => createSelector(selectProperty(), property => property.get('loading'));

export const isLoadedProperties = () => createSelector(selectProperty(), property => property.get('propertiesLoaded'));

export const isLoadingJobRequests = () =>
  createSelector(selectProperty(), property => property.get('loadingJobRequests'));

export const getBluePrint = () => createSelector(selectProperty(), property => property.get('propertyBlueprint'));
export const selectCloneProperty = () => createSelector(selectProperty(), property => property.get('cloneProperty'));

export const hasLoadingErrorJobRequests = () =>
  createSelector(selectProperty(), property => property.get('errorJobRequests'));

export const isLoadingDeleteProperty = () =>
  createSelector(selectProperty(), property => property.get('loadingDeleteProperty'));

export const hasLoadingErrorDeleteProperty = () =>
  createSelector(selectProperty(), property => property.get('errorDeleteProperty'));

export const getLoadingErrorDeletePropertyMessage = () =>
  createSelector(selectProperty(), property => property.get('errorDeletePropertyMessage'));

export const hasLoadingError = () => createSelector(selectProperty(), property => property.get('error'));

export const jobRequestsArray = () => createSelector(selectProperty(), property => property.get('jobRequests'));

export const propertiesRaw = () => createSelector(selectProperty(), property => property.get('properties'));

export const selectPaginationStateCL = () =>
  createSelector(selectProperty(), property => property.get('propertyPaginationStateCL'));

export const selectIcal = createSelector(selectProperty(), property => property.get('icalData'));

export const propertiesSelected = (id, selector) =>
  createSelector(selectProperty(), property => property.getIn([selector || 'properties', id]));

export const propertiesEventData = () => createSelector(selectProperty(), property => property.getIn(['eventData']));
export const propertiesEventDataMeta = () =>
  createSelector(selectProperty(), property => property.getIn(['eventData', 'meta']));
export const propertiesEventDataEditId = () =>
  createSelector(selectProperty(), property => lodashResult(property.getIn(['eventData', 'editEvent']), ['objectId']));
export const propertiesTriggerResolved = () =>
  createSelector(
    propertiesEventDataEditId(),
    (state, props) => props.trigger,
    selectProperty(),
    (editTriggerId, trigger, all) => {
      if (editTriggerId === trigger.objectId) {
        return { val: all.getIn(['eventData', 'editEvent']), isActive: true };
      }
      return { val: trigger, isActive: false };
    },
  );
