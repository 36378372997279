"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var config = _interopRequireWildcard(require("@properly/config"));

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

const getCalendarEventTime = value => value && value.iso || value || null;

const getCalendarEventPayload = value => value && value.payload || {};

var _default = (obj = {}) => {
  const payload = getCalendarEventPayload(obj);
  const bookingPayload = payload.booking || {};
  const reminderPayload = payload.trigger || {};
  const jobRequestPayload = payload.jobRequest || {};
  const eventType = obj.type;
  const jobRequestStatus = obj.status;
  const bookingStatus = obj.eventStatus;
  return {
    id: obj.objectId || obj.id,
    days: obj.days || [],
    type: eventType,
    typeDescription: config.calendarEventType[eventType],
    startTime: getCalendarEventTime(obj.startTime),
    endTime: getCalendarEventTime(obj.endTime),
    createdAt: obj.createdAt,
    timeZone: obj.timeZone,
    property: {
      id: obj.propertyId,
      displayAddress: obj.propertyAddress
    },
    booking: {
      id: obj.bookingId,
      status: config.bookingStatus[bookingStatus],
      statusCode: bookingStatus,
      title: bookingPayload.title,
      numberOfGuests: bookingPayload.numberOfGuests,
      partner: bookingPayload.partner,
      guestName: bookingPayload.guest || bookingPayload.guestName,
      arrivalDate: getCalendarEventTime(bookingPayload.arrivalDate),
      departureDate: getCalendarEventTime(bookingPayload.departureDate),
      arrivalLocalDay: bookingPayload.arrivalLocalDay,
      departureLocalDay: bookingPayload.departureLocalDay,
      bookingDurationDays: bookingPayload.bookingDurationDays
    },
    reminder: {
      id: obj.triggerId,
      title: reminderPayload.title,
      time: reminderPayload.time,
      type: reminderPayload.type,
      needsCTA: obj.needsCTA,
      checklistId: reminderPayload.checklistId
    },
    jobRequest: {
      id: obj.jobRequestId,
      status: config.jobRequestStatus[jobRequestStatus],
      statusCode: jobRequestStatus,
      incomplete: jobRequestPayload.incomplete,
      title: jobRequestPayload.title,
      problem: jobRequestPayload.problem,
      seen: jobRequestPayload.seen,
      numberOfCleaners: jobRequestPayload.numberOfCleaners,
      cleanerName: jobRequestPayload.cleanerName,
      jobStartTimeType: jobRequestPayload.jobStartTimeType,
      duration: jobRequestPayload.duration,
      jobSnapshotId: obj.jobSnapshotId
    }
  };
};

exports.default = _default;