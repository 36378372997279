import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import t, { getPlansHighlights } from '@properly/localization';
import { openIntercomWithMsg } from '@properly/common';
import chunk from 'lodash/chunk';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import isArray from 'lodash/isArray';
import findIndex from 'lodash/findIndex';
import isString from 'lodash/isString';
import sortBy from 'lodash/sortBy';
import classNames from 'classnames/bind';
import log from 'loglevel';
import { SpaceBase, Button, LoadingSplash, Icon } from '@properly/components';
import styles from './pricingStyles.module.css';
import { isCustomPlan, formatPrice } from '../../../helper/herbert';
import { trackUpgradePlanClick } from '../../../actions/trackingEvents';
import { selectProperties } from '../../../selectors/globalSelector';
import { goCheckoutUpgrade } from '../../../actions/globalActions';
import { getParsePlans } from '../data';

const cx = classNames.bind(styles);

function priceStringNew(priceType, price, hasPrice = true) {
  const perWhat = t('account.month');
  const obj = hasPrice ? { price, perWhat } : { perWhat };
  if (priceType === 'property') return t('account.price_property_new', obj);
  if (priceType === 'job') return t('account.price_job', obj);
  if (priceType === 'flatrate') return t('account.price_flat_new', obj);
  return false;
}

function FeatureRender({ feature }) {
  const regexMatch = /^\*(.*)\*$/.exec(feature);
  const res = regexMatch ? <strong>{regexMatch[1]}</strong> : <span>{feature}</span>;
  return res;
}

function PricingBox({
  title,
  price = false,
  forwhat,
  features,
  mode,
  isPopular,
  isSlim,
  onUpgrade,
  onDowngrade,
  onContact,
  isSmall,
}) {
  return (
    <div
      className={cx('pricing__planbox-wrap', {
        'pricing__planbox-wrap--active': isPopular,
        'pricing__planbox-wrap--slim': isSlim,
      })}
    >
      {!isSlim && (
        <div className={cx('pricing__planbox-top')}>
          <span>{t('pricing.most_popular')}</span>
        </div>
      )}
      <div
        className={cx('pricing__planbox', {
          'pricing__planbox--slim': isSlim,
          'pricing__planbox--small': isSmall,
        })}
      >
        <div className={cx('pricing__planbox-upper')}>
          <div className={cx('pricing__planbox-name')}>{title}</div>
          {mode !== 'custom' && !isSlim && (
            <div className={cx('pricing__planbox-price-wrap')}>
              <div className={cx('pricing__planbox-price')}>
                <span>{price}</span>
                <span className={cx('pricing__planbox-price-dollar', 'pricing__planbox-smalltxt')}>$</span>
                <span className={cx('pricing__planbox-price-usd', 'pricing__planbox-smalltxt')}>USD</span>
              </div>
              <div className={cx('pricing__planbox-smalltxt')}>{forwhat}</div>
            </div>
          )}
          {mode === 'custom' && !isSlim && (
            <div className={cx('pricing__planbox-contactus')}>
              <span>{t('pricing.contact_us')}</span>
            </div>
          )}
          {!isSlim && <div className={cx('pricing__divider')} />}
          <div
            className={cx('pricing__featurelist', {
              'pricing__featurelist--slim': isSlim,
            })}
          >
            {map(features, (feature, index) => (
              <div key={`feature-${title}-${feature}-${index}`} className={cx('pricing__planbox-txt')}>
                <FeatureRender feature={feature} />
              </div>
            ))}
          </div>
        </div>
        {mode === 'upgrade' && (
          <Button onClick={onUpgrade} types={['type-full-primary', 'size-large']}>
            {t('pricing.upgrade')}
          </Button>
        )}
        {mode === 'your' && (
          <Button onClick={() => {}} types={['type-border-primary', 'size-large']}>
            {t('pricing.your_plan')}
          </Button>
        )}
        {mode === 'downgrade' && (
          <Button onClick={onDowngrade} types={['type-border-grey', 'size-large']}>
            {t('pricing.contact_us')}
          </Button>
        )}
        {mode === 'custom' && (
          <Button onClick={onContact} types={['type-full-primary', 'size-large']}>
            {t('pricing.contact_us')}
          </Button>
        )}
      </div>
    </div>
  );
}

function indexToStar(index) {
  let res = '';
  for (let i = 0; i < index + 1; i += 1) {
    res += '*';
  }
  return res;
}

function reduceTranslations(items) {
  if (isArray(items)) {
    return reduce(items, (acc, i) => acc + t(i), '');
  }
  if (isString(items)) {
    if (items.startsWith('*')) {
      return `*${t(items.replace(/(^\*)|(\*$)/g, ''))}*`;
    }
    return t(items);
  }
  return '';
}

function mapHighlightsToTranslations(items) {
  return map(items, i => reduceTranslations(i));
}

class PricingPlansContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        plans: [],
        highlights: [],
        notes: [],
      },
      isLoading: true,
    };
  }

  async componentDidMount() {
    try {
      const plans = await getParsePlans();
      const publicPlans = plans?.filter(plan => plan.public);
      const sortedPlans = sortBy(publicPlans, publicPlan => publicPlan.displayOrder);
      this.setState({
        data: {
          plans: sortedPlans,
          highlights: getPlansHighlights().highlights,
          highlightsCommon: getPlansHighlights().highlightsCommon,
          notes: getPlansHighlights().notes,
        },
        isLoading: false,
      });
    } catch (error) {
      log.error(`[getParsePlans]: Could not fetch plans ${error}`);
      this.setState({ isLoading: false });
    }
  }

  renderItem(title, index) {
    return (
      <div key={`highlight-${index}`} className={cx('pricing__bottomtxt')}>
        <div
          style={{
            marginRight: '8px',
            flexShrink: 0,
          }}
        >
          <Icon.Ic16PxCheck />
        </div>
        <div>{t(title)}</div>
      </div>
    );
  }

  handleDowngrade = () => {
    openIntercomWithMsg(t('pricing.contact_for_custom_plan'));
  };

  handleContact = () => {
    openIntercomWithMsg(t('pricing.contact_for_custom_plan'));
  };

  handleUpgrade = id => () => {
    trackUpgradePlanClick();
    if (this.props.isInModal) this.props.closeModal();
    this.props.goCheckoutUpgrade(id);
  };

  mapPlansData(plans, currentPlanId) {
    const isUserPlanIdNotBasicFree = currentPlanId !== 'basic_free';
    const currentPlanIndex = findIndex(plans, { id: currentPlanId });
    return map(plans, (item, index) => {
      let mode = '';

      if (index < currentPlanIndex) {
        mode = 'downgrade';
      }

      if (index > currentPlanIndex) {
        mode = 'upgrade';
      }

      if ((item.planId === 'basic_free' && isUserPlanIdNotBasicFree) || item.price === 0) {
        mode = ''; // hide button for basic because they cannot downgrade to it
      }

      // if contact us
      if (item.contactUs) {
        mode = 'custom';
      }

      if (currentPlanId === item.planId) {
        mode = 'your';
      }

      // if user has currently 'custom' plan, then user can downgrade to 'basic_skills' or 'advanced' plan
      if (isCustomPlan(currentPlanId) && item.planId !== 'basic_free' && !isCustomPlan(item.planId)) {
        mode = 'downgrade';
      }
      const price = item.price || 0;
      return {
        title: item.title,
        price: formatPrice(price / 100),
        forwhat: priceStringNew(item.priceType, false, false),
        features: mapHighlightsToTranslations(item.highlights),
        mode,
        onUpgrade: this.handleUpgrade(item.planId, item.priceType),
        onContact: this.handleContact,
        onDowngrade: this.handleDowngrade,
        isPopular: item.mostPopular,
      };
    });
  }

  renderPlans(planData, isSmall) {
    return map(planData, (item, index) => (
      <div key={`plan-${item.title}-${index}`} className={cx('pricing__item')}>
        {PricingBox({ ...item, isSmall })}
      </div>
    ));
  }

  renderIncludes(data) {
    return map(chunk(data, 2), (items, index) => (
      <div key={`highlightcol-${index}`} className={cx('pricing__item')}>
        {map(items, (item, indexInner) => this.renderItem(item, indexInner))}
      </div>
    ));
  }

  get subscription() {
    if (!this.props.currentUser.user) return undefined;
    return this.props.currentUser.user.subscription;
  }

  renderContent(isInModal) {
    return (
      <>
        <div className={cx('pricing__row')}>
          {this.renderPlans(this.mapPlansData(this.state.data.plans, this.subscription.currentPlanId), isInModal)}
        </div>
        <div>
          <div className={cx('pricing__row')}>
            <div className={cx('pricing__item', 'pricing__item--big')}>
              <div
                className={cx('pricing__headbottom', {
                  'pricing__headbottom--low': isInModal,
                })}
              >
                {t('pricing.all_plans_include')}
              </div>
            </div>
          </div>
          <SpaceBase />
          <div className={cx('pricing__row')}>{this.renderIncludes(this.state.data.highlightsCommon)}</div>
        </div>
        {map(this.state.data.notes, (note, i) => (
          <div key={i} className={cx('pricing__row')}>
            <div className={cx('pricing__item', 'pricing__item--big')} style={{ width: '100%' }}>
              <span className={cx('pricing__infotxt')}>
                {indexToStar(i)}
                {t(note)}
              </span>
            </div>
          </div>
        ))}
      </>
    );
  }

  render() {
    if (!this.subscription) {
      return null;
    }
    return (
      <div
        className={cx('pricing__plans', {
          'pricing__plans--nopadding': this.props.noPadding,
        })}
      >
        {!this.state.isLoading && this.renderContent(this.props.isInModal)}
        {this.state.isLoading && <LoadingSplash />}
      </div>
    );
  }
}

PricingPlansContainer.propTypes = {
  noPadding: PropTypes.bool,
  isInModal: PropTypes.bool,
  closeModal: PropTypes.func,
};

PricingPlansContainer.PricingBox = PricingBox;
PricingPlansContainer.Row = props => (
  <div
    className={cx('pricing__row', {
      'pricing__row--full': props.full,
    })}
  >
    {props.children}
  </div>
);
PricingPlansContainer.Item = props => (
  <div style={props.style} className={cx('pricing__item')}>
    {props.children}
  </div>
);
PricingPlansContainer.MobileWrap = props => <div className={cx('pricing__mobilewrap')}>{props.children}</div>;

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    properties: selectProperties()(state),
  };
}

export default connect(mapStateToProps, {
  goCheckoutUpgrade,
})(PricingPlansContainer);
