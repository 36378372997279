"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var styles = {
  root: {
    padding: 0
  },
  body1: {
    fontSize: '1rem',
    lineHeight: 1.75,
    margin: 0
  },
  scrollable: {
    maxHeight: 150,
    overflowY: 'scroll'
  },
  noPadding: {
    padding: '8px 16px'
  },
  mediumPrimaryText: {
    fontSize: '14px',
    lineHeight: '18px'
  },
  smallPrimaryText: {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 400
  },
  smallSecondaryText: {
    color: '#6b757f'
  },
  marketplaceCleaner: {
    color: '#1565c0'
  },
  darkBackgroundPrimaryText: {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 400,
    color: 'white',
    padding: '4px 0'
  },
  darkBackgroundSecondaryText: {
    color: 'white'
  },
  titleText: {
    fontWeight: 500
  },
  darkBackgroundDivider: {
    borderBottom: '1px solid rgba(160,170,180,.2)'
  }
};
exports.styles = styles;