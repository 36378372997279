import { createSelector } from 'reselect';
import lunr from 'lunr';

const stateToJs = state => (state && state.toJS && state.toJS()) || state;
const filterChecklists = checklists => checklists;

const selectPropChecklistId = (state, props) => props && props.checklistId;
const mapObjectToArray = obj => Object.keys(obj).map(key => obj[key]);

const selectLibrary = state => state.library;
const selectUserChecklists = createSelector(selectLibrary, state => state.get('userChecklists'));
const selectUserChecklistsFiltered = createSelector(selectUserChecklists, filterChecklists);
const selectPublishedChecklists = createSelector(selectLibrary, state => state.get('publishedChecklists'));
const selectPublishedChecklistsFiltered = createSelector(selectPublishedChecklists, filterChecklists);
const selectSingleChecklistsLoading = createSelector(selectLibrary, state => state.get('singleChecklistLoading'));
const selectChecklistCloningIntoProperties = createSelector(selectLibrary, state =>
  state.get('cloneChecklistIntoPropertiesSubmitting'),
);

export const selectLibraryTabsValue = createSelector(selectLibrary, state => state.get('tabsValue'));

export const selectHideUserChecklists = createSelector(selectLibrary, state => state.get('hideUserChecklists'));
export const selectHidePublishedChecklists = createSelector(selectLibrary, state =>
  state.get('hidePublishedChecklists'),
);

export const selectUserChecklistsJs = createSelector(selectUserChecklistsFiltered, stateToJs);
export const selectPublishedChecklistsJs = createSelector(selectPublishedChecklistsFiltered, stateToJs);

export const selectUserChecklistsArray = createSelector(selectUserChecklistsJs, checklists =>
  mapObjectToArray(checklists || {}),
);

export const selectPublishedChecklistsArray = createSelector(
  selectPublishedChecklistsJs,
  selectHidePublishedChecklists,
  (checklists, hide) => (hide ? [] : mapObjectToArray(checklists || {})),
);

export const selectChecklistByPropId = createSelector(
  selectUserChecklistsJs,
  selectPublishedChecklistsJs,
  selectPropChecklistId,
  (userChecklists, publishedChecklists, checklistId) => userChecklists[checklistId] || publishedChecklists[checklistId],
);

export const selectChecklistLoadingByPropId = createSelector(
  selectSingleChecklistsLoading,
  selectPropChecklistId,
  (singleChecklistLoading, checklistId) => !!singleChecklistLoading.get(checklistId),
);

export const selectChecklistCloningIntoPropertiesByPropId = createSelector(
  selectChecklistCloningIntoProperties,
  selectPropChecklistId,
  (cloneSubmitting, checklistId) => !!cloneSubmitting.get(checklistId),
);

export const selectChecklistsLoading = createSelector(
  selectLibrary,
  state => state.get('userChecklistsIsLoading') || state.get('publishedChecklistsIsLoading'),
);
export const selectUserChecklistsError = createSelector(selectLibrary, state => state.get('userChecklistsHasError'));
export const selectPublishedChecklistsError = createSelector(selectLibrary, state =>
  state.get('publishedChecklistsHasError'),
);

export const selectChecklistSearchQuery = createSelector(selectLibrary, state => state.get('checklistSearchQuery'));

const createChecklistsIndex = selector =>
  createSelector(selector, checklists =>
    lunr(function indexChecklists() {
      this.ref('objectId');
      this.field('title');

      mapObjectToArray(checklists || {}).forEach(function indexChecklist(checklist) {
        this.add(checklist);
      }, this);
    }),
  );

const selectUserChecklistsIndex = createChecklistsIndex(selectUserChecklistsJs);
const selectPublishedChecklistsIndex = createChecklistsIndex(selectPublishedChecklistsJs);

const searchIndex = (index, query) => {
  const result = index.query(q => {
    q.term(lunr.tokenizer(query), { usePipeline: true, boost: 100 });
    q.term(lunr.tokenizer(query), {
      usePipeline: false,
      wildcard: lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING, // eslint-disable-line
      boost: 20,
    });
    q.term(lunr.tokenizer(query), { usePipeline: false, editDistance: 1 });
  });

  return result;
};

export const selectFilteredUserChecklistsArray = createSelector(
  selectUserChecklistsJs,
  selectUserChecklistsArray,
  selectHideUserChecklists,
  selectUserChecklistsIndex,
  selectChecklistSearchQuery,
  (checklists, checklistsArray, hide, index, query) => {
    if (hide) {
      return [];
    }

    if (!query) {
      return checklistsArray;
    }

    const queryResults = searchIndex(index, query);
    return queryResults.map(({ ref }) => checklists[ref]);
  },
);

export const selectFilteredPublishedChecklistsArray = createSelector(
  selectPublishedChecklistsJs,
  selectPublishedChecklistsArray,
  selectHidePublishedChecklists,
  selectPublishedChecklistsIndex,
  selectChecklistSearchQuery,
  (checklists, checklistsArray, hide, index, query) => {
    if (hide) {
      return [];
    }

    if (!query) {
      return checklistsArray;
    }

    const queryResults = searchIndex(index, query);
    return queryResults.map(({ ref }) => checklists[ref]);
  },
);

// SKILLS
const selectPropSkillId = (state, props) => props?.skillId;

const searchIndexSkill = (index, query) => {
  const result = index.query(q => {
    q.term(lunr.tokenizer(query), { usePipeline: true, boost: 100 });
    q.term(lunr.tokenizer(query), {
      usePipeline: true,
      wildcard: lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING, // eslint-disable-line
      boost: 20,
    });
    q.term(lunr.tokenizer(query), { usePipeline: true, editDistance: 1 });
  });

  const maxScore = result.some(({ score }) => score >= 0.7) ? 0.7 : result.slice(-1)[0]?.score ?? 0;

  return result.filter(({ score }) => score >= maxScore);
};

export const selectSkillSearchQuery = createSelector(selectLibrary, state => state.get('skillSearchQuery'));
const selectSkills = createSelector(selectLibrary, state => state.get('skills'));
const filterSkills = skills => skills;
const selectFilteredSkills = createSelector(selectSkills, filterSkills);
const selectSkillsJS = createSelector(selectFilteredSkills, stateToJs);
const selectSkillsArray = createSelector(selectSkillsJS, skills => mapObjectToArray(skills || {}));
const selectSkillsIndex = createSelector(selectSkillsJS, skills =>
  lunr(function indexSkills() {
    this.ref('skillId');
    this.field('title');

    mapObjectToArray(skills || {}).forEach(function indexSkill(skill) {
      this.add(skill);
    }, this);
  }),
);

export const selectSkillByPropId = createSelector(
  selectSkillsJS,
  selectPropSkillId,
  (skills, skillId) => skills[skillId],
);

export const selectSingleSkillLoading = createSelector(selectLibrary, state => state.get('singleSkillLoading'));
export const selectSingleSkillError = createSelector(selectLibrary, state => state.get('singleSkillError'));

export const selectFilteredSkillsArray = createSelector(
  selectSkillsJS,
  selectSkillsArray,
  selectSkillsIndex,
  selectSkillSearchQuery,
  (skills, skillsArray, index, query) => {
    if (!query) {
      return skillsArray;
    }

    const queryResults = searchIndexSkill(index, query);
    return queryResults.map(({ ref }) => skills[ref]);
  },
);
export const selectSkillsLoading = createSelector(selectLibrary, state => state.get('skillsIsLoading'));
export const selectSkillsError = createSelector(selectLibrary, state => state.get('skillsHasError'));
export const selectSkillsAccess = createSelector(selectLibrary, state => state.get('hasSkillsAccess'));
