import React from 'react';
import PropTypes from 'prop-types';
import t from '@properly/localization';
import { LoadingSpinner, Icon } from '@properly/components';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const ChecklistHeader = ({
  onClose,
  onDone,
  onEdit,
  onCancel,
  onRemove,
  hasSelectedItems,
  onDuplicate,
  isLoading,
  text,
  type,
  onSelect,
  canSelect,
  canRemove,
  selectedCount,

  // dataKeys
  onCloseKey,
  onDoneKey,
  onEditKey,
  onCancelKey,
  onRemoveKey,
  onDuplicateKey,
  onSelectKey,
}) => {
  if (type === 'multi') {
    return (
      <div className={cx('checklist__header', 'checklist__header--black')}>
        <div onClick={onClose} data-key={onCloseKey} className={cx('checklist__header-part', 'grey', 'pointer')}>
          {t('checklist.cancel')}
        </div>
        <div className={cx('checklist__header-main')}>{text}</div>
        {isLoading && (
          <div className={cx('checklist__header-part', 'bold', 'orange', 'pointer')}>
            <LoadingSpinner />
          </div>
        )}
        {!isLoading && (
          <div
            onClick={onDone}
            data-key={onDoneKey || 'multi-done-button'}
            className={cx('checklist__header-part', 'bold', 'orange', 'pointer')}
          >
            {t('checklist.done')}
          </div>
        )}
      </div>
    );
  }

  if (type === 'merge') {
    return (
      <div className={cx('checklist__header', 'checklist__header--black')}>
        <div onClick={onClose} data-key={onCloseKey} className={cx('checklist__header-part', 'grey', 'pointer')}>
          {t('account.merge_property.cancel')}
        </div>
        <div className={cx('checklist__header-main')}>{text}</div>
        {isLoading && (
          <div className={cx('checklist__header-part', 'bold', 'orange', 'pointer')}>
            <LoadingSpinner />
          </div>
        )}
        {!isLoading && (
          <div
            onClick={onDone}
            data-key={onDoneKey || 'merge-done-button'}
            className={cx('checklist__header-part', 'bold', 'orange', 'pointer')}
          >
            {t('account.merge_property.merge')}
          </div>
        )}
      </div>
    );
  }

  if (type === 'dark') {
    return (
      <div className={cx('checklist__header', 'checklist__header--white')}>
        <div onClick={onClose} data-key={onCloseKey} className={cx('checklist__header-part', 'flex', 'pointer')}>
          <Icon.IcCloseWhite margin="0 4px" />
        </div>
        <div className={cx('checklist__header-main')}>{t('checklist.overview')}</div>
        <div onClick={onEdit} data-key={onEditKey} className={cx('checklist__header-part', 'pointer')}>
          {t('checklist.edit_slides')}
        </div>
      </div>
    );
  }

  if (type === 'orange') {
    return (
      <div className={cx('checklist__header', 'checklist__header--white')}>
        <div className={cx('checklist__header-part')} />
        <div
          className={cx('checklist__header-main', {
            'checklist__header-main--opacity': !hasSelectedItems,
          })}
        >
          <span className={cx('pointer')} onClick={onDuplicate} data-key={onDuplicateKey}>
            <Icon.IcDuplicateWhite3 margin="0 4px" /> {t('checklist.duplicate')}
          </span>
          <span className={cx('checklist__header-space')} />
          <span className={cx('pointer')} onClick={onRemove} data-key={onRemoveKey}>
            <Icon.IcTrash3 margin="0 4px" /> {t('checklist.remove')}
          </span>
        </div>
        <div onClick={onCancel} data-key={onCancelKey} className={cx('checklist__header-part', 'bold', 'pointer')}>
          {t('checklist.cancel')}
        </div>
      </div>
    );
  }

  if (type === 'orangegallery') {
    return (
      <div className={cx('checklist__header', 'checklist__header--white', 'checklist__header--primary')}>
        <div className={cx('checklist__header-part')} />
        <div className={cx('checklist__header-main')}>
          <span>
            <span style={{ display: 'inline-block', minWidth: '12px' }}>{selectedCount}</span>
            {` ${t('properties.selected')}`}
          </span>
          <span className={cx('checklist__header-space')} />
          <span
            className={cx('pointer', {
              'pointer--opacity': !canSelect,
            })}
            onClick={onSelect}
            data-key={onSelectKey}
          >
            <Icon.IcDuplicateWhite3 margin="0 4px" /> {t('checklist.set_as_p_p')}
          </span>
          <span className={cx('checklist__header-space')} />
          <span
            className={cx('pointer', {
              'pointer--opacity': !canRemove,
            })}
            onClick={onRemove}
            data-key={onRemoveKey}
          >
            <Icon.IcTrash3 margin="0 4px" /> {t('checklist.remove')}
          </span>
        </div>
        <div onClick={onCancel} data-key={onCancelKey} className={cx('checklist__header-part', 'bold', 'pointer')}>
          {t('checklist.cancel')}
        </div>
      </div>
    );
  }

  return <div />;
};

ChecklistHeader.propTypes = {
  onClose: PropTypes.func,
  onDone: PropTypes.func,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  onRemove: PropTypes.func,
  hasSelectedItems: PropTypes.bool,
  isLoading: PropTypes.bool,
  onDuplicate: PropTypes.func,
  text: PropTypes.string,
  selectedCount: PropTypes.number,
  type: PropTypes.oneOf(['orange', 'dark', 'multi', 'merge', 'orangegallery']),
};

export default ChecklistHeader;
