"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  androidAppPlaystoreLink: 'https://play.google.com/store/apps/details?id=com.getproperly.properlyv2&hl=en',
  iOSAppStoreLink: 'https://itunes.apple.com/in/app/properly-visual-checklists/id1032619209'
};
exports["default"] = _default;