import Parse from 'parse';
import { fetchParseCloudFunction, fetchParseClassId } from './data.http';
import { requestHandler } from './data.requestHandler';
import { _mapParseJobToJob, _mapParseJobInstructionsToJobInstructions } from './data.map';

export function copyChecklistIntoLibrary(checklistId) {
  return requestHandler(() => fetchParseCloudFunction('copyIntoLibrary', { checklistId }, true));
}

export function getParseJobInstruction(id) {
  return requestHandler(() =>
    fetchParseClassId('JobInstructions', id).then(jobInstructions =>
      _mapParseJobInstructionsToJobInstructions(jobInstructions),
    ),
  );
}

export function getParseJobSingle(id) {
  return requestHandler(() => fetchParseClassId('Job', id).then(job => _mapParseJobToJob(job)));
}

export const getIndependentChecklistInstructions = checklistInstructionsId =>
  fetchParseCloudFunction('getIndependentChecklistInstructions', { checklistInstructionsId }, true);

export function getIndependentChecklist(checklistId) {
  return fetchParseCloudFunction('getIndependentChecklist', { checklistId }, true).then(checklist => {
    Parse.Object.fromJSON(checklist);
    return checklist;
  });
}
