import isFunction from 'lodash/isFunction';
import orderBy from 'lodash/orderBy';
import lowerCase from 'lodash/lowerCase';
import extend from 'lodash/extend';
import lodashResult from 'lodash/result';
import filter from 'lodash/filter';
import { fromJS } from 'immutable';
import moment from 'moment-timezone';
import t from '@properly/localization';
import { Icon } from '@properly/components';
import { displayStreetWithApt } from '@properly/common';
import log from 'loglevel';
import sortBy from 'lodash/sortBy';

// bridge between react-virtualized and the render func
export function rowRenderMapper({ data, handler, ctx }) {
  return ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it),
    parent,
  } = {}) => handler({ row: data[index], key, style, ctx, index, parent });
}

export function sortProperties(properties, picker) {
  return sortBy([...properties], property => {
    const finalProperty = picker ? picker(property) : property;
    return lowerCase(finalProperty.getSearchString());
  });
}

export function calcJobProgressStats(steps, taskDoneCount, totalTaskCount) {
  const total = {
    totalTaskCount,
    taskDoneCount: 0,
    totalPics: 0,
    picsDone: 0,
  };

  if (steps.length > 0) {
    Object.values(steps.filter(step => !!step)[0]).forEach(step => {
      if (step.isVerificationRequired) {
        total.totalPics += 1;
        total.totalTaskCount += 1;
      }
      if (step.isVerificationComplete) {
        total.picsDone += 1;
      }
      total.taskDoneCount = taskDoneCount || 0;
    });
  }
  return total;
}

export function mapJobRequestToColor(jobRequest, isCleaner, cleanerStatus) {
  try {
    if (cleanerStatus === t('jobdetails.declined') || cleanerStatus === t('jobdetails.canceled')) return 'cancelled';

    const status = jobRequest.getStatusString(isCleaner);
    if (status === 'jobstatus.delayed') return 'delayed';
    if (status === 'jobstatus.seen' || status === 'jobstatus.pending' || status === 'jobstatus.pendingChanges')
      return 'pending';
    if (status === 'jobstatus.accepted') return 'accepted';
    if (
      status === 'jobstatus.cancelled' ||
      status === 'jobstatus.declined' ||
      status === 'jobstatus.accepted_by_other_user'
    )
      return 'cancelled';
    if (status === 'jobstatus.started') return 'progress';
    if (status === 'jobstatus.completed') return 'finished';

    return 'accepted';
  } catch (error) {
    log.error('mapJobRequestToColor - fallback', error);
    return null;
  }
}

export function propertyInfos(property) {
  const pic = lodashResult(property, ['pictureUrl']);
  const l1 = lodashResult(property, ['title']);
  const l2 = displayStreetWithApt(lodashResult(property, ['address'], {}));
  let l3 = lodashResult(property, ['address', 'city']);
  const state = lodashResult(property, ['address', 'state']);
  if (state) l3 += `, ${state}`;
  const l4 = lodashResult(property, 'propertyAddress');
  return {
    l1,
    l2,
    l3,
    l4,
    pic,
  };
}

export function mapUpDownToIcon(val) {
  if (val === 'up') {
    return Icon.IcThumbsUp2;
  }
  if (val === 'down') {
    return Icon.IcThumbsDown2;
  }
  return undefined;
}

export function isCustomPlan(val) {
  return val && val.indexOf('custom') > -1;
}

export function genPropertyTags(data) {
  const array = [];
  const bedRoomString = count => (count > 1 ? t('properties.bedrooms') : t('properties.bedroom'));
  const bedString = count => (count > 1 ? t('properties.beds') : t('properties.bed'));
  if (data.numOfBedrooms) array.push(`${data.numOfBedrooms} ${bedRoomString(data.numOfBedrooms)}`);
  if (data.numOfBeds) array.push(`${data.numOfBeds} ${bedString(data.numOfBeds)}`);
  if (data.numOfBathrooms) array.push(`${data.numOfBathrooms} ${t('properties.bath')}`);
  if (data.type) array.push(t(`properties.${data.type.toLowerCase()}`));
  return array.join(' • ');
}

export function changeHelper(MyClass, oldData, newData) {
  const property = new MyClass();
  const data = oldData && isFunction(oldData.getData) ? extend(oldData.getData(), newData) : newData;
  property.setData(data);
  return fromJS(property);
}

export function genPropertyTagsGQL(data, type) {
  const array = [];
  const bedRoomString = count => (count > 1 ? t('properties.bedrooms') : t('properties.bedroom'));
  const bedString = count => (count > 1 ? t('properties.beds') : t('properties.bed'));
  if (data?.numberOfBedrooms) array.push(`${data.numberOfBedrooms} ${bedRoomString(data.numberOfBedrooms)}`);
  if (data?.numberOfBeds) array.push(`${data.numberOfBeds} ${bedString(data.numberOfBeds)}`);
  if (data?.numberOfBathrooms) array.push(`${data.numberOfBathrooms} ${t('properties.bath')}`);
  if (type) array.push(t(`properties.${type.toLowerCase()}`));
  return array.join(' • ');
}

export function filterPropertyList(properties) {
  return filter(properties, item => propertyShowAble(item));
}

export function filterPropertySidebar(items) {
  return orderBy(filterPropertyList(items), 'title');
}

export function propertyShowAble(item) {
  return !item.deleted && !item.mergedTo && !item.toBeDeleted;
}

export function getInitials(firstName, lastName) {
  if (firstName && lastName) {
    return firstName.slice(0, 1).toUpperCase() + lastName.slice(0, 1).toUpperCase();
  }
  return '';
}

export function parseNumber(nextVal, setter) {
  const isANumber = String(nextVal).match(/\d/g);
  const isNull = isANumber === null;
  if (!isNull) {
    setter(isANumber.join('') * 1);
  } else {
    setter(0);
  }
}

export function makeNameString(item, definitelyNotMe, userId) {
  let name = `${item.userData.firstName} ${item.userData.lastName}`;
  if (!definitelyNotMe && item.contactId === userId) {
    name = `${name} (me)`;
  }
  return name;
}

export function makeInfoString(item) {
  const formatDate = date => (date && moment(date).format('M/D/YY')) || '';
  let infoString;
  let color;
  if (item.userData.signedUp) {
    infoString = t('contacts.contact_joined', {
      when: formatDate(item.userData.signedUpDate.iso || item.userData.signedUpDate),
    });
    color = false;
  }
  if (item.lastJobSent) {
    infoString = t('contacts.last_job_sent', {
      when: formatDate(item.lastJobSent.iso || item.lastJobSent),
    });
    color = false;
  }
  if (!item.userData.signedUp) {
    infoString = t('contacts.invite_pending');
    color = true;
  }
  return {
    value: infoString,
    color,
  };
}

export function findMatchNonDisabled(index, limitFunc, modFunc, array) {
  if (limitFunc(index)) return undefined;
  if (array[index] && !array[index].isDisabled) return array[index];
  return findMatchNonDisabled(modFunc(index), limitFunc, modFunc, array);
}

export function isApprovedAction(action) {
  return action.isApproved || lodashResult(action, 'payload.state.isApproved');
}

export function formatPrice(price) {
  const float = parseFloat(price);
  if (float === 0) return 0;
  return float.toFixed(2);
}

export function priceString(priceType, price, hasPrice = true, isAnnually = false) {
  const perWhat = isAnnually ? t('account.year') : t('account.month');
  const obj = hasPrice ? { price, perWhat } : { perWhat };
  if (priceType === 'property') return t('account.price_property', obj);
  if (priceType === 'job') return t('account.price_job', obj);
  if (priceType === 'flatrate') return t('account.price_flat', obj);
  return false;
}

export function genChecklistSubtitle(checklist) {
  if (!checklist) {
    return '';
  }

  const { totalStepCount, totalTaskCount } = checklist || {};
  const subtitle = `${totalStepCount || 0} ${t('job_request.slides')} • ${totalTaskCount || 0} ${t(
    'job_request.tasks',
  )}`;
  return checklist.objectId === 'none' ? '' : subtitle;
}

export function genSkillSubtitle(skill) {
  if (!skill) {
    return '';
  }
  const { stepCount } = skill || {};
  const subtitle = stepCount > 1 ? `${stepCount} ${t('skills.steps')}` : `${stepCount || 0} ${t('skills.step')}`;
  return subtitle;
}

export function approveRedirect(obj) {
  return {
    ...obj,
    state: { isApproved: true },
  };
}

export const isSanctionedPropertyJob = selectedProperty => {
  if (!(selectedProperty && selectedProperty.bookingComSanctionedProperty)) {
    return false;
  }
  return (
    moment(selectedProperty.createdAt)
      .add(90, 'd')
      .diff(moment()) > 0
  );
};

export const getUrlParams = search => {
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  const params = {};
  hashes.forEach(hash => {
    const [key, val] = hash.split('=');
    params[key] = decodeURIComponent(val);
  });
  return params;
};
