import React from 'react';
import PropTypes from 'prop-types';
import { DividerLine, Regular, Button, Icon, RoundSelect } from '@properly/components';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const JobChooseSection = ({
  active,
  open,
  name,
  nameActive,
  children,
  disabled,
  onClick,
  canNext,
  disabledNext,
  onClickNext,
  nextText,
  disabledButShow,
  nameWidthAuto,
  ...more
}) => {
  const finalName = active ? nameActive : name;
  const onClickNextPrevent = e => {
    e.stopPropagation();
    onClickNext();
  };
  return (
    <div
      data-key={more['data-key']}
      className={cx('job-c-section', {
        'job-c-section--clickable': !disabled,
      })}
      onClick={disabled ? () => {} : onClick}
    >
      <DividerLine type={['bottom', 'light']} />
      <div className={cx('job-c-section__inner')}>
        <div
          className={cx('job-c-section__name', {
            'job-c-section__name--withauto': nameWidthAuto,
          })}
        >
          {!disabled && active && <Regular type="orange">{finalName}</Regular>}
          {!disabled && !active && <Regular>{finalName}</Regular>}
          {disabled && <Regular type="greylight">{finalName}</Regular>}
        </div>
        {(!disabled || disabledButShow) && (
          <div
            className={cx('job-c-section__content', {
              'job-c-section__content--bigger': canNext,
              'job-c-section__content--grey': disabledButShow,
            })}
          >
            {children}
          </div>
        )}
        {disabled && !disabledButShow && (
          <div className={cx('job-c-section__icon', 'job-c-section__icon--disabled')}>
            <Icon.IcExpandMore />
          </div>
        )}
        {!disabled && !(canNext && open) && (
          <div className={cx('job-c-section__icon')}>{open ? <Icon.IcExpandLess /> : <Icon.IcExpandMore />}</div>
        )}
        {canNext && open && (
          <div className={cx('job-c-section__btn')}>
            <Button
              disabled={disabledNext}
              onClick={disabled ? () => {} : onClickNextPrevent}
              types={['type-full-primary', 'size-medium', 'height-32']}
            >
              {nextText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

JobChooseSection.Checkbox = ({ title, onClick, isSelected }) => (
  <div className={cx('job-c-section')}>
    <DividerLine type={['bottom', 'light']} />
    <div className={cx('job-c-section__inner')}>
      <div className={cx('job-c-section__name', 'job-c-section__name--big')}>
        <Regular>{title}</Regular>
      </div>
      <div className={cx('job-c-section__icon')}>
        <RoundSelect style={{ height: 18, width: 18 }} type="quarter" selected={isSelected} onChange={onClick} />
      </div>
    </div>
  </div>
);

JobChooseSection.NormalTxt = ({ children }) => <Regular type="greyloading">{children}</Regular>;
JobChooseSection.LightTxt = ({ children }) => <Regular type="noresult">{children}</Regular>;

JobChooseSection.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  nextText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  disabledButShow: PropTypes.bool,
  disabledNext: PropTypes.bool,
  name: PropTypes.string.isRequired,
  nameActive: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onClickNext: PropTypes.func,
  children: PropTypes.node,
};

export default JobChooseSection;
