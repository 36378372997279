import { selectModalData, selectSubmitDataRaw, selectAllowedTypeSectionsInModeContext } from './permissionSelector';
import {
  selectProperties,
  selectSearchData,
  selectPropertiesFiltered,
  selectCurrentUser,
} from '../../../selectors/globalSelector';
import { collSearchKey } from './PermissionsConstants';

function mapToMainState() {
  const memo = selectModalData();
  const memo2 = selectSearchData('properties', collSearchKey, selectProperties);
  const memo3 = selectPropertiesFiltered();
  const memo4 = selectCurrentUser();
  return state => {
    const res = memo(state);
    return {
      ...res,
      searchDataProperties: memo2(state),
      filteredProperties: memo3(state),
      selectSubmitDataRaw: selectSubmitDataRaw()(state),
      currentUser: memo4(state),
      allowedTypes: selectAllowedTypeSectionsInModeContext(state),
    };
  };
}

export { mapToMainState };
