"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateIntercom = updateIntercom;
exports.hideIntercom = hideIntercom;
exports.showIntercom = showIntercom;
exports.shutdownIntercom = shutdownIntercom;
exports.openIntercomWithMsg = openIntercomWithMsg;
exports.showMsgIntercom = showMsgIntercom;
exports.restartIntercom = void 0;

var _loglevel = _interopRequireDefault(require("loglevel"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

let shutdownForeverIntercom = false;

function updateIntercom(userId) {
  if (window.Intercom && !shutdownForeverIntercom) {
    _loglevel.default.info('updateIntercom - userid', userId);

    window.Intercom('update', {
      user_id: userId
    });
  }
}

function hideIntercom() {
  if (window.Intercom) {
    window.Intercom('update', {
      hide_default_launcher: true
    });
  }
}

function showIntercom() {
  if (window.Intercom) {
    window.Intercom('update', {
      hide_default_launcher: false
    });
  }
}

function shutdownIntercom(shutdownForever) {
  if (window.Intercom) {
    if (shutdownForever) {
      shutdownForeverIntercom = true;
    }

    _loglevel.default.info('updateIntercom - shutdown');

    window.Intercom('shutdown');
  }
}

function openIntercomWithMsg(msg) {
  if (window.Intercom) {
    _loglevel.default.info('updateIntercom - openIntercomWithMsg');

    window.Intercom('showNewMessage', msg);
  }
}

function showMsgIntercom() {
  if (window.Intercom) {
    window.Intercom('showNewMessage');
  }
}

const restartIntercom = userId => {
  shutdownForeverIntercom = false;
  showIntercom();

  _loglevel.default.info('updateIntercom - userid', userId);

  window.Intercom('update', {
    user_id: userId
  });
};

exports.restartIntercom = restartIntercom;