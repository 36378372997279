import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import * as selectors from '../../checklists/state/ChecklistSelectors';
import { startPreloadSagaWithDelay, setGlobalLoadingState } from '../../../../actions/globalActions';
import {
  locationHitChecklistDetail,
  stepMutatorSaga,
  goToProperty,
  setActiveChecklist,
  setTextTemplate,
  checklistDirectSlideUpload,
  updateChecklistSaga,
  updateLastUpdatedTime,
  setModalClose,
} from '../../checklists/state/ChecklistActions';
import { mediaUploadLoadingKey } from '../../../../dataConstants';

function mapStateToProps(state, props) {
  const selectedJobInstruction = props.jobInstructionId
    ? selectors
        .selectJobInstructions()(state)
        .toJS()[props.jobInstructionId]
    : undefined;
  const selectedChecklist = props.checklistId
    ? selectorsGlobal
        .selectJobs()(state)
        .toJS()[props.checklistId]
    : undefined;
  const selectedProperty =
    selectedChecklist && selectedChecklist.defaultProperty
      ? selectorsGlobal
          .selectProperties()(state)
          .toJS()[selectedChecklist.defaultProperty]
      : undefined;
  const imageUploadErrored = selectorsGlobal.selectLoadingState(mediaUploadLoadingKey)(state)?.isErrored;

  const currentChecklistStateCode = selectors.selectCurrentState()(state, props);
  const isLoading = currentChecklistStateCode === 1;
  const isError = currentChecklistStateCode === 2;

  return {
    isLoading,
    isError,
    imageUploadErrored,
    lastTimeUpdated: selectors.selectLastTimeUpdate()(state, props),
    modalData: selectors.selectModalData()(state),
    selectedJobInstruction,
    selectedChecklist,
    selectedProperty,
  };
}

// connect with store
export default connect(mapStateToProps, {
  locationHitChecklistDetail,
  goToProperty,
  setActiveChecklist,
  setTextTemplate,
  setGlobalLoadingState,
  checklistDirectSlideUpload,
  stepMutatorSaga,
  updateChecklistSaga,
  updateLastUpdatedTime,
  push,
  setModalClose,
  startPreloadSagaWithDelay, // to refresh state
});
