import React from 'react';
import { makeInfoString, makeNameString, getInitials } from '../../../../helper/herbert';
import { MasterDetailCell } from '../../../../components/MasterDetail/index';

function ContactRow({ row, key, style, ctx: { isActive, currentUserId, onClick, canShowProBadge } }) {
  const name = makeNameString(row, false, currentUserId);
  const infoStringRes = makeInfoString(row);

  return (
    <MasterDetailCell
      data-key="contact-search-row-container"
      key={key}
      style={style}
      picture={{
        text: getInitials(row.userData.firstName, row.userData.lastName),
        picture: row.userData.pictureUrl,
      }}
      type="contact"
      noHover
      onClick={onClick(row)}
      active={isActive(row)}
      title={name}
      subTitleColor={infoStringRes.color ? 'orange' : undefined}
      subTitle={infoStringRes.value}
      proMarketProperty={canShowProBadge && row.listSuggestedCleaner}
    />
  );
}

export default ContactRow;
