"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getGoogleGeoFromAddress = getGoogleGeoFromAddress;
exports.getTimeZoneFromLatLong = void 0;

var _config = require("@properly/config");

const config = (0, _config.getConfigSync)();

const getTimeZoneFromLatLong = (lat, lng, locale) => new Promise((resolve, reject) => {
  const googleTimezoneApi = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${Math.floor(Date.now() / 1000)}&language=${locale}&key=${config.GOOGLE_MAPS_KEY}`;
  fetch(googleTimezoneApi).then(response => {
    const responseFromApi = response.json(); // check for error response and throw error

    if (responseFromApi.errorMessage) {
      throw responseFromApi;
    }

    resolve(responseFromApi);
  }).catch(error => reject(error));
});

exports.getTimeZoneFromLatLong = getTimeZoneFromLatLong;

function getGoogleGeoFromAddress(address) {
  return new Promise((resolve, reject) => {
    try {
      new window.google.maps.Geocoder().geocode({
        address
      }, (results, status) => {
        if (status === 'OK') {
          resolve({
            data: {
              results
            }
          });
        } else {
          reject(status);
        }
      });
    } catch (e) {
      reject(e);
    }
  });
}