import { put, fork, call, takeLatest } from 'redux-saga/effects';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import log from 'loglevel';
import * as types from '../../../../types';
import { setIcalData } from './PropertyActions';
import { getPartnerPropertyIds } from '../../../../graphql/api/partnerPropertyIds';
import { PAID_FEATURES } from '../../../../dataConstants';
import { canUserUseFeature } from '../../../../sagas/global';

function* getIcalProperties(propertyId) {
  const { results } = yield call(getPartnerPropertyIds, propertyId);
  // only with partner
  const filteredForValidPartner = filter(results, property => property.partner);
  // to avoid duplicates
  const finalAccountsObj = reduce(
    filteredForValidPartner,
    (acc, item) => {
      if (!acc[item.id]) {
        acc[item.id] = { ...item, objectId: item.id };
      }
      return acc;
    },
    {},
  );

  yield put(setIcalData({ accounts: finalAccountsObj, isLoadingAccounts: false }));
}

function* setInitIcalSaga(action) {
  log.info('setInitIcalSaga', action);

  const canUserFeatureResponse = yield canUserUseFeature(PAID_FEATURES.ical);
  yield put(setIcalData({ canUserIcalFeatureStatus: canUserFeatureResponse }));

  try {
    yield fork(getIcalProperties, action.property);
  } catch (e) {
    log.error('setInitIcalSaga', e);
  }
}

function* saga() {
  yield fork(takeLatest, types.PROPERTY_SET_INIT_ICAL_DATA, setInitIcalSaga);
}

export default saga;
