import React, { Component } from 'react';
import log from 'loglevel';
import reduce from 'lodash/reduce';
import omit from 'lodash/omit';
import map from 'lodash/map';
import lodashResult from 'lodash/result';
import locale from 'browser-locale';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { compose, onlyUpdateForKeys } from 'recompose';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import t from '@properly/localization';
import { getConfigSync } from '@properly/config';
import { getGoogleGeoFromAddress, getTimeZoneFromLatLong } from '@properly/common';
import { RadioSelect, Box, SpaceBase, SpaceLarge, Input, LoadingSplash, QtyPicker } from '@properly/components';
import TitleBar from '../../../../components/TitleBar/index';
import PropertyGeoContainer from './PropertyGeoContainer';
import styles from './propertyStyles.module.css';
import LoadGoogle from '../../../../hoc/LoadGoogle/index';

const cx = classNames.bind(styles);

const config = getConfigSync();
const formName = 'propertyNewEdit';
const selector = formValueSelector(formName);

function mapModeToLabel(mode) {
  switch (mode) {
    case 'edit':
      return t('properties.edit_property');
    case 'new':
      return t('properties.create_property');
    default:
      return '';
  }
}

class TitleBarContainer extends Component {
  state = {};

  render() {
    return (
      <TitleBar
        isValid={this.props.valid}
        onClickMain={this.props.handleSubmit}
        onClickCancel={this.props.onCancelAction}
        label={mapModeToLabel(this.props.mode)}
        activeTxt={t('properties.save_changes')}
      />
    );
  }
}

const TitleBarContainerConnected = compose(
  reduxForm({
    asyncBlurFields: [],
    form: formName,
  }),
)(TitleBarContainer);

class PropertyForm extends React.PureComponent {
  renderField = ({ input, meta: { error }, ...more }) => (
    <Input
      style={{ background: 'white' }}
      data-key={more['data-key']}
      error={(this.props.submitFailed && error) || (this.props.submitSucceeded && error)}
      {...more}
      {...omit(input, ['onBlur', 'onFocus'])}
      onChange={input.onChange}
    />
  );

  genRadioOptions() {
    return [
      {
        id: 'apartment',
        value: t('properties.apartment'),
      },
      {
        id: 'house',
        value: t('properties.house'),
      },
    ];
  }

  genOptionsPicker(numOfBathrooms, numOfBedrooms, numOfBeds) {
    return [
      {
        id: 'numOfBedrooms',
        qty: numOfBedrooms,
        stepper: 1,
        value: t('properties.bedrooms'),
      },
      {
        id: 'numOfBeds',
        qty: numOfBeds,
        stepper: 1,
        value: t('properties.beds'),
      },
      {
        id: 'numOfBathrooms',
        qty: numOfBathrooms,
        stepper: 0.5,
        value: t('properties.bathrooms'),
      },
    ];
  }

  setPicker = (field, value) => {
    this.props.change(field, value);
  };

  setPropertyType = value => {
    this.props.change('propertyType', value);
  };

  setGeoResult = data => {
    map(['street', 'apt', 'city', 'state', 'zip', 'country'], key => {
      this.props.change(key, data[key] || '');
    });
    this.props.asyncValidate();
  };

  render() {
    const {
      // valid,
      mode,
      loading,
      numOfBeds,
      numOfBedrooms,
      numOfBathrooms,
      propertyType,
      handleSubmit,
      onCancelAction,
    } = this.props;
    if (loading) {
      return <LoadingSplash />;
    }
    return (
      <form autoComplete="off" onSubmit={handleSubmit}>
        <TitleBarContainerConnected mode={mode} handleSubmit={handleSubmit} onCancelAction={onCancelAction} />
        <SpaceBase />

        <div className={cx('property-detail-flex')}>
          <div className={cx('property-detail-flex-1')}>
            <Box.Label text={t('properties.title')} />
            <Field
              component={this.renderField}
              data-key="property_title_input"
              isLast
              isFirst
              type="text"
              name="title"
              placeholder={t('properties.default_prop_title')}
            />
          </div>
          <div className={cx('property-detail-flex-1')}>
            <Box.Label text={t('properties.address')} />
            <Box isFirst noPadding noOverflow>
              <PropertyGeoContainer onSet={this.setGeoResult} />
            </Box>
            <Field
              component={this.renderField}
              data-key="property_street_input"
              type="text"
              name="street"
              placeholder={t('properties.street_address')}
            />
            <Field
              component={this.renderField}
              data-key="property_apt_input"
              type="text"
              name="apt"
              placeholder={t('properties.apt_address')}
            />
            <Field
              component={this.renderField}
              data-key="property_city_input"
              type="text"
              name="city"
              placeholder={t('properties.city_address')}
            />
            <Input.TwoSplit>
              <Field
                component={this.renderField}
                data-key="property_state_input"
                type="text"
                twoSplit="left"
                name="state"
                placeholder={t('properties.state_address')}
              />
              <Field
                component={this.renderField}
                data-key="property_zip_input"
                type="text"
                twoSplit="right"
                name="zip"
                placeholder={t('properties.zip_address')}
              />
            </Input.TwoSplit>
            <Field
              component={this.renderField}
              data-key="roperty_country_input"
              type="text"
              isLast
              name="country"
              placeholder={t('properties.country_address')}
            />
          </div>
        </div>
        <SpaceLarge />
        <div className={cx('property-detail-flex')}>
          <div className={cx('property-detail-flex-1')}>
            <Box.Label text={t('properties.ptype')} />
            <RadioSelect options={this.genRadioOptions()} selectedId={propertyType} onSelect={this.setPropertyType} />
          </div>
          <div className={cx('property-detail-flex-1')}>
            <Box.Label text={t('properties.rooms')} />
            <QtyPicker
              min={0}
              options={this.genOptionsPicker(numOfBathrooms, numOfBedrooms, numOfBeds)}
              onChange={this.setPicker}
            />
          </div>
        </div>
        <SpaceLarge />
        <SpaceLarge />
      </form>
    );
  }
}

function toGeoAddress(obj) {
  const array = reduce(
    ['street', 'city', 'state', 'zip', 'country'],
    (acc, key) => {
      if (obj[key]) {
        acc.push(obj[key]);
      }
      return acc;
    },
    [],
  );
  return array.join(' ');
}

let previousAddress;

function asyncValidate(values, dispatch, props) {
  const err = { lat: true, lng: true, _error: 'async' };

  const address = toGeoAddress(values);

  // If the address has not changed then there is no need to refetch the geometry
  if (previousAddress === address) {
    log.info('PropertyForm asyncValidate Address is the same so skipping geo validation', address);
    return Promise.resolve();
  }

  previousAddress = address;

  return new Promise((resolve, reject) => {
    getGoogleGeoFromAddress(address).then(
      res => {
        const geometry = lodashResult(res, ['data', 'results', 0, 'geometry', 'location']);
        // search for country code which is inside the array of objects of address components
        const { short_name: countryCode } = lodashResult(res, [
          'data',
          'results',
          0,
          'address_components',
        ]).filter(addressComponent => addressComponent.types?.includes('country'))[0];
        if (geometry) {
          getTimeZoneFromLatLong(geometry.lat(), geometry.lng(), locale())
            .then(funcRes => {
              dispatch(props.change('timeZoneId', funcRes.timeZoneId));
            })
            .catch(funcErr => {
              log.error('timezone api error', funcErr);
              return reject(funcErr);
            });
          dispatch(props.change('lat', geometry.lat()));
          dispatch(props.change('lng', geometry.lng()));
          dispatch(props.change('countryCode', countryCode));
          resolve();
        } else {
          reject(err);
        }
      },
      () => reject(err),
    );
  });
}

function validate(values, props) {
  // TODO: improve validation
  const errors = {};
  if (!values.title) {
    errors.title = true;
  }
  if (props.asyncValidating) {
    errors.asyncRunning = true;
  }
  if (!values.lat) {
    errors.lat = true;
  }
  if (!values.lng) {
    errors.lng = true;
  }
  if (!values.street) {
    errors.street = true;
  }
  if (!values.zip) {
    errors.zip = true;
  }
  if (!values.country) {
    errors.country = true;
  }
  return errors;
}

function mapStateToProps(state) {
  return {
    numOfBathrooms: selector(state, 'numOfBathrooms'),
    numOfBedrooms: selector(state, 'numOfBedrooms'),
    numOfBeds: selector(state, 'numOfBeds'),
    propertyType: selector(state, 'propertyType'),
  };
}

export default compose(
  LoadGoogle({ googleKey: config.GOOGLE_MAPS_KEY }),
  connect(mapStateToProps, {}),
  reduxForm({
    asyncBlurFields: [],
    validate,
    asyncValidate,
    form: formName,
    enableReinitialize: true,
  }),
  onlyUpdateForKeys([
    'change',
    'asyncValidate',
    'mode',
    'loading',
    'numOfBeds',
    'numOfBedrooms',
    'numOfBathrooms',
    'propertyType',
    'handleSubmit',
    'onCancelAction',
  ]),
)(PropertyForm);
