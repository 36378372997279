/* eslint-disable react/no-multi-comp */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import t, { mapServiceIdToLabel } from '@properly/localization';
import { formatMinHours } from '@properly/common';
import {
  PropertyImage,
  WithHover,
  SpaceFlexi,
  Button,
  LoadingSpinner,
  PopoverDefault,
  Icon,
  Badge,
  RoundSelect,
  SpaceSmall,
} from '@properly/components';
import lodashResult from 'lodash/result';
import map from 'lodash/map';
import chunk from 'lodash/chunk';
import noop from 'lodash/noop';
import reduce from 'lodash/reduce';
import indexOf from 'lodash/indexOf';
import { List } from 'react-virtualized';
import classNames from 'classnames/bind';
import styles from './index.module.css';
import JobChooseSection from '../JobChooseSection';
import { makeNameString, propertyInfos, makeInfoString, getInitials, rowRenderMapper } from '../../helper/herbert';
import { MasterDetailCell } from '../MasterDetail';
import Contact from '../../model/contact';
import Profile from '../../model/profile';
import ContactImageGrid from '../ContactImageGrid';
import ChecklistOverlay from '../ChecklistOverlay';
import { TodosTextEle } from '../TodosElements';
import ProBadge from '../ProBadge';

const cx = classNames.bind(styles);

function JobRequestTimeRow(props) {
  return <div className={cx('job-request__time-row')}>{props.children}</div>;
}

function JobRequestTimeCell(props) {
  return (
    <div
      className={cx('job-request__time-cell', {
        'job-request__time-cell--small': props.smallRel,
        'job-request__time-cell--relative': props.smallRel,
      })}
    >
      {props.children}
    </div>
  );
}

function JobRequestTimeRowName(props) {
  return <div className={cx('job-request__time-row-name')}>{props.children}</div>;
}

function JobRequestCleaner(props) {
  return (
    <div
      className={cx('job-request__mycleaner', {
        'job-request__mycleaner--nomargin': props.noMargin,
      })}
    >
      {props.children}
    </div>
  );
}

function JobRequestCleanerHead(props) {
  return (
    <div
      className={cx('job-request__mycleaner__head', {
        'job-request__mycleaner__head--nomargin': props.noMargin,
      })}
    >
      {props.children}
    </div>
  );
}

function JobRequestCleanerSpace(props) {
  return <div className={cx('job-request__mycleaner__space')}>{props.children}</div>;
}

function JobRequestCleanerHeadTxt(props) {
  return (
    <div
      className={cx('job-request__mycleaner__head-txt', {
        'job-request__mycleaner__head-txt-marginleft': props.marginLeft,
      })}
    >
      {props.children}
    </div>
  );
}

function JobRequestCleanerAll(props) {
  return (
    <div
      className={cx('job-request__mycleaner__all', {
        'job-request__mycleaner__all--marginlr': props.marginLR,
        'job-request__mycleaner__all--small': props.small,
      })}
    >
      {props.children}
    </div>
  );
}

function JobRequestPrice(props) {
  return <div className={cx('job-request__price')}>{props.children}</div>;
}

function JobRequestPriceInfo(props) {
  return (
    <div
      className={cx('job-request__price-info', {
        'job-request__price-info--small': props.isSmall,
      })}
    >
      {props.children}
    </div>
  );
}

function JobRequestTextArea(props) {
  return (
    <div
      className={cx('job-request__textarea', {
        'job-request__textarea--defaultmodal': props.defaultModal,
      })}
    >
      {props.children}
    </div>
  );
}

function JobRequestMessagePreview(props) {
  if (!props.message || !props.message === '') return null;
  return (
    <div className={cx('job-request__cut')}>
      <JobChooseSection.NormalTxt>{props.message}</JobChooseSection.NormalTxt>
    </div>
  );
}

function JobRequestPricePreview(props) {
  if (!props.currency) return null;
  const content = props.value > 0 ? `${props.currency} ${props.value}` : null;
  return <JobChooseSection.NormalTxt>{content}</JobChooseSection.NormalTxt>;
}

function JobRequestTimePreview(props) {
  return (
    <div>
      <JobChooseSection.LightTxt>{t('job_request.start_time')}:</JobChooseSection.LightTxt>{' '}
      <JobChooseSection.NormalTxt>{props.startTime}</JobChooseSection.NormalTxt>
      <span style={{ width: 10, display: 'inline-block' }} />
      <JobChooseSection.LightTxt>{t('job_request.duration')}:</JobChooseSection.LightTxt>{' '}
      <JobChooseSection.NormalTxt>{props.duration}</JobChooseSection.NormalTxt>
    </div>
  );
}
JobRequestTimePreview.propTypes = {
  startTime: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
};
function JobRequestTimePreviewNew(props) {
  const isFlexible = props.jobStartTimeType && props.jobStartTimeType.toLowerCase() === 'flexible';

  return (
    <div className={cx('job-request__timepreview')}>
      {!props.hideDate && (
        <div className={cx('job-request__timepreview-col')}>
          <JobRequestTextMain>
            {!isFlexible ? t('job_request.start_date') : t('job_request.start_after')}
          </JobRequestTextMain>
          <JobRequestTextSub>{props.startDate}</JobRequestTextSub>
          {isFlexible && <JobRequestTextSub>{props.startTime}</JobRequestTextSub>}
        </div>
      )}
      <div className={cx('job-request__timepreview-col')}>
        <JobRequestTextMain>
          {!isFlexible ? t('job_request.start_time') : t('job_request.finish_before')}
        </JobRequestTextMain>
        <JobRequestTextSub>{!isFlexible ? props.startTime : props.endDate}</JobRequestTextSub>
        {isFlexible && <JobRequestTextSub>{props.endTime}</JobRequestTextSub>}
      </div>
      <div className={cx('job-request__timepreview-col')} style={{ paddingRight: 0 }}>
        <JobRequestTextMain>{t('job_request.duration')}</JobRequestTextMain>
        <JobRequestTextSub>{props.duration}</JobRequestTextSub>
      </div>
    </div>
  );
}
JobRequestTimePreviewNew.propTypes = {
  startTime: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  jobStartTimeType: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

function CleanerList(props) {
  const {
    contacts,
    isBlue,
    onClick,
    selectedCleaners,
    onClickBtn,
    hasButton,
    currentUserId,
    proMarketProperty,
  } = props;
  return (
    <div>
      {map(contacts, (contact, index) => {
        const titleEtc = {};
        if (isBlue) {
          titleEtc.activeBlue = true;
          titleEtc.title = makeNameString(contact, true, currentUserId);
          const clientsOrClient = contact.numOfHosts > 1 ? t('job_request.clients') : t('job_request.client');
          titleEtc.subTitle = `${contact.numOfJobRequestsDone} ${t('job_request.total_jobs')} •
              ${contact.numOfHosts} ${clientsOrClient}`;
        } else {
          const res = makeInfoString(contact);
          titleEtc.title = makeNameString(contact, false, currentUserId);
          titleEtc.subTitleColor = res.color ? 'orange' : undefined;
          titleEtc.subTitle = res.value;
        }
        const selected = selectedCleaners[contact.userData.objectId] !== undefined;
        return (
          <MasterDetailCell
            key={contact.objectId}
            hasButton={hasButton}
            picture={{
              text: getInitials(contact.userData.firstName, contact.userData.lastName),
              picture: contact.userData.pictureUrl,
            }}
            type="community"
            onClick={e => onClick(contact, selected, e)}
            active={selected}
            showProBadge={proMarketProperty && contact?.getProStatus?.()}
            noHover={selected}
            noBorderBottom={index === contacts.length - 1}
            {...titleEtc}
          >
            {hasButton && (
              <Button onClick={e => onClickBtn(contact, selected, e)} types={['type-border-grey', 'size-small']}>
                {t('job_request.view')}
              </Button>
            )}
          </MasterDetailCell>
        );
      })}
    </div>
  );
}
CleanerList.propTypes = {
  isBlue: PropTypes.bool,
  hasButton: PropTypes.bool,
  contacts: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.instanceOf(Contact)),
    PropTypes.arrayOf(PropTypes.instanceOf(Profile)),
  ]),
  onClick: PropTypes.func.isRequired,
  onClickBtn: PropTypes.func,
  selectedCleaners: PropTypes.shape({}).isRequired,
};

function JobRequestPopoverText({ popoverContent, closePopup }) {
  return (
    <div>
      <span className={cx('job-request-popover__text')}>{popoverContent}</span>
      <SpaceSmall />
      <Button styleOverwrite={{ margin: '0 auto' }} types={['type-border-grey', 'size-small']} onClick={closePopup}>
        Close
      </Button>
    </div>
  );
}

function JobRequestPopover({ children, popoverContent, width, arrowPosition, hasPadding, hasArrow }) {
  return (
    <div className={cx('job-request-popover')}>
      <PopoverDefault
        popoverKey="jobRequestPopover"
        width={width}
        hasPadding={hasPadding}
        hasArrow={hasArrow}
        arrowPosition={arrowPosition}
        popoverContent={PopoverState => (
          <JobRequestPopoverText popoverContent={popoverContent} closePopup={PopoverState.closePopup} />
        )}
      >
        {children}
      </PopoverDefault>
    </div>
  );
}

function JobRequestTextMain({ children, isBlue, cutText, type = 'main' }) {
  return (
    <div
      className={cx({
        'job-request-sidebar-text__main--blue': isBlue,
        'job-request-sidebar-text__main--cuttxt': cutText,
        'job-request-sidebar-text__main': type === 'main',
        'job-request-sidebar-text__sub': type === 'sub',
        'job-request-sidebar-text__light': type === 'light',
      })}
    >
      <span data-key="job-request-text-main">{children}</span>
    </div>
  );
}

function JobRequestTextSub({ children, ...more }) {
  return (
    <JobRequestTextMain type="sub" {...more}>
      {children}
    </JobRequestTextMain>
  );
}

function JobRequestTextLight({ children, ...more }) {
  return (
    <JobRequestTextMain type="light" {...more}>
      {children}
    </JobRequestTextMain>
  );
}

function JobRequestTextColor({ children, onClick, color = 'orange', isInline }) {
  return (
    <div
      onClick={onClick}
      className={cx('job-request-sidebar-text__orange', {
        'job-request-sidebar-text__orange--orange': color === 'orange',
        'job-request-sidebar-text__orange--blue': color === 'blue',
        'job-request-sidebar-text__orange--inline': isInline,
      })}
    >
      {children}
    </div>
  );
}

function JobRequestContent({ children }) {
  return <div className={cx('job-request-content')}>{children}</div>;
}

function JobRequestPermissionSection({ text, rightComponent, paddingRight }) {
  return (
    <div className={cx('job-request-permission')} style={{ paddingRight }}>
      <JobRequestTextMain cutText>{text}</JobRequestTextMain>
      <div className={cx('job-request-permission__right')}>{rightComponent()}</div>
    </div>
  );
}

function renderJobRequestListRow({
  row,
  key,
  style,
  ctx: {
    handleClick,
    isActiveFunc,
    isDisabledFunc,
    isHoverableFunc,
    hasClickHoleFunc,
    isClickableFunc,
    isMarketPlaceFeatureFlagEnabled,
  },
}) {
  return (
    <ChecklistOverlay.GridRow key={key} style={style} spacing="12">
      {map(row, (item, keyInner) => {
        const pInfo = propertyInfos(item);
        return (
          <ChecklistOverlay.GridItem key={keyInner} spacing="12" itemPerRow="2">
            <JobRequestCheckableHover
              data-key="jobrequestsend_propertyitem"
              onChange={handleClick(item)}
              isActive={isActiveFunc(item)}
              isDisabled={isDisabledFunc(item)}
              isHoverable={isHoverableFunc(item)}
              hasClickHole={hasClickHoleFunc(item)}
              isClickable={isClickableFunc(item)}
            >
              <JobRequestPropertyInfo
                order={['pic', 'text']}
                txtMod={{ withpic: true }}
                l1={pInfo.l1}
                l2={pInfo.l2}
                l3={pInfo.l3}
                pic={pInfo.pic}
                showProBadge={isMarketPlaceFeatureFlagEnabled && item?.proMarketProperty}
              />
            </JobRequestCheckableHover>
          </ChecklistOverlay.GridItem>
        );
      })}
    </ChecklistOverlay.GridRow>
  );
}

function JobRequestPropertyList({
  properties,
  handleClick,
  isActiveFunc,
  isDisabledFunc = () => false,
  isClickableFunc = () => true,
  isHoverableFunc = () => true,
  hasClickHoleFunc = () => true,
  height,
  width,
  isMarketPlaceFeatureFlagEnabled,
}) {
  const chunkedData = chunk(properties, 2);
  return (
    <div data-key="job-request-property-list">
      <List
        height={height}
        rowHeight={86}
        rowCount={chunkedData.length}
        rowRenderer={rowRenderMapper({
          data: chunkedData,
          handler: renderJobRequestListRow,
          ctx: {
            handleClick,
            isActiveFunc,
            isDisabledFunc,
            isClickableFunc,
            isHoverableFunc,
            hasClickHoleFunc,
            isMarketPlaceFeatureFlagEnabled,
          },
        })}
        width={width}
      />
    </div>
  );
}

function JobRequestPropertyInfo({
  type,
  picType = 'normal',
  txtMod = {},
  liftPic,
  onChange,
  isClickable,
  order = ['text', 'pic'],
  showProBadge = false,
  probadgeAlignmentOverride = false,
  ...more
}) {
  return (
    <div
      className={cx('jobinfo', {
        'jobinfo--cleaner': type === 'cleaner',
      })}
    >
      {map(order, (i, index) => {
        if (i === 'pic') {
          return (
            <div
              key={index}
              className={cx('jobinfo__pic', {
                'jobinfo__pic--lift': liftPic,
              })}
            >
              {picType === 'normal' && <PropertyImage.Small pic={more.pic} />}
              {picType === 'custom' && more.pic}
              {showProBadge && (
                <div
                  className={cx('jobinfo__properlypro', {
                    'jobinfo__properlypro--override': probadgeAlignmentOverride,
                  })}
                >
                  <ProBadge small withTooltip={type === 'cleaner'} />
                </div>
              )}
            </div>
          );
        }
        if (i === 'text') {
          return (
            <div
              key={index}
              className={cx('jobinfo__txt', {
                'jobinfo__txt--widthpic': txtMod.withpic,
              })}
              style={{
                paddingLeft: index === order.length - 1 ? 10 : 0,
                paddingRight: index === 0 ? 10 : 0,
              }}
            >
              <JobRequestTextMain cutText>{more.l1}</JobRequestTextMain>
              <JobRequestTextSub cutText>{more.l2}</JobRequestTextSub>
              <JobRequestTextLight cutText>{more.l3}</JobRequestTextLight>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

function JobRequestTopBar({
  nextText,
  disabledNext,
  onClickNext,
  title,
  backText,
  canNext,
  canBack,
  onClickBack,
  nextIsLoading,
}) {
  return (
    <div className={cx('topbar')}>
      <div className={cx('topbar__title')}>
        <span>{title}</span>
      </div>
      <div className={cx('topbar__btns', 'topbar__btns--left')}>
        {canBack && (
          <Button
            data-key="headbar_go_back"
            onClick={onClickBack}
            types={[
              'type-border-primary',
              'size-large',
              'height-36',
              'border-trans',
              'background-trans',
              'padding-no',
              'miwidth-0',
              'fontw-normal',
            ]}
          >
            {backText}
          </Button>
        )}
      </div>
      <div className={cx('topbar__btns', 'topbar__btns--right')}>
        {canNext && (
          <Button
            data-key="headbar_go_next"
            disabled={disabledNext || nextIsLoading}
            onClick={disabledNext ? noop : onClickNext}
            types={['type-full-primary', 'size-medium']}
          >
            {!nextIsLoading && nextText}
            {nextIsLoading && <LoadingSpinner type="white" />}
          </Button>
        )}
      </div>
    </div>
  );
}

function JobRequestTopBarLeft({ title, belowTitle }) {
  return (
    <div className={cx('topbar', 'topbar--bright')}>
      <div title={title} className={cx('topbar__title', 'topbar__title--lefttop')}>
        <span>{title}</span>
      </div>
      {belowTitle}
    </div>
  );
}

function JobRequestCheckableBox({ isActive, number, isHovered, isBlue, isDisabled, isClickable }) {
  return (
    <div
      className={cx('checkable__check', {
        'checkable__check--hover': isHovered,
        'checkable__check--active': isActive,
        'checkable__check--blue': isBlue,
        'checkable__check--disabled': !isClickable || isDisabled,
      })}
    >
      {isActive && number && <span>{number}</span>}
      {((isActive && !number) || (!isClickable && !isDisabled)) && <Icon.IcCheckicon />}
    </div>
  );
}

function JobRequestCheckable({
  isHovered,
  children,
  isActive,
  type,
  number,
  onChange,
  isBlue,
  isDisabled,
  isClickable = true,
  isHoverable = true,
  hasClickHole = true,
  sendMoreMode,
  ...props
}) {
  return (
    <label
      {...props}
      className={cx('checkable', {
        'checkable--hovered': isHoverable && isHovered,
        'checkable--active': isActive,
        'checkable--blue': isBlue,
        'checkable--disabled': isDisabled,
        'checkable--notclickable': !isClickable,
        'checkable--fullwidth': type === 'fullwidth',
      })}
    >
      {hasClickHole && (
        <div className={cx('checkable__post-check')}>
          <JobRequestCheckableBox
            isHovered={isHoverable && isHovered}
            isActive={isActive}
            isBlue={isBlue}
            isDisabled={isDisabled}
            number={number}
            isClickable={isClickable}
          />
        </div>
      )}
      {children}
      <input
        className={cx('checkable__checkbox')}
        onChange={((isClickable || (sendMoreMode && isDisabled)) && onChange) || noop}
        checked={!!isActive}
        type="checkbox"
      />
    </label>
  );
}

function JobRequestContentWrap({ id, children }) {
  return (
    <div id={id} className={cx('jobrequest__contentwrap')}>
      {children}
    </div>
  );
}

function JobRequestSpacerWrap({ left, top, right, bottom, children, flexGrow, isFlex, isRelative, minHeight, style }) {
  return (
    <div
      style={{
        paddingLeft: left,
        paddingRight: right,
        paddingTop: top,
        paddingBottom: bottom,
        minHeight,
        ...style,
      }}
      className={cx('jobrequest__spacerwrap', {
        'jobrequest__spacerwrap--growflex': flexGrow,
        'jobrequest__spacerwrap--flex': isFlex,
        'jobrequest__spacerwrap--isrelative': isRelative,
      })}
    >
      {children}
    </div>
  );
}

function JobRequestIcon({ icon: JobRequestIconComponent }) {
  return <JobRequestIconComponent className={cx('jobrequest__icon')} />;
}

function JobRequestIconPlusPreHover({ onClick, isHovered, ...more }) {
  return (
    <button
      type="button"
      {...more}
      data-key={more['data-key']}
      onClick={onClick}
      className={cx('jobrequest__icon_plus', {
        'jobrequest__icon_plus--hover': isHovered,
      })}
    >
      <Icon.IcPlusWhite width="24px" byWidth />
    </button>
  );
}

function JobRequestDateBox({ children, isFlex, isSmall }) {
  return (
    <div
      className={cx('jobrequest__datebox', 'SingleDatePickerCustom', {
        'jobrequest__datebox--flex': isFlex,
        'jobrequest__datebox--small': isSmall,
      })}
    >
      {children}
    </div>
  );
}
function JobRequestPropertyInfoEle({ icon: JobRequestPropertyInfoIcon, title }) {
  return (
    <div className={cx('jobrequest__propertyinfo')}>
      <JobRequestPropertyInfoIcon className={cx('jobrequest__propertyinfo-pic')} />
      <JobRequestTextMain>{title}</JobRequestTextMain>
    </div>
  );
}
function JobRequestCenterFlex({ children }) {
  return <div className={cx('jobrequest__centerflex')}>{children}</div>;
}
function JobRequestTabCleaner({ children, isActive }) {
  return (
    <div
      className={cx('jobrequest__tabcleaner', {
        'jobrequest__tabcleaner--active': isActive,
      })}
    >
      {children}
    </div>
  );
}

function JobRequestCleanerProfileBox({ children, title, noBorder }) {
  return (
    <div
      className={cx('jobrequest__cleanerbox', {
        'jobrequest__cleanerbox--noborder': noBorder,
      })}
    >
      {title && <div className={cx('jobrequest__cleanerbox-title')}>{title}</div>}
      {children}
    </div>
  );
}

class JobRequestCleanerProfile extends PureComponent {
  static propTypes = {
    cleaner: PropTypes.oneOfType([PropTypes.instanceOf(Profile).isRequired, PropTypes.instanceOf(Contact).isRequired])
      .isRequired,
  };

  get isProfile() {
    return this.props.cleaner instanceof Profile;
  }

  get isContact() {
    return this.props.cleaner instanceof Contact;
  }

  get resolvedContact() {
    if (this.isProfile) return this.props.cleaner;
    if (this.isContact) return this.props.cleaner.profile;
    return {};
  }

  render() {
    const {
      isOpen,
      config,
      cleaner: { listSuggestedCleaner },
    } = this.props;

    const cleaner = this.resolvedContact || {};
    const email = lodashResult(cleaner, ['userData', 'email']);
    const phone = lodashResult(cleaner, ['userData', 'phone']);
    const configBadgeOrder = lodashResult(config, ['badgeOrder']);
    const bio = lodashResult(cleaner, ['userData', 'bio']);
    const badges = lodashResult(cleaner, ['badges'], []);
    const firstName = lodashResult(cleaner, ['userData', 'firstName']);
    const offered = (cleaner.offeredServices || []).map(mapServiceIdToLabel).join(', ');
    const badgeArray = reduceBadges(badges, configBadgeOrder);
    if (!isOpen) return null;
    return (
      <div>
        <JobRequestCleanerProfileBox noBorder>
          <JobRequestTypicalTime responseTime={cleaner.responseTime} />
        </JobRequestCleanerProfileBox>
        {bio && <JobRequestCleanerProfileBox title={t('job_request.about_user')}>{bio}</JobRequestCleanerProfileBox>}
        {!listSuggestedCleaner && (
          <JobRequestCleanerProfileBox title={t('account.contact')}>
            {email && <div>{email}</div>}
            {phone && <div>{phone}</div>}
          </JobRequestCleanerProfileBox>
        )}

        {badgeArray.length > 0 && (
          <JobRequestCleanerProfileBox title={t('job_request.badges_title')}>
            <JobRequestBadges badges={badgeArray} />
          </JobRequestCleanerProfileBox>
        )}
        {offered && (
          <JobRequestCleanerProfileBox title={t('job_request.services_user')}>{offered}</JobRequestCleanerProfileBox>
        )}
        {cleaner.propertyPhotos && cleaner.propertyPhotos.length > 0 && (
          <JobRequestCleanerProfileBox
            title={t('contacts.properties_cleaned', {
              name: firstName,
            })}
          >
            <ContactImageGrid
              itemsPerRow={3}
              height={44}
              onClick={this.props.onOpenImageSlider}
              images={cleaner.propertyPhotos}
            />
          </JobRequestCleanerProfileBox>
        )}
      </div>
    );
  }
}

function mapTimeToColor(minutes) {
  if (minutes <= 15) {
    return 'color--rt-green';
  }
  if (minutes > 15 && minutes < 60 * 4) {
    return 'color--rt-orange';
  }
  if (minutes >= 60 * 4) {
    return 'color--rt-red';
  }
  return 'color--black';
}

const JobRequestTypicalTime = ({ responseTime, size = 'small' }) => {
  const txtSize = size === 'small' ? 'txt--l6' : 'txt--l5';
  const hasRTime = responseTime && responseTime > 0;
  const rTime = hasRTime ? formatMinHours(responseTime) : t('job_request.na_na');
  return (
    <div style={{ display: 'flex', justifyContent: 'left' }}>
      <Icon.IcClock componentClass="span" byWidth width="18px" />
      <SpaceFlexi type="horizontal" size="10px" />
      <TodosTextEle componentClass="span" modifier={[txtSize, 'color--black']}>
        {t('job_request.typical_reponse')}:
      </TodosTextEle>
      <SpaceFlexi type="horizontal" size="10px" />
      <TodosTextEle componentClass="span" modifier={[txtSize, mapTimeToColor(responseTime)]}>
        {rTime}
      </TodosTextEle>
    </div>
  );
};

function reduceBadges(badges, badgeOrder) {
  return reduce(
    badgeOrder,
    (acc, badgeId) => {
      if (badgeId && indexOf(badges, badgeId) !== -1) {
        acc.push({
          id: badgeId,
        });
      }
      return acc;
    },
    [],
  );
}

const JobRequestBadges = ({ badges, size = 'small' }) => (
  <div style={{ display: 'flex' }}>
    {map(badges, ({ id }) => (
      <div key={id} style={{ display: 'flex' }}>
        <Badge badgeId={id} size={size} />
        <SpaceFlexi type="horizontal" size="8px" />
      </div>
    ))}
  </div>
);

const JobRequestSidebarBoxPre = ({
  children,
  isActive,
  isHovered,
  title,
  onClick,
  isDisabled,
  id,
  isSmall,
  isTitleRequired,
  modifiers,
  ...more
}) => (
  <button
    type="button"
    {...more}
    data-key={`sidebarsendjob-${id}`}
    disabled={isDisabled}
    onClick={!isDisabled ? onClick : undefined}
    className={cx(
      'job-request-sidebar-box',
      {
        'job-request-sidebar-box--active': isActive,
        'job-request-sidebar-box--hover': isHovered,
        'job-request-sidebar-box--disabled': isDisabled,
        'job-request-sidebar-box--small': isSmall,
      },
      map(modifiers, mod => `job-request-sidebar-box--${mod}`),
    )}
  >
    <div
      className={cx('job-request-sidebar-box__title', {
        'job-request-sidebar-box--istitlerequired': isTitleRequired,
      })}
    >
      {title}
    </div>
    <div className={cx('job-request-sidebar-box__inner')}>{children}</div>
    {isActive && <div className={cx('job-request-sidebar-box__indicator')} />}
    {isDisabled && <div className={cx('job-request-sidebar-box__overlay')} />}
  </button>
);

const JobRequestSidebarBoxEmpty = () => <div className={cx('job-request-sidebar-box__empty')}>-</div>;

const JobRequestWrap = ({ children }) => (
  <div className={cx('job-request-wrap')} id="jobrequestflow">
    {children}
  </div>
);

const JobRequestSidebar = ({ children, disableClick }) => (
  <div className={cx('job-request-sidebar')}>
    {children}
    {disableClick && <div className={cx('job-request-sidebar__overlay')} />}
  </div>
);

const JobRequestLoadingOverlay = ({ hideLoader }) => (
  <div className={cx('loading-overlay')}>
    <div className={cx('loading-overlay__bg')} />
    <div style={{ marginLeft: '320px' }}>{!hideLoader && <LoadingSpinner />}</div>
  </div>
);

const JobRequestPermissionBox = ({ children, selected, onClick, disabled }) => (
  <div
    className={cx('permission-box', {
      'permission-box--selected': selected,
    })}
    onClick={(!disabled && onClick) || noop}
  >
    {children}
    <div className={cx('permission-box__checkable')}>
      <RoundSelect onChange={noop} selected={selected} disabled={disabled} />
    </div>
  </div>
);

const JobRequestCheckableHover = WithHover(JobRequestCheckable);
const JobRequestIconPlus = WithHover(JobRequestIconPlusPreHover);
const JobRequestSidebarBox = WithHover(JobRequestSidebarBoxPre);

export {
  JobRequestTimeRow,
  JobRequestTimeRowName,
  JobRequestTimeCell,
  JobRequestTimePreview,
  CleanerList,
  JobRequestCleaner,
  JobRequestCleanerHead,
  JobRequestCleanerSpace,
  JobRequestCleanerHeadTxt,
  JobRequestCleanerAll,
  JobRequestPrice,
  JobRequestPriceInfo,
  JobRequestTextArea,
  JobRequestMessagePreview,
  JobRequestPricePreview,
  JobRequestPopover,
  JobRequestTextMain,
  JobRequestTextSub,
  JobRequestTextLight,
  JobRequestTimePreviewNew,
  JobRequestPropertyInfo,
  JobRequestTopBar,
  JobRequestTopBarLeft,
  JobRequestCheckableHover,
  JobRequestContentWrap,
  JobRequestSpacerWrap,
  JobRequestIcon,
  JobRequestIconPlus,
  JobRequestTextColor,
  JobRequestDateBox,
  JobRequestCenterFlex,
  JobRequestTabCleaner,
  JobRequestCleanerProfile,
  JobRequestCheckableBox,
  JobRequestPropertyInfoEle,
  JobRequestContent,
  JobRequestSidebarBoxEmpty,
  JobRequestSidebarBox,
  JobRequestWrap,
  JobRequestSidebar,
  JobRequestLoadingOverlay,
  JobRequestPermissionSection,
  JobRequestPropertyList,
  JobRequestPermissionBox,
  JobRequestTypicalTime,
  JobRequestBadges,
  reduceBadges,
};
