import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SpaceBase, LoadingSplash, LoadingSpinner } from '@properly/components';
import t from '@properly/localization';
import classNames from 'classnames/bind';
import styles from './checkoutStyles.module.css';
import { CheckoutSection, PropertySection } from './CheckoutElements';

const cx = classNames.bind(styles);

class CheckoutPricingOverview extends PureComponent {
  componentDidMount() {
    this.props.onMount();
  }

  renderByProperty(price, currency) {
    const { properties } = this.props;
    return (
      <div>
        <PropertySection left={t('checkout.propertyname')} right={t('checkout.price')} mod="bold" />

        <div className={cx('checkout__property-section-scroll')}>
          {properties.map(property => (
            <PropertySection key={property.title} left={String(property.title)} right={`${currency}${price}`} />
          ))}
        </div>
      </div>
    );
  }

  renderByJob(price, currency, qty) {
    return (
      <div>
        <PropertySection left={t('checkout.jobssendthis')} right={t('checkout.price')} mod="bold" />
        <div className={cx('checkout__property-section-scroll', 'checkout__property-section-scroll--nominheight')}>
          <PropertySection left={`${qty} ${t('checkout.jobs')}`} right={`${currency}${price}`} />
        </div>
      </div>
    );
  }

  renderContent() {
    const {
      pricePerUnit,
      taxAmount = 0,
      discountAmount = 0,
      jobsQty,
      totalAmount,
      symbol,
      isAnnually,
      isLoading,
      planType,
    } = this.props;
    const isSubLoading = isLoading && taxAmount;
    return (
      <>
        {isSubLoading && (
          <div className={cx('checkout__overlayloading')}>
            <div className={cx('checkout__overlayloading-inner')}>
              <LoadingSpinner />
            </div>
          </div>
        )}

        {planType === 'property' && this.renderByProperty(pricePerUnit, symbol)}

        {planType === 'job' && this.renderByJob(pricePerUnit, symbol, jobsQty)}

        <PropertySection left={t('checkout.tax')} right={`+${taxAmount}`} mod="grey" />
        <PropertySection left={t('checkout.discount')} right={`-${discountAmount}`} mod="grey" />
        <PropertySection left={t('checkout.total')} right={totalAmount} mod="big" />
        <PropertySection
          left={null}
          right={isAnnually ? t('checkout.billedannual') : t('checkout.billedmonthly')}
          mod="grey"
        />
        <SpaceBase />
      </>
    );
  }

  render() {
    const { planTitle, isLoading, renderNothing } = this.props;
    const isInitLoading = !this.props.taxAmount && isLoading;
    if (renderNothing) return null;
    return (
      <CheckoutSection title={planTitle}>
        {isInitLoading && <LoadingSplash topLeft />}
        {!isInitLoading && this.renderContent(isInitLoading)}
      </CheckoutSection>
    );
  }
}

CheckoutPricingOverview.propTypes = {
  planTitle: PropTypes.string,
  properties: PropTypes.arrayOf(PropTypes.shape({})),
  pricePerUnit: PropTypes.string,
  taxAmount: PropTypes.string,
  discountAmount: PropTypes.string,
  totalAmount: PropTypes.string,
  jobsQty: PropTypes.number,
  symbol: PropTypes.string,
  isLoading: PropTypes.bool,
  renderNothing: PropTypes.bool,
  isAnnually: PropTypes.bool,
  onMount: PropTypes.func.isRequired,
};

export default CheckoutPricingOverview;
