import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import t from '@properly/localization';
import { Button, Icon } from '@properly/components';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const renderBtns = (tryAgain, showIntercom) => (
  <div className={cx('error500__btns')}>
    <Button types={['type-full-primary', 'size-large']} onClick={tryAgain}>
      {t('error.try_again')}
    </Button>
    <p className={cx('error500__or')}>{t('error.or')}</p>
    <Button types={['type-flat-primary', 'size-small']} onClick={showIntercom}>
      {t('error.contact_support')}
    </Button>
  </div>
);

const ErrorComponent = ({ showIntercom, tryAgain, is500, headline, subHeadline, is404, showButtons }) => (
  <div className={cx('error500__wrap')}>
    {is500 && <Icon.IcError500 byWidth width="335px" />}
    {is404 && <Icon.IcError404 byWidth width="335px" />}
    <h1 className={cx('error500__title')}>{headline}</h1>
    <p className={cx('error500__subtitle')}>{subHeadline}</p>
    {(is500 || showButtons) && renderBtns(tryAgain, showIntercom)}
  </div>
);

ErrorComponent.propTypes = {
  showIntercom: PropTypes.func,
  tryAgain: PropTypes.func,
  is500: PropTypes.bool,
  is404: PropTypes.bool,
  headline: PropTypes.string,
  subHeadline: PropTypes.string,
};

export default ErrorComponent;
