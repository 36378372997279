import { fromJS } from 'immutable';
import * as types from '../../types/index';

const MODAL_STATE_LOADING = 1;
const MODAL_STATE_SUCCESS = 2; // eslint-disable-line no-unused-vars
const MODAL_STATE_ERROR = 3; // eslint-disable-line no-unused-vars

const initIntentState = {
  intent: {
    id: undefined,
    clientSecret: undefined,
  },
};

const initModalState = {
  view: MODAL_STATE_LOADING,
  error: undefined,
};

const initState = fromJS({
  stripeCustomerLoading: true,
  hostStripeStatus: false,
  stripeCustomer: {},
  intent: initIntentState,
  modal: initModalState,
});

export default function PaymentInterceptReducer(state = initState, action = {}) {
  switch (action.type) {
    case types.GLOBAL_LOGOUT: // on logout reset
      return initState;
    case types.STRIPE_PM_SET_CUSTOMER:
      return state.mergeIn(['stripeCustomer'], fromJS(action.data));
    case types.STRIPE_PM_SET_CUSTOMER_LOADING_STATE:
      return state.set('stripeCustomerLoading', action.loading);
    case types.STRIPE_PM_SET_HOST_STRIPE_STATUS:
      return state.set('hostStripeStatus', action.status);
    case types.STRIPE_PM_SET_MODAL_STATE:
      return state.mergeDeepIn(['modal'], fromJS(action.data));
    case types.STRIPE_PM_RESET_MODAL_STATE:
      return state.setIn(['modal'], initState.get('modal'));
    case types.STRIPE_PM_SET_INTENT_STATE:
      return state.mergeDeepIn(['intent'], fromJS(action.data));
    case types.STRIPE_PM_RESET_INTENT_STATE:
      return state.setIn(['intent'], initState.get('intent'));
    default:
      return state;
  }
}
