import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { HoverTriggeredTooltip } from '@properly/components';
import styles from './index.module.css';

const cx = classNames.bind(styles);

class NavSection extends PureComponent {
  state = {
    hover: false,
  };

  static propTypes = {
    active: PropTypes.bool,
    onClick: PropTypes.func,
    section: PropTypes.shape({}),
  };

  toggleHover = val => () => {
    this.setState(state => ({ ...state, hover: val }));
  };

  stateToColor = (section, active, hover) => {
    if (active) {
      return section.colorActive;
    }
    if (hover) {
      return section.colorHover;
    }
    return section.colorDefault;
  };

  render() {
    const { section, active, onClick } = this.props;
    const SectionIcon = section.icon;
    return (
      <div
        data-key={`navsection-${section.id}`}
        onClick={() => onClick(section)}
        onMouseEnter={this.toggleHover(true)}
        onMouseLeave={this.toggleHover(false)}
        onFocus={this.toggleHover(true)}
        onBlur={this.toggleHover(false)}
        className={cx('nav-section', {
          'nav-section--active': active,
        })}
      >
        <div
          className={cx('nav-section__box', {
            'nav-section__box--active': this.state.hover,
          })}
        >
          <HoverTriggeredTooltip placement="right" title={section.name}>
            <div className={cx('nav-section__box-icon')}>
              <SectionIcon byWidth color={this.stateToColor(section, active, this.state.hover)} />
            </div>
          </HoverTriggeredTooltip>
        </div>
        {this.state.hover && this.props.popover}
        {active && <div className={cx('nav-section__side')} />}
      </div>
    );
  }
}

export default NavSection;
