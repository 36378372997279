import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import t from '@properly/localization';
import { Modal } from '@properly/components';
import { setWarningModalSaga } from '../state/ChecklistActions';
import { selectGlobalModalResolved } from '../../../../selectors/globalSelector';

const name = 'checklistdeletewarningmodal';

function mapStateToProps() {
  const memo = selectGlobalModalResolved(name);
  return (state, props) => ({
    data: memo(state, props),
  });
}

class DefaultSettingsWarningModal extends PureComponent {
  answer = answer => () => this.props.setWarningModalSaga(answer, name);

  render() {
    const isOpen = !!this.props.data.isOpen;
    return (
      <Modal show={isOpen} onClose={this.answer(false)} height={230}>
        <div>
          <Modal.H1>{t('defaultsettings.headline_modal')}</Modal.H1>
          <Modal.Text>{t('defaultsettings.subhead_modal')}</Modal.Text>
          <Modal.Options>
            <Modal.Cancel onClick={this.answer(false)}>{t('defaultsettings.cancel')}</Modal.Cancel>
            <Modal.Ok data-key="delete_default_checklist" onClick={this.answer(true)}>
              {t('defaultsettings.ok')}
            </Modal.Ok>
          </Modal.Options>
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  {
    setWarningModalSaga,
  },
)(DefaultSettingsWarningModal);
