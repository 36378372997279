import './config/momentLocal';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

/* eslint-disable */
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router, hashHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { ApolloProvider } from 'react-apollo';
import { App } from '@properly/components';
import { initConfig, initSentry } from '@properly/config';
/* eslint-enable */

import moment from 'moment-timezone';
import createRoutes from './routes';
import configureStore from './store/configureStore';
import { setRequestApi } from './modules/desktopApp/data.requestHandler';
import { logout } from './modules/desktopApp/account/state/AccountActions';
import { getLanguage } from './config/localization';
import { runintercom } from './config/intercom';
import './config/touch';
import './config/logger';
import './config/timezone';
import './config/googleAdwords';
import { client } from './config/apollo';

runintercom();
initConfig();
initSentry('desktop');

if (typeof window !== 'undefined') {
  window._REACT_VERSION_ = React.version; // eslint-disable-line
  window.moment = window.moment || moment;
}

const initWebApp = () => {
  const store = configureStore(undefined, hashHistory, client);
  setRequestApi({ logout: () => store.dispatch(logout()) });

  const history = syncHistoryWithStore(hashHistory, store);
  const routes = createRoutes(store, history);

  if (process.env.NODE_ENV === 'development') {
    const installDevTools = require('immutable-devtools'); // eslint-disable-line
    const Immutable = require('immutable'); // eslint-disable-line
    installDevTools(Immutable);
  }

  render(
    <App>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <Router history={history}>{routes}</Router>
        </Provider>
      </ApolloProvider>
    </App>,
    document.getElementById('app'),
  );
};

getLanguage(initWebApp);
