/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Button,
  Icon,
  Input,
  Large,
  LoadingSplash,
  Regular,
  Small,
  Title2,
  Title3,
  PopoverDefault,
  ButtonList,
  JobDetailsExpansion,
  ProblemReportsExpansion,
} from '@properly/components';
import t from '@properly/localization';
import { getTitleFromJobRequest, prettyPrintDuration, getJobTypeKey } from '@properly/common';
import { doJob, skillStatus, featureFlag, jobRequestStatus } from '@properly/config';
import log from 'loglevel';
import lodashResult from 'lodash/result';
import map from 'lodash/map';
import indexOf from 'lodash/indexOf';
import findIndex from 'lodash/findIndex';
import reduce from 'lodash/reduce';
import classNames from 'classnames/bind';
import styles from '../components/jobDetailsStyles.module.css';
import HeadBar from '../../../../components/HeadBar/index';
import { PagePadding } from '../../../../components/PageElements/index';
import { MasterDetailCell } from '../../../../components/MasterDetail/index';
import {
  selectedJobRequest,
  selectJobDetails,
  selectPhotoFeedbackStats,
  selectSourceJobProblems,
  selectResolvedProblem,
  selectStripePaymentDetails,
  selectVerificationPhotos,
} from '../state/JobDetailsSelectors';
import {
  cancelJobRequestSaga,
  closeJobRequestDetails,
  editSaga,
  costSaga,
  moreCleanersSaga,
  showBookingDetails,
  goToContact,
  scheduleProblemsJob,
  openSourceJob,
} from '../state/JobDetailsActions';
import { getInitials } from '../../../../helper/herbert';
import { setSliderSaga, setModal } from '../../../../actions/globalActions';
import { selectCurrentUser } from '../../../../selectors/globalSelector';
import {
  JobDetailsAddress,
  JobDetailsCleanerList,
  JobDetailsImageGrid,
  JobDetailsSection,
  JobDetailsSummary,
  JobDetailsPaymentDetails,
} from '../components/JobDetailsItems';
import CommentSection from '../../../../components/CommentSection';
import JobDetailsProblemList from '../components/JobDetailsProblemList';
import HasPermission from '../../../../hoc/HasPermission';
import JobProgress from '../../../../model/jobProgress';
import { handleClickSaga, updateProblemStatus } from '../../jobRequest/state/JobRequestActions';
import { selectJobRequestTasks } from '../../jobRequest/state/JobRequestSelectors';
import { selectIsFeatureFlagEnabledForUser } from '../../settings/state/SettingsSelectors';
import { getDebugMode } from '../../../../containers/Environment';
import JobDetailsDebugMode from '../components/JobDetailsDebugMode';

const cx = classNames.bind(styles);

class JobDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { openMore: {} };
  }

  setOpenMore(id) {
    this.setState(prevState => {
      const val = prevState.openMore[id] === undefined ? false : prevState.openMore[id];
      const res = {
        openMore: {
          ...prevState.openMore,
          [id]: !val,
        },
      };
      return res;
    });
  }

  getLocationSafe(property) {
    if (!property) return undefined;
    return property.location;
  }

  get selectJobDetailsJS() {
    return this.props.selectJobDetails?.toJS();
  }

  getJobRequestId() {
    return lodashResult(this.props.selectedJobRequest, ['jobRequest', 'backwardCompatibility', 'jobRequestId']);
  }

  getPropertyId() {
    return lodashResult(this.props.selectedJobRequest, ['jobRequest', 'propertyId']);
  }

  openVerificationPics = (images, imageNeedle) => () => {
    const index = indexOf(images, imageNeedle);
    const indexReal = index !== -1 ? index : 0;
    const imagesMapped = map(images, image => ({
      image,
    }));
    this.props.setSliderSaga(true, imagesMapped, indexReal, {
      isThumbsUpDownSlider: true,
      jobRequestId: this.getJobRequestId(),
      propertyId: this.getPropertyId(),
    });
  };

  openProblemPics = (problems, image) => () => {
    const images = reduce(
      problems,
      (total, problem) => {
        log.info(`total:${total}`);
        log.info(`problem:${JSON.stringify(problem)}`);

        if (problem.pictureIdentifiers && problem.pictureIdentifiers.length > 0) {
          problem.pictureIdentifiers.forEach(pictureUrl => total.push({ image: pictureUrl }));
        }
        return total;
      },
      [],
    );

    const index = findIndex(images, ['image', image]);
    const indexReal = index !== -1 ? index : 0;
    this.props.setSliderSaga(true, images, indexReal);
  };

  mapProblemToTask = ({ id, title, note, pictureIdentifiers }) => ({
    id,
    isProblem: true,
    title,
    task: note,
    pictureIdentifiers,
  });

  handleProblemClick = (e, problem) => {
    this.props.handleClickSaga(false, {
      action: 'mapProblemsToTask',
      mapProblemsToTask: this.mapProblemToTask(problem),
      set: e.target.checked,
    });
  };

  onChangeProblemStatus = (jobRequest, problem, problemStatus, sourceJob) => {
    this.props.updateProblemStatus(jobRequest, problem, problemStatus, sourceJob);
  };

  openSourceJobSummary = sourceJobSnapshotId => {
    this.props.openSourceJob(sourceJobSnapshotId);
  };

  showEditButton(bool, canCancel, disableEdit) {
    if (!bool) return null;
    const styleOverwrite = {
      buttonBase: {
        padding: '11px 24px',
      },
    };
    return (
      <div id="jobdetails-edit">
        <PopoverDefault
          popoverKey="editJobDetails"
          hasArrow
          width={230}
          popoverContent={PopoverState => (
            <ButtonList
              closePopover={PopoverState.closePopup}
              items={[
                ...(disableEdit
                  ? [
                      {
                        'data-key': 'editJobRequest',
                        name: t('jobdetails.edit'),
                        onClick: () => this.props.editSaga(),
                      },
                    ]
                  : []),
                ...(canCancel
                  ? [
                      {
                        'data-key': 'cancelJobRequest',
                        name: t('jobdetails.cancel'),
                        onClick: () => {
                          const jobRequestId = this.props.selectedJobRequest.jobRequest.jobId;
                          this.props.cancelJobRequestSaga(jobRequestId);
                        },
                      },
                    ]
                  : []),
              ]}
              styleOverwrite={styleOverwrite}
              disableTypography
            />
          )}
        >
          <div>
            <Large type="orange" data-key="edit-job-request-button">
              Edit
            </Large>
          </div>
        </PopoverDefault>
      </div>
    );
  }

  canRenderSenderData(senderData, hostData, currentUser) {
    return !(
      (!senderData && hostData.id === currentUser.objectId) ||
      (senderData && senderData.id === currentUser.objectId)
    );
  }

  renderSenderDat(obj, companyName) {
    return (
      <JobDetailsSection title={t('jobdetails.sentby')}>
        <MasterDetailCell
          picture={{
            text: getInitials(obj.firstName, obj.lastName),
            picture: obj.pictureIdentifier,
          }}
          type="jobdetails"
          noHover
          onClick={() => {}}
          active={false}
          title={companyName}
          subTitle={`${obj.firstName} ${obj.lastName}`}
        />
      </JobDetailsSection>
    );
  }

  renderFlexibleStartTime(jobRequest) {
    const duration = jobRequest.getDateDurationInMin();
    const prettyDuration = prettyPrintDuration(duration);
    return (
      <>
        <div style={{ display: 'flex' }}>
          <div style={{ flexDirection: 'column' }} className={cx('jobdetails__date')}>
            <Small type="bold">{t('job_request.start_after')}</Small>
            <Title3 type="reseth1">{jobRequest.getFlexibleStartDateString()}</Title3>
            <Title3 type="reseth1">{jobRequest.getStartTimeString()}</Title3>
          </div>
          <div style={{ margin: '0 16px' }} className={cx('jobdetails__date-icon')}>
            <Icon.IcTimeArrow />
          </div>
          <div style={{ flexDirection: 'column' }} className={cx('jobdetails__date')}>
            <Small type="bold">{t('job_request.finish_before')}</Small>
            <Title3 type="reseth1">{jobRequest.getFlexibleEndDateString()}</Title3>
            <Title3 type="reseth1">{jobRequest.getEndTimeString()}</Title3>
          </div>
        </div>
        <div>
          <Regular type="greyloading">
            Duration:
            {prettyDuration}
          </Regular>
        </div>
      </>
    );
  }

  renderFixedStartTime(jobRequest) {
    const duration = jobRequest.getDateDurationInMin();
    const prettyDuration = prettyPrintDuration(duration);
    return (
      <>
        <Title3 type="reseth1">{jobRequest.getDateString()}</Title3>
        <div className={cx('jobdetails__date')}>
          <Title3 type="reseth1">{jobRequest.getStartTimeString()}</Title3>
          <div className={cx('jobdetails__date-icon')}>
            <Icon.IcTimeArrow />
          </div>
          <Title3 type="reseth1">{jobRequest.getEndTimeString()}</Title3>
        </div>
        <div>
          <Regular type="greyloading">
            <Regular type="greyloading">
              Duration:
              {prettyDuration}
            </Regular>
          </Regular>
        </div>
      </>
    );
  }

  renderLeft(jobRequest, mappedCleaners, currentUser) {
    const { checklists, sourceProblems, tasks = [], skillRequirements = [] } = jobRequest || {};
    const { isMarketPlaceFeatureFlagEnabled } = this.props;
    const pureChecklists = checklists.filter(checklist => !checklist.isOneOff);
    const sourceJobSnapshotId = sourceProblems ? sourceProblems.sourceJobSnapshotId : null;
    const jobTypeKey = getJobTypeKey(jobRequest.jobType);
    const idmapper = (id, jobr) => {
      log.log('inidmapper', id, 'jobrequest', jobr);
    };

    const checklistTitles = (pureChecklists || [{}]).map(({ title }, index) => ({
      title: title || t('jobdetails.no_checklist'),
      number: checklists && checklists.length > 1 ? index + 1 : undefined,
    }));

    const taskTitles = (tasks || [{}]).map(({ title }, index) => ({
      title: title || t('jobdetails.no_task'),
      number: tasks && tasks.length > 0 ? index + 1 : undefined,
    }));

    const skillTitles = (skillRequirements || [{}]).map(({ title, progress }, index) => ({
      title: title || t('skills.no_skill'),
      number: skillRequirements?.length > 0 ? index + 1 : undefined,
      progress,
    }));

    return (
      <div>
        <JobDetailsSection title={t('jobdetails.date_time')}>
          {jobRequest.isFlexibleStartTime()
            ? this.renderFlexibleStartTime(jobRequest)
            : this.renderFixedStartTime(jobRequest)}
        </JobDetailsSection>

        {jobRequest.eventTitle && (
          <JobDetailsSection title={t('jobdetails.event')}>
            <Title3 type="reseth1">{jobRequest.eventTitle}</Title3>
          </JobDetailsSection>
        )}

        <HasPermission
          meta={{ propertyId: jobRequest.propertyId, jobRequestId: jobRequest.jobId }}
          hasAccessFormatter={hasAccessRes => hasAccessRes.canSeePriceAndPayments}
          render={canSeePriceAndPayments => {
            if (canSeePriceAndPayments) {
              if (jobRequest.hasPrice) {
                return (
                  <JobDetailsSection title={t('jobdetails.price')}>
                    {jobRequest.offeredPrice !== 0 && (
                      <Title3 type="reseth1">
                        {`${jobRequest.offeredPriceCurrency || ''} `}
                        <span data-key="offered-price-on-job-details">{jobRequest.offeredPrice}</span>
                      </Title3>
                    )}
                  </JobDetailsSection>
                );
              }
            }
            return null;
          }}
        />

        {jobRequest.jobType && jobRequest.jobType !== doJob && (
          <JobDetailsSection title={t('job_request.type')}>
            <Title3 type="reseth1">{jobTypeKey ? t(`reports.jobTypes.${jobTypeKey}`) : jobRequest.jobType}</Title3>
          </JobDetailsSection>
        )}

        {pureChecklists.length > 0 && (
          <JobDetailsSection title={t('jobdetails.checklist')}>
            {checklistTitles.map(({ title, number }) => (
              <Title3 key={title} type="reseth1">
                {number && <span style={{ minWidth: '28px', display: 'inline-block' }}>{number && `${number}) `}</span>}
                {title}
              </Title3>
            ))}
          </JobDetailsSection>
        )}

        {tasks.length > 0 && (
          <JobDetailsSection title={t('job_request.task')}>
            {taskTitles.map(({ title, number }) => (
              <Title3 key={title} type="reseth1">
                {number && <span style={{ minWidth: '28px', display: 'inline-block' }}>{number && `${number}) `}</span>}
                {title}
              </Title3>
            ))}
          </JobDetailsSection>
        )}
        {skillRequirements?.length > 0 && (
          <JobDetailsSection title={t('skills.requirements')}>
            {skillTitles.map(({ title, number, progress }) => (
              <Title3 key={title} type="reseth1">
                <div style={{ display: 'inline-flex' }}>
                  {number && (
                    <span style={{ minWidth: '28px', display: 'inline-block' }}>{number && `${number}) `}</span>
                  )}
                  {title}
                  {progress === skillStatus.PROGRESS_STATUS.COMPLETED && (
                    <span style={{ marginLeft: '10px' }}>
                      <Icon.Ic16PxCheck height="24x" width="24px" byHeight />
                    </span>
                  )}
                </div>
              </Title3>
            ))}
          </JobDetailsSection>
        )}

        <HasPermission
          meta={{ propertyId: jobRequest.propertyId, jobRequestId: jobRequest.jobId }}
          hasAccessFormatter={hasAccessRes => hasAccessRes.canSendJobs}
          render={canSendJobs => {
            if (canSendJobs) {
              if (sourceJobSnapshotId) {
                return (
                  <ProblemReportsExpansion
                    sourceJob
                    renderLeft
                    problems={this.props.sourceProblems}
                    openProblemPics={this.openProblemPics}
                    onProblemSelect={this.handleProblemClick}
                    tasks={this.props.tasks}
                    jobRequest={jobRequest}
                    onChangeProblemStatus={this.onChangeProblemStatus}
                    openSourceJobSummary={this.openSourceJobSummary}
                    resolvedProblemUserName={this.props.resolvedProblem.name}
                  />
                );
              }
            }
            return null;
          }}
        />

        <JobDetailsSection title={t('jobdetails.address')}>
          <JobDetailsAddress propertyData={jobRequest.propertyData} />
        </JobDetailsSection>

        {this.canRenderSenderData(jobRequest.senderData, jobRequest.hostData, currentUser) &&
          this.renderSenderDat(jobRequest.senderData, jobRequest.companyName)}

        {mappedCleaners.length > 0 && (
          <JobDetailsSection title={t('jobdetails.invited_cleaners')}>
            <HasPermission
              meta={{ propertyId: jobRequest.propertyId }}
              hasAccessFormatter={hasAccessRes => hasAccessRes.canSendJobs}
              render={canSendMore => (
                <JobDetailsCleanerList
                  onClick={id => {
                    idmapper(id, jobRequest);
                    this.props.closeJobRequestDetails();
                    this.props.goToContact(id);
                  }}
                  cleaners={mappedCleaners}
                  timezone={jobRequest.propertyData.timeZone}
                  showButton={canSendMore && jobRequest.canSendMore}
                  sagaCleaner={this.props.moreCleanersSaga}
                  setOpenMore={() => this.setOpenMore(jobRequest.jobId)}
                  isOpen={this.state.openMore[jobRequest.jobId] || false}
                  proMarketProperty={isMarketPlaceFeatureFlagEnabled && jobRequest.propertyData?.proMarketId}
                />
              )}
            />
          </JobDetailsSection>
        )}
        {jobRequest.hasNote && (
          <JobDetailsSection title={t('job_request.description')}>
            <Small>
              <Input isLast isFirst textAreaHeight={200} value={jobRequest.note.trim()} type="textarea" disabled />
            </Small>
          </JobDetailsSection>
        )}
      </div>
    );
  }

  renderBookingDetailsButton(jobRequest) {
    const { event } = jobRequest || {};
    const { bookingId, propertyId } = event || {};

    return bookingId && propertyId ? (
      <div style={{ paddingTop: 16 }}>
        <Button
          onClick={() => this.props.handleBookingClick(propertyId, bookingId)}
          types={['type-border-grey', 'size-medium', 'width-flex']}
        >
          {t('jobdetails.show_booking_details')}
        </Button>
      </div>
    ) : null;
  }

  renderRight(jobRequest, mappedCleaners, locationStatus) {
    const {
      problems,
      propertyData: { countryCode },
      costs,
    } = jobRequest || {};
    const { photoStats, tasks, stripePaymentDetails, verificationPhotos, isMarketPlaceFeatureFlagEnabled } = this.props;

    const {
      jobProgress: { completedTaskCount, verificationPictureCount, bilableTime, totalMileage, totalCost },
    } = this.selectJobDetailsJS;

    let totalTaskCount = jobRequest.jobData?.totalTaskCount;
    const isdefaultExpanded = tasks.some(task => task.isProblem);

    return (
      <div>
        <JobDetailsSection title={t('jobdetails.summary')}>
          <HasPermission
            meta={{
              propertyId: jobRequest.propertyId,
              jobRequestId: jobRequest.jobId,
            }}
            hasAccessFormatter={hasAccessRes => hasAccessRes}
            render={({ canSeeTaskLeftPhotos, canViewThumbsUpComments }) => {
              const totalPics = canSeeTaskLeftPhotos ? jobRequest.jobData.totalVerificationPictureCount || 0 : 0;
              const totalCommentsViewed = canViewThumbsUpComments ? photoStats.commentsViewed : 0;
              const totalCommentsTotal = canViewThumbsUpComments ? photoStats.commentsTotal : 0;
              const totalThumbsUp = canViewThumbsUpComments ? photoStats.thumbsUp : 0;
              const totalThumbsDown = canViewThumbsUpComments ? photoStats.thumbsDown : 0;
              const ccompletedTaskCount = canSeeTaskLeftPhotos ? completedTaskCount : 0;
              totalTaskCount = canSeeTaskLeftPhotos ? totalTaskCount : 0;
              return (
                <JobDetailsSummary
                  tasksDone={ccompletedTaskCount}
                  totalTaskCount={totalTaskCount}
                  verificationDoneCount={verificationPictureCount}
                  totalVerificationCount={totalPics}
                  actualStartTime={jobRequest.actualStartTime}
                  timezone={jobRequest.propertyData.timeZone}
                  actualEndTime={jobRequest.actualEndTime}
                  cleaners={mappedCleaners}
                  locationStatus={locationStatus}
                  commentsViewed={totalCommentsViewed}
                  commentsTotal={totalCommentsTotal}
                  thumbsUp={totalThumbsUp}
                  thumbsDown={totalThumbsDown}
                  proMarketProperty={isMarketPlaceFeatureFlagEnabled && jobRequest.monetizedJob}
                />
              );
            }}
          />

          {this.renderBookingDetailsButton(jobRequest)}
        </JobDetailsSection>

        <HasPermission
          meta={{ propertyId: jobRequest.propertyId, jobRequestId: jobRequest.jobId }}
          hasAccessFormatter={hasAccessRes => hasAccessRes.canSeePriceAndPayments}
          render={canSeePriceAndPayments => {
            if (canSeePriceAndPayments) {
              if (
                isMarketPlaceFeatureFlagEnabled &&
                jobRequest.monetizedJob &&
                jobRequest.status === jobRequestStatus.StateFinished
              ) {
                return (
                  <JobDetailsPaymentDetails
                    jobRequestId={jobRequest.backwardCompatibility?.jobRequestId}
                    actualEndTime={jobRequest.actualEndTime}
                    stripePaymentDetails={stripePaymentDetails}
                  />
                );
              }
            }
            return null;
          }}
        />
        <HasPermission
          meta={{ propertyId: jobRequest.propertyId, jobRequestId: jobRequest.jobId }}
          hasAccessFormatter={hasAccessRes => hasAccessRes.canSendJobs}
          render={canSendJobs => {
            if (problems.length || costs) {
              if (canSendJobs) {
                return (
                  <JobDetailsExpansion
                    problems={problems}
                    openProblemPics={this.openProblemPics}
                    openJobRequestModal={this.props.scheduleProblemsJob}
                    onProblemSelect={this.handleProblemClick}
                    tasks={tasks}
                    jobRequest={jobRequest}
                    onChangeProblemStatus={this.onChangeProblemStatus}
                    isdefaultExpanded={isdefaultExpanded}
                    bilableTime={bilableTime}
                    totalMileage={totalMileage}
                    totalCost={totalCost}
                    resolvedProblemUserName={this.props.resolvedProblem.name}
                    countryCode={countryCode}
                    costs={costs}
                    openCostModal={this.props.costSaga}
                    openSourceJobSummary={this.openSourceJobSummary}
                  />
                );
              }
              if (problems.length) {
                return (
                  <JobDetailsSection title={t('jobdetails.problems_reported')}>
                    <JobDetailsProblemList
                      problems={problems}
                      timezone={jobRequest.propertyData.timeZone}
                      openProblemPics={this.openProblemPics}
                    />
                  </JobDetailsSection>
                );
              }
              return null;
            }
            return null;
          }}
        />

        <HasPermission
          meta={{
            propertyId: jobRequest.propertyId,
            jobRequestId: jobRequest.jobId,
          }}
          hasAccessFormatter={hasAccessRes => ({
            seeVerificationPhotos: hasAccessRes.seeVerificationPhotos && verificationPhotos.length > 0,
            canViewThumbsUpComments: hasAccessRes.canViewThumbsUpComments,
          })}
          render={({ seeVerificationPhotos, canViewThumbsUpComments }) => {
            if (!seeVerificationPhotos || verificationPhotos.length === 0) {
              return null;
            }

            const pictureIdentifiers = verificationPhotos.map(
              verificationPicture => verificationPicture.pictureIdentifier,
            );
            return (
              <JobDetailsSection title={t('jobdetails.verify_pics')}>
                <div className={cx('jobdetails__box')}>
                  <JobDetailsImageGrid
                    withThumbs={canViewThumbsUpComments}
                    jobRequestId={jobRequest?.backwardCompatibility?.jobRequestId}
                    images={pictureIdentifiers}
                    openVerificationPics={this.openVerificationPics}
                    openFirstVerificationPic={this.openVerificationPics(pictureIdentifiers, pictureIdentifiers[0])}
                  />
                </div>
              </JobDetailsSection>
            );
          }}
        />
        <CommentSection currentUser={this.props.currentUser} />
      </div>
    );
  }

  render() {
    if (this.selectJobDetailsJS.state === 1) {
      return (
        <div className={cx('jobdetails__bg')}>
          <div className={cx('jobdetails__loading')}>
            <LoadingSplash static />
          </div>
        </div>
      );
    }
    if (this.selectJobDetailsJS.state === 2 || !this.props.selectedJobRequest) {
      return (
        <div className={cx('jobdetails__bg')}>
          <HeadBar onClose={() => this.props.closeJobRequestDetails()} middleText={t('jobdetails.errror_head')} />
          <div className={cx('jobdetails__loading')}>
            <Regular type="orange">{t('jobdetails.error')}</Regular>
          </div>
        </div>
      );
    }
    const { jobRequest, mappedCleaners } = this.props.selectedJobRequest;

    const jobProgress = new JobProgress(jobRequest.jobProgress);

    if (!jobProgress) {
      return null;
    }

    const jobRequestTitle = getTitleFromJobRequest(jobRequest);
    const locationStatus = jobProgress.getAllLocationStatuses(this.getLocationSafe(jobRequest.propertyData));
    const canShowEditJob = jobRequest.jobType !== doJob;
    return (
      <div className={cx('jobdetails__bg')} data-key="job-details-popover">
        <HeadBar
          onClose={this.props.closeJobRequestDetails}
          rightPart={() => (
            <HasPermission
              meta={{ propertyId: jobRequest.propertyId }}
              hasAccessFormatter={hasAccessRes => hasAccessRes.canSendJobs}
              renderWithPermission={() =>
                this.showEditButton(
                  jobRequest.canEdit && (canShowEditJob || jobRequest.canCancel),
                  jobRequest.canCancel,
                  canShowEditJob,
                )
              }
            />
          )}
          middleText={t(jobRequest.getStatusStringAdvanced())}
        />
        <PagePadding>
          <div className={cx('jobdetails__fixmargin')}>
            {getDebugMode() && <JobDetailsDebugMode jobRequest={jobRequest} />}
            {jobRequestTitle && <Title2 style={{ marginBottom: 0 }}>{jobRequestTitle}</Title2>}

            <div className={cx('jobdetails__flex')}>
              <div className={cx('jobdetails__flex-1')}>
                {this.renderLeft(jobRequest, mappedCleaners, this.props.currentUser)}
              </div>
              <div className={cx('jobdetails__flex-1')}>
                {this.renderRight(jobRequest, mappedCleaners, locationStatus)}
              </div>
            </div>
          </div>
        </PagePadding>
      </div>
    );
  }
}

function getJobRequestIdOfSelect(val) {
  return lodashResult(val, ['jobRequest', 'jobId']);
}

function mapStateToProps(state, props) {
  const selectedJobRequestImport = selectedJobRequest()(state, props);
  const verificationPhotos = selectVerificationPhotos()(state, props);

  return {
    selectedJobRequest: selectedJobRequestImport,
    selectJobDetails: selectJobDetails()(state, props),
    currentUser: selectCurrentUser()(state, props),
    jobRequestId: getJobRequestIdOfSelect(selectedJobRequest),
    photoStats: selectPhotoFeedbackStats()(state, props),
    tasks: selectJobRequestTasks()(state, props)?.toJS(),
    stripePaymentDetails: selectStripePaymentDetails()(state, props)?.toJS(),
    sourceProblems: selectSourceJobProblems()(state, props)?.toJS(),
    resolvedProblem: selectResolvedProblem()(state, props)?.toJS(),
    isMarketPlaceFeatureFlagEnabled: selectIsFeatureFlagEnabledForUser(featureFlag.FEATURE_FLAG_MARKETPLACE)(
      state,
      props,
    ),
    verificationPhotos,
  };
}

JobDetailsContainer.propTypes = {
  jobRequestId: PropTypes.string,
  oldJobRequestId: PropTypes.string,
  selectedJobRequest: PropTypes.shape({
    jobRequest: PropTypes.shape({
      jobId: PropTypes.string.isRequired,
    }),
    mappedCleaners: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  selectJobDetails: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  resolvedProblem: PropTypes.shape({
    name: PropTypes.string,
  }),
  stripePaymentDetails: PropTypes.shape({
    id: PropTypes.string,
    paymentDate: PropTypes.string,
    paymentStatus: PropTypes.string,
    paymentAmount: PropTypes.number,
    paymentCurrency: PropTypes.string,
  }),
  verificationPhotos: PropTypes.arrayOf(PropTypes.shape({})),
  photoStats: PropTypes.shape({}),
  sourceProblems: PropTypes.arrayOf(PropTypes.shape({})),
  tasks: PropTypes.arrayOf(PropTypes.shape({})),
  closeJobRequestDetails: PropTypes.func,
  goToContact: PropTypes.func,
  moreCleanersSaga: PropTypes.func,
  handleBookingClick: PropTypes.func,
  handleClickSaga: PropTypes.func,
  updateProblemStatus: PropTypes.func,
  openSourceJob: PropTypes.func,
  scheduleProblemsJob: PropTypes.func,
  costSaga: PropTypes.func,
  editSaga: PropTypes.func,
  cancelJobRequestSaga: PropTypes.func,
  setSliderSaga: PropTypes.func,
};

JobDetailsContainer.defaultProps = {
  jobRequestId: '',
  oldJobRequestId: '',
  editSaga: () => {},
  cancelJobRequestSaga: () => {},
  setSliderSaga: () => {},
  closeJobRequestDetails: () => {},
  goToContact: () => {},
  moreCleanersSaga: () => {},
  handleBookingClick: () => {},
  handleClickSaga: () => {},
  updateProblemStatus: () => {},
  openSourceJob: () => {},
  scheduleProblemsJob: () => {},
  costSaga: () => {},
  photoStats: {},
  sourceProblems: [],
  tasks: [],
  resolvedProblem: {
    name: '',
  },
  stripePaymentDetails: {
    id: null,
    paymentDate: null,
    paymentStatus: null,
  },
  verificationPhotos: [],
};

export default compose(
  connect(mapStateToProps, {
    goToContact,
    closeJobRequestDetails,
    cancelJobRequestSaga,
    editSaga,
    costSaga,
    setSliderSaga,
    moreCleanersSaga,
    handleBookingClick: showBookingDetails,
    setModal,
    handleClickSaga,
    scheduleProblemsJob,
    updateProblemStatus,
    openSourceJob,
  }),
)(JobDetailsContainer);
