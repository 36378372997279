"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  CleanerRequestStateSent: 1,
  CleanerRequestStateDeclined: 2,
  CleanerRequestStateAccepted: 3,
  CleanerRequestStateAcceptedByOtherUser: 4,
  CleanerRequestStateCancelled: 5,
  Pending: 'pending',
  Viewed: 'viewed',
  Declined: 'declined',
  Accepted: 'accepted',
  AcceptedByOtherCleaner: 'acceptedByOtherCleaner',
  PendingChanges: 'pendingChanges',
  DeclinedChanges: 'declinedChanges',
  InProgress: 'inProgress',
  CanceledByCleaner: 'canceledByCleaner',
  CanceledByHost: 'canceledByHost',
  Finished: 'finished'
};
exports["default"] = _default;