import { put, fork, select, takeEvery } from 'redux-saga/effects';
import * as types from '../../../types';
import { setPricingModal } from './PricingActions';
import * as globalSelectors from '../../../selectors/globalSelector';
import { trackProFeatureClick, trackLimitReached, trackPricingThankYou } from '../../../actions/trackingEvents';

function* openPricingModalSaga(action) {
  if (action.kind === 'default' && action.meta && action.meta.defaultKind === 'upgradeplan') {
    trackProFeatureClick(action.attemptedAction);
  }
  if (action.kind === 'default' && action.meta && action.meta.defaultKind === 'yousend') {
    const currentPlan = yield select(globalSelectors.selectUserPlanData);
    if (currentPlan && currentPlan.userData) {
      trackLimitReached(
        currentPlan.userData.subscription.allowedJobsMonth - currentPlan.userData.subscription.jobsUsedMonth,
      );
    }
  }
  if (action.kind === 'thankyou') {
    trackPricingThankYou();
  }
  yield put(setPricingModal({ kind: action.kind, meta: action.meta }));
}

function* closeModalSaga() {
  yield put(setPricingModal({ kind: undefined, meta: {} }));
}

function* saga() {
  yield fork(takeEvery, types.PRICING_OPEN_MODAL_SAGA, openPricingModalSaga);
  yield fork(takeEvery, types.PRICING_CLOSE_MODAL_SAGA, closeModalSaga);
}

export default saga;
