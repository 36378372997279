"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setTrackingCookie = setTrackingCookie;
exports.getUtmParamsFromCookie = getUtmParamsFromCookie;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _loglevel = _interopRequireDefault(require("loglevel"));

var _isArray = _interopRequireDefault(require("lodash/isArray"));

var _find = _interopRequireDefault(require("lodash/find"));

var _mapValues = _interopRequireDefault(require("lodash/mapValues"));

var _extend = _interopRequireDefault(require("lodash/extend"));

var _config = require("@properly/config");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function setTrackingCookie(myParams) {
  try {
    const params = myParams || getUrlParameters();
    const mappedParams = (0, _mapValues.default)(params, val => [].concat(val)[0]);

    _loglevel.default.info('setTrackingCookie', params, mappedParams);

    const domain = '.getproperly.com';
    const settings = {
      domain,
      expires: 365
    };

    if (params.utm_campaign) {
      _jsCookie.default.set('utm_campaign', mappedParams.utm_campaign, settings);
    }

    if (params.utm_source) {
      _jsCookie.default.set('utm_source', mappedParams.utm_source, settings);
    }

    if (params.utm_content) {
      _jsCookie.default.set('utm_content', mappedParams.utm_content, settings);
    }

    if (params.utm_medium) {
      _jsCookie.default.set('utm_medium', mappedParams.utm_medium, settings);
    }

    if (params.utm_term) {
      _jsCookie.default.set('utm_term', mappedParams.utm_term, settings);
    }

    const productInstance = getFirstTruthyParamIfArray(params.product_instance_id);

    if (productInstance) {
      _jsCookie.default.set('signupMeta', extendStringCookie(_jsCookie.default.set('signupMeta'), {
        bookingcomId: productInstance
      }));
    }
  } catch (err) {
    _loglevel.default.error('Error setting tracking cookie', err);
  }
}

function getUtmParamsFromCookie() {
  const res = {
    campaign: _jsCookie.default.get('utm_campaign'),
    referrer: _jsCookie.default.get('utm_referrer') || document.referrer,
    content: _jsCookie.default.get('utm_content'),
    medium: _jsCookie.default.get('utm_medium'),
    source: _jsCookie.default.get('utm_source'),
    term: _jsCookie.default.get('utm_term'),
    signupMeta: _jsCookie.default.get('signupMeta')
  };

  if (!res.medium && !res.source && !res.campaign) {
    if (document.referrer) {
      const match = (0, _find.default)(_config.utmList, i => referrerContains(i.referrer));

      if (match) {
        res.medium = match.medium;
        res.source = match.source;
        res.campaign = match.campaign;
      } else if (referrerContains('getproperly')) {
        res.medium = 'organic';
        res.source = _config.SEO;
        res.campaign = 'home';
      } else {
        res.medium = 'referral';
      }
    } else {
      // no referrer
      res.medium = 'webapp';
      res.source = _config.DIRECT;
    }
  }

  return res;
}

function referrerContains(needle) {
  return document.referrer.indexOf(needle) !== -1;
}

function extendStringCookie(cookieval, objectData) {
  const val = cookieval || '{}';
  let parsed = {};

  try {
    parsed = JSON.parse(val);
  } catch (e) {// silent
  }

  return JSON.stringify((0, _extend.default)({}, objectData, parsed));
}

function getFirstTruthyParamIfArray(param) {
  if ((0, _isArray.default)(param)) {
    return (0, _find.default)(param, val => !!val);
  }

  return param;
}

function getUrlParameters(val) {
  const search = String(window.location.search || '');
  const href = String(window.location.href || '');
  const searchParams = search.substr(1);
  const hrefParams = href.substr(href.indexOf('?') + 1);
  const paramString = val || searchParams || hrefParams;
  const params = {};

  if (paramString !== null && paramString !== '') {
    const paramArray = paramString.split('&');

    for (let i = 0; i < paramArray.length; i++) {
      const tempArray = paramArray[i].split('=');
      const value = tempArray[1];
      params[tempArray[0]] = value;
    }
  }

  return params;
}