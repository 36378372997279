import { fromJS } from 'immutable';
import * as types from '../../../types';

const initState = fromJS({
  modal: {
    kind: undefined,
    meta: {},
  },
});

export default function PricingReducer(state = initState, action = {}) {
  switch (action.type) {
    case types.GLOBAL_LOGOUT: // on logout reset
      return initState;
    case types.PRICING_SET_MODAL:
      return state.set('modal', fromJS(action.data));
    default:
      return state;
  }
}
