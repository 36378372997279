import gql from 'graphql-tag';

export const GET_COMPLETED_SKILLS_QUERY = gql`
  query getCompletedSkills($cleanerId: ID, $language: String) {
    getCompletedSkills(cleanerId: $cleanerId, language: $language) {
      skillId
      title
    }
  }
`;

export const GET_COMPLETED_SKILLS_QUERY_ONLY_IDS = gql`
  query getCompletedSkills($cleanerId: ID, $language: String) {
    getCompletedSkills(cleanerId: $cleanerId, language: $language) {
      skillId
    }
  }
`;

export const GET_SKILLS_QUERY = gql`
  query fetchSkills(
    $includeCompletionStatus: Boolean
    $language: String
    $updatedAfter: DateTime
    $offset: Int
    $limit: Int
  ) {
    fetchSkills(
      includeCompletionStatus: $includeCompletionStatus
      language: $language
      updatedAfter: $updatedAfter
      offset: $offset
      limit: $limit
    ) {
      skillId
      title
      stepCount
      isComplete
    }
  }
`;

export const GET_SPECIFIC_SKILL_QUERY = gql`
  query fetchSkill($includeProgress: Boolean, $language: String, $skillId: String!) {
    fetchSkill(includeProgress: $includeProgress, language: $language, skillId: $skillId) {
      skillId
      title
      stepCount
      isComplete
      steps {
        ...stepsFragment
      }
      description
    }
  }

  fragment stepsFragment on stepFields {
    id
    pictureIdentifier
    title
    tasks {
      ...tasksFragment
    }
  }

  fragment tasksFragment on taskFields {
    id
    note
    notePictureIdentifiers
  }
`;
