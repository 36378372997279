import { delay } from 'redux-saga';
import last from 'lodash/last';
import { call, cancel, fork, put, select, takeEvery } from 'redux-saga/effects';
import * as types from '../../types';
import { updateCollectionEntry } from '../../actions/globalActions';
import { selectPhotoFeedbackById } from '../../selectors/globalSelector';
import { selectAdminMode } from '../../modules/desktopApp/settings/state/SettingsSelectors';
import { notifyVerificationPhotoFeedback } from '../../modules/desktopApp/data';

function* handleError(e, photofeedback, snapshot) {
  if (e.code === 'NotFound') {
    // scrape
    yield put(updateCollectionEntry('photofeedback', photofeedback.uniId));
  } else if (e.latestVersion) {
    // up version
    yield put(updateCollectionEntry('photofeedback', photofeedback.uniId, e.latestVersion));
  } else if (snapshot) {
    // rollback
    yield put(updateCollectionEntry('photofeedback', photofeedback.uniId, snapshot.extend({})));
  }
}

// create
function* createPhotoComment({ data }) {
  const dashboardAdminMode = yield select(selectAdminMode);
  try {
    const res = yield call(notifyVerificationPhotoFeedback, data.jobRequestId, data.pictureUrl, 'comment', 'add', {
      comment: data.comment?.trim(),
      dashboardAdminMode,
    });
    yield put(updateCollectionEntry('photofeedback', res.uniId, res));
  } catch (e) {
    // dont handle
  }
}

// create
function* createPhotoThumbs({ data }) {
  const dashboardAdminMode = yield select(selectAdminMode);
  try {
    const res = yield call(
      notifyVerificationPhotoFeedback,
      data.jobRequestId,
      data.pictureUrl,
      'thumbs',
      'set',
      {
        thumbs: data.thumbs,
      },
      dashboardAdminMode,
    );
    yield put(updateCollectionEntry('photofeedback', res.uniId, res));
  } catch (e) {
    // dont handle
  }
}

function bufferFlush(handler, flushEvery) {
  const buffer = [];
  let task;
  return function* run(action) {
    if (task) {
      yield cancel(task);
    }
    buffer.push(action);
    task = yield fork(
      // eslint-disable-next-line
      function*(h, a, bu) {
        yield delay(flushEvery);
        yield call(h, a);
        bu.length = 0; // reset buffer
      },
      handler,
      last(buffer),
      buffer,
    );
  };
}

// delete
function* deletePhotoComment({ photofeedback }) {
  const dashboardAdminMode = yield select(selectAdminMode);
  const snapshot = yield select(selectPhotoFeedbackById(photofeedback.uniId));
  try {
    yield call(
      notifyVerificationPhotoFeedback,
      photofeedback.jobRequestId,
      photofeedback.pictureUrl,
      'comment',
      'remove',
      { id: photofeedback.id, version: photofeedback.version },
      dashboardAdminMode,
    );
    yield put(updateCollectionEntry('photofeedback', photofeedback.uniId));
  } catch (e) {
    yield handleError(e, photofeedback, snapshot);
  }
}

// delete
function* deletePhotoThumbs({ photofeedback }) {
  const dashboardAdminMode = yield select(selectAdminMode);

  const snapshot = yield select(selectPhotoFeedbackById(photofeedback.uniId));
  try {
    yield call(
      notifyVerificationPhotoFeedback,
      photofeedback.jobRequestId,
      photofeedback.pictureUrl,
      'thumbs',
      'remove',
      { id: photofeedback.id, version: photofeedback.version },
      dashboardAdminMode,
    );
    yield put(updateCollectionEntry('photofeedback', photofeedback.uniId));
  } catch (e) {
    yield handleError(e, photofeedback, snapshot);
  }
}

// update
function* updatePhotoComment({ photofeedback }) {
  const dashboardAdminMode = yield select(selectAdminMode);

  const snapshot = yield select(selectPhotoFeedbackById(photofeedback.uniId));
  try {
    const updatedEntry = yield call(
      notifyVerificationPhotoFeedback,
      photofeedback.jobRequestId,
      photofeedback.pictureUrl,
      'comment',
      'edit',
      {
        id: photofeedback.id,
        version: photofeedback.version,
        comment: photofeedback.comment?.trim(),
        dashboardAdminMode,
      },
    );
    yield put(updateCollectionEntry('photofeedback', photofeedback.uniId, updatedEntry));
  } catch (e) {
    yield handleError(e, photofeedback, snapshot);
  }
}

// update
function* updatePhotoThumbs({ photofeedback }) {
  const dashboardAdminMode = yield select(selectAdminMode);

  const snapshot = yield select(selectPhotoFeedbackById(photofeedback.uniId));
  try {
    const updatedEntry = yield call(
      notifyVerificationPhotoFeedback,
      photofeedback.jobRequestId,
      photofeedback.pictureUrl,
      'thumbs',
      'edit',
      { id: photofeedback.id, version: photofeedback.version, thumbs: photofeedback.thumbs },
      dashboardAdminMode,
    );
    yield put(updateCollectionEntry('photofeedback', photofeedback.uniId, updatedEntry));
  } catch (e) {
    yield handleError(e, photofeedback, snapshot);
  }
}

function* saga() {
  yield fork(takeEvery, types.THUMBS_BAR_CREATE_COMMENT, createPhotoComment);
  yield fork(takeEvery, types.THUMBS_BAR_DELETE_COMMENT, deletePhotoComment);
  yield fork(takeEvery, types.THUMBS_BAR_UPDATE_COMMENT, updatePhotoComment);
  yield fork(takeEvery, types.THUMBS_BAR_UPDATE_THUMBS, bufferFlush(updatePhotoThumbs, 1000));
  yield fork(takeEvery, types.THUMBS_BAR_CREATE_THUMBS, bufferFlush(createPhotoThumbs, 1000));
  yield fork(takeEvery, types.THUMBS_BAR_DELETE_THUMBS, deletePhotoThumbs);
}

export default saga;
