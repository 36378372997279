import React from 'react';
import t from '@properly/localization';
import { Input, SpacedHeadline, SpaceMedium } from '@properly/components';
import { getJobType, getJobTypeKey } from '@properly/common';

import {
  JobRequestTextMain,
  JobRequestContentWrap,
  JobRequestSpacerWrap,
} from '../../../../components/JobRequestElements/index';

function Title({ title, changeJobTitle, changeJobType, jobType }) {
  const jobTypeList = [{ value: t('job_request.select_type'), title: t('job_request.select_type') }, ...getJobType()];

  return (
    <JobRequestContentWrap id="job-request-message-form">
      <JobRequestSpacerWrap left="24px" top="12px" bottom="12px" right="24px" flexGrow>
        <SpacedHeadline size="small">{t('job_request.type')}</SpacedHeadline>

        <Input
          data-key="jobrequestsend_job_type"
          placeholder={t('job_request.select_type')}
          onChange={e => changeJobType(e.target.value || '')}
          value={jobType}
          styleOverwrite={{ backgroundColor: 'white' }}
          isFirst
          isLast
          options={jobTypeList}
          optionsBtn
          optionsColor={jobType ? 'normal' : 'inactive'}
          style={{ textAlign: 'left', fontSize: '16px' }}
          type="select"
          defaultValueOfSelect={t('job_request.select_type')}
        />

        <SpaceMedium />

        <SpacedHeadline size="small">{t('job_request.title')}</SpacedHeadline>
        <Input
          isFirst
          isLast
          data-key="jobrequestsend_title"
          placeholder={t('job_request.write_title')}
          onChange={e => changeJobTitle(e.target.value || '')}
          defaultValue={title}
          type="input"
        />
      </JobRequestSpacerWrap>
    </JobRequestContentWrap>
  );
}

Title.content = ({ title, jobType }) => {
  const jobTypeKey = getJobTypeKey(jobType);
  return (
    <>
      <JobRequestTextMain cutText>{jobTypeKey ? t(`reports.jobTypes.${jobTypeKey}`) : jobType}</JobRequestTextMain>
      <JobRequestTextMain cutText>{title}</JobRequestTextMain>
    </>
  );
};

export default Title;
