import React from 'react';
import lodashResult from 'lodash/result';
import Checklists from './ChecklistsContainerConnected';

export default ({ readOnly, closeRoute, params, location }) => (
  <Checklists
    redirectUrl={lodashResult(location, ['query', 'redirectUrl'])}
    pathname={lodashResult(location, ['pathname'])}
    checklistId={params.checklistId}
    jobInstructionId={params.jobInstructionId}
    readOnly={!!readOnly}
    closeRoute={closeRoute}
  />
);
