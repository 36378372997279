import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import lodashResult from 'lodash/result';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { Pony, ProfilePicture, ButtonList, Icon, PopoverDefault } from '@properly/components';
import { connect } from 'react-redux';
import t from '@properly/localization';
import classNames from 'classnames/bind';
import styles from './index.module.css';
import NavSection from './NavSection';
import { getInitials } from '../../helper/herbert';
import { ROUTES } from '../../paths';
import { goTo } from '../../actions/globalActions';
import { goToAccountHelp, logout } from '../../modules/desktopApp/account/state/AccountActions';
import { TodosTextEle } from '../../components/TodosElements';
import { selectGeneralConfig } from '../../selectors/globalSelector';
import { selectHasConnectionIssue } from '../../modules/desktopApp/account/state/AccountSelectors';

const cx = classNames.bind(styles);

const navColors = {
  colorDefault: '#858A8F',
  colorHover: '#91959A',
  colorActive: '#FFFFFF',
};

class NavContainer extends PureComponent {
  static propTypes = {
    currentPage: PropTypes.string,
    popoverSections: PropTypes.arrayOf(PropTypes.string).isRequired,
    mainSections: PropTypes.arrayOf(PropTypes.string).isRequired,
    showCompanyInfo: PropTypes.bool,
  };

  sections = {
    todos: {
      id: 'todos',
      icon: Icon.NavTodos,
      ...navColors,
      path: ROUTES.todos,
      name: t('todos.headline'),
    },
    calendar: {
      id: 'calendar',
      icon: Icon.NavCalendar,
      ...navColors,
      path: ROUTES.calendar,
      name: t('topbar.calendar'),
    },
    properties: {
      id: 'properties',
      icon: Icon.NavProperties,
      ...navColors,
      path: ROUTES.properties,
      name: t('topbar.properties'),
    },
    library: {
      id: 'library',
      icon: Icon.IcChecklistLibrary,
      ...navColors,
      path: ROUTES.library,
      name: t('topbar.library'),
    },
    contacts: {
      id: 'contacts',
      icon: Icon.NavContacts,
      ...navColors,
      path: ROUTES.contacts,
      name: t('topbar.contacts'),
    },
    reports: {
      id: 'reports',
      icon: Icon.NavReports,
      ...navColors,
      path: ROUTES.reports,
      name: t('topbar.reports'),
    },
    settings: {
      id: 'settings',
      icon: Icon.NavSettings,
      ...navColors,
      path: ROUTES.settings,
      name: t('topbar.settings'),
      showConnectionIssue: true,
    },
  };

  style = ['big', 'invert'];

  profileNavigationList = () => [
    {
      key: 'user',
      'data-key': 'nav-userprofile',
      icon: (
        <span style={{ display: 'block', width: '36px', height: '36px', margin: '0' }}>
          <ProfilePicture type="square-small" picture={this.props.pictureUrl} />
        </span>
      ),
      isTitle: true,
      name: `${this.props.firstName} ${this.props.lastName}`,
      onClick: () => this.props.goTo(ROUTES.account),
      secondaryName: this.props.showCompanyInfo ? (
        <TodosTextEle
          modifier={['txt--l7', 'color--grey2']}
          preElement={<Icon.TeamIcon height="10px" width="9px" inlineBlock byWidth />}
        >
          <span>{this.props.companyName}</span>
        </TodosTextEle>
      ) : (
        ''
      ),
    },
    {
      key: 'notifications',
      'data-key': 'nav-notifications',
      name: t('account.notifications'),
      onClick: () => this.props.goTo(ROUTES.accountNotifications),
    },
    {
      key: 'password',
      'data-key': 'nav-password',
      name: t('account.password'),
      onClick: () => this.props.goTo(ROUTES.accountPassword),
    },
    {
      key: 'help',
      'data-key': 'nav-help',
      name: t('account.help'),
      onClick: () => goToAccountHelp(),
    },
    {
      key: 'logout',
      'data-key': 'nav-logout',
      name: t('titles.logout'),
      onClick: () => this.props.logout(),
    },
  ];

  get sectionsFiltered() {
    return this.mapToPopover(this.props.mainSections, this.sections);
  }

  handleClick = section => {
    const { currentPage } = this.props;

    if (currentPage === section.id) {
      return;
    }

    switch (section.id) {
      case 'properties':
        // TODO: Ensure we are landing on the first property
        this.props.goTo(section.path);
        break;
      case 'reports':
      case 'settings':
      case 'contacts':
      case 'calendar':
      case 'todos':
      case 'library':
        this.props.goTo(section.path);
        break;
      default:
    }
  };

  popoverContent = closePopover => (
    <ButtonList
      closePopover={closePopover}
      hasDivider
      isDarkBackground
      items={filter(this.profileNavigationList(), item => this.props.popoverSections.includes(item.key))}
    />
  );

  mapToPopover(array, obj) {
    return map(array, item => obj[item]);
  }

  renderLogo() {
    const { logoApp: LogoApp } = this.props.generalConfig;
    if (!LogoApp) {
      return null;
    }
    return typeof LogoApp !== 'string' ? (
      <LogoApp width="26px" height="33px" byWidth />
    ) : (
      LogoApp && <Icon width="26px" height="33px" byWidth src={LogoApp} />
    );
  }

  render() {
    return (
      <div className={cx('nav')}>
        <div className={cx('nav__icon')}>
          <Pony>{this.renderLogo()}</Pony>
        </div>
        <div className={cx('nav__sections')}>
          {map(this.sectionsFiltered, section => (
            <div key={`navitem-${section.id}`} className={cx('nav__sections-item')}>
              <NavSection active={this.props.currentPage === section.id} onClick={this.handleClick} section={section} />
              {this.props.hasConnectionIssue && section.showConnectionIssue && (
                <div className={cx('nav__sections-warning')}>
                  <Icon.IcError1 byWidth />
                </div>
              )}
            </div>
          ))}
        </div>
        <div className={cx('nav__user')} id="user-profile-id">
          <PopoverDefault
            hasArrow
            hasDarkBackground
            width={276}
            arrowPosition={{
              vertical: 'left',
            }}
            popoverContent={PopoverStates => this.popoverContent(PopoverStates.closePopup)}
          >
            <button type="button" data-key="user-profile-image-btn" className={cx('nav__user-btn')}>
              <ProfilePicture
                type="square-small"
                picture={this.props.pictureUrl}
                text={getInitials(this.props.firstName, this.props.lastName)}
              />
            </button>
          </PopoverDefault>
        </div>
      </div>
    );
  }
}

NavContainer.ClickItem = ({ children, onClick, side, ...rest }) => (
  <li
    onClick={onClick}
    className={cx('nav__li', 'nav__li--item', {
      [`nav__li--item--${side}`]: side,
    })}
    {...rest}
  >
    <div>{children}</div>
  </li>
);

function mapStateToProps(state) {
  return {
    firstName: lodashResult(state.currentUser, ['user', 'firstName']),
    lastName: lodashResult(state.currentUser, ['user', 'lastName']),
    companyName: lodashResult(state.currentUser, ['user', 'companyName']),
    pictureUrl: lodashResult(state.currentUser, ['user', 'pictureUrl']),
    hasConnectionIssue: selectHasConnectionIssue(state),
    generalConfig: selectGeneralConfig(state),
  };
}

export default connect(mapStateToProps, {
  goTo,
  logout,
})(NavContainer);
