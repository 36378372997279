import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import t from '@properly/localization';
import {
  displayCityStateZip,
  isSharedProperty,
  isSharingProperty,
  isCompleteAddress,
  displayStreetWithApt,
  isSanctionedProperty,
} from '@properly/common';
import { featureFlag } from '@properly/config';
import { SearchField, Icon, PropertyDetailIcon } from '@properly/components';
import { List, AutoSizer } from 'react-virtualized';
import { compose, onlyUpdateForKeys } from 'recompose';
import { connect } from 'react-redux';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import TopLabel from '../../../../components/TopLabel/index';
import { MasterDetailCell } from '../../../../components/MasterDetail/index';
import { filterPropertyList, sortProperties, rowRenderMapper } from '../../../../helper/herbert';
import Sidebar from '../../../../components/Sidebar/index';
import HasPermission from '../../../../hoc/HasPermission/index';
import { goNewProperty, goToProperty } from '../state/PropertyActions';
import * as globalActions from '../../../../actions/globalActions';
import { selectIsFeatureFlagEnabledForUser } from '../../settings/state/SettingsSelectors';

class PropertySidebarContainer extends PureComponent {
  renderCells(cells, activeId, goTo, isSearch, user) {
    const goToPropertyBound = id => () => goTo(id);
    if (isSearch && cells.length === 0) {
      return (
        <Sidebar.List>
          <Sidebar.NoRes>{t('properties.no_result')}</Sidebar.NoRes>
        </Sidebar.List>
      );
    }
    return (
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            rowHeight={68}
            rowCount={cells.length}
            rowRenderer={rowRenderMapper({
              data: cells,
              handler: this.renderRow.bind(this),
              ctx: { goToPropertyBound, user, activeId, collData: this.props.collData },
            })}
            width={width}
          />
        )}
      </AutoSizer>
    );
  }

  renderRow({ key, style, row: property, ctx: { activeId, user, goToPropertyBound, collData } }) {
    const isActive = property.objectId === activeId;
    const incompleteAddress = !isCompleteAddress(property.address, property);
    const sharedProperty = isSharedProperty(property.ownerRole, user);
    const sharingProperty = isSharingProperty(property.objectId, collData);
    const sanctionedProperty = isSanctionedProperty(property);
    const { isMarketPlaceFeatureFlagEnabled } = this.props;
    const propertyDetailIcon = (
      <PropertyDetailIcon
        collaboratorData={this.props.collData}
        id={property.objectId}
        propertyOwnerName={property.ownerName}
        isSharingProperty={sharingProperty}
        isSharedProperty={sharedProperty}
        isSanctionedProperty={sanctionedProperty}
        incompleteAddress={incompleteAddress}
      />
    );
    const hasIcon = !!(incompleteAddress || sharedProperty || sharingProperty);

    const pretitle =
      (property.title !== property.address.street && displayStreetWithApt(property.address)) || undefined;

    return (
      <MasterDetailCell
        data-key="propertysidebar"
        type="property"
        noHover
        key={key}
        style={style}
        onClick={goToPropertyBound(property.objectId)}
        active={isActive}
        title={property.title}
        pretitle={pretitle}
        subTitle={displayCityStateZip(property.address)}
        picRight={hasIcon}
        picture={hasIcon}
        svg={propertyDetailIcon}
        proMarketProperty={isMarketPlaceFeatureFlagEnabled && property.proMarketProperty}
      />
    );
  }

  get propertiesFiltered() {
    return filterPropertyList(this.props.properties);
  }

  get needsSearchBar() {
    return this.propertiesFiltered.length > 1 || !!this.props.searchQuery;
  }

  render() {
    const filteredSearchOrRaw = this.props.searchQuery
      ? this.propertiesFiltered
      : sortProperties(this.propertiesFiltered);

    return (
      <Sidebar>
        <HasPermission
          hasAccessFormatter={hasAccessRes => hasAccessRes.canCreateProperties}
          render={hasAccess => (
            <TopLabel
              isEmpty={!hasAccess}
              disabled={!hasAccess}
              data-key="button_new_property_sidebar"
              label={t('properties.new_properties')}
              onClick={this.props.goNewProperty}
              icon={Icon.IcPlus}
            />
          )}
        />
        <Sidebar.Hr />
        {this.needsSearchBar && (
          <SearchField
            blank
            placeholder={t('properties.search_for_address', {
              n: filteredSearchOrRaw.length,
            })}
            value={this.props.searchQuery}
            iconLocation="left"
            onChange={value => this.props.setSearchQuery('properties', value)}
          />
        )}
        {this.needsSearchBar && <Sidebar.Hr />}
        <div style={{ flex: 1 }}>
          {this.renderCells(
            filteredSearchOrRaw,
            this.props.activeId,
            this.props.goToFunc,
            !!this.props.searchQuery,
            this.props.currentUser,
          )}
        </div>
      </Sidebar>
    );
  }
}

function mapStateToProps() {
  const memo = selectorsGlobal.selectSearchData('properties', 'properties', selectorsGlobal.selectProperties);
  const currentUser = selectorsGlobal.selectCurrentUser();
  const collData = selectorsGlobal.selectPropertyCollaborators();

  return (state, props) => {
    const isMarketPlaceFeatureFlagEnabled = selectIsFeatureFlagEnabledForUser(featureFlag.FEATURE_FLAG_MARKETPLACE)(
      state,
      props,
    );
    const { result, searchQuery } = memo(state);
    return {
      searchQuery,
      properties: result,
      currentUser: currentUser(state, props),
      collData: collData(state, props),
      isMarketPlaceFeatureFlagEnabled,
    };
  };
}

PropertySidebarContainer.propTypes = {
  activeId: PropTypes.string,
  routeName: PropTypes.string,
  goToFunc: PropTypes.func.isRequired,
};

export default compose(
  connect(mapStateToProps, {
    goNewProperty,
    setSearchQuery: globalActions.setSearchQuery,
    goToProperty,
    goTo: globalActions.goTo,
  }),
  onlyUpdateForKeys(['activeId', 'collData', 'currentUser', 'properties', 'searchQuery']),
)(PropertySidebarContainer);
