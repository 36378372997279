import React, { Fragment } from 'react';
import map from 'lodash/map';
import t from '@properly/localization';
import { pictureSizes, genImageBg } from '@properly/common';
import { Icon, SpaceSmall, SpaceTiny, Small, Micro } from '@properly/components';
import moment from 'moment-timezone';
import classNames from 'classnames/bind';
import styles from './jobDetailsStyles.module.css';

const cx = classNames.bind(styles);

export const getSeverityTextLevel = severity => {
  if (severity > 3) {
    return t('jobdetails.severity_high');
  }
  if (severity === 3) {
    return t('jobdetails.severity_medium');
  }
  if (severity > 0 && severity < 3) {
    return t('jobdetails.severity_low');
  }
  return '';
};

const JobDetailsProblemList = ({ problems, timezone, openProblemPics }) => {
  const res = map(problems, (problem, key) => {
    const isLast = key === problems.length - 1;
    const { note, stepTitle, severity, date, pictureIdentifiers, checklistTitle } = problem || {};

    const cleanedNote = (note && note.trim()) || '';

    const hasImage = pictureIdentifiers && pictureIdentifiers.length > 0;
    const images = hasImage ? pictureIdentifiers.map(pictureIdentifier => pictureIdentifier) : [];

    const time = moment(date)
      .tz(timezone)
      .format('MMM D, YYYY # LT')
      .replace('#', t('jobdetails.at'));

    const hasSeverity = !!severity;
    const severityLevel = getSeverityTextLevel(severity);
    const severityText = severity && `${t('jobdetails.severity')} ${severity} (${severityLevel})`;
    const severityColor = severity === 5 ? '#FF3230' : '#6B757F';

    return (
      <div
        key={key}
        className={cx('jobdetails__box', 'jobdetails__box--problems', {
          'jobdetails__box--last': isLast,
        })}
      >
        {checklistTitle && (
          <div className={cx('jobdetails__box-title', 'jobdetails__box-title--nomargin')}>
            <Small type="regular">{checklistTitle}</Small>
          </div>
        )}
        <SpaceSmall />
        {hasImage &&
          images.map((image, index) => (
            <Fragment key={image}>
              <div
                onClick={openProblemPics(problems, image)}
                className={cx('jobdetails__box-image')}
                style={genImageBg(image, pictureSizes.PROPERTY_PICTURE)}
              />
              {index < images.length - 1 && <SpaceTiny />}
            </Fragment>
          ))}
        {cleanedNote && (
          <div
            className={cx('jobdetails__box-title', {
              'jobdetails__box-title--nomargin': !hasImage,
            })}
          >
            <Small>{cleanedNote}</Small>
          </div>
        )}

        <SpaceTiny />

        {hasSeverity && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '4px' }}>
              <Icon.IcWarningProblems color={severityColor} width="1em" />
            </div>
            {severityText && <Micro color={severityColor}>{severityText}</Micro>}
          </div>
        )}

        {stepTitle && (
          <div className={cx('jobdetails__box-title', 'jobdetails__box-title--nomargin')}>
            <Micro type="grey">{stepTitle}</Micro>
          </div>
        )}

        <div>
          <Micro type="grey">{time}</Micro>
        </div>
      </div>
    );
  });
  return <div>{res}</div>;
};

export default JobDetailsProblemList;
