"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.logPerformance = void 0;

var _loglevel = _interopRequireDefault(require("loglevel"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function precisionRound(number, precision) {
  const factor = 10 ** precision;
  return Math.round(number * factor) / factor;
}

const logPerformance = (name, fn) => (...params) => {
  let before;
  let after;

  try {
    before = performance.now();
  } catch (err) {
    before = Date.now();
  }

  const results = fn(...params);

  try {
    after = performance.now();
  } catch (err) {
    after = Date.now();
  }

  const perf = precisionRound(after - before, 1);

  if (perf > 32) {
    _loglevel.default.warn(`${name} performance ${perf}ms`);
  } else {
    _loglevel.default.info(`${name} performance ${perf}ms`);
  }

  return results;
};

exports.logPerformance = logPerformance;