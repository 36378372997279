import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import log from 'loglevel';
import { setTrackingCookie } from '@properly/common';
import classNames from 'classnames/bind';
import styles from './index.module.css';
import PageLoad from '../PageLoad';
import ImageSlider from '../ImageSlider';
import Config from '../Config';
import Environment from '../Environment';

// load for html template
import '../../images/apple-ninja152-precomposed.png';
import '../../images/chrome-ninja192-precomposed.png';
import '../../images/ms-ninja144-precomposed.png';

const cx = classNames.bind(styles);

class App extends React.Component {
  componentDidMount() {
    setTrackingCookie(this.props.location.query);

    document.addEventListener('keydown', this.debugListener, { passive: true });

    const ele = document.querySelector('#scrolladjusttest');
    if (ele && ele.clientWidth !== 80) {
      document.documentElement.className += ' has-scrollbars';
    }
  }

  componentWillUnmount() {
    this.removeKeyDownListener();
  }

  listenForTouch = () => {
    window.addEventListener(
      'touchstart',
      function onFirstTouch() {
        document.body.classList.add('user-is-touching');
        window.USER_IS_TOUCHING = true;
        window.removeEventListener('touchstart', onFirstTouch, false);
      },
      false,
    );
  };

  removeKeyDownListener = () => {
    document.removeEventListener('keydown', this.debugListener, {
      passive: true,
    });
  };

  componentDidCatch(error, info) {
    const { push: pushDispatch } = this.props;

    log.error('Root App Component componentDidCatch');
    log.error(error);
    log.error(info);

    // Only redirect if we are NOT in a local dev environment
    if (window.location.host.indexOf('localhost') === -1) {
      pushDispatch('/error');
    }
  }

  render() {
    const { children } = this.props;
    return (
      <div>
        {children}
        <div className={cx('scroll')}>
          <div id="scrolladjusttest" className={cx('scroll__inner')} />
        </div>
        <Environment />
        <PageLoad />
        <ImageSlider />
        <Config />
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.node,
};

export default connect(
  null,
  { push },
)(App);
