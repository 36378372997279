import { put, fork, select, call, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import log from 'loglevel';
import { error, getConfigSync } from '@properly/config';
import { detectLanguage } from '@properly/localization';
import {
  getUtmParamsFromCookie,
  updateIntercom,
  restartIntercom,
  isMobileUser,
  isNoTouchDevice,
  getCountryCode,
} from '@properly/common';
import lodashResult from 'lodash/result';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import { reset } from 'redux-form';
import queryString from 'query-string';
import * as types from '../../types';
import { ROUTES } from '../../paths';
import { selectCurrentUser } from '../../selectors/globalSelector';
import { loginSuccess, loginError, setLoginLoading, hostSignUpError, hostSignUpSuccess } from './LoginSignUpActions';
import { approveRedirect } from '../../helper/herbert';
import { trackLoginComplete, trackSignupComplete } from '../../actions/trackingEvents';
import { becomeUser, awsLogin, awsSignup, setReferrerFromWebapp } from '../desktopApp/data';
import { guardedFork } from '../../helper/saga';
import { refetchUser } from '../../sagas/global';
import {
  selectAllowedConnections,
  selectIsPartnerDomain,
  selectLandingPage,
  selectHideConnectAccount,
} from '../branding/BrandingSelector';
import { getCustomBrandingConfigSaga, redirectToDownloadAppPageSaga } from '../branding/sagas';

function* becomeUserSaga(action) {
  try {
    const user = yield call(becomeUser, action.token);
    yield put(loginSuccess(user));
    yield loginGateSaga({ fromWhere: 'login' });
  } catch (e) {
    log.error('becomeUserSaga', e);
  }
}

function* loginGateSaga({ fromWhere, query, redirectRoute }) {
  try {
    const routing = yield select(state => state.routing);
    log.info('loginGateSaga', fromWhere, query, routing);
    yield refetchUser();

    const queryOfPrevRoute = lodashResult(routing, 'locationBeforeTransitions.query');

    // signup
    if (fromWhere === 'signup') {
      const currentUser = yield select(selectCurrentUser());
      log.info({ currentUser });

      if (!currentUser.isStandalone) {
        yield put(push({ pathname: ROUTES.landingPage, state: { fromEntry: true } }));
        return;
      }

      let hasPrefilledOAuth = false;
      let outhKeys = {};
      if (queryOfPrevRoute && queryOfPrevRoute.redirectUrl) {
        const queryParams = queryString.extract(queryOfPrevRoute.redirectUrl);
        if (queryParams) {
          const parsedParams = queryString.parse(queryParams);
          outhKeys = pick(parsedParams, ['code', 'oAuth']);
          hasPrefilledOAuth = keys(outhKeys).length > 0;
        }
      }
      log.info('loginGateSaga - oauthKeys', outhKeys);

      const allowedConnections = yield select(selectAllowedConnections);
      const hideConnectAccount = yield select(selectHideConnectAccount);
      const isPartnerDomain = yield select(selectIsPartnerDomain());

      if (isPartnerDomain && hideConnectAccount) {
        yield call(redirectToDownloadAppPageSaga);
        return;
      }

      if (!hideConnectAccount) {
        yield put(
          push({
            pathname: ROUTES.importAccounts,
            // URL query may contain oAuth parameters
            query: hasPrefilledOAuth ? outhKeys : undefined,
            state: { fromEntry: true, allowedConnections },
          }),
        );
        return;
      }
    }

    // redirect after login
    let redirectUrl = (query && query.redirectUrl) || redirectRoute;
    if (redirectUrl && (fromWhere === 'login' || fromWhere === 'loginWithToken')) {
      let search;
      try {
        const queryParams = queryString.extract(redirectUrl);
        if (queryParams) {
          search = `?${queryParams}`;
          redirectUrl = redirectUrl.replace(search, '');
        }
      } catch (err) {
        log.error(err);
      }
      log.info('loginGateSaga - login with redirecturl', redirectUrl, search);
      yield put(push({ pathname: redirectUrl, search, state: { fromEntry: true } }));
      return;
    }

    // redirect mobile users
    if (isMobileUser() && !isNoTouchDevice()) {
      yield put(push({ pathname: ROUTES.mobileUpgrade, state: { fromEntry: true } }));
      return;
    }

    yield put(push({ pathname: ROUTES.landingPage, state: { fromEntry: true } }));
  } catch (e) {
    log.error('loginGateSaga', e);
    yield put(push(approveRedirect({ pathname: ROUTES.error })));
  }
}

function* redirectToLandingPageSaga() {
  const goWhere = ROUTES.landingPage;
  yield put(push({ pathname: goWhere, state: { fromEntry: true } }));
}

function* setReferrer() {
  try {
    yield call(setReferrerFromWebapp, getUtmParamsFromCookie());
  } catch (e) {
    // silent
  }
}

function* signUpSaga({ data }) {
  try {
    const config = getConfigSync();
    const isPartnerDomain = yield select(selectIsPartnerDomain());
    const utm = getUtmParamsFromCookie();
    let domain;
    let defaultPhoneCountryCode;
    if (isPartnerDomain) {
      const hostName = window.location.hostname;
      const appWebUrl = String(config.APP_WEB_LINK).replace('//', '');
      domain = !hostName.includes(appWebUrl) ? hostName : null;
      defaultPhoneCountryCode = yield call(getCountryCode);
    }
    // do not send recaptcha token for e2e tests, so we don't fail verification
    if (window.PROPERLY_CONFIG.env.isE2E && data.reCaptchaToken) {
      data.reCaptchaToken = undefined;
      data.reCaptchaVersion = undefined;
    }

    const utmSource = utm.source === 'direct' ? null : utm.source;
    const language = detectLanguage();
    yield put(setLoginLoading(true));

    const user = yield call(
      awsSignup,
      data.firstName?.trim(),
      data.lastName?.trim(),
      data.phoneRegionalNumber,
      data.phoneCountryCode || defaultPhoneCountryCode,
      data.email?.trim(),
      data.password,
      data.reCaptchaToken,
      data.reCaptchaVersion,
      language,
      utm.campaign,
      utm.referrer,
      utm.content,
      utm.medium,
      utm.source,
      utm.term,
      domain || utmSource,
    );
    updateIntercom(user.objectId);
    trackSignupComplete();
    yield fork(setReferrer);
    yield put(hostSignUpSuccess(user));
    yield loginGateSaga({ fromWhere: 'signup' });
    yield put(reset('signup'));
  } catch (err) {
    yield put(hostSignUpError([err.properlyErrorMsg]));
  }
}

function* loginSaga({ data, query }) {
  log.info('loginSaga', data, query);
  try {
    const errors = [];

    if (data.username.length < 1) {
      errors.push(error.INVALID_USERNAME);
    }
    if (data.password.length < 1) {
      errors.push(error.PASSWORD_MISSING);
    }

    if (errors.length > 0) {
      yield put(loginError(errors));
    } else {
      yield put(setLoginLoading(true));
      const user = yield call(awsLogin, data.username, data.password);
      yield put(loginSuccess(user));
      restartIntercom(user.objectId);
      trackLoginComplete();
      yield loginGateSaga({ fromWhere: 'login', query });

      const { customBrandingId } = user;
      if (customBrandingId) {
        yield call(getCustomBrandingConfigSaga, customBrandingId);
        const onBoardingLandingPage = yield select(selectLandingPage());
        const redirectTo = onBoardingLandingPage || ROUTES.landingPage;
        yield put(push({ pathname: redirectTo, state: { fromEntry: true } }));
      }
    }
  } catch (err) {
    yield put(loginError([err.properlyErrorMsg]));
  }
}

function* saga() {
  yield guardedFork(takeEvery, types.LOGIN_BECOME_USER, becomeUserSaga);
  yield guardedFork(takeEvery, types.LOGIN_SAGA, loginSaga);
  yield guardedFork(takeEvery, types.LOGIN_REDIRECT_TO_LANDING_PAGE_SAGA, redirectToLandingPageSaga);
  yield guardedFork(takeEvery, types.SIGNUP_SAGA, signUpSaga);
  yield guardedFork(takeEvery, types.LOGIN_GATE_SAGA, loginGateSaga);
}

export default saga;
