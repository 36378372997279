import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import { combineReducers } from 'redux';
import currentUser from './currentUser';
import globalReducer from './globalReducer';
import moreReducer from './moreReducer';
import PropertyReducer from '../modules/desktopApp/property/state/PropertyReducer';
import ChecklistReducer from '../modules/desktopApp/checklists/state/ChecklistReducer';
import JobRequestReducer from '../modules/desktopApp/jobRequest/state/JobRequestReducer';
import ContactReducer from '../modules/desktopApp/contacts/state/ContactReducer';
import AccountReducer from '../modules/desktopApp/account/state/AccountReducer';
import PricingReducer from '../modules/desktopApp/pricing/PricingReducer';
import LibraryReducer from '../modules/desktopApp/library/state/library.reducer';
import TodosReducer from '../modules/desktopApp/todos/TodosReducer';
import CalendarReducer from '../modules/desktopApp/calendar/state/CalendarReducer';
import JobDetailsReducer from '../modules/desktopApp/jobDetails/state/JobDetailsReducer';
import ReportReducer from '../modules/desktopApp/reports/ReportsReducer';
import paymentsInterceptReducer from '../containers/PaymentInterceptWizard/paymentInterceptReducer';
import PermissionReducer from '../modules/desktopApp/permissions/PermissionReducer';
import loginSignUpReducers from '../modules/loginSignUp/LoginSignUpReducers';
import BrandingReducer from '../modules/branding/BrandingReducer';
import SettingsReducer from '../modules/desktopApp/settings/state/SettingsReducer';

const rootReducer = asyncReducers =>
  combineReducers({
    currentUser,
    routing,
    branding: BrandingReducer,
    globalReducer,
    moreReducer,
    reports: ReportReducer,
    jobDetails: JobDetailsReducer,
    calendar: CalendarReducer,
    checklists: ChecklistReducer,
    account: AccountReducer,
    todos: TodosReducer,
    jobRequestSend: JobRequestReducer,
    pricing: PricingReducer,
    loginSignUpReducers,
    permission: PermissionReducer,
    properties: PropertyReducer,
    library: LibraryReducer,
    contacts: ContactReducer,
    stripe: paymentsInterceptReducer,
    form: formReducer,
    settings: SettingsReducer,
    ...asyncReducers,
  });

export default rootReducer;
