export const ROLE = {
  host: 1,
  cleaner: 2,
};

export const PAID_FEATURES = {
  pms: 'proPartners',
  ical: 'iCal',
  reports: 'reports',
  webapp: 'webapp',
  skillsLibrary: 'skillsLibrary',
  fullPartnerMode: 'fullPartnerMode',
  companyModule: 'companyModule',
};

export const RELOAD_STATUS = {
  LOADED: 'loaded',
  NOTLOADED: 'notloaded',
};

export const sendJobRequestModes = {
  edit: 'edit',
  sendmore: 'sendmore',
  normal: 'normal',
  prefill: 'prefill',
  problemSchedule: 'problemSchedule',
};

export const modals = {
  LOADING_MODAL: 'LOADING_MODAL',
  CONTACT_DELETE_MODAL: 'CONTACT_DELETE_MODAL',
  EDIT_PROPERTY_ERROR_MODAL: 'EDIT_PROPERTY_ERROR_MODAL',
  CREATE_PROPERTY_ERROR_MODAL: 'CREATE_PROPERTY_ERROR_MODAL',
  CANNOT_SEND_PROPERTY_JOBS_PERMISSION: 'CANNOT_SEND_PROPERTY_JOBS_PERMISSION',
};

export const mediaUploadLoadingKey = 'mediaUpload';
