import { fromJS } from 'immutable';

export const recurlyFields = {
  all: {
    style: {
      fontFamily: 'Arial',
      fontSize: '14px',
      fontColor: '#29333D',
      fontWeight: 'normal',
      letterSpacing: '0.2',
      lineHeight: '100%',
    },
  },
  number: {
    selector: '[data-key="number"]',
    style: {
      placeholder: {
        content: '',
      },
    },
  },
  month: {
    selector: '[data-key="month"]',
    style: {
      placeholder: {
        content: 'MM',
      },
    },
  },
  year: {
    selector: '[data-key="year"]',
    style: {
      placeholder: {
        content: 'YYYY',
      },
    },
  },
  cvv: {
    selector: '[data-key="cvv"]',
  },
};

export const formData = fromJS({
  planId: undefined,
  firstname: '',
  lastname: '',
  email: '',
  company: '',
  billingaddress: '',
  country: '',
  address2: '',
  coupon: '',
  taxnumber: '',
  city: '',
  state: '',
  zip: '',
  tos: false,

  // super stuff
  cvv: false,
  year: false,
  month: false,
  number: false,
});

export const requiredFields = {
  firstname: true,
  lastname: true,
  email: true,
  billingaddress: true,
  country: true,
  city: true,
  zip: true,
  tos: true,
  cvv: true,
  year: true,
  month: true,
  number: true,
  planId: true,
};
