import reduce from 'lodash/reduce';
import each from 'lodash/each';
import mapValues from 'lodash/mapValues';
import { isValidEmail } from '@properly/common';
import { EmailSection, PermissionSection, PropertySection, PropSection, TypeSection } from './PermissionModalElements';

export const initLoadingKey = 'permission_init_loading';
export const teamLoadingKey = 'permission_team_loading';
export const updateActionLoadingKey = 'permission_updateaction_loading';
export const modalLoadingKey = 'permission_modal_loading';
export const modalLoadingSubmitKey = 'permission_modalsubmit_loading';
export const modalLoadingNextKey = 'permission_modalnext_loading';

export const collSearchKey = 'collinvite_search';

export const modalModes = {
  invite: 'invite',
  edit_partner: 'edit_partner',
  property: 'property',
  resend: 'resend',
  invite_company: 'invite_company',
  invite_partner: 'invite_partner',
  edit_company: 'edit_company',
  resend_company: 'resend_company',
  resend_partner: 'resend_partner',
};

export const modalSectionsNoId = {
  email: {
    sectionTitle: 'permissions.modal.email',
    isEmpty: sectionValue => !sectionValue.get('email'),
    isValid: sectionValue => isValidEmail(sectionValue.get('email')),
    mapToData: submitData => submitData.raw.get('email'),
    content: EmailSection,
  },
  email_company: {
    sectionTitle: 'permissions.modal.email',
    isEmpty: sectionValue => !sectionValue.get('email'),
    isValid: sectionValue => sectionValue.get('email') && sectionValue.get('email').length > 1,
    mapToData: submitData => submitData.raw.get('email'),
    content: EmailSection,
  },
  type: {
    sectionTitle: 'permissions.modal.type_selection',
    isEmpty: sectionValue => !sectionValue.get('type'),
    isValid: sectionValue => !!sectionValue.get('type'),
    mapToData: submitData => submitData.raw.get('type'),
    content: TypeSection,
  },
  seeper: {
    sectionTitle: '',
    topBarTitle: 'permissions.modal.property',
    neverActive: true,
    sectionMods: ['viewonly'],
    isEmpty: () => false,
    isValid: () => true,
    mapToData: submitData => submitData.raw.getIn(['view']),
    content: PropSection,
  },
  collType: {
    sectionTitle: 'permissions.modal.coll_type',
    isEmpty: sectionValue => !sectionValue.get('type'),
    isValid: sectionValue => !!sectionValue.get('type'),
    mapToData: submitData => submitData.raw.get('collType'),
    content: () => null,
  },
  permissions: {
    sectionTitle: 'permissions.modal.permissions',
    isEmpty: sectionValue => !sectionValue.get('type'),
    isValid: sectionValue => {
      const option = sectionValue.get('type');
      const optionSelected = !!option;
      if (optionSelected && option === 'option1') return true;
      const permissions = sectionValue.get('permissions')?.toJS();
      const trueKeyCount = Object.values(permissions).filter(isSelected => !!isSelected).length;
      if (optionSelected && trueKeyCount > 0) return true;
      return false;
    },
    mapToData: submitData => submitData.raw.get('permissions'),
    content: PermissionSection,
  },
  property: {
    sectionTitle: 'permissions.modal.property',
    isEmpty: sectionValue => !sectionValue.get('type'),
    isValid: sectionValue => {
      const type = sectionValue.get('type');
      if (!type) return false;
      if (type === 'option2' && sectionValue.get('properties').size === 0) {
        return false;
      }
      return true;
    },
    mapToData: submitData => submitData.raw.get('properties'),
    content: PropertySection,
  },
};

export const modalSections = mapValues(modalSectionsNoId, (item, key) => ({
  ...item,
  sectionId: key,
}));

export const permissionMap = [
  {
    groupName: 'permissions.modal.permissions_select.see_permission',
    groupdId: 'see_permission',
    children: [
      {
        permissionName: 'permissions.modal.permissions_select.see_servicepro',
        permissionId: 'seeCleanerNames',
      },
      {
        permissionName: 'permissions.modal.permissions_select.see_offeredprice',
        permissionId: 'seePrice',
      },
      {
        permissionName: 'permissions.modal.permissions_select.see_problem',
        permissionId: 'seeProblems',
      },
      {
        permissionName: 'permissions.modal.permissions_select.see_problem_pics',
        permissionId: 'seeVerificationPhotos',
      },
    ],
  },
  {
    groupName: 'permissions.modal.permissions_select.edit_permissions',
    groupId: 'edit_permissions',
    children: [
      {
        permissionName: 'permissions.modal.permissions_select.edit_checklist',
        permissionId: 'createChecklists',
      },
    ],
  },
  {
    groupName: 'permissions.modal.permissions_select.send_permission',
    groupId: 'send_permission',
    children: [
      {
        permissionName: 'permissions.modal.permissions_select.send_job',
        permissionId: 'sendJobs',
      },
      {
        permissionName: 'permissions.modal.permissions_select.start_job',
        permissionId: 'doJob',
      },
    ],
  },
];

export const permissionMapTrueKey = reduce(
  permissionMap,
  (acc, item) => {
    each(item.children, child => {
      acc[child.permissionId] = true; // eslint-disable-line
    });
    return acc;
  },
  {},
);
