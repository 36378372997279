"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _convict = _interopRequireDefault(require("@kauabunga/convict"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var validateString = function validateString(val) {
  if (typeof val !== 'string') {
    throw new Error(val);
  }
};

var validateBoolean = function validateBoolean(val) {
  if (typeof val !== 'boolean') {
    throw new Error(val);
  }
};

var getConfigItem = function getConfigItem(name) {
  return {
    format: validateString,
    "default": process.env[name] || null
  };
};

var getConfigItemBoolean = function getConfigItemBoolean(name) {
  return {
    format: validateBoolean,
    "default": process.env[name] || false
  };
}; // TODO: make grouping in schema wherever possible


var schema = (0, _convict["default"])({
  ENVIRONMENT: {
    format: ['beta', 'develop', 'local', 'everest', 'sandbox', 'production', 'staging'],
    "default": 'production'
  },
  env: {
    env: {
      format: String,
      "default": ''
    },
    path: {
      format: String,
      "default": ''
    },
    subDomain: {
      format: Array,
      "default": []
    }
  },
  GOOGLE_ANALYTICS_TRACKING_ID: getConfigItem('GOOGLE_ANALYTICS_TRACKING_ID'),
  GOOGLE_ADWORDS_TRACKING_ID: getConfigItem('GOOGLE_ADWORDS_TRACKING_ID'),
  GOOGLE_ADWORDS_SIGNUP_CONVERSION_ID: getConfigItem('GOOGLE_ADWORDS_SIGNUP_CONVERSION_ID'),
  GOOGLE_ADWORDS_SUBSCRIPTION_CONVERSION_ID: getConfigItem('GOOGLE_ADWORDS_SUBSCRIPTION_CONVERSION_ID'),
  GOOGLE_MAPS_KEY: getConfigItem('GOOGLE_MAPS_KEY'),
  GOOGLE_TIMEZONE_KEY: getConfigItem('GOOGLE_TIMEZONE_KEY'),
  GOOGLE_RECAPTCHA_V3_URL: getConfigItem('GOOGLE_RECAPTCHA_V3_URL'),
  GOOGLE_RECAPTCHA_V3_SITE_KEY: getConfigItem('GOOGLE_RECAPTCHA_V3_SITE_KEY'),
  GOOGLE_RECAPTCHA_V2_SITE_KEY: getConfigItem('GOOGLE_RECAPTCHA_V2_SITE_KEY'),
  APP_ID: getConfigItem('APP_ID'),
  JS_KEY: getConfigItem('JS_KEY'),
  API_BASE_URL: getConfigItem('API_BASE_URL'),
  API_GRAPHQL_BASE_URL: getConfigItem('API_GRAPHQL_BASE_URL'),
  API_GRAPHQL_BASE_SUBSCRIPTION_URL: getConfigItem('API_GRAPHQL_BASE_SUBSCRIPTION_URL'),
  PARSE_SERVER_URL: getConfigItem('PARSE_SERVER_URL'),
  PARSE_SERVER_ADMIN: getConfigItem('PARSE_SERVER_ADMIN'),
  PARSE_SERVER_READ_URL: getConfigItem('PARSE_SERVER_READ_URL'),
  PARSE_SERVER_LIVE_URL: getConfigItem('PARSE_SERVER_LIVE_URL'),
  PARSE_SERVER_LIVE_QUERY_URL: getConfigItem('PARSE_SERVER_LIVE_QUERY_URL'),
  LOGIN_URL: getConfigItem('LOGIN_URL'),
  SIGNUP_URL: getConfigItem('SIGNUP_URL'),
  GET_PROPERLY_LINK: getConfigItem('GET_PROPERLY_LINK'),
  GET_PROPERLY_HELP_LINK: getConfigItem('GET_PROPERLY_HELP_LINK'),
  TOS_LINK: getConfigItem('TOS_LINK'),
  APP_WEB_LINK: getConfigItem('APP_WEB_LINK'),
  APP_ADMIN_LINK: getConfigItem('APP_ADMIN_LINK'),
  APP_DASHBOARD_LINK: getConfigItem('APP_DASHBOARD_LINK'),
  PROPERLY_DEEP_LINK_IOS_ANDROID_WEB: getConfigItem('PROPERLY_DEEP_LINK_IOS_ANDROID_WEB'),
  PROPERLY_DEEP_LINK_IOS_FORCE_OPEN_APP: getConfigItem('PROPERLY_DEEP_LINK_IOS_FORCE_OPEN_APP'),
  APP_ANDROID_LINK: getConfigItem('APP_ANDROID_LINK'),
  APP_IOS_LINK: getConfigItem('APP_IOS_LINK'),
  RECURLY_PUBLIC_KEY: getConfigItem('RECURLY_PUBLIC_KEY'),
  RECURLY_SDK_URL: getConfigItem('RECURLY_SDK_URL'),
  STRIPE_API_KEY: getConfigItem('STRIPE_API_KEY'),
  STRIPE_LIBRARY_URL: getConfigItem('STRIPE_LIBRARY_URL'),
  DESKTOP: {
    INTERCOM_APP_ID: getConfigItem('DESKTOP__INTERCOM_APP_ID'),
    SENTRY_CONFIG: getConfigItem('DESKTOP__SENTRY_CONFIG'),
    SENTRY_FORCE_IN_DEVELOPMENT: getConfigItemBoolean('DESKTOP__SENTRY_FORCE_IN_DEVELOPMENT')
  },
  INVITE: {
    SENTRY_CONFIG: getConfigItem('INVITE__SENTRY_CONFIG'),
    SENTRY_FORCE_IN_DEVELOPMENT: getConfigItemBoolean('INVITE__SENTRY_FORCE_IN_DEVELOPMENT')
  },
  SIGNUP: {
    SENTRY_CONFIG: getConfigItem('SIGNUP__SENTRY_CONFIG'),
    SENTRY_FORCE_IN_DEVELOPMENT: getConfigItemBoolean('SIGNUP__SENTRY_FORCE_IN_DEVELOPMENT')
  },
  BRANDING_CONFIG_API: getConfigItem('BRANDING_CONFIG_API'),
  BRANDING_PILOT_SIGNUP_NEW_USER: getConfigItem('BRANDING_PILOT_SIGNUP_NEW_USER')
});

var _default = function _default(config) {
  schema.load(config);
  return schema.validate({
    allowed: 'strict'
  });
};

exports["default"] = _default;