import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  DividerLine,
  Modal,
  ModalError,
  Input,
  Button,
  LoadingSplash,
  Title3,
  Small,
  SpaceBase,
  SpaceTiny,
  SpaceSmall,
  SpaceLarge,
} from '@properly/components';
import t from '@properly/localization';
import classNames from 'classnames/bind';
import { PagePadding } from '../../../../components/PageElements/index';
import * as selectorsAccount from '../state/AccountSelectors';
import styles from '../components/accountStyles.module.css';
import { setPasswordStore, runChangePw, setState, sendPwSaga } from '../state/AccountActions';
import { AccountHeadSection } from '../../../../components/AccountElements/index';

const cx = classNames.bind(styles);

// refactor ideas
// - get rid of cx
// - use redux form
// - outsource modal

class AccountPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { showError: false };
  }

  get passwordStateJS() {
    return this.props.passwordState.toJS();
  }

  get generalStatesJS() {
    return this.props.generalStates.toJS();
  }

  get validation() {
    if (this.passwordStateJS.oldPassword === '') {
      return { valid: false, oldpw: t('account.old_pw_needed') };
    }
    if (this.passwordStateJS.newPassword1 !== this.passwordStateJS.newPassword2) {
      return { valid: false, pw1: t('account.pw_not_match'), pw2: t('account.pw_not_match') };
    }
    if (this.passwordStateJS.newPassword1.length < 6) {
      return { valid: false, pw1: t('account.minlength'), pw2: t('account.minlength') };
    }

    return { valid: true };
  }

  canSeeError(msg) {
    return this.state.showError ? msg : false;
  }

  handleClick(isValid) {
    if (isValid) {
      this.props.runChangePw(this.passwordStateJS.oldPassword, this.passwordStateJS.newPassword2);
    } else {
      this.setState({ showError: true });
    }
  }

  render() {
    return (
      <PagePadding type="absolute-scroll">
        <AccountHeadSection>
          <Title3 type="nomargin">{t('account.password')}</Title3>
          <DividerLine type={['bottom']} />
        </AccountHeadSection>
        <SpaceBase />
        <div>
          <div className={cx('account__row')}>
            <div className={cx('account__col', 'account__col--maxheight')}>
              <Small type="medium">{t('account.current_password')}</Small>
            </div>
            <div className={cx('account__col', 'account__col--start')}>
              <Input
                data-key="current-password"
                isFirst
                isLast
                styleOverwrite={{ backgroundColor: '#FFF' }}
                error={this.canSeeError(this.validation.oldpw)}
                value={this.passwordStateJS.oldPassword}
                type="password"
                onChange={e => {
                  this.props.setPasswordStore({
                    oldPassword: e.target.value,
                  });
                }}
              />
              <SpaceTiny />
              <div className={cx('account__forgot')} onClick={() => this.props.setState('sendPw', 1)}>
                <Small>{t('account.forgot_pw')}</Small>
              </div>
            </div>
          </div>
          <SpaceBase />
          <div className={cx('account__row')}>
            <div className={cx('account__col')}>
              <Small type="medium">{t('account.new_password')}</Small>
            </div>
            <div className={cx('account__col')}>
              <Input
                data-key="new-password"
                isFirst
                isLast
                styleOverwrite={{ backgroundColor: '#FFF' }}
                error={this.canSeeError(this.validation.pw1)}
                value={this.passwordStateJS.newPassword1}
                type="password"
                onChange={e => {
                  this.props.setPasswordStore({
                    newPassword1: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <SpaceSmall />
          <div className={cx('account__row')}>
            <div className={cx('account__col')}>
              <Small type="medium">{t('account.verify_password')}</Small>
            </div>
            <div className={cx('account__col')}>
              <Input
                data-key="verify-password"
                isFirst
                isLast
                styleOverwrite={{ backgroundColor: '#FFF' }}
                showErrorMsg
                error={this.canSeeError(this.validation.pw2)}
                value={this.passwordStateJS.newPassword2}
                type="password"
                onChange={e => {
                  this.props.setPasswordStore({
                    newPassword2: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <SpaceBase />
          <div className={cx('account__row')}>
            <div className={cx('account__col')} />
            <div className={cx('account__col', 'account__col--start')}>
              <Button
                data-key="change-password"
                types={['type-full-primary', 'size-large']}
                fakeDisable={!this.validation.valid}
                onClick={() => this.handleClick(this.validation.valid)}
              >
                {t('account.change_password')}
              </Button>
              <SpaceBase />
              {this.generalStatesJS.updatePw === 3 && (
                <div data-key="password-updated">
                  <Small>{t('account.password_was_updated')}</Small>
                </div>
              )}
              {this.generalStatesJS.sendPw === 4 && (
                <div>
                  <Small>{t('account.password_was_send')}</Small>
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal type="loading" show={this.generalStatesJS.updatePw === 1} />
        <ModalError
          message1={t('account.edit_fail_pw_head')}
          message2={t('account.edit_fail_pw')}
          show={this.generalStatesJS.updatePw === 2}
          onClose={() => this.props.setState('updatePw', 0)}
        />
        <Modal
          height={220}
          onClose={() => this.props.setState('sendPw', 0)}
          show={
            this.generalStatesJS.sendPw === 1 || this.generalStatesJS.sendPw === 2 // skip error
          }
        >
          <div style={{ height: '100%' }}>
            {this.generalStatesJS.sendPw === 1 && (
              <div>
                <Modal.H1>{t('account.forgot_pw')}</Modal.H1>
                <Modal.Text>{t('account.will_email_link')}</Modal.Text>
                <SpaceLarge />
                <div className={cx('account__flexright')}>
                  <Button types={['type-full-primary', 'size-large']} onClick={() => this.props.sendPwSaga()}>
                    {t('account.send_pw_by_mail')}
                  </Button>
                </div>
              </div>
            )}
            {(this.generalStatesJS.sendPw === 2 || this.generalStatesJS.sendPw === 4) && (
              <div style={{ height: '100%' }}>
                <LoadingSplash static />
              </div>
            )}
          </div>
        </Modal>
      </PagePadding>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    passwordState: selectorsAccount.selectPasswordState()(state, props),
    generalStates: selectorsAccount.selectStates()(state, props),
  };
}

export default connect(mapStateToProps, {
  setPasswordStore,
  runChangePw,
  setState,
  sendPwSaga,
})(AccountPasswordContainer);
