import React from 'react';
import { connect } from 'react-redux';
import { Modal } from '@properly/components';
import { selectGlobalModalResolved } from '../../selectors/globalSelector';
import { modals } from '../../dataConstants';

const LoadingModal = ({ modal }) => {
  const isOpen = !!modal.isOpen;
  return <Modal type="loading" show={isOpen} />;
};

export default connect(
  state => ({
    modal: selectGlobalModalResolved(modals.LOADING_MODAL)(state),
  }),
  {},
)(LoadingModal);
