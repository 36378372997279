import { createSelector } from 'reselect';

import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import * as selectorsGlobal from '../../../selectors/globalSelector';
import { filterPropertySidebar } from '../../../helper/herbert';

export const selectReports = () => state => state.reports;
export const selectReportsSection = which => state => state.reports.getIn(['section', which]);
export const selectReportsSections = () => state => state.reports.getIn(['section']);
export const selectReportsModal = () => state => state.reports.getIn(['modal', 'modal']);
export const selectReportsModalMeta = () => state => state.reports.getIn(['modal', 'meta']);
export const selectReportsLoading = () => state => state.reports.getIn(['isLoading']);
export const selectReportsData = () => state => state.reports.getIn(['reportData']);
export const selectReportsSnapshot = () => state => state.reports.getIn(['sectionsSnapshot']);
export const selectReportsLoadedReport = () => state => state.reports.getIn(['loadedReport']);
export const selectReportsSectionSelected = which => state => state.reports.getIn(['section', which, 'selected']);
export const selectReportsSectionIsOpen = which => state => state.reports.getIn(['section', which, 'isOpen']);
export const selectReportsSectionIsAll = which => state => state.reports.getIn(['section', which, 'isAll']);
export const selectReportsSectionIsTouched = which => state => state.reports.getIn(['section', which, 'isTouched']);

export const selectContactsSearchQuery = selectorsGlobal.selectSearchQuery('reports_contacts_search');
export const selectContacts = () =>
  createSelector(
    selectReportsSection('contacts'),
    selectorsGlobal.selectContacts(),
    selectorsGlobal.selectSearchData('contacts', 'reports_contacts_search', selectorsGlobal.selectContacts),
    (contactSection, contacts, { result }) => {
      const reallyAll = contacts.toJS();
      const all = filterPropertySidebar(reallyAll); // filtered

      // handle search
      const filtered = orderBy(result, item => item.userData.firstName.toLowerCase());

      return {
        all,
        filtered,
        reallyAll: contacts,
      };
    },
  );

export const selectPropertiesSearchQuery = selectorsGlobal.selectSearchQuery('reports_properties_search');
export const selectProperties = () =>
  createSelector(
    selectReportsSection('properties'),
    selectorsGlobal.selectProperties(),
    selectorsGlobal.selectSearchData('properties', 'reports_properties_search', selectorsGlobal.selectProperties),
    (propertySection, properties, { result }) => {
      const reallyAll = properties.toJS();
      const all = filterPropertySidebar(reallyAll); // filtered

      // handle search
      let filtered = filterPropertySidebar(result);

      // order
      filtered = orderBy(filtered, property => property.getSearchString(), ['asc']);

      return {
        all,
        filtered,
        reallyAll: properties,
      };
    },
  );

export const selectReportsForSidebarLoading = createSelector(
  selectorsGlobal.selectPreloadStatus,
  preloadStatus => preloadStatus === 'notloaded',
);

export const selectReportsForSidebar = () =>
  createSelector(
    selectorsGlobal.selectReports(),
    selectorsGlobal.selectSearchData('reports', 'reports', selectorsGlobal.selectReports),
    (reports, { result }) => {
      const reallyAll = reports.toJS();
      return {
        all: keyBy(filterPropertySidebar(reallyAll), 'objectId'),
        filtered: keyBy(filterPropertySidebar(result), 'objectId'),
        reallyAll: reports,
      };
    },
  );
