import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import t from '@properly/localization';
import log from 'loglevel';
import map from 'lodash/map';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment-timezone';
import { jobRequestStatus } from '@properly/config';
import { connect } from 'react-redux';
import { LoadingSplash, SpaceTiny, SpaceXLarge, BrandingAds } from '@properly/components';
import { TransitionMotion, spring } from 'react-motion';
import classNames from 'classnames/bind';
import styles from './todosStyles.module.css';
import MainWrapper from '../../../containers/MainWrapper';
import TopLabel from '../../../components/TopLabel';
import { PageFlexOuter, PageTopBar, PagePadding, PageFlexInner } from '../../../components/PageElements';
import { TodosEle } from './TodosElements';
import DateLabel from '../../../components/DateLabel';
import { initSaga, loadDataSaga, handleClickSaga } from './TodosActions';
import { selectGroupedEvents, selectTodosPagination } from './TodosSelectors';
import NoProperties from '../../../components/NoProperties';
import { selectLoadingState } from '../../../selectors/globalSelector';
import {
  selectPartnerWebsiteUrl,
  selectIsPartnerDomain,
  selectAdStyles,
  selectTodosPageAdFromAds,
} from '../../branding/BrandingSelector';

const cx = classNames.bind(styles);

class TodosContainer extends PureComponent {
  calcRed(event) {
    if (
      event.status === jobRequestStatus.StateCleanerCancelled ||
      event.status === jobRequestStatus.StateDeclined ||
      event.eventStatus === 2 ||
      event.eventStatus === 3
    ) {
      return true;
    }
    return false;
  }

  handleClick = (mode, data) => e => {
    if (mode !== 'clicktile') {
      e.stopPropagation();
    }

    log.info('TodosContainer - handleClick', { mode, data });

    this.props.handleClickSaga(mode, data);
  };

  willLeave() {
    return {
      opacity: spring(0, { stiffness: 50, damping: 17 }),
    };
  }

  renderSection(items, key, style) {
    return (
      <div className={cx('todo__maxwith')} key={key} style={style}>
        <SpaceTiny />
        <DateLabel>{moment(key, ['YYYYMMDD']).format('dddd, D MMMM, YYYY')}</DateLabel>
        <SpaceTiny />
        <TransitionMotion
          willLeave={this.willLeave}
          styles={map(items, item => ({
            key: `item-${key}-${item.objectId}`,
            style: { opacity: 1 },
            data: item,
          }))}
        >
          {interpolatedStyles => (
            <div>
              {map(interpolatedStyles, config => (
                <div key={config.key} style={config.style}>
                  <TodosEle
                    onClick={this.handleClick('clicktile', config.data)}
                    handleClickBtn={this.handleClick}
                    isClickable={this.calcRed(config.data) && config.data.jobRequestResolved}
                    calendarEvent={config.data}
                    isRed={this.calcRed(config.data)}
                  />
                </div>
              ))}
            </div>
          )}
        </TransitionMotion>
      </div>
    );
  }

  componentWillMount() {
    this.props.initSaga(
      true,
      moment()
        .subtract(1, 'day')
        .toDate(),
    );
  }

  componentWillUnmount() {
    this.props.initSaga(undefined, false);
  }

  loadMore = () => {
    const { isLoaded } = this.props;
    if (!isLoaded) {
      return;
    }

    this.props.loadDataSaga(true);
  };

  renderLoader() {
    return (
      <div key={0}>
        <SpaceXLarge />
        <LoadingSplash static />
        <SpaceXLarge />
      </div>
    );
  }

  renderMain() {
    if (this.props.isErrored) {
      return null; // TODO: get error page
    }

    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={this.loadMore}
        hasMore={this.props.hasMoreData}
        loader={this.renderLoader()}
        useWindow={false}
      >
        <TransitionMotion
          willLeave={this.willLeave}
          styles={map(this.props.grouped, (item, key) => ({
            key: `item-${key}`,
            style: { opacity: 1 },
            data: item,
          }))}
        >
          {interpolatedStyles => (
            <div role="tree">
              {map(interpolatedStyles, configMain =>
                this.renderSection(configMain.data, configMain.key, configMain.style),
              )}
            </div>
          )}
        </TransitionMotion>
      </InfiniteScroll>
    );
  }

  get showChillMessage() {
    // TODO: These should be part of the selectors to trigger less re renders
    return !this.props.hasNextSeven && !this.props.isEmpty && this.props.isLoaded;
  }

  get showEmptyMessage() {
    // TODO: These should be part of the selectors to trigger less re renders
    return this.props.isEmpty && this.props.isLoaded && !this.props.hasMoreData;
  }

  render() {
    const { children, todosAd, adStyles, isPartnerDomain, partnerWebsiteUrl } = this.props;
    return (
      <>
        <MainWrapper currentPage="todos" disablePreloadLoader>
          <PageFlexOuter grey>
            <PageTopBar>
              <TopLabel
                data-key="button_edit_property"
                center
                black
                onlyTxtCursor
                extraPadding
                label={t('todos.headline')}
              />
            </PageTopBar>
            <PageFlexInner>
              <PagePadding variation="property" type="absolute-scroll">
                {this.showChillMessage && (
                  <div className={cx('todo__nextsevenok')}>
                    <NoProperties type="noeventsin7days" />
                  </div>
                )}
                {this.showEmptyMessage && <NoProperties type="noeventsatall" />}
                {this.renderMain()}
              </PagePadding>
              {isPartnerDomain && (
                <BrandingAds brandingAd={todosAd} partnerWebsiteUrl={partnerWebsiteUrl} adStyles={adStyles} typeTwoAd />
              )}
            </PageFlexInner>
          </PageFlexOuter>
        </MainWrapper>
        {children}
      </>
    );
  }
}

function mapStateToProps() {
  const memo = selectGroupedEvents();
  const selectTodoLoadingState = selectLoadingState('todos');
  return state => {
    const hasMoreDataPre = selectTodosPagination(state).get('hasMoreData');
    const isNotUndefined = hasMoreDataPre !== undefined;
    const hasMoreData = isNotUndefined ? hasMoreDataPre : true;
    const todosAd = selectTodosPageAdFromAds()(state) && selectTodosPageAdFromAds()(state).toJS();
    const adStyles = selectAdStyles()(state)?.toJS();
    const isPartnerDomain = selectIsPartnerDomain()(state);
    const partnerWebsiteUrl = selectPartnerWebsiteUrl()(state);
    return {
      ...memo(state),
      ...selectTodoLoadingState(state),
      hasMoreData,
      todosAd,
      adStyles,
      isPartnerDomain,
      partnerWebsiteUrl,
    };
  };
}

TodosContainer.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  isErrored: PropTypes.bool.isRequired,
  todosAd: PropTypes.shape({}),
  adStyles: PropTypes.shape({}),
  isPartnerDomain: PropTypes.bool,
};

TodosContainer.defaultProps = {
  todosAd: {},
  adStyles: {},
  isPartnerDomain: false,
};

export default connect(mapStateToProps, {
  initSaga,
  loadDataSaga,
  handleClickSaga,
})(TodosContainer);
