import React, { Component } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import classNames from 'classnames/bind';
import styles from '../components/checklistsStyles.module.css';
import ChecklistTopBar from '../components/ChecklistTopBar';
import ChecklistImageListItem from '../../../../components/ChecklistImageListItem/index';
import ChecklistSkill from '../components/ChecklistSkill';

const cx = classNames.bind(styles);

class CheckListSkillList extends Component {
  getPictureUrl(arr) {
    return Array.isArray(arr) && arr[0] ? arr[0] : undefined;
  }

  renderSingleTaskImage(task) {
    return (
      <ChecklistImageListItem
        key={`${task.id}${task.note ? task.note.length : ''}`}
        iconBg="white"
        isReadOnly
        defaultValue={task.note}
        pictureUrl={this.getPictureUrl(task.notePictureIdentifiers)}
        open={!!this.getPictureUrl(task.notePictureIdentifiers)}
        canHaveImg
        iconImage="other"
        iconColor="grey"
      />
    );
  }

  renderTasksImage(tasks) {
    return <>{map(tasks, task => this.renderSingleTaskImage(task))}</>;
  }

  render() {
    const { step, index } = this.props;
    const { pictureIdentifier, title, tasks } = step || {};
    return (
      <>
        <ChecklistTopBar title={title} isReadOnly />
        <ChecklistSkill isReadOnly index={index} image={pictureIdentifier} tasks={tasks} />
        <div className={cx('checklist__tasks')}>{this.renderTasksImage(tasks)}</div>
      </>
    );
  }
}

CheckListSkillList.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.shape({}).isRequired,
};

export default CheckListSkillList;
