import React from 'react';
import { Route } from 'react-router';
import { BookingDetailsModal } from '@properly/components';
import { setWindowTitle } from '@properly/common';

import CalendarRoot from './modules/desktopApp/calendar/containers/CalendarRoot';
import BookingDetailsContainer from './containers/BookingDetailsContainer/index';

const renderBookingDetailsContainer = props => <BookingDetailsContainer {...props} />;

export default () => (
  <>
    <Route exact path="calendar" component={CalendarRoot} onEnter={setWindowTitle('titles.calendar')}>
      <Route
        exact
        path="event/:propertyId/:bookingId"
        component={props =>
          props.params && props.params.propertyId && props.params.bookingId ? (
            <BookingDetailsModal
              bookingId={props.params.bookingId}
              propertyId={props.params.propertyId}
              handleCancel={() => props.router.push('calendar')}
              renderChildren={renderBookingDetailsContainer}
              show
            />
          ) : null
        }
      />
    </Route>
    <Route path="calendar/:from/:to" component={CalendarRoot} onEnter={setWindowTitle('titles.calendar')} />
  </>
);
