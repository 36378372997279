import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router';
import { setWindowTitle } from '@properly/common';

import LibraryChecklistsPublishedRoute from './modules/desktopApp/library/containers/LibraryChecklistsPublishedRoute';
import LibraryChecklistsIndependentRoute from './modules/desktopApp/library/containers/LibraryChecklistsIndependentRoute';
import LibraryChecklistsCloneRoute from './modules/desktopApp/library/containers/LibraryChecklistsCloneRoute';

import LibraryContainer from './modules/desktopApp/library/containers/LibraryContainer';
import ChecklistsSkillContainer from './modules/desktopApp/checklists/containers/ChecklistsSkillContainer';
import ChecklistsContainer from './modules/desktopApp/checklists/containers/ChecklistsContainer';
import ChecklistCloneContainer from './modules/desktopApp/checklists/containers/ChecklistCloneContainer';
import LibrarySkillRoute from './modules/desktopApp/library/containers/LibrarySkillRoute';

export default () => (
  <>
    <Route path="library" component={LibraryContainer} onEnter={setWindowTitle('titles.library')} />

    {['/', '//', '/:checklistId', '/:checklistId/'].map(path => (
      <Fragment key={path}>
        <Redirect from={`library/published${path}`} to="library" />
        <Redirect from={`library/independent${path}`} to="library" />
        <Redirect from={`library/clone/published${path}`} to="library" />
        <Redirect from={`library/clone/independent${path}`} to="library" />
      </Fragment>
    ))}

    <Route
      path="library/clone/independent/:checklistId/:jobInstructionId"
      component={props => {
        const RouteComponent = LibraryChecklistsCloneRoute(ChecklistCloneContainer);
        return (
          <RouteComponent
            {...props}
            jobInstructionId={props.params.jobInstructionId}
            checklistId={props.params.checklistId}
            published
            redirectUrl="library"
          />
        );
      }}
    />
    <Route
      path="library/clone/published/:checklistId/:jobInstructionId"
      component={props => {
        const RouteComponent = LibraryChecklistsCloneRoute(ChecklistCloneContainer);
        return (
          <RouteComponent
            {...props}
            jobInstructionId={props.params.jobInstructionId}
            checklistId={props.params.checklistId}
            published
            redirectUrl="library"
          />
        );
      }}
    />
    <Route
      path="library/published/:checklistId/:jobInstructionId"
      component={props => {
        const RouteComponent = LibraryChecklistsPublishedRoute(ChecklistsContainer);
        return (
          <RouteComponent
            {...props}
            jobInstructionId={props.params.jobInstructionId}
            checklistId={props.params.checklistId}
            published
            redirectUrl="library"
          />
        );
      }}
    />
    <Route
      path="library/independent/:checklistId/:jobInstructionId"
      component={props => {
        const RouteComponent = LibraryChecklistsIndependentRoute(ChecklistsContainer);
        return (
          <RouteComponent
            {...props}
            isIndependent
            jobInstructionId={props.params.jobInstructionId}
            checklistId={props.params.checklistId}
            redirectUrl="library"
          />
        );
      }}
    />
    <Route
      path="library/skill/:skillId"
      component={props => {
        const RouteComponent = LibrarySkillRoute(ChecklistsSkillContainer);
        return <RouteComponent {...props} skillId={props.params.skillId} redirectUrl="library" />;
      }}
    />
  </>
);
