import React from 'react';
import { LoadingSpinner, Modal, Icon, Regular, SpaceMedium, SpaceSmallMid } from '@properly/components';
import t from '@properly/localization';
import classNames from 'classnames/bind';
import styles from './accountStyles.module.css';

const cx = classNames.bind(styles);

export default ({ show }) => (
  <Modal onClose={() => {}} show={show}>
    <div style={{ height: '100%' }}>
      <div className={cx('account__loading')}>
        <div className={cx('account__loading-inner')}>
          <Icon.IcBigProperties2 />
          <SpaceMedium />
          <Regular>{t('account.importing_listing')}</Regular>
          <SpaceSmallMid />
          <LoadingSpinner />
        </div>
      </div>
    </div>
  </Modal>
);
