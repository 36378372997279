import {
  LOGIN_SUCCESS_USER,
  LOGIN_ERROR_USER,
  SIGNUP_USER,
  SIGNUP_SUCCESS_USER,
  SIGNUP_ERROR_USER,
  SIGNUP_ERROR_USER_RESET,
  SEND_TO_MOBILE_HOST_SUCCESS,
  SEND_TO_MOBILE_HOST_RESET,
  SEND_TO_MOBILE_HOST_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_VALIDATE,
  RESET_PASSWORD_RESET,
  LOGIN_ERROR_USER_RESET,
  LOGIN_SET_LOADING,
  LOGIN_SET_USER_OVERRIDE,
} from '../../types';

export default function loginSignUpReducers(
  state = {
    isWaiting: false,
    loginLoading: false,
    errors: [],
    step: 0,
    index: 0,
    length: 0,
    toMobileSent: false,
    toast: {
      message: '',
      type: null,
    },
    validUsername: false,
    matchingPasswords: false,
    loginOverride: false,
    submitHasFailed: false,
  },
  action = {},
) {
  switch (action.type) {
    case LOGIN_SUCCESS_USER:
      return Object.assign({}, state, {
        errors: [],
      });
    case LOGIN_ERROR_USER:
      return Object.assign({}, state, {
        loginLoading: false,
        errors: action.errors,
      });
    case LOGIN_ERROR_USER_RESET:
      return Object.assign({}, state, {
        loginLoading: false,
        errors: [],
      });
    case LOGIN_SET_LOADING:
      return Object.assign({}, state, {
        loginLoading: action.val,
      });
    case LOGIN_SET_USER_OVERRIDE:
      return Object.assign({}, state, {
        loginOverride: action.val,
      });

    /* HOST SIGN UP */
    case SIGNUP_USER:
      return Object.assign({}, state, {
        loginLoading: true,
        errors: [],
      });
    case SIGNUP_SUCCESS_USER:
      return Object.assign({}, state, {
        // isWaiting: false,
        loginLoading: false,
        errors: [],
        submitHasFailed: false,
      });
    case SIGNUP_ERROR_USER:
      return Object.assign({}, state, {
        // isWaiting: false,
        loginLoading: false,
        errors: action.errors,
        submitHasFailed: true,
      });
    case SIGNUP_ERROR_USER_RESET:
      return Object.assign({}, state, {
        loginLoading: false,
        errors: [],
      });

    /* SEND TO MOBILE */
    case SEND_TO_MOBILE_HOST_SUCCESS:
      return Object.assign({}, state, {
        isWaiting: false,
        loginLoading: false,
        toMobileSent: true,
        errors: [],
      });
    case SEND_TO_MOBILE_HOST_RESET:
      return Object.assign({}, state, {
        isWaiting: false,
        loginLoading: false,
        toMobileSent: false,
        errors: [],
      });
    case SEND_TO_MOBILE_HOST_ERROR:
      return Object.assign({}, state, {
        isWaiting: false,
        loginLoading: false,
        errors: action.errors,
      });

    /* RESET PASSWORD */
    case RESET_PASSWORD_SUCCESS:
      return Object.assign({}, state, { toast: action.payload });

    case RESET_PASSWORD_ERROR:
      return Object.assign({}, state, { toast: action.payload }, { validUsername: false });

    case RESET_PASSWORD_VALIDATE:
      if (action.payload === null) {
        return state;
      }
      return Object.assign({}, state, { validUsername: action.payload });

    case RESET_PASSWORD_RESET:
      return Object.assign({}, state, {
        toast: {
          type: 'hide',
          message: state.toast.message,
        },
      });

    default:
      return state;
  }
}
