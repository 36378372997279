import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Modal, LoadingSpinner } from '@properly/components';
import t from '@properly/localization';
import * as selectors from '../state/AccountSelectors';
import { setWarningModal, reImportProperties } from '../state/AccountActions';

class AccountWarningModals extends PureComponent {
  renderCorrectAccountWarning(partnerTitle, url) {
    return (
      <div>
        <Modal.H1>{t('account.verify_account')}</Modal.H1>
        <Modal.Text>{t('account.verify_account_low', { provider: partnerTitle })}</Modal.Text>
        <Modal.Options>
          <Modal.Cancel onClick={this.close} />
          <Modal.Ok onClick={this.goToProvider(url)}>{t('account.continue')}</Modal.Ok>
        </Modal.Options>
      </div>
    );
  }

  renderAlreadyConnectWarning(meta) {
    return (
      <div>
        <Modal.H1>{t('account.verify_account')}</Modal.H1>
        <Modal.Text>{t('account.verify_account_low2')}</Modal.Text>
        <Modal.Options>
          <Modal.Cancel onClick={this.close} />
          <Modal.Ok onClick={this.reimport(meta)}>{t('account.continue')}</Modal.Ok>
        </Modal.Options>
      </div>
    );
  }

  reimport = ({ partnerId, userId, updateHandler }) => () => {
    this.close();
    this.props.reImportProperties(partnerId, userId, updateHandler);
  };

  goToProvider(url) {
    return () => {
      this.props.setWarningModal({
        isOpen: true,
        meta: {
          modal: 3,
        },
      });

      setTimeout(() => {
        window.location.href = url;
      });
    };
  }

  close = () => {
    this.props.setWarningModal(false, true);
  };

  get stateJS() {
    return this.props.state.toJS();
  }

  render() {
    return (
      <Modal id="account-warning" height={230} onClose={this.close} show={this.stateJS.isOpen}>
        {this.stateJS.meta.modal === 1 &&
          this.renderCorrectAccountWarning(this.stateJS.meta.partnerTitle, this.stateJS.meta.url)}
        {this.stateJS.meta.modal === 2 && this.renderAlreadyConnectWarning(this.stateJS.meta)}
        {this.stateJS.meta.modal === 3 && (
          <Grid
            container
            style={{
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LoadingSpinner />
          </Grid>
        )}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    state: selectors.selectWarningModal()(state),
  };
}

export default connect(
  mapStateToProps,
  {
    setWarningModal,
    reImportProperties,
  },
)(AccountWarningModals);
