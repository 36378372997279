import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import * as selectors from '../state/ChecklistSelectors';
import { startPreloadSagaWithDelay } from '../../../../actions/globalActions';
import {
  locationHitChecklistDetail,
  stepMutatorSaga,
  goToProperty,
  setActiveChecklist,
  setTextTemplate,
  setUploadState,
  updateChecklistSaga,
  updateLastUpdatedTime,
  setModalClose,
  checklistDirectSlideUpload,
} from '../state/ChecklistActions';
import Checklists from './ChecklistsContainer';

function mapStateToProps(state, props) {
  const selectedJobInstruction = props.jobInstructionId
    ? selectors
        .selectJobInstructions()(state)
        .toJS()[props.jobInstructionId]
    : undefined;
  const selectedChecklist = props.checklistId
    ? selectorsGlobal
        .selectJobs()(state)
        .toJS()[props.checklistId]
    : undefined;
  const selectedProperty =
    selectedChecklist && selectedChecklist.defaultProperty
      ? selectorsGlobal
          .selectProperties()(state)
          .toJS()[selectedChecklist.defaultProperty]
      : undefined;

  const currentChecklistStateCode = selectors.selectCurrentState()(state, props);
  const isLoading = currentChecklistStateCode === 1;
  const isError = currentChecklistStateCode === 2;

  return {
    isLoading,
    isError,
    lastTimeUpdated: selectors.selectLastTimeUpdate()(state, props),
    modalData: selectors.selectModalData()(state),
    selectedJobInstruction,
    selectedChecklist,
    selectedProperty,
  };
}

// connect with store
export default connect(mapStateToProps, {
  checklistDirectSlideUpload,
  locationHitChecklistDetail,
  goToProperty,
  setActiveChecklist,
  setTextTemplate,
  setUploadState,
  stepMutatorSaga,
  updateChecklistSaga,
  updateLastUpdatedTime,
  push,
  setModalClose,
  startPreloadSagaWithDelay, // to refresh state
})(Checklists);
