import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import t from '@properly/localization';
import { Button, Chip, HoverTriggeredTooltip } from '@properly/components';
import ProBadgeModal from '../ProBadgeModal';
import { selectConfig } from '../../selectors/globalSelector';

class ProBadge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openProModal: false,
    };
  }

  render() {
    const onClickfunc = () => {
      this.setState(prevState => ({
        openProModal: !prevState.openProModal,
      }));
    };
    const { openProModal } = this.state;
    const { small, modalRequired, propertyType, withTooltip, config } = this.props;

    const chipStyle = big => ({
      backgroundColor: '#FF5722',
      color: 'white',
      height: big ? '17px' : '11px',
      width: big ? '33px' : '25px',
      borderRadius: big ? '3px' : '2px',
      fontFamily: 'graphik',
      fontSize: big ? '13px' : '9px',
      lineHeight: big ? '12px' : '9px',
      fontWeight: '500',
    });
    if (withTooltip)
      return (
        <HoverTriggeredTooltip title={t('probadge_modal.projob_sp_tooltip')}>
          <Chip chipStyle={chipStyle()} label={t('marketplace.probadge')} noOverflow />
        </HoverTriggeredTooltip>
      );
    if (small) return <Chip chipStyle={chipStyle()} label={t('marketplace.probadge')} noOverflow />;
    if (modalRequired)
      return (
        <div>
          <Button
            onClick={() =>
              this.setState(prevState => ({
                openProModal: !prevState.openProModal,
              }))
            }
            types={['size-badge-large', 'type-full-orange']}
          >
            {t('marketplace.probadge')}
          </Button>
          <ProBadgeModal
            propertyType={propertyType}
            openProModal={openProModal}
            onClickfunc={onClickfunc}
            config={config}
          />
        </div>
      );
    return <Chip chipStyle={chipStyle('big')} label={t('marketplace.probadge')} noOverflow />;
  }
}

ProBadge.propTypes = {
  small: PropTypes.bool,
  modalRequired: PropTypes.bool,
  propertyType: PropTypes.bool,
};

ProBadge.defaultProps = {
  small: false,
  modalRequired: false,
  propertyType: false,
};

function mapStateToProps(state, props) {
  return {
    config: selectConfig(state, props),
  };
}

export default connect(mapStateToProps)(ProBadge);
