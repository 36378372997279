import React, { PureComponent } from 'react';
import { Modal, CostForm, ModalError } from '@properly/components';
import t from '@properly/localization';
import { connect } from 'react-redux';
import * as selectorsJobDetails from '../state/JobDetailsSelectors';
import {
  closeJobRequestDetails,
  editCloseSaga,
  editContinueSaga,
  costCloseSaga,
  addCostSaga,
  addCostPicturesSaga,
  setCostType,
} from '../state/JobDetailsActions';
import { setGlobalLoadingState } from '../../../../actions/globalActions';
import * as globalSelector from '../../../../selectors/globalSelector';
import JobDetailsContainer from './JobDetailsContainer';
import { mediaUploadLoadingKey } from '../../../../dataConstants';

class JobDetailsContainerModal extends PureComponent {
  handleOnCloseErrorModal = () => {
    const { setGlobalLoadingState: setGlobalLoadingAction } = this.props;
    setGlobalLoadingAction(mediaUploadLoadingKey);
  };

  render() {
    const { imageUploadErrored } = this.props;
    const jobDetailsJS = this.props.jobDetails?.toJS();
    const jobRequest = this.props.jobRequest;
    const countryCode = jobRequest ? jobRequest.propertyData.countryCode : '';

    return (
      <div>
        {/* main modal */}
        <Modal
          type="jobdetail"
          style={{ zIndex: '100 !important' }}
          onClose={() => this.props.closeJobRequestDetails()}
          show={jobDetailsJS.isOpen}
        >
          <div>
            <JobDetailsContainer
              jobRequestId={this.props.jobRequestId}
              oldJobRequestId={jobRequest?.backwardCompatibility?.jobRequestId}
            />
          </div>
        </Modal>
        {/* are u sure u want to edit the job request */}
        <Modal
          height={210}
          width={580}
          onClose={() => this.props.editCloseSaga()}
          onClick={() => this.props.editCloseSaga()}
          show={jobDetailsJS.isOpenEditModal}
        >
          <div>
            <Modal.H1>{t('jobdetails.edit_modal')}</Modal.H1>
            <Modal.Text>{t('jobdetails.edit_modal_txt')}</Modal.Text>
            <Modal.Options>
              <Modal.Cancel onClick={() => this.props.editCloseSaga()} />
              <Modal.Ok onClick={() => this.props.editContinueSaga()} data-key="continue-edit-job-request">
                {t('jobdetails.edit_modal_continue')}
              </Modal.Ok>
            </Modal.Options>
          </div>
        </Modal>
        {/* open cost modal */}
        <Modal type="costModal" onClose={this.props.costCloseSaga} show={jobDetailsJS.isOpenCostModal}>
          <div>
            <CostForm
              open={jobDetailsJS.isOpenCostModal}
              close={this.props.costCloseSaga}
              data={this.props.addCostSaga}
              onUploadPic={this.props.addCostPicturesSaga}
              tempMilesImages={this.props.tempMilesImages}
              tempDurationImages={this.props.tempDurationImages}
              tempCostImages={this.props.tempCostImages}
              costType={this.props.setCostType}
              countryCode={countryCode}
              imageUploadErrored={imageUploadErrored}
            />
          </div>
        </Modal>

        {/* Modal to show if Cost or Comment image upload fails */}
        <ModalError.ImageUpload
          showUploadError={imageUploadErrored}
          onCloseUploadError={this.handleOnCloseErrorModal}
        />
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    jobDetails: selectorsJobDetails.selectJobDetails()(state, props),
    jobRequest: globalSelector.selectJobRequest(props.jobRequestId)(state, props),
    tempMilesImages: selectorsJobDetails
      .selectTempMilesImages()(state, props)
      .toJS(),
    tempDurationImages: selectorsJobDetails
      .selectTempDurationImages()(state, props)
      .toJS(),
    tempCostImages: selectorsJobDetails
      .selectTempCostImages()(state, props)
      .toJS(),
    imageUploadErrored: globalSelector.selectLoadingState(mediaUploadLoadingKey)(state)?.isErrored,
  };
}

export default connect(mapStateToProps, {
  closeJobRequestDetails,
  editCloseSaga,
  editContinueSaga,
  costCloseSaga,
  addCostSaga,
  addCostPicturesSaga,
  setCostType,
  setGlobalLoadingState,
})(JobDetailsContainerModal);
