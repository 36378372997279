import { push } from 'react-router-redux';
import noop from 'lodash/noop';
import * as types from '../../../../types';
import { ROUTES } from '../../../../paths';

export function setEventFilters(filters) {
  return {
    type: types.CALENDAR_SET_EVENT_FILTERS,
    filters,
  };
}

export function showBookingDetails(calendarEvent) {
  return push(ROUTES.bookingPageFromCalendar(calendarEvent.property.id, calendarEvent.booking.id));
}

export function resetCalendar() {
  return {
    type: types.CALENDAR_RESET,
  };
}

export function toggleCanceledEventsFilter() {
  return {
    type: types.CALENDAR_TOGGLE_CANCELED_EVENTS_FILTER,
  };
}

export function initCalendar() {
  return {
    type: types.CALENDAR_INIT,
  };
}

export function setSearchQuery(query) {
  return {
    type: types.CALENDAR_SET_SEARCH_QUERY,
    query,
  };
}

export function loadCalendarEventsRequest(
  startDate,
  endDate,
  offset = 0,
  limit = 20,
  dateBucketId,
  searchQuery,
  eventFilters,
) {
  const hasFilters = (!!eventFilters && eventFilters.length > 0) || !!searchQuery;
  const filtersId = hasFilters ? 'filters' : 'no-filters';

  const requestId = `${dateBucketId}${offset}-${limit}-${filtersId}`;
  const minimumLimit = limit;

  return {
    type: types.CALENDAR_LOAD_EVENTS_REQUEST,
    startDate,
    endDate,
    searchQuery,
    eventFilters,
    offset,
    limit: minimumLimit,
    dateBucketId,
    requestId,
    hasFilters,
  };
}

export function loadCalendarEventsSuccess(
  calendarPropertyEvents,
  total,
  offset,
  limit,
  dateBucketId,
  requestId,
  hasFilters,
  searchQuery,
) {
  return {
    type: types.CALENDAR_LOAD_EVENTS_SUCCESS,
    calendarPropertyEvents,
    total,
    dateBucketId,
    requestId,
    hasFilters,
    offset,
    limit,
    searchQuery,
  };
}

export function loadCalendarEventsFailure(error, requestId) {
  return {
    type: types.CALENDAR_LOAD_EVENTS_FAILURE,
    error,
    requestId,
  };
}

export function resetCalendarEventsFailure() {
  return {
    type: types.CALENDAR_LOAD_EVENTS_FAILURE_RESET,
  };
}

export function addLiveCalendarEvents(calendarEvents) {
  return {
    type: types.CALENDAR_ADD_LIVE_CALENDAR_EVENTS,
    calendarEvents,
  };
}

export function deleteLiveCalendarEvents(calendarEvents) {
  return {
    type: types.CALENDAR_DELETE_LIVE_CALENDAR_EVENTS,
    calendarEvents,
  };
}

export function setDates(startDate, endDate) {
  return {
    type: types.CALENDAR_SET_DATES,
    startDate,
    endDate,
  };
}

export function setPropertyViewport(overscrollStart, overscrollEnd) {
  return {
    type: types.CALENDAR_SET_PROPERTY_VIEWPORT,
    overscrollStart,
    overscrollEnd,
  };
}

export function handleEventButtonSaga(event, mode, resolve, reject) {
  return {
    type: types.CALENDAR_HANDLE_EVENT_BTN_SAGA,
    event,
    mode,
    resolve: resolve || noop,
    reject: reject || noop,
  };
}

export function eventChannelStartSaga() {
  return {
    type: types.CALENDAR_EVENT_CHANNEL_START_SAGA,
  };
}

export function eventChannelStopSaga() {
  return {
    type: types.CALENDAR_EVENT_CHANNEL_STOP_SAGA,
  };
}

export function setBackdrop(isOpen, data) {
  return {
    type: types.CALENDAR_SET_BACKDROP,
    isOpen,
    data,
  };
}

export function setModalData(data) {
  return {
    type: types.CALENDAR_SET_MODALDATA,
    data,
  };
}
