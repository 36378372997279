import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LoadingSplash } from '@properly/components';
import t from '@properly/localization';
import { getCurrencyFromCountryShortCode, isNoTouchDevice } from '@properly/common';
import log from 'loglevel';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';
import once from 'lodash/once';
import drop from 'lodash/drop';
import chunk from 'lodash/chunk';
import classNames from 'classnames/bind';
import styles from './propertyStyles.module.css';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import * as selectors from '../state/PropertySelectors';
import { goToChecklist, propertyChecklistPageSaga } from '../state/PropertyActions';
import ChecklistTileProperties from '../../../../components/ChecklistTileProperties/index';
import {
  copyChecklistToLibraryRequest,
  createChecklistSaga,
  updateChecklistSaga,
  setCloneChecklist,
  deleteChecklistSaga,
} from '../../checklists/state/ChecklistActions';
import * as selectorsChecklist from '../../checklists/state/ChecklistSelectors';
import Property from '../../../../model/property';
import { setModal } from '../../../../actions/globalActions';
import DefaultSettingsWarningModal from '../../checklists/containers/ChecklistDeleteWarningModal';
import HasPermission from '../../../../hoc/HasPermission/index';
import { prefilledDurationNewJobRequest } from '../../../../config';

const cx = classNames.bind(styles);

class PropertyDetailChecklistsContainer extends PureComponent {
  static propTypes = {
    deleteChecklistSagaProps: PropTypes.func,
    updateChecklistSagaProps: PropTypes.func,
    goToChecklistProps: PropTypes.func,
    propertyChecklistPageSagaProps: PropTypes.func,
    createChecklistSagaProps: PropTypes.func,
    setCloneChecklistProps: PropTypes.func,
    copyChecklistToLibrary: PropTypes.func,
    setModalProps: PropTypes.func,
    paginationStateCL: PropTypes.func,
    propertyId: PropTypes.string,
    jobs: PropTypes.shape({}),
    checklistLoadingStates: PropTypes.shape({}),
  };

  static defaultProps = {
    deleteChecklistSagaProps: () => {},
    updateChecklistSagaProps: () => {},
    goToChecklistProps: () => {},
    propertyChecklistPageSagaProps: () => {},
    createChecklistSagaProps: () => {},
    setCloneChecklistProps: () => {},
    copyChecklistToLibrary: () => {},
    setModalProps: () => {},
    paginationStateCL: () => {},
    propertyId: '',
    jobs: {},
    checklistLoadingStates: {},
  };

  constructor(props) {
    super(props);
    this.state = { hovered: false };
    this.isTouch = !isNoTouchDevice();
    const { createChecklistSagaProps } = this.props;
    this.createChecklist = once(createChecklistSagaProps);
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    const { propertyId } = this.props;
    if (prevProps.propertyId !== propertyId) {
      this.init();
    }
  }

  getType(job) {
    // eslint-disable-next-line
    if (job._create) {
      return 'create';
    }

    return 'picture';
  }

  get computedData() {
    const { jobs } = this.props;
    const array = jobs.toArray();
    const filteredDeleted = filter(array, item => !item.deleted);
    const withoutDeleted = orderBy(filteredDeleted, ['title'], ['asc']);
    const finalArray = withoutDeleted.slice(0);
    finalArray.unshift({
      _create: true,
    });
    return {
      withoutDeleted,
      withoutDeletedWithCreate: finalArray,
    };
  }

  get propertyData() {
    const { propertyData } = this.props;
    return propertyData;
  }

  duplicateChecklistToOther = checklist => () => {
    const { setCloneChecklistProps } = this.props;
    setCloneChecklistProps({
      isOpen: true,
      checklistTitle: checklist.title,
      selectedChecklistId: checklist.objectId,
    });
  };

  duplicateChecklistToLibrary = checklist => () => {
    log.info('Duplicating checklist into library', { checklist });
    const { copyChecklistToLibrary } = this.props;
    copyChecklistToLibrary(checklist.objectId);
  };

  openDefaultSettingsModal = checklistObj => () => {
    log.info('Property Checklist Detail prefil - openDefaultSettingsModal', checklistObj);

    const { defaultSettings } = checklistObj || {};
    const { duration } = defaultSettings || {};
    const { setModalProps } = this.props;
    setModalProps(true, 'jobrequest', {
      mode: 'prefill',
      source: 'property',
      checklistObj,
      data: {
        jobStartTimeType: 'fixed',
        currency: getCurrencyFromCountryShortCode(this.propertyData) || 'USD',
        propertyId: this.propertyData.objectId,
        checklistId: [checklistObj.objectId],
        isDefault: this.propertyData.defaultJob === checklistObj.objectId,
        duration: duration || prefilledDurationNewJobRequest,
      },
    });
  };

  updateChecklistSagaAction = (action, id) => () => {
    const { updateChecklistSagaProps } = this.props;
    updateChecklistSagaProps(action, id);
  };

  deleteChecklistSagaAction = (item, propertyData) => () => {
    const { deleteChecklistSagaProps } = this.props;
    deleteChecklistSagaProps(item, propertyData);
  };

  goToChecklistAction = (id, instructions) => () => {
    const { goToChecklistProps } = this.props;
    goToChecklistProps(id, instructions);
  };

  init() {
    const { propertyChecklistPageSagaProps, propertyData } = this.props;
    propertyChecklistPageSagaProps(propertyData.objectId);
  }

  genSubtitle(job, isDefault) {
    return `${job.totalStepCount || 0} ${t('properties.slides')} •
        ${job.totalTaskCount || 0} ${t('properties.tasks')} ${
      isDefault ? `• ${t('properties.default_checklist')}` : ''
    }`;
  }

  isChecklistLoading(query) {
    const { checklistLoadingStates } = this.props;
    return checklistLoadingStates?.toJS()[query] === 1;
  }

  renderChecklists(data) {
    return map(chunk(data, 4), (row, key) => (
      <div className={cx('property-detail__checklistrow')} key={`checklist-row-${key}`}>
        {map(row, checklist => {
          switch (this.getType(checklist)) {
            case 'create': {
              const isLoading = this.isChecklistLoading(`create${this.propertyData.objectId}`);
              return (
                <div className={cx('property-detail__checklistcol')} key="checklist-create">
                  <ChecklistTileProperties
                    type="dashed"
                    isCreate
                    isLoading={isLoading}
                    onClick={() => {
                      // Ensure we are not already trying to create a checklist
                      if (!isLoading) {
                        log.info('Creating checklist');
                        this.createChecklist({
                          role: this.propertyData.role,
                          user: this.propertyData.userId,
                          propertyId: this.propertyData.objectId,
                          title: t('checklist.checklist_untitled'),
                        });
                      }
                    }}
                    title={t('properties.create_checklist')}
                  />
                </div>
              );
            }
            case 'picture': {
              const isDefaultChecklist = this.propertyData.defaultJob === checklist.objectId;
              const { hovered } = this.state;
              return (
                <div className={cx('property-detail__checklistcol')} key={`checklist-${checklist.objectId}`}>
                  <HasPermission
                    meta={{ propertyId: this.propertyData.objectId }}
                    hasAccessFormatter={hasAccessRes => !hasAccessRes.canCreateChecklists}
                    render={isReadOnly => (
                      <ChecklistTileProperties
                        type="image"
                        isTouch={this.isTouch}
                        isShowOnly={isReadOnly}
                        isHovered={hovered}
                        isLoading={this.isChecklistLoading(checklist.objectId)}
                        setState={this.setStateLocal}
                        onDelete={this.deleteChecklistSagaAction(checklist, this.propertyData)}
                        onDuplicate={this.updateChecklistSagaAction('duplicate', checklist)}
                        onSetDefault={this.openDefaultSettingsModal(checklist)}
                        onDuplicateOther={this.duplicateChecklistToOther(checklist)}
                        onDuplicateLibrary={this.duplicateChecklistToLibrary(checklist)}
                        id={checklist.objectId}
                        isDefaultChecklist={isDefaultChecklist}
                        slideCount={checklist.totalStepCount}
                        taskCount={checklist.totalTaskCount}
                        onClick={
                          !isReadOnly && this.goToChecklistAction(checklist.objectId, checklist.jobInstructionId)
                        }
                        subTitle={this.genSubtitle(checklist, isDefaultChecklist)}
                        title={checklist.title}
                      />
                    )}
                  />
                </div>
              );
            }
            default:
              return undefined;
          }
        })}
        <DefaultSettingsWarningModal />
      </div>
    ));
  }

  render() {
    const { paginationStateCL, propertyData } = this.props;
    if (paginationStateCL === 1) {
      return (
        <div className={cx('property-detail__loadingcheck')}>
          <LoadingSplash static />
        </div>
      );
    }
    const data = this.computedData;
    return (
      <div style={{ paddingRight: 106 }}>
        <HasPermission
          meta={{ propertyId: propertyData.objectId }}
          hasAccessFormatter={hasAccessRes => hasAccessRes.canCreateChecklists}
          render={hasAccess => {
            const checklists = hasAccess ? data.withoutDeletedWithCreate : drop(data.withoutDeletedWithCreate, 1);
            return <div>{this.renderChecklists(checklists)}</div>;
          }}
        />
      </div>
    );
  }
}

PropertyDetailChecklistsContainer.propTypes = {
  propertyData: PropTypes.instanceOf(Property).isRequired,
};

function mapStateToProps(state, props) {
  return {
    jobs: selectorsGlobal.selectJobsByProperty(props.propertyData.objectId)(state, props),
    paginationStateCL: selectors.selectPaginationStateCL()(state, props),
    checklistLoadingStates: selectorsChecklist.selectLoadingStates()(state, props),
  };
}

export default connect(mapStateToProps, {
  createChecklistSagaProps: createChecklistSaga,
  deleteChecklistSagaProps: deleteChecklistSaga,
  updateChecklistSagaProps: updateChecklistSaga,
  goToChecklistProps: goToChecklist,
  setModalProps: setModal,
  setCloneChecklistProps: setCloneChecklist,
  propertyChecklistPageSagaProps: propertyChecklistPageSaga,
  copyChecklistToLibrary: copyChecklistToLibraryRequest,
})(PropertyDetailChecklistsContainer);
