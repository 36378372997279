import React from 'react';
import { Route, Redirect } from 'react-router';
import { BookingDetailsModal } from '@properly/components';

import CanRenderPage from './hoc/CanRenderPage';

import ReportsContainer from './modules/desktopApp/reports/ReportsContainer';
import ReportsDetailContainer from './modules/desktopApp/reports/ReportsDetailContainer';
import BookingDetailsContainer from './containers/BookingDetailsContainer';

const renderBookingDetailsContainer = props => <BookingDetailsContainer {...props} />;

export default () => (
  <>
    <Redirect from="reports" to="reports/new" />
    <Route
      path="reports/:id"
      component={CanRenderPage('reportsPage', ({ children, ...rest }) => (
        <ReportsContainer {...rest}>
          <ReportsDetailContainer {...rest}>{children}</ReportsDetailContainer>
        </ReportsContainer>
      ))}
    >
      <Route
        exact
        path="booking/:propertyId/:bookingId"
        component={props => (
          <BookingDetailsModal
            bookingId={props.params.bookingId}
            propertyId={props.params.propertyId}
            handleCancel={() => props.router.replace(`reports/new`)}
            renderChildren={renderBookingDetailsContainer}
            show
          />
        )}
      />
    </Route>
  </>
);
