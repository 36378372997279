import locale from 'browser-locale';
import moment from 'moment-timezone';
import log from 'loglevel';
import { client as apolloClient } from '../../config/apollo';
import { MUTATION_JOB_REQUEST, UPDATE_PROBLEM, ADD_COST } from '../mutations/jobRequest';
import { getProblemIdsFromTasks } from '../helper';
import { generateToken } from '../../modules/desktopApp/data';
import { QUERY_GET_PROGRESS_COUNT } from '../queries/jobDetails';

const getMetadata = name => ({
  name,
  userRole: 'HOST',
  date: new Date().toISOString(),
  syncClientTimestamp: new Date().toISOString(),
  languageCode: locale(), // browser local language
});

const transformJobRequest = job => {
  const {
    jobType,
    property,
    day,
    endDay,
    checklist,
    offeredPrice,
    offeredPriceCurrency,
    selectedCleaners,
    note,
    title,
    jobStartTimeType,
    eventId,
    skills,
    tasks,
    prevJobRequestId,
    sourceJobSnapshotId,
  } = job;

  const duration = parseFloat(job.duration);

  const timeZone = property.getTimeZone();

  const endDate = jobStartTimeType === 'FLEXIBLE' ? endDay : null;

  const startTime = moment
    .tz(day, timeZone)
    .millisecond(0)
    .toDate();
  const endTime = endDate
    ? moment
        .tz(endDate, timeZone)
        .millisecond(0)
        .toDate()
    : moment
        .tz(day, timeZone)
        .add(duration, 'minutes')
        .millisecond(0)
        .toDate();

  const mappedTasks = tasks.map(task => ({
    id: task.id,
    sourceProblemId: task.sourceProblemId,
    title: task.title,
    note: task.note,
    pictureIdentifiers: task.pictureIdentifiers,
  }));

  const sourceProblems = {};

  const problemIds = getProblemIdsFromTasks(tasks);

  if (problemIds) {
    sourceProblems.sourceJobSnapshotId = prevJobRequestId || sourceJobSnapshotId;
    sourceProblems.problemIds = problemIds;
  }

  return {
    jobType,
    title,
    note: note || '',
    propertyId: property.objectId,
    type: jobStartTimeType === 'fixed' || jobStartTimeType === 'REGULAR' ? 'REGULAR' : 'FLEXIBLE',
    checklistIds: checklist,
    serviceProviderIds: selectedCleaners,
    offeredPrice: parseFloat(offeredPrice) || 0,
    offeredPriceCurrency,
    duration,
    skills,
    calendarEventId: eventId,
    startDate: startTime,
    endDate: endTime,
    tasks: mappedTasks,
    sourceProblems: problemIds ? sourceProblems : null,
  };
};

export const createJobRequest = async job => {
  try {
    const createdJobRequest = await apolloClient.mutate({
      mutation: MUTATION_JOB_REQUEST,
      variables: {
        input: {
          eventMetadataInput: getMetadata('JOB_SEND'),
          eventInput: transformJobRequest(job),
        },
      },
      fetchPolicy: 'no-cache',
    });

    const response = createdJobRequest.data.JobRequestEvent.JobRequestEventResponse;

    return response;
  } catch (error) {
    log.error(`createJobRequest ${error}`);
    return error;
  }
};

export const editJobRequest = async ({ id, job }) => {
  try {
    const editedJobRequest = await apolloClient.mutate({
      mutation: MUTATION_JOB_REQUEST,
      variables: {
        input: {
          eventMetadataInput: getMetadata('JOB_EDIT'),
          eventInput: {
            ...transformJobRequest(job),
            id,
          },
        },
      },
      fetchPolicy: 'no-cache',
    });
    const response = editedJobRequest.data.JobRequestEvent.JobRequestEventResponse;

    return response;
  } catch (error) {
    log.error(`editJobRequest ${error}`);
    return error;
  }
};

export const sendJobRequestToMoreCleaners = async ({ id, serviceProviderIds, propertyId }) => {
  try {
    const jobRequest = await apolloClient.mutate({
      mutation: MUTATION_JOB_REQUEST,
      variables: {
        input: {
          eventMetadataInput: getMetadata('SEND_TO_MORE_CLEANERS'),
          eventInput: {
            id,
            serviceProviderIds,
            propertyId,
          },
        },
      },
      fetchPolicy: 'no-cache',
    });
    const response = jobRequest.data.JobRequestEvent.JobRequestEventResponse;

    return response;
  } catch (error) {
    log.error(`sendJobRequestToMoreCleaners API ${error}`);
    return error;
  }
};

export const cancelJobRequest = async id => {
  try {
    const canceledJobRequest = await apolloClient.mutate({
      mutation: MUTATION_JOB_REQUEST,
      variables: {
        input: {
          eventMetadataInput: getMetadata('JOB_HOST_CANCEL'),
          eventInput: {
            id,
          },
        },
      },
      fetchPolicy: 'no-cache',
    });
    const response = canceledJobRequest.data.JobRequestEvent.JobRequestEventResponse;

    return response;
  } catch (error) {
    log.error(`cancelJobRequest ${error}`);
    return null;
  }
};

export const updateProblem = async job => {
  try {
    const updatedProblem = await apolloClient.mutate({
      mutation: UPDATE_PROBLEM,
      variables: {
        input: {
          eventMetadataInput: getMetadata('UPDATE_PROBLEM'),
          job,
        },
      },
      fetchPolicy: 'no-cache',
    });

    const response = updatedProblem.data.UpdateProblem.UpdateProblemEventResponse;

    return response;
  } catch (error) {
    log.error(`updateProblem api ${error}`);
    return null;
  }
};

export const getJobProgressCountApi = async id => {
  try {
    const response = await apolloClient.query({
      query: QUERY_GET_PROGRESS_COUNT,
      variables: {
        role: 'HOST',
        languageCode: locale(),
        id,
        isHost: true,
      },
      fetchPolicy: 'no-cache',
    });
    return response.data.Job.progress;
  } catch (error) {
    log.error(`[getJobProgressCountApi] ${error}`);
    error.apiError = true;
    return error;
  }
};

// *************************************************************
//                  COST RE-CAPTURE STARTS HERE
// *************************************************************

const mapJobCostInput = cost => {
  const jobCostInput = {
    // costId: '', // only in case of edit
    type: cost.type,
    value: parseFloat(cost.value),
    note: cost.note,
    pictureIdentifiers: cost.pictureIdentifiers,
  };

  return jobCostInput;
};

const mapJobProgressInput = (jobId, propertyId) => {
  const date = new Date();
  const jobProgressInput = {
    id: jobId,
    date,
    // checklistId: '', // optional
    // stepId: '', // optional
    // taskId: '', // optional
    propertyId,
  };

  return jobProgressInput;
};

export const addCostApi = async (cost, selectedJobRequestId, propertyId) => {
  try {
    const jobCostInput = mapJobCostInput(cost);
    const jobProgressInput = mapJobProgressInput(selectedJobRequestId, propertyId);
    log.debug('jobCostInput : ', jobCostInput);
    log.debug('jobProgressInput : ', jobProgressInput);

    const reportCost = await apolloClient.mutate({
      mutation: ADD_COST,
      variables: {
        input: {
          eventMetadataInput: getMetadata('ADD_REPORT_COST'),
          jobCostInput,
          jobProgressInput,
          clientMutationId: generateToken(10),
        },
        fetchPolicy: 'no-cache',
      },
    });

    const response = reportCost.data.ReportCost.JobEventResponse;
    return response;
  } catch (error) {
    log.error(`addCost api ${error}`);
    return null;
  }
};

// *************************************************************
//                  COST RE-CAPTURE ENDS HERE
// *************************************************************
