"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.roundToNearest15 = exports.configurableOnChange = void 0;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var configurableOnChange = function configurableOnChange(component, property, propertyValue, postFn) {
  var shouldTriggerEvent = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  return function (event) {
    var valueToSet = shouldTriggerEvent ? event : propertyValue;
    var result = shouldTriggerEvent ? event : propertyValue;
    component.setState(_defineProperty({}, property, valueToSet), function () {
      return postFn ? postFn(result) : null;
    });
  };
};

exports.configurableOnChange = configurableOnChange;

var roundToNearest15 = function roundToNearest15(value) {
  return value && Math.ceil(Number(value) / 15) * 15;
};

exports.roundToNearest15 = roundToNearest15;