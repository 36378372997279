import React, { Component } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import Scroll from 'react-scroll';
import * as selectors from '../state/ChecklistSelectors';
import Sidebar from '../../../../components/Sidebar/index';
import ChecklistSidebarItem from '../../../../components/ChecklistSidebarItem/index';
import DragField from '../components/ChecklistField';

class ChecklistSkillSidebar extends Component {
  goToSection(id) {
    const { scroller } = Scroll;
    scroller.scrollTo(id, {
      duration: 1500,
      delay: 100,
      smooth: true,
      containerId: 'checklist-scroll-main',
    });
  }

  renderInner() {
    const { selectedSkill, activeSkill } = this.props;
    const ident = 'sidebar';
    const steps = selectedSkill?.steps || [];
    return (
      <div>
        {map(steps, (step, i) => (
          <DragField isReadOnly key={step.id} id={step.id} index={i}>
            <div onClick={() => this.goToSection(step.id)}>
              <ChecklistSidebarItem
                needsVerify={step.isVerificationRequired}
                identifier={ident}
                title={step.title}
                active={activeSkill === step.id}
                steps={step.tasks}
                image={step.pictureIdentifier}
              />
            </div>
          </DragField>
        ))}
      </div>
    );
  }

  render() {
    return (
      <div style={{ height: '100%' }}>
        <Sidebar>
          <Sidebar.List>
            <Sidebar.ListInner>
              <Sidebar.ListInnerInner>{this.renderInner()}</Sidebar.ListInnerInner>
            </Sidebar.ListInner>
          </Sidebar.List>
        </Sidebar>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    activeSkill: selectors.selectActiveChecklist()(state, props),
  };
}

export default connect(mapStateToProps, {})(ChecklistSkillSidebar);
