import React, { Component } from 'react';
import PropTypes from 'prop-types';
import t from '@properly/localization';
import { SearchSelect, PopoverDefault, ProfilePicture, ButtonList, Icon, ModalError } from '@properly/components';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import map from 'lodash/map';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import values from 'lodash/values';
import pickBy from 'lodash/pickBy';
import differenceWith from 'lodash/differenceWith';
import keyBy from 'lodash/keyBy';
import classNames from 'classnames/bind';
import colors from '@properly/components/lib/common/styles/colors.module.css';
import stylesCss from './index.module.css';
import InfoMessage from '../../../../components/InfoMessage/index';
import Sidebar from '../../../../components/Sidebar/index';
import CleanerListTags from '../../../../components/CleanerListTags/index';
import {
  JobRequestPropertyInfo,
  JobRequestCheckableHover,
  JobRequestContentWrap,
  JobRequestSpacerWrap,
  JobRequestTextColor,
  JobRequestCleanerProfile,
} from '../../../../components/JobRequestElements/index';
import { makeNameString } from '../../../../helper/herbert';
import { sendJobRequestModes } from '../../../../dataConstants';
import ChecklistOverlay from '../../../../components/ChecklistOverlay/index';
import { trackAssingJobShowCleaners } from '../../../../actions/trackingEvents';
import ProBadge from '../../../../components/ProBadge';

const cx = classNames.bind(stylesCss);

const styles = () => ({
  root: {
    boxShadow: 'none',
    borderBottom: `1px solid rgba(29,39,48,0.12)`,
    textAlign: 'justify',
    marginBottom: -1,
  },
  content: {
    justifyContent: 'center',
    margin: '20px 125px !important',
    minHeight: 24,
    '&$expanded': {
      margin: 'auto',
    },
    '&> :last-child': {
      paddingRight: '0',
    },
  },
  expandIcon: {
    right: '110px',
  },
  title: {
    margin: '0 4px',
  },
  lightBulbIcon: {
    position: 'absolute',
    top: '14px',
    left: '120px',
  },
  expansionPanelDetails: {
    color: colors['color-grey'],
  },
  expanded: {},
  label: {
    fontSize: '15px',
  },
  link: {
    color: colors['color-primary'],
    textDecoration: 'none',
    '&:hover': {
      color: colors['color-primary-hover'],
    },
  },
});

class JobRequestSectionCleaners extends Component {
  state = {
    activeTab: 'normal',
    query: '',
    isErrorModalOpen: false,
  };

  get allCleaners() {
    return this.props.searchData.result;
  }

  get selectedCleaners() {
    return this.props.finalCleaners;
  }

  get communityCleaners() {
    return this.props.communityCleaners.toArray();
  }

  get filteredCommunityCleaners() {
    const querySplit = this.state.query.split(' ').filter(split => !!split && split.length >= 2);

    return querySplit.length === 0
      ? this.communityCleaners || []
      : (this.communityCleaners || []).filter(({ userData }) => {
          const cleanerString = `${userData.firstName || ''} ${userData.lastName || ''}`.toLowerCase();
          return (
            querySplit.filter(currentSplit => cleanerString.indexOf(currentSplit.toLowerCase()) !== -1).length !== 0
          );
        });
  }

  get hasCCleaners() {
    return (this.communityCleaners || []).length > 0 && this.canSeeCCleaners;
  }

  get canSeeCCleaners() {
    if (this.props.finalMode === sendJobRequestModes.prefill) {
      return false;
    }
    return true;
  }

  get isSendMoreMode() {
    const { finalMode } = this.props;
    return finalMode === sendJobRequestModes.sendmore;
  }

  handleSelectCleaner = (contact, selected) => {
    this.props.handleClickSaga(false, {
      action: 'setcleaner',
      contact,
      set: !selected,
    });
  };

  handleAllCleaners = (contacts, areAllSelected) => {
    const selectedContactsArray = values(this.selectedCleaners);
    const contactsArray = values(contacts);

    if (!areAllSelected) {
      this.props.unsetJobRequestCleaners({
        cleaners: keyBy(
          selectedContactsArray.filter(cleaner => cleaner?.isDisabled),
          'id',
        ),
      });
      return undefined;
    }

    const restOfTheCleaners = differenceWith(
      contactsArray,
      selectedContactsArray,
      (contact, selectedContact) => contact.userData.objectId === selectedContact?.id,
    );

    return keyBy(restOfTheCleaners, 'contactId');
  };

  handleSelectCleaners = (contacts, areAllSelected) => {
    contacts = this.handleAllCleaners(contacts, areAllSelected);

    this.props.handleClickSaga(false, {
      action: 'setcleaners',
      contacts,
    });
  };

  handleEventClickCleaner = contact => e => {
    // show an alert if a user clicks on a pro contact
    if (this.isDisabled(contact) && !this.isActive(contact)) {
      this.setState({ isErrorModalOpen: true });
    } else {
      this.handleSelectCleaner(contact, !e.target.checked);
    }
  };

  isActive = contact => this.isSelected(contact) && this.isClickable(contact);

  isSelected = contact => this.selectedCleaners[contact.userData.objectId] !== undefined;

  isClickable = contact => {
    const result = pickBy(this.selectedCleaners, (obj, key) => {
      if (obj.isDisabled) {
        return key;
      }
      return '';
    });
    return !(this.isSendMoreMode && Object.keys(result).includes(contact.contactId));
  };

  isDisabled = contact => {
    const { finalPrice, proMarketProperty } = this.props;
    return this.isSendMoreMode && proMarketProperty && !finalPrice && contact.listSuggestedCleaner;
  };

  openCleaner = cleaner => e => {
    e.stopPropagation();
    e.preventDefault();
    if (!this.isCleanerOpen(cleaner)) {
      trackAssingJobShowCleaners();
    }
    this.setState({
      openCleaner: this.isCleanerOpen(cleaner) ? undefined : cleaner.userData.objectId,
    });
  };

  handleOpenSlider = images => (index, e) => {
    e.stopPropagation();
    e.preventDefault();
    const mappedImages = map(images, image => ({
      image,
    }));
    this.props.setSliderSaga(true, mappedImages, index);
  };

  renderCleaner = (isCommunity, config) => (cleaner, i) => {
    const color = isCommunity ? 'blue' : 'orange';
    const cleanerProfile = isCommunity ? cleaner : cleaner.profile;
    const { proMarketProperty } = this.props;

    return (
      <div key={cleaner.userId || cleaner.objectId}>
        <JobRequestCheckableHover
          type="fullwidth"
          data-key-cleaner-index={i}
          data-key-is-community={isCommunity}
          data-key="jobrequestsend_cleaneritem"
          data-key-id={cleaner.objectId}
          data-key-is-cleaner-me={this.props.currentUserId === cleaner.contactId}
          data-key-is-clickable={!!this.isClickable(cleaner)}
          isBlue={isCommunity}
          onChange={this.handleEventClickCleaner(cleaner)}
          isActive={this.isActive(cleaner)}
          isClickable={this.isClickable(cleaner) && !this.isDisabled(cleaner)}
          isDisabled={this.isDisabled(cleaner) && !this.isActive(cleaner)}
          sendMoreMode={this.isSendMoreMode}
        >
          <JobRequestPropertyInfo
            order={['pic', 'text']}
            txtMod={{ withpic: true }}
            picType="custom"
            type="cleaner"
            showProBadge={proMarketProperty && cleaner?.getProStatus?.()}
            pic={<ProfilePicture picture={cleaner.userPicture} text={cleaner.userInitials} type="cleaner" />}
            l1={makeNameString(cleaner, isCommunity, this.props.currentUserId)}
            l2={!isCommunity ? cleaner.lastJobSendString : communityCleanerJobInfo(cleaner)}
            l3={
              <JobRequestTextColor color={color} isInline onClick={this.openCleaner(cleaner)}>
                {this.isCleanerOpen(cleaner) ? t('job_request.hide_profile') : t('job_request.view_profile')}
              </JobRequestTextColor>
            }
          />
          <JobRequestCleanerProfile
            cleaner={cleaner}
            config={config}
            onOpenImageSlider={this.handleOpenSlider(cleanerProfile.propertyPhotos, isCommunity)}
            isOpen={this.isCleanerOpen(cleaner)}
          />
        </JobRequestCheckableHover>
        <JobRequestSpacerWrap left="0px" top="0px" bottom="12px" right="0px" />
      </div>
    );
  };

  mapToTabProps(props) {
    return { isActive: props.active };
  }

  changeTab = name => () => {
    this.setState({ activeTab: name });
  };

  isCleanerOpen(cleaner) {
    return cleaner.userData.objectId === this.state.openCleaner;
  }

  isActiveTab(name) {
    return this.state.activeTab === name;
  }

  get filteredCleaners() {
    return filter(this.allCleaners, item => !item.deleted);
  }

  get renderData() {
    let data = {
      arrayLeft: [],
      arrayRight: [],
    };
    if (this.hasCCleaners) {
      data.arrayLeft = this.filteredCleaners;
      data.arrayRight = this.filteredCommunityCleaners;
    } else {
      data = reduce(
        this.filteredCleaners,
        (acc, item, index) => {
          if (index % 2) {
            acc.arrayRight.push(item);
          } else {
            acc.arrayLeft.push(item);
          }
          return acc;
        },
        {
          arrayLeft: [],
          arrayRight: [],
        },
      );
    }
    return data;
  }

  get allCleanersConcated() {
    const communityCleaners = this.canSeeCCleaners ? this.filteredCommunityCleaners : [];
    return concat(this.filteredCleaners, communityCleaners);
  }

  get allCleanersConcatedFiltered() {
    return this.allCleanersConcated.filter(contact => !this.isDisabled(contact));
  }

  get filteredCleanersLength() {
    return (this.filteredCleaners || []).length;
  }

  get communityCleanersLength() {
    return (this.communityCleaners || []).length;
  }

  get selectedCleanersLength() {
    return values(this.selectedCleaners).length;
  }

  get areAllSelected() {
    const selectedCleaners = this.selectedCleaners;
    const allVisibleCleaners = this.allCleanersConcatedFiltered;

    const areAllselected =
      allVisibleCleaners.filter(({ contactId, userId }) => !selectedCleaners[contactId] && !selectedCleaners[userId])
        .length === 0;
    return areAllselected;
  }

  selectAll() {
    const areAllSelected = this.areAllSelected;
    const reduceCleaners = (input, setOrUnset) =>
      reduce(
        input,
        (total, item) => {
          total[item.userData.objectId] = setOrUnset ? item : undefined;
          return total;
        },
        {},
      );

    this.handleSelectCleaners(reduceCleaners(this.allCleanersConcatedFiltered, !areAllSelected), !areAllSelected);
  }

  handleSearch(query) {
    const { setSearchQuery } = this.props;
    this.setState({ query });
    setSearchQuery('jobrequestnew_searchcleaners', query);
  }

  get totalContactsCount() {
    return filter(this.props.contacts?.toJS(), item => !item.deleted).length;
  }

  renderProInfo() {
    const { classes, config } = this.props;
    return (
      <Accordion square className={classes.root}>
        <AccordionSummary
          classes={{ content: classes.content, expandIcon: classes.expandIcon }}
          expandIcon={<Icon.IcExpandMore width="24px" className={classes.highlightColor} />}
        >
          <div className={classes.lightBulbIcon}>
            <Icon.LightBulb width="17.5px" height="28px" byHeight />
          </div>
          {t('marketplace.expansion_title')}
          <span className={classes.title}>Properly</span>
          <div>
            <ProBadge />
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.expansionPanelDetails}>
          <span className={classes.label}>
            {t('marketplace.expansion_content', {
              msg: (
                <a
                  className={`${classes?.link} text-l6m`}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={config.proMPSatisfactionGuaranteeUrl}
                >
                  {t('marketplace.guarantee')}
                </a>
              ),
              tos: (
                <a
                  className={`${classes.link} text-l6m`}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={config.proTOSUrl}
                >
                  {t('marketplace.expansion_content_tos')}
                </a>
              ),
            })}
          </span>
        </AccordionDetails>
      </Accordion>
    );
  }

  renderHeader() {
    const { searchData } = this.props;
    const { query } = this.state;

    const communityTotalLength = this.canSeeCCleaners ? this.communityCleanersLength : 0;
    const communityQueryLength = this.canSeeCCleaners ? this.filteredCommunityCleaners.length : 0;

    const selectedCount = this.selectedCleanersLength;
    const totalCount = this.totalContactsCount + communityTotalLength;
    const queryCount = query ? this.filteredCleanersLength + communityQueryLength : totalCount;
    return (
      <JobRequestSpacerWrap left="24px" top="16px" bottom="0px" right="24px" minHeight="50px">
        <div className={cx('cleaner__topbarwrap')} style={{ width: '100%', marginBottom: '6px' }}>
          <SearchSelect
            selectedCount={selectedCount}
            queryCount={queryCount}
            totalCount={totalCount}
            value={searchData.searchQuery}
            onChange={value => this.handleSearch(value)}
            placeholder={t('contacts.search_cleaner')}
            isSelected={this.areAllSelected}
            onSelect={() => this.selectAll()}
          />
        </div>
        <div className={cx('cleaner__topbarwrap')} style={{ marginBottom: '6px' }}>
          <div className={cx('cleaner__topbarwrap-item')}>{t('job_request.my_service_providers')}</div>
          <div
            className={cx('cleaner__topbarwrap-item', {
              'cleaner__topbarwrap-item--end': !this.hasCCleaners,
            })}
          >
            {this.hasCCleaners && t('job_request.community_service_providers')}
          </div>
        </div>
      </JobRequestSpacerWrap>
    );
  }

  render() {
    const renderData = this.renderData;
    const { proMarketProperty } = this.props;
    const { isErrorModalOpen } = this.state;
    return (
      <>
        <JobRequestContentWrap id="job-request-cleaners-form">
          {proMarketProperty && this.renderProInfo()}
          {this.renderHeader()}
          <Sidebar.List>
            <Sidebar.ListInner>
              <Sidebar.ListInnerInner enableOverflow>
                <JobRequestSpacerWrap left="24px" top="0px" bottom="12px" right="24px" flexGrow>
                  <ChecklistOverlay.GridRow spacing="12">
                    <ChecklistOverlay.GridItem spacing="12" itemPerRow="2">
                      {map(renderData.arrayLeft, this.renderCleaner(false, this.props.config))}
                    </ChecklistOverlay.GridItem>
                    <ChecklistOverlay.GridItem spacing="12" itemPerRow="2">
                      {map(renderData.arrayRight, this.renderCleaner(this.hasCCleaners, this.props.config))}
                    </ChecklistOverlay.GridItem>
                  </ChecklistOverlay.GridRow>
                </JobRequestSpacerWrap>
              </Sidebar.ListInnerInner>
            </Sidebar.ListInner>
          </Sidebar.List>
          <InfoMessage
            text={this.props.infoTextMsg(this.isActiveTab('cc'))}
            isPrompt
            isVcenter
            isHcenter
            icon="light"
            color="white"
          />
        </JobRequestContentWrap>
        <ModalError
          message2={t('job_request.pro_cleaner_send_more_error')}
          show={isErrorModalOpen}
          onClose={() => this.setState({ isErrorModalOpen: false })}
        />
      </>
    );
  }
}

function communityCleanerJobInfo(cleaner) {
  const realNumHosts = cleaner.numOfHosts || 0;
  const realNumHostsString = realNumHosts > 1 || realNumHosts === 0 ? t('contacts.clients') : t('contacts.client');
  return `${cleaner.numOfJobRequestsDone || 0} ${t('contacts.total_jobs')} • ${realNumHosts} ${realNumHostsString}`;
}

const mapLeftOverCleaners = leftOverCleaners =>
  leftOverCleaners.map((cleaner, index) => {
    const formedName = `${cleaner.cleaner.firstName} ${cleaner.cleaner.lastName}`;

    return {
      'data-key': index,
      isCommunityCleaner: cleaner.isCommunity,
      name: formedName,
      secondaryName: !cleaner.isCommunity
        ? cleaner.rawCleaner.lastJobSendString
        : communityCleanerJobInfo(cleaner.rawCleaner),
    };
  });

const seeMoreCleanersContent = leftOverCleaners => (
  <ButtonList isButton={false} hasDivider isDenseStyle items={mapLeftOverCleaners(leftOverCleaners)} />
);

function wrapperSeeMoreCleaners({ children, leftOverCleaners }) {
  return (
    <PopoverDefault
      popoverKey="viewSelectedCleaners"
      width={220}
      hasArrow
      popoverContent={() => seeMoreCleanersContent(leftOverCleaners)}
    >
      <span>{children}</span>
    </PopoverDefault>
  );
}

function mapContactsToListTags({ finalCleaners: listOfContactOrProfile, finalProperty }) {
  return map(listOfContactOrProfile, item => {
    const isContact = item.profile && item.userData;
    return {
      isCommunity: !isContact,
      contactId: item.userData.objectId,
      rawCleaner: item,
      cleaner: {
        firstName: item.userData.firstName,
        lastName: item.userData.lastName,
      },
      proMarketProperty: finalProperty ? finalProperty.proMarketProperty : false,
    };
  });
}

JobRequestSectionCleaners.content = ({ finalCleaners, finalProperty }) => (
  <CleanerListTags
    type="simple"
    wrapperComponent={wrapperSeeMoreCleaners}
    items={mapContactsToListTags({ finalCleaners, finalProperty })}
    limit={1}
  />
);

JobRequestSectionCleaners.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(JobRequestSectionCleaners);
