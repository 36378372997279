import log from 'loglevel';
import { client as apolloClient } from '../../config/apollo';
import { QUERY_GET_PARTNER_PROPERTY_IDS } from '../queries/partnerPropertyIds';

export const getPartnerPropertyIds = async id => {
  try {
    const rawResponse = await apolloClient.query({
      query: QUERY_GET_PARTNER_PROPERTY_IDS,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });
    return rawResponse.data.PartnerPropertyIds;
  } catch (error) {
    log.error(`getPartnerPropertyIds api error :${error}`);
    error.apiError = true;
    return error;
  }
};
