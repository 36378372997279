import { push } from 'react-router-redux';
import * as types from '../../../../types/index';
import { ROUTES } from '../../../../paths';

export function goToCalendar() {
  return push(ROUTES.calendar);
}

export function initJobRequestSaga(data) {
  return {
    type: types.JOB_REQUEST_INIT_SAGA,
    data,
  };
}

export function submitSaga(meta) {
  return {
    type: types.JOB_REQUEST_SUBMIT,
    meta,
  };
}

export function handleClickSaga(event, meta) {
  return {
    type: types.JOB_REQUEST_HANDLECLICK,
    event,
    meta,
  };
}

export function selectChecklist(checklistId) {
  return {
    type: types.JOB_REQUEST_SELECT_CHECKLIST,
    checklistId,
  };
}

export function selectSkill(skillId) {
  return {
    type: types.JOB_REQUEST_SELECT_SKILL,
    skillId,
  };
}

export function changeJobTitle(title) {
  return {
    type: types.JOB_REQUEST_CHANGE_JOB_TITLE,
    title,
  };
}

export function changeJobType(jobType) {
  return {
    type: types.JOB_REQUEST_CHANGE_JOB_TYPE,
    jobType,
  };
}

export function selectChecklistViewType(checklistViewType) {
  return {
    type: types.JOB_REQUEST_SELECT_CHECKLIST_TYPE,
    checklistViewType,
  };
}

export function openPaywallModal() {
  return {
    type: types.JOB_REQUEST_OPEN_PAYWALL_MODAL,
  };
}

export function closePaywallModal() {
  return {
    type: types.JOB_REQUEST_CLOSE_PAYWALL_MODAL,
  };
}

export function setOpenSection(key, meta) {
  return {
    type: types.JOB_REQUEST_SET_OPEN_SECTION,
    key,
    meta,
  };
}

export function setDataJobRequest(data) {
  return {
    type: types.JOB_REQUEST_SET_DATA,
    data,
  };
}

export function setCommunityJobRequest(data) {
  return {
    type: types.JOB_REQUEST_SET_COMMUNITY,
    data,
  };
}

export function setStartupDataJobRequest(data) {
  return {
    type: types.JOB_REQUEST_SET_STARTUPDATA,
    data,
  };
}

export function unsetJobRequestCleaners({ cleaners }) {
  return {
    type: types.JOB_REQUEST_UNSET_CLEANERS,
    cleaners,
  };
}

export function resetJobRequest() {
  return {
    type: types.JOB_REQUEST_RESET,
  };
}

export function updateProblemStatus(jobRequest, problem, problemStatus, sourceJob) {
  return {
    type: types.JOB_REQUEST_UPDATE_PROBLEM,
    jobRequest,
    problem,
    problemStatus,
    sourceJob,
  };
}

export function changeTaskTitle(taskTitle) {
  return {
    type: types.JOB_REQUEST_CHANGE_TASK_TITLE,
    taskTitle,
  };
}

export function changeTaskDescription(taskDescription) {
  return {
    type: types.JOB_REQUEST_CHANGE_TASK_DESCRIPTION,
    taskDescription,
  };
}

export function setHostStripeStatus(status) {
  return {
    type: types.JOB_REQUEST_SET_HOST_STRIPE_STATUS,
    status,
  };
}
