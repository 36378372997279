import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const ChecklistBox = ({ children }) => (
  <div className={cx('checklist-box')}>
    <div>{children}</div>
  </div>
);

ChecklistBox.propTypes = {
  children: PropTypes.node,
};

export default ChecklistBox;
