import noop from 'lodash/noop';
import log from 'loglevel';
import ProperlyError from '../../helper/properlyError';

let requestHandlerApi;

export function setRequestApi(api) {
  requestHandlerApi = api;
}

function validateArgs(array) {
  if (array.length > 1) {
    log.error('too many args, just 1 allowed');
  }
  return array[0];
}

export function requestHandler(promiseFunc, promiseObj) {
  return new Promise((resolve, reject) => {
    const logoutFunc = (requestHandlerApi && requestHandlerApi.logout) || noop;

    const handleReject = (...args) => {
      log.error('requestHandler handleReject', ...args);
      const response = validateArgs(args);

      if (response && response.code && response.code === 107) {
        resolve();
        return;
      }

      const res = ProperlyError.errorFromParse(response);
      if (res.properlyErrorMsg === 'InvalidSession') {
        logoutFunc();
      }

      reject(res);
    };

    const handleResolve = (...args) => {
      const response = validateArgs(args);
      resolve(response);
    };

    const returnedPromise = promiseFunc(handleResolve, handleReject);

    if (!returnedPromise) {
      log.warn('There was no returned promise from a requestHandler function', promiseFunc);
    }

    // If the request handler is returned a promise then attach our handlers to it
    if (returnedPromise && returnedPromise.then && returnedPromise.catch) {
      return returnedPromise.then(handleResolve).catch(handleReject);
    }

    return Promise.resolve();
  }, promiseObj);
}
