import { createSelector } from 'reselect';
import reduce from 'lodash/reduce';
import each from 'lodash/each';
import filter from 'lodash/filter';
import lodashResult from 'lodash/result';
import values from 'lodash/values';
import { fixMomentDates, getCalendarEventReminderTitle, roundNext15Min } from '@properly/common';
import { Iterable } from 'immutable';
import log from 'loglevel';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import { sectionsWithId, sectionsArray, sectionsByIndex } from '../containers/JobRequestFormSchema';
import { sendJobRequestModes } from '../../../../dataConstants';

export const selectJobRequest = () => state => state.jobRequestSend;
export const selectJobRequestOpenSection = () => createSelector(selectJobRequest(), state => state.get('openSection'));
export const selectJobRequestData = () => createSelector(selectJobRequest(), state => state.get('jobRequestData'));

export const selectJobRequestDataChecklistId = () =>
  createSelector(selectJobRequestData(), state => state.get('checklistId'));
export const selectJobRequestCommunity = () =>
  createSelector(selectJobRequest(), state => state.get('communityCleaners'));
export const selectHostStripeStatus = () => createSelector(selectJobRequest(), state => state.get('hostStripeStatus'));
export const selectJobRequestStartupData = () => createSelector(selectJobRequest(), state => state.get('startupData'));
export const selectJobRequestTasks = () =>
  createSelector(selectJobRequestData(), jobRequestData => jobRequestData.get('tasks'));

export const selectJobRequestMode = createSelector(selectJobRequest(), state => state.getIn(['startupData', 'mode']));
export const selectIsPaywallModalOpen = createSelector(selectJobRequest(), state => state.get('isPaywallModalOpen'));
export const selectHasSeenPaywallModal = createSelector(selectJobRequest(), state => state.get('seenPaywallModal'));

export const selectJobRequestStartTime = createSelector(selectJobRequestData(), jobRequestData => {
  const startTime = jobRequestData.get('startTime');
  return startTime ? roundNext15Min(fixMomentDates(startTime)) : undefined;
});

export const selectJobRequestChecklistViewType = createSelector(selectJobRequest(), jobRequestState =>
  jobRequestState.get('checklistViewType'),
);

export const selectJobRequestEndTime = createSelector(selectJobRequestData(), jobRequestData => {
  const endTime = jobRequestData.get('endTime');
  return endTime ? roundNext15Min(fixMomentDates(endTime)) : undefined;
});

export const selectJobRequestCleaners = createSelector(selectJobRequestData(), jobRequestData =>
  jobRequestData.get('cleaners'),
);

function defaultValidFunc(name, context) {
  return function func() {
    log.error(`validation func missing ${name}`, context);
    return false;
  };
}

function modeToDisabledSections(mode) {
  switch (mode) {
    case sendJobRequestModes.sendmore: {
      return {
        property: true,
        propertyDetails: true,
        checklist: true,
        title: true,
        time: true,
        price: true,
        message: true,
        skills: true,
      };
    }
    case sendJobRequestModes.prefill: {
      return {
        property: true,
        propertyDetails: true,
        checklist: true,
      };
    }
    default: {
      return {};
    }
  }
}

function mapModeToIsValid(mode, { allSectionsLength, validCount }) {
  switch (mode) {
    case sendJobRequestModes.prefill: {
      return true;
    }
    default: {
      return validCount === allSectionsLength;
    }
  }
}

export const selectedJobRequestComputedData = () =>
  createSelector(
    selectJobRequestOpenSection(),
    selectJobRequestData(),
    selectorsGlobal.selectProperties(),
    selectJobRequestStartupData(),
    (openSection, jobRequestData, properties, meta) => {
      let validCount = 0;
      const jobRequestSendMode = meta.get('mode');
      const mappedSections = reduce(
        sectionsWithId,
        (acc, sectionData, sectionId) => {
          const data = {};
          data.open = !!openSection.get(sectionId);
          data.isSectionDisabled = !!modeToDisabledSections(jobRequestSendMode)[sectionId];
          data.active = data.open;
          data.validFunc = sectionData.isValid || defaultValidFunc('isValid', sectionData);
          data.emptyFunc = sectionData.isEmpty || defaultValidFunc('isValid', sectionData);
          data.isValid = data.validFunc(jobRequestData, properties, meta); //  supply obj
          data.isEmpty = data.emptyFunc(jobRequestData, properties, meta); //  supply obj
          data.infoTextMsg =
            sectionData.infoText &&
            sectionData.infoText({ jobRequestSendMode, isEmpty: data.isEmpty, jobRequestData, properties });
          data.prevSectionIndex = parseInt(sectionsByIndex[sectionId], 10) - 1;
          data.nextSectionIndex = parseInt(sectionsByIndex[sectionId], 10) + 1;
          data.prevSection = sectionsArray[data.prevSectionIndex];
          data.nextSection = sectionsArray[data.nextSectionIndex];
          data.prevSectionRes = acc[lodashResult(data.prevSection, 'id')];
          data.sectionTitle = sectionData.title();
          data.prevSectionDisabled = !!(!data.prevSectionRes || data.prevSectionRes.isSectionDisabled);
          if (data.isValid) validCount += 1;
          acc[sectionId] = {
            open: data.open,
            active: data.active,
            isValid: data.isValid,
            isSectionDisabled: data.isSectionDisabled,
            infoTextMsg: data.infoTextMsg,
            disabledNext: !data.isValid,
            optional: sectionData.optional,
            isEmpty: data.isEmpty,
            sectionTitle: data.sectionTitle,
            prevSection: data.prevSection,
            nextSection: data.nextSection,
            prevSectionDisabled: data.prevSectionDisabled,
            // nextSectionDisabled: see below,
          };
          return acc;
        },
        {},
      );
      let hightLightedSectionIndex = -1;
      each(sectionsArray, (item, index) => {
        const currentItemResolved = mappedSections[item.id];
        // nextSectionDisabled?
        const nextSectionRes = mappedSections[lodashResult(currentItemResolved.nextSection, 'id')];
        mappedSections[item.id].nextSectionDisabled = !!(!nextSectionRes || nextSectionRes.isSectionDisabled);

        // change title for property to event if needed
        const calendarEvent = meta.get('event');
        if (item.id === 'property' && calendarEvent) {
          mappedSections[item.id].sectionTitle = getCalendarEventReminderTitle(
            Iterable.isIterable(calendarEvent) ? calendarEvent.toJS() : calendarEvent,
          );
        }

        // find the section to highlight
        if (
          hightLightedSectionIndex === -1 &&
          (!currentItemResolved.isValid ||
            (item.id === 'checklist' && jobRequestData.get('checklistId').size === 0) ||
            (currentItemResolved.optional && mappedSections[item.id].isEmpty))
        ) {
          hightLightedSectionIndex = index;
        }
      });
      const isAllValid = mapModeToIsValid(jobRequestSendMode, {
        validCount,
        allSectionsLength: sectionsArray.length,
      });
      const activeSection = values(openSection.toJS())[0];
      const hightLightedSectionId = lodashResult(sectionsArray[hightLightedSectionIndex], ['id']);
      return {
        mappedSections,
        activeSection,
        isAllValid,
        jobRequestSendMode,
        meta,
        hightLightedSectionId,
      };
    },
  );

export const selectedChecklistsFiltered = property =>
  createSelector(selectorsGlobal.selectJobs(), jobs =>
    filter(
      jobs.toArray(),
      checklist =>
        checklist.defaultProperty === property &&
        (checklist.totalStepCount || checklist.totalTaskCount) &&
        !checklist.deleted,
    ),
  );

export const selectJobRequestSkills = () => createSelector(selectJobRequestData(), state => state.get('skills'));
