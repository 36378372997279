import React from 'react';
import PropTypes from 'prop-types';
import t from '@properly/localization';
import { Icon } from '@properly/components';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const ChecklistDropbox = ({ children, onClick }) => (
  <div className={cx('checklist-dropbox')} onClick={onClick}>
    <Icon.IcUpload />
    <div className={cx('checklist-dropbox__txt')}>{t('checklist.uploaddrag')}</div>
    <div className={cx('checklist-dropbox__btn')}>{t('checklist.select_file')}</div>
    {children}
  </div>
);

ChecklistDropbox.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default ChecklistDropbox;
