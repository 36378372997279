import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { LoadingSpinner, ModalError } from '@properly/components';
import t from '@properly/localization';
import log from 'loglevel';
import { compose } from 'recompose';
import moment from 'moment-timezone';
import CalendarListContainer from './CalendarListContainer';
import CalendarHeaderContainer from './CalendarHeaderContainer';
import * as actions from '../state/CalendarActions';
import * as selectors from '../state/CalendarSelectors';
import { ROUTES } from '../../../../paths';

class CalendarContainer extends Component {
  state = {
    navHeight: 147,
    hasVerticalScroll: false,
  };

  componentWillMount = () => {
    const dateParam = this.props.location.query.date;
    const from = dateParam ? moment(dateParam, ['YYYYMMDD']).toDate() : this.props.params.from;
    if (from) {
      this.setCalendarToday(from, this.props.params.to);
    }

    this.props.initCalendar();
    this.props.eventChannelStartSaga();
  };

  componentWillUnmount() {
    this.props.eventChannelStopSaga();
    document.getElementById('app').click(); // close popover
  }

  setCalendarToday = (from, to) => {
    log.info('setCalendarToday - params', from, to);

    if (from) {
      const start = moment(from, ['YYYYMMDD']).toDate();
      start.setHours(0, 0, 0, 0);

      const end = to ? moment(to, ['YYYYMMDD']).toDate() : new Date(start).setDate(start.getDate() + 6);
      end.setHours(23, 59, 59, 0);

      return this.setDates(start, end);
    }
    const start = new Date();
    start.setHours(0, 0, 0, 0);
    start.setDate(start.getDate() - 1);

    const end = new Date(start);
    end.setDate(start.getDate() + 6);
    end.setHours(23, 59, 59, 0);

    return this.setDates(start, end);
  };

  setCalendarPlus = (currentStartDate, days) => {
    const start = new Date(currentStartDate);
    start.setDate(currentStartDate.getDate() + days);
    const end = new Date(start);
    end.setDate(start.getDate() + 6);
    this.setDates(start, end);
  };

  setDates(startDate, endDate) {
    this.props.setDates(startDate, endDate);
  }

  onPropertiesRendered = ({ overscanStartIndex, overscanStopIndex }) => {
    this.props.setPropertyViewport(overscanStartIndex, overscanStopIndex);
  };

  renderLoading() {
    return (
      <div
        style={{
          position: 'absolute',
          width: '100%',
          display: 'flex',
          top: 0,
          left: 0,
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          pointerEvents: 'none',
        }}
      >
        <LoadingSpinner dataKey="calendar-loading-spinner" />
      </div>
    );
  }

  setDateWithinRange() {
    const { startDate, outsideRangeDayCount } = this.props;

    this.setDates(
      moment(startDate)
        .add(outsideRangeDayCount, 'd')
        .toDate(),
      moment(startDate)
        .add(outsideRangeDayCount, 'd')
        .add(7, 'd')
        .toDate(),
    );
  }

  handleHasVerticalScrollChange = hasScroll => {
    this.setState({
      hasVerticalScroll: hasScroll,
    });
  };

  renderRangeErrorModal() {
    const { outsideRange } = this.props;
    return (
      <ModalError
        width={575}
        height={150}
        message1={t('calendar.range_error_1')}
        message2={t('calendar.range_error_2')}
        onClose={() => this.setDateWithinRange()}
        show={outsideRange}
      />
    );
  }

  closeErrorModal() {
    const { initCalendar } = this.props;
    initCalendar();
    this.props.resetCalendarEventsFailure();

    // If the calendar is unavailable (possibly because of an elasticsearch failure),
    // we direct the user to the properties tab as it's likely other parts of
    // the application that don't rely on elasticsearch still work fine.
    this.props.goToPage(ROUTES.properties);
  }

  renderErrorModal() {
    const { loading, error } = this.props;
    return (
      <ModalError
        message1={t('calendar.load_error_1')}
        message2={t('calendar.load_error_2')}
        onClose={() => this.closeErrorModal()}
        show={!!error && !loading}
      />
    );
  }

  renderList() {
    return (
      <div style={{ position: 'absolute', top: this.state.navHeight, left: 0, right: 0, bottom: 0 }}>
        <CalendarListContainer
          onHasVerticalScrollChange={this.handleHasVerticalScrollChange}
          hasVerticalScroll={this.state.hasVerticalScroll}
          handleBookingClick={this.props.handleBookingClick}
          onPropertiesRendered={this.onPropertiesRendered}
        />
      </div>
    );
  }

  render() {
    const { loading, totalCurrentProperties } = this.props;
    const isCalendarLoading = loading && totalCurrentProperties === 0;

    return (
      <div>
        {this.renderErrorModal()}
        {this.renderRangeErrorModal()}

        <CalendarHeaderContainer
          hasVerticalScroll={this.state.hasVerticalScroll}
          onHeightChange={navHeight => this.setState({ navHeight })}
          isCalendarLoading={isCalendarLoading}
          setCalendarToday={this.setCalendarToday}
          setCalendarPlus={this.setCalendarPlus}
        />

        {isCalendarLoading ? this.renderLoading() : this.renderList()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  outsideRange: selectors.selectCalendarOutsideDateRange(state),
  outsideRangeDayCount: selectors.selectCalendarOutsideDateRangeDayCount(state),
  startDate: selectors.selectStartDate(state),
  error: selectors.selectCalendarError(state),
  loading: selectors.selectPropertiesLoading(state),
  totalCurrentProperties: selectors.selectCurrentPropertyTotal(state),
});

const mapDispatchToProps = {
  setSearchQuery: actions.setSearchQuery,
  loadCalendarEventsRequest: actions.loadCalendarEventsRequest,
  initCalendar: actions.initCalendar,
  setPropertyViewport: actions.setPropertyViewport,
  setDates: actions.setDates,
  goToPage: page => push(page),
  resetCalendarEventsFailure: actions.resetCalendarEventsFailure,
  eventChannelStartSaga: actions.eventChannelStartSaga,
  eventChannelStopSaga: actions.eventChannelStopSaga,
  handleBookingClick: actions.showBookingDetails,
};

export default compose(
  // Redux connection
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(CalendarContainer);
