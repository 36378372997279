import React from 'react';
import { Route } from 'react-router';
import { BookingDetailsModal } from '@properly/components';
import { setWindowTitle } from '@properly/common';

import TodosContainer from './modules/desktopApp/todos/TodosContainer';
import BookingDetailsContainer from './containers/BookingDetailsContainer';

const renderBookingDetailsContainer = props => <BookingDetailsContainer {...props} />;

export default () => (
  <>
    <Route path="todos" component={TodosContainer} onEnter={setWindowTitle('titles.todos')}>
      <Route
        exact
        path="booking/:propertyId/:bookingId"
        component={props =>
          props.params && props.params.propertyId && props.params.bookingId ? (
            <BookingDetailsModal
              bookingId={props.params.bookingId}
              propertyId={props.params.propertyId}
              handleCancel={() => props.router.push('todos')}
              renderChildren={renderBookingDetailsContainer}
              show
            />
          ) : null
        }
      />
    </Route>
  </>
);
