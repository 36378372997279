import React from 'react';
import { Loader } from 'google-maps';

const wrapper = ({ googleKey }) => Component => {
  class LoadGoogle extends React.Component {
    state = {
      loading: !window.google,
    };

    async componentDidMount() {
      const { loading } = this.state;
      if (loading) {
        const options = { libraries: ['places', 'geometry'] };
        const loader = new Loader(googleKey, options);
        const google = await loader.load();
        window.google = google;
        this.setState({ loading: false });
      }
    }

    render() {
      return <Component loading={this.state.loading} {...this.props} />;
    }
  }
  return LoadGoogle;
};

export default wrapper;
