import Parse from 'parse';
import { error as errorMessage } from '@properly/config';
import log from 'loglevel';

function runWithCatch(func) {
  try {
    return func();
  } catch (e) {
    return false;
  }
}

function mapErrorToProperlyError(error) {
  try {
    const errorObj = runWithCatch(() => JSON.parse(error.message));
    if (errorObj) {
      switch (errorObj.code) {
        case errorMessage.EMAIL_NOT_UNIQUE:
        case errorMessage.EMAIL_DUPLICATE:
          return errorMessage.EMAIL_NOT_UNIQUE;
        case errorMessage.INVALID_EMAIL:
          return errorMessage.INVALID_EMAIL;
        case errorMessage.PHONE_IS_NOT_UNIQUE:
        case errorMessage.PHONE_NOT_UNIQUE:
          return errorMessage.PHONE_NOT_UNIQUE;
        case errorMessage.INVALID_PHONE:
          return errorMessage.INVALID_PHONE;
        case errorMessage.EMAIL_NOT_FOUND:
          return errorMessage.EMAIL_NOT_FOUND;
        case errorMessage.COULD_NOT_LOGIN:
          return errorMessage.COULD_NOT_LOGIN;
        case errorMessage.INVALID_USERNAME:
          return errorMessage.INVALID_USERNAME;
        case errorMessage.FAILED_RECAPTCHA_VERIFICATION:
          return errorMessage.FAILED_RECAPTCHA_VERIFICATION;
        default:
      }
    }

    const stringifiedError = JSON.stringify(error);

    if (/(.*)CanNotConnectPartnerUser(.*)/.test(stringifiedError)) {
      return errorMessage.OAUTH_ALREADY_CONNECTED;
    }
    if (/(.*)DisabledByOwner(.*)/.test(stringifiedError)) {
      return errorMessage.ACCOUNT_DISABLED;
    }
    if (/(.*)invalid session token(.*)/.test(stringifiedError)) {
      return errorMessage.INVALID_SESSION;
    }

    return errorMessage.UNKNOWN;
  } catch (e) {
    log.error('mapErrorToProperlyError', e);
    return errorMessage.UNKNOWN;
  }
}

class ProperlyError extends Parse.Error {
  constructor(code, message, properlyErrorMsg, latestVersion) {
    super(code, message, properlyErrorMsg);
    this.properlyErrorMsg = properlyErrorMsg;
    this.latestVersion = latestVersion;
  }

  static errorFromParse(error) {
    return new ProperlyError(error.code, error.message, mapErrorToProperlyError(error), error.latestVersion);
  }
}

export default ProperlyError;
