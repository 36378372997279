import extend from 'lodash/extend';
import {
  LOGIN_SUCCESS_USER,
  SIGNUP_SUCCESS_USER,
  LOGOUT_SUCCESS_USER,
  SEND_TO_MOBILE_HOST_SUCCESS,
  USER_UPDATE_CURRENT_USER,
  USER_UPDATE_CURRENT_USER_SUBSCRIPTION,
  GLOBAL_LOGOUT,
  HAS_SEEN_ONBOARDING_VIDEO,
  SET_USER_EMAIL_TO_AUTO_POPULATE,
  RESET_USER_EMAIL_TO_AUTO_POPULATE,
} from '../types';
import User from '../model/user';

import { getCurrentUser, isLoggedIn } from '../modules/desktopApp/data';
/*
 * Message store for global messages, i.e. Network messages / Redirect messages
 * that need to be communicated on the page itself. Ideally
 * messages/notifications should appear within the component to give the user
 * more context. - My 2 cents.
 */
export default function currentUser(
  state = {
    user: getCurrentUser(),
    isLoggedIn: isLoggedIn(),
    isLoggingOut: false,
    hasSeenOnBoardingVideo: false,
    userEmail: '',
  },
  action = {},
) {
  switch (action.type) {
    case LOGIN_SUCCESS_USER:
    case SIGNUP_SUCCESS_USER:
    case SEND_TO_MOBILE_HOST_SUCCESS:
      return Object.assign({}, state, {
        user: action.user,
        isLoggedIn: true,
        isLoggingOut: false,
      });
    case USER_UPDATE_CURRENT_USER:
      return Object.assign({}, state, {
        user: action.user,
        isLoggedIn: true,
        isLoggingOut: false,
      });
    case USER_UPDATE_CURRENT_USER_SUBSCRIPTION: {
      if (!state.user) return state;
      const newUser = extend({}, state.user.getData(), { subscription: action.sub });
      const newUserClass = new User();
      newUserClass.setData(newUser);
      return Object.assign({}, state, {
        user: newUserClass,
        isLoggedIn: true,
        isLoggingOut: false,
      });
    }
    case LOGOUT_SUCCESS_USER:
      return Object.assign({}, state, {
        user: null,
        isLoggedIn: false,
        isLoggingOut: false,
      });
    case GLOBAL_LOGOUT:
      return Object.assign({}, state, {
        isLoggingOut: true,
        hasSeenOnBoardingVideo: false,
      });
    case HAS_SEEN_ONBOARDING_VIDEO:
      return Object.assign({}, state, { hasSeenOnBoardingVideo: true });
    case SET_USER_EMAIL_TO_AUTO_POPULATE:
      return Object.assign({}, state, { userEmail: action.email });
    case RESET_USER_EMAIL_TO_AUTO_POPULATE:
      return Object.assign({}, state, {
        userEmail: '',
      });
    default:
      return state;
  }
}
