import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SpaceMedium, Regular, Button } from '@properly/components';

import map from 'lodash/map';
import classNames from 'classnames/bind';
import styles from './loginSignUpStyles.css';
import LoginWrapContainer from './LoginWrapContainer';

const cx = classNames.bind(styles);

class SignupCampaignLayout extends Component {
  static propTypes = {
    signUpForm: PropTypes.element.isRequired,
    goToHome: PropTypes.func.isRequired,
    logo: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    list: PropTypes.shape({}).isRequired,
    headLine: PropTypes.string.isRequired,
    subHeadline: PropTypes.string.isRequired,
    infoTitle: PropTypes.string.isRequired,
    pageTitle: PropTypes.string.isRequired,
    pageDes: PropTypes.string.isRequired,
    phoneImage: PropTypes.string.isRequired,
    secondaryBtn: PropTypes.string,
  };

  renderList(isMobile) {
    const str = isMobile ? 'mobile' : 'desktop';
    return (
      <ul className={cx('signup__partnerlist')}>
        {map(this.props.list[str], val => (
          <li key={val[0]}>{val[1]}</li>
        ))}
      </ul>
    );
  }

  render() {
    const {
      isMobile,
      logo: Logo,
      headLine,
      subHeadline,
      signUpForm,
      infoTitle,
      pageTitle,
      pageDes,
      phoneImage,
      secondaryBtn,
      goToHome,
    } = this.props;
    return (
      <div>
        <div
          className={cx('signup__partnerlogo', {
            'signup__partnerlogo--mobile': isMobile,
          })}
        >
          <Logo width="200" height="58" byWidth />
        </div>
        <div
          className={cx('signup__partnerbox-wrap', {
            'signup__partnerbox-wrap--mobile': isMobile,
          })}
        >
          <div
            className={cx('signup__partnerbox', {
              'signup__partnerbox--bottom': isMobile,
            })}
          >
            <SpaceMedium />
            <LoginWrapContainer.Headline withSubHeadline lessSpaceTop title={headLine} />
            <Regular>{subHeadline}</Regular>
            <SpaceMedium />
            {signUpForm}
            <p
              className={cx('bookingcom_is_not_mobile_view', {
                bookingcom_partnerlist_title_mobile: isMobile,
              })}
            >
              {infoTitle}
            </p>
            {isMobile && this.renderList(true)}
          </div>
          <div
            className={cx('signup__partnerbox', {
              'signup__partnerbox--top': isMobile,
            })}
          >
            <p className={cx('bookingcom_title')}>{pageTitle}</p>
            <p className={cx('bookingcom_description')}>{pageDes}</p>
            <img alt="Partner phone" className={cx('signup__partnerimg')} src={phoneImage} />
            {!isMobile && this.renderList(false)}
            {!isMobile && secondaryBtn && (
              <Button onClick={goToHome} types={['type-border-primary', 'size-large', 'minwidth-200']}>
                {secondaryBtn}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SignupCampaignLayout;
