import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import curry from 'lodash/curry';
import lodashResult from 'lodash/result';
import difference from 'lodash/difference';
import values from 'lodash/values';
import { Modal, SpaceBase, Title3, Regular } from '@properly/components';
import { isValidEmail } from '@properly/common';
import t from '@properly/localization';
import { setModal, setGlobalLoadingState } from '../../../actions/globalActions';
import {
  selectGlobalModalResolved,
  selectCurrentUserEmail,
  selectLoadingState,
} from '../../../selectors/globalSelector';
import PermissionModalContent from './PermissionModalContent';
import lazyLoadComponent from '../../../containers/LazyLoadComponent';
import PermissionModalSidebar from './PermissionModalSidebar';
import PermissionModalMain from './PermissionModalMain';
import { handlePermissionActionSaga } from './PermssionsActions';
import Snapshot from '../../../hoc/Snapshot';
import { selectFirstTeam } from './permissionSelector';
import PermissionTeamBoxNew from './PermissionTeamBoxNew';
import { teamLoadingKey } from './PermissionsConstants';
import { mediaUploadLoadingKey } from '../../../dataConstants';

const SnapShotConnected = connect(
  state => ({
    data: selectFirstTeam()(state),
  }),
  null,
  null,
  { pure: false },
)(Snapshot);

const PermissionTeamBoxNewConnected = connect(
  state => ({
    imageUploadErrored: selectLoadingState(mediaUploadLoadingKey)(state)?.isErrored,
  }),
  {
    setGlobalLoadingState,
  },
  null,
  { pure: false },
)(PermissionTeamBoxNew);

function mapExternalToLocal(obj) {
  return obj && obj.toJSON ? obj.toJSON() : obj;
}

function hasDifference(localObj, externalObj) {
  const a = values(localObj);
  const b = values(externalObj);
  return difference(a, b).length > 0;
}

const PermissionModalContentResolved = lazyLoadComponent(PermissionModalContent);
const name = 'permissionmodal';
const name2 = 'permissions_cancelaccess';
const name3 = 'permissions_leaveproperty';
const name4 = 'permissions_company';
const name5 = 'permissions_company_success';
const name6 = 'permissions_company_verifyinfo';
const name7 = 'permissions_company_verifyresult';
const name8 = 'permissions_warningcompanyemailmissing';

class PermissionModal extends PureComponent {
  constructor(props) {
    super(props);
    this.curryedHandle = curry(this.props.handlePermissionActionSaga, 3);
    this.createTeam = this.curryedHandle('createteam');
    this.saveTeam = this.curryedHandle('saveteam');
  }

  close = nameInner => () => this.props.setModal(false, nameInner, {});

  run = (nameFunc, meta, modalName) => e => {
    this.close(modalName)();
    setTimeout(() => {
      this.curryedHandle(nameFunc)(meta)(e);
    }, 200);
  };

  renderLeaveModal() {
    return (
      <Modal height={290} onClose={this.close(name3)} show={!!this.props.leaveData.isOpen}>
        <div>
          <Modal.H1>{t('permissions.modal.leave_property')}</Modal.H1>
          <Modal.Text>{t('permissions.modal.leave_property_info')}</Modal.Text>
          <SpaceBase />
          <Modal.Options>
            <Modal.Cancel onClick={this.close(name3)} />
            <Modal.Ok onClick={this.run('leave_property', this.props.leaveData.meta, name3)} />
          </Modal.Options>
        </div>
      </Modal>
    );
  }

  renderCancelModal() {
    return (
      <Modal onClose={this.close(name2)} height={230} show={!!this.props.cancelAccessData.isOpen}>
        <div>
          <Modal.H1>{t('permissions.modal.cancel_access')}</Modal.H1>
          <Modal.Text>{t('permissions.modal.cancel_access_info')}</Modal.Text>
          <SpaceBase />
          <Modal.Options>
            <Modal.Cancel onClick={this.close(name2)} />
            <Modal.Ok onClick={this.run('revoke_col', this.props.cancelAccessData.meta, name2)} />
          </Modal.Options>
        </div>
      </Modal>
    );
  }

  renderInviteModal() {
    return (
      <Modal type="jobsend" onClose={this.close(name)} show={!!this.props.data.isOpen}>
        <div>
          <PermissionModalContentResolved
            sidebarComponent={PermissionModalSidebar}
            mainComponent={PermissionModalMain}
            onClose={this.close}
            actionHandler={this.curryedHandle}
            startupData={this.props.data.meta}
          />
        </div>
      </Modal>
    );
  }

  renderCompanyModal() {
    return (
      <Modal id="companymodal" onClose={this.close(name4)} height={680} show={!!this.props.companyData.isOpen}>
        <div>
          <SnapShotConnected
            mapExternalToLocal={mapExternalToLocal}
            hasDifference={hasDifference}
            getDefaultValues={this.getDefaultValues}
            isSave={this.isSaveMode}
            render={(getter, setter, hasDiff, dataRaw) => (
              <PermissionTeamBoxNewConnected
                data={dataRaw}
                getter={getter}
                setter={setter}
                isSave={!!getter('objectId')}
                isLoading={this.props.teamLoading.isLoadingFull}
                error={this.props.teamLoading.errorRaw}
                isValid={this.companyValid(dataRaw)}
                createFunc={this.createTeam}
                saveFunc={this.saveTeam}
              />
            )}
          />
        </div>
      </Modal>
    );
  }

  renderCompanySuccess() {
    return (
      <Modal show={!!this.props.companySuccess.isOpen} onClose={this.close(name5)} height={240}>
        <div>
          <Modal.H1 type="">
            <Title3 type="bold">{t('permissions.sent_invite')}</Title3>
          </Modal.H1>
          <Modal.Text>
            <Regular>{t('permissions.send_invite_info')}</Regular>
          </Modal.Text>
          <Modal.Options>
            <Modal.Ok onClick={this.close(name5)} />
          </Modal.Options>
        </div>
      </Modal>
    );
  }

  renderCompanyEmailWarning() {
    return (
      <Modal height={200} show={!!this.props.emailWarning.isOpen} onClose={this.close(name8)}>
        <div>
          <Modal.H1 type="">
            <Title3 type="bold">{t('permissions.modal.enter_a_company_email')}</Title3>
          </Modal.H1>
          <Modal.Text>
            <Regular>{t('permissions.modal.enter_a_company_email_txt')}</Regular>
          </Modal.Text>
          <Modal.Options>
            <Modal.Ok onClick={this.close(name8)} />
          </Modal.Options>
        </div>
      </Modal>
    );
  }

  renderVerifyInfo() {
    const { isOpen, meta } = this.props.verifyInfo;
    return (
      <Modal height={220} show={!!isOpen} onClose={this.close(name6)}>
        <div>
          <Modal.H1 type="">
            <Title3 type="bold">{t('permissions.modal.confirm_your_mail')}</Title3>
          </Modal.H1>
          <Modal.Text>
            <Regular>{t('permissions.modal.confirm_your_mail_txt', { email: lodashResult(meta, 'email') })}</Regular>
          </Modal.Text>
          <Modal.Options>
            <Modal.Ok onClick={this.close(name6)} />
          </Modal.Options>
        </div>
      </Modal>
    );
  }

  mapRes(res) {
    if (res) {
      return {
        headline: t('contacts.congrats'),
        txt: t('permissions.modal.verify_success'),
      };
    }
    return {
      headline: t('permissions.modal.verify_fail_head'),
      txt: t('permissions.modal.verify_fail'),
    };
  }

  renderVerifyResult() {
    const { isOpen, meta } = this.props.verifyResult;
    const res = this.mapRes(lodashResult(meta, 'result'));
    return (
      <Modal height={200} show={!!isOpen} onClose={this.close(name7)}>
        <div>
          <Modal.H1 type="">
            <Title3 type="bold">{res.headline}</Title3>
          </Modal.H1>
          <Modal.Text>
            <Regular>{res.txt}</Regular>
          </Modal.Text>
          <Modal.Options>
            <Modal.Ok onClick={this.close(name7)} />
          </Modal.Options>
        </div>
      </Modal>
    );
  }

  companyValid(data) {
    return !!(data.name && isValidEmail(data.email));
  }

  isSaveMode = dataRaw => !!dataRaw.objectId;

  getDefaultValues = () => ({
    email: this.props.currentUserEmail,
  });

  render() {
    return (
      <div>
        {this.renderCompanyEmailWarning()}
        {this.renderVerifyResult()}
        {this.renderInviteModal()}
        {this.renderVerifyInfo()}
        {this.renderCancelModal()}
        {this.renderLeaveModal()}
        {this.renderCompanyModal()}
        {this.renderCompanySuccess()}
      </div>
    );
  }
}

function mapStateToProps() {
  const memo = selectGlobalModalResolved(name);
  const memo2 = selectGlobalModalResolved(name2);
  const memo3 = selectGlobalModalResolved(name3);
  const memo4 = selectGlobalModalResolved(name4);
  const memo5 = selectGlobalModalResolved(name5);
  const memo6 = selectGlobalModalResolved(name6);
  const memo7 = selectGlobalModalResolved(name7);
  const memo8 = selectGlobalModalResolved(name8);
  const loading = selectLoadingState(teamLoadingKey);
  return (state, props) => ({
    data: memo(state, props),
    cancelAccessData: memo2(state, props),
    leaveData: memo3(state, props),
    companyData: memo4(state, props),
    currentUserEmail: selectCurrentUserEmail()(state),
    teamLoading: loading(state),
    companySuccess: memo5(state),
    verifyInfo: memo6(state),
    verifyResult: memo7(state),
    emailWarning: memo8(state),
  });
}

export default connect(mapStateToProps, {
  setModal,
  handlePermissionActionSaga,
})(PermissionModal);
