import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SearchField, Icon, LoadingSpinner } from '@properly/components';
import t from '@properly/localization';
import { connect } from 'react-redux';
import map from 'lodash/map';
import Sidebar from '../../../components/Sidebar';
import TopLabel from '../../../components/TopLabel';
import { MasterDetailCell } from '../../../components/MasterDetail';
import * as selectorsGlobal from '../../../selectors/globalSelector';
import * as selectors from './ReportsSelectors';
import * as globalActions from '../../../actions/globalActions';
import { goToReports, goToReportDetail } from './ReportsActions';

class ReportsSidebarContainer extends PureComponent {
  get needsSearchBar() {
    return this.props.reportsData.all.length > 2;
  }

  renderReports(reports, activeId) {
    return map(reports, (item, i) => (
      <div data-key-report-id={item.objectId} key={i}>
        <MasterDetailCell
          type="reports"
          noHover
          onClick={() => this.props.goToReportDetail(item.objectId)}
          active={item.objectId === activeId}
          title={item.title}
        />
      </div>
    ));
  }

  render() {
    const { isLoading } = this.props;
    return (
      <Sidebar>
        <TopLabel
          data-key="button_new_report"
          label={t('reports.create_new')}
          onClick={this.props.goToReports}
          icon={Icon.IcPlus}
        />
        <Sidebar.Hr />
        {this.needsSearchBar && (
          <SearchField
            blank
            placeholder={t('reports.search_for_report', {})}
            value={this.props.searchQuery}
            iconLocation="left"
            onChange={value => this.props.setSearchQuery('reports', value)}
          />
        )}
        {this.needsSearchBar && <Sidebar.Hr />}
        <Sidebar.List>
          <Sidebar.ListInner>
            <Sidebar.ListInnerInner>
              {isLoading && <LoadingSpinner />}
              {!isLoading && this.renderReports(this.props.reportsData.filtered, this.props.activeId)}
            </Sidebar.ListInnerInner>
          </Sidebar.ListInner>
        </Sidebar.List>
      </Sidebar>
    );
  }
}

function mapStateToProps(state, props) {
  const reportsData = selectors.selectReportsForSidebar()(state, props);
  const searchQuery = selectorsGlobal.selectSearchQuery('reports')(state, props);
  const isLoading = selectors.selectReportsForSidebarLoading(state, props);

  return {
    isLoading,
    searchQuery,
    reportsData,
  };
}

ReportsSidebarContainer.propTypes = {
  activeId: PropTypes.string,
};

export default connect(
  mapStateToProps,
  {
    setSearchQuery: globalActions.setSearchQuery,
    goToReports,
    goToReportDetail,
  },
)(ReportsSidebarContainer);
