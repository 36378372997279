import React, { PureComponent } from 'react';
import t from '@properly/localization';
import { SearchField, Input, DayRangePicker, START_DATE, END_DATE, RoundSelect, Icon } from '@properly/components';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import keys from 'lodash/keys';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import concat from 'lodash/concat';
import { AutoSizer, List } from 'react-virtualized';
import classNames from 'classnames/bind';
import ReportsBox from '../../../components/ReportsBox';
import Sidebar from '../../../components/Sidebar';
import { makeNameString, rowRenderMapper } from '../../../helper/herbert';
import { MasterDetailCell } from '../../../components/MasterDetail';
import * as selectorsGlobal from '../../../selectors/globalSelector';
import * as selectors from './ReportsSelectors';
import styles from './reportsStyles.module.css';
import {
  setSelectedElementsSection,
  setOpenSection,
  setValueSection,
  setToggleValueSection,
  reportsDateSaga,
  setSelectedAll,
  setIsTouched,
} from './ReportsActions';
import ReportsInput from '../../../components/ReportsInput';
import { mapReportsTableHeads, titleIsValid } from './ReportsMapper';
import { ORDER_COLUMNS, NOT_FOUND } from './ReportsConstants';
import { setSearchQuery } from '../../../actions/globalActions';
import ContactRow from '../contacts/containers/ContactRow';

const cx = classNames.bind(styles);

const ReportsSelectBox = {};

class Column extends PureComponent {
  renderHeader(name, isOpen, onClick) {
    const mappedName = mapReportsTableHeads(name);
    return (
      <div onClick={() => onClick(name)} className={cx('reports__selectb-header')}>
        <div className={cx('reports__selectb-header-inner')}>
          <span>{mappedName}</span>
          {isOpen ? <Icon.IcExpandMore4 /> : <Icon.IcExpandMore3 />}
        </div>
      </div>
    );
  }

  renderElement(name, isSelected, onClick) {
    const mappedName = mapReportsTableHeads(name);
    return (
      <div key={`ele${name}`} className={cx('reports__selectb-ele')}>
        <span>{mappedName}</span>
        <RoundSelect
          data-key="reports_column_checkbox"
          type="quarter"
          small
          selected={isSelected}
          onChange={() => onClick(name)}
        />
        <div className={cx('reports__selectb-ele-divider')} />
      </div>
    );
  }

  toggleHeader = name => {
    this.props.setToggleValueSection(['section', 'columns', 'selectedHeaders', name]);
  };

  toggleElement = name => {
    this.props.setSelectedElementsSection('columns', { [name]: true }, false, true);
  };

  renderElements(structure, selectedHeaders, selectedEles) {
    return map(structure, (value, key) => (
      <div key={`header${key}`}>
        {this.renderHeader(key, selectedHeaders[key], this.toggleHeader)}
        {selectedHeaders[key] && (
          <div>
            {reduce(
              ORDER_COLUMNS,
              (total, keyI) => {
                if (value.children[keyI]) {
                  return concat(total, this.renderElement(keyI, selectedEles[keyI], this.toggleElement));
                }
                return total;
              },
              [],
            )}
          </div>
        )}
      </div>
    ));
  }

  handleToggleOpen = () => {
    this.props.setIsTouched('columns', true);
    this.props.setOpenSection('columns', !this.columnsJS.isOpen);
  };

  get columnsJS() {
    return this.props.columns.toJS();
  }

  get preview() {
    if (!this.props.isTouched) return undefined;
    if (this.isAll) return t('reports.all_columns');
    const mapped = map(this.columnsJS.selected, (value, key) => mapReportsTableHeads(key));
    return mapped.join(', ');
  }

  get isAll() {
    const all = reduce(
      this.columnsJS.states,
      (total, item) => {
        total = concat(total, keys(item.children));
        return total;
      },
      [],
    );
    return all.length === keys(this.columnsJS.selected).length;
  }

  render() {
    return (
      <div className={cx('reports__dropwrap')}>
        <ReportsInput.Dropdown
          data-key="select-columns"
          isOpen={this.columnsJS.isOpen}
          onClick={this.handleToggleOpen}
          input={this.preview}
          title={t('reports.col_selector')}
          placeholder={t('reports.col_selector_placeholder')}
        />
        {this.columnsJS.isOpen && (
          <ReportsBox onClose={this.handleToggleOpen}>
            <div className={cx('reports__selectb-title')}>{t('reports.select_columns')}</div>
            <Sidebar.List style={{ minHeight: 288 }}>
              <Sidebar.ListInner>
                <Sidebar.ListInnerInner>
                  {this.renderElements(this.columnsJS.states, this.columnsJS.selectedHeaders, this.columnsJS.selected)}
                </Sidebar.ListInnerInner>
              </Sidebar.ListInner>
            </Sidebar.List>
          </ReportsBox>
        )}
      </div>
    );
  }
}

class Service extends PureComponent {
  setSearch = val => {
    this.props.setSearchQuery('reports_contacts_search', val);
  };

  handleSingleSelect = objectId => () => {
    this.props.setSelectedAll('contacts', false);
    this.props.setSelectedElementsSection('contacts', { [objectId]: true }, true);
  };

  handleBulkSelect = isSelected => () => {
    this.props.setSelectedAll('contacts', !isSelected);
    this.props.setSelectedElementsSection('contacts', {}, true);
  };

  handleToggleOpen = () => {
    this.props.setIsTouched('contacts', true);
    this.props.setOpenSection('contacts', !this.props.isOpen);
    this.props.setSearchQuery('reports_contacts_search', '');
  };

  get preview() {
    if (!this.props.isTouched) return undefined;
    if (this.isAllSelected) return t('reports.all_service_providers');
    const data = this.isAllSelected
      ? this.props.contacts.all
      : map(this.props.contactsSelected.toJS(), (v, key) => this.props.contacts.reallyAll.get(key));
    const mapped = map(data, contact => {
      if (!contact) return '';
      if (contact.deleted) return `${makeNameString(contact)} (${t('reports.deleted')})`;
      return makeNameString(contact);
    });
    return mapped.join(', ');
  }

  get lengthSelected() {
    return this.props.contactsSelected.size;
  }

  get isAllSelected() {
    return this.lengthSelected === 0 && this.props.isAll;
  }

  isActive = item => this.props.contactsSelected.get(item.objectId) !== undefined || this.isAllSelected;

  render() {
    return (
      <div className={cx('reports__dropwrap')}>
        <ReportsInput.Dropdown
          data-key="service-provider-column-selector"
          isOpen={this.props.isOpen}
          onClick={this.handleToggleOpen}
          input={this.preview}
          title={t('reports.service_provider')}
          placeholder={t('reports.service_provider_placeholder')}
        />
        {this.props.isOpen && (
          <ReportsBox onClose={this.handleToggleOpen} align={this.props.align}>
            <SearchField
              placeholder={t('reports.search_for_service_pro', {})}
              iconLocation="left"
              value={this.props.search || ''}
              onChange={this.setSearch}
            />
            <div className={cx('reports__selectb-ele-divider-full')} />
            <div className={cx('reports__selectb-title', 'reports__selectb-title--low')}>
              {t('reports.all_service_pros')}
            </div>
            <div onClick={() => {}} className={cx('reports__selectb-header')}>
              <div className={cx('reports__selectb-header-inner')}>
                <span>{t('reports.select_all_service_pro')}</span>
                <RoundSelect
                  type="quarter"
                  small
                  selected={this.isAllSelected}
                  onChange={this.handleBulkSelect(this.isAllSelected, this.props.contacts.all)}
                />
              </div>
            </div>
            <div style={{ height: '300px', width: '100%' }}>
              <AutoSizer>
                {({ width }) => (
                  <List
                    height={300}
                    rowHeight={68}
                    width={width}
                    rowCount={this.props.contacts.filtered.length}
                    rowRenderer={rowRenderMapper({
                      data: this.props.contacts.filtered,
                      handler: ContactRow,
                      ctx: {
                        isActive: this.isActive,
                        onClick: contact => this.handleSingleSelect(contact.objectId),
                        currentUserId: this.props.currentUserId,
                      },
                    })}
                  />
                )}
              </AutoSizer>
            </div>
          </ReportsBox>
        )}
      </div>
    );
  }
}

class Property extends PureComponent {
  renderRow({ row, key, style, ctx: { handleSingleSelect, isActive } }) {
    return (
      <MasterDetailCell
        type="property"
        noHover
        key={key}
        style={style}
        onClick={handleSingleSelect(row.objectId)}
        active={isActive(row)}
        title={row.title}
        pretitle={row.address.street}
        subTitle={`${row.address.city}, ${row.address.state}`}
      />
    );
  }

  setSearch = val => {
    this.props.setSearchQuery('reports_properties_search', val);
  };

  handleSingleSelect = objectId => () => {
    this.props.setSelectedAll('properties', false);
    this.props.setSelectedElementsSection('properties', { [objectId]: true }, true);
  };

  handleBulkSelect = isSelected => () => {
    this.props.setSelectedAll('properties', !isSelected);
    this.props.setSelectedElementsSection('properties', {}, true);
  };

  handleToggleOpen = () => {
    this.props.setIsTouched('properties', true);
    this.props.setOpenSection('properties', !this.props.isOpen);
    this.props.setSearchQuery('reports_properties_search', '');
  };

  get preview() {
    if (!this.props.isTouched) return undefined;
    if (this.isAllSelected) return t('reports.all_properties');
    const data = this.isAllSelected
      ? this.props.contacts.all
      : map(this.props.contactsSelected.toJS(), (v, key) => this.props.contacts.reallyAll.get(key));
    const mapped = map(data, contact => {
      if (!contact) return NOT_FOUND;
      if (contact.deleted) return `${contact.title} (${t('reports.deleted')})`;
      return contact.title;
    });
    return mapped.join(', ');
  }

  get lengthSelected() {
    return this.props.contactsSelected.size;
  }

  get isAllSelected() {
    return this.lengthSelected === 0 && this.props.isAll;
  }

  isActive = item => this.props.contactsSelected.get(item.objectId) !== undefined || this.isAllSelected;

  render() {
    return (
      <div className={cx('reports__dropwrap')}>
        <ReportsInput.Dropdown
          data-key="property-column-selector"
          isOpen={this.props.isOpen}
          onClick={this.handleToggleOpen}
          input={this.preview}
          title={t('reports.property')}
          placeholder={t('reports.property_placeholder')}
        />
        {this.props.isOpen && (
          <ReportsBox onClose={this.handleToggleOpen}>
            <SearchField
              placeholder={t('reports.search_for_address', {})}
              iconLocation="left"
              value={this.props.search || ''}
              onChange={this.setSearch}
            />
            <div className={cx('reports__selectb-ele-divider-full')} />
            <div className={cx('reports__selectb-title', 'reports__selectb-title--low')}>
              {t('reports.all_properties')}
            </div>
            <div onClick={() => {}} className={cx('reports__selectb-header')}>
              <div className={cx('reports__selectb-header-inner')}>
                <span>{t('reports.select_all_properties')}</span>
                <RoundSelect
                  type="quarter"
                  small
                  selected={this.isAllSelected}
                  onChange={this.handleBulkSelect(this.isAllSelected, this.props.contacts.all)}
                />
              </div>
            </div>
            <div style={{ height: '300px', width: '100%' }}>
              <AutoSizer>
                {({ width }) => (
                  <List
                    height={300}
                    rowHeight={68}
                    width={width}
                    rowCount={this.props.contacts.filtered.length}
                    rowRenderer={rowRenderMapper({
                      data: this.props.contacts.filtered,
                      handler: this.renderRow,
                      ctx: { isActive: this.isActive, handleSingleSelect: this.handleSingleSelect },
                    })}
                  />
                )}
              </AutoSizer>
            </div>
          </ReportsBox>
        )}
        {this.preview === NOT_FOUND && <Input.ErrorMsg>{t('reports.no_access_to_property')}</Input.ErrorMsg>}
      </div>
    );
  }
}

class Status extends PureComponent {
  mapMe(name) {
    return t(`reports.states.${name}`);
  }

  handleToggleOpen = () => {
    this.props.setIsTouched('status', true);
    this.props.setOpenSection('status', !this.statusJS.isOpen);
  };

  handleSingleSelect = objectId => () => {
    this.props.setSelectedElementsSection('status', { [objectId]: true }, true);
  };

  handleBulkSelect = (isSelected, all) => () => {
    const out = !isSelected ? all : {};
    this.props.setSelectedElementsSection('status', out, true);
  };

  get statusJS() {
    return this.props.status.toJS();
  }

  get preview() {
    if (!this.props.isTouched) return undefined;
    if (this.isAllSelected) return t('reports.all_status');
    const mapped = map(this.statusJS.selected, (value, key) => this.mapMe(key));
    return mapped.join(', ');
  }

  get lengthSelected() {
    return this.props.status.get('selected').size;
  }

  get isAllSelected() {
    return this.lengthSelected === this.props.status.get('states').size;
  }

  renderStates(states, selected) {
    return map(states, (value, key) => {
      const mappedName = this.mapMe(key);
      return (
        <MasterDetailCell
          type="status"
          noHover
          key={`status${key}`}
          onClick={this.handleSingleSelect(key)}
          active={!!selected[key]}
          title={mappedName}
        />
      );
    });
  }

  render() {
    return (
      <div className={cx('reports__dropwrap')}>
        <ReportsInput.Dropdown
          data-key="status-column-selector"
          isOpen={this.statusJS.isOpen}
          onClick={this.handleToggleOpen}
          input={this.preview}
          title={t('reports.status')}
          placeholder={t('reports.status_placeholder')}
        />
        {this.statusJS.isOpen && (
          <ReportsBox onClose={this.handleToggleOpen}>
            <div className={cx('reports__selectb-title')}>{t('reports.select_status')}</div>
            <div onClick={() => {}} className={cx('reports__selectb-header')}>
              <div className={cx('reports__selectb-header-inner')}>
                <span>{t('reports.select_all_status')}</span>
                <RoundSelect
                  type="quarter"
                  small
                  selected={this.isAllSelected}
                  onChange={this.handleBulkSelect(this.isAllSelected, this.statusJS.states)}
                />
              </div>
            </div>
            <Sidebar.List style={{ minHeight: 238 }}>
              <Sidebar.ListInner>
                <Sidebar.ListInnerInner>
                  {this.renderStates(this.statusJS.states, this.statusJS.selected)}
                </Sidebar.ListInnerInner>
              </Sidebar.ListInner>
            </Sidebar.List>
          </ReportsBox>
        )}
      </div>
    );
  }
}

class JobTypes extends PureComponent {
  mapMe(name) {
    return t(`reports.jobTypes.${name}`);
  }

  handleToggleOpen = () => {
    this.props.setIsTouched('jobTypes', true);
    this.props.setOpenSection('jobTypes', !this.jobTypesJS.isOpen);
  };

  handleSingleSelect = objectId => () => {
    this.props.setSelectedElementsSection('jobTypes', { [objectId]: true }, true);
  };

  handleBulkSelect = (isSelected, all) => () => {
    const out = !isSelected ? all : {};
    this.props.setSelectedElementsSection('jobTypes', out, true);
  };

  get jobTypesJS() {
    return this.props.jobTypes.toJS();
  }

  get preview() {
    if (!this.props.isTouched) return undefined;
    if (this.isAllSelected) return t('reports.all_types');
    const mapped = map(this.jobTypesJS.selected, (value, key) => this.mapMe(key));
    return mapped.join(', ');
  }

  get lengthSelected() {
    return this.props.jobTypes.get('selected').size;
  }

  get isAllSelected() {
    return this.lengthSelected === this.props.jobTypes.get('types').size;
  }

  renderJobTypes(jobTypes, selected) {
    return map(jobTypes, (value, key) => {
      const mappedName = this.mapMe(key);
      return (
        <MasterDetailCell
          type="status"
          noHover
          key={`jobtype${key}`}
          onClick={this.handleSingleSelect(key)}
          active={!!selected[key]}
          title={mappedName}
        />
      );
    });
  }

  render() {
    return (
      <div className={cx('reports__dropwrap')}>
        <ReportsInput.Dropdown
          data-key="status-column-selector"
          isOpen={this.jobTypesJS.isOpen}
          onClick={this.handleToggleOpen}
          input={this.preview}
          title={t('reports.job_types')}
          placeholder={t('reports.job_type_placeholder')}
        />
        {this.jobTypesJS.isOpen && (
          <ReportsBox onClose={this.handleToggleOpen}>
            <div className={cx('reports__selectb-title')}>{t('reports.select_job_type')}</div>
            <div onClick={() => {}} className={cx('reports__selectb-header')}>
              <div className={cx('reports__selectb-header-inner')}>
                <span>{t('reports.select_all_job_types')}</span>
                <RoundSelect
                  type="quarter"
                  small
                  selected={this.isAllSelected}
                  onChange={this.handleBulkSelect(this.isAllSelected, this.jobTypesJS.types)}
                />
              </div>
            </div>
            <Sidebar.List style={{ minHeight: 238 }}>
              <Sidebar.ListInner>
                <Sidebar.ListInnerInner>
                  {this.renderJobTypes(this.jobTypesJS.types, this.jobTypesJS.selected)}
                </Sidebar.ListInnerInner>
              </Sidebar.ListInner>
            </Sidebar.List>
          </ReportsBox>
        )}
      </div>
    );
  }
}

class Date extends PureComponent {
  handleToggleOpen = () => {
    if (!this.dateJS.start || !this.dateJS.end) {
      this.props.reportsDateSaga('thisweek');
    }
    this.props.setOpenSection('date', !this.dateJS.isOpen);
  };

  mapMe(name) {
    return t(`reports.dates.${name}`);
  }

  toggle = res => {
    const push = {};
    if (res.startDate) {
      push.start = res.startDate;
      push.end = null;
    }
    if (res.endDate) {
      push.end = res.endDate;
    }
    this.props.reportsDateSaga('custom', { ...push });
  };

  renderHeaderEles(eles) {
    return map(eles, (val, key) => {
      const mappedName = this.mapMe(key);
      return (
        <li
          key={`dates${key}`}
          data-key={`dates${key}`}
          onClick={this.handleChange(key)}
          className={cx('reports__dates-header-inner-ele', {
            'reports__dates-header-inner-ele--active': this.isActive(key),
          })}
        >
          {mappedName}
        </li>
      );
    });
  }

  handleChange = where => () => {
    this.props.reportsDateSaga(where);
  };

  get dateJS() {
    return this.props.date.toJS();
  }

  get elesWithOverlay() {
    return {
      thisweek: true,
      thismonth: true,
      lastweek: true,
      lastmonth: true,
    };
  }

  get eles() {
    return {
      thisweek: true,
      thismonth: true,
      lastweek: true,
      lastmonth: true,
      custom: true,
    };
  }

  isActive(what) {
    return this.dateJS.active === what;
  }

  get preview() {
    if (!this.dateJS.start || !this.dateJS.end) return '';
    return `${moment(this.dateJS.start).format('L')} - ${moment(this.dateJS.end).format('L')}`;
  }

  get mode() {
    if (!this.dateJS.end && this.dateJS.start) return END_DATE;
    return START_DATE;
  }

  render() {
    return (
      <div className={cx('reports__dropwrap')}>
        <ReportsInput.Dropdown
          data-key="date-selector"
          input={this.preview}
          title={t('reports.date_range')}
          placeholder={t('reports.date_range_placeholder')}
          isOpen={this.dateJS.isOpen}
          onClick={this.handleToggleOpen}
        />
        {this.dateJS.isOpen && (
          <ReportsBox isBig onClose={this.handleToggleOpen}>
            <div className={cx('reports__dates-header')}>
              <ul className={cx('reports__dates-header-inner')}>{this.renderHeaderEles(this.eles)}</ul>
            </div>
            <div className={cx('reports__dateswrap')}>
              <DayRangePicker
                key={this.dateJS.start}
                minimumNights={0}
                isOutsideRange={() => false}
                focusedInput={this.mode}
                numberOfMonths={1}
                initialVisibleMonth={() => moment(this.dateJS.start)}
                startDate={this.dateJS.start}
                endDate={this.dateJS.end}
                onDatesChange={this.toggle}
              />
              {this.elesWithOverlay[this.dateJS.active] && <div className={cx('reports__dateswrap-overlay')} />}
            </div>
          </ReportsBox>
        )}
      </div>
    );
  }
}

class Problem extends PureComponent {
  handleToggleOpen = () => {
    this.props.setOpenSection('problems', !this.problemsJS.isOpen);
  };

  setValue = val => () => {
    this.props.setValueSection('problems', val);
  };

  get problemsJS() {
    return this.props.problems.toJS();
  }

  get preview() {
    return this.problemsJS.value ? t('reports.problems_reported') : t('reports.none');
  }

  render() {
    return (
      <div className={cx('reports__dropwrap')}>
        <ReportsInput.Dropdown
          data-key="problems-reported-column-selector"
          isOpen={this.problemsJS.isOpen}
          onClick={this.handleToggleOpen}
          input={this.preview}
          title={t('reports.problems_reported')}
          placeholder={t('reports.none')}
        />
        {this.problemsJS.isOpen && (
          <ReportsBox onClose={this.handleToggleOpen}>
            <div className={cx('reports__selectb-title', 'reports__selectb-title--full')}>
              {t('reports.problems_reported')}
            </div>
            <Sidebar.List style={{ minHeight: 140 }}>
              <Sidebar.ListInner>
                <Sidebar.ListInnerInner>
                  <MasterDetailCell
                    type="status"
                    noHover
                    onClick={this.setValue(false)}
                    active={!this.problemsJS.value}
                    title={t('reports.none')}
                  />
                  <MasterDetailCell
                    type="status"
                    noHover
                    onClick={this.setValue(true)}
                    active={this.problemsJS.value}
                    title={t('reports.problems_reported_placeholder')}
                  />
                </Sidebar.ListInnerInner>
              </Sidebar.ListInner>
            </Sidebar.List>
          </ReportsBox>
        )}
      </div>
    );
  }
}

class Title extends PureComponent {
  setValue = e => {
    this.props.setValueSection('title', e.target.value);
  };

  get titleJS() {
    return this.props.title.toJS();
  }

  get showValidation() {
    return !titleIsValid(this.titleJS.value) && this.titleJS.showValidation;
  }

  render() {
    return (
      <ReportsInput
        title={t('reports.title')}
        onChange={this.setValue}
        error={this.showValidation}
        value={this.titleJS.value}
        placeholder={t('reports.title_placeholder')}
      />
    );
  }
}

function mapStateToProps(state, props) {
  return {
    search: selectors.selectContactsSearchQuery(state),
    contacts: selectors.selectContacts()(state, props),
    contactsSelected: selectors.selectReportsSectionSelected('contacts')(state, props),
    isOpen: selectors.selectReportsSectionIsOpen('contacts')(state, props),
    isAll: selectors.selectReportsSectionIsAll('contacts')(state, props),
    isTouched: selectors.selectReportsSectionIsTouched('contacts')(state, props),
    currentUserId: selectorsGlobal.selectCurrentUserId()(state, props),
  };
}

function mapStateToPropsProperty(state, props) {
  return {
    search: selectors.selectPropertiesSearchQuery(state),
    contacts: selectors.selectProperties()(state, props),
    contactsSelected: selectors.selectReportsSectionSelected('properties')(state, props),
    isOpen: selectors.selectReportsSectionIsOpen('properties')(state, props),
    isAll: selectors.selectReportsSectionIsAll('properties')(state, props),
    isTouched: selectors.selectReportsSectionIsTouched('properties')(state, props),
  };
}

function mapStateToPropsProblem(state, props) {
  return {
    problems: selectors.selectReportsSection('problems')(state, props),
  };
}

function mapStateToPropsTitle(state, props) {
  return {
    title: selectors.selectReportsSection('title')(state, props),
  };
}

function mapStateToPropsStatus(state, props) {
  return {
    status: selectors.selectReportsSection('status')(state, props),
    isTouched: selectors.selectReportsSectionIsTouched('status')(state, props),
  };
}

function mapStateToPropsJobTypes(state, props) {
  return {
    jobTypes: selectors.selectReportsSection('jobTypes')(state, props),
    isTouched: selectors.selectReportsSectionIsTouched('jobTypes')(state, props),
  };
}

function mapStateToPropsColumn(state, props) {
  return {
    columns: selectors.selectReportsSection('columns')(state, props),
    isTouched: selectors.selectReportsSectionIsTouched('columns')(state, props),
  };
}

function mapStateToPropsDate(state, props) {
  return {
    date: selectors.selectReportsSection('date')(state, props),
  };
}

ReportsSelectBox.Service = connect(mapStateToProps, {
  setSearchQuery,
  setSelectedElementsSection,
  setOpenSection,
  setSelectedAll,
  setIsTouched,
})(Service);

ReportsSelectBox.Property = connect(mapStateToPropsProperty, {
  setSearchQuery,
  setSelectedElementsSection,
  setOpenSection,
  setSelectedAll,
  setIsTouched,
})(Property);

ReportsSelectBox.Status = connect(mapStateToPropsStatus, {
  setOpenSection,
  setSelectedElementsSection,
  setIsTouched,
})(Status);

ReportsSelectBox.JobTypes = connect(mapStateToPropsJobTypes, {
  setOpenSection,
  setSelectedElementsSection,
  setIsTouched,
})(JobTypes);

ReportsSelectBox.Column = connect(mapStateToPropsColumn, {
  setOpenSection,
  setSelectedElementsSection,
  setToggleValueSection,
  setIsTouched,
})(Column);

ReportsSelectBox.Date = connect(mapStateToPropsDate, {
  setOpenSection,
  reportsDateSaga,
})(Date);

ReportsSelectBox.Problem = connect(mapStateToPropsProblem, {
  setValueSection,
  setOpenSection,
})(Problem);

ReportsSelectBox.Title = connect(mapStateToPropsTitle, {
  setValueSection,
})(Title);

export default ReportsSelectBox;
