import React from 'react';
import PlaidLink from 'react-plaid-link';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { getConfigSync } from '@properly/config';
import { Icon, Pill } from '@properly/components';
import t from '@properly/localization';
import colors from '@properly/components/lib/common/styles/colors.module.css';

const STEP_0 = 0;
const STEP_1 = 1;
const STEP_2 = 2;

const config = getConfigSync();

const PillWithContentAndStyles = withStyles(() => ({
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    display: 'flex',
    marginLeft: '-.5em',
    padding: '0 .5em',
  },
  divider__content: {
    width: '100%',
    textAlign: 'center',
  },
  text: {
    display: 'flex',
  },
  text__content: {
    padding: '0 0 0 0.25em',
  },
}))(props => {
  const { key, text, icon, classes } = props;

  let iconToRender;

  switch (icon) {
    case 'alert':
      iconToRender = <Icon.AlertRed width="1em" byWidth />;
      break;
    case 'success':
      iconToRender = <Icon.IcActive width="1em" byWidth />;
      break;
    default:
      break;
  }

  return (
    <Pill key={key} style={{ margin: '5px' }}>
      <div className={classes.content}>
        {iconToRender}
        <span className={classes.text}>
          <span className={classes.text__content}>{text}</span>
        </span>
      </div>
    </Pill>
  );
});

function Steps(props, prevState, error, onPlaidSuccess, onPlaidExit) {
  const { useMarketplace } = props;
  const PlaidLinkWithStyles = withStyles(() => ({
    button: {
      // button
      border: '1px solid transparent !important',
      cursor: 'pointer',
      transition: '150ms background-color ease, 150ms border ease, 150ms border-color ease, 150ms color ease',
      // button--size-medium
      height: '36px',
      minWidth: '90px',
      paddingLeft: '16px !important',
      paddingRight: '16px !important',
      // button--type-full-orange
      backgroundColor: `${colors['color-orange']} !important`,
      color: colors['color-white'],
    },
    buttonHover: {
      '&:hover': {
        backgroundColor: `${colors['color-orange-hover']} !important`,
      },
    },
  }))(({ classes }) => (
    <PlaidLink
      clientName={config.PLAID_CLIENT_NAME}
      env={config.PLAID_ENV}
      countryCodes={['US']}
      product={['auth', 'transactions']}
      publicKey={config.PLAID_PUBLIC_KEY}
      onExit={onPlaidExit}
      onSuccess={onPlaidSuccess}
      className={classNames(classes.button, classes.buttonHover, 'text-l6m')}
    >
      Verify your bank account
    </PlaidLink>
  ));

  switch (true) {
    case useMarketplace:
      return [
        {
          stepNumber: STEP_0, // agree terms
          label: <Typography variant="h6">{t('job_request.wizard.payment_method')}</Typography>,
          content: t('job_request.wizard.payment_method_blurb'),
          showActionsLeft: true,
          nextLabel: t('job_request.wizard.next'),
        },
        {
          stepNumber: STEP_1, // enter details
          label: <Typography variant="h6">{t('job_request.wizard.bank_account_details')}</Typography>,
          content: (
            <>
              <p>
                {'Properly use '}
                <a
                  className="text-l6m"
                  style={{ color: colors['color-orange'], textDecoration: 'none' }}
                  href="https://plaid.com/overview-privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Plaid
                </a>
                {' to verify your bank account details.'}
                <br />
                Click the button below to start the process.
              </p>
              <PlaidLinkWithStyles />
            </>
          ),
          showActionsLeft: false,
          nextLabel: '',
          async onStepHandler(currentProps, state) {
            const { plaidPublicToken, plaidAccountId } = state;
            return Promise.resolve({ stepNumber: STEP_1, plaidPublicToken, plaidAccountId });
          },
        },
        {
          stepNumber: STEP_2, // success or fail
          label: error ? (
            <PillWithContentAndStyles text={t('job_request.wizard.inactive')} icon="alert" />
          ) : (
            <PillWithContentAndStyles text={t('job_request.wizard.active')} icon="success" />
          ),
          content: (
            <Typography style={{ color: colors['color-grey'] }} variant="h6">
              {error ? error.message : t('job_request.wizard.success')}
            </Typography>
          ),
          showActionsLeft: false,
          nextLabel: t('job_request.wizard.next'),
        },
      ];

    default:
      break;
  }
}

export default Steps;
