import React from 'react';
import lodashResult from 'lodash/result';
import invert from 'lodash/invert';
import chunk from 'lodash/chunk';
import map from 'lodash/map';
import t from '@properly/localization';

import {
  Regular,
  TabBar,
  SearchField,
  SpaceLarge,
  LoadingSplash,
  Icon,
  PopoverDefault,
  ButtonList,
  ModalError,
} from '@properly/components';
import CleanerListTags from '../../../../components/CleanerListTags/index';
import InfoMessage from '../../../../components/InfoMessage/index';
import Sidebar from '../../../../components/Sidebar/index';

import {
  JobRequestTextMain,
  JobRequestPropertyInfo,
  JobRequestCheckableHover,
  JobRequestContentWrap,
  JobRequestSpacerWrap,
  JobRequestIcon,
  JobRequestTextColor,
  JobRequestCheckableBox,
} from '../../../../components/JobRequestElements/index';
import { genChecklistSubtitle } from '../../../../helper/herbert';
import ChecklistOverlay from '../../../../components/ChecklistOverlay/index';
import TaskFormOverlay from '../../../../components/TaskFormOverlay';
import { mediaUploadLoadingKey } from '../../../../dataConstants';

const Checklists = ({
  finalPropertyId,
  finalChecklistIds,
  selectChecklist,
  selectChecklistViewType,
  isPropertiesActive,
  isTaskformActive,

  loadingStateChecklist,
  searchQuery,
  checklists,
  taskformlist,
  tempTaskImages,
  setSearchQuery,
  createChecklistSaga,
  finalPropertyResolved,
  setModalObj,
  handleClickSaga,
  infoTextMsg,
  onClose,
  changeTaskTitle,
  changeTaskDescription,
  taskTitleRedux,
  taskDescription,
  imageUploadErrored,
  setGlobalLoadingState,
}) => {
  const isLoading = loadingStateChecklist;
  const onEdit = ({ objectId, jobInstructionId, jobInstructions, propertyIndependent, published }) => e => {
    onClose();
    e.stopPropagation();
    e.preventDefault();
    setModalObj({
      isOpen: true,
      meta: {
        checklistId: objectId,
        jobInstructionId: jobInstructionId || jobInstructions.objectId,
        isIndependent: propertyIndependent && !published,
      },
    });
  };

  // eslint-disable-next-line
  const onCreate = () => {
    createChecklistSaga({
      role: lodashResult(finalPropertyResolved, ['role']),
      user: lodashResult(finalPropertyResolved, ['userId']),
      propertyId: lodashResult(finalPropertyResolved, ['objectId']),
      title: t('checklist.checklist_untitled'),
      openInModal: true,
    });
  };

  const handleFormClick = event => {
    handleClickSaga(false, {
      action: 'addTaskForm',
      addTaskForm: event,
    });
  };

  const handleFormCancel = event => {
    handleClickSaga(false, {
      action: 'cancelTaskForm',
      cancelTaskForm: event,
    });
  };

  const handleTaskPicUpload = event => {
    handleClickSaga(false, {
      action: 'addTaskPic',
      addTaskPic: event,
    });
  };

  const handleOnDelete = (title, description) => {
    handleClickSaga(false, {
      action: 'deleteTaskForm',
      deleteTaskTitle: title,
      deleteTaskDescription: description,
    });
  };

  const handleOnCloseErrorModal = () => {
    setGlobalLoadingState(mediaUploadLoadingKey);
  };

  const needToSelectProperty = !finalPropertyId && isPropertiesActive && !isTaskformActive;
  const selected = invert(finalChecklistIds.toJS());

  return (
    <>
      <JobRequestContentWrap id="job-request-task-checklist-form">
        <JobRequestContentWrap id="job-request-checklist-form">
          <JobRequestSpacerWrap left="24px" top="12px" bottom="12px" right="24px">
            <TabBar>
              <TabBar.Item
                data-key="button_section_task_form"
                onClick={() => selectChecklistViewType('taskform')}
                active={!isPropertiesActive && isTaskformActive}
              >
                {t('job_request.add_extra_task')}
              </TabBar.Item>
              <TabBar.Item
                data-key="button_section_checklist_properties"
                onClick={() => selectChecklistViewType('properties')}
                active={isPropertiesActive && !isTaskformActive}
              >
                {t('titles.property_checklists')}
              </TabBar.Item>
              <TabBar.Item
                data-key="button_section_checklist_library"
                onClick={() => selectChecklistViewType('library')}
                active={!isPropertiesActive && !isTaskformActive}
              >
                {t('titles.library')}
              </TabBar.Item>
            </TabBar>
          </JobRequestSpacerWrap>
          <JobRequestSpacerWrap left="24px" top="12px" bottom="12px" right="24px">
            {!needToSelectProperty && !isTaskformActive && (
              <SearchField
                onGrey
                data-key="jobrequestsend_search_checklist"
                placeholder={t('job_request.search_checklist')}
                value={searchQuery}
                iconLocation="left"
                onChange={value => setSearchQuery('jobrequest_searchchecklist', value)}
              />
            )}
            {!needToSelectProperty && isTaskformActive && (
              <div>
                <ChecklistOverlay.GridRow>
                  <ChecklistOverlay.GridItem itemPerRow="1" spacing="12">
                    <TaskFormOverlay.Header count={taskformlist.length} />
                  </ChecklistOverlay.GridItem>
                </ChecklistOverlay.GridRow>
              </div>
            )}
          </JobRequestSpacerWrap>
          <Sidebar.List>
            <Sidebar.ListInner>
              <Sidebar.ListInnerInner enableOverflow>
                <JobRequestSpacerWrap left="24px" top="0px" bottom="12px" right="24px">
                  {isLoading && (
                    <div>
                      <SpaceLarge />
                      <LoadingSplash static />
                      <SpaceLarge />
                    </div>
                  )}
                  {!isLoading && needToSelectProperty && <Regular>{t('job_request.select_property')}</Regular>}
                  {/* Changed here for maintenance loop */}
                  {!isLoading &&
                    isTaskformActive &&
                    taskformlist &&
                    taskformlist.map(taskform => (
                      <TaskFormOverlay.Item task={taskform} key={taskform.title} onDelete={handleOnDelete} />
                    ))}

                  {/* form list here */}
                  {!isLoading && isTaskformActive && (
                    <ChecklistOverlay.GridRow>
                      <ChecklistOverlay.GridItem itemPerRow="1" spacing="12">
                        <TaskFormOverlay.TaskForm
                          active={taskformlist.length === 0}
                          onSubmitForm={handleFormClick}
                          onCancelForm={handleFormCancel}
                          onUploadTaskPic={handleTaskPicUpload}
                          tempTaskImages={tempTaskImages}
                          changeTaskTitle={changeTaskTitle}
                          changeTaskDescription={changeTaskDescription}
                          taskTitle={taskTitleRedux}
                          taskDescription={taskDescription}
                          imageUploadErrored={imageUploadErrored}
                        />
                      </ChecklistOverlay.GridItem>
                    </ChecklistOverlay.GridRow>
                  )}
                  {/* --------- */}
                  {!isLoading &&
                    !isTaskformActive &&
                    map(chunk(checklists, 2), (row, iRow) => (
                      <ChecklistOverlay.GridRow key={iRow} spacing="12">
                        {map(row, (checklist, key) => {
                          if (checklist.totalTaskCount === 0) {
                            return null;
                          }

                          const isActive = selected[checklist.objectId] !== undefined;
                          return (
                            <ChecklistOverlay.GridItem key={key} spacing="12" itemPerRow="2">
                              <JobRequestCheckableHover
                                number={Number(selected[checklist.objectId]) + 1}
                                data-key={`jobrequestsend_checklistitem-${checklist.objectId}`}
                                onChange={() => selectChecklist(checklist.objectId)}
                                isActive={isActive}
                              >
                                <JobRequestPropertyInfo
                                  picType="custom"
                                  order={['pic', 'text']}
                                  txtMod={{ withpic: true }}
                                  l1={checklist.title}
                                  l2={genChecklistSubtitle(checklist)}
                                  l3={
                                    !checklist.published && (
                                      <JobRequestTextColor color="orange" isInline onClick={onEdit(checklist)}>
                                        {t('job_request.edit_checklist')}
                                      </JobRequestTextColor>
                                    )
                                  }
                                  pic={<JobRequestIcon icon={isActive ? Icon.IcBigJobsOrange : Icon.IcBigJobs} />}
                                />
                              </JobRequestCheckableHover>
                            </ChecklistOverlay.GridItem>
                          );
                        })}
                      </ChecklistOverlay.GridRow>
                    ))}
                </JobRequestSpacerWrap>
              </Sidebar.ListInnerInner>
            </Sidebar.ListInner>
          </Sidebar.List>
          <InfoMessage text={infoTextMsg} isPrompt isVcenter isHcenter icon="light" color="white" />
        </JobRequestContentWrap>
      </JobRequestContentWrap>

      {/* If task image upload fails */}
      <ModalError.ImageUpload showUploadError={imageUploadErrored} onCloseUploadError={handleOnCloseErrorModal} />
    </>
  );
};

const mapLeftOverChecklists = leftOverChecklists =>
  leftOverChecklists
    .filter(checklist => checklist)
    .map((checklist, index) => ({
      'data-key': checklist.objectId,
      name: checklist.title,
      secondaryName: genChecklistSubtitle(checklist),
      icon: <JobRequestCheckableBox isActive number={parseInt(index, 10) + 2} />,
    }));

const seeMoreChecklistsContent = leftOverChecklists => (
  <ButtonList isButton={false} hasDivider isDenseStyle items={mapLeftOverChecklists(leftOverChecklists)} />
);

function wrapperSeeMoreChecklists({ children, leftOverCleaners: leftOverChecklists }) {
  return (
    <PopoverDefault
      popoverKey="viewSelectedChecklists"
      width={240}
      hasArrow
      popoverContent={() => seeMoreChecklistsContent(leftOverChecklists)}
    >
      <span>{children}</span>
    </PopoverDefault>
  );
}

const ChecklistsContent = ({ checklistTitle, taskTitle, finalChecklistIds, allChecklists }) => (
  <div>
    <CleanerListTags
      type="simple"
      limit={1}
      wrapperComponent={wrapperSeeMoreChecklists}
      items={finalChecklistIds.map(checklistId => allChecklists.get(checklistId))}
    >
      <JobRequestTextMain key={`title${checklistTitle}`} cutText>
        {checklistTitle}
      </JobRequestTextMain>
      <JobRequestTextMain key={`title${taskTitle}`} cutText>
        {taskTitle}
      </JobRequestTextMain>
    </CleanerListTags>
  </div>
);

Checklists.content = ChecklistsContent;

export default Checklists;
