import { fromJS } from 'immutable';
import * as types from '../../../types';

const submitData = {
  type: {
    type: undefined,
  },
  email: {
    email: undefined,
    error: undefined,
  },
  collType: {
    type: undefined,
    disabled: [],
  },
  permissions: {
    type: undefined,
    permissions: {},
    disabled: [],
  },
  properties: {
    type: undefined,
    properties: {},
  },
};

const initState = fromJS({
  listdata: {
    sharedWithMe: {},
    infomessage: {},
    activeSection: undefined,
    modalMeta: {
      mode: undefined,
      meta: {},
    },
  },
  submitData,
});

export default function PermissionReducer(state = initState, action = {}) {
  switch (action.type) {
    case types.GLOBAL_LOGOUT: // on logout reset
      return initState;
    case types.PERMISSION_MERGE_LIST_DATA:
      return state.mergeIn(['listdata', action.name], fromJS(action.data));
    case types.PERMISSION_SET_LIST_DATA:
      return state.setIn(['listdata', action.name], fromJS(action.data));
    case types.PERMISSION_SET_SUBMIT_DATA:
      return state.mergeIn(['submitData'], fromJS(action.data));
    case types.PERMISSION_RESET_SUBMIT_DATA:
      return state.mergeIn(['submitData'], fromJS(submitData));
    default:
      return state;
  }
}
