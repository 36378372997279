import forOwn from 'lodash/forOwn';

export default class UserData {
  constructor(
    objectId,
    firstName,
    lastName,
    shortName,
    email,
    phone,
    pictureUrl,
    bio,
    signedUp,
    signedUpDate,
    phoneCountryCode,
    phoneRegionalNumber,
  ) {
    this.objectId = objectId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.shortName = shortName;
    this.email = email;
    this.phone = phone;
    this.pictureUrl = pictureUrl;
    this.bio = bio;
    this.signedUp = signedUp;
    this.signedUpDate = signedUpDate;
    this.phoneCountryCode = phoneCountryCode;
    this.phoneRegionalNumber = phoneRegionalNumber;
  }

  setData(obj) {
    this.getOwnProps(obj, this);
  }

  getData() {
    return this.getOwnProps(this);
  }

  getOwnProps(scope, onScope) {
    const obj = onScope || {};
    forOwn(scope, (value, key) => {
      obj[key] = value;
    });
    return obj;
  }

  getShortName() {
    return this.shortName;
  }

  getFullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  getId() {
    return this.objectId;
  }

  getPicture() {
    return this.pictureUrl;
  }

  getInitials() {
    let initials = '';
    if (this.firstName) {
      initials += this.firstName.charAt(0);
    }
    if (this.lastName) {
      initials += this.lastName.charAt(0);
    }
    return initials;
  }
}
