import { trackEvent } from './trackingService';
import { sendEventToGoogleAdwords, sendPageToGoogleAnalytics } from '../config/googleAdwords';

export const trackLoginComplete = () => {
  trackEvent('Log In Complete');
};

export const trackToggleMode = role => {
  trackEvent('Toggle Mode', { role });
};

export const trackCalendarSearchQuery = query => {
  trackEvent('Calendar search query', { query });
};

export const trackCalendarFilter = filter => {
  trackEvent('Calendar filter', { filter });
};

export const trackCalendarFilterSave = (filterName, filters) => {
  trackEvent('Calendar filter save', { filterName, filters });
};

export const trackCalendarCancelToggle = () => {
  trackEvent('Calendar cancel toggle');
};

export const trackMergePropOpen = () => {
  trackEvent('Merge Properties Open');
};

export const trackPropertyTagChanged = (change, propertyId) => {
  trackEvent('Property tag change', { change, propertyId });
};

export const trackContactTagChanged = (change, contactId) => {
  trackEvent('Contact tag change', { change, contactId });
};

export const trackMergeAction = answer => {
  trackEvent('Property Duplicate Banner Action', { answer });
};

export const trackMergeSuccess = () => {
  trackEvent('Merge Property Success');
};

export const trackMergeDialogShow = () => {
  trackEvent('Property Duplicate Banner Shown');
};

export const trackOpenJobRequest = jobRequestId => {
  trackEvent('Host Activity Open', { jobRequestId });
};

export const trackPageChange = pagepath => {
  sendPageToGoogleAnalytics(pagepath);

  trackEvent('Webapp Page Change', {
    pagepath,
  });
};

export const trackPageOpen = (loggedIn, role) => {
  trackEvent('App opened', {
    loggedIn,
    role,
  });
};

export const trackLogout = () => {
  trackEvent('logout');
};

export const trackErrorScreenShown = () => {
  trackEvent('Show Error Screen');
};

export const trackOpenSettings = () => {
  trackEvent('Settings Profile Page', { role: 1 });
};

export const trackInviteStart = () => {
  trackEvent('Invite Contact Start');
};

export const trackInviteExistingContact = () => {
  trackEvent('Invite Contact Existing');
};

export const trackInvitNewContact = () => {
  trackEvent('Invite Contact New');
};

export const trackInvitAddedSuccess = () => {
  trackEvent('Add Contact Successful');
};

export const trackLoginScreenShown = () => {
  trackEvent('Show Log In Screen');
};

export const trackSignupScreenShown = () => {
  trackEvent('Show Sign Up Screen');
};

export const trackSignupComplete = () => {
  trackEvent('Sign Up Complete');
  sendEventToGoogleAdwords('GOOGLE_ADWORDS_SIGNUP_CONVERSION_ID');
};

export const trackSendJobRequestOpen = (source, action) => {
  trackEvent('Assign Job Flow Started', { source, action });
};

export const trackSendJobRequestSent = (
  checklistId,
  numOfCleaners,
  daysToJob,
  endDaysToJob,
  eventId,
  countChecklists,
  checklistsCommaSeperated,
  isEdit,
  jobStartTimeType,
) => {
  trackEvent('Assign Job Sent', {
    checklistId,
    numOfCleaners,
    daysToJob,
    endDaysToJob,
    eventId,
    countChecklists,
    checklistsCommaSeperated,
    isEdit,
    jobStartTimeType,
  });
};

export const trackFREAction = (action, partner) => {
  trackEvent('Host FRE Start Action', { action, partner });
};

export const trackCreateNewProperty = () => {
  trackEvent('Create Property Started');
};

export const trackCreateNewPropertySuccess = propertyId => {
  trackEvent('Create Property Created', { propertyId });
};

export const trackPropertyDelete = propertyId => {
  trackEvent('Property Delete', { propertyId });
};

export const trackPropertyEditDetails = propertyId => {
  trackEvent('Property Edit Details', { propertyId });
};

export const trackPropertyEditAddress = propertyId => {
  trackEvent('Edit Property Address', { propertyId });
};

export const trackChecklistDelete = checklistId => {
  trackEvent('Checklist Delete', { checklistId });
};

export const trackChecklistDesSave = () => {
  trackEvent('Checklist Edit Description Done');
};

export const trackChecklistDesFocus = () => {
  trackEvent('Checklist Edit Description');
};

export const trackChecklistAddSlide = () => {
  trackEvent('Checklist Add Slide');
};

export const trackChecklistDeleteSlide = () => {
  trackEvent('Checklist Slide Delete');
};

export const trackChecklistDupliSlide = () => {
  trackEvent('Checklist Slide Duplicate');
};

export const trackChecklistCreated = checklistId => {
  trackEvent('Checklist Created', { checklistId });
};

export const trackChecklistAddPin = checklistId => {
  trackEvent('Checklist Add Pin', { checklistId });
};

export const trackChecklistAddPinNote = checklistId => {
  trackEvent('Checklist Add Pin Note', { checklistId });
};

export const trackPartnerDisconnect = () => {
  trackEvent('Partner Disconnect');
};

export const trackPartnerReimport = () => {
  trackEvent('Partner Re-Import');
};

export const trackPartnerConnectError = () => {
  trackEvent('Partner Connect Error');
};

export const trackPartnerConnectSuccess = () => {
  trackEvent('Partner Connect Success');
};

export const trackPartnerConnectPageOpen = () => {
  trackEvent('Partner Open');
};

export const trackPartnerConnectStart = partner => {
  trackEvent('Partner Connect Start', { partner });
};

export const trackPartnerImport = step => {
  trackEvent('Partner Import', { step });
};

export const trackJobViewSuggested = () => {
  trackEvent('Assign Job View Suggested');
};

export const trackJobViewSuggestedConfirm = () => {
  trackEvent('Assign Job View Suggested Confirmed');
};

export const trackAssingJobSelectedCleaner = () => {
  trackEvent('Assign Job Select Cleaner');
};

export const trackAssingJobShowCleaners = () => {
  trackEvent('Assign Job Show Cleaners');
};

export const trackAssingSelectProperty = propertyId => {
  trackEvent('Assign Select Property', { propertyId });
};

export const trackAssingSelectJob = jobId => {
  trackEvent('Assign Select Job', { jobId });
};

export const trackAssingJobMe = () => {
  trackEvent('Assign Job Added Myself');
};

export const trackSendToMobileAction = type => {
  trackEvent('Host Send To Mobile Action', {
    type,
  });
};

export const trackDuplicateChecklist = () => {
  trackEvent('Duplicate Checklist into other Property Webapp');
};

export const trackSendJobRequestToMoreCleaners = () => {
  trackEvent('Send Jobrequest to more cleaners Webapp');
};

export const trackCreateReport = () => {
  trackEvent('Create report');
};

export const trackSaveReport = () => {
  trackEvent('Save report');
};

export const trackExportReport = () => {
  trackEvent('Export report');
};

export const trackUpgradePlanClick = () => {
  trackEvent('Pricing Upgrade Plan');
};

export const trackProFeatureClick = attemptedAction => {
  trackEvent('Pricing Pro Feature', { attemptedAction });
};

export const trackVisitReceiptPage = () => {
  trackEvent('Pricing Visit Receipt Page');
};

export const trackPricingUpdateDetails = () => {
  trackEvent('Pricing Click on Update Details');
};

export const trackPricingCancelSub = () => {
  trackEvent('Pricing Click Cancel Subscription');
};

export const trackPricingThankYou = () => {
  trackEvent('Pricing Thank You Upgrade Modal');
  sendEventToGoogleAdwords('GOOGLE_ADWORDS_SUBSCRIPTION_CONVERSION_ID');
};

export const trackLimitReached = num => {
  trackEvent('Pricing Limit Reached', { remainingJobRequests: num });
};

export const trackEventSkip = eventId => {
  trackEvent('Event Skipped', { eventId });
};
