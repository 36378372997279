import { push } from 'react-router-redux';
import * as types from '../../../../types';
import { ROUTES } from '../../../../paths';

export function locationHitChecklistDetail(id, jobId, isIndependent) {
  return {
    type: types.CHECKLIST_DETAIL_HIT,
    id,
    jobId,
    isIndependent: !!isIndependent,
  };
}

export function goToProperty(id) {
  return push(ROUTES.propertyPageChecklists(id));
}

export function checklistDirectSlideUpload(index, image) {
  return {
    type: types.CHECKLIST_DIRECT_SLIDE_IMAGE_UPLOAD,
    index,
    image,
  };
}

export function checklistDirectTaskImageUpload(stepId, taskIndex, image) {
  return {
    type: types.CHECKLIST_DIRECT_TASK_IMAGE_UPLOAD,
    stepId,
    taskIndex,
    image,
  };
}

export function setState(val) {
  return {
    type: types.CHECKLIST_SET_STATE,
    val,
  };
}

export function setCurrentJobIns(val) {
  return {
    type: types.CHECKLIST_SET_CURRENT_JOBINS_ID,
    val,
  };
}

export function setCurrentJob(val) {
  return {
    type: types.CHECKLIST_SET_CURRENT_JOB_ID,
    val,
  };
}

export function setTileView(val) {
  return {
    type: types.CHECKLIST_SET_TILEVIEW,
    val,
  };
}

export function setUploadState(val) {
  return {
    type: types.CHECKLIST_SET_UPLOADSTATE,
    val,
  };
}

export function updateChecklistGlobal(id, changeSet) {
  return {
    type: types.UPDATE_COLLECTION_ENTRY,
    collectionName: 'jobs',
    id,
    changeSet,
  };
}

export function updatePropertyGlobal(id, changeSet) {
  return {
    type: types.UPDATE_COLLECTION_ENTRY,
    collectionName: 'properties',
    id,
    changeSet,
  };
}

export function uploadPicsSaga(pics, propertyId) {
  return {
    type: types.CHECKLIST_UPLOAD_PICS_SAGA,
    pics,
    propertyId,
  };
}

export function setOrUpdatePictureSaga() {
  return {
    type: types.CHECKLIST_SET_OR_UPDATE_PICTURE_SAGA,
  };
}

export function createChecklistSaga({ propertyId, role, user, title, redirectUrl, openInModal }) {
  return {
    type: types.CHECKLIST_CREATE_CHECKLIST_SAGA,
    propertyId,
    role,
    user,
    title,
    redirectUrl,
    openInModal,
  };
}

export function updateChecklistSaga(action, checklist) {
  return {
    type: types.CHECKLIST_UPDATE_CHECKLIST_SAGA,
    checklist,
    action,
  };
}

export function setTextTemplate(val) {
  return {
    type: types.CHECKLIST_SET_TEXTTEMPLATE,
    val,
  };
}

export function copyChecklistToLibraryRequest(checklistId) {
  return {
    type: types.CHECKLIST_COPY_CHECKLIST_TO_LIBRARY_REQUEST,
    checklistId,
  };
}
export function copyChecklistToLibrarySuccess(checklistId) {
  return {
    type: types.CHECKLIST_COPY_CHECKLIST_TO_LIBRARY_SUCCESS,
    checklistId,
  };
}
export function copyChecklistToLibraryFailure(checklistId, error) {
  return {
    type: types.CHECKLIST_COPY_CHECKLIST_TO_LIBRARY_FAILURE,
    checklistId,
    error,
  };
}

export function setCloneChecklist(val) {
  return {
    type: types.CHECKLIST_SET_CLONE_CHECKLIST,
    val,
  };
}

export function multiCloneSaga(propertyIdArray, checklistId, title, independent) {
  return {
    type: types.CHECKLIST_MULTI_CLONE,
    propertyIdArray,
    checklistId,
    title,
    independent: independent || false,
  };
}

export function setActiveChecklist(val) {
  return {
    type: types.CHECKLIST_SET_ACTIVE,
    val,
  };
}

export function goToChecklist(checklistId, jobInstructionId, redirectUrl) {
  const params = redirectUrl ? { query: { redirectUrl } } : {};
  return push({
    pathname: ROUTES.checklist(checklistId, jobInstructionId),
    ...params,
  });
}

export function setJobInstruction(id, data) {
  return {
    type: types.CHECKLIST_UPDATE_JOBINSTRUCTION,
    id,
    data,
  };
}

export function updateLastUpdatedTime(time) {
  return {
    type: types.CHECKLIST_UPDATE_LAST_UPDATE_TIME,
    time,
  };
}

export function setLoadingState(id, val) {
  return {
    type: types.CHECKLIST_SET_LOADING_STATE,
    id,
    val,
  };
}

export function stepMutatorSaga(action, meta) {
  return {
    type: types.CHECKLIST_STEP_MUTATOR_SAGA,
    action,
    meta,
  };
}

export function taskMutatorSaga(action, jobInstructionId, stepId, meta) {
  return {
    type: types.CHECKLIST_TASK_MUTATOR_SAGA,
    action,
    stepId,
    jobInstructionId,
    meta,
  };
}

export function setModalObj(obj) {
  return {
    type: types.CHECKLIST_SET_MODAL,
    obj,
  };
}

export function setModalClose(checklistId) {
  return {
    type: types.CHECKLIST_SET_MODAL_CLOSE,
    checklistId,
  };
}

export function deleteChecklistSaga(checklist, property) {
  return {
    type: types.CHECKLIST_DELETE_SAGA,
    checklist,
    property,
  };
}

export function setWarningModalSaga(answer, name) {
  return {
    type: types.CHECKLIST_DELETE_WARNING_SAGA,
    answer,
    name,
  };
}
