import React, { PureComponent } from 'react';
import t from '@properly/localization';
import { LoadingSplash, Regular } from '@properly/components';
import { featureFlag } from '@properly/config';
import noop from 'lodash/noop';
import reduce from 'lodash/reduce';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import classNames from 'classnames/bind';
import styles from './propertyStyles.module.css';
import { getParseSuggestedCleanersNew } from '../../data';
import HeadBar from '../../../../components/HeadBar/index';
import { CleanerList } from '../../../../components/JobRequestElements/index';
import JobRequestServicePro from '../../../../containers/JobRequestServicePro/index';
import { selectCurrentUserId } from '../../../../selectors/globalSelector';
import { selectIsFeatureFlagEnabledForUser } from '../../settings/state/SettingsSelectors';

const cx = classNames.bind(styles);

class PropertyCcleaners extends PureComponent {
  state = {
    isLoading: false,
    cleaners: [],
    selectedCleaner: undefined,
  };

  componentWillMount() {
    this.setState({ isLoading: true });
    getParseSuggestedCleanersNew(this.props.property).then(data => {
      this.setState({ isLoading: false, cleaners: data });
    });
  }

  setContact = val => {
    this.setState({ selectedCleaner: val });
  };

  render() {
    const { isMarketPlaceFeatureFlagEnabled } = this.props;
    if (this.state.selectedCleaner) {
      return (
        <JobRequestServicePro
          contact={this.state.selectedCleaner}
          offeredServices={undefined}
          onSelect={noop}
          showButton={false}
          onBack={() => this.setContact(undefined)}
          proMarketProperty={isMarketPlaceFeatureFlagEnabled && this.props.property?.proMarketProperty}
        />
      );
    }
    const hasCleaners = this.state.cleaners.length > 0;
    return (
      <div className={cx('property__ccborder--top', 'property__ccborder--bottom')}>
        <HeadBar onClose={this.props.onClose} middleText={t('job_request.community_cleaner')} />

        {this.state.isLoading && (
          <div className={cx('property__ccloading')}>
            <LoadingSplash static />
          </div>
        )}
        {!this.state.isLoading && (
          <div className={cx('property__ccborder--bottom')}>
            {hasCleaners || (
              <div className={cx('property__ccempty')}>
                <Regular type="orange">{t('properties.not_community_cleaners')}</Regular>
              </div>
            )}
            {hasCleaners && (
              <CleanerList
                hasButton
                isBlue
                currentUserId={this.props.currentUserId}
                onClickBtn={(contact, selected, e) => {
                  e.stopPropagation();
                  this.setContact(contact);
                }}
                contacts={this.state.cleaners}
                onClick={contact => {
                  this.setContact(contact);
                }}
                selectedCleaners={reduce(
                  this.state.cleaners,
                  (acc, contact) => {
                    acc[contact.userData.objectId] = contact;
                    return acc;
                  },
                  {},
                )}
                proMarketProperty={isMarketPlaceFeatureFlagEnabled && this.props.property?.proMarketProperty}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  connect((state, props) => ({
    currentUserId: selectCurrentUserId()(state, props),
    isMarketPlaceFeatureFlagEnabled: selectIsFeatureFlagEnabledForUser(featureFlag.FEATURE_FLAG_MARKETPLACE)(
      state,
      props,
    ),
  })),
)(PropertyCcleaners);
