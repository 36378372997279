"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapTimeToColor = mapTimeToColor;
exports.formatMinHours = formatMinHours;
exports.generateOptionsTime = generateOptionsTime;
exports.generateOptionsDuration = generateOptionsDuration;
exports.userHas24hClock = userHas24hClock;
exports.addMinToDate = addMinToDate;
exports.getMomentZone = getMomentZone;
exports.getTimezoneOffset = getTimezoneOffset;
exports.doesJobRequestDurationFit = exports.fixJobRequestStartTime = exports.roundNext15Min = exports.timezonesAreEqual = exports.prettyPrintDuration = exports.isWithinRange = exports.fixMomentDates = exports.generateDateBucket = exports.getDateBucketRange = exports.getDateBucket = exports.generateDays = exports.isWeekend = exports.isToday = exports.getDefaultCalendarStartLimit = exports.getDefaultCalendarEnd = exports.getDefaultCalendarStart = void 0;

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

var _localization = _interopRequireDefault(require("@properly/localization"));

var _loglevel = _interopRequireDefault(require("loglevel"));

var _uniq = _interopRequireDefault(require("lodash/uniq"));

var _memoize = _interopRequireDefault(require("lodash/memoize"));

var _once = _interopRequireDefault(require("lodash/once"));

var _times = _interopRequireDefault(require("lodash/times"));

var _map = _interopRequireDefault(require("lodash/map"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const getDefaultCalendarStart = (0, _once.default)(() => (0, _momentTimezone.default)().subtract(1, 'd').startOf('d').toDate());
exports.getDefaultCalendarStart = getDefaultCalendarStart;
const getDefaultCalendarEnd = (0, _once.default)(() => (0, _momentTimezone.default)(getDefaultCalendarStart()).add(6, 'd').endOf('d').toDate());
exports.getDefaultCalendarEnd = getDefaultCalendarEnd;
const getDefaultCalendarStartLimit = (0, _once.default)(() => (0, _momentTimezone.default)(getDefaultCalendarStart()).subtract(12, 'w').startOf('d').toDate());
exports.getDefaultCalendarStartLimit = getDefaultCalendarStartLimit;
const BUCKET_ADDED_SIZE = 1;

const isToday = (() => {
  let todayMoment = (0, _momentTimezone.default)();

  const isTodayFn = (day, format) => (0, _momentTimezone.default)(day, [format]).isSame(todayMoment, 'day');

  let lastCache = Date.now();
  let memoizedIsToday = (0, _memoize.default)(isTodayFn);
  return (day, format) => {
    try {
      if (Date.now() - lastCache > 5 * 60 * 1000) {
        lastCache = Date.now();
        todayMoment = (0, _momentTimezone.default)();
        memoizedIsToday = (0, _memoize.default)(isTodayFn);
      }

      return memoizedIsToday(day, format);
    } catch (err) {
      _loglevel.default.error('Error calculating date isToday', day, format);

      _loglevel.default.error('Error calculating date isToday', err);

      return false;
    }
  };
})();

exports.isToday = isToday;

const isWeekend = (() => {
  const isWeekendFn = (day, format) => (0, _momentTimezone.default)(day, [format]).format('dddd') === 'Sunday' || (0, _momentTimezone.default)(day, [format]).format('dddd') === 'Saturday';

  let lastCache = Date.now();
  let memoizedIsWeekend = (0, _memoize.default)(isWeekendFn);
  return (day, format) => {
    try {
      if (Date.now() - lastCache > 5 * 60 * 1000) {
        lastCache = Date.now();
        memoizedIsWeekend = (0, _memoize.default)(isWeekendFn);
      }

      return memoizedIsWeekend(day, format);
    } catch (err) {
      _loglevel.default.error('Error calculating date isWeekend', day, format);

      _loglevel.default.error('Error calculating date isWeekend', err);

      return false;
    }
  };
})();

exports.isWeekend = isWeekend;
const generateDays = (0, _memoize.default)((startDate, endDate) => {
  const dateFormat = 'YYYYMMDD';
  const dates = [];
  let mutatedStartDate = (0, _momentTimezone.default)(startDate);
  const finalEndDate = (0, _momentTimezone.default)(endDate).toDate();
  finalEndDate.setHours(0, 0, 0, 0); // need to revert 23:59:59

  dates.push(mutatedStartDate.format(dateFormat)); // first

  let limit = 0;

  while (mutatedStartDate.format(dateFormat) !== (0, _momentTimezone.default)(finalEndDate).format(dateFormat)) {
    mutatedStartDate = (0, _momentTimezone.default)(mutatedStartDate).add(1, 'days');
    dates.push(mutatedStartDate.format(dateFormat)); // Ensure we never loop forever

    limit += 1;

    if (limit > 365) {
      return dates;
    }
  }

  return dates;
});
exports.generateDays = generateDays;

const getDateBucket = (() => {
  const dateFormat = 'YYYYMMDD';
  const getDateOverscrollStart = (0, _once.default)(() => (0, _momentTimezone.default)(getDefaultCalendarStart()).subtract(BUCKET_ADDED_SIZE, 'days'));
  const getDateOverscrollEnd = (0, _once.default)(() => (0, _momentTimezone.default)(getDefaultCalendarEnd()).add(BUCKET_ADDED_SIZE, 'days'));
  const getDateOverscrollColumnCount = (0, _once.default)(() => getDateOverscrollEnd().diff(getDateOverscrollStart(), 'days'));
  return (0, _memoize.default)(date => {
    const dateOverscrollStart = getDateOverscrollStart();
    const dateOverscrollColumnCount = getDateOverscrollColumnCount();
    const dateMoment = (0, _momentTimezone.default)(date);
    const diff = dateMoment.diff(getDefaultCalendarStart(), 'd');
    const overScanBucketId = Math.floor(diff / dateOverscrollColumnCount);
    const overScanBucket = overScanBucketId * dateOverscrollColumnCount;
    const overScanBucketStart = overScanBucket === 0 ? dateOverscrollStart : dateOverscrollStart.clone().add(overScanBucket, 'd');
    const overScanBucketEnd = overScanBucket === 0 ? dateOverscrollStart.clone().add(dateOverscrollColumnCount, 'd') : dateOverscrollStart.clone().add(dateOverscrollColumnCount + overScanBucket, 'd');
    const stringStart = overScanBucketStart.format(dateFormat);
    const stringEnd = overScanBucketEnd.format(dateFormat);
    return {
      id: overScanBucketId,
      diff: overScanBucket,
      start: stringStart,
      end: stringEnd,
      bucket: `${stringStart}-${stringEnd}`
    };
  });
})();

exports.getDateBucket = getDateBucket;

const getDateBucketRange = (() => {
  const getDateOverscrollStart = (0, _once.default)(() => (0, _momentTimezone.default)(getDefaultCalendarStart()).subtract(1, 'days'));
  const getDateOverscrollEnd = (0, _once.default)(() => (0, _momentTimezone.default)(getDefaultCalendarEnd()).add(1, 'days'));
  const getDateOverscrollColumnCount = (0, _once.default)(() => getDateOverscrollEnd().diff(getDateOverscrollStart(), 'days'));
  return (start, end) => {
    // while difference is big, keep looping
    const startMoment = (0, _momentTimezone.default)(start);
    const endMoment = (0, _momentTimezone.default)(end || start);
    const difference = endMoment.diff(startMoment, 'days');

    if (difference <= 0) {
      return [getDateBucket(start).bucket];
    }

    const {
      bucket: startBucket
    } = getDateBucket(startMoment);
    const {
      bucket: endBucket
    } = getDateBucket(endMoment);

    if (startBucket === endBucket) {
      return [startBucket];
    }

    const buckets = [startBucket];
    let currentBucket = startBucket;
    let count = 0;

    while (count < 100 && currentBucket !== endBucket) {
      count += 1;
      const currentOffset = count * getDateOverscrollColumnCount();
      currentBucket = getDateBucket(startMoment.clone().add(currentOffset, 'days')).bucket;
      buckets.push(currentBucket);
    }

    return buckets;
  };
})();

exports.getDateBucketRange = getDateBucketRange;

const generateDateBucket = (() => {
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  return (0, _memoize.default)((start, end) => {
    const startDate = (0, _momentTimezone.default)(start);
    const endDate = (0, _momentTimezone.default)(end);
    const startDateOverscan = (0, _momentTimezone.default)(start).subtract(1, 'w');
    const endDateOverscan = (0, _momentTimezone.default)(end).add(1, 'w');
    const buckets = (0, _uniq.default)([getDateBucket(startDate), getDateBucket(endDate), getDateBucket(startDateOverscan), getDateBucket(endDateOverscan)]);
    const ids = buckets.map(({
      bucket
    }) => bucket).filter(onlyUnique);
    return {
      dateBucketIds: ids
    };
  });
})();

exports.generateDateBucket = generateDateBucket;

function mapTimeToColor(minutes) {
  const minutesNumber = minutes && Number(minutes);

  if (minutesNumber <= 15) {
    return '#43A047';
  }

  if (minutesNumber > 15 && minutesNumber < 60 * 4) {
    return '#FFA726';
  }

  if (minutesNumber >= 60 * 4) {
    return '#FF5722';
  }

  return '#000';
}

function formatMinHours(val) {
  const valNumber = Number(val);
  const hasMins = valNumber % 60 !== 0;
  const hasHours = valNumber >= 60;
  const mins = valNumber % 60;
  const hrs = Math.floor(valNumber / 60);
  const hrsPart = hrs === 1 ? `${hrs} ${(0, _localization.default)('job_request.just_hour')}` : `${hrs} ${(0, _localization.default)('job_request.just_hours')}`;
  const minsPart = (0, _localization.default)('job_request.just_minutes', {
    mins: Math.floor(mins)
  });

  if (hasMins && hasHours) {
    return `${hrsPart} ${minsPart}`;
  }

  if (hasMins) {
    return minsPart;
  }

  if (hasHours) {
    return hrsPart;
  }

  if (valNumber === 0) {
    return (0, _localization.default)('job_request.just_minutes', {
      mins: 0
    });
  }

  return '';
}

function generateOptionsTime(stepper) {
  const array = (0, _times.default)(60 * 24 / stepper, val => {
    const formatString = userHas24hClock() ? 'HH:mm' : 'hh:mm A';
    const min = val * stepper;
    return {
      value: `${min}`,
      title: addMinToDate(undefined, min).format(formatString)
    };
  });
  return array;
}

function generateOptionsDuration(stepMin, maxMin) {
  const array = (0, _times.default)(maxMin / stepMin, val => stepMin * (val + 1));
  return (0, _map.default)(array, val => ({
    value: `${val}`,
    title: formatMinHours(val)
  }));
}

function userHas24hClock() {
  const nowString = (0, _momentTimezone.default)().format('LT');
  return nowString.indexOf('AM') === -1 && nowString.indexOf('PM') === -1;
}

function addMinToDate(date, min) {
  return (0, _momentTimezone.default)(date).clone().hours(Math.floor(min / 60)).minutes(min % 60);
}

function getMomentZone(timezone) {
  return _momentTimezone.default.tz.zone(timezone);
}

function getTimezoneOffset(targetTimeZone, currentTimeZone = (0, _momentTimezone.default)().tz() || _momentTimezone.default.tz.guess()) {
  // Update ISO Start Time to be in the property timezone
  if (targetTimeZone && currentTimeZone !== targetTimeZone) {
    const currentTime = (0, _momentTimezone.default)();
    const currentZone = getMomentZone(currentTimeZone);
    const currentOffset = currentZone.utcOffset(currentTime);
    const targetZone = getMomentZone(targetTimeZone);
    const targetOffset = targetZone.utcOffset(currentTime);
    return targetOffset - currentOffset;
  }

  return 0;
} // fix for moment dates on wrong years (parse problem)


const fixMomentDates = date => {
  const momentFormattedDate = (0, _momentTimezone.default)(date).millisecond(0);

  if (momentFormattedDate.year() < 2000) {
    const dateString = date._i; // eslint-disable-line

    return (0, _momentTimezone.default)(dateString, 'YYYY-MM-DD[T]HH:mm:ss.zzz[Z]');
  }

  return momentFormattedDate;
};

exports.fixMomentDates = fixMomentDates;

const isWithinRange = ({
  rangeStartDate,
  rangeEndDate,
  startDate,
  endDate
}) => {
  const isStartWithinRange = (0, _momentTimezone.default)(startDate).isSameOrBefore((0, _momentTimezone.default)(rangeEndDate));
  const isEndWithinRange = (0, _momentTimezone.default)(endDate).isSameOrAfter((0, _momentTimezone.default)(rangeStartDate));
  return isStartWithinRange && isEndWithinRange;
};

exports.isWithinRange = isWithinRange;

const prettyPrintDuration = duration => {
  const durationNumber = Number.isNaN(Number(duration)) ? 0 : Number(duration);
  const durationHours = Math.floor(durationNumber / 60);
  const durationMinutes = durationNumber % 60;
  const prettyDuration = durationHours ? (0, _localization.default)('job_request.hours_minutes', {
    hrs: durationHours,
    mins: durationMinutes
  }) : (0, _localization.default)('job_request.just_minutes', {
    mins: durationMinutes || 0
  });
  return prettyDuration;
};

exports.prettyPrintDuration = prettyPrintDuration;

const timezonesAreEqual = (timeZoneA, timeZoneB) => timeZoneA === timeZoneB || _momentTimezone.default.tz(timeZoneA).format('Z') === _momentTimezone.default.tz(timeZoneB).format('Z');

exports.timezonesAreEqual = timezonesAreEqual;

const roundNext15Min = date => {
  if (!date) {
    return date;
  }

  const momentDate = (0, _momentTimezone.default)(date).clone();

  if (!momentDate.isValid()) {
    return date;
  }

  let intervals = Math.floor(momentDate.minutes() / 15);

  if (momentDate.minutes() % 15 !== 0) {
    intervals += 1;
  }

  if (intervals === 4) {
    momentDate.add('hours', 1);
    intervals = 0;
  }

  momentDate.minutes(intervals * 15);
  momentDate.seconds(0);
  return momentDate;
};

exports.roundNext15Min = roundNext15Min;

const fixJobRequestStartTime = (time, prefilledJobRequestStartTime) => {
  // this block computes the default checkout time
  const computedTimeResult = time.clone();
  computedTimeResult.set({
    hour: prefilledJobRequestStartTime.hour,
    minute: prefilledJobRequestStartTime.minute
  });
  return computedTimeResult;
};

exports.fixJobRequestStartTime = fixJobRequestStartTime;

const doesJobRequestDurationFit = ({
  startTime,
  endTime,
  nowTime,
  duration
}) => {
  const diffStartEndTime = endTime && _momentTimezone.default.duration(endTime.diff(startTime));

  const diffStartEndMinutes = endTime && diffStartEndTime.asMinutes() || 0;

  const diffCurrentTimeAndEndTime = endTime && _momentTimezone.default.duration(endTime.diff(nowTime));

  const diffCurrentTimeAndEndTimeMinutes = endTime && diffCurrentTimeAndEndTime.asMinutes() || 0;
  return +duration <= Math.round(diffCurrentTimeAndEndTimeMinutes) && +duration <= Math.round(diffStartEndMinutes);
};

exports.doesJobRequestDurationFit = doesJobRequestDurationFit;