import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import styles from '../components/accountStyles.module.css';
import MainWrapper from '../../../../containers/MainWrapper';

const cx = classNames.bind(styles);

const AccountContainer = ({ children, disableWrapper, onboardingMobile }) => (
  <MainWrapper
    hidePricewarning
    hideMobilePopover
    disableWrapper={disableWrapper}
    currentPage="account"
    disablePreloadLoader
  >
    <div className={cx('account__bg', { 'account__bg-white': onboardingMobile })}>{children}</div>
  </MainWrapper>
);

AccountContainer.propTypes = {
  children: PropTypes.node,
  disableWrapper: PropTypes.bool,
};

export default connect(null, null)(AccountContainer);
