import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import * as selectors from '../../checklists/state/ChecklistSelectors';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import { setSearchQuery } from '../../../../actions/globalActions';
import { ROUTES } from '../../../../paths';
// import { cloneChecklistIntoPropertiesRequest } from '../state/library.actions';
// import { setCloneChecklist } from '../../checklists/state/ChecklistActions';
import { loadIndependentChecklistsRequest, loadPublishedChecklistsRequest } from '../state/library.actions';
import { setCloneChecklist, multiCloneSaga } from '../../checklists/state/ChecklistActions';
// import { selectChecklistCloningIntoPropertiesByPropId } from '../state/library.selectors';

const propertiesSelector = selectorsGlobal.selectProperties();
const userSelector = selectorsGlobal.selectCurrentUser();
const selectChecklistSearch = selectorsGlobal.selectSearchData(
  'properties',
  'checklist_clone_search',
  selectorsGlobal.selectProperties,
);

function mapStateToProps() {
  return (state, props) => {
    const { searchQuery, result } = selectChecklistSearch(state);
    const cloneChecklistState = selectors.selectCloneChecklistJS(state, props);
    const properties = propertiesSelector(state, props);
    const currentUser = userSelector(state, props);

    return {
      navHidden: true,

      // isSubmitting: selectChecklistCloningIntoPropertiesByPropId(state, props),
      isSubmitting: cloneChecklistState.mode !== 0,

      redirectOnSuccessRoute: '/library',

      isLoading: properties.size === 0,
      searchQuery,
      searchRes: result,
      selectedChecklists: cloneChecklistState.selected,
      checklistTitle: null,
      properties,
      currentUser,
    };
  };
}

function mapDispatchToProps(dispatch) {
  return {
    push: route => dispatch(push(route)),
    loadChecklists: () => {
      dispatch(loadIndependentChecklistsRequest());
      dispatch(loadPublishedChecklistsRequest());
    },
    setCloneChecklist: (...args) => dispatch(setCloneChecklist(...args)),
    setSearchQuery: (...args) => dispatch(setSearchQuery(...args)),
    multiCloneSaga: (propertyIds, checklistId, checklistTitle) =>
      dispatch(multiCloneSaga(propertyIds, checklistId, checklistTitle)),
    onClose: () => dispatch(push(ROUTES.library)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
