"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ownerAcquisitionBlacklistedUtmSources = exports["default"] = exports.DIRECT = exports.SEO = void 0;
var SEO = 'seo';
exports.SEO = SEO;
var DIRECT = 'direct';
exports.DIRECT = DIRECT;
var _default = [{
  referrer: 'google',
  medium: 'organic',
  source: 'google',
  campaign: ''
}, {
  referrer: 'bing',
  medium: 'organic',
  source: 'bing',
  campaign: ''
}, {
  referrer: 'learnairbnb',
  medium: 'referral',
  source: 'blog',
  campaign: 'learnAirbnb'
}, {
  referrer: 'capterra',
  medium: 'referral',
  source: 'reviews',
  campaign: 'Capterra'
}, {
  referrer: 'hoteltechreport',
  medium: 'referral',
  source: 'reviews',
  campaign: 'HotelTechReport'
}, {
  referrer: 'cpc',
  medium: 'cpc',
  source: 'google',
  campaign: 'adwords'
}, {
  referrer: 'bookingsync',
  medium: 'channels',
  source: 'bookingsync',
  campaign: 'bookingsync'
}, {
  referrer: 'v12',
  medium: 'channels',
  source: 'v12',
  campaign: 'v12'
}, {
  referrer: 'guesty',
  medium: 'channels',
  source: 'guesty',
  campaign: 'guesty'
}, {
  referrer: 'orbirental',
  medium: 'channels',
  source: 'orbirental',
  campaign: 'orbirental'
}, {
  referrer: 'escapia',
  medium: 'channels',
  source: 'escapia',
  campaign: 'escapia'
}, {
  referrer: 'myvr',
  medium: 'channels',
  source: 'myvr',
  campaign: 'myvr'
}, {
  referrer: 'rentalsunited',
  medium: 'channels',
  source: 'rentals_united',
  campaign: 'rentals_united'
}, {
  referrer: 'getproperly.com/blog/',
  medium: 'organic',
  source: SEO,
  campaign: 'blog'
}, {
  referrer: 'getproperly.com/partners/',
  medium: 'organic',
  source: SEO,
  campaign: 'partners'
}, {
  referrer: 'help.getproperly.com',
  medium: 'organic',
  source: SEO,
  campaign: 'helpAndLearning'
}];
exports["default"] = _default;
var ownerAcquisitionBlacklistedUtmSources = [SEO, DIRECT];
exports.ownerAcquisitionBlacklistedUtmSources = ownerAcquisitionBlacklistedUtmSources;