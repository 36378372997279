import * as types from '../../../types';

export function openPricingModalSaga(kind, meta, attemptedAction) {
  return {
    type: types.PRICING_OPEN_MODAL_SAGA,
    kind,
    meta,
    attemptedAction,
  };
}

export function setPricingModal(data) {
  return {
    type: types.PRICING_SET_MODAL,
    data,
  };
}

export function closeModalSaga(kind) {
  return {
    type: types.PRICING_CLOSE_MODAL_SAGA,
    kind,
  };
}
