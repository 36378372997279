"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  RECAPTCHA_VERSION_V2: 'V2',
  RECAPTCHA_VERSION_V3: 'V3'
};
exports["default"] = _default;