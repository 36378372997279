import log from 'loglevel';
import { client as apolloClient } from '../../config/apollo';
import { SET_DISABLE_ONBOARDING_VIDEO } from '../mutations/bookingComPilot';
import { QUERY_GET_PROPERTY_BOOKING_SYNC_STATUS } from '../queries/bookingComPilot';

export const setDisableOnBoardingVideo = async status => {
  try {
    const res = await apolloClient.mutate({
      mutation: SET_DISABLE_ONBOARDING_VIDEO,
      variables: {
        disableVideo: status ? 'YES' : 'NO',
        fetchPolicy: 'no-cache',
      },
    });

    return res.data.DisableOnBoardingVideo;
  } catch (error) {
    log.error(`setDisableOnBoardingVideo api error : ${error}`);
    return error;
  }
};

export const getPropertyBookingSyncStatus = async partner => {
  try {
    const rawResponse = await apolloClient.query({
      query: QUERY_GET_PROPERTY_BOOKING_SYNC_STATUS,
      variables: {
        partner,
      },
      fetchPolicy: 'no-cache',
    });
    return rawResponse.data.IsBookingSyncComplete;
  } catch (error) {
    log.error(`getPropertyBookingSyncStatus api error :${error}`);
    error.apiError = true;
    return error;
  }
};
