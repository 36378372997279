import { compose } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { selectSkillByPropId, selectSingleSkillLoading, selectSingleSkillError } from '../state/library.selectors';
import { loadSkillRequest } from '../state/library.actions';
import { setActiveChecklist } from '../../checklists/state/ChecklistActions';

const mapStateToProps = (state, props) => {
  const selectedSkill = selectSkillByPropId(state, props);
  return {
    isLoading: selectSingleSkillLoading(state, props),
    isError: selectSingleSkillError(state, props),
    selectedSkill,
  };
};

const mapDispatchToProps = dispatch => ({
  push: route => dispatch(push(route)),
  loadSkill: skillId => dispatch(loadSkillRequest(skillId)),
  // we reuse the active checklist function to set active skill
  setActiveChecklist: val => dispatch(setActiveChecklist(val)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps));
