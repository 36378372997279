import log from 'loglevel';
import { fork, call, put, takeEvery } from 'redux-saga/effects';
import { updateIntercom } from '@properly/common';
import { connectedAccounts as ConnectedAccounts } from '@properly/config';
import { push } from 'react-router-redux';
import { ROUTES } from '../../../../paths';
import { setUserEmailAutoPopulate } from '../../../../actions/globalActions';
import { trackSignupComplete } from '../../../../actions/trackingEvents';
import { brandingPilotSignUpNewUser } from '../../../desktopApp/data';
import { pollChannel } from '../../../../sagas/global';
import {
  getExternalSessionApiTokenLocalStorage,
  resetExternalSessionApiTokenLocalStorage,
} from '../../../../config/apiLinkGen';
import { getCustomBrandindLandingPage } from '../../../../graphql/api/customBranding';
import { setBranchIOLink } from '../../../branding/BrandingActions';
import { hostSignUpSuccess, loginGateSaga } from '../../../loginSignUp/LoginSignUpActions';
import * as types from '../../../../types/index';
import { getPropertyBookingSyncStatus } from '../../../../graphql/api/bookingComPilot';

function* redirectToOauthProviderSaga(action) {
  log.info('booking com redirectToOauth ProviderSaga-', action.data);
  const redirectFunc = () => {
    window.location.href = action.data.bookingcomOAuth;
  };
  yield redirectFunc();
}

const isPropertyBookingSyncCompleteHandler = () => {
  const POLLING_STATUS = 1;
  return apiResult => (apiResult.result && apiResult.result.syncStatus) || apiResult.status === POLLING_STATUS;
};

function* bookingComRedirectLandingPageSaga() {
  log.info('Redirecting user to Landing after error page retry click');
  resetExternalSessionApiTokenLocalStorage();
  yield put(push({ pathname: ROUTES.bookingcomPublic }));
}

function* bookingComPilotSignUpNewUserSaga() {
  try {
    const userExternalSessionToken = getExternalSessionApiTokenLocalStorage();
    if (!userExternalSessionToken) {
      log.info('user token does not exist');
      yield put(push({ pathname: ROUTES.bookingcomPublic, search: '?error=true&message=OAUTH_FLOW_NOT_COMPLETE' }));
      return;
    }
    const config = yield call(brandingPilotSignUpNewUser, 'bookingcom', userExternalSessionToken);
    if (config.apiError) {
      if (config.error.userEmail) {
        yield put(setUserEmailAutoPopulate(config.error.userEmail));
      }
      yield put(push({ pathname: ROUTES.bookingcomPublic, search: `?error=true&message=${config.error.errorCode}` }));
      return;
    }
    yield put(setBranchIOLink(config.branchIOLink));
    updateIntercom(config.userObject.objectId);
    trackSignupComplete();
    yield put(hostSignUpSuccess(config.userObject));
    yield call(pollChannel, {
      pullFunc: getPropertyBookingSyncStatus,
      args: ConnectedAccounts.BOOKINGCOM,
      handleEventGenerator: isPropertyBookingSyncCompleteHandler(),
      interval: 2000,
      timeout: 30000,
    });
    yield loginGateSaga({ fromWhere: 'signup', redirectRoute: ROUTES.library });

    const onBoardingLandingPage = yield getCustomBrandindLandingPage(config.userObject.customBrandingId);
    const redirectTo = onBoardingLandingPage.landingPage || ROUTES.landingPage;
    yield put(push({ pathname: redirectTo }));
  } catch (error) {
    log.error(`bookingComPilotSignUpNewUserSaga ${error}`);
    yield put(push({ pathname: ROUTES.bookingcomPublic, search: '?error=true' }));
  }
}

function* saga() {
  yield fork(takeEvery, types.BOOKINGCOM_REDIRECT_OAUTH_PROVIDER_SAGA, redirectToOauthProviderSaga);
  yield fork(takeEvery, types.BOOKINGCOM_PILOT_NEW_USER_SIGNUP_SAGA, bookingComPilotSignUpNewUserSaga);
  yield fork(takeEvery, types.BOOKINGCOM_PILOT_REDIRECT_TO_LANDING_PAGE_SAGA, bookingComRedirectLandingPageSaga);
}

export default saga;
