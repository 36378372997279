"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasCalendarEventJobRequest = hasCalendarEventJobRequest;
exports.hasOnlyCalendarEventJobRequest = hasOnlyCalendarEventJobRequest;
exports.hasCalendarEventReminder = hasCalendarEventReminder;
exports.hasOnlyCalendarEventReminder = hasOnlyCalendarEventReminder;
exports.hasCalendarEventReminderAndJobRequest = hasCalendarEventReminderAndJobRequest;
exports.getCalendarEventJobRequestType = getCalendarEventJobRequestType;
exports.isCalendarEventJobRequestTypeFlexible = isCalendarEventJobRequestTypeFlexible;
exports.getCalendarEventType = getCalendarEventType;
exports.getCalendarEventServiceProviders = getCalendarEventServiceProviders;
exports.getCalendarEventNumberOfServiceProviders = getCalendarEventNumberOfServiceProviders;
exports.getCalendarEventJobRequestId = getCalendarEventJobRequestId;
exports.getCalendarEventJobRequest = getCalendarEventJobRequest;
exports.isCalendarEventDelayed = isCalendarEventDelayed;
exports.getCalendarEventJobRequestTitle = getCalendarEventJobRequestTitle;
exports.isCalendarEventSeen = isCalendarEventSeen;
exports.getCalendarEventJobRequestStatus = getCalendarEventJobRequestStatus;
exports.isCalendarEventJobRequestCanceled = isCalendarEventJobRequestCanceled;
exports.hasCalendarEventProblem = hasCalendarEventProblem;
exports.hasCalendarEventIncomplete = hasCalendarEventIncomplete;
exports.getCalendarEventJobRequestDuration = getCalendarEventJobRequestDuration;
exports.isCalendarEventStarted = isCalendarEventStarted;
exports.isCalendarEventFinished = isCalendarEventFinished;
exports.getCalendarEventJobRequestNumberOfCleaners = getCalendarEventJobRequestNumberOfCleaners;
exports.getCalendarEventJobRequestDisplayTime = getCalendarEventJobRequestDisplayTime;
exports.getCalendarEventReminder = getCalendarEventReminder;
exports.getCalendarEventReminderId = getCalendarEventReminderId;
exports.getCalendarEventReminderTitle = getCalendarEventReminderTitle;
exports.hasCalendarEventCTA = hasCalendarEventCTA;
exports.getCalendarEventReminderUUID = getCalendarEventReminderUUID;
exports.getCalendarEventBooking = getCalendarEventBooking;
exports.getCalendarEventBookingId = getCalendarEventBookingId;
exports.getCalendarEventBookingPartner = getCalendarEventBookingPartner;
exports.getCalendarEventBookingInfoFormatted = getCalendarEventBookingInfoFormatted;
exports.getCalendarEventBookingGuestFormatted = getCalendarEventBookingGuestFormatted;
exports.getCalendarEventBookingDatesFormatted = getCalendarEventBookingDatesFormatted;
exports.getCalendarEventBookingGuestCount = getCalendarEventBookingGuestCount;
exports.getCalendarEventBookingGuestName = getCalendarEventBookingGuestName;
exports.getCalendarEventBookingStatus = getCalendarEventBookingStatus;
exports.getCalendarEventBookingDuration = getCalendarEventBookingDuration;
exports.getCalendarEventBookingTitle = getCalendarEventBookingTitle;
exports.isCalendarEventBookingCanceled = isCalendarEventBookingCanceled;
exports.isCalendarEventBookingChanged = isCalendarEventBookingChanged;
exports.getCalendarEventStartMoment = getCalendarEventStartMoment;
exports.getCalendarEventEndMoment = getCalendarEventEndMoment;
exports.getCalendarEventStartDate = getCalendarEventStartDate;
exports.getCalendarEventStartLocalTime = getCalendarEventStartLocalTime;
exports.getCalendarEventEndLocalTime = getCalendarEventEndLocalTime;
exports.getCalendarEventBookingArrivalTime = getCalendarEventBookingArrivalTime;
exports.getCalendarEventBookingArrivalLocalDay = getCalendarEventBookingArrivalLocalDay;
exports.isCalendarEventBookingArrivalLocalDay = isCalendarEventBookingArrivalLocalDay;
exports.getCalendarEventArrivalMoment = getCalendarEventArrivalMoment;
exports.getCalendarEventArrivalUnix = getCalendarEventArrivalUnix;
exports.getCalendarEventBookingDepartureTime = getCalendarEventBookingDepartureTime;
exports.getCalendarEventBookingDepartureLocalDay = getCalendarEventBookingDepartureLocalDay;
exports.isCalendarEventBookingDepartureLocalDay = isCalendarEventBookingDepartureLocalDay;
exports.getCalendarEventDepartureMoment = getCalendarEventDepartureMoment;
exports.getCalendarEventDepartureUnix = getCalendarEventDepartureUnix;
exports.isCalendarEventBookingPartnerICal = isCalendarEventBookingPartnerICal;
exports.getCalendarEventICalBookingUUID = getCalendarEventICalBookingUUID;
exports.getCalendarEventProperty = getCalendarEventProperty;
exports.getCalendarEventPropertyId = getCalendarEventPropertyId;
exports.getCalendarEventPropertyAddress = getCalendarEventPropertyAddress;

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

var _config = require("@properly/config");

var _localization = _interopRequireDefault(require("@properly/localization"));

var _memoize = _interopRequireDefault(require("lodash/memoize"));

var _index = require("../string/index");

var _index2 = require("../time/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const calendarEventDateFormat = 'YYYYMMDD';
const memoizeDateUnix = (0, _memoize.default)(date => (0, _momentTimezone.default)(date).unix());
/**
 * Types
 */

function hasCalendarEventJobRequest(calendarEvent = {}) {
  return !!getCalendarEventJobRequestId(calendarEvent);
}

function hasOnlyCalendarEventJobRequest(calendarEvent = {}) {
  return hasCalendarEventJobRequest(calendarEvent) && !hasCalendarEventReminder(calendarEvent);
}

function hasCalendarEventReminder(calendarEvent = {}) {
  return !!getCalendarEventReminderId(calendarEvent);
}

function hasOnlyCalendarEventReminder(calendarEvent = {}) {
  return hasCalendarEventReminder(calendarEvent) && !hasCalendarEventJobRequest(calendarEvent);
}

function hasCalendarEventReminderAndJobRequest(calendarEvent = {}) {
  return hasCalendarEventReminder(calendarEvent) && hasCalendarEventJobRequest(calendarEvent);
}

function getCalendarEventJobRequestType(calendarEvent = {}) {
  return getCalendarEventJobRequest(calendarEvent).jobStartTimeType;
}

function isCalendarEventJobRequestTypeFlexible(calendarEvent = {}) {
  return getCalendarEventJobRequestType(calendarEvent) === 'flexible';
}

function getCalendarEventType(calendarEvent = {}) {
  return calendarEvent.type;
}

function getCalendarEventServiceProviders(calendarEvent = {}) {
  return calendarEvent.serviceProviderIds;
}

function getCalendarEventNumberOfServiceProviders(calendarEvent = {}) {
  return (getCalendarEventServiceProviders(calendarEvent) || []).length;
}
/**
 * Job Request
 */


function getCalendarEventJobRequestId(calendarEvent = {}) {
  return getCalendarEventJobRequest(calendarEvent).id;
}

function getCalendarEventJobRequest(calendarEvent = {}) {
  return calendarEvent.jobRequest || {};
}

function isCalendarEventDelayed(calendarEvent = {}) {
  const status = getCalendarEventJobRequestStatus(calendarEvent);
  const isAccepted = status === _config.jobRequestStatus.StateAccepted;

  if (!isAccepted) {
    return false;
  } // If the job is a flexible start time, then it is not delayed until (endtime - duration < currentTime)


  const isFlexible = isCalendarEventJobRequestTypeFlexible(calendarEvent);

  if (isFlexible) {
    const endMoment = getCalendarEventEndMoment(calendarEvent);
    const duration = getCalendarEventJobRequestDuration(calendarEvent);
    return endMoment.subtract(duration, 'mins').isBefore();
  }

  const startDate = getCalendarEventStartDate(calendarEvent);
  return new Date() - startDate > 0;
}

function getCalendarEventJobRequestTitle(calendarEvent = {}) {
  return getCalendarEventJobRequest(calendarEvent).title;
}

function isCalendarEventSeen(calendarEvent = {}) {
  return getCalendarEventJobRequest(calendarEvent).seen;
}

function getCalendarEventJobRequestStatus(calendarEvent = {}) {
  return getCalendarEventJobRequest(calendarEvent).statusCode;
}

function isCalendarEventJobRequestCanceled(calendarEvent = {}) {
  return getCalendarEventJobRequest(calendarEvent).statusCode === _config.jobRequestStatus.StateCancelled;
}

function hasCalendarEventProblem(calendarEvent = {}) {
  return getCalendarEventJobRequest(calendarEvent).problem;
}

function hasCalendarEventIncomplete(calendarEvent = {}) {
  return getCalendarEventJobRequest(calendarEvent).incomplete;
}

function getCalendarEventJobRequestDuration(calendarEvent = {}) {
  return getCalendarEventJobRequest(calendarEvent).duration;
}

function isCalendarEventStarted(calendarEvent = {}) {
  const statusCode = getCalendarEventJobRequestStatus(calendarEvent);
  return statusCode === _config.jobRequestStatus.StateStarted || statusCode === _config.jobRequestStatus.StatePaused;
}

function isCalendarEventFinished(calendarEvent = {}) {
  const statusCode = getCalendarEventJobRequestStatus(calendarEvent);
  return statusCode === _config.jobRequestStatus.StateFinished;
}

function getCalendarEventJobRequestNumberOfCleaners(calendarEvent = {}) {
  const numberFromJobRequest = getCalendarEventJobRequest(calendarEvent).numberOfCleaners;
  const numberFromServiceProviders = getCalendarEventNumberOfServiceProviders(calendarEvent);

  const asNumber = value => Number.isNaN(Number(value)) ? 0 : Number(value);

  return Math.max(asNumber(numberFromServiceProviders), asNumber(numberFromJobRequest));
}

function getCalendarEventJobRequestDisplayTime(calendarEvent = {}) {
  const startMoment = getCalendarEventStartMoment(calendarEvent);
  const endMoment = getCalendarEventEndMoment(calendarEvent);
  const currentTime = (0, _momentTimezone.default)();
  const isAfter = currentTime.isAfter(startMoment);
  const startedLocalizationKey = isAfter ? 'calendar.started_at' : 'calendar.starting_at';
  const durationInMinutes = getCalendarEventJobRequestDuration(calendarEvent);
  const isFlexible = isCalendarEventJobRequestTypeFlexible(calendarEvent);

  if (startMoment && !endMoment) {
    return (0, _localization.default)(startedLocalizationKey, {
      time: startMoment.format('LT')
    });
  }

  const jobStartTimeMoment = isFlexible ? endMoment : startMoment;
  const flexibleLabel = isFlexible ? 'Flex.' : '';
  return `${flexibleLabel} ${jobStartTimeMoment.format('LT')} (${(0, _index2.formatMinHours)(durationInMinutes)})`;
}
/**
 * Reminder
 */


function getCalendarEventReminder(calendarEvent = {}) {
  return calendarEvent.reminder || {};
}

function getCalendarEventReminderId(calendarEvent = {}) {
  const reminder = getCalendarEventReminder(calendarEvent);
  return reminder.reminderId || reminder.id;
}

function getCalendarEventReminderTitle(calendarEvent = {}) {
  return getCalendarEventReminder(calendarEvent).title;
}

function hasCalendarEventCTA(calendarEvent = {}) {
  return !!getCalendarEventReminder(calendarEvent).needsCTA;
}

function getCalendarEventReminderUUID(calendarEvent = {}) {
  const ids = [getCalendarEventType(calendarEvent), getCalendarEventPropertyId(calendarEvent), getCalendarEventPropertyAddress(calendarEvent), // Reminder
  getCalendarEventReminderTitle(calendarEvent), // Booking details
  getCalendarEventBookingPartner(calendarEvent), getCalendarEventBookingDatesFormatted(calendarEvent), getCalendarEventBookingStatus(calendarEvent), getCalendarEventBookingGuestCount(calendarEvent), getCalendarEventBookingGuestName(calendarEvent), getCalendarEventBookingTitle(calendarEvent)];
  return ids.join('--');
}
/**
 * Booking
 */


function getCalendarEventBooking(calendarEvent = {}) {
  return calendarEvent.booking || {};
}

function getCalendarEventBookingId(calendarEvent = {}) {
  return getCalendarEventBooking(calendarEvent).id;
}

function getCalendarEventBookingPartner(calendarEvent = {}) {
  const {
    partner
  } = getCalendarEventBooking(calendarEvent);
  return partner === 'iCal' ? partner : (0, _index.capitalizeFirstLetter)(partner);
}

function getCalendarEventBookingInfoFormatted(calendarEvent = {}, renderGuestString) {
  const partner = getCalendarEventBookingPartner(calendarEvent);
  const partnerString = partner && `${partner}: ` || '';
  const dateString = getCalendarEventBookingDatesFormatted(calendarEvent);
  const guestString = getCalendarEventBookingGuestFormatted(calendarEvent, renderGuestString);
  return [[partnerString, dateString].join(''), guestString].filter(val => !!val).join(' • ').trim();
}

function getCalendarEventBookingGuestFormatted(calendarEvent = {}, renderGuestString) {
  const numberOfGuests = getCalendarEventBookingGuestCount(calendarEvent);
  const guestName = getCalendarEventBookingGuestName(calendarEvent);
  const guestCountString = renderGuestString && numberOfGuests > 0 ? `${renderGuestString(numberOfGuests)}` : '';
  return [guestCountString, guestName].filter(val => !!val).join(' • ').trim();
}

function getCalendarEventBookingDatesFormatted(calendarEvent = {}) {
  const bookingArrivalDate = getCalendarEventArrivalMoment(calendarEvent);
  const bookingDepartureDate = getCalendarEventDepartureMoment(calendarEvent);
  return bookingArrivalDate && bookingDepartureDate && `${bookingArrivalDate.format('L')} – ${bookingDepartureDate.format('L')}` || '';
}

function getCalendarEventBookingGuestCount(calendarEvent = {}) {
  return getCalendarEventBooking(calendarEvent).numberOfGuests;
}

function getCalendarEventBookingGuestName(calendarEvent = {}) {
  return getCalendarEventBooking(calendarEvent).guestName;
}

function getCalendarEventBookingStatus(calendarEvent = {}) {
  return getCalendarEventBooking(calendarEvent).statusCode;
}

function getCalendarEventBookingDuration(calendarEvent = {}) {
  return getCalendarEventBooking(calendarEvent).bookingDurationDays;
}

function getCalendarEventBookingTitle(calendarEvent = {}) {
  const fallback = [getCalendarEventBookingPartner(calendarEvent), getCalendarEventBookingGuestName(calendarEvent)].filter(value => !!value).join(': ');
  return getCalendarEventBooking(calendarEvent).title || fallback || 'Booking';
}

function isCalendarEventBookingCanceled(calendarEvent = {}) {
  const calendarEventBookingStatus = getCalendarEventBookingStatus(calendarEvent);
  return calendarEventBookingStatus === _config.bookingStatus.Canceled;
}

function isCalendarEventBookingChanged(calendarEvent = {}) {
  const calendarEventBookingStatus = getCalendarEventBookingStatus(calendarEvent);
  return calendarEventBookingStatus === _config.bookingStatus.Changed;
}

function getCalendarEventStartMoment(calendarEvent = {}) {
  return _momentTimezone.default.tz(calendarEvent.startTime, calendarEvent.timeZone);
}

function getCalendarEventEndMoment(calendarEvent = {}) {
  return calendarEvent.endTime && _momentTimezone.default.tz(calendarEvent.endTime, calendarEvent.timeZone);
}

function getCalendarEventStartDate(calendarEvent = {}) {
  return getCalendarEventStartMoment(calendarEvent).toDate();
}

function getCalendarEventStartLocalTime(calendarEvent = {}) {
  return getCalendarEventStartMoment(calendarEvent).format('LT');
}

function getCalendarEventEndLocalTime(calendarEvent = {}) {
  const endMoment = getCalendarEventEndMoment(calendarEvent);
  return endMoment && endMoment.format('LT');
}

function getCalendarEventBookingArrivalTime(calendarEvent = {}) {
  return getCalendarEventBooking(calendarEvent).arrivalDate;
}

function getCalendarEventBookingArrivalLocalDay(calendarEvent = {}) {
  const {
    arrivalLocalDay
  } = getCalendarEventBooking(calendarEvent);

  if (!arrivalLocalDay) {
    const arrivalTimeDayMoment = getCalendarEventArrivalMoment(calendarEvent);
    return arrivalTimeDayMoment && arrivalTimeDayMoment.format(calendarEventDateFormat);
  }

  return arrivalLocalDay && `20${arrivalLocalDay}`;
}

function isCalendarEventBookingArrivalLocalDay(calendarEvent = {}, day) {
  const arrivalLocalDay = getCalendarEventBookingArrivalLocalDay(calendarEvent);
  return day === arrivalLocalDay;
}

function getCalendarEventArrivalMoment(calendarEvent = {}) {
  const arrivalTime = getCalendarEventBookingArrivalTime(calendarEvent);
  return arrivalTime && _momentTimezone.default.tz(arrivalTime, calendarEvent.timeZone);
}

function getCalendarEventArrivalUnix(calendarEvent = {}) {
  return memoizeDateUnix(getCalendarEventBookingArrivalTime(calendarEvent));
}

function getCalendarEventBookingDepartureTime(calendarEvent = {}) {
  return getCalendarEventBooking(calendarEvent).departureDate;
}

function getCalendarEventBookingDepartureLocalDay(calendarEvent = {}) {
  const {
    departureLocalDay
  } = getCalendarEventBooking(calendarEvent);

  if (!departureLocalDay) {
    const departureTimeDayMoment = getCalendarEventDepartureMoment(calendarEvent);
    return departureTimeDayMoment && departureTimeDayMoment.format(calendarEventDateFormat);
  }

  return departureLocalDay && `20${departureLocalDay}`;
}

function isCalendarEventBookingDepartureLocalDay(calendarEvent = {}, day) {
  const departureLocalDay = getCalendarEventBookingDepartureLocalDay(calendarEvent);
  return day === departureLocalDay;
}

function getCalendarEventDepartureMoment(calendarEvent = {}) {
  const departureTime = getCalendarEventBookingDepartureTime(calendarEvent);
  return departureTime && _momentTimezone.default.tz(departureTime, calendarEvent.timeZone);
}

function getCalendarEventDepartureUnix(calendarEvent = {}) {
  return memoizeDateUnix(getCalendarEventBookingDepartureTime(calendarEvent));
}

function isCalendarEventBookingPartnerICal(calendarEvent = {}) {
  return getCalendarEventBookingPartner(calendarEvent) === 'iCal';
}

function getCalendarEventICalBookingUUID(calendarEvent = {}) {
  const ids = [getCalendarEventPropertyId(calendarEvent), getCalendarEventBookingPartner(calendarEvent), getCalendarEventBookingDatesFormatted(calendarEvent), getCalendarEventBookingStatus(calendarEvent), getCalendarEventBookingGuestCount(calendarEvent), getCalendarEventBookingGuestName(calendarEvent), getCalendarEventBookingTitle(calendarEvent)];
  return ids.join('--');
}
/**
 * Property
 */


function getCalendarEventProperty(calendarEvent = {}) {
  return calendarEvent.property || {};
}

function getCalendarEventPropertyId(calendarEvent = {}) {
  return getCalendarEventProperty(calendarEvent).id;
}

function getCalendarEventPropertyAddress(calendarEvent = {}) {
  return getCalendarEventProperty(calendarEvent).displayAddress;
}