import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import t from '@properly/localization';
import Grid from '@material-ui/core/Grid';
import { Modal, SquareSelect } from '@properly/components';
import TopLabel from '../../components/TopLabel';
import {
  selectDisableOnBoardingVideo,
  selectCurrentPath,
  selectHasSeenOnBoardingVideo,
} from '../../selectors/globalSelector';
import { selectOnBoardingVideoUrl, selectOnBoardingVideoDisplayPaths } from '../../modules/branding/BrandingSelector';
import { setHasSeenOnBoardingVideo, disableShowOnBoardingVideo } from './actions';

class OnBoardingVideoContainerModal extends PureComponent {
  static propTypes = {
    disableOnBoardingVideo: PropTypes.bool,
    onBoardingVideoURL: PropTypes.string,
    setDisableShowOnBoardingVideo: PropTypes.func,
    setHasSeenOnBoardingVideoAction: PropTypes.func,
    currentPath: PropTypes.string,
    hasSeenOnBoardingVideo: PropTypes.bool,
    onBoardingVideoDisplayPaths: PropTypes.shape({}),
  };

  constructor(props) {
    super(props);

    this.state = { showVideoSelectedCheckbox: false };
  }

  handleCloseOnBoardingVideo = () => {
    const { setDisableShowOnBoardingVideo, setHasSeenOnBoardingVideoAction } = this.props;
    const { showVideoSelectedCheckbox } = this.state;
    setHasSeenOnBoardingVideoAction();
    if (showVideoSelectedCheckbox) {
      setDisableShowOnBoardingVideo(showVideoSelectedCheckbox); // call API
    }
  };

  changeShowOnBoardingVideo = () => {
    this.setState(prev => ({ showVideoSelectedCheckbox: !prev.showVideoSelectedCheckbox }));
  };

  renderOnBoardingVideo(show, selected, videoUrl) {
    return (
      <Modal type="videoModal" show={show} onClose={this.handleCloseOnBoardingVideo}>
        <div style={{ backgroundColor: 'black', textDecorationColor: 'white' }}>
          <iframe
            width="600"
            height="340"
            src={videoUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Booking.com OnBoarding Video"
          />

          <Grid container justify="space-between">
            <div
              style={{
                margin: '15px 0px 0px 10px',
              }}
            >
              <SquareSelect
                label={t('onboarding.bookingcom.dont_show_again')}
                style={{ height: 18, width: 18 }}
                textColor="white"
                type="quarter"
                data-key="do-not-show-again"
                selected={selected}
                onChange={this.changeShowOnBoardingVideo}
              />
            </div>
            <TopLabel label="Close" onClick={this.handleCloseOnBoardingVideo} />
          </Grid>
        </div>
      </Modal>
    );
  }

  render() {
    const { showVideoSelectedCheckbox } = this.state;
    const {
      disableOnBoardingVideo,
      hasSeenOnBoardingVideo,
      onBoardingVideoURL,
      currentPath,
      onBoardingVideoDisplayPaths,
    } = this.props;
    let renderOnBoardingVideo = false;

    if (currentPath && onBoardingVideoDisplayPaths && onBoardingVideoDisplayPaths.count) {
      const absolutePath = currentPath.split('/')[1];
      const onBoardingVideoDisplayPathsJS = onBoardingVideoDisplayPaths?.toJS();
      if (onBoardingVideoDisplayPathsJS.includes(absolutePath)) {
        renderOnBoardingVideo = true;
      }
    }

    return (
      <div>
        {!disableOnBoardingVideo &&
          !hasSeenOnBoardingVideo &&
          onBoardingVideoURL &&
          renderOnBoardingVideo &&
          this.renderOnBoardingVideo(!disableOnBoardingVideo, showVideoSelectedCheckbox, onBoardingVideoURL)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    disableOnBoardingVideo: selectDisableOnBoardingVideo()(state), // boolean
    onBoardingVideoURL: selectOnBoardingVideoUrl()(state), // string
    currentPath: selectCurrentPath()(state),
    onBoardingVideoDisplayPaths: selectOnBoardingVideoDisplayPaths()(state),
    hasSeenOnBoardingVideo: selectHasSeenOnBoardingVideo()(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setHasSeenOnBoardingVideoAction: () => dispatch(setHasSeenOnBoardingVideo()), // action (func)
    setDisableShowOnBoardingVideo: toggle => dispatch(disableShowOnBoardingVideo(toggle)), // action (func)
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(OnBoardingVideoContainerModal);
