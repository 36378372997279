import React from 'react';
import Textarea from 'react-textarea-autosize';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const ChecklistTextArea = ({ ...more }) => <Textarea {...more} className={cx('textarea')} />;

export default ChecklistTextArea;
