import React from 'react';
import { Route } from 'react-router';
import { isMobileTouch, setWindowTitle } from '@properly/common';
import log from 'loglevel';
import { lazyWithPreload } from './hoc/LazyWithPreload';

const BookingComContainer = lazyWithPreload(() =>
  import('./modules/onboarding/bookingcom/containers/BookingComContainer'),
);
BookingComContainer.preload();

const recordMobileViewedKey = 'PROPERLY_recordMobileConnectionsViewed';
const recordMobileConnectionsViewed = () => {
  try {
    localStorage.setItem(recordMobileViewedKey, 'true');
  } catch (err) {
    log.error(err);
  }
};

const hasViewedMobileConnections = () => {
  try {
    return !!localStorage.getItem(recordMobileViewedKey);
  } catch (err) {
    log.error(err);
    return true;
  }
};
const isMobileAppIFrameLoad = hasViewedMobileConnections() && isMobileTouch();
log.info('isMobileAppIFrameLoad', isMobileAppIFrameLoad);

export default () => (
  <>
    <Route
      exact
      path="public/bookingcom/connect"
      component={props => {
        recordMobileConnectionsViewed();
        return <BookingComContainer mobile={isMobileAppIFrameLoad} {...props} />;
      }}
      onEnter={setWindowTitle('Booking.com')}
    />
  </>
);
