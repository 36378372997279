"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  MILEAGE: 'MILEAGE',
  COST: 'COST',
  DURATION: 'DURATION'
};
exports["default"] = _default;