import log from 'loglevel';
import { guid } from '@properly/common';
import { ApolloClient } from 'apollo-client';
import { split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { getConfigSync } from '@properly/config';
import Parse from 'parse';
import unfetch from 'unfetch';

const config = getConfigSync();
export const getToken = () => (Parse.User.current() ? Parse.User.current().getSessionToken() : null);

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    'X-Parse-Request-UUID': guid(),
    'x-parse-session-token': getToken(),
  },
}));

// subscription query errors are handled here, as they are difficult to handle elsewhere
const graphQLErrorHandler = onError(({ operation, response, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    log.error(`[ERROR]: GraphQL error trying to execute ${operation.operationName}:`, graphQLErrors);
    if (operation.query.definitions.some(definition => definition.operation === 'subscription')) {
      // as we've got a subscription, we'll ignore the error in order to prevent
      // our app from crashing.
      response.errors = undefined;
    }
  }
  if (networkError) {
    log.error(`[ERROR]: GraphQL network error trying to execute ${operation.operationName}:`, networkError);
  }
});

const dataIdFromObject = doc => {
  // eslint-disable-next-line
  switch (doc.__typename) {
    default:
      return defaultDataIdFromObject(doc);
  }
};

const cache = new InMemoryCache({
  dataIdFromObject: doc => dataIdFromObject(doc),
});

// Create an http link:
const httpLink = new HttpLink({ uri: config.API_GRAPHQL_BASE_URL });

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: config.API_GRAPHQL_BASE_SUBSCRIPTION_URL,
  options: {
    reconnect: true,
    connectionParams: () => ({
      // we can't supply custom headers with a web socket request,
      // so we add the headers in the connectionParams such that they will be
      // used for authentication in the onConnect handler server-side.
      'X-Parse-Request-UUID': guid(),
      'x-parse-session-token': getToken(),
    }),
  },
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

export const client = new ApolloClient({
  link: authLink.concat(graphQLErrorHandler).concat(link),
  cache,
  fetch: unfetch,
});
