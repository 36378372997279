import gql from 'graphql-tag';

export const MUTATION_ADD_CONTACT_BY_ID = gql`
  mutation AddContactById($contactId: String!) {
    AddContactById(contactId: $contactId) {
      id
      contactId
      ownerId
      profileId
      userData {
        email
        firstName
        lastName
        shortName
        objectId
        signedUp
        signedUpDate
        phoneCountryCode
        phoneRegionalNumber
        phone
        bio
        pictureUrl
      }
      deleted
      createdAt
      updatedAt
    }
  }
`;

export const MUTATION_REMOVE_CONTACT_BY_ID = gql`
  mutation RemoveContact($contactId: String!) {
    RemoveContact(contactId: $contactId) {
      id
      contactId
      ownerId
      profileId
      userData {
        email
        firstName
        lastName
        shortName
        objectId
        signedUp
        signedUpDate
        phoneCountryCode
        phoneRegionalNumber
        phone
        bio
        pictureUrl
      }
      deleted
      createdAt
      updatedAt
    }
  }
`;
