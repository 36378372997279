import React, { PureComponent } from 'react';
import uniqueId from 'lodash/uniqueId';
import omit from 'lodash/omit';
import classNames from 'classnames/bind';
import styles from './checkoutStyles.module.css';
import CheckoutLabel from '../../../components/CheckoutLabel';

const cx = classNames.bind(styles);

class CheckoutInputNormal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { focus: false };
    this.uniqueId = uniqueId();
    this.blacklist = ['label', 'isRequired', 'error', 'msg', 'icon', 'recurlyField'];
  }

  setFocus = val => () => {
    this.setState({ focus: val });
  };

  renderWhat() {
    if (this.props.children) return this.props.children;
    if (this.props.recurlyField) return null;
    return (
      <div
        className={cx('recurly-hosted-field', {
          'recurly-hosted-field-focus': this.state.focus,
        })}
      >
        <input
          id={this.uniqueId}
          className={cx('checkout__input')}
          onFocus={this.setFocus(true)}
          onBlur={this.setFocus(false)}
          {...omit(this.props, this.blacklist)}
        />
      </div>
    );
  }

  render() {
    const params = this.props.recurlyField ? omit(this.props, this.blacklist) : {};
    return (
      <CheckoutLabel
        label={this.props.label}
        isRequired={this.props.isRequired}
        icon={this.props.icon}
        id={this.uniqueId}
      >
        <div
          className={cx('checkout__recurlyfield', {
            'checkout__recurlyfield--error': this.props.error,
          })}
          {...params}
        >
          {this.renderWhat()}
        </div>
        <div
          className={cx('checkout__input-error', {
            'checkout__input-error--grey': this.props.msg,
          })}
        >
          <div>
            {this.props.error}
            {this.props.msg}
          </div>
        </div>
      </CheckoutLabel>
    );
  }
}

const CheckoutInput = props => <CheckoutInputNormal {...props} recurlyField />;

export { CheckoutInput, CheckoutInputNormal, CheckoutLabel };
