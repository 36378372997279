import React, { Component } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import Scroll from 'react-scroll';
import { DividerLine, Icon } from '@properly/components';
import classNames from 'classnames/bind';
import styles from '../components/checklistsStyles.module.css';
import * as selectors from '../state/ChecklistSelectors';
import Sidebar from '../../../../components/Sidebar/index';
import ChecklistSidebarItem from '../../../../components/ChecklistSidebarItem/index';
import DragField from '../components/ChecklistField';
import { setTileView, stepMutatorSaga, setTextTemplate, setUploadState } from '../state/ChecklistActions';

const cx = classNames.bind(styles);

class ChecklistSidebar extends Component {
  goToSection(id) {
    const { scroller } = Scroll;
    scroller.scrollTo(id, {
      duration: 1500,
      delay: 100,
      smooth: true,
      containerId: 'checklist-scroll-main',
    });
  }

  moveCard(jobInstructionId, a, b) {
    this.props.stepMutatorSaga('moveStep', {
      jobInstructionId,
      fromIndex: a,
      toIndex: b,
    });
  }

  renderInner() {
    const { jobInstructionId, selectedChecklist, isReadOnly, activeChecklist } = this.props;

    if (!jobInstructionId) {
      return null;
    }

    const ident = 'sidebar';
    const steps = (selectedChecklist && selectedChecklist.steps) || [];
    return (
      <div>
        {map(steps, (step, i) => (
          <DragField
            isReadOnly={isReadOnly}
            key={step.objectId}
            id={step.objectId}
            moveCard={(a, b) => this.moveCard(jobInstructionId, a, b)}
            index={i}
          >
            <div onClick={() => this.goToSection(step.objectId)}>
              <ChecklistSidebarItem
                needsVerify={step.isVerificationNeeded}
                identifier={ident}
                title={step.title}
                active={activeChecklist === step.objectId}
                steps={step.tasks}
                image={step.pictureUrl}
              />
            </div>
          </DragField>
        ))}
      </div>
    );
  }

  render() {
    const { isReadOnly } = this.props;
    return (
      <div style={{ height: '100%' }}>
        <Sidebar>
          {!isReadOnly && (
            <div
              data-key="checklist_overview_btn"
              onClick={() => this.props.setTileView({ isOpen: true })}
              className={cx('checklist__sidebar-btn')}
            >
              <Icon.IcGrid />
              <DividerLine type={['bottom', 'dark']} />
            </div>
          )}
          <Sidebar.List>
            <Sidebar.ListInner>
              <Sidebar.ListInnerInner>{this.renderInner()}</Sidebar.ListInnerInner>
            </Sidebar.ListInner>
          </Sidebar.List>
        </Sidebar>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const selectedChecklist = props.jobInstructionId
    ? selectors
        .selectJobInstructions()(state)
        .toJS()[props.jobInstructionId]
    : undefined;
  return {
    activeChecklist: selectors.selectActiveChecklist()(state, props),
    selectedChecklist,
  };
}

export default connect(
  mapStateToProps,
  {
    setTileView,
    stepMutatorSaga,
    setTextTemplate,
    setUploadState,
  },
)(ChecklistSidebar);
