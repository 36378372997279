import log from 'loglevel';
import locale from 'browser-locale';

import { client } from '../../config/apollo';
import {
  GET_COMPLETED_SKILLS_QUERY,
  GET_SKILLS_QUERY,
  GET_SPECIFIC_SKILL_QUERY,
  GET_COMPLETED_SKILLS_QUERY_ONLY_IDS,
} from '../queries/skills';

export const getCompletedSkills = async (cleanerId, fetchOnlyIds) => {
  try {
    const getCompletedSkillsResponse = await client.query({
      query: fetchOnlyIds ? GET_COMPLETED_SKILLS_QUERY_ONLY_IDS : GET_COMPLETED_SKILLS_QUERY,
      variables: {
        cleanerId,
        language: locale(),
      },
      fetchPolicy: 'no-cache',
    });
    if (fetchOnlyIds) {
      return getCompletedSkillsResponse?.data?.getCompletedSkills?.map(({ skillId }) => skillId);
    }
    return getCompletedSkillsResponse?.data?.getCompletedSkills;
  } catch (error) {
    log.error(`[getCompletedSkills] ${error}`);
    error.apiError = true;
    return error;
  }
};

export const getSkills = async (limit, offset) => {
  try {
    const getSkillsResponse = await client.query({
      query: GET_SKILLS_QUERY,
      variables: {
        includeCompletionStatus: true,
        language: locale(),
        limit,
        offset,
      },
      fetchPolicy: 'no-cache',
    });
    return getSkillsResponse?.data?.fetchSkills;
  } catch (error) {
    log.error(`[getSkills] ${error}`);
    error.apiError = true;
    return error;
  }
};

export const getSkill = async skillId => {
  try {
    const getSkillResponse = await client.query({
      query: GET_SPECIFIC_SKILL_QUERY,
      variables: {
        skillId,
        language: locale(),
      },
      fetchPolicy: 'no-cache',
    });
    return getSkillResponse?.data?.fetchSkill;
  } catch (error) {
    log.error(`[getSkill] ${error}`);
    error.apiError = true;
    return error;
  }
};
