import { put, takeEvery } from 'redux-saga/effects';
import { showIntercom, hideIntercom } from '@properly/common';
import * as globalActions from '../../actions/globalActions';
import * as types from '../../types';
import { guardedFork } from '../../helper/saga';

import GlobalPreloadSagas from './sagas.global.preload';
import GlobalUserSagas from './sagas.global.user';
import GlobalGuardsSagas from './sagas.global.guards';

// TODO: put together with the slider component
// TODO: update only the changed index
function* setSlideSaga(action) {
  if (action.open) {
    hideIntercom();
  } else {
    showIntercom();
  }
  yield put(
    globalActions.setSliderData({
      isOpen: action.open,
      images: action.images,
      index: action.index,
      meta: action.meta || {},
    }),
  );
}

export default function* saga() {
  yield guardedFork(GlobalPreloadSagas);
  yield guardedFork(GlobalUserSagas);
  yield guardedFork(GlobalGuardsSagas);

  yield guardedFork(takeEvery, types.GLOBAL_SET_SLIDER_SAGA, setSlideSaga);
}
