import ContactSagas from './contacts/state/ContactSagas';
import PropertySagas from './property/state/PropertySagas';
import PropertyTrackingSagas from './property/state/PropertySagas.tracking';
import PropertyConnectionsSagas from './property/state/PropertySagas.connections';
import JobDetailsSaga from './jobDetails/state/JobDetailsSagas';
import ChecklistSagas from './checklists/state/ChecklistSagas';
import CalendarSagasActions from './calendar/state/CalendarSagas.actions';
import CalendarSagasLoading from './calendar/state/CalendarSagas.loading';
import CalendarSagasSync from './calendar/state/CalendarSagas.sync';
import CalendarSagasTracking from './calendar/state/CalendarSagas.tracking';
import AccountSagas from './account/state/AccountSagas';
import ReportsSagas from './reports/ReportsSagas';
import PermissionsSagas from './permissions/PermissionsSagas';
import TodosSaga from './todos/TodosSagas';
import JobRequestSagasNew from './jobRequest/state/JobRequestSagas';
import PricingSagas from './pricing/PricingSagas';
import StripeSPSagas from './stripeSP/state/StripeSPSagas';
import LibrarySagas from './library/state/library.sagas';
import GlobalSagas from './sagas.global';
import { guardedFork } from '../../helper/saga';

export default function* saga() {
  yield guardedFork(GlobalSagas);

  yield guardedFork(PropertySagas);
  yield guardedFork(PropertyConnectionsSagas);
  yield guardedFork(PropertyTrackingSagas);
  yield guardedFork(JobDetailsSaga);
  yield guardedFork(TodosSaga);
  yield guardedFork(ContactSagas);
  yield guardedFork(AccountSagas);
  yield guardedFork(ChecklistSagas);
  yield guardedFork(JobRequestSagasNew);
  yield guardedFork(CalendarSagasActions);
  yield guardedFork(CalendarSagasLoading);
  yield guardedFork(CalendarSagasSync);
  yield guardedFork(CalendarSagasTracking);
  yield guardedFork(ReportsSagas);
  yield guardedFork(PricingSagas);
  yield guardedFork(PermissionsSagas);
  yield guardedFork(StripeSPSagas);
  yield guardedFork(LibrarySagas);
}
