"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  PROGRESS_STATUS: {
    COMPLETED: 'COMPLETED',
    IN_PROGRESS: 'IN_PROGRESS',
    REVIEW_REQUIRED: 'REVIEW_REQUIRED',
    RECOMPLETE_REQUIRED: 'RECOMPLETE_REQUIRED'
  },
  DEPENDENCY_STATUS: {
    REQUIRED: 'REQUIRED',
    OPTIONAL: 'OPTIONAL'
  },
  LIFECYCLE_STATUS: {
    PENDING: 'PENDING',
    IN_REVIEW: 'IN_REVIEW',
    PUBLISHED: 'PUBLISHED',
    PUBLISHED_HIDDEN: 'PUBLISHED_HIDDEN',
    UNPUBLISHED: 'UNPUBLISHED',
    ARCHIVED: 'ARCHIVED'
  }
};
exports["default"] = _default;