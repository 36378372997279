import isArray from 'lodash/isArray';
import forOwn from 'lodash/forOwn';
import lodashResult from 'lodash/result';

export default class Property {
  constructor(
    objectId,
    title,
    address,
    location,
    pictureUrl,
    timeZone,
    countryCode,
    numOfBedrooms,
    numOfBeds,
    numOfBathrooms,
    type,
    deleted,
    detailsAccess,
    detailsGarbage,
    detailsParking,
    detailsWifiName,
    detailsWifiDescription,
    detailsWifiPassword,
    detailsInformation,
    userId,
    pictureUrls,
    role,
    noMerge,
    mergedTo,
    partner,
    partnerPropertyId,
    defaultJob,
    hasUserTitle,
    sample,
    ownerRole,
    propertyRole,
    userData,
    toBeDeleted,
    alexaDetails,
    otherAttributes,
    bedConfiguration,
    tags,
    proMarketId,
    bookingComSanctionedProperty,
    createdAt,
  ) {
    this.objectId = objectId;
    this.title = title;
    this.address = address;
    this.location = location;
    this.pictureUrl = pictureUrl;
    this.timeZone = timeZone;
    this.countryCode = countryCode;
    this.numOfBedrooms = numOfBedrooms;
    this.numOfBeds = numOfBeds;
    this.numOfBathrooms = numOfBathrooms;
    this.type = type;
    this.deleted = deleted;
    this.detailsAccess = detailsAccess;
    this.detailsGarbage = detailsGarbage;
    this.detailsParking = detailsParking;
    this.detailsWifiName = detailsWifiName;
    this.detailsWifiDescription = detailsWifiDescription;
    this.detailsWifiPassword = detailsWifiPassword;
    this.detailsInformation = detailsInformation;
    this.userId = userId;
    this.pictureUrls = pictureUrls;
    this.role = role;
    this.noMerge = noMerge;
    this.mergedTo = mergedTo;
    this.partner = partner;
    this.partnerPropertyId = partnerPropertyId;
    this.defaultJob = defaultJob;
    this.hasUserTitle = hasUserTitle;
    this.sample = sample;
    this.ownerRole = ownerRole;
    this.propertyRole = propertyRole;
    this.userData = userData;
    this.toBeDeleted = toBeDeleted;
    this.alexaDetails = alexaDetails;
    this.otherAttributes = otherAttributes;
    this.bedConfiguration = bedConfiguration;
    this.tags = tags;
    this.bookingComSanctionedProperty = bookingComSanctionedProperty;
    this.createdAt = createdAt;
    this.proMarketProperty = !!proMarketId;
  }

  setData(obj) {
    this.getOwnProps(obj, this);
  }

  getData() {
    return this.getOwnProps(this);
  }

  notDefined(val) {
    return !val || val === '';
  }

  validate() {
    const errors = {};
    if (this.notDefined(this.title)) errors.title = true;
    if (this.notDefined(this.address.street)) errors['address.street'] = true;
    if (this.notDefined(this.address.zip)) errors['address.zip'] = true;
    if (this.notDefined(this.address.country)) errors['address.country'] = true;
    return errors;
  }

  get ownerName() {
    return lodashResult(this, ['userData', 'fullNameCompany'], 'Unknown');
  }

  getOwnProps(scope, onScope) {
    const obj = onScope || {};
    forOwn(scope, (value, key) => {
      obj[key] = value;
    });
    return obj;
  }

  getId() {
    return this.objectId;
  }

  getTitle() {
    return this.title;
  }

  getCity() {
    if (this.address && this.address.city) {
      return this.address.city;
    }
    return '';
  }

  getTimeZone() {
    return this.timeZone || 'America/Los_Angeles';
  }

  getLong() {
    return this.location.longitude;
  }

  getLat() {
    return this.location.latitude;
  }

  get propertyAddress() {
    return lodashResult(this.address, ['AddressArray'], []).join(', ');
  }

  get hasAlexaSetup() {
    return !!lodashResult(this.alexaDetails, ['deviceArn']);
  }

  getSearchString() {
    if (this.title) return this.title;
    if (this.address && this.address.AddressArray && isArray(this.address.AddressArray)) {
      return this.address.AddressArray[0];
    }
    return '';
  }

  getAddress() {
    return this.address;
  }
}
