import { createSelector } from 'reselect';
import { cleanerStatus } from '@properly/config';
import * as selectorsGlobal from '../../selectors/globalSelector';

export const selectCleanerRequestsByContact = () =>
  createSelector(
    selectorsGlobal.selectCleanerRequests(),
    (state, props) => props.contactId,
    (cleanerRequests, contactId) => {
      const cleanerRequestJS = (cleanerRequests && cleanerRequests.toJS()) || {};
      if (cleanerRequestJS) {
        return Object.values(cleanerRequestJS || {}).filter(cleanerRequest => {
          const cleanerMapper = cleanerRequest.requestedCleaners.map(
            cleaner => cleaner.id === contactId && cleaner.status !== cleanerStatus.AcceptedByOtherCleaner,
          );
          if (cleanerMapper.includes(true)) return true;
          return false;
        });
      }
      return null;
    },
  );
