import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Input, SpaceBase, SpacedHeadline } from '@properly/components';
import { generateAlexaAccessKeys } from '../../data';
import { selectGlobalModalResolved } from '../../../../selectors/globalSelector';
import { setModal } from '../../../../actions/globalActions';

export const MODAL_ALEXA_NAME = 'alexaModal';

class SettingsAlexaModal extends Component {
  /* eslint-disable */
  state = {
    loadingAlexa: false,
    alexaData: {},
  };

  setupAlexa = () => {
    this.setState({ loadingAlexa: true });
    generateAlexaAccessKeys()
      .then(res => {
        this.setState({ loadingAlexa: false, alexaData: res });
      })
      .catch(() => {
        this.setState({ loadingAlexa: false, alexaData: {}, error: true });
      });
  };
  /* eslint-enable */

  componentDidMount() {
    this.setupAlexa();
  }

  render() {
    return (
      <div style={{ padding: '24px' }}>
        <Modal.H1>Set up Devices</Modal.H1>
        <Modal.Text>
          Enter these tokens in your Alexa for Business Setup tool and follow the application flow. After the set up,
          connect each alexa device to a property under property &gt; connections.
        </Modal.Text>
        <SpaceBase />
        <SpacedHeadline>AccessKeyId</SpacedHeadline>
        <Input isFirst isLast value={this.state.alexaData.AccessKeyId} type="text" disabled />
        <SpacedHeadline>SecretAccessKey</SpacedHeadline>
        <Input isFirst isLast value={this.state.alexaData.SecretAccessKey} type="text" disabled />
        {this.state.error && <Input.ErrorMsg>Could not generate tokens.</Input.ErrorMsg>}
        <Modal.Options>
          <Modal.Ok onClick={this.props.onClose}>Close</Modal.Ok>
        </Modal.Options>
      </div>
    );
  }
}

class SettingsAlexaModalContainer extends Component {
  closeAlexaModal = () => this.props.setModal(false, MODAL_ALEXA_NAME, {});

  render() {
    return (
      <Modal id="alexa" height={500} onClose={this.closeAlexaModal} show={!!this.props.alexaModal.isOpen}>
        <SettingsAlexaModal onClose={this.closeAlexaModal} />
      </Modal>
    );
  }
}

export default connect(
  () => {
    const alexaModal = selectGlobalModalResolved(MODAL_ALEXA_NAME);
    return (state, props) => ({
      alexaModal: alexaModal(state, props),
    });
  },
  {
    setModal,
  },
)(SettingsAlexaModalContainer);
