import * as types from '../../types';

export function brandingConfigSaga(domainName, utmSource) {
  return {
    type: types.BRANDING_CONFIG_SAGA,
    domainName,
    utmSource,
  };
}

export function setBrandingConfig(data) {
  return {
    type: types.BRANDING_CONFIG_SET,
    data,
  };
}

export function redirectToDownloadAppPage() {
  return {
    type: types.BRANDING_REDIRECT_TO_DOWNLOAD_APP_PAGE,
  };
}

export function setBranchIOLink(data) {
  return {
    type: types.SET_BRANCH_IO_LINK,
    data,
  };
}
