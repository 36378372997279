import log from 'loglevel';
import { client as apolloClient } from '../../../../config/apollo';
import { MergePropertiesQuery } from './PropertyQuery';

export function mergeProperties(propertyIds) {
  return apolloClient
    .mutate({
      mutation: MergePropertiesQuery,
      fetchPolicy: 'no-cache',
      variables: {
        propertyIds,
      },
    })
    .then(({ data, error }) => {
      if (error) {
        log.error('MergePropertyApi - Error merging properties', propertyIds);
        throw error;
      }

      const { MergeProperties } = data || {};

      return MergeProperties;
    });
}
