import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, mapProps } from 'recompose';

import pick from 'lodash/pick';
import noop from 'lodash/noop';
import t from '@properly/localization';
import { mapToMainState } from './permissionhelper';
import { permissionMap } from './PermissionsConstants';
import { JobRequestTextSub } from '../../../components/JobRequestElements';
import PermissionList from './PermissionList';

function mapCollType(type) {
  switch (type) {
    case 'option1':
      return t('permissions.internal_coll', { context: 1 });
    case 'option2':
      return t('permissions.external_coll', { context: 1 });
    default:
      return 'Not defined';
  }
}
function mapPerType(type) {
  switch (type) {
    case 'option1':
      return t('permissions.modal.full_access');
    case 'option2':
      return t('permissions.modal.specific_access');
    default:
      return 'Not defined';
  }
}
function mapTypeType(type) {
  switch (type) {
    case 'setupsupport':
      return t('permissions.modal.setup_support');
    case 'owneraccess':
      return t('permissions.modal.owner_access');
    case 'spaccess':
      return t('permissions.modal.service_provider_access');
    default:
      return 'Not defined';
  }
}

class PermissionModalSidebarContent extends PureComponent {
  static propTypes = {
    sectionId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    propertyViewRights: {
      get: () => false,
    },
  };

  render() {
    if (this.props.sectionId === 'email') {
      return <JobRequestTextSub cutText>{this.props.sectionValue.get('email')}</JobRequestTextSub>;
    }
    if (this.props.sectionId === 'email_company') {
      return <JobRequestTextSub cutText>{this.props.sectionValue.get('email')}</JobRequestTextSub>;
    }
    if (this.props.sectionId === 'type') {
      return <JobRequestTextSub cutText>{mapTypeType(this.props.sectionValue.get('type'))}</JobRequestTextSub>;
    }
    if (this.props.sectionId === 'collType') {
      return <JobRequestTextSub cutText>{mapCollType(this.props.sectionValue.get('type'))}</JobRequestTextSub>;
    }
    if (this.props.sectionId === 'permissions') {
      return <JobRequestTextSub cutText>{mapPerType(this.props.sectionValue.get('type'))}</JobRequestTextSub>;
    }
    if (this.props.sectionId === 'property') {
      return <JobRequestTextSub cutText>{mapPerType(this.props.sectionValue.get('type'))}</JobRequestTextSub>;
    }
    if (this.props.sectionId === 'seeper') {
      return (
        <PermissionList
          permissionMap={permissionMap}
          noSpacingFirst
          keyPrefix="sidebarmodal"
          onChangeFunc={() => noop}
          isDisabled={() => true}
          isSelected={permissionId => this.props.propertyViewRights.get(permissionId)}
        />
      );
    }
    return <div>{this.props.sectionId}</div>;
  }
}

PermissionModalSidebarContent.propTypes = {
  propertyViewRights: PropTypes.bool,
};

export default compose(
  connect(
    mapToMainState,
    {},
  ),
  mapProps(props => ({
    ...props.sectionsById[props.id],
    propertyViewRights: props.selectSubmitDataRaw.getIn(['view', 'rights']),
  })),
  mapProps(props => pick(props, ['sectionId', 'sectionValue', 'propertyViewRights'])),
)(PermissionModalSidebarContent);
