import React from 'react';
import PropTypes from 'prop-types';
import { Route, IndexRoute } from 'react-router';
import { setWindowTitle, isMobileTouch } from '@properly/common';
import { DownloadAppPageDesktop, DownloadAppPageMobile } from '@properly/components';

import lazyLoadComponent from './containers/LazyLoadComponent';

import LoginComponent from './modules/loginSignUp/LoginComponent';
import HostSignUpFormComponent from './modules/loginSignUp/HostSignUpFormComponent';

import HostSignUpAccountsComponentAdaptor from './modules/loginSignUp/HostSignUpAccountsComponentAdaptor';
import HostSignUpDownloadComponent from './modules/loginSignUp/HostSignUpDownloadComponent';
import HostSignUpCompleteComponent from './modules/loginSignUp/HostSignUpCompleteComponent';
import ResetPassword from './modules/loginSignUp/ResetPassword';
import NewPassword from './modules/loginSignUp/NewPassword';
import LoginAsComponent from './modules/loginSignUp/LoginAsComponent';

import SubscriptionContainer from './modules/desktopApp/mobileOnly/SubscriptionContainer';
import UpgradeMobileContainer from './modules/desktopApp/mobileOnly/UpgradeMobileContainer';

const DownloadAppPage = props =>
  isMobileTouch() ? (
    <DownloadAppPageMobile userEmail={props.params.userEmail} />
  ) : (
    <DownloadAppPageDesktop userEmail={props.params.userEmail} />
  );

export default () => (
  <>
    <IndexRoute
      component={lazyLoadComponent(HostSignUpFormComponent, 'login')}
      onEnter={setWindowTitle('titles.signup')}
    />
    <Route
      path="hostSignUpForm"
      component={lazyLoadComponent(HostSignUpFormComponent, 'login')}
      onEnter={setWindowTitle('titles.signup')}
    />

    <Route
      path="hostSignUpAccounts"
      component={lazyLoadComponent(HostSignUpAccountsComponentAdaptor, 'login')}
      onEnter={setWindowTitle('titles.import_accounts')}
    />

    <Route
      path="hostSignUpDownload"
      component={lazyLoadComponent(HostSignUpDownloadComponent, 'login')}
      onEnter={setWindowTitle('titles.download_app')}
    />
    <Route
      path="hostSignUpComplete"
      component={lazyLoadComponent(HostSignUpCompleteComponent, 'login')}
      onEnter={setWindowTitle('titles.signup_complete')}
    />
    <Route
      path="login"
      component={lazyLoadComponent(LoginComponent, 'login')}
      onEnter={setWindowTitle('titles.login')}
    />
    <Route
      path="password/reset"
      component={lazyLoadComponent(ResetPassword, 'login')}
      onEnter={setWindowTitle('titles.reset_password')}
    />
    <Route
      path="password/new"
      component={lazyLoadComponent(NewPassword, 'login')}
      onEnter={setWindowTitle('titles.set_password')}
    />

    <Route exact path="loginas" component={LoginAsComponent} />
    <Route exact path="loginas/:id" component={LoginAsComponent} />
    <Route path="loginas/:id/:redirectRoute*" component={LoginAsComponent} />
    <Route path="mobile/subscription" component={SubscriptionContainer} />
    <Route path="mobile/upgrade" component={UpgradeMobileContainer} />
    <Route path="entry" component={props => <LoginAsComponent isEntry params={props.location} />} />
    <Route exact path="branding/mobile/downloadApp/:userEmail" component={DownloadAppPage} />
  </>
);

DownloadAppPage.propTypes = {
  params: PropTypes.shape({
    userEmail: PropTypes.string.isRequired,
  }).isRequired,
};
