import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, SpaceXLarge, LoadingSplash } from '@properly/components';
import t from '@properly/localization';

import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import styles from './propertyStyles.module.css';
import { mountEventsPageSaga, addEventSaga } from '../state/PropertyActions';
import * as selectors from '../state/PropertySelectors';
import EventContainer from '../../../../containers/EventContainer/index';
import { selectTrigger } from '../../../../selectors/globalSelector';
import { selectLoadingStates } from '../../checklists/state/ChecklistSelectors';
import Property from '../../../../model/property';

const cx = classNames.bind(styles);

class PropertyDetailTriggerContainer extends PureComponent {
  init() {
    this.props.mountEventsPageSaga(this.props.property, false);
    this.props.mountEventsPageSaga(this.props.property, true);
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.property !== this.props.property) {
      this.init();
    }
  }

  renderLoading() {
    return (
      <div className={cx('property-detail__loadingcheck')}>
        <LoadingSplash static />
      </div>
    );
  }

  addEvent = () => {
    this.props.addEventSaga(this.props.propertyData);
  };

  renderTop() {
    return (
      <div>
        <div className={cx('property-detail__addwrap')}>
          <div data-key="add_reminders" className={cx('property-detail__add')} onClick={this.addEvent}>
            {t('properties.add_automation')}
          </div>
        </div>
        <div className={cx('property-detail__eventtxt')}>{t('properties.events_info')}</div>
      </div>
    );
  }

  get isLoading() {
    return this.props.meta.get('state') === 1;
  }

  get isDeleteLoading() {
    return this.props.meta.get('deleteState') === 1;
  }

  get orderedTrigger() {
    const filered = this.props.trigger.filter(job => !job.deleted && job.propertyId === this.props.property);
    return orderBy(filered?.toJS(), [ele => ele.isUnsaved, 'createdAt'], ['desc', 'desc']);
  }

  get isChecklistLoading() {
    return this.props.checklistLoadingStates.get(`create${this.props.property}`) === 1;
  }

  render() {
    if (this.isLoading) {
      return this.renderLoading();
    }

    return (
      <div>
        {this.renderTop()}
        {map(this.orderedTrigger, trigger => (
          <EventContainer key={trigger.objectId} trigger={trigger} propertyData={this.props.propertyData} />
        ))}
        <Modal type="loading" show={this.isChecklistLoading || this.isDeleteLoading} />
        <SpaceXLarge />
      </div>
    );
  }
}

PropertyDetailTriggerContainer.propTypes = {
  property: PropTypes.string.isRequired,
  propertyData: PropTypes.instanceOf(Property).isRequired,
};

function mapStateToProps(state, props) {
  return {
    meta: selectors.propertiesEventDataMeta()(state),
    trigger: selectTrigger()(state),
    checklistLoadingStates: selectLoadingStates()(state, props),
  };
}

export default connect(
  mapStateToProps,
  {
    mountEventsPageSaga,
    addEventSaga,
  },
)(PropertyDetailTriggerContainer);
