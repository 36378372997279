import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import keys from 'lodash/keys';
import map from 'lodash/map';
import chunk from 'lodash/chunk';
import findIndex from 'lodash/findIndex';
import { DividerLine, Icon, PopoverDefault, ButtonList } from '@properly/components';
import classNames from 'classnames/bind';
import t from '@properly/localization';
import styles from '../components/checklistsStyles.module.css';
import * as selectors from '../state/ChecklistSelectors';
import { setTileView, stepMutatorSaga, setTextTemplate, setUploadState } from '../state/ChecklistActions';
import DragField from '../components/ChecklistField';
import ChecklistOverlay from '../../../../components/ChecklistOverlay/index';
import ChecklistHeader from '../../../../components/ChecklistHeader/index';
import ChecklistSidebarItem from '../../../../components/ChecklistSidebarItem/index';

const cx = classNames.bind(styles);

class ChecklistStepsContainer extends PureComponent {
  moveCard = (jobInstructionId, a, b) => {
    this.props.stepMutatorSaga('moveStep', {
      jobInstructionId,
      fromIndex: a,
      toIndex: b,
    });
  };

  renderChecklistsBig(
    selectMode,
    steps,
    isSelectedFunc,
    onClickTile,
    jobInstructionId,
    mutateStep,
    setTemplate,
    setUpload,
  ) {
    const newIndex = steps.length - 1;
    const propertyId = this.props.propertyId;
    const inputId = `upload-new-photo-${newIndex}-${propertyId || 'noproperty'}`;
    const cloneArray = steps.slice(0);
    const spacing = 24;
    const itemsPerRow = 6;
    const ident = 'checklistbig';
    cloneArray.unshift({
      objectId: 'create',
      _isCreate: true,
    });
    const checklistOptions = [
      {
        'data-key': 'checklist_blankslide_btn_popoversidebar',
        name: t('checklist.text_template'),
        onClick: () => setTemplate({ isOpen: true }),
        icon: <Icon.IcTextPopover />,
      },
      {
        'data-key': 'checklist_texttemplate_btn_popoversidebar',
        name: t('checklist.blank_text_slide'),
        onClick: () => mutateStep('addEnd', { jobInstructionId }),
        icon: <Icon.IcTemplatePopover2 />,
      },
      {
        'data-key': 'checklist_phototemplate_btn_popoversidebar',
        name: t('checklist.photo_slide'),
        ...(propertyId && {
          onClick: () =>
            setUpload({
              isOpen: true,
              do: {
                mode: 'addEnd',
              },
            }),
        }),
        ...(!propertyId && {
          onClick: () => this.overviewUploadRef.click(),
        }),
        icon: <Icon.IcCamera3 />,
      },
    ];
    const renderPopoverContent = closePopup => <ButtonList items={checklistOptions} closePopover={closePopup} />;
    return (
      <div>
        {map(chunk(cloneArray, 6), (row, iRow) => (
          <ChecklistOverlay.GridRow key={iRow} spacing={spacing}>
            {map(row, step => {
              const isSelected = isSelectedFunc(step.objectId);
              const index = findIndex(steps, { objectId: step.objectId });

              // eslint-disable-next-line
              const isCreateStep = step._isCreate;

              return (
                <ChecklistOverlay.GridItem key={step.objectId} spacing={spacing} itemPerRow={itemsPerRow}>
                  {!isCreateStep && (
                    <DragField
                      id={step.objectId}
                      moveCard={(a, b) => this.moveCard(jobInstructionId, a, b)}
                      index={index}
                    >
                      <ChecklistSidebarItem
                        noPadding
                        identifier={ident}
                        onClick={() => onClickTile(step.objectId)}
                        needsVerify={step.isVerificationNeeded}
                        title={step.title}
                        steps={step.tasks}
                        image={step.pictureUrl}
                      >
                        {selectMode && !isSelected && <ChecklistOverlay.GridItemOverlay mode="canselect" />}
                        {isSelected && <ChecklistOverlay.GridItemOverlay mode="selected" />}
                      </ChecklistSidebarItem>
                    </DragField>
                  )}
                  {isCreateStep && (
                    <div id="steps-popover" className={cx('checklist__createchecklist')}>
                      <div
                        className={cx(
                          'checklist__createchecklist-spacer',
                          'checklist__createchecklist-spacer--minheight',
                        )}
                      />
                      <PopoverDefault
                        width={230}
                        popoverKey="checklistCreateOptions"
                        hasArrow
                        popoverContent={PopoverState => renderPopoverContent(PopoverState.closePopup)}
                      >
                        <div className={cx('checklist__createchecklist-abs')}>
                          <Icon.IcPlusWhite width="24px" byWidth />
                        </div>
                      </PopoverDefault>
                    </div>
                  )}
                </ChecklistOverlay.GridItem>
              );
            })}
          </ChecklistOverlay.GridRow>
        ))}
        {!propertyId && (
          <div id="img-uploader">
            <input
              accept="image/*"
              onChange={event => this.props.handleDirectImageUpload(newIndex, event)}
              onClick={this.props.handleInputFileClick}
              className={cx('checklist__upload_input')}
              id={inputId}
              ref={e => {
                this.overviewUploadRef = e;
              }}
              type="file"
            />
          </div>
        )}
      </div>
    );
  }

  onDuplicate = () => {
    this.props.stepMutatorSaga('duplicateStep', {
      jobInstructionId: this.props.jobInstructionId,
      steps: keys(this.tileState.selected),
    });
    this.props.setTileView({ mode: 0, selected: 'empty' });
  };

  onRemove = () => {
    this.props.stepMutatorSaga('deleteStep', {
      jobInstructionId: this.props.jobInstructionId,
      deleteSteps: this.tileState.selected,
    });
    this.props.setTileView({ mode: 0, selected: 'empty' });
  };

  clickTile = objectId => {
    if (this.tileState.mode === 0) return;
    const removeOrAdd = this.isSelected(objectId);
    this.props.setTileView({
      selected: {
        [objectId]: !removeOrAdd,
      },
    });
  };

  get isSelectMode() {
    return this.tileState.mode === 1;
  }

  onClose = () => {
    this.props.setTileView({ isOpen: false, mode: 0, selected: 'empty' });
  };

  isSelected = objectId => this.tileState.selected[objectId] === true;

  onEdit = () => {
    this.props.setTileView({ mode: 1 });
  };

  onCancel = () => {
    this.props.setTileView({ mode: 0, selected: 'empty' });
  };

  get tileState() {
    return this.props.tileState.toJS();
  }

  get hasSelectedItems() {
    return keys(this.tileState.selected).length > 0;
  }

  getHeaderType = () => {
    if (this.tileState.mode === 0) return 'dark';
    if (this.tileState.mode === 1) return 'orange';
    return null;
  };

  render() {
    const steps =
      this.props.selectedJobInstruction && this.props.selectedJobInstruction.steps
        ? this.props.selectedJobInstruction.steps
        : [];
    return (
      <ChecklistOverlay background="dark" show={this.tileState.isOpen}>
        <ChecklistOverlay.Header type={this.getHeaderType(this.tileState.mode)}>
          {this.tileState.mode === 0 && (
            <ChecklistHeader
              type="dark"
              onCloseKey="checklist_checklistoverview_close_btn"
              onEditKey="checklist_checklistoverview_edit_btn"
              onClose={this.onClose}
              onEdit={this.onEdit}
            />
          )}
          {this.tileState.mode === 1 && (
            <ChecklistHeader
              type="orange"
              onDuplicateKey="checklist_editslides_duplicate_btn"
              onRemoveKey="checklist_editslides_remove_btn"
              onCancelKey="checklist_editslides_cancel_btn"
              onCancel={this.onCancel}
              hasSelectedItems={this.hasSelectedItems}
              onRemove={this.onRemove}
              onDuplicate={this.onDuplicate}
            />
          )}
          {this.tileState.mode !== 1 && <DividerLine type={['bottom', 'dark']} />}
        </ChecklistOverlay.Header>
        <ChecklistOverlay.ContentWrap>
          <ChecklistOverlay.Content>
            <ChecklistOverlay.ContentInner>
              {this.renderChecklistsBig(
                this.isSelectMode,
                steps,
                this.isSelected,
                this.clickTile,
                this.props.jobInstructionId,
                this.props.stepMutatorSaga,
                this.props.setTextTemplate,
                this.props.setUploadState,
              )}
            </ChecklistOverlay.ContentInner>
          </ChecklistOverlay.Content>
        </ChecklistOverlay.ContentWrap>
      </ChecklistOverlay>
    );
  }
}

ChecklistStepsContainer.propTypes = {
  jobInstructionId: PropTypes.string.isRequired,
  tileState: PropTypes.shape({}).isRequired,
  stepMutatorSaga: PropTypes.func.isRequired,
  setTileView: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    tileState: selectors.selectTileOverview()(state, props),
    selectedJobInstruction: selectors.selectJobInstruction(props.jobInstructionId)(state),
  };
}

export default connect(mapStateToProps, {
  setTileView,
  stepMutatorSaga,
  setTextTemplate,
  setUploadState,
})(ChecklistStepsContainer);
