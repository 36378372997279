import moment from 'moment-timezone';
import log from 'loglevel';
import { jobRequestStatus } from '@properly/config';
import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isObject';
import orderBy from 'lodash/orderBy';
import each from 'lodash/each';
import memoize from 'lodash/memoize';
import map from 'lodash/map';
import t from '@properly/localization';

import {
  isCalendarEventDelayed,
  isCalendarEventSeen,
  getCalendarEventArrivalUnix,
  getCalendarEventDepartureUnix,
  getCalendarEventJobRequestStatus,
  getCalendarEventBookingArrivalLocalDay,
  getCalendarEventBookingDepartureLocalDay,
  isCalendarEventBookingArrivalLocalDay,
  isCalendarEventBookingDepartureLocalDay,
} from '../calendarEvent';

export function mapCalendarEventToColor(calendarEvent = {}) {
  const isDelayed = isCalendarEventDelayed(calendarEvent);

  if (isDelayed) {
    return 'delayed';
  }

  switch (getCalendarEventJobRequestStatus(calendarEvent)) {
    case jobRequestStatus.StateCreated:
      return 'pending';
    case jobRequestStatus.StateAccepted:
      return 'accepted';
    case jobRequestStatus.StateCancelled:
    case jobRequestStatus.StateCleanerCancelled:
    case jobRequestStatus.StateDeclined:
      return 'cancelled';

    case jobRequestStatus.StateStarted:
    case jobRequestStatus.StatePaused:
      return 'progress';

    case jobRequestStatus.StateFinished:
      return 'finished';

    default:
      log.warn('Unknown color for calendar event', calendarEvent);
      return 'accepted';
  }
}

export function mapCalendarEventToLetter(calendarEvent = {}) {
  const label = mapCalendarEventToLabel(calendarEvent);
  switch (label) {
    case 'jobstatus.delayed':
      return 'D';
    case 'jobstatus.seen':
      return 'V';
    case 'jobstatus.accepted':
      return 'A';
    case 'jobstatus.pending':
      return 'P';
    case 'jobstatus.cancelled':
      return 'C';
    case 'jobstatus.declined':
      return 'D';
    case 'jobstatus.started':
      return 'IP';
    case 'jobstatus.completed':
      return 'F';
    default:
      log.warn('Unknown mapping for calendar event label', calendarEvent, label);
      return '';
  }
}

export const BUTTON_MODES = {
  edit: 'edit',
  sendmore: 'sendmore',
  cancel: 'cancel',
  schedule: 'schedule',
};

export const getCalendarButton = memoize(
  (type, status, eventStatus) => {
    const matchButtonMode = (x, y, z) => type === x && status === y && eventStatus === z;
    // Pending
    if (matchButtonMode(4, 1, 1)) return BUTTON_MODES.edit;
    if (matchButtonMode(3, 1, 1)) return BUTTON_MODES.edit;
    if (matchButtonMode(3, 1, 2)) return BUTTON_MODES.edit;
    if (matchButtonMode(3, 1, 3)) return BUTTON_MODES.cancel;
    // Declined Job
    if (matchButtonMode(4, 4, 1)) return BUTTON_MODES.sendmore;
    if (matchButtonMode(3, 4, 1)) return BUTTON_MODES.sendmore;
    if (matchButtonMode(3, 4, 2)) return BUTTON_MODES.edit;
    if (matchButtonMode(3, 4, 3)) return BUTTON_MODES.cancel;
    // Cancelled By Cleaner
    if (matchButtonMode(4, 8, 1)) return BUTTON_MODES.edit;
    if (matchButtonMode(3, 8, 1)) return BUTTON_MODES.edit;
    if (matchButtonMode(3, 8, 2)) return BUTTON_MODES.edit;
    if (matchButtonMode(3, 8, 3)) return BUTTON_MODES.cancel;
    // Cancelled By Host
    if (matchButtonMode(3, 3, 2)) return BUTTON_MODES.edit;
    if (matchButtonMode(3, 3, 3)) return BUTTON_MODES.cancel;
    // Cancelled By Host
    if (matchButtonMode(3, 2, 2)) return BUTTON_MODES.edit;
    if (matchButtonMode(3, 2, 3)) return BUTTON_MODES.cancel;
    if (status === 3 || status === 8) return BUTTON_MODES.edit;
    return 'schedule';
  },
  (type, status, eventStatus) => `${type}-${status}-${eventStatus}`,
);

export function getCalendarChangeButtonText(statusRaw, eventStatusRaw) {
  let changedTitle = false;
  if (statusRaw === jobRequestStatus.StateDeclined && eventStatusRaw === 1)
    changedTitle = t('calendartile.modes.sendmore');
  if (statusRaw === jobRequestStatus.StateCleanerCancelled && eventStatusRaw === 1)
    changedTitle = t('calendartile.modes.sendnew');
  if (statusRaw === jobRequestStatus.StateCancelled && eventStatusRaw === 1)
    changedTitle = t('calendartile.modes.sendnew');
  return changedTitle;
}

export function mapCalendarEventToLabel(calendarEvent = {}) {
  const isDelayed = isCalendarEventDelayed(calendarEvent);

  if (isDelayed) {
    return 'jobstatus.delayed';
  }

  const status = getCalendarEventJobRequestStatus(calendarEvent);
  const isCreated = status === jobRequestStatus.StateCreated;

  if (isCreated && isCalendarEventSeen(calendarEvent)) {
    return 'jobstatus.seen';
  }

  switch (status) {
    case jobRequestStatus.StateCreated:
      return 'jobstatus.pending';
    case jobRequestStatus.StateAccepted:
      return 'jobstatus.accepted';
    case jobRequestStatus.StateCancelled:
    case jobRequestStatus.StateCleanerCancelled:
      return 'jobstatus.cancelled';
    case jobRequestStatus.StateDeclined:
      return 'jobstatus.declined';
    case jobRequestStatus.StateStarted:
    case jobRequestStatus.StatePaused:
      return 'jobstatus.started';
    case jobRequestStatus.StateFinished:
      return 'jobstatus.completed';
    default:
      log.warn('Unknown label for calendar event', calendarEvent);
      return null;
  }
}

function findBookingSpace(lineIndex, dates, bookingLineResult, newDoc) {
  let success = 0;

  const MAX_BOOKING_DEPTH = 20;

  if (lineIndex > MAX_BOOKING_DEPTH) {
    return;
  }

  const currentLine = (bookingLineResult[lineIndex] = bookingLineResult[lineIndex] || {}); // eslint-disable-line
  const getClone = () => (bookingLineResult[lineIndex] ? cloneDeep(currentLine) : currentLine);

  let clone;

  const bookingDays = Object.keys(newDoc.obj);

  const isSameDayBooking = (calendarEvent, day) =>
    (isCalendarEventBookingArrivalLocalDay(currentLine[day], day) &&
      isCalendarEventBookingDepartureLocalDay(calendarEvent, day)) ||
    (isCalendarEventBookingArrivalLocalDay(calendarEvent, day) &&
      isCalendarEventBookingDepartureLocalDay(currentLine[day], day));

  for (let i = 0; i < bookingDays.length; i++) {
    const currentDay = bookingDays[i];
    const currentItem = newDoc.obj[currentDay];

    if (currentItem) {
      if (!currentLine[currentDay]) {
        clone = clone || getClone();

        // empty date
        clone[currentDay] = currentItem;
        success += 1;
      } else if (
        currentLine[currentDay] &&
        isObject(currentLine[currentDay]) &&
        !Array.isArray(currentLine[currentDay]) &&
        isSameDayBooking(currentItem, currentDay)
      ) {
        clone = clone || getClone();

        const { booking: existingBooking } = clone[currentDay] || {};
        const { booking: currentBooking } = currentItem || {};
        const { arrivalLocalDay, departureLocalDay } = currentBooking || {};
        const { departureLocalDay: existingDeparture } = existingBooking || {};

        // Make sure that this is not a booking where the arrival and departure are the same day
        // Unless the existing item finishes on this day
        if (existingDeparture !== arrivalLocalDay && arrivalLocalDay === departureLocalDay) {
          break;
        }

        clone[currentDay] = [clone[currentDay], currentItem];
        success += 1;
      } else {
        // Continue to the next line
        break;
      }
    }
  }

  if (success === newDoc.matches) {
    bookingLineResult[lineIndex] = clone || getClone(); // eslint-disable-line
    return;
  }

  findBookingSpace(lineIndex + 1, dates, bookingLineResult, newDoc);
}

export const getCalendarBookingDays = memoize(
  (dates, bookingTimeZone) =>
    dates.map(date => {
      const unixEnd = moment
        .tz(date, bookingTimeZone)
        .endOf('day')
        .unix();

      return {
        date,
        unixEnd,
      };
    }),
  (dates, bookingTimeZone) => (dates || []).join('-') + bookingTimeZone,
);

export function matchCalendarBookingEvents(calendarEventBookings, dates, bookingTimeZone) {
  const bookingLineResult = [];

  const daysAndUnix = getCalendarBookingDays(dates, bookingTimeZone);

  const orderedBookings = orderBy(
    calendarEventBookings || [],
    calendarEventBooking => calendarEventBooking.booking.bookingDurationDays,
    ['desc'],
  );

  const daysArray = orderedBookings.map(calendarEvent => getCalendarBookingByDays(calendarEvent, daysAndUnix));

  each(daysArray, item => findBookingSpace(0, dates, bookingLineResult, item));

  return bookingLineResult;
}

export function getCalendarBookingByDays(calendarEvent, dates) {
  const departureUnix = getCalendarEventDepartureUnix(calendarEvent);
  const arrivalUnix = getCalendarEventArrivalUnix(calendarEvent);

  const arrivalLocalDay = getCalendarEventBookingArrivalLocalDay(calendarEvent);
  const departureLocalDay = getCalendarEventBookingDepartureLocalDay(calendarEvent);

  let matches = 0;
  const dateMatches = [];
  const obj = {};

  const array = map(dates, ({ date, unixEnd }) => {
    const isOnStartOrEndDay = arrivalLocalDay === date || departureLocalDay === date;
    const isWithinBookingRange = departureUnix > unixEnd && arrivalUnix < unixEnd;

    if (isOnStartOrEndDay || isWithinBookingRange) {
      matches += 1;
      dateMatches.push(date);
      obj[date] = calendarEvent;
      return [date, calendarEvent];
    }
    obj[date] = null;
    return ['no', null];
  });

  return {
    array,
    obj,
    matches,
    dateMatches,
  };
}
