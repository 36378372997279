import { push } from 'react-router-redux';
import assign from 'lodash/assign';
import pick from 'lodash/pick';
import log from 'loglevel';
import t from '@properly/localization';
import { isValidPhone } from '@properly/common';
import * as types from '../../types';
import { ROUTES } from '../../paths';
import {
  becomeUser as becomeUserImport,
  resetPassword as resetPasswordImport,
  parseLogoutUser,
  getCurrentUser,
} from '../desktopApp/data';
import * as accountActions from '../desktopApp/account/state/AccountActions';
import * as globalActions from '../../actions/globalActions';
import { setTracking } from '../../actions/trackingService';

export function redirectToLandingPageSaga(from, meta) {
  return {
    type: types.LOGIN_REDIRECT_TO_LANDING_PAGE_SAGA,
    from,
    meta,
  };
}

export function resetErrors() {
  return {
    type: types.LOGIN_ERROR_USER_RESET,
  };
}

function pushAndPreserveQuery(location, newPathname) {
  return push(assign(pick(location, ['search']), { pathname: newPathname }));
}

export function goToSignup(location) {
  return pushAndPreserveQuery(location, ROUTES.signup);
}

export function goToLogin(location) {
  return pushAndPreserveQuery(location, ROUTES.login);
}

export function goToPwReset() {
  return push(ROUTES.resetPassword);
}

export function handleCloseOAuth() {
  return accountActions.setState('oauth', {});
}

export function oAuthHandleUpdate(infoObj) {
  if (infoObj.done) {
    // do the final thing
    return dispatch => {
      dispatch(accountActions.setState('oauth', {}));
      dispatch(redirectToLandingPageSaga('accountimport'));
      dispatch(globalActions.startPreloadSaga('hostsignupaccountscomponent'));
    };
  }
  if (infoObj.error || infoObj.step) {
    return accountActions.setState('oauth', infoObj);
  }
  return accountActions.setState('oauth', {});
}

/* SIGN UP FUNCTIONS */
export function hostSignUpSuccess(user) {
  return {
    type: types.SIGNUP_SUCCESS_USER,
    user,
  };
}
export function hostSignUpError(errors) {
  return {
    type: types.SIGNUP_ERROR_USER,
    errors,
  };
}
export function hostSignUpErrorReset() {
  return {
    type: types.SIGNUP_ERROR_USER_RESET,
  };
}
export function signUpSaga(data) {
  return {
    type: types.SIGNUP_SAGA,
    data,
  };
}

/* LOGIN FUNCTIONS */
export function loginSuccess(user) {
  return {
    type: types.LOGIN_SUCCESS_USER,
    user,
  };
}

export function loginError(errors) {
  return {
    type: types.LOGIN_ERROR_USER,
    errors,
  };
}

export function becomeUserSaga(token) {
  return {
    type: types.LOGIN_BECOME_USER,
    token,
  };
}

export function loginSetOverride(val) {
  return {
    type: types.LOGIN_SET_USER_OVERRIDE,
    val,
  };
}

function loginAsInner(dispatch, token, redirectRoute) {
  becomeUserImport(token)
    .then(user => {
      getCurrentUser(); // refresh user / hack
      setTimeout(() => {
        dispatch(loginSuccess(user));
        dispatch(loginGateSaga({ redirectRoute, fromWhere: 'loginWithToken' }));
      }, 2000);
    })
    .catch(() => {
      alert('Failed to login user'); // eslint-disable-line
    });
}

export function loginUserWithToken(token, redirectRoute) {
  const redirect = redirectRoute || 'properties';

  return dispatch => {
    const currentUser = getCurrentUser();
    const currentUserSessionToken = currentUser && currentUser.sessionToken;
    // If our current user has the same session token then we do not need to log them out
    if (currentUserSessionToken === token) {
      log.info('User session token already logged in');
      return setTimeout(() => dispatch(push(redirect)));
    }
    // Otherwise log the user out as per normal
    setTracking(false);
    return parseLogoutUser()
      .catch(() => {
        // If parse logout fails it is possibly because we are not already authenticated or the session has expired already
        log.error('Failed to log user out'); // eslint-disable-line
      })
      .then(() => {
        // set the override so that we do not show intermittent login screen
        dispatch(globalActions.globalLogout());
        dispatch({
          type: types.LOGOUT_SUCCESS_USER,
        });
        loginAsInner(dispatch, token, redirect);
      });
  };
}

function genToastMessage(input) {
  if (isValidPhone(input)) return t('resetpassword.text_message', { number: input });
  return t('resetpassword.email_message', { email: input });
}

function resetPasswordSuccess(username) {
  const message = genToastMessage(username);
  return {
    type: types.RESET_PASSWORD_SUCCESS,
    payload: {
      type: 'success',
      message,
    },
  };
}

function resetPasswordError(username) {
  return {
    type: types.RESET_PASSWORD_ERROR,
    payload: {
      type: 'error',
      message: t('resetpassword.reset_error', { user: username }),
    },
  };
}

export function resetPasswordValidate(isValid = false) {
  return {
    type: types.RESET_PASSWORD_VALIDATE,
    payload: isValid,
  };
}

export function resetPasswordReset() {
  return {
    type: types.RESET_PASSWORD_RESET,
  };
}

export function resetPassword(username) {
  return dispatch => {
    dispatch(resetPasswordReset());
    function hideToast() {
      dispatch(resetPasswordReset(null));
    }
    resetPasswordImport(username)
      .then(() => {
        dispatch(resetPasswordSuccess(username));
        setTimeout(hideToast, 4200);
      })
      .catch(() => {
        dispatch(resetPasswordError(username));
      });
  };
}

// new
export function loginSaga(data, query) {
  return {
    type: types.LOGIN_SAGA,
    data,
    query,
  };
}

export function setLoginLoading(val) {
  return {
    type: types.LOGIN_SET_LOADING,
    val,
  };
}

export function loginGateSaga({ fromWhere, query, redirectRoute }) {
  return {
    type: types.LOGIN_GATE_SAGA,
    fromWhere,
    query,
    redirectRoute,
  };
}
