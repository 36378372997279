import * as types from '../../../types';

export function initSaga(initOrDeinit, date) {
  return {
    type: types.TODOS_INIT_SAGA,
    date,
    initOrDeinit,
  };
}

export function loadDataSaga(paginate, date) {
  return {
    type: types.TODOS_LOAD_DATA,
    paginate,
    date,
  };
}

export function setTodosData(data) {
  return {
    type: types.TODOS_SET_DATA,
    data,
  };
}

export function setTodosReset() {
  return {
    type: types.TODOS_RESET,
  };
}

export function handleClickSaga(mode, data, resolve, reject) {
  return {
    type: types.TODOS_HANDLE_CLICK_SAGA,
    mode,
    data,
    resolve,
    reject,
  };
}

export function mergeEventsStore(events) {
  return {
    type: types.TODOS_MERGE_EVENTS,
    events,
  };
}
