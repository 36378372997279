import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import omit from 'lodash/omit';

const cardSource = {
  beginDrag(props) {
    return {
      index: props.index,
      indexImage: props.indexImage,
      children: props.children,
      wrapFunc: props.wrapFunc,
    };
  },
  canDrag: props => !props.isReadOnly,
};

class ChecklistImagePin extends PureComponent {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    indexImage: PropTypes.number.isRequired,
  };

  componentDidMount() {
    // hide draglayer
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }

  render() {
    const { connectDragSource, children, isDragging, ...more } = this.props;
    // hide draglayer
    const overwrite = isDragging ? { style: { ...more.style, opacity: 0 } } : {};
    return connectDragSource(
      <div {...omit(more, ['connectDragPreview', 'wrapFunc', 'index', 'isReadOnly', 'indexImage'])} {...overwrite}>
        {children}
      </div>,
    );
  }
}

export default compose(
  DragSource('PIN', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  })),
)(ChecklistImagePin);
