import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import t from '@properly/localization';
import { showMsgIntercom } from '@properly/common';
import ErrorComponent from '../../components/ErrorComponent';
import { trackErrorScreenShown } from '../../actions/trackingEvents';

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}

class ErrorPage extends PureComponent {
  componentWillMount() {
    trackErrorScreenShown();
  }

  tryAgain() {
    window.location.href = '/';
  }

  render() {
    return (
      <ErrorComponent
        showIntercom={() => showMsgIntercom()}
        tryAgain={this.tryAgain}
        is500
        headline={t('error.oops')}
        subHeadline={t('error.looks_like_smth_wrong')}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  null,
)(ErrorPage);
