"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapJobTypeToBackendJobType = exports.doJob = void 0;
var doJob = 'do_job';
exports.doJob = doJob;
var mapJobTypeToBackendJobType = {
  turnover: 'Turnover',
  concierge: 'Concierge',
  maintenance: 'Maintenance',
  inspection: 'Inspection',
  other: 'Other'
};
exports.mapJobTypeToBackendJobType = mapJobTypeToBackendJobType;