import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import lodashResult from 'lodash/result';
import log from 'loglevel';
import map from 'ramda/src/map';
import t from '@properly/localization';
import classNames from 'classnames/bind';
import { featureFlag, emailLink } from '@properly/config';
import { Button, LoadingSpinner, SquareSelect, Modal } from '@properly/components';
import JobRequestSection from './JobRequestSection';
import { sectionsArray, sectionsWithId, loadingKeyInit, loadingKeyMain } from './JobRequestFormSchema';
import {
  goToCalendar,
  initJobRequestSaga,
  submitSaga,
  handleClickSaga,
  selectChecklist,
} from '../state/JobRequestActions';
import NoProperties from '../../../../components/NoProperties/index';
import {
  selectedJobRequestComputedData,
  selectJobRequestData,
  selectJobRequestMode,
  selectHostStripeStatus,
} from '../state/JobRequestSelectors';
import styles from '../components/index.module.css';
import { sendJobRequestModes } from '../../../../dataConstants';
import {
  JobRequestTopBarLeft,
  JobRequestContentWrap,
  JobRequestTextSub,
  JobRequestContent,
  JobRequestWrap,
  JobRequestSidebar,
  JobRequestLoadingOverlay,
} from '../../../../components/JobRequestElements/index';
import {
  selectLoadingState,
  selectJobLoadingState,
  selectJobRequest,
  selectConfig,
} from '../../../../selectors/globalSelector';
import PaymentInterceptWizard from '../../../../containers/PaymentInterceptWizard';
import { selectLoadingStates } from '../../checklists/state/ChecklistSelectors';
import { selectIsFeatureFlagEnabledForUser } from '../../settings/state/SettingsSelectors';

const cx = classNames.bind(styles);

class JobRequestContainer extends PureComponent {
  state = {
    isButtonEnabled: true,
    showStripeWizardModal: false,
  };

  componentDidMount() {
    const { initJobRequestSaga, startupData } = this.props; // eslint-disable-line no-shadow
    initJobRequestSaga(startupData);
  }

  componentWillUnmount() {
    this.setState({ isButtonEnabled: true });
  }

  checkMarketplaceCleaner = cleaners =>
    !!Object.values(cleaners).filter(cleanerDetails => !!cleanerDetails?.getProStatus?.()).length;

  get isMainLoading() {
    const { loadingState } = this.props;
    return loadingState.isLoadingFull;
  }

  get isSuccess() {
    const { loadingState } = this.props;
    return loadingState.isLoaded;
  }

  changeDefaultPrefill = prevVal => () => {
    const { handleClickSaga } = this.props; // eslint-disable-line no-shadow

    handleClickSaga(undefined, {
      action: 'clickdefaultchange',
      val: !prevVal,
    });
  };

  submitHandler = () => {
    const { submitSaga, proMarketProperty, hostStripeStatus, cleaners } = this.props; // eslint-disable-line no-shadow

    this.setState({ isButtonEnabled: false }); // this is to avoid multiple job requests on click repeatedly

    if (proMarketProperty && this.checkMarketplaceCleaner(cleaners) && !hostStripeStatus) {
      return this.setState({
        showStripeWizardModal: true,
      });
    }

    return submitSaga();
  };

  onClose = lastStep => {
    let response = true;
    if (lastStep) {
      response = window.confirm(t('job_request.wizard.confirm_leave')); // eslint-disable-line
    }

    if (response === true) {
      this.setState({
        showStripeWizardModal: false,
        isButtonEnabled: true,
      });
    }

    return response;
  };

  onFinish = () => {
    const { submitSaga } = this.props; // eslint-disable-line no-shadow

    this.setState({
      showStripeWizardModal: false,
    });
    return submitSaga();
  };

  goToCalendar = () => {
    const { onClose, goToCalendar } = this.props; // eslint-disable-line no-shadow
    onClose();
    goToCalendar();
  };

  mapSectionToSuccessMessage = (mode, proMarketProperty, cleaners, config) => {
    if (mode === sendJobRequestModes.prefill) {
      return { line1: t('job_request.prefill_job_saved') };
    }
    if (proMarketProperty && this.checkMarketplaceCleaner(cleaners)) {
      return {
        line1: t('job_request.send_request'),
        line2: t('job_request.send_request_pro_txt', {
          msg: (
            <a
              className={cx('jobRequest__sent-marketplace-email')}
              target="_blank"
              rel="noopener noreferrer"
              href={config.proMPSatisfactionGuaranteeUrl}
            >
              {t('marketplace.guarantee')}
            </a>
          ),
          email: (
            <a className={cx('jobRequest__sent-marketplace-email')} href={`mailto:${emailLink.marketplaceEmail}`}>
              {t('checkout.email')}
            </a>
          ),
        }),
      };
    }
    return { line1: t('job_request.send_request'), line2: t('job_request.send_request_txt') };
  };

  // TODO: put in selector
  mapModeToSendButtonTxt() {
    const { jobRequestSendMode } = this.props;

    if (jobRequestSendMode === sendJobRequestModes.prefill) {
      return t('checklist.save');
    }
    return t('job_request.send_job_request');
  }

  highlightSectionAccWithOther(hightLightedSectionId, currentMappedSection, id) {
    return lodashResult(sectionsWithId[hightLightedSectionId], ['alsoHightLight', id]) && currentMappedSection.isEmpty;
  }

  // TODO: put in selector
  mapModeToHeader() {
    const { jobRequestSendMode, isDefaultPrefill } = this.props;

    if (jobRequestSendMode === sendJobRequestModes.prefill) {
      return {
        title: t('job_request.prefill_job'),
        belowTitle: (
          <div className={cx('job-request__defaultline')}>
            <JobRequestTextSub>{t('defaultsettings.set_default_yes_no')}</JobRequestTextSub>
            <div>
              <SquareSelect
                style={{ height: 18, width: 18 }}
                type="quarter"
                data-key="default-job-request"
                selected={isDefaultPrefill}
                onChange={this.changeDefaultPrefill(isDefaultPrefill)}
              />
            </div>
          </div>
        ),
      };
    }
    return { title: t('job_request.job_summary') };
  }

  renderSection = (section, canOpen) => {
    const {
      mappedSections,
      hightLightedSectionId,
      finalMode,
      onClose,
      startupData,
      jobRequest,
      isLoadingJobData,
    } = this.props;
    const naturalActive = mappedSections[section.id].active;
    const isOrange = hightLightedSectionId === section.id;
    const isEditMode = finalMode === sendJobRequestModes.edit;
    const isProblemScheduleMode = finalMode === sendJobRequestModes.problemSchedule;
    return (
      <JobRequestSection
        key={section.id}
        canOpen={canOpen}
        contentTop={section.contentTop}
        contentMain={section.contentMain}
        nameWidthAuto={section.nameWidthAuto}
        canNext={section.canNext}
        isEditMode={isEditMode}
        isProblemScheduleMode={isProblemScheduleMode}
        nextText={t('job_request.next')}
        mapStateToProps={section.mapStateToProps}
        hideTopWhenOpen={section.hideTopWhenOpen}
        id={section.id}
        showReset={finalMode === sendJobRequestModes.prefill}
        {...mappedSections[section.id]}
        isTitleRequired={!isLoadingJobData && !mappedSections[section.id].isValid}
        active={
          !!naturalActive ||
          !!(
            isOrange || this.highlightSectionAccWithOther(hightLightedSectionId, mappedSections[section.id], section.id)
          )
        }
        onClose={onClose}
        jobRequestId={startupData.prevJobRequestId}
        jobStatus={jobRequest && jobRequest.status}
      />
    );
  };

  renderSectionFull = section => {
    const { finalMode, proMarketProperty, cleaners, config } = this.props;

    switch (section.id) {
      case 'property':
      case 'propertyDetails':
      case 'checklist':
      case 'skills':
      case 'time':
      case 'cleaners':
      case 'price':
      case 'title':
      case 'message':
        return this.renderSection(sectionsWithId[section.id], true);
      case 'success':
        return (
          <NoProperties
            type="jobrequest"
            onClick={this.goToCalendar}
            {...this.mapSectionToSuccessMessage(finalMode, proMarketProperty, cleaners, config)}
          />
        );
      case 'error':
        return <NoProperties type="jobrequesterror" />;
      case 'community_service_provider_sample_property_error':
        return <NoProperties type="community_service_provider_sample_property_error" />;
      case 'job_already_scheduled':
        return <NoProperties type="job_already_scheduled" />;
      case 'error_handled_from_api':
        return <NoProperties type="error_handled_from_api" message={section.metaval} />;
      default:
        log.warn('Unknown render section full', section);
        return null;
    }
  };

  renderSidebar() {
    const { isAllValid } = this.props;
    const { isButtonEnabled } = this.state;
    const btnArray = ['type-full-primary-job', 'size-large', 'border-rad-no', 'width-flex', 'miheight-62'];

    return (
      <JobRequestContentWrap>
        <JobRequestTopBarLeft {...this.mapModeToHeader()} />
        {map(val => this.renderSection(val, false), sectionsArray)}
        <Button
          data-key="send_job_request_button"
          disabled={!isAllValid || this.isSuccess || !isButtonEnabled}
          onClick={this.submitHandler}
          types={this.isMainLoading ? [...btnArray, 'zindex-11'] : btnArray}
        >
          {!this.isMainLoading && this.mapModeToSendButtonTxt()}
          {this.isMainLoading && <LoadingSpinner type="white" />}
        </Button>
      </JobRequestContentWrap>
    );
  }

  render() {
    const { activeSection, loadingStateInit, isLoadingCreateChecklist } = this.props;
    const { showStripeWizardModal } = this.state;
    return (
      <JobRequestWrap>
        <JobRequestSidebar disableClick={this.isSuccess}>{this.renderSidebar()}</JobRequestSidebar>
        <JobRequestContent>{activeSection && this.renderSectionFull(activeSection)}</JobRequestContent>
        {(loadingStateInit.isLoadingFull || isLoadingCreateChecklist) && <JobRequestLoadingOverlay />}
        {this.isMainLoading && <JobRequestLoadingOverlay hideLoader />}
        <Modal
          id="payment-intercept-wizard-modal"
          height={350}
          show={showStripeWizardModal}
          onClose={this.onClose}
          block
        >
          <PaymentInterceptWizard onClose={this.onClose} onFinish={this.onFinish} textAlign="center" />
        </Modal>
      </JobRequestWrap>
    );
  }
}

function memoMapStateToProps() {
  const memo = selectedJobRequestComputedData();
  const memoMain = selectLoadingState(loadingKeyMain);
  const memoInit = selectLoadingState(loadingKeyInit);
  // TODO: check that they memorize properly
  return function mapStateToProps(state, props) {
    const jobRequestId = props.startupData && props.startupData.prevJobRequestId;
    const checklistLoadingStates = selectLoadingStates()(state, props);
    const jobRequestData = selectJobRequestData()(state);
    const isLoadingJobData = selectJobLoadingState()(state, props);
    const hostStripeStatus = selectHostStripeStatus()(state, props);
    const jobRequest = selectJobRequest(jobRequestId)(state, props);
    const isMarketPlaceFeatureFlagEnabled = selectIsFeatureFlagEnabledForUser(featureFlag.FEATURE_FLAG_MARKETPLACE)(
      state,
      props,
    );
    const isLoadingCreateChecklist = checklistLoadingStates.get(`create${jobRequestData.get('propertyId')}`) === 1;
    return {
      config: selectConfig(state, props),
      isLoadingJobData,
      isLoadingCreateChecklist,
      finalMode: selectJobRequestMode(state),
      isDefaultPrefill: jobRequestData.get('isDefault'),
      startTime: jobRequestData.get('startTime'),
      proMarketProperty: isMarketPlaceFeatureFlagEnabled && jobRequestData.get('proMarketProperty'),
      cleaners: jobRequestData.get('cleaners')?.toJS(),
      hostStripeStatus,
      loadingState: memoMain(state),
      loadingStateInit: memoInit(state),
      jobRequest,
      ...memo(state),
    };
  };
}

JobRequestContainer.propTypes = {
  jobRequest: PropTypes.shape({}),
};

JobRequestContainer.defaultProps = {
  jobRequest: {},
};

export default connect(memoMapStateToProps, {
  initJobRequestSaga,
  handleClickSaga,
  selectChecklist,
  submitSaga,
  goToCalendar,
})(JobRequestContainer);
