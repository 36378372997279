import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, LoadingSpinner } from '@properly/components';
import t from '@properly/localization';
import { compose, mapProps } from 'recompose';

import pick from 'lodash/pick';
import map from 'lodash/map';
import { connect } from 'react-redux';
import {
  JobRequestContentWrap,
  JobRequestSidebarBoxEmpty,
  JobRequestSidebarBox,
  JobRequestTopBarLeft,
} from '../../../components/JobRequestElements';
import { mapToMainState } from './permissionhelper';
import PermissionModalSidebarContent from './PermissionModalSidebarContent';

class PermissionModalSidebar extends PureComponent {
  static propTypes = {
    actionHandler: PropTypes.func.isRequired,
  };

  render() {
    const btnArray = ['type-full-primary-job', 'size-large', 'border-rad-no', 'width-flex', 'miheight-62'];
    return (
      <JobRequestContentWrap>
        <JobRequestTopBarLeft title={t(this.props.modalTitle)} />
        {map(this.props.sections, section => (
          <JobRequestSidebarBox
            key={`permission-sidebar-${section.sectionId}`}
            id={section.sectionId}
            style={{ flex: 1 }}
            isDisabled={section.isDisabled}
            onClick={this.props.actionHandler('sidebarclicksection')({
              sectionId: section.sectionId,
            })}
            modifiers={section.sectionMods}
            isActive={section.isActive}
            title={t(section.sectionTitle)}
          >
            {section.sectionIsEmpty && <JobRequestSidebarBoxEmpty />}
            {!section.sectionIsEmpty && <PermissionModalSidebarContent id={section.sectionId} />}
          </JobRequestSidebarBox>
        ))}
        {!this.props.hideSubmitButton && (
          <Button
            disabled={this.props.submitDisabled || this.props.submitIsLoading}
            onClick={this.props.actionHandler('handlesubmit')()}
            types={this.props.submitIsLoading ? [...btnArray, 'zindex-11'] : btnArray}
          >
            {!this.props.submitIsLoading && t(this.props.submitText)}
            {this.props.submitIsLoading && <LoadingSpinner type="white" />}
          </Button>
        )}
      </JobRequestContentWrap>
    );
  }
}

export default compose(
  connect(mapToMainState, {}),
  mapProps(props =>
    pick(props, [
      'modalTitle',
      'sections',
      'submitDisabled',
      'submitText',
      'actionHandler',
      'submitIsLoading',
      'hideSubmitButton',
    ]),
  ),
)(PermissionModalSidebar);
