import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Icon, TaskForm, SpacedHeadline, TasklistItem } from '@properly/components';
import styles from './index.module.css';

const cx = classNames.bind(styles);

class TaskFormOverlay extends Component {
  focus() {
    if (this.elementRef) {
      this.elementRef.focus();
    }
  }

  componentDidMount() {
    this.focus();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      this.focus();
    }
  }

  render() {
    const { show } = this.props;
    if (!show) return null;
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ height: '2px', paddingRight: '10px' }}>
            <Icon.AddTask />
          </div>
          <SpacedHeadline size="small">Job Task</SpacedHeadline>
        </div>

        <div>
          <SpacedHeadline size="small">tasks</SpacedHeadline>
        </div>
      </div>
    );
  }
}

TaskFormOverlay.TaskForm = ({ children, ...rest }) => <TaskForm {...rest}>{children}</TaskForm>;

//   -----
TaskFormOverlay.Header = ({ count = 0 }) => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <SpacedHeadline size="small">
      <Icon.AddTask />
      &nbsp; Add Task
    </SpacedHeadline>
    <SpacedHeadline size="small">{count} task</SpacedHeadline>
  </div>
);

TaskFormOverlay.GridRow = ({ children, spacing, style = {} }) => {
  const halfSpacing = (spacing / 2) * -1;
  const styleIn = {
    marginLeft: halfSpacing,
    marginRight: halfSpacing,
    ...style,
  };
  return (
    <div className={cx('taskformoverlay-overlay__gridrow')} style={styleIn}>
      {children}
    </div>
  );
};

TaskFormOverlay.GridItem = ({ children, spacing, itemPerRow, style = {} }) => {
  const halfSpacing = spacing / 2;
  const styleIn = {
    padding: halfSpacing,
    maxWidth: `${100 / itemPerRow}%`,
    ...style,
  };
  return (
    <div className={cx('taskformoverlay-overlay__griditem')} style={styleIn}>
      {children}
    </div>
  );
};

TaskFormOverlay.Content = ({ children, type, style }) => (
  <div className={cx('taskformoverlay-overlay__content', `taskformoverlay-overlay__content--${type}`)} style={style}>
    <Icon.AddTask />
    {children}
  </div>
);

TaskFormOverlay.ContentWrap = ({ children, style }) => (
  <div className={cx('taskformoverlay-overlay__content-wrap')} style={style}>
    {children}
  </div>
);

TaskFormOverlay.ContentInner = ({ children, style }) => (
  <div className={cx('taskformoverlay-overlay__content-inner')} style={style}>
    {children}
  </div>
);

TaskFormOverlay.Item = ({ task, onDelete }) => (
  <TasklistItem
    title={task.title}
    description={task.note}
    onDelete={onDelete}
    pictureIdentifiers={task.pictureIdentifiers}
  />
);

TaskFormOverlay.propTypes = {
  show: PropTypes.bool,
  background: PropTypes.string,
  children: PropTypes.node,
};

export default TaskFormOverlay;
