import { fromJS } from 'immutable';
import * as types from '../../../../types/index';

const initState = fromJS({
  selectedJobRequestId: undefined,
  isOpen: false,
  jobProgress: undefined,
  isOpenEditModal: false,
  isOpenCostModal: false,
  isWarningModalOpen: false,
  event: undefined, // just temp
  state: 0, // 0=ok , 1=loading, 2=error
  sourceJobProblems: [],
  tempMilesImages: [],
  tempDurationImages: [],
  tempCostImages: [],
  type: undefined,
  resolvedProblem: {},
  stripePaymentDetails: {},
  comments: [],
  commentLoader: false,
  checklistsVerificationPhotos: [],
});

export default function JobRequestReducer(state = initState, action = {}) {
  switch (action.type) {
    case types.GLOBAL_LOGOUT: // on logout reset
      return initState;
    case types.JOB_DETAILS_SET:
      return state.merge(action.changeSet);
    case types.JOB_DETAILS_SET_SOURCE_JOB_PROBLEMS:
      return state.setIn(['sourceJobProblems'], fromJS(action.data));
    case types.JOB_DETAILS_SET_RESOLVED_PROBLEM:
      return state.set('resolvedProblem', fromJS(action.data));
    case types.JOB_DETAILS_SET_STRIPE_PAYMENT_DETAILS:
      return state.set('stripePaymentDetails', fromJS(action.data));
    case types.JOB_DETAILS_SET_TEMP_MILES_IMAGES:
      return state.set('tempMilesImages', fromJS(action.data));
    case types.JOB_DETAILS_SET_TEMP_DURATION_IMAGES:
      return state.set('tempDurationImages', fromJS(action.data));
    case types.JOB_DETAILS_SET_TEMP_COST_IMAGES:
      return state.set('tempCostImages', fromJS(action.data));
    case types.JOB_DETAILS_SET_COST_TYPE:
      return state.set('type', fromJS(action.data));
    case types.JOB_DETAILS_SET_CHECKLISTS_VERIFICATION_PHOTOS:
      return state.set('checklistsVerificationPhotos', fromJS(action.data));
    case types.SET_COMMENT_LOADER:
      return state.set('commentLoader', fromJS(action.payload));
    case types.SET_COMMENTS:
      return state.set('comments', fromJS(action.payload));
    default:
      return state;
  }
}
