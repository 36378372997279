import React, { PureComponent } from 'react';
import t from '@properly/localization';
import curry from 'lodash/curry';
import lodashResult from 'lodash/result';
import { DividerLine, Modal, SpaceFlexi, Title3, Icon, Button, LoadingSplash } from '@properly/components';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import styles from './permissionStyles.css';
import MainWrapper from '../../../containers/MainWrapper';
import { PagePadding } from '../../../components/PageElements';
import NoProperties from '../../../components/NoProperties';
import PermissionModal from './PermissionModal';
import { initPermissionsSaga, handlePermissionActionSaga } from './PermssionsActions';
import { selectLoadingState } from '../../../selectors/globalSelector';
import { initLoadingKey, teamLoadingKey, updateActionLoadingKey } from './PermissionsConstants';
import { selectFirstTeam, selectInfoMessage, selectTabData } from './permissionSelector';
import InfoMessage from '../../../components/InfoMessage';
import PermissionCollListNew from './PermissionCollListNew';
import { AccountHeadSection } from '../../../components/AccountElements';
import { TodosTextEle } from '../../../components/TodosElements';
import CompanyBox from '../../../components/CompanyBox';
import Sidebar from '../../../components/Sidebar';
import { setModal } from '../../../actions/globalActions';

const cx = classNames.bind(styles);

class PermissionsContainerCompany extends PureComponent {
  constructor(props) {
    super(props);
    this.curryedHandle = curry(this.props.handlePermissionActionSaga, 3);
    this.createTeam = this.curryedHandle('createteam');
    this.saveTeam = this.curryedHandle('saveteam');
  }

  componentDidMount() {
    this.props.initPermissionsSaga();
    if (this.props.verifyModal) {
      const result = this.props.verifyModal === 'success';
      this.props.setModal(true, 'permissions_company_verifyresult', { result });
    }
  }

  renderEmptyCompany() {
    const { pictureUrl, name, email, description, adminEmails } = this.props.team || {};
    return (
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
        <div style={{ width: 250 }}>
          <CompanyBox
            pic={pictureUrl}
            name={name}
            email={email}
            about={description}
            adminEmails={adminEmails}
            onEdit={event => this.curryedHandle('open_edit_company', {}, event)}
          />
        </div>
        <Sidebar.List>
          <Sidebar.ListInner>
            <Sidebar.ListInnerInner>
              {this.props.hasInternal && (
                <div style={{ paddingLeft: 20, marginTop: '-10px' }}>
                  <PermissionCollListNew renderType="internal" />
                </div>
              )}
              {!this.props.hasInternal && (
                <NoProperties type="inviteemployee" onClick={this.curryedHandle('invite_company')({})} />
              )}
            </Sidebar.ListInnerInner>
          </Sidebar.ListInner>
        </Sidebar.List>
      </div>
    );
  }

  renderNoCompany() {
    return (
      <div>
        <SpaceFlexi type="vertical" size="179px" />
        <div style={{ position: 'relative ' }}>
          <div className={cx('permission__starticon', 'permission__starticon--company')}>
            <Icon.IcCompanyNew byWidth width="594px" />
          </div>
        </div>
        <div style={{ width: '364px', height: '400px' }}>
          <TodosTextEle modifier={['txt--l3m', 'color--black']}>{t('permissions.create_company')}</TodosTextEle>
          <SpaceFlexi type="vertical" size="25px" />
          <TodosTextEle modifier={['txt--l4', 'color--grey']}>{t('permissions.company_start_txt')}</TodosTextEle>
          <SpaceFlexi type="vertical" size="36px" />
          <Button
            data-key="button_create_company"
            onClick={this.curryedHandle('open_create_company')({})}
            types={['type-full-primary', 'size-large']}
          >
            {t('permissions.create_company_btn')}
          </Button>
        </div>
      </div>
    );
  }

  renderMain() {
    if (this.props.team) {
      return this.renderMainCreated();
    }
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {this.renderTop()}
        {this.renderNoCompany()}
      </div>
    );
  }

  renderTop(more) {
    return (
      <AccountHeadSection>
        <Title3 type="nomargin">{t('account.company')}</Title3>
        <DividerLine type={['bottom']} />
        {more || null}
      </AccountHeadSection>
    );
  }

  renderMainCreated() {
    const btn = this.props.hasInternal && (
      <div style={{ position: 'absolute', right: 0 }}>
        <Button
          data-key="button_invite_employee"
          onClick={this.curryedHandle('invite_company')({})}
          types={['type-full-primary', 'size-medium']}
        >
          {t('permissions.invite_employee')}
        </Button>
      </div>
    );
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {this.renderTop(btn)}
        <SpaceFlexi type="vertical" size="35px" />
        {this.renderEmptyCompany()}
      </div>
    );
  }

  render() {
    return (
      <div>
        <MainWrapper disableWrapper currentPage="permission" color="grey">
          {this.props.infoMessage.get('text') && (
            <div className={cx('permission__infomessagewrap')}>
              <InfoMessage
                onClose={this.curryedHandle('hideinfomessage')({})}
                text={t(this.props.infoMessage.get('text'))}
                color="red"
                isSmall
                style={{
                  width: this.props.infoMessage.get('width') || 284,
                }}
              />
            </div>
          )}
          <PagePadding type="absolute-scroll">
            {this.props.isLoaded && this.renderMain()}
            {this.props.isLoadingFull && <LoadingSplash />}
          </PagePadding>
          <PermissionModal />
        </MainWrapper>
        <Modal type="loading" show={this.props.loadingModal.isLoadingFull} />
      </div>
    );
  }
}

function mapStateToProps() {
  const memo = selectLoadingState(initLoadingKey);
  const memo2 = selectLoadingState(teamLoadingKey);
  const memo3 = selectLoadingState(updateActionLoadingKey);
  const memo4 = selectTabData();
  return (state, props) => ({
    ...memo(state),
    loadingTeam: memo2(state),
    loadingModal: memo3(state),
    infoMessage: selectInfoMessage()(state),
    team: selectFirstTeam()(state),
    hasInternal: lodashResult(memo4(state), ['lengthInternal']) > 0,
    verifyModal: lodashResult(props, ['location', 'query', 'verifyResult']),
  });
}

export default connect(mapStateToProps, {
  initPermissionsSaga,
  handlePermissionActionSaga,
  setModal,
})(PermissionsContainerCompany);
