import { fromJS } from 'immutable';
import { GLOBAL_LOGOUT } from '../../../../types';
import {
  LIBRARY_UPDATE_LIBRARY_SEARCH_QUERY,
  LIBRARY_UPDATE_TABS_VALUE,
  CHECKLIST_TOGGLE_DISPLAY_USER_CHECKLISTS,
  CHECKLIST_TOGGLE_DISPLAY_PUBLISHED_CHECKLISTS,
  CHECKLIST_LOAD_INDEPENDENT_CHECKLISTS_REQUEST,
  CHECKLIST_LOAD_INDEPENDENT_CHECKLISTS_SUCCESS,
  CHECKLIST_LOAD_INDEPENDENT_CHECKLISTS_FAILURE,
  CHECKLIST_LOAD_PUBLISHED_CHECKLISTS_REQUEST,
  CHECKLIST_LOAD_PUBLISHED_CHECKLISTS_SUCCESS,
  CHECKLIST_LOAD_PUBLISHED_CHECKLISTS_FAILURE,
  CHECKLIST_LOAD_SINGLE_CHECKLIST_REQUEST,
  CHECKLIST_LOAD_SINGLE_CHECKLIST_SUCCESS,
  CHECKLIST_LOAD_SINGLE_CHECKLIST_FAILURE,
  CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_REQUEST,
  CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_SUCCESS,
  CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_FAILURE,
  CHECKLIST_REMOVE_CHECKLIST_FROM_LIBRARY_REQUEST,
  CHECKLIST_REMOVE_CHECKLIST_FROM_LIBRARY_SUCCESS,
  CHECKLIST_REMOVE_CHECKLIST_FROM_LIBRARY_FAILURE,
  CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_REQUEST,
  CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_SUCCESS,
  CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_FAILURE,
  SKILLS_LOAD_SUCCESS,
  SKILLS_LOAD_REQUEST,
  SKILLS_LOAD_FAILURE,
  SKILL_LOAD_SUCCESS,
  SKILL_LOAD_FAILURE,
  SKILL_LOAD_REQUEST,
  SKILLS_ACCESS,
} from './library.constants';

const initState = fromJS({
  checklistSearchQuery: '',
  skillSearchQuery: '',

  tabsValue: 0,

  hideUserChecklists: false,
  hidePublishedChecklists: false,

  singleChecklistLoading: {},
  singleChecklistError: {},

  userChecklists: {},
  userChecklistsIsLoading: false,
  userChecklistsHasError: null,

  publishedChecklists: {},
  publishedChecklistsIsLoading: false,
  publishedChecklistsHasError: null,

  skills: {},
  skillsIsLoading: false,
  skillsHasError: null,

  singleSkillLoading: false,
  singleSkillError: null,
  hasSkillsAccess: true,

  cloneChecklistIntoPropertiesSubmitting: {},
  cloneChecklistIntoPropertiesError: {},

  removeChecklistFromLibrarySubmitting: {},
  removeChecklistFromLibraryError: {},

  createNewChecklistFromLibrarySubmitting: false,
  createNewChecklistFromLibraryError: null,
});

const getChecklistId = checklist => checklist.objectId || checklist.id || checklist._id; // eslint-disable-line

const mapChecklistsToHashMap = checklists =>
  (checklists || []).reduce((acc, current) => ({ ...acc, [getChecklistId(current)]: current }), {});
const mapSkillsToHashMap = skills => (skills || []).reduce((acc, skill) => ({ ...acc, [skill?.skillId]: skill }), {});

export default function reducer(state = initState, action = {}) {
  switch (action.type) {
    case GLOBAL_LOGOUT: // on logout reset
      return initState;
    case LIBRARY_UPDATE_LIBRARY_SEARCH_QUERY:
      return action.isSkill
        ? state.set('skillSearchQuery', action.searchQuery || '')
        : state.set('checklistSearchQuery', action.searchQuery || '');

    case LIBRARY_UPDATE_TABS_VALUE:
      return state.set('tabsValue', action.value);

    case CHECKLIST_TOGGLE_DISPLAY_USER_CHECKLISTS:
      return state.set('hideUserChecklists', !state.get('hideUserChecklists'));
    case CHECKLIST_TOGGLE_DISPLAY_PUBLISHED_CHECKLISTS:
      return state.set('hidePublishedChecklists', !state.get('hidePublishedChecklists'));

    // CHECKLIST_LOAD_CHECKLISTS
    case CHECKLIST_LOAD_INDEPENDENT_CHECKLISTS_REQUEST:
      return state.set('userChecklistsIsLoading', true).set('userChecklistsHasError', null);
    case CHECKLIST_LOAD_INDEPENDENT_CHECKLISTS_SUCCESS:
      return state
        .set('userChecklistsIsLoading', false)
        .set('userChecklists', fromJS(mapChecklistsToHashMap(action.checklists)));
    case CHECKLIST_LOAD_INDEPENDENT_CHECKLISTS_FAILURE:
      return state.set('userChecklistsIsLoading', false).set('userChecklistsHasError', action.error);

    // CHECKLIST_LOAD_PUBLISHED_CHECKLISTS
    case CHECKLIST_LOAD_PUBLISHED_CHECKLISTS_REQUEST:
      return state.set('publishedChecklistsIsLoading', true).set('publishedChecklistsHasError', null);
    case CHECKLIST_LOAD_PUBLISHED_CHECKLISTS_SUCCESS:
      return state
        .set('publishedChecklistsIsLoading', false)
        .set('publishedChecklists', fromJS(mapChecklistsToHashMap(action.checklists)));
    case CHECKLIST_LOAD_PUBLISHED_CHECKLISTS_FAILURE:
      return state.set('publishedChecklistsIsLoading', false).set('publishedChecklistsHasError', action.error);

    // CHECKLIST_LOAD_SINGLE_CHECKLIST
    case CHECKLIST_LOAD_SINGLE_CHECKLIST_REQUEST:
      return state
        .setIn(['singleChecklistLoading', action.checklistId], true)
        .setIn(['singleChecklistError', action.checklistId], null);
    case CHECKLIST_LOAD_SINGLE_CHECKLIST_SUCCESS:
      return state.setIn(['singleChecklistLoading', action.checklistId], false).mergeIn(
        [action.published ? 'publishedChecklists' : 'userChecklists'],
        fromJS({
          [action.checklistId]: Object.assign({}, action.checklist, {
            jobInstructions: action.checklistInstructions,
          }),
        }),
      );
    case CHECKLIST_LOAD_SINGLE_CHECKLIST_FAILURE:
      return state
        .setIn(['singleChecklistLoading', action.checklistId], false)
        .setIn(['singleChecklistError', action.checklistId], action.error);

    // CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES
    case CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_REQUEST:
      return state
        .setIn(['cloneChecklistIntoPropertiesSubmitting', action.checklistId], true)
        .setIn(['cloneChecklistIntoPropertiesError', action.checklistId], null);
    case CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_SUCCESS:
      return state.setIn(['cloneChecklistIntoPropertiesSubmitting', action.checklistId], false);
    case CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_FAILURE:
      return state
        .setIn(['removeChecklistFromLibrarySubmitting', action.checklistId], false)
        .setIn(['removeChecklistFromLibraryError', action.checklistId], action.error);

    // CHECKLIST_REMOVE_CHECKLIST_FROM_LIBRARY
    case CHECKLIST_REMOVE_CHECKLIST_FROM_LIBRARY_REQUEST:
      return state
        .setIn(['removeChecklistFromLibrarySubmitting', action.checklistId], true)
        .setIn(['removeChecklistFromLibraryError', action.checklistId], null);
    case CHECKLIST_REMOVE_CHECKLIST_FROM_LIBRARY_SUCCESS:
      return state
        .setIn(['removeChecklistFromLibrarySubmitting', action.checklistId], false)
        .removeIn(['userChecklists', action.checklistId]);
    case CHECKLIST_REMOVE_CHECKLIST_FROM_LIBRARY_FAILURE:
      return state
        .setIn(['removeChecklistFromLibrarySubmitting', action.checklistId], false)
        .setIn(['removeChecklistFromLibraryError', action.checklistId], action.error);

    // CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY
    case CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_REQUEST:
      return state.set('createNewChecklistFromLibrarySubmitting', true).set('createNewChecklistFromLibraryError', null);
    case CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_SUCCESS:
      return state.set('createNewChecklistFromLibrarySubmitting', false);
    case CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_FAILURE:
      return state
        .set('createNewChecklistFromLibrarySubmitting', false)
        .set('createNewChecklistFromLibraryError', action.error);

    // SKILLS LIBRARY
    case SKILLS_LOAD_REQUEST:
      return state.set('skillsIsLoading', true);
    case SKILLS_LOAD_SUCCESS:
      return state.set('skillsIsLoading', false).set('skills', fromJS(mapSkillsToHashMap(action.skills)));
    case SKILLS_LOAD_FAILURE:
      return state.set('skillsIsLoading', false).set('skillsHasError', action.error);

    case SKILL_LOAD_REQUEST:
      return state.set('singleSkillLoading', true).set('singleSkillError', null);
    case SKILL_LOAD_SUCCESS:
      return state.set('singleSkillLoading', false).mergeIn(
        ['skills'],
        fromJS({
          [action.skillId]: action.skill,
        }),
      );
    case SKILL_LOAD_FAILURE:
      return state.set('singleSkillLoading', false).set('singleSkillError', action.error);
    case SKILLS_ACCESS:
      return state.set('hasSkillsAccess', action.hasAccess);

    default:
      return state;
  }
}
