import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SearchField } from '@properly/components';

import reduce from 'lodash/reduce';
import indexOf from 'lodash/indexOf';
import t from '@properly/localization';

class PropertyGeoContainer extends PureComponent {
  setRef(node) {
    if (!node) return;
    const a = new window.google.maps.places.Autocomplete(node, { types: ['geocode'] });
    const { onSet } = this.props;
    a.addListener('place_changed', () => {
      const place = a.getPlace();
      if (place) {
        const res = reduce(
          place.address_components,
          (total, item) => {
            if (indexOf(item.types, 'postal_code') !== -1) {
              total.zip = item.short_name;
            }
            if (indexOf(item.types, 'country') !== -1) {
              total.country = item.long_name;
            }
            if (indexOf(item.types, 'administrative_area_level_1') !== -1) {
              total.state = item.short_name;
            }
            if (indexOf(item.types, 'locality') !== -1) {
              total.city = item.long_name;
            }
            if (indexOf(item.types, 'route') !== -1) {
              total.street = item.short_name;
            }
            if (indexOf(item.types, 'street_number') !== -1) {
              total.streetnum = item.short_name;
            }
            return total;
          },
          {
            zip: '',
            country: '',
            state: '',
            city: '',
            street: '',
            streetnum: '',
          },
        );
        if (res.streetnum && res.street) {
          res.street = `${res.streetnum} ${res.street}`;
          delete res.streetnum;
        }
        onSet(res);
      }
    });
  }

  render() {
    return (
      <SearchField
        onRefSet={node => this.setRef(node)}
        fitInBox
        placeholder={t('properties.search_for_address2')}
        reverseStyle
        iconLocation="left"
      />
    );
  }
}

PropertyGeoContainer.propTypes = {
  onSet: PropTypes.func.isRequired,
};

export default PropertyGeoContainer;
