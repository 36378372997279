import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import log from 'loglevel';
import { PopoverDefault, LoadingSpinner, Icon, ButtonList, Small, Micro } from '@properly/components';
import t from '@properly/localization';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

class ChecklistTileProperties extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    type: PropTypes.oneOf(['dashed', 'image']),
    id: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onSetDefault: PropTypes.func,
    onDuplicate: PropTypes.func,
    onDuplicateOther: PropTypes.func,
    slideCount: PropTypes.number,
    taskCount: PropTypes.number,
    isDefaultChecklist: PropTypes.bool,
    isLoading: PropTypes.bool,
    isShowOnly: PropTypes.bool,
    subTitle: PropTypes.string,
  };

  static defaultProps = {
    slideCount: 0,
    taskCount: 0,
    isDefaultChecklist: false,
  };

  handleClick = (e, force) => {
    const isNotMorePopoverClick = e.target.getAttribute('data-ele') === 'outer';
    const { onClick } = this.props;

    log.info('ChecklistTileProperties handleClick', e, { force, isNotMorePopoverClick });

    if (onClick && (force || isNotMorePopoverClick)) {
      onClick(e);
    }
  };

  renderPopoverOverlay(closePopup) {
    const {
      slideCount,
      isDefaultChecklist,
      taskCount,
      onSetDefault,
      onDuplicate,
      onDuplicateOther,
      onDuplicateLibrary,
      onDelete,
    } = this.props;

    // Only display the prefil option if they have tasks in the checklist
    const displayPrefill = isDefaultChecklist || Number(slideCount) + Number(taskCount) > 0;

    const checklistActionItemList = [
      ...(displayPrefill
        ? [
            {
              'data-key': 'checklist_settings_button',
              name: t('checklist.set_default_settings'),
              onClick: onSetDefault,
              icon: <Icon.IcDefaultSettings />,
            },
          ]
        : []),
      {
        'data-key': 'checklist_duplicate_button',
        name: t('checklist.duplicate'),
        onClick: onDuplicate,
        icon: <Icon.IcDuplicate2 />,
      },
      {
        'data-key': 'checklist_duplicate_into_other_button',
        name: t('checklist.duplicate_into_other'),
        onClick: onDuplicateOther,
        icon: <Icon.IcDuplicate2 />,
      },
      {
        'data-key': 'checklist_duplicate_into_library_button',
        name: t('checklist.duplicate_into_library'),
        onClick: onDuplicateLibrary,
        icon: <Icon.IcDuplicate2 />,
      },
      {
        'data-key': 'checklist_delete_button',
        name: t('checklist.delete'),
        onClick: onDelete,
        icon: <Icon.IcTrash2 />,
      },
    ];

    return <ButtonList smallPadding mediumPrimaryText closePopover={closePopup} items={checklistActionItemList} />;
  }

  render() {
    const { title, subTitle, type, id, isLoading, isHovered, isTouch, isShowOnly } = this.props;
    return (
      <div className={cx('checklist-tile-prop')} data-key-id={id} data-key="property-checklist-container">
        <div
          id={`item-tile-id-${id}`}
          className={cx('checklist-tile-prop__canvas', {
            'checklist-tile-prop__canvas--dashed': type === 'dashed',
            'checklist-tile-prop__canvas--grey': type === 'image',
            'checklist-tile-prop__canvas--greytouch': isTouch,
            'checklist-tile-prop__canvas--hover': isHovered,
          })}
        >
          <div data-ele="outer" onClick={e => this.handleClick(e, true)}>
            {isLoading && (
              <div data-ele="outer" className={cx('checklist-tile-prop__fillflex')}>
                <LoadingSpinner />
              </div>
            )}
            {type === 'dashed' && !isLoading && (
              <div data-ele="outer" className={cx('checklist-tile-prop__fillflex')}>
                <Icon.IcPlusOrange onClick={e => this.handleClick(e, true)} />
              </div>
            )}
            {type === 'image' && !isLoading && (
              <div className={cx('checklist-tile-prop__fillflex')}>
                <div className={cx('checklist-tile-prop__image')}>
                  <Icon.IcBigJobs width="46px" height="46px" byWidth />
                </div>
                <div className={cx('checklist-tile-prop__image__hovered')}>
                  <Icon.IcBigJobsOrange width="50px" height="50px" byWidth />
                </div>
              </div>
            )}
            <div data-ele="outer" className={cx('checklist-tile-prop__canvas-layer')} />
          </div>
          {!isShowOnly && (
            <PopoverDefault
              popoverKey={id}
              popoverContent={PopOverState => this.renderPopoverOverlay(PopOverState.closePopup)}
              width={300}
              hasArrow
              arrowPosition={{
                vertical: 'bottom',
              }}
            >
              <div className={cx('checklist-tile-prop__canvas-layer-icon')}>
                <div data-key="button_checklist_open_popover" className={cx('checklist-tile-prop__icon')}>
                  <Icon.IcMorePrimary width="1.5em" byWidth />
                </div>
              </div>
            </PopoverDefault>
          )}
        </div>
        <div className={cx('checklist-tile-prop__title')}>
          <Small>{title}</Small>
        </div>
        {subTitle && (
          <div className={cx('checklist-tile-prop__subtitle')}>
            <Micro type="grey">{subTitle}</Micro>
          </div>
        )}
      </div>
    );
  }
}

export default ChecklistTileProperties;
