export const ROUTES = {
  // front
  main: '/',

  // bookingcom public
  bookingcomPublic: '/public/bookingcom/connect/',

  // default landing page
  landingPage: '/calendar',

  // properties
  properties: '/properties/',
  newProperties: '/properties/new',
  propertyPage: id => (id && `/properties/${id}/jobrequest`) || '/properties/',
  propertyPageGallery: id => `/properties/${id}/gallery`,
  propertyPageJobRequests: id => `/properties/${id}/jobrequest`,
  propertyPageDetails: id => `/properties/${id}/details`,
  propertyPageChecklists: id => `/properties/${id}/checklists`,
  propertyPageConnections: id => `/properties/${id}/connections`,
  propertyPageEdit: id => `/properties/${id}/edit`,
  propertyPageEvents: id => `/properties/${id}/events`,
  bookingPageFromProperty: (propertyId, bookingId) => `/properties/${propertyId}/jobrequest/booking/${bookingId}`,

  // checklists
  checklist: (checklistId, jobInstructionId) => `/checklists/${checklistId}/${jobInstructionId}/`,

  // library
  library: '/library',
  libraryCreate: '/library/create',
  libraryChecklist: (checklistId, jobInstructionId, published) =>
    published
      ? `/library/published/${checklistId || ''}/${jobInstructionId || ''}`
      : `/library/independent/${checklistId || ''}/${jobInstructionId || ''}`,
  libraryChecklistClone: (checklistId, jobInstructionId, published) =>
    published
      ? `/library/clone/published/${checklistId || ''}/${jobInstructionId || ''}`
      : `/library/clone/independent/${checklistId || ''}/${jobInstructionId || ''}`,

  // skills
  librarySkill: skillId => `/library/skill/${skillId}`,

  // account
  account: '/account/',
  accountConnectionsMobile: '/account-mobile',
  oauth: '/account/oauth/',
  accountNotifications: '/account/notifications/',
  accountPassword: '/account/password/',
  checkout: '/checkout/',

  // contact
  contacts: '/contacts/',
  contactPage: (id, contactPage) => `/contacts/${id}/${contactPage || 'upcoming'}`,
  contactPageAll: id => `/contacts/${id}/all`,
  bookingPageFromContacts: (contactId, contactPage, propertyId, bookingId) =>
    `/contacts/${contactId}/${contactPage}/booking/${propertyId}/${bookingId}`,

  // login signup
  newPassword: '/password/new',
  resetPassword: '/password/reset',
  importAccounts: '/hostSignUpAccounts',
  login: '/login',
  signup: '/hostSignUpForm',
  hostDownload: '/hostSignUpDownload',
  signUpComplete: '/hostSignUpComplete',

  // calendar
  calendar: '/calendar',
  bookingPageFromCalendar: (propertyId, bookingId) => `/calendar/event/${propertyId}/${bookingId}`,

  // reports
  reports: '/reports/new',
  reportPage: id => `/reports/${id || 'new'}`,
  bookingPageFromReports: (reportId, propertyId, bookingId) =>
    `/reports/${reportId || 'new'}/booking/${propertyId}/${bookingId}`,

  // settings
  settings: '/settings/connections',
  settingsConnections: '/settings/connections',
  settingsConnectionsEdit: (partner, id) => `/settings/connections/${partner}/${id}`,
  settingsCompany: '/settings/company',
  settingsPartner: '/settings/partner',
  settingsPartnerShared: '/settings/partner/shared',
  settingsPaymentMethods: '/settings/payment-methods',
  settingsIotDevices: '/settings/iot-devices',
  settingsReceipts: '/settings/receipts',
  settingsSubscription: '/settings/subscription',

  // todos
  todos: '/todos/',
  bookingPageFromTodos: (propertyId, bookingId) => `/todos/booking/${propertyId}/${bookingId}`,

  // permissions
  permissions: '/permissions/',

  // error
  error: '/error',
  error404: '/error404',

  // mobile
  mobileSubscription: 'mobile/subscription',
  mobileUpgrade: 'mobile/upgrade',

  // branding
  brandingMobileUpgrade: userEmail => `branding/mobile/downloadApp/${userEmail}`,

  // admin panel
  loginAs: id => `/loginas/${id}/`,
};

export const ROUTENAMES = {
  propertyjobrequest: 'jobrequest',
  propertydetails: 'details',
  propertychecklists: 'checklists',
  propertyconnections: 'connections',
  propertyevents: 'events',
};

export const mapRouteNameToRoute = {
  jobrequest: ROUTES.propertyPageJobRequests,
  details: ROUTES.propertyPageDetails,
  checklists: ROUTES.propertyPageChecklists,
  connections: ROUTES.propertyPageConnections,
  events: ROUTES.propertyPageEvents,
};
