export default class StripePaymentMethodCard {
  constructor(id, brand, country, expMonth, expYear, funding, last4) {
    this.id = id;
    this.brand = brand;
    this.country = country;
    this.expMonth = expMonth;
    this.expYear = expYear;
    this.funding = funding;
    this.last4 = last4;
  }
}
