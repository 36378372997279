import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import t from '@properly/localization';
import { SpaceFlexi, RoundSelect } from '@properly/components';

import filter from 'lodash/filter';
import map from 'lodash/map';
import indexOf from 'lodash/indexOf';
import { JobRequestTextMain, JobRequestPermissionSection } from '../../../components/JobRequestElements';

class PermissionList extends PureComponent {
  static propTypes = {
    permissionMap: PropTypes.arrayOf(PropTypes.shape({})),
    blackList: PropTypes.arrayOf(PropTypes.string),
    noSpacingFirst: PropTypes.bool,
    spaceAll: PropTypes.bool,
    keyPrefix: PropTypes.string.isRequired,
    isSelected: PropTypes.func.isRequired,
    isDisabled: PropTypes.func.isRequired,
    onChangeFunc: PropTypes.func.isRequired,
  };

  render() {
    const {
      noSpacingFirst,
      permissionMap,
      spaceAll,
      keyPrefix,
      isSelected,
      isDisabled,
      onChangeFunc,
      blackList,
    } = this.props;
    return (
      <div>
        {map(permissionMap, (group, index) => {
          const children = filter(group.children, child => indexOf(blackList, child.permissionId) === -1);
          if (children.length === 0) return null;
          return (
            <div key={`${keyPrefix}-${group.groupId}`}>
              {((index !== 0 && noSpacingFirst) || spaceAll) && <SpaceFlexi type="vertical" size="15px" />}
              <JobRequestTextMain type="sub">{t(group.groupName)}</JobRequestTextMain>
              <SpaceFlexi type="vertical" size="3px" />
              {map(children, child => (
                <div key={`${keyPrefix}-${child.permissionId}`}>
                  <JobRequestPermissionSection
                    text={t(child.permissionName)}
                    rightComponent={() => (
                      <RoundSelect
                        disabled={isDisabled(child.permissionId)}
                        style={{ height: 18, width: 18 }}
                        type="quarter"
                        selected={isSelected(child.permissionId)}
                        onChange={onChangeFunc(child.permissionId)}
                      />
                    )}
                  />
                </div>
              ))}
            </div>
          );
        })}
      </div>
    );
  }
}

export default PermissionList;
