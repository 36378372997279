import React, { PureComponent } from 'react';
import t, { detectLanguage } from '@properly/localization';
import { DividerLine, Button, LoadingSplash, SpaceBase, Title3 } from '@properly/components';
import { openInNewWindowBlank } from '@properly/common';
import moment from 'moment-timezone';
import map from 'lodash/map';
import first from 'lodash/first';
import log from 'loglevel';
import { connect } from 'react-redux';
import { PagePadding } from '../../../../components/PageElements/index';
import InfoMessage from '../../../../components/InfoMessage/index';
import { parseGetInvoicesByUser } from '../../data';
import { openPricingModalSaga } from '../../pricing/PricingActions';
import { recurlyInvoiceUrl } from '../../../../config';
import { isCustomPlan } from '../../../../helper/herbert';
import { trackVisitReceiptPage } from '../../../../actions/trackingEvents';
import { AccountHeadSection } from '../../../../components/AccountElements/index';
import { selectCurrentUserSubscription } from '../../../../selectors/globalSelector';
import ReceiptsTable from '../../../../components/ReceiptsTable/index';

function mapStatus(status) {
  switch (status) {
    case 'open':
      return t('pricing.status.unpaid');
    case 'failed':
      return t('pricing.status.unpaid');
    case 'past_due':
      return t('pricing.status.unpaid');
    case 'processing':
      return t('pricing.status.unpaid');
    case 'collected':
      return t('pricing.status.paid');
    default:
      return 'Undefined';
  }
}

function formatInvoice(invoice) {
  const price = invoice.totalCents / 100;
  let formattedPrice = price.toFixed(2);
  if (typeof Intl === 'object' && typeof Intl.NumberFormat === 'function') {
    const language = detectLanguage();
    const currencyFormat = Intl.NumberFormat(language, {
      minimumFractionDigits: 2,
    });
    formattedPrice = currencyFormat.format(price);
  }
  let plan = first(invoice.lineItems);
  if (plan) plan = plan.description;
  return {
    price: `${invoice.currency} ${formattedPrice}`,
    id: invoice.number,
    date: moment(invoice.created).format('L'),
    transaction: mapStatus(invoice.state),
    plan,
  };
}

const headRowName = '__HEAD';
const isHeadRow = id => id === headRowName;

class AccountReceiptsContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.section = ['date', 'id', 'price', 'plan', 'transaction'];
    this.underline = {
      id: true,
    };
    this.state = {};
    this.state.isLoading = true;
    this.state.data = [
      // {
      //   date: '29/01/17',
      //   id: '000000',
      //   price: '$8.00',
      //   plan: 'Advanced 1500 Properties',
      //   transaction: 'paid'
      // },
    ];
  }

  componentDidMount() {
    trackVisitReceiptPage();
    parseGetInvoicesByUser()
      .then(res => {
        this.setState({ data: map(res, formatInvoice), isLoading: false });
      })
      .catch(e => {
        log.error('parseGetInvoicesByUser', e);
        this.setState({ isLoading: false });
      });
  }

  openRecurly = (id, canOpen) => () => {
    if (!canOpen) return;
    openInNewWindowBlank(recurlyInvoiceUrl(id, this.props.subscription.subscriptionProviderToken), '_blank');
  };

  tdWrap(data, section, id, isHead) {
    const canOpen = this.underline[section] && !isHeadRow(id);
    const resolvedId = this.state.data[id] ? this.state.data[id].id : null;
    return (
      <ReceiptsTable.Col
        key={`table${resolvedId}-${section}`}
        onClick={this.openRecurly(resolvedId, canOpen)}
        isUnderlined={canOpen}
        isHead={isHead}
      >
        {data}
      </ReceiptsTable.Col>
    );
  }

  getDataForSection(id, section) {
    if (isHeadRow(id)) {
      return this.tdWrap(t(`account.receipttable.${section}`), section, id, true);
    }
    return this.tdWrap(this.state.data[id][section], section, id);
  }

  renderRow(id) {
    return (
      <ReceiptsTable.Row key={`tablerow${id}`} isHead={isHeadRow(id)}>
        {map(this.section, value => this.getDataForSection(id, value))}
      </ReceiptsTable.Row>
    );
  }

  renderBody() {
    return map(this.state.data, (val, key) => this.renderRow(key));
  }

  renderTable() {
    return (
      <ReceiptsTable>
        <ReceiptsTable.Head>{this.renderRow(headRowName)}</ReceiptsTable.Head>
        <ReceiptsTable.Body>{this.renderBody()}</ReceiptsTable.Body>
      </ReceiptsTable>
    );
  }

  get showUpgrade() {
    return (
      !this.state.isLoading && this.state.data.length === 0 && !isCustomPlan(this.props.subscription.currentPlanId)
    );
  }

  get showCustom() {
    return !this.state.isLoading && isCustomPlan(this.props.subscription.currentPlanId);
  }

  get showTable() {
    return !this.showCustom && !this.showUpgrade;
  }

  onUpgrade = () => {
    this.props.openPricingModalSaga('upgrade', {});
  };

  renderPage() {
    return (
      <div>
        <AccountHeadSection>
          <Title3 type="nomargin">{t('account.receipts')}</Title3>
          <DividerLine type={['bottom']} />
        </AccountHeadSection>
        <SpaceBase />
        {this.showUpgrade && (
          <InfoMessage text={t('account.receipt_warning')} icon="warning" color="red" isMergeInfo>
            <Button onClick={this.onUpgrade} types={['type-full-cancelled', 'size-medium', 'height-32']}>
              {t('account.upgrade')}
            </Button>
          </InfoMessage>
        )}
        {this.showCustom && (
          <InfoMessage text={t('account.please_contact_account')} icon="warning" color="red" isMergeInfo />
        )}
        {this.showTable && this.renderTable()}
      </div>
    );
  }

  render() {
    if (!this.props.subscription) return null;
    return (
      <PagePadding type="absolute-scroll">
        {this.state.isLoading && <LoadingSplash static />}
        {!this.state.isLoading && this.renderPage()}
      </PagePadding>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    subscription: selectCurrentUserSubscription(state, props),
  };
}

export default connect(mapStateToProps, {
  openPricingModalSaga,
})(AccountReceiptsContainer);
