import { delay } from 'redux-saga';
import { put, fork, select, call, take, cancel, takeEvery, takeLatest } from 'redux-saga/effects';
import log from 'loglevel';
import each from 'lodash/each';
import keys from 'lodash/keys';
import lodashResult from 'lodash/result';
import difference from 'lodash/difference';
import reduce from 'lodash/reduce';
import values from 'lodash/values';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import indexOf from 'lodash/indexOf';
import pick from 'lodash/pick';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import extend from 'lodash/extend';
import omit from 'lodash/omit';
import { getCurrencyFromCountryShortCode, addMinToDate, fixMomentDates, isSharedProperty } from '@properly/common';
import { error, skillStatus, featureFlag } from '@properly/config';
import merge from 'ramda/src/merge';
import moment from 'moment-timezone';
import * as types from '../../../../types/index';
import {
  setOpenSection,
  setDataJobRequest,
  setCommunityJobRequest,
  setStartupDataJobRequest,
  resetJobRequest,
  setHostStripeStatus,
} from './JobRequestActions';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import {
  getParseSuggestedCleanersNew,
  getParseJobsForProperty,
  updateParseJob,
  updateParseProperty,
  triggerLiveQuery,
  uploadImage,
} from '../../data';
import {
  updateCollectionEntryBatch,
  setModal,
  updateCollectionEntry,
  updateInBackgroundSaga,
  startPreloadSaga,
  setGlobalLoadingState,
} from '../../../../actions/globalActions';
import {
  selectJobRequestData,
  selectJobRequestMode,
  selectJobRequestStartupData,
  selectedJobRequestComputedData,
  selectJobRequestDataChecklistId,
  selectJobRequestTasks,
  selectJobRequestSkills,
} from './JobRequestSelectors';
import { sendJobRequestModes, mediaUploadLoadingKey, modals } from '../../../../dataConstants';
import { loadingKeyInit, loadingKeyChecklist, loadingKeyMain } from '../containers/JobRequestFormSchema';
import {
  trackSendJobRequestOpen,
  trackSendJobRequestToMoreCleaners,
  trackSendJobRequestSent,
  trackAssingSelectProperty,
  trackAssingSelectJob,
  trackAssingJobMe,
  trackAssingJobSelectedCleaner,
} from '../../../../actions/trackingEvents';
import { refetchUser } from '../../../../sagas/global';
import { prefilledDurationNewJobRequest } from '../../../../config';

import {
  openJobRequestDetails,
  closeJobRequestDetails,
  setResolvedProblemResponse,
} from '../../jobDetails/state/JobDetailsActions';
import { openPricingModalSaga } from '../../pricing/PricingActions';
import {
  loadIndependentChecklistsRequest,
  loadPublishedChecklistsRequest,
  loadSkillsRequest,
} from '../../library/state/library.actions';

// Graphql API
import {
  createJobRequest,
  editJobRequest,
  updateProblem,
  sendJobRequestToMoreCleaners,
} from '../../../../graphql/api/jobRequest';
import { getSpecificJobrequest } from '../../../../graphql/api/jobList';
import { getHostStripeStatus } from '../../../../graphql/api/stripe';
import { isCustomGraphQLError, determineErrorMessage } from '../../../../graphql/helper';
import { isSanctionedPropertyJob } from '../../../../helper/herbert';
import { canSendPropertyJobs } from '../../../../hoc/HasPermission/hasAccess';
import { selectIsFeatureFlagEnabledForUser } from '../../settings/state/SettingsSelectors';

const orderArray = [
  'propertyId',
  'proMarketProperty',
  'checklistId',
  'skills',
  'jobStartTimeType',
  'duration',
  'startTime',
  'endTime',
  'currency',
  'price',
  'cleaners',
  'title',
  'message',
  'isDefault',
  'tasks',
  'jobType',
];

const mapping = {
  propertyId: loadProperty,
  checklistId: loadChecklist,
};

const isMoment = obj => {
  const momentObj = moment(obj);
  return momentObj.isValid();
};

const merger = {
  defaultMerger: (oldVal, newVal) => newVal,
  cleaners: (oldVal, newVal) => extend({}, oldVal, newVal),
  startTime: (oldVal, newVal) => {
    if (isMoment(oldVal) && isString(newVal)) {
      return addMinToDate(oldVal, newVal);
    }
    if (isMoment(oldVal) && isMoment(newVal)) {
      return merger.defaultMerger(oldVal, newVal);
    }
    log.error(
      '⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️️️\nmerger issue - startTime',
      { oldVal, newVal },
      oldVal.prototype,
      typeof oldVal,
      typeof newVal,
    );
    return undefined;
  },
  endTime: (oldVal, newVal) => {
    if (isMoment(oldVal) && isString(newVal)) {
      return addMinToDate(oldVal, newVal);
    }
    if (isMoment(oldVal) && isMoment(newVal)) {
      return merger.defaultMerger(oldVal, newVal);
    }

    return undefined;
  },
};

function remappContacts(allCleaners, array) {
  return reduce(
    allCleaners,
    (total, item) => {
      if (indexOf(array, item.userData.objectId) !== -1 && !item.deleted) {
        total[item.userData.objectId] = item; //eslint-disable-line
      }
      return total;
    },
    {},
  );
}
function remappCleaners(allCleaners, array) {
  return reduce(
    allCleaners,
    (total, item) => {
      if (indexOf(array, item.id) !== -1 && item.status !== 'canceledByHost') {
        total[item.id] = item; //eslint-disable-line
      }
      return total;
    },
    {},
  );
}

function dateToMinutesOfDay(date) {
  return date.getHours() * 60 + date.getMinutes();
}

function* loadProperty(dataInput, settings) {
  const output = {};
  let loadChecklistsVal = 'sync';
  const { propertyId } = dataInput;
  if (!propertyId) {
    return output;
  }

  // snapshot property details so they can be edited
  const property = yield select(selectorsGlobal.selectProperty(propertyId));

  const isMarketPlaceFeatureFlagEnabled = yield select(
    selectIsFeatureFlagEnabledForUser(featureFlag.FEATURE_FLAG_MARKETPLACE),
  );
  // get promarketstatus
  output.proMarketProperty = !!property.proMarketProperty;
  // check for host stripe status
  if (isMarketPlaceFeatureFlagEnabled && output.proMarketProperty) {
    const res = yield call(getHostStripeStatus);
    yield put(setHostStripeStatus(res.hostStripeStatus?.defaultPaymentMethodExists));
  }

  // get currency
  const currency = dataInput.currency || getCurrencyFromCountryShortCode(property.countryCode) || 'USD';
  output.currency = currency;
  // get community cleaners
  yield fork(getCommunityCleaners, property);

  const defaultPropertyChecklistId = property.defaultJob;

  if (defaultPropertyChecklistId && !settings.noPrefill) {
    output.checklistId = [defaultPropertyChecklistId];
  }

  if (!lodashResult(output, ['checklistId', 0]) && !lodashResult(dataInput, ['checklistId', 0])) {
    loadChecklistsVal = 'async';
  }

  if (settings.isReadOnly) {
    loadChecklistsVal = 'async';
  }

  if (settings.noPropertyRefetch) {
    loadChecklistsVal = false;
  }

  // trigger download of checklists
  if (loadChecklistsVal === 'sync') {
    yield call(loadChecklists, propertyId, defaultPropertyChecklistId);
  } else if (loadChecklistsVal === 'async') {
    yield fork(loadChecklists, propertyId, defaultPropertyChecklistId);
  }

  log.info('sendJobRequest - loadProperty', { loadChecklistsVal });

  return output;
}

function* loadChecklist(dataInput) {
  let output = {};
  const firstChecklistId = lodashResult(dataInput, ['checklistId', 0]);

  if (!firstChecklistId) {
    return output;
  }

  const checklists = yield select(selectorsGlobal.selectJobs());
  const resolvedChecklist = checklists.get(firstChecklistId);

  log.info('sendJobRequest - loadChecklist', {
    resolvedChecklist,
    dataInput,
    firstChecklistId,
  });

  if (resolvedChecklist && resolvedChecklist.defaultSettings) {
    const normalized = yield normalizeInput(
      mapDefaultSettingsToLocalNames(resolvedChecklist.defaultSettings, {
        startTime: dataInput.startTime,
        endTime: dataInput.endTime,
      }),
    );
    output = extend({}, output, normalized);
    log.info('loadChecklist - defaulsettings', resolvedChecklist.defaultSettings, normalized);
  }

  if (!resolvedChecklist) {
    // If this checklist cannot be resolved then we cannot send it.
    output.checklistId = (dataInput.checklistId || []).filter(id => id !== firstChecklistId);
  }

  if (!moment(output.startTime).isValid() || !moment(output.endTime).isValid()) {
    log.error('Invalid startTime / endTime from loadChecklist', output);
  }

  return output;
}

function mapDefaultSettingsToLocalNames(input, { startTime, endTime }) {
  return {
    ...input,
    price: input.offeredPrice,
    startTime: startTime || input.startTime ? addMinToDate(startTime, input.startTime) : input.startTime,
    endTime: endTime || input.endTime ? addMinToDate(endTime, input.endTime) : input.endTime,
  };
}
function* sendJobRequestMode(data) {
  const contacts = yield select(selectorsGlobal.selectContacts());
  const output = {};
  each(orderArray, key => {
    if (data[key]) {
      if (key === 'cleaners') {
        output[key] = remappContacts(contacts?.toJS(), data[key]);
      } else {
        output[key] = data[key];
      }
    }
  });
  return output;
}
function* editJobRequestMode(data, jobRequestId) {
  const jobRequests = yield select(selectorsGlobal.selectJobRequests());
  const jobRequestsJS = jobRequests?.toJS();
  const jobRequest = get(jobRequestsJS, jobRequestId);

  const { requestedCleaners, tasks, jobType, sourceProblems, jobStartTimeType, propertyId } = jobRequest;

  const cleaners = requestedCleaners.map(cleaner => {
    cleaner.isDisabled = true;
    return cleaner;
  });

  const transformedCleaners = keyBy(cleaners, 'id');

  // Select one-off tasks
  const mappedTasks = yield select(selectJobRequestTasks());
  const mappedTasksJS = mappedTasks?.toJS();

  const output = {};
  each(orderArray, key => {
    if (data[key]) {
      if (key === 'cleaners') {
        output[key] = remappCleaners(transformedCleaners, data[key]);
      } else {
        output[key] = data[key];
      }
    }
  });

  // This is too complictaed
  if (tasks) {
    const getProblemTasks = tasks.filter(task => task.isProblem);
    if (mappedTasksJS.length) {
      output.tasks = mappedTasksJS;
    } else if (getProblemTasks.length === tasks.length) {
      output.tasks = getProblemTasks;
    } else {
      output.tasks = tasks;
    }
  } else {
    output.tasks = mappedTasksJS;
  }
  const isMarketPlaceFeatureFlagEnabled = yield select(
    selectIsFeatureFlagEnabledForUser(featureFlag.FEATURE_FLAG_MARKETPLACE),
  );
  // get promarketstatus
  const property = yield select(selectorsGlobal.selectProperty(propertyId));
  output.proMarketProperty = !!property.proMarketProperty;
  // check for host stripe status
  if (isMarketPlaceFeatureFlagEnabled && output.proMarketProperty) {
    const res = yield call(getHostStripeStatus);
    yield put(setHostStripeStatus(res.hostStripeStatus?.defaultPaymentMethodExists));
  }

  output.jobType = jobType;
  output.jobStartTimeType = jobStartTimeType;
  output.sourceProblems = sourceProblems;
  return output;
}
function* normalizeInput(data, jobRequestId) {
  if (data !== {}) {
    if (jobRequestId === undefined) {
      return yield sendJobRequestMode(data);
    }
    return yield editJobRequestMode(data, jobRequestId);
  }
  return {};
}

function* executeFunc(key, data, settings) {
  if (mapping[key]) {
    return yield mapping[key](data, settings);
  }
  return {};
}

function* resolvedData(base, settings) {
  let i = 0;
  let obj = extend({}, base);
  while (i < orderArray.length) {
    const key = orderArray[i];
    const res = yield executeFunc(key, obj, settings);
    const resFinal = settings.isReadOnly ? {} : res;
    log.info('resolvedData', { key, resFinal, settings });
    obj = extend({}, obj, resFinal);
    i += 1;
  }
  return obj;
}

function mergeData(oldData, newData) {
  let i = 0;
  const obj = {};
  while (i < orderArray.length) {
    const key = orderArray[i];
    if (oldData[key] && newData[key]) {
      const mergeFunc = merger[key] || merger.defaultMerger;
      obj[key] = mergeFunc(oldData[key], newData[key]);
    } else {
      obj[key] = newData[key] ? newData[key] : oldData[key];
    }
    i += 1;
  }
  return obj;
}

function mapToTrackingAction(openData) {
  let action = '';
  if (openData.mode === sendJobRequestModes.normal) {
    action = 'new';
  }
  if (openData.mode === sendJobRequestModes.edit) {
    action = 'edit';
  }
  if (openData.mode === sendJobRequestModes.sendmore) {
    action = 'addmore';
  }
  if (openData.mode === sendJobRequestModes.prefill) {
    action = 'prefill';
  }
  if (openData.source === 'event') {
    action = 'scheduleEvent';
  }
  return action;
}

function* getCurrencyForProperty(propertyId) {
  const rawProperty = yield select(selectorsGlobal.selectProperty(propertyId));
  return getCurrencyFromCountryShortCode(rawProperty.countryCode) || 'USD';
}

function* initJobRequestSaga({ data }) {
  let inputData = data.data;
  // Load all library checklists and skills

  yield put(loadIndependentChecklistsRequest());
  yield put(loadPublishedChecklistsRequest());
  yield put(loadSkillsRequest());
  log.info('initJobRequestSaga - open', { data });
  const hasDefaultSettings = data && data.checklistObj && data.checklistObj.defaultSettings;

  if (data.mode === sendJobRequestModes.prefill) {
    const currency = yield getCurrencyForProperty(inputData.propertyId);
    if (hasDefaultSettings) {
      inputData = extend({}, inputData, mapDefaultSettingsToLocalNames(data.checklistObj.defaultSettings, {}));
    }
    inputData.currency = currency;
    yield openSectionHandler('time');
  } else if (data.mode === sendJobRequestModes.sendmore) {
    yield openSectionHandler('cleaners');
  } else if (data.mode === sendJobRequestModes.edit) {
    yield openSectionHandler('checklist');
  } else if (data.mode === sendJobRequestModes.problemSchedule) {
    yield openSectionHandler('checklist');
  }

  if (data.mode === sendJobRequestModes.edit && !inputData.currency) {
    inputData.currency = yield getCurrencyForProperty(inputData.propertyId);
  }

  // check if already open
  trackSendJobRequestOpen(data.source, mapToTrackingAction(data));

  let normalized;
  if (data.mode === sendJobRequestMode.normal) {
    normalized = yield normalizeInput(inputData);
  } else {
    normalized = yield normalizeInput(inputData, data.prevJobRequestId);
  }

  const extendedNormalized = extend({}, data, { data: normalized });

  log.info('initJobRequestSaga - normalized', {
    inputData,
    normalized,
    extendedNormalized,
  });

  yield put(setStartupDataJobRequest(extendedNormalized));
  yield put(setGlobalLoadingState(loadingKeyInit, 1));

  const isReadOnly =
    data.mode === sendJobRequestModes.edit ||
    data.mode === sendJobRequestModes.sendmore ||
    data.mode === sendJobRequestModes.prefill;

  const noPrefill = !!lodashResult(extendedNormalized, ['data', 'checklistId', 0]);
  yield reloadSection([], { isReadOnly, isInit: true, noPrefill });
  if (data.mode === sendJobRequestModes.normal) {
    // open section which needs to be filled next
    const dataComputed = yield select(selectedJobRequestComputedData());
    yield openSectionHandler(dataComputed.hightLightedSectionId || 'time'); // fallback to message if all are valid
  }
  yield put(setGlobalLoadingState(loadingKeyInit, 0));
}

function* reloadSection(whitelist, settings = {}) {
  const startupData = yield select(selectJobRequestStartupData());
  const jobData = yield select(selectJobRequestData());
  log.info('reloadSection - start', {
    startupData,
    jobData,
    settings,
    whitelist,
  });
  const defaults = { cleaners: {}, checklistId: [] };
  const preMerge = extend(defaults, startupData.get('data').toJS(), pick(jobData.toJS(), whitelist));

  log.info('reloadSection - preresolve', preMerge);
  const res = yield resolvedData(preMerge, settings);
  log.info('reloadSection - premerge', res);
  const merged = mergeData(preMerge, res);
  yield flushUpdate(merged, 'reloadSection');
}

function* loadChecklists(id) {
  try {
    yield put(setGlobalLoadingState(loadingKeyChecklist, 1));
    const res = yield call(getParseJobsForProperty, id);
    const checklistsById = keyBy(res, 'objectId');
    yield put(updateCollectionEntryBatch('jobs', checklistsById, true));

    return checklistsById;
  } catch (e) {
    log.error('loadChecklists', e);
    return {}; // nope
  } finally {
    yield put(setGlobalLoadingState(loadingKeyChecklist, 0));
  }
}

function* postCreateJobRequest(jobReq, payloadData) {
  // update jobrequest in store / cleanerrequest
  yield put(updateCollectionEntry('jobRequests', jobReq.jobId, jobReq));
  yield put(updateInBackgroundSaga('cleanerRequest', jobReq.jobId, true));
  yield put(startPreloadSaga()); // to refetch new contacts
  // if cancel also update cancelled
  if (payloadData && payloadData.cancelJobRequest) {
    yield put(updateInBackgroundSaga('jobRequests', payloadData.cancelJobRequest, true));
    yield put(updateInBackgroundSaga('cleanerRequest', payloadData.cancelJobRequest, true));
  }
  const modalTask = yield fork(maybeShowPriceWarningModal, payloadData.property);
  yield take(action => isCloseModal(action) && !lodashResult(action, ['meta', 'triggeredByScript']));
  const isRunning = modalTask.isRunning();
  yield cancel(modalTask);
  // because we cancelled make sure that we really have the latest subscription in store
  if (isRunning) {
    yield fork(refetchUser);
  }
}

function* maybeShowPriceWarningModal(property) {
  yield call(refetchUser);
  // check if needs message
  const res = yield select(selectorsGlobal.selectUserPlanData);
  const allowedJobsMonth = lodashResult(res, ['userData', 'subscription', 'allowedJobsMonth']);
  const jobsUsedMonth = lodashResult(res, ['userData', 'subscription', 'jobsUsedMonth'], 0);
  const user = yield select(selectorsGlobal.selectCurrentUser());
  const { ownerRole } = property || {};
  const mode = yield select(selectJobRequestMode);

  // Do not show subscription alert after creating a job if it is sent to a shared property.
  if (mode !== sendJobRequestModes.normal || isSharedProperty(ownerRole, user)) {
    return;
  }

  // check if its a sanctioned property ,for sanctioned property one can send unlimited jobs for 90 days
  if (!isSanctionedPropertyJob(property) && allowedJobsMonth > 0 && allowedJobsMonth - jobsUsedMonth < 3) {
    yield put(setModal(false, 'jobrequest', { triggeredByScript: true }));
    if (jobsUsedMonth >= allowedJobsMonth) {
      yield put(
        openPricingModalSaga('default', {
          defaultKind: 'jobrequestlimitreached',
        }),
      );
    } else {
      yield put(
        openPricingModalSaga('default', {
          defaultKind: 'yousend',
        }),
      );
    }
  }
}

export function* defaultFlow(data, fields, isEdit) {
  try {
    yield put(setGlobalLoadingState(loadingKeyMain, 1));

    const finalData = pick(data, fields);
    let res;
    if (!isEdit) {
      res = yield call(createJobRequest, finalData);
    } else if (isEdit) {
      res = yield call(editJobRequest, {
        id: data.prevJobRequestId,
        job: finalData,
      });

      yield delay(1000);
    }

    if (isCustomGraphQLError(res, error.JOB_ALREADY_SCHEDULED)) {
      // Forcibly trigger live query for that event
      yield call(triggerLiveQuery, finalData.eventId);

      yield openSectionHandler('job_already_scheduled');
      return yield put(setGlobalLoadingState(loadingKeyMain, 3));
    }

    if (isCustomGraphQLError(res, error.CANNOT_SEND_JOB_TO_COMMUNITY_SP_FROM_SAMPLE_PROPERTY)) {
      yield openSectionHandler('community_service_provider_sample_property_error');
      return yield put(setGlobalLoadingState(loadingKeyMain, 3));
    }
    // check for all errors and display accordingly, only unhandled errors to go to catch
    const isGraphQLApiError = determineErrorMessage(res);
    if (isGraphQLApiError?.status === 'ERROR') {
      yield openSectionHandler('error_handled_from_api', isGraphQLApiError.message);
      return yield put(setGlobalLoadingState(loadingKeyMain, 3));
    }

    yield put(setGlobalLoadingState(loadingKeyMain, 2));
    yield openSectionHandler('success');
    trackSendJobRequestSent(
      data.checklist[0],
      data.selectedCleaners.length,
      moment(data.day).diff(moment(), 'days'),
      moment(data.endDay).diff(moment(), 'days'),
      data.eventId,
      data.checklist.length,
      data.checklist.join(','),
      isEdit,
      data.jobStartTimeType,
    );

    const singleJobReq = yield call(getSpecificJobrequest, res.transactionId);

    yield call(postCreateJobRequest, singleJobReq, finalData);
  } catch (e) {
    log.error('submitSaga', e);
    yield openSectionHandler('error');
    yield put(setGlobalLoadingState(loadingKeyMain, 3));
  }
  return null;
}

function* addMoreFlow({ prevJobRequestId, selectedCleaners, property }) {
  try {
    trackSendJobRequestToMoreCleaners();
    yield put(setGlobalLoadingState(loadingKeyMain, 1));

    const params = {
      id: prevJobRequestId,
      serviceProviderIds: selectedCleaners,
      propertyId: property.objectId,
    };

    const res = yield call(sendJobRequestToMoreCleaners, params);

    if (isCustomGraphQLError(res, error.CANNOT_SEND_JOB_TO_COMMUNITY_SP_FROM_SAMPLE_PROPERTY)) {
      yield openSectionHandler('community_service_provider_sample_property_error');
      return yield put(setGlobalLoadingState(loadingKeyMain, 3));
    }

    // check for all errors and display accordingly, only unhandled errors to go to catch
    const isGraphQLApiError = determineErrorMessage(res);
    if (isGraphQLApiError?.status === 'ERROR') {
      yield openSectionHandler('error_handled_from_api', isGraphQLApiError.message);
      return yield put(setGlobalLoadingState(loadingKeyMain, 3));
    }

    yield put(setGlobalLoadingState(loadingKeyMain, 2));
    yield call(delay, 500);
    yield put(setModal(false, 'jobrequest', {}));
    yield call(delay, 100);
    yield put(openJobRequestDetails(res.transactionId));
  } catch (e) {
    log.error('addMoreFlow - error', e);
    yield openSectionHandler('error');
    yield put(setGlobalLoadingState(loadingKeyMain, 3));
  }
  return null;
}

function mapToBackend(data) {
  return {
    jobStartTimeType: data.jobStartTimeType,
    startTime: data.day ? dateToMinutesOfDay(data.day.toDate()).toString() : undefined,
    endTime: data.endDay ? dateToMinutesOfDay(data.endDay.toDate()).toString() : undefined,
    duration: data.duration,
    message: data.note,
    title: data.title || data.defaultJobTitle,
    offeredPrice: data.offeredPrice ? data.offeredPrice : undefined,
    cleaners: data.selectedCleaners,
  };
}

function isDefaultChecklist(checklistId, property = {}) {
  const isValidProperty = !!property;
  const isValidChecklistId = !!checklistId;
  return isValidProperty && isValidChecklistId && property.defaultJob === checklistId;
}

function* updatePropertyDefaultChecklist(property, checklistId) {
  log.info('updatePropertyDefaultChecklist', { property, checklistId });

  yield call(updateParseProperty, property.objectId, {
    defaultJob: checklistId,
  });

  // fixing the mutation of property 😁
  const updatedProperty = {
    ...property,
    defaultJob: checklistId,
  };

  yield put(updateCollectionEntry('properties', property.objectId, updatedProperty));
}

function* saveJobRequestPrefillFlow(data) {
  const mappedData = mapToBackend(data);
  let propertyUpdateVal;
  log.info('saveJobRequestPrefillFlow - input', mappedData, data);
  try {
    yield put(setGlobalLoadingState(loadingKeyMain, 1));
    const checklistId = data.checklist['0'];

    // update job
    const resJob = yield call(updateParseJob, checklistId, {
      defaultSettings: mappedData,
    });

    yield put(updateCollectionEntry('jobs', resJob.objectId, resJob));

    const wantsToDelete = isDefaultChecklist(checklistId, data.property) && !data.isDefault;
    const wantsToMakeItDefault = data.isDefault === true;

    // if needs update of property
    if (wantsToDelete || wantsToMakeItDefault) {
      if (wantsToMakeItDefault) propertyUpdateVal = checklistId;
      log.info('saveSaga default settings - save property', {
        wantsToMakeItDefault,
        wantsToDelete,
        propertyUpdateVal,
      });
      yield updatePropertyDefaultChecklist(data.property, propertyUpdateVal);
    }
    yield openSectionHandler('success');
    yield put(setGlobalLoadingState(loadingKeyMain, 2));
  } catch (e) {
    log.error('saveJobRequestPrefillFlow - default settings', e);
    yield openSectionHandler('error');
    yield put(setGlobalLoadingState(loadingKeyMain, 3));
  }
}

function* permissionAndSubscriptionCheck({ data }) {
  const { ownerRole } = data.property || {};
  const user = yield select(selectorsGlobal.selectCurrentUser());
  const canSendPropertyJobsPermission = canSendPropertyJobs({ propertyOwner: ownerRole, user });

  if (!canSendPropertyJobsPermission) {
    yield put(setModal(true, modals.CANNOT_SEND_PROPERTY_JOBS_PERMISSION, {}));
    yield put(setModal(false, 'jobrequest', {}));
    return false;
  }

  if (isSharedProperty(ownerRole, user)) {
    return true;
  }

  const userSubscription = user.subscription;
  if (userSubscription.allowedJobsMonth > 0 && userSubscription.jobsUsedMonth >= userSubscription.allowedJobsMonth) {
    yield put(
      openPricingModalSaga('default', {
        defaultKind: 'jobrequestlimitalreadyreached',
      }),
    );
    yield put(setModal(false, 'jobrequest', {}));
    return false;
  }
  return true;
}

const selectCleaners = ({ startupData, jobRequestData }) => {
  const cleaners = jobRequestData.get('cleaners').toJS();
  const cleanersArray = values(cleaners);
  const mode = startupData.get('mode');

  if (mode === sendJobRequestModes.sendmore) {
    return cleanersArray.filter(cleaner => !cleaner.isDisabled).map(cleaner => cleaner.userData.objectId);
  }

  return keys(cleaners);
};

const selectData = ({ startupData, jobRequestData, properties }) => {
  const { tasks } = jobRequestData.toJS();
  const tasksArr = tasks || [];
  const startupDataJS = startupData.toJS();
  const { data } = startupDataJS;
  let sourceJobSnapshotId;

  if (data.sourceProblems) {
    if (data.sourceProblems.sourceJobSnapshotId) {
      sourceJobSnapshotId = data.sourceProblems.sourceJobSnapshotId;
    }
  }

  const baseData = {
    prevJobRequestId: startupData.get('prevJobRequestId'),
    property: properties.get(jobRequestData.get('propertyId')),
    tasks: tasksArr,
    jobStartTimeType: jobRequestData.get('jobStartTimeType'),
    checklist: jobRequestData.get('checklistId').toJS(),
    skills: jobRequestData.get('skills').toJS(),
    jobType: jobRequestData.get('jobType'),
    offeredPrice: jobRequestData.get('price'),
    isDefault: jobRequestData.get('isDefault'),
    offeredPriceCurrency: jobRequestData.get('currency'),
    note: jobRequestData.get('message'),
    title: jobRequestData.get('title') || jobRequestData.get('defaultJobTitle'),
    selectedCleaners: selectCleaners({ startupData, jobRequestData }),
    cancelJobRequest: startupData.get('prevJobRequestId'),
    eventId: startupData.get('eventId'),
    duration: jobRequestData.get('duration'),
    backwardCompatibilityJobReqId: startupData.get('backwardCompatibilityJobReqId'),
    sourceJobSnapshotId,
    problemIds: startupData.getIn('data', 'sourceProblems', 'problemIds'),
  };

  return jobRequestData.get('jobStartTimeType') === 'fixed'
    ? merge(baseData, {
        day: jobRequestData.get('startTime') ? fixMomentDates(jobRequestData.get('startTime')) : undefined,
      })
    : merge(baseData, {
        day: jobRequestData.get('startTime') ? fixMomentDates(jobRequestData.get('startTime')) : undefined,
        endDay: jobRequestData.get('endTime') ? fixMomentDates(jobRequestData.get('endTime')) : undefined,
      });
};

export function* submitSaga() {
  const jobRequestData = yield select(selectJobRequestData());

  const properties = yield select(selectorsGlobal.selectProperties());

  const mode = yield select(selectJobRequestMode);
  const startupData = yield select(selectJobRequestStartupData());
  log.info('submitSaga', { mode, properties, jobRequestData });

  const data = selectData({ startupData, jobRequestData, properties });

  const baseAttributes = [
    'property',
    'duration',
    'day',
    'endDay',
    'checklist',
    'skills',
    'offeredPrice',
    'selectedCleaners',
    'offeredPriceCurrency',
    'note',
    'jobType',
    'title',
    'tasks',
    'eventId',
    'jobStartTimeType',
  ];

  if (mode === sendJobRequestModes.normal) {
    const permissionAndSubscriptionCheckPassed = yield permissionAndSubscriptionCheck({ data });
    if (!permissionAndSubscriptionCheckPassed) {
      return;
    }
    yield defaultFlow(data, baseAttributes, false);
    return;
  }

  if (mode === sendJobRequestModes.edit) {
    yield defaultFlow(
      data,
      [...baseAttributes, 'cancelJobRequest', 'eventId', 'sourceJobSnapshotId', 'problemIds'],
      true,
    );
    return;
  }

  if (mode === sendJobRequestModes.problemSchedule) {
    yield defaultFlow(data, [...baseAttributes, 'cancelJobRequest', 'eventId', 'prevJobRequestId', 'tasks'], false);

    return;
  }

  if (mode === sendJobRequestModes.sendmore && data.prevJobRequestId) {
    yield addMoreFlow(data);
    return;
  }

  if (mode === sendJobRequestModes.prefill) {
    yield saveJobRequestPrefillFlow(data);
    return;
  }
  log.error(`submitSaga -- unknown flow ${mode}`, data);
}

function* getCommunityCleaners(rawProperty) {
  try {
    // reset community cleaners
    yield put(setCommunityJobRequest([]));
    const res = yield call(getParseSuggestedCleanersNew, rawProperty);
    yield put(setCommunityJobRequest(res));
  } catch (e) {
    log.error('getCommunityCleaners', e);
  }
}

function mergeCleaners(a, b) {
  return reduce(
    extend({}, a, b),
    (acc, val, key) => {
      const res = {};
      if (val) {
        res[key] = val;
      }
      return { ...acc, ...res };
    },
    {},
  );
}

function mergeObj(nextProps, currentProps) {
  if (keys(nextProps).length > 0) {
    return mergeCleaners(currentProps, nextProps);
  }
  return {};
}

function* trackChanges(oldData, newData) {
  const currentUserId = yield select(selectorsGlobal.selectCurrentUserId());
  setTimeout(() => {
    each(oldData, (val, key) => {
      if (newData[key] && val !== newData[key]) {
        if (key === 'propertyId') {
          trackAssingSelectProperty(newData[key]);
        }
        if (key === 'checklistId') {
          const changed = difference(values(newData[key]), values(val));
          each(changed, checklist => {
            trackAssingSelectJob(checklist);
          });
        }
        if (key === 'cleaners') {
          const changed = difference(keys(newData[key]), keys(val));
          each(changed, userId => {
            if (userId === currentUserId) {
              trackAssingJobMe();
            } else {
              trackAssingJobSelectedCleaner();
            }
          });
        }
      }
    });
  });
}

function* getDefaultJobTitleFromChecklistIds(checklistIds = []) {
  const checklists = yield select(selectorsGlobal.selectJobs());

  if ((checklistIds || []).length === 0) {
    return null;
  }
  const firstChecklistId = checklistIds && checklistIds[0];

  const checklistsJS = checklists.toJS();
  const { title } = (firstChecklistId && checklistsJS[firstChecklistId]) || {};
  return (title && title + (checklistIds.length > 1 ? ` + ${checklistIds.length - 1}` : '')) || '';
}

export function* flushUpdate(changeSet, source) {
  log.info('Job request flushUpdate -pre', { changeSet, source });
  const data = yield select(selectJobRequestData());
  const dataJS = data.toJS();
  const clonedJobRequestData = extend({}, dataJS);

  if (changeSet.cleaners) {
    clonedJobRequestData.cleaners = mergeObj(changeSet.cleaners, clonedJobRequestData.cleaners);
  }

  const updatedChecklistId = changeSet.checklistId || clonedJobRequestData.checklistId || [];
  if (changeSet.checklistId) {
    clonedJobRequestData.checklistId = updatedChecklistId;
  }

  const updatedSkills = changeSet.skills || clonedJobRequestData.skills || [];
  if (changeSet.skills) {
    clonedJobRequestData.skills = updatedSkills;
  }

  const defaultJobTitle = yield getDefaultJobTitleFromChecklistIds(updatedChecklistId);
  clonedJobRequestData.defaultJobTitle = defaultJobTitle;

  const updatedJobRequestData = extend(
    {},
    clonedJobRequestData,
    omit(changeSet, ['cleaners', 'checklistId', 'skills']),
  );
  yield fork(trackChanges, dataJS, updatedJobRequestData);
  yield put(setDataJobRequest(updatedJobRequestData));
}

function* openSectionHandler(newSection, metaval) {
  let params = [];
  if (!newSection) {
    params = [undefined, undefined];
  } else {
    params = [newSection, { id: newSection, metaval }];
  }
  yield put(setOpenSection(...params));
}

function* changeJobTitle({ title }) {
  yield call(flushUpdate, {
    title,
  });
}

function* changeJobType({ jobType }) {
  yield call(flushUpdate, {
    jobType,
  });
}

function* changeTaskTitleSaga({ taskTitle }) {
  yield call(flushUpdate, {
    taskTitle,
  });
}

function* changeTaskDescriptionSaga({ taskDescription }) {
  yield call(flushUpdate, {
    taskDescription,
  });
}

function* selectChecklist({ checklistId }) {
  const checklistIdsState = yield select(selectJobRequestDataChecklistId());

  const existingChecklistIds = (checklistIdsState && checklistIdsState.toJS()) || [];

  log.info('JobRequestSagas - Select checklist existingChecklistIds', existingChecklistIds);

  const hasChecklistId = !!existingChecklistIds.find(id => checklistId === id);

  const updatedChecklistIds = hasChecklistId
    ? // Remove the id
      existingChecklistIds.filter(id => checklistId !== id)
    : // Add the id
      [...existingChecklistIds, checklistId];

  yield call(flushUpdate, {
    checklistId: updatedChecklistIds,
  });

  const hasChecklist = updatedChecklistIds && updatedChecklistIds.length > 0;

  // We only need to reload the sections if there is still a checklist selected.
  // If there is no checklist selected then leave the data as is
  if (hasChecklist) {
    yield fork(reloadSection, ['title', 'propertyId', 'checklistId', 'tasks', 'jobType'], {
      noPropertyRefetch: true,
      noPrefill: true,
    });
  }
}

function* selectSkill({ skillId }) {
  const newSkillToAddToStore = {
    skillId,
    dependency: skillStatus.DEPENDENCY_STATUS.REQUIRED,
  };

  const existingSkillsInState = yield select(selectJobRequestSkills());

  const existingSkillsInStateJS = existingSkillsInState?.toJS() || [];

  log.info('JobRequestSagas - Select checklist existingChecklistIds', existingSkillsInStateJS);

  const hasSkillId = !!existingSkillsInStateJS.find(skillIdPresent => skillIdPresent.skillId === skillId);

  const updatedSkills = hasSkillId
    ? // Remove the key
      existingSkillsInStateJS.filter(skillIdPresent => skillIdPresent.skillId !== skillId)
    : // Add the key
      [...existingSkillsInStateJS, newSkillToAddToStore];

  yield call(flushUpdate, {
    skills: updatedSkills,
  });
}

/**
 * ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️️️
 *
 * DO NOT ADD ANY MORE MAGIC TO THIS SAGA - ACTIONS SHOULD BE MORE GRANULAR
 * E.G. selectChecklist action
 *
 * ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️️️
 */
function* handleClickSaga(action) {
  // open section / toggle
  if (action.meta.action === 'click' && action.meta.sender) {
    yield openSectionHandler(action.meta.sender);
  }

  // change start time
  if (action.meta.action === 'changeStartTime') {
    yield call(flushUpdate, {
      startTime: action.meta.startTime,
    });
  }
  // change end time
  if (action.meta.action === 'changeEndTime') {
    yield call(flushUpdate, {
      endTime: action.meta.endTime,
    });
  }
  if (action.meta.action === 'changeJobStartTimeType') {
    yield call(flushUpdate, {
      jobStartTimeType: action.meta.jobStartTimeType,
    });
  }
  // change message
  if (action.meta.action === 'typemessage') {
    yield call(flushUpdate, {
      message: action.meta.value,
    });
  }
  // set cleaner
  if (action.meta.action === 'setcleaner') {
    const id = isObject(action.meta.contact) ? action.meta.contact.userData.objectId : action.meta.contact;

    const cleaners = {};
    cleaners[id] = action.meta.set ? action.meta.contact : undefined;

    yield call(flushUpdate, {
      cleaners,
    });
  }

  // add task
  if (action.meta.action === 'addTaskForm') {
    const jobData = yield select(selectJobRequestData());
    let { tasks, tempTaskImages } = jobData.toJS();

    if (!tasks) {
      tasks = [];
    }

    const task = {};
    task.id = action.meta.addTaskForm.id;
    task.note = action.meta.addTaskForm.task;
    task.title = action.meta.addTaskForm.title;
    task.pictureIdentifiers = tempTaskImages;
    tasks.push(task);
    tempTaskImages = [];
    yield call(flushUpdate, {
      tasks,
      tempTaskImages,
    });
  }

  // Map problems to one-off task
  if (action.meta.action === 'mapProblemsToTask') {
    const jobData = yield select(selectJobRequestData());
    let { tasks } = jobData?.toJS() || [];

    if (action.meta.set) {
      const newTask = {};
      const { id, task, title, pictureIdentifiers, isProblem } = action.meta.mapProblemsToTask;
      newTask.id = id;
      newTask.note = task;
      newTask.title = title;
      newTask.pictureIdentifiers = pictureIdentifiers;
      newTask.isProblem = isProblem;
      tasks.push(newTask);
    } else {
      tasks = tasks.filter(task => task.id !== action.meta.mapProblemsToTask.id);
    }
    yield call(flushUpdate, {
      tasks,
    });
  }

  // set task image
  if (action.meta.action === 'addTaskPic') {
    const image = action.meta.addTaskPic;
    log.info('JobRequestSagas - uploadTaskPic', image);
    try {
      const jobData = yield select(selectJobRequestData());
      const { tempTaskImages } = jobData.toJS();
      const res = yield call(uploadImage, image);
      log.info('uploadPic - res', res);
      tempTaskImages.push(res.pictureUrl);
      yield call(flushUpdate, {
        tempTaskImages,
      });
    } catch (e) {
      log.error('uploadPic - error', e);
      yield put(setGlobalLoadingState(mediaUploadLoadingKey, 3));
    }
  }

  // cancel task
  if (action.meta.action === 'cancelTaskForm') {
    const jobData = yield select(selectJobRequestData());
    let { tempTaskImages } = jobData.toJS();
    tempTaskImages = [];
    yield call(flushUpdate, {
      tempTaskImages,
    });
  }

  // delete task
  if (action.meta.action === 'deleteTaskForm') {
    const jobData = yield select(selectJobRequestData());
    const { tasks } = jobData.toJS();
    const filteredTasks = tasks.filter(
      task => task.title !== action.meta.deleteTaskTitle || task.note !== action.meta.deleteTaskDescription,
    );
    yield call(flushUpdate, {
      tasks: filteredTasks,
    });
  }

  // set cleaners
  if (action.meta.action === 'setcleaners') {
    yield call(flushUpdate, {
      cleaners: action.meta.contacts,
    });
  }
  // change duration
  if (action.meta.action === 'changeDuration') {
    yield call(flushUpdate, {
      duration: action.meta.duration,
    });
  }
  if (action.meta.action === 'clickdefaultchange') {
    yield call(flushUpdate, {
      isDefault: action.meta.val,
    });
  }
  if (action.meta.action === 'clickback') {
    yield openSectionHandler(action.meta.prevSection.id);
  }
  // change price
  if (action.meta.action === 'setprice') {
    yield call(flushUpdate, {
      price: action.meta.value,
    });
  }
  // reset prefill section
  if (action.meta.action === 'clickreset') {
    const obj = {};
    if (action.meta.section === 'time') {
      obj.time = undefined;
      obj.day = undefined;
      obj.endDay = undefined;
      obj.startTime = undefined;
      obj.endTime = undefined;
      obj.duration = prefilledDurationNewJobRequest;
      obj.jobStartTimeType = 'fixed';
    }
    if (action.meta.section === 'cleaners') {
      obj.cleaners = {};
    }
    if (action.meta.section === 'price') {
      obj.price = 0;
    }
    if (action.meta.section === 'message') {
      obj.message = '';
    }
    if (action.meta.section === 'title') {
      obj.title = '';
    }

    yield call(flushUpdate, obj);
  }

  // select a property

  if (action.meta.action === 'clickproperty') {
    // If the selected property is in a different timeZone then we need to handle this
    const propertyId = action.meta.id;
    const jobData = yield select(selectJobRequestData());
    const rawProperty = yield select(selectorsGlobal.selectProperty(propertyId));
    const { startTime, endTime } = jobData.toJS();

    yield call(flushUpdate, {
      propertyId,
      startTime: moment(startTime).tz(rawProperty.timeZone),
      endTime: moment(endTime).tz(rawProperty.timeZone),
      proMarketProperty: rawProperty.proMarketProperty,
    });

    const isMarketPlaceFeatureFlagEnabled = yield select(
      selectIsFeatureFlagEnabledForUser(featureFlag.FEATURE_FLAG_MARKETPLACE),
    );
    // check for host stripe status
    if (isMarketPlaceFeatureFlagEnabled && rawProperty.proMarketProperty) {
      const res = yield call(getHostStripeStatus);
      yield put(setHostStripeStatus(res.hostStripeStatus?.defaultPaymentMethodExists));
    }

    yield fork(reloadSection, ['propertyId', 'startTime', 'endTime']);
  }
  // press next button
  if (action.meta.action === 'next') {
    yield openSectionHandler(action.meta.nextSection.id);
  }
}

function* updateProblemStatusSaga({ jobRequest, problem, problemStatus, sourceJob }) {
  try {
    const { jobId, propertyId, sourceProblems } = jobRequest;

    let sourceJobSnapshotId;
    if (sourceProblems) {
      if (sourceProblems.sourceJobSnapshotId) {
        sourceJobSnapshotId = sourceProblems.sourceJobSnapshotId;
      }
    }

    const finalData = {
      id: sourceJob ? sourceJobSnapshotId : jobId,
      problemIds: [problem.id],
      status: problemStatus,
      propertyId,
    };
    yield put(setGlobalLoadingState(loadingKeyMain, 2));

    const res = yield call(updateProblem, finalData);

    yield put(closeJobRequestDetails());

    if (res.name) {
      yield put(setResolvedProblemResponse(res));
    }

    if (sourceJobSnapshotId) {
      yield put(openJobRequestDetails(jobId));
    } else {
      yield put(openJobRequestDetails(res.transactionId));
    }
  } catch (e) {
    log.error(`updateProblemStatusSaga ${e}`);
  }
}

function* closeModalSaga() {
  yield put(resetJobRequest());
  yield put(setGlobalLoadingState(loadingKeyInit, undefined, {}));
  yield put(setGlobalLoadingState(loadingKeyChecklist, undefined, {}));
  yield put(setGlobalLoadingState(loadingKeyMain, undefined, {}));
}

function isCloseModal(action) {
  return action.type === types.GLOBAL_SET_MODAL && !action.value && action.id === 'jobrequest';
}

function* saga() {
  yield fork(takeEvery, types.JOB_REQUEST_INIT_SAGA, initJobRequestSaga);
  yield fork(takeEvery, types.JOB_REQUEST_SUBMIT, submitSaga);
  yield fork(takeEvery, types.JOB_REQUEST_HANDLECLICK, handleClickSaga);
  yield fork(takeLatest, types.JOB_REQUEST_SELECT_CHECKLIST, selectChecklist);
  yield fork(takeLatest, types.JOB_REQUEST_SELECT_SKILL, selectSkill);
  yield fork(takeLatest, types.JOB_REQUEST_CHANGE_JOB_TITLE, changeJobTitle);
  yield fork(takeLatest, types.JOB_REQUEST_CHANGE_JOB_TYPE, changeJobType);
  yield fork(takeLatest, types.JOB_REQUEST_UPDATE_PROBLEM, updateProblemStatusSaga);
  yield fork(takeLatest, types.JOB_REQUEST_CHANGE_TASK_TITLE, changeTaskTitleSaga);
  yield fork(takeLatest, types.JOB_REQUEST_CHANGE_TASK_DESCRIPTION, changeTaskDescriptionSaga);
  yield fork(takeEvery, isCloseModal, closeModalSaga);
}

export default saga;
