import { delay } from 'redux-saga';
import { put, fork, select, call, takeEvery, takeLatest } from 'redux-saga/effects';
import t from '@properly/localization';
import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import log from 'loglevel';
import { fromJS } from 'immutable';
import * as types from '../../../../types/index';
import {
  goToProperty,
  goToProperties,
  updateProperty,
  setCloneProperty,
  setFetchStateChecklist,
  goToPropertyChecklists,
  setEventsObj,
  setEventsMeta,
  setEditEventData,
  setClonePropertySelected,
  deletePropertySuccess,
  deletePropertyFailure,
} from './PropertyActions';
import { ROUTES } from '../../../../paths';
import { filterPropertySidebar, changeHelper } from '../../../../helper/herbert';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import { updateParseProperty, getParseJobsForProperty, getParseTrigger, updateParseTrigger } from '../../data';
import {
  startPreloadSaga,
  updateCollectionEntryBatch,
  updateCollectionEntry,
  setSearchQuery,
  setModal,
} from '../../../../actions/globalActions';
import {
  trackMergeSuccess,
  trackCreateNewPropertySuccess,
  trackPropertyEditAddress,
} from '../../../../actions/trackingEvents';
import { modals } from '../../../../dataConstants';
import { waitForPreloadFinish } from '../../../../sagas/global';
import Trigger from '../../../../model/trigger';
import { mapFormDataToClassData } from '../containers/mapper';
import { mergeProperties } from './MergePropertyApi';

/*
  surf on /properties/ should set the first property in the sidebar as detail view
 */
function* setFirstItem() {
  yield call(waitForPreloadFinish);
  const items = yield select(selectorsGlobal.selectProperties());
  const data = yield select();
  const isPropertyPage =
    data.routing.locationBeforeTransitions && data.routing.locationBeforeTransitions.pathname === ROUTES.properties;
  const itemsRaw = filterPropertySidebar(values(items?.toJS()));
  if (itemsRaw.length > 0 && isPropertyPage) {
    yield put(goToPropertyChecklists(itemsRaw[0].objectId, true));
  }
}

function* goToPropertiesAfterDelete(action) {
  yield put(updateProperty(action.propertyId, action.req));
  yield put(goToProperties());
}

const lengthNotZero = collection => collection && collection.length && !collection.length === 0;

function* mergeSaga(action) {
  yield put(setCloneProperty({ mode: 1 }));
  const { idArray } = action;
  try {
    if (!lengthNotZero(idArray)) {
      const res = yield call(mergeProperties, idArray);
      // pull the new data
      yield put(startPreloadSaga());
      yield call(waitForPreloadFinish, true);
      yield put(
        setCloneProperty({
          hideMsg: false,
          isOpen: false,
          selected: [],
          mode: 0,
        }),
      );
      yield put(setClonePropertySelected(fromJS([])));
      yield put(goToProperty(res.objectId));
      yield put(setSearchQuery('merge_property_search', ''));
      trackMergeSuccess();
      return;
    }
    yield put(
      setCloneProperty({
        hideMsg: false,
        isOpen: false,
        selected: [],
        mode: 0,
      }),
    );
    yield put(setSearchQuery('merge_property_search', ''));
  } catch (e) {
    log.error('mergeSaga', e);
    yield put(setCloneProperty({ mode: 0 }));
  }
}

function* noMergeSaga(action) {
  try {
    const res = yield call(updateParseProperty, action.propertyId, {
      noMerge: true,
    });
    yield put(updateProperty(action.propertyId, res));
  } catch (e) {
    log.error('noMergeSaga', e);
  }
}

function* propertyChecklistPageSaga(action) {
  try {
    const { propertyId } = action;
    yield put(setFetchStateChecklist(1));
    const res = yield call(getParseJobsForProperty, propertyId);
    yield put(updateCollectionEntryBatch('jobs', keyBy(res, 'objectId'), false));
    yield put(setFetchStateChecklist(0));
  } catch (e) {
    log.error('propertyChecklistPageSaga', e);
    yield put(setFetchStateChecklist(3));
  }
}

function* mountEventsPageSaga({ propertyId, mount }) {
  if (mount) {
    try {
      yield put(setEventsMeta({ state: 1 }));
      const res = yield [call(getParseTrigger, propertyId), call(getParseJobsForProperty, propertyId)];
      yield put(updateCollectionEntryBatch('jobs', keyBy(res[1], 'objectId'), false));
      yield put(updateCollectionEntryBatch('trigger', keyBy(res[0], 'objectId'), false));
      yield put(setEventsMeta({ state: 0 }));
    } catch (e) {
      yield put(setEventsMeta({ state: 0 }));
      log.error('mountEventsPageSaga', e);
    }
  } else {
    // alert('cleanup');
  }
}

function* editEventSaga({ trigger }) {
  yield put(setEventsObj('editEvent', trigger));
}

function* addEventSaga({ propertyData }) {
  const data = changeHelper(Trigger, undefined, {
    objectId: 'new',
    role: propertyData.role,
    propertyId: propertyData.objectId,
  });
  yield put(updateCollectionEntryBatch('trigger', { [data.objectId]: data }, true));
  yield call(editEventSaga, { trigger: data });
}

function* deleteEventSaga({ trigger }) {
  try {
    yield put(setEventsMeta({ deleteState: 1 }));
    const res = yield call(updateParseTrigger, trigger.objectId, {
      deleted: true,
    });
    yield put(updateCollectionEntry('trigger', res.objectId, res));
    yield put(setEventsMeta({ deleteState: 0 }));
  } catch (e) {
    log.error('deleteEventSaga', e);
    yield put(setEventsMeta({ deleteState: 0 }));
  }
}

function* cancelEditEventSaga({ trigger }) {
  yield put(setEventsObj('editEvent', undefined));
  if (trigger.isUnsaved) {
    yield put(
      updateCollectionEntry('trigger', trigger.objectId, {
        deleted: true,
      }),
    );
  }
}

function* saveEditTrigger({ trigger }) {
  try {
    yield put(setEventsMeta({ savingState: 'loading' }));
    const res = yield call(updateParseTrigger, trigger.objectId, trigger);
    yield put(setEventsMeta({ savingState: 'saved' }));
    yield delay(1000);
    yield put(updateCollectionEntry('trigger', res.objectId, res));
    if (trigger.isUnsaved) {
      yield put(
        updateCollectionEntry('trigger', trigger.objectId, {
          deleted: true,
        }),
      );
    }
    yield put(setEventsMeta({ savingState: 'changed' }));
    yield put(setEventsObj('editEvent', undefined));
  } catch (e) {
    log.error('saveEditTrigger', e);
    yield put(setEventsMeta({ savingState: 'error' }));
    yield delay(1000);
    yield put(setEventsMeta({ savingState: 'changed' }));
  }
}

function* handleEventFillSaga({ checklistId }) {
  log.info('handleEventFillSaga', { checklistId });
  yield put(setEditEventData({ jobId: checklistId }));
}

function* newEditPropertySaga({ mode }) {
  const formDataFromState = yield select(state => state.form?.propertyNewEdit?.values);
  const userId = yield select(selectorsGlobal.selectCurrentUserId());
  const property = yield select(selectorsGlobal.selectProperty(formDataFromState.objectId));
  // get form data from state to avoid race condition for submit and async data retrieval from google api,
  const mappedData = mapFormDataToClassData(formDataFromState, property || {});

  if (mode === 'new') {
    try {
      yield put(setModal(true, modals.LOADING_MODAL, {}));
      const res = yield call(updateParseProperty, formDataFromState.objectId, mappedData, userId);

      yield put(updateCollectionEntry('properties', res.objectId, res));
      yield put(setModal(false, modals.LOADING_MODAL, {}));
      yield put(goToProperty(res.objectId));
      trackCreateNewPropertySuccess(res.objectId);
    } catch (e) {
      log.error('newEditPropertySaga - new', e);
      yield put(setModal(false, modals.LOADING_MODAL, {}));
      yield put(setModal(true, modals.CREATE_PROPERTY_ERROR_MODAL, {}));
    }
  }
  if (mode === 'edit') {
    try {
      const prev = yield select(selectorsGlobal.selectProperty(formDataFromState.objectId));
      yield put(setModal(true, modals.LOADING_MODAL, {}));
      const res = yield call(updateParseProperty, formDataFromState.objectId, mappedData);
      yield put(updateCollectionEntry('properties', res.objectId, res));
      yield put(setModal(false, modals.LOADING_MODAL, {}));
      yield put(goToProperty(res.objectId));
      if (JSON.stringify(prev.address) !== JSON.stringify(mappedData.address)) {
        trackPropertyEditAddress(res.objectId);
      }
    } catch (e) {
      log.error('newEditPropertySaga - edit', e);
      yield put(setModal(false, modals.LOADING_MODAL, {}));
      yield put(setModal(true, modals.EDIT_PROPERTY_ERROR_MODAL, {}));
    }
  }
}

function* updateTags({ propertyId, tags }) {
  try {
    const response = yield call(updateParseProperty, propertyId, { tags });

    yield put(updateCollectionEntry('properties', response.objectId, response));
  } catch (err) {
    log.error('Error updating property tags', propertyId, tags);
  }
}

function* deleteProperty({ propertyId }) {
  try {
    yield updateParseProperty(propertyId, {
      deleted: true,
    });
    yield put(deletePropertySuccess(propertyId));
  } catch (err) {
    log.error('Error deleting property', err);
    try {
      const parseError = JSON.parse(err.message);
      yield put(deletePropertyFailure(propertyId, parseError.localizedMessage));
    } catch (ignoredError) {
      yield put(deletePropertyFailure(propertyId, t('properties.delete_fail_property')));
    }
  }
}

function* saga() {
  yield fork(takeEvery, types.PROPERTY_PROPERTIES_HIT, setFirstItem);
  yield fork(takeEvery, types.PROPERTY_DELETE_PROPERTY_REQUEST, deleteProperty);
  yield fork(takeEvery, types.PROPERTY_DELETE_PROPERTY_SUCCESS, goToPropertiesAfterDelete);
  yield fork(takeEvery, types.PROPERTY_MERGE, mergeSaga);
  yield fork(takeEvery, types.PROPERTY_NO_MERGE_SAGA, noMergeSaga);
  yield fork(takeEvery, types.PROPERTY_NEW_EDIT_SAGA, newEditPropertySaga);

  yield fork(takeLatest, types.PROPERTY_MOUNT_EVENTS, mountEventsPageSaga);
  yield fork(takeLatest, types.PROPERTY_EDIT_EVENT, editEventSaga);
  yield fork(takeLatest, types.PROPERTY_ADD_EVENT, addEventSaga);
  yield fork(takeLatest, types.PROPERTY_DELETE_EVENT, deleteEventSaga);
  yield fork(takeLatest, types.PROPERTY_CANCEL_EDIT_EVENT, cancelEditEventSaga);
  yield fork(takeLatest, types.PROPERTY_SAVE_EDIT_EVENT, saveEditTrigger);

  yield fork(takeLatest, types.PROPERTY_UPDATE_TAGS, updateTags);
  yield fork(takeLatest, types.PROPERTY_UPDATE_TAGS, function* delaySearchQueryLoad(action) {
    yield delay(200);
    yield updateTags(action);
  });

  yield fork(takeEvery, types.PROPERTY_DETAIL_CHECKLIST_PAGE_SAGA, propertyChecklistPageSaga);

  // foreigners
  yield fork(takeEvery, types.CHECKLIST_SET_MODAL_CLOSE, handleEventFillSaga);
}

export default saga;
