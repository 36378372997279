import log from 'loglevel';
import { eventChannel } from 'redux-saga';
import { client as apolloClient } from '../../config/apollo';
import { SUBSCRIPTION_LISTEN_FOR_VERIFICATION_PHOTOS } from '../subscriptions/verificationPhotos';

export const createVerificationPhotosSubscriptionChannel = params =>
  eventChannel(emitter => {
    const observable = apolloClient.subscribe({
      query: SUBSCRIPTION_LISTEN_FOR_VERIFICATION_PHOTOS,
      variables: params,
      fetchPolicy: 'no-cache',
    });

    const subscription = observable.subscribe({
      next(payload) {
        log.info(
          '[createVerificationPhotosSubscriptionChannel] client payload from listenForVerificationPhotos',
          payload,
        );
        const { checklistId, verificationPhoto, type } = payload.data.listenForVerificationPhotos;

        const verificationPhotoData = {
          checklistId,
          verificationPhoto,
          type,
        };

        emitter(verificationPhotoData);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  });
