export const CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_REQUEST = 'CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_REQUEST';
export const CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_SUCCESS = 'CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_SUCCESS';
export const CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_FAILURE = 'CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_FAILURE';

export const LIBRARY_UPDATE_LIBRARY_SEARCH_QUERY = 'LIBRARY_UPDATE_LIBRARY_SEARCH_QUERY';
export const LIBRARY_UPDATE_TABS_VALUE = 'LIBRARY_UPDATE_TABS_VALUE';

export const CHECKLIST_TOGGLE_DISPLAY_USER_CHECKLISTS = 'CHECKLIST_TOGGLE_DISPLAY_USER_CHECKLISTS';
export const CHECKLIST_TOGGLE_DISPLAY_PUBLISHED_CHECKLISTS = 'CHECKLIST_TOGGLE_DISPLAY_PUBLISHED_CHECKLISTS';

export const CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_REQUEST = 'CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_REQUEST';
export const CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_SUCCESS = 'CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_SUCCESS';
export const CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_FAILURE = 'CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_FAILURE';

export const CHECKLIST_REMOVE_CHECKLIST_FROM_LIBRARY_REQUEST = 'CHECKLIST_REMOVE_CHECKLIST_FROM_LIBRARY_REQUEST';
export const CHECKLIST_REMOVE_CHECKLIST_FROM_LIBRARY_SUCCESS = 'CHECKLIST_REMOVE_CHECKLIST_FROM_LIBRARY_SUCCESS';
export const CHECKLIST_REMOVE_CHECKLIST_FROM_LIBRARY_FAILURE = 'CHECKLIST_REMOVE_CHECKLIST_FROM_LIBRARY_FAILURE';

export const CHECKLIST_LOAD_INDEPENDENT_CHECKLISTS_REQUEST = 'CHECKLIST_LOAD_INDEPENDENT_CHECKLISTS_REQUEST';
export const CHECKLIST_LOAD_INDEPENDENT_CHECKLISTS_SUCCESS = 'CHECKLIST_LOAD_INDEPENDENT_CHECKLISTS_SUCCESS';
export const CHECKLIST_LOAD_INDEPENDENT_CHECKLISTS_FAILURE = 'CHECKLIST_LOAD_INDEPENDENT_CHECKLISTS_FAILURE';

export const CHECKLIST_LOAD_SINGLE_CHECKLIST_REQUEST = 'CHECKLIST_LOAD_SINGLE_CHECKLIST_REQUEST';
export const CHECKLIST_LOAD_SINGLE_CHECKLIST_SUCCESS = 'CHECKLIST_LOAD_SINGLE_CHECKLIST_SUCCESS';
export const CHECKLIST_LOAD_SINGLE_CHECKLIST_FAILURE = 'CHECKLIST_LOAD_SINGLE_CHECKLIST_FAILURE';

export const CHECKLIST_LOAD_PUBLISHED_CHECKLISTS_REQUEST = 'CHECKLIST_LOAD_PUBLISHED_CHECKLISTS_REQUEST';
export const CHECKLIST_LOAD_PUBLISHED_CHECKLISTS_SUCCESS = 'CHECKLIST_LOAD_PUBLISHED_CHECKLISTS_SUCCESS';
export const CHECKLIST_LOAD_PUBLISHED_CHECKLISTS_FAILURE = 'CHECKLIST_LOAD_PUBLISHED_CHECKLISTS_FAILURE';

// *************************************************************
//                  SKILLS
// *************************************************************
export const SKILLS_LOAD_REQUEST = 'SKILLS_LOAD_REQUEST';
export const SKILLS_LOAD_SUCCESS = 'SKILLS_LOAD_SUCCESS';
export const SKILLS_LOAD_FAILURE = 'SKILLS_LOAD_FAILURE';

export const SKILL_LOAD_REQUEST = 'SKILL_LOAD_REQUEST';
export const SKILL_LOAD_SUCCESS = 'SKILL_LOAD_SUCCESS';
export const SKILL_LOAD_FAILURE = 'SKILL_LOAD_FAILURE';

export const SKILLS_ACCESS = 'SKILLS_ACCESS';
