import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Icon, Input, Pill, SpaceSmall, SquareSelect } from '@properly/components';
import t from '@properly/localization';
import colors from '@properly/components/lib/common/styles/colors.module.css';

const STEP_0_AGREE_TERMS = 0;
const STEP_1_ENTER_DETAILS = 1;
const STEP_2_SUCCESS_OR_FAIL = 2;

const PillWithContentAndStyles = withStyles(() => ({
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    display: 'flex',
    marginLeft: '-.5em',
    padding: '0 .5em',
  },
  divider__content: {
    width: '100%',
    textAlign: 'center',
  },
  text: {
    display: 'flex',
  },
  text__content: {
    padding: '0 0 0 0.25em',
  },
}))(props => {
  const { key, text, icon, classes } = props;

  let iconToRender;

  switch (icon) {
    case 'alert':
      iconToRender = <Icon.AlertRed width="1em" byWidth />;
      break;
    case 'success':
      iconToRender = <Icon.IcActive width="1em" byWidth />;
      break;
    default:
      break;
  }

  return (
    <Pill key={key} style={{ margin: '5px' }}>
      <div className={classes.content}>
        {iconToRender}
        <span className={classes.text}>
          <span className={classes.text__content}>{text}</span>
        </span>
      </div>
    </Pill>
  );
});

function Steps(
  props,
  prevState,
  error,
  onChangeRoutingNumber,
  onChangeAccountNumber,
  onChangeAccountHolderName,
  onChangeAccountHolderType,
  onChangeSetAsDefault,
  forceSetAsDefault,
) {
  /* eslint-disable no-shadow */
  const { classes } = props;
  const { routingNumber, accountNumber, accountHolderName, accountHolderType, setAsDefault } = prevState;
  const accountHolderTypeOptions = [
    { value: 'individual', title: 'Individual' },
    { value: 'company', title: 'Company' },
  ].map(({ value, title }) => ({ value, title }));
  const elementErrorStyles = {
    zIndex: 1,
    border: `1px solid ${colors['color-orange']}`,
  };

  function setAsDefaultLabel(forceSetAsDefault) {
    return forceSetAsDefault ? (
      <Typography style={{ color: colors['color-grey'] }}>{t('job_request.wizard.set_as_default')}</Typography>
    ) : (
      t('job_request.wizard.set_as_default')
    );
  }

  return [
    {
      stepNumber: STEP_0_AGREE_TERMS,
      label: <Typography variant="h6">{t('job_request.wizard.payment_method')}</Typography>,
      content: t('job_request.wizard.payment_method_blurb'),
      showActionsLeft: true,
      nextLabel: t('job_request.wizard.next'),
      onStepHandler() {
        const { resetIntentState } = props;
        resetIntentState();
        return Promise.resolve({ stepNumber: STEP_0_AGREE_TERMS });
      },
    },
    {
      stepNumber: STEP_1_ENTER_DETAILS,
      label: <Typography variant="h6">{t('job_request.wizard.bank_account_details')}</Typography>,
      content: (
        <>
          <Input
            value={routingNumber}
            isFirst
            isLast
            data-key="routingNumber"
            placeholder={t('job_request.wizard.routing_number', {})}
            type="text"
            autoComplete="routingNumber"
            onChange={e => onChangeRoutingNumber && onChangeRoutingNumber(e.target.value)}
            styleOverwrite={error && error.elementType === 'accountRoutingNumber' ? elementErrorStyles : {}}
          />

          <SpaceSmall />

          <Input
            value={accountNumber}
            isFirst
            isLast
            data-key="accountNumber"
            placeholder={t('job_request.wizard.account_number', {})}
            type="text"
            autoComplete="accountNumber"
            onChange={e => onChangeAccountNumber && onChangeAccountNumber(e.target.value)}
            styleOverwrite={error && error.elementType === 'accountNumber' ? elementErrorStyles : {}}
          />

          <SpaceSmall />

          <Input
            value={accountHolderName}
            isFirst
            isLast
            data-key="accountHolderName"
            placeholder={t('job_request.wizard.account_holder_name', {})}
            type="text"
            autoComplete="accountHolderName"
            onChange={e => onChangeAccountHolderName && onChangeAccountHolderName(e.target.value)}
            styleOverwrite={error && error.elementType === 'accountHolderName' ? elementErrorStyles : {}}
          />

          <SpaceSmall />

          <Input
            value={accountHolderType}
            data-key="accountHolderType"
            placeholder={t('job_request.wizard.account_holder_type', {})}
            type="select"
            autoComplete="accountHolderType"
            options={accountHolderTypeOptions}
            onChange={e => onChangeAccountHolderType && onChangeAccountHolderType(e.target.value)}
            styleOverwrite={error && error.elementType === 'accountHolderType' ? elementErrorStyles : {}}
          />

          <SpaceSmall />

          <SquareSelect
            small
            label={setAsDefaultLabel(forceSetAsDefault)}
            onChange={onChangeSetAsDefault}
            selected={forceSetAsDefault || setAsDefault}
            disabled={forceSetAsDefault}
          />

          <SpaceSmall />

          <div className={classes.cardErrors} role="alert">
            {error ? error.message : ''}
          </div>
        </>
      ),
      showActionsLeft: false,
      nextLabel: t('job_request.wizard.continue'),
      async onStepHandler(props, state) {
        const { stripe } = props;
        const { routingNumber, accountNumber, accountHolderName, accountHolderType } = state;

        if (!(routingNumber && routingNumber.trim())) {
          const err = { message: t('job_request.wizard.routing_number_invalid'), elementType: 'routingNumber' };
          return Promise.resolve({ error: err });
        }

        if (!(accountNumber && accountNumber.trim())) {
          const err = { message: t('job_request.wizard.account_number_invalid'), elementType: 'accountNumber' };
          return Promise.resolve({ error: err });
        }

        if (!(accountHolderName && accountHolderName.trim())) {
          const err = {
            message: t('job_request.wizard.account_holder_name_invalid'),
            elementType: 'accountHolderName',
          };
          return Promise.resolve({ error: err });
        }

        // bank account
        // https://stripe.com/docs/stripe-js/reference#collecting-bank-account-details
        // https://stripe.com/docs/ach#testing-ach
        try {
          const { error, token } = await stripe.createToken('bank_account', {
            country: 'US',
            currency: 'usd',
            routing_number: routingNumber,
            account_number: accountNumber,
            account_holder_name: accountHolderName,
            account_holder_type: accountHolderType,
          });

          return Promise.resolve({ stepNumber: STEP_1_ENTER_DETAILS, token, error });
        } catch (err) {
          throw err;
        }
      },
    },
    {
      stepNumber: STEP_2_SUCCESS_OR_FAIL,
      label: error ? (
        <PillWithContentAndStyles text={t('job_request.wizard.inactive')} icon="alert" />
      ) : (
        <PillWithContentAndStyles text={t('job_request.wizard.active')} icon="success" />
      ),
      content: (
        <Typography style={{ color: colors['color-grey'] }} variant="h6">
          {error ? error.message : t('job_request.wizard.success')}
        </Typography>
      ),
      showActionsLeft: false,
      nextLabel: t('job_request.wizard.next'),
    },
  ];
}

export default Steps;
