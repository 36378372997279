import { fork, takeEvery } from 'redux-saga/effects';
import { trackCalendarFilter, trackCalendarCancelToggle } from '../../../../actions/trackingEvents';
import * as types from '../../../../types';

// eslint-disable-next-line
function* trackCalendarEventFilters({ filters }) {
  trackCalendarFilter(filters);
}

// eslint-disable-next-line
function* trackCalendarToggleCanceledEvents() {
  trackCalendarCancelToggle();
}

function* saga() {
  yield fork(takeEvery, types.CALENDAR_SET_EVENT_FILTERS, trackCalendarEventFilters);
  yield fork(takeEvery, types.CALENDAR_TOGGLE_CANCELED_EVENTS_FILTER, trackCalendarToggleCanceledEvents);
}

export default saga;
