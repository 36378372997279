import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps } from 'recompose';

import pick from 'lodash/pick';
import { connect } from 'react-redux';
import { JobRequestContentWrap } from '../../../components/JobRequestElements';
import { mapToMainState } from './permissionhelper';
import PermissionModalTopBar from './PermissionModalTopBar';
import PermissionModalContentInner from './PermissionModalContentInner';

class PermissionModalMain extends PureComponent {
  static propTypes = {
    actionHandler: PropTypes.func.isRequired,
  };

  render() {
    return (
      <JobRequestContentWrap>
        {this.props.activeSectionData && <PermissionModalTopBar actionHandler={this.props.actionHandler} />}
        <PermissionModalContentInner actionHandler={this.props.actionHandler} />
      </JobRequestContentWrap>
    );
  }
}

export default compose(
  connect(
    mapToMainState,
    {},
  ),
  mapProps(props => pick(props, ['activeSectionData', 'actionHandler'])),
)(PermissionModalMain);
