import React from 'react';
import t from '@properly/localization';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { Chip } from '@properly/components';
import { paymentStatus as PaymentStatus } from '@properly/config';
import colors from '@properly/components/lib/common/styles/colors.module.css';

const PaymentStatusChip = ({ paymentStatus }) => {
  const chipStyles = [
    {
      status: PaymentStatus.UNPROCESSED,
      styles: { backgroundColor: colors['color-red'], color: colors['color-white'] },
    },
    {
      status: PaymentStatus.SUBMITTED,
      styles: { backgroundColor: colors['color-blue'], color: colors['color-white'] },
    },
    {
      status: PaymentStatus.PROCESSING,
      styles: { backgroundColor: colors['color-blue'], color: colors['color-white'] },
    },
    {
      status: PaymentStatus.PENDING,
      styles: { backgroundColor: colors['color-blue'], color: colors['color-white'] },
    },

    {
      status: PaymentStatus.SUCCEEDED,
      styles: { backgroundColor: colors['color-green'], color: colors['color-white'] },
    },
    { status: PaymentStatus.FAILED, styles: { backgroundColor: colors['color-red'], color: colors['color-white'] } },
    { status: PaymentStatus.CANCELED, styles: { backgroundColor: colors['color-red'], color: colors['color-white'] } },
    { status: PaymentStatus.UNKNOWN, styles: { backgroundColor: colors['color-grey'], color: colors['color-white'] } },
  ];
  if (!Object.values(PaymentStatus).includes(paymentStatus)) {
    paymentStatus = PaymentStatus.UNKNOWN;
  }
  const chipStyle = find(chipStyles, item => item.status === (paymentStatus && paymentStatus.toLowerCase()));

  return <Chip label={t(`payments.status.${paymentStatus}`)} chipStyle={chipStyle && chipStyle.styles} />;
};

PaymentStatusChip.propTypes = {
  paymentStatus: PropTypes.string.isRequired,
};

export default PaymentStatusChip;
