import gql from 'graphql-tag';

export const GET_COMMENTS_QUERY = gql`
  query getComment(
    $jobSnapshotId: ID!
    $userRole: RoleType!
    $updatedAfter: DateTime
    $languageCode: String
    $offset: Int
    $limit: Int
  ) {
    GetComment(
      jobSnapshotId: $jobSnapshotId
      userRole: $userRole
      updatedAfter: $updatedAfter
      languageCode: $languageCode
      offset: $offset
      limit: $limit
    ) {
      commentId
      message
      pictureIdentifier
      createdAt
      updatedAt
      jobSnapshotId
      sender {
        ...senderFields
      }
    }
  }

  fragment senderFields on senderFields {
    id
    companyName
    userData {
      ...userFields
    }
  }

  fragment userFields on userFields {
    id
    firstName
    lastName
  }
`;
