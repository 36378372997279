import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { capitalizeFirstLetter } from '@properly/common';
import { Icon, Box, Pill, Small, SpaceTiny } from '@properly/components';
import t from '@properly/localization';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const MyBox = ({
  icon: BoxIcon,
  text,
  isLast,
  isFirst,
  onButtonClick,
  buttonText,
  defaultPaymentMethod,
  defaultSource,
  paymentMethod,
  isCard,
  isBankAccount,
}) => {
  const style = {
    backgroundColor: '#fff',
    height: 'auto',
    flexWrap: 'wrap',
  };
  const {
    accountHolderName,
    bankName,
    routingNumber, // bank account
    brand,
    expMonth,
    expYear, // credit card
    last4, // both
  } = paymentMethod; // eslint-disable-line camelcase
  const expiryDate = moment(`${expMonth}-${expYear}`, 'MM-YYYY').format('MMM-YYYY');

  let CardIcon = Icon.IcSubscription;

  if (isCard) {
    switch (brand) {
      case 'visa':
        CardIcon = Icon.IcCardVisa;
        break;

      case 'amex':
        CardIcon = Icon.IcCardAmex;
        break;

      case 'mastercard':
        CardIcon = Icon.IcCardMastercard;
        break;

      case 'diners':
        CardIcon = Icon.IcCardDiners;
        break;

      case 'discover':
        CardIcon = Icon.IcCardDiscover;
        break;

      case 'jcb':
        CardIcon = Icon.IcCardJcb;
        break;

      default:
        CardIcon = Icon.IcSubscription;
        break;
    }
  }

  return (
    <Box isLast={isLast} isFirst={isFirst} styleOverwrite={style}>
      <div data-key={text} className={cx('section__inner')}>
        <Pill key={text} style={{ border: 'none', padding: 0 }}>
          <div className={cx('section-pill__content')}>
            <Small type="grey">{t('job_request.wizard.payment_method')}</Small>
            <span className={cx('section-pill__divider')}>
              <span className={cx('section-pill__divider-content')}>|</span>
            </span>
            <BoxIcon width="1em" byWidth />
            <span className={cx('section-pill__text')}>
              <span className={cx('section-pill__text-content')}>
                <Small>{text}</Small>
              </span>
            </span>
            {isCard && defaultPaymentMethod && defaultPaymentMethod === paymentMethod.id && <Icon.IcCheckFinished />}
            {isBankAccount && defaultSource && defaultSource === paymentMethod.id && <Icon.IcCheckFinished />}
          </div>
        </Pill>
        <div onClick={onButtonClick} className={cx('section__right', 'pointer')}>
          <Small type="orange">{buttonText}</Small>
        </div>
      </div>
      <SpaceTiny />
      <div data-key={text} className={cx('section__inner')}>
        {isCard && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CardIcon margin="0 .5em 0 0" width="1.5em" byWidth />
            <span className={cx('section-text__container')}>
              <Small>
                <span className={cx('section-text__label')}>{`${capitalizeFirstLetter(brand)}`}</span>
                <span className={cx('section-text__last4')}>{`(...${last4})`}</span>
              </Small>
            </span>
            <span className={cx('section-text__container')}>
              <Small style={{ display: 'flex', margin: '0 1em' }}>
                <span className={cx('section-text__label')}>{t('settings.expiry_date')}:</span>
                <span className={cx('section-text__value')}>{expiryDate}</span>
              </Small>
            </span>
          </div>
        )}
        {isBankAccount && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon.IcBank margin="0 .5em 0 0" width="1.5em" byWidth />
            <span className={cx('section-text__container')}>
              <Small>
                <span className={cx('section-text__label')}>{`${capitalizeFirstLetter(accountHolderName)}`}</span>
                <span className={cx('section-text__last4')}>{`(...${last4})`}</span>
              </Small>
            </span>
            <span className={cx('section-text__container')}>
              <Small style={{ display: 'flex', margin: '0 1em' }}>
                <span className={cx('section-text__label', 'section-text__label--flexed')}>
                  {t('settings.routing_number')}:
                </span>
                <span className={cx('section-text__value')}>{`${routingNumber} (${bankName})`}</span>
              </Small>
            </span>
          </div>
        )}
      </div>
    </Box>
  );
};

const SettingsPaymentMethod = ({
  isFirst,
  isLast,
  text,
  icon,
  onButtonClick,
  buttonText,
  paymentMethod,
  isCard,
  isBankAccount,
  defaultPaymentMethod,
  defaultSource,
}) => (
  <MyBox
    icon={icon}
    isLast={isLast}
    isFirst={isFirst}
    text={text}
    buttonText={buttonText}
    onButtonClick={onButtonClick}
    paymentMethod={paymentMethod}
    isCard={isCard}
    isBankAccount={isBankAccount}
    defaultPaymentMethod={defaultPaymentMethod}
    defaultSource={defaultSource}
  />
);

SettingsPaymentMethod.defaultProps = {
  paymentMethod: {},
  isCard: false,
  isBankAccount: false,
  defaultPaymentMethod: '',
  defaultSource: '',
  buttonText: '',
  onButtonClick: () => {},
};

SettingsPaymentMethod.propTypes = {
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func,
  icon: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  paymentMethod: PropTypes.shape({}),
  isCard: PropTypes.bool,
  isBankAccount: PropTypes.bool,
  defaultPaymentMethod: PropTypes.string,
  defaultSource: PropTypes.string,
};

MyBox.defaultProps = SettingsPaymentMethod.defaultProps;
MyBox.propTypes = SettingsPaymentMethod.propTypes;

export default SettingsPaymentMethod;
