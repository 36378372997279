import { push, replace } from 'react-router-redux';
import * as types from '../../../../types/index';
import { ROUTES } from '../../../../paths';
import { trackMergePropOpen, trackMergeAction } from '../../../../actions/trackingEvents';

export function openPropertyGallery(propertyId) {
  return push(ROUTES.propertyPageGallery(propertyId));
}

export function updateProperty(propertyId, changeSet) {
  return {
    type: types.UPDATE_COLLECTION_ENTRY,
    collectionName: 'properties',
    id: propertyId,
    changeSet,
  };
}

export function updatePropertyTags(propertyId, tags) {
  return {
    type: types.PROPERTY_UPDATE_TAGS,
    propertyId,
    tags,
  };
}

export function broadcastPropertiesHit() {
  return {
    type: types.PROPERTY_PROPERTIES_HIT,
  };
}

export function deletePropertyRequest(propertyId) {
  return {
    type: types.PROPERTY_DELETE_PROPERTY_REQUEST,
    propertyId,
  };
}

export function deletePropertySuccess(propertyId) {
  return {
    type: types.PROPERTY_DELETE_PROPERTY_SUCCESS,
    propertyId,
  };
}

export function deletePropertyFailure(propertyId, message) {
  return {
    type: types.PROPERTY_DELETE_PROPERTY_FAILURE,
    propertyId,
    message,
  };
}

export function cancelErrorDeleteProperty() {
  return {
    type: types.PROPERTY_DELETE_PROPERTY_CANCEL_ERROR,
  };
}

export function setCloneProperty(val) {
  if (val && val.isOpen) {
    trackMergePropOpen();
    trackMergeAction(true);
  }
  return {
    type: types.PROPERTY_SET_CLONE_PROPERTY,
    val,
  };
}

export function noMergeSaga(propertyId) {
  trackMergeAction(false);
  return {
    type: types.PROPERTY_NO_MERGE_SAGA,
    propertyId,
  };
}

export function mergeSaga(idArray) {
  return {
    type: types.PROPERTY_MERGE,
    idArray,
  };
}

export function goToProperty(id) {
  return push(ROUTES.propertyPage(id));
}

export function goToPropertyGallery(id) {
  return push(ROUTES.propertyPageGallery(id));
}

export function goToPropertyEdit(id) {
  return push(ROUTES.propertyPageEdit(id));
}

export function goToProperties() {
  return push(ROUTES.properties);
}

export function newEditPropertySaga(mode) {
  return {
    type: types.PROPERTY_NEW_EDIT_SAGA,
    mode,
  };
}

export function goToPropertyDetails(id) {
  return push(ROUTES.propertyPageDetails(id));
}

export function goToPropertyConnections(id) {
  return push(ROUTES.propertyPageConnections(id));
}

export function goToPropertyTrigger(id) {
  return push(ROUTES.propertyPageEvents(id));
}

export function goToPropertyChecklists(id, replaceRoute) {
  const route = ROUTES.propertyPageChecklists(id);
  return replaceRoute ? replace(route) : push(route);
}

export function goToChecklist(checklistId, jobInstructionId) {
  return push(ROUTES.checklist(checklistId, jobInstructionId));
}

export function goNewProperty() {
  return push(ROUTES.newProperties);
}

export function propertyChecklistPageSaga(propertyId) {
  return {
    type: types.PROPERTY_DETAIL_CHECKLIST_PAGE_SAGA,
    propertyId,
  };
}

export function setFetchStateChecklist(val) {
  return {
    type: types.PROPERTY_SET_FETCHSTATE_CHECKLIST,
    val,
  };
}

export function setIcalData(val) {
  return {
    type: types.PROPERTY_SET_ICAL_DATA,
    val,
  };
}

export function setInitIcalSaga(property) {
  return {
    type: types.PROPERTY_SET_INIT_ICAL_DATA,
    property,
  };
}

export function addEventSaga(propertyData) {
  return {
    type: types.PROPERTY_ADD_EVENT,
    propertyData,
  };
}

export function editEventSaga(trigger) {
  return {
    type: types.PROPERTY_EDIT_EVENT,
    trigger,
  };
}

export function deleteEventSaga(trigger) {
  return {
    type: types.PROPERTY_DELETE_EVENT,
    trigger,
  };
}

export function cancelEditEventSaga(trigger) {
  return {
    type: types.PROPERTY_CANCEL_EDIT_EVENT,
    trigger,
  };
}

export function saveEditTrigger(trigger) {
  return {
    type: types.PROPERTY_SAVE_EDIT_EVENT,
    trigger,
  };
}

export function mountEventsPageSaga(propertyId, mount) {
  return {
    type: types.PROPERTY_MOUNT_EVENTS,
    mount,
    propertyId,
  };
}

export function setEventsMeta(meta) {
  return {
    type: types.PROPERTY_SET_EVENTS_META,
    meta,
  };
}

export function setEventsObj(key, val) {
  return {
    type: types.PROPERTY_EVENTS_SET_KEY,
    key,
    val,
  };
}

export function setEditEventData(changeSet) {
  return {
    type: types.PROPERTY_SET_EDIT_EVENT_DATA,
    changeSet,
  };
}

export function setClonePropertySelected(val) {
  return {
    type: types.PROPERTY_SET_CLONE_PROPERTY_SELECT,
    val,
  };
}
