import React, { Component } from 'react';
import PropTypes from 'prop-types';
import log from 'loglevel';
import noop from 'lodash/noop';
import map from 'lodash/map';
import { Large, Input, Small, LoadingSplash, Icon, PopoverDefault, ButtonList, ModalError } from '@properly/components';
import Scroll from 'react-scroll';
import t from '@properly/localization';
import { imageUploadLimit } from '@properly/common';
import moment from 'moment-timezone';
import classNames from 'classnames/bind';
import styles from '../components/checklistsStyles.module.css';
import ChecklistTextArea from '../../../../components/ChecklistTextArea/index';
import ChecklistBox from '../../../../components/ChecklistBox/index';
import RerenderEveryX from '../../../../components/RerenderEveryX/index';
import NavContainer from '../../../../containers/NavContainer/index';
import { PageFlexOuter, PagePadding, PageFlexInner } from '../../../../components/PageElements/index';
import getDragWrapper from '../components/ChecklistDragWrapper';
import ChecklistUploadContainer from './ChecklistUploadContainer';
import ChecklistTextTemplateContainer from './ChecklistTextTemplateContainer';
import ChecklistStepsContainer from './ChecklistStepsContainer';
import ChecklistImagePinPreview from '../components/ChecklistImagePinPreview';
import ChecklistTextItemPreview from '../components/ChecklistTextItemPreview';
import ChecklistFieldPreview from '../components/ChecklistFieldPreview';
import DesktopAppContainer from '../../../../containers/DesktopAppContainer/index';
import ChecklistTextList from './ChecklistTextList';
import ChecklistImageList from './ChecklistImageList';
import { MasterDetail, MasterDetailMaster, MasterDetailDetail } from '../../../../components/MasterDetail/index';
import ChecklistSidebar from './ChecklistSidebar';
import { trackChecklistDesSave, trackChecklistDesFocus } from '../../../../actions/trackingEvents';
import HasPermission from '../../../../hoc/HasPermission/index';
import { mediaUploadLoadingKey } from '../../../../dataConstants';

const cx = classNames.bind(styles);

class ChecklistsContainer extends Component {
  constructor(props) {
    super(props);
    this.DragWrapper = getDragWrapper();
    this.state = {
      isEditingTitle: false,
      showErrorModal: false,
    };
  }

  componentDidMount() {
    const { jobInstructionId, checklistId, setActiveChecklist } = this.props;
    this.broadcastDetailsHit(jobInstructionId, checklistId);
    Scroll.Events.scrollEvent.register('end', to => {
      setActiveChecklist(to);
    });
  }

  componentDidUpdate(prevProps) {
    const { pathname, jobInstructionId, checklistId } = this.props;
    if (prevProps.pathname !== pathname) {
      this.broadcastDetailsHit(jobInstructionId, checklistId);
    }
  }

  broadcastDetailsHit(jobInstructionId, checklistId) {
    const { isIndependent, modalData, locationHitChecklistDetail } = this.props;
    if (jobInstructionId && checklistId) {
      locationHitChecklistDetail(
        jobInstructionId,
        checklistId,
        isIndependent || modalData?.getIn(['meta', 'isIndependent']),
      );
    }
  }

  componentWillUnmount() {
    Scroll.Events.scrollEvent.remove('end');
    this.props.updateLastUpdatedTime(undefined);
  }

  handleDirectImageUpload = (index, event) => {
    log.info('ChecklistsContainer - handleDirectImageUpload', index);
    const [imageFile] = event.target.files;
    const { size } = imageFile;
    const { minSize, maxSize } = imageUploadLimit;
    if (size < minSize || size > maxSize) {
      this.setState({
        showErrorModal: true,
      });
      return;
    }
    const { checklistDirectSlideUpload } = this.props;
    checklistDirectSlideUpload(index, imageFile);
  };

  openErrorModal = () => {
    this.setState({
      showErrorModal: true,
    });
  };

  // reset value to ensure that the same file can be uploaded more than once
  handleInputFileClick = e => {
    e.target.value = '';
  };

  handleOnCloseErrorModal = () => {
    const { setGlobalLoadingState } = this.props;
    setGlobalLoadingState(mediaUploadLoadingKey);
  };

  renderNewPhotoSlideButton = index => {
    const { selectedProperty } = this.props;
    const { objectId: propertyId } = selectedProperty || {};

    const inputId = `upload-new-photo-${index}-${propertyId || 'noproperty'}`;
    const directUpload = !propertyId;
    const handleNewPhotoSlideClick = directUpload
      ? noop
      : () =>
          this.props.setUploadState({
            isOpen: true,
            do: {
              mode: 'new',
              after: index,
            },
          });

    return (
      <>
        {directUpload && (
          <input
            accept="image/*"
            onChange={event => this.handleDirectImageUpload(index, event)}
            onClick={this.handleInputFileClick}
            className={cx('checklist__upload_input')}
            id={inputId}
            type="file"
          />
        )}

        <label
          htmlFor={inputId}
          data-key="newPhotoSlide"
          onClick={() => handleNewPhotoSlideClick()}
          className={cx('checklist__btn', 'checklist__btn--first')}
        >
          <span>
            <Icon.IcCameraWhite />
            <span>
              <span className={cx('checklist__btn-txt')}>{t('checklist.photo_slide')}</span>
            </span>
          </span>
        </label>
      </>
    );
  };

  renderButtons(id, index, jobInstructionId, isReadOnly) {
    const mutateStep = (action, meta) => this.props.stepMutatorSaga(action, meta);
    const setTemplate = this.props.setTextTemplate;
    const checklistCreateOptions = [
      {
        'data-key': 'checklist_blankslide_btn_newtxtslidepopover',
        name: t('checklist.blank_text_slide'),
        onClick: () => mutateStep('setBetween', { index, jobInstructionId }),
        icon: <Icon.IcTextPopover />,
      },
      {
        'data-key': 'checklist_texttemplate_btn_newtxtslidepopover',
        name: t('checklist.text_template'),
        onClick: () =>
          setTemplate({
            isOpen: true,
            do: {
              mode: 'setAfter',
              after: index,
            },
          }),
        icon: <Icon.IcTemplatePopover2 />,
      },
    ];

    return (
      <div id={`pin-image-wrap${id}`} className={cx('checklist__btn-wrap')}>
        {!isReadOnly && this.renderNewPhotoSlideButton(index)}
        {!isReadOnly && (
          <div style={{ width: '50%' }}>
            <PopoverDefault
              width={230}
              popoverKey="textSlideCreateOptions"
              hasArrow
              popoverContent={PopoverState => (
                <ButtonList closePopover={PopoverState.closePopup} items={checklistCreateOptions} />
              )}
            >
              <div className={cx('checklist__btn', 'checklist__btn--last')} data-key="newTextSlide">
                <span>
                  <Icon.IcTextWhite />
                  <span className={cx('checklist__btn-txt')}>{t('checklist.text_slide')}</span>
                </span>
              </div>
            </PopoverDefault>
          </div>
        )}
      </div>
    );
  }

  renderSections = (steps, jobInstructionId, checklist, isReadOnly) => {
    const updateChecklist = this.props.updateChecklistSaga;
    const { selectedProperty } = this.props;
    const { objectId: propertyId } = selectedProperty || {};

    return (
      <div>
        {this.renderDescriptionBox(updateChecklist, checklist, isReadOnly)}
        {!isReadOnly && this.renderButtons('first', -1, jobInstructionId, isReadOnly)}
        {map(steps, (step, index) => (
          <Scroll.Element key={step.objectId} name={step.objectId}>
            <div className={cx('checklist__box-wrap')} data-key="checklist-steps">
              <ChecklistBox>
                {step.pictureUrl && (
                  <ChecklistImageList
                    isReadOnly={isReadOnly}
                    step={step}
                    index={index}
                    jobId={checklist.objectId}
                    jobInstructionId={jobInstructionId}
                    propertyId={propertyId}
                    openErrorModal={this.openErrorModal}
                  />
                )}
                {!step.pictureUrl && (
                  <ChecklistTextList
                    isReadOnly={isReadOnly}
                    step={step}
                    index={index}
                    jobInstructionId={jobInstructionId}
                  />
                )}
              </ChecklistBox>
            </div>
            {this.renderButtons(step.objectId, index, jobInstructionId, isReadOnly)}
            <Scroll.Link
              containerId="checklist-scroll-main"
              onSetActive={to => this.props.setActiveChecklist(to)}
              to={step.objectId}
              spy
            />
          </Scroll.Element>
        ))}
      </div>
    );
  };

  renderDescriptionBox(updateChecklist, checklist, isReadOnly) {
    const { jobDescription, objectId } = checklist || {};

    if (!jobDescription && isReadOnly) {
      return null;
    }

    return (
      <div className={cx('checklist__box-wrap')}>
        <ChecklistBox>
          <div className={cx('checklist__topdes')}>
            <Small type="medium">{t('checklist.des')}</Small>
            <div className={cx('checklist__topdes-right')}>
              <ChecklistTextArea
                data-key="checklist_des_input"
                disabled={!!isReadOnly}
                onFocus={() => trackChecklistDesFocus()}
                onBlur={e => {
                  trackChecklistDesSave();
                  updateChecklist('update', {
                    objectId,
                    jobDescription: e.target.value,
                  });
                }}
                defaultValue={jobDescription}
                placeholder={t('checklist.topdes')}
              />
            </div>
          </div>
        </ChecklistBox>
      </div>
    );
  }

  renderWhat(isReadOnly) {
    const { isLoading, isError, jobInstructionId, selectedJobInstruction, selectedChecklist } = this.props;

    const selectedSteps = (selectedJobInstruction && selectedJobInstruction.steps) || [];

    if (isLoading) {
      return <LoadingSplash type="white" />;
    }

    if (isError) {
      return (
        <div className={cx('checklist__container--isError')}>
          <Large style={{ textAlign: 'center' }} type="bold red">
            Something went wrong while loading this checklist
          </Large>
        </div>
      );
    }

    return (
      <MasterDetail>
        <MasterDetailMaster type="checklist">
          <ChecklistSidebar jobInstructionId={jobInstructionId} isReadOnly={isReadOnly} />
        </MasterDetailMaster>
        <MasterDetailDetail>
          <PageFlexOuter>
            <PageFlexInner>
              <PagePadding id="checklist-scroll-main" type="absolute-scroll" variation="checklist">
                <div className={cx('checklist__maxwidth')}>
                  {this.renderSections(selectedSteps, jobInstructionId, selectedChecklist, isReadOnly)}
                </div>
              </PagePadding>
            </PageFlexInner>
          </PageFlexOuter>
        </MasterDetailDetail>
      </MasterDetail>
    );
  }

  changeTitle(e, func) {
    if (e.charCode === 13 || e.type === 'blur') {
      func(e.target.value);
      this.setState({ isEditingTitle: false });
    }
  }

  renderTopMiddle(bool, title, func, lastTimeUpdated, isReadOnly) {
    if (bool) {
      return (
        <Input
          isFirst
          isLast
          defaultValue={title}
          style={{ backgroundColor: '#FFF', width: 300, textAlign: 'center', padding: '0px 10px' }}
          styleOverwrite={{ backgroundColor: '#FFF', height: 32 }}
          type="text"
          data-key="checklistInputTitle"
          onBlur={e => this.changeTitle(e, func)}
          onKeyPress={e => this.changeTitle(e, func)}
        />
      );
    }
    return (
      <div className={cx('checklist__topalign', 'checklist__topalign--col')}>
        <div className={cx('checklist__topalign')}>
          <span className={cx('checklist__topalign-title')}>{title}</span>
          {!isReadOnly && <Icon.IcEditPencil />}
        </div>
        {lastTimeUpdated && (
          <div className={cx('checklist__topalign-txt')}>
            <RerenderEveryX
              seconds="5"
              content={() => <div>{t('checklist.last_saved', { time: moment(lastTimeUpdated).fromNow() })}</div>}
            />
          </div>
        )}
      </div>
    );
  }

  triggerChangeTitle = () => {
    this.setState({ isEditingTitle: true });
  };

  updateTitle(value) {
    this.props.updateChecklistSaga('update', {
      objectId: this.props.selectedChecklist.objectId,
      title: value,
    });
  }

  onSaveSession = () => {
    const { selectedProperty } = this.props;
    this.props.updateLastUpdatedTime(undefined);

    if (this.props.redirectUrl) {
      log.info('onSaveSession redirectUrl', this.props.redirectUrl);
      this.props.push(this.props.redirectUrl);
    } else if (
      this.props.modalData.get('isOpen') &&
      this.props.selectedChecklist &&
      this.props.selectedChecklist.objectId
    ) {
      this.props.setModalClose(this.props.selectedChecklist.objectId);
      log.info('onSaveSession setModalClose', this.props.selectedChecklist.objectId);
    } else if (selectedProperty) {
      this.props.goToProperty(selectedProperty.objectId);
      log.info('onSaveSession goToProperty', selectedProperty.objectId);
    } else {
      this.props.goToProperty();
      log.info('onSaveSession goToProperty');
    }
  };

  renderMiddleNav(isReadOnly) {
    if (!this.props.selectedChecklist) return null;
    return (
      <NavContainer.ClickItem
        data-key="checklist_title_btn"
        onClick={(!isReadOnly && this.triggerChangeTitle) || noop}
        side="middle"
      >
        {this.renderTopMiddle(
          this.state.isEditingTitle,
          this.props.selectedChecklist.title,
          this.updateTitle.bind(this),
          this.props.lastTimeUpdated,
          isReadOnly,
        )}
      </NavContainer.ClickItem>
    );
  }

  genNavItems = (isReadOnly, showSaveButton) => (
    <ul style={{ height: '100%', display: 'block' }}>
      <NavContainer.ClickItem onClick={this.onSaveSession} side="left" data-key="closeChecklist">
        {t('checklist.close')}
      </NavContainer.ClickItem>
      {this.renderMiddleNav(isReadOnly)}
      {showSaveButton && (
        <NavContainer.ClickItem onClick={this.onSaveSession} side="right" data-key="saveChecklist">
          {!isReadOnly ? t('checklist.save') : t('checklist.done')}
        </NavContainer.ClickItem>
      )}
    </ul>
  );

  render() {
    const { readOnly, isLoading, isError, imageUploadErrored, jobInstructionId, selectedProperty } = this.props;
    const { showErrorModal } = this.state;
    const showSaveButton = !isLoading && !isError;
    const { DragWrapper } = this;
    return (
      <>
        <DragWrapper>
          <div data-key="checklist-container-modal">
            <ChecklistImagePinPreview />
            <ChecklistTextItemPreview />
            <ChecklistFieldPreview />
            <ChecklistStepsContainer
              jobInstructionId={jobInstructionId}
              propertyId={selectedProperty?.objectId}
              handleDirectImageUpload={this.handleDirectImageUpload}
              handleInputFileClick={this.handleInputFileClick}
            />
            <ChecklistTextTemplateContainer jobInstructionId={jobInstructionId} />
            {selectedProperty && (
              <ChecklistUploadContainer
                pictureUrls={selectedProperty.pictureUrls}
                propertyId={selectedProperty.objectId}
              />
            )}
            <HasPermission
              meta={{ propertyId: selectedProperty?.objectId }}
              hasAccessFormatter={hasAccessRes => !hasAccessRes.canCreateChecklists}
              render={isReadOnly => (
                <DesktopAppContainer
                  bgColor="grey"
                  noMaxWidth
                  canOverLayNav
                  navItems={this.genNavItems(isReadOnly || readOnly, showSaveButton)}
                >
                  {this.renderWhat(isReadOnly || readOnly)}
                </DesktopAppContainer>
              )}
            />
          </div>
        </DragWrapper>

        {/* Modal to show for image size limit validation or if upload fails */}
        <ModalError.ImageUpload
          onCloseLimitError={() => this.setState({ showErrorModal: false })}
          showLimitError={showErrorModal}
          showUploadError={imageUploadErrored}
          onCloseUploadError={this.handleOnCloseErrorModal}
        />
      </>
    );
  }
}

ChecklistsContainer.propTypes = {
  jobInstructionId: PropTypes.string.isRequired,
  checklistId: PropTypes.string.isRequired,
  isIndependent: PropTypes.bool,

  pathname: PropTypes.string,
  redirectUrl: PropTypes.string,

  isLoading: PropTypes.bool,
  isError: PropTypes.bool,

  lastTimeUpdated: PropTypes.instanceOf(Date),
  modalData: PropTypes.shape({}),
  selectedJobInstruction: PropTypes.shape({}).isRequired,
  selectedChecklist: PropTypes.shape({}).isRequired,
  selectedProperty: PropTypes.shape({}),
  locationHitChecklistDetail: PropTypes.func.isRequired,
  goToProperty: PropTypes.func,
  updateLastUpdatedTime: PropTypes.func,
  setActiveChecklist: PropTypes.func,
  checklistDirectSlideUpload: PropTypes.func,
};

ChecklistsContainer.defaultProps = {
  pathname: undefined,
  redirectUrl: undefined,
  isIndependent: false,

  isLoading: true,
  isError: false,

  selectedProperty: null,
  lastTimeUpdated: null,
  setActiveChecklist: () => log.warn('thumbnail is not visible for properly published checklist'),

  updateLastUpdatedTime: () => log.warn('updateLastUpdatedTime prop is not implemented'),
  goToProperty: () => log.error('goToProperty prop is not implemented'),
  checklistDirectSlideUpload: () => {},
};

export default ChecklistsContainer;
