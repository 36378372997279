import React from 'react';
import { Route, IndexRoute, Redirect } from 'react-router';
import { BookingDetailsModal } from '@properly/components';
import { setWindowTitle } from '@properly/common';
import store from 'store';

import PropertyContainer from './modules/desktopApp/property/containers/PropertyContainer';
import PropertyDetail from './modules/desktopApp/property/containers/PropertyDetailContainer';

import PropertyNew from './modules/desktopApp/property/containers/PropertyNewContainer';

import ChecklistUploadContainer from './modules/desktopApp/checklists/containers/ChecklistUploadContainer';
import BookingDetailsContainer from './containers/BookingDetailsContainer';

const renderBookingDetailsContainer = props => <BookingDetailsContainer {...props} />;

export default () => {
  const KEY = 'PROPERTY_DETAIL_VIEW_KEY';
  const setPropertyDetailView = view => store.set(KEY, view);
  const getPropertyDetailView = () => store.get(KEY) || 'jobrequest';
  return (
    <>
      <Route exact path="properties" component={PropertyContainer} onEnter={setWindowTitle('titles.property')} />

      <Route exact path="properties/new" component={PropertyContainer} onEnter={setWindowTitle('titles.property')}>
        <IndexRoute component={PropertyNew} onEnter={setWindowTitle('titles.new_property')} />
      </Route>

      <Route exact path="properties/:id/edit" component={PropertyContainer}>
        <IndexRoute component={PropertyNew} onEnter={setWindowTitle('titles.edit_property')} />
      </Route>

      <Route
        exact
        path="properties/:id/gallery"
        onEnter={setWindowTitle('titles.property')}
        component={props => (
          <ChecklistUploadContainer
            onCloseRoute={`properties/${props.params.id}/${getPropertyDetailView()}`}
            fromRoute
            isGallery
            propertyId={props.params.id}
          />
        )}
      />

      <Redirect from="properties/:id" to={`properties/:id/${getPropertyDetailView()}`} />

      <Route
        path="properties/:id/:propertyDetailView/:jobRequestId"
        component={props => {
          setPropertyDetailView(props.params.propertyDetailView);
          const { children, ...rest } = props;
          return (
            <PropertyContainer {...rest}>
              <PropertyDetail {...rest}>{children}</PropertyDetail>
            </PropertyContainer>
          );
        }}
      />

      <Route
        path="properties/:id/:propertyDetailView"
        component={props => {
          setPropertyDetailView(props.params.propertyDetailView);
          const { children, ...rest } = props;
          return (
            <PropertyContainer {...rest}>
              <PropertyDetail {...rest}>{children}</PropertyDetail>
            </PropertyContainer>
          );
        }}
      >
        <Route
          exact
          path="booking/:bookingId"
          component={props => (
            <BookingDetailsModal
              bookingId={props.params.bookingId}
              propertyId={props.params.id}
              handleCancel={() => props.router.push(`properties/${props.params.id}/${getPropertyDetailView()}`)}
              renderChildren={renderBookingDetailsContainer}
              show
            />
          )}
          onEnter={setWindowTitle('titles.property')}
        />
      </Route>
    </>
  );
};
