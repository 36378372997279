import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ButtonList, PopoverDefault } from '@properly/components';
import t from '@properly/localization';
import * as selectors from './ReportsSelectors';
import { PageTopBar } from '../../../components/PageElements';
import TopLabel from '../../../components/TopLabel';
import { reportsSaveSaga, reportsDeleteSaga, exportCSVSaga } from './ReportsActions';
import { NEW_KEY } from './ReportsConstants';

class ReportsTopBarContainer extends PureComponent {
  export(format) {
    return () => {
      this.props.exportCSVSaga(format);
    };
  }

  popWrap(input) {
    return (
      <PopoverDefault
        popoverKey="exportReport"
        width={230}
        hasArrow
        popoverContent={PopoverState => (
          <ButtonList
            closePopover={PopoverState.closePopup}
            items={[
              {
                name: t('reports.export_as_csv'),
                onClick: this.export('comma'),
              },
              {
                name: t('reports.export_as_csv1'),
                onClick: this.export('tab'),
              },
              {
                name: t('reports.export_as_csv2'),
                onClick: this.export('pipe'),
              },
            ]}
          />
        )}
      >
        {input}
      </PopoverDefault>
    );
  }

  renderInner(loadedReport) {
    if (!loadedReport) return null;
    return (
      <TopLabel.Wrap>
        <TopLabel
          onlyTxtCursor
          paddingRight
          data-key="button_delete_report"
          disabled={!this.canDelete}
          onClick={this.props.reportsDeleteSaga}
          label={t('reports.delete')}
        />
        <TopLabel
          onlyTxtCursor
          data-key="button_export_report"
          disabled={!this.canExport}
          paddingRight
          wrap={input => this.popWrap(input)}
          label={t('reports.export')}
        />
        <TopLabel
          onlyTxtCursor
          noPadding
          data-key="button_save_report"
          disabled={!this.canSave}
          onClick={this.props.reportsSaveSaga}
          label={t('reports.save')}
        />
      </TopLabel.Wrap>
    );
  }

  get canDelete() {
    return this.props.loadedReport && this.props.loadedReport !== NEW_KEY;
  }

  get sectionsJS() {
    return this.props.sections?.toJS();
  }

  get canSave() {
    return this.sectionsJS.title && this.sectionsJS.title.value && this.sectionsJS.title.value.length > 0;
  }

  get canExport() {
    const loadedReportsData = this.props.reportsData.get(this.props.loadedReport);
    if (!loadedReportsData) return false;
    return loadedReportsData.get('data').size > 0;
  }

  render() {
    return (
      <PageTopBar>
        <div style={{ position: 'relative' }} id="topbar3">
          {this.renderInner(this.props.loadedReport)}
        </div>
      </PageTopBar>
    );
  }
}

function mapStateToProps(state) {
  return {
    loadedReport: selectors.selectReportsLoadedReport()(state),
    sections: selectors.selectReportsSections()(state),
    reportsData: selectors.selectReportsData()(state),
  };
}

export default connect(mapStateToProps, {
  reportsSaveSaga,
  reportsDeleteSaga,
  exportCSVSaga,
})(ReportsTopBarContainer);
