import log from 'loglevel';
import { put, fork, call, takeEvery } from 'redux-saga/effects';
import * as types from '../../../../types/index';
import {
  setUserFeaturesSuccess,
  setUserFeaturesFailure,
  setUserFeatureFlagsSuccess,
  setUserFeatureFlagsFailure,
} from './SettingsActions';
import { getUserFeatures } from '../../../../graphql/api/feature';
import { getAllUserFeatureFlags } from '../../../../graphql/api/featureFlagConfig';

export function* getUserFeaturesSaga() {
  try {
    const userFeaturesResponse = yield call(getUserFeatures);

    log.info('getUserFeaturesSaga userFeatures', {
      userFeaturesResponse,
    });

    if (userFeaturesResponse.apiError) {
      throw userFeaturesResponse;
    }

    yield put(setUserFeaturesSuccess(userFeaturesResponse));
  } catch (error) {
    yield put(setUserFeaturesFailure(error));
  }
}

export function* getUserFeatureFlagsSaga() {
  try {
    const userFeatureFlagsResponse = yield call(getAllUserFeatureFlags);

    log.info('getUserFeatureFlagsSaga userFeatureFlags', {
      userFeatureFlagsResponse,
    });

    if (userFeatureFlagsResponse.apiError) {
      throw userFeatureFlagsResponse;
    }

    yield put(setUserFeatureFlagsSuccess(userFeatureFlagsResponse));
  } catch (error) {
    yield put(setUserFeatureFlagsFailure(error));
  }
}

function* saga() {
  yield fork(takeEvery, types.SETTINGS_GET_USER_FEATURES_REQUEST, getUserFeaturesSaga);
  yield fork(takeEvery, types.SETTINGS_GET_USER_FEATURE_FLAGS_REQUEST, getUserFeatureFlagsSaga);
}

export default saga;
