/* User */
export const USER_UPDATE_CURRENT_USER = 'USER_UPDATE_CURRENT_USER';
export const USER_UPDATE_CURRENT_USER_SUBSCRIPTION = 'USER_UPDATE_CURRENT_USER_SUBSCRIPTION';
export const DISABLE_SHOW_ONBOARDING_VIDEO = 'DISABLE_SHOW_ONBOARDING_VIDEO';
export const SET_USER_EMAIL_TO_AUTO_POPULATE = 'SET_USER_EMAIL_TO_AUTO_POPULATE';
export const RESET_USER_EMAIL_TO_AUTO_POPULATE = 'RESET_USER_EMAIL_TO_AUTO_POPULATE';

/* LOG IN */
export const LOGIN_SUCCESS_USER = 'LOGIN_SUCCESS_USER';
export const LOGIN_ERROR_USER = 'LOGIN_ERROR_USER';
export const LOGIN_ERROR_USER_RESET = 'LOGIN_ERROR_USER_RESET';
export const LOGIN_BECOME_USER = 'LOGIN_BECOME_USER';
export const LOGIN_REDIRECT_TO_LANDING_PAGE_SAGA = 'LOGIN_REDIRECT_TO_LANDING_PAGE_SAGA';
export const LOGIN_SAGA = 'LOGIN_SAGA';
export const LOGIN_SET_LOADING = 'LOGIN_SET_LOADING';
export const LOGIN_GATE_SAGA = 'LOGIN_GATE_SAGA';
export const LOGIN_SET_USER_OVERRIDE = 'LOGIN_SET_USER_OVERRIDE';

/* HOST SIGN UP */
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_SUCCESS_USER = 'SIGNUP_SUCCESS_USER';
export const SIGNUP_ERROR_USER = 'SIGNUP_ERROR_USER';
export const SIGNUP_ERROR_USER_RESET = 'SIGNUP_ERROR_USER_RESET';
export const SIGNUP_SAGA = 'SIGNUP_SAGA';

/* LOG OUT */
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_SUCCESS_USER = 'LOGOUT_SUCCESS_USER';

/* BRANDING */
export const BRANDING_CONFIG_SAGA = 'BRANDING_CONFIG_SAGA';
export const BRANDING_CONFIG_SET = 'BRANDING_CONFIG_SET';
export const BRANDING_REDIRECT_TO_DOWNLOAD_APP_PAGE = 'BRANDING_REDIRECT_TO_DOWNLOAD_APP_PAGE';

/* SEND TO MOBILE */
export const SEND_TO_MOBILE_HOST_SUCCESS = 'SEND_TO_MOBILE_HOST_SUCCESS';
export const SEND_TO_MOBILE_HOST_ERROR = 'SEND_TO_MOBILE_HOST_ERROR';
export const SEND_TO_MOBILE_HOST_RESET = 'SEND_TO_MOBILE_HOST_RESET';

/* RESET PASSWORD */
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_VALIDATE = 'RESET_PASSWORD_VALIDATE';
export const RESET_PASSWORD_RESET = 'RESET_PASSWORD_RESET';

/* FETCHED COUNTRY */
export const SETTINGS_COUNTRY_SUCCESS = 'SETTINGS_COUNTRY_SUCCESS';
export const SETTINGS_GET_USER_FEATURES_REQUEST = 'SETTINGS_GET_USER_FEATURES_REQUEST';
export const SETTINGS_SET_USER_FEATURES_SUCCESS = 'SETTINGS_SET_USER_FEATURES_SUCCESS';
export const SETTINGS_SET_USER_FEATURES_FAILURE = 'SETTINGS_SET_USER_FEATURES_FAILURE';

export const SETTINGS_GET_USER_FEATURE_FLAGS_REQUEST = 'SETTINGS_GET_USER_FEATURES_REQUEST';
export const SETTINGS_SET_USER_FEATURE_FLAGS_SUCCESS = 'SETTINGS_SET_USER_FEATURE_FLAGS_SUCCESS';
export const SETTINGS_SET_USER_FEATURE_FLAGS_FAILURE = 'SETTINGS_SET_USER_FEATURES_FAILURE';

/* ADMIN MODE */
export const SET_DASHBOARD_ADMIN_DETAILS = 'SET_DASHBOARD_ADMIN_DETAILS';

/* Properties */
export const PROPERTY_LOAD_PROPERTIES_FAILURE = 'PROPERTY_LOAD_PROPERTIES_FAILURE';
export const PROPERTY_DELETE_PROPERTY = 'PROPERTY_DELETE_PROPERTY';
export const PROPERTY_DELETE_PROPERTY_SUCCESS = 'PROPERTY_DELETE_PROPERTY_SUCCESS';
export const PROPERTY_DELETE_PROPERTY_FAILURE = 'PROPERTY_DELETE_PROPERTY_FAILURE';
export const PROPERTY_DELETE_PROPERTY_REQUEST = 'PROPERTY_DELETE_PROPERTY_REQUEST';
export const PROPERTY_DELETE_PROPERTY_CANCEL_ERROR = 'PROPERTY_DELETE_PROPERTY_CANCEL_ERROR';
export const PROPERTY_SET_TMP_PROPERTY = 'PROPERTY_SET_TMP_PROPERTY';
export const PROPERTY_UPDATE_TMP_PROPERTY = 'PROPERTY_UPDATE_TMP_PROPERTY';
export const PROPERTY_UPDATE_PROPERTY_REQUEST_STATE = 'PROPERTY_UPDATE_PROPERTY_REQUEST_STATE';
export const PROPERTY_PROPERTIES_HIT = 'PROPERTY_PROPERTIES_HIT';
export const PROPERTY_SET_CLONE_PROPERTY = 'PROPERTY_SET_CLONE_PROPERTY';
export const PROPERTY_SET_CLONE_PROPERTY_SELECT = 'PROPERTY_SET_CLONE_PROPERTY_SELECT';
export const PROPERTY_MERGE = 'PROPERTY_MERGE';
export const PROPERTY_UPDATE_TAGS = 'PROPERTY_UPDATE_TAGS';
export const PROPERTY_NO_MERGE_SAGA = 'PROPERTY_NO_MERGE_SAGA';
export const PROPERTY_SET_FETCHSTATE_CHECKLIST = 'PROPERTY_SET_FETCHSTATE_CHECKLIST';
export const PROPERTY_DETAIL_CHECKLIST_PAGE_SAGA = 'PROPERTY_DETAIL_CHECKLIST_PAGE_SAGA';
export const PROPERTY_SET_ICAL_DATA = 'PROPERTY_SET_ICAL_DATA';
export const PROPERTY_SET_INIT_ICAL_DATA = 'PROPERTY_SET_INIT_ICAL_DATA';
export const PROPERTY_DELETE_ICAL = 'PROPERTY_DELETE_ICAL';
export const PROPERTY_ADD_ICAL_LINK_REQUEST = 'PROPERTY_ADD_ICAL_LINK_REQUEST';
export const PROPERTY_EVENTS_SET_KEY = 'PROPERTY_EVENTS_SET_KEY';
export const PROPERTY_MOUNT_EVENTS = 'PROPERTY_MOUNT_EVENTS';
export const PROPERTY_DELETE_EVENT = 'PROPERTY_DELETE_EVENT';
export const PROPERTY_EDIT_EVENT = 'PROPERTY_EDIT_EVENT';
export const PROPERTY_ADD_EVENT = 'PROPERTY_ADD_EVENT';
export const PROPERTY_SET_EVENTS_META = 'PROPERTY_SET_EVENTS_META';
export const PROPERTY_SET_EDIT_EVENT_DATA = 'PROPERTY_SET_EDIT_EVENT_DATA';
export const PROPERTY_CANCEL_EDIT_EVENT = 'PROPERTY_CANCEL_EDIT_EVENT';
export const PROPERTY_SAVE_EDIT_EVENT = 'PROPERTY_SAVE_EDIT_EVENT';
export const PROPERTY_NEW_EDIT_SAGA = 'PROPERTY_NEW_EDIT_SAGA';

/* Contacts */
export const CONTACTS_UPDATE_TAGS = 'CONTACTS_UPDATE_TAGS';
export const CONTACTS_DELETE_CONTACT = 'CONTACTS_DELETE_CONTACT';
export const CONTACTS_SET_CONTACT_MODAL = 'CONTACTS_SET_CONTACT_MODAL';
export const CONTACTS_SET_CONTACT_MODAL_DATA = 'CONTACTS_SET_CONTACT_MODAL_DATA';
export const CONTACTS_RESET_CONTACT_MODAL_DATA = 'CONTACTS_RESET_CONTACT_MODAL_DATA';
export const CONTACTS_RESEND_INVITE_SAGA = 'CONTACTS_RESEND_INVITE_SAGA';
export const CONTACTS_FRESH_INVITE_SAGA = 'CONTACTS_FRESH_INVITE_SAGA';
export const CONTACTS_ADD_CONTACT_SAGA = 'CONTACTS_ADD_CONTACT_SAGA';
export const CONTACTS_CLOSE_MODAL_SAGA = 'CONTACTS_CLOSE_MODAL_SAGA';
export const CONTACTS_HIDE_ERROR = 'CONTACTS_HIDE_ERROR';
export const CONTACTS_RESET_ERROR = 'CONTACTS_RESET_ERROR';
export const CONTACTS_DETAIL_PAGE_SAGA = 'CONTACTS_DETAIL_PAGE_SAGA';
export const CONTACTS_SET_FETCHSTATE_CR = 'CONTACTS_SET_FETCHSTATE_CR';
export const CONTACT_GET_COMPLETED_SKILL_SAGA = 'CONTACT_GET_COMPLETED_SKILL_SAGA';
export const CONTACT_SAVE_COMPLETED_SKILLS_TO_STORE = 'CONTACT_SAVE_COMPLETED_SKILLS_TO_STORE';

/* global */
export const GLOBAL_SET_PRELOAD_DATA = 'GLOBAL_SET_PRELOAD_DATA';
export const GLOBAL_SET_PRELOAD_STATUS = 'GLOBAL_SET_PRELOAD_STATUS';
export const GLOBAL_SET_GLOBAL_SEARCH_QUERY = 'GLOBAL_SET_GLOBAL_SEARCH_QUERY';
export const START_PRELOAD_SAGA = 'START_PRELOAD_SAGA';
export const UPDATE_COLLECTION_ENTRY = 'UPDATE_COLLECTION_ENTRY';
export const UPDATE_COLLECTION_ENTRY_BATCH = 'UPDATE_COLLECTION_ENTRY_BATCH';
export const FILTER_COLLECTION_ENTRY_BATCH = 'FILTER_COLLECTION_ENTRY_BATCH';
export const SET_ERROR_MODAL = 'SET_ERROR_MODAL';
export const RESET_ERROR_MODAL = 'RESET_ERROR_MODAL';
export const GLOBAL_LOGOUT = 'GLOBAL_LOGOUT';
export const GLOBAL_SET_SLIDER_SAGA = 'GLOBAL_SET_SLIDER_SAGA';
export const SET_SLIDER_STATE = 'SET_SLIDER_STATE';
export const GLOBAL_UPDATE_IN_BACKGROUND = 'GLOBAL_UPDATE_IN_BACKGROUND';
export const GLOBAL_PRE_LOGIN_SAGA = 'GLOBAL_PRE_LOGIN_SAGA';
export const GLOBAL_USER_REFETCH_SAGA = 'GLOBAL_USER_REFETCH_SAGA';
export const GLOBAL_INTERCEPT_SAGA = 'GLOBAL_INTERCEPT_SAGA';
export const GLOBAL_INTERCEPT_MODAL_SAGA = 'GLOBAL_INTERCEPT_MODAL_SAGA';
export const GLOBAL_PRE_LOGIN_SUCCESS = 'GLOBAL_PRE_LOGIN_SUCCESS';
export const START_PRELOAD_SAGA_WITH_DELAY = 'START_PRELOAD_SAGA_WITH_DELAY';
export const START_LISTEN_USER = 'START_LISTEN_USER';
export const STOP_LISTEN_USER = 'STOP_LISTEN_USER';
export const SET_GLOBAL_LOADING_STATE = 'SET_GLOBAL_LOADING_STATE';
export const GLOBAL_SET_MODAL = 'GLOBAL_SET_MODAL';

/* Job Request */
export const JOB_REQUEST_INIT_SAGA = 'JOB_REQUEST_INIT_SAGA';
export const JOB_REQUEST_SUBMIT = 'JOB_REQUEST_SUBMIT';
export const JOB_REQUEST_SELECT_CHECKLIST = 'JOB_REQUEST_SELECT_CHECKLIST';
export const JOB_REQUEST_CHANGE_JOB_TITLE = 'JOB_REQUEST_CHANGE_JOB_TITLE';
export const JOB_REQUEST_CHANGE_JOB_TYPE = 'JOB_REQUEST_CHANGE_JOB_TYPE';
export const JOB_REQUEST_SELECT_CHECKLIST_TYPE = 'JOB_REQUEST_SELECT_CHECKLIST_TYPE';
export const JOB_REQUEST_OPEN_PAYWALL_MODAL = 'JOB_REQUEST_OPEN_PAYWALL_MODAL';
export const JOB_REQUEST_CLOSE_PAYWALL_MODAL = 'JOB_REQUEST_CLOSE_PAYWALL_MODAL';
export const JOB_REQUEST_HANDLECLICK = 'JOB_REQUEST_HANDLECLICK';
export const JOB_REQUEST_SET_OPEN_SECTION = 'JOB_REQUEST_SET_OPEN_SECTION';
export const JOB_REQUEST_SET_DATA = 'JOB_REQUEST_SET_DATA';
export const JOB_REQUEST_SET_COMMUNITY = 'JOB_REQUEST_SET_COMMUNITY';
export const JOB_REQUEST_SET_STARTUPDATA = 'JOB_REQUEST_SET_STARTUPDATA';
export const JOB_REQUEST_RESET = 'JOB_REQUEST_RESET';
export const JOB_REQUEST_UNSET_CLEANERS = 'JOB_REQUEST_UNSET_CLEANERS';
export const JOB_REQUEST_LOAD_LIST = 'JOB_REQUEST_LOAD_LIST';
export const JOB_REQUEST_LOAD_PHOTO = 'JOB_REQUEST_LOAD_PHOTO';
export const JOB_REQUEST_UPDATE_PROBLEM = 'JOB_REQUEST_UPDATE_PROBLEM';
export const JOB_REQUEST_SELECT_SKILL = 'JOB_REQUEST_SELECT_SKILL';
export const JOB_REQUEST_SET_HOST_STRIPE_STATUS = 'JOB_REQUEST_SET_HOST_STRIPE_STATUS';
export const JOB_DETAILS_SET_STRIPE_PAYMENT_DETAILS = 'JOB_DETAILS_SET_STRIPE_PAYMENT_DETAILS';

/* Comments */
export const GET_COMMENTS = 'GET_COMMENTS';
export const ADD_COMMENTS = 'ADD_COMMENTS';
export const SET_COMMENTS = 'SET_COMMENTS';
export const GET_COMMENTS_STORE = 'GET_COMMENTS_STORE';
export const JOB_DETAILS_HANDLECLICK = 'JOB_DETAILS_HANDLECLICK';
export const JOB_REQUEST_CHANGE_TASK_DESCRIPTION = 'JOB_REQUEST_CHANGE_TASK_DESCRIPTION';
export const JOB_REQUEST_CHANGE_TASK_TITLE = 'JOB_REQUEST_CHANGE_TASK_TITLE';
export const SET_COMMENT_LOADER = 'SET_COMMENT_LOADER';

/* Config */
export const SET_PARSE_CONFIG = 'SET_PARSE_CONFIG';
export const SET_GENERAL_CONFIG = 'SET_GENERAL_CONFIG';

/* Account */
export const ACCOUNT_LOGOUT = 'ACCOUNT_LOGOUT';
export const ACCOUNT_SNAP_USER = 'ACCOUNT_SNAP_USER';
export const ACCOUNT_SNAP_USER_SET = 'ACCOUNT_SNAP_USER_SET';
export const ACCOUNT_UPDATE_TMP_USER = 'ACCOUNT_UPDATE_TMP_USER';
export const ACCOUNT_UPDATE_USER_SAGA = 'ACCOUNT_UPDATE_USER_SAGA';
export const ACCOUNT_UPLOAD_PROFILE_PIC = 'ACCOUNT_UPLOAD_PROFILE_PIC';
export const ACCOUNT_SET_UPDATE_USER_STATE = 'ACCOUNT_SET_UPDATE_USER_STATE';
export const ACCOUNT_SET_PASSWORD_STORE = 'ACCOUNT_SET_PASSWORD_STORE';
export const ACCOUNT_CHANGE_PW_SAGA = 'ACCOUNT_CHANGE_PW_SAGA';
export const ACCOUNT_SET_STATE = 'ACCOUNT_SET_STATE';
export const ACCOUNT_SEND_PW_SAGA = 'ACCOUNT_SEND_PW_SAGA';
export const ACCOUNT_PHONE_VERIFY_SAGA = 'ACCOUNT_PHONE_VERIFY_SAGA';
export const ACCOUNT_OAUTH_SAGA = 'ACCOUNT_OAUTH_SAGA';
export const ACCOUNT_DISCONNECT = 'ACCOUNT_DISCONNECT';
export const ACCOUNT_REIMPORT = 'ACCOUNT_REIMPORT';
export const ACCOUNT_IMPORT_SUCCESS = 'ACCOUNT_IMPORT_SUCCESS';
export const ACCOUNT_CLOSE_MODAL_BACK_SAGA = 'ACCOUNT_CLOSE_MODAL_BACK_SAGA';
export const ACCOUNT_SET_WARNING_MODAL = 'ACCOUNT_SET_WARNING_MODAL';
export const ACCOUNT_REDIRECT_PROVIDER_SAGA = 'ACCOUNT_REDIRECT_PROVIDER_SAGA';
export const ACCOUNT_CONNECT_DIRECT_TO_PARTNER_SAGA = 'ACCOUNT_CONNECT_DIRECT_TO_PARTNER_SAGA';
export const ACCOUNT_REDIRECT_TO_MOBILE_SAGA = 'ACCOUNT_REDIRECT_TO_MOBILE_SAGA';
export const ACCOUNT_REDIRECT_PROVIDER_LEGACY_SAGA = 'ACCOUNT_REDIRECT_PROVIDER_LEGACY_SAGA';
export const ACCOUNT_DISMISS_DISCONNECT_ERROR = 'connectedAccount/ACCOUNT_DISMISS_DISCONNECT_ERROR';
export const ACCOUNT_GET_CONNECTED_ACCOUNT_REQUEST = 'connectedAccount/ACCOUNT_GET_CONNECTED_ACCOUNT_REQUEST';
export const ACCOUNT_GET_CONNECTED_ACCOUNT_SUCCESS = 'connectedAccount/ACCOUNT_GET_CONNECTED_ACCOUNT_SUCCESS';
export const ACCOUNT_GET_CONNECTED_ACCOUNT_FAILURE = 'connectedAccount/ACCOUNT_GET_CONNECTED_ACCOUNT_FAILURE';
export const ACCOUNT_LEAVE_ACCOUNT_CONNECTIONS = 'connectedAccount/ACCOUNT_LEAVE_ACCOUNT_CONNECTIONS';
export const ACCOUNT_DISMISS_SYNC_ERROR = 'connectedAccount/ACCOUNT_DISMISS_SYNC_ERROR';
export const ACCOUNT_SYNC_CONNECTED_ACCOUNT_REQUEST = 'connectedAccount/ACCOUNT_SYNC_CONNECTED_ACCOUNT_REQUEST';
export const ACCOUNT_SYNC_CONNECTED_ACCOUNT_SUCCESS = 'connectedAccount/ACCOUNT_SYNC_CONNECTED_ACCOUNT_SUCCESS';
export const ACCOUNT_SYNC_CONNECTED_ACCOUNT_FAILURE = 'connectedAccount/ACCOUNT_SYNC_CONNECTED_ACCOUNT_FAILURE';
export const ACCOUNT_DISCONNECT_CONNECTED_ACCOUNT_REQUEST =
  'connectedAccount/ACCOUNT_DISCONNECT_CONNECTED_ACCOUNT_REQUEST';
export const ACCOUNT_DISCONNECT_CONNECTED_ACCOUNT_SUCCESS =
  'connectedAccount/ACCOUNT_DISCONNECT_CONNECTED_ACCOUNT_SUCCESS';
export const ACCOUNT_DISCONNECT_CONNECTED_ACCOUNT_FAILURE =
  'connectedAccount/ACCOUNT_DISCONNECT_CONNECTED_ACCOUNT_FAILURE';

/* Booking com pilot onboarding */

export const BOOKINGCOM_REDIRECT_OAUTH_PROVIDER_SAGA = 'BOOKINGCOM_REDIRECT_OAUTH_PROVIDER_SAGA';
export const BOOKINGCOM_PILOT_NEW_USER_SIGNUP_SAGA = 'BOOKINGCOM_PILOT_NEW_USER_SIGNUP_SAGA';
export const BOOKINGCOM_PILOT_REDIRECT_TO_LANDING_PAGE_SAGA = 'BOOKINGCOM_PILOT_REDIRECT_TO_LANDING_PAGE_SAGA';
export const SET_BRANCH_IO_LINK = 'SET_BRANCH_IO_LINK';

/* ONBOARDING */
export const HAS_SEEN_ONBOARDING_VIDEO = 'HAS_SEEN_ONBOARDING_VIDEO';

/* Job Details */
export const JOB_DETAILS_OPEN_SAGA = 'JOB_DETAILS_OPEN_SAGA';
export const JOB_DETAILS_CLOSE_SAGA = 'JOB_DETAILS_CLOSE_SAGA';
export const JOB_DETAILS_SET = 'JOB_DETAILS_SET';
export const JOB_DETAILS_CANCEL_SAGA = 'JOB_DETAILS_CANCEL_SAGA';
export const JOB_DETAILS_EDIT_SAGA = 'JOB_DETAILS_EDIT_SAGA';
export const JOB_DETAILS_COST_SAGA = 'JOB_DETAILS_COST_SAGA';
export const JOB_DETAILS_ADD_COST_SAGA = 'JOB_DETAILS_ADD_COST_SAGA';
export const JOB_DETAILS_ADD_COST_PICTURE_SAGA = 'JOB_DETAILS_ADD_COST_PICTURE_SAGA';
export const JOB_DETAILS_EDIT_CLOSE_SAGA = 'JOB_DETAILS_EDIT_CLOSE_SAGA';
export const JOB_DETAILS_COST_CLOSE_SAGA = 'JOB_DETAILS_COST_CLOSE_SAGA';
export const JOB_DETAILS_EDIT_CONTINUE_SAGA = 'JOB_DETAILS_EDIT_CONTINUE_SAGA';
export const JOB_DETAILS_MORE_CLEANER_SAGA = 'JOB_DETAILS_MORE_CLEANER_SAGA';
export const JOB_DETAILS_EDIT_STOP_CHANNEL = 'JOB_DETAILS_EDIT_STOP_CHANNEL';
export const JOB_DETAILS_SCHEDULE_PROBLEMS_SAGA = 'JOB_DETAILS_SCHEDULE_PROBLEMS_SAGA';
export const JOB_DETAILS_SET_SOURCE_JOB_PROBLEMS = 'JOB_DETAILS_SET_SOURCE_JOB_PROBLEMS';
export const JOB_DETAILS_SET_RESOLVED_PROBLEM = 'JOB_DETAILS_SET_RESOLVED_PROBLEM';
export const JOB_DETAILS_SET_TEMP_MILES_IMAGES = 'JOB_DETAILS_SET_TEMP_MILES_IMAGES';
export const JOB_DETAILS_SET_TEMP_DURATION_IMAGES = 'JOB_DETAILS_SET_TEMP_DURATION_IMAGES';
export const JOB_DETAILS_SET_TEMP_COST_IMAGES = 'JOB_DETAILS_SET_TEMP_COST_IMAGES';
export const JOB_DETAILS_SET_COST_TYPE = 'JOB_DETAILS_SET_COST_TYPE';
export const JOB_DETAILS_SET_CHECKLISTS_VERIFICATION_PHOTOS = 'JOB_DETAILS_SET_CHECKLISTS_VERIFICATION_PHOTOS';
export const JOB_DETAILS_OPEN_SOURCE_JOB = 'JOB_DETAILS_OPEN_SOURCE_JOB';

/* Checklist */
export const CHECKLIST_DETAIL_HIT = 'CHECKLIST_DETAIL_HIT';
export const CHECKLIST_UPDATE_JOBINSTRUCTION = 'CHECKLIST_UPDATE_JOBINSTRUCTION';
export const CHECKLIST_SET_STATE = 'CHECKLIST_SET_STATE';
export const CHECKLIST_SET_ACTIVE = 'CHECKLIST_SET_ACTIVE';
export const CHECKLIST_SET_TILEVIEW = 'CHECKLIST_SET_TILEVIEW';
export const CHECKLIST_SET_TEXTTEMPLATE = 'CHECKLIST_SET_TEXTTEMPLATE';
export const CHECKLIST_SET_UPLOADSTATE = 'CHECKLIST_SET_UPLOADSTATE';
export const CHECKLIST_UPLOAD_PICS_SAGA = 'CHECKLIST_UPLOAD_PICS_SAGA';
export const CHECKLIST_ADD_IMAGE_PROPERTY_SAGA = 'CHECKLIST_ADD_IMAGE_PROPERTY_SAGA';
export const CHECKLIST_SET_OR_UPDATE_PICTURE_SAGA = 'CHECKLIST_SET_OR_UPDATE_PICTURE_SAGA';
export const CHECKLIST_SET_CURRENT_JOBINS_ID = 'CHECKLIST_SET_CURRENT_JOBINS_ID';
export const CHECKLIST_STEP_MUTATOR_SAGA = 'CHECKLIST_STEP_MUTATOR_SAGA';
export const CHECKLIST_CREATE_CHECKLIST_SAGA = 'CHECKLIST_CREATE_CHECKLIST_SAGA';
export const CHECKLIST_UPDATE_CHECKLIST_SAGA = 'CHECKLIST_UPDATE_CHECKLIST_SAGA';
export const CHECKLIST_TASK_MUTATOR_SAGA = 'CHECKLIST_TASK_MUTATOR_SAGA';
export const CHECKLIST_UPDATE_LAST_UPDATE_TIME = 'CHECKLIST_UPDATE_LAST_UPDATE_TIME';
export const CHECKLIST_SET_CURRENT_JOB_ID = 'CHECKLIST_SET_CURRENT_JOB_ID';
export const CHECKLIST_SET_LOADING_STATE = 'CHECKLIST_SET_LOADING_STATE';
export const CHECKLIST_SET_CLONE_CHECKLIST = 'CHECKLIST_SET_CLONE_CHECKLIST';
export const CHECKLIST_MULTI_CLONE = 'CHECKLIST_MULTI_CLONE';
export const CHECKLIST_SET_MODAL = 'CHECKLIST_SET_MODAL';
export const CHECKLIST_SET_MODAL_CLOSE = 'CHECKLIST_SET_MODAL_CLOSE';
export const CHECKLIST_DELETE_SAGA = 'CHECKLIST_DELETE_SAGA';
export const CHECKLIST_DELETE_WARNING_SAGA = 'CHECKLIST_DELETE_WARNING_SAGA';
export const CHECKLIST_DIRECT_SLIDE_IMAGE_UPLOAD = 'CHECKLIST_DIRECT_SLIDE_IMAGE_UPLOAD';
export const CHECKLIST_DIRECT_TASK_IMAGE_UPLOAD = 'CHECKLIST_DIRECT_TASK_IMAGE_UPLOAD';
export const CHECKLIST_COPY_CHECKLIST_TO_LIBRARY_REQUEST = 'CHECKLIST_COPY_CHECKLIST_TO_LIBRARY_REQUEST';
export const CHECKLIST_COPY_CHECKLIST_TO_LIBRARY_SUCCESS = 'CHECKLIST_COPY_CHECKLIST_TO_LIBRARY_SUCCESS';
export const CHECKLIST_COPY_CHECKLIST_TO_LIBRARY_FAILURE = 'CHECKLIST_COPY_CHECKLIST_TO_LIBRARY_FAILURE';

/* Calendar */
export const CALENDAR_INIT = 'CALENDAR_INIT';
export const CALENDAR_RESET = 'CALENDAR_RESET';
export const CALENDAR_SET_DATES = 'CALENDAR_SET_DATES';
export const CALENDAR_SET_SEARCH_QUERY = 'CALENDAR_SET_SEARCH_QUERY';
export const CALENDAR_SET_PROPERTY_VIEWPORT = 'CALENDAR_SET_PROPERTY_VIEWPORT';
export const CALENDAR_LOAD_EVENTS_REQUEST = 'CALENDAR_LOAD_EVENTS_REQUEST';
export const CALENDAR_LOAD_EVENTS_SUCCESS = 'CALENDAR_LOAD_EVENTS_SUCCESS';
export const CALENDAR_LOAD_EVENTS_FAILURE = 'CALENDAR_LOAD_EVENTS_FAILURE';
export const CALENDAR_LOAD_EVENTS_FAILURE_RESET = 'CALENDAR_LOAD_EVENTS_FAILURE_RESET';
export const CALENDAR_HANDLE_EVENT_BTN_SAGA = 'CALENDAR_HANDLE_EVENT_BTN_SAGA';
export const CALENDAR_EVENT_CHANNEL_STOP_SAGA = 'CALENDAR_EVENT_CHANNEL_STOP_SAGA';
export const CALENDAR_EVENT_CHANNEL_START_SAGA = 'CALENDAR_EVENT_CHANNEL_START_SAGA';
export const CALENDAR_ADD_LIVE_CALENDAR_EVENTS = 'CALENDAR_ADD_LIVE_CALENDAR_EVENTS';
export const CALENDAR_DELETE_LIVE_CALENDAR_EVENTS = 'CALENDAR_DELETE_LIVE_CALENDAR_EVENTS';
export const CALENDAR_SET_BACKDROP = 'CALENDAR_SET_BACKDROP';
export const CALENDAR_SET_MODALDATA = 'CALENDAR_SET_MODALDATA';
export const CALENDAR_SET_EVENT_FILTERS = 'CALENDAR_SET_EVENT_FILTERS';
export const CALENDAR_TOGGLE_CANCELED_EVENTS_FILTER = 'CALENDAR_TOGGLE_CANCELED_EVENTS_FILTER';

/* Reports */
export const REPORTS_SET_SELECTED_ELES = 'REPORTS_SET_SELECTED_ELES';
export const REPORTS_SET_OPEN_SECTION = 'REPORTS_SET_OPEN_SECTION';
export const REPORTS_SET_VALUE_SECTION = 'REPORTS_SET_VALUE_SECTION';
export const REPORTS_SET_TOGGLE_VALUE_SECTION = 'REPORTS_SET_TOGGLE_VALUE_SECTION';
export const REPORTS_DATE_SAGA = 'REPORTS_DATE_SAGA';
export const REPORTS_MERGE_SECTION = 'REPORTS_MERGE_SECTION';
export const REPORTS_SAVE_SAGA = 'REPORTS_SAVE_SAGA';
export const REPORTS_DELETE_SAGA = 'REPORTS_DELETE_SAGA';
export const REPORTS_SET_MODAL = 'REPORTS_SET_MODAL';
export const REPORTS_LOADREPORT_SAGA = 'REPORTS_LOADREPORT_SAGA';
export const REPORTS_SET_LOADED_REPORT = 'REPORTS_SET_LOADED_REPORT';
export const REPORTS_SET_SECTIONS_REPORT = 'REPORTS_SET_SECTIONS_REPORT';
export const REPORTS_LEAVE_SAGA = 'REPORTS_LEAVE_SAGA';
export const REPORTS_LEAVE_HOOK_MODAL = 'REPORTS_LEAVE_HOOK_MODAL';
export const REPORTS_SET_REPORT_DATA = 'REPORTS_SET_REPORT_DATA';
export const REPORTS_SET_LOADING = 'REPORTS_SET_LOADING';
export const REPORTS_PAGINATE = 'REPORTS_PAGINATE';
export const REPORTS_EXPORT_SAGA = 'REPORTS_EXPORT_SAGA';
export const REPORTS_SET_SNAPSHOT = 'REPORTS_SET_SNAPSHOT';
export const REPORTS_SET_SELECTED_ALL = 'REPORTS_SET_SELECTED_ALL';
export const REPORTS_SET_TOUCHED = 'REPORTS_SET_TOUCHED';
export const REPORTS_SET_VALIDATION = 'REPORTS_SET_VALIDATION';
export const REPORTS_SET_ADMINMODE_REPORT_FIELDS = 'REPORTS_SET_ADMINMODE_REPORT_FIELDS';
export const REPORTS_RESET_ADMINMODE_REPORT_FIELDS = 'REPORTS_RESET_ADMINMODE_REPORT_FIELDS';
export const REPORTS_SET_PAYMENT_DETAIL_REPORT_FIELDS = 'REPORTS_SET_PAYMENT_DETAIL_REPORT_FIELDS';

/* Pricing */
export const PRICING_OPEN_MODAL_SAGA = 'PRICING_OPEN_MODAL_SAGA';
export const PRICING_CLOSE_MODAL_SAGA = 'PRICING_CLOSE_MODAL_SAGA';
export const PRICING_SET_MODAL = 'PRICING_SET_MODAL';

/* Todos */
export const TODOS_INIT_SAGA = 'TODOS_INIT_SAGA';
export const TODOS_LOAD_DATA = 'TODOS_LOAD_DATA';
export const TODOS_RESET = 'TODOS_RESET';
export const TODOS_SET_DATA = 'TODOS_SET_DATA';
export const TODOS_HANDLE_CLICK_SAGA = 'TODOS_HANDLE_CLICK_SAGA';
export const TODOS_MERGE_EVENTS = 'TODOS_MERGE_EVENTS';

/* Permissions */
export const PERMISSION_INIT = 'PERMISSION_INIT';
export const PERMISSION_SET_LIST_DATA = 'PERMISSION_SET_LIST_DATA';
export const PERMISSION_MERGE_LIST_DATA = 'PERMISSION_MERGE_LIST_DATA';
export const PERMISSION_HANDLE_ACTION = 'PERMISSION_HANDLE_ACTION';
export const PERMISSION_INIT_MODAL = 'PPERMISSION_INIT_MODAL';
export const PERMISSION_SET_SUBMIT_DATA = 'PERMISSION_SET_SUBMIT_DATA';
export const PERMISSION_RESET_SUBMIT_DATA = 'PERMISSION_RESET_SUBMIT_DATA';

/* ThumbsUpBar */
export const THUMBS_BAR_CREATE_COMMENT = 'THUMBS_BAR_CREATE_COMMENT';
export const THUMBS_BAR_DELETE_COMMENT = 'THUMBS_BAR_DELETE_COMMENT';
export const THUMBS_BAR_UPDATE_COMMENT = 'THUMBS_BAR_UPDATE_COMMENT';
export const THUMBS_BAR_UPDATE_THUMBS = 'THUMBS_BAR_UPDATE_THUMBS';
export const THUMBS_BAR_CREATE_THUMBS = 'THUMBS_BAR_CREATE_THUMBS';
export const THUMBS_BAR_DELETE_THUMBS = 'THUMBS_BAR_DELETE_THUMBS';

// Stripe SP
export const STRIPE_SP_REDIRECT_PROVIDER_SAGA = 'STRIPE_SP_REDIRECT_PROVIDER_SAGA';

// Stripe MP

export const STRIPE_PM_GET_CUSTOMER_SAGA = 'STRIPE_PM_GET_CUSTOMER_SAGA';
export const STRIPE_PM_GET_HOST_STATUS_SAGA = 'STRIPE_PM_GET_HOST_STATUS_SAGA';
export const STRIPE_PM_ADD_CUSTOMER_PAYMENT_METHOD_SAGA = 'STRIPE_PM_ADD_CUSTOMER_PAYMENT_METHOD_SAGA';
export const STRIPE_PM_REMOVE_CUSTOMER_PAYMENT_METHOD_SAGA = 'STRIPE_PM_REMOVE_CUSTOMER_PAYMENT_METHOD_SAGA';
export const STRIPE_PM_ADD_CUSTOMER_SOURCE_SAGA = 'STRIPE_PM_ADD_CUSTOMER_SOURCE_SAGA';
export const STRIPE_PM_REMOVE_CUSTOMER_SOURCE_SAGA = 'STRIPE_PM_REMOVE_CUSTOMER_SOURCE_SAGA';
export const STRIPE_PM_ADD_PLAID_CUSTOMER_SOURCE_SAGA = 'STRIPE_PM_ADD_PLAID_CUSTOMER_SOURCE_SAGA';
export const STRIPE_PM_SET_CUSTOMER = 'STRIPE_PM_SET_CUSTOMER';
export const STRIPE_PM_SET_CUSTOMER_LOADING_STATE = 'STRIPE_PM_SET_CUSTOMER_LOADING_STATE';
export const STRIPE_PM_SET_HOST_STRIPE_STATUS = 'STRIPE_PM_SET_HOST_STRIPE_STATUS';
export const STRIPE_PM_SETUP_INTENT_SAGA = 'STRIPE_PM_SETUP_INTENT_SAGA';
export const STRIPE_PM_SET_MODAL_STATE = 'STRIPE_PM_SET_MODAL_STATE';
export const STRIPE_PM_RESET_MODAL_STATE = 'STRIPE_PM_RESET_MODAL_STATE';
export const STRIPE_PM_SET_INTENT_STATE = 'STRIPE_PM_SET_INTENT_STATE';
export const STRIPE_PM_RESET_INTENT_STATE = 'STRIPE_PM_RESET_INTENT_STATE';
