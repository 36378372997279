import t from '@properly/localization';
import { fixMomentDates, doesJobRequestDurationFit } from '@properly/common';
import { featureFlag } from '@properly/config';
import { fromJS } from 'immutable';
import lodashResult from 'lodash/result';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import mapValues from 'lodash/mapValues';
import values from 'lodash/values';
import moment from 'moment-timezone';
import JobRequestSectionProperty from '../components/JobRequestSectionProperty';
import JobRequestSectionPropertyDetails from '../components/JobRequestSectionPropertyDetails';
import JobRequestSectionTime from '../components/JobRequestSectionTime';
import JobRequestSectionTitle from '../components/JobRequestSectionTitle';
import JobRequestSectionPrice from '../components/JobRequestSectionPrice';
import JobRequestSectionCleaners from '../components/JobRequestSectionCleaners';
import JobRequestSectionChecklist from '../components/JobRequestSectionChecklist';
import JobRequestSectionSkills from '../components/JobRequestSectionSkills';
import JobRequestSectionMessage from '../components/JobRequestSectionMessage';
import {
  selectProperties,
  selectSearchData,
  selectContacts,
  selectJobs,
  selectConfig,
  selectCurrentUserId,
  selectLoadingState,
  selectSkills,
  selectPropertiesByAllowedSendJobs,
  selectIfProMarketPropertyExists,
} from '../../../../selectors/globalSelector';
import {
  selectJobRequestData,
  selectJobRequestCommunity,
  selectJobRequestMode,
  selectJobRequestStartupData,
  selectJobRequestStartTime,
  selectJobRequestEndTime,
  selectJobRequestChecklistViewType,
} from '../state/JobRequestSelectors';
import { propertyInfos, genChecklistSubtitle, genSkillSubtitle } from '../../../../helper/herbert';
import { sendJobRequestModes, mediaUploadLoadingKey } from '../../../../dataConstants';
import { selectSkillsAccess } from '../../library/state/library.selectors';
import { selectIsFeatureFlagEnabledForUser } from '../../settings/state/SettingsSelectors';
import { offeredPriceLimit as defaultOfferedPriceLimit } from '../../../../config';

export const loadingKeyInit = 'jobrequest_init';
export const loadingKeyChecklist = 'jobrequest_checklistloading';
export const loadingKeyMain = 'jobrequest_mainloadingstate';

const memoChecklistLoadingState = selectLoadingState(loadingKeyChecklist);

const isMarketplaceJob = jobRequestData => {
  // check whether job is marketplace job
  const selectedCleaners = jobRequestData.get('cleaners')?.toJS();
  return !!Object.values(selectedCleaners).filter(cleanerDetails => !!cleanerDetails?.getProStatus?.()).length;
};

export const sections = {
  title: {
    title: () => t('job_request.title_type'),
    titleActive: () => t('job_request.write_title'),
    canNext: true,
    optional: true,
    contentTop: JobRequestSectionTitle.content,
    contentMain: JobRequestSectionTitle,
    infoText: ({ jobRequestSendMode }) => {
      if (jobRequestSendMode === sendJobRequestModes.prefill) {
        return t('job_request.title_info_prefill');
      }
      return t('job_request.title_info');
    },
    isEmpty: propState => {
      const title = propState.get('title');
      const jobType = propState.get('jobType');
      return !title && !jobType;
    },
    isValid: () => true,
    mapStateToProps: (state, props) => {
      const res = selectJobRequestData()(state, props);
      return {
        title: res.get('title'),
        jobType: res.get('jobType'),
        defaultTitle: res.get('defaultJobTitle'),
      };
    },
  },
  property: {
    title: () => t('job_request.property'),
    titleActive: () => t('job_request.choose_property'),
    contentTop: JobRequestSectionProperty.content,
    contentMain: JobRequestSectionProperty,
    canNext: true,
    infoText: () => t('job_request.property_info'),
    isValid: propState => !!propState.get('propertyId'),
    isEmpty: propState => !propState.get('propertyId'),
    mapStateToProps: (state, props) => {
      const res = selectJobRequestData()(state, props);
      const properties = selectProperties()(state, props);
      const isMarketPlaceFeatureFlagEnabled = selectIsFeatureFlagEnabledForUser(featureFlag.FEATURE_FLAG_MARKETPLACE)(
        state,
        props,
      );
      const isProMarketPropertyExists = selectIfProMarketPropertyExists()(state, props);
      return {
        config: selectConfig(state, props),
        isMarketPlaceFeatureFlagEnabled: isMarketPlaceFeatureFlagEnabled && isProMarketPropertyExists,
        finalStartUpData: selectJobRequestStartupData()(state, props),
        finalPropertyResolved: properties.get(res.get('propertyId')),
        finalPropertyId: res.get('propertyId'),
        ...propertyInfos(properties.get(res.get('propertyId'))),
        searchData: selectSearchData(
          'properties',
          'jobrequestnew_search',
          selectPropertiesByAllowedSendJobs,
        )(state, props),
      };
    },
  },
  propertyDetails: {
    optional: true,
    nameWidthAuto: true,
    contentTop: JobRequestSectionPropertyDetails.content,
    contentMain: JobRequestSectionPropertyDetails,
    canNext: true,
    infoText: ({ isEmpty }) => {
      if (isEmpty) return t('job_request.property_details_info');
      return t('job_request.property_details_info_filled');
    },
    isValid: () => true,
    isEmpty: (propState, properties) => {
      const resolvedProperty = properties.get(propState.get('propertyId'));
      return !resolvedProperty || genTagsForPropertyDetails(resolvedProperty).length === 0;
    },
    title: () => t('job_request.property_details'),
    titleActive: () => t('job_request.enter_property_details'),
    mapStateToProps: (state, props) => {
      const res = selectJobRequestData()(state, props);
      const properties = selectProperties()(state, props);
      const currentProperty = properties.get(res.get('propertyId'));
      return {
        finalPropertyId: res.get('propertyId'),
        otherAttributes: (currentProperty && currentProperty.otherAttributes) || [],
        propertyDetailTags: genTagsForPropertyDetails(currentProperty).join(', '),
      };
    },
  },
  checklist: {
    title: () => t('job_request.checklist'),
    titleActive: () => t('job_request.choose_checklist'),
    contentTop: JobRequestSectionChecklist.content,
    contentMain: JobRequestSectionChecklist,
    canNext: true,
    isValid: () => true,
    infoText: () => t('job_request.checklist_info'),
    isEmpty: propState => {
      const countChecklists = values(propState.get('checklistId')?.toJS()).length;
      const { tasks } = propState?.toJS();
      const tasksArr = tasks || [];
      return !countChecklists && !tasksArr.length;
    },
    mapStateToProps: (state, props) => {
      const jobRequestData = selectJobRequestData()(state, props);
      const imageUploadErrored = selectLoadingState(mediaUploadLoadingKey)(state)?.isErrored;
      const { tasks } = jobRequestData?.toJS();
      const taskformlist = tasks || [];
      const firstTask = taskformlist.length > 0 ? taskformlist[0] : null;
      const taskTitle = firstTask ? firstTask.title : null;
      const tempTaskImages = jobRequestData.get('tempTaskImages').toJS();
      const taskTitleRedux = jobRequestData.get('taskTitle');
      const taskDescription = jobRequestData.get('taskDescription');

      const finalPropertyId = jobRequestData.get('propertyId');
      const properties = selectProperties()(state, props);
      const allChecklists = selectJobs()(state, props);
      const firstSelectedChecklist = allChecklists.get(jobRequestData.getIn(['checklistId', '0']));
      const currentChecklist =
        firstSelectedChecklist && firstSelectedChecklist.toJS ? firstSelectedChecklist.toJS() : firstSelectedChecklist;
      const checklistTitle = lodashResult(currentChecklist, ['title'], '');
      const checklistSubtitle = genChecklistSubtitle(currentChecklist);

      const checklistViewType = selectJobRequestChecklistViewType(state);

      const isPropertiesActive = checklistViewType === 'properties';
      const isTaskformActive = checklistViewType === 'taskform';
      const searchResult = selectSearchData('jobs', 'jobrequest_searchchecklist', selectJobs)(state, props);
      const { searchQuery, result: searchChecklists } = searchResult || {};

      const propertyChecklists = filter(
        searchChecklists,
        checklist => finalPropertyId && checklist.defaultProperty && checklist.defaultProperty === finalPropertyId,
      );
      const libraryChecklists = filter(
        searchChecklists,
        checklist => checklist.published || checklist.propertyIndependent,
      );

      const currentChecklists = isPropertiesActive ? propertyChecklists : libraryChecklists;

      const checklists = filter(currentChecklists, checklist => checklist.totalTaskCount !== 0 && !checklist.deleted);

      return {
        finalPropertyId,
        finalPropertyResolved: properties.get(jobRequestData.get('propertyId')),
        finalChecklistIds: jobRequestData.get('checklistId'),
        checklistTitle,
        isTaskformActive,
        taskformlist,
        taskTitle,
        isPropertiesActive,
        allChecklists,
        checklists,
        checklistViewType,
        checklistSubtitle,
        loadingStateChecklist: memoChecklistLoadingState(state).isLoadingFull,
        searchQuery,
        tempTaskImages,
        taskTitleRedux,
        taskDescription,
        imageUploadErrored,
      };
    },
  },
  skills: {
    title: () => t('skills.requirements'),
    titleActive: () => t('skills.choose_requirements'),
    contentTop: JobRequestSectionSkills.content,
    contentMain: JobRequestSectionSkills,
    canNext: true,
    isValid: () => true,
    infoText: () => t('skills.skill_info'),
    isEmpty: propState => {
      const countSkills = values(propState.get('skills')?.toJS()).length;
      return !countSkills;
    },
    mapStateToProps: (state, props) => {
      const jobRequestData = selectJobRequestData()(state, props);

      const allSkills = selectSkills()(state, props);
      const firstSelectedSkill = allSkills.get(jobRequestData.getIn(['skills', '0', 'skillId']));
      // TODO: check below current skill is always immutable and simplify below
      const currentSkill = (firstSelectedSkill?.toJS && firstSelectedSkill.toJS()) ?? firstSelectedSkill;
      const skillTitle = lodashResult(currentSkill, ['title'], '');
      const skillSubtitle = genSkillSubtitle(currentSkill);
      const searchResult = selectSearchData('skills', 'jobrequest_searchskills', selectSkills)(state, props);
      const { searchQuery, result: searchSkills } = searchResult || {};

      const filteredSkills = filter(searchSkills, skill => skill);

      return {
        finalSkills: jobRequestData.get('skills'),
        skillTitle,
        allSkills,
        skills: filteredSkills,
        skillSubtitle,
        loadingStateChecklist: memoChecklistLoadingState(state).isLoadingFull,
        searchQuery,
        hasSkillsAccess: selectSkillsAccess(state),
      };
    },
  },
  time: {
    canNext: true,
    title: () => t('job_request.time'),
    titleActive: () => t('job_request.set_time'),
    contentTop: JobRequestSectionTime.content,
    contentMain: JobRequestSectionTime,
    infoText: ({ jobRequestSendMode }) => {
      if (jobRequestSendMode === sendJobRequestModes.prefill) {
        return t('job_request.time_info_prefill');
      }
      return t('job_request.time_info');
    },
    isEmpty: propState => {
      const jobStartTimeType = propState.get('jobStartTimeType');
      const startTime = propState.get('startTime');
      const endTime = propState.get('endTime');
      const duration = propState.get('duration');
      return jobStartTimeType === 'fixed' ? !startTime || !duration : !startTime || !endTime || !duration;
    },
    isValid: (propState, properties, meta) => {
      const property = properties.get(propState.get('propertyId'));

      const startTime = propState.get('startTime') ? fixMomentDates(propState.get('startTime')) : undefined;
      const endTime = propState.get('endTime') ? fixMomentDates(propState.get('endTime')) : undefined;

      const jobStartTimeType = propState.get('jobStartTimeType');
      const duration = propState.get('duration');
      const nowTime = new Date();
      let timeex = moment(startTime).isAfter(nowTime);

      if (property) {
        const selectedTime = moment.tz(startTime, property.getTimeZone());

        // Start time should be after current time
        timeex = moment(selectedTime).isAfter(nowTime);
      }
      // exception for edit or send more
      if (meta.get('mode') === sendJobRequestModes.prefill) {
        return !!(duration && startTime);
      }

      // TODO: Assign 'jobStartTimeType' value to a constant in 'packages/config'
      if (jobStartTimeType === 'REGULAR' || jobStartTimeType === 'fixed') {
        return !!(duration && startTime && timeex);
      }

      // End time is not guarunteed to be populated
      const timeex2 = endTime && moment(endTime).isAfter(startTime);

      const timeDetails = { startTime, endTime, nowTime, duration };

      // Only for 'FLEXIBLE' jobStartTimeType, the 'startTime' can be in the past taking into account that the 'endTime' is ahead of current date i.e in future
      if ((jobStartTimeType === 'FLEXIBLE' || jobStartTimeType === 'flexible') && property) {
        // End time should be after current time *** update -- changed the logic, now the start time should also be after current time
        const selectedStartTime = moment.tz(startTime, property.getTimeZone());
        const selectedEndTime = moment.tz(endTime, property.getTimeZone());
        timeex = moment(selectedStartTime).isAfter(nowTime) && moment(selectedEndTime).isAfter(nowTime);
      }
      return duration && startTime && timeex && timeex2 && doesJobRequestDurationFit(timeDetails);
    },
    mapStateToProps: (state, props) => {
      const res = selectJobRequestData()(state, props);
      const properties = selectProperties()(state, props);
      const resolvedProperty = properties.get(res.get('propertyId'));
      const finalMode = selectJobRequestMode(state);

      const startTime = selectJobRequestStartTime(state, props);
      const endTime = selectJobRequestEndTime(state, props);

      return {
        resolvedProperty,
        finalMode,
        finalDuration: res.get('duration'),
        finalStartTime: startTime,
        finalEndTime: endTime,
        jobStartTimeType: res.get('jobStartTimeType'),
      };
    },
  },
  cleaners: {
    title: () => t('job_request.cleaners'),
    titleActive: () => t('job_request.select_cleaners'),
    canNext: true,
    contentTop: JobRequestSectionCleaners.content,
    contentMain: JobRequestSectionCleaners,
    isValid: (propState, _, meta) => {
      const mode = meta.get('mode');
      const cleanersLength = propState.get('cleaners').size;
      const cleanersJS = propState.get('cleaners')?.toJS();
      const cleanersArray = values(cleanersJS);
      if (mode === sendJobRequestModes.sendmore) {
        return !!cleanersLength && !cleanersArray.every(cleaner => cleaner.isDisabled);
      }
      return !!cleanersLength;
    },
    infoText: ({ jobRequestSendMode }) => isCommunity => {
      if (jobRequestSendMode === sendJobRequestModes.prefill) {
        return t('job_request.cleaners_info_prefill');
      }
      if (isCommunity) {
        return t('job_request.cleaners_info_community');
      }
      return t('job_request.cleaners_info_normal');
    },
    isEmpty: propState => propState.get('cleaners').size === 0,
    mapStateToProps: (state, props) => {
      const res = selectJobRequestData()(state, props);
      const properties = selectProperties()(state, props);
      const finalPropertyResolved = properties.get(res.get('propertyId'));
      const isMarketPlaceFeatureFlagEnabled = selectIsFeatureFlagEnabledForUser(featureFlag.FEATURE_FLAG_MARKETPLACE)(
        state,
        props,
      );
      // Ensure a user cannot send a cleaner to a sample property
      const isSampleProperty = (finalPropertyResolved && !!finalPropertyResolved.sample) || false;
      const communityCleaners = selectJobRequestCommunity()(state, props);
      const emptyCommunityCleaners = fromJS([]);

      return {
        proMarketProperty: !!(isMarketPlaceFeatureFlagEnabled && finalPropertyResolved?.proMarketProperty) || false,
        finalMode: selectJobRequestMode(state),
        finalProperty: finalPropertyResolved,
        config: selectConfig(state, props),
        finalPrice: res.get('price'),
        communityCleaners: isSampleProperty ? emptyCommunityCleaners : communityCleaners,
        contacts: selectContacts()(state, props),
        currentUserId: selectCurrentUserId()(state, props),
        finalCleaners: res.get('cleaners')?.toJS(),
        searchData: selectSearchData('contacts', 'jobrequestnew_searchcleaners', selectContacts)(state, props),
      };
    },
  },
  price: {
    canNext: true,
    nameWidthAuto: true,
    title: () => t('job_request.offered_price'),
    titleActive: () => t('job_request.offered_price_op'),
    contentTop: JobRequestSectionPrice.content,
    contentMain: JobRequestSectionPrice,
    // toggle for offered price mandatory
    isValid: (propState, properties) => {
      const finalPropertyResolved = properties.get(propState.get('propertyId'));
      const proMarketProperty = !!(finalPropertyResolved && finalPropertyResolved.proMarketProperty) || false;
      if (proMarketProperty) {
        const price = propState.get('price');
        return !(isMarketplaceJob(propState) && !price);
      }
      return true;
    },
    infoText: ({ jobRequestSendMode, properties, jobRequestData }) => {
      const finalPropertyResolved = properties.get(jobRequestData.get('propertyId'));
      const proMarketProperty = !!(finalPropertyResolved && finalPropertyResolved.proMarketProperty) || false;
      if (jobRequestSendMode === sendJobRequestModes.prefill) {
        return t('job_request.offered_price_info_prefill');
      }
      if (proMarketProperty && isMarketplaceJob(jobRequestData)) return t('job_request.offered_price_pro_info');
      return t('job_request.offered_price_info');
    },
    alsoHightLight: {
      message: true,
    },
    isEmpty: propState => {
      const price = propState.get('price');
      const currency = propState.get('currency');
      return !price || price === 0 || !currency;
    },
    mapStateToProps: (state, props) => {
      const res = selectJobRequestData()(state, props);
      const properties = selectProperties()(state, props);
      const finalPropertyResolved = properties.get(res.get('propertyId'));
      const finalCleanersResolved = res.get('cleaners')?.toJS();
      const isMarketPlaceFeatureFlagEnabled = selectIsFeatureFlagEnabledForUser(featureFlag.FEATURE_FLAG_MARKETPLACE)(
        state,
        props,
      );
      const config = selectConfig(state, props);
      const { offeredPriceLimitByCountryCode, suggestedCleanerCountries } = config;
      let offeredPriceLimit = defaultOfferedPriceLimit;
      if (suggestedCleanerCountries?.includes(finalPropertyResolved?.countryCode) && offeredPriceLimitByCountryCode) {
        offeredPriceLimit =
          offeredPriceLimitByCountryCode[finalPropertyResolved?.countryCode] || offeredPriceLimitByCountryCode.DEFAULT;
      }

      return {
        finalMode: selectJobRequestMode(state),
        finalProperty: finalPropertyResolved,
        finalCurrency: res.get('currency'),
        finalPrice: res.get('price'),
        finalCleaners: finalCleanersResolved,
        isMarketPlaceFeatureFlagEnabled,
        offeredPriceLimit,
      };
    },
  },

  message: {
    title: () => t('job_request.description'),
    titleActive: () => t('job_request.write_description'),
    canNext: true,
    optional: true,
    contentTop: JobRequestSectionMessage.content,
    contentMain: JobRequestSectionMessage,
    infoText: ({ jobRequestSendMode }) => {
      if (jobRequestSendMode === sendJobRequestModes.prefill) {
        return t('job_request.message_info_prefill');
      }
      return t('job_request.message_info');
    },
    isEmpty: propState => {
      const msg = propState.get('message');
      return !msg || msg === '';
    },
    isValid: () => true,
    mapStateToProps: (state, props) => {
      const res = selectJobRequestData()(state, props);
      return {
        finalMessage: res.get('message'),
      };
    },
  },
};

function genTagsForPropertyDetails(propertyResolved) {
  const options = [];
  if (!propertyResolved) return options;
  if (propertyResolved.detailsAccess) options.push(t('properties.access'));
  if (propertyResolved.detailsGarbage) options.push(t('properties.garbage'));
  if (propertyResolved.detailsParking) options.push(t('properties.parking'));
  if (propertyResolved.detailsWifiName) options.push(t('properties.wifi'));
  if (propertyResolved.detailsInformation) {
    options.push(t('properties.important_note'));
  }
  return options;
}

export const sectionsWithId = mapValues(sections, (section, key) => ({
  ...section,
  id: key,
}));

export const sectionsArray = [
  sectionsWithId.title,
  sectionsWithId.property,
  sectionsWithId.propertyDetails,
  sectionsWithId.checklist,
  sectionsWithId.skills,
  sectionsWithId.time,
  sectionsWithId.cleaners,
  sectionsWithId.price,
  sectionsWithId.message,
];

export const sectionsByIndex = reduce(sectionsArray, (acc, val, index) => ({ ...acc, [val.id]: index }), {});
