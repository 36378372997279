import React, { Component } from 'react';
import t from '@properly/localization';
import { Input, SpaceBase, SpaceMedium, Regular, Button } from '@properly/components';
import { isValidEmail, isValidPhone } from '@properly/common';
import { resetPassword } from '../desktopApp/data';
import LoginWrapContainer from './LoginWrapContainer';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { username: '', showErrors: false, state: 0 };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ username: event.target.value, state: 0 });
  }

  genToastMessage(input) {
    if (isValidPhone(input)) return t('resetpassword.text_message', { number: input });
    return t('resetpassword.email_message', { email: input });
  }

  handleSubmit(event) {
    event.preventDefault();
    const showErrors = false;
    const state = 0;
    const that = this;
    if (!this.isValid(this.state.username)) {
      this.setState({ showErrors: true, state });
    } else {
      resetPassword(this.state.username)
        .then(() => {
          that.setState({ showErrors, state: 1 });
          setTimeout(that.hideToast.bind(this), 4200);
        })
        .catch(() => {
          that.setState({ showErrors, state: 2 });
        });
    }
  }

  isValid(input) {
    if (isValidEmail(input)) {
      return true;
    }
    if (isValidPhone(input)) {
      return true;
    }
    return false;
  }

  hideToast() {
    this.setState({ state: 0 });
  }

  render() {
    const isValid = this.isValid(this.state.username);
    let error = !isValid && this.state.showErrors;
    if (this.state.state === 2) error = t('resetpassword.error_msg', { username: this.state.username });
    return (
      <LoginWrapContainer
        center
        withMaxWidth
        withSubHeadline
        showToast={this.state.state === 1}
        message={this.genToastMessage(this.state.username)}
        title={t('login.reset', {})}
      >
        <Regular>{t('login.enter_email', {})}</Regular>
        <SpaceMedium />
        <form onSubmit={this.handleSubmit}>
          <Input
            data-key="email-for-forgot-password"
            isFirst
            isLast
            error={error}
            showErrorMsg={error && error.length}
            placeholder={t('login.email_phone', {})}
            type="text"
            onChange={this.handleChange}
          />
          <SpaceBase />
          <Button
            data-key="forgot-password-button"
            fakeDisable={!isValid}
            onClick={this.handleSubmit}
            types={['type-full-primary', 'width-flex', 'size-large']}
          >
            {t('login.submit', {})}
          </Button>
        </form>
      </LoginWrapContainer>
    );
  }
}

export default ResetPassword;
