import React, { Component } from 'react';
import PropTypes from 'prop-types';
import log from 'loglevel';
import { graphql } from 'react-apollo';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import { CalendarFilter } from '@properly/components';
import { setEventFilters, toggleCanceledEventsFilter } from '../state/CalendarActions';
import * as selectors from '../state/CalendarSelectors';
import { GetReminderOptions, GetCalendarFilterSchema } from '../state/CalendarQuery';
import { client as apolloClient } from '../../../../config/apollo';
import { trackCalendarFilterSave } from '../../../../actions/trackingEvents';

export const CALENDAR_FILTER_FORM_NAME = 'calendarFilter';

class CalendarFilterContainer extends Component {
  static propTypes = {
    allDates: PropTypes.shape({}).isRequired,
    onHeightChange: PropTypes.func,
  };

  static defaultProps = {
    onHeightChange: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      currentHash: this.getFilterHash(this.props.initFilter),
    };
  }

  getFilterHash = values =>
    (values || [])
      .filter(({ id, operator }) => !!id && !!operator)
      .map(({ id, operator, value }) => `${id}:${operator}:${String(value)}`)
      .join('-') || null;

  getGraphqlPrefetchAutocomplete = ({ graphqlSchema, graphqlKey }) => {
    log.info('getGraphqlPrefetchAutocomplete', { graphqlSchema, graphqlKey });

    return apolloClient
      .query({
        query: gql(graphqlSchema),
        fetchPolicy: 'no-cache',
      })
      .then(({ error, data }) => {
        if (error) {
          log.error('Error getGraphqlPrefetchAutocomplete', error);
          return [];
        }
        return data[graphqlKey] || [];
      })
      .then(options => (options || []).map(({ label }) => ({ label, value: label })));
  };

  handleSave = ({ filterName, filters }) => {
    trackCalendarFilterSave(filterName, filters);
  };

  handleChange = values => {
    log.info('calendar filter container handleChange', values);

    const currentHash = this.getFilterHash(values);
    const hasChanged = currentHash !== this.state.currentHash;
    if (hasChanged) {
      this.setState({ currentHash });
      this.props.actions.setEventFilters(values);
    }
  };

  render() {
    const { currentUserId, initFilter, reminderOptions, calendarFilterSchema, onHeightChange } = this.props;

    return (
      <CalendarFilter
        onSave={this.handleSave}
        onHeightChange={onHeightChange}
        filterSchema={calendarFilterSchema}
        reminderOptions={reminderOptions}
        initFilter={initFilter}
        onChange={this.handleChange}
        currentUserId={currentUserId}
        getGraphqlAutocomplete={this.getGraphqlPrefetchAutocomplete}
      />
    );
  }
}

const selectAllDates = state => {
  const dates = selectors.selectDates(state);
  return dates && dates.toJS();
};

const selectFilters = state => {
  const filters = selectors.selectFilters(state);
  return (filters && filters.toJS()) || [];
};

function mapStateToProps() {
  const selectContacts = selectors.selectContacts();
  const selectProperties = selectors.selectProperties();
  const selectCurrentUserId = selectors.selectCurrentUserId();
  return state => ({
    currentUserId: selectCurrentUserId(state),
    initFilter: selectFilters(state),
    allDates: selectAllDates(state),
    properties: selectProperties(state),
    propertiesFilter: selectors.selectPropertiesSearch(state),
    serviceProviders: selectContacts(state),
    serviceProvidersFilter: selectors.selectContactsSearch(state),
  });
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ toggleCanceledEventsFilter, setEventFilters }, dispatch),
});

export default compose(
  graphql(GetReminderOptions, {
    props: ({ data }) => {
      const { reminderOptions } = data;
      return { reminderOptions };
    },
  }),
  graphql(GetCalendarFilterSchema, {
    props: ({ data }) => {
      const { calendarFilterSchema } = data;
      return { calendarFilterSchema };
    },
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(CalendarFilterContainer);
