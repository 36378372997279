import React, { Component } from 'react';
import { connect } from 'react-redux';
import t from '@properly/localization';
import ErrorComponent from '../../components/ErrorComponent';

class NotFound extends Component {
  componentDidMount() {}

  render() {
    return <ErrorComponent is404 headline={t('error.404error')} subHeadline={t('error.looks_like_smth_wrong')} />;
  }
}

export default connect(
  null,
  {},
)(NotFound);
