import log from 'loglevel';
import { push } from 'react-router-redux';
import * as types from '../../../../types/index';
import { ROUTES } from '../../../../paths';
import { resetNotificationStorage } from '../../../../helper/notification';
import { helpCenterUrl } from '../../../../config';
import { trackLogout, trackPartnerReimport } from '../../../../actions/trackingEvents';
import { startPreloadSaga } from '../../../../actions/globalActions';

export function goToAccount() {
  return push(ROUTES.account);
}

export function goToConnectionEdit(partner, id, connectionIssue, route) {
  if (connectionIssue) {
    return push({
      pathname: route ? `/${route}/${partner}/${id}` : ROUTES.settingsConnectionsEdit(partner, id),
      query: {
        connectionIssue: '1',
      },
    });
  }
  return push(route ? `/${route}/${partner}/${id}` : ROUTES.settingsConnectionsEdit(partner, id));
}

export function goToAccountConntections() {
  return push(ROUTES.settingsConnections);
}

export function goToAccountConnectionsMobile() {
  return push(ROUTES.accountConnectionsMobile);
}

export function goToAccountNotifications() {
  return push(ROUTES.accountNotifications);
}

export function goToAccountPassword() {
  return push(ROUTES.accountPassword);
}

export function goToLogin() {
  return push({
    pathname: '/login',
    query: {
      loggedOut: '1',
    },
    state: { isApproved: true },
  });
}

export function snapShotSetUser(user) {
  return {
    type: types.ACCOUNT_SNAP_USER_SET,
    user,
  };
}

export function uploadProfilePic(image) {
  return {
    type: types.ACCOUNT_UPLOAD_PROFILE_PIC,
    image,
  };
}

export function disconnectPartnerSaga(partner, partnerUserId) {
  return {
    type: types.ACCOUNT_DISCONNECT,
    partner,
    partnerUserId,
  };
}

export function sendPwSaga() {
  return {
    type: types.ACCOUNT_SEND_PW_SAGA,
  };
}

export function setState(where, val) {
  return {
    type: types.ACCOUNT_SET_STATE,
    where,
    val,
  };
}

export function closeErrorModal() {
  return setState('oauth', {});
}

export function triggerSnapShotUser() {
  return {
    type: types.ACCOUNT_SNAP_USER,
  };
}

export function updateTmpUser(changeSet) {
  return {
    type: types.ACCOUNT_UPDATE_TMP_USER,
    changeSet,
  };
}

export function updateUserSaga(user) {
  return {
    type: types.ACCOUNT_UPDATE_USER_SAGA,
    user,
  };
}

export function phoneVerifySaga(code) {
  return {
    type: types.ACCOUNT_PHONE_VERIFY_SAGA,
    code,
  };
}

export function oAuthSaga(code, partnerId, updateHandler, source) {
  return {
    type: types.ACCOUNT_OAUTH_SAGA,
    code,
    partnerId,
    updateHandler,
    source,
  };
}

export function setUpdateUserState(val) {
  return {
    type: types.ACCOUNT_SET_UPDATE_USER_STATE,
    val,
  };
}

export function setPasswordStore(changeSet) {
  return {
    type: types.ACCOUNT_SET_PASSWORD_STORE,
    changeSet,
  };
}

export function runChangePw(oldPassword, newPassword) {
  return {
    type: types.ACCOUNT_CHANGE_PW_SAGA,
    oldPassword,
    newPassword,
  };
}

export function goToAccountHelp() {
  window.open(helpCenterUrl);
}

export function oAuthSwitch(infoObj) {
  if (infoObj.done) {
    return dispatch => {
      dispatch(setState('oauth', {}));
      dispatch(startPreloadSaga('accountreimport'));
    };
  }
  if (infoObj.error || infoObj.step) {
    log.warn('oAuthSwitch', infoObj);
    return setState('oauth', infoObj);
  }
  return setState('oauth', {});
}

export function closeOAuthModalAndGoBack() {
  return {
    type: types.ACCOUNT_CLOSE_MODAL_BACK_SAGA,
  };
}
export function oAuthSwitchFull(infoObj) {
  if (infoObj.done) {
    return dispatch => {
      dispatch(closeOAuthModalAndGoBack());
      dispatch(startPreloadSaga('accountconnect'));
    };
  }
  if (infoObj.error || infoObj.step) {
    log.warn('oAuthSwitchFull', infoObj);
    return setState('oauth', infoObj);
  }
  return setState('oauth', {});
}

export function reImportProperties(partnerId, userId, updateHandler) {
  trackPartnerReimport();
  return {
    type: types.ACCOUNT_REIMPORT,
    partnerId,
    userId,
    updateHandler,
  };
}

export function accountImportSuccess() {
  return {
    type: types.ACCOUNT_IMPORT_SUCCESS,
  };
}

export function logout() {
  trackLogout();
  resetNotificationStorage();
  return {
    type: types.ACCOUNT_LOGOUT,
  };
}

export function setWarningModal(data, reset) {
  return {
    type: types.ACCOUNT_SET_WARNING_MODAL,
    data,
    reset,
  };
}

export function redirectToProviderSaga(data) {
  return {
    type: types.ACCOUNT_REDIRECT_PROVIDER_SAGA,
    data,
  };
}

export function connectDirectToPartnerSaga(redirectPartnerId, reconnectId) {
  return {
    type: types.ACCOUNT_CONNECT_DIRECT_TO_PARTNER_SAGA,
    redirectPartnerId,
    reconnectId,
  };
}

export function redirectToMobileSaga(
  status,
  externalMobileBrowserConnected,
  onboardingMobile,
  connectionSuccess,
  delayedRedirect,
) {
  return {
    type: types.ACCOUNT_REDIRECT_TO_MOBILE_SAGA,
    status,
    externalMobileBrowserConnected,
    onboardingMobile,
    connectionSuccess,
    delayedRedirect,
  };
}

export function redirectToProviderLegacySaga(data) {
  return {
    type: types.ACCOUNT_REDIRECT_PROVIDER_LEGACY_SAGA,
    data,
  };
}

export const getConnectedAccountRequest = () => ({
  type: types.ACCOUNT_GET_CONNECTED_ACCOUNT_REQUEST,
});

export const leaveAccountConnectionsRequest = () => ({
  type: types.ACCOUNT_LEAVE_ACCOUNT_CONNECTIONS,
});

export const getConnectedAccountSuccess = (connectedAccounts, connectionSettingsCount) => ({
  type: types.ACCOUNT_GET_CONNECTED_ACCOUNT_SUCCESS,
  connectedAccounts,
  connectionSettingsCount,
});

export const getConnectedAccountFailure = error => ({
  type: types.ACCOUNT_GET_CONNECTED_ACCOUNT_FAILURE,
  error,
});

export const disconnectConnectedAccountRequest = (id, mobile) => ({
  type: types.ACCOUNT_DISCONNECT_CONNECTED_ACCOUNT_REQUEST,
  id,
  mobile,
});
export const disconnectConnectedAccountSuccess = id => ({
  type: types.ACCOUNT_DISCONNECT_CONNECTED_ACCOUNT_SUCCESS,
  id,
});
export const disconnectConnectedAccountFailure = (id, error) => ({
  type: types.ACCOUNT_DISCONNECT_CONNECTED_ACCOUNT_FAILURE,
  error,
});
export const dismissDisconnectError = () => ({
  type: types.ACCOUNT_DISMISS_DISCONNECT_ERROR,
});

export const syncConnectedAccountRequest = (id, partner) => ({
  type: types.ACCOUNT_SYNC_CONNECTED_ACCOUNT_REQUEST,
  id,
  partner,
});
export const syncConnectedAccountSuccess = id => ({
  type: types.ACCOUNT_SYNC_CONNECTED_ACCOUNT_SUCCESS,
  id,
});
export const syncConnectedAccountFailure = (id, error) => ({
  type: types.ACCOUNT_SYNC_CONNECTED_ACCOUNT_FAILURE,
  error,
});
export const dismissSyncError = () => ({
  type: types.ACCOUNT_DISMISS_SYNC_ERROR,
});
