import React, { Component } from 'react';
import PropTypes from 'prop-types';

import each from 'lodash/each';
import range from 'lodash/range';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import styles from '../components/checklistsStyles.module.css';
import { generateToken } from '../../data';
import { stepMutatorSaga, taskMutatorSaga } from '../state/ChecklistActions';
import ChecklistTopBar from '../components/ChecklistTopBar';
import ChecklistImageListItem from '../../../../components/ChecklistImageListItem/index';
import ChecklistDragTextItem from '../components/ChecklistDragTextItem';

const cx = classNames.bind(styles);

class ChecklistTextList extends Component {
  constructor(props) {
    super(props);
    this.state = { newId: generateToken(10) };
  }

  onDelete = () => {
    this.props.stepMutatorSaga('deleteStep', {
      jobInstructionId: this.props.jobInstructionId,
      deleteSteps: {
        [this.props.step.objectId]: true,
      },
    });
  };

  onDuplicate = () => {
    this.props.stepMutatorSaga('duplicateStep', {
      jobInstructionId: this.props.jobInstructionId,
      steps: [this.props.step.objectId].slice(0),
      index: this.props.index,
    });
  };

  onBlurTitle = e => {
    this.props.stepMutatorSaga('update', {
      jobInstructionId: this.props.jobInstructionId,
      stepId: `${this.props.step.objectId}`,
      changeSet: {
        title: e.target.value,
      },
    });
  };

  onDeleteTask = index => {
    this.props.taskMutatorSaga('delete', this.props.jobInstructionId, this.props.step.objectId, {
      index,
    });
  };

  onMoveTask = (fromIndex, toIndex) => {
    this.props.taskMutatorSaga('moveStep', this.props.jobInstructionId, this.props.step.objectId, {
      fromIndex,
      toIndex,
    });
  };

  addOrUpdate = (isEnter, objectId, index, val) => {
    if (isEnter) {
      if (val && val.length < 2) return;
      this.props.taskMutatorSaga('add', this.props.jobInstructionId, this.props.step.objectId, {
        index,
        changeSet: {
          objectId,
          icon: 'other',
          note: val,
        },
      });
      this.setState({ newId: generateToken(10) });
    } else {
      if (!val) {
        this.onDeleteTask(index);
        return;
      }
      this.props.taskMutatorSaga('update', this.props.jobInstructionId, this.props.step.objectId, {
        index,
        changeSet: {
          note: val,
        },
      });
    }
  };

  renderTasksText(tasks, stepId, newId, isReadOnly) {
    const enterPart = {
      _enter: true,
      objectId: newId,
    };

    const clonedArray = [...tasks.slice(0), enterPart];
    const minElements = 5;
    const padding = Math.max(minElements - clonedArray.length, 0);

    // check if needs padding
    if (clonedArray.length <= minElements) {
      each(range(padding), () => {
        clonedArray.push({ _empty: true });
      });
    }

    return (
      <div>
        {map(clonedArray, (task, key) => {
          const realIndex = findIndex(tasks, { objectId: task.objectId });

          const isTaskEmpty = task._empty; // eslint-disable-line
          const isTaskEnter = task._enter; // eslint-disable-line

          if (isTaskEmpty) {
            return (
              <div key={key + stepId + task.objectId}>
                <ChecklistImageListItem iconBg="white" isEmpty={isTaskEmpty} iconImage="other" />
              </div>
            );
          }
          if (isTaskEnter && isReadOnly) {
            return null;
          }
          return (
            <ChecklistDragTextItem
              type={`step${stepId}`}
              placeholderType="text"
              isEmpty={isTaskEmpty}
              isEnter={isTaskEnter}
              isReadOnly={isReadOnly}
              dragDisable={!(isTaskEmpty || isTaskEnter)}
              addOrUpdate={e => this.addOrUpdate(isTaskEnter, task.objectId, realIndex, e.target.value)}
              onDeleteTask={() => this.onDeleteTask(realIndex)}
              key={key + stepId + task.objectId}
              id={key + stepId + task.objectId}
              defaultValue={isTaskEnter ? undefined : task.note}
              moveCard={this.onMoveTask}
              index={realIndex}
            />
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <div data-key={this.props.step.tasks.length === 0 ? 'blank-text-slide' : 'text-template'}>
        <ChecklistTopBar
          isReadOnly={this.props.isReadOnly}
          title={this.props.step.title}
          onDelete={this.onDelete}
          onDuplicate={this.onDuplicate}
          onBlur={this.onBlurTitle}
        />
        <div className={cx('checklist__tasks')}>
          {this.renderTasksText(
            this.props.step.tasks,
            this.props.step.objectId,
            this.state.newId,
            this.props.isReadOnly,
          )}
        </div>
      </div>
    );
  }
}

ChecklistTextList.propTypes = {
  jobInstructionId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  step: PropTypes.shape({}).isRequired,
  isReadOnly: PropTypes.bool,

  // connect
  stepMutatorSaga: PropTypes.func.isRequired,
  taskMutatorSaga: PropTypes.func.isRequired,
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {
  stepMutatorSaga,
  taskMutatorSaga,
})(ChecklistTextList);
