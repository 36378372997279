import reduce from 'lodash/reduce';
import map from 'lodash/map';
import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import { createSelector } from 'reselect';
import * as selectorsGlobal from '../../../../selectors/globalSelector';

export const selectJobDetails = () => state => state.jobDetails;
export const selectJobDetailsJS = () => state => state.jobDetails?.toJS();

export const selectTempMilesImages = () =>
  createSelector(selectJobDetails(), jobDetails => jobDetails.get('tempMilesImages'));

export const selectTempDurationImages = () =>
  createSelector(selectJobDetails(), jobDetails => jobDetails.get('tempDurationImages'));

export const selectTempCostImages = () =>
  createSelector(selectJobDetails(), jobDetails => jobDetails.get('tempCostImages'));

export const selectSourceJobProblems = () =>
  createSelector(selectJobDetails(), jobDetails => jobDetails.get('sourceJobProblems'));

export const selectStripePaymentDetails = () =>
  createSelector(selectJobDetails(), jobDetails => jobDetails.get('stripePaymentDetails'));

export const selectResolvedProblem = () =>
  createSelector(selectJobDetails(), jobDetails => jobDetails.get('resolvedProblem'));

export const selectComments = () => createSelector(selectJobDetails(), jobDetails => jobDetails.get('comments'));
export const commentLoader = () => createSelector(selectJobDetails(), jobDetails => jobDetails.get('commentLoader'));

export const selectChecklistsVerificationPhotos = () =>
  createSelector(selectJobDetails(), jobDetails => jobDetails.get('checklistsVerificationPhotos'));

export const selectVerificationPhotos = () =>
  createSelector(selectChecklistsVerificationPhotos(), checklistsVerificationPhotos => {
    // flatten our array of checklists containing arrays of verification photos
    // to a single array of all verification photos.
    const verificationPhotos = checklistsVerificationPhotos
      .toJS()
      .reduce((acc, checklistsVerificationPhoto) => [...acc, ...checklistsVerificationPhoto.verificationPhotos], []);

    return verificationPhotos;
  });

export const selectedJobRequest = () =>
  createSelector(
    selectorsGlobal.selectJobRequests(),
    (state, props) => props,
    (jobRequests, props) => {
      const oneJobRequest = jobRequests.get(props.jobRequestId);
      if (!oneJobRequest) return {}; // kill early
      const cleaners = map(oneJobRequest.requestedCleaners, cleaner => {
        const hostData = cleaner.userData;
        return {
          request: cleaner,
          contact: hostData && { userData: hostData },
        };
      });

      // only allow requests with a contact (play it safe)
      const mapped = filter(cleaners, val => val.contact);

      return {
        jobRequest: oneJobRequest,
        mappedCleaners: mapped,
      };
    },
  );

export const selectPhotoFeedbackStats = () =>
  createSelector(
    selectorsGlobal.selectPhotoFeedbackByJobRequest(),
    (state, props) => selectorsGlobal.selectVericationPhotosOfJobRequest(props.jobRequestId)(state),
    (_, props) => props.jobRequestId,
    (res, verificationPhotos, jobRequestId) => {
      const defaults = {
        commentsTotal: 0,
        commentsViewed: 0,
        thumbsUp: 0,
        thumbsDown: 0,
      };

      // Extract identifiers of verification photos from JRE
      const pictureIdentifiers = flatten(verificationPhotos)?.map(({ pictureIdentifier = '' }) => pictureIdentifier);

      if (!jobRequestId) return defaults;
      return reduce(
        res?.toJS(),
        (acc, i) => {
          // Need to check this for retaken verrification photos. Since if retaken, the old feedback does not get deleted in db.
          const isVerificationPhotoInJRE = pictureIdentifiers?.includes(i.pictureUrl);

          if (i.isComment) {
            acc.commentsTotal += 1;
          }

          if (i.isComment && i.isViewed) {
            acc.commentsViewed += 1;
          }

          // kill early
          if (!isVerificationPhotoInJRE) return acc;

          if (i.isThumbsUp) {
            acc.thumbsUp += 1;
          }

          if (i.isThumbsDown) {
            acc.thumbsDown += 1;
          }

          return acc;
        },
        defaults,
      );
    },
  );
