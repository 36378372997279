import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import values from 'lodash/values';
import { Icon } from '@properly/components';
import classNames from 'classnames/bind';
import styles from './index.module.css';
import ProBadge from '../ProBadge';

const cx = classNames.bind(styles);

const renderTag = (remove, item, key) => {
  const dontBubble = func => e => {
    e.stopPropagation();
    func(e);
  };

  return (
    <div
      key={key}
      className={cx('tag', {
        'tag--primary': !item.isCommunity,
        'tag--blue': item.isCommunity,
      })}
    >
      <div className={cx('tag__inner')}>
        <div data-key="cleaner-name">{`${item.cleaner.firstName} ${item.cleaner.lastName}`}</div>
        <div onClick={dontBubble(remove.bind(null, item.contactId))} className={cx('tag__icon')}>
          {!item.isCommunity ? <Icon.IcSmCloseOrange /> : <Icon.IcSmCloseBlue />}
        </div>
      </div>
    </div>
  );
};

const renderTagSimple = (countAll, item, key) => {
  const isLast = key === countAll - 1;
  return (
    <div
      key={key}
      className={cx('tag-simple', {
        'tag-simple--primary': !item.isCommunity,
        'tag-simple--blue': item.isCommunity,
      })}
    >
      <div className={cx('tag__inner')}>
        <div className={cx('tag__align-space')}>
          {`${item.cleaner.firstName} ${item.cleaner.lastName}`}
          {!isLast && ','}
        </div>
      </div>
      {item.proMarketProperty && !!item?.rawCleaner?.getProStatus?.() && <ProBadge withTooltip />}
    </div>
  );
};

const MaybeWrap = ({ children, wrapperComponent, data }) => {
  if (wrapperComponent) {
    const Wrap = wrapperComponent;
    return <Wrap leftOverCleaners={data}>{children}</Wrap>;
  }
  return children;
};

const CleanerListTags = ({ items, onRemove, limit, type, wrapperComponent, children }) => {
  const itemsJS = (items?.toJS && items.toJS()) ?? items;
  const finalCleaners = limit ? values(itemsJS).slice(0, limit) : values(itemsJS);
  const overLimitCleaners = values(itemsJS).slice(limit);
  const numberOfSelectedItems = itemsJS.length;
  const overLimit = limit && numberOfSelectedItems > limit;
  const isSimple = type === 'simple';
  const func = isSimple ? renderTagSimple.bind(null, finalCleaners.length) : renderTag.bind(null, onRemove);
  return (
    <div
      className={cx('tags', {
        'tags--hasmore': overLimit && isSimple,
      })}
    >
      {!children && map(finalCleaners, func)}
      {children && children}
      {overLimit && (
        <span
          className={cx('red', {
            'red--right': isSimple,
          })}
        >
          <MaybeWrap data={overLimitCleaners} wrapperComponent={wrapperComponent}>
            +{numberOfSelectedItems - limit}
          </MaybeWrap>
        </span>
      )}
    </div>
  );
};

CleanerListTags.propTypes = {
  onRemove: PropTypes.func,
  limit: PropTypes.number,
  type: PropTypes.oneOf(['simple']),
};

export default CleanerListTags;
