import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import invert from 'lodash/invert';
import keys from 'lodash/keys';
import chunk from 'lodash/chunk';
import {
  mapTaskIconToIconAndTitle,
  keepInBoundsWithPinPosition,
  pictureSizes,
  generatePictureUrl,
} from '@properly/common';
import t from '@properly/localization';
import { Icon } from '@properly/components';
import Popover from '@material-ui/core/Popover';
import classNames from 'classnames/bind';
import styles from './checklistsStyles.module.css';
import ChecklistImageTrash from './ChecklistImageTrash';
import ChecklistMasterPin from './ChecklistMasterPin';
import ChecklistImageDragarea from './ChecklistImageDragarea';
import ChecklistImagePin from './ChecklistImagePin';
import { ROUTES } from '../../../../paths';

const cx = classNames.bind(styles);

class ChecklistImage extends Component {
  static propTypes = {
    tasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    image: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    onReplace: PropTypes.func.isRequired,
    onTrashPin: PropTypes.func.isRequired,
    onSetActivePin: PropTypes.func.isRequired,
    pinDropped: PropTypes.func.isRequired,
    newPinDropped: PropTypes.func.isRequired,

    activePinId: PropTypes.string,
    isReadOnly: PropTypes.bool,
  };

  static defaultProps = {
    activePinId: null,
    isReadOnly: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      hidePin: false,
      posPin: { x: -100, y: -100, position: 'top' },
      posPinAbsolute: { x: -1000, y: -1000, position: 'top' },
      pinFixed: false,
      isDragging: false,
      hideOverlay: 0, // eslint-disable-line
    };
    this.steps = [
      'custom',
      'look',
      'laundry',
      'stripandreplace',
      'iron',
      'wipe',
      'disinfect',
      'scrub',
      'vacuum',
      'mop',
      'sweep',
      'refill',
      'dust',
      'cleanglass',
      'emptytrash',
      'water',
      'lighton',
      'lock',
      'unlock',
      'seal',
      'stage',
    ];
  }

  onClickImagePin = () => {
    if (
      this.state.posPin.x !== -100 &&
      this.state.posPin.y !== -100 &&
      !this.props.activePinId // no pin active
    ) {
      this.setState({ pinFixed: true });
    }
  };

  setPin(left, top) {
    return {
      transform: `translate3d(${left}px, ${top}px, 0px)`,
    };
  }

  boundAndAbsoluteMousePos(event, parent) {
    if (!parent) return undefined;
    const bounds = parent.getBoundingClientRect();
    if (!bounds) return undefined;
    return {
      x: event.clientX,
      y: event.clientY,
      bounds,
    };
  }

  absoluteToRelative(bounds, more) {
    if (!bounds || !more) return undefined;
    const x = more.x - bounds.left;
    const y = more.y - bounds.top;
    return {
      ...more,
      x,
      y,
    };
  }

  serverPositionToLocalPosition(canvasWidth, canvasHeight, left, top, raw) {
    const leftAbsolute = canvasWidth * left;
    const topAbsolute = canvasHeight * top;
    const transform = { transform: `translate3d(${leftAbsolute}px, ${topAbsolute}px, 0px)` };
    if (raw) {
      return {
        y: topAbsolute,
        x: leftAbsolute,
        transform,
      };
    }
    return transform;
  }

  localPositionToServerPosition(canvasWidth, canvasHeight, y, x) {
    return {
      centerX: x / canvasWidth,
      centerY: y / canvasHeight,
    };
  }

  wrap = (children, x, y) => (
    <div
      style={{
        ...this.setPin(x, y),
      }}
      className={cx('checklist__pin')}
    >
      {children}
    </div>
  );

  renderPins = (pins, setActivePin, activePinId, indexImage, isReadOnly) => {
    const doNotBubble = (func, args, e) => {
      e.stopPropagation();
      func.call(this, ...args);
    };
    return map(pins, (pin, taskIndex) => {
      if (!pin.centerX && !pin.centerY) return null;
      const iconObj = mapTaskIconToIconAndTitle(pin.icon);
      const zIndex = activePinId === pin.objectId ? { zIndex: 1000 } : {};
      const { x, y, transform } = this.serverPositionToLocalPosition(752, 423, pin.centerX, pin.centerY, true);
      const positionObj = keepInBoundsWithPinPosition({ left: 0, right: 752, top: 0, bottom: 423 }, x, y);
      return (
        <ChecklistImagePin
          style={{
            ...zIndex,
            ...transform,
          }}
          isReadOnly={isReadOnly}
          key={taskIndex}
          onClick={e => doNotBubble(setActivePin.bind(this, pin.objectId), [], e)}
          className={cx('checklist__pin')}
          wrapFunc={this.wrap}
          indexImage={indexImage}
          index={taskIndex}
        >
          <ChecklistMasterPin positioned icon={Icon[iconObj.icon]} position={positionObj.position} />
        </ChecklistImagePin>
      );
    });
  };

  calcPopoverPosition(x, y) {
    const winWidth = window.innerWidth;
    const left = x;
    const right = winWidth - x;
    const reversed = invert({ left, right });
    const sorted = reverse(sortBy(keys(reversed), val => val * 1));
    return {
      x,
      y,
      position: reversed[sorted[0]],
    };
  }

  mouseOver = e => {
    let hidePin = false;
    if (e.target && e.target.getAttribute('data-pin') === 'master') {
      hidePin = true;
    }
    // get absolute positon + bound of mouse
    // eslint-disable-next-line
    const res = this.boundAndAbsoluteMousePos(e, this.refs.image);
    if (!res || this.state.pinFixed) return;
    // hinder the pin from leaving the canvas
    const finalRes = keepInBoundsWithPinPosition(res.bounds, res.x, res.y);
    // calc popover positioning
    const withPopoverPosition = this.calcPopoverPosition(res.x, res.y);
    if (!finalRes) return;
    // make position relative to canvas so we can display the pin
    const finalFinal = this.absoluteToRelative(res.bounds, finalRes);
    if (!finalFinal) return;
    this.setState({
      hidePin,
      posPin: finalFinal,
      posPinAbsolute: withPopoverPosition,
    });
  };

  createPin(type) {
    const { x, y } = this.state.posPin;
    const xy = this.localPositionToServerPosition(752, 423, y, x);
    this.props.newPinDropped(type, xy);
    this.resetPin({});
  }

  resetPin = () => {
    // if (this.refs.image && !this.refs.image.contains(e.target)) {
    this.setState({ posPin: { x: -100, y: -100 }, pinFixed: false });
    // }
  };

  setPinPos(e) {
    return {
      top: e.y,
      left: e.x,
    };
  }

  closeFixed = e => {
    e.stopPropagation();
    this.resetPin({});
  };

  renderBubbles = (steps, createPin) => {
    const mapped = map(steps, step => mapTaskIconToIconAndTitle(step));
    const doNotBubble = (func, args, e) => {
      e.stopPropagation();
      func.call(this, ...args);
    };
    return (
      <div className={cx('bubble__container')}>
        {map(chunk(mapped, 5), (row, iRow) => (
          <div key={iRow} className={cx('bubble__wrap')}>
            {map(row, (item, iItem) => {
              const BubbleIcon = Icon[item.icon];
              return (
                <div key={iItem} onClick={e => doNotBubble(createPin, [item.symbol], e)} className={cx('bubble-outer')}>
                  <div className={cx('bubble')}>
                    <BubbleIcon height="2.5em" width="2.5em" byWidth />
                  </div>
                  <span>{item.title}</span>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  };

  genImage(imageInner) {
    return {
      backgroundImage: `url(${generatePictureUrl(imageInner, pictureSizes.LARGE)})`,
    };
  }

  handleDropPinFromDragging = (node, y, x) => {
    const xy = this.localPositionToServerPosition(752, 423, y, x);
    this.props.pinDropped(node.index, xy);
  };

  handleIsDragging = (item, val) => {
    this.setState({ isDragging: val });
  };

  handleTrashPin = item => {
    this.handleIsDragging(item, false);
    this.props.onTrashPin(item);
  };

  renderReplaceBtn = () => {
    const doNotBubble = (func, e) => {
      e.stopPropagation();
      func.call(this, e);
    };
    return (
      <div onClick={e => doNotBubble(this.props.onReplace, e)} className={cx('checklist__image-replace')}>
        <Icon.IcSmReplace />
        <span>{t('checklist.replace')}</span>
      </div>
    );
  };

  renderImageOverlay() {
    return (
      <div data-overlay="true" className={cx('checklist__image-info')}>
        <div data-overlay="true">
          <Icon.IcPinclickFre2 data-overlay="true" />
        </div>
        <div data-overlay="true" className={cx('checklist__image-info-txt')}>
          {t('checklist.image_info')}
        </div>
      </div>
    );
  }

  renderPopover = (pos, postPin, steps, createPin) => {
    const heightPopover = 388;
    const widthPopover = 432;
    const pinHeight = 70;
    const pinWidth = 62;
    const pinWidthHalf = pinWidth / 2;
    const pinHeightHalf = pinHeight / 2;
    let posY = pos.y;
    let posX = pos.x;

    // center popover in the middle of pin
    if (pos.position === 'left' || pos.position === 'right') {
      posY -= heightPopover / 2;
    }
    if (pos.position === 'left') {
      posX -= widthPopover;
    }

    // adjust popover depending on pin position
    if (
      (postPin.position === 'top' || postPin.position === 'bottom') &&
      (pos.position === 'left' || pos.position === 'right')
    ) {
      posX = pos.position === 'left' ? posX - pinWidthHalf : posX + pinWidthHalf;
      posY = postPin.position === 'bottom' ? posY + pinHeightHalf : posY - pinHeightHalf;
    }
    if (
      (postPin.position === 'left' || postPin.position === 'right') &&
      (pos.position === 'left' || pos.position === 'right')
    ) {
      posX = postPin.position === 'left' ? posX - pinHeight : posX + pinHeight;
    }

    // handle overlapping with window
    const overlapYBottom = window.innerHeight - (posY + heightPopover);
    const overlapYTop = posY - 48; // 48 because the top bar is 48 px high
    if (overlapYBottom < 0) {
      posY += overlapYBottom; // move popover up
    }
    if (overlapYTop < 0) {
      posY += overlapYTop * -1; // move popover down
    }
    const children = <div>{this.renderBubbles(steps, createPin)}</div>;
    return (
      <div onClick={this.closeFixed} className={cx('checklist__fixedlayer')}>
        <Popover open anchorReference="anchorPosition" anchorPosition={{ top: posY, left: posX }}>
          {children}
        </Popover>
      </div>
    );
  };

  renderMouseOverPin = () => (
    <div
      style={{
        ...this.setPin(this.state.posPin.x, this.state.posPin.y),
      }}
      className={cx('checklist__pin', { 'checklist__pin--hover': !this.state.pinFixed })}
    >
      <ChecklistMasterPin position={this.state.posPin.position} small={!this.state.pinFixed} />
    </div>
  );

  renderDarkOverlay(setActivePin) {
    return <div onClick={() => setActivePin(undefined)} className={cx('checklist__image-pin-overlay')} />;
  }

  get hasReplaceBtn() {
    const path = this.props.location?.pathname;
    const pathToNotShowOn = ROUTES.library;
    return (
      !this.state.isDragging &&
      !this.props.activePinId &&
      this.props.tasks.length > 0 &&
      !path.includes(pathToNotShowOn)
    );
  }

  get hasPleaseAddPinsOverlay() {
    return this.props.tasks.length === 0 && !this.state.pinFixed;
  }

  get hasHoverOrangePin() {
    return (
      !this.state.isDragging &&
      !this.props.activePinId &&
      !this.state.hidePin &&
      (this.props.tasks.length > 0 || this.state.pinFixed)
    );
  }

  render() {
    const { tasks, image, index, isReadOnly } = this.props;
    return (
      <ChecklistImageDragarea setIsDragging={this.handleIsDragging} setPosition={this.handleDropPinFromDragging}>
        <div
          ref="image" // eslint-disable-line
          data-drag={`image${index}`}
          id="pin-image-wrap"
          onMouseMove={(!isReadOnly && this.mouseOver) || noop}
          onMouseLeave={(!isReadOnly && this.resetPin) || noop} // remove this for pin debugging
          onClick={(!isReadOnly && this.onClickImagePin) || noop}
          className={cx('checklist__image', 'modal-container')}
        >
          <div className={cx('checklist__image-actual')} style={this.genImage(image)} />

          {this.hasReplaceBtn && !isReadOnly && this.renderReplaceBtn()}

          {this.state.isDragging && <ChecklistImageTrash onDropPinInTrash={this.handleTrashPin} />}

          {this.hasPleaseAddPinsOverlay && !isReadOnly && this.renderImageOverlay()}

          {this.hasHoverOrangePin && !isReadOnly && this.renderMouseOverPin()}

          {this.props.activePinId && this.renderDarkOverlay(this.props.onSetActivePin)}

          {this.renderPins(tasks, this.props.onSetActivePin, this.props.activePinId, index, isReadOnly)}

          {this.state.pinFixed &&
            this.renderPopover(this.state.posPinAbsolute, this.state.posPin, this.steps, this.createPin)}
        </div>
      </ChecklistImageDragarea>
    );
  }
}

export default withRouter(ChecklistImage);
