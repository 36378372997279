import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PhoneOverlay from '../../../components/PhoneOverlay';
import { ROUTES } from '../../../paths';

class UpgradeMobileContainer extends PureComponent {
  render() {
    return <PhoneOverlay isUpgrade handleUpgrade={() => this.props.toSubscription()} />;
  }
}

export default connect(
  null,
  {
    toSubscription: () => push(ROUTES.mobileSubscription),
  },
)(UpgradeMobileContainer);
