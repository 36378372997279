import React from 'react';
import PropTypes from 'prop-types';
import t from '@properly/localization';
import { Button, Icon, SpaceLarge, Regular } from '@properly/components';
import StripeSPPageHeader from './StripeSPPageHeader';

function StripeSPLanding(props) {
  const { redirectToProvider } = props;
  return (
    <>
      <StripeSPPageHeader noLogo />
      <SpaceLarge />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '14px',
          textAlign: 'center',
        }}
      >
        <Regular>{t('payments.connect_to_stripe')}</Regular>
        <SpaceLarge />
        <Regular>{t('payments.click_button_to_connect')}</Regular>
        <SpaceLarge />
        <Button types={['type-flat-grey']} onClick={redirectToProvider}>
          <Icon.StripeConnect byWidth />
        </Button>
      </div>
    </>
  );
}
StripeSPLanding.propTypes = {
  redirectToProvider: PropTypes.func.isRequired,
};

export default StripeSPLanding;
