import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Title1, DividerLine, Regular, Button, Title3, SpaceBase, SpaceTiny, SpaceSmall } from '@properly/components';
import { showMsgIntercom, openIntercomWithMsg, isMobileTouch } from '@properly/common';
import moment from 'moment-timezone';
import map from 'lodash/map';
import lodashResult from 'lodash/result';
import t from '@properly/localization';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import styles from '../components/accountStyles.module.css';
import { PagePadding } from '../../../../components/PageElements/index';
import PricingPlansContainer from '../../pricing/PricingPlansContainer';
import { openPricingModalSaga } from '../../pricing/PricingActions';
import { refetchUserSaga, goCheckoutUpdate } from '../../../../actions/globalActions';
import { priceString, isCustomPlan, formatPrice } from '../../../../helper/herbert';
import { trackPricingUpdateDetails, trackPricingCancelSub } from '../../../../actions/trackingEvents';
import { AccountHeadSection } from '../../../../components/AccountElements/index';
import { selectCurrentUserSubscription } from '../../../../selectors/globalSelector';

const cx = classNames.bind(styles);

const hasSubscriptionOnRecurly = subscription => !!lodashResult(subscription, ['subscriptionProviderSubscriptionId']);

const subscriptionIsCustomPlan = subscription => isCustomPlan(subscription.currentPlanId);

class AccountSubscriptionContainer extends PureComponent {
  componentDidMount() {
    this.props.refetchUserSaga(); // make sure to refetch in case something changed
    if (this.props.hasThankuModal) {
      this.props.openPricingModalSaga('thankyou', {});
    }
    if (this.props.hasUpgradeModal) {
      this.onUpgrade();
    }
  }

  renderPlanSection() {
    return (
      <div>
        <div className={cx('account__subscription-spacenormal')} />
        <AccountHeadSection>
          <Title3 type="nomargin">{t('account.pricing_plans')}</Title3>
        </AccountHeadSection>
        <SpaceBase />
        <div className={cx('account__subscription-subtxt')}>{t('account.price_plan_txt')}</div>
        <div className={cx('account__subscription-space')} />
        <PricingPlansContainer noPadding />
      </div>
    );
  }

  onUpgrade = () => {
    if (isMobileTouch()) {
      window.scrollTo(0, 500);
    } else {
      this.props.openPricingModalSaga('upgrade', {});
    }
  };

  getPriceStringArray(isAnnually) {
    const priceStringArray = [];
    const priceStringVal = priceString(
      this.props.subscription.priceType,
      formatPrice(this.props.subscription.price / 100),
      true,
    );
    if (priceStringVal !== false) {
      if (
        !(
          subscriptionIsCustomPlan(this.props.subscription) &&
          !this.props.subscription.price &&
          this.props.subscription.price !== 0
        )
      ) {
        priceStringArray.push(priceStringVal);
      }
    }
    if (isAnnually) {
      priceStringArray.push(t('account.billed_annually'));
    }
    return priceStringArray;
  }

  getInfoDetails({ allowedJobsMonth, jobsUsedMonth, currentPlanId, expiration, nextRenewal }) {
    const arr = [];
    if (allowedJobsMonth > 0) {
      arr.push(t('account.x_of_y_jobssent', { x: jobsUsedMonth, y: allowedJobsMonth }));
    }
    if (currentPlanId === 'trial') {
      arr.push(t('account.will_end_soon', { date: moment(expiration).format('L') }));
    } else {
      arr.push(t('account.next_renew', { date: moment(nextRenewal).format('L') }));
    }
    return arr;
  }

  renderSubInfoDetails(array) {
    return map(array, data => <li key={data}>{data}</li>);
  }

  handleUpdateClick = () => {
    trackPricingUpdateDetails();
    this.props.goCheckoutUpdate();
  };

  handleContactSuccess = () => {
    showMsgIntercom();
  };

  handleCancelClick = () => {
    trackPricingCancelSub();
    if (isMobileTouch()) {
      openIntercomWithMsg(t('pricing.cancel_msg_intercom'));
    } else {
      this.props.openPricingModalSaga('contact', {});
    }
  };

  renderCancel() {
    return (
      <div className={cx('account__subscription-cancel')}>
        {t('account.would_ulike_to_cancel', {
          dowhat: <span onClick={this.handleCancelClick}>{t('account.cancel_subscrib')}</span>,
        })}
      </div>
    );
  }

  renderCurrentPlanSection(isMobile) {
    return (
      <div>
        <AccountHeadSection>
          <Title3 type="nomargin">{t('account.subscription')}</Title3>
        </AccountHeadSection>
        <SpaceTiny />
        <PricingPlansContainer.Row>
          <PricingPlansContainer.Item>
            <div className={cx('account__subscription-head')}>{t('account.your_current_plan')}</div>
            <SpaceTiny />
            <PricingPlansContainer.PricingBox
              title={this.props.subscription.currentPlanTitle}
              features={this.getPriceStringArray(this.props.subscription.annualSubscription)}
              mode={!subscriptionIsCustomPlan(this.props.subscription) ? t('pricing.upgrade') : ''}
              isSlim
              onUpgrade={this.onUpgrade}
            />
            {hasSubscriptionOnRecurly(this.props.subscription) && (
              <Button
                types={['type-flat-primary', 'background-trans', 'size-large', 'fontw-normal', 'width-flex']}
                onClick={this.handleUpdateClick}
              >
                {t('account.update_details')}
              </Button>
            )}
            {!hasSubscriptionOnRecurly(this.props.subscription) && isMobile && <SpaceSmall />}
            {subscriptionIsCustomPlan(this.props.subscription) && (
              <Button
                types={['type-flat-primary', 'background-trans', 'size-small', 'fontw-normal', 'width-flex']}
                onClick={this.handleContactSuccess}
              >
                {t('account.contact_success_manager')}
              </Button>
            )}
          </PricingPlansContainer.Item>
          <PricingPlansContainer.Item style={{ marginLeft: isMobile ? 0 : 145 }}>
            <div className={cx('account__subscription-head')}>{t('account.usage')}</div>
            <ul className={cx('account__subscription-list')}>
              {this.renderSubInfoDetails(this.getInfoDetails(this.props.subscription))}
            </ul>
            {!subscriptionIsCustomPlan(this.props.subscription) && (
              <div>
                <SpaceTiny />
                {this.renderCancel()}
              </div>
            )}
          </PricingPlansContainer.Item>
        </PricingPlansContainer.Row>
        <div className={cx('account__subscription-spacesmall')} />
      </div>
    );
  }

  renderDesktop(content) {
    return (
      <PagePadding type="absolute-scroll" variation="subscription">
        <div style={{ maxWidth: 1200, margin: '0 auto' }}>{content}</div>
      </PagePadding>
    );
  }

  renderMobile(content) {
    return (
      <div className={cx('account__subscription-mobilewrap')}>
        <PricingPlansContainer.MobileWrap>{content}</PricingPlansContainer.MobileWrap>
      </div>
    );
  }

  render() {
    if (!this.props.subscription) {
      return (
        <div style={{ margin: '100px 20px' }}>
          <Title1 component="div">{t('pricing.dont_have_active_sub')}</Title1>
          <Regular component="div">{t('pricing.contact_customer_support')}</Regular>
        </div>
      );
    }

    const content = (
      <div>
        {this.renderCurrentPlanSection(this.props.isMobile)}
        <div style={{ position: 'relative' }}>
          <DividerLine type={['bottom', 'mediumdark']} />
        </div>
        {!subscriptionIsCustomPlan(this.props.subscription) && this.renderPlanSection()}
      </div>
    );
    return this.props.isMobile ? this.renderMobile(content) : this.renderDesktop(content);
  }
}

function mapStateToProps(state, props) {
  return {
    subscription: selectCurrentUserSubscription(state, props),
    hasThankuModal: !!lodashResult(props, ['location', 'query', 'thankyou']),
    hasUpgradeModal: lodashResult(props, ['location', 'query', 'open']) === 'modal',
  };
}

AccountSubscriptionContainer.propTypes = {
  isMobile: PropTypes.bool,
};

export default connect(mapStateToProps, {
  openPricingModalSaga,
  refetchUserSaga,
  goCheckoutUpdate,
})(AccountSubscriptionContainer);
