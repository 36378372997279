const countries = require('./countries.json');
const currencyData = require('./currencyData.json');

export { countries };
export { currencyData };

export const countryPhoneOptions = countries.map(country => ({
  title: `${country.name} ${country.dial_code}`,
  value: country.dial_code,
}));

export const countryDialCodeOptions = countries
  .map(country => ({
    title: country.dial_code,
    value: country.dial_code,
  }))
  .sort((a, b) => (a.title > b.title ? 1 : -1));

export function getCurrencyFromCountryShortCode(shortCode) {
  const currencies = currencyData[shortCode];
  if (!currencies) {
    return undefined;
  }
  const lastItem = currencies[currencies.length - 1];
  if (!lastItem) {
    return undefined;
  }
  return Object.keys(lastItem)[0];
}

export function splitCountryCode(val) {
  const finalVal = String(val || '');
  const splitted = finalVal.split('+');
  if (splitted.length === 2) return `+${splitted[1]}`;
  return '';
}

export function countryToDialCode(countryCode, fallBack) {
  // eslint-disable-next-line
  for (const country of countries) {
    if (country.code === countryCode) return country.dial_code;
  }
  return fallBack;
}

export function getCurrentCountryByIp() {
  return new Promise(resolve => {
    const request = new XMLHttpRequest();
    request.open('GET', 'https://telize-v1.p.rapidapi.com/location', true);
    request.setRequestHeader('x-rapidapi-host', 'telize-v1.p.rapidapi.com');
    request.setRequestHeader('x-rapidapi-key', '2tiEUYgmK9mshVkWCHSYrHVWTb5Bp1G1umMjsnihz2u2Hz1p0D');
    request.setRequestHeader('Accept', 'application/json');
    request.onreadystatechange = function change() {
      if (this.readyState === 4 && this.status >= 200 && this.status < 400) {
        // Success!
        const data = JSON.parse(this.responseText);
        const foundCountries = countries.filter(c => c.code === data.country_code);

        if (foundCountries.length > 0) {
          resolve(foundCountries[0].code);
        } else {
          resolve(null);
        }
      }
    };
    request.send();
  });
}

export async function getCountryCode() {
  const country = await getCurrentCountryByIp();
  return countryToDialCode(country, null);
}
