import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import t from '@properly/localization';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import { connect } from 'react-redux';
import { featureFlag } from '@properly/config';
import { SearchField, Icon } from '@properly/components';
import { List, AutoSizer } from 'react-virtualized';
import TopLabel from '../../../../components/TopLabel/index';
import Sidebar from '../../../../components/Sidebar/index';
import { goToContact, inviteCleaner } from '../state/ContactActions';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import { rowRenderMapper } from '../../../../helper/herbert';
import * as globalActions from '../../../../actions/globalActions';
import HasPermission from '../../../../hoc/HasPermission/index';
import ContactRow from './ContactRow';
import { selectIsFeatureFlagEnabledForUser } from '../../settings/state/SettingsSelectors';

class ContactSidebarContainer extends PureComponent {
  renderCells(cells, activeId, isSearch, currentUserId) {
    const { activeSection, canShowProBadge } = this.props;

    const goToContactInner = contact => () => this.props.goToContact(contact.objectId, activeSection);

    if (isSearch && cells.length === 0) {
      return (
        <Sidebar.List>
          <Sidebar.NoRes>{t('properties.no_result')}</Sidebar.NoRes>
        </Sidebar.List>
      );
    }
    const isActive = row => row.objectId === activeId;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            rowHeight={68}
            rowCount={cells.length}
            rowRenderer={rowRenderMapper({
              data: cells,
              handler: ContactRow,
              ctx: { onClick: goToContactInner, currentUserId, isActive, canShowProBadge },
            })}
            width={width}
          />
        )}
      </AutoSizer>
    );
  }

  get rawList() {
    const filteredList = filter(this.props.searchRes, item => !item.deleted);
    return orderBy(filteredList, item => item.firstName.toLowerCase());
  }

  render() {
    return (
      <Sidebar>
        <HasPermission
          hasAccessFormatter={hasAccessRes => hasAccessRes.canSendJobsGlobal}
          render={hasAccess => (
            <TopLabel
              isEmpty={!hasAccess}
              disabled={!hasAccess}
              data-key="button_top_invite_service_provider"
              label={t('contacts.invite_cleaner')}
              onClick={this.props.inviteCleaner}
              icon={Icon.IcPlus}
            />
          )}
        />
        <Sidebar.Hr />
        <SearchField
          blank
          placeholder={t('contacts.search_cleaner')}
          value={this.props.searchQuery}
          iconLocation="left"
          onChange={value => this.props.setSearchQuery('contacts', value)}
        />
        <Sidebar.Hr />
        <div style={{ flex: 1 }}>
          {this.renderCells(this.rawList, this.props.activeId, !!this.props.searchQuery, this.props.currentUserId)}
        </div>
      </Sidebar>
    );
  }
}

function mapStateToProps() {
  const memo = selectorsGlobal.selectSearchData('contacts', 'contacts', selectorsGlobal.selectContacts);
  return (state, props) => {
    const { result, searchQuery } = memo(state);
    const isProMarketPropertyExists = selectorsGlobal.selectIfProMarketPropertyExists()(state);
    const isMarketPlaceFeatureFlagEnabled = selectIsFeatureFlagEnabledForUser(featureFlag.FEATURE_FLAG_MARKETPLACE)(
      state,
      props,
    );
    return {
      searchQuery,
      searchRes: result,
      currentUserId: selectorsGlobal.selectCurrentUserId()(state, props),
      canShowProBadge: isProMarketPropertyExists && isMarketPlaceFeatureFlagEnabled,
    };
  };
}

ContactSidebarContainer.propTypes = {
  activeId: PropTypes.string,
  activeSection: PropTypes.string,
};

export default connect(mapStateToProps, {
  setSearchQuery: globalActions.setSearchQuery,
  goToContact,
  inviteCleaner,
})(ContactSidebarContainer);
