import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from 'react-stripe-elements';
import { Box, Icon, Input, Pill, SpaceSmall, SquareSelect } from '@properly/components';
import t from '@properly/localization';
import colors from '@properly/components/lib/common/styles/colors.module.css';

const STEP_0_AGREE_TERMS = 0;
const STEP_1_ENTER_DETAILS = 1;
const STEP_2_SUCCESS_OR_FAIL = 2;

const PillWithContentAndStyles = withStyles(() => ({
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    display: 'flex',
    marginLeft: '-.5em',
    padding: '0 .5em',
  },
  divider__content: {
    width: '100%',
    textAlign: 'center',
  },
  text: {
    display: 'flex',
  },
  text__content: {
    padding: '0 0 0 0.25em',
  },
}))(props => {
  const { key, text, icon, classes } = props;

  let iconToRender;

  switch (icon) {
    case 'alert':
      iconToRender = <Icon.AlertRed width="1em" byWidth />;
      break;
    case 'success':
      iconToRender = <Icon.IcActive width="1em" byWidth />;
      break;
    default:
      break;
  }

  return (
    <Pill key={key} style={{ margin: '5px' }}>
      <div className={classes.content}>
        <Icon.StripeLogo width="4em" byWidth />
        <span className={classes.divider}>
          <span className={classes.divider__content}>|</span>
        </span>
        {iconToRender}
        <span className={classes.text}>
          <span className={classes.text__content}>{text}</span>
        </span>
      </div>
    </Pill>
  );
});

function stripeElementStyles() {
  return {
    style: {
      base: {
        color: colors['color-black'],
        '::placeholder': {
          color: colors['color-no-result'],
        },
        ':hover::placeholder': {
          color: colors['color-grey'],
        },
      },
      invalid: {
        color: colors['color-orange'],
      },
    },
  };
}

function Steps(props, prevState, error, onChangeName, onChangeStripeElement, onChangeSetAsDefault, forceSetAsDefault) {
  /* eslint-disable no-shadow */

  const { classes, config } = props;
  const { name, setAsDefault } = prevState;
  const elementSplitLeftStyles = {
    width: 'calc(50% + 0.5px)',
    position: 'absolute',
    left: 0,
    borderBottomRightRadius: 0,
  };
  const elementErrorStyles = {
    zIndex: 1,
    border: `1px solid ${colors['color-orange']}`,
  };
  const elementSplitRightStyles = {
    width: 'calc(50% + 0.5px)',
    position: 'absolute',
    right: 0,
    borderBottomLeftRadius: 0,
  };

  function setAsDefaultLabel(forceSetAsDefault) {
    return forceSetAsDefault ? (
      <Typography style={{ color: colors['color-grey'] }}>{t('job_request.wizard.set_as_default')}</Typography>
    ) : (
      t('job_request.wizard.set_as_default')
    );
  }

  return [
    {
      stepNumber: STEP_0_AGREE_TERMS,
      label: <Typography variant="h6">{t('job_request.wizard.payment_method')}</Typography>,
      content: t('job_request.wizard.payment_method_blurb', {
        msg: (
          <a
            className={`${classes?.link} text-l6m`}
            target="_blank"
            rel="noopener noreferrer"
            href={config.proMPSatisfactionGuaranteeUrl}
          >
            {t('marketplace.guarantee')}
          </a>
        ),
        tos: (
          <a className={`${classes?.link} text-l6m`} target="_blank" rel="noopener noreferrer" href={config.proTOSUrl}>
            {t('marketplace.expansion_content_tos')}
          </a>
        ),
      }),
      showActionsLeft: true,
      nextLabel: t('job_request.wizard.next'),
      onStepHandler() {
        const { resetIntentState } = props;
        resetIntentState();
        return Promise.resolve({ stepNumber: STEP_0_AGREE_TERMS });
      },
    },
    {
      stepNumber: STEP_1_ENTER_DETAILS,
      label: <Icon.StripeLogo margin="auto" width="4em" byWidth />,
      content: (
        <>
          <Input
            value={name}
            isFirst
            isLast
            data-key="name"
            placeholder={t('account.name', {})}
            type="text"
            autoComplete="name"
            onChange={e => onChangeName && onChangeName(e.target.value)}
            styleOverwrite={error && error.elementType === 'accountName' ? elementErrorStyles : {}}
          />

          <SpaceSmall />

          <Box
            noPadding
            hover
            isFirst
            styleOverwrite={error && error.elementType === 'cardNumber' ? elementErrorStyles : {}}
          >
            <CardNumberElement
              {...stripeElementStyles()}
              className={classes.stripeElement}
              onReady={onChangeStripeElement}
            />
          </Box>

          <Input.TwoSplit>
            <Box
              noPadding
              hover
              isLast
              styleOverwrite={
                error && error.elementType === 'cardExpiry'
                  ? { ...elementSplitLeftStyles, ...elementErrorStyles }
                  : elementSplitLeftStyles
              }
            >
              <CardExpiryElement
                {...stripeElementStyles()}
                className={classes.stripeElement}
                onReady={onChangeStripeElement}
              />
            </Box>

            <Box
              noPadding
              hover
              isLast
              styleOverwrite={
                error && error.elementType === 'cardCvc'
                  ? { ...elementSplitRightStyles, ...elementErrorStyles }
                  : elementSplitRightStyles
              }
            >
              <CardCvcElement
                {...stripeElementStyles()}
                className={classes.stripeElement}
                onReady={onChangeStripeElement}
              />
            </Box>
          </Input.TwoSplit>

          <SpaceSmall />

          <SquareSelect
            small
            label={setAsDefaultLabel(forceSetAsDefault)}
            onChange={onChangeSetAsDefault}
            selected={forceSetAsDefault || setAsDefault}
            disabled={forceSetAsDefault}
          />

          <SpaceSmall />

          <div className={classes.cardErrors} role="alert">
            {error ? error.message : ''}
          </div>
        </>
      ),
      showActionsLeft: false,
      nextLabel: t('job_request.wizard.continue'),
      async onStepHandler(props, state, intent) {
        const { stripe } = props;
        const { name, cardNumberElement } = state;

        if (!(name && name.trim())) {
          const err = { message: t('job_request.wizard.name_invalid'), elementType: 'accountName' };

          return Promise.resolve({ error: err });
        }

        // credit card
        try {
          const { setupIntent, error } = await stripe.handleCardSetup(intent.clientSecret, cardNumberElement, {
            payment_method_data: {
              billing_details: { name },
            },
          });

          return Promise.resolve({ stepNumber: STEP_1_ENTER_DETAILS, setupIntent, error });
        } catch (err) {
          throw err;
        }
      },
    },
    {
      stepNumber: STEP_2_SUCCESS_OR_FAIL,
      label: error ? (
        <PillWithContentAndStyles text={t('job_request.wizard.inactive')} icon="alert" />
      ) : (
        <PillWithContentAndStyles text={t('job_request.wizard.active')} icon="success" />
      ),
      content: (
        <Typography style={{ color: colors['color-grey'] }} variant="h6">
          {error ? error.message : t('job_request.wizard.success')}
        </Typography>
      ),
      showActionsLeft: false,
      nextLabel: t('job_request.wizard.next'),
    },
  ];
}

export default Steps;
