import React from 'react';
import PropTypes from 'prop-types';
import { CardShow } from '@properly/components';
import StripeSPPageHeader from './StripeSPPageHeader';

function StripeSPConnectFailure(props) {
  const { redirectToProvider } = props;
  return (
    <div>
      <StripeSPPageHeader />
      <CardShow
        stripe
        notifyIcon="warning"
        buttonOnClick={redirectToProvider}
        content="Oops! Something went wrong. There was a problem connecting to stripe. Please try again."
      />
    </div>
  );
}

StripeSPConnectFailure.propTypes = {
  redirectToProvider: PropTypes.func.isRequired,
};

export default StripeSPConnectFailure;
