import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { DragLayer } from 'react-dnd';
import raf from 'raf';
import { keepInBoundsWithPinPosition } from '@properly/common';

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

class CustomDragLayer extends PureComponent {
  static propTypes = {
    item: PropTypes.shape({}),
    itemType: PropTypes.string,
    initialOffset: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }),
    currentOffset: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }),
    isDragging: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    // eslint-disable-next-line
    this.state = { offsetX: undefined, offsetY: undefined };
  }

  renderItem(type, item, clientOffset) {
    switch (type) {
      case 'PIN': {
        const child = React.Children.only(item.children);
        const childInner = React.cloneElement(child, { position: clientOffset.position, dragging: true });
        if (!clientOffset) return null;
        const wrapped = item.wrapFunc(childInner, clientOffset.x, clientOffset.y);
        // return React.cloneElement(child, {'data-drag': 'item', style: { marginTop: this.state.offsetY, marginLeft: this.state.offsetX }});
        return wrapped;
      }
      default:
        return null;
    }
  }

  updateBounds() {
    const that = this;
    raf(function tick() {
      if (!that.props.item) return;
      const hoverBoundingRect = document.querySelector(`[data-drag="image${that.props.item.indexImage}"]`);
      if (!hoverBoundingRect) return;
      that.bounds = hoverBoundingRect.getBoundingClientRect();
      raf(tick);
    });
  }

  render() {
    const { item, itemType, isDragging, clientOffset } = this.props;
    this.updateBounds();
    if (!isDragging || !clientOffset || !this.bounds) {
      return null;
    }
    const bounds = keepInBoundsWithPinPosition(this.bounds, clientOffset.x, clientOffset.y);
    return <div style={layerStyles}>{this.renderItem(itemType, item, bounds)}</div>;
  }
}

export default compose(
  DragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    clientOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  })),
)(CustomDragLayer);
