import store from 'store';
import log from 'loglevel';
import { requestHandler } from './data.requestHandler';
import { _mapParsePropertyToProperty } from './data.map';
import {
  buildIgnoreDeletedQuery,
  buildUpdatedAtGreaterThanOrEqualQuery,
  fetchParseClass,
  getBatchMappingWithMax,
} from './data.http';

const PROPERLY_MAX_PROPERTY_KEY = 'PROPERLY_MAX_PROPERTY_KEY';

const getMaxPropertyCount = () => store.get(PROPERLY_MAX_PROPERTY_KEY) || 0;
const setMaxPropertyCount = max => {
  const roundedMax = Math.round(max / 50) * 50;
  store.set(PROPERLY_MAX_PROPERTY_KEY, roundedMax);
};

export function getUserProperties(timestamp) {
  return requestHandler(() => {
    // We do want to retrieve these mergedTo properties when we have a timestamp as it may
    // Update properties that have been merged
    const noMergedToQuery = !timestamp ? { mergedTo: { $exists: false } } : {};

    const query = timestamp
      ? { ...buildIgnoreDeletedQuery(), ...buildUpdatedAtGreaterThanOrEqualQuery(timestamp), ...noMergedToQuery }
      : { ...buildIgnoreDeletedQuery(), ...noMergedToQuery };

    const getProperties = (limit = 10000, skip = 0) =>
      fetchParseClass('Property', limit, query, `skip=${skip}`).then(results =>
        (results || []).map(property => _mapParsePropertyToProperty(property)),
      );

    // This could be a little smarter and portion the concurrencies based on the previous size of properties seen.
    // Works well for people with properties up to 800x
    const mapping = getBatchMappingWithMax(getMaxPropertyCount());

    return Promise.all(mapping.map(({ limit, skip } = {}) => getProperties(limit, skip)))
      .then(propertiesLists => propertiesLists.reduce((acc, list) => acc.concat(list), []))
      .then(results => {
        const resultCount = results.length;
        log.info('Properties result count', resultCount);

        setMaxPropertyCount(resultCount);

        return results;
      });
  });
}
