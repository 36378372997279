import { fork, takeEvery } from 'redux-saga/effects';
import { trackPropertyTagChanged } from '../../../../actions/trackingEvents';
import * as types from '../../../../types';

// eslint-disable-next-line
function* trackPropertyTags({ propertyId, tags }) {
  trackPropertyTagChanged(tags, propertyId);
}

function* saga() {
  yield fork(takeEvery, types.PROPERTY_UPDATE_TAGS, trackPropertyTags);
}

export default saga;
