import gql from 'graphql-tag';

export const QUERY_GET_CUSTOM_BRANDING = gql`
  query CustomBranding($id: ID!, $languageCode: String!, $device: DeviceTypeEnum!) {
    CustomBranding(id: $id, languageCode: $languageCode, device: $device) {
      partnerWebsiteUrl
      ads {
        propertyPage {
          ...adsPage
        }
        cleanerPage {
          ...adsPage
        }
        checklistLibraryPage {
          ...adsPage
        }
        calendarPage {
          ...adsPage
        }
        todosPage {
          ...adsPage
        }
      }
      style {
        ...style
      }
      isPartnerDomain
      onBoardingVideoUrl
      landingPage
      onBoardingVideoDisplayPaths
    }
  }

  fragment style on Style {
    adBackgroundColor
    adTextColor
    adLinkTextColor
  }

  fragment link on Link {
    text
    url
  }

  fragment adsPage on AdsPage {
    name
    text
    link {
      ...link
    }
    pictureIdentifier
  }
`;

export const QUERY_GET_CUSTOM_BRANDING_LANDING_PAGE = gql`
  query CustomBranding($id: ID!, $languageCode: String!, $device: DeviceTypeEnum!) {
    CustomBranding(id: $id, languageCode: $languageCode, device: $device) {
      landingPage
    }
  }
`;
