import t from '@properly/localization';
import { getJobTypeKey } from '@properly/common';
import { mapJobTypeToBackendJobType } from '@properly/config';
import moment from 'moment-timezone';
import lodashResult from 'lodash/result';
import reduce from 'lodash/reduce';
import keys from 'lodash/keys';
import invert from 'lodash/invert';
import first from 'lodash/first';
import map from 'lodash/map';
import log from 'loglevel';
import {
  FETCH_X_JOBREQUEST_PER_LOAD,
  DEFAULT_PLACEHOLDER,
  mapDate,
  mapReportStatusToBackendCode,
  NOT_FOUND,
} from './ReportsConstants';

export function mapBool(val) {
  return val ? t('reports.yes_val') : t('reports.no_val');
}

export function titleIsValid(val) {
  return val && val.length !== 0;
}

export function hashData(sectionObj) {
  let date = sectionObj.date.active;
  const cols = keys(sectionObj.columns.selected).join(',');
  const status = keys(sectionObj.status.selected).join(',');
  const properties = keys(sectionObj.properties.selected).join(',');
  const contacts = keys(sectionObj.contacts.selected).join(',');
  const problems = sectionObj.problems.value;
  const title = sectionObj.title.value;
  if (date === 'custom') {
    date += sectionObj.date.start ? sectionObj.date.start.toDate().toString() : '';
    date += sectionObj.date.end ? sectionObj.date.end.toDate().toString() : '';
  }
  return `${date}#${cols}#${status}#${properties}#${contacts}#${problems}#${title}`;
}

export function mapIdsToObj(array) {
  return reduce(
    array,
    (total, v) => {
      total[v] = true;
      return total;
    },
    {},
  );
}

export function mapTypesFromBackend(jobTypes) {
  if (keys(jobTypes.selected).length === keys(jobTypes.types).length) {
    return 'all';
  }
  return first(keys(jobTypes.selected));
}

export function mapStatusFromBackend(status) {
  if (keys(status.selected).length === keys(status.states).length) {
    return 'all';
  }
  const ele = first(keys(status.selected));
  return ele; // can be undefined of not selected
}

export function mapReportsTableHeads(name) {
  return t(`reports.select.${name}`) || 'Unknown';
}

export function mapTextToDate(text) {
  let start;
  let end;
  if (text === 'thisweek') {
    start = moment().startOf('week');
    end = moment().endOf('week');
  }
  if (text === 'lastweek') {
    const todayMinusOneWeek = moment().subtract(1, 'week');
    start = moment(todayMinusOneWeek).startOf('week');
    end = moment(todayMinusOneWeek).endOf('week');
  }
  if (text === 'thismonth') {
    start = moment().startOf('month');
    end = moment().endOf('month');
  }
  if (text === 'thismonth') {
    start = moment().startOf('month');
    end = moment().endOf('month');
  }
  if (text === 'lastmonth') {
    const todayMinusOneMonth = moment().subtract(1, 'months');
    start = moment(todayMinusOneMonth).startOf('month');
    end = moment(todayMinusOneMonth).endOf('month');
  }
  return { start, end };
}

export function mapReportToBackendReport(section) {
  const filter = {};
  filter.problem = section.problems.value;
  filter.columns = map(section.columns.selected, (value, key) => key);
  filter.status = mapStatusFromBackend(section.status);
  filter.jobTypes = mapTypesFromBackend(section.jobTypes);
  filter.properties = keys(section.properties.selected);
  filter.cleaners = keys(section.contacts.selected);
  filter.dateRange = mapDateRange(section.date);
  if (section.date.active === 'custom') {
    filter.startDate = section.date.start ? section.date.start.toDate() : undefined;
    filter.endDate = section.date.end ? section.date.end.toDate() : undefined;
  }

  return {
    title: section.title.value,
    filter,
  };
}

export function mapDateRange(date, reverse) {
  if (reverse) return invert(mapDate)[date];
  return mapDate[date.active];
}

export function mapBackendReportToReport(backendReport, allStatusStates = {}, allJobTypes = {}) {
  const { filter } = backendReport || {};
  const { cleaners: filterCleaners, properties: filterProperties } = filter || {};

  const report = {};
  const cleaners = mapIdsToObj(filterCleaners || []);
  const properties = mapIdsToObj(filterProperties || []);
  report.title = { value: backendReport.title };
  report.contacts = {};
  report.properties = {};
  report.date = {};
  report.problems = {};
  report.columns = {};
  report.status = { selected: {} };
  report.jobTypes = { selected: {} };
  report.contacts.selected = cleaners;
  report.contacts.isAll = !filterCleaners || filterCleaners.length === 0;
  report.properties.selected = properties;
  report.properties.isAll = !filterProperties || filterProperties.length === 0;
  report.date.active = mapDateRange(filter && filter.dateRange, true);
  if (report.date.active === 'custom') {
    report.date.start = moment(filter && filter.startDate).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    report.date.end = moment(filter && filter.endDate).set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 0,
    });
  } else {
    report.date.start = null;
    report.date.end = null;
  }
  report.problems.value = filter && filter.problem;
  report.columns.selected = mapIdsToObj(filter && filter.columns);
  if (filter && filter.status === 'all') {
    report.status.selected = mapIdsToObj(keys(allStatusStates));
  } else if (filter && filter.status) {
    report.status.selected[filter && filter.status] = true;
  }

  if (filter && filter.jobTypes === 'all') {
    report.jobTypes.selected = mapIdsToObj(keys(allJobTypes));
  } else if (filter && filter.jobTypes) {
    report.jobTypes.selected[filter && filter.jobTypes] = true;
  }

  return report;
}

export function isValidForFetch(sections) {
  // const hasDate = (sections.date.end && sections.date.start);
  const hasColumns = keys(sections.columns.selected).length > 0;
  const hasProperty = keys(sections.properties.selected).length > 0 || sections.properties.isAll;
  const hasContact = keys(sections.contacts.selected).length > 0 || sections.contacts.isAll;
  const hasStatus = keys(sections.status.selected).length > 0;
  log.info('isValidForFetch', {
    hasColumns,
    hasProperty,
    hasContact,
    hasStatus,
  });
  return hasColumns && hasProperty && hasContact && hasStatus;
}

export function replaceContactIdWithUserId(allContactsMap, selectedContactsMap) {
  return reduce(
    selectedContactsMap,
    (acc, booleanTrue, contactId) => {
      const userId = lodashResult(allContactsMap, [contactId, 'contactId']);
      if (userId) {
        acc[userId] = true;
      }
      return acc;
    },
    {},
  );
}

export function genParamsFromSections(sections, hostId) {
  const jobStatusValues = [
    mapReportStatusToBackendCode.started,
    mapReportStatusToBackendCode.finished,
    mapReportStatusToBackendCode.delayed,
  ];
  const res = {};
  res.hostId = hostId;
  try {
    const properties = keys(sections.properties.selected);
    const cleaners = keys(sections.contacts.selected);
    const status = keys(sections.status.selected);
    const jobType = keys(sections.jobTypes.selected);
    if (sections.date.start) res.start = sections.date.start.toDate();
    if (sections.date.end) res.end = sections.date.end.toDate();
    res.skip = 0;
    res.limit = FETCH_X_JOBREQUEST_PER_LOAD;
    if (properties.length === 1) res.property = properties[0]; // eslint-disable-line
    if (cleaners.length === 1) res.cleaner = cleaners[0]; // eslint-disable-line
    if (status.length === 1) {
      res.status = mapReportStatusToBackendCode[status[0]];
    }
    if (jobStatusValues.includes(res.status)) {
      //  job status to set to inProgress and finished and delayed
      res.jobStatus = res.status % 100;
      res.status =
        res.status === mapReportStatusToBackendCode.delayed ? mapReportStatusToBackendCode.accepted : undefined; // check if delayed and assign cleanerStatus =accepted
    }
    if (jobType.length === 1) res.jobType = mapJobTypeToBackendJobType[jobType[0]];
    if (sections.problems.value) res.problem = true;
  } catch (e) {
    log.error('genParamsFromSections', e);
  }
  return res;
}

function hasStartTime(jobRequest) {
  return !!jobRequest.actualStartTime;
}

function hasEndTime(jobRequest) {
  return !!jobRequest.actualEndTime;
}

function hasStartTimeOnly(jobRequest) {
  return hasStartTime(jobRequest) && !hasEndTime(jobRequest);
}

function hasStartAndEndTime(jobRequest) {
  return hasStartTime(jobRequest) && hasEndTime(jobRequest);
}

export function formatActualTime(jobRequest) {
  const res = `${jobRequest.getStartTimeString(true)} - `;
  if (hasStartTimeOnly(jobRequest)) return res;
  if (hasStartAndEndTime(jobRequest)) {
    return res + jobRequest.getEndTimeString(true);
  }
  return defaultify();
}

export function formatDate(date, timezone) {
  if (!date) return defaultify();
  return moment(date)
    .tz(timezone)
    .format('L');
}

export function formatName(cleanerRequest) {
  return `${cleanerRequest.firstName} ${cleanerRequest.lastName}`;
}

export function formatRepliedAt(cleanerRequest) {
  if (!cleanerRequest.repliedAt) return defaultify();
  return `${moment(cleanerRequest.repliedAt).format('L')} ${moment(cleanerRequest.repliedAt).format('LT')}`;
}

export function defaultify(val) {
  if (!val) return DEFAULT_PLACEHOLDER;
  return val;
}

export function mapApartmentType(type) {
  const val = `reports.${type}`;
  if (val !== t(val)) return t(val);
  return defaultify();
}

function mapRowToType(key, data) {
  switch (key) {
    case 'msgfromserviceprovider':
    case 'title':
    case 'description':
    case 'problemslist':
      return { type: 'textstackflex', data };
    case 'response':
    case 'viewed':
    case 'timedate':
    case 'name':
      return { type: 'textstack', data };
    case 'paymentstatus':
      return { type: 'paymentstatus', data };
    case 'jobType': {
      if (data === NOT_FOUND) {
        return data;
      }
      const jobTypeKey = getJobTypeKey(data);
      return jobTypeKey ? t(`reports.jobTypes.${jobTypeKey}`) : data;
    }
    default:
      return data;
  }
}

export function mapRowKeysToTypes(row) {
  return reduce(
    row,
    (acc, val, key) => {
      acc[key] = mapRowToType(key, val);
      return acc;
    },
    {},
  );
}
