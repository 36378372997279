import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { getConfigSync } from '@properly/config';
import WizardWithContentAndStyles from './wizardWithContentAndStyles';

const config = getConfigSync();

class PaymentInterceptWizard extends Component {
  static propTypes = {
    classes: PropTypes.shape({}),
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    textAlign: PropTypes.string,
    showProgress: PropTypes.bool,
    useAch: PropTypes.bool,
    useBankAccounts: PropTypes.bool,
    useCards: PropTypes.bool,
    useMarketplace: PropTypes.bool,
  };

  static defaultProps = {
    classes: {},
    onClose: () => {},
    onFinish: () => {},
    textAlign: undefined,
    showProgress: false,
    useAch: false,
    useBankAccounts: false,
    useCards: true,
    useMarketplace: true,
  };

  constructor() {
    super();

    this.state = { stripeLoaded: false };
  }

  /**
   * Listen for the load of the stripe library script, and when we know it's loaded
   * set the stripeLoaded state variable so we can render the stripe elements.
   */
  handleChangeClientState = (newState, addedTags) => {
    if (addedTags && addedTags.scriptTags) {
      const foundScript = addedTags.scriptTags.find(({ src }) => src === config.STRIPE_LIBRARY_URL);

      if (foundScript) {
        foundScript.addEventListener('load', () => this.setState({ stripeLoaded: true }), { once: true });
      }
    }
  };

  render() {
    const {
      textAlign,
      showProgress,
      onClose,
      onFinish,
      useMarketplace,
      useAch,
      useBankAccounts,
      useCards,
    } = this.props;
    const { stripeLoaded } = this.state;

    return (
      <>
        <Helmet onChangeClientState={this.handleChangeClientState}>
          {!stripeLoaded && <script id="stripe-js" src={config.STRIPE_LIBRARY_URL} async defer />}
        </Helmet>
        {stripeLoaded && (
          <StripeProvider apiKey={config.STRIPE_API_KEY}>
            <div style={{ display: 'flex', minHeight: '100%', flexDirection: 'column' }}>
              <Elements>
                <WizardWithContentAndStyles
                  useMarketplace={useMarketplace}
                  textAlign={textAlign}
                  showProgress={showProgress}
                  onClose={onClose}
                  onFinish={onFinish}
                  useAch={useAch}
                  useBankAccounts={useBankAccounts}
                  useCards={useCards}
                />
              </Elements>
            </div>
          </StripeProvider>
        )}
      </>
    );
  }
}

export default PaymentInterceptWizard;
