import {
  CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_REQUEST,
  CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_SUCCESS,
  CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_FAILURE,
  LIBRARY_UPDATE_LIBRARY_SEARCH_QUERY,
  LIBRARY_UPDATE_TABS_VALUE,
  CHECKLIST_TOGGLE_DISPLAY_USER_CHECKLISTS,
  CHECKLIST_TOGGLE_DISPLAY_PUBLISHED_CHECKLISTS,
  CHECKLIST_LOAD_INDEPENDENT_CHECKLISTS_REQUEST,
  CHECKLIST_LOAD_INDEPENDENT_CHECKLISTS_SUCCESS,
  CHECKLIST_LOAD_INDEPENDENT_CHECKLISTS_FAILURE,
  CHECKLIST_LOAD_PUBLISHED_CHECKLISTS_REQUEST,
  CHECKLIST_LOAD_PUBLISHED_CHECKLISTS_SUCCESS,
  CHECKLIST_LOAD_PUBLISHED_CHECKLISTS_FAILURE,
  CHECKLIST_LOAD_SINGLE_CHECKLIST_REQUEST,
  CHECKLIST_LOAD_SINGLE_CHECKLIST_SUCCESS,
  CHECKLIST_LOAD_SINGLE_CHECKLIST_FAILURE,
  CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_REQUEST,
  CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_SUCCESS,
  CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_FAILURE,
  CHECKLIST_REMOVE_CHECKLIST_FROM_LIBRARY_REQUEST,
  CHECKLIST_REMOVE_CHECKLIST_FROM_LIBRARY_SUCCESS,
  CHECKLIST_REMOVE_CHECKLIST_FROM_LIBRARY_FAILURE,
  SKILLS_LOAD_REQUEST,
  SKILLS_LOAD_SUCCESS,
  SKILLS_LOAD_FAILURE,
  SKILL_LOAD_REQUEST,
  SKILL_LOAD_SUCCESS,
  SKILL_LOAD_FAILURE,
  SKILLS_ACCESS,
} from './library.constants';

export const updateLibraryTabsValue = value => ({
  type: LIBRARY_UPDATE_TABS_VALUE,
  value,
});

export const updateLibrarySearchQuery = (searchQuery, isSkill) => ({
  type: LIBRARY_UPDATE_LIBRARY_SEARCH_QUERY,
  searchQuery,
  isSkill,
});

export const toggleDisplayUserChecklists = () => ({
  type: CHECKLIST_TOGGLE_DISPLAY_USER_CHECKLISTS,
});

export const toggleDisplayPublishedChecklists = () => ({
  type: CHECKLIST_TOGGLE_DISPLAY_PUBLISHED_CHECKLISTS,
});

export const cloneChecklistIntoPropertiesRequest = (propertyIds, checklistId) => ({
  type: CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_REQUEST,
  propertyIds,
  checklistId,
});

export const cloneChecklistIntoPropertiesSuccess = checklistId => ({
  type: CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_SUCCESS,
  checklistId,
});

export const cloneChecklistIntoPropertiesFailure = (checklistId, error) => ({
  type: CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_FAILURE,
  checklistId,
  error,
});

export const removeChecklistFromLibraryRequest = checklistId => ({
  type: CHECKLIST_REMOVE_CHECKLIST_FROM_LIBRARY_REQUEST,
  checklistId,
});

export const removeChecklistFromLibrarySuccess = checklistId => ({
  type: CHECKLIST_REMOVE_CHECKLIST_FROM_LIBRARY_SUCCESS,
  checklistId,
});

export const removeChecklistFromLibraryFailure = (checklistId, error) => ({
  type: CHECKLIST_REMOVE_CHECKLIST_FROM_LIBRARY_FAILURE,
  checklistId,
  error,
});

export const loadIndependentChecklistsRequest = () => ({
  type: CHECKLIST_LOAD_INDEPENDENT_CHECKLISTS_REQUEST,
});

export const loadIndependentChecklistsSuccess = checklists => ({
  type: CHECKLIST_LOAD_INDEPENDENT_CHECKLISTS_SUCCESS,
  checklists,
});

export const loadIndependentChecklistsFailure = error => ({
  type: CHECKLIST_LOAD_INDEPENDENT_CHECKLISTS_FAILURE,
  error,
});

export const loadPublishedChecklistsRequest = () => ({
  type: CHECKLIST_LOAD_PUBLISHED_CHECKLISTS_REQUEST,
});

export const loadPublishedChecklistsSuccess = checklists => ({
  type: CHECKLIST_LOAD_PUBLISHED_CHECKLISTS_SUCCESS,
  checklists,
});

export const loadPublishedChecklistsFailure = error => ({
  type: CHECKLIST_LOAD_PUBLISHED_CHECKLISTS_FAILURE,
  error,
});

export const loadChecklistRequest = (checklistId, checklistInstructionsId, published) => ({
  type: CHECKLIST_LOAD_SINGLE_CHECKLIST_REQUEST,
  checklistId,
  checklistInstructionsId,
  published,
});

export const loadChecklistSuccess = (
  checklistId,
  checklistInstructionsId,
  checklist,
  checklistInstructions,
  published,
) => ({
  type: CHECKLIST_LOAD_SINGLE_CHECKLIST_SUCCESS,
  checklistId,
  checklistInstructionsId,
  published,
  checklist,
  checklistInstructions,
});

export const loadChecklistFailure = error => ({
  type: CHECKLIST_LOAD_SINGLE_CHECKLIST_FAILURE,
  error,
});

export const createNewChecklistInLibraryRequest = () => ({
  type: CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_REQUEST,
});

export const createNewChecklistInLibrarySuccess = (
  checklistId,
  checklistInstructionsId,
  checklist,
  checklistInstructions,
) => ({
  type: CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_SUCCESS,
  checklistId,
  checklistInstructionsId,
  checklist,
  checklistInstructions,
});

export const createNewChecklistInLibraryFailure = error => ({
  type: CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_FAILURE,
  error,
});

// SKILLS
export const loadSkillsRequest = () => ({
  type: SKILLS_LOAD_REQUEST,
});

export const loadSkillsSuccess = skills => ({
  type: SKILLS_LOAD_SUCCESS,
  skills,
});

export const loadSkillsFailure = error => ({
  type: SKILLS_LOAD_FAILURE,
  error,
});

export const loadSkillRequest = skillId => ({
  type: SKILL_LOAD_REQUEST,
  skillId,
});

export const loadSkillSuccess = (skillId, skill) => ({
  type: SKILL_LOAD_SUCCESS,
  skillId,
  skill,
});

export const loadSkillFailure = error => ({
  type: SKILL_LOAD_FAILURE,
  error,
});

export const setSkillsAccess = hasAccess => ({
  type: SKILLS_ACCESS,
  hasAccess,
});
