import { put, spawn } from 'redux-saga/effects';
import log from 'loglevel';
import { push } from 'react-router-redux';
import { approveRedirect } from './herbert';
import { ROUTES } from '../paths';

export function* guardedFork(...args) {
  yield spawn(function* guardedForkRun() {
    try {
      const [effectCreator, ...rest] = args;
      yield effectCreator(...rest);
    } catch (e) {
      log.error('guardedFork', e);
      if (process.env.NODE_ENV === 'development') {
        throw e;
      } else {
        yield put(push(approveRedirect({ pathname: ROUTES.error })));
      }
    }
  });
}
