import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import t from '@properly/localization';
import { SpaceMediumBase, SpaceMedium, Title2, Title3, Regular, Button, Icon } from '@properly/components';
import { isMobileUser, isNoTouchDevice } from '@properly/common';
import classNames from 'classnames/bind';
import styles from './index.css';
import AppStoreBar from '../../containers/AppStoreBar';
import { selectCurrentUserEmail } from '../../selectors/globalSelector';
import { selectBranchIOLink } from '../../modules/branding/BrandingSelector';

const cx = classNames.bind(styles);

class PhoneOverlay extends PureComponent {
  static propTypes = {
    handleUpgrade: PropTypes.func,
    isUpgrade: PropTypes.bool,
    currentUserEmail: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { isMobileUser: false };
  }

  componentWillMount() {
    this.setState({ isMobileUser: isMobileUser() && !isNoTouchDevice() });
  }

  render() {
    if (!this.state.isMobileUser) return null;
    const { currentUserEmail, isUpgrade, handleUpgrade, branchIOLink } = this.props;

    return (
      <div className={cx('phone-overlay')}>
        <div className={cx('phone-overlay__inner')}>
          <Icon.LogoFull width="100px" byWidth />
          <SpaceMediumBase />
          <div>
            <Title2 type="nomargin">
              {branchIOLink ? t('phoneoverlay.title_bookingcom') : t('phoneoverlay.title')}
            </Title2>
          </div>
          <SpaceMedium />
          {!isUpgrade && (
            <div>
              <Regular>
                {branchIOLink
                  ? t('phoneoverlay.subtitle_bookingcom', {
                      email: <Title3 style={{ margin: '8px 0', wordBreak: 'break-all' }}>{currentUserEmail}</Title3>,
                    })
                  : t('phoneoverlay.subtitle', {
                      email: <Title3 style={{ margin: '8px 0', wordBreak: 'break-all' }}>{currentUserEmail}</Title3>,
                    })}
              </Regular>
            </div>
          )}
          {isUpgrade && (
            <Button
              onClick={handleUpgrade}
              style={{ border: 'none' }}
              types={['type-full-primary', 'width-flex', 'size-large']}
            >
              {t('phoneoverlay.upgrade_plan', {})}
            </Button>
          )}
          <SpaceMediumBase />
          <AppStoreBar branchIOLink={branchIOLink} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUserEmail: selectCurrentUserEmail()(state),
    branchIOLink: selectBranchIOLink(state),
  };
}

export default connect(mapStateToProps)(PhoneOverlay);
