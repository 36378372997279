import React from 'react';
import { getTitleFromJobRequest, prettyPrintDuration } from '@properly/common';
import { JobRequest, JobRequestIcons } from '@properly/components';
import classNames from 'classnames/bind';
import styles from './index.module.css';
import { mapJobRequestToColor } from '../../helper/herbert';

const cx = classNames.bind(styles);

const JobRequestCell = ({
  futureDate,
  cleaner,
  status,
  startDate,
  endDate,
  startTime,
  endTime,
  location,
  onClick,
  jobRequest,
  id,
}) => {
  const { jobStartTimeType, duration } = jobRequest || {};
  const title = getTitleFromJobRequest(jobRequest);
  const jobRequestStatus = jobRequest.subStatus;
  const isFlexible = jobStartTimeType === 'FLEXIBLE';
  const icons = (
    <JobRequestIcons
      isInProgress={!!jobRequest.isInProgress}
      isFinished={jobRequestStatus.isComplete}
      hasProblem={jobRequestStatus.hasProblem}
      isIncomplete={jobRequestStatus.isIncomplete}
    />
  );

  const prettyDuration = prettyPrintDuration(duration);
  const durationContent = <span>Duration: {prettyDuration}</span>;

  const spacer = <span>&nbsp;</span>;

  const timeColumn = (
    <div className={cx('job-request-cell--time')}>
      <span>
        <span className={cx('job-request-cell-l2')}>{startDate}</span>
        {spacer}
        <span className={cx('job-request-cell-l2')}>{startTime}</span>
      </span>

      <span className={cx('job-request-cell-l2')}>
        {spacer}-{spacer}
      </span>
      <span>
        <span className={cx('job-request-cell-l2')}>{endDate === startDate ? '' : endDate}</span>
        {endDate !== startDate && spacer}
        <span className={cx('job-request-cell-l2')}>{endTime}</span>
      </span>
      <div className={cx('job-request-cell-l3')}>
        {isFlexible ? 'Flexible - ' : ''}
        {durationContent}
      </div>
    </div>
  );

  return (
    <div
      onClick={onClick}
      data-id={id}
      className={cx('job-request-cell', {
        'job-request-cell--future': futureDate,
      })}
    >
      <div className={cx('job-request-cell--title', 'job-request-cell-l1')}>{title}</div>
      {timeColumn}
      {cleaner && <div className={cx('job-request-cell--cleaner', 'job-request-cell-l3')}>{cleaner}</div>}
      {location && <div className={cx('job-request-cell--location', 'job-request-cell-l3')}>{location}</div>}
      <div className={cx('job-request-cell--job', 'job-request-cell-label')}>
        <JobRequest statusString={status} icons={icons} status={mapJobRequestToColor(jobRequest, cleaner, status)} />
      </div>
    </div>
  );
};

export default JobRequestCell;
