import { ParseCalendarEvent } from '@properly/model';
import Property from '../../model/property';
import Report from '../../model/report';
import Contact from '../../model/contact';
import JobInstructions from '../../model/jobInstructions';
import UserData from '../../model/userData';
import User from '../../model/user';
import Plan from '../../model/plan';
import Job from '../../model/job';
import PhotoFeedback from '../../model/photoFeedback';
import Ical from '../../model/ical';
import Subscription from '../../model/subscription';
import Trigger from '../../model/trigger';
import Profile from '../../model/profile';
import Team from '../../model/team';
import Collaborator from '../../model/collaborator';

/* eslint-disable no-underscore-dangle */

export const getValueFromKeyOrGetter = (obj, key) => {
  const hasGet = obj && !!obj.get;
  const value = hasGet ? obj.get(key) : obj[key];

  // Test to see if it is a crazy Parse iso date
  if (value && value.__type === 'Date') {
    return value.iso;
  }

  return value;
};

const getIdFromObject = object => object && (object.id || object.objectId);

export function _mapParseUserToUser(parseUser) {
  return new User(
    parseUser.id || parseUser.objectId,
    getValueFromKeyOrGetter(parseUser, 'firstName'),
    getValueFromKeyOrGetter(parseUser, 'lastName'),
    getValueFromKeyOrGetter(parseUser, 'email'),
    getValueFromKeyOrGetter(parseUser, 'phone'),
    getValueFromKeyOrGetter(parseUser, 'phoneCountryCode'),
    getValueFromKeyOrGetter(parseUser, 'phoneRegionalNumber'),
    getValueFromKeyOrGetter(parseUser, 'isHost'),
    getValueFromKeyOrGetter(parseUser, 'isCleaner'),
    getValueFromKeyOrGetter(parseUser, 'pictureUrl'),
    getValueFromKeyOrGetter(parseUser, 'bio'),
    getValueFromKeyOrGetter(parseUser, 'phoneVerified'),
    getValueFromKeyOrGetter(parseUser, 'notificationSettings'),
    getValueFromKeyOrGetter(parseUser, 'role'),
    getValueFromKeyOrGetter(parseUser, 'connectedAccounts'),
    getValueFromKeyOrGetter(parseUser, 'hostFRESeen'),
    getValueFromKeyOrGetter(parseUser, 'webFRESeen'),
    getValueFromKeyOrGetter(parseUser, 'subscription')
      ? _mapParseSubscriptionToSubscription(getValueFromKeyOrGetter(parseUser, 'subscription'))
      : null,
    getValueFromKeyOrGetter(parseUser, 'language'),
    getValueFromKeyOrGetter(parseUser, 'permissions'),
    getValueFromKeyOrGetter(parseUser, 'teams'),
    getValueFromKeyOrGetter(parseUser, 'rights'),
    getValueFromKeyOrGetter(parseUser, 'customBrandingId'),
    getValueFromKeyOrGetter(parseUser, 'disableOnBoardingVideo'),
    getValueFromKeyOrGetter(parseUser, 'isStandaloneUser'),
    getValueFromKeyOrGetter(parseUser, 'actAsRole'),
    getValueFromKeyOrGetter(parseUser, 'personalRole'),
    getValueFromKeyOrGetter(parseUser, 'hardRefresh'),
    getValueFromKeyOrGetter(parseUser, 'companyName'),
    getValueFromKeyOrGetter(parseUser, 'features'),
  );
}

export function _mapParseSubscriptionToSubscription(parseSubscription) {
  return new Subscription(
    parseSubscription.id || parseSubscription.objectId,
    getValueFromKeyOrGetter(parseSubscription, 'subscriptionProviderSubscriptionId'),
    getValueFromKeyOrGetter(parseSubscription, 'subscriptionProviderToken'),
    getValueFromKeyOrGetter(parseSubscription, 'currentPlanTitle'),
    getValueFromKeyOrGetter(parseSubscription, 'priceType'),
    getValueFromKeyOrGetter(parseSubscription, 'price'),
    getValueFromKeyOrGetter(parseSubscription, 'allowedJobsMonth'),
    getValueFromKeyOrGetter(parseSubscription, 'jobsUsedMonth'),
    getValueFromKeyOrGetter(parseSubscription, 'expiration'),
    getValueFromKeyOrGetter(parseSubscription, 'nextRenewal'),
    getValueFromKeyOrGetter(parseSubscription, 'lastRenewal'),
    getValueFromKeyOrGetter(parseSubscription, 'currentPlanId'),
    getValueFromKeyOrGetter(parseSubscription, 'features'),
    getValueFromKeyOrGetter(parseSubscription, 'annualSubscription'),
    getValueFromKeyOrGetter(parseSubscription, 'removePartnersTrialAccessBy'),
  );
}

export function _mapParseTriggerToTrigger(parseTrigger) {
  return new Trigger(
    parseTrigger.id,
    parseTrigger.get('title'),
    parseTrigger.get('propertyId'),
    parseTrigger.get('jobId'),
    parseTrigger.get('type'),
    parseTrigger.get('deleted'),
    parseTrigger.get('updatedAt'),
    parseTrigger.get('createdAt'),
    parseTrigger.get('settings'),
  );
}

export function _mapParsePropertyToProperty(property) {
  return new Property(
    property.id || property.objectId,
    getValueFromKeyOrGetter(property, 'title'),
    getValueFromKeyOrGetter(property, 'address'),
    getValueFromKeyOrGetter(property, 'location'),
    getValueFromKeyOrGetter(property, 'pictureUrl'),
    getValueFromKeyOrGetter(property, 'timeZone'),
    getValueFromKeyOrGetter(property, 'countryCode'),
    getValueFromKeyOrGetter(property, 'numOfBedrooms'),
    getValueFromKeyOrGetter(property, 'numOfBeds'),
    getValueFromKeyOrGetter(property, 'numOfBathrooms'),
    getValueFromKeyOrGetter(property, 'type'),
    getValueFromKeyOrGetter(property, 'deleted'),
    getValueFromKeyOrGetter(property, 'detailsAccess'),
    getValueFromKeyOrGetter(property, 'detailsGarbage'),
    getValueFromKeyOrGetter(property, 'detailsParking'),
    getValueFromKeyOrGetter(property, 'detailsWifiName'),
    getValueFromKeyOrGetter(property, 'detailsWifiDescription'),
    getValueFromKeyOrGetter(property, 'detailsWifiPassword'),
    getValueFromKeyOrGetter(property, 'detailsInformation'),
    getValueFromKeyOrGetter(property, 'user')
      ? getValueFromKeyOrGetter(property, 'user').id || getValueFromKeyOrGetter(property, 'user').objectId
      : null,
    getValueFromKeyOrGetter(property, 'pictureUrls'),
    getValueFromKeyOrGetter(property, 'role')
      ? getValueFromKeyOrGetter(property, 'role').id || getValueFromKeyOrGetter(property, 'role').objectId
      : null,
    getValueFromKeyOrGetter(property, 'noMerge'),
    getValueFromKeyOrGetter(property, 'mergedTo'),
    getValueFromKeyOrGetter(property, 'partner'),
    getValueFromKeyOrGetter(property, 'partnerPropertyId'),
    getValueFromKeyOrGetter(property, 'defaultJob')
      ? getValueFromKeyOrGetter(property, 'defaultJob').id || getValueFromKeyOrGetter(property, 'defaultJob').objectId
      : undefined,
    getValueFromKeyOrGetter(property, 'hasUserTitle'),
    getValueFromKeyOrGetter(property, 'sample'),
    getValueFromKeyOrGetter(property, 'ownerRole'),
    getValueFromKeyOrGetter(property, 'propertyRole'),
    getValueFromKeyOrGetter(property, 'userData'),
    getValueFromKeyOrGetter(property, 'toBeDeleted'),
    getValueFromKeyOrGetter(property, 'alexaDetails'),
    getValueFromKeyOrGetter(property, 'otherAttributes'),
    getValueFromKeyOrGetter(property, 'bedConfiguration'),
    getValueFromKeyOrGetter(property, 'tags'),
    getValueFromKeyOrGetter(property, 'proMarketId'),
    getValueFromKeyOrGetter(property, 'bookingComSanctionedProperty'),
    getValueFromKeyOrGetter(property, 'createdAt'),
  );
}

export function _mapParseReportToReport(report) {
  return new Report(
    report.id,
    report.get('title'),
    report.get('filter'),
    report.get('createdAt'),
    report.get('updatedAt'),
    report.get('user'),
    report.get('deleted'),
  );
}

export function _mapParseCalendarEventToCalendarEvent(event) {
  return new ParseCalendarEvent(
    event.id || event.objectId,
    getValueFromKeyOrGetter(event, 'type'),
    getValueFromKeyOrGetter(event, 'jobRequestId'),
    getValueFromKeyOrGetter(event, 'bookingId'),
    getValueFromKeyOrGetter(event, 'triggerId'),
    getValueFromKeyOrGetter(event, 'propertyId'),
    getValueFromKeyOrGetter(event, 'timeZone'),
    getValueFromKeyOrGetter(event, 'propertyTitle'),
    getValueFromKeyOrGetter(event, 'startTime'),
    getValueFromKeyOrGetter(event, 'endTime'),
    getValueFromKeyOrGetter(event, 'status'),
    getValueFromKeyOrGetter(event, 'eventStatus'),
    getValueFromKeyOrGetter(event, 'needsCTA'),
    getValueFromKeyOrGetter(event, 'deleted'),
    getValueFromKeyOrGetter(event, 'payload'),
    getValueFromKeyOrGetter(event, 'days'),
    getValueFromKeyOrGetter(event, 'propertyAddress'),
    getValueFromKeyOrGetter(event, 'createdAt'),
    getValueFromKeyOrGetter(event, 'jobSnapshotId'),
  );
}

export function _mapParseTeamToTeam(team) {
  return new Team(team.objectId, team.name, team.description, team.pictureUrl, team.adminEmails);
}

export function _mapParsePhotoFeedbackToPhotoFeedback(pf) {
  return new PhotoFeedback(
    pf.id,
    pf.version,
    pf.jobRequestId,
    pf.pictureUrl,
    pf.type,
    pf.viewed,
    pf.comment,
    pf.thumbs,
    pf.sender,
    pf.updatedAt,
    pf.propertyTimeZone,
    pf.clientId,
  );
}

export function _mapParseUserDataToUserData(userData) {
  return new UserData(
    userData.objectId,
    userData.firstName,
    userData.lastName,
    userData.shortName,
    userData.email,
    userData.phone,
    userData.pictureUrl,
    userData.bio,
    userData.signedUp,
    userData.signedUpDate,
    userData.phoneCountryCode,
    userData.phoneRegionalNumber,
  );
}

export function _mapParseContactToContact(contact) {
  const hasGet = contact && !!contact.get;
  const get = key => (hasGet ? contact.get(key) : contact[key]);

  return new Contact(
    contact.id || contact.objectId,
    get('ownerId'),
    get('contactId'),
    get('userData') ? _mapParseUserDataToUserData(get('userData')) : null,
    get('lastJobSent'),
    get('deleted'),
    get('profile') ? _mapParseProfileToProfile(get('profile')) : null,
    get('isSuggested'),
  );
}

export function _mapParsePlanToPlan(plan) {
  return new Plan(
    plan.id,
    plan.get('planId'),
    plan.get('features'),
    plan.get('title'),
    plan.get('price'),
    plan.get('priceType'),
    plan.get('public'),
    plan.get('annual'),
    plan.get('weight'),
    plan.get('highlights'),
    plan.get('contactUs'),
    plan.get('mostPopular'),
  );
}

export function _mapParseIcalToIcal(ical) {
  return new Ical(
    ical.id,
    ical.get('title'),
    ical.get('property').id,
    ical.get('ownerRole'),
    ical.get('propertyRole'),
    ical.get('link'),
    ical.get('partner'),
  );
}

export function _mapParseJobToJob(job) {
  return new Job(
    getIdFromObject(job),
    getIdFromObject(getValueFromKeyOrGetter(job, 'defaultProperty')),
    getValueFromKeyOrGetter(job, 'title'),
    getValueFromKeyOrGetter(job, 'totalStepCount'),
    getValueFromKeyOrGetter(job, 'totalTaskCount'),
    getIdFromObject(getValueFromKeyOrGetter(job, 'jobInstructions')),
    getValueFromKeyOrGetter(job, 'pictureUrl'),
    getValueFromKeyOrGetter(job, 'totalVerificationCount'),
    getIdFromObject(getValueFromKeyOrGetter(job, 'user')),
    getValueFromKeyOrGetter(job, 'jobDescription'),
    getValueFromKeyOrGetter(job, 'generalTasks'),
    getValueFromKeyOrGetter(job, 'deleted'),
    getValueFromKeyOrGetter(job, 'weight'),
    getValueFromKeyOrGetter(job, 'role') ? getIdFromObject(getValueFromKeyOrGetter(job, 'role')) : null,
    getValueFromKeyOrGetter(job, 'createdAt'),
    getValueFromKeyOrGetter(job, 'defaultSettings'),
  );
}

export function _mapParseProfileToProfile(profile) {
  const hasGet = profile && !!profile.get;
  const get = key => (hasGet ? profile.get(key) : profile[key]);

  return new Profile(
    profile.id || profile.objectId,
    get('userData') ? _mapParseUserDataToUserData(get('userData')) : null,
    get('numOfJobRequestsDone30'),
    get('numOfHosts'),
    get('signedUpDate'),
    get('numOfJobRequestsDone'),
    get('propertyPhotos'),
    get('offeredServices'),
    get('badges'),
    get('responseTime'),
    get('numOfProperties'),
    get('listSuggestedCleaner'),
  );
}

export function _mapParseCollaboratorTCollaborator(collaborator) {
  return new Collaborator(
    collaborator.enabled,
    collaborator.internal,
    collaborator.properties,
    collaborator.rights,
    collaborator.userData,
    collaborator.userId,
    collaborator.teamId,
    collaborator.createdAt,
    collaborator.partnerType,
  );
}

export function _mapParseJobInstructionsToJobInstructions(jobInstruction) {
  return new JobInstructions(
    getIdFromObject(jobInstruction),
    getValueFromKeyOrGetter(jobInstruction, 'steps'),
    getValueFromKeyOrGetter(jobInstruction, 'role')
      ? getIdFromObject(getValueFromKeyOrGetter(jobInstruction, 'role'))
      : null,
    getValueFromKeyOrGetter(jobInstruction, 'user')
      ? getIdFromObject(getValueFromKeyOrGetter(jobInstruction, 'user'))
      : null,
  );
}
