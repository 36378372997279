import gql from 'graphql-tag';

export const SUBSCRIPTION_LISTEN_FOR_COMMENTS = gql`
  subscription listenForComments($jobSnapshotId: ID!, $userRole: RoleType!) {
    listenForComments(jobSnapshotId: $jobSnapshotId, userRole: $userRole) {
      commentId
      message
      pictureIdentifier
      createdAt
      updatedAt
      jobSnapshotId
      sender {
        ...senderFields
      }
    }
  }

  fragment senderFields on senderFields {
    id
    companyName
    userData {
      ...userFields
    }
  }

  fragment userFields on userFields {
    id
    firstName
    lastName
  }
`;
