import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import log from 'loglevel';
import t from '@properly/localization';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import moment from 'moment-timezone';
import map from 'lodash/map';
import noop from 'lodash/noop';
import keyBy from 'lodash/keyBy';
import {
  Regular,
  DividerLine,
  Button,
  LoadingSpinner,
  SpacedHeadline,
  Small,
  SpaceSmall,
  SpaceMedium,
  Icon,
} from '@properly/components';
import { connect } from 'react-redux';
import AccountProviderSection from '../../../../components/AccountProviderSection';
import { goTo, setModal } from '../../../../actions/globalActions';
import { ROUTES } from '../../../../paths';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import * as selectors from '../state/PropertySelectors';
import { setInitIcalSaga } from '../state/PropertyActions';
import PropertyAlexaModal, { PROPERTY_ALEXA_MODAL } from '../components/PropertyAlexaModal';
import PropertyIcalModal from '../components/PropertyIcalModal';
import {
  GetPropertyICalConnections,
  CreatePropertyICalConnection,
  DeletePropertyICalConnection,
} from '../state/PropertyQuery';
import { openPricingModalSaga } from '../../pricing/PricingActions';
import HasPermission from '../../../../hoc/HasPermission';

const ConnectionButtonContent = ({ children, icon }) => (
  <Grid container justify="center" alignItems="center">
    {icon || <Icon.IcConnect />}
    <span style={{ marginTop: '2px', marginLeft: '8px' }}>{children}</span>
  </Grid>
);

const ConnectionContainer = ({
  label,
  content,
  connectButtonDisabled,
  connectButtonText,
  onConnect,
  loading,
  children,
}) => (
  <Grid container direction="column" style={{ position: 'relative', maxWidth: '900px', margin: '0 auto' }}>
    <SpaceSmall />
    <SpacedHeadline>{label}</SpacedHeadline>
    <Grid container direction="row" justify="space-between" wrap="nowrap">
      <Grid item style={{ flexGrow: 1 }}>
        <Small>{content}</Small>
      </Grid>

      <Grid item style={{ marginTop: '2px', marginLeft: '24px', flexShrink: 0, width: '40px' }}>
        {loading && <LoadingSpinner />}
      </Grid>

      <Grid item style={{ marginLeft: '24px', flexShrink: 0 }}>
        <HasPermission
          hasAccessFormatter={hasAccessRes => hasAccessRes.isStandalone}
          renderWithPermission={() => (
            <Button
              onClick={onConnect || noop}
              disabled={connectButtonDisabled}
              types={['size-medium', 'type-border-grey']}
            >
              <ConnectionButtonContent icon={<Icon.IcConnect />}>
                {connectButtonText || t('accountprovidersection.connect')}
              </ConnectionButtonContent>
            </Button>
          )}
        />
      </Grid>
    </Grid>

    {children && <SpaceSmall />}
    {children}
    <SpaceSmall />
    <DividerLine type={['bottom', 'light']} />
    <SpaceSmall />
  </Grid>
);

const getLastSyncDateText = lastSyncDate =>
  lastSyncDate ? `${t('properties.ical_last_synced')} ${moment(lastSyncDate).from()}` : t('properties.ical_no_sync');

const ICalContainer = ({ title, link, lastSyncDate, onDelete }) => (
  <Grid container wrap="nowrap" justify="space-between" direction="row" spacing={3}>
    <Grid
      item
      style={{
        flexShrink: 0,
        width: '160px',
        maxWidth: '160px',
      }}
    >
      <Grid container direction="row" wrap="nowrap" style={{ overflow: 'hidden' }}>
        <Icon.IcCalendar />
        <Regular title={title} type="medium" style={{ marginTop: '4px', marginLeft: '6px' }}>
          {title}
        </Regular>
      </Grid>
    </Grid>
    <Grid item zeroMinWidth style={{ flexGrow: 1 }}>
      <Regular
        style={{
          display: 'block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        noWrap
        title={link}
      >
        {link}
      </Regular>
      <Regular
        style={{
          display: 'block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        type="grey"
        title={lastSyncDate ? moment(lastSyncDate).format('lll') : ''}
      >
        {getLastSyncDateText(lastSyncDate) || ''}
      </Regular>
    </Grid>
    <Grid item style={{ flexShrink: 0 }}>
      <Grid container spacing={1} justify="space-between">
        <HasPermission
          hasAccessFormatter={hasAccessRes => hasAccessRes.isStandalone}
          renderWithPermission={() => (
            <Button style={{ margin: '0 4px' }} onClick={onDelete || noop} types={['size-medium', 'type-border-grey']}>
              <ConnectionButtonContent icon={<Icon.IcDisconnect />}>{t('account.disconnect')}</ConnectionButtonContent>
            </Button>
          )}
        />
      </Grid>
    </Grid>
  </Grid>
);

class PropertyDetailConnectionsContainer extends Component {
  state = {
    iCalModalOpen: false,
    successfullyLoadedICal: false,
  };

  componentDidMount() {
    this.handleInit();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.property !== this.props.property) {
      this.handleInit();
    }
  }

  componentWillReceiveProps = nextProps => {
    const fromiCalLoadingToNotLoading = this.props.iCalLoading && !nextProps.iCalLoading;
    // Once the iCal list has successfully loaded once, we need to keep this state to
    // disable the loader from appearing again on the next polling
    if (fromiCalLoadingToNotLoading && !nextProps.iCalLoadingError) {
      this.setState({ successfullyLoadedICal: true });
    }
  };

  handleInit() {
    this.props.initIcal(this.props.property);
  }

  handleAddLink(title, link) {
    const { createICalConnectionMutate, propertyId } = this.props;

    createICalConnectionMutate({
      title,
      link,
      targetPropertyId: propertyId,
    }).catch(err => this.setState({ iCalCreateError: err }));
  }

  mapAccount(accounts) {
    return map(accounts || {}, account => ({
      icon: account.partner,
      id: account.partnerPropertyId,
    }));
  }

  handleIcalSubmit = ({ title, link }) => {
    this.handleAddLink(title, link);
    this.handleCloseIcalModal();
  };

  handleOpenNewIcalModal = () => {
    const { noActionOnICalConnectionClick, openPricingModal, openModalOnICalConnectionClick } = this.props;

    if (noActionOnICalConnectionClick) {
      return;
    }

    if (openModalOnICalConnectionClick) {
      openPricingModal();
      return;
    }

    this.setState({ iCalDeleteError: null, iCalCreateError: null, iCalModalOpen: true });
  };

  handleCloseIcalModal = () => this.setState({ iCalModalOpen: false });

  renderAccounts(sections) {
    const { partnerById } = this.props;
    return map(sections, section => {
      const partner = partnerById[section.icon] || {};
      const { icon, text } = partner;
      return <AccountProviderSection.Light key={section.id} icon={icon} id={section.id} text={text} />;
    });
  }

  handleDeleteConnection = linkId => {
    const { deletePropertyIdMutation } = this.props;
    this.setState({ iCalDeleteError: null, iCalCreateError: null });
    deletePropertyIdMutation(linkId).catch(err => this.setState({ iCalDeleteError: err }));
  };

  renderLinks(links) {
    if (!links || !links.length) {
      return null;
    }

    return (
      <Card style={{ padding: '6px 24px 12px' }}>
        {map(links, (link, index) => (
          <Grid container style={{ position: 'relative' }} key={link.partnerPropertyId}>
            <SpaceMedium />
            <ICalContainer
              title={link.title}
              id={link.id}
              link={link.link}
              onDelete={() => this.handleDeleteConnection(link.id)}
              lastSyncDate={link.lastSync}
            />

            <SpaceSmall />
            {index !== links.length - 1 && <DividerLine type={['bottom', 'light']} />}
          </Grid>
        ))}
      </Card>
    );
  }

  renderError(error) {
    if (!error) {
      return null;
    }

    log.error('iCal graphql error', error);

    return (
      <Card style={{ padding: '6px 24px 12px' }}>
        <Regular type={['red', 'medium']}>{t('login.error')}</Regular>
      </Card>
    );
  }

  renderConnectedAccounts() {
    const { connectedAccounts, iCalState } = this.props;
    const { isLoadingAccounts } = iCalState || {};

    const accounts = this.mapAccount(connectedAccounts);
    const showAccounts = !isLoadingAccounts && !!accounts && accounts.length > 0;

    return (
      <ConnectionContainer
        label={t('properties.connected_properties')}
        content={t('account.connect_only')}
        loading={isLoadingAccounts}
        onConnect={() => this.props.goTo(ROUTES.settingsConnections)}
      >
        {showAccounts && <Card style={{ padding: '24px' }}>{this.renderAccounts(accounts)}</Card>}
      </ConnectionContainer>
    );
  }

  renderIcalLinks() {
    const { ICalAdaptorSettings, iCalLoading, iCalLoadingError } = this.props;
    const { successfullyLoadedICal, iCalModalOpen, iCalCreateError, iCalDeleteError } = this.state;

    return (
      <>
        <ConnectionContainer
          label={t('properties.ical')}
          content={t('properties.ical_info')}
          onConnect={this.handleOpenNewIcalModal}
          loading={iCalLoading && !successfullyLoadedICal}
        >
          {this.renderLinks(ICalAdaptorSettings)}
          {iCalLoadingError && this.renderError(iCalLoadingError)}
          {iCalCreateError && this.renderError(iCalCreateError)}
          {iCalDeleteError && this.renderError(iCalDeleteError)}
        </ConnectionContainer>
        <PropertyIcalModal
          settings={ICalAdaptorSettings}
          show={iCalModalOpen}
          onClose={this.handleCloseIcalModal}
          onSubmit={this.handleIcalSubmit}
        />
      </>
    );
  }

  renderAmazonEcho() {
    const { propertyData } = this.props;
    return (
      <>
        <ConnectionContainer
          label="Amazon Echo"
          content={t('amazon_alexa.to_connect')}
          connectButtonDisabled={propertyData.hasAlexaSetup}
          connectButtonText={
            propertyData.hasAlexaSetup ? t('properties.connected') : t('accountprovidersection.connect')
          }
          onConnect={() => this.props.openAlexaModal()}
        />
        <PropertyAlexaModal propertyId={propertyData.objectId} />
      </>
    );
  }

  render() {
    return (
      <>
        <SpaceSmall />
        {this.renderConnectedAccounts()}
        {this.renderIcalLinks()}
        {this.renderAmazonEcho()}
      </>
    );
  }
}

PropertyDetailConnectionsContainer.propTypes = {
  property: PropTypes.string.isRequired,
};

function mapStateToProps(state, props) {
  const { ICalAdaptorSettings, refetch } = props;

  const config = selectorsGlobal.selectConfig(state, props);

  const iCalState = selectors.selectIcal(state, props);
  const iCalStateJS = (iCalState && iCalState.toJS()) || {};
  const { accounts: connectedAccounts, canUserFeatureResponse } = iCalStateJS;

  return {
    propertyData: selectorsGlobal.selectProperty(props.property)(state, props),
    propertyId: props.property,
    partnerById: keyBy((config && config.partner) || [], 'id') || {},
    connectedAccounts,
    iCalState: iCalStateJS,

    openModalOnICalConnectionClick: canUserFeatureResponse === 2,
    noActionOnICalConnectionClick: canUserFeatureResponse === 0,

    ICalAdaptorSettings,
    refetch,
  };
}

const mapDispatchToProps = dispatch => ({
  goTo: route => dispatch(goTo(route)),
  initIcal: property => dispatch(setInitIcalSaga(property)),
  openAlexaModal: () => dispatch(setModal(true, PROPERTY_ALEXA_MODAL, {})),
  openPricingModal: () =>
    dispatch(
      openPricingModalSaga(
        'default',
        {
          defaultKind: 'upgradeplan',
        },
        'ical',
      ),
    ),
});

const CreateMutation = graphql(CreatePropertyICalConnection, {
  options: props => ({
    update: (cache, { data: { CreateICalAdaptorSetting } }) => {
      const { ICalAdaptorSettings } = cache.readQuery({
        query: GetPropertyICalConnections,
        variables: { propertyId: props.property },
      });

      cache.writeQuery({
        query: GetPropertyICalConnections,
        variables: { propertyId: props.property },
        data: { ICalAdaptorSettings: ICalAdaptorSettings.concat([CreateICalAdaptorSetting]) },
      });
    },
  }),
  props: ({ mutate }) => ({
    createICalConnectionMutate: iCalConnection =>
      mutate({
        variables: { setting: iCalConnection },
      }),
  }),
});

const DeleteMutation = graphql(DeletePropertyICalConnection, {
  options: props => ({
    update: (cache, { data: { DeleteICalAdaptorSetting } }) => {
      const { ICalAdaptorSettings } = cache.readQuery({
        query: GetPropertyICalConnections,
        variables: { propertyId: props.property },
      });

      cache.writeQuery({
        query: GetPropertyICalConnections,
        variables: { propertyId: props.property },
        data: { ICalAdaptorSettings: ICalAdaptorSettings.filter(({ id }) => id !== DeleteICalAdaptorSetting.id) },
      });
    },
  }),
  props: ({ mutate }) => ({ deletePropertyIdMutation: id => mutate({ variables: { settingId: id } }) }),
});

const ListQuery = graphql(GetPropertyICalConnections, {
  options: props => ({
    variables: { propertyId: props.property },
    pollInterval: 5000,
  }),
  props: ({ data }) => {
    const { ICalAdaptorSettings, refetch, loading, error } = data;
    return { iCalLoadingError: error, iCalLoading: loading, ICalAdaptorSettings, refetch };
  },
});

export default compose(
  CreateMutation,
  DeleteMutation,
  ListQuery,
  connect(mapStateToProps, mapDispatchToProps),
)(PropertyDetailConnectionsContainer);
