import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Icon } from '@properly/components';
import { ThumbsUpBubble } from '../../components/ThumbsUpBarElements';
import { selectPhotoGroupedByImageId } from '../../selectors/globalSelector';
import { mapUpDownToIcon } from '../../helper/herbert';

class ThumbsBubbleBar extends PureComponent {
  static propTypes = {
    oldJobRequestId: PropTypes.string.isRequired,
    imageId: PropTypes.string.isRequired,
  };

  bubbleStyle = { height: '24px', width: '24px', iconSize: '24px' };

  render() {
    const { hasComment, hasUpOrDown } = this.props;
    return (
      <div style={{ display: 'flex' }}>
        {hasComment && <ThumbsUpBubble {...this.bubbleStyle} spaceLeft="4px" isActive icon={Icon.IcComment2} />}
        {hasUpOrDown && (
          <ThumbsUpBubble
            spaceLeft="4px"
            isActive
            {...this.bubbleStyle}
            icon={mapUpDownToIcon(this.props.thumbs.thumbs)}
          />
        )}
      </div>
    );
  }
}

export default compose(
  connect(
    (state, props) => {
      const mappedData = selectPhotoGroupedByImageId()(state, props);
      return {
        hasComment: mappedData.hasComment,
        hasUpOrDown: mappedData.hasUpOrDown,
        thumbs: mappedData.thumbs,
      };
    },
    null,
  ),
)(ThumbsBubbleBar);
