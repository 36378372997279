import React from 'react';
import { Route, IndexRoute, Redirect } from 'react-router';
import { BookingDetailsModal } from '@properly/components';
import { setWindowTitle } from '@properly/common';

import ContactsContainer from './modules/desktopApp/contacts/containers/ContactContainer';
import ContactDetailContainer from './modules/desktopApp/contacts/containers/ContactDetailContainer';
import BookingDetailsContainer from './containers/BookingDetailsContainer';

const renderBookingDetailsContainer = props => <BookingDetailsContainer {...props} />;

export default () => (
  <>
    <Route path="contacts" component={ContactsContainer} />

    {/* OLD ROUTE */}
    <Redirect exact from="contacts/:id/accepted" to="contacts/:id/upcoming" />
    <Redirect from="contacts/:id" to="contacts/:id/upcoming" />

    <Route path="contacts/:id/:contactDetailView" component={ContactsContainer}>
      <IndexRoute component={ContactDetailContainer} onEnter={setWindowTitle('titles.contacts')}>
        <Route
          path="booking/:propertyId/:bookingId"
          component={props => (
            <BookingDetailsModal
              bookingId={props.params.bookingId}
              propertyId={props.params.propertyId}
              handleCancel={() => props.router.replace(`contacts/${props.params.id}/${props.params.contactDetailView}`)}
              renderChildren={renderBookingDetailsContainer}
              show
            />
          )}
        />
      </IndexRoute>
    </Route>
  </>
);
