import React from 'react';
import { SearchField } from '@properly/components';
import t from '@properly/localization';
import { LibrarySearch } from './LibrarySearch';

export const LibrarySkillsSearch = ({ searchQuery, updateSearchQuery }) => {
  const handleOnUpdateSearchQuery = searchValue => updateSearchQuery(searchValue, true);

  return (
    <LibrarySearch>
      <SearchField
        fitInBox
        onGrey
        placeholder={t('skills.search')}
        value={searchQuery}
        iconLocation="left"
        onChange={handleOnUpdateSearchQuery}
      />
    </LibrarySearch>
  );
};
