import React from 'react';
import { Route } from 'react-router';
import { setWindowTitle } from '@properly/common';

import authRoutes from './routes.auth';
import libraryRoutes from './routes.library';
import calendarRoutes from './routes.calendar';
import todoRoutes from './routes.todo';
import propertyRoutes from './routes.property';
import contactRoutes from './routes.contact';
import accountRoutes from './routes.account';
import checklistRoutes from './routes.checklist';
import reportRoutes from './routes.report';
import stripeRoutes from './routes.stripe';
import settingsRoutes from './routes.settings';

// onboarding
import bookingComOnboarding from './routes.onboarding.bookingcom';

// load always
import App from './containers/App';
import NotFound from './containers/NotFound';
import ErrorPage from './containers/ErrorPage';

import CheckoutContainer from './modules/desktopApp/checkout/CheckoutContainer';

const Routes = () => (
  <Route path="/" component={App}>
    {bookingComOnboarding()}
    {authRoutes()}
    {libraryRoutes()}
    {calendarRoutes()}
    {todoRoutes()}
    {propertyRoutes()}
    {contactRoutes()}
    {checklistRoutes()}
    {accountRoutes()}
    {reportRoutes()}
    {settingsRoutes()}
    {stripeRoutes()}

    <Route path="checkout" component={CheckoutContainer} onEnter={setWindowTitle('titles.checkout')} />
    <Route path="error" component={ErrorPage} />
    <Route path="*" component={NotFound} />
  </Route>
);

export default Routes;
