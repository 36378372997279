import React from 'react';
import map from 'lodash/map';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

function TodosElementMain({ children, isClickable, onClick, isRed, modifier }) {
  const noop = () => {};
  const onClickSafe = (isClickable && typeof onClick === 'function' && onClick) || noop;
  return (
    <div
      onClick={onClickSafe}
      tabIndex="0"
      role="treeitem"
      className={cx(
        'todosele',
        {
          'todosele--red': isRed,
          'todosele--clickable': isClickable,
        },
        map(modifier, mod => `todosele--${mod}`),
      )}
    >
      {children}
    </div>
  );
}

function TodosElementMainInner({ children }) {
  return <div className={cx('todosele__inner')}>{children}</div>;
}

function TodosElementMainMain({ children }) {
  return <div className={cx('todosele__main')}>{children}</div>;
}

function TodosElementSection({ children, width, noPadding, extraStyles }) {
  const extras = noPadding ? { padding: 0, ...extraStyles } : { ...extraStyles };
  return (
    <div className={cx('todosele__section')} style={{ width, ...extras }}>
      {children}
    </div>
  );
}

function TodosElementMarker({ children }) {
  return <div className={cx('todosele__marker')}>{children}</div>;
}

function TodosTextEle({ modifier, children, preElement, componentClass = 'div', onClick, styleOverwrite }) {
  const Component = componentClass;
  return (
    <Component style={styleOverwrite} onClick={onClick} className={cx(map(modifier, mod => `todosele__${mod}`))}>
      {preElement && <span className={cx('todosele__preele')}>{preElement}</span>}
      {children}
    </Component>
  );
}
function TodosElementIconPos({ type, children }) {
  return <div className={cx(`todosele__icon--${type}`)}>{children}</div>;
}

export {
  TodosElementMain,
  TodosElementMainInner,
  TodosElementMainMain,
  TodosElementSection,
  TodosTextEle,
  TodosElementMarker,
  TodosElementIconPos,
};
