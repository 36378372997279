import { delay } from 'redux-saga';
import log from 'loglevel';
import { MapParseObjToGraphQLCalendarEvent } from '@properly/model';
import { put, fork, take, call, cancel, select, takeLatest } from 'redux-saga/effects';
import * as types from '../../../../types';
import { addLiveCalendarEvents, deleteLiveCalendarEvents, resetCalendar, initCalendar } from './CalendarActions';
import { syncBookings } from '../../data';
import { handleEventChannelEvent } from '../../../../sagas/global';
import { selectCurrentUser } from '../../../../selectors/globalSelector';

const liveCalendarEventQueue = [];
const liveCalendarEventDeletedQueue = [];

function* syncBookingsFork() {
  try {
    log.info('syncBookingsFork');
    yield call(syncBookings, true);
  } catch (error) {
    log.error('Error syncing bookings', error);
  }
}

function onlyUniqueCalendarEvents(value, index, self) {
  const currentId = value.id;
  const existingValue = self.find(currentValue => currentValue.id === currentId);
  return self.indexOf(existingValue) === index;
}

function* emptyCalendarEventQueue() {
  const clonedCalendarEvents = liveCalendarEventQueue.slice(0);
  const clonedDeletedEvents = liveCalendarEventDeletedQueue.slice(0);

  if (clonedDeletedEvents.length > 0) {
    log.info('flush deleted live query calendar events', clonedDeletedEvents);
    liveCalendarEventDeletedQueue.splice(0, liveCalendarEventDeletedQueue.length);
    yield put(deleteLiveCalendarEvents(clonedDeletedEvents.filter(onlyUniqueCalendarEvents)));
  }

  if (clonedCalendarEvents.length > 0) {
    log.info('flush created live query calendar events', clonedCalendarEvents);
    liveCalendarEventQueue.splice(0, liveCalendarEventQueue.length);
    // We need to ignore multiple updates to the same event id and only take the latest update
    yield put(addLiveCalendarEvents(clonedCalendarEvents.filter(onlyUniqueCalendarEvents)));
  }
}

function* intervalEmptyQueue() {
  while (true) {
    const beforeQueueLength = liveCalendarEventQueue.length;
    yield emptyCalendarEventQueue();
    yield delay(2000);
    const afterQueueLength = liveCalendarEventQueue.length;

    // If there are a lot of events coming through
    // this queue then debounce it a little longer
    const isLargeThroughput = beforeQueueLength > 10 || afterQueueLength > 10;
    if (isLargeThroughput) {
      yield delay(5000);
    }
  }
}

// eslint-disable-next-line
function* putNewCalendarEventInStore(calendarEvent) {
  const isDeleted = !!calendarEvent.deleted;

  log.info('calendar live query event', calendarEvent, { isDeleted });
  const mappedObject = MapParseObjToGraphQLCalendarEvent(calendarEvent);
  liveCalendarEventDeletedQueue.unshift(mappedObject);

  if (!isDeleted) {
    log.info('new live calendar event', calendarEvent);
    liveCalendarEventQueue.unshift(mappedObject);
  }
}

function* eventChannelStartSaga() {
  try {
    const currentUser = yield select(selectCurrentUser());
    log.info('eventChannelStartSaga - start');
    const eventChannelFork = yield fork(handleEventChannelEvent, {
      className: 'Event',
      listenOnArray: ['update', 'create'],
      queryModifierFunc: query => {
        query.containedIn('ownerRole', currentUser.actAsRole);
        return query;
      },
      handleEventGenerator: putNewCalendarEventInStore,
    });
    const intervalFork = yield fork(intervalSyncBookings);
    const intervalFlush = yield fork(intervalEmptyQueue);
    yield take([types.CALENDAR_EVENT_CHANNEL_STOP_SAGA, types.GLOBAL_LOGOUT]);
    yield cancel(eventChannelFork);
    yield cancel(intervalFork);
    yield cancel(intervalFlush);

    log.info('eventChannelStartSaga - stop');
  } catch (e) {
    log.error('eventChannelStartSaga', e);
  }
}

function* intervalSyncBookings() {
  while (true) {
    yield delay(60000);
    yield fork(syncBookingsFork);
  }
}

function* handlePropertyChange() {
  // If a property is created/edited/deleted we need to invalidate our loaded calendar properties
  // Reset immediately
  yield put(resetCalendar());
  yield delay(2000);
  // Reset again in case they are speed bunnies and have already landed on the calendar page
  yield put(resetCalendar());
  yield put(initCalendar());
}

function* saga() {
  yield fork(takeLatest, types.CALENDAR_EVENT_CHANNEL_START_SAGA, eventChannelStartSaga);

  yield fork(
    takeLatest,
    [types.ACCOUNT_IMPORT_SUCCESS, types.PROPERTY_DELETE_PROPERTY_SUCCESS, types.PROPERTY_NEW_EDIT_SAGA],
    handlePropertyChange,
  );
}

export default saga;
