import gql from 'graphql-tag';

export const GET_BOOKINGS_BY_ID = gql`
  query bookingDetailsQuery($id: String, $propertyId: String) {
    booking(id: $id) {
      _id
      objectId
      arrivalDate
      departureDate
      guestId
      propertyId
      partnerBookingId
      hostId
      ownerRole
      propertyRole
      partner
      partnerUserId
      status
      createdAt
      updatedAt
      hasEvents
      otherAttributes {
        group
        label
        value
        pictures
      }
      tags
      guestData {
        firstName
        lastName
        pictureUrl
        title
      }
      numberOfGuests
    }
    property(id: $propertyId) {
      _id
      objectId
      pictureUrl
      title
      pictureUrls
      deleted
      address {
        apt
        city
        country
        state
        street
        zip
      }
      user {
        _id
        email
      }
      rooms {
        _id
        title
        type
      }
      timeZone
      partner
      partnerPropertyId
      partnerUserId
      partnerPictureIds
      numOfBedrooms
      numOfBeds
      numOfBathrooms
      location
      type
      detailsAccess
      detailsGarbage
      detailsParking
      detailsWifiName
      detailsWifiPassword
      detailsWifiDescription
      detailsInformation
      sample
      hasUserTitle
      countryCode
      propertyRole
      ownerRole
      userData
      userDataVersion
      hasTrigger
      createdAt
      updatedAt
      acl
      bedConfiguration {
        bedType
        bedDisplayType
        quantity
        twinable
      }
      otherAttributes {
        group
        label
        value
      }
      tags
    }
  }
`;
