import { createSelector } from 'reselect';

const selectSettings = state => state.settings;

export const selectAdminMode = createSelector(selectSettings, settings => settings.get('isAdminMode'));

export const selectCurrentCountryCode = createSelector(selectSettings, settings => settings.get('currentCountry'));

// features
export const selectUserFeatures = createSelector(
  createSelector(selectSettings, settings => settings.get('userFeatures')),
  userFeatures => userFeatures?.toJS(),
);

export const selectUserFeaturesLoading = createSelector(selectSettings, settings =>
  settings.get('userFeaturesLoading'),
);
export const selectUserFeaturesError = createSelector(selectSettings, settings => settings.get('userFeaturesError'));

/**
 *
 *
 * @param {*} featureName
 */
export const selectIsFeatureEnabledForUser = featureName =>
  createSelector(selectUserFeatures, userFeatures => {
    const userFeature = userFeatures.find(feature => feature.name === featureName && feature.enabled);
    return userFeature?.enabled || false;
  });
/**
 *
 *
 * @param {*} featureNames is an Array
 */
export const selectIsAnyFeatureEnabledForUser = featureNames =>
  createSelector(selectUserFeatures, userFeatures => {
    const enabled = userFeatures
      ?.filter(feature => feature.enabled)
      .reduce((acc, current) => acc || featureNames.includes(current.name), false);
    return enabled;
  });

// feature flags
export const selectUserFeatureFlags = createSelector(
  createSelector(selectSettings, settings => settings.get('userFeatureFlags')),
  userFeatureFlags => userFeatureFlags?.toJS(),
);

export const selectUserFeatureFlagsLoading = createSelector(selectSettings, settings =>
  settings.get('userFeatureFlagsLoading'),
);
export const selectUserFeatureFlagsError = createSelector(selectSettings, settings =>
  settings.get('userFeatureFlagsError'),
);
/**
 *
 *
 * @param {*} featureFlagName
 */
export const selectIsFeatureFlagEnabledForUser = featureFlagName =>
  createSelector(selectUserFeatureFlags, userFeatureFlags => {
    const userFeatureFlag = userFeatureFlags.find(
      featureFlag => featureFlag.name === featureFlagName && featureFlag.enabled,
    );
    return userFeatureFlag?.enabled || false;
  });

/**
 *
 *
 * @param {*} featureFlagNames is an Array
 */
export const selectIsAnyFeatureFlagEnabledForUser = featureFlagNames =>
  createSelector(selectUserFeatureFlags, userFeatureFlags => {
    const enabled = userFeatureFlags
      ?.filter(featureFlag => featureFlag.enabled)
      .reduce((acc, current) => acc || featureFlagNames.includes(current.name), false);
    return enabled;
  });

// feature plus feature flag selector
/**
 *
 *
 * @param {*} featureName
 * @param {*} featureFlagName
 */
export const selectIsFeatureAndFeatureFlagEnabledForUser = (featureName, featureFlagName) =>
  createSelector(
    selectIsFeatureEnabledForUser(featureName),
    selectIsFeatureFlagEnabledForUser(featureFlagName),
    (feature, featureFlag) => feature && featureFlag,
  );
