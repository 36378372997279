import gql from 'graphql-tag';

export const QUERY_FIND_CONTACT = gql`
  query FindContact($search: String!, $type: String!) {
    FindContact(search: $search, type: $type) {
      profileId
      userId
      standaloneUser
    }
  }
`;
