import React from 'react';
import t from '@properly/localization';
import { DividerLine, Title3, Icon } from '@properly/components';
import { AccountHeadSection } from '../../../../components/AccountElements';

function StripeSPPageHeader({ noLogo }) {
  return (
    <AccountHeadSection>
      {noLogo ? (
        <>
          <Title3 type="nomargin" style={{ margin: 'auto' }}>
            {t('payments.bank_account')}
          </Title3>
        </>
      ) : (
        <>
          <Icon.Logoproperly width="3em" byWidth />
          <Title3 type="nomargin">{t('titles.payments')}</Title3>
        </>
      )}
      <DividerLine type={['bottom']} />
    </AccountHeadSection>
  );
}
export default StripeSPPageHeader;
