import React from 'react';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { LoadingSplash, BookingDetails, Regular, Large } from '@properly/components';
import { GET_BOOKINGS_BY_ID } from './queries';
import { setSliderSaga } from '../../actions/globalActions';

const BookingDetailsContainer = ({ openPictures, data: { loading, error, booking, property } }) => {
  if (loading) return <LoadingSplash topLeft />;

  if (error)
    return (
      <div>
        <Large type="orange">Something went wrong while getting your booking.</Large>
      </div>
    );

  if (!booking || !property) {
    return (
      <div>
        <Regular type="greyloading">Booking not found!</Regular>
      </div>
    );
  }
  const bookingResult = { ...booking, id: booking.objectId };
  const propertyResult = { ...property, id: property.objectId };
  return (
    <BookingDetails id="bookingDetails" openPictures={openPictures} booking={bookingResult} property={propertyResult} />
  );
};

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = dispatch => ({
  openPictures: pictures => dispatch(setSliderSaga(true, (pictures || []).map(image => ({ image })))),
});

export default compose(
  graphql(GET_BOOKINGS_BY_ID, {
    options: props => ({
      variables: {
        id: props.bookingId,
        propertyId: props.propertyId,
      },
    }),
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(BookingDetailsContainer);
