import React from 'react';
import classNames from 'classnames/bind';
import styles from './checkoutStyles.module.css';

const cx = classNames.bind(styles);

const CheckoutSection = ({ children, title }) => (
  <div>
    <h2 className={cx('checkout__section-title')}>{title}</h2>
    <section className={cx('checkout__section')}>
      <div>{children}</div>
    </section>
  </div>
);

const CheckoutRow = ({ children, mode, gutter }) => (
  <div
    className={cx('checkout__row', `checkout__row--${mode}`)}
    style={{ marginLeft: gutter * -1, marginRight: gutter * -1 }}
  >
    {React.Children.map(children, ele => React.cloneElement(ele, { mode, gutter }))}
  </div>
);

const CheckoutCol = ({ children, size, sizeSmall, gutter, mode }) => {
  let style = { paddingLeft: gutter, paddingRight: gutter, minWidth: sizeSmall, maxWidth: sizeSmall, width: sizeSmall };
  if (mode !== 'column') {
    style = { ...style, minWidth: size, maxWidth: size, width: size };
  }
  return <div style={style}>{children}</div>;
};

const PropertySection = ({ left, right, mod }) => (
  <div className={cx('checkout__property-section', `checkout__property-section--${mod}`)}>
    <div>{left}</div>
    <div>{right}</div>
  </div>
);

export { CheckoutSection, CheckoutRow, CheckoutCol, PropertySection };
