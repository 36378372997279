import isEmpty from 'lodash/isEmpty';
import conformsTo from 'lodash/conformsTo';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import invariant from 'invariant';
import warning from 'warning';
import log from 'loglevel';

/**
 * Validate the shape of redux store
 */
export function checkStore(store) {
  const shape = {
    dispatch: isFunction,
    subscribe: isFunction,
    getState: isFunction,
    replaceReducer: isFunction,
    runSaga: isFunction,
    asyncReducers: isObject,
    asyncSagas: isObject,
  };
  invariant(conformsTo(store, shape), 'asyncInjectors: Expected a valid redux store');
}

/**
 * Inject an asynchronously loaded reducer
 */
export function injectAsyncReducer(store, isValid, createReducer) {
  return function injectReducer(name, asyncReducer) {
    if (!isValid) {
      checkStore(store);
    }

    invariant(
      isString(name) && !isEmpty(name) && isFunction(asyncReducer),
      'injectAsyncReducer: Expected `asyncReducer` to be a reducer function',
    );

    if (Reflect.has(store.asyncReducers, name)) {
      return;
    }

    store.asyncReducers[name] = asyncReducer; // eslint-disable-line
    store.replaceReducer(createReducer(store.asyncReducers));
  };
}

/**
 * Inject an asynchronously loaded saga
 */
export function injectAsyncSagas(store, isValid) {
  return function injectSagas(key, saga) {
    if (!isValid) {
      checkStore(store);
    }

    if (store.asyncSagas[key]) {
      warning(false, 'injectAsyncSagas: Already running');
      return;
    }

    if (!saga) {
      warning(false, 'injectAsyncSagas: No saga generator passed');
      return;
    }

    try {
      // Webpack may bundle our saga functions under a default property
      // Handle these cases by safely checking the passed parameter
      if (saga && saga.default && isFunction(saga.default)) {
        store.runSaga(saga.default);
      } else {
        store.runSaga(saga);
      }
    } catch (err) {
      log.error('Could not inject async saga', err);
      throw err;
    }

    store.asyncSagas[key] = true; // eslint-disable-line
  };
}

/**
 * Helper for creating injectors
 */
export function getAsyncInjectors(store, createReducer) {
  checkStore(store);

  return {
    injectReducer: injectAsyncReducer(store, true, createReducer),
    injectSagas: injectAsyncSagas(store, true),
  };
}
