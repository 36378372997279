import log from 'loglevel';
import { client as apolloClient } from '../../config/apollo';
import {
  MUTATION_STRIPE_CREATE_SETUP_INTENT,
  MUTATION_STRIPE_CREATE_CUSTOMER_WITH_PAYMENT_METHOD,
  MUTATION_STRIPE_ADD_CUSTOMER_PAYMENT_METHOD,
  MUTATION_STRIPE_REMOVE_CUSTOMER_PAYMENT_METHOD,
  MUTATION_STRIPE_CREATE_CUSTOMER_WITH_SOURCE,
  MUTATION_STRIPE_ADD_CUSTOMER_SOURCE,
  MUTATION_STRIPE_REMOVE_CUSTOMER_SOURCE,
  MUTATION_STRIPE_CREATE_PLAID_CUSTOMER_WITH_SOURCE,
  MUTATION_STRIPE_ADD_PLAID_CUSTOMER_SOURCE,
} from '../mutations/stripe';
import { QUERY_GET_STRIPE_STATUS, QUERY_GET_STRIPE_CUSTOMER } from '../queries/stripe';
import { stripeCustomerModelMapper, stripeSetupIntentModelMapper } from '../datamap/stripeMap';

export const getHostStripeStatus = async () => {
  const { name } = getHostStripeStatus;

  try {
    const hostStripeStatus = await apolloClient.query({
      query: QUERY_GET_STRIPE_STATUS,
      fetchPolicy: 'no-cache',
    });
    return hostStripeStatus.data;
  } catch (error) {
    log.error(name, error);
    return error;
  }
};

export const getStripeCustomer = async () => {
  const { name } = getStripeCustomer;

  try {
    const stripeCustomer = await apolloClient.query({
      query: QUERY_GET_STRIPE_CUSTOMER,
      fetchPolicy: 'no-cache',
    });
    const response = stripeCustomerModelMapper(stripeCustomer.data.stripeCustomer);
    return response;
  } catch (error) {
    log.error(name, error);
    error.apiError = true;
    return error;
  }
};

export const createStripeSetupIntent = async () => {
  const { name } = createStripeSetupIntent;

  try {
    const createdStripeSetupIntent = await apolloClient.mutate({
      mutation: MUTATION_STRIPE_CREATE_SETUP_INTENT,
      fetchPolicy: 'no-cache',
    });
    const response = stripeSetupIntentModelMapper(createdStripeSetupIntent.data.createStripeSetupIntent);

    return response;
  } catch (error) {
    log.error(name, error);
    error.apiError = true;
    return error;
  }
};

export const createStripeCustomerWithPaymentMethod = async stripePaymentMethod => {
  const { name } = createStripeCustomerWithPaymentMethod;

  try {
    const createdStripeCustomer = await apolloClient.mutate({
      mutation: MUTATION_STRIPE_CREATE_CUSTOMER_WITH_PAYMENT_METHOD,
      variables: {
        stripePaymentMethod,
      },
      fetchPolicy: 'no-cache',
    });
    const response = stripeCustomerModelMapper(createdStripeCustomer.data.createStripeCustomer);

    return response;
  } catch (error) {
    log.error(name, error);
    error.apiError = true;
    return error;
  }
};

export const addStripeCustomerPaymentMethod = async (stripePaymentMethod, setAsDefault) => {
  const { name } = addStripeCustomerPaymentMethod;

  try {
    const stripeCustomer = await apolloClient.mutate({
      mutation: MUTATION_STRIPE_ADD_CUSTOMER_PAYMENT_METHOD,
      variables: {
        stripePaymentMethod,
        setAsDefault,
      },
      fetchPolicy: 'no-cache',
    });
    const response = stripeCustomerModelMapper(stripeCustomer.data.addStripeCustomerPaymentMethod);

    return response;
  } catch (error) {
    log.error(name, error);
    error.apiError = true;
    return error;
  }
};

export const removeStripeCustomerPaymentMethod = async stripePaymentMethodId => {
  const { name } = removeStripeCustomerPaymentMethod;

  try {
    const stripeCustomer = await apolloClient.mutate({
      mutation: MUTATION_STRIPE_REMOVE_CUSTOMER_PAYMENT_METHOD,
      variables: {
        stripePaymentMethodId,
      },
      fetchPolicy: 'no-cache',
    });
    const response = stripeCustomerModelMapper(stripeCustomer.data.removeStripeCustomerPaymentMethod);

    return response;
  } catch (error) {
    log.error(name, error);
    error.apiError = true;
    return error;
  }
};

export const createStripeCustomerWithSource = async stripeSourceToken => {
  const { name } = createStripeCustomerWithSource;

  try {
    const createdStripeCustomer = await apolloClient.mutate({
      mutation: MUTATION_STRIPE_CREATE_CUSTOMER_WITH_SOURCE,
      variables: {
        stripeSourceToken,
      },
      fetchPolicy: 'no-cache',
    });
    const response = stripeCustomerModelMapper(createdStripeCustomer.data.createStripeCustomer);

    return response;
  } catch (error) {
    log.error(name, error);
    error.apiError = true;
    return error;
  }
};

export const addStripeCustomerSource = async stripeSourceToken => {
  const { name } = addStripeCustomerSource;

  try {
    const stripeCustomer = await apolloClient.mutate({
      mutation: MUTATION_STRIPE_ADD_CUSTOMER_SOURCE,
      variables: {
        stripeSourceToken,
      },
      fetchPolicy: 'no-cache',
    });
    const response = stripeCustomerModelMapper(stripeCustomer.data.addStripeCustomerSource);

    return response;
  } catch (error) {
    log.error(name, error);
    error.apiError = true;
    return error;
  }
};

export const removeStripeCustomerSource = async stripeSourceId => {
  const { name } = removeStripeCustomerSource;

  try {
    const stripeCustomer = await apolloClient.mutate({
      mutation: MUTATION_STRIPE_REMOVE_CUSTOMER_SOURCE,
      variables: {
        stripeSourceId,
      },
      fetchPolicy: 'no-cache',
    });
    const response = stripeCustomerModelMapper(stripeCustomer.data.removeStripeCustomerSource);

    return response;
  } catch (error) {
    log.error(name, error);
    error.apiError = true;
    return error;
  }
};

export const createPlaidStripeCustomerWithSource = async (plaidPublicToken, plaidAccountId) => {
  const { name } = createPlaidStripeCustomerWithSource;

  try {
    const stripeCustomer = await apolloClient.mutate({
      mutation: MUTATION_STRIPE_CREATE_PLAID_CUSTOMER_WITH_SOURCE,
      variables: {
        plaidPublicToken,
        plaidAccountId,
      },
      fetchPolicy: 'no-cache',
    });
    const response = stripeCustomerModelMapper(stripeCustomer.data.createStripeCustomer);

    return response;
  } catch (error) {
    log.error(name, error);
    error.apiError = true;
    return error;
  }
};

export const addPlaidStripeCustomerSource = async (plaidPublicToken, plaidAccountId) => {
  const { name } = addPlaidStripeCustomerSource;

  try {
    const stripeCustomer = await apolloClient.mutate({
      mutation: MUTATION_STRIPE_ADD_PLAID_CUSTOMER_SOURCE,
      variables: {
        plaidPublicToken,
        plaidAccountId,
      },
      fetchPolicy: 'no-cache',
    });
    const response = stripeCustomerModelMapper(stripeCustomer.data.addStripeCustomerSource);

    return response;
  } catch (error) {
    log.error(name, error);
    error.apiError = true;
    return error;
  }
};
