import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import orderBy from 'lodash/orderBy';
import values from 'lodash/values';
import reduce from 'lodash/reduce';
import isArray from 'lodash/isArray';

export const selectTodos = () => state => state.todos;
export const selectTodosPagination = state => selectTodos()(state).get('pagination');
export const selectTodosEvents = () => state => selectTodos()(state).get('events');
export const selectTodosEventsGrouped = () => state => selectTodos()(state).get('eventsGrouped');

const dateFormat = 'YYYYMMDD';

function scanNext7Days(today, obj) {
  const format = date => moment(date).format(dateFormat);
  let i = 1;
  let matches = 0;
  const date = format(today);
  let currentDate = `${date}`;
  while (i <= 7) {
    const addOrNot = i === 1 ? 0 : 1;
    currentDate = format(moment(currentDate, [dateFormat]).add(addOrNot, 'day'));
    if (obj[currentDate]) {
      matches += 1;
    }
    i += 1;
  }
  return matches;
}

const filterEmpty = () => true;

function groupData(data, filter = filterEmpty) {
  return reduce(
    data,
    (acc, item) => {
      const date = moment(item.startTime)
        .tz(item.timeZone)
        .format(dateFormat);
      if (filter(date, item)) {
        if (isArray(acc.filtered[date])) {
          acc.filtered[date].push(item);
        } else {
          acc.filtered[date] = [item]; // eslint-disable-line
        }
        acc.length += 1; // eslint-disable-line
      }
      return acc;
    },
    {
      filtered: {},
      length: 0,
    },
  );
}

export const selectGroupedEvents = () =>
  createSelector(selectTodosEvents(), events => {
    const eventsJS = events?.toJS();
    const ordered = orderBy(values(eventsJS), ['startTime'], ['asc']);
    const grouped = groupData(
      ordered,
      (dateInner, item) => dateInner >= moment().format(dateFormat) && !item.deleted && item.needsCTA, // must be today or later
    );
    return {
      grouped: grouped.filtered,
      hasNextSeven: scanNext7Days(moment(), grouped.filtered) > 0,
      isEmpty: grouped.length === 0,
    };
  });
