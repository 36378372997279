import moment from 'moment-timezone';
import { jobRequestStatus, problemUpdateStatus } from '@properly/config';
import forOwn from 'lodash/forOwn';
import map from 'lodash/map';
import result from 'lodash/result';
import { calcJobProgressStats } from '../helper/herbert';

export default class JobRequestGraphql {
  constructor({
    status,
    property,
    sender,
    checklists,
    cleaners,
    progress,
    job,
    backwardCompatibility,
    problems,
    connections,
    additionalInformationReport,
    sourceProblems,
    costs,
    skillRequirements,
    stripePaymentDetails,
  }) {
    this.propertyId = property.id;
    this.jobId = job.id;
    this.hostId = sender.id;
    this.jobData = job;
    this.note = job.note;
    this.requestedCleaners = cleaners;
    this.scheduledStartTime = new Date(job.scheduledStartTime);
    this.scheduledEndTime = new Date(job.scheduledEndTime);
    this.actualStartTime = progress.actualStartTime;
    this.actualEndTime = progress.actualEndTime;
    this.setJobStatus = status;
    this.statusString = status;
    this.taskDoneCount = progress.completedTaskCount;
    this.verificationDoneCount = progress.verificationPictureCount;
    this.problemCount = progress.problemCount;
    this.jobProgressId = progress.id;
    this.offeredPrice = job.offeredPrice;
    this.offeredPriceCurrency = job.offeredPriceCurrency;
    this.jobProgress = progress;
    this.problems = problems;
    this.skillRequirements = skillRequirements;
    this.propertyData = property;
    this.createdAt = job.sentOn;
    this.hostData = sender.userData;
    this.senderData = sender.userData;
    this.companyName = sender.companyName;
    this.requestedCleanersData = cleaners;
    this.senderId = sender.id;
    this.jobStartTimeType = job.startTimeType;
    this.duration = job.duration;
    this.setChecklists = checklists;
    this.connections = connections;
    this.title = job.title;
    this.jobType = job.jobType;
    this.totalTaskCount = job.totalTaskCount;
    this.backwardCompatibility = backwardCompatibility;
    this.tasksSetter = checklists;
    this.additionalInformationReport = additionalInformationReport;
    this.sourceProblems = sourceProblems;
    this.costs = costs;
    this.stripePaymentDetails = stripePaymentDetails;
    this.monetizedJob = job.isMonetizedJob;
  }

  set tasksSetter(checklists) {
    const tasksArray = checklists.filter(checklist => checklist.isOneOff);
    if (tasksArray.length > 0) {
      const res = tasksArray.map(task => ({
        id: task.id,
        sourceProblemId: task.sourceProblemId,
        title: task.title,
        note: task.steps[0].tasks[0].note,
        pictureIdentifiers: task.steps[0].tasks[0].notePictureIdentifiers,
        isProblem: !!task.sourceProblemId,
      }));
      this.tasks = res || [];
    }
  }

  // Set 'checklists' property to not have one off tasks
  set setChecklists(checklists) {
    this.checklists = checklists.filter(checklist => !checklist.isOneOff);
  }

  set setJobStatus(value) {
    switch (value) {
      case 'pending':
        this.status = 1;
        break;
      case 'accepted':
        this.status = 2;
        break;
      case 'cancelled':
      case 'canceledByHost':
        this.status = 3;
        break;
      case 'declined':
      case 'declinedChanges':
        this.status = 4;
        break;
      case 'inProgress':
        this.status = 5;
        break;
      case 'finished':
        this.status = 7;
        break;
      case 'canceledByCleaner':
        this.status = 8;
        break;
      case 'pendingChanges':
        this.status = 9;
        break;
      default:
        this.status = undefined;
    }
  }

  get jobIdAsArray() {
    if (this.jobId) {
      return [this.jobId];
    }
    return [];
  }

  get getConnections() {
    return this.connections;
  }

  get isProblemResolved() {
    return this.problems.some(problem => problem.status === problemUpdateStatus.RESOLVED);
  }

  getProblemsNotInProgress() {
    return this.problems.filter(problem => problem.status !== problemUpdateStatus.IN_PROGRESS);
  }

  setData(obj) {
    this.getOwnProps(obj, this);
  }

  getData() {
    return this.getOwnProps(this);
  }

  getTasks() {
    return this.checklists ? this.checklists.oneOff : [];
  }

  getOwnProps(scope, onScope) {
    const obj = onScope || {};
    forOwn(scope, (value, key) => {
      obj[key] = value;
    });
    return obj;
  }

  getId() {
    return this.objectId;
  }

  getReports() {
    return this.additionalInformationReport.map(additionalInformationReport => additionalInformationReport.report);
  }

  getAdditionalInformationReports() {
    return this.additionalInformationReport;
  }

  getTimeZone() {
    return this.propertyData.timeZone || 'America/Los_Angeles';
  }

  getScheduledStartTime() {
    return this.scheduledStartTime;
  }

  getActualStartTime() {
    return this.actualStartTime;
  }

  getPropertyId() {
    return this.propertyId;
  }

  getDateDurationInMin() {
    const ms =
      this.duration && this.duration > 0
        ? this.duration * 60 * 1000
        : moment(this.scheduledEndTime).diff(moment(this.scheduledStartTime));
    return moment.duration(ms) / 60 / 1000;
  }

  getFlexibleDateString(format) {
    let startDate = this.scheduledStartTime;
    let endDate = this.scheduledEndTime;
    startDate = moment(startDate)
      .tz(this.getTimeZone())
      .format(format || 'll');
    endDate = moment(endDate)
      .tz(this.getTimeZone())
      .format(format || 'll');
    return `${startDate} - ${endDate}`;
  }

  getFlexibleStartDateString() {
    return moment(this.scheduledStartTime)
      .tz(this.getTimeZone())
      .format('ll');
  }

  getFlexibleEndDateString() {
    return moment(this.scheduledEndTime)
      .tz(this.getTimeZone())
      .format('ll');
  }

  isFlexibleStartTime() {
    return this.jobStartTimeType === 'FLEXIBLE';
  }

  getShortDateString() {
    return this.getDateString('l');
  }

  getDateString(format) {
    return this.isFlexibleStartTime()
      ? this.getFlexibleDateString(format)
      : moment(this.scheduledStartTime)
          .tz(this.getTimeZone())
          .format(format || 'll');
  }

  getTimeString() {
    let startTime = this.scheduledStartTime;
    let endTime = this.scheduledEndTime
      ? this.scheduledEndTime
      : moment(this.scheduledStartTime)
          .clone()
          .add(this.duration, 'minutes');
    startTime = moment(startTime)
      .tz(this.getTimeZone())
      .format('LT');
    endTime = moment(endTime)
      .tz(this.getTimeZone())
      .format('LT');
    return `${startTime} - ${endTime}`;
  }

  getEndTime(isActual) {
    let endTime = this.scheduledEndTime;
    if (isActual) endTime = this.actualEndTime;
    if (!endTime) {
      endTime = moment(this.scheduledStartTime)
        .clone()
        .add(this.duration, 'minutes');
    }
    return endTime;
  }

  getEndTimeString(isActual) {
    return moment(this.getEndTime(isActual))
      .tz(this.getTimeZone())
      .format('LT');
  }

  getLocalEndTimeString(isActual) {
    return moment(this.getEndTime(isActual)).format('LT');
  }

  getStartTimeString(isActual) {
    let startTime = this.scheduledStartTime;
    if (isActual) {
      startTime = this.actualStartTime;
    }

    return moment(startTime)
      .tz(this.getTimeZone())
      .format('LT');
  }

  getLocalStartTimeString() {
    const startTime = this.scheduledStartTime;
    return moment(startTime).format('LT');
  }

  isDelayed() {
    return (
      !this.actualStartTime &&
      this.status === jobRequestStatus.StateAccepted &&
      moment().toDate() -
        moment(this.scheduledStartTime)
          .tz(this.getTimeZone())
          .toDate() >
        0
    );
  }

  allProblemsResolved = () => this.problems.every(problem => problem.status === problemUpdateStatus.RESOLVED);

  get isInProgress() {
    return this.status === jobRequestStatus.StateStarted || this.status === jobRequestStatus.StatePaused;
  }

  get isFinished() {
    return this.status === jobRequestStatus.StateFinished;
  }

  get subStatus() {
    const completedTaskCount = this.jobProgress.completedTaskCount;
    const totalTaskCount = map(this.checklists || [], checklist => checklist.totalTaskCount).reduce((a, b) => a + b, 0);
    const isIncomplete = completedTaskCount < totalTaskCount;
    const isFinished = this.status === jobRequestStatus.StateFinished;
    return {
      hasProblem: this.problemCount > 0 && !this.allProblemsResolved(),
      isIncomplete,
      isComplete: isFinished,
    };
  }

  getStatusString() {
    if (this.isDelayed() && this.status !== jobRequestStatus.StateFinished) {
      return 'jobstatus.delayed';
    }

    if (this.statusString === 'viewed') {
      return 'jobstatus.seen';
    }

    switch (this.status) {
      case jobRequestStatus.StateCreated:
        return 'jobstatus.pending';
      case jobRequestStatus.StatePendingChanges:
        return 'jobstatus.pendingChanges';
      case jobRequestStatus.StateAccepted:
        return 'jobstatus.accepted';
      case jobRequestStatus.StateCancelled:
      case jobRequestStatus.StateCleanerCancelled:
        return 'jobstatus.cancelled';
      case jobRequestStatus.StateDeclined:
        return 'jobstatus.declined';
      case jobRequestStatus.StateStarted:
      case jobRequestStatus.StatePaused:
        return 'jobstatus.started';
      case jobRequestStatus.StateFinished:
        return 'jobstatus.completed';
      default:
        return undefined;
    }
  }

  getStatusStringAdvanced() {
    const res = this.getStatusString();
    if (res === 'jobstatus.cancelled') {
      if (this.status === jobRequestStatus.StateCleanerCancelled) {
        return 'jobstatus.cancelledbycleaner';
      }
      if (this.status === jobRequestStatus.StateCancelled) {
        return 'jobstatus.cancelledbyhost';
      }
      return 'jobstatus.cancelled';
    }
    return res;
  }

  get durationInMin() {
    // eslint-disable-next-line
    console.warn('This function durationInMin is deprecated by getDateDurationInMin');
    return (new Date(this.scheduledEndTime) - new Date(this.scheduledStartTime)) / 1000 / 60;
  }

  get mappedSteps() {
    const jobSteps = map(this.checklists || [], ({ checklist }) => checklist.steps);
    const doneTaskCount = map(this.checklists || [], ({ checklist }) => checklist.doneTaskCount);

    return calcJobProgressStats(jobSteps, doneTaskCount[0]);
  }

  getCleanerStatusByContactId = (cleaners, contactId) => {
    const filteredCleaners = cleaners.filter(cleaner => cleaner.id === contactId);
    return filteredCleaners.length ? filteredCleaners[0].status : '';
  };

  getCleanerString() {
    const cleaners = this.requestedCleaners.filter(cleaner =>
      this.assignedToUserId ? cleaner.userId === this.assignedToUserId : true,
    );
    if (cleaners.length === 0) {
      return '';
    }
    const firstCleaner = this.resolveUserCleanerDataShortName(cleaners[0]);

    if (cleaners.length === 1) {
      if (!cleaners[0].contact) {
        return firstCleaner;
      }
      return firstCleaner;
    }

    return `${firstCleaner}, +${this.requestedCleaners.length - 1}`;
  }

  resolveUserCleanerDataShortName(cleaner) {
    const shortName = `${cleaner.userData.firstName} ${cleaner.userData.lastName.substring(0, 1)}.`;

    return shortName;
  }

  getPropertyLines() {
    const lines = [];
    if (this.title !== this.propertyData.street) {
      lines.push(this.propertyData.title);
    }

    if (this.propertyData.street) lines.push(this.propertyData.street);
    lines.push(`${this.propertyData.city} ${this.propertyData.countryCode} ${this.propertyData.zip}`);
    lines.push(this.propertyData.country);

    return lines;
  }

  get eventTitle() {
    return result(this, ['event', 'triggerResolved', 'title']);
  }

  get checklistName() {
    return result(this, ['jobData', 'title']);
  }

  get canCancel() {
    return this.status !== jobRequestStatus.StateCancelled;
  }

  get canEdit() {
    return (
      this.status !== jobRequestStatus.StateStarted &&
      this.status !== jobRequestStatus.StatePaused &&
      this.status !== jobRequestStatus.StateFinished &&
      this.status !== jobRequestStatus.StateCancelled
    );
  }

  get canSendMore() {
    return (
      (this.status === jobRequestStatus.StateCleanerCancelled ||
        this.status === jobRequestStatus.StateCreated ||
        this.status === jobRequestStatus.StateDeclined ||
        this.statusString === 'viewed') &&
      moment().toDate() -
        moment(this.scheduledStartTime)
          .tz(this.getTimeZone())
          .toDate() <
        0
    );
  }

  get hasNote() {
    return this.note && this.note !== '';
  }

  get hasPrice() {
    return this.offeredPrice && this.offeredPrice !== 0;
  }
}
