"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.capitalizeFirstLetter = capitalizeFirstLetter;

function capitalizeFirstLetter(value) {
  if (typeof value !== 'string') {
    return '';
  }

  return value.charAt(0).toUpperCase() + value.slice(1);
}