import React, { PureComponent } from 'react';
import log from 'loglevel';
import t from '@properly/localization';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Regular, Large, SpaceSmall, SpacedHeadline, Button, Modal, Input } from '@properly/components';

class PropertyIcalModal extends PureComponent {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    iCalTitle: '',
    iCalLink: '',
    errors: [],
  };

  isInvalid({ title, link }) {
    const { settings } = this.props;
    const errors = [];

    if (!title) {
      errors.push(t('properties.error.enter_title'));
    }

    if (!link) {
      errors.push(t('properties.error.paste_link'));
    }

    if (link && !this.checkLinkValid(link)) {
      errors.push(t('properties.error.bad_link'));
    }

    if (link && (settings || []).find(({ link: currentLink }) => link === currentLink)) {
      errors.push(t('properties.error.link_exists'));
    }

    return errors.length > 0 ? errors : false;
  }

  handleSubmit = e => {
    e.preventDefault();
    const { onSubmit } = this.props;
    const { iCalTitle, iCalLink } = this.state;

    const data = { title: iCalTitle, link: iCalLink };

    const errors = this.isInvalid(data);
    if (errors) {
      log.warn('Invalid Ical information', errors);
      this.setState({ errors });
      return;
    }

    onSubmit({ title: iCalTitle, link: iCalLink });
    this.setState({ iCalLink: '', iCalTitle: '', errors: [] });
  };

  handleLinkChange = e => {
    this.setState({ iCalLink: e.target.value, errors: [] });
  };

  handleTitleChange = e => {
    this.setState({ iCalTitle: e.target.value, errors: [] });
  };

  checkLinkValid(link) {
    const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!-]))?/;
    return regexp.test(link);
  }

  handleClose = () => {
    const { onClose } = this.props;

    onClose();
    this.setState({
      iCalTitle: '',
      iCalLink: '',
      errors: [],
    });
  };

  hasErrors() {
    const { errors } = this.state;

    if (!errors || !errors.length) {
      return false;
    }

    return true;
  }

  renderErrors() {
    const { errors } = this.state;

    if (!this.hasErrors()) {
      return null;
    }

    return (
      <Grid container direction="column">
        {errors.map(error => (
          <Grid item key={error}>
            <Regular type="red">{error}</Regular>
          </Grid>
        ))}
      </Grid>
    );
  }

  render() {
    const { show } = this.props;
    const { iCalTitle, iCalLink } = this.state;
    const hasErrors = this.hasErrors();
    return (
      <Modal onClose={this.handleClose} show={show} width={360}>
        <form onSubmit={this.handleSubmit}>
          <Grid container direction="column">
            <Grid item>
              <Large type="medium">{t('properties.ical_connect')}</Large>
            </Grid>
            <Grid item>
              <SpacedHeadline>{t('properties.paste_ical_title')}</SpacedHeadline>
              <Input
                error={hasErrors}
                placeholder={t('properties.ical_enter_title')}
                type="text"
                autoFocus
                value={iCalTitle}
                onChange={this.handleTitleChange}
              />
            </Grid>
            <Grid item>
              <SpacedHeadline>{t('properties.link')}</SpacedHeadline>
              <Input
                error={hasErrors}
                placeholder={`${t('properties.ical_paste_link')} ( e.g. http://iCalLink.ics )`}
                type="text"
                value={iCalLink}
                onChange={this.handleLinkChange}
              />
            </Grid>
            <SpaceSmall />
            <Grid item>
              <Grid container direction="row" wrap="nowrap" justify="space-between">
                <Grid item>{this.renderErrors()}</Grid>
                <Grid item style={{ flexShrink: 0 }}>
                  <Grid container direction="row" justify="flex-end">
                    <Grid item style={{ marginRight: '8px' }}>
                      <Button type="button" onClick={this.handleClose} types={['type-flat-grey', 'size-medium']}>
                        {t('properties.cancel')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button type="submit" types={['type-full-primary', 'size-medium']}>
                        {t('accountprovidersection.connect')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Modal>
    );
  }
}

export default connect()(PropertyIcalModal);
