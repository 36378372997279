import log from 'loglevel';

export const mobileExternalInterface = (function() {
  const methodTypes = {
    OPEN_EXTERNAL_BROWSER: 'openInExternalBrowser',
    SUCCESS: 'taskFinishedWithSuccess',
    FAILURE: 'taskFinishedWithFailure',
  };

  const doesExist = () => !!(window.webkit || window.AndroidInterface);

  // As per https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
  const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const sendNotification = (type, partner = '', message = true) => {
    if (!type || !doesExist()) {
      log.error('bad request: unable to send notification mobile interface/method doesnt exist');
      return;
    }
    try {
      if (window.webkit) {
        window.webkit.messageHandlers[type].postMessage({ message, partner, error: false });
      }
      if (window.AndroidInterface) {
        window.AndroidInterface[type](JSON.stringify({ message, partner, error: false }));
      }
    } catch {
      log.error('unable to send Notification :Mobile Interface Error');
    }
  };

  return {
    sendNotification,
    doesExist,
    methodTypes,
    isIOS,
  };
})();
