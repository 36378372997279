import Parse from 'parse';
import log from 'loglevel';
import { getConfigSync } from '@properly/config';

const MAX_CONCURRENT_REQUEST_COUNT = 4;
const MAX_CONCURRENT_REQUEST_LIMIT = 250;

const config = getConfigSync();

// Pure http parse get - this does not really decouple us from Parse but it enables better GET caching mechanism
// and also allows us to not depend on the global Parse instance and its initialised APP config

const BASE_HTTP_URL = config.PARSE_SERVER_READ_URL;

export function buildIgnoreDeletedQuery() {
  return { deleted: { $ne: true } };
}

export function buildUpdatedAtGreaterThanOrEqualQuery(date) {
  return {
    updatedAt: {
      $gte: {
        __type: 'Date',
        iso: date && new Date(date).toISOString(),
      },
    },
  };
}

export function buildGreaterThanStartTimeQuery(date) {
  return {
    startTime: {
      $gt: {
        __type: 'Date',
        iso: date && new Date(date).toISOString(),
      },
    },
  };
}

export const getBatchMappingWithMax = max => {
  const increments = Math.max(50, Math.round(max / MAX_CONCURRENT_REQUEST_COUNT));
  const concurrency = Math.min(MAX_CONCURRENT_REQUEST_LIMIT, Math.round(max / increments) - 1);

  return max < 50
    ? [{ limit: 10000, skip: 0 }]
    : [...new Array(concurrency)]
        .map((blank, index) => ({ limit: increments, skip: index * increments }))
        .concat([{ limit: 10000, skip: concurrency * increments }]);
};

export function fetchParseClass(name, limit, query, extra) {
  const queryString = (query && `&where=${JSON.stringify(query)}`) || '';
  const params = encodeURI(`limit=${limit}${queryString}`);
  const url = `${BASE_HTTP_URL}/classes/${name}?${params}${extra ? `&${extra}` : ''}`;
  return fetchUrl(url).then(({ results }) => {
    // When requesting from a pure http endpoint, we need to instantiate the parse class object
    // while enableSingleInstance is still in effect
    // This will fix the issue seen in WEB-826
    (results || []).forEach(result => Parse.Object.fromJSON({ ...result, className: name }));
    return results;
  });
}

export function fetchParseClassId(name, id) {
  const url = `${BASE_HTTP_URL}/classes/${name}/${id}`;
  return fetchUrl(url).then(result => {
    // When requesting from a pure http endpoint, we need to instantiate the parse class object
    // while enableSingleInstance is still in effect
    // This will fix the issue seen in WEB-826
    if (name !== '_User') {
      Parse.Object.fromJSON({ ...result, className: name });
    }

    return result;
  });
}

export function fetchParseCloudFunction(name, data, throwOnNon200) {
  const url = `${BASE_HTTP_URL}/functions/${name}`;
  return fetchUrl(url, 'POST', data, throwOnNon200).then(({ result } = {}) => result);
}

export function fetchParseConfig() {
  return fetchUrl(`${BASE_HTTP_URL}/config`, 'GET', null, true).then(({ params } = {}) => params || {});
}

export function fetchUrl(url, method, data, throwOnNon200, isFile) {
  const currentUser = Parse.User.current();
  const sessionToken = currentUser && currentUser.getSessionToken();

  const headers = {
    'X-Parse-Application-Id': config.APP_ID,
    'X-Parse-Javascript-Key': config.JS_KEY,
  };

  if (sessionToken) {
    headers['X-Parse-Session-Token'] = sessionToken;
  } else {
    log.warn('Could not find session token for current user', { currentUser, url, method, data, throwOnNon200 });
  }

  const bodyOptions = data ? { body: isFile ? data : JSON.stringify(data) } : {};
  return fetch(url, {
    method: method || 'GET',
    headers,
    ...bodyOptions,
  }).then(response => {
    const isErrorResponse = response.status < 200 || response.status >= 400;
    if (throwOnNon200 && isErrorResponse) {
      return response.json().then(errorResponseData => {
        throw errorResponseData;
      });
    }
    return response.json();
  });
}
