import React, { Component } from 'react';
import { connect } from 'react-redux';
import log from 'loglevel';
import lodashResult from 'lodash/result';
import { LoadingSpinner, DividerLine, Title3, Regular, SpaceBase, SpaceXLarge, Icon } from '@properly/components';
import { shutdownIntercom, capitalizeFirstLetter } from '@properly/common';
import t from '@properly/localization';
import { bindActionCreators } from 'redux';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import { refetchUserSaga } from '../../../../actions/globalActions';
import { PagePadding } from '../../../../components/PageElements';
import AccountProviderSection from '../../../../components/AccountProviderSection';
import {
  goToConnectionEdit,
  setState,
  setWarningModal,
  redirectToProviderSaga,
  getConnectedAccountRequest,
  redirectToMobileSaga,
  leaveAccountConnectionsRequest,
} from '../state/AccountActions';
import {
  selectConnectedAccounts,
  selectConnectedAccountsLoading,
  selectConnectedAccountsError,
} from '../state/AccountSelectors';
import { setCloneProperty } from '../../property/state/PropertyActions';
import { trackPartnerConnectPageOpen } from '../../../../actions/trackingEvents';
import { AccountHeadSection } from '../../../../components/AccountElements';
import { mobileExternalInterface } from '../../../../helper/mobileExternalInterface';
import AdaptorConnectionMessageModal from '../components/AdaptorConnectionMessageModal';

class AccountConnectionsAdaptorContainer extends Component {
  constructor(props) {
    super(props);

    const params = props.location.query;
    const { error, message } = params || {};

    const successfulServicesOauth = !!error && error === 'false';
    const failedServicesOauth =
      (error && error === 'true') || message === 'NOT_IMPLEMENTED' || message === 'SERVICE_FAILED';

    this.state = {
      connectionSuccess: successfulServicesOauth, // eslint-disable-line
      connectionFailed: failedServicesOauth,
    };
  }

  componentWillMount = () => {
    const { getConnectedAccount, mobile } = this.props;
    if (mobile) {
      shutdownIntercom(true);
    }

    getConnectedAccount();
  };

  componentDidMount() {
    trackPartnerConnectPageOpen();
  }

  componentDidUpdate() {
    const {
      redirectToMobileSaga, // eslint-disable-line no-shadow
      externalMobileBrowserConnected,
      onboardingMobile,
      mobile,
    } = this.props;
    const { connectionSuccess } = this.state;

    if (!mobile || !(externalMobileBrowserConnected || onboardingMobile)) {
      return;
    }

    if (connectionSuccess) {
      redirectToMobileSaga(
        mobileExternalInterface.methodTypes.SUCCESS,
        externalMobileBrowserConnected,
        onboardingMobile,
        connectionSuccess,
        true,
      );
    }
  }

  componentWillUnmount() {
    const { leaveAccountConnections } = this.props;
    leaveAccountConnections();
  }

  redirectToProvider(connectedAccount, mobile, onboardingMobile) {
    const { oAuthLink, title } = connectedAccount;

    if (!oAuthLink) {
      alert('could not gadoodle the foodle');
      return;
    }

    const loginWarningBlacklist = lodashResult(this.props.config, ['partnerLoginWarningBlacklist']);
    log.info('loginWarningBlacklist', loginWarningBlacklist);

    this.props.redirectToProviderSaga({
      oAuthLink,
      partnerTitle: title,
      connectedAccount,
      loginWarningBlacklist,
      mobile,
      onboardingMobile,
    });
  }

  renderSections() {
    const { connectedAccounts, editRoute, mobile, onboardingMobile } = this.props;

    return (connectedAccounts || []).map(connectedAccount => (
      <div
        key={connectedAccount.id}
        tabIndex={-1}
        style={{ outline: 'none' }}
        onClick={() => log.info('AccountConnectionsAdaptorContainer onClick', connectedAccount)}
      >
        <AccountProviderSection
          accounts={connectedAccount.connectionSettings}
          onConnect={() => this.redirectToProvider(connectedAccount, mobile, onboardingMobile)}
          onAdd={() => this.redirectToProvider(connectedAccount, mobile, onboardingMobile)}
          onEdit={account =>
            this.props.goToConnectionEdit(connectedAccount.id, account.id, account.connectionIssue, editRoute)
          }
          icon={connectedAccount.icon}
          text={connectedAccount.label}
          onboardingMobile={onboardingMobile}
        />
        <SpaceBase />
      </div>
    ));
  }

  handleMergeClick = () => {
    this.props.setCloneProperty({ isOpen: true });
  };

  handleForceRedirect = (connectionSuccess, e) => {
    const {
      redirectToMobileSaga, // eslint-disable-line no-shadow
      externalMobileBrowserConnected,
      onboardingMobile,
    } = this.props;

    redirectToMobileSaga(
      connectionSuccess ? mobileExternalInterface.methodTypes.SUCCESS : mobileExternalInterface.methodTypes.FAILURE,
      externalMobileBrowserConnected,
      onboardingMobile,
      connectionSuccess,
    );
    e.preventDefault();
  };

  dismissConnectionError = () => this.setState({ connectionFailed: false });

  dismissConnectionSuccess = () => this.setState({ connectionSuccess: false });

  render() {
    const { loading, mobile, onboardingMobile, externalMobileBrowserConnected, location } = this.props;
    const { message = {}, partner } = location?.query || {};
    const { connectionFailed, connectionSuccess } = this.state;

    const Wrapper = ({ children }) =>
      mobile ? <>{children}</> : <PagePadding type="absolute-scroll">{children}</PagePadding>;

    return (
      <Wrapper>
        {!mobile && (
          <>
            <AccountHeadSection>
              <Title3 type="nomargin">{t('account.connect_accounts')}</Title3>
              <DividerLine type={['bottom']} />
            </AccountHeadSection>
            <SpaceBase />
          </>
        )}

        {!onboardingMobile && (
          <>
            <div style={{ maxWidth: 900 }}>
              <Regular type="greyloading">{t('account.connect_txt')}</Regular>
            </div>

            <SpaceXLarge />
          </>
        )}

        {loading && (
          <div style={{ display: 'flex', width: '100%', height: 300, alignItems: 'center', justifyContent: 'center' }}>
            <LoadingSpinner />
          </div>
        )}

        {!loading && (
          <div style={{ marginBottom: 60 }}>
            {this.renderSections()}

            {!mobile && (
              <AccountProviderSection.Box
                isMerge
                onClick={this.handleMergeClick}
                text={t('account.merge')}
                isFirst
                isLast
                icon={Icon.IcMerge}
              />
            )}
          </div>
        )}

        <AdaptorConnectionMessageModal
          errorCode={message}
          connectionSuccess={connectionSuccess}
          connectionFailed={connectionFailed}
          externalMobileBrowserConnected={externalMobileBrowserConnected}
          handleForceRedirect={this.handleForceRedirect}
          dismissConnectionSuccess={this.dismissConnectionSuccess}
          dismissConnectionError={this.dismissConnectionError}
          currentPartner={capitalizeFirstLetter(partner)}
        />
      </Wrapper>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    config: selectorsGlobal.selectConfig(state, props),
    connectedAccounts: selectConnectedAccounts(state, props),
    loading: selectConnectedAccountsLoading(state, props),
    error: selectConnectedAccountsError(state, props),
  };
}

export default connect(mapStateToProps, dispatch => ({
  ...bindActionCreators(
    {
      getConnectedAccount: getConnectedAccountRequest,
      leaveAccountConnections: leaveAccountConnectionsRequest,
      refetchUserSaga,
      goToConnectionEdit,
      setState,
      setCloneProperty,
      setWarningModal,
      redirectToProviderSaga,
      redirectToMobileSaga,
    },
    dispatch,
  ),
  dispatch,
}))(AccountConnectionsAdaptorContainer);
