import { put, fork, call, takeEvery } from 'redux-saga/effects';
import log from 'loglevel';
import keyBy from 'lodash/keyBy';
import * as types from '../../types';
import { updateCollectionEntryBatch, setGlobalLoadingState } from '../../actions/globalActions';
import { jobRequestLoadPhoto } from './actions';
import { getGQLJobRequestsForProperty } from '../../graphql/api/jobList';

export const loadingKey = 'PropertyJobPhototsLoadingKey';

const limit = 30;

function* handleProperty(func, propertyId, role, page, queryLimit) {
  const propertyResponse = yield call(func, propertyId, role, page, queryLimit);
  yield put(updateCollectionEntryBatch('jobRequests', keyBy(propertyResponse, 'jobId'), true));
  return propertyResponse;
}

function* jobRequestPhotoSaga({ propertyId, page, pagination }) {
  try {
    log.info('jobRequestPhotoSaga', { propertyId, page, pagination });
    yield put(setGlobalLoadingState(loadingKey, pagination ? -1 : 1, { page }));
    const res = yield call(handleProperty, getGQLJobRequestsForProperty, propertyId, 'HOST', page, limit);
    const hasMore = res.length === limit;
    yield put(setGlobalLoadingState(loadingKey, 2, { page, hasMore }));

    // Load next page
    if (hasMore) {
      yield put(jobRequestLoadPhoto({ propertyId, page: page + 1, pagination: true }));
    }
  } catch (e) {
    log.error('jobRequestPhotoSaga', e);
    yield put(setGlobalLoadingState(loadingKey, 3, { page }));
  }
}

function* saga() {
  yield fork(takeEvery, types.JOB_REQUEST_LOAD_PHOTO, jobRequestPhotoSaga);
}

export default saga;
