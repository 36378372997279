import React from 'react';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const renderMan = val => {
  if (!val) return null;
  return <span className={cx('checkout__input-man')}>*</span>;
};

const CheckoutLabel = ({ label, isRequired, id, children, icon }) => (
  <div>
    <label className={cx('checkout__input-label')} htmlFor={id} style={{ display: 'flex' }}>
      {label} {icon} {renderMan(isRequired)}
    </label>
    {children}
  </div>
);

export default CheckoutLabel;
