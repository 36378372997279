import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import CalendarContainer from './CalendarContainer';
import MainWrapper from '../../../../containers/MainWrapper';

const CalendarRoot = props => (
  <MainWrapper currentPage="calendar" disablePreloadLoader>
    <CalendarContainer {...props} />
    {props.children}
  </MainWrapper>
);

export default withRouter(compose()(CalendarRoot));
