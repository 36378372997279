import log from 'loglevel';
import { eventChannel } from 'redux-saga';
import { client as apolloClient } from '../../../../config/apollo';
import {
  GET_CONNECTED_ACCOUNT_QUERY,
  LISTEN_FOR_CONNECTED_ACCOUNTS_SUBSCRIPTION,
  DISCONNECT_CONNECTED_ACCOUNT_QUERY,
  SYNC_CONNECTED_ACCOUNT_QUERY_NEWSERVICE,
  SYNC_CONNECTED_ACCOUNT_QUERY,
} from './AccountQuery';
import ConnectedAccountClass from '../../../../model/connectedAccount';

export function getRealConnectedAccounts() {
  return apolloClient
    .query({
      query: GET_CONNECTED_ACCOUNT_QUERY,
      fetchPolicy: 'no-cache',
      variables: {},
    })
    .then(({ data, error }) => {
      if (error) {
        log.error('AccountApi - Error getting connected accounts');
        throw error;
      }

      const { ConnectedAccount } = data || {};
      const connectedAccountMapped = ConnectedAccount.map(account => new ConnectedAccountClass(account));

      const connectionSettingsCount = (connectedAccountMapped || []).reduce(
        (acc, current) => acc + (current.connectionSettings || []).length,
        0,
      );

      return { connectedAccounts: connectedAccountMapped, connectionSettingsCount };
    });
}

export function disconnectConnectedAccount(id) {
  return apolloClient
    .mutate({
      mutation: DISCONNECT_CONNECTED_ACCOUNT_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        connectionSettingsId: id,
      },
    })
    .then(({ data, error }) => {
      if (error) {
        log.error('AccountApi - Error disconnected connected account', id);
        throw error;
      }

      const { DeleteConnectionSetting } = data || {};

      return DeleteConnectionSetting;
    });
}

export function syncConnectedAccount(id, partner) {
  if (partner === 'newbook') {
    return apolloClient
      .mutate({
        mutation: SYNC_CONNECTED_ACCOUNT_QUERY_NEWSERVICE,
        fetchPolicy: 'no-cache',
        variables: {
          connectionSettingsId: id,
          partner,
        },
      })
      .then(({ data, error }) => {
        if (error) {
          log.error('AccountApi - Error sync connected account', id);
          throw error;
        }

        const { SyncConnectionSetting } = data || {};

        return SyncConnectionSetting;
      });
  }

  return apolloClient
    .mutate({
      mutation: SYNC_CONNECTED_ACCOUNT_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        connectionSettingsId: id,
      },
    })
    .then(({ data, error }) => {
      if (error) {
        log.error('AccountApi - Error sync connected account', id);
        throw error;
      }

      const { SyncConnectionSetting } = data || {};

      return SyncConnectionSetting;
    });
}

export const createConnectedAccountsSubscriptionChannel = params =>
  eventChannel(emitter => {
    const observable = apolloClient.subscribe({
      query: LISTEN_FOR_CONNECTED_ACCOUNTS_SUBSCRIPTION,
      variables: params,
      fetchPolicy: 'no-cache',
    });

    const subscription = observable.subscribe({
      next(payload) {
        log.info(
          '[createConnectedAccountsSubscriptionChannel] client payload from listenForConnectedAccounts',
          payload,
        );

        const { listenForConnectedAccounts: ConnectedAccount } = payload.data || {};

        const connectionSettingsCount = (ConnectedAccount || []).reduce(
          (acc, current) => acc + (current.connectionSettings || []).length,
          0,
        );

        emitter({ connectedAccounts: ConnectedAccount, connectionSettingsCount });
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  });
