import gql from 'graphql-tag';

export const SUBSCRIPTION_LISTEN_FOR_VERIFICATION_PHOTOS = gql`
  subscription listenForVerificationPhotos($jobSnapshotId: ID!) {
    listenForVerificationPhotos(jobSnapshotId: $jobSnapshotId) {
      checklistId
      type
      verificationPhoto {
        ...verificationPhotoFields
      }
    }
  }

  fragment verificationPhotoFields on verificationPhotoFields {
    id
    stepId
    stepTitle
    pictureIdentifier
    date
  }
`;
