"use strict";

require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.filter");

require("core-js/modules/es.array.find");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.object.assign");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.search");

require("core-js/modules/es.string.split");

require("core-js/modules/es.string.trim");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOverrideEnvironment = getOverrideEnvironment;
exports.setOverrideEnvironment = setOverrideEnvironment;
exports.resetOverrideEnvironment = resetOverrideEnvironment;
exports.getEnvironment = getEnvironment;

var _loglevel = _interopRequireDefault(require("loglevel"));

var _environments = _interopRequireDefault(require("./environments.json"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var queryString = typeof window !== 'undefined' ? require('query-string') : {
  parse: function parse() {
    return null;
  }
};
var store = typeof window !== 'undefined' ? require('store') : {
  remove: function remove() {
    return null;
  },
  set: function set() {
    return null;
  },
  get: function get() {
    return null;
  }
};
var PRODUCTION_KEY = 'prod';

var PRODUCTION_ENV = _environments["default"].find(function (_ref) {
  var env = _ref.env;
  return env === PRODUCTION_KEY;
});

var LOCAL_STORAGE_ENV_OVERRIDE_KEY = 'properly:overrideEnvironment';

var getStoredOverride = function getStoredOverride() {
  return store.get(LOCAL_STORAGE_ENV_OVERRIDE_KEY);
};

var setStoredOverride = function setStoredOverride(value) {
  return store.set(LOCAL_STORAGE_ENV_OVERRIDE_KEY, value);
};

var isE2E = !!window['testcafe|request-barrier'];
var nodeEnv = process.env.NODE_ENV || 'prod';
var isProd = nodeEnv.indexOf('prod') !== -1;

var getEnvOverride = function getEnvOverride() {
  return !isProd && process.env.REACT_APP_BACKEND_ENV || null;
};

var overrideEnvironment = getStoredOverride() || getEnvOverride() || null;
var initialSearchQuery = typeof window !== 'undefined' && window.location.search;

var getEnvironmentSearchQuery = function getEnvironmentSearchQuery() {
  try {
    var _ref2 = queryString.parse(initialSearchQuery) || {},
        environment = _ref2.environment,
        env = _ref2.env,
        everest = _ref2.everest,
        beta = _ref2.beta,
        stage = _ref2.stage,
        staging = _ref2.staging,
        develop = _ref2.develop,
        dev = _ref2.dev,
        prod = _ref2.prod,
        production = _ref2.production,
        sandbox = _ref2.sandbox;

    var mappings = {
      production: 'prod',
      develop: 'dev',
      development: 'dev',
      stage: 'staging'
    };
    var envString = String(environment || env || '').toLowerCase();

    if (mappings[envString]) {
      return mappings[envString];
    }

    var devString = dev ? 'dev' : null;
    var developString = develop ? 'dev' : null;
    var everestString = everest ? 'everest' : null;
    var betaString = beta ? 'beta' : null;
    var stageString = stage ? 'stage' : null;
    var stagingString = staging ? 'staging' : null;
    var sandboxString = sandbox ? 'sandbox' : null;
    var prodString = prod ? 'prod' : null;
    var productionString = production ? 'prod' : null;
    return environment || env || everestString || betaString || stageString || stagingString || devString || developString || sandboxString || prodString || productionString;
  } catch (err) {
    console.error('Error getting environment from query', err); // eslint-disable-line

    return '';
  }
};

function getOverrideEnvironment() {
  return getStoredOverride();
}

function setOverrideEnvironment(env) {
  store.set(LOCAL_STORAGE_ENV_OVERRIDE_KEY, env);
}

function resetOverrideEnvironment() {
  store.remove(LOCAL_STORAGE_ENV_OVERRIDE_KEY);
}

var logCache = {};

var logOnce = function logOnce(string) {
  var level = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'info';

  try {
    if (!logCache[string]) {
      logCache[string] = true;

      _loglevel["default"][level](string);
    }
  } catch (err) {// empty
  }
};
/**
 * Get our environment based on the current domain.
 *
 * In the future it would be nice to get this config from a relative url or config service
 * that determines a dynamic config from the referer
 */


function getEnvironment(forceEnv) {
  var currentHostname = window.location.hostname || '';
  var currentSubDomain = currentHostname.split('.')[0];
  var urlOverrideEnvironment = getEnvironmentSearchQuery();

  if (urlOverrideEnvironment) {
    // Set it in our local storage unless the resolved domain env is prod
    setStoredOverride(urlOverrideEnvironment);
  }

  var override = urlOverrideEnvironment || overrideEnvironment || forceEnv;
  var isOverride = !!override;

  if (isOverride) {
    logOnce("Environment has been overridden ".concat(override));
  }

  var subDomainTest = function subDomainTest(env) {
    return [].concat(env.subDomain).indexOf(currentSubDomain) !== -1;
  };

  var overrideTest = function overrideTest(_ref3) {
    var env = _ref3.env;
    return env === override || env === forceEnv;
  };

  var domainEnvironment = _environments["default"].filter(subDomainTest);

  var possibleEnvironments = isOverride ? _environments["default"].filter(overrideTest) : domainEnvironment;
  var currentEnvironment = possibleEnvironments[0];

  if (isOverride && currentEnvironment) {
    logOnce("Returning override environment ".concat(override));
    return _extends({}, currentEnvironment, {
      isOverride: isOverride,
      isE2E: isE2E,
      subDomainEnv: domainEnvironment,
      all: _environments["default"]
    });
  }

  if (isOverride && !currentEnvironment) {
    logOnce("No environments matched for override ".concat(override), 'error');
    currentEnvironment = PRODUCTION_ENV;
  } else if (!currentSubDomain) {
    logOnce("No subdomain found ".concat(currentHostname).trim(), 'error');
    currentEnvironment = PRODUCTION_ENV;
  } else if (possibleEnvironments.length > 1) {
    logOnce("More than one environment matched ".concat(JSON.stringify(possibleEnvironments)), 'error');
    currentEnvironment = PRODUCTION_ENV;
  } else if (!currentEnvironment) {
    logOnce("No environments matched for subdomain ".concat(currentSubDomain), 'error');
    currentEnvironment = PRODUCTION_ENV;
  }

  return _extends({}, currentEnvironment, {
    isOverride: isOverride,
    isE2E: isE2E,
    subDomainEnv: domainEnvironment,
    all: _environments["default"]
  });
}