import React, { Component } from 'react';
import t from '@properly/localization';
import { Box, SpaceMedium, SpaceTiny, SpaceSmall, SpaceLarge, Regular, Small } from '@properly/components';
import { showMsgIntercom } from '@properly/common';
import { push } from 'react-router-redux';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import styles from './loginSignUpStyles.css';
import AppStoreBar from '../../containers/AppStoreBar';
import LoginWrapContainer from './LoginWrapContainer';
import { ROUTES } from '../../paths';

const cx = classNames.bind(styles);

class HostSignUpCompleteComponent extends Component {
  resendLink = event => {
    event.preventDefault();
    this.props.dispatch(push(ROUTES.hostDownload));
  };

  startChat = event => {
    event.preventDefault();
    showMsgIntercom();
  };

  render() {
    const { user } = this.props.currentUser;
    const email = user.getEmail();
    const phone = user.getPhone();

    let phoneString = '';

    if (phone) {
      phoneString = (
        <span>
          {t('signup.download.description_sent', {
            phone: <strong>{phone}</strong>,
          })}
          <br />
          <br />
        </span>
      );
    }
    const styleBox = { padding: 24, height: 'auto' };

    return (
      <LoginWrapContainer center withSubHeadline title={t('signup.complete.title', {})}>
        <Regular>
          {phoneString}
          {t('signup.download.description_sent_2', {
            username: <strong>{email}</strong>,
          })}
        </Regular>
        <SpaceMedium />
        <Box isFirst isLast styleOverwrite={styleBox}>
          <div className={cx('wizard__clicktxt-wrap')}>
            <Small>{t('signup.complete.no_link')}</Small>
            <SpaceTiny />
            <div onClick={this.resendLink} className={cx('wizard__clicktxt')}>
              {t('signup.complete.send_to_different')}
            </div>
          </div>
        </Box>
        <SpaceSmall />
        <Box isFirst isLast styleOverwrite={styleBox}>
          <div className={cx('wizard__clicktxt-wrap')}>
            <Small>{t('signup.complete.questions')}</Small>
            <SpaceTiny />
            <div onClick={this.startChat} className={cx('wizard__clicktxt')}>
              {t('signup.complete.chat', {})}
            </div>
          </div>
        </Box>
        <SpaceLarge />
        <AppStoreBar />
      </LoginWrapContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}

export default connect(
  mapStateToProps,
  dispatch => ({ dispatch }),
)(HostSignUpCompleteComponent);
