import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CommentExpansionPanel from '../CommentExpansionPanel';
import { selectComments, selectJobDetails } from '../../modules/desktopApp/jobDetails/state/JobDetailsSelectors';
import { getComments, addComments, handleClickSaga } from '../../modules/desktopApp/jobDetails/state/JobDetailsActions';

class CommentsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.commentDetailsPanel = React.createRef();
    this.state = {
      expanded: false,
    };
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    const { expanded } = this.state;
    if (expanded) {
      this.scrollToBottom();
      this.scrollCommentsIntoView();
    }
  }

  handleCommentPanelExpanded = isExpanded => {
    this.setState({ expanded: isExpanded });
  };

  scrollCommentsIntoView = () => {
    this.commentDetailsPanel.current.scrollIntoView();
  };

  scrollToBottom = () => {
    this.commentDetailsPanel.current.scrollTop = this.commentDetailsPanel.current.scrollHeight;
  };

  handleTaskPicUpload = async event => {
    const { handleClickSagaEvent } = this.props;
    await handleClickSagaEvent(false, {
      action: 'addTaskPic',
      addTaskPic: event,
    });
  };

  sendComment = message => {
    const { currentUser, jobDetails, addComment } = this.props;
    const commentInfo = {
      userRole: currentUser.isHost ? 'HOST' : 'CLEANER',
      message,
      pictureIdentifier: '',
      jobSnapshotId: jobDetails.selectedJobRequestId,
      date: new Date().toISOString(),
    };
    addComment(commentInfo);
  };

  render() {
    const {
      comments,
      currentUser,
      getCommentsResponse,
      jobDetails: { selectedJobRequestId: jobId, commentLoader },
    } = this.props;

    return (
      <CommentExpansionPanel
        comments={comments}
        currentUser={currentUser}
        getComments={() => {
          getCommentsResponse({ jobId });
        }}
        onSendComment={this.sendComment}
        loader={commentLoader}
        jobRequestId={jobId}
        onUploadTaskPic={this.handleTaskPicUpload}
        commentDetailsPanel={this.commentDetailsPanel}
        handleCommentPanelExpanded={this.handleCommentPanelExpanded}
      />
    );
  }
}

function mapStateToProps(state, props) {
  return {
    comments: selectComments()(state, props).toJS(),
    jobDetails: selectJobDetails()(state, props).toJS(),
  };
}

export default connect(mapStateToProps, {
  getCommentsResponse: getComments,
  addComment: addComments,
  handleClickSagaEvent: handleClickSaga,
})(CommentsContainer);

CommentsContainer.propTypes = {
  currentUser: PropTypes.shape({
    isHost: PropTypes.bool.isRequired,
  }),
  jobDetails: PropTypes.shape({
    selectedJobRequestId: PropTypes.string.isRequired,
  }),
  getCommentsResponse: PropTypes.func.isRequired,
  handleClickSagaEvent: PropTypes.func.isRequired,
  addComment: PropTypes.func.isRequired,
};

CommentsContainer.defaultProps = {
  currentUser: {
    isHost: true,
  },
  jobDetails: {
    selectedJobRequestId: '',
  },
};
