import log from 'loglevel';
import { fromJS } from 'immutable';
import { getConfigSync } from '@properly/config';
import {
  SETTINGS_COUNTRY_SUCCESS,
  SET_DASHBOARD_ADMIN_DETAILS,
  SETTINGS_GET_USER_FEATURES_REQUEST,
  SETTINGS_SET_USER_FEATURES_SUCCESS,
  SETTINGS_SET_USER_FEATURES_FAILURE,
  SETTINGS_GET_USER_FEATURE_FLAGS_REQUEST,
  SETTINGS_SET_USER_FEATURE_FLAGS_SUCCESS,
  SETTINGS_SET_USER_FEATURE_FLAGS_FAILURE,
  GLOBAL_LOGOUT,
} from '../../../../types';

const config = getConfigSync();

/*
 * Message store for global messages, i.e. Network messages / Redirect messages
 * that need to be communicated on the page itself. Ideally
 * messages/notifications should appear within the component to give the user
 * more context. - My 2 cents.
 */

const isAdminModeOpened = () => {
  try {
    const dashboardAppUrl = new RegExp('^//localhost').test(config.APP_DASHBOARD_LINK)
      ? String(config.APP_DASHBOARD_LINK).replace('//', 'http://')
      : String(config.APP_DASHBOARD_LINK).replace('//', 'https://');
    const appAdminUrl = new RegExp('^//localhost').test(config.APP_ADMIN_LINK)
      ? String(config.APP_ADMIN_LINK).replace('//', 'http://')
      : String(config.APP_ADMIN_LINK).replace('//', 'https://');

    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // fallback for non-chrome browsers
    let parentOriginURL = document.referrer;

    // for chrome as primary recommended browser
    if (isChrome) {
      [parentOriginURL] = window.location.ancestorOrigins;
    }
    if (parentOriginURL) {
      // guard against undefined error if not in adminMode
      parentOriginURL = parentOriginURL.endsWith('/') ? parentOriginURL.slice(0, -1) : parentOriginURL;
    }
    if (parentOriginURL === dashboardAppUrl && window.location.origin === appAdminUrl) {
      return true;
    }
  } catch (error) {
    // error is a permission error that top.location.href is not accessible
    // (which means parent domain <> iframe domain)
    log.info('not admin user', error);
    return false;
  }
  return false;
};

const initState = fromJS({
  currentCountry: 'US',
  isAdminMode: isAdminModeOpened(),
  adminUser: '',
  adminSession: '',
  userFeatures: [],
  userFeaturesLoading: false,
  userFeaturesError: null,
  userFeatureFlags: [],
  userFeatureFlagsLoading: false,
  userFeatureFlagsError: null,
});

export default function SettingsReducer(state = initState, action = {}) {
  switch (action.type) {
    case GLOBAL_LOGOUT: // on logout reset
      return initState;
    case SETTINGS_COUNTRY_SUCCESS:
      return state.merge({
        currentCountry: action.currentCountry,
      });
    case SET_DASHBOARD_ADMIN_DETAILS:
      return state.merge({
        adminUser: action.adminName,
        adminSession: action.adminSession,
      });

    // features
    case SETTINGS_GET_USER_FEATURES_REQUEST:
      return state.merge({
        userFeaturesLoading: true,
        userFeaturesError: null,
      });
    case SETTINGS_SET_USER_FEATURES_SUCCESS:
      return state.merge({
        userFeatures: action.userFeatures,
        userFeaturesLoading: false,
      });
    case SETTINGS_SET_USER_FEATURES_FAILURE:
      return state.merge({
        userFeaturesError: action.error,
        userFeaturesLoading: false,
      });

    // feature flags
    case SETTINGS_GET_USER_FEATURE_FLAGS_REQUEST:
      return state.merge({
        userFeatureFlagsLoading: true,
        userFeatureFlagsError: null,
      });
    case SETTINGS_SET_USER_FEATURE_FLAGS_SUCCESS:
      return state.merge({
        userFeatureFlags: action.userFeatureFlags,
        userFeatureFlagsLoading: false,
      });
    case SETTINGS_SET_USER_FEATURE_FLAGS_FAILURE:
      return state.merge({
        userFeatureFlagsError: action.error,
        userFeatureFlagsLoading: false,
      });

    default:
      return state;
  }
}
