"use strict";

require("core-js/modules/es.array.concat");

require("core-js/modules/es.object.assign");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.promise");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getConfigSync = getConfigSync;
exports.getConfig = getConfig;
exports.fetchLocalConfig = fetchLocalConfig;
exports.fetchHttpConfig = fetchHttpConfig;
exports.isLocalOrDevelopOrStagingSync = exports.isDevEnvSync = exports.isStagingEnvSync = exports.isEverestEnvSync = exports.isLocalEnvSync = exports.initConfig = void 0;

var _loglevel = _interopRequireDefault(require("loglevel"));

var _config = require("./config.environment");

var _config2 = _interopRequireDefault(require("./config.schema"));

var _config3 = _interopRequireDefault(require("./config.build"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var fetchPromiseCache;

var initConfig = function initConfig(targetEnv) {
  return getConfig(targetEnv);
};

exports.initConfig = initConfig;

function getConfigSync(targetEnv) {
  // NOTE: This is not ideal as it means we cannot decouple our configuration from
  // the app itself and load config from the server etc
  var env = (0, _config.getEnvironment)(targetEnv);
  var url = env.path;
  return getLocalConfig(env, url);
}

function getConfig(targetEnv) {
  var force = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var configPromiseResult = (fetchPromiseCache = !force && fetchPromiseCache || Promise.resolve().then(function () {
    var env = (0, _config.getEnvironment)(targetEnv);
    var url = env.path;
    return fetchLocalConfig(env, url);
  }))["catch"](function (err) {
    _loglevel["default"].error('Error fetching config', err);

    throw err;
  });
  return configPromiseResult;
}

function fetchLocalConfig(env, url) {
  return new Promise(function (resolve, reject) {
    try {
      var config = getLocalConfig(env, url);
      return resolve(config);
    } catch (err) {
      return reject(err);
    }
  });
}

function getLocalConfig(env, url) {
  var config = require(".".concat(url)); // eslint-disable-line


  (0, _config2["default"])(config);
  var build = (0, _config3["default"])();

  var complete = _extends({
    env: env
  }, build, config);

  if (typeof window !== 'undefined') {
    window.PROPERLY_CONFIG = complete;
  }

  return complete;
}

function fetchHttpConfig(env, url) {
  return Promise.resolve().then(function () {
    return fetch(url).then(function (response) {
      return response.json();
    }).then(function (config) {
      return _extends({
        env: env
      }, config);
    }).then(function (config) {
      (0, _config2["default"])(config);
      return config;
    }).then(function (config) {
      return _extends({}, config, (0, _config3["default"])());
    }).then(function (config) {
      if (typeof window !== 'undefined') {
        window.PROPERLY_CONFIG = config;
      }

      return config;
    })["catch"](function (err) {
      fetchPromiseCache = null;

      _loglevel["default"].error('Error fetching config', err);

      throw new Error("Unknown environment ".concat(env.env, " ").concat(url));
    });
  });
}

var isEnvSync = function isEnvSync(targetEnv) {
  var _getConfigSync = getConfigSync(),
      env = _getConfigSync.env;

  var _ref = env || {},
      envName = _ref.env;

  return envName === targetEnv;
};

var isLocalEnvSync = function isLocalEnvSync() {
  return isEnvSync('local');
};

exports.isLocalEnvSync = isLocalEnvSync;

var isEverestEnvSync = function isEverestEnvSync() {
  return isEnvSync('everest');
};

exports.isEverestEnvSync = isEverestEnvSync;

var isStagingEnvSync = function isStagingEnvSync() {
  return isEnvSync('staging');
};

exports.isStagingEnvSync = isStagingEnvSync;

var isDevEnvSync = function isDevEnvSync() {
  return isEnvSync('dev');
};

exports.isDevEnvSync = isDevEnvSync;

var isLocalOrDevelopOrStagingSync = function isLocalOrDevelopOrStagingSync() {
  return isLocalEnvSync() || isEverestEnvSync() || isStagingEnvSync() || isDevEnvSync();
};

exports.isLocalOrDevelopOrStagingSync = isLocalOrDevelopOrStagingSync;