import React from 'react';
import ModalImage from 'react-modal-image';
import { pictureSizes, generatePictureUrl } from '@properly/common';
import PropTypes from 'prop-types';

function ImageRender(props) {
  const { image } = props;
  let cloudinaryDownloadLink = generatePictureUrl(image, '', 'jpg');
  // we use fl_attachment flag https://cloudinary.com/documentation/transformation_flags#delivery_and_image_format_flags
  // this is required for the library to download the image
  cloudinaryDownloadLink = cloudinaryDownloadLink.replace('upload', 'upload/fl_attachment');
  // if we use fauto mode it doesn't download jpg along with fl_attachment
  cloudinaryDownloadLink = cloudinaryDownloadLink.replace('f_auto/', '');

  return (
    <ModalImage
      showRotate
      small={generatePictureUrl(image, pictureSizes.ORIGINAL)}
      large={cloudinaryDownloadLink}
      alt=""
    />
  );
}

ImageRender.propTypes = {
  image: PropTypes.string,
};

ImageRender.defaultProps = {
  image: '',
};

export default ImageRender;
