export const isCustomGraphQLError = (response, errorMessage) =>
  response.graphQLErrors && response.graphQLErrors[0].message === errorMessage;

const tasksContainsProblems = tasks => tasks.some(task => task.isProblem);

export const getProblemIdsFromTasks = tasks => {
  if (tasks.length > 0 && tasksContainsProblems(tasks)) {
    return tasks.filter(task => task.id).map(task => task.id);
  }
  return undefined;
};

export const determineErrorMessage = e => {
  if (e.graphQLErrors) {
    return JSON.parse(e.graphQLErrors?.[0].message);
  }

  return undefined;
};
