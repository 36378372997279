import React from 'react';
import { Modal, Icon, SpaceTiny } from '@properly/components';
import PropTypes from 'prop-types';
import t from '@properly/localization';
import { withStyles } from '@material-ui/core/styles';
import colors from '@properly/components/lib/common/styles/colors.module.css';
import './index.css';

const styles = () => ({
  link: {
    color: colors['color-primary'],
    textDecoration: 'none',
    '&:hover': {
      color: colors['color-primary-hover'],
    },
  },
});

const modalText = ({ propertyType, config, classes }) => {
  if (propertyType) {
    return (
      <>
        <div className="modal-header__text">
          <span className="modal-header__text-content">{t('probadge_modal.projob')}</span>
        </div>
        <Modal.Text>
          {t('probadge_modal.projob_explanation', {
            msg: (
              <a
                className={`${classes?.link} text-l6m`}
                target="_blank"
                rel="noopener noreferrer"
                href={config.proMPSatisfactionGuaranteeUrl}
              >
                {t('marketplace.guarantee')}
              </a>
            ),
            tos: (
              <a
                className={`${classes?.link} text-l6m`}
                target="_blank"
                rel="noopener noreferrer"
                href={config.proTOSUrl}
              >
                {t('marketplace.expansion_content_tos')}
              </a>
            ),
          })}
        </Modal.Text>
      </>
    );
  }
  return (
    <>
      <div className="modal-header__text">
        <span className="modal-header__text-content">{t('probadge_modal.header')}</span>
      </div>
      <Modal.Text>{t('probadge_modal.sub_header')}</Modal.Text>
      <Modal.Text>
        <ul>
          <li className="modal-text__list">
            <Icon.Bigcheck width="20px" />
            <span className="modal-text__list-content"> {t('probadge_modal.content_1')}</span>
          </li>
          <SpaceTiny />
          <li className="modal-text__list">
            <Icon.Bigcheck width="20px" />
            <span className="modal-text__list-content"> {t('probadge_modal.content_2')}</span>
          </li>
          <SpaceTiny />
          <li className="modal-text__list">
            <Icon.Bigcheck width="20px" />
            <span className="modal-text__list-content">{t('probadge_modal.content_3')}</span>
          </li>
          <SpaceTiny />
          <li className="modal-text__list">
            <Icon.Bigcheck width="20px" />
            <span className="modal-text__list-content">
              {t('probadge_modal.content_6', {
                msg: (
                  <a className={`${classes?.link} text-l6m`} href={config.proMPSatisfactionGuaranteeUrl}>
                    {t('marketplace.guarantee')}
                  </a>
                ),
              })}
            </span>
          </li>
        </ul>
      </Modal.Text>
      <Modal.Text>{t('probadge_modal.conclusion')}</Modal.Text>
    </>
  );
};

const ProBadgeModal = ({ openProModal, onClickfunc, propertyType, config, classes }) => (
  <Modal height={propertyType ? 200 : 450} onClick={onClickfunc} onClose={onClickfunc} show={openProModal}>
    {modalText({ propertyType, config, classes })}
    <Modal.Options>
      <Modal.Ok onClick={onClickfunc}>{t('modal.ok')}</Modal.Ok>
    </Modal.Options>
  </Modal>
);

ProBadgeModal.propTypes = {
  openProModal: PropTypes.bool.isRequired,
  onClickfunc: PropTypes.func.isRequired,
  propertyType: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ProBadgeModal);
