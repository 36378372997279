import * as Sentry from '@sentry/react';
import log from 'loglevel';
import { sendEventToGoogleAnalytics } from '../config/googleAdwords';

let trackingEnabled = true;

export const trackSentry = (name, data) =>
  trackingEnabled &&
  Sentry.addBreadcrumb({
    message: name,
    data,
  });

export const trackGoogle = (name, data) => {
  if (trackingEnabled) {
    sendEventToGoogleAnalytics('webapp', name, JSON.stringify(data));
  }
};

export const trackEvent = (name, data) => {
  log.info('tracking event', name, data);
  trackGoogle(name, data);
  trackSentry(name, data);
};

export const setTracking = val => {
  trackingEnabled = val;
};
