import React from 'react';
import classNames from 'classnames/bind';
import styles from './index.module.css';
import { TodosTextEle } from '../TodosElements';

const cx = classNames.bind(styles);

const ReceiptsTable = ({ children }) => <table className={cx('account__receipt-table')}>{children}</table>;
const ReceiptsTableHead = ({ children }) => <thead>{children}</thead>;
const ReceiptsTableBody = ({ children }) => <tbody>{children}</tbody>;
const ReceiptsTableRow = ({ children, isHead }) => (
  <tr
    className={cx('account__receipt-table-tr', {
      'account__receipt-table-tr--head': isHead,
    })}
  >
    {children}
  </tr>
);

const ReceiptsTableCol = ({ children, onClick, isUnderlined, isHead }) => {
  const txtKey = isHead ? 'txt--l6m' : 'txt--l5';
  return (
    <td
      onClick={onClick}
      className={cx('account__receipt-table-td', {
        'account__receipt-table-td--underline': isUnderlined,
        'account__receipt-table-tr--head': isHead,
      })}
    >
      <TodosTextEle modifier={[txtKey, 'color--black']}>{children}</TodosTextEle>
    </td>
  );
};

ReceiptsTable.Head = ReceiptsTableHead;
ReceiptsTable.Body = ReceiptsTableBody;
ReceiptsTable.Row = ReceiptsTableRow;
ReceiptsTable.Col = ReceiptsTableCol;

export default ReceiptsTable;
