import { fromJS } from 'immutable';
import * as types from '../../types';

const initState = fromJS({
  config: {
    ads: {
      propertyPage: {},
      checklistLibraryPage: {},
      cleanerPage: {},
      calendarPage: {},
      todosPage: {},
    },
    style: {},
    onBoardingVideoUrl: '',
    onBoardingVideoDisplayPaths: [],
    landingPage: '',
    onboardingConfig: {},
  },
  branchIOLink: '',
});

export default function(state = initState, action = {}) {
  switch (action.type) {
    case types.BRANDING_CONFIG_SET:
      return state.set('config', fromJS(action.data));
    case types.GLOBAL_LOGOUT:
      return initState;
    case types.SET_BRANCH_IO_LINK:
      return state.set('branchIOLink', fromJS(action.data));
    default:
      return state;
  }
}
