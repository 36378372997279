import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import t from '@properly/localization';
import colors from '@properly/components/lib/common/styles/colors.module.css';
import fonts from '@properly/components/lib/common/styles/sizes.module.css';
import TopLabel from '../../../../components/TopLabel';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  root: {
    textTransform: 'none',
    fontSize: fonts['font-size-regular'],
    fontWeight: 'normal',
  },
  indicator: {
    backgroundColor: colors['color-primary'],
  },
});

export const TABS = {
  CHECKLIST_LIBRARY: 0,
  SKILLS_LIBRARY: 1,
};

export const LibraryTabs = ({ handleNewChecklist, handleOnChangeTab, value }) => {
  const { indicator, root, ...classes } = useStyles();

  const getTabStyle = isActive => ({
    color: isActive ? colors['color-primary'] : colors['color-black'],
  });

  return (
    <div className={classes.container}>
      <Tabs classes={{ indicator }} value={value} onChange={handleOnChangeTab}>
        <Tab classes={{ root }} style={getTabStyle(value === TABS.CHECKLIST_LIBRARY)} label={t('titles.library')} />
        <Tab classes={{ root }} style={getTabStyle(value === TABS.SKILLS_LIBRARY)} label={t('skills.library')} />
      </Tabs>
      {value === TABS.CHECKLIST_LIBRARY ? (
        <TopLabel label={t('properties.create_new_checklist')} onClick={handleNewChecklist} />
      ) : null}
    </div>
  );
};

LibraryTabs.propTypes = {
  value: PropTypes.number.isRequired,
  handleOnChangeTab: PropTypes.func.isRequired,
  handleNewChecklist: PropTypes.func,
};

LibraryTabs.defaultProps = {
  handleNewChecklist: () => {},
};
