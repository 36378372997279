import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { LoadingSpinner, Icon, TaskForm } from '@properly/components';
import styles from './index.module.css';

const cx = classNames.bind(styles);

class ChecklistOverlay extends Component {
  focus() {
    if (this.elementRef) {
      this.elementRef.focus();
    }
  }

  componentDidMount() {
    this.focus();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      this.focus();
    }
  }

  render() {
    const { show, children, background, zIndex, isRoot } = this.props;
    if (!show) return null;
    return (
      <div
        style={{ zIndex }}
        ref={input => {
          this.elementRef = input;
        }}
        tabIndex="-1"
        className={cx('checklist-overlay', {
          'checklist-overlay--dark': background === 'dark',
          app: isRoot,
        })}
      >
        {children}
      </div>
    );
  }
}

// maintaince change
ChecklistOverlay.TaskForm = ({ children }) => (
  <div>
    <TaskForm>{children}</TaskForm>
  </div>
);

//   -----
ChecklistOverlay.Header = ({ children, type }) => (
  <div
    className={cx('checklist-overlay__header', {
      'checklist-overlay__header--primary': type === 'orange',
      'checklist-overlay__header--dark': type === 'dark',
    })}
  >
    {children}
  </div>
);

ChecklistOverlay.GridRow = ({ children, spacing = '0', style = {} }) => {
  const halfSpacing = (spacing / 2) * -1;
  const styleIn = {
    marginLeft: halfSpacing,
    marginRight: halfSpacing,
    ...style,
  };
  return (
    <div className={cx('checklist-overlay__gridrow')} style={styleIn}>
      {children}
    </div>
  );
};

ChecklistOverlay.GridItem = ({ children, spacing, itemPerRow, style = {} }) => {
  const halfSpacing = spacing / 2;
  const styleIn = {
    padding: halfSpacing,
    maxWidth: `${100 / itemPerRow}%`,
    ...style,
  };
  return (
    <div className={cx('checklist-overlay__griditem')} style={styleIn}>
      {children}
    </div>
  );
};

ChecklistOverlay.GridItemOverlay = ({ children, mode, left, number, hasHover }) => (
  <div
    data-key="property-images"
    className={cx('checklist-overlay__griditem-overlay', {
      'checklist-overlay__griditem-overlay--selected': mode === 'selected' || mode === 'num',
      'checklist-overlay__griditem-overlay--loading': mode === 'loading',
      'checklist-overlay__griditem-overlay--hover': hasHover,
      'checklist-overlay__griditem-overlay--left': left,
    })}
  >
    {mode === 'loading' && <LoadingSpinner />}
    {mode === 'open' && (
      <div data-key="property-images-icon" className={cx('checklist-overlay__griditem-overlay-icon')}>
        <Icon.IcCameraWhite />
      </div>
    )}
    {mode === 'canselect' && (
      <div data-key="property-images-icon" className={cx('checklist-overlay__griditem-overlay-icon')}>
        <Icon.IcSelectPhoto />
      </div>
    )}
    {mode === 'selected' && (
      <div data-key="property-images-icon" className={cx('checklist-overlay__griditem-overlay-icon')}>
        <Icon.IcSelectedPhoto2 />
      </div>
    )}
    {mode === 'num' && (
      <div data-key="property-images-icon" className={cx('checklist-overlay__griditem-overlay-icon')}>
        <Icon.IcSelectedPhotoBlank />
        <div className={cx('checklist-overlay__griditem-overlay-num')}>{number}</div>
      </div>
    )}
    {children}
  </div>
);

ChecklistOverlay.Content = ({ children, type, style }) => (
  <div className={cx('checklist-overlay__content', `checklist-overlay__content--${type}`)} style={style}>
    {children}
  </div>
);

ChecklistOverlay.ContentWrap = ({ children, style }) => (
  <div className={cx('checklist-overlay__content-wrap')} style={style}>
    {children}
  </div>
);

ChecklistOverlay.ContentInner = ({ children, style }) => (
  <div className={cx('checklist-overlay__content-inner')} style={style}>
    {children}
  </div>
);

ChecklistOverlay.propTypes = {
  show: PropTypes.bool,
  background: PropTypes.string,
  children: PropTypes.node,
};

export default ChecklistOverlay;
