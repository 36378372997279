import { push } from 'react-router-redux';
import * as types from '../../../types';
import { ROUTES } from '../../../paths';

export function setSelectedElementsSection(where, eleObj, flush, toggle) {
  return {
    type: types.REPORTS_SET_SELECTED_ELES,
    where,
    eleObj,
    flush,
    toggle,
  };
}

export function setOpenSection(where, val) {
  return {
    type: types.REPORTS_SET_OPEN_SECTION,
    where,
    val,
  };
}

export function setValueSection(where, val) {
  return {
    type: types.REPORTS_SET_VALUE_SECTION,
    where,
    val,
  };
}

export function setToggleValueSection(path) {
  return {
    type: types.REPORTS_SET_TOGGLE_VALUE_SECTION,
    path,
  };
}

export function reportsDateSaga(range, more) {
  return {
    type: types.REPORTS_DATE_SAGA,
    range,
    more,
  };
}

export function mergeSection(where, data) {
  return {
    type: types.REPORTS_MERGE_SECTION,
    where,
    data,
  };
}

export function setSelectedAll(where, val) {
  return {
    type: types.REPORTS_SET_SELECTED_ALL,
    where,
    val,
  };
}

export function setIsTouched(where, val) {
  return {
    type: types.REPORTS_SET_TOUCHED,
    where,
    val,
  };
}

export function goToReportDetail(id) {
  return push(ROUTES.reportPage(id));
}

export function goToReports() {
  return push(ROUTES.reports);
}

export function reportsSaveSaga() {
  return {
    type: types.REPORTS_SAVE_SAGA,
  };
}

export function reportsDeleteSaga(event, { ...props }) {
  return {
    type: types.REPORTS_DELETE_SAGA,
    ...props,
  };
}

export function reportsSetModal(what, meta) {
  return {
    type: types.REPORTS_SET_MODAL,
    what,
    meta,
  };
}

export function loadReportsRequest() {
  // Note: trying to remove the pageLoad HOC
  // This should really be a REPORTS_LOAD_REPORTS_REQUEST action

  return {
    type: 'PAGE_LOAD',
    dataLoaders: [{ key: 'reports', mode: 'cacheFresh' }],
  };
}

export function reportsLoadReportSaga(id) {
  return {
    type: types.REPORTS_LOADREPORT_SAGA,
    id,
  };
}

export function setLoadedReport(id) {
  return {
    type: types.REPORTS_SET_LOADED_REPORT,
    id,
  };
}

export function setSectionsReport(data) {
  return {
    type: types.REPORTS_SET_SECTIONS_REPORT,
    data,
  };
}

export function leaveHookSaga(nextLocation) {
  return {
    type: types.REPORTS_LEAVE_SAGA,
    nextLocation,
  };
}

export function leaveHookModalSaga(val) {
  return {
    type: types.REPORTS_LEAVE_HOOK_MODAL,
    val,
  };
}

export function setReportData(id, data, pointer, merge) {
  return {
    type: types.REPORTS_SET_REPORT_DATA,
    data,
    pointer,
    id,
    merge,
  };
}

export function setLoading(val) {
  return {
    type: types.REPORTS_SET_LOADING,
    val,
  };
}

export function paginate() {
  return {
    type: types.REPORTS_PAGINATE,
  };
}

export function exportCSVSaga(format) {
  return {
    type: types.REPORTS_EXPORT_SAGA,
    format,
  };
}

export function snapShot() {
  return {
    type: types.REPORTS_SET_SNAPSHOT,
  };
}

export function setValidation(val) {
  return {
    type: types.REPORTS_SET_VALIDATION,
    val,
  };
}

export function setAdminModeReportFields() {
  return {
    type: types.REPORTS_SET_ADMINMODE_REPORT_FIELDS,
  };
}

export function resetAdminModeReportFields() {
  return {
    type: types.REPORTS_RESET_ADMINMODE_REPORT_FIELDS,
  };
}

export function setPaymentDetailsReportFields() {
  return {
    type: types.REPORTS_SET_PAYMENT_DETAIL_REPORT_FIELDS,
  };
}
