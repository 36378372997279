import React, { PureComponent } from 'react';
import t from '@properly/localization';
import PropTypes from 'prop-types';

import curry from 'lodash/curry';
import lodashResult from 'lodash/result';
import { connect } from 'react-redux';
import { DividerLine, Modal, SpaceFlexi, Title3, Icon, Button, LoadingSplash } from '@properly/components';
import classNames from 'classnames/bind';
import styles from './permissionStyles.css';
import MainWrapper from '../../../containers/MainWrapper';
import { PagePadding } from '../../../components/PageElements';
import PermissionModal from './PermissionModal';
import { initPermissionsSaga, handlePermissionActionSaga } from './PermssionsActions';
import { selectLoadingState } from '../../../selectors/globalSelector';
import { initLoadingKey, teamLoadingKey, updateActionLoadingKey } from './PermissionsConstants';
import { selectFirstTeam, selectInfoMessage, selectTabData } from './permissionSelector';
import InfoMessage from '../../../components/InfoMessage';
import PermissionCollListNew from './PermissionCollListNew';
import { AccountHeadSection } from '../../../components/AccountElements';
import { TodosTextEle } from '../../../components/TodosElements';
import PermissionsTabBar from './PermissionsTabBar';
import { goTo } from '../../../actions/globalActions';
import { ROUTES } from '../../../paths';

const cx = classNames.bind(styles);

class PermissionsContainerPartner extends PureComponent {
  static propTypes = {
    initPermissionsSaga: PropTypes.func.isRequired,
    handlePermissionActionSaga: PropTypes.func.isRequired,
    goTo: PropTypes.func.isRequired,
    hasShared: PropTypes.bool.isRequired,
    hasPartner: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool,
    isLoadingFull: PropTypes.bool,
    infoMessage: PropTypes.shape({}).isRequired,
    loadingModal: PropTypes.shape({}).isRequired,
  };

  static defaultProps = {
    isLoaded: false,
    isLoadingFull: false,
  };

  constructor(props) {
    super(props);
    const { handlePermissionActionSaga } = this.props; // eslint-disable-line no-shadow
    this.curryedHandle = curry(handlePermissionActionSaga, 3);
  }

  componentDidMount() {
    const { initPermissionsSaga } = this.props; // eslint-disable-line no-shadow

    initPermissionsSaga();
  }

  componentDidUpdate(prevProps) {
    const { hasShared, isLoaded, goTo } = this.props; // eslint-disable-line no-shadow

    // redirect to shared page if someone has no partners but has shared
    if (!this.isSharedPage(prevProps) && !prevProps.hasPartner && hasShared && isLoaded) {
      goTo(ROUTES.settingsPartnerShared);
    }
    // if on shared page but no shared propertys then redirect to partner page
    if (this.isSharedPage(prevProps) && !prevProps.hasShared && isLoaded) {
      goTo(ROUTES.settingsPartner);
    }
  }

  get renderType() {
    if (this.isSharedPage(this.props)) {
      return 'shared';
    }
    return 'external';
  }

  get viewableTabs() {
    const { hasPartner, hasShared } = this.props;
    const arr = [];

    if (hasPartner) arr.push('external');
    if (hasShared) arr.push('shared');

    return arr;
  }

  get hasListItem() {
    const { hasPartner, hasShared } = this.props;
    return hasPartner || hasShared;
  }

  onClickTab = name => () => {
    if (name === 'shared') return this.props.goTo(ROUTES.settingsPartnerShared);
    return this.props.goTo(ROUTES.settingsPartner);
  };

  isSharedPage(props) {
    return props.route.path === 'partner/shared';
  }

  renderNoPartner() {
    return (
      <div>
        <SpaceFlexi type="vertical" size="179px" />
        <div style={{ position: 'relative ' }}>
          <div className={cx('permission__starticon')}>
            <Icon.IcCompany byWidth width="594px" />
          </div>
        </div>
        <div style={{ width: '364px', height: '400px' }}>
          <TodosTextEle modifier={['txt--l3m', 'color--black']}>{t('permissions.invite_partners')}</TodosTextEle>
          <SpaceFlexi type="vertical" size="25px" />
          <TodosTextEle modifier={['txt--l4', 'color--grey']}>{t('permissions.partners_start_txt')}</TodosTextEle>
          <SpaceFlexi type="vertical" size="36px" />
          <Button
            data-key="button_create_company"
            onClick={this.curryedHandle('invite_partner')({})}
            types={['type-full-primary', 'size-large']}
          >
            {t('permissions.invite_partner_btn')}
          </Button>
        </div>
      </div>
    );
  }

  renderPartner() {
    return (
      <div style={{ display: 'flex', flex: 1 }}>
        {this.hasListItem && (
          <div style={{ width: '100%' }}>
            <SpaceFlexi type="vertical" size="36px" />
            <PermissionsTabBar tabs={this.viewableTabs} activeTab={this.renderType} onClick={this.onClickTab} />
            <PermissionCollListNew renderType={this.renderType} />
          </div>
        )}
        {!this.hasListItem && this.renderNoPartner()}
      </div>
    );
  }

  renderTop(more) {
    return (
      <AccountHeadSection>
        <Title3 type="nomargin">{t('account.partner')}</Title3>
        <DividerLine type={['bottom']} />
        {more || null}
      </AccountHeadSection>
    );
  }

  renderMain() {
    const btn = this.hasListItem && (
      <div style={{ position: 'absolute', right: 0 }}>
        <Button
          data-key="button_invite_partner"
          onClick={this.curryedHandle('invite_partner')({})}
          types={['type-full-primary', 'size-medium']}
        >
          {t('permissions.invite_partner')}
        </Button>
      </div>
    );
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {this.renderTop(btn)}
        {this.renderPartner()}
      </div>
    );
  }

  render() {
    const { infoMessage, isLoaded, isLoadingFull, loadingModal } = this.props;

    return (
      <div>
        <MainWrapper disableWrapper currentPage="permission" color="grey">
          {infoMessage.get('text') && (
            <div className={cx('permission__infomessagewrap')}>
              <InfoMessage
                onClose={this.curryedHandle('hideinfomessage')({})}
                text={t(infoMessage.get('text'))}
                color="red"
                isSmall
                style={{
                  width: infoMessage.get('width') || 284,
                }}
              />
            </div>
          )}
          <PagePadding type="absolute-scroll">
            {isLoaded && this.renderMain()}
            {isLoadingFull && <LoadingSplash />}
          </PagePadding>
          <PermissionModal />
        </MainWrapper>
        <Modal type="loading" show={loadingModal.isLoadingFull} />
      </div>
    );
  }
}

function mapStateToProps() {
  const memo = selectLoadingState(initLoadingKey);
  const memo2 = selectLoadingState(teamLoadingKey);
  const memo3 = selectLoadingState(updateActionLoadingKey);
  const memo4 = selectTabData();
  return state => {
    const memo4Res = memo4(state);
    return {
      ...memo(state),
      loadingTeam: memo2(state),
      loadingModal: memo3(state),
      infoMessage: selectInfoMessage()(state),
      team: selectFirstTeam()(state),
      hasPartner: lodashResult(memo4Res, ['external'], []).length > 0,
      hasShared: lodashResult(memo4Res, ['shared'], []).length > 0,
    };
  };
}

export default connect(mapStateToProps, {
  initPermissionsSaga,
  handlePermissionActionSaga,
  goTo,
})(PermissionsContainerPartner);
