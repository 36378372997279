export default class Plan {
  constructor(
    objectId,
    planId,
    features,
    title,
    price,
    priceType,
    publicd,
    annual,
    weight,
    highlights,
    contactUs,
    mostPopular,
  ) {
    this.objectId = objectId;
    this.planId = planId;
    this.features = features;
    this.title = title;
    this.price = price;
    this.priceType = priceType;
    this.public = publicd;
    this.annual = annual;
    this.displayOrder = weight;
    this.highlights = highlights;
    this.contactUs = contactUs;
    this.mostPopular = mostPopular;
  }
}
