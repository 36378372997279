import log from 'loglevel';
import { client as apolloClient } from '../../config/apollo';

import { MUTATION_ADD_CONTACT_BY_ID, MUTATION_REMOVE_CONTACT_BY_ID } from '../mutations/contact';
import { QUERY_FIND_CONTACT } from '../queries/contact';

import { contactModelMapper } from '../datamap/contactMap';

export const addContactByIdGraphql = async contactId => {
  try {
    const createdContact = await apolloClient.mutate({
      mutation: MUTATION_ADD_CONTACT_BY_ID,
      variables: {
        contactId,
      },
    });
    const mappedresponse = contactModelMapper(createdContact.data.AddContactById);
    return mappedresponse;
  } catch (error) {
    log.error(`Create contact by id ${error}`);
    return null;
  }
};

export const removeContactByIdGraphql = async contactId => {
  try {
    const removedContact = await apolloClient.mutate({
      mutation: MUTATION_REMOVE_CONTACT_BY_ID,
      variables: {
        contactId,
      },
    });

    const mappedresponse = contactModelMapper(Object.values(removedContact.data)[0]);
    return mappedresponse;
  } catch (error) {
    log.error(`Remove contact by id ${error}`);
    return null;
  }
};

export const findContactGraphql = contactObject => {
  const { type } = contactObject;
  const phoneNumber = contactObject.phoneCountryCode + contactObject.phoneRegionalNumber?.trim();
  const search = type === 'email' ? contactObject.email.toString().trim() : phoneNumber.toString().trim();

  return apolloClient
    .query({
      query: QUERY_FIND_CONTACT,
      variables: { search, type },
      fetchPolicy: 'no-cache',
    })
    .then(({ data, errors }) => {
      if (errors) {
        log.error(`find contact query error ${errors}`);
        throw errors;
      }

      return Object.values(data)[0];
    });
};
