import { push } from 'react-router-redux';
import { ROUTES } from '../../../paths';

export function goToError500() {
  return push(ROUTES.error);
}

export function goToError404() {
  return push(ROUTES.error404);
}

export function goToDesktopSubscription() {
  return push(ROUTES.settingsSubscription);
}

export function goToMobileSubscription() {
  return push(ROUTES.mobileSubscription);
}

export function goToDesktopSubscriptionThankyou() {
  return push({ pathname: ROUTES.settingsSubscription, query: { thankyou: true } });
}
