import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import t from '@properly/localization';
import { EditableTagList } from '@properly/components';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import { GetPropertyTags } from '../state/PropertyQuery';
import { updatePropertyTags } from '../state/PropertyActions';

class PropertyTagContainer extends PureComponent {
  state = {};

  handleChange = value => {
    const { propertyId, update } = this.props;
    const tagStrings = (value || []).map(({ label }) => label);
    update(propertyId, tagStrings);
  };

  handleBlur = () => {
    const { refetch } = this.props;
    refetch();
  };

  render() {
    const { property, propertyTags } = this.props;

    const { tags } = property || {};
    const mappedTags = [].concat(tags || []).map(tag => ({ label: tag, value: tag.toUpperCase() }));

    return (
      <EditableTagList
        buttonLabel={t('properties.add_property_tag')}
        placeholder={t('properties.add_property_tag')}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        initTags={mappedTags}
        suggestions={[...(propertyTags || [])]}
      />
    );
  }
}

function mapStateToProps(state, props) {
  const { propertyId } = props;
  return {
    property: selectorsGlobal.selectPropertyMemorized()(state, { id: propertyId }),
  };
}

export default compose(
  // Query property tag suggestions
  graphql(GetPropertyTags, {
    props: ({ data }) => {
      const { propertyTags, refetch } = data;
      return { propertyTags, refetch };
    },
  }),
  // Query property object
  connect(mapStateToProps, { update: updatePropertyTags }),
)(PropertyTagContainer);
