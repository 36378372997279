import React from 'react';
import { CardShow } from '@properly/components';
import t from '@properly/localization';
import StripeSPPageHeader from './StripeSPPageHeader';

function StripeSPConnectSuccess() {
  return (
    <div>
      <StripeSPPageHeader />
      <CardShow stripelogo notifyIcon="success" content={t('payments.connect_successful')} />
    </div>
  );
}

export default StripeSPConnectSuccess;
