import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import t from '@properly/localization';
import { Icon } from '@properly/components';
import { DropTarget } from 'react-dnd';
import classNames from 'classnames/bind';
import styles from './checklistsStyles.module.css';

const cx = classNames.bind(styles);

const cardTarget = {
  drop(props, monitor) {
    props.onDropPinInTrash(monitor.getItem());
  },
};

class ChecklistImageTrash extends PureComponent {
  static propTypes = {
    connectDropTarget: PropTypes.func.isRequired,
    onDropPinInTrash: PropTypes.func.isRequired,
  };

  renderDropTarget() {
    return this.props.connectDropTarget(
      <div className={cx('checklist__trash-inner')}>
        <Icon.IcTrashSolid />
        <span>{t('checklist.remove')}</span>
      </div>,
    );
  }

  render() {
    return <div className={cx('checklist__trash')}>{this.renderDropTarget()}</div>;
  }
}

export default compose(
  DropTarget('PIN', cardTarget, connect => ({
    connectDropTarget: connect.dropTarget(),
  })),
)(ChecklistImageTrash);
