import React from 'react';
import { connect } from 'react-redux';
import NotFound from '../../containers/NotFound';
import { selectCanRenderPage } from '../../selectors/globalSelector';
import MainWrapper from '../../containers/MainWrapper';

function mapStateToProps(state) {
  return {
    ...selectCanRenderPage(state),
  };
}

function CanRenderPage(pageName, Component) {
  class Wrapper extends React.PureComponent {
    canRender(pageNameI) {
      return !!this.props[pageNameI];
    }

    render() {
      if (!this.canRender(pageName)) {
        return (
          <MainWrapper>
            <NotFound />
          </MainWrapper>
        );
      }
      return <Component {...this.props} />;
    }
  }
  return connect(mapStateToProps)(Wrapper);
}

export default CanRenderPage;
