import forOwn from 'lodash/forOwn';
import lodashResult from 'lodash/result';
import moment from 'moment-timezone';

export default class User {
  constructor(
    objectId,
    firstName,
    lastName,
    email,
    phone,
    phoneCountryCode,
    phoneRegionalNumber,
    isHost,
    isCleaner,
    pictureUrl,
    bio,
    phoneVerified,
    notificationSettings,
    role,
    connectedAccounts,
    hostFRESeen,
    webFRESeen,
    subscription,
    language,
    permissions,
    teams,
    rights,
    customBrandingId,
    disableOnBoardingVideo,
    isStandaloneUser,
    actAsRole,
    hardRefresh,
    companyName,
  ) {
    this.objectId = objectId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
    this.phoneCountryCode = phoneCountryCode;
    this.phoneRegionalNumber = phoneRegionalNumber;
    this.isHost = isHost;
    this.isCleaner = isCleaner;
    this.pictureUrl = pictureUrl;
    this.bio = bio;
    this.phoneVerified = phoneVerified;
    this.notificationSettings = notificationSettings;
    this.role = role;
    this.connectedAccounts = connectedAccounts;
    this.hostFRESeen = hostFRESeen;
    this.webFRESeen = webFRESeen;
    this.subscription = subscription;
    this.language = language;
    this.permissions = permissions;
    this.teams = teams;
    this.rights = rights;
    this.customBrandingId = customBrandingId;
    this.isStandaloneUser = isStandaloneUser;
    this.actAsRole = actAsRole;
    this.companyName = companyName;
    this.disableOnBoardingVideo = disableOnBoardingVideo;
    this.hardRefresh = hardRefresh;
  }

  get lastHardRefreshTimeStamp() {
    return this.hardRefresh && moment(this.hardRefresh).unix();
  }

  setSessionToken(sessionToken) {
    this.sessionToken = sessionToken;
  }

  isDefaultRole(roleString) {
    return roleString === this.defaultRole;
  }

  isPersonalRole(roleString) {
    return roleString === this.personalRole;
  }

  getTeamFromRole(role) {
    return lodashResult(this.teams || {}, [role], {});
  }

  getOwnProps(scope, onScope) {
    const obj = onScope || {};
    forOwn(scope, (value, key) => {
      obj[key] = value;
    });
    return obj;
  }

  get defaultRole() {
    return lodashResult(this, ['permissions', 'defaultRole']);
  }

  get personalRole() {
    return lodashResult(this, ['permissions', 'personalRole']);
  }

  get isStandalone() {
    return !!this.isStandaloneUser;
  }

  setData(obj) {
    this.getOwnProps(obj, this);
  }

  getData() {
    return this.getOwnProps(this);
  }

  getSessionToken() {
    return this.sessionToken;
  }

  getId() {
    return this.id;
  }

  getEmail() {
    return this.email;
  }

  getPhone() {
    return this.phone;
  }

  getPhoneRegionalNumber() {
    return this.phoneRegionalNumber;
  }

  getPhoneCountryCode() {
    return this.phoneCountryCode;
  }
}
