import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@properly/components';
import classNames from 'classnames/bind';
import styles from './checklistsStyles.module.css';

const cx = classNames.bind(styles);

const ChecklistMasterPin = ({ icon: PinIcon, position, small, positioned, dragging }) => (
  <div
    className={cx(
      'checklist__pin-actual',
      { 'checklist__pin-actual--positioned': positioned },
      { 'checklist__pin-actual--dragging': dragging },
      { 'checklist__pin-actual--small': small },
      `checklist__pin-actual--${position}`,
    )}
  >
    <Icon.IcPin2 height="4em" width="4em" byWidth />

    {PinIcon && (
      <div
        data-pin="master"
        style={{
          left: '16px',
          top: '12px',
          position: 'absolute',
        }}
        className={cx(`checklist__pin-icon--${position}`)}
      >
        <PinIcon height="2em" width="2em" byWidth />
      </div>
    )}
  </div>
);

ChecklistMasterPin.propTypes = {
  icon: PropTypes.func,
  position: PropTypes.string,
  small: PropTypes.bool,
  positioned: PropTypes.bool,
  dragging: PropTypes.bool,
};

export default ChecklistMasterPin;
