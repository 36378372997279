import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const DesktopAppContainer = props => (
  <div
    className={cx('main-main', {
      'main-main--absolute': props.isAbsolute,
    })}
  >
    <div className={cx('main-navigation')}>
      <div className={cx('main-navigation-bg')}>
        <div
          className={cx('main-navigation-inner', {
            'main-navigation-inner--nomaxwidth': props.noMaxWidthNav,
          })}
        >
          {props.navItems && props.navItems}
        </div>
      </div>
    </div>
    <div
      className={cx('main-page', {
        'main-page--grey': props.bgColor === 'grey',
        'main-page--nomax': !!props.noMaxWidth,
        'main-page--canoverlaynav': props.canOverLayNav,
      })}
    >
      {props.children}
    </div>
  </div>
);

DesktopAppContainer.propTypes = {
  isAbsolute: PropTypes.bool,
  noMaxWidthNav: PropTypes.bool,
  noMaxWidth: PropTypes.bool,
  canOverLayNav: PropTypes.bool,
  navItems: PropTypes.node,
  bgColor: PropTypes.oneOf(['grey']),
};

DesktopAppContainer.defaultProps = {
  noMaxWidthNav: true,
  noMaxWidth: true,
};

export default DesktopAppContainer;
