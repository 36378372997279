import { createSelector } from 'reselect';
import { generateDays } from '@properly/common';
import log from 'loglevel';
import moment from 'moment-timezone';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import { filterPropertySidebar, sortProperties } from '../../../../helper/herbert';

export const selectCurrentUserId = selectorsGlobal.selectCurrentUserId;
export const selectContactsSearch = selectorsGlobal.selectContactsSearch;
export const selectPropertiesSearch = selectorsGlobal.selectPropertiesSearch;
export const selectProperties = () =>
  createSelector(selectorsGlobal.selectProperties(), properties => {
    const propertiesJS = properties.toJS();
    const propertiesArray = Object.keys(propertiesJS).map(key => propertiesJS[key]);
    return sortProperties(filterPropertySidebar(propertiesArray));
  });
export const selectContacts = () =>
  createSelector(selectorsGlobal.selectContacts(), contacts => {
    const contactsJS = contacts.toJS();
    const contactsArray = Object.keys(contactsJS).map(key => contactsJS[key]);
    return contactsArray;
  });

export const selectCalendar = state => state.calendar;
export const selectEventsLoading = createSelector(selectCalendar, state => state.get('eventsLoading'));
export const selectFiltersLoading = createSelector(selectCalendar, state => state.get('filtersLoading'));
export const selectShowCanceledEvents = createSelector(selectCalendar, state => state.get('showCanceledEvents'));
export const selectFilters = createSelector(selectCalendar, state => state.get('filters'));
export const selectDatesDays = createSelector(selectCalendar, state => state.getIn(['dates', 'dates']));
export const selectDates = createSelector(selectCalendar, state => state.get('dates'));
export const selectStartDate = createSelector(selectCalendar, state => state.getIn(['dates', 'startDate']));
export const selectEndDate = createSelector(selectCalendar, state => state.getIn(['dates', 'endDate']));
export const selectDateBucketIds = createSelector(selectCalendar, state => state.getIn(['dates', 'dateBucketIds']));
export const selectPropertyViewportStart = createSelector(selectCalendar, state => state.get('propertyViewportStart'));
export const selectPropertyViewportEnd = createSelector(selectCalendar, state => state.get('propertyViewportEnd'));
export const selectProperty = createSelector(selectCalendar, state => state.getIn(['property']));
export const selectPropertyById = id => createSelector(selectProperty, state => state.get(id));
export const selectPropertyEvents = createSelector(selectCalendar, state => state.get('propertyEvents'));
export const selectPropertyEventsById = id => createSelector(selectPropertyEvents, state => state.get(id));
export const selectPropertyTotal = createSelector(selectCalendar, state => state.get('propertyTotal'));
export const selectPropertyFilteredTotal = createSelector(selectCalendar, state => state.get('propertyTotalFiltered'));
export const selectPropertyLoaded = createSelector(selectCalendar, state => state.get('propertyLoaded'));
export const selectPropertyFilteredLoaded = createSelector(selectCalendar, state =>
  state.get('propertyLoadedFiltered'),
);
export const selectPropertyOrder = createSelector(selectCalendar, state => state.get('propertyOrder'));
export const selectPropertyOrderFiltered = createSelector(selectCalendar, state => state.get('propertyOrderFiltered'));
export const selectCalendarError = createSelector(selectCalendar, state => state.get('error'));
export const selectCalendarErrorCount = createSelector(selectCalendar, state => state.get('errorCount'));
export const selectCalendarOutsideDateRange = createSelector(selectCalendar, state =>
  state.getIn(['dates', 'outsideRange']),
);
export const selectCalendarOutsideDateRangeDayCount = createSelector(selectCalendar, state =>
  state.getIn(['dates', 'outsideRangeDayCount']),
);

export const selectSearchQuery = createSelector(selectCalendar, state => state.get('searchQuery'));
export const selectSearchQueryLoaded = createSelector(selectCalendar, state => state.get('searchQueryLoaded'));
export const selectHasSearchQuery = createSelector(selectCalendar, state => !!state.get('searchQuery'));
export const selectHasEventFilter = createSelector(selectFilters, filters => filters && filters.size > 0);

export const selectFilterDates = createSelector(
  selectHasEventFilter,
  selectFilters,
  (hasFilters, filters) => hasFilters && getFilterDates(filters),
);

export const selectFilterDays = createSelector(selectFilterDates, dates => {
  if (dates && dates.startDate && dates.endDate) {
    log.info('selectFilterDays', dates, String(dates.startDate), String(dates.endDate));
    return generateDays(dates.startDate, dates.endDate);
  }
  return [];
});

export const selectHasFilter = createSelector(
  selectHasSearchQuery,
  selectHasEventFilter,
  (hasSearchQuery, hasFilters) => hasSearchQuery || hasFilters,
);

export const selectCurrentPropertyOrder = createSelector(
  selectHasFilter,
  selectPropertyOrder,
  selectPropertyOrderFiltered,
  (hasFilters, propertyOrder, propertyOrderFiltered) => (hasFilters ? propertyOrderFiltered : propertyOrder),
);

export const selectPropertyIdByIndex = index =>
  createSelector(selectCurrentPropertyOrder, propertyOrder => propertyOrder.get(index));

export const selectPropertyByIndex = index =>
  createSelector(selectPropertyIdByIndex(index), selectProperty, (propertyId, property) => property.get(propertyId));

export const selectPropertyEventsByIndex = index =>
  createSelector(selectCurrentPropertyOrder, selectPropertyEvents, (propertyOrder, propertyEvents) =>
    propertyEvents.get(propertyOrder.get(index)),
  );

export const selectCurrentPropertyTotal = createSelector(
  selectHasFilter,
  selectPropertyTotal,
  selectPropertyFilteredTotal,
  (hasFilters, total, filteredTotal) => (hasFilters ? filteredTotal : total),
);

export const selectSearchQueryLoading = createSelector(
  selectHasSearchQuery,
  selectSearchQueryLoaded,
  (hasSearchQuery, searchQueryLoaded) => (hasSearchQuery ? !searchQueryLoaded : false),
);

export const selectPropertiesLoading = createSelector(
  selectHasFilter,
  selectFiltersLoading,
  selectEventsLoading,
  selectSearchQueryLoading,
  (hasFilters, filtersLoading, eventsLoading, searchLoading) =>
    (hasFilters && filtersLoading) || eventsLoading.reduce((acc, current) => current || acc, false) || searchLoading,
);

export const selectPropertiesLoaded = createSelector(
  selectHasFilter,
  selectPropertyLoaded,
  selectPropertyFilteredLoaded,
  (hasFilter, propertyLoaded, propertyLoadedFiltered) => (hasFilter ? propertyLoadedFiltered : propertyLoaded),
);

export const selectModalData = () => createSelector(selectCalendar, state => state.get('modalData'));

const dateIds = ['jobDate', 'bookingDate', 'todoDate'];

function getFilterDates(filters) {
  const dateFilters = filters
    .toJS()
    .filter(currentFilter => dateIds.indexOf(currentFilter.id) !== -1)
    .map(currentFilter => getFilterDate(currentFilter))
    .filter(currentFilter => !!currentFilter);

  const maxDates = dateFilters.reduce(
    (acc, current) => ({
      startDate: acc.startDate && moment(acc.startDate).isBefore(current.startDate) ? acc.startDate : current.startDate,
      endDate: acc.endDate && moment(acc.endDate).isAfter(current.endDate) ? acc.endDate : current.endDate,
    }),
    {},
  );

  return dateFilters.length > 0 ? maxDates : null;
}

function getFilterDate({ id, operator, value } = {}) {
  switch (operator) {
    case 'on': {
      const [start] = value || [];
      return {
        startDate: moment(start).startOf('d'),
        endDate: moment(start).endOf('d'),
      };
    }
    case 'between': {
      const [start, end] = value || [];
      return {
        startDate: moment(start).startOf('d'),
        endDate: moment(end).endOf('d'),
      };
    }
    case 'today':
      return {
        startDate: moment().startOf('d'),
        endDate: moment().endOf('d'),
      };
    case 'tomorrow':
      return {
        startDate: moment()
          .add(1, 'd')
          .startOf('d'),
        endDate: moment()
          .add(1, 'd')
          .endOf('d'),
      };
    case 'next7Days':
      return {
        startDate: moment().startOf('d'),
        endDate: moment()
          .add(1, 'w')
          .endOf('d'),
      };
    case 'next30Days':
      return {
        startDate: moment().startOf('d'),
        endDate: moment()
          .add(30, 'd')
          .endOf('d'),
      };
    case 'last7Days':
      return {
        startDate: moment()
          .subtract(1, 'w')
          .startOf('d'),
        endDate: moment().endOf('d'),
      };
    case 'last30Days':
      return {
        startDate: moment()
          .subtract(30, 'd')
          .startOf('d'),
        endDate: moment().endOf('d'),
      };
    default:
      log.error('Error getting filter dates', { id, operator, value });
      return undefined;
  }
}
