"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _parseCalendarEvent = _interopRequireDefault(require("../models/parseCalendarEvent"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = obj => {
  const {
    id,
    objectId,
    type,
    jobRequestId,
    bookingId,
    triggerId,
    propertyId,
    timeZone,
    propertyTitle,
    startTime,
    endTime,
    status,
    eventStatus,
    needsCTA,
    deleted,
    payload,
    days,
    propertyAddress,
    createdAt
  } = obj;
  return new _parseCalendarEvent.default(id || objectId, type, jobRequestId, bookingId, triggerId, propertyId, timeZone, propertyTitle, startTime, endTime, status, eventStatus, needsCTA, deleted, payload, days, propertyAddress, createdAt);
};

exports.default = _default;