import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BrandingAds } from '@properly/components';
import { MasterDetail, MasterDetailMaster, MasterDetailDetail } from '../../../../components/MasterDetail/index';
import ContactSidebarContainer from './ContactSidebarContainer';
import ContactModalContainer from './ContactModalContainer';
import NoProperties from '../../../../components/NoProperties/index';
import { ROUTES } from '../../../../paths';
import * as globalActions from '../../../../actions/globalActions';
import MainWrapper from '../../../../containers/MainWrapper/index';

import { inviteCleaner, hideError } from '../state/ContactActions';
import HasPermission from '../../../../hoc/HasPermission/index';
import {
  selectPartnerWebsiteUrl,
  selectIsPartnerDomain,
  selectAdStyles,
  selectContactPageAdFromAds,
} from '../../../branding/BrandingSelector';

class ContactsContainer extends PureComponent {
  componentDidMount() {
    this.props.setSearchQuery('contacts', '');
    this.props.startPreloadSagaWithDelay('contacts');
  }

  get isIndexPage() {
    return this.props.location.pathname === ROUTES.contacts;
  }

  renderContent() {
    if (this.isIndexPage) {
      return (
        <HasPermission
          hasAccessFormatter={hasAccessRes => hasAccessRes.canSendJobsGlobal}
          render={hasAccess => (
            <NoProperties type="invitecleaner" meta={{ hasBtn: hasAccess }} onClick={this.props.inviteCleaner} />
          )}
        />
      );
    }
    return this.props.children;
  }

  render() {
    const { detailRouteName, contactPageAd, adStyles, isPartnerDomain, partnerWebsiteUrl } = this.props;
    return (
      <div>
        <MainWrapper currentPage="contacts">
          <MasterDetail>
            <MasterDetailMaster>
              <ContactSidebarContainer activeSection={detailRouteName} activeId={this.props.params.id} />
            </MasterDetailMaster>
            <MasterDetailDetail grey>{this.renderContent()}</MasterDetailDetail>
          </MasterDetail>
          <ContactModalContainer activeContactId={this.props.params.id} />
          {!this.props.params.id && isPartnerDomain && (
            <BrandingAds
              brandingAd={contactPageAd}
              partnerWebsiteUrl={partnerWebsiteUrl}
              adStyles={adStyles}
              typeTwoAd
              itemNotSelected
            />
          )}
        </MainWrapper>
      </div>
    );
  }
}

ContactsContainer.propTypes = {
  children: PropTypes.node,
  contactPageAd: PropTypes.shape({}),
  adStyles: PropTypes.shape({}),
  isPartnerDomain: PropTypes.bool,
  partnerWebsiteUrl: PropTypes.string,
};

ContactsContainer.defaultProps = {
  contactPageAd: {},
  adStyles: {},
  isPartnerDomain: false,
  partnerWebsiteUrl: '',
};

function mapStateToProps() {
  return (state, props) => ({
    detailRouteName: props.params.contactDetailView,
    contactPageAd: selectContactPageAdFromAds()(state) && selectContactPageAdFromAds()(state).toJS(),
    adStyles: selectAdStyles()(state)?.toJS(),
    isPartnerDomain: selectIsPartnerDomain()(state),
    partnerWebsiteUrl: selectPartnerWebsiteUrl()(state),
  });
}

export default connect(mapStateToProps, {
  inviteCleaner,
  hideError,
  startPreloadSagaWithDelay: globalActions.startPreloadSagaWithDelay,
  setSearchQuery: globalActions.setSearchQuery,
})(ContactsContainer);
