import React from 'react';
import Grid from '@material-ui/core/Grid';

export const LibrarySearch = ({ children }) => (
  <Grid
    container
    alignItems="center"
    justify="space-between"
    style={{ paddingBottom: '12px', paddingRight: '4px', paddingLeft: '28px' }}
  >
    {children}
  </Grid>
);
