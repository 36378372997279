import { getConfigSync } from '@properly/config';
import Parse from 'parse';
import log from 'loglevel';

const config = getConfigSync();

const generateAPIExternalToken = () =>
  `${Math.random()
    .toString(36)
    .substring(2, 15)}-${Math.random()
    .toString(36)
    .substring(2, 15)}-${Math.random()
    .toString(36)
    .substring(2, 15)}-${new Date().toISOString()}`;

const setExternalSessionApiTokenLocalStorage = token => {
  try {
    localStorage.setItem('externalSessionApiToken', token);
  } catch (err) {
    log.error(err);
  }
};

export const resetExternalSessionApiTokenLocalStorage = () => {
  try {
    localStorage.removeItem('externalSessionApiToken');
    const generatedAPIToken = generateAPIExternalToken();
    setExternalSessionApiTokenLocalStorage(generatedAPIToken);
  } catch (err) {
    log.error(err);
  }
};

export const getExternalSessionApiTokenLocalStorage = () => {
  try {
    return localStorage.getItem('externalSessionApiToken');
  } catch (err) {
    log.error(err);
    return false;
  }
};

const getExternalSessionApiToken = () => {
  const sessionTokenRetrieved = getExternalSessionApiTokenLocalStorage();
  if (sessionTokenRetrieved) return sessionTokenRetrieved;
  const generatedAPIToken = generateAPIExternalToken();
  setExternalSessionApiTokenLocalStorage(generatedAPIToken);
  return generatedAPIToken;
};

export const bookingcomOAuthLink = () =>
  `${
    config.API_BASE_URL
  }/public/connection-redirect/bookingcom?externalSessionToken=${getExternalSessionApiToken()}&action=brandingConnect&connectionType=oauth`;

const getToken = () => (Parse.User.current() ? Parse.User.current().getSessionToken() : null);

export const stripeOAuthLinkGen = () =>
  `${config.API_BASE_URL}/public/stripe/connect-oauth-redirect?sessionToken=${getToken()}`;
