"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.theme = void 0;

var _styles = require("@material-ui/core/styles");

require("../../../common/styles/typography.module.css");

var fontFamily = "".concat(['"Graphik Web"', 'Helvetica', 'Arial', 'sans-serif'].join(','), " !important");
var defaultTheme = (0, _styles.createMuiTheme)();
var theme = (0, _styles.createMuiTheme)({
  typography: {
    fontFamily: fontFamily
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    },
    MuiInput: {
      disableUnderline: true
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontFamily: fontFamily
      }
    },
    MuiInput: {
      root: {
        fontFamily: fontFamily,
        padding: 0,
        'label + &': {
          marginTop: defaultTheme.spacing(3)
        }
      },
      input: {
        borderRadius: 0,
        backgroundColor: defaultTheme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 12px',
        width: 'calc(100% - 24px)',
        transition: defaultTheme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: fontFamily,
        '&:focus': {
          border: '1px solid #ced4da',
          borderColor: '#ced4da',
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
        }
      }
    },
    MuiListItem: {
      root: {
        fontFamily: fontFamily,
        paddingTop: '11px',
        paddingBottom: '11px'
      }
    },
    MuiListItemText: {
      multiline: {
        marginTop: 0,
        marginBottom: 0
      },
      root: {
        margin: 0
      }
    },
    MuiMenuItem: {
      root: {
        fontFamily: fontFamily
      }
    },
    MuiTypography: {
      root: {
        fontFamily: fontFamily
      },
      body1: {
        fontSize: '.875rem'
      }
    },
    MuiCard: {
      root: {
        borderRadius: 0
      }
    },
    MuiChip: {
      root: {
        fontFamily: fontFamily,
        borderRadius: '4px'
      }
    },
    MuiButtonBase: {
      root: {
        fontFamily: fontFamily
      }
    }
  }
});
exports.theme = theme;