import { buildIgnoreDeletedQuery, buildGreaterThanStartTimeQuery, fetchParseClass } from './data.http';
import { requestHandler } from './data.requestHandler';
import { _mapParseCalendarEventToCalendarEvent } from './data.map';

export function getEventsTodos(startTime, skip, limit) {
  return requestHandler(() => {
    const query = {
      ...buildIgnoreDeletedQuery(),
      ...buildGreaterThanStartTimeQuery(startTime),
      needsCTA: true,
    };

    return fetchParseClass('Event', limit || 25, query, `skip=${skip || 0}&order=startTime`).then(results =>
      (results || []).map(event => _mapParseCalendarEventToCalendarEvent(event)),
    );
  });
}
