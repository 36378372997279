import store from 'store';

export function resetNotificationStorage() {
  store.remove('properlynotstorage');
}

export function getNotificationStorage() {
  return store.get('properlynotstorage');
}

export function setNotificationStorage(data) {
  let prevData = getNotificationStorage();
  if (prevData) {
    prevData = { ...prevData, ...data }; // merge
  } else {
    prevData = { ...data };
  }
  store.set('properlynotstorage', prevData);
}
