import { fromJS } from 'immutable';
import * as types from '../../../types';

const initState = fromJS({
  events: {},
  pagination: {
    limit: 25,
    skip: 0,
  },
});

export default function TodosReducer(state = initState, action = {}) {
  switch (action.type) {
    case types.GLOBAL_LOGOUT: // on logout reset
      return initState;
    case types.TODOS_RESET:
      return initState;
    case types.TODOS_SET_DATA:
      return state.merge(action.data);
    case types.TODOS_MERGE_EVENTS:
      return state.mergeIn(['events'], fromJS(action.events));
    default:
      return state;
  }
}
