import React from 'react';
import { Box, Input, Icon, RoundSelect } from '@properly/components';
import noop from 'lodash/noop';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const ReportsInput = ({ title, ...rest }) => (
  <div>
    <div className={cx('reportin__title')}>{title}</div>
    <Input
      data-key="report-title"
      isFirst
      isLast
      styleOverwrite={{ backgroundColor: '#FFF', height: 36 }}
      style={{ padding: '6px 16px' }}
      type="text"
      {...rest}
    />
  </div>
);

ReportsInput.Dropdown = ({
  title,
  input,
  onClick,
  isOpen,
  placeholder,
  greyAll,
  greyPart,
  pretxt,
  error,
  disabled,
  ...more
}) => {
  const txt = !input && placeholder ? placeholder : input;
  const showPretxt = txt !== placeholder && pretxt;
  return (
    <div>
      <div className={cx('reportin__title')}>{title}</div>
      <Box
        onClick={disabled ? noop : onClick}
        isFirst
        noPadding
        hover={!disabled}
        error={error}
        focus={isOpen}
        styleOverwrite={{ height: 36 }}
        isLast
        data-key={more['data-key']}
      >
        <div
          className={cx('reportin__dropdown', {
            'reportin__dropdown--greyall': greyAll,
            'reportin__dropdown--greypart': greyPart,
          })}
        >
          {showPretxt ? <span>{pretxt}: </span> : null}
          <span className={cx({ reportin__maintxt: showPretxt })}>{txt}</span>
        </div>
        <div className={cx('reportin__dropdown-icon')}>{isOpen ? <Icon.IcExpandMore4 /> : <Icon.IcExpandMore3 />}</div>
      </Box>
    </div>
  );
};

ReportsInput.Select = ({ title, input, onClick, selected }) => (
  <div>
    <div className={cx('reportin__title')}>{title}</div>
    <Box isFirst noPadding hover styleOverwrite={{ height: 36 }} isLast>
      <div className={cx('reportin__dropdown')}>{input}</div>
      <div className={cx('reportin__select')}>
        <RoundSelect.Base onChange={onClick} small selected={selected} />
      </div>
    </Box>
  </div>
);

export default ReportsInput;
