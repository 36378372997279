import React, { Component } from 'react';
import {
  DividerLine,
  Modal,
  ModalError,
  Input,
  Button,
  Title3,
  SpaceBase,
  SpaceTiny,
  SpaceSmall,
} from '@properly/components';
import keyBy from 'lodash/keyBy';
import lodashResult from 'lodash/result';
import findIndex from 'lodash/findIndex';
import t from '@properly/localization';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { PagePadding } from '../../../../components/PageElements';
import styles from '../components/accountStyles.module.css';
import * as selectorsAccount from '../state/AccountSelectors';
import {
  disconnectPartnerSaga,
  reImportProperties,
  oAuthSwitch,
  setState,
  redirectToProviderSaga,
} from '../state/AccountActions';
import { selectConfig } from '../../../../selectors/globalSelector';
import { ROUTES } from '../../../../paths';
import { AccountHeadSection } from '../../../../components/AccountElements';

const cx = classNames.bind(styles);

class AccountContainerEdit extends Component {
  get isDefined() {
    return this.props.currentUser.user;
  }

  get indexOfId() {
    if (!this.isDefined) return -1;
    return findIndex(this.props.currentUser.user.connectedAccounts, { partnerUserId: this.props.params.id });
  }

  get canRender() {
    return this.isDefined && this.indexOfId !== -1;
  }

  get generalStatesJS() {
    return this.props.generalStates.toJS();
  }

  updateField(field, val) {
    const arrayClone = this.props.currentUser.user.connectedAccounts.slice();
    arrayClone[this.indexOfId][field] = val;
    this.props.updateTmpUser({
      connectedAccounts: arrayClone,
    });
  }

  genRedirectUrl(baseUrl) {
    // current location without url parameters
    const currentLocation = `${window.location.href.slice(0, window.location.href.indexOf('#'))}#${
      ROUTES.settingsConnections
    }`;
    return `${baseUrl}?redirect=${encodeURIComponent(currentLocation)}`;
  }

  editConnectFunc = () => {
    const partnerObj = keyBy(this.props.config.partner, 'id')[this.accountData.partner];
    this.props.redirectToProviderSaga({
      oAuthLink: this.genRedirectUrl(partnerObj.link),
      partnerTitle: partnerObj.title,
      connectedAccount: this.accountData,
    });
  };

  get accountData() {
    return this.props.currentUser.user.connectedAccounts[this.indexOfId];
  }

  render() {
    if (!this.canRender) return null;
    const { accountData } = this;
    const isConnectionIssue = lodashResult(this.props, ['location', 'query', 'connectionIssue']) === '1';
    return (
      <PagePadding type="absolute-scroll">
        <AccountHeadSection>
          <Title3 type="nomargin">{t('account.edit_account')}</Title3>
          <DividerLine type={['bottom']} />
        </AccountHeadSection>
        <SpaceBase />
        <div className={cx('account__edit')}>
          <Input
            isFirst
            isLast
            styleOverwrite={{ backgroundColor: '#FFF' }}
            value={accountData.name}
            disabled
            type="text"
          />
          <SpaceTiny />
          <Input
            isFirst
            isLast
            styleOverwrite={{ backgroundColor: '#FFF' }}
            value={accountData.contact}
            disabled
            type="text"
          />
          <SpaceBase />
          {!isConnectionIssue && (
            <Button
              types={['type-reimport', 'size-large', 'width-flex']}
              onClick={() => this.props.reImportProperties(accountData.partner, accountData.partnerUserId, oAuthSwitch)}
            >
              {t('account.re_import')}
            </Button>
          )}
          {isConnectionIssue && (
            <Button types={['type-reimport', 'size-large', 'width-flex']} onClick={this.editConnectFunc}>
              {t('account.re_connect')}
            </Button>
          )}
          <SpaceSmall />
          <Button
            types={['type-delete', 'size-large', 'width-flex']}
            onClick={() => this.props.disconnectPartnerSaga(accountData.partner, accountData.partnerUserId)}
          >
            {t('account.delete_account')}
          </Button>
        </div>
        <Modal type="loading" show={this.generalStatesJS.disconnect === 1} />
        <ModalError
          message1={t('account.disconnect_fail_head')}
          message2={t('account.disconnect_fail')}
          show={this.generalStatesJS.disconnect === 2}
          onClose={() => this.props.setState('disconnect', 0)}
        />
      </PagePadding>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    currentUser: state.currentUser,
    tmpUser: selectorsAccount.selectTmpUser()(state, props),
    generalStates: selectorsAccount.selectStates()(state, props),
    config: selectConfig(state, props),
  };
}

export default connect(
  mapStateToProps,
  {
    disconnectPartnerSaga,
    reImportProperties,
    setState,
    redirectToProviderSaga,
  },
)(AccountContainerEdit);
