import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import t from '@properly/localization';
import { isPropertyOwner } from '@properly/common';

import map from 'lodash/map';
import filter from 'lodash/filter';
import chunk from 'lodash/chunk';
import extend from 'lodash/extend';
import indexOf from 'lodash/indexOf';
import { SearchField, DividerLine, SpaceTiny, SpacedHeadline } from '@properly/components';
import { fromJS } from 'immutable';
import { List, AutoSizer } from 'react-virtualized';
import classNames from 'classnames/bind';
import styles from '../../checklists/components/checklistsStyles.module.css';
import * as selectors from '../state/PropertySelectors';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import ChecklistOverlay from '../../../../components/ChecklistOverlay/index';
import ChecklistHeader from '../../../../components/ChecklistHeader/index';
import PropertyChecklistClone from '../../../../components/PropertyChecklistClone/index';
import { filterPropertySidebar, rowRenderMapper } from '../../../../helper/herbert';
import { setCloneProperty, mergeSaga, setClonePropertySelected } from '../state/PropertyActions';
import { setSearchQuery } from '../../../../actions/globalActions';
import InfoMessage from '../../../../components/InfoMessage/index';

const cx = classNames.bind(styles);

const flexStyle = { height: '100%', display: 'flex', flexDirection: 'column' };

class PropertyCloneContainer extends PureComponent {
  setMsg(val) {
    this.props.setCloneProperty({ hideMsg: val });
  }

  onClose = () => {
    this.props.setCloneProperty({
      hideMsg: false,
      isOpen: false,
      mode: 0,
    });
    this.props.setClonePropertySelected(fromJS([]));
    this.props.setSearchQuery('merge_property_search', '');
  };

  onDone = () => {
    const keys = this.clonePropertyStateJS.selected;
    if (keys.length < 2) {
      this.onClose();
    } else {
      this.props.mergeSaga(keys);
    }
  };

  addSingleProperty = id => () => {
    const current = this.props.clonePropertyState.get('selected');
    const added = current.set(current.size, id);
    this.props.setClonePropertySelected(added);
  };

  toggleSelectedProperty = index => () => {
    const current = this.props.clonePropertyState.get('selected');
    const removed = current.delete(index);
    this.props.setClonePropertySelected(removed);
  };

  get clonePropertyStateJS() {
    return this.props.clonePropertyState.toJS();
  }

  get propertiesJS() {
    return this.props.properties.toJS();
  }

  get filteredProperties() {
    return filter(filterPropertySidebar(this.props.searchResult), property =>
      isPropertyOwner(property.ownerRole, this.props.currentUser),
    );
  }

  renderSelectedProperties(properties, selected) {
    const selectedProperties = selected;
    if (selectedProperties.length === 0) {
      return (
        <div className={cx('checklist__clone-noresult')}>{t('account.merge_property.no_properties_selected')}</div>
      );
    }
    return (
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            rowHeight={78}
            rowCount={selectedProperties.length}
            rowRenderer={rowRenderMapper({
              data: selectedProperties,
              handler: this.renderSelectedRow,
              ctx: { toggleSelectedProperty: this.toggleSelectedProperty, properties },
            })}
            width={width}
          />
        )}
      </AutoSizer>
    );
  }

  renderSelectedRow({ row, index, key, style, ctx: { toggleSelectedProperty, properties } }) {
    return (
      <ChecklistOverlay.GridRow key={key} spacing="0" style={extend({}, style, { marginBottom: 8 })}>
        <ChecklistOverlay.GridItem spacing="4" itemPerRow="1">
          <PropertyChecklistClone onClick={toggleSelectedProperty(index)} property={properties[row]} selected />
        </ChecklistOverlay.GridItem>
      </ChecklistOverlay.GridRow>
    );
  }

  renderProperties = (properties, selected) => {
    const filterdProperties = filter(properties, prop => indexOf(selected, prop.objectId) === -1);
    if (filterdProperties.length === 0) {
      return <div className={cx('checklist__clone-noresult')}>{t('account.merge_property.no_properties_found')}</div>;
    }
    const data = chunk(filterdProperties, 2);
    return (
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            rowHeight={78}
            rowCount={data.length}
            rowRenderer={rowRenderMapper({
              data,
              handler: this.renderRow,
              ctx: { addSingleProperty: this.addSingleProperty },
            })}
            width={width}
          />
        )}
      </AutoSizer>
    );
  };

  renderRow({ row, key, style, ctx: { addSingleProperty } }) {
    return (
      <ChecklistOverlay.GridRow key={key} spacing="0" style={extend({}, style, { marginBottom: 8 })}>
        {map(row, property => (
          <ChecklistOverlay.GridItem key={property.objectId} spacing="4" itemPerRow="1">
            <PropertyChecklistClone onClick={addSingleProperty(property.objectId)} property={property} />
          </ChecklistOverlay.GridItem>
        ))}
      </ChecklistOverlay.GridRow>
    );
  }

  render() {
    if (!this.clonePropertyStateJS.isOpen) return null;
    return (
      <ChecklistOverlay show>
        <ChecklistOverlay.Header>
          <ChecklistHeader
            type="merge"
            isLoading={this.clonePropertyStateJS.mode === 1}
            onClose={() => this.onClose()}
            onDone={() => this.onDone()}
            text={t('account.merge_property.title')}
          />
          <DividerLine type={['bottom']} />
        </ChecklistOverlay.Header>
        <ChecklistOverlay.ContentWrap style={flexStyle}>
          <ChecklistOverlay.Content type="grey">
            <ChecklistOverlay.ContentInner style={flexStyle}>
              {!this.clonePropertyStateJS.hideMsg && (
                <InfoMessage
                  text={t('account.merge_property.info')}
                  icon="light"
                  color="red"
                  onClose={() => this.setMsg(true)}
                />
              )}
              <ChecklistOverlay.GridRow spacing="16" style={{ height: '100%' }}>
                <ChecklistOverlay.GridItem spacing="16" itemPerRow="3">
                  <SpacedHeadline>{t('account.merge_property.selected_properties')}</SpacedHeadline>
                  <div style={{ height: 'calc(100% - 40px)' }}>
                    {this.renderSelectedProperties(this.propertiesJS, this.clonePropertyStateJS.selected)}
                  </div>
                </ChecklistOverlay.GridItem>
                <ChecklistOverlay.GridItem
                  spacing="16"
                  itemPerRow="3"
                  style={{ minWidth: `${(100 / 3) * 2}px`, maxWidth: 'none' }}
                >
                  <SpacedHeadline>{t('account.merge_property.properties')}</SpacedHeadline>
                  <SearchField
                    placeholder={t('account.merge_property.search_for_address')}
                    value={this.props.searchQuery}
                    onGrey
                    iconLocation="left"
                    onChange={value => this.props.setSearchQuery('merge_property_search', value)}
                  />
                  <SpaceTiny />
                  <div style={{ height: 'calc(100% - 80px)' }}>
                    {this.renderProperties(this.filteredProperties, this.clonePropertyStateJS.selected)}
                  </div>
                </ChecklistOverlay.GridItem>
              </ChecklistOverlay.GridRow>
              {this.clonePropertyStateJS.mode === 1 && <div className={cx('checklist__clone-overlay')} />}
            </ChecklistOverlay.ContentInner>
          </ChecklistOverlay.Content>
        </ChecklistOverlay.ContentWrap>
      </ChecklistOverlay>
    );
  }
}

function mapStateToProps() {
  const memo = selectorsGlobal.selectSearchData(
    'properties',
    'merge_property_search',
    selectorsGlobal.selectProperties,
  );
  return (state, props) => {
    const { result, searchQuery } = memo(state);
    return {
      currentUser: selectorsGlobal.selectCurrentUser()(state, props),
      searchQuery,
      searchResult: result,
      clonePropertyState: selectors.selectCloneProperty()(state, props),
      properties: selectorsGlobal.selectProperties()(state, props),
    };
  };
}

export default connect(
  mapStateToProps,
  {
    setCloneProperty,
    setSearchQuery,
    mergeSaga,
    setClonePropertySelected,
  },
)(PropertyCloneContainer);
