import { delay } from 'redux-saga';
import log from 'loglevel';
import moment from 'moment-timezone';
import filter from 'lodash/filter';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import lodashResult from 'lodash/result';
import reduce from 'lodash/reduce';
import keyBy from 'lodash/keyBy';
import cloneDeep from 'lodash/cloneDeep';
import { put, fork, select, call, take, cancel as cancelImport, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  problemUpdateStatus,
  costCapture,
  featureFlag,
  jobRequestStatus,
  cleanerStatus,
  skillStatus,
} from '@properly/config';
import * as types from '../../../../types/index';
import { getVerificationPhotoFeedback, uploadImage } from '../../data';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import { selectIsFeatureFlagEnabledForUser } from '../../settings/state/SettingsSelectors';

import {
  setJobRequestDetails,
  closeJobRequestDetails,
  setSourceJobProblems,
  openJobRequestDetails,
  setTempMilesImages,
  setTempDurationImages,
  setTempCostImages,
  setCostType,
  setChecklistsVerificationPhotos,
  setComments,
  setCommentsLoader,
  addComments,
  setStripePaymentDetails,
} from './JobDetailsActions';

import * as jobDetailsSelectors from './JobDetailsSelectors';
import {
  updateCollectionEntryBatch,
  updateInBackgroundSaga,
  setModal,
  updateCollectionEntry,
  filterCollectionEntryBatch,
  setGlobalLoadingState,
} from '../../../../actions/globalActions';
import { handleEventChannelEvent } from '../../../../sagas/global';
import { trackOpenJobRequest } from '../../../../actions/trackingEvents';
import { sendJobRequestModes, mediaUploadLoadingKey } from '../../../../dataConstants';

// Graphql API
import { cancelJobRequest, addCostApi, getJobProgressCountApi } from '../../../../graphql/api/jobRequest';
import { createVerificationPhotosSubscriptionChannel } from '../../../../graphql/api/verificationPhotos';
import { getSpecificJobrequest } from '../../../../graphql/api/jobList';
import { getGQLComments, addComment, createCommentsSubscriptionChannel } from '../../../../graphql/api/comments';
import { getCompletedSkills } from '../../../../graphql/api/skills';
import { selectJobRequestTasks, selectJobRequestData } from '../../jobRequest/state/JobRequestSelectors';
import { flushUpdate } from '../../jobRequest/state/JobRequestSagas';

const { MILEAGE, DURATION, COST } = costCapture;
let commentsSubscriptionChannel;
let verificationPhotosSubscriptionChannel;

function* getJobRequestFromStore() {
  // get jobRequestId
  const res = yield select(jobDetailsSelectors.selectJobDetailsJS());
  const { selectedJobRequestId } = res;
  // get job request
  const jobRequest = yield select(selectorsGlobal.selectJobRequestWithCleaners(selectedJobRequestId));
  if (!jobRequest) {
    log.error('getJobRequestFromStore - jobrequest not found', selectedJobRequestId);
    return false;
  }
  return jobRequest;
}

function filterOutExtraFeedback(feedbackArray) {
  const res = reduce(
    feedbackArray,
    (acc, item) => {
      if (item.isThumbs) {
        acc.result.push(item);
      }
      if (item.isComment && !acc.seen[item.picUid]) {
        acc.result.push(item);
        acc.seen[item.picUid] = true;
      }
      return acc;
    },
    {
      result: [],
      seen: {},
    },
  );
  return res.result;
}

function* getVerificationPhotoFeedbackFunc(backwardCompatibilityJobReqId) {
  const feedback = yield call(getVerificationPhotoFeedback, backwardCompatibilityJobReqId); // changed here for verification photo get it right
  yield put(filterCollectionEntryBatch('photofeedback', item => item.jobRequestId !== backwardCompatibilityJobReqId));

  // put new data
  yield put(updateCollectionEntryBatch('photofeedback', keyBy(filterOutExtraFeedback(feedback), 'uniId'), true));
}

function upsertChecklistsVerificationPhoto(checklists, payload) {
  const { checklistId, verificationPhoto, type } = payload;
  const { stepId } = verificationPhoto;

  // Replace the verificationPhoto if it exists, otherwise add it.
  return checklists.map(checklist => {
    if (checklistId !== checklist.id) {
      return checklist;
    }

    // We've found the checklist. Now evaluate whether new or retaken verification photo
    if (type === 'RETAKEN_VERIFICATION_PHOTO') {
      // Get the index of the verificationPhoto by stepId that will be replaced.
      const index = findIndex(checklist.verificationPhotos, { stepId });

      // replace the verificationPhoto
      checklist.verificationPhotos.splice(index, 1, verificationPhoto);
    } else {
      // add the new verificationPhoto
      checklist.verificationPhotos.push(verificationPhoto);
    }

    log.debug('[upsertChecklistsVerificationPhoto] upserting verification photo in checklist', checklist);
    return checklist;
  });
}

function* addSubscriptionVerificationPhoto(payload = {}) {
  try {
    // update the checklist verification photos in our job detail state
    const checklistsVerificationPhotos = yield select(jobDetailsSelectors.selectChecklistsVerificationPhotos());

    const checklistsVerificationPhotosJS = checklistsVerificationPhotos.toJS();

    // Check if verification photo from subscription already exists in job. If yes, we ignore that subscription.
    const photoExists = checklistsVerificationPhotosJS
      .filter(checklist => payload.checklistId === checklist.id)
      .some((checklist = {}) => {
        const pictureIdentifierIndex = findIndex(checklist.verificationPhotos, {
          pictureIdentifier: payload.verificationPhoto.pictureIdentifier,
        });
        return pictureIdentifierIndex > -1;
      });
    if (photoExists) return;

    const jobDetailChecklists = upsertChecklistsVerificationPhoto(checklistsVerificationPhotosJS, payload);
    yield put(setChecklistsVerificationPhotos(jobDetailChecklists));

    const jobRequest = yield getJobRequestFromStore();
    if (!jobRequest) return;

    // update the checklist verification photos in our job request state
    const jobRequestChecklists = upsertChecklistsVerificationPhoto(jobRequest.checklists || [], payload);
    const clonedJobRequest = cloneDeep(jobRequest);
    clonedJobRequest.checklists = jobRequestChecklists;
    yield putUpdatedJobRequestInStore(clonedJobRequest);

    if (payload.type === 'NEW_VERIFICATION_PHOTO') {
      const jobProgressCount = yield call(getJobProgressCountApi, jobRequest.jobId);
      yield put(
        setJobRequestDetails({
          jobProgress: { ...jobRequest.jobProgress, ...jobProgressCount },
        }),
      );
      clonedJobRequest.taskDoneCount = jobProgressCount.completedTaskCount;
      clonedJobRequest.verificationDoneCount = jobProgressCount.verificationPictureCount;
      clonedJobRequest.jobProgress = { ...jobRequest.jobProgress, ...jobProgressCount };
      yield putUpdatedJobRequestInStore(clonedJobRequest);
    }
  } catch (e) {
    log.error('[addSubscriptionVerificationPhoto] upserting verification photo failed', e);
  }
}

function* open(action) {
  yield put(
    setJobRequestDetails({
      state: 1,
      isOpen: true,
    }),
  );
  trackOpenJobRequest(action.jobRequestId);
  // load job request
  try {
    // TODO: call verification feedback parallelly after upgrading redux-saga and using all method

    if (action.backwardCompatibilityJobReqId) {
      yield fork(getVerificationPhotoFeedbackFunc, action.backwardCompatibilityJobReqId);
    }
    const jobRequestResponse = yield call(getSpecificJobrequest, action.jobRequestId);
    const {
      sourceProblems,
      skillRequirements,
      status,
      requestedCleaners,
      stripePaymentDetails,
      checklists,
    } = jobRequestResponse;

    yield put(setStripePaymentDetails(stripePaymentDetails));

    let updatedSkillRequirements = skillRequirements;

    // enhance skill requirements with the completed requirement

    if (
      skillRequirements?.length &&
      [jobRequestStatus.StateAccepted, jobRequestStatus.StateStarted, jobRequestStatus.StateFinished].includes(status)
    ) {
      const getAcceptedCleaner = requestedCleaners.find(cleaner =>
        [cleanerStatus.Accepted, cleanerStatus.InProgress, cleanerStatus.Finished].includes(cleaner.status),
      );
      // get completed skills for the cleaner
      let allCompletedSkills;
      if (getAcceptedCleaner) {
        allCompletedSkills = yield call(getCompletedSkills, getAcceptedCleaner.id, true);
      }

      if (allCompletedSkills && !allCompletedSkills.apiError) {
        updatedSkillRequirements = skillRequirements.map(skillObj => ({
          ...skillObj,
          progress: allCompletedSkills.includes(skillObj.skillId) ? skillStatus.PROGRESS_STATUS.COMPLETED : false,
        }));
      }
    }

    // note: didnot create a new object over here so as we do not again initate class
    jobRequestResponse.skillRequirements = updatedSkillRequirements;

    yield put(
      updateCollectionEntryBatch(
        'jobRequests',
        {
          [jobRequestResponse.jobId]: jobRequestResponse,
        },
        true,
      ),
    );

    // source problem call
    if (sourceProblems) {
      if (sourceProblems.sourceJobSnapshotId) {
        const { problems } = yield call(getSpecificJobrequest, sourceProblems.sourceJobSnapshotId);

        // Get only the problems from original job for which the job was scheduled
        const matchedProblems = problems.filter(problem =>
          sourceProblems.problemIds.find(problemId => problemId === problem.id),
        );
        yield put(setSourceJobProblems(matchedProblems));
      }
    }

    const { problems } = jobRequestResponse;
    let mappedProblems;

    if (jobRequestResponse.isProblemResolved) {
      mappedProblems = problems
        .filter(problem => problem.status === problemUpdateStatus.RESOLVED)
        .map(problem => ({
          id: problem.id,
          title: problem.title,
          note: problem.note,
          pictureIdentifiers: problem.pictureIdentifiers,
          isProblem: true,
        }));

      yield call(flushUpdate, {
        tasks: mappedProblems,
      });
    }

    yield put(
      setJobRequestDetails({
        selectedJobRequestId: action.jobRequestId,
        jobProgress: jobRequestResponse.jobProgress,
        state: 0,
      }),
    );

    const mappedChecklists = checklists.map(checklist => ({
      id: checklist.id,
      verificationPhotos: checklist.verificationPhotos,
    }));

    yield put(setChecklistsVerificationPhotos(mappedChecklists));

    const isLiveVerificationPhotosFeatureFlagEnabled = yield select(
      selectIsFeatureFlagEnabledForUser(featureFlag.FEATURE_FLAG_LIVE_VERIFICATION_PHOTOS),
    );

    if (isLiveVerificationPhotosFeatureFlagEnabled) {
      // set up our channel to listen for new verificationPhotos via our GraphQL subscription
      const params = { jobSnapshotId: action.jobRequestId };
      verificationPhotosSubscriptionChannel = yield call(createVerificationPhotosSubscriptionChannel, params);
      yield takeEvery(verificationPhotosSubscriptionChannel, addSubscriptionVerificationPhoto);
    }

    yield runJobRequestUpdateChannel(action.jobRequestId);
  } catch (e) {
    log.error('openJobRequest - fetch job request', action, e);
    yield put(
      setJobRequestDetails({
        state: 2,
      }),
    );
  }
}

function* addCostSaga(action) {
  const jobDetails = yield select(jobDetailsSelectors.selectJobDetails());
  const { selectedJobRequestId } = jobDetails?.toJS();
  const jobRequest = yield select(selectorsGlobal.selectJobRequest(selectedJobRequestId));
  const { propertyId } = jobRequest;
  const { type, value, note, pictureIdentifiers } = action.meta[0];

  yield put(setCostType(type));

  const cost = {
    type,
    value,
    note,
    pictureIdentifiers,
  };

  try {
    const res = yield call(addCostApi, cost, selectedJobRequestId, propertyId);
    log.debug('addCostApi -> res : ', res);

    yield put(closeJobRequestDetails());
    yield put(openJobRequestDetails(res.transactionId));
  } catch (e) {
    log.error(`addCostSaga -> ${e}`);
  }
}

function* addCostPictures(action) {
  const image = action.meta;
  try {
    const jobDetail = yield select(jobDetailsSelectors.selectJobDetailsJS());
    const { tempMilesImages, tempDurationImages, tempCostImages, type } = jobDetail;
    const res = yield call(uploadImage, image);
    log.info('uploadPic - res', res);

    if (type === MILEAGE) {
      tempMilesImages.push(res.pictureUrl);
      yield put(setTempMilesImages(tempMilesImages));
    }

    if (type === DURATION) {
      tempDurationImages.push(res.pictureUrl);
      yield put(setTempDurationImages(tempDurationImages));
    }

    if (type === COST) {
      tempCostImages.push(res.pictureUrl);
      yield put(setTempCostImages(tempCostImages));
    }
  } catch (e) {
    log.error('uploadPic - error', e);
    yield put(setGlobalLoadingState(mediaUploadLoadingKey, 3));
  }
}

function* costSaga(action) {
  if (!action.noDelay) {
    // close
    yield put(
      setJobRequestDetails({
        isOpen: false,
      }),
    );
    yield delay(600);
  }

  const jobRequest = yield getJobRequestFromStore();
  if (!jobRequest) return;

  // open small modal
  yield put(
    setJobRequestDetails({
      isOpenCostModal: true,
      event: lodashResult(action, ['meta', 'event']),
    }),
  );
}

function* costCloseSaga() {
  yield put(
    setJobRequestDetails({
      isOpenCostModal: false,
      isWarningModalOpen: false,
      event: undefined,
      tempMilesImages: [],
      tempDurationImages: [],
      tempCostImages: [],
      type: undefined,
    }),
  );

  yield delay(3000);

  const res = yield select(jobDetailsSelectors.selectJobDetailsJS());
  if (!res.isOpen) {
    yield put(
      setJobRequestDetails({
        selectedJobRequestId: undefined,
        jobProgress: undefined,
        state: 0,
      }),
    );
  }
}

function* editSaga(action) {
  if (!action.noDelay) {
    // close
    yield put(
      setJobRequestDetails({
        isOpen: false,
      }),
    );
    yield delay(600);
  }

  const jobRequest = yield getJobRequestFromStore();
  if (!jobRequest) return;

  // open small modal
  yield put(
    setJobRequestDetails({
      isOpenEditModal: true,
      event: lodashResult(action, ['meta', 'event']),
    }),
  );
}

function* editCloseSaga() {
  yield put(
    setJobRequestDetails({
      isOpenEditModal: false,
      isWarningModalOpen: false,
      event: undefined,
    }),
  );

  yield delay(3000);

  const res = yield select(jobDetailsSelectors.selectJobDetailsJS());
  if (!res.isOpen) {
    yield put(
      setJobRequestDetails({
        selectedJobRequestId: undefined,
        jobProgress: undefined,
        state: 0,
      }),
    );
  }
}

function* editContinueSaga() {
  const dataStore = yield select(jobDetailsSelectors.selectJobDetailsJS());
  // get jobRequestId
  const jobRequest = yield getJobRequestFromStore();
  if (!jobRequest) return;

  const selectedJobRequestId = jobRequest.jobId;

  // map cleaners in the right format
  const filteredCleaners = filter(jobRequest.requestedCleaners, cleaner => !!cleaner.contact);
  const mappedCleaners = map(filteredCleaners, cleaner => cleaner.contact.userData.objectId);
  const mappedSkills = map(jobRequest.skillRequirements, skill => ({
    skillId: skill.skillId,
    dependency: skillStatus.DEPENDENCY_STATUS.REQUIRED,
  }));

  // close small info modal
  yield put(
    setJobRequestDetails({
      isOpenEditModal: false,
      selectedJobRequestId: undefined,
      jobProgress: undefined,
      state: 0,
    }),
  );
  const jobRequestData = {
    mode: sendJobRequestModes.edit,
    prevJobRequestId: selectedJobRequestId,
    source: 'jobRequestDetails',
    data: {
      checklistId: jobRequest.checklists.map(checklist => checklist.id),
      propertyId: jobRequest.propertyId,
      message: jobRequest.note,
      cleaners: mappedCleaners,
      skills: mappedSkills,
      price: jobRequest.offeredPrice,
      currency: jobRequest.offeredPriceCurrency,
      startTime: moment(jobRequest.scheduledStartTime).tz(jobRequest.propertyData.timeZone),
      endTime: moment(jobRequest.scheduledEndTime).tz(jobRequest.propertyData.timeZone),
      jobStartTimeType: jobRequest.jobStartTimeType,
      title: jobRequest.title,
      duration:
        (jobRequest.jobStartTimeType === 'fixed' || jobRequest.jobStartTimeType === 'REGULAR') && // TODO: Define 'REGULAR' to be a constant. Import and change in all places where it is being used.
        jobRequest.durationInMin > 0
          ? `${jobRequest.durationInMin}`
          : jobRequest.duration,
    },
  };

  if (dataStore.event) {
    jobRequestData.eventId = dataStore.event.objectId || dataStore.event.id;
  }

  // open create jobrequest modal
  yield put(setModal(true, 'jobrequest', jobRequestData));
}

function* scheduleProblemsSaga() {
  const dataStore = yield select(jobDetailsSelectors.selectJobDetailsJS());
  // get jobRequestId
  const jobRequest = yield select(selectorsGlobal.selectJobRequest(dataStore.selectedJobRequestId));
  if (!jobRequest) return;

  // select tasks
  const tasks = yield select(selectJobRequestTasks());
  const tasksJS = tasks?.toJS();

  const selectedJobRequestId = jobRequest.jobId;

  const mappedSkills = map(jobRequest.skillRequirements, skill => ({
    skillId: skill.skillId,
    dependency: skillStatus.DEPENDENCY_STATUS.REQUIRED,
  }));

  const jobRequestData = {
    mode: sendJobRequestModes.problemSchedule,
    prevJobRequestId: selectedJobRequestId,
    source: 'jobRequestDetails',
    data: {
      checklistId: jobRequest.checklists.map(checklist => checklist.id),
      skills: mappedSkills,
      propertyId: jobRequest.propertyId,
      currency: jobRequest.offeredPriceCurrency,
      startTime: moment(jobRequest.scheduledStartTime).tz(jobRequest.propertyData.timeZone),
      endTime: moment(jobRequest.scheduledEndTime).tz(jobRequest.propertyData.timeZone),
      jobStartTimeType: jobRequest.jobStartTimeType,
      title: jobRequest.title,
      duration:
        (jobRequest.jobStartTimeType === 'fixed' || jobRequest.jobStartTimeType === 'REGULAR') &&
        jobRequest.durationInMin > 0
          ? `${jobRequest.durationInMin}`
          : jobRequest.duration,
      tasks: tasksJS,
    },
  };

  if (dataStore.event) {
    jobRequestData.eventId = dataStore.event.objectId || dataStore.event.id;
  }

  // open create jobrequest modal
  yield put(setModal(true, 'jobrequest', jobRequestData));
}

function* moreCleanersSaga(action) {
  // get jobRequestId
  const jobRequest = yield getJobRequestFromStore();
  if (!jobRequest) return;
  const selectedJobRequestId = jobRequest.jobId;
  let backwardCompatibilityJobReqId;
  if (jobRequest.backwardCompatibility) {
    backwardCompatibilityJobReqId = jobRequest.backwardCompatibility.jobRequestId;
  }

  if (!action.noDelay) {
    yield close();
  }

  // map cleaners in the right format
  const filteredCleaners = filter(jobRequest.requestedCleaners, cleaner => !!cleaner.contact);
  const mappedCleaners = map(filteredCleaners, cleaner => cleaner.contact.userData.objectId);
  const mappedSkills = map(jobRequest.skillRequirements, skill => ({
    skillId: skill.skillId,
    dependency: skillStatus.DEPENDENCY_STATUS.REQUIRED,
  }));

  yield put(
    setModal(true, 'jobrequest', {
      mode: sendJobRequestModes.sendmore,
      prevJobRequestId: selectedJobRequestId,
      backwardCompatibilityJobReqId,
      source: 'jobRequestDetails',
      data: {
        checklistId: jobRequest.checklists.map(checklist => checklist.id),
        skills: mappedSkills,
        propertyId: jobRequest.propertyId,
        message: jobRequest.note,
        cleaners: mappedCleaners,
        title: jobRequest.title,
        price: jobRequest.offeredPrice,
        currency: jobRequest.offeredPriceCurrency,
        startTime: moment(jobRequest.scheduledStartTime).tz(jobRequest.propertyData.timeZone),
        endTime: moment(jobRequest.scheduledEndTime).tz(jobRequest.propertyData.timeZone),
        duration: jobRequest.durationInMin,
      },
    }),
  );
}

function* close() {
  yield put(
    setJobRequestDetails({
      isOpen: false,
      selectedJobRequestId: undefined,
      jobProgress: undefined,
      state: 0,
      sourceJobProblems: [],
    }),
  );

  const tasks = yield select(selectJobRequestTasks());

  const tasksJS = tasks?.toJS();

  if (tasksJS?.length) {
    // Flush tasks array to be empty when job modal is closed
    yield call(flushUpdate, {
      tasks: [],
    });
  }

  if (commentsSubscriptionChannel) {
    commentsSubscriptionChannel.close();
  }

  if (verificationPhotosSubscriptionChannel) {
    verificationPhotosSubscriptionChannel.close();
  }

  yield put({
    type: types.JOB_DETAILS_EDIT_STOP_CHANNEL,
  });
}

function* putUpdatedJobRequestInStore(jobRequest) {
  yield put(updateCollectionEntry('jobRequests', jobRequest.jobId, jobRequest));
}

function* runJobRequestUpdateChannel(id) {
  try {
    const currentUserId = yield select(selectorsGlobal.selectCurrentUserId());
    const myFork = yield fork(handleEventChannelEvent, {
      className: 'JobRequest',
      listenOnArray: ['update'],
      queryModifierFunc: query => {
        query.equalTo('objectId', id);
        query.equalTo('hostId', currentUserId);
        query.include('jobProgress');
        return query;
      },
      handleEventGenerator: putUpdatedJobRequestInStore,
    });
    yield take([types.JOB_DETAILS_EDIT_STOP_CHANNEL, types.GLOBAL_LOGOUT]);
    yield cancelImport(myFork);
  } catch (e) {
    log.error('runJobRequestUpdateChannel', e);
  }
}

export function* cancel(action) {
  yield put(
    setJobRequestDetails({
      state: 1,
    }),
  );
  try {
    const res = yield call(cancelJobRequest, action.jobRequestId);
    const singleJobReq = yield call(getSpecificJobrequest, res?.transactionId);

    yield close();
    yield put(updateCollectionEntry('jobRequests', singleJobReq.jobId, singleJobReq));
    yield put(updateInBackgroundSaga('cleanerRequest', singleJobReq.jobId, true));
  } catch (e) {
    log.error('cancelJobRequest', e);
    yield put(
      setJobRequestDetails({
        state: 2,
      }),
    );
  }
}

export function* openSourceJobSaga({ sourceJobSnapshotId }) {
  yield put(openJobRequestDetails(sourceJobSnapshotId));
}

function* addSubscriptionComment(payload = []) {
  try {
    yield put(setCommentsLoader(true));
    const result = yield select(jobDetailsSelectors.selectComments());
    const comments = [...result.toJS()];
    const { commentId } = payload;
    // As we may be getting a notification from the graphql subscription
    // about a comment that we've added directly in the webapp, we need
    // to avoid adding these again to the state.
    if (!find(comments, { commentId })) {
      comments.push(payload);
      yield put(setComments(comments));
    }
    yield put(setCommentsLoader(false));
  } catch (e) {
    log.error('[addSubscriptionComment] adding comment failed', e);
  }
}

function* getCommentsResponse(action) {
  try {
    yield put(setCommentsLoader(true));

    let data;
    let comments = [];
    let offset = 0;
    const limit = 100;
    do {
      data = yield call(getGQLComments, action.payload.jobId, 'HOST', offset, limit);
      log.info(`[getCommentsResponse] ${data.GetComment.length} comments returned (offset ${offset}, limit ${limit})`);
      if (data.GetComment.length !== 0) {
        const newComments = data.GetComment.reduce(
          (acc, { message, pictureIdentifier, updatedAt, commentId, sender }) => {
            const obj = {
              comment: message,
              pictureIdentifier,
              time: updatedAt,
              commentId,
            };

            if (sender && sender.userData) {
              obj.senderFirstname = sender.userData.firstName;
              obj.senderId = sender.userData.id;
              obj.senderLastname = sender.userData.lastName;
            }
            acc.push(obj);
            return acc;
          },
          [],
        );

        comments = comments.concat(newComments);
      }
      offset += limit;
    } while (data.GetComment.length === limit);

    yield put(setComments(comments));
    yield put(setCommentsLoader(false));

    const isLiveCommentsFeatureFlagEnabled = yield select(
      selectIsFeatureFlagEnabledForUser(featureFlag.FEATURE_FLAG_LIVE_COMMENTS),
    );

    if (isLiveCommentsFeatureFlagEnabled) {
      // set up our channel to listen for new comments via our GraphQL subscription
      const params = { jobSnapshotId: action.payload.jobId, userRole: 'HOST' };
      commentsSubscriptionChannel = yield call(createCommentsSubscriptionChannel, params);
      yield takeEvery(commentsSubscriptionChannel, addSubscriptionComment);
    }
  } catch (e) {
    log.error('getComment - fetch job request', action, e);
  }
}

function* addCommentsResponse(payload) {
  try {
    yield put(setCommentsLoader(true));
    const data = yield call(addComment, payload);
    const { lastName, firstName, objectId } = yield select(st => st.currentUser.user);
    const { pictureIdentifier, message, date } = payload.payload;

    const tempPayload = {
      commentId: data.Comments.CommentResponse.commentId,
      senderFirstname: firstName,
      senderLastname: lastName,
      senderId: objectId,
      pictureIdentifier,
      comment: message,
      time: date,
    };
    const com = yield select(jobDetailsSelectors.selectComments());
    const tempCom = [...com.toJS()];
    tempCom.push(tempPayload);
    yield put(setComments(tempCom));
    yield put(setCommentsLoader(false));
  } catch (e) {
    log.error('addCommentis failed', e);
  }
}

function* handleClickSaga(action) {
  // set task image
  yield put(setCommentsLoader(true));
  if (action.meta.action === 'addTaskPic') {
    const image = action.meta.addTaskPic;
    log.info('JobRequestSagas - uploadTaskPic', image);
    try {
      yield select(selectJobRequestData());
      const res = yield call(uploadImage, image);
      log.info('uploadPic - res', res);
      const { selectedJobRequestId } = yield select(jobDetailsSelectors.selectJobDetailsJS());

      yield put(
        addComments({
          jobSnapshotId: selectedJobRequestId,
          pictureIdentifier: res.pictureUrl,
          date: new Date().toISOString(),
          message: '',
          userRole: 'HOST',
        }),
      );
    } catch (e) {
      log.error('uploadPic - error', e);
      yield put(setGlobalLoadingState(mediaUploadLoadingKey, 3));
      yield put(setCommentsLoader(false));
    }
  }
}

function* saga() {
  yield fork(takeEvery, types.JOB_DETAILS_OPEN_SAGA, open);
  yield fork(takeEvery, types.JOB_DETAILS_CLOSE_SAGA, close);
  yield fork(takeEvery, types.JOB_DETAILS_CANCEL_SAGA, cancel);
  yield fork(takeEvery, types.JOB_DETAILS_EDIT_SAGA, editSaga);
  yield fork(takeEvery, types.JOB_DETAILS_COST_SAGA, costSaga);
  yield fork(takeEvery, types.JOB_DETAILS_ADD_COST_SAGA, addCostSaga);
  yield fork(takeLatest, types.JOB_DETAILS_ADD_COST_PICTURE_SAGA, addCostPictures);
  yield fork(takeEvery, types.JOB_DETAILS_EDIT_CLOSE_SAGA, editCloseSaga);
  yield fork(takeEvery, types.JOB_DETAILS_COST_CLOSE_SAGA, costCloseSaga);
  yield fork(takeEvery, types.JOB_DETAILS_EDIT_CONTINUE_SAGA, editContinueSaga);
  yield fork(takeEvery, types.JOB_DETAILS_MORE_CLEANER_SAGA, moreCleanersSaga);
  yield fork(takeEvery, types.JOB_DETAILS_SCHEDULE_PROBLEMS_SAGA, scheduleProblemsSaga);
  yield fork(takeEvery, types.JOB_DETAILS_OPEN_SOURCE_JOB, openSourceJobSaga);
  yield fork(takeEvery, types.GET_COMMENTS, getCommentsResponse);
  yield fork(takeEvery, types.ADD_COMMENTS, addCommentsResponse);
  yield fork(takeEvery, types.JOB_DETAILS_HANDLECLICK, handleClickSaga);
}

export default saga;
