import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const ReportsBox = ({ children, onClose, isBig, align }) => (
  <div>
    <div
      className={cx('reports__selectb', {
        'reports__selectb--big': isBig,
        'reports__selectb--left': align === 'left',
        'reports__selectb--right': align === 'right',
      })}
      onClick={e => e.stopPropagation()}
    >
      {children}
    </div>
    <div onClick={onClose} className={cx('reports__selectb-backdrop')} />
  </div>
);

ReportsBox.defaultProps = {
  align: 'left',
};

ReportsBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  align: PropTypes.oneOf(['left', 'right']),
};

export default ReportsBox;
