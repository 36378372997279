import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, mapProps, flattenProp } from 'recompose';
import t from '@properly/localization';
import { SpaceFlexi, Button, SpacedHeadline } from '@properly/components';
import pick from 'lodash/pick';
import indexOf from 'lodash/indexOf';
import { mapToMainState } from './permissionhelper';
import {
  JobRequestSpacerWrap,
  JobRequestTextMain,
  JobRequestPermissionBox,
} from '../../../components/JobRequestElements';
import { handlePermissionActionSaga } from './PermssionsActions';
import InfoMessage from '../../../components/InfoMessage';
import HasPermission from '../../../hoc/HasPermission';

const defaultSpacing = {
  left: '32px',
  top: '12px',
  bottom: '12px',
  right: '32px',
};

class TypeSection extends PureComponent {
  static propTypes = {
    sectionValue: PropTypes.shape({}),
  };

  selectOption = val => () => {
    this.props.handlePermissionActionSaga('changeSubmitType', {
      value: this.props.sectionValue.set('type', val),
    });
  };

  doUpgrade = () => {
    this.props.handlePermissionActionSaga('doUpgrade', {});
  };

  isSelected = val => this.props.sectionValue.get('type') === val;

  genOptions = val => ({
    selected: this.isSelected(val),
    onClick: this.selectOption(val),
    disabled: indexOf(this.props.allowedTypes, val) === -1,
  });

  renderMsg() {
    return (
      <InfoMessage
        text={t('permissions.modal.trial_message')}
        color="red"
        isMergeInfo
        spaceFunc={() => <SpaceFlexi type="vertical" size="15px" />}
      >
        <Button onClick={this.doUpgrade} types={['type-full-primary', 'size-medium', 'height-28']}>
          {t('permissions.modal.upgrade')}
        </Button>
      </InfoMessage>
    );
  }

  render() {
    const setupsupport = this.genOptions('setupsupport');
    const owneraccess = this.genOptions('owneraccess');
    const spaccess = this.genOptions('spaccess');
    return (
      <JobRequestSpacerWrap left="24px" top="20px" bottom="20px" right="24px" flexGrow>
        <HasPermission
          hasAccessFormatter={hasAccessRes => hasAccessRes.isFullPartnerMode}
          render={isFullPartnerMode => {
            if (!isFullPartnerMode && this.props.allowedTypes.length < 2) {
              return this.renderMsg();
            }
            return null;
          }}
        />
        <SpacedHeadline size="small">{t('permissions.modal.invite_outsiders')}</SpacedHeadline>
        <JobRequestTextMain>{t('permissions.modal.invite_outsiders_l2')}</JobRequestTextMain>

        <SpaceFlexi type="vertical" size="14px" />

        <SpacedHeadline size="small">{t('permissions.modal.service_provider_access')}</SpacedHeadline>
        <JobRequestPermissionBox {...spaccess}>
          <div>
            <JobRequestTextMain>{t('permissions.modal.service_provider_access_info')}</JobRequestTextMain>
          </div>
        </JobRequestPermissionBox>

        <SpacedHeadline size="small">{t('permissions.modal.owner_access')}</SpacedHeadline>
        <JobRequestPermissionBox {...owneraccess}>
          <div>
            <JobRequestTextMain>{t('permissions.modal.owner_access_info')}</JobRequestTextMain>
          </div>
        </JobRequestPermissionBox>

        <SpacedHeadline size="small">{t('permissions.modal.setup_support')}</SpacedHeadline>
        <JobRequestPermissionBox {...setupsupport}>
          <div>
            <JobRequestTextMain>{t('permissions.modal.setupsupport_info')}</JobRequestTextMain>
          </div>
        </JobRequestPermissionBox>
        {setupsupport.disabled && (
          <JobRequestSpacerWrap {...defaultSpacing}>
            <JobRequestTextMain type="sub">
              {t('permissions.modal.you_cannot_invite_more', { partner: t('permissions.partner', { context: 1 }) })}
            </JobRequestTextMain>
          </JobRequestSpacerWrap>
        )}
      </JobRequestSpacerWrap>
    );
  }
}

export default compose(
  connect(mapToMainState, { handlePermissionActionSaga }),
  mapProps(props => pick(props, ['activeSectionData', 'handlePermissionActionSaga', 'isInternalColl', 'allowedTypes'])),
  flattenProp('activeSectionData'),
)(TypeSection);
