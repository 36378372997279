import React from 'react';
import { TabBar } from '@properly/components';
import map from 'lodash/map';
import t from '@properly/localization';

class PermissionsTabBar extends React.PureComponent {
  genPropsForTab = name => ({
    onClick: this.props.onClick(name),
    active: this.props.activeTab === name,
  });

  tabs = {
    external: () => (
      <TabBar.Item
        key="button_section_external_coll"
        data-key="button_section_external_coll"
        {...this.genPropsForTab('external')}
      >
        {t('permissions.external_coll', { context: 2 })}
      </TabBar.Item>
    ),
    shared: () => (
      <TabBar.Item
        key="button_section_properties_shared"
        data-key="button_section_properties_shared"
        {...this.genPropsForTab('shared')}
      >
        {t('permissions.properties_shared')}
      </TabBar.Item>
    ),
  };

  render() {
    return (
      <TabBar
        rightSiderStyle={{
          right: 20,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {map(this.props.tabs, val => this.tabs[val]())}
      </TabBar>
    );
  }
}

export default PermissionsTabBar;
