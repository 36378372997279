import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import t from '@properly/localization';
import { Icon } from '@properly/components';
import {
  mapTaskIconToIconAndTitle,
  getClosestElement,
  pictureSizes,
  generatePictureUrl,
  imageUploadLimit,
} from '@properly/common';
import noop from 'lodash/noop';
import classNames from 'classnames/bind';
import styles from './index.module.css';
import ChecklistTextArea from '../ChecklistTextArea';

const cx = classNames.bind(styles);

function genImage(imageInner) {
  return {
    backgroundImage: `url(${generatePictureUrl(imageInner, pictureSizes.SMALL)})`,
  };
}

class ChecklistImageListItem extends Component {
  constructor(props) {
    super(props);
    this.state = { disabled: this.props.dragDisable }; // eslint-disable-line
  }

  setFocusAndEnable = e => {
    e.persist();
    this.setState({ disabled: false }); // eslint-disable-line
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  handleInputFileClick = e => {
    e.target.value = '';
  };

  handleDirectTaskImageUpload = (stepId, taskIndex, event) => {
    const [imageFile] = event.target.files;
    const { size } = imageFile;
    const { minSize, maxSize } = imageUploadLimit;
    if (size < minSize || size > maxSize) {
      this.props.openErrorModal();
      return;
    }
    const { checklistDirectTaskImageUpload } = this.props;
    checklistDirectTaskImageUpload(stepId, taskIndex, imageFile);
  };

  renderNewTaskImageButton = () => {
    const { stepId, realIndex, directUpload } = this.props;
    const inputId = `upload-new-photo-${realIndex}-${stepId}`;

    const handleNewTaskPhotoClick = directUpload
      ? noop
      : () =>
          this.props.setUploadState({
            isOpen: true,
            do: {
              mode: 'replacetaskimg',
              stepId: `${stepId}`,
              taskIndex: realIndex,
            },
          });

    return (
      <>
        {directUpload && (
          <input
            accept="image/*"
            onChange={event => this.handleDirectTaskImageUpload(stepId, realIndex, event)}
            onClick={this.handleInputFileClick}
            className={cx('checklist__upload_input')}
            id={inputId}
            type="file"
            style={{ display: 'none' }}
          />
        )}

        <label
          htmlFor={inputId}
          data-key="newTaskImage"
          onClick={() => handleNewTaskPhotoClick()}
          style={{ cursor: 'inherit' }}
        >
          <Icon.IcCamera2 />
        </label>
      </>
    );
  };

  render() {
    const {
      iconImage,
      title,
      active,
      isEmpty,
      defaultValue,
      iconBg,
      onDelete,
      canHaveImg,
      isEnter,
      open,
      onChange,
      pictureUrl,
      onRemoveImage,
      isDragging,
      isRed,
      dragDisable,
      isReadOnly,
      iconColor,
    } = this.props;
    const isActiveOpen = active || open;
    const IconName = mapTaskIconToIconAndTitle(iconImage).icon;
    const ChecklistImageListIcon =
      iconImage === 'other' ? () => <Icon.IcItemCheck color={iconColor} /> : Icon[IconName] || Icon.IcItemCheck;

    const runBlur = e => {
      const that = this;
      e.persist();
      if (this.props.dragDisable) {
        setTimeout(() => {
          that.setState({ disabled: true });
          onChange(e);
        }, 0);
      } else {
        setTimeout(() => {
          onChange(e);
        }, 100);
      }
    };

    const handleKeyPress = mode => e => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        if (mode === 'selectinputbox') {
          const parent = getClosestElement(e.target, '[data-key="checklistbox"]');
          if (parent) {
            const input = parent.querySelector('[data-key="checklist_enternewfield"]');
            if (input) {
              // eslint-disable-next-line
              const node = ReactDOM.findDOMNode(input);
              if (node) node.focus();
            }
          }
        }
        if (mode === 'blur') {
          e.target.blur();
        }
      }
    };

    const listener = isEnter ? { onChange, 'data-key': 'checklist_enternewfield' } : { onBlur: runBlur };
    const mode = canHaveImg || isEnter ? 'blur' : 'selectinputbox';
    listener.onKeyPress = handleKeyPress(mode);

    const renderInner = () => (
      <div className={cx('checklist-image-list__inner')}>
        {!isEnter && (
          <div className={cx('checklist-image-list__left')}>
            <div
              className={cx('checklist-image-list__icon', {
                'checklist-image-list__icon--white': iconBg === 'white',
                'checklist-image-list__icon--orange': iconBg === 'orange',
              })}
            >
              {this.props.connectDragSource ? (
                this.props.connectDragSource(
                  <div style={{ cursor: 'move' }}>
                    <ChecklistImageListIcon />
                  </div>,
                )
              ) : (
                <ChecklistImageListIcon />
              )}
            </div>
            {title && <div className={cx('checklist-image-list__title')}>{title}</div>}
          </div>
        )}
        {isEnter && (
          <div className={cx('checklist-image-list__left')}>
            <div className={cx('checklist-image-list__icon')}>
              <Icon.IcTextPlus />
            </div>
          </div>
        )}
        <div onClick={dragDisable ? this.setFocusAndEnable : noop} className={cx('checklist-image-list__text')}>
          <ChecklistTextArea
            defaultValue={defaultValue}
            autoFocus={active}
            disabled={isReadOnly}
            data-key="checklist_text_area"
            {...listener}
            placeholder={t('checklist.enter_detail')}
          />
        </div>
        {!isActiveOpen && !isEnter && (
          <div
            data-key="checklist_task_delete_btn"
            className={cx('checklist-image-list__right', 'checklist-image-list__right--flex')}
          >
            {canHaveImg && !isReadOnly && (
              <div className={cx('checklist-image-list__icon-small')}>{this.renderNewTaskImageButton()}</div>
            )}
            {!isReadOnly && (
              <div
                onMouseDown={onDelete}
                className={cx('checklist-image-list__icon-small')}
                data-key="checklist-delete-task-icon"
              >
                <Icon.IcTrash />
              </div>
            )}
          </div>
        )}

        {isActiveOpen && canHaveImg && (
          <div className={cx('checklist-image-list__right', 'checklist-image-list__right--flex')}>
            {!pictureUrl && (
              <div className={cx('checklist-image-list__box')}>
                {!isReadOnly && (
                  <div className={cx('checklist-image-list__icon-small', 'checklist-image-list__icon-small--cam')}>
                    {this.renderNewTaskImageButton()}
                  </div>
                )}
              </div>
            )}
            {pictureUrl && (
              <div
                style={genImage(pictureUrl)}
                className={cx('checklist-image-list__box', 'checklist-image-list__box--image')}
              >
                {!isReadOnly && (
                  <div className={cx('checklist-image-list__icon-remove')}>
                    <Icon.ReportproblemRemoveImage onClick={onRemoveImage} />
                  </div>
                )}
              </div>
            )}
            {!isReadOnly && (
              <div
                onMouseDown={onDelete}
                className={cx('checklist-image-list__icon-small', 'checklist-image-list__icon-small--trash')}
              >
                <Icon.IcTrash />
              </div>
            )}
          </div>
        )}
      </div>
    );

    return (
      <div
        data-key="checklist_task_line_outer"
        className={cx('checklist-image-list', {
          'checklist-image-list--active': active,
          'checklist-image-list--open': open,
          'checklist-image-list--red': isRed,
          'checklist-image-list--dragging': isDragging,
          'checklist-image-list--hover': !active && !open,
        })}
      >
        {!isEmpty && renderInner()}
      </div>
    );
  }
}

ChecklistImageListItem.defaultProps = {
  dragDisable: () => {},
};

ChecklistImageListItem.propTypes = {
  iconImage: PropTypes.string,
  defaultValue: PropTypes.string,
  pictureUrl: PropTypes.string,
  onDelete: PropTypes.func,
  onRemoveImage: PropTypes.func,
  onChange: PropTypes.func,
  dragDisable: PropTypes.bool,
  iconBg: PropTypes.string,
  title: PropTypes.string,
  canHaveImg: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isRed: PropTypes.bool,
  isDragging: PropTypes.bool,
  isEnter: PropTypes.bool,
  active: PropTypes.bool,
  open: PropTypes.bool,
  checklistDirectTaskImageUpload: PropTypes.func,
  directUpload: PropTypes.bool,
};

export default ChecklistImageListItem;
