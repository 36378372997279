import React, { PureComponent } from 'react';
import t from '@properly/localization';
import { PropertyImage, DividerLine, Box, SpaceFlexi, Icon, ButtonList, PopoverDefault } from '@properly/components';
import { pictureSizes, generatePictureUrl } from '@properly/common';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import { TodosTextEle } from '../TodosElements';

class CompanyBox extends PureComponent {
  mapEmail() {
    const { adminEmails } = this.props;
    if (adminEmails.length === 0) return '-';
    const ordered = orderBy(adminEmails, emailObj => !emailObj.active);
    return map(ordered, emailObj => (
      <TodosTextEle key={emailObj.email} modifier={['txt--l7', emailObj.active ? 'color--black' : 'color--grey']}>
        {emailObj.email}
      </TodosTextEle>
    ));
  }

  render() {
    const { name, about, pic } = this.props;
    return (
      <Box
        isFirst
        isLast
        styleOverwrite={{
          padding: '24px',
          height: 'auto',
          background: 'white',
          flexDirection: 'column',
        }}
      >
        <div style={{ position: 'absolute', right: 10, top: 10 }}>
          <PopoverDefault
            width={150}
            popoverContent={PopoverStates => (
              <ButtonList
                closePopover={PopoverStates.closePopup}
                items={[
                  {
                    key: 'Company_edit',
                    'data-key': 'Company_edit',
                    name: t('permissions.edit'),
                    onClick: this.props.onEdit,
                  },
                ]}
              />
            )}
            hasArrow
          >
            <Icon.IcMorePrimary byWidth width="24px" />
          </PopoverDefault>
        </div>
        <PropertyImage type={['image--company']} image={generatePictureUrl(pic, pictureSizes.PROFILE_PICTURE)} />
        <SpaceFlexi type="vertical" size="12px" />
        <TodosTextEle modifier={['txt--l3m', 'color--black', 'helper--wordbreak']}>{name}</TodosTextEle>
        <SpaceFlexi type="vertical" size="16px" />
        <DividerLine type={['relative', 'grey']} />
        <SpaceFlexi type="vertical" size="9px" />
        <div style={{ textAlign: 'left', width: '100%' }}>
          <TodosTextEle modifier={['txt--l7', 'color--grey']}>{t('permissions.company_box_l1')}</TodosTextEle>
          <SpaceFlexi type="vertical" size="4px" />
          {this.mapEmail()}
          <SpaceFlexi type="vertical" size="16px" />
          <DividerLine type={['relative', 'grey']} />
          <div>
            <SpaceFlexi type="vertical" size="9px" />
            <TodosTextEle modifier={['txt--l7', 'color--grey']}>{t('permissions.company_box_l2')}</TodosTextEle>
            <SpaceFlexi type="vertical" size="4px" />
            <TodosTextEle modifier={['txt--l7', 'color--black']}>{about || '-'}</TodosTextEle>
          </div>
        </div>
      </Box>
    );
  }
}

export default CompanyBox;
