import forOwn from 'lodash/forOwn';
import lodashResult from 'lodash/result';
import { changeHelper, getInitials, makeInfoString } from '../helper/herbert';
import Profile from './profile';
import UserData from './userData';

export default class Contact {
  constructor(objectId, ownerId, contactId, userData, lastJobSent, deleted, profile, isSuggested) {
    this.objectId = objectId;
    this.ownerId = ownerId;
    this.contactId = contactId;
    this.userData = userData;
    this.lastJobSent = lastJobSent;
    this.deleted = deleted;
    this.profile = profile;
    this.listSuggestedCleaner = isSuggested;
  }

  setData(obj) {
    this.getOwnProps(obj, this);
  }

  getData() {
    return this.getOwnProps(this);
  }

  getOwnProps(scope, onScope) {
    const obj = onScope || {};
    forOwn(scope, (value, key) => {
      if (key === 'profile' && value) {
        obj[key] = changeHelper(Profile, null, value);
      } else if (key === 'userData' && value) {
        obj[key] = changeHelper(UserData, null, value);
      } else {
        obj[key] = value;
      }
    });
    return obj;
  }

  getId() {
    return this.objectId;
  }

  getUserData() {
    return this.userData;
  }

  get userInitials() {
    return getInitials(lodashResult(this, ['userData', 'firstName']), lodashResult(this, ['userData', 'lastName']));
  }

  get firstName() {
    return lodashResult(this, ['userData', 'firstName'], '');
  }

  get userPicture() {
    return lodashResult(this, ['userData', 'pictureUrl']);
  }

  get lastJobSendString() {
    return makeInfoString(this).value;
  }

  getProStatus() {
    return this.listSuggestedCleaner && !this.profile?.marketplaceBlacklisted && !this.profile?.marketplaceSuspended;
  }
}
