import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { mapProps, compose } from 'recompose';

import pick from 'lodash/pick';
import { connect } from 'react-redux';
import {
  JobRequestContent,
  JobRequestWrap,
  JobRequestSidebar,
  JobRequestLoadingOverlay,
} from '../../../components/JobRequestElements';
import { mapToMainState } from './permissionhelper';
import { initModalSaga } from './PermssionsActions';

class PermissionModalContent extends PureComponent {
  static propTypes = {
    sidebarComponent: PropTypes.func.isRequired,
    mainComponent: PropTypes.func.isRequired,
    startupData: PropTypes.shape({}).isRequired,
    actionHandler: PropTypes.func.isRequired,
    initModalSaga: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.initModalSaga({
      mode: 'mount',
      startupData: this.props.startupData,
    });
  }

  componentWillUnmount() {
    this.props.initModalSaga({ mode: 'unmount' });
  }

  render() {
    const Sidebar = this.props.sidebarComponent || null;
    const Main = this.props.mainComponent || null;
    return (
      <JobRequestWrap>
        <JobRequestSidebar>
          <Sidebar actionHandler={this.props.actionHandler} />
        </JobRequestSidebar>
        <JobRequestContent>
          <Main actionHandler={this.props.actionHandler} />
        </JobRequestContent>
        {(this.props.isModalLoading || this.props.submitIsLoading) && <JobRequestLoadingOverlay />}
      </JobRequestWrap>
    );
  }
}

export default compose(
  connect(
    mapToMainState,
    { initModalSaga },
  ),
  mapProps(props =>
    pick(props, [
      'sidebarComponent',
      'mainComponent',
      'startupData',
      'actionHandler',
      'initModalSaga',
      'isModalLoading',
      'submitIsLoading',
    ]),
  ),
)(PermissionModalContent);
