import { put, fork, select, take, call, cancel, takeEvery, takeLatest } from 'redux-saga/effects';
import reduce from 'lodash/reduce';
import lodashResult from 'lodash/result';
import set from 'lodash/set';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import { fromJS } from 'immutable';
import { push } from 'react-router-redux';
import { featureFlag } from '@properly/config';
import log from 'loglevel';
import moment from 'moment-timezone';
import * as types from '../../../types';
import {
  mergeSection,
  reportsSetModal,
  setLoadedReport,
  setSectionsReport,
  goToReports,
  setAdminModeReportFields,
  resetAdminModeReportFields,
  setReportData,
  setLoading,
  snapShot,
  setPaymentDetailsReportFields,
  setValidation,
} from './ReportsActions';
import * as selectors from './ReportsSelectors';
import { updateCollectionEntry, setSearchQuery } from '../../../actions/globalActions';
import {
  selectCurrentUserLoggingOut,
  selectCurrentUserLoggedIn,
  selectCurrentUserId,
  selectContacts,
} from '../../../selectors/globalSelector';
import { selectAdminMode, selectIsFeatureFlagEnabledForUser } from '../settings/state/SettingsSelectors';
import { parseReportHandler, downloadReport, downloadStatus, fetchReport } from '../data';
import { NEW_KEY, DEFAULT_SECTIONS, FETCH_X_JOBREQUEST_PER_LOAD, ORDER_COLUMNS } from './ReportsConstants';
import { ROUTES } from '../../../paths';
import { pollChannel } from '../../../sagas/global';
import {
  mapReportsTableHeads,
  mapTextToDate,
  mapReportToBackendReport,
  hashData,
  isValidForFetch,
  genParamsFromSections,
  mapBackendReportToReport,
  titleIsValid,
  mapRowKeysToTypes,
  replaceContactIdWithUserId,
} from './ReportsMapper';
import { trackCreateReport, trackSaveReport, trackExportReport } from '../../../actions/trackingEvents';

function genSelectedColumns(selected) {
  return reduce(
    ORDER_COLUMNS,
    (total, val) => {
      if (selected[val]) {
        total[val] = mapReportsTableHeads(val); // eslint-disable-line
      }
      return total;
    },
    {},
  );
}

function resultGen(format) {
  return function*(res) {
    const status = lodashResult(res, ['result', 'progressData', 'status']);
    const url = lodashResult(res, ['result', 'resultData', 'url']);
    const statusPoll = lodashResult(res, ['status']);
    if (status === 'finished') {
      yield put(reportsSetModal('csv', { status, url, format }));
      return true;
    }
    if (status === 'error' || statusPoll === 1) {
      yield put(reportsSetModal('error'));
      return true;
    }
    yield put(reportsSetModal('csv', { status, format }));
    return undefined;
  };
}

function transformParams(params) {
  return {
    startDate: params.start ? moment(params.start).format('DD.MM.YYYY') : undefined,
    endDate: params.end ? moment(params.end).format('DD.MM.YYYY') : undefined,
    properties: params.property ? [params.property] : undefined,
    cleaners: params.cleaner ? [params.cleaner] : undefined,
    problemsReported: !!params.problem,
    cleanerRequestStatus: params.status,
    jobType: params.jobType,
    jobRequestStatus: params.jobStatus,
  };
}

function* replaceContactIdWithUserIdGen(sectionData) {
  const cloned = cloneDeep(sectionData);
  const contacts = yield select(selectContacts());
  const selectedUsersMap = replaceContactIdWithUserId(contacts.toJS(), get(cloned, 'contacts.selected'));
  return set(cloned, 'contacts.selected', selectedUsersMap);
}

function* exportCSVSaga({ format }) {
  try {
    const selectedColumns = yield select(selectors.selectReportsSectionSelected('columns'));
    const columns = genSelectedColumns(selectedColumns.toJS());
    const userId = yield select(selectCurrentUserId());
    const sectionData = yield select(selectors.selectReportsSections());
    const res1 = yield replaceContactIdWithUserIdGen(sectionData.toJS());
    const params = genParamsFromSections(res1, userId);
    yield put(reportsSetModal('csv', { format, status: 'started' })); // loading
    const res = yield call(downloadReport, {
      columns,
      format,
      title: 'unset',
      ...transformParams(params),
    });
    yield call(pollChannel, {
      pullFunc: downloadStatus,
      args: res,
      handleEventGenerator: resultGen(format),
      interval: 1000,
    });
    trackExportReport();
  } catch (e) {
    log.error('exportCSVSaga', e);
    yield put(reportsSetModal('error'));
  }
}

function* reportsDateSaga(action) {
  const { start, end } = mapTextToDate(action.range);
  if (action.range === 'custom') {
    const more = action.more || {};
    if (more.end) {
      more.end = more.end.set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 0,
      });
    }
    if (more.start) {
      more.start = more.start.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    }
    yield put(
      mergeSection('date', {
        mode: true,
        start: undefined,
        end: undefined,
        active: action.range,
        ...more,
      }),
    );
    return;
  }
  yield put(mergeSection('date', { start, end, active: action.range }));
}

function mapAction(isNew) {
  if (isNew) return 'add';
  return 'edit';
}

function* reportsSaveSaga() {
  const sectionData = yield select(selectors.selectReportsSections());
  const loadedReport = yield select(selectors.selectReportsLoadedReport());
  const isNew = loadedReport === NEW_KEY;
  const reportParams = mapReportToBackendReport(sectionData.toJS());
  log.info('reportsSaveSaga', { isNew });
  try {
    yield put(reportsSetModal('loading'));
    const res = yield call(parseReportHandler, mapAction(isNew), {
      title: reportParams.title,
      filter: reportParams.filter,
      objectId: isNew ? undefined : loadedReport,
    });
    yield put(updateCollectionEntry('reports', res.objectId, res));
    if (isNew) {
      trackCreateReport();
      yield letChange(ROUTES.reportPage(res.objectId));
    } else {
      trackSaveReport();
    }
  } catch (e) {
    log.error('reportsSaveSaga', e);
  } finally {
    yield put(reportsSetModal(undefined));
  }
}

function* reportsDeleteSaga(action) {
  if (action.cancel) {
    yield put(reportsSetModal(undefined));
    return;
  }
  if (action.delete) {
    const reportId = yield select(selectors.selectReportsLoadedReport());
    yield put(reportsSetModal(undefined));
    try {
      yield put(reportsSetModal('loading'));
      const res = yield call(parseReportHandler, 'delete', {
        objectId: reportId,
      });
      yield put(updateCollectionEntry('reports', res.objectId, res));
      yield put(setSearchQuery('reports', ''));
      yield put(goToReports());
    } catch (e) {
      log.error('reportsDeleteSaga', e);
    }
    yield put(reportsSetModal(undefined));
    return;
  }
  yield put(reportsSetModal('delete'));
}

function* reportsLoadReportSaga(action) {
  const defaultData = fromJS(DEFAULT_SECTIONS);
  const isLoggingOut = yield select(selectCurrentUserLoggingOut());
  const isLoggedIn = yield select(selectCurrentUserLoggedIn());
  const dashboardAdminMode = yield select(selectAdminMode);
  const isMarketplaceFeatureAvailable = yield select(
    selectIsFeatureFlagEnabledForUser(featureFlag.FEATURE_FLAG_MARKETPLACE),
  );

  // stop if loggedout
  if (isLoggingOut || !isLoggedIn) {
    return;
  }

  const { id } = action;

  // new report
  if (!id || id === 'new') {
    yield put(setLoadedReport(NEW_KEY));
    yield put(setSectionsReport(defaultData));

    if (isMarketplaceFeatureAvailable) {
      // added action to make reducer granular plus its easier to scale wrt to feature/feature flag
      yield put(setPaymentDetailsReportFields());
    }

    // check for admin mode to display admin only fields in report
    if (dashboardAdminMode) {
      yield put(setAdminModeReportFields());
    }
    yield put(snapShot());
    yield call(userChangedTryRefetchSaga);
  } else {
    // existing report
    const reports = yield select(selectors.selectReportsForSidebar());
    const statusData = yield select(selectors.selectReportsSection('status'));
    const jobTypeData = yield select(selectors.selectReportsSection('jobTypes'));
    const report = reports.all[action.id];
    const loading = yield select(selectors.selectReportsForSidebarLoading);

    if (!report && !loading) {
      // not found
      yield put(reportsSetModal('error'));
    } else {
      // edit report
      yield put(setLoadedReport(action.id));
      const mappedData = mapBackendReportToReport(
        report,
        statusData.get('states').toJS(),
        jobTypeData.get('types').toJS(),
      );
      const fixedDefaultData = defaultData
        .setIn(['columns', 'selected'], fromJS({}))
        .setIn(['status', 'selected'], fromJS({}))
        .setIn(['status', 'isTouched'], true)
        .setIn(['jobTypes', 'selected'], fromJS({}))
        .setIn(['jobTypes', 'isTouched'], true)
        .setIn(['properties', 'isTouched'], true)
        .setIn(['contacts', 'isTouched'], true)
        .setIn(['columns', 'isTouched'], true);
      const mergedData = fixedDefaultData.mergeDeep(fromJS(mappedData));
      const mergedDataJS = mergedData.toJS();
      yield put(setSectionsReport(mergedData));

      // set pp fields and payment details fields if available
      if (isMarketplaceFeatureAvailable) {
        yield put(setPaymentDetailsReportFields());
      }

      // reset admin mode fields for normal users
      // add admin report fields for admin users
      if (!dashboardAdminMode) {
        yield put(resetAdminModeReportFields());
      } else {
        yield put(setAdminModeReportFields());
      }
      const more =
        mergedDataJS.date.active === 'custom'
          ? {
              start: mergedDataJS.date.start,
              end: mergedDataJS.date.end,
              mode: true,
            }
          : undefined;
      yield call(reportsDateSaga, { range: mappedData.date.active, more });
      yield call(userChangedTryRefetchSaga);
    }
  }
}

function* letChange(where) {
  if (!where) return;
  yield put(push({ pathname: where, state: { fromReports: true } }));
}

function* leaveHookSaga(action) {
  const reports = yield select(selectors.selectReportsForSidebar());
  const sectionData = yield select(selectors.selectReportsSections());
  const openReport = yield select(selectors.selectReportsLoadedReport());
  const metaModal = yield select(selectors.selectReportsModalMeta());
  const openReportResolved = reports.all[openReport];
  const isNew = openReport === NEW_KEY;

  if (!openReportResolved && !isNew) {
    log.info('leaveHookSaga - report not found or isNew');
    yield letChange(action.nextLocation);
    return;
  }

  let isSame = false;
  let h1;
  let h2;

  if (isNew) {
    const snapShotData = yield select(selectors.selectReportsSnapshot());
    h1 = hashData(snapShotData.toJS());
    h2 = hashData(sectionData.toJS());
    isSame = h1 === h2;
    log.info('leaveHookSaga', 'new');
  } else {
    const old = mapBackendReportToReport(openReportResolved, sectionData.getIn(['status', 'states']).toJS());
    h1 = hashData(old);
    h2 = hashData(sectionData.toJS());
    isSame = h1 === h2;
    log.info('leaveHookSaga', 'old');
  }

  if (isSame) {
    yield letChange(action.nextLocation);
    return;
  }

  log.info('leaveHookSaga - nomatch', {
    h1,
    h2,
    isSame,
    sectionData: sectionData.toJS(),
  });

  if (metaModal) {
    log.info('No meta model - must be in the middle of something???');
    return;
  }

  yield put(reportsSetModal('save', { path: action.nextLocation, isNew }));
}

function* leaveHookModalSaga(action) {
  const metaModal = yield select(selectors.selectReportsModalMeta());
  if (action.val) {
    // if (metaModal.isNew) {
    const sectionData = yield select(selectors.selectReportsSections());
    const sectionDataJS = sectionData.toJS();
    const isValid = titleIsValid(sectionDataJS.title.value);
    if (isValid) {
      yield call(reportsSaveSaga, false, true);
      yield letChange(metaModal.path);
    } else {
      yield put(setValidation(true));
    }
    // yield letChange(metaModal.path);
    // }
    yield put(reportsSetModal(undefined, undefined));
  } else {
    yield letChange(metaModal.path);
    yield put(reportsSetModal(undefined, undefined));
  }
}

function* userChangedTryRefetchSaga(action) {
  log.info('userChangedTryRefetchSaga', action);
  let page;
  const sectionData = yield select(selectors.selectReportsSections());
  const openReport = yield select(selectors.selectReportsLoadedReport());
  const reportsData = yield select(selectors.selectReportsData());

  const sectionDataJS = sectionData.toJS();
  if (!isValidForFetch(sectionDataJS)) {
    log.info('userChangedTryRefetchSaga - not valid');
    return;
  }

  const userId = yield select(selectCurrentUserId());
  const res = yield replaceContactIdWithUserIdGen(sectionDataJS);
  const params = genParamsFromSections(res, userId);
  // pagination
  if (action && action.type === types.REPORTS_PAGINATE && reportsData.get(openReport)) {
    page = reportsData.getIn([openReport, 'pointer']) * 1 + FETCH_X_JOBREQUEST_PER_LOAD;
    params.skip = page;
  }

  const forked = yield fork(fetchReportDataSaga, params, openReport);
  yield take([types.REPORTS_SET_SELECTED_ELES, types.REPORTS_SET_VALUE_SECTION, types.REPORTS_MERGE_SECTION]);
  yield cancel(forked);
}

function* getMappedBackendData(params) {
  const res = yield call(fetchReport, {
    ...transformParams(params),
    skip: params.skip ? params.skip : 0,
    limit: FETCH_X_JOBREQUEST_PER_LOAD,
  });

  return map(res.data, mapRowKeysToTypes);
}

function* fetchReportDataSaga(params, openReportId) {
  try {
    log.info('fetchReportDataSaga', params);
    yield put(setLoading(true));
    const res = yield getMappedBackendData(params);
    const skipString = params.skip.toString();
    yield put(setReportData(openReportId, res, skipString, skipString !== '0'));
  } catch (e) {
    log.error('fetchReportDataSaga', e);
    yield put(reportsSetModal('error'));
  } finally {
    yield put(setLoading(false));
  }
}

function* saga() {
  yield fork(takeEvery, types.REPORTS_DATE_SAGA, reportsDateSaga);
  yield fork(takeEvery, types.REPORTS_SAVE_SAGA, reportsSaveSaga);
  yield fork(takeEvery, types.REPORTS_DELETE_SAGA, reportsDeleteSaga);
  yield fork(takeEvery, types.REPORTS_LOADREPORT_SAGA, reportsLoadReportSaga);
  yield fork(takeEvery, types.REPORTS_LEAVE_SAGA, leaveHookSaga);
  yield fork(takeEvery, types.REPORTS_LEAVE_HOOK_MODAL, leaveHookModalSaga);
  yield fork(
    takeEvery,
    [types.REPORTS_SET_SELECTED_ELES, types.REPORTS_SET_VALUE_SECTION, types.REPORTS_MERGE_SECTION],
    userChangedTryRefetchSaga,
  );
  yield fork(takeLatest, types.REPORTS_PAGINATE, userChangedTryRefetchSaga);
  yield fork(takeLatest, types.REPORTS_EXPORT_SAGA, exportCSVSaga);
}

export default saga;
