import gql from 'graphql-tag';

export const GET_CONNECTED_ACCOUNT_QUERY = gql`
  query {
    ConnectedAccount {
      id
      partner
      label
      icon
      oAuthLink

      connectionSettings {
        id
        label
        lastSync
        connectionIssue
        migrated
      }
    }
  }
`;

export const LISTEN_FOR_CONNECTED_ACCOUNTS_SUBSCRIPTION = gql`
  subscription listenForConnectedAccounts {
    listenForConnectedAccounts {
      id
      partner
      label
      icon
      oAuthLink

      connectionSettings {
        id
        label
        lastSync
        connectionIssue
        migrated
      }
    }
  }
`;

export const DISCONNECT_CONNECTED_ACCOUNT_QUERY = gql`
  mutation DisconnectConnectedAccount($connectionSettingsId: ID!) {
    DeleteConnectionSetting(connectionSettingsId: $connectionSettingsId) {
      id
      label
    }
  }
`;

export const SYNC_CONNECTED_ACCOUNT_QUERY_NEWSERVICE = gql`
  mutation ReimportPropertiesMutation($connectionSettingsId: String!, $partner: String!) {
    ReimportProperties(connectionSettingsId: $connectionSettingsId, partner: $partner) {
      acknowledged
    }
  }
`;

export const SYNC_CONNECTED_ACCOUNT_QUERY = gql`
  mutation SyncConnectionSetting($connectionSettingsId: ID!) {
    SyncConnectionSetting(connectionSettingsId: $connectionSettingsId) {
      id
      label
    }
  }
`;
