"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  paymentsEmail: 'payments@getproperly.com',
  supportEmail: 'support@getproperly.com',
  marketplaceEmail: 'marketplace@getproperly.com',
  complaintsEmail: 'complaints@getproperly.com'
};
exports["default"] = _default;