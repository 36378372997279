import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import t from '@properly/localization';
import { Regular, SpaceMedium, Icon } from '@properly/components';
import { isMobileTouch, openInNewWindow } from '@properly/common';
import lodashResult from 'lodash/result';
import queryString from 'query-string';
import { signUpSaga, hostSignUpErrorReset } from './LoginSignUpActions';
import { trackSignupScreenShown } from '../../actions/trackingEvents';
import LoginWrapContainer from './LoginWrapContainer';
import SignupForm from '../../containers/SignupForm';
import SignupCampaignLayout from './SignupCampaignLayout';
import phoneImage from '../../images/phone-image.jpg';
import { selectIsWebLoginDisabled } from '../branding/BrandingSelector';

class HostSignUpFormComponent extends Component {
  componentDidMount() {
    trackSignupScreenShown();
  }

  get campaign() {
    const { campaignName } = this.props;
    return this.mapCampaignToSettings(campaignName);
  }

  goToHome() {
    window.open(
      'https://www.getproperly.com/en/pms?utm_source=partners&utm_medium=bookingcom&utm_campaign=bookingcom-emails',
    );
  }

  mapCampaignToSettings(name) {
    switch (name) {
      case 'bookingcom':
        return {
          goToHome: this.goToHome,
          logo: Icon.BookingsComlogo,
          phoneImage,
          headLine: t('marketing.bookingcom_email.create_account'),
          subHeadline: t('marketing.bookingcom_email.subheadline'),
          infoTitle: t('marketing.bookingcom_email.info_title'),
          pageTitle: t('marketing.bookingcom_email.page_title'),
          pageDes: t('marketing.bookingcom_email.page_description'),
          secondaryBtn: t('marketing.bookingcom_email.request_a_demo'),
          list: {
            mobile: [
              ['1', t('marketing.bookingcom_email.info_l1')],
              ['2', t('marketing.bookingcom_email.info_l2')],
              ['3', t('marketing.bookingcom_email.info_l3')],
              ['4', t('marketing.bookingcom_email.info_l4')],
            ],
            desktop: [
              ['1', t('marketing.bookingcom_email.info_l1')],
              ['2', t('marketing.bookingcom_email.info_l2')],
              ['3', t('marketing.bookingcom_email.info_l3')],
              ['4', t('marketing.bookingcom_email.info_l4')],
            ],
          },
        };
      default:
        return undefined;
    }
  }

  renderForm() {
    const {
      signUpSaga, // eslint-disable-line no-shadow
      hostSignUpErrorReset, // eslint-disable-line no-shadow
      presetMail,
      loginSignUpReducers: { errors, submitHasFailed },
    } = this.props;
    return (
      <SignupForm
        key="SignupForm"
        onSubmit={signUpSaga}
        openLink={openInNewWindow}
        errors={errors}
        hostSignUpErrorReset={hostSignUpErrorReset}
        submitHasFailed={submitHasFailed}
        preSetEmail={presetMail}
      />
    );
  }

  renderDefault() {
    return [<Regular key={0}>{t('signup.no_creditcard', {})}</Regular>, <SpaceMedium key={1} />, this.renderForm()];
  }

  render() {
    const { campaign } = this;
    const { isLoggingIn, isWebLoginDisabled, location } = this.props;
    return (
      <LoginWrapContainer
        loading={isLoggingIn}
        showLogin={!isWebLoginDisabled}
        center
        isMobile={isMobileTouch()}
        big={!!campaign}
        withSubHeadline
        location={location}
        title={t('signup.title', {})}
      >
        {campaign && (
          <SignupCampaignLayout
            isMobile={isMobileTouch()}
            goToHome={campaign.goToHome}
            logo={campaign.logo}
            headLine={campaign.headLine}
            subHeadline={campaign.subHeadline}
            list={campaign.list}
            infoTitle={campaign.infoTitle}
            pageTitle={campaign.pageTitle}
            phoneImage={campaign.phoneImage}
            pageDes={campaign.pageDes}
            secondaryBtn={campaign.secondaryBtn}
            signUpForm={this.renderForm()}
          />
        )}
        {!campaign && this.renderDefault()}
      </LoginWrapContainer>
    );
  }
}

function mapStateToProps(state, props) {
  const query = queryString.parse(props.location.search);
  const currentUser = (state && state.currentUser) || {};
  const currentPath = props.location.pathname;
  const isLoggedIn = currentUser.isLoggedIn && !currentUser.isLoggingOut;
  const isRootRoute = currentPath === '/';
  const isLoggingIn = isLoggedIn && isRootRoute;

  return {
    loginSignUpReducers: state.loginSignUpReducers,
    campaignName: [].concat(lodashResult(query, ['utm_medium']))[0],
    presetMail: lodashResult(query, ['email'], ''),
    isWebLoginDisabled: selectIsWebLoginDisabled()(state),
    isLoggingIn,
  };
}

HostSignUpFormComponent.propTypes = {
  signUpSaga: PropTypes.func.isRequired,
  hostSignUpErrorReset: PropTypes.func.isRequired,
  presetMail: PropTypes.string.isRequired,
  loginSignUpReducers: PropTypes.shape({
    errors: PropTypes.arrayOf(PropTypes.string),
    submitHasFailed: PropTypes.bool,
  }).isRequired,
  isWebLoginDisabled: PropTypes.bool,
  isLoggingIn: PropTypes.bool.isRequired,
  location: PropTypes.shape({}),
  campaignName: PropTypes.string,
};

HostSignUpFormComponent.defaultProps = {
  isWebLoginDisabled: false,
  campaignName: undefined,
};

export default connect(mapStateToProps, { signUpSaga, hostSignUpErrorReset })(HostSignUpFormComponent);
