import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Modal, SpaceBase, LoadingSplash } from '@properly/components';
import t from '@properly/localization';
import noop from 'lodash/noop';
import * as selectors from '../state/CalendarSelectors';
import { setModalData } from '../state/CalendarActions';

class CalendarModalContainer extends PureComponent {
  state = {
    loading: false,
  };

  txts = {
    txt1: {
      line1: t('calendar.remove_reminder'),
      line2: t('calendar.remove_reminder_info'),
    },
    txt2: {
      line1: t('jobdetails.warning'),
      line2: t('calendar.are_you_sure_cancel'),
    },
  };

  close = () => {
    const { modalData } = this.props;
    const restore = modalData && modalData.get('restore');

    if (restore) {
      restore();
    }

    this.props.dispatch(setModalData({}));
  };

  save = () => {
    this.setState({ loading: true });
    this.props.dispatch(
      this.props.modalData.get('continue')(() => {
        this.setState({ loading: false });
        this.props.dispatch(setModalData({}));
      }, noop),
    );
  };

  modeToTxtObj(mode) {
    switch (mode) {
      case 'skip':
        return this.txts.txt1;
      case 'cancel':
        return this.txts.txt2;
      default:
        return {};
    }
  }

  renderModalContent(txtObj) {
    if (this.state.loading) {
      return (
        <div
          style={{
            height: '180px',
            position: 'relative',
          }}
        >
          <LoadingSplash />
        </div>
      );
    }
    return (
      <div>
        <Modal.H1>{txtObj.line1}</Modal.H1>
        <Modal.Text>{txtObj.line2}</Modal.Text>
        <SpaceBase />
        <Modal.Options>
          <Modal.Cancel onClick={this.close} />
          <Modal.Ok onClick={this.save} />
        </Modal.Options>
      </div>
    );
  }

  render() {
    const showCalendarModal = !!this.props.modalData.get('mode');
    return (
      <Modal id="calendar-modal" height={230} onClose={this.close} show={showCalendarModal}>
        {this.renderModalContent(this.modeToTxtObj(this.props.modalData.get('mode')))}
      </Modal>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    modalData: selectors.selectModalData()(state, props),
  };
}

export default connect(mapStateToProps)(CalendarModalContainer);
