"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isNoTouchDevice = isNoTouchDevice;
exports.isMobileUser = isMobileUser;
exports.isMobileTouch = isMobileTouch;

function isNoTouchDevice() {
  return !('ontouchstart' in document.documentElement);
}

function isMobileUser() {
  return window.window.innerWidth < 1024;
}

function isMobileTouch() {
  return isMobileUser() && !isNoTouchDevice();
}