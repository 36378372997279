import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReportsSidebarContainer from './ReportsSidebarContainer';
import ReportsModalsContainer from './ReportsModalsContainer';
import { MasterDetail, MasterDetailMaster, MasterDetailDetail } from '../../../components/MasterDetail';
import { PagePadding, PageFlexOuter, PageFlexInner } from '../../../components/PageElements';
import ReportsTopBarContainer from './ReportsTopBarContainer';
import { leaveHookSaga, loadReportsRequest } from './ReportsActions';
import MainWrapper from '../../../containers/MainWrapper';
import * as selectors from './ReportsSelectors';

class ReportsContainer extends PureComponent {
  static contextTypes = {
    router: PropTypes.shape({}).isRequired,
  };

  componentWillMount() {
    this.props.loadReports();
  }

  render() {
    const { loading, params, children } = this.props;
    return (
      <div>
        <MainWrapper overWriteLoading={loading} currentPage="reports">
          <MasterDetail>
            <MasterDetailMaster>
              <ReportsSidebarContainer activeId={params.id} />
            </MasterDetailMaster>
            <MasterDetailDetail>
              <PageFlexOuter>
                <ReportsTopBarContainer />
                <PageFlexInner>
                  <PagePadding variation="property" type="absolute-scroll">
                    {!loading && children}
                  </PagePadding>
                </PageFlexInner>
              </PageFlexOuter>
            </MasterDetailDetail>
          </MasterDetail>
        </MainWrapper>
        <ReportsModalsContainer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const loading = selectors.selectReportsForSidebarLoading(state);

  return {
    loading,
  };
}

export default connect(
  mapStateToProps,
  {
    leaveHookSaga,
    loadReports: loadReportsRequest,
  },
)(ReportsContainer);
