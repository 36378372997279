import { fromJS } from 'immutable';

import keys from 'lodash/keys';
import first from 'lodash/first';
import * as types from '../../../types';
import { DEFAULT_SECTIONS, AdminDashboardReportFields, PaymentDetailsReportFields } from './ReportsConstants';

const initState = fromJS({
  modal: { modal: undefined, meta: undefined },
  loadedReport: undefined,
  isLoading: false,
  reportData: {
    // id: { data: [], pointer: '0' }
  },
  section: DEFAULT_SECTIONS,
  sectionsSnapshot: {},
});

export default function ReportsReducer(state = initState, action = {}) {
  switch (action.type) {
    case types.GLOBAL_LOGOUT: // on logout reset
      return initState;
    case types.REPORTS_SET_MODAL:
      return state.setIn(['modal', 'modal'], action.what).setIn(['modal', 'meta'], action.meta);
    case types.REPORTS_SET_VALIDATION:
      return state.setIn(['section', 'title', 'showValidation'], action.val);
    case types.REPORTS_SET_LOADING:
      return state.setIn(['isLoading'], action.val);
    case types.REPORTS_SET_SNAPSHOT:
      return state.setIn(['sectionsSnapshot'], state.get('section'));
    case types.REPORTS_SET_PAYMENT_DETAIL_REPORT_FIELDS: {
      return state.mergeIn(
        ['section', 'columns', 'states', 'paymentdetails', 'children'],
        fromJS(PaymentDetailsReportFields),
      );
    }
    case types.REPORTS_SET_REPORT_DATA: {
      const obj = {};
      if (action.pointer) obj.pointer = action.pointer;
      if (action.data && !action.merge) obj.data = action.data;
      const res = state.mergeIn(['reportData', action.id], fromJS(obj));
      if (!action.merge) return res; // non pagination
      return res.updateIn(['reportData', action.id, 'data'], val => val.concat(fromJS(action.data))); // pagination
    }
    case types.REPORTS_SET_SECTIONS_REPORT:
      return state.setIn(['section'], action.data); // pass ImmutableJS Map
    case types.REPORTS_SET_LOADED_REPORT:
      return state.setIn(['loadedReport'], action.id);
    case types.REPORTS_SET_OPEN_SECTION:
      return state.setIn(['section', action.where, 'isOpen'], action.val);
    case types.REPORTS_SET_SELECTED_ALL:
      return state.setIn(['section', action.where, 'isAll'], action.val);
    case types.REPORTS_SET_TOUCHED:
      return state.setIn(['section', action.where, 'isTouched'], action.val);
    case types.REPORTS_MERGE_SECTION:
      return state.mergeIn(['section', action.where], fromJS(action.data));
    case types.REPORTS_SET_TOGGLE_VALUE_SECTION:
      return state.updateIn(action.path, val => {
        if (val === undefined) return true;
        return !val;
      });
    case types.REPORTS_SET_VALUE_SECTION:
      return state.setIn(['section', action.where, 'value'], action.val);
    case types.REPORTS_SET_SELECTED_ELES: {
      const firstKey = first(keys(action.eleObj));
      if (action.toggle && state.getIn(['section', action.where, 'selected', firstKey])) {
        return state.deleteIn(['section', action.where, 'selected', firstKey]);
      }
      if (action.flush) {
        return state.setIn(['section', action.where, 'selected'], fromJS(action.eleObj));
      }
      return state.mergeIn(['section', action.where, 'selected'], fromJS(action.eleObj));
    }
    case types.REPORTS_SET_ADMINMODE_REPORT_FIELDS: {
      return state
        .mergeIn(['section', 'columns', 'states', 'property', 'children'], fromJS(AdminDashboardReportFields))
        .mergeIn(['section', 'columns', 'selected'], fromJS(AdminDashboardReportFields));
    }

    case types.REPORTS_RESET_ADMINMODE_REPORT_FIELDS: {
      const isAdminFieldFoundExistingReport = Object.keys(AdminDashboardReportFields).some(
        field =>
          state.hasIn(['section', 'columns', 'selected', field]) ||
          state.hasIn(['section', 'columns', 'states', 'property', 'children', field]),
      );

      if (isAdminFieldFoundExistingReport) {
        let updatedState;
        Object.keys(AdminDashboardReportFields).forEach(field => {
          updatedState = state.deleteIn(['section', 'columns', 'selected', field]);
          updatedState = updatedState.deleteIn(['section', 'columns', 'states', 'property', 'children', field]);
        });
        return updatedState;
      }
      return state;
    }

    default:
      return state;
  }
}
