import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import t from '@properly/localization';
import reduce from 'lodash/reduce';
import keys from 'lodash/keys';
import filter from 'lodash/filter';
import pick from 'lodash/pick';
import indexOf from 'lodash/indexOf';
import flatten from 'lodash/flatten';
import find from 'lodash/find';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import isObject from 'lodash/isObject';
import extend from 'lodash/extend';
import update from 'ramda/src/update';
import clone from 'ramda/src/clone';
import omit from 'lodash/omit';
import log from 'loglevel';
import * as types from '../../../../types/index';
import * as selectors from './ChecklistSelectors';
import {
  goToChecklist,
  setCloneChecklist,
  setCurrentJob,
  setCurrentJobIns,
  setJobInstruction,
  setLoadingState,
  setModalObj,
  setState,
  setUploadState,
  updateChecklistGlobal,
  updateChecklistSaga,
  updateLastUpdatedTime,
  updatePropertyGlobal,
  copyChecklistToLibraryFailure,
  copyChecklistToLibrarySuccess,
} from './ChecklistActions';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import {
  cloneJobAndJobInstruction,
  createJobAndJobInstruction,
  generateToken,
  uploadImage,
  updateParseJob,
  updateParseJobInstructions,
  updateParseProperty,
} from '../../data';
import {
  getIndependentChecklist,
  getIndependentChecklistInstructions,
  copyChecklistIntoLibrary,
  getParseJobSingle,
  getParseJobInstruction,
} from '../../data.http.checklists';
import {
  setModal,
  updateCollectionEntry,
  updateCollectionEntryBatch,
  setGlobalLoadingState,
} from '../../../../actions/globalActions';
import {
  trackChecklistAddSlide,
  trackChecklistCreated,
  trackChecklistDelete,
  trackChecklistDeleteSlide,
  trackChecklistDupliSlide,
  trackDuplicateChecklist,
} from '../../../../actions/trackingEvents';
import { mediaUploadLoadingKey } from '../../../../dataConstants';

function* addImageToProperty(action) {
  const { pictureUrl, propertyId } = action;
  const selector = state => selectorsGlobal.selectProperty(propertyId)(state);
  const property = yield select(selector);
  const defaultifyPicUrls = property.pictureUrls || [];
  const newArray = [pictureUrl, ...defaultifyPicUrls];
  try {
    const res = yield call(updateParseProperty, propertyId, {
      pictureUrls: newArray,
    });
    yield put(updatePropertyGlobal(propertyId, res));
  } catch (e) {
    log.error('addImageToProperty', e);
  }
}

function* sendJobInsToBackend(id, data) {
  try {
    yield call(updateParseJobInstructions, id, data);
    yield put(updateLastUpdatedTime(new Date()));
  } catch (e) {
    log.error('sendJobInsToBackend', e);
  }
}

function* updateStepsAndTasksRaw(data, jobId) {
  const stepsCount = data.steps && data.steps.length ? data.steps.length : 0;
  const taskCount = reduce(
    data.steps,
    (sum, step) => {
      const tasks = step.tasks && step.tasks.length ? step.tasks.length : 0;
      const verification = step.isVerificationNeeded ? 1 : 0;
      return sum + tasks + verification;
    },
    0,
  );
  const totalVerificationCount = reduce(
    data.steps,
    (sum, step) => {
      if (step.isVerificationNeeded) {
        return sum + 1;
      }
      return sum;
    },
    0,
  );
  try {
    const resJob = yield call(updateParseJob, jobId, {
      totalStepCount: stepsCount,
      totalTaskCount: taskCount,
      totalVerificationCount,
    });
    yield put(updateChecklistGlobal(jobId, resJob));
  } catch (e) {
    log.error('updateStepsAndTasksRaw - trycatch', e);
  }
}

function* updateStepsAndTasksBackend(data) {
  const selectJobId = state => selectors.selectCurrentJobId()(state);
  const jobId = yield select(selectJobId);
  if (jobId) {
    yield updateStepsAndTasksRaw(data, jobId);
  } else {
    log.error('updateStepsAndTasksBackend - no jobid');
  }
}

function* updateJobInsAsync(id, data) {
  yield put(setJobInstruction(id, data));
  yield fork(sendJobInsToBackend, id, data);
  yield fork(updateStepsAndTasksBackend, data);
}

function* loadOrChillChecklistDetail(action) {
  yield put(setState(1));

  // always load fresh from server
  try {
    const { id, jobId, isIndependent } = action;

    const { jobInstruction, job } = yield getChecklistData(jobId, id, isIndependent);

    log.info('loadOrChillChecklistDetail', { jobInstruction, job });

    yield put(setState(0));
    yield put(updateCollectionEntryBatch('jobs', { [job.objectId]: job }, true)); // store local storage
    yield put(setJobInstruction(jobInstruction.objectId, jobInstruction)); // that needs to go in the local store only
    yield put(setCurrentJobIns(jobInstruction.objectId, true));
    yield put(setCurrentJob(jobId));
  } catch (e) {
    log.error('loadOrChillChecklistDetail', e);
    yield put(setState(2));
  }
}

function* getChecklistData(checklistId, checklistInstructionsId, isIndependent) {
  const [jobInstruction, job] = yield isIndependent
    ? [call(getIndependentChecklistInstructions, checklistInstructionsId), call(getIndependentChecklist, checklistId)]
    : [call(getParseJobInstruction, checklistInstructionsId), call(getParseJobSingle, checklistId)];
  return { jobInstruction, job };
}

function* getChecklistInstructionsData(checklistInstructionsId, isIndependent) {
  return yield isIndependent
    ? call(getIndependentChecklistInstructions, checklistInstructionsId)
    : call(getParseJobInstruction, checklistInstructionsId);
}

function* uploadSlidePicture({ index, image }) {
  log.info('ChecklistSagas - uploadSlidePicture', index, image);
  try {
    const response = yield call(uploadImage, image);
    const { pictureUrl } = response;

    const selectorChecklistReducer = state => selectors.selectChecklistsJS()(state);
    const checklistStatus = yield select(selectorChecklistReducer);
    const jobInstructions = checklistStatus.jobInstructions[checklistStatus.currentJobInstructionId];

    const merged = placeAfterIndex(
      jobInstructions.steps,
      [
        {
          ...genBaseStep(),
          pictureUrl,
        },
      ],
      index,
    );
    trackChecklistAddSlide();

    yield fork(updateJobInsAsync, checklistStatus.currentJobInstructionId, {
      steps: merged,
    });
  } catch (error) {
    log.error('Error uploading slide picture', error);
    yield put(setGlobalLoadingState(mediaUploadLoadingKey, 3));
  }
}

function* uploadTaskPicture({ stepId, taskIndex, image }) {
  const resReducer = yield select(selectors.selectChecklistsJS());
  const jobIns = resReducer.jobInstructions[resReducer.currentJobInstructionId];

  let merged = [];
  try {
    const response = yield call(uploadImage, image);
    const { pictureUrl } = response;

    merged = yield updateTaskSingle(jobIns.steps, stepId, taskIndex, {
      pictureUrls: [pictureUrl],
    });
  } catch (error) {
    log.error('Error uploading task picture', error);
    yield put(setGlobalLoadingState(mediaUploadLoadingKey, 3));
  }

  yield fork(updateJobInsAsync, resReducer.currentJobInstructionId, {
    steps: merged,
  });
}

function* uploadPropertyPicture(image, propertyId) {
  log.info('ChecklistSagas - uploadPropertyPic', image);
  try {
    const res = yield call(uploadImage, image);

    log.info('uploadPic - res', res);
    yield addImageToProperty({
      pictureUrl: res.pictureUrl,
      propertyId,
    });
    yield put(
      setUploadState(
        {
          uploading: {
            [image.name]: false,
          },
        },
        true,
      ),
    );
  } catch (e) {
    log.error('uploadPic - error', e);
    yield put(
      setUploadState(
        {
          uploading: {
            [image.name]: false,
          },
        },
        true,
      ),
    );
    yield put(setGlobalLoadingState(mediaUploadLoadingKey, 3));
  }
}

function* uploadPics(action) {
  const { pics } = action;
  let i = 0;
  let b = 0;
  if (!pics || !pics[0]) return;

  // mark all as loading
  while (b < pics.length) {
    const item = pics[b];
    log.info('uploadPics - set pic uploading', item);
    yield put(
      setUploadState(
        {
          uploading: {
            [item.name]: item,
          },
        },
        true,
      ),
    );
    b += 1;
  }

  // upload one by one
  while (i < pics.length) {
    const item = pics[i];
    log.info('uploadPics - upload', item);
    yield uploadPropertyPicture(item, action.propertyId);
    i += 1;
  }
}

function genBaseStep() {
  return {
    isVerificationNeeded: false,
    node: '',
    objectId: generateToken(10),
    pictureUrl: undefined,
    room: null,
    section: '',
    tasks: [],
    title: '',
  };
}

function* closeUploadModal() {
  yield put(
    setUploadState(
      {
        isOpen: false,
        do: {},
        selected: [],
      },
      true,
    ),
  );
}

// eslint-disable-next-line
function* updateStepSingle(steps, stepId, changeSet) {
  // eslint-disable-line
  const stepToUpdate = findIndex(steps, { objectId: stepId });
  if (stepToUpdate === -1) {
    log.error('updateStepSingle - step not found', stepId, steps);
    return steps;
  }
  const cloned = steps.slice(0);
  cloned[stepToUpdate] = {
    ...cloned[stepToUpdate],
    ...changeSet,
  };
  return cloned;
}

// eslint-disable-next-line
function* updateTaskSingle(steps, stepId, taskIndex, changeSet) {
  const stepToUpdate = findIndex(steps, { objectId: stepId });
  if (stepToUpdate === -1) {
    log.error('updateTaskSingle - step not found', stepId, steps);
    return steps;
  }
  const cloned = steps.slice(0);
  const task = cloned[stepToUpdate].tasks[taskIndex];
  if (!task) {
    log.error('updateTaskSingle - task not found', stepId, steps, taskIndex);
    return steps;
  }
  cloned[stepToUpdate].tasks[taskIndex] = {
    ...cloned[stepToUpdate].tasks[taskIndex],
    ...changeSet,
  };
  return cloned;
}

function* setUpdatePicture() {
  const selector = state => selectors.selectUploadState()(state);
  const selectorChecklistReducer = state => selectors.selectChecklistsJS()(state);
  const res = yield select(selector);
  const resReducer = yield select(selectorChecklistReducer);
  const jobIns = resReducer.jobInstructions[resReducer.currentJobInstructionId];
  log.info('setUpdatePicture - res', res);
  log.info('setUpdatePicture - jobIns', jobIns);

  // if nothing is set kill early
  if (!res || !res.do || !res.do.mode || !jobIns) {
    log.error('setUpdatePicture - no mode set or jobins undefined');
    yield closeUploadModal();
  }

  let merged = [];
  let needsFlush = false;
  const mappedSteps = map(res.selected, pic => ({
    ...genBaseStep(),
    pictureUrl: pic,
  }));

  log.info('setUpdatePicture - mapped', mappedSteps);
  if (res.do.mode === 'addEnd') {
    merged = flatten([jobIns.steps, mappedSteps]);
    needsFlush = true;
    trackChecklistAddSlide();
  }
  if (res.do.mode === 'new') {
    merged = placeAfterIndex(jobIns.steps, mappedSteps, res.do.after);
    needsFlush = true;
    trackChecklistAddSlide();
  }
  if (res.do.mode === 'replace' && res.selected.length > 0) {
    merged = yield updateStepSingle(jobIns.steps, res.do.stepId, {
      pictureUrl: res.selected[0],
    });
    needsFlush = true;
  }
  if (res.do.mode === 'replacetaskimg' && res.selected.length > 0) {
    merged = yield updateTaskSingle(jobIns.steps, res.do.stepId, res.do.taskIndex, {
      pictureUrls: [res.selected[0]],
    });
    needsFlush = true;
  }

  // publish it
  //
  // TODO: use stepmutate sage
  log.info('setUpdatePicture - merge', merged);
  if (needsFlush) {
    yield fork(updateJobInsAsync, resReducer.currentJobInstructionId, {
      steps: merged,
    });
  }
  yield closeUploadModal();
}

const newOrAddExisting = input => {
  if (!input) {
    // new step
    return [genBaseStep()];
  }
  return input;
};

const selectJobInstructions = selectors.selectJobInstructions();

function* stepMutate(action) {
  const jobInstructions = yield select(selectJobInstructions);
  const jobInstructionsJS = jobInstructions.toJS();
  const { meta } = action || {};
  const { jobInstructionId: jobInstructionsId } = meta || {};
  const { steps } = jobInstructionsJS[jobInstructionsId] || {};

  if (action.action === 'addEnd') {
    const finalSteps = newOrAddExisting(meta.steps);
    yield updateJobInsAsync(jobInstructionsId, {
      steps: flatten([steps, finalSteps]),
    });
    trackChecklistAddSlide();
  }
  if (action.action === 'update') {
    const cloned = yield updateStepSingle(steps, meta.stepId, meta.changeSet);
    yield updateJobInsAsync(jobInstructionsId, {
      steps: cloned,
    });
  }
  if (action.action === 'moveStep') {
    yield updateJobInsAsync(jobInstructionsId, {
      steps: arraymove(steps.slice(0), meta.fromIndex, meta.toIndex),
    });
  }
  if (action.action === 'setBetween') {
    log.info('stepMutate - setBetween', action);
    const finalSteps = newOrAddExisting(meta.steps);
    yield updateJobInsAsync(jobInstructionsId, {
      steps: placeAfterIndex(steps.slice(0), finalSteps, meta.index),
    });
    trackChecklistAddSlide();
  }
  if (action.action === 'duplicateStep') {
    const addOns = map(meta.steps, id => {
      const res = find(steps, { objectId: id });
      return {
        ...res,
        objectId: generateToken(10),
      };
    });
    let stepsDuplicate = [];
    if (meta.index !== undefined) {
      stepsDuplicate = placeAfterIndex(steps.slice(0), addOns, meta.index);
    } else {
      stepsDuplicate = flatten([steps, addOns]);
    }
    yield updateJobInsAsync(jobInstructionsId, {
      steps: stepsDuplicate,
    });
    trackChecklistDupliSlide();
  }
  if (action.action === 'deleteStep') {
    const stepKeys = isObject(meta.deleteSteps) ? keys(meta.deleteSteps) : meta.deleteSteps;
    yield updateJobInsAsync(jobInstructionsId, {
      steps: filter(steps, step => indexOf(stepKeys, step.objectId) === -1),
    });
    trackChecklistDeleteSlide();
  }
}

function* taskMutate(action) {
  // This part needs to be refactored, because it is mutating the state - this should be done in the reducer
  // +1
  const selectJbIns = state => selectors.selectJobInstruction(action.jobInstructionId)(state);
  const jobInstructions = yield select(selectJbIns);
  const steps = clone(jobInstructions.steps);
  if (!jobInstructions) {
    log.error('taskMutate - jobins not found', action.jobInstructionId);
    return;
  }
  const stepToUpdate = findIndex(steps, { objectId: action.stepId });
  if (stepToUpdate === -1) {
    log.error('taskMutate - step not found', action.stepId);
    return;
  }
  const cloned = clone(steps[stepToUpdate].tasks);
  let baseTasks = [];
  let needsFlush = false;
  // TODO - refactor all these if for actions to an action mapper with immutable simple functions
  if (action.action === 'moveStep') {
    baseTasks = arraymove(cloned, action.meta.fromIndex, action.meta.toIndex);
    needsFlush = true;
  }
  if (action.action === 'delete') {
    cloned.splice(action.meta.index, 1);
    baseTasks = cloned;
    needsFlush = true;
  }
  if (action.action === 'addimage') {
    cloned.push({
      objectId: generateToken(10),
      note: '',
      icon: 'custom',
      pictureUrls: [],
      centerX: 0.5,
      centerY: 0.5,
      ...action.meta.changeSet,
    });
    baseTasks = cloned;
    needsFlush = true;
  }
  if (action.action === 'update') {
    // guard in case something goes wrong
    if (!cloned[action.meta.index]) {
      log.error('taskMutate - updateindex not found');
      return;
    }
    // Changes because this is a mutation that should only happen
    // in the reducer level
    const result = update(
      action.meta.index,
      {
        ...cloned[action.meta.index],
        ...action.meta.changeSet,
      },
      cloned,
    );
    baseTasks = clone(result);
    needsFlush = true;
  }
  if (action.action === 'add') {
    cloned.push({
      objectId: generateToken(10),
      icon: 'other',
      note: '',
      ...action.meta.changeSet,
    });
    baseTasks = cloned;
    needsFlush = true;
  }
  if (needsFlush) {
    steps[stepToUpdate].tasks = clone(baseTasks);
    // write
    yield updateJobInsAsync(action.jobInstructionId, {
      steps: clone(steps),
    });
  }
}

function* createChecklistForProperty(action) {
  const { propertyId } = action || {};

  try {
    yield put(setLoadingState(`create${propertyId}`, 1));
    const res = yield call(createJobAndJobInstruction, propertyId, action.title, action.user, action.role);
    log.info('res - createJobAndJobInstruction', res);
    yield put(updateChecklistGlobal(res.objectId, res));
    if (!action.openInModal) {
      yield put(goToChecklist(res.objectId, res.jobInstructionId, action.redirectUrl));
    } else {
      yield put(
        setModalObj({
          isOpen: true,
          meta: { checklistId: res.objectId, jobInstructionId: res.jobInstructionId },
        }),
      );
    }
    yield put(setLoadingState(`create${propertyId}`, 0));
    trackChecklistCreated(res.objectId);
  } catch (e) {
    yield put(setLoadingState(`create${propertyId}`, 0));
    log.error('createChecklist', e);
  }
}

function* modalCloseSaga() {
  yield put(setModalObj({ isOpen: false, meta: {} }));
}

function* updateChecklist(action) {
  const checklistId = action.checklist.objectId;
  if (action.action === 'delete') {
    try {
      yield put(setLoadingState(checklistId, 1));
      const resJob = yield call(updateParseJob, checklistId, {
        deleted: true,
      });
      yield put(updateChecklistGlobal(checklistId, resJob));
      yield put(setLoadingState(checklistId, 0));
      trackChecklistDelete(checklistId);
    } catch (e) {
      yield put(setLoadingState(checklistId, 0));
      log.error('updateChecklist - delete', e);
    }
  }
  if (action.action === 'duplicate') {
    try {
      yield put(setLoadingState(checklistId, 1));
      const jobInstructions = yield getChecklistInstructionsData(action.checklist.jobInstructionId);
      const rawWithOutIdJobIns = pick(jobInstructions.getData(), ['steps']);
      const rawJob = omit(action.checklist.getData(), ['objectId', 'defaultSettings']);
      rawJob.title = t('checklist.copy_checklist', { name: action.checklist.title });
      const newJob = yield call(cloneJobAndJobInstruction, rawJob, rawWithOutIdJobIns);
      log.info('res2 - updateChecklist', newJob);
      yield put(updateChecklistGlobal(newJob.objectId, newJob));
      yield put(setLoadingState(checklistId, 0));
    } catch (e) {
      yield put(setLoadingState(checklistId, 0));
      log.error('updateChecklist - duplicate', e);
    }
  }
  if (action.action === 'update') {
    try {
      const resJob = yield call(updateParseJob, checklistId, action.checklist);
      yield put(updateChecklistGlobal(checklistId, resJob));
    } catch (e) {
      log.error('updateChecklist - update', e);
    }
  }
}

function* cloneOneChecklist(jobIns, checklist, changeSet) {
  const jobInstructionData = jobIns && jobIns.getData ? jobIns.getData() : jobIns;
  // trim job instruction
  const rawWithOutIdJobIns = pick(jobInstructionData, ['steps']);

  // get checklist raw object
  const checklistData = checklist && checklist.getData ? checklist.getData() : checklist;
  let rawJob = pick(checklistData, [
    'defaultProperty',
    'jobDescription',
    'jobInstructionId',
    'jobInstructions',
    'title',
    'totalStepCount',
    'totalTaskCount',
    'totalVerificationCount',
    'user',
  ]);
  rawJob = extend({}, rawJob, changeSet);

  const newJob = yield call(cloneJobAndJobInstruction, rawJob, rawWithOutIdJobIns);
  log.info('cloneOneChecklist', rawJob, rawWithOutIdJobIns);
  yield put(updateChecklistGlobal(newJob.objectId, newJob));
}

function* cloneIsInProgress() {
  const selectClone = state => selectors.selectCloneChecklist(state);
  const res = yield select(selectClone);
  return res.toJS().mode === 1;
}

function* batchCloneChecklist(jobIns, cloneChecklist, propertyInfoArray) {
  const MAX_CONCURRENCY = 10;
  let index = 0;

  function* cloneSingle(propertyInfo) {
    log.info('multiCloneSaga - set clone to ', propertyInfo);
    yield cloneOneChecklist(jobIns, cloneChecklist, propertyInfo);
  }

  while (index < propertyInfoArray.length) {
    const stillInProgress = yield cloneIsInProgress();
    if (!stillInProgress) {
      log.info('multiCloneSaga - abort');
      return;
    }
    yield propertyInfoArray.slice(index, index + MAX_CONCURRENCY).map(cloneSingle);
    index += MAX_CONCURRENCY;
  }
}

function* multiCloneSaga(action) {
  const { propertyIdArray, independent, checklistId, title } = action;

  yield put(setCloneChecklist({ mode: 1 }));

  const selectJob = state => selectorsGlobal.selectJobs()(state);
  const res = yield select(selectJob);
  const cloneChecklist = res.toJS()[checklistId];

  const cloneChecklistTitle = title || (cloneChecklist && cloneChecklist.title);

  const propertyInfoArray = map(propertyIdArray, propertyId => ({
    title: cloneChecklistTitle,
    defaultProperty: propertyId,
  }));

  if (cloneChecklist) {
    try {
      log.info('multiCloneSaga getChecklistInstructionsData cloneChecklist', cloneChecklist, { independent });

      const jobInstructionsId = cloneChecklist.jobInstructionId || cloneChecklist.jobInstructions.objectId;

      if (cloneChecklist.jobInstructions) {
        // If we already have the job instructions
        yield batchCloneChecklist(cloneChecklist.jobInstructions, cloneChecklist, propertyInfoArray);
      } else {
        // Fetch the instructions if they are not already there
        const jobInstructions = yield getChecklistInstructionsData(jobInstructionsId, independent);
        yield batchCloneChecklist(jobInstructions, cloneChecklist, propertyInfoArray);
      }
    } catch (e) {
      log.error('multiCloneSaga - error', e);
    }
  } else {
    log.error('multiCloneSaga - checklist not found', checklistId);
  }

  // close
  yield put(
    setCloneChecklist({
      checklistTitle: '',
      isOpen: false,
      mode: 0,
      selectedChecklistId: undefined,
      selected: [],
    }),
  );
  trackDuplicateChecklist();
}

function isDefault(job, property) {
  return property.defaultJob === job.objectId;
}

function arraymove(arr, fromIndex, toIndex) {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
}

function placeAfterIndex(arrayMain, arrayAdd, index) {
  const array = arrayMain.slice(0);
  const part1 = array.slice(0, index + 1);
  const part2 = array.slice(index + 1);
  return [...part1, ...arrayAdd, ...part2];
}

function* deleteChecklistSaga(action) {
  // do we need to show modal or not
  if (isDefault(action.checklist, action.property)) {
    yield put(setModal(true, 'checklistdeletewarningmodal', pick(action, ['checklist', 'property'])));
  } else {
    yield put(updateChecklistSaga('delete', action.checklist));
  }
}

function* setWarningModalSaga(action) {
  if (action.answer) {
    const warningModalData = yield select(selectorsGlobal.selectGlobalModalResolved(action.name));
    const { property, checklist } = warningModalData.meta;
    try {
      yield put(setModal(false, action.name, {}));
      const propertyRes = yield call(updateParseProperty, property.objectId, {
        defaultJob: null,
      });

      yield put(updateCollectionEntry('properties', propertyRes.objectId, propertyRes));
      yield put(updateChecklistSaga('delete', checklist));
    } catch (e) {
      log.error('setWarningModalSaga', e);
    }
  } else {
    yield put(setModal(false, action.name, {}));
  }
}

function* copyChecklistToLibraryRequest({ checklistId }) {
  try {
    yield copyChecklistIntoLibrary(checklistId);
    yield put(copyChecklistToLibrarySuccess(checklistId));
  } catch (err) {
    yield put(copyChecklistToLibraryFailure(checklistId, err));
  }
}

function* saga() {
  yield fork(takeEvery, types.CHECKLIST_DETAIL_HIT, loadOrChillChecklistDetail);
  yield fork(takeEvery, types.CHECKLIST_STEP_MUTATOR_SAGA, stepMutate);
  yield fork(takeEvery, types.CHECKLIST_TASK_MUTATOR_SAGA, taskMutate);
  yield fork(takeEvery, types.CHECKLIST_UPLOAD_PICS_SAGA, uploadPics);
  yield fork(takeEvery, types.CHECKLIST_CREATE_CHECKLIST_SAGA, createChecklistForProperty);
  yield fork(takeEvery, types.CHECKLIST_UPDATE_CHECKLIST_SAGA, updateChecklist);
  yield fork(takeEvery, types.CHECKLIST_SET_OR_UPDATE_PICTURE_SAGA, setUpdatePicture);
  yield fork(takeEvery, types.CHECKLIST_SET_MODAL_CLOSE, modalCloseSaga);
  yield fork(takeEvery, types.CHECKLIST_ADD_IMAGE_PROPERTY_SAGA, addImageToProperty);
  yield fork(takeEvery, types.CHECKLIST_MULTI_CLONE, multiCloneSaga);
  yield fork(takeEvery, types.CHECKLIST_DELETE_SAGA, deleteChecklistSaga);
  yield fork(takeEvery, types.CHECKLIST_DELETE_WARNING_SAGA, setWarningModalSaga);
  yield fork(takeEvery, types.CHECKLIST_COPY_CHECKLIST_TO_LIBRARY_REQUEST, copyChecklistToLibraryRequest);
  yield fork(takeEvery, types.CHECKLIST_DIRECT_SLIDE_IMAGE_UPLOAD, uploadSlidePicture);
  yield fork(takeEvery, types.CHECKLIST_DIRECT_TASK_IMAGE_UPLOAD, uploadTaskPicture);
}

export default saga;
