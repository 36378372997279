import gql from 'graphql-tag';

export const QUERY_GET_FEATURE_FLAGS_FOR_USER = gql`
  query getFeatureFlagsForUserQuery {
    getFeatureFlagsForUser {
      name
      enabled
    }
  }
`;

export const QUERY_IS_FEATURE_FLAG_ENABLED_FOR_USER = gql`
  query isFeatureFlagEnabledForUserQuery($featureName: String!) {
    isFeatureFlagEnabledForUser(featureName: $featureName)
  }
`;
