"use strict";

const {
  MDText
} = require('i18n-react');

const englishTranslations = require('./languages/en.json');

const detectLanguage = require('./detectLanguage');

const defaultLanguage = 'en';
const fallback = new MDText(englishTranslations);
const availableLanguages = ['en', 'de', 'es', 'fr', 'it'];

function localizationService({
  mappingLangFn
}) {
  let translations = null;

  function isFunction(functionToCheck) {
    const getType = {};
    return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
  }

  function isTranslations(translationsObject) {
    return translationsObject && typeof translationsObject === 'object' && translationsObject.signup;
  }

  function initLocalization(locale = detectLanguage(), complete = () => {}, overrideMappingLangFn) {
    const mappingLangImport = overrideMappingLangFn ? overrideMappingLangFn(availableLanguages) : mappingLangFn(availableLanguages);

    if (!mappingLangImport) {
      throw new Error('No mapping function passed to init. Try using the main @properly/localization package');
    }

    const language = detectLanguage(locale);
    const importProp = mappingLangImport[language]; // If it is the default language then just use the fallback and dont bother loading

    const isDefault = language === defaultLanguage;

    if (isDefault) {
      return complete({
        type: 'Default',
        language,
        translations
      });
    }

    if (importProp && importProp.then) {
      return importProp.then(importData => {
        translations = new MDText(importData);
        return complete({
          type: 'Success Promise',
          language,
          translations
        });
      });
    }

    if (isFunction(importProp)) {
      return importProp(importData => {
        translations = new MDText(importData);
        return complete({
          type: 'Success Callback',
          language,
          translations
        });
      });
    }

    if (isTranslations(importProp)) {
      translations = new MDText(importProp);
      return complete({
        type: 'Success Direct',
        language,
        translations
      });
    }

    return complete({
      type: 'Failed Fallback',
      importProp,
      language,
      translations
    });
  }

  function recordKeyUse(key, translation) {
    if (typeof window !== 'undefined') {
      window.localizationKeys = window.localizationKeys || {};
      window.localizationKeys[key] = translation;
    }
  }

  function getTranslation(key, options) {
    if (translations) {
      return translations.translate(key, Object.assign({}, options, {
        notFound: fallback.translate(key, options)
      }));
    }

    return fallback.translate(key, Object.assign({}, options, {
      notFound: key
    }));
  }

  function t(key, options) {
    const translation = getTranslation(key, options);

    if (process.env.NODE_ENV === 'development') {
      // Record key so we can detect where it is used
      recordKeyUse(key, translation);
    }

    return translation;
  }

  t.initLocalization = initLocalization;
  t.detectLanguage = detectLanguage;
  return t;
}

module.exports = localizationService;