import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import t, { mapServiceIdToLabel } from '@properly/localization';
import {
  Tag,
  SpaceSmall,
  SpaceBase,
  SpaceMedium,
  SpaceTiny,
  SpaceFlexi,
  Small,
  Title2,
  Button,
  Icon,
  ProfilePicture,
} from '@properly/components';
import moment from 'moment-timezone';
import lodashResult from 'lodash/result';
import map from 'lodash/map';
import classNames from 'classnames/bind';
import styles from './index.module.css';
import HeadBar from '../../components/HeadBar';
import { getInitials } from '../../helper/herbert';
import ContactImageGrid from '../../components/ContactImageGrid';
import { trackJobViewSuggested, trackJobViewSuggestedConfirm } from '../../actions/trackingEvents';
import Profile from '../../model/profile';
import { setSliderSaga } from '../../actions/globalActions';
import { selectConfig } from '../../selectors/globalSelector';
import { JobRequestTypicalTime, JobRequestBadges, reduceBadges } from '../../components/JobRequestElements';
import ProBadge from '../../components/ProBadge';

const cx = classNames.bind(styles);

function WrapTag({ isEventTag, children }) {
  return (
    <div className={cx('job-request__tag')}>
      <Tag isEventTag={isEventTag}>{children}</Tag>
    </div>
  );
}

class JobRequestServicePro extends Component {
  componentDidMount() {
    trackJobViewSuggested();
  }

  get isProfile() {
    const { contact } = this.props;
    return contact instanceof Profile;
  }

  get resolvedContact() {
    const { contact } = this.props;
    if (this.isProfile) return contact;
    return {};
  }

  handleOpenSlider(images) {
    return index => {
      const mappedImages = map(images, image => ({
        image,
      }));
      this.props.setSliderSaga(true, mappedImages, index);
    };
  }

  render() {
    const { onSelect, config, onBack, showButton, proMarketProperty } = this.props;
    const topText = this.isProfile ? t('job_request.service_pro') : t('job_request.service_pro_contact');
    const contact = this.resolvedContact;
    const badgeArray = reduceBadges(contact.badges, lodashResult(config, ['badgeOrder']), 'big');
    let inner = (
      <div className={cx('job-request__community-detail')}>
        <div className={cx('job-request__community-detail-top')}>
          <div className={cx('job-request__community-detail-info')}>
            <div className={cx('job-request__community-detail-top')}>
              <div className={cx('job-request__community-detail-top')}>
                <Title2 type="nomargin">{`${contact.userData.firstName} ${contact.userData.lastName}`}</Title2>
                {contact?.getProStatus?.() && proMarketProperty && (
                  <div className={cx('job-request__properlypro')}>
                    <ProBadge modalRequired />
                  </div>
                )}
              </div>
            </div>
            <div>
              <Small type="grey">
                {t('job_request.joined')} {moment(contact.userData.signedUpDate).format('MMM Do, YYYY')}
              </Small>
            </div>
            <SpaceTiny />
            {(contact.offeredServices || []).map(mapServiceIdToLabel).map(label => (
              <WrapTag key={label}>{label}</WrapTag>
            ))}
            <SpaceSmall />
            <JobRequestTypicalTime size="big" responseTime={contact.responseTime} />
            <SpaceFlexi type="vertical" size="15px" />
            <JobRequestBadges size="big" badges={badgeArray} />
            {showButton && (
              <div>
                <SpaceBase />
                <Button
                  data-key="button_select_serviceprovider"
                  onClick={() => {
                    onSelect(this.props.contact);
                    trackJobViewSuggestedConfirm();
                  }}
                  types={['type-full-primary', 'size-large']}
                >
                  {t('job_request.select_service_pro')}
                </Button>
              </div>
            )}
            <SpaceMedium />
            {contact.userData.bio && (
              <div>
                <div>
                  <Small type="grey">{t('job_request.about_user')}:</Small>
                </div>
                <div>
                  <div className={cx('job-request__userbio')}>{contact.userData.bio}</div>
                </div>
                <SpaceMedium />
              </div>
            )}
            {contact.propertyPhotos && (
              <div>
                <div>
                  <Small type="grey">
                    {t('job_request.worked_at', {
                      num: contact.numOfProperties,
                    })}
                  </Small>
                </div>
                <SpaceSmall />
                <ContactImageGrid
                  onClick={this.handleOpenSlider(contact.propertyPhotos)}
                  images={contact.propertyPhotos}
                />
              </div>
            )}
          </div>
          <div className={cx('job-request__community-detail-pic')}>
            <ProfilePicture
              type="square"
              text={getInitials(contact.userData.firstName, contact.userData.lastName)}
              picture={contact.userData.pictureUrl}
            />
          </div>
        </div>
      </div>
    );
    if (!contact) inner = null;
    return (
      <div className={cx('job-request__full')}>
        <HeadBar icon={Icon.IcCloseCc} onClose={() => onBack()} middleText={topText} />
        {inner}
      </div>
    );
  }
}

JobRequestServicePro.defaultProps = {
  showButton: true,
};

JobRequestServicePro.propTypes = {
  contact: PropTypes.instanceOf(Profile).isRequired,
  onSelect: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  showButton: PropTypes.bool,
  config: PropTypes.shape.isRequired,
  setSliderSaga: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    config: selectConfig(state, props),
  };
}

export default connect(mapStateToProps, {
  setSliderSaga,
})(JobRequestServicePro);
