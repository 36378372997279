import log from 'loglevel';

export const recurlyInvoiceUrl = (id, token) => `https://properly.recurly.com/account/invoices/${id}/?ht=${token}`;
export const recurlyEditSubscription = token => `https://properly.recurly.com/account/billing_info/edit?ht=${token}`;
export const recurlyUpgrade = (planId, userId, firstname, lastname, email, qty) =>
  `https://properly.recurly.com/subscribe/${planId}/${userId}/?first_name=${encodeURIComponent(
    firstname,
  )}&last_name=${encodeURIComponent(lastname)}&email=${encodeURIComponent(email)}&quantity=${qty}`;

export const AppStoreUrls = {
  ios: 'https://itunes.apple.com/app/properly-visual-checklist/id1032619209?mt=8',
  android: 'https://play.google.com/store/apps/details?id=com.getproperly.properlyv2',
};

export const prefilledDurationNewJobRequest = '60';

export const prefilledJobRequestStartTime = { hour: '10', minute: '00' };

export const offeredPriceLimit = 1000000;

export const helpCenterUrl = 'https://help.getproperly.com';

log.info('config', module.exports);
