import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { DragLayer } from 'react-dnd';
import ChecklistImageListItem from '../../../../components/ChecklistImageListItem/index';

function getItemStyles(currentOffset) {
  if (!currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;

  return {
    pointerEvents: 'none',
    transform,
    WebkitTransform: transform,
  };
}

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

class CustomDragLayer extends PureComponent {
  static propTypes = {
    item: PropTypes.shape({}),
    itemType: PropTypes.string,
    currentOffset: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }),
    isDragging: PropTypes.bool.isRequired,
  };

  get style() {
    return { height: 40, width: 752, position: 'absolute' };
  }

  renderItem(type, currentOffset, defaultValue) {
    switch (type) {
      case 'TEXT':
        return (
          <div style={{ ...this.style, ...getItemStyles(currentOffset) }}>
            <ChecklistImageListItem
              iconBg="white"
              isRed
              onChange={() => {}}
              onDelete={() => {}}
              defaultValue={defaultValue}
              iconImage="other"
            />
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    const { itemType, isDragging, currentOffset, item } = this.props;

    if (!isDragging) {
      return null;
    }

    return <div style={layerStyles}>{this.renderItem(itemType, currentOffset, item.defaultValue)}</div>;
  }
}

export default compose(
  DragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    clientOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  })),
)(CustomDragLayer);
