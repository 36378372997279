import React, { PureComponent } from 'react';
import log from 'loglevel';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import t from '@properly/localization';
import PromiseProps from 'promise-props';
import Tooltip from '@material-ui/core/Tooltip';
import { Input, Button, RoundSelect, LoadingSplash, LoadingSpinner, Icon } from '@properly/components';
import { isValidEmail, isMobileTouch } from '@properly/common';
import { getConfigSync } from '@properly/config';
import { fromJS } from 'immutable';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import keyBy from 'lodash/keyBy';
import includes from 'lodash/includes';
import lowerCase from 'lodash/lowerCase';
import lodashResult from 'lodash/result';
import debounce from 'lodash/debounce';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import styles from './checkoutStyles.module.css';
import { CheckoutInput, CheckoutInputNormal } from './CheckoutInput';
import { CheckoutSection, CheckoutRow, CheckoutCol } from './CheckoutElements';
import {
  goToError500,
  goToError404,
  goToDesktopSubscription,
  goToMobileSubscription,
  goToDesktopSubscriptionThankyou,
} from './CheckoutActions';
import CheckoutModal from './CheckoutModal';
import * as selectorsGlobal from '../../../selectors/globalSelector';
import { RELOAD_STATUS } from '../../../dataConstants';
import { getParsePlans, createSubscriptionFromToken, updateBillingInfo, getBillingInfoForForm } from '../data';
import CheckoutPricingOverview from './CheckoutPricingOverview';
import countries from './CheckoutCountries';
import { recurlyFields, formData, requiredFields } from './CheckoutConstants';
import { mapServerDataToLocalData, genObjForToken, genObjForUpdate } from './CheckoutMapper';
import { selectCurrentCountryCode } from '../settings/state/SettingsSelectors';

const config = getConfigSync();
const { RECURLY_PUBLIC_KEY, RECURLY_SDK_URL } = config;

const cx = classNames.bind(styles);

class CheckoutContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.isMobile = isMobileTouch();
    this.debouncedCalcPricing = debounce(this.calcPricing.bind(this, 'debounce'), 1000);
    this.mappedCountries = map(countries, val => ({
      title: val.name,
      value: val.code,
    }));
    this.state = {
      loadState: 0,
      loadStatePricing: 0,
      loadStateSubmit: 0,
      showValidation: false,
      pricing: {}, // eslint-disable-line
      plans: {},
      applied: 0,
      appliedMsg: undefined,
      requiredFields,
      formData,
      fields: recurlyFields,
    };
  }

  get subscription() {
    if (!this.props.currentUser.user) return undefined;
    return this.props.currentUser.user.subscription;
  }

  setLoadState = val => {
    this.setState({ loadState: val });
  };

  setLoadStatePricing = val => {
    this.setState({ loadStatePricing: val });
  };

  getResolvedKey(key) {
    let p = [];
    switch (key) {
      case 'tax':
        p = ['state', 'pricing', 'next', 'tax'];
        break;
      case 'discount':
        p = ['state', 'pricing', 'next', 'discount'];
        break;
      case 'total':
        p = ['state', 'pricing', 'next', 'total'];
        break;
      case 'code':
        p = ['state', 'pricing', 'currency', 'code'];
        break;
      case 'symbol':
        p = ['state', 'pricing', 'currency', 'symbol'];
        break;
      case 'perunit':
        p = ['state', 'pricing', 'base', 'plan', 'unit'];
        break;
      case 'paramPlanId':
        p = ['props', 'location', 'query', 'planId'];
        break;
      case 'pageMode':
        p = ['props', 'location', 'query', 'mode'];
        break;
      case 'settingsCountry':
        p = ['props', 'countryCode'];
        break;
      case 'qtyJobsUsedMonth':
        p = ['subscription', 'jobsUsedMonth'];
        break;
      case 'currentPlanId':
        p = ['subscription', 'currentPlanId'];
        break;
      case 'subscriptionId':
        p = ['subscription', 'subscriptionProviderSubscriptionId'];
        break;

      default:
    }
    return lodashResult(this, p);
  }

  addCurrency(val) {
    return val ? `${this.getResolvedKey('symbol')}${val} ${this.getResolvedKey('code')}` : '0';
  }

  setField = (name, setter) => e => {
    if (!setter) e.persist();
    if (name === 'taxnumber') this.debouncedCalcPricing();
    const realSetter = setter ? setter(e) : lodashResult(e, 'target.value');
    this.setState(state => ({
      ...state,
      formData: state.formData.set(name, realSetter),
    }));
  };

  setRequiredFields = data => {
    this.setState(state => ({
      ...state,
      requiredFields: {
        ...state.requiredFields,
        ...data,
      },
    }));
  };

  batchSetFields = data => {
    this.setState(state => ({
      ...state,
      formData: state.formData.merge(fromJS(data)),
    }));
  };

  getField = name => this.state.formData.get(name);

  flashDiscountApplied = (id, msg) => {
    this.setState({ applied: id, appliedMsg: msg });
    setTimeout(() => {
      this.setState({ applied: 0, appliedMsg: undefined });
    }, 5500);
  };

  getQty() {
    const type = lodashResult(this.getCurrentPlan(), ['priceType']);
    if (type === 'property') return this.props.properties.size;
    if (type === 'job') return 0;
    if (type === 'flatrate') return 1;
    return 1;
  }

  genCommandObj() {
    return {
      plan: [this.getField('planId'), { quantity: this.getQty() }],
      currency: 'USD',
      address: {
        address1: this.getField('billingaddress'),
        address2: this.getField('address2'),
        city: this.getField('city'),
        state: this.getField('state'),
        zip: this.getField('zip'),
        country: this.getField('country'), // 2 letter code
      },
      tax: {
        tax_code: 'digital',
        vat_number: this.getField('taxnumber'),
      },
    };
  }

  runCommandObj(recurlyPricingImport, commandObj) {
    const reducedPricing = reduce(
      commandObj,
      (acc, val, key) => acc[key].apply(acc, isArray(val) ? val : [val]), // eslint-disable-line
      recurlyPricingImport,
    );
    return new Promise((resolve, reject) => {
      reducedPricing
        .catch(err => {
          reject(err);
        })
        .done(pricing => {
          resolve(pricing);
        });
    });
  }

  calcPricing(triggeredFrom) {
    this.setLoadStatePricing(1);
    const coupon = this.getField('coupon');
    const hasCoupon = coupon && coupon.length > 0;
    const commandObj = this.genCommandObj();
    // add coupon if we have one
    if (hasCoupon) {
      commandObj.coupon = coupon;
    }
    // calc pricing
    this.runCommandObj(this.recurlyPricing, commandObj)
      .then(pricing => {
        this.setState({ pricing }); // eslint-disable-line
        if (hasCoupon && triggeredFrom === 'couponbutton') {
          this.flashDiscountApplied(1);
        }
        this.setLoadStatePricing(2);
      })
      .catch(err => {
        const isCouponError = err && err.code === 'not-found' && includes(err.message, 'coupon_code');
        if (isCouponError) {
          this.setField('coupon', e => e)('');
          this.flashDiscountApplied(5);
          this.calcPricing('couponerror'); // refresh pricecalc
        } else {
          log.error('calcPricing', err);
        }
        this.setLoadStatePricing(3);
      });
  }

  validate() {
    return reduce(
      this.state.requiredFields,
      (acc, val, key) => {
        const fieldValue = this.getField(key);
        if (val) {
          // if is enabled for that key
          if ((fieldValue && fieldValue.length > 0) || fieldValue === true) {
            acc.fields[key] = false;
          } else {
            acc.fields[key] = t('checkout.isrequired');
            acc.isValid = false;
          }
          if (key === 'email' && !isValidEmail(fieldValue)) {
            acc.fields[key] = t('checkout.email_not_valid');
            acc.isValid = false;
          }
        }
        return acc;
      },
      {
        isValid: true,
        fields: {},
      },
    );
  }

  handleRecurelyChange = data => {
    const getValue = field => lodashResult(data, ['fields', field, 'valid']);
    const res = {
      cvv: getValue('cvv'),
      year: getValue('year'),
      month: getValue('month'),
      number: getValue('number'),
    };
    this.batchSetFields(res);
  };

  setupRecurly = () => {
    log.info('setupRecurly');
    let { fields } = this.state;
    if (this.getField('lastFour')) {
      const number = `**** **** **** ${this.getField('lastFour')}`;
      fields = fromJS(fields)
        .setIn(['number', 'style', 'placeholder', 'content'], number)
        .toJS();
    }
    window.recurly.configure({
      publicKey: RECURLY_PUBLIC_KEY,
      fields,
    });
    window.recurly.on('change', this.handleRecurelyChange);
    this.recurlyPricing = window.recurly.Pricing();
    this.calcPricing();
  };

  loadedHook = () => {
    this.setupRecurly();
    const countryOfUser = this.getResolvedKey('settingsCountry');
    if (!this.getField('country')) {
      const val = countryOfUser ? lowerCase(countryOfUser) : 'us';
      this.setField('country', e => e)(val);
    }
  };

  getToken = obj =>
    new Promise((resolve, reject) => {
      window.recurly.token(obj, (err, token) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(token);
      });
    });

  componentWillUnmount() {
    if (window.recurly) window.recurly.off('change', this.handleRecurelyChange);
  }

  hasBankingInfo() {
    return this.getField('cvv') && this.getField('year') && this.getField('month') && this.getField('number');
  }

  setModalSuccess(val) {
    this.setState({ modalSuccess: val });
  }

  closeModalMobile = () => {
    this.setModalSuccess(false);
    this.props.goToMobileSubscription();
  };

  handleSubmitPromise = (promise, fromWhere) => {
    this.setState({ loadStateSubmit: 1 });
    promise
      .then(() => {
        this.setState({ loadStateSubmit: 0 });
        if (fromWhere === 'update') this.flashDiscountApplied(2);
        if (fromWhere === 'upgrade') {
          if (this.isMobile) {
            this.setModalSuccess(true);
          } else {
            // Record this ma999
            this.props.goToDesktopSubscriptionThankyou();
          }
        }
      })
      .catch(error => {
        this.setState({ loadStateSubmit: 0 });
        this.flashDiscountApplied(4, getErrorMsg(error));
      });
  };

  handleSubmit = isValid => () => {
    if (!isValid) {
      this.setState({ showValidation: true });
      return;
    }
    if (this.hasBankingInfo()) {
      const promise = this.getToken(genObjForToken(this.getField)).then(res =>
        createSubscriptionFromToken({
          token: res.id,
          companyName: this.getField('company'),
          vatNumber: this.getField('taxnumber'),
          email: this.getField('email'),
          quantity: this.getQty(),
          planCode: this.getField('planId'),
          couponCode: this.getField('coupon'),
          subscriptionId: this.getResolvedKey('subscriptionId'),
        }),
      );
      let mode = 'upgrade';
      if (this.isUpdateMode) mode = 'update';
      this.handleSubmitPromise(promise, mode);
    } else {
      const promise = updateBillingInfo(genObjForUpdate(this.getField));
      this.handleSubmitPromise(promise, 'update');
    }
  };

  getCurrentPlan = () => this.state.plans[this.getField('planId')];

  get isUpdateMode() {
    return this.getResolvedKey('pageMode') === 'update';
  }

  get isUpgradeMode() {
    return this.getResolvedKey('pageMode') === 'upgrade';
  }

  componentDidMount() {
    this.setLoadState(1);
    PromiseProps({
      plans: getParsePlans(),
      script: loadScript(RECURLY_SDK_URL),
      prefillData: getBillingInfoForForm(),
    })
      .then(res => {
        this.batchSetFields(mapServerDataToLocalData(res.prefillData));
        const plans = keyBy(res.plans, 'planId');
        if (this.isUpgradeMode) {
          const askedForPlan = this.getResolvedKey('paramPlanId');
          if (!plans[askedForPlan]) {
            this.setLoadState(0);
            this.props.goToError404();
            log.error('CheckoutContainer - plan not found', askedForPlan);
            return;
          }
          this.setField('planId', e => e)(askedForPlan); // set the plan
        }
        if (this.isUpdateMode) {
          this.setField('planId', e => e)(this.getResolvedKey('currentPlanId')); // dont change the plan in update mode
          this.setRequiredFields({
            tos: false,
            cvv: false,
            year: false,
            month: false,
            number: false,
          });
        }
        this.setState({ plans });
        this.setLoadState(2);
      })
      .catch(e => {
        log.error('CheckoutContainer', e);
        this.props.goToError500();
      });
  }

  handleBack = () => {
    if (this.isMobile) {
      this.props.goToMobileSubscription();
    } else {
      this.props.goToDesktopSubscription();
    }
  };

  handleTosClick(what) {
    return () => {
      let url = '';
      if (what === 'tos') url = this.props.config.tosUrl;
      if (what === 'priv') url = this.props.config.privacyPolicyUrl;
      window.open(url);
    };
  }

  renderTos() {
    return (
      <div className={cx('checkout__tos')}>
        <RoundSelect
          style={{ height: 16, width: 16 }}
          type="quarter"
          selected={this.getField('tos')}
          onChange={this.setField('tos', e => lodashResult(e, ['target', 'checked']))}
        />
        <span className={cx('checkout__tos-txt')}>
          {t('checkout.tos', {
            tos: (
              <span onClick={this.handleTosClick('tos')} className={cx('checkout__link')}>
                {t('signup.form.tos')}
              </span>
            ),
            privacy: (
              <span onClick={this.handleTosClick('priv')} className={cx('checkout__link')}>
                {t('signup.form.privacy')}
              </span>
            ),
          })}
        </span>
      </div>
    );
  }

  renderButton(isValid, isLoading) {
    let txt = '';
    if (this.isUpdateMode) {
      txt = t('checkout.updatebilling', {});
    }
    if (this.isUpgradeMode) {
      txt = t('checkout.subscribe', {});
    }
    const textGen = (txtd, color, additionalError) => (
      <div className={cx(`checkout__btn-msg-${color}`)}>
        <div>{txtd}</div>
        <div>{additionalError}</div>
      </div>
    );
    return (
      <div className={cx('checkout__btn-container')}>
        {!this.isUpdateMode && this.renderTos()}
        <Button
          fakeDisable={!isValid}
          onClick={this.handleSubmit(isValid)}
          types={['type-full-primary', 'width-flex', 'size-large']}
        >
          {isLoading && <LoadingSpinner type="white" />}
          {!isLoading && txt}
        </Button>
        <div className={cx('checkout__btn-msg')}>
          {this.state.applied === 2 ? textGen(t('checkout.informationupdated'), 'black') : null}
          {this.state.applied === 3 ? textGen(t('checkout.accountupgraded'), 'black') : null}
          {this.state.applied === 4 ? textGen(t('checkout.therewasanerror'), 'error', this.state.appliedMsg) : null}
        </div>
      </div>
    );
  }

  renderContent() {
    const gutter = 8;
    const mobile = this.isMobile;
    const mode = mobile ? 'column' : 'row';
    const { isValid, fields } = this.validate();
    const hasError = fieldKey => this.state.showValidation && fields[fieldKey];
    const isRequired = fieldKey => this.state.requiredFields[fieldKey];
    return (
      <div
        data-form="checkout"
        className={cx('checkout__container', {
          'checkout__container--mobile': mobile,
        })}
      >
        <div className={cx('checkout__container-inner')}>
          <input type="hidden" name="recurly-token" data-recurly="token" />
          <input type="hidden" name="recurly-coupon" value={this.getField('coupon')} data-recurly="coupon" />
          <div className={cx('checkout__header')}>
            <div className={cx('checkout__back')}>
              <Icon.IcCloseCc onClick={this.handleBack} />
            </div>

            <div className={cx('checkout__logo')}>
              <Icon.Plogo width="133px" byWidth alt="Properly logo" />
            </div>
          </div>
          <CheckoutSection title="Contact">
            <CheckoutRow mode={mode} gutter={gutter}>
              <CheckoutCol size="50%" sizeSmall="100%">
                <CheckoutInputNormal
                  type="text"
                  isRequired={isRequired('firstname')}
                  error={hasError('firstname')}
                  onChange={this.setField('firstname')}
                  value={this.getField('firstname')}
                  label={t('checkout.firstname')}
                />
              </CheckoutCol>
              <CheckoutCol size="50%" sizeSmall="100%">
                <CheckoutInputNormal
                  type="text"
                  isRequired={isRequired('lastname')}
                  error={hasError('lastname')}
                  onChange={this.setField('lastname')}
                  value={this.getField('lastname')}
                  label={t('checkout.lastname')}
                />
              </CheckoutCol>
            </CheckoutRow>
            <CheckoutRow mode={mode} gutter={gutter}>
              <CheckoutCol size="50%" sizeSmall="100%">
                <CheckoutInputNormal
                  type="text"
                  isRequired={isRequired('email')}
                  error={hasError('email')}
                  onChange={this.setField('email')}
                  value={this.getField('email')}
                  label={t('checkout.email')}
                />
              </CheckoutCol>
              <CheckoutCol size="50%" sizeSmall="100%">
                <CheckoutInputNormal
                  type="text"
                  onChange={this.setField('company')}
                  value={this.getField('company')}
                  label={t('checkout.company')}
                />
              </CheckoutCol>
            </CheckoutRow>
          </CheckoutSection>
          <CheckoutSection title={t('checkout.address')}>
            <CheckoutInputNormal
              type="text"
              isRequired={isRequired('billingaddress')}
              error={hasError('billingaddress')}
              onChange={this.setField('billingaddress')}
              value={this.getField('billingaddress')}
              label={t('checkout.billingaddress')}
            />
            <CheckoutInputNormal
              type="text"
              onChange={this.setField('address2')}
              value={this.getField('address2')}
              label={t('checkout.address2')}
            />
            <CheckoutRow mode={mode} gutter={gutter}>
              <CheckoutCol size="50%" sizeSmall="100%">
                <CheckoutInputNormal
                  label={t('checkout.country')}
                  isRequired={isRequired('country')}
                  error={hasError('country')}
                >
                  <Input
                    type="select"
                    optionsColor="normal"
                    optionsBtn
                    error={!!hasError('country')}
                    style={{
                      textAlign: 'left',
                      fontSmoothing: 'initial',
                      fontFamily: 'Arial',
                    }}
                    options={this.mappedCountries}
                    onChange={this.setField('country')}
                    isFirst
                    isLast
                    value={this.getField('country')}
                  />
                </CheckoutInputNormal>
              </CheckoutCol>
              <CheckoutCol size="50%" sizeSmall="100%">
                <CheckoutInputNormal
                  type="text"
                  onChange={this.setField('taxnumber')}
                  value={this.getField('taxnumber')}
                  label={t('checkout.taxnumber')}
                />
              </CheckoutCol>
            </CheckoutRow>
            <CheckoutRow mode={mode} gutter={gutter}>
              <CheckoutCol size="50%" sizeSmall="100%">
                <CheckoutInputNormal
                  type="text"
                  isRequired={isRequired('city')}
                  error={hasError('city')}
                  onChange={this.setField('city')}
                  value={this.getField('city')}
                  label={t('checkout.city')}
                />
              </CheckoutCol>
              <CheckoutCol size="25%" sizeSmall="50%">
                <CheckoutInputNormal
                  type="text"
                  onChange={this.setField('state')}
                  value={this.getField('state')}
                  label={t('checkout.state')}
                />
              </CheckoutCol>
              <CheckoutCol size="25%" sizeSmall="50%">
                <CheckoutInputNormal
                  type="text"
                  isRequired={isRequired('zip')}
                  error={hasError('zip')}
                  onChange={this.setField('zip')}
                  value={this.getField('zip')}
                  label={t('checkout.zip')}
                />
              </CheckoutCol>
            </CheckoutRow>
          </CheckoutSection>
          <CheckoutSection title={t('checkout.payment')}>
            <CheckoutInput
              data-key="number"
              data-recurly="number"
              type="text"
              isRequired={isRequired('number')}
              error={hasError('number')}
              label={t('checkout.cardnumber')}
            />
            <CheckoutRow mode={mode} gutter={gutter}>
              <CheckoutCol size="25%" sizeSmall="50%">
                <CheckoutInput
                  data-key="month"
                  data-recurly="month"
                  type="text"
                  isRequired={isRequired('month')}
                  error={hasError('month')}
                  label={t('checkout.expiremonth')}
                />
              </CheckoutCol>
              <CheckoutCol size="25%" sizeSmall="50%">
                <CheckoutInput
                  data-key="year"
                  data-recurly="year"
                  type="text"
                  isRequired={isRequired('year')}
                  error={hasError('year')}
                  label={t('checkout.expireyear')}
                />
              </CheckoutCol>
              <CheckoutCol size="25%" sizeSmall="50%">
                <CheckoutInput
                  icon={
                    <Tooltip title={t('checkout.cvvinfo')}>
                      <span style={{ display: 'inline-block' }}>
                        <Icon.IcInfocvv />
                      </span>
                    </Tooltip>
                  }
                  data-key="cvv"
                  data-recurly="cvv"
                  type="text"
                  isRequired={isRequired('cvv')}
                  error={hasError('cvv')}
                  label={t('checkout.cvv')}
                />
              </CheckoutCol>
            </CheckoutRow>
            {this.isUpgradeMode && (
              <CheckoutRow mode={mode} gutter={gutter}>
                <CheckoutCol size="75%" sizeSmall="65%">
                  <CheckoutInputNormal
                    onChange={this.setField('coupon')}
                    value={this.getField('coupon')}
                    msg={this.state.applied === 1 ? t('checkout.discountapplied') : null}
                    error={this.state.applied === 5 ? t('checkout.invalidcode') : null}
                    type="text"
                    label={t('checkout.coupon')}
                  />
                </CheckoutCol>
                <CheckoutCol size="25%" sizeSmall="35%">
                  <div className={cx('checkout__applywrap')}>
                    <Button
                      disabled={this.getField('coupon').length === 0}
                      onClick={() => this.calcPricing('couponbutton')}
                      types={['type-full-primary', 'width-flex', 'size-large', 'minwidth-no', 'padding-5']}
                    >
                      {t('checkout.apply', {})}
                    </Button>
                  </div>
                </CheckoutCol>
              </CheckoutRow>
            )}
          </CheckoutSection>
          <CheckoutPricingOverview
            renderNothing={this.isUpdateMode}
            planTitle={lodashResult(this.getCurrentPlan(), ['title'])}
            planType={lodashResult(this.getCurrentPlan(), ['priceType'])}
            isLoading={this.state.loadStatePricing === 1}
            properties={this.props.properties && this.props.properties.toArray()}
            pricePerUnit={this.getResolvedKey('perunit')}
            taxAmount={this.getResolvedKey('tax')}
            jobsQty={this.getQty()}
            discountAmount={this.getResolvedKey('discount')}
            totalAmount={this.addCurrency(this.getResolvedKey('total'))}
            symbol={this.getResolvedKey('symbol')}
            onMount={this.loadedHook}
            isAnnually={lodashResult(this.getCurrentPlan(), ['annual'])}
          />
          {this.renderButton(isValid, this.state.loadStateSubmit === 1)}
        </div>
      </div>
    );
  }

  renderWhat() {
    if (this.state.loadState <= 1 || this.props.loading) return <LoadingSplash />;
    return this.renderContent();
  }

  render() {
    return (
      <div className={cx('checkout')}>
        {this.renderWhat()}
        <CheckoutModal show={this.state.modalSuccess} mode="success" onClose={this.closeModalMobile} isMobile />
      </div>
    );
  }
}

function loadScript(src) {
  return new Promise((resolve, reject) => {
    const s = document.createElement('script');
    s.src = src;
    s.onload = resolve;
    s.onerror = reject;
    document.head.appendChild(s);
  });
}

function getErrorMsg(error) {
  let msg = '';
  try {
    const msgJson = JSON.parse(error.message);
    if (isString(msgJson.error)) {
      msg = msgJson.error;
    }
  } catch (e) {
    // kill
  }
  return msg;
}

function mapStateToProps(state) {
  return {
    loading: selectorsGlobal.selectPreloadStatus(state) === RELOAD_STATUS.NOTLOADED,
    config: selectorsGlobal.selectConfig(state),
    properties: selectorsGlobal.selectOwnerProperties()(state),
    countryCode: selectCurrentCountryCode(state),
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  goToError404,
  goToError500,
  goToDesktopSubscription,
  goToMobileSubscription,
  goToDesktopSubscriptionThankyou,
})(CheckoutContainer);
