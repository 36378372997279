import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getConfigSync, ownerAcquisitionBlacklistedUtmSources } from '@properly/config';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { LoadingSplash } from '@properly/components';
import { pictureSizes, generatePictureUrl, getUtmParamsFromCookie, setTrackingCookie } from '@properly/common';
import TopBarLoginSignup from '../../components/TopBarLoginSignup';
import styles from './loginSignUpStyles.css';
import { goToSignup, goToLogin } from './LoginSignUpActions';
import { brandingConfigSaga } from '../branding/BrandingActions';
import * as selectorsGlobal from '../../selectors/globalSelector';
import AccountWarningModals from '../desktopApp/account/components/AccountWarningModals';
import AccountOAuthContainerModal from '../desktopApp/account/components/AccountOAuthContainerModal';
import { RELOAD_STATUS } from '../../dataConstants';
import { selectBrandingLogo, selectIsPartnerDomain } from '../branding/BrandingSelector';

const cx = classNames.bind(styles);
const config = getConfigSync();

class LoginWrapContainer extends PureComponent {
  getBrandingConfigBeforeLogin() {
    const hostName = window.location.hostname;
    const appWebUrl = String(config.APP_WEB_LINK).replace('//', '');
    const domain = !hostName.includes(appWebUrl) ? hostName : null;

    const queryParams = this.props.location && this.props.location.query;
    setTrackingCookie(queryParams);
    const { source } = getUtmParamsFromCookie();

    this.props.brandingConfigSaga(domain, ownerAcquisitionBlacklistedUtmSources.includes(source) ? null : source);
  }

  componentDidMount() {
    this.getBrandingConfigBeforeLogin();
  }

  renderLoading() {
    return (
      <div className={cx('wizard-loading')}>
        <LoadingSplash hasRandomMsg />
      </div>
    );
  }

  renderContent() {
    return (
      <div
        className={cx('wizard-inner', {
          center: this.props.center,
        })}
      >
        <h1
          className={cx('wizard-h1', {
            'wizard-h1--passwordreset': this.props.withSubHeadline,
            'wizard-h1--login': !this.props.withSubHeadline,
          })}
        >
          {this.props.title}
        </h1>
        {this.props.children}
      </div>
    );
  }

  render() {
    const options = ['showSignUp', 'showToast', 'message', 'showLogin'];
    const {
      loginSignUpReducers,
      appLoadState,
      loading,
      generalConfig,
      logo: partnerLogo,
      isPartnerDomain,
    } = this.props;

    const { loginLoading } = loginSignUpReducers;
    const appIsNotLoaded = appLoadState === RELOAD_STATUS.NOTLOADED && this.props.currentUser.isLoggedIn;
    const isLoading = loading || loginLoading || appIsNotLoaded;
    const trimmedProps = appIsNotLoaded ? omit(this.props, options) : this.props;
    const { logoLogin } = generalConfig;

    const logo = isPartnerDomain ? generatePictureUrl(partnerLogo, pictureSizes.ORIGINAL) : logoLogin;

    return (
      <div className={cx('login-container')}>
        <TopBarLoginSignup
          {...pick(trimmedProps, options)}
          logo={logo}
          onClickCreate={() => this.props.goToSignup(this.props.location)}
          onClickLogin={() => this.props.goToLogin(this.props.location)}
        />
        <div>
          <div
            className={cx('wizard-container', {
              'wizard-reset-password': this.props.withMaxWidth,
              'wizard-container--big': this.props.big,
              'wizard-container--mobile': this.props.isMobile,
            })}
          >
            {isLoading && this.renderLoading()}
            {!isLoading && !this.props.big && this.renderContent()}
            {!isLoading && this.props.big && this.props.children}
          </div>
        </div>
        <AccountOAuthContainerModal />
        <AccountWarningModals />
      </div>
    );
  }
}

LoginWrapContainer.propTypes = {
  title: PropTypes.string,
  showSignUp: PropTypes.bool, // eslint-disable-line
  showLogin: PropTypes.bool, // eslint-disable-line
  center: PropTypes.bool,
  withMaxWidth: PropTypes.bool,
  withSubHeadline: PropTypes.bool,
  loading: PropTypes.bool,
  location: PropTypes.shape({}),
  logo: PropTypes.string,
};

LoginWrapContainer.defaultProps = {
  logo: '',
};

LoginWrapContainer.Headline = ({ title, withSubHeadline, lessSpaceTop }) => (
  <h1
    className={cx('wizard-h1', {
      'wizard-h1--passwordreset': withSubHeadline,
      'wizard-h1--login': !withSubHeadline,
      'wizard-h1--lesstop': lessSpaceTop,
    })}
  >
    {title}
  </h1>
);

function mapStateToProps(state) {
  return {
    loginSignUpReducers: state.loginSignUpReducers,
    currentUser: state.currentUser,
    appLoadState: selectorsGlobal.selectPreloadStatus(state),
    generalConfig: selectorsGlobal.selectGeneralConfig(state),
    logo: selectBrandingLogo()(state),
    isPartnerDomain: selectIsPartnerDomain()(state),
  };
}

export default connect(mapStateToProps, { goToSignup, goToLogin, brandingConfigSaga })(LoginWrapContainer);
