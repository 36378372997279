import React from 'react';
import PropTypes from 'prop-types';
import log from 'loglevel';
import t from '@properly/localization';
import {
  Input,
  Large,
  SpaceLarge,
  SpaceMedium,
  SpaceBase,
  SpaceTiny,
  SpaceSmallMid,
  SpaceFlexi,
  Button,
  Icon,
} from '@properly/components';
import lodashResult from 'lodash/result';
import classNames from 'classnames/bind';
import styles from './index.module.css';
import { TodosTextEle } from '../TodosElements';

const cx = classNames.bind(styles);

const NoProperties = ({ onClick, type, ...props }) => {
  // Default to no properties type
  if (type === 'notselected') {
    return (
      <div className={cx('no-properties')}>
        <div className={cx('no-properties__inner')}>
          <Icon.IcBigPropertiesGrey width="4em" height="4em" byWidth />
          <SpaceMedium />
          <div className={cx('no-properties__txt', 'no-properties__txt--grey')}>
            <div>{t('properties.no_property_selected')}</div>
          </div>
        </div>
      </div>
    );
  }

  if (type === 'noresults') {
    return (
      <div className={cx('no-properties')}>
        <div className={cx('no-properties__inner')}>
          <Icon.IcBigPropertiesGrey width="4em" height="4em" byWidth />
          <SpaceMedium />
          <div className={cx('no-properties__txt', 'no-properties__txt--grey')}>
            <div>{t('properties.no_property_found')}</div>
          </div>
        </div>
      </div>
    );
  }

  if (type === 'noproperties') {
    return (
      <div className={cx('no-properties')}>
        <div className={cx('no-properties__inner')}>
          <SpaceLarge />
          <Icon.IcBigProperties width="4em" height="4em" byWidth />
          <SpaceMedium />
          <div className={cx('no-properties__txt')}>
            <Large>{t('properties.no_properties')}</Large>
          </div>
          <SpaceLarge />
          <div data-key="button_add_new_property">
            <Button types={['type-full-primary', 'size-large']} onClick={onClick}>
              {t('properties.new_properties')}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (type === 'jobrequest') {
    return (
      <div className={cx('no-properties')}>
        <div className={cx('no-properties__inner')}>
          <Icon.IcBigSent width="4em" height="4em" />
          <SpaceBase />
          <div>
            <Large type="medium">{props.line1}</Large>
          </div>
          {props.line2 && (
            <div>
              <SpaceTiny />
              <div className={cx('no-properties__txt-job')}>{props.line2}</div>
            </div>
          )}

          <SpaceMedium />

          <div>
            <Button
              onClick={onClick}
              types={['size-large', 'type-border-primary']}
              data-key="open-calendar-from-job-request"
            >
              {t('job_request.send_request_action')}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  const renderErrorText = errorType => {
    switch (errorType) {
      case 'jobrequesterror':
        return t('job_request.error_action_notsuccessful');
      case 'community_service_provider_sample_property_error':
        return t('job_request.community_service_provider_sample_property_error');
      case 'job_already_scheduled':
        return t('job_request.job_already_scheduled');
      default:
        log.warn(`Unknown error type: ${errorType}`);
        return t('job_request.error_action_notsuccessful');
    }
  };

  const errorType = [
    'jobrequesterror',
    'community_service_provider_sample_property_error',
    'job_already_scheduled',
    'error_handled_from_api',
  ];

  if (errorType.includes(type)) {
    return (
      <div className={cx('no-properties')}>
        <div className={cx('no-properties__inner')}>
          <Input.ErrorMsg type="medium">{props.message || renderErrorText(type)}</Input.ErrorMsg>
        </div>
      </div>
    );
  }

  if (type === 'sms') {
    return (
      <div className={cx('no-properties')}>
        <div className={cx('no-properties__inner')}>
          <Icon.IcBigSent width="4em" height="4em" />
          <SpaceBase />
          <div>
            <Large type="medium">{t('fueslider.smssend')}</Large>
          </div>
        </div>
      </div>
    );
  }

  if (type === 'invitecleaner') {
    return (
      <div className={cx('no-properties')}>
        <div className={cx('no-properties__inner')}>
          <SpaceLarge />
          <Icon.IcEmptyAdd />
          <SpaceMedium />
          <div className={cx('no-properties__txt')}>
            <Large>{t('contacts.invite_trusted_cleaner')}</Large>
          </div>
          <SpaceLarge />
          {lodashResult(props, ['meta', 'hasBtn']) && (
            <Button types={['type-full-primary', 'size-large']} onClick={onClick}>
              {t('contacts.invite_cleaner')}
            </Button>
          )}
        </div>
      </div>
    );
  }
  if (type === 'inviteteammember') {
    return (
      <div className={cx('no-properties', 'no-properties--maxwidth', 'no-properties--center')}>
        <div className={cx('no-properties__inner')}>
          <Icon.IcCompanyCreate height="111px" width="111px" byWidth />
          <SpaceSmallMid />
          <TodosTextEle modifier={['txt--l5m', 'color--black']}>{t('permissions.invite_coll')}</TodosTextEle>
          <TodosTextEle modifier={['txt--l5', 'color--black']}>{t('permissions.invite_coll_txt')}</TodosTextEle>
          <SpaceBase />
          <Button types={['type-full-primary', 'size-medium']} onClick={onClick}>
            {t('permissions.invite')}
          </Button>
        </div>
      </div>
    );
  }
  if (type === 'inviteemployee') {
    return (
      <div className={cx('no-properties', 'no-properties--maxwidth', 'no-properties--center')}>
        <div className={cx('no-properties__inner')}>
          <Icon.IcCompanyCreate height="90px" width="90px" byWidth />
          <SpaceFlexi type="vertical" size="18px" />
          <TodosTextEle modifier={['txt--l4m', 'color--black']}>{t('permissions.welcome')}</TodosTextEle>
          <SpaceFlexi type="vertical" size="7px" />
          <TodosTextEle modifier={['txt--l4', 'color--black']}>{t('permissions.welcome_l2')}</TodosTextEle>
          <SpaceFlexi type="vertical" size="18px" />
          <Button types={['type-full-primary', 'size-large']} onClick={onClick}>
            {t('permissions.invite_team_member')}
          </Button>
        </div>
      </div>
    );
  }
  if (type === 'invitepartner') {
    return (
      <div className={cx('no-properties', 'no-properties--maxwidth', 'no-properties--center')}>
        <div className={cx('no-properties__inner')}>
          <Icon.IcCompanyCreate height="90px" width="90px" byWidth />
          <SpaceFlexi type="vertical" size="18px" />
          <TodosTextEle modifier={['txt--l4m', 'color--black']}>{t('permissions.invite_partner_l1')}</TodosTextEle>
          <SpaceFlexi type="vertical" size="7px" />
          <TodosTextEle modifier={['txt--l4', 'color--black']}>{t('permissions.invite_partner_l2')}</TodosTextEle>
          <SpaceFlexi type="vertical" size="18px" />
          <Button types={['type-full-primary', 'size-large']} onClick={onClick}>
            {t('permissions.start')}
          </Button>
        </div>
      </div>
    );
  }
  if (type === 'noeventsin7days') {
    return (
      <div className={cx('no-properties')}>
        <div className={cx('no-properties__inner')}>
          <Icon.Bigtick />
          <SpaceMedium style={{ width: '100%' }} />
          <div className={cx('no-properties__txt')}>
            <Large>{t('todos.nothingin7days')}</Large>
          </div>
        </div>
      </div>
    );
  }
  if (type === 'noeventsatall') {
    return (
      <div className={cx('no-properties')}>
        <div className={cx('no-properties__inner')}>
          <Icon.Bigtick />
          <SpaceMedium style={{ width: '100%' }} />
          <div className={cx('no-properties__txt')}>
            <Large>{t('todos.noeventsatall')}</Large>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

NoProperties.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.oneOf([
    'noproperties',
    'noeventsatall',
    'noresults',
    'notselected',
    'invitecleaner',
    'jobrequest',
    'jobrequesterror',
    'sms',
    'inviteteammember',
    'invitepartner',
    'community_service_provider_sample_property_error',
    'job_already_scheduled',
    'error_handled_from_api',
  ]),
  meta: PropTypes.shape({}),
  line1: PropTypes.string,
  line2: PropTypes.string,
};

NoProperties.defaultProps = {
  type: 'notselected',
  meta: null,
  line1: '',
  line2: '',
  onClick: () => {},
};
export default NoProperties;
