import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, mapProps, flattenProp } from 'recompose';
import t from '@properly/localization';
import { Input } from '@properly/components';
import pick from 'lodash/pick';
import { mapToMainState } from './permissionhelper';
import { JobRequestCenterFlex } from '../../../components/JobRequestElements';
import { handlePermissionActionSaga } from './PermssionsActions';

class EmailSection extends PureComponent {
  static propTypes = {
    isSectionValid: PropTypes.bool,
    sectionValue: PropTypes.shape({}),
  };

  updateData = () => e => {
    this.props.handlePermissionActionSaga('changeSubmitEmail', {
      value: this.props.sectionValue.set('email', e.target.value).set('error', undefined),
    });
  };

  hasError(val) {
    return this.props.sectionValue.get('error') === val;
  }

  renderInput(width) {
    return (
      <div style={{ width }}>
        <Input
          isFirst
          isLast
          styleOverwrite={{ backgroundColor: '#FFF' }}
          placeholder={t('permissions.modal.email_enter')}
          type="text"
          error={!this.hasError(undefined) || this.props.isSectionValid}
          value={this.props.sectionValue.get('email')}
          onChange={this.updateData()}
        />
      </div>
    );
  }

  get isFullEmailSection() {
    return this.props.activeSectionId === 'email';
  }

  render() {
    return (
      <JobRequestCenterFlex>
        <div>
          {this.renderInput(433)}
          <div style={{ width: 433 }}>
            {this.hasError(1) && <Input.ErrorMsg>{t('permissions.modal.error_request')}</Input.ErrorMsg>}
            {this.hasError(2) && <Input.ErrorMsg>{t('permissions.modal.only_sandalone')}</Input.ErrorMsg>}
            {this.hasError(3) && <Input.ErrorMsg>{t('permissions.modal.already_invited')}</Input.ErrorMsg>}
            {this.hasError(4) && <Input.ErrorMsg>{t('permissions.modal.does_not_match_rootdomain')}</Input.ErrorMsg>}
          </div>
        </div>
      </JobRequestCenterFlex>
    );
  }
}

export default compose(
  connect(mapToMainState, { handlePermissionActionSaga }),
  mapProps(props => pick(props, ['activeSectionData', 'handlePermissionActionSaga', 'activeSectionId'])),
  flattenProp('activeSectionData'),
)(EmailSection);
