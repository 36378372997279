import { takeEvery } from 'redux-saga';
import log from 'loglevel';
import { call, fork, put } from 'redux-saga/effects';
import t from '@properly/localization';
import * as types from '../../types/index';
import { determineErrorMessage } from '../../graphql/helper';
import {
  updateCustomerLoadingState,
  updateModalState,
  updateIntentState,
  setStripeCustomer,
  setHostStripeStatus,
} from './paymentInterceptActions';

import {
  getHostStripeStatus,
  getStripeCustomer,
  createStripeSetupIntent,
  createStripeCustomerWithPaymentMethod,
  addStripeCustomerPaymentMethod,
  removeStripeCustomerPaymentMethod,
  createStripeCustomerWithSource,
  addStripeCustomerSource,
  removeStripeCustomerSource,
  addPlaidStripeCustomerSource,
  createPlaidStripeCustomerWithSource,
} from '../../graphql/api/stripe';
import { getProMarketplaceJobCount } from '../../graphql/api/jobList';

const MODAL_STATE_LOADING = 1;
const MODAL_STATE_SUCCESS = 2;
const MODAL_STATE_ERROR = 3;

function* stripeGetHostStatusSaga() {
  const { name } = stripeGetHostStatusSaga;
  log.info(`in ${name}`);

  try {
    const res = yield call(getHostStripeStatus);
    yield put(setHostStripeStatus(res.hostStripeStatus?.defaultPaymentMethodExists));
  } catch (e) {
    e.message = determineErrorMessage(e);
    log.error(name, e);
  }
}

function* getCanRemovePaymentMethod() {
  const { name } = getCanRemovePaymentMethod;
  log.info(`in ${name}`);

  try {
    const { pendingJobCount, acceptedJobCount, inprogressJobCount, finishedUnpaidJobCount } = yield call(
      getProMarketplaceJobCount,
    );
    if (pendingJobCount + acceptedJobCount + inprogressJobCount + finishedUnpaidJobCount === 0) {
      return true;
    }
    // eslint-disable-next-line no-alert
    alert(t('settings.payment_remove_error'));

    return false;
  } catch (e) {
    log.error(name, e);

    return e;
  }
}

function* stripeGetCustomerSaga() {
  const { name } = stripeGetCustomerSaga;
  log.info(`in ${name}`);

  try {
    yield put(updateCustomerLoadingState(true));
    const res = yield call(getStripeCustomer);

    if (res.apiError) {
      throw res;
    }

    yield put(setStripeCustomer(res));
    yield put(updateCustomerLoadingState(false));
  } catch (e) {
    e.message = determineErrorMessage(e);
    yield put(updateCustomerLoadingState(false));
    log.error(name, e);
  }
}

function* stripeSetupIntentSaga() {
  const { name } = stripeSetupIntentSaga;
  log.info(`in ${name}`);
  try {
    yield put(
      updateModalState({
        view: MODAL_STATE_LOADING,
        error: null,
      }),
    );

    const res = yield call(createStripeSetupIntent);

    if (res.apiError) {
      throw res;
    }

    yield put(updateIntentState(res));
    yield put(
      updateModalState({
        view: MODAL_STATE_SUCCESS,
        error: null,
      }),
    );
  } catch (e) {
    e.message = determineErrorMessage(e);
    yield put(
      updateModalState({
        view: MODAL_STATE_ERROR,
        error: e,
      }),
    );
    log.error(name, e);
  }
}

function* stripeAddCustomerPaymentMethodSaga(action) {
  const { name } = stripeAddCustomerPaymentMethodSaga;
  const { stripePaymentMethod, setAsDefault } = action;
  log.info(`in ${name}`);

  try {
    yield put(
      updateModalState({
        view: MODAL_STATE_LOADING,
        error: null,
      }),
    );

    let res = yield call(getHostStripeStatus);
    const hostStripeStatus = res.hostStripeStatus.active;

    if (hostStripeStatus) {
      // host exists as a stripe customer
      res = yield call(addStripeCustomerPaymentMethod, stripePaymentMethod, setAsDefault);
    } else {
      // host doesn't exist yet as a stripe customer
      res = yield call(createStripeCustomerWithPaymentMethod, stripePaymentMethod);
    }

    if (res.apiError) {
      throw res;
    }

    yield put(setStripeCustomer(res));
    yield put(
      updateModalState({
        view: MODAL_STATE_SUCCESS,
        error: null,
      }),
    );
  } catch (e) {
    e.message = determineErrorMessage(e);
    yield put(updateCustomerLoadingState(false));
    yield put(
      updateModalState({
        view: MODAL_STATE_ERROR,
        error: e,
      }),
    );
    log.error(name, e);
  }
}

function* stripeRemoveCustomerPaymentMethodSaga(action) {
  const { name } = stripeRemoveCustomerPaymentMethodSaga;
  const { stripePaymentMethodId } = action;
  log.info(`in ${name}`);

  try {
    yield put(updateCustomerLoadingState(true));
    const canRemovePaymentMethod = yield getCanRemovePaymentMethod();
    if (!canRemovePaymentMethod.apiError && canRemovePaymentMethod) {
      const res = yield call(removeStripeCustomerPaymentMethod, stripePaymentMethodId);

      if (res.apiError) {
        throw res;
      }

      yield put(setStripeCustomer(res));
    }
    yield put(updateCustomerLoadingState(false));
  } catch (e) {
    e.message = determineErrorMessage(e);
    yield put(updateCustomerLoadingState(false));
    log.error(name, e);
  }
}

function* stripeAddCustomerSourceSaga(action) {
  const { name } = stripeAddCustomerSourceSaga;
  const { stripeSourceToken } = action;
  log.info(`in ${name}`);

  try {
    yield put(updateCustomerLoadingState(true));
    yield put(
      updateModalState({
        view: MODAL_STATE_LOADING,
        error: null,
      }),
    );

    let res = yield call(getHostStripeStatus);
    const hostStripeStatus = res.hostStripeStatus.active;

    if (hostStripeStatus) {
      // host exists as a stripe customer
      res = yield call(addStripeCustomerSource, stripeSourceToken.id);
    } else {
      // host doesn't exist yet as a stripe customer
      res = yield call(createStripeCustomerWithSource, stripeSourceToken.id);
    }

    if (res.apiError) {
      throw res;
    }

    yield put(setStripeCustomer(res));
    yield put(updateCustomerLoadingState(false));
    yield put(
      updateModalState({
        view: MODAL_STATE_SUCCESS,
        error: null,
      }),
    );
  } catch (e) {
    e.message = determineErrorMessage(e);
    yield put(updateCustomerLoadingState(false));
    yield put(
      updateModalState({
        view: MODAL_STATE_ERROR,
        error: e,
      }),
    );

    log.error(name, e);
  }
}

function* stripeRemoveCustomerSourceSaga(action) {
  const { name } = stripeRemoveCustomerSourceSaga;
  const { stripeSourceId } = action;
  log.info(`in ${name}`);

  try {
    yield put(updateCustomerLoadingState(true));
    const canRemovePaymentMethod = yield getCanRemovePaymentMethod();
    if (!canRemovePaymentMethod.apiError && canRemovePaymentMethod) {
      const res = yield call(removeStripeCustomerSource, stripeSourceId);

      if (res.apiError) {
        throw res;
      }

      yield put(setStripeCustomer(res));
    }
    yield put(updateCustomerLoadingState(false));
  } catch (e) {
    e.message = determineErrorMessage(e);
    yield put(updateCustomerLoadingState(false));
    log.error(name, e);
  }
}

function* stripeAddPlaidCustomerSourceSaga(action) {
  const { name } = stripeAddPlaidCustomerSourceSaga;
  const { plaidPublicToken, plaidAccountId } = action;
  log.info(`in ${name}`);

  try {
    yield put(updateCustomerLoadingState(true));
    yield put(
      updateModalState({
        view: MODAL_STATE_LOADING,
        error: null,
      }),
    );

    let res = yield call(getHostStripeStatus);
    const hostStripeStatus = res.hostStripeStatus.active;

    if (hostStripeStatus) {
      // host exists as a stripe customer
      res = yield call(addPlaidStripeCustomerSource, plaidPublicToken, plaidAccountId);
    } else {
      // host doesn't exist yet as a stripe customer
      res = yield call(createPlaidStripeCustomerWithSource, plaidPublicToken, plaidAccountId);
    }

    if (res.apiError) {
      throw res;
    }

    yield put(setStripeCustomer(res));
    yield put(updateCustomerLoadingState(false));
    yield put(
      updateModalState({
        view: MODAL_STATE_SUCCESS,
        error: null,
      }),
    );
  } catch (e) {
    e.message = determineErrorMessage(e);
    yield put(updateCustomerLoadingState(false));
    yield put(
      updateModalState({
        view: MODAL_STATE_ERROR,
        error: e,
      }),
    );
    log.error(name, e);
  }
}

function* saga() {
  yield fork(takeEvery, types.STRIPE_PM_GET_HOST_STATUS_SAGA, stripeGetHostStatusSaga);
  yield fork(takeEvery, types.STRIPE_PM_GET_CUSTOMER_SAGA, stripeGetCustomerSaga);
  yield fork(takeEvery, types.STRIPE_PM_SETUP_INTENT_SAGA, stripeSetupIntentSaga);
  yield fork(takeEvery, types.STRIPE_PM_ADD_CUSTOMER_PAYMENT_METHOD_SAGA, stripeAddCustomerPaymentMethodSaga);
  yield fork(takeEvery, types.STRIPE_PM_REMOVE_CUSTOMER_PAYMENT_METHOD_SAGA, stripeRemoveCustomerPaymentMethodSaga);
  yield fork(takeEvery, types.STRIPE_PM_ADD_CUSTOMER_SOURCE_SAGA, stripeAddCustomerSourceSaga);
  yield fork(takeEvery, types.STRIPE_PM_REMOVE_CUSTOMER_SOURCE_SAGA, stripeRemoveCustomerSourceSaga);
  yield fork(takeEvery, types.STRIPE_PM_ADD_PLAID_CUSTOMER_SOURCE_SAGA, stripeAddPlaidCustomerSourceSaga);
}

export default saga;
