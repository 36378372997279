import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import curry from 'lodash/curry';
import map from 'lodash/map';
import t from '@properly/localization';
import { SpaceFlexi } from '@properly/components';
import DateLabel from '../../../components/DateLabel';
import { selectAdminMode } from '../settings/state/SettingsSelectors';
import { selectConfig } from '../../../selectors/globalSelector';
import { handlePermissionActionSaga } from './PermssionsActions';
import { selectTabData } from './permissionSelector';
import PermissionCollItem from './PermissionCollItem';

class PermissionCollListNew extends PureComponent {
  constructor(props) {
    super(props);
    this.curryedHandle = curry(this.props.handlePermissionActionSaga, 3);
  }

  mapGroupIdToTranslation(groupId) {
    switch (groupId) {
      case 'enabled':
        return t('permissions.active_user');
      case 'disabled':
        return t('permissions.disabled_user');
      default:
        return 'Unnamed';
    }
  }

  groupRenderer(data) {
    return (
      <div>
        {map(data, (group, groupId) => (
          <div key={`permission${groupId}`}>
            <DateLabel border>{this.mapGroupIdToTranslation(groupId)}</DateLabel>
            <SpaceFlexi type="vertical" size="12px" />
            {map(group, groupItem => (
              <div key={`groupitem-${groupItem.teamId}-${groupItem.userId}`}>
                <PermissionCollItem
                  renderType={this.props.renderType}
                  data={groupItem}
                  handleAction={this.curryedHandle}
                />
                <SpaceFlexi type="vertical" size="12px" />
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }

  defaultRenderer(data) {
    const {
      renderType,
      dashboardAdminMode,
      config: { properlyRIpartnerEmails },
    } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <SpaceFlexi type="vertical" size="20px" />
        {map(data, (dataItem, index) => (
          <div key={index}>
            <PermissionCollItem
              renderType={renderType}
              data={dataItem}
              handleAction={this.curryedHandle}
              dashboardAdminMode={dashboardAdminMode}
              properlyRIpartnerEmails={properlyRIpartnerEmails}
            />
            <SpaceFlexi type="vertical" size="12px" />
          </div>
        ))}
      </div>
    );
  }

  mapToRenderer(tabName) {
    switch (tabName) {
      case 'shared':
        return this.defaultRenderer(this.props.data.shared);
      case 'external':
        return this.defaultRenderer(this.props.data.external);
      case 'internal':
        return this.groupRenderer(this.props.data.internal);
      default:
        return null;
    }
  }

  render() {
    return this.mapToRenderer(this.props.renderType);
  }
}

export default compose(
  connect(
    () => {
      const memo = selectTabData();
      return state => ({
        data: memo(state),
        dashboardAdminMode: selectAdminMode(state),
        config: selectConfig(state),
      });
    },
    {
      handlePermissionActionSaga,
    },
  ),
)(PermissionCollListNew);
