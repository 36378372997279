import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import t from '@properly/localization';
import { Button, Modal, Title3, Regular } from '@properly/components';

export default class JobRequestPaywallModal extends PureComponent {
  static propTypes = {
    isPaywallModalOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onUpgrade: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isPaywallModalOpen: false,
  };

  render() {
    const { isPaywallModalOpen, onClose, onUpgrade } = this.props;
    const buttonContainerStyle = {
      display: 'flex',
      justifyContent: 'space-around',
      margin: '24px',
    };
    return (
      <Modal type="pricing" onClose={onClose} show={!!isPaywallModalOpen}>
        <div style={{ padding: '0 48px 24px' }}>
          <Title3>{t('job_request.paywall_title')}</Title3>
          <Regular>{t('job_request.paywall_content')}</Regular>
          <div style={buttonContainerStyle}>
            <Button types={['size-large', 'type-full-primary']} onClick={onUpgrade}>
              {t('account.upgrade')}
            </Button>
            <Button types={['size-large', 'type-border-primary']} onClick={onClose}>
              {t('job_request.cancel')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}
