import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import store from 'store';
import t from '@properly/localization';
import { TabBar, DividerLine, SpaceSmallMid, ModalError } from '@properly/components';
import { imageUploadLimit } from '@properly/common';
import head from 'ramda/src/head';
import intersection from 'ramda/src/intersection';
import values from 'lodash/values';
import chunk from 'lodash/chunk';
import isObject from 'lodash/isObject';
import difference from 'lodash/difference';
import flatten from 'lodash/flatten';
import indexOf from 'lodash/indexOf';
import map from 'lodash/map';
import Dropzone from 'react-dropzone';
import classNames from 'classnames/bind';
import styles from '../components/checklistsStyles.module.css';
import * as selectors from '../state/ChecklistSelectors';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import { updateCollectionEntry, setGlobalLoadingState } from '../../../../actions/globalActions';
import * as actions from '../state/ChecklistActions';
import ChecklistOverlay from '../../../../components/ChecklistOverlay/index';
import ChecklistDropbox from '../../../../components/ChecklistDropbox/index';
import ChecklistHeader from '../../../../components/ChecklistHeader/index';
import ChecklistSidebarItem from '../../../../components/ChecklistSidebarItem/index';
import { updateParseProperty } from '../../data';
import PropertyJobPhotos from '../../../../containers/PropertyJobPhotos';
import { mediaUploadLoadingKey } from '../../../../dataConstants';

const cx = classNames.bind(styles);

const { minSize, maxSize } = imageUploadLimit;

// TODO: Rename this PropertyLibraryContainer
class ChecklistUploadContainer extends PureComponent {
  static propTypes = {
    pictureUrls: PropTypes.arrayOf(PropTypes.string),
    propertyId: PropTypes.string.isRequired,

    setOrUpdatePictureSaga: PropTypes.func.isRequired,
    setUploadState: PropTypes.func.isRequired,
    updateCollectionEntry: PropTypes.func.isRequired,
    uploadPicsSaga: PropTypes.func.isRequired,
    pushRoute: PropTypes.func.isRequired,

    fromRoute: PropTypes.bool,
    isGallery: PropTypes.bool,
    onCloseRoute: PropTypes.string,
  };

  static defaultProps = {
    pictureUrls: [],
    onCloseRoute: null,
    fromRoute: false,
    isGallery: false,
  };

  constructor(props) {
    super(props);

    const tabs = this.getTabs();
    const { id } = head(tabs.filter(({ defaultTab: currentDefaultTab }) => currentDefaultTab)) || {};

    this.state = {
      activeTab: id || 'library',
      showImageLimitErrorModal: false,
    };
  }

  onClose = () => {
    const { onCloseRoute, pushRoute, setUploadState } = this.props;

    if (onCloseRoute) {
      pushRoute(onCloseRoute);
    }

    setUploadState({
      isOpen: false,
      do: {},
      selected: [],
    });
  };

  onClickTile = (picture, isSingleSelect) => {
    if (isSingleSelect) {
      this.props.setUploadState({ selected: [picture] });
    } else {
      const isSelected = this.isPictureSelected(picture);
      const cloneArr = this.uploadStateJS.selected.slice(0);
      if (isSelected) {
        const index = this.indexOfSelectedPicture(picture);
        cloneArr.splice(index, 1); // remove
      } else {
        cloneArr.push(picture); // add
      }
      this.props.setUploadState({ selected: cloneArr });
    }
  };

  onDone = () => {
    if (this.isGalleryMode()) {
      this.onClose();
    } else {
      this.props.setOrUpdatePictureSaga();
    }
  };

  onRemove = () => {
    const diff = difference(this.props.pictureUrls, this.uploadStateJS.selected);
    const changeSet = {
      pictureUrls: diff,
    };

    this.props.updateCollectionEntry('properties', this.props.propertyId, changeSet);
    updateParseProperty(this.props.propertyId, changeSet); // set to server
    this.props.setUploadState({ selected: [] });
  };

  onSelect = () => {
    const changeSet = {
      pictureUrl: this.uploadStateJS.selected[0],
    };

    this.props.updateCollectionEntry('properties', this.props.propertyId, changeSet);
    updateParseProperty(this.props.propertyId, changeSet);
    this.onClose();
  };

  onDropRejected = () => {
    this.setState({ showImageLimitErrorModal: true });
  };

  onDropAccepted = files => {
    this.props.uploadPicsSaga(files, this.props.propertyId);
  };

  handleOnCloseErrorModal = () => {
    const { setGlobalLoadingState: setGlobalLoadingAction } = this.props;
    setGlobalLoadingAction(mediaUploadLoadingKey);
  };

  get uploadStateJS() {
    return this.props.uploadState;
  }

  get isSingleSelect() {
    return (
      this.uploadStateJS.do &&
      (this.uploadStateJS.do.mode === 'replace' || this.uploadStateJS.do.mode === 'replacetaskimg')
    );
  }

  get typeHeader() {
    const isGalleryMode = this.isGalleryMode();
    const hasSelectedItems = this.uploadStateJS.selected.length > 0;

    if (isGalleryMode && hasSelectedItems) {
      return 'orangegallery';
    }

    return 'multi';
  }

  isGalleryMode = () => this.uploadStateJS.do.mode === 'gallery' || this.props.isGallery;

  getActiveTab() {
    return head(this.getTabs().filter(tab => tab.isActive()));
  }

  getTabs() {
    const { propertyId } = this.props;

    const PROPERTY_GALLERY_ACTIVE_TAB_KEY = 'PROPERTY_GALLERY_ACTIVE_TAB_KEY';
    const isDefault = tab => store.get(PROPERTY_GALLERY_ACTIVE_TAB_KEY) === tab;

    const onClick = tab => () => {
      store.set(PROPERTY_GALLERY_ACTIVE_TAB_KEY, tab);
      this.setState({ activeTab: tab });
    };

    return [
      {
        defaultTab: isDefault('library'),
        label: 'Property Photos',
        id: 'library',
        isActive: () => this.state.activeTab === 'library',
        onClick: onClick('library'),
        content: () => this.renderPropertyLibrary(),
      },
      {
        defaultTab: isDefault('verification'),
        label: t('job_request.home_assessment'),
        id: 'verification',
        isActive: () => this.state.activeTab === 'verification',
        onClick: onClick('verification'),
        content: () => (
          <PropertyJobPhotos
            isTileSelected={this.isPictureSelected}
            onClickTile={pic => this.onClickTile(pic, this.isSingleSelect)}
            propertyId={propertyId}
          />
        ),
      },
      {
        defaultTab: isDefault('problem'),
        label: t('job_request.problems.problem_reports'),
        id: 'problem',
        isActive: () => this.state.activeTab === 'problem',
        onClick: onClick('problem'),
        content: () => (
          <PropertyJobPhotos
            isTileSelected={this.isPictureSelected}
            onClickTile={pic => this.onClickTile(pic, this.isSingleSelect)}
            noHeaderRow
            problemReportsOnly
            propertyId={propertyId}
          />
        ),
      },
      {
        defaultTab: isDefault('additional'),
        label: t('checklist.other_reports'),
        id: 'additional',
        isActive: () => this.state.activeTab === 'additional',
        onClick: onClick('additional'),
        content: () => (
          <PropertyJobPhotos
            isTileSelected={this.isPictureSelected}
            onClickTile={pic => this.onClickTile(pic, this.isSingleSelect)}
            noHeaderRow
            otherReportsOnly
            propertyId={propertyId}
          />
        ),
      },
    ].filter(({ disabled }) => !disabled);
  }

  indexOfSelectedPicture = ele => indexOf(this.uploadStateJS.selected, ele);

  isPictureSelected = pic => this.indexOfSelectedPicture(pic) !== -1;

  uploadClick = () => this.dropzone.open();

  cancelGallery = () => {
    this.props.setUploadState({ selected: [] });
  };

  renderPicsBig(pictureUrls = [], uploadingPics, onClickTile, getIndexFunc, singleSelect) {
    const cloneArray = pictureUrls.slice(0);
    const mergedArray = flatten([values(uploadingPics), cloneArray]);

    return map(chunk(mergedArray, 6), (row, iRow) => (
      <ChecklistOverlay.GridRow key={iRow} spacing="24">
        {map(row, (pic, iPic) => {
          let content = null;
          const myIndex = getIndexFunc(pic);
          const isSelected = myIndex !== -1;
          if (isObject(pic)) {
            content = (
              <ChecklistSidebarItem noPadding isRawImage image={pic.preview}>
                <ChecklistOverlay.GridItemOverlay hasHover mode="loading" />
              </ChecklistSidebarItem>
            );
          } else if (singleSelect) {
            content = (
              <ChecklistSidebarItem noPadding onClick={() => onClickTile(pic, true)} image={pic}>
                {!isSelected && <ChecklistOverlay.GridItemOverlay hasHover mode="canselect" />}
                {isSelected && <ChecklistOverlay.GridItemOverlay hasHover mode="selected" />}
              </ChecklistSidebarItem>
            );
          } else {
            content = (
              <ChecklistSidebarItem noPadding onClick={() => onClickTile(pic, false)} image={pic}>
                {!isSelected && <ChecklistOverlay.GridItemOverlay hasHover mode="canselect" />}
                {isSelected && <ChecklistOverlay.GridItemOverlay hasHover mode="num" number={myIndex + 1} />}
              </ChecklistSidebarItem>
            );
          }
          return (
            <ChecklistOverlay.GridItem key={iPic} spacing="24" itemPerRow="6">
              {content}
            </ChecklistOverlay.GridItem>
          );
        })}
      </ChecklistOverlay.GridRow>
    ));
  }

  renderPropertyLibrary() {
    return (
      <Dropzone
        id="uploadDropzone"
        disableClick
        accept="image/*"
        maxSize={maxSize}
        minSize={minSize}
        onDropAccepted={this.onDropAccepted}
        onDropRejected={this.onDropRejected}
        ref={node => {
          this.dropzone = node;
        }}
        style={{ padding: '18px' }}
        activeStyle={{}}
        rejectStyle={{}}
        activeClassName={cx('checklist__overlay-white--show')}
      >
        <div className={cx('checklist__dropbox')}>
          <ChecklistDropbox onClick={this.uploadClick} />
        </div>
        <SpaceSmallMid />
        {this.renderPicsBig(
          this.props.pictureUrls,
          this.props.uploadState.uploading,
          this.onClickTile,
          this.indexOfSelectedPicture,
          this.isSingleSelect,
        )}
        <SpaceSmallMid />
        <div className={cx('checklist__overlay-white')} />
      </Dropzone>
    );
  }

  renderTabContent() {
    const activeTab = this.getActiveTab();
    return (activeTab && activeTab.content && activeTab.content()) || null;
  }

  renderTabs() {
    const tabs = this.getTabs();
    return (
      <TabBar>
        {tabs.map(({ label, id, onClick, isActive }) => (
          <TabBar.Item key={id} onClick={onClick} active={isActive()}>
            {label}
          </TabBar.Item>
        ))}
      </TabBar>
    );
  }

  render() {
    const { isGallery, canRemove, imageUploadErrored } = this.props;
    const { typeHeader } = this;
    const { showImageLimitErrorModal } = this.state;

    return (
      <>
        <ChecklistOverlay show={this.uploadStateJS.isOpen || isGallery}>
          <ChecklistOverlay.Header>
            <ChecklistHeader
              type={typeHeader}
              selectedCount={this.uploadStateJS.selected.length}
              onSelect={this.onSelect}
              onRemove={this.onRemove}
              canRemove={canRemove}
              canSelect={this.uploadStateJS.selected.length === 1}
              onCancel={this.cancelGallery}
              onClose={this.onClose}
              onDone={this.onDone}
              text={t('checklist.choose_photos')}
            />
            <DividerLine type={['bottom', 'light']} />
          </ChecklistOverlay.Header>
          <ChecklistOverlay.ContentWrap>
            <ChecklistOverlay.Content style={{ overflowY: 'auto' }}>
              <div style={{ height: '100%' }}>
                {this.renderTabs()}
                {this.renderTabContent()}
              </div>
            </ChecklistOverlay.Content>
          </ChecklistOverlay.ContentWrap>
        </ChecklistOverlay>

        {/* Modal to show for image size limit validation or if upload fails */}
        <ModalError.ImageUpload
          onCloseLimitError={() => this.setState({ showImageLimitErrorModal: false })}
          showLimitError={showImageLimitErrorModal}
          showUploadError={imageUploadErrored}
          onCloseUploadError={this.handleOnCloseErrorModal}
        />
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const { propertyId } = props;

  const properties = selectorsGlobal.selectProperties()(state, props);

  const property = properties && properties.get(propertyId);

  const uploadState = selectors.selectUploadState()(state, props);
  const selectedPictures = (uploadState && uploadState.selected) || [];

  const pictureUrls = props.pictureUrls || (property && property.pictureUrls) || [];

  // Can only remove pictures if they are all selected from the property picture urls
  const selectedAndPropertyPhotos = intersection(selectedPictures, pictureUrls);
  const canRemove = selectedAndPropertyPhotos.length === selectedPictures.length;

  const imageUploadErrored = selectorsGlobal.selectLoadingState(mediaUploadLoadingKey)(state)?.isErrored;

  return {
    pictureUrls,
    property,
    uploadState,
    canRemove,
    imageUploadErrored,
  };
}

export default connect(mapStateToProps, {
  setOrUpdatePictureSaga: actions.setOrUpdatePictureSaga,
  setUploadState: actions.setUploadState,
  uploadPicsSaga: actions.uploadPicsSaga,
  updateCollectionEntry,
  pushRoute: push,
  setGlobalLoadingState,
})(ChecklistUploadContainer);
