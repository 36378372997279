import React, { PureComponent } from 'react';
import t from '@properly/localization';
import { ModalError, Modal, SpaceBase, Button, LoadingSpinner } from '@properly/components';
import { openInNewWindow } from '@properly/common';
import { connect } from 'react-redux';
import * as selectors from './ReportsSelectors';
import { reportsDeleteSaga, reportsSetModal, leaveHookModalSaga } from './ReportsActions';

const centerDownload = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

class ReportsModalsContainer extends PureComponent {
  renderErrorModal() {
    const action = () => this.props.reportsSetModal(undefined);
    return (
      <ModalError
        message1={t('reports.error_cannot_load_report')}
        message2={t('reports.error_cannot_load_report_l2')}
        onClose={action}
        show
      />
    );
  }

  renderLoading() {
    return <Modal type="loading" show />;
  }

  renderSaveModal() {
    return (
      <Modal show onClose={this.cancelSave} height={200}>
        <div>
          <Modal.H1>{t('reports.modal.save_report')}</Modal.H1>
          <Modal.Text>{t('reports.modal.save_report_l2')}</Modal.Text>
          <Modal.Options>
            <Modal.Cancel onClick={this.cancelSave}>{t('reports.modal.cancel')}</Modal.Cancel>
            <Modal.Ok onClick={this.doSave}>{t('reports.modal.save')}</Modal.Ok>
          </Modal.Options>
        </div>
      </Modal>
    );
  }

  renderCSV() {
    const action = () => this.props.reportsSetModal(undefined);
    const { format, status, url } = this.props.meta;
    return (
      <Modal width={450} height={200} show onClose={action}>
        <div>
          <Modal.H1>{t('reports.modal.csv_export', { format })}</Modal.H1>
          <SpaceBase />
          <div style={centerDownload}>
            {status !== 'finished' && (
              <div style={centerDownload}>
                <LoadingSpinner />
                <Modal.Text>{t('reports.modal.processing')}</Modal.Text>
              </div>
            )}
            {status === 'finished' && (
              <Button types={['type-full-primary', 'size-medium']} onClick={() => openInNewWindow(url)}>
                {' '}
                {t('reports.modal.download')}
              </Button>
            )}
          </div>
          <Modal.Options>
            <Modal.Ok onClick={action}>{t('pricing.modalmsgs.cancel_sub_close')}</Modal.Ok>
          </Modal.Options>
        </div>
      </Modal>
    );
  }

  cancelSave = () => this.props.leaveHookModalSaga(false);

  doSave = () => this.props.leaveHookModalSaga(true);

  cancelDelete = () => this.props.reportsDeleteSaga(null, { cancel: true });

  realDelete = () => this.props.reportsDeleteSaga(null, { delete: true });

  renderDeleteModal() {
    return (
      <Modal show onClose={this.cancelDelete} width={400} height={200}>
        <div>
          <Modal.H1>{t('reports.modal.delete_report')}</Modal.H1>
          <Modal.Text>{t('reports.modal.delete_report_l2')}</Modal.Text>
          <Modal.Options>
            <Modal.Cancel onClick={this.cancelDelete}>{t('reports.modal.cancel')}</Modal.Cancel>
            <Modal.Ok onClick={this.realDelete}>{t('reports.modal.delete')}</Modal.Ok>
          </Modal.Options>
        </div>
      </Modal>
    );
  }

  render() {
    if (this.props.modal === 'delete') return this.renderDeleteModal();
    if (this.props.modal === 'loading') return this.renderLoading();
    if (this.props.modal === 'error') return this.renderErrorModal();
    if (this.props.modal === 'save') return this.renderSaveModal();
    if (this.props.modal === 'csv') return this.renderCSV();
    return null;
  }
}

function mapStateToProps(state) {
  return {
    modal: selectors.selectReportsModal()(state),
    meta: selectors.selectReportsModalMeta()(state),
  };
}

export default connect(mapStateToProps, {
  reportsDeleteSaga,
  reportsSetModal,
  leaveHookModalSaga,
})(ReportsModalsContainer);
