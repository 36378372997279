import React from 'react';
import t from '@properly/localization';
import { Input, SpacedHeadline } from '@properly/components';
import InfoMessage from '../../../../components/InfoMessage';

import {
  JobRequestTextMain,
  JobRequestContentWrap,
  JobRequestSpacerWrap,
} from '../../../../components/JobRequestElements/index';

function Message({ finalMessage, handleClickSaga, infoTextMsg }) {
  return (
    <JobRequestContentWrap id="job-request-message-form">
      <JobRequestSpacerWrap left="24px" top="12px" bottom="12px" right="24px" flexGrow>
        <SpacedHeadline>{t('job_request.description')}</SpacedHeadline>
        <Input
          autoFocus
          isFirst
          isLast
          data-key="jobrequestsend_message"
          placeholder={t('job_request.write_description')}
          onChange={e =>
            handleClickSaga(e, {
              action: 'typemessage',
              value: e.target.value || '',
            })
          }
          value={finalMessage}
          type="textarea"
        />
      </JobRequestSpacerWrap>
      <InfoMessage text={infoTextMsg} isPrompt isVcenter isHcenter icon="light" color="white" />
    </JobRequestContentWrap>
  );
}

Message.content = ({ finalMessage }) => <JobRequestTextMain cutText>{finalMessage}</JobRequestTextMain>;

export default Message;
