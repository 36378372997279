import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps, flattenProp } from 'recompose';

import pick from 'lodash/pick';
import t from '@properly/localization';
import { connect } from 'react-redux';
import { JobRequestTopBar } from '../../../components/JobRequestElements';
import { mapToMainState } from './permissionhelper';

class PermissionModalTopBar extends PureComponent {
  static propTypes = {
    actionHandler: PropTypes.func.isRequired,
  };

  render() {
    return (
      <JobRequestTopBar
        canBack={this.props.sectionCanBack}
        backText={t(this.props.sectionBackText)}
        onClickBack={this.props.actionHandler('topbarback')(this.props)}
        canNext={this.props.sectionCanNext}
        nextIsLoading={this.props.nextIsLoading}
        title={t(this.props.sectionTopBarTitle)}
        nextText={t(this.props.sectionNextText)}
        disabledNext={this.props.sectionDisableNext}
        onClickNext={this.props.actionHandler('topbarnext')(this.props)}
      />
    );
  }
}

export default compose(
  connect(
    mapToMainState,
    {},
  ),
  mapProps(props => pick(props, ['activeSectionData', 'actionHandler', 'nextIsLoading'])),
  flattenProp('activeSectionData'),
)(PermissionModalTopBar);
