import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const DateLabel = ({ children, border }) => (
  <div
    className={cx('job-request-list__datebox', {
      'job-request-list__datebox-border': border,
    })}
  >
    <div className={cx('job-request-list__datebox-txt')}>{children}</div>
  </div>
);

DateLabel.propTypes = {
  border: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

DateLabel.defaultProps = {
  border: false,
};

export default DateLabel;
