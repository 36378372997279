import React, { PureComponent } from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { updateIntercom } from '@properly/common';
import { trackPageOpen } from '../../actions/trackingEvents';

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}

class PageLoad extends PureComponent {
  componentDidMount() {
    const isLoggedin = this.props.currentUser.isLoggedIn;
    const role =
      isLoggedin && this.props.currentUser && this.props.currentUser.user
        ? this.props.currentUser.user.role
        : undefined;

    trackPageOpen(isLoggedin, role);

    if (role) {
      updateIntercom(this.props.currentUser.user.objectId);
    }
  }

  render() {
    return null;
  }
}

export default connect(
  mapStateToProps,
  null,
)(PageLoad);
