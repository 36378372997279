import { createSelector } from 'reselect';

export const selectContacts = () => state => state.contacts;

export const selectPaginationState = () =>
  createSelector(selectContacts(), contacts => contacts.get('contactPaginationState'));

export const modalState = () => createSelector(selectContacts(), contacts => contacts.get('modal'));

export const modalData = () => createSelector(selectContacts(), contacts => contacts.get('modalData'));

export const contactSelected = (id, selector) =>
  createSelector(selectContacts(), property => property.getIn([selector || 'contacts', id]));

export const selectContactCompletedSkills = contactId =>
  createSelector(selectContacts(), contacts => contacts.getIn(['contactCompletedSkills', contactId]));
