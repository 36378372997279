import gql from 'graphql-tag';

export const QUERY_GET_FEATURES = gql`
  query getFeaturesQuery {
    getFeatures {
      name
      title
      children
      countryCodes
      enabled
    }
  }
`;

export const QUERY_GET_FEATURE = gql`
  query getFeatureQuery($featureName: String!) {
    getFeature(featureName: $featureName) {
      name
      title
      children
      countryCodes
      enabled
    }
  }
`;

export const QUERY_GET_USER_FEATURES = gql`
  query getUserFeaturesQuery {
    getUserFeatures {
      name
      title
      children
      countryCodes
      enabled
    }
  }
`;

export const QUERY_IS_FEATURE_ACTION_ALLOWED_FOR_USER = gql`
  query isFeatureActionAllowedForUserQuery($propertyId: String, $featureName: String!, $actionName: String!) {
    isFeatureActionAllowedForUser(propertyId: $propertyId, featureName: $featureName, actionName: $actionName)
  }
`;
