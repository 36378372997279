import { put, fork, call, takeEvery } from 'redux-saga/effects';
import { getCurrentCountryByIp } from '@properly/common';
import log from 'loglevel';
import { Icon } from '@properly/components';
import { push } from 'react-router-redux';
import each from 'lodash/each';
import reduce from 'lodash/reduce';
import omit from 'lodash/omit';
import { fetchParseConfig } from '../../modules/desktopApp/data.http';
import { logout } from '../../modules/desktopApp/account/state/AccountActions';
import * as types from '../../types';
import {
  setParseConfig,
  setGeneralConfig,
  preLoginSuccess,
  foundCurrentCountryByIp,
} from '../../actions/globalActions';
import { approveRedirect } from '../../helper/herbert';
import { ROUTES } from '../../paths';

// Labels can be either an Icon or hosted svg image
const defaultLabel = {
  logoLogin: Icon.IcDeskstopiconwhite,
  logoApp: Icon.Logo,
};

function reduceDomainsToConfig(array) {
  return reduce(
    array,
    (acc, val) => {
      each(val.domains, domain => {
        acc[domain] = omit(val, ['domain']);
      });
      return acc;
    },
    {},
  );
}

function mapDomainToLabeling(domain, whitelabelConfig) {
  if (!whitelabelConfig) {
    return defaultLabel;
  }
  const domainsToConfigMapped = reduceDomainsToConfig(whitelabelConfig);
  if (domainsToConfigMapped[domain]) {
    return domainsToConfigMapped[domain];
  }
  return defaultLabel;
}

function* setParseConfigSaga() {
  try {
    const config = yield fetchParseConfig();

    yield put(setParseConfig(config));
    yield put(setGeneralConfig(mapDomainToLabeling(window.location.hostname, config.whiteLabelDomains)));
  } catch (e) {
    // TODO: Config in unauthenticated. We should be using the User refresh to trigger this logout
    // logout if the first request on page load says invalid session token
    log.error('setParseConfig', e);

    if (e && e.code === 209) {
      yield put(logout());
      return;
    }

    throw e;
  }
}

function* preLoginSaga() {
  try {
    yield [call(setParseConfigSaga), call(getCountryByIp)];
    yield put(preLoginSuccess());
  } catch (e) {
    log.error('preLoginSaga', e);
    yield put(push(approveRedirect({ pathname: ROUTES.error })));
  }
}

function* getCountryByIp() {
  try {
    const res = yield call(getCurrentCountryByIp);
    yield put(foundCurrentCountryByIp(res));
  } catch (e) {
    log.error('getCountryByIp', e);
  }
}

function* saga() {
  yield fork(takeEvery, types.GLOBAL_PRE_LOGIN_SAGA, preLoginSaga);
}

export default saga;
