import React from 'react';
import t from '@properly/localization';
import { Icon, HoverTriggeredTooltip } from '@properly/components';

import indexOf from 'lodash/indexOf';

const returnStateData = status => {
  switch (status) {
    case 2: {
      return {
        txt: t('jobdetails.servicep_not_at_location'),
        icon: Icon.IcNotAtLocation,
      };
    }
    case 1: {
      return {
        txt: t('jobdetails.servicep_at_location'),
        icon: Icon.IcAtLocation,
      };
    }
    default: {
      return {
        txt: t('jobdetails.servicep_undef_location'),
        icon: Icon.IcUndefAtLocation,
      };
    }
  }
};

const renderIcon = status => {
  if (indexOf([1, 2, 0], status) === -1) return null;
  const LocationIconComponent = returnStateData(status).icon;

  return (
    <HoverTriggeredTooltip title={returnStateData(status).txt}>
      <LocationIconComponent />
    </HoverTriggeredTooltip>
  );
};

const LocationIcon = ({ status }) => <span>{renderIcon(status)}</span>;

export default LocationIcon;
