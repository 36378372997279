import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { error } from '@properly/config';
import { Input, SpaceSmall, Button } from '@properly/components';
import { splitCountryCode, countryPhoneOptions } from '@properly/common';
import t from '@properly/localization';
import { parseSendToMobileHost } from '../desktopApp/data';
import PhoneNumberLoader from '../../containers/PhoneNumberLoader';

function validate(errors) {
  let phoneHasError = false;
  let phoneErrorMessage = null;
  if (errors && errors.indexOf) {
    if (errors.indexOf(error.UNKNOWN) > -1) {
      phoneHasError = true;
      phoneErrorMessage = t('signup.error.cloud_code_error', {});
    } else {
      if (errors.indexOf(error.PHONE_NOT_UNIQUE) > -1) {
        phoneHasError = true;
        phoneErrorMessage = t('signup.error.phone_registered', {});
      }
      if (errors.indexOf(error.INVALID_PHONE) > -1) {
        phoneHasError = true;
        phoneErrorMessage = t('signup.error.phone_invalid', {});
      }
    }
  }
  return { phoneHasError, phoneErrorMessage };
}

class SendLinkToPhoneContainer extends Component {
  state = {
    phoneCountryCode: undefined,
    phoneRegionalNumber: '',
    errors: [],
    isLoading: false,
  };

  onUpdatePhone = phoneRegionalNumber => {
    this.setState(state => ({
      ...state,
      errors: [],
      phoneRegionalNumber,
    }));
  };

  onUpdatePhoneCode = phoneCountryCode => {
    this.setState(state => ({
      ...state,
      errors: [],
      phoneCountryCode,
    }));
  };

  handleOnSubmit = event => {
    event.preventDefault();
    this.setState(state => ({ ...state, isLoading: true }));
    parseSendToMobileHost(this.state.phoneCountryCode, this.state.phoneRegionalNumber)
      .then(user => {
        this.setState(state => ({ ...state, isLoading: false }));
        this.props.onSuccess(user);
      })
      .catch(err => {
        this.setState(state => ({
          ...state,
          errors: [err.properlyErrorMsg],
          isLoading: false,
        }));
      });
  };

  render() {
    const { phoneHasError, phoneErrorMessage } = validate(this.state.errors);
    return (
      <PhoneNumberLoader currentPhoneCode={this.state.phoneCountryCode} setter={this.onUpdatePhoneCode}>
        {() => (
          <form onSubmit={this.handleOnSubmit}>
            <Input.TwoSplit type="bigger">
              <Input
                twoSplit="leftsmall"
                type="select"
                style={{ color: '#36ACB2' }}
                options={countryPhoneOptions}
                onChange={e => this.onUpdatePhoneCode(e.target.value)}
                transformSelectTitle={splitCountryCode}
                error={false}
                isFirst
                isLast
                styleOverwrite={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
                value={this.state.phoneCountryCode}
              />
              <Input
                twoSplit="rightbig"
                type="text"
                onChange={e => this.onUpdatePhone(e.target.value)}
                placeholder="(201) 555-5555"
                error={phoneHasError}
                isFirst
                isLast
                styleOverwrite={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                value={this.state.userRegionalNumber}
              />
            </Input.TwoSplit>
            {phoneHasError && <Input.ErrorMsg>{phoneErrorMessage}</Input.ErrorMsg>}
            <SpaceSmall />
            <Button
              disabled={this.state.isLoading}
              isLoading={this.state.isLoading}
              onClick={this.handleOnSubmit}
              types={['type-full-primary', 'width-flex', 'size-large']}
            >
              {t('signup.download.cta', {})}
            </Button>
          </form>
        )}
      </PhoneNumberLoader>
    );
  }
}

SendLinkToPhoneContainer.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default SendLinkToPhoneContainer;
