import React from 'react';
import { Route, IndexRoute } from 'react-router';
import { setWindowTitle } from '@properly/common';

import ChecklistsContainerRoute from './modules/desktopApp/checklists/containers/ChecklistsContainerRoute';

export default () => (
  <Route path="checklists" component={ChecklistsContainerRoute} onEnter={setWindowTitle('titles.checklist')}>
    <IndexRoute component={ChecklistsContainerRoute} />
    <Route path=":checklistId/:jobInstructionId/:id" component={ChecklistsContainerRoute} />
    <Route path=":checklistId/:jobInstructionId/" component={ChecklistsContainerRoute} />
  </Route>
);
