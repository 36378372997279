"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.JOB_ALREADY_SCHEDULED = exports.CANNOT_SEND_JOB_TO_COMMUNITY_SP_FROM_SAMPLE_PROPERTY = exports.OAUTH_ALREADY_CONNECTED = exports.DIFFERENT_ACCOUNT_RECONNECT = exports.ACCOUNT_UNAVAILABLE_RECONNECT = exports.ACCOUNT_ALREADY_CONNECTED = exports.NOT_IMPLEMENTED = exports.RECONNECT_FAILED = exports.SERVICE_FAILED = exports.FATAL_ERROR = exports.INVALID_SESSION = exports.ACCOUNT_DISABLED = exports.EMAIL_NOT_FOUND = exports.COULD_NOT_LOGIN = exports.PASSWORD_MISSING = exports.LAST_NAME_MISSING = exports.FIRST_NAME_MISSING = exports.INVALID_USERNAME = exports.INVALID_PHONE = exports.INVALID_EMAIL = exports.FAILED_RECAPTCHA_VERIFICATION = exports.PHONE_IS_NOT_UNIQUE = exports.PHONE_NOT_UNIQUE = exports.EMAIL_DUPLICATE = exports.EMAIL_NOT_UNIQUE = exports.UNKNOWN = void 0;
var UNKNOWN = 'CloudCodeError'; // camel case error codes match backend error codes
// SIGN UP CLOUD ERRORS

exports.UNKNOWN = UNKNOWN;
var EMAIL_NOT_UNIQUE = 'EmailNotUnique';
exports.EMAIL_NOT_UNIQUE = EMAIL_NOT_UNIQUE;
var EMAIL_DUPLICATE = 'DuplicateEmailUserFound';
exports.EMAIL_DUPLICATE = EMAIL_DUPLICATE;
var PHONE_NOT_UNIQUE = 'DuplicatePhoneUserFound';
exports.PHONE_NOT_UNIQUE = PHONE_NOT_UNIQUE;
var PHONE_IS_NOT_UNIQUE = 'PhoneNumberNotUnique';
exports.PHONE_IS_NOT_UNIQUE = PHONE_IS_NOT_UNIQUE;
var FAILED_RECAPTCHA_VERIFICATION = 'FailedReCaptchaVerification'; // FORM VALIDATION ERRORS

exports.FAILED_RECAPTCHA_VERIFICATION = FAILED_RECAPTCHA_VERIFICATION;
var INVALID_EMAIL = 'InvalidEmailAddress';
exports.INVALID_EMAIL = INVALID_EMAIL;
var INVALID_PHONE = 'InvalidPhone';
exports.INVALID_PHONE = INVALID_PHONE;
var INVALID_USERNAME = 'UsernameNotReadable';
exports.INVALID_USERNAME = INVALID_USERNAME;
var FIRST_NAME_MISSING = 'FirstNameMissing';
exports.FIRST_NAME_MISSING = FIRST_NAME_MISSING;
var LAST_NAME_MISSING = 'LastNameMissing';
exports.LAST_NAME_MISSING = LAST_NAME_MISSING;
var PASSWORD_MISSING = 'PasswordMissing';
exports.PASSWORD_MISSING = PASSWORD_MISSING;
var COULD_NOT_LOGIN = 'CouldNotLogIn';
exports.COULD_NOT_LOGIN = COULD_NOT_LOGIN;
var EMAIL_NOT_FOUND = 'UserNotFoundEmail';
exports.EMAIL_NOT_FOUND = EMAIL_NOT_FOUND;
var ACCOUNT_DISABLED = 'DisabledByOwner';
exports.ACCOUNT_DISABLED = ACCOUNT_DISABLED;
var INVALID_SESSION = 'InvalidSession'; // ADAPTOR ERRORS

exports.INVALID_SESSION = INVALID_SESSION;
var FATAL_ERROR = 'FATAL_ERROR';
exports.FATAL_ERROR = FATAL_ERROR;
var SERVICE_FAILED = 'SERVICE_FAILED';
exports.SERVICE_FAILED = SERVICE_FAILED;
var RECONNECT_FAILED = 'RECONNECT_FAILED';
exports.RECONNECT_FAILED = RECONNECT_FAILED;
var NOT_IMPLEMENTED = 'NOT_IMPLEMENTED';
exports.NOT_IMPLEMENTED = NOT_IMPLEMENTED;
var ACCOUNT_ALREADY_CONNECTED = 'ACCOUNT_ALREADY_CONNECTED';
exports.ACCOUNT_ALREADY_CONNECTED = ACCOUNT_ALREADY_CONNECTED;
var ACCOUNT_UNAVAILABLE_RECONNECT = 'ACCOUNT_UNAVAILABLE_RECONNECT';
exports.ACCOUNT_UNAVAILABLE_RECONNECT = ACCOUNT_UNAVAILABLE_RECONNECT;
var DIFFERENT_ACCOUNT_RECONNECT = 'DIFFERENT_ACCOUNT_RECONNECT';
exports.DIFFERENT_ACCOUNT_RECONNECT = DIFFERENT_ACCOUNT_RECONNECT;
var OAUTH_ALREADY_CONNECTED = 'CanNotConnectPartnerUser'; // Custom graphQL errors

exports.OAUTH_ALREADY_CONNECTED = OAUTH_ALREADY_CONNECTED;
var CANNOT_SEND_JOB_TO_COMMUNITY_SP_FROM_SAMPLE_PROPERTY = 'CANNOT_SEND_JOB_TO_COMMUNITY_SP_FROM_SAMPLE_PROPERTY';
exports.CANNOT_SEND_JOB_TO_COMMUNITY_SP_FROM_SAMPLE_PROPERTY = CANNOT_SEND_JOB_TO_COMMUNITY_SP_FROM_SAMPLE_PROPERTY;
var JOB_ALREADY_SCHEDULED = 'JOB_ALREADY_SCHEDULED';
exports.JOB_ALREADY_SCHEDULED = JOB_ALREADY_SCHEDULED;