"use strict";

require("core-js/modules/es.date.now");

require("core-js/modules/es.date.to-string");

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.split");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getBuild;

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function getBuild() {
  var timestamp = process.env.REACT_APP_TIMESTAMP && Number(process.env.REACT_APP_TIMESTAMP) * 1000 || Date.now();
  var currentHostname = typeof window !== 'undefined' && window.location.hostname || '';
  var currentSubDomain = currentHostname.split('.')[0];
  return {
    _versions_: getVersions(),
    _build_: {
      buildDate: timestamp,
      buildTimeFromNow: (0, _momentTimezone["default"])(timestamp).fromNow(),
      buildTimestamp: (0, _momentTimezone["default"])(timestamp).format('z MMM Do YYYY, h:mm a'),
      buildGitBranch: process.env.REACT_APP_GIT_BRANCH,
      buildGitTag: process.env.REACT_APP_GIT_TAG,
      buildGitCommit: process.env.REACT_APP_GIT_COMMIT,
      buildGitHash: process.env.REACT_APP_GIT_HASH,
      buildBuildNumber: process.env.REACT_APP_BUILD_NUMBER,
      buildSubDomain: currentSubDomain
    }
  };
}

function getVersions() {
  try {
    var versions = require('./versions.json'); // eslint-disable-line


    return versions;
  } catch (err) {
    return [];
  }
}