import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Portal } from 'react-portal';
import Checklists from './ChecklistsContainerConnected';
import ChecklistOverlay from '../../../../components/ChecklistOverlay/index';
import { selectModalData } from '../state/ChecklistSelectors';

class ChecklistContainerModal extends PureComponent {
  render() {
    return (
      <Portal isOpened={this.props.modalData.get('isOpen')}>
        <ChecklistOverlay isRoot zIndex="1080" background="dark" show={this.props.modalData.get('isOpen')}>
          <Checklists
            checklistId={this.props.modalData.getIn(['meta', 'checklistId'])}
            jobInstructionId={this.props.modalData.getIn(['meta', 'jobInstructionId'])}
          />
        </ChecklistOverlay>
      </Portal>
    );
  }
}

function mapStateToProps(state) {
  return {
    modalData: selectModalData()(state),
  };
}

export default connect(
  mapStateToProps,
  {},
)(ChecklistContainerModal);
