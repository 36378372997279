import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { DropTarget } from 'react-dnd';
import { findDOMNode } from 'react-dom';
import debounce from 'lodash/debounce';

const debouncedReset = debounce((props, monitor) => {
  props.setIsDragging(monitor.getItem(), false);
}, 3000);

const cardTarget = {
  drop(props, monitor, component) {
    // Determine rectangle on screen
    // eslint-disable-next-line
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();
    if (clientOffset) {
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      props.setPosition(monitor.getItem(), hoverClientY, hoverClientX);
    }
    props.setIsDragging(monitor.getItem(), false);
  },
  hover(props, monitor) {
    props.setIsDragging(monitor.getItem(), true);
    debouncedReset(props, monitor);
  },
};

class ChecklistImageDragarea extends PureComponent {
  static propTypes = {
    connectDropTarget: PropTypes.func.isRequired,
    setPosition: PropTypes.func.isRequired, // eslint-disable-line
    setIsDragging: PropTypes.func.isRequired, // eslint-disable-line
  };

  render() {
    const { connectDropTarget, children } = this.props;
    return connectDropTarget(<div>{children}</div>);
  }
}

export default compose(
  DropTarget('PIN', cardTarget, connect => ({
    connectDropTarget: connect.dropTarget(),
  })),
)(ChecklistImageDragarea);
