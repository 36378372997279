import React from 'react';
import chunk from 'lodash/chunk';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import { SearchField, SpaceLarge, LoadingSplash, Icon, PopoverDefault, ButtonList } from '@properly/components';
import t from '@properly/localization';
import CleanerListTags from '../../../../components/CleanerListTags/index';
import InfoMessage from '../../../../components/InfoMessage/index';
import Sidebar from '../../../../components/Sidebar/index';

import {
  JobRequestTextMain,
  JobRequestTextSub,
  JobRequestPropertyInfo,
  JobRequestCheckableHover,
  JobRequestContentWrap,
  JobRequestSpacerWrap,
  JobRequestIcon,
  JobRequestCheckableBox,
} from '../../../../components/JobRequestElements/index';
import { genSkillSubtitle } from '../../../../helper/herbert';
import ChecklistOverlay from '../../../../components/ChecklistOverlay/index';
import LibraryNoSkillsAccess from '../../library/containers/LibraryNoSkillsAccess';

const Skills = ({
  finalSkills,
  selectSkill,
  loadingStateSkill,
  searchQuery,
  skills,
  setSearchQuery,
  infoTextMsg,
  hasSkillsAccess,
}) => {
  const isLoading = loadingStateSkill;
  const finalSkillsJS = finalSkills?.toJS();
  // we make an object that will be useful for selecting ,numbering
  const selected = finalSkillsJS.reduce((accObject, skill, iterator) => {
    // eslint-disable-next-line no-param-reassign
    accObject[skill.skillId] = iterator;
    return accObject;
  }, {});

  if (!hasSkillsAccess) {
    return (
      <JobRequestSpacerWrap left="24px" top="12px" bottom="12px" right="24px">
        <LibraryNoSkillsAccess />
      </JobRequestSpacerWrap>
    );
  }

  return (
    <>
      <JobRequestContentWrap id="job-request-skills-form">
        <JobRequestContentWrap id="job-request-skills-form">
          <JobRequestSpacerWrap left="24px" top="12px" bottom="12px" right="24px">
            <SearchField
              onGrey
              data-key="jobrequestsend_search_skills"
              placeholder={t('skills.search')}
              value={searchQuery}
              iconLocation="left"
              onChange={value => setSearchQuery('jobrequest_searchskills', value)}
            />
          </JobRequestSpacerWrap>
          <Sidebar.List>
            <Sidebar.ListInner>
              <Sidebar.ListInnerInner enableOverflow>
                <JobRequestSpacerWrap left="24px" top="0px" bottom="12px" right="24px">
                  {isLoading && (
                    <div>
                      <SpaceLarge />
                      <LoadingSplash static />
                      <SpaceLarge />
                    </div>
                  )}

                  {map(chunk(skills, 2), (row, iRow) => (
                    <ChecklistOverlay.GridRow key={iRow} spacing="12">
                      {map(row, (skill, key) => {
                        const isActive = selected[skill.skillId] !== undefined;
                        return (
                          <ChecklistOverlay.GridItem key={key} spacing="12" itemPerRow="2">
                            <JobRequestCheckableHover
                              number={Number(selected[skill.skillId]) + 1}
                              data-key={`jobrequestsend_skillitem-${skill.skillId}`}
                              onChange={() => selectSkill(skill.skillId)}
                              isActive={isActive}
                            >
                              <JobRequestPropertyInfo
                                picType="custom"
                                order={['pic', 'text']}
                                txtMod={{ withpic: true }}
                                l1={skill.title}
                                l2={genSkillSubtitle(skill)}
                                pic={<JobRequestIcon icon={isActive ? Icon.IcBigJobsOrange : Icon.IcBigJobs} />}
                              />
                            </JobRequestCheckableHover>
                          </ChecklistOverlay.GridItem>
                        );
                      })}
                    </ChecklistOverlay.GridRow>
                  ))}
                </JobRequestSpacerWrap>
              </Sidebar.ListInnerInner>
            </Sidebar.ListInner>
          </Sidebar.List>
          <InfoMessage text={infoTextMsg} isPrompt isVcenter isHcenter icon="light" color="white" />
        </JobRequestContentWrap>
      </JobRequestContentWrap>
    </>
  );
};

const mapLeftOverSkills = leftOverSkills =>
  leftOverSkills
    .filter(skill => skill)
    .map((skill, index) => ({
      'data-key': skill.objectId,
      name: skill.title,
      secondaryName: genSkillSubtitle(skill),
      icon: <JobRequestCheckableBox isActive number={parseInt(index, 10) + 2} />,
    }));

const seeMoreSkillsContent = leftOverSkills => (
  <ButtonList isButton={false} hasDivider isDenseStyle items={mapLeftOverSkills(leftOverSkills)} />
);

function wrapperSeeMoreSkills({ children, leftOverCleaners: leftOverSkills }) {
  return (
    <PopoverDefault
      popoverKey="viewSelectedSkills"
      width={240}
      hasArrow
      popoverContent={() => seeMoreSkillsContent(leftOverSkills)}
    >
      <span>{children}</span>
    </PopoverDefault>
  );
}

// eslint-disable-next-line react/no-multi-comp
const SkillsContent = ({ skillTitle, finalSkills, allSkills, skillSubtitle }) => (
  <>
    <CleanerListTags
      type="simple"
      limit={1}
      wrapperComponent={wrapperSeeMoreSkills}
      items={finalSkills.map(skill => allSkills.get(skill?.get && skill?.get('skillId')))}
    >
      <JobRequestTextMain key={`title${skillTitle}`} cutText>
        {skillTitle}
      </JobRequestTextMain>
      <JobRequestTextSub>{skillSubtitle}</JobRequestTextSub>
    </CleanerListTags>
  </>
);

Skills.propTypes = {
  finalSkills: PropTypes.shape({}),
  selectSkill: PropTypes.func.isRequired,
  loadingStateSkill: PropTypes.bool,
  searchQuery: PropTypes.string,
  skills: PropTypes.arrayOf(PropTypes.shape({})),
  setSearchQuery: PropTypes.func.isRequired,
  infoTextMsg: PropTypes.string.isRequired,
};

SkillsContent.propTypes = {
  skillTitle: PropTypes.string,
  finalSkills: PropTypes.shape({}),
  allSkills: PropTypes.shape({}),
};

Skills.defaultProps = {
  finalSkills: {},
  loadingStateSkill: false,
  searchQuery: '',
  skills: [],
};

SkillsContent.defaultProps = {
  finalSkills: {},
  skillTitle: '',
  allSkills: {},
};

Skills.content = SkillsContent;

export default Skills;
