import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import startsWith from 'lodash/startsWith';
import forOwn from 'lodash/forOwn';

export default class Trigger {
  constructor(objectId, title, propertyId, jobId, type, deleted, updatedAt, createdAt, settings) {
    this.objectId = objectId;
    this.title = title;
    this.propertyId = propertyId;
    this.jobId = jobId;
    this.type = type;
    this.deleted = deleted;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.settings = settings;
  }

  getOwnProps(scope, onScope) {
    const obj = onScope || {};
    forOwn(scope, (value, key) => {
      obj[key] = value;
    });
    return obj;
  }

  get hash() {
    return reduce(pick(this, ['title', 'jobId', 'type']), (acc, val) => acc + val, '');
  }

  get isUnsaved() {
    return startsWith(this.objectId, 'new');
  }

  setData(obj) {
    this.getOwnProps(obj, this);
  }

  getData() {
    return this.getOwnProps(this);
  }
}
