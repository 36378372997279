import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { Icon } from '@properly/components';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const SmallStepsList = ({ title, steps, size }) => (
  <div
    className={cx('small-steps__txt', {
      'small-steps__txt--big': size === 'big',
    })}
  >
    <div className={cx('small-steps__head')}>{title}</div>
    <ul className={cx('small-steps__ul')}>
      {map(steps, (item, i) => (
        <li key={i}>
          <Icon.IcItemCheck2 width="1.2em" height="1.2em" />
          <span style={{ marginLeft: '4px' }}>{item.note}</span>
        </li>
      ))}
    </ul>
  </div>
);

SmallStepsList.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      note: PropTypes.string,
    }),
  ),
};

export default SmallStepsList;
