import { delay } from 'redux-saga';
import { put, fork, select, call, takeEvery, takeLatest } from 'redux-saga/effects';
import log from 'loglevel';
import { countryToDialCode } from '@properly/common';
import * as types from '../../../../types/index';
import * as selectors from './ContactSelectors';
import {
  updateModalState,
  updateModalData,
  resetModalData,
  goToContact,
  goToContacts,
  saveCompletedSkillsToStore,
} from './ContactActions';
import { resendInvite, addContact, updateParseContactTags } from '../../data';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import { updateCollectionEntry, setModal } from '../../../../actions/globalActions';
import {
  trackInviteExistingContact,
  trackInvitNewContact,
  trackInvitAddedSuccess,
} from '../../../../actions/trackingEvents';
import { modals } from '../../../../dataConstants';
import { addContactByIdGraphql, removeContactByIdGraphql, findContactGraphql } from '../../../../graphql/api/contacts';
import { selectCurrentCountryCode } from '../../settings/state/SettingsSelectors';
import { getCompletedSkills } from '../../../../graphql/api/skills';

function* addContactSaga() {
  const selectC = state => selectors.modalData()(state);
  const modalDataRaw = yield select(selectC);
  const modalData = modalDataRaw.toJS();

  try {
    trackInvitNewContact();
    yield put(
      updateModalState({
        view: 5,
      }),
    );

    const contact = yield call(addContact, {
      phoneRegionalNumber: modalData.phoneRegionalNumber || modalData.phoneNumber || undefined,
      phoneCountryCode: modalData.phoneCountryCode || undefined,
      firstName: modalData.firstName?.trim(),
      lastName: modalData.lastName?.trim(),
      email: modalData.email?.trim(),
    });
    yield put(
      updateModalState({
        view: 3,
      }),
    );
    yield put(updateCollectionEntry('contacts', contact.objectId, contact)); // save new contact in store
    yield put(goToContact(contact.objectId)); // go to contact page
    trackInvitAddedSuccess();
    yield put(resetModalData());
  } catch (e) {
    yield put(
      updateModalState({
        view: 2,
        hasError: true,
      }),
    );
    log.error('addContactSaga', e);
  }
}

function* prefillUserData(action) {
  if (action && action.meta && action.meta.objectId) {
    const selectCRaw = state => selectorsGlobal.selectContact(action.meta.objectId)(state);
    const selectedContact = yield select(selectCRaw);
    const base = {
      firstName: selectedContact.userData.firstName,
      lastName: selectedContact.userData.lastName,
    };
    if (selectedContact.userData.email) {
      base.email = selectedContact.userData.email;
    }
    if (selectedContact.userData.phoneCountryCode) {
      base.phoneCountryCode = selectedContact.userData.phoneCountryCode;
    }
    if (selectedContact.userData.phoneRegionalNumber) {
      base.phoneNumber = selectedContact.userData.phoneRegionalNumber;
    }
    yield put(updateModalData(base));
  }
}

function* resendInviteSaga(action) {
  const selectC = state => selectors.modalData()(state);
  const modalDataRaw = yield select(selectC);
  const modalData = modalDataRaw.toJS();

  try {
    yield put(
      updateModalState({
        view: 5,
      }),
    );
    const contact = yield call(resendInvite, {
      contactId: action.contactId,
      firstName: modalData.firstName?.trim(),
      lastName: modalData.lastName?.trim(),
      phoneCountryCode: modalData.phoneCountryCode || undefined,
      phoneRegionalNumber: modalData.phoneNumber || undefined,
      email: modalData.email?.trim() || undefined,
    });
    yield put(updateCollectionEntry('contacts', contact.objectId, contact)); // save new contact in store
    yield put(
      updateModalState({
        view: 3,
      }),
    );
  } catch (e) {
    yield put(
      updateModalState({
        view: 6,
        hasError: true,
      }),
    );
    log.error('resendInviteSaga', e);
  }
}

function* freshInviteSaga(action) {
  try {
    const { inviteData } = action || {};

    // reset error
    yield put(
      updateModalState({
        hasError: false,
      }),
    );

    yield put(
      updateModalState({
        view: 5,
      }),
    );

    // calling find contact graphql service
    const res = yield call(findContactGraphql, inviteData);

    const { userId } = res || {};

    if (userId === null) {
      yield put(
        updateModalState({
          view: 2,
        }),
      );

      // Make sure the data is updated with what they requested
      yield put(
        updateModalData({
          email: undefined,
          phoneNumber: undefined,
          phoneCountryCode: undefined,
          ...inviteData,
        }),
      );
    } else {
      trackInviteExistingContact();

      // calling graphql with userid
      const newContact = yield call(addContactByIdGraphql, userId);

      yield put(updateCollectionEntry('contacts', newContact.objectId, newContact)); // save new contact in store
      yield put(
        updateModalData({
          // we need this data for the next modal view
          firstName: newContact.userData.firstName,
          lastName: newContact.userData.lastName,
          // Update all other user data also
          email: newContact.userData.email,
          phoneNumber: newContact.userData.phoneRegionalNumber || newContact.userData.phoneNumber,
          phoneCountryCode: newContact.userData.phoneCountryCode,
        }),
      );
      yield put(
        updateModalState({
          view: 4,
        }),
      );
      yield put(goToContact(newContact.objectId)); // go to contact page
      trackInvitAddedSuccess();
    }
  } catch (e) {
    yield put(
      updateModalState({
        view: 1,
        hasError: true,
      }),
    );
    log.error('freshInviteSaga', e);
  }
}

function* closeModalSaga() {
  yield put(
    updateModalData({
      firstName: '',
      lastName: '',
      phoneCountryCode: '',
      phoneNumber: '',
      email: '',
    }),
  );
  yield put(
    updateModalState({
      hasError: false,
      isOpen: false,
      showErrors: false,
    }),
  );
  yield delay(1000); // wait for animation to finish to change the view
  yield put(
    updateModalState({
      view: 1,
    }),
  );
}

function* addPhoneCode(action) {
  if (action.data && action.data.view && (action.data.view === 6 || action.data.view === 1) && action.data.isOpen) {
    const foundCode = yield select(selectCurrentCountryCode);
    yield put(
      updateModalData({
        phoneCountryCode: countryToDialCode(foundCode, '+1'),
      }),
    );
  }
}

function* deleteContactSaga(action) {
  try {
    yield put(setModal(true, modals.LOADING_MODAL, {}));
    const res = yield call(removeContactByIdGraphql, action.contactId);
    yield put(setModal(false, modals.LOADING_MODAL, {}));
    yield put(goToContacts());
    yield put(updateCollectionEntry('contacts', res.objectId, res));
  } catch (e) {
    yield put(setModal(false, modals.LOADING_MODAL, {}));
    yield put(setModal(true, modals.CONTACT_DELETE_MODAL, {}));
  }
}

function* updateTags({ contactId, tags }) {
  try {
    const response = yield call(updateParseContactTags, contactId, tags);
    yield put(updateCollectionEntry('contacts', response.objectId, response));
  } catch (err) {
    log.error('Error updating contact tags', contactId, tags);
  }
}

function* getCompletedSkill({ contactId }) {
  const selectedContact = yield select(selectorsGlobal.selectContact(contactId));
  if (selectedContact?.userData.signedUp) {
    const allCompletedSkillsIds = yield call(getCompletedSkills, selectedContact.contactId);

    if (!allCompletedSkillsIds.apiError) {
      yield put(saveCompletedSkillsToStore(contactId, allCompletedSkillsIds));
    }
  }
}

function* saga() {
  yield fork(takeEvery, types.CONTACTS_RESEND_INVITE_SAGA, resendInviteSaga);
  yield fork(takeEvery, types.CONTACTS_SET_CONTACT_MODAL, addPhoneCode);
  yield fork(takeEvery, types.CONTACTS_SET_CONTACT_MODAL, prefillUserData);
  yield fork(takeEvery, types.CONTACTS_FRESH_INVITE_SAGA, freshInviteSaga);
  yield fork(takeEvery, types.CONTACTS_ADD_CONTACT_SAGA, addContactSaga);
  yield fork(takeEvery, types.CONTACTS_CLOSE_MODAL_SAGA, closeModalSaga);
  yield fork(takeEvery, types.CONTACTS_DELETE_CONTACT, deleteContactSaga);
  yield fork(takeLatest, types.CONTACT_GET_COMPLETED_SKILL_SAGA, getCompletedSkill);

  yield fork(takeLatest, types.CONTACTS_UPDATE_TAGS, function* delayContactsTagUpdate(action) {
    yield delay(200);
    yield updateTags(action);
  });
}

export default saga;
