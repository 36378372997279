import lowerCase from 'lodash/lowerCase';

export function mapServerDataToLocalData(data) {
  return {
    firstname: data.firstName || '',
    lastname: data.lastName || '',
    email: data.email || '',
    company: data.company || '',
    billingaddress: data.address1 || '',
    country: lowerCase(data.country),
    address2: data.address2 || '',
    coupon: data.couponCode || '',
    taxnumber: data.vatNumber || '',
    city: data.city || '',
    state: data.state || '',
    zip: data.zip || '',
    lastFour: data.lastFour,
  };
}

export function genObjForToken(resolver) {
  return {
    first_name: resolver('firstname'),
    last_name: resolver('lastname'),
    address1: resolver('billingaddress'),
    address2: resolver('address2'),
    city: resolver('city'),
    country: resolver('country'),
    state: resolver('state'),
    postal_code: resolver('zip'),
    company_name: resolver('company'),
  };
}

export function genObjForUpdate(resolver) {
  return {
    firstName: resolver('firstname'),
    lastName: resolver('lastname'),
    address1: resolver('billingaddress'),
    address2: resolver('address2'),
    email: resolver('email'),
    city: resolver('city'),
    state: resolver('state'),
    country: resolver('country'),
    zip: resolver('zip'),
    companyName: resolver('company'),
    vatNumber: resolver('taxnumber'),
  };
}
