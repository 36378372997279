import React, { Component } from 'react';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import values from 'lodash/values';
import t from '@properly/localization';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { compose } from 'recompose';
import {
  DividerLine,
  ModalError,
  Modal,
  LoadingSplash,
  RoundSelect,
  Icon,
  Title3,
  Small,
  SpaceBase,
} from '@properly/components';
import classNames from 'classnames/bind';
import { PagePadding } from '../../../../components/PageElements/index';
import styles from '../components/accountStyles.module.css';
import { AccountHeadSection } from '../../../../components/AccountElements/index';

const mapNumToBool = val => {
  switch (val) {
    case 1:
      return true;
    case 0:
      return false;
    default:
      return false;
  }
};

const mapBoolToNum = val => {
  if (val) return 1;
  return 0;
};

const GetNotificationSettings = gql`
  query Query($role: RoleType, $languageCode: String) {
    notificationSetting(role: $role, languageCode: $languageCode) {
      id
      localizedTitle
      notificationChannels {
        id
        type
        status
        isDisabled
      }
    }
  }
`;

const MutateNotificationChannel = gql`
  mutation UpdateNotificationChannel($input: UpdateNotificationChannelInput!) {
    UpdateNotificationChannel(input: $input) {
      notificationChannel {
        id
        type
        status
      }
    }
  }
`;

const cx = classNames.bind(styles);

function NotificationRow({ title, email, sms, push, onChange, id }) {
  const emailDataKey = `${id}_email_notification`;
  const smsDataKey = `${id}_sms_notification`;
  const pushDataKey = `${id}_push_notification`;
  return (
    <div key={id} className={cx('account__notification')}>
      <Small>{title}</Small>
      <div className={cx('account__notification-right')}>
        <div className={cx('account__notification-block')}>
          <RoundSelect
            data-key={emailDataKey}
            selected={mapNumToBool(email.status)}
            onChange={e => onChange(email.id, 'EMAIL', e.target.checked)}
          />
        </div>
        <div className={cx('account__notification-block')}>
          <RoundSelect
            data-key={smsDataKey}
            selected={mapNumToBool(sms.status)}
            onChange={e => onChange(sms.id, 'SMS', e.target.checked)}
          />
        </div>
        <div className={cx('account__notification-block')}>
          <RoundSelect
            data-key={pushDataKey}
            selected={mapNumToBool(push.status)}
            onChange={e => onChange(push.id, 'PUSH', e.target.checked)}
          />
        </div>
      </div>
      <DividerLine type={['bottom']} />
    </div>
  );
}

class AccountNotificationsContainer extends Component {
  state = {
    status: 0,
  };

  renderNotifications(notifications) {
    return map(notifications, notification => {
      const channelsByType = keyBy(notification.notificationChannels, 'type');
      return (
        <NotificationRow
          id={notification.id}
          email={channelsByType.EMAIL}
          sms={channelsByType.SMS}
          push={channelsByType.PUSH}
          onChange={this.updateChannel}
          title={notification.localizedTitle}
        />
      );
    });
  }

  updateChannel = (channelId, type, status) => {
    this.setState({ status: 1 });
    this.props
      .mutate({
        variables: {
          input: {
            notificationChannel: {
              id: channelId,
              status: mapBoolToNum(status),
            },
          },
        },
      })
      .then(() => {
        this.resetStatus();
      })
      .catch(() => {
        this.setState({ status: 2 });
      });
  };

  resetStatus = () => {
    this.setState({ status: 0 });
  };

  render() {
    const { loading, error, notificationSetting } = this.props.data;
    if (loading) return <LoadingSplash />;
    if (error) return null; // show empty while loading
    return (
      <PagePadding type="absolute-scroll">
        <AccountHeadSection>
          <Title3 type="nomargin">{t('account.notifications')}</Title3>
          <DividerLine type={['bottom']} />
        </AccountHeadSection>
        <SpaceBase />
        <div>
          <div className={cx('account__notification')}>
            <div className={cx('account__notification-right')}>
              <div className={cx('account__notification-block')}>
                <Icon.IcMailSolid />
              </div>
              <div className={cx('account__notification-block')}>
                <Icon.IcMessageSolid2 />
              </div>
              <div className={cx('account__notification-block')}>
                <Icon.IcMessageSolid />
              </div>
            </div>
            <DividerLine type={['bottom']} />
          </div>
          {this.renderNotifications(values(notificationSetting))}
        </div>
        <Modal type="loading" show={this.state.status === 1} />
        <ModalError
          message1={t('account.edit_fail_account_head')}
          message2={t('account.edit_fail_account')}
          show={this.state.status === 2}
          onClose={this.resetStatus}
        />
      </PagePadding>
    );
  }
}

export default compose(
  graphql(GetNotificationSettings, {
    options: { variables: { role: 'HOST', languageCode: t.detectLanguage() } },
  }),
  graphql(MutateNotificationChannel),
)(AccountNotificationsContainer);
