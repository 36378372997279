import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Regular, SpaceSmall, Small, SpaceMicro } from '@properly/components';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

const JobDetailsDebugMode = ({ jobRequest }) => {
  const [copyState, setCopyState] = useState(false);

  return (
    <>
      <Regular>You have debug mode on, hence you see this data</Regular>
      <SpaceSmall />
      <Small>{copyState ? 'Copied!' : 'Click the Job ID to copy'}</Small>
      <SpaceMicro />
      <div style={{ display: 'flex' }}>
        <Regular
          onClick={() => {
            setCopyState(true);
            navigator.clipboard.writeText(jobRequest.jobId);
          }}
        >
          Job ID:&nbsp;
          {jobRequest.jobId}
        </Regular>
      </div>
      <SpaceSmall />
      <Accordion>
        <AccordionSummary>Click to Open Job JSON</AccordionSummary>
        <AccordionDetails>
          <pre>{JSON.stringify(jobRequest, null, 2)}</pre>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

JobDetailsDebugMode.propTypes = {
  jobRequest: PropTypes.shape({}).isRequired,
};

export default JobDetailsDebugMode;
