import lodashResult from 'lodash/result';
import { isPropertyOwner } from '@properly/common';
import restrictionsUandP from './restrictionsUandP';
import { PAID_FEATURES } from '../../dataConstants';

const defaultFullAccess = {
  allRights: true,
  createChecklists: true,
  createProperties: true,
  seeAllProperties: true,
  seeCleanerNames: true,
  seePrice: true,
  seeProblems: true,
  seeVerificationPhotos: true,
  sendJobs: true,
};

function isEmploymentProperty(property, user, canPartnerGiveFeedback) {
  if (!property || !user) return false;
  return property.ownerRole === user.defaultRole || canPartnerGiveFeedback;
} // check if its owner or only give access to RI admin partner / partner with role 'partnerFeedback'

function isTakerOfTheJobRequest(jobrequest, user) {
  if (!jobrequest || !user) return false;
  return jobrequest.assignedToUserId === user.objectId;
}

function isCreateOfTheJobRequest(jobrequest, user) {
  if (!jobrequest || !user) return false;
  return jobrequest.hostId === user.objectId || jobrequest.senderId === user.objectId;
}

function canViewThumbsUpComments(property, user, jobrequest, canPartnerGiveFeedback) {
  if (
    isEmploymentProperty(property, user, canPartnerGiveFeedback) ||
    isTakerOfTheJobRequest(jobrequest, user) ||
    isCreateOfTheJobRequest(jobrequest, user)
  )
    return true;
  return false;
}

function canEditThumbsUpComments(property, user, jobrequest, canPartnerGiveFeedback) {
  return (
    canViewThumbsUpComments(property, user, jobrequest, canPartnerGiveFeedback) &&
    isEmploymentProperty(property, user, canPartnerGiveFeedback)
  );
}

function canSeeReportsIconSidebar(isStandalone, canSendJobs) {
  if (isStandalone) return true;
  if (!isStandalone && canSendJobs) return true;
  return false;
}

function getPropertyPermissions({ propertyOwner, user }) {
  const userTeams = lodashResult(user, ['teams'], {});
  return lodashResult(userTeams, [propertyOwner, 'rights'], defaultFullAccess);
}

export function canSendPropertyJobs({ propertyOwner, user }) {
  return lodashResult(getPropertyPermissions({ propertyOwner, user }), ['sendJobs'], false);
}

export function canSeeCleanerNames({ propertyOwner, user }) {
  return lodashResult(getPropertyPermissions({ propertyOwner, user }), ['seeCleanerNames'], true);
}

function hasAccess(meta) {
  // base entities
  const user = lodashResult(meta, ['user']);
  const jobrequest = lodashResult(meta, ['jobrequest']);
  const property = lodashResult(meta, ['property']);

  const userTeams = lodashResult(meta, ['user', 'teams'], {});
  const isStandalone = lodashResult(meta, ['user', 'isStandaloneUser'], false);
  const subscriptionPlanFeatures = lodashResult(meta, ['user', 'subscription', 'features'], '');
  const isFullPartnerMode = subscriptionPlanFeatures?.includes(PAID_FEATURES.fullPartnerMode);
  const propertyOwner = lodashResult(meta, ['property', 'ownerRole']);
  const propertyPermissions = getPropertyPermissions({ propertyOwner, user: meta.user });
  const defaultRole = lodashResult(meta, ['user', 'defaultRole']);
  const defaultRoleResolved = lodashResult(userTeams, [defaultRole, 'rights'], defaultFullAccess);
  const teamName = lodashResult(userTeams, [defaultRole, 'name']);
  const canSeeAllPropertiesGlobal = lodashResult(defaultRoleResolved, ['seeAllProperties'], false);
  const canSendJobs = lodashResult(defaultRoleResolved, ['sendJobs'], false);
  const canSeeAllProperties = lodashResult(propertyPermissions, ['seeAllProperties'], false);
  const canPartnerGiveFeedback = lodashResult(propertyPermissions, ['partnerFeedback'], false);

  const res = {
    canSeeFullNav: isStandalone,
    isStandalone,
    teamName: teamName || '',

    canSendJobs: canSendPropertyJobs({ user: meta.user, propertyOwner }),
    seeCleanerNames: canSeeCleanerNames({ user: meta.user, propertyOwner }),
    seeVerificationPhotos: lodashResult(propertyPermissions, ['seeVerificationPhotos'], false),
    canCreateChecklists: lodashResult(propertyPermissions, ['createChecklists'], false),
    canSendJobsGlobal: lodashResult(defaultRoleResolved, ['sendJobs'], false),
    canSeePriceAndPayments: lodashResult(propertyPermissions, ['seePrice'], false),
    hasAllRights: lodashResult(defaultRoleResolved, ['allRights'], false),
    canEditThisProperty: lodashResult(propertyPermissions, ['createProperties'], false),
    hasAllRightsForProperty: lodashResult(propertyPermissions, ['allRights'], false),
    isOwnerProperty: property && isPropertyOwner(property.ownerRole, user),

    canSeeTaskLeftPhotos: lodashResult(propertyPermissions, ['seeVerificationPhotos'], false),
    canCreateProperties: canSeeAllPropertiesGlobal,
    canEditDeleteProperty: canSeeAllProperties,
    isFullPartnerMode,

    settingsUandP: restrictionsUandP({
      isFullPartnerMode,
      companyModule: subscriptionPlanFeatures?.includes(PAID_FEATURES.companyModule),
    }),

    // thumbs up / down
    canViewThumbsUpComments: canViewThumbsUpComments(property, user, jobrequest, canPartnerGiveFeedback),
    canEditThumbsUpComments: canEditThumbsUpComments(property, user, jobrequest, canPartnerGiveFeedback),

    // report
    canSeeReportsIconSidebar: canSeeReportsIconSidebar(isStandalone, canSendJobs),
  };
  return res;
}

export default hasAccess;
