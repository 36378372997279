import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SpaceSmall, SpaceMedium, Regular, Button } from '@properly/components';
import t from '@properly/localization';
import { connectedAccounts as ConnectedAccounts } from '@properly/config';
import map from 'lodash/map';
import Cookies from 'js-cookie';
import {
  getConnectedAccountRequest,
  oAuthSaga,
  redirectToProviderSaga,
} from '../desktopApp/account/state/AccountActions';
import { oAuthHandleUpdate, handleCloseOAuth, redirectToLandingPageSaga } from './LoginSignUpActions';
import { redirectToDownloadAppPage } from '../branding/BrandingActions';
import * as selectorsAccount from '../desktopApp/account/state/AccountSelectors';
import { trackFREAction } from '../../actions/trackingEvents';
import LoginWrapContainer from './LoginWrapContainer';
import { selectIsPartnerDomain } from '../branding/BrandingSelector';

const UTM_SOURCE = Cookies.get('utm_source');

class HostSignUpAccountsComponentAdaptor extends Component {
  constructor(props) {
    super(props);

    const params = props.location.query;
    const { code, partner, error, message } = params || {};

    // Our new oauth service will return an error callback.
    // If there is no error then we should connect via the frontend
    const successfulServicesOauth = !!error && error === 'false';
    const serviceFailed = message === 'NOT_IMPLEMENTED' || message === 'SERVICE_FAILED';
    const failedServicesOauth = error && error === 'true';
    const attemptLegacyConnect = code && partner && (!successfulServicesOauth || serviceFailed);

    this.state = {
      attemptLegacyConnect, // eslint-disable-line
      serviceFailed, // eslint-disable-line
      connectionSuccess: successfulServicesOauth, // eslint-disable-line
      connectionFailed: attemptLegacyConnect ? null : failedServicesOauth, // eslint-disable-line
    };
  }

  componentWillMount = () => {
    const { getConnectedAccount } = this.props;
    getConnectedAccount();
  };

  goToProviderNew(providerUrl, partnerTitle, partnerData) {
    trackFREAction('oAuth', partnerTitle);

    this.props.redirectToProviderSaga({
      oAuthLink: `${providerUrl}&redirectPath=${encodeURIComponent('/#/hostSignUpAccounts')}`,
      partnerTitle,
      connectedAccount: partnerData,
    });
  }

  handleOnSkip() {
    const { isPartnerDomain } = this.props;

    if (isPartnerDomain) {
      this.props.redirectToDownloadAppPage();
      return;
    }

    this.props.redirectToLandingPageSaga('accountimport', { skip: true });
  }

  componentDidMount() {
    const { attemptLegacyConnect } = this.state;
    const params = this.props.location.query;

    if (attemptLegacyConnect) {
      this.props.oAuthSaga(params.code, params.oAuth || params.partner, oAuthHandleUpdate, 'signup');
    }
  }

  renderPartners = () => {
    const { connectedAccounts } = this.props;

    return map(connectedAccounts, partner => (
      <div key={partner.id}>
        <Button
          onClick={() => this.goToProviderNew(partner.oAuthLink, partner.title, partner)}
          types={['width-flex', 'size-large', !partner.color ? 'type-full-grey' : '']}
          styleOverwrite={partner.color ? { backgroundColor: partner.color, color: '#fff' } : {}}
          hoverStyleOverwrite={{ backgroundColor: partner.color_hover }}
        >
          {t('signup.button.connect_with_partner', { partner: partner.label })}
        </Button>
        <SpaceSmall />
      </div>
    ));
  };

  render() {
    return (
      <LoginWrapContainer center withSubHeadline title={t('signup.accounts.title', {})}>
        <Regular>{t('signup.accounts.description', {})}</Regular>
        <SpaceMedium />
        {this.renderPartners()}
        <SpaceSmall />
        <Button
          data-key="button-skip-connect"
          onClick={() => this.handleOnSkip()}
          types={['type-flat-primary', 'width-flex', 'size-medium', 'fontw-normal']}
        >
          {t('signup.button.skip', {})}
        </Button>
      </LoginWrapContainer>
    );
  }
}

const filterConnectedAccounts = allowedConnections => ({ id }) => {
  // 'allowedConnections' could be an immutable List data structure
  const hasAllowedConnections = allowedConnections && (allowedConnections.size > 0 || allowedConnections.length > 0);

  switch (UTM_SOURCE) {
    case ConnectedAccounts.BOOKINGCOM:
      return ConnectedAccounts.BOOKINGCOM === id;
    default:
      if (hasAllowedConnections) {
        return allowedConnections.findIndex(allowedAdaptor => allowedAdaptor === id) !== -1;
      }
      return [ConnectedAccounts.AIRBNB, ConnectedAccounts.BOOKINGCOM].includes(id);
  }
};

function mapStateToProps(state, props) {
  const {
    location: {
      state: { allowedConnections },
    },
  } = props;

  const connectedAccounts = selectorsAccount.selectConnectedAccounts(state, props);
  const filteredConnectedAccounts = (connectedAccounts || [])
    .filter(filterConnectedAccounts(allowedConnections))
    .map(transformConnectedAccounts);

  return {
    connectedAccounts: filteredConnectedAccounts,
    isPartnerDomain: selectIsPartnerDomain()(state),
  };
}

function transformConnectedAccounts(connectedAccount) {
  return {
    ...connectedAccount,
    color: connectedAccount.id === 'airbnb' ? '#FC5C63' : '#296FB7',
    color_hover: connectedAccount.id === 'airbnb' ? '#EF575D' : '#2669AD',
  };
}

HostSignUpAccountsComponentAdaptor.propTypes = {
  isPartnerDomain: PropTypes.bool.isRequired,
  redirectToDownloadAppPage: PropTypes.func,
};

HostSignUpAccountsComponentAdaptor.defaultProps = {
  redirectToDownloadAppPage: () => {},
};

export default connect(mapStateToProps, {
  getConnectedAccount: getConnectedAccountRequest,

  oAuthSaga,
  handleCloseOAuth,
  redirectToLandingPageSaga,
  redirectToProviderSaga,
  redirectToDownloadAppPage,
})(HostSignUpAccountsComponentAdaptor);
