import { put, fork, call, takeEvery } from 'redux-saga/effects';
import log from 'loglevel';
import keyBy from 'lodash/keyBy';
import * as types from '../../types';
import { updateCollectionEntryBatch, setGlobalLoadingState } from '../../actions/globalActions';
import { getGQLJobRequestsForProperty, getGQLJobRequestsForCleaners } from '../../graphql/api/jobList';

export const loadingKey = 'JobCleanerRequestList';

function* handleContact(func, contactId, role, page, onlyUpcoming, limit) {
  const res2 = yield call(func, contactId, role, page, limit, onlyUpcoming);
  yield put(updateCollectionEntryBatch('cleanerRequest', keyBy(res2, 'jobId'), true));
  return res2;
}

function* handleProperty(func, propertyId, page, limit) {
  const role = 'HOST';
  const res2 = yield call(func, propertyId, role, page, limit);
  const keyedby = {};
  res2.forEach(res => {
    keyedby[res.jobId] = res;
  });

  yield put(updateCollectionEntryBatch('jobRequests', keyedby, true));
  return res2;
}

function* jobCleanerRequestListSaga({ meta: { page, onlyUpcoming, contactId, pagination, mode, limit } }) {
  try {
    const role = 'HOST';
    let res;

    yield put(setGlobalLoadingState(loadingKey, pagination ? -1 : 1, { page }));

    if (mode === 'contact') {
      res = yield call(handleContact, getGQLJobRequestsForCleaners, contactId, role, page, onlyUpcoming, limit);
    }

    if (mode === 'property') {
      res = yield call(handleProperty, getGQLJobRequestsForProperty, contactId, page, limit);
    }

    const hasMore = res.length === limit;
    yield put(setGlobalLoadingState(loadingKey, 2, { page, hasMore }));
  } catch (e) {
    log.error('jobCleanerRequestListSaga', e);
    yield put(setGlobalLoadingState(loadingKey, 3, { page }));
  }
}

function* saga() {
  yield fork(takeEvery, types.JOB_REQUEST_LOAD_LIST, jobCleanerRequestListSaga);
}

export default saga;
