import React, { PureComponent } from 'react';
import log from 'loglevel';
import { Iterable } from 'immutable';
import lodashResult from 'lodash/result';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import { withStyles } from '@material-ui/core/styles';
import colors from '@properly/components/lib/common/styles/colors.module.css';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { SearchField, Icon } from '@properly/components';
import { getCalendarEventBookingPartner, getCalendarEventBookingDatesFormatted } from '@properly/common';
import t from '@properly/localization';
import InfoMessage from '../../../../components/InfoMessage/index';
import Sidebar from '../../../../components/Sidebar/index';
import BookingDetailsContainer from '../../../../containers/BookingDetailsContainer/index';
import {
  JobRequestPropertyInfo,
  JobRequestContentWrap,
  JobRequestSpacerWrap,
  JobRequestPropertyList,
} from '../../../../components/JobRequestElements/index';
import { propertyShowAble } from '../../../../helper/herbert';
import ProBadge from '../../../../components/ProBadge';

const styles = () => ({
  root: {
    boxShadow: 'none',
    borderBottom: `1px solid rgba(29,39,48,0.12)`,
    textAlign: 'justify',
    marginBottom: -1,
  },
  content: {
    justifyContent: 'center',
    margin: '20px 125px !important',
    minHeight: 24,
    '&$expanded': {
      margin: 'auto',
    },
    '&> :last-child': {
      paddingRight: '0',
    },
  },
  expandIcon: {
    right: '110px',
  },
  title: {
    margin: '0 4px',
  },
  lightBulbIcon: {
    position: 'absolute',
    top: '14px',
    left: '120px',
  },
  expansionPanelDetails: {
    color: colors['color-grey'],
  },
  expanded: {},
  label: {
    fontSize: '15px',
  },
  link: {
    color: colors['color-primary'],
    textDecoration: 'none',
    '&:hover': {
      color: colors['color-primary-hover'],
    },
  },
});

class Property extends PureComponent {
  getPropertyType(type) {
    if (type === 'apartment') return t('properties.apartment');
    if (type === 'house') return t('properties.house');
    return t('job_request.not_set');
  }

  handleClick = item => e => {
    this.props.handleClickSaga(e, {
      action: 'clickproperty',
      id: item.objectId,
    });
  };

  renderProInfo() {
    const { classes, config } = this.props;
    return (
      <Accordion square className={classes.root}>
        <AccordionSummary
          classes={{ content: classes.content, expandIcon: classes.expandIcon }}
          expandIcon={<Icon.IcExpandMore width="24px" className={classes.highlightColor} />}
        >
          <div className={classes.lightBulbIcon}>
            <Icon.LightBulb width="17.5px" height="28px" byHeight />
          </div>
          {t('marketplace.expansion_title')}
          <span className={classes.title}>Properly</span>
          <div>
            <ProBadge />
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.expansionPanelDetails}>
          <span className={classes.label}>
            {t('marketplace.expansion_content', {
              msg: (
                <a
                  className={`${classes?.link} text-l6m`}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={config.proMPSatisfactionGuaranteeUrl}
                >
                  {t('marketplace.guarantee')}
                </a>
              ),
              tos: (
                <a
                  className={`${classes.link} text-l6m`}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={config.proTOSUrl}
                >
                  {t('marketplace.expansion_content_tos')}
                </a>
              ),
            })}
          </span>
        </AccordionDetails>
      </Accordion>
    );
  }

  isActiveFunc = item => item.objectId === this.props.finalPropertyId;

  renderEvent({ calendarEvent: event }) {
    const calendarEvent = Iterable.isIterable(event) ? event.toJS() : event;

    log.info('Job Request elements Property calendarEvent', calendarEvent);

    const bookingId = lodashResult(calendarEvent, ['booking', 'id']) || lodashResult(calendarEvent, ['bookingId']);
    const propertyId = lodashResult(calendarEvent, ['property', 'id']) || lodashResult(calendarEvent, ['propertyId']);

    return (
      <JobRequestContentWrap id="job-request-property-form">
        <JobRequestSpacerWrap flexGrow left="24px" top="12px" bottom="12px" right="24px" style={{ overflow: 'auto' }}>
          <BookingDetailsContainer bookingId={bookingId} propertyId={propertyId} />
        </JobRequestSpacerWrap>
        <InfoMessage text={t('job_request.view_eventsum')} isPrompt isVcenter isHcenter icon="light" color="white" />
      </JobRequestContentWrap>
    );
  }

  renderProperty({ searchData, setSearchQuery, infoTextMsg, ordered }) {
    const { isMarketPlaceFeatureFlagEnabled } = this.props;
    return (
      <JobRequestContentWrap id="job-request-property-form">
        {isMarketPlaceFeatureFlagEnabled && this.renderProInfo()}

        <JobRequestSpacerWrap left="24px" top="12px" bottom="12px" right="24px">
          <SearchField
            onGrey
            data-key="jobrequestsend_search_property"
            placeholder={t('job_request.search_property')}
            value={searchData.searchQuery}
            iconLocation="left"
            onChange={value => setSearchQuery('jobrequestnew_search', value)}
          />
        </JobRequestSpacerWrap>
        <Sidebar.List>
          <Sidebar.ListInner>
            <Sidebar.ListInnerInner>
              <JobRequestSpacerWrap left="24px" top="0px" bottom="12px" right="24px">
                <JobRequestPropertyList
                  height={475}
                  width={540}
                  properties={ordered}
                  handleClick={this.handleClick}
                  isActiveFunc={this.isActiveFunc}
                  isMarketPlaceFeatureFlagEnabled={isMarketPlaceFeatureFlagEnabled}
                />
              </JobRequestSpacerWrap>
            </Sidebar.ListInnerInner>
          </Sidebar.ListInner>
        </Sidebar.List>
        <InfoMessage text={infoTextMsg} isPrompt isVcenter isHcenter icon="light" color="white" />
      </JobRequestContentWrap>
    );
  }

  render() {
    const { searchData, finalStartUpData } = this.props;
    const calendarEvent = finalStartUpData.get('event');
    const calendarEventTriggerTime = finalStartUpData.getIn(['data', 'startTime']);
    if (calendarEvent) {
      return this.renderEvent({ ...this.props, calendarEvent, calendarEventTriggerTime });
    }

    const ordered = orderBy(searchData.result, property => property.getSearchString(), ['asc']);
    const filteredResults = filter(ordered, propertyShowAble);

    return this.renderProperty({ ...this.props, ordered: filteredResults });
  }
}

Property.content = props => {
  const event = props.finalStartUpData.get('event');
  const calendarEvent = Iterable.isIterable(event) ? event.toJS() : event;
  const proMarketProperty = props.finalPropertyResolved?.proMarketProperty || false;
  if (calendarEvent) {
    const bookingPartner = getCalendarEventBookingPartner(calendarEvent);
    const bookingInfoDates = getCalendarEventBookingDatesFormatted(calendarEvent);
    return (
      <JobRequestPropertyInfo
        liftPic
        txtMod={{ withpic: true }}
        l1={props.l1}
        l2={props.l2}
        l3={`${bookingPartner}: ${bookingInfoDates}`}
        pic={props.pic}
      />
    );
  }
  return (
    <JobRequestPropertyInfo
      txtMod={{ withpic: true }}
      liftPic
      l1={props.l1}
      l2={props.l2}
      l3={props.l3}
      pic={props.pic}
      showProBadge={props.isMarketPlaceFeatureFlagEnabled && proMarketProperty}
      probadgeAlignmentOverride
    />
  );
};

export default withStyles(styles)(Property);
