import log from 'loglevel';
import reduce from 'lodash/reduce';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import concat from 'lodash/concat';
import Parse from 'parse';

export default class JobProgress {
  constructor({
    objectId,
    actualStartTime,
    actualEndTime,
    taskDoneCount,
    verificationDoneCount,
    problemCount,
    steps,
    problems,
    endLocation,
    startLocation,
    jobSteps,
    reports,
  }) {
    this.objectId = objectId;
    this.actualStartTime = actualStartTime;
    this.actualEndTime = actualEndTime;
    this.taskDoneCount = taskDoneCount;
    this.verificationDoneCount = verificationDoneCount;
    this.problemCount = problemCount;
    this.steps = steps || [];
    this.jobSteps = jobSteps || [];
    this.problems = problems || [];
    this.reports = reports || [];
    this.startLat = startLocation?.latitude;
    this.startLng = startLocation?.longitude;
    this.endLat = endLocation?.latitude;
    this.endLng = endLocation?.longitude;
  }

  get startGeoPoint() {
    const parseStartGeoPoint =
      this.startLat && this.startLng
        ? new Parse.GeoPoint({ latitude: this.startLat, longitude: this.startLng })
        : undefined;
    return parseStartGeoPoint;
  }

  // 0=not found, 1= match, 2= out of radius
  getLocationStatus(propertyLocation, point) {
    try {
      const parsePropertyLocation = propertyLocation
        ? new Parse.GeoPoint({ latitude: propertyLocation.latitude, longitude: propertyLocation.longitude })
        : undefined;

      if (!parsePropertyLocation || !point) {
        return 0;
      }

      const distance = parsePropertyLocation.kilometersTo(point);
      if (distance <= 0.5) {
        return 1;
      }

      return 2;
    } catch (err) {
      log.error('Error getting location status');
      return 0;
    }
  }

  getAllLocationStatuses(propertyLocation) {
    const start = this.getLocationStatus(propertyLocation, this.startGeoPoint);
    const endPoint =
      this.endLat && this.endLng ? new Parse.GeoPoint({ latitude: this.endLat, longitude: this.endLng }) : undefined;
    const end = this.getLocationStatus(propertyLocation, endPoint);
    return {
      start,
      end,
    };
  }

  get problemsComputed() {
    if (isArray(this.problems)) return this.problems;
    return reduce(
      this.steps,
      (arr, item) => {
        if (item.problems && item.problems.length > 0) {
          return concat(arr, item.problems);
        }
        return arr;
      },
      [],
    );
  }

  get problemsNotes() {
    return map(this.problemsComputed, item => item.note || '');
  }

  getId() {
    return this.objectId;
  }

  getSteps() {
    return this.steps;
  }
}
