import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Button, Icon } from '@properly/components';
import t from '@properly/localization';
import { toggleDisplayPublishedChecklists, toggleDisplayUserChecklists } from '../state/library.actions';
import { selectHidePublishedChecklists, selectHideUserChecklists } from '../state/library.selectors';

const LibraryChecklistsToggle = ({
  hideUserChecklists,
  hidePublishedChecklists,
  toggleUserChecklistsDisplay,
  togglePublishedChecklistsDisplay,
}) => (
  <Grid item style={{ marginLeft: '24px' }}>
    <Grid container>
      <Grid item>
        <Button types={['size-small', 'type-border-grey']} onClick={toggleUserChecklistsDisplay}>
          <Grid container alignItems="center">
            <Grid item style={{ marginRight: '8px' }}>
              {hideUserChecklists ? <Icon.IcVisibility /> : <Icon.IcVisibilityOff />}
            </Grid>
            <Grid item>
              {hideUserChecklists ? t('checklist.show_user_checklist') : t('checklist.hide_user_checklist')}
            </Grid>
          </Grid>
        </Button>
      </Grid>

      <Grid item style={{ marginLeft: '8px' }}>
        <Button types={['size-small', 'type-border-grey']} onClick={togglePublishedChecklistsDisplay}>
          <Grid container alignItems="center">
            <Grid item style={{ marginRight: '8px' }}>
              {hidePublishedChecklists ? <Icon.IcVisibility /> : <Icon.IcVisibilityOff />}
            </Grid>
            <Grid item>
              {hidePublishedChecklists
                ? t('checklist.show_published_checklist')
                : t('checklist.hide_published_checklist')}
            </Grid>
          </Grid>
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

const mapStateToProps = state => ({
  hideUserChecklists: selectHideUserChecklists(state),
  hidePublishedChecklists: selectHidePublishedChecklists(state),
});

export default connect(mapStateToProps, {
  toggleUserChecklistsDisplay: toggleDisplayUserChecklists,
  togglePublishedChecklistsDisplay: toggleDisplayPublishedChecklists,
})(LibraryChecklistsToggle);
