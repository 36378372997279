import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import log from 'loglevel';
import { Modal, ModalError } from '@properly/components';
import t from '@properly/localization';
import values from 'lodash/values';
import { ROUTES, mapRouteNameToRoute } from '../../../../paths';
import * as selectors from '../state/PropertySelectors';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import MainWrapper from '../../../../containers/MainWrapper/index';
import { filterPropertySidebar } from '../../../../helper/herbert';
import { MasterDetail, MasterDetailMaster, MasterDetailDetail } from '../../../../components/MasterDetail/index';
import NoProperties from '../../../../components/NoProperties/index';
import PropertySidebarContainer from './PropertySidebarContainer';
import ChecklistCloneContainerConnected from '../../checklists/containers/ChecklistCloneContainerConnected';
import * as globalActions from '../../../../actions/globalActions';

import {
  goNewProperty,
  cancelErrorDeleteProperty,
  broadcastPropertiesHit,
  goToProperty,
} from '../state/PropertyActions';

class PropertyContainer extends PureComponent {
  componentDidMount() {
    this.props.broadcastPropertiesHit();
    this.props.setSearchQuery('properties', '');
    this.props.startPreloadSagaWithDelay('properties');
  }

  goToFunc = propertyId => {
    const routeName = this.props.routeName || 'jobrequest';
    const routeMapping = mapRouteNameToRoute[routeName];

    const currentMapping = routeMapping(propertyId);
    if (currentMapping) {
      this.props.goTo(currentMapping);
    } else {
      log.warn('Did not find route mapping in PropertyContainer', propertyId, routeMapping(propertyId));
    }
  };

  renderSplitView() {
    return (
      <MasterDetail>
        <MasterDetailMaster>
          <PropertySidebarContainer
            goToFunc={this.goToFunc}
            routeName={this.props.routeName}
            activeId={this.props.params.id}
          />
        </MasterDetailMaster>
        <MasterDetailDetail grey>{this.props.children}</MasterDetailDetail>
      </MasterDetail>
    );
  }

  renderNoProperty() {
    return <NoProperties type="noproperties" onClick={this.props.goNewProperty} />;
  }

  renderEmpty() {
    return (
      <MasterDetail>
        <MasterDetailMaster>
          <PropertySidebarContainer goToFunc={this.goToFunc} routeName={this.props.routeName} activeId={undefined} />
        </MasterDetailMaster>
        <MasterDetailDetail grey>
          <NoProperties type="notselected" />
        </MasterDetailDetail>
      </MasterDetail>
    );
  }

  renderPropertyContent() {
    if (this.props.location.pathname !== ROUTES.newProperties) {
      if (this.props.params.id) {
        // detail page
        return this.renderSplitView();
      }
      // all properties
      if (this.props.properties && filterPropertySidebar(values(this.props.properties?.toJS())).length === 0) {
        // the user does not have properties
        return this.renderNoProperty();
      }
      // no properties selected
      return this.renderEmpty();
    }
    // new property
    return this.renderSplitView();
  }

  render() {
    return (
      <MainWrapper currentPage="properties">
        {this.renderPropertyContent()}
        <Modal type="loading" show={this.props.loadingDeleteProperty} data-key="delete-property-loading" />
        <ModalError
          message1={t('properties.delete_fail_property_head')}
          message2={this.props.errorDeletePropertyMessage || t('properties.delete_fail_property')}
          onClose={this.props.cancelErrorDeleteProperty}
          show={this.props.errorDeleteProperty}
        />
        <ChecklistCloneContainerConnected />
      </MainWrapper>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    properties: selectorsGlobal.selectProperties()(state, props),
    loadingDeleteProperty: selectors.isLoadingDeleteProperty()(state, props),
    errorDeleteProperty: selectors.hasLoadingErrorDeleteProperty()(state, props),
    errorDeletePropertyMessage: selectors.getLoadingErrorDeletePropertyMessage()(state, props),
    routeName: props.params.propertyDetailView,
  };
}

PropertyContainer.propTypes = {
  children: PropTypes.node,
};

export default connect(
  mapStateToProps,
  {
    goNewProperty,
    cancelErrorDeleteProperty,
    broadcastPropertiesHit,
    startPreloadSagaWithDelay: globalActions.startPreloadSagaWithDelay,
    setSearchQuery: globalActions.setSearchQuery,
    goToProperty,
    goTo: globalActions.goTo,
  },
)(PropertyContainer);
