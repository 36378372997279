import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Large } from '@properly/components';
import t from '@properly/localization';

export const LibraryNoSkills = ({ searchQuery }) => (
  <Grid container alignItems="center" justify="center">
    <Grid item>
      {!searchQuery && <Large type="bold">{t('checklist.nothing_here')}</Large>}
      {!!searchQuery && (
        <Large type="bold">
          {t('checklist.no_results')} {searchQuery}
        </Large>
      )}
    </Grid>
  </Grid>
);
