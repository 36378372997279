import React from 'react';
import PropTypes from 'prop-types';
import { DividerLine, Icon } from '@properly/components';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const HeadBar = ({ middleText, onClose, rightPart, icon }) => {
  const HeadBarIcon = icon || Icon.IcClose;
  return (
    <div className={cx('headbar__head')}>
      <div className={cx('headbar__head-middle')}>
        <span>{middleText}</span>
      </div>
      <div onClick={onClose} data-key="headbar_go_back" className={cx('headbar__head-left')}>
        <HeadBarIcon />
      </div>
      <div className={cx('headbar__head-right')}>{rightPart ? rightPart() : null}</div>
      <DividerLine type={['bottom', 'light']} />
    </div>
  );
};

HeadBar.propTypes = {
  middleText: PropTypes.string,
  icon: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  rightPart: PropTypes.func,
};

export default HeadBar;
