/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import chunk from 'lodash/chunk';
import find from 'lodash/find';
import indexOf from 'lodash/indexOf';
import { pictureSizes, genImageBg } from '@properly/common';
import { cleanerStatus, emailLink } from '@properly/config';
import t from '@properly/localization';
import {
  DividerLine,
  Button,
  Icon,
  Micro,
  MoreLess,
  Regular,
  Small,
  SpaceSmall,
  HoverTriggeredTooltip,
} from '@properly/components';
import moment from 'moment-timezone';
import { generate } from 'shortid';
import classNames from 'classnames/bind';
import styles from './jobDetailsStyles.module.css';
import { genPropertyTags, getInitials, genPropertyTagsGQL } from '../../../../helper/herbert';
import { MasterDetailCell } from '../../../../components/MasterDetail/index';
import LocationIcon from '../../../../components/LocationIcon/index';
import PaymentStatusChip from '../../../../components/PaymentStatusChip';
import ThumbsBubbleBar from '../../../../containers/ThumbsBubbleBar/index';
import { JobDetailsFeedback } from './JobDetailsFeedback';

const cx = classNames.bind(styles);

export const JobDetailsSection = ({ title, children }) => (
  <div className={cx('jobdetails__section')}>
    <DividerLine type={['top', 'light']} />
    <div className={cx('jobdetails__section-title')}>
      <Small type="medium">{title}</Small>
    </div>
    {children}
  </div>
);

export const JobDetailsAddress = ({ propertyData }) => (
  <div>
    <div>
      <Regular>{`${propertyData.street} ${propertyData.apartment}`}</Regular>
    </div>
    <div>
      <Regular>{`${propertyData.zip} ${propertyData.city}`}</Regular>
    </div>
    <div>
      <Regular>{propertyData.country}</Regular>
    </div>
    {genPropertyTags(propertyData) !== '' && (
      <div>
        <Small type="grey">{genPropertyTagsGQL(propertyData.details, propertyData.type)}</Small>
      </div>
    )}
  </div>
);

export const JobDetailsPaymentDetails = ({ jobRequestId, actualEndTime, stripePaymentDetails }) => {
  let { paymentDate } = stripePaymentDetails;
  const { paymentStatus, paymentAmount, paymentCurrency } = stripePaymentDetails;
  paymentDate = paymentDate ? moment(paymentDate).format('L') : '-';
  const getTosLink = e => {
    e.preventDefault();
    window.location.href = `mailto:${emailLink.complaintsEmail}?subject=Stop%20Payment%20for%20${jobRequestId}&body=Hi%20Properly%20team,%0ARequest%20you%20to%20stop%20AutoPayment%20for%20this%20Job%20${jobRequestId}%0AReason:`;
  };
  const canShowComplaintsButton =
    actualEndTime &&
    moment
      .utc(actualEndTime)
      .add(24, 'hours')
      .isAfter(moment().utc());

  return (
    <div className={cx('jobdetails__section--small-margin')}>
      <div className={cx('jobdetails__box', 'jobdetails__box--noptb')}>
        <div
          className={cx('jobdetails__payment-section', {
            'jobdetails__payment-section--active': true,
          })}
        >
          <div className={cx('jobdetails__payment-section-head')}>
            <div className={cx('jobdetails__payment-icon')}>
              <Icon.IcDollar />
            </div>
            <span>{t('jobdetails.payment_details')}</span>
          </div>
        </div>

        <div style={{ position: 'relative' }}>
          <DividerLine type={['top', 'light']} />
        </div>

        <div className={cx('jobdetails__payment-container')}>
          <div className={cx('jobdetails__payment-subcontainer')}>
            <div className={cx('jobdetails__payment-subcontainer-item', 'jobdetails__flex')}>
              <Small>{t('job_request.wizard.amount')}</Small>
              <div className={cx('jobdetails__payment-subcontainer-item-tooltip')}>
                <HoverTriggeredTooltip title={t('jobdetails.payment_amount_info')}>
                  <Icon.IcWarning height="12px" width="12px" byWidth />
                </HoverTriggeredTooltip>
              </div>
            </div>

            <div className={cx('jobdetails__payment-subcontainer-item')}>
              <Small>{paymentCurrency && paymentAmount ? `${paymentCurrency} ${paymentAmount}` : '-'}</Small>
            </div>
          </div>

          <div className={cx('jobdetails__payment-subcontainer')}>
            <div className={cx('jobdetails__payment-subcontainer-item')}>
              <Small>{t('jobdetails.payment_due_date')}</Small>
            </div>

            <div className={cx('jobdetails__payment-subcontainer-item')}>
              <Small>{paymentDate}</Small>
            </div>
          </div>

          <div className={cx('jobdetails__payment-subcontainer')}>
            <div
              className={cx(
                'jobdetails__payment-subcontainer-item',
                'jobdetails__payment-subcontainer-item-against-chip',
              )}
            >
              <Small>{t('jobdetails.payment_status')}</Small>
            </div>
            <Small>{paymentStatus ? <PaymentStatusChip paymentStatus={paymentStatus} /> : '-'}</Small>
          </div>
        </div>
      </div>

      {canShowComplaintsButton && (
        <div className={cx('jobdetails__payment-caption-info')}>
          <Button types={['size-small', 'type-border-primary']} onClick={getTosLink}>
            <Micro>{t('jobdetails.payment_complaint')}</Micro>
          </Button>
        </div>
      )}
    </div>
  );
};

export const JobDetailsImageGrid = ({
  images,
  openVerificationPics,
  openFirstVerificationPic,
  jobRequestId,
  withThumbs,
}) => (
  <div className={cx('jobdetails__imagegrid')}>
    {map(chunk(images, 3), (imageRow, key) => (
      <div key={key} className={cx('jobdetails__imagegrid-row')}>
        {map(imageRow, (image, keyImg) => (
          <div key={`${image}${keyImg}`} className={cx('jobdetails__imagegrid-item')}>
            <div
              onClick={openVerificationPics(images, image)}
              className={cx('jobdetails__imagegrid-image')}
              style={genImageBg(image, pictureSizes.PROFILE_PICTURE)}
            >
              {withThumbs && (
                <div className={cx('jobdetails__imagegrid-image-bubble')}>
                  <ThumbsBubbleBar imageId={image} oldJobRequestId={jobRequestId} />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    ))}
    <Button onClick={openFirstVerificationPic} types={['type-full-primary', 'size-medium', 'width-flex']}>
      {t('jobdetails.view_photos')}
    </Button>
  </div>
);

function genName(cleaner) {
  return `${cleaner.contact.userData.firstName} ${cleaner.contact.userData.lastName}`;
}

function mapStatus(status, date, timezone, isCleanerList) {
  const formattedDate = moment(date)
    .tz(timezone)
    .format('L');

  const cleanerStatuses = [cleanerStatus.Accepted, cleanerStatus.Finished, cleanerStatus.InProgress];
  const includesCleanerStatus = cleanerStatuses.includes(status);

  if (isCleanerList && includesCleanerStatus) {
    return `${t('jobdetails.accepted')} ${formattedDate}`;
  }

  switch (status) {
    case cleanerStatus.Pending:
      return t('jobdetails.pending');
    case cleanerStatus.PendingChanges:
      return t('jobdetails.pendingChanges');
    case cleanerStatus.Declined:
    case cleanerStatus.DeclinedChanges:
      return t('jobdetails.declined');
    case cleanerStatus.Accepted:
      return `${t('jobdetails.accepted')} ${formattedDate}`;
    case cleanerStatus.InProgress:
      return t('jobdetails.acceptedreal');
    case cleanerStatus.Finished:
      return `${t('jobdetails.finished_on')} ${formattedDate}`;
    case cleanerStatus.Viewed:
      return t('jobstatus.seen');
    case cleanerStatus.AcceptedByOtherCleaner:
      return t('jobstatus.accepted_by_other_user');
    case cleanerStatus.CanceledByHost:
    case cleanerStatus.CanceledByCleaner:
      return t('jobdetails.canceled');
    default:
      return '';
  }
}

export const JobDetailsCleanerList = ({
  cleaners,
  timezone,
  onClick,
  showButton,
  sagaCleaner,
  setOpenMore,
  isOpen,
  proMarketProperty,
}) => {
  const contentInner = map(
    sortBy(cleaners, cleaner => cleaner.request.status !== 3),
    cleaner => {
      const status = mapStatus(cleaner.request.status, cleaner.request.date, timezone, true);
      const { userData } = cleaner.contact || {};
      const { isSuggested, contactId } = cleaner.request || {};

      return (
        <MasterDetailCell
          key={generate()}
          picture={{
            text: getInitials(userData.firstName, userData.lastName),
            picture: userData.pictureUrl,
          }}
          type="jobdetails"
          noHover={!onClick}
          onClick={() => onClick && onClick(contactId)}
          active={false}
          note={cleaner.request.note}
          title={genName(cleaner)}
          subTitle={status}
          showProBadge={proMarketProperty && isSuggested}
        />
      );
    },
  );
  const content = (
    <div>
      {contentInner}
      {showButton && (
        <div>
          <SpaceSmall />
          <Button
            onClick={() => sagaCleaner()}
            types={['type-border-primary', 'size-medium', 'width-flex']}
            data-key="send-to-more-button"
          >
            {t('jobdetails.send_to_more')}
          </Button>
        </div>
      )}
    </div>
  );

  const renderHow = bool => {
    if (bool) {
      return (
        <MoreLess maxHeight={10000} peekHeight="98px" color="grey" open={isOpen} onClick={() => setOpenMore()}>
          {content}
        </MoreLess>
      );
    }
    return content;
  };
  const hasAccepted = find(cleaners, cleaner => cleaner.request.status === 3) !== undefined;
  return <div>{renderHow(hasAccepted && cleaners.length > 1)}</div>;
};

export const JobDetailsSummary = ({
  tasksDone,
  totalTaskCount,
  verificationDoneCount,
  totalVerificationCount,
  actualStartTime,
  timezone,
  actualEndTime,
  cleaners,
  locationStatus,
  commentsViewed,
  commentsTotal,
  thumbsUp,
  thumbsDown,
  proMarketProperty,
}) => {
  const validStatuses = {
    finished: 1,
    accepted: 2,
    inProgress: 2,
    canceledByCleaner: 3,
  };

  const filteredCleaners = filter(
    cleaners,
    cleaner => indexOf(Object.keys(validStatuses), cleaner.request.status) >= 0,
  );

  const mappedCleaners = map(filteredCleaners, cleaner => {
    const index = indexOf(Object.keys(validStatuses), cleaner.request.status);
    const statusPriority = validStatuses[Object.keys(validStatuses)[index]];
    return { ...cleaner, request: { ...cleaner.request, statusPriority } };
  });

  const sortedCleaners = sortBy(mappedCleaners, cleaner => cleaner.request.statusPriority);

  const cleanerFound = find(
    sortedCleaners,
    cleaner =>
      cleaner.request.statusPriority === 1 ||
      cleaner.request.statusPriority === 2 ||
      cleaner.request.statusPriority === 3,
  );

  let cleanerRender = null;
  if (cleanerFound) {
    const { status, date } = cleanerFound?.request || {};
    const { firstName, lastName, pictureUrl } = cleanerFound?.contact?.userData || {};

    const jobStatus = mapStatus(status, date, timezone);
    const name = genName(cleanerFound);

    cleanerRender = (
      <MasterDetailCell
        picture={{
          text: getInitials(firstName, lastName),
          picture: pictureUrl,
        }}
        type="jobdetails"
        noHover
        picRight
        onClick={() => {}}
        active={false}
        subTitleColor
        title={name}
        subTitle={jobStatus}
        showProBadge={proMarketProperty && cleanerFound.request.isSuggested}
      />
    );
  }
  const totalVCount = totalVerificationCount || 0;

  const missingFeedbackCount = verificationDoneCount - (thumbsUp + thumbsDown);

  const feedbacks = [
    {
      id: 1,
      count: thumbsUp,
      icon: <Icon.IcThumbsUp3 />,
      text: t('jobdetails.thumbs_up'),
    },
    {
      id: 2,
      count: thumbsDown,
      icon: <Icon.IcThumbsDown3 />,
      text: t('jobdetails.thumbs_down'),
    },
    {
      id: 3,
      count: missingFeedbackCount,
      icon: <Icon.IcMissingFeedback />,
      text: t('jobdetails.missing_feedback'),
    },
  ];

  return (
    <div className={cx('jobdetails__box', 'jobdetails__box--noptb')}>
      {cleanerRender}
      <div className={cx('jobdetails__summary-section', 'jobdetails__summary-section--big')}>
        <div
          className={cx('jobdetails__summary-section-inner', {
            'jobdetails__summary-section-inner--active': actualStartTime,
          })}
        >
          {!actualStartTime && <div className={cx('jobdetails__summary-section-inner-big')}>—</div>}
          {actualStartTime && (
            <>
              <div className={cx('jobdetails__summary-section-inner-text')}>
                {moment(actualStartTime)
                  .tz(timezone)
                  .format('MM/DD/YY')}
              </div>
              <div className={cx('jobdetails__summary-section-inner-big')}>
                {moment(actualStartTime)
                  .tz(timezone)
                  .format('LT')}
                <span className={cx('jobdetails__space8')} />
                <LocationIcon status={locationStatus.start} />
              </div>
            </>
          )}
          <div>{t('jobdetails.started')}</div>
        </div>
        <div
          className={cx('jobdetails__summary-section-inner', {
            'jobdetails__summary-section-inner--active': actualEndTime,
          })}
        >
          {!actualEndTime && <div className={cx('jobdetails__summary-section-inner-big')}>—</div>}
          {actualEndTime && (
            <>
              <div className={cx('jobdetails__summary-section-inner-text')}>
                {moment(actualEndTime)
                  .tz(timezone)
                  .format('MM/DD/YY')}
              </div>
              <div className={cx('jobdetails__summary-section-inner-big')}>
                {moment(actualEndTime)
                  .tz(timezone)
                  .format('LT')}
                <span className={cx('jobdetails__space8')} />
                <LocationIcon status={locationStatus.end} />
              </div>
            </>
          )}
          <div>{t('jobdetails.finished')}</div>
        </div>
      </div>
      {totalVCount > 0 && (
        <div
          className={cx('jobdetails__summary-section', {
            'jobdetails__summary-section--active': true, // (verificationDoneCount > 0)
          })}
        >
          <DividerLine type={['top', 'light']} />
          <div className={cx('jobdetails__summary-section-head')}>
            <div className={cx('jobdetails__summary-icon')}>
              <Icon.IcPhotosJobdetails />
            </div>
            <span>{t('jobdetails.photos')}</span>
          </div>
          <div className={cx('jobdetails__canorange')}>
            {`${verificationDoneCount || 0} ${t('jobdetails.of')} ${totalVCount}`}
          </div>
        </div>
      )}
      {totalTaskCount > 0 && (
        <div
          className={cx('jobdetails__summary-section', {
            'jobdetails__summary-section--active': true, // (tasksDone > 0)
          })}
        >
          <DividerLine type={['top', 'light']} />
          <div className={cx('jobdetails__summary-section-head')}>
            <div className={cx('jobdetails__summary-icon')}>
              <Icon.IcTaskRemain />
            </div>
            <span>{t('jobdetails.task_complete')}</span>
          </div>
          <div className={cx('jobdetails__canorange')}>{`${tasksDone} ${t('jobdetails.of')} ${totalTaskCount}`}</div>
        </div>
      )}
      {commentsTotal > 0 && (
        <div
          className={cx('jobdetails__summary-section', {
            'jobdetails__summary-section--active': true, // (tasksDone > 0)
          })}
        >
          <DividerLine type={['top', 'light']} />
          <div className={cx('jobdetails__summary-section-head')}>
            <div className={cx('jobdetails__summary-icon')}>
              <Icon.IcComments />
            </div>
            <span>{t('jobdetails.comments_viewed')}</span>
          </div>
          <div className={cx('jobdetails__canorange')}>
            {`${commentsViewed} ${t('jobdetails.of')} ${commentsTotal}`}
          </div>
        </div>
      )}
      {totalVerificationCount > 0 &&
        feedbacks.map(feedback => (
          <JobDetailsFeedback key={feedback.id} feedback={feedback} verificationDoneCount={verificationDoneCount} />
        ))}
    </div>
  );
};

JobDetailsSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

JobDetailsSection.defaultProps = {
  children: null,
};

JobDetailsAddress.propTypes = {
  propertyData: PropTypes.shape().isRequired,
};

JobDetailsImageGrid.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  openVerificationPics: PropTypes.func.isRequired,
  openFirstVerificationPic: PropTypes.func.isRequired,
  jobRequestId: PropTypes.string.isRequired,
  withThumbs: PropTypes.bool.isRequired,
};

JobDetailsCleanerList.propTypes = {
  cleaners: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  timezone: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  showButton: PropTypes.bool.isRequired,
  sagaCleaner: PropTypes.func.isRequired,
  setOpenMore: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

JobDetailsSummary.propTypes = {
  tasksDone: PropTypes.number.isRequired,
  totalTaskCount: PropTypes.number.isRequired,
  verificationDoneCount: PropTypes.number.isRequired,
  totalVerificationCount: PropTypes.number.isRequired,
  actualStartTime: PropTypes.string,
  timezone: PropTypes.string.isRequired,
  actualEndTime: PropTypes.string,
  cleaners: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  locationStatus: PropTypes.shape().isRequired,
  commentsViewed: PropTypes.number.isRequired,
  commentsTotal: PropTypes.number.isRequired,
  thumbsUp: PropTypes.number.isRequired,
  thumbsDown: PropTypes.number.isRequired,
};

JobDetailsSummary.defaultProps = {
  actualStartTime: null,
  actualEndTime: null,
};
