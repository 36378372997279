import { delay } from 'redux-saga';
import { put, fork, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { initialize, reset } from 'redux-form';
import { isWithinRange } from '@properly/common';
import memoize from 'lodash/memoize';
import log from 'loglevel';
import moment from 'moment-timezone';
import * as types from '../../../../types';
import {
  loadCalendarEventsRequest,
  loadCalendarEventsSuccess,
  loadCalendarEventsFailure,
  setDates,
} from './CalendarActions';
import { CALENDAR_FILTER_FORM_NAME } from '../containers/CalendarFilterContainer';
import { selectCurrentUserLoggingOut, selectCurrentUserLoggedIn } from '../../../../selectors/globalSelector';
import * as selectors from './CalendarSelectors';
import { trackCalendarSearchQuery } from '../../../../actions/trackingEvents';
import { client as apolloClient } from '../../../../config/apollo';
import { GetCalendarPropertyEvents } from './CalendarQuery';

const getStartDateQuery = memoize(startDate => {
  const date = (startDate && moment(startDate)) || moment().subtract(1, 'd');
  return date.startOf('day').toISOString();
});

const getEndDateQuery = memoize(endDate => {
  const date = (endDate && moment(endDate)) || moment().add(5, 'd');
  return date.endOf('day').toISOString();
});

export function getPropertyDatesQuery(startDate, endDate) {
  const startDateQuery = getStartDateQuery(startDate);
  const endDateQuery = getEndDateQuery(endDate);

  return {
    startDate: startDateQuery,
    endDate: endDateQuery,
  };
}

function* ensureDateInView() {
  const eventFiltersImmutable = yield select(selectors.selectFilters);
  const eventFilters = eventFiltersImmutable && eventFiltersImmutable.toJS();

  const startDate = yield select(selectors.selectStartDate);
  const endDate = yield select(selectors.selectEndDate);

  const { dateRange } = eventFilters || {};
  const { startDate: rangeStartDate, endDate: rangeEndDate } = dateRange || {};

  const withinRange = isWithinRange({ rangeStartDate, rangeEndDate, startDate, endDate });

  // Make sure the current filter dates displayed to the user are within view of their calendar
  const hasAllDates = !!startDate && !!endDate && !!rangeStartDate && !!rangeEndDate;
  if (hasAllDates && !withinRange) {
    yield put(
      setDates(
        moment(rangeStartDate)
          .startOf('d')
          .toDate(),
        moment(rangeStartDate)
          .add(6, 'd')
          .endOf('d')
          .toDate(),
      ),
    );
  }
}

function* shouldLoadCalendarEvents(action) {
  const { type } = action || {};
  const isUserLoggingOut = yield select(selectCurrentUserLoggingOut());
  const isUserLoggedIn = yield select(selectCurrentUserLoggedIn());

  if (isUserLoggingOut || !isUserLoggedIn) {
    return;
  }

  const searchQuery = yield select(selectors.selectSearchQuery);
  const eventFiltersImmutable = yield select(selectors.selectFilters);
  const eventFilters = eventFiltersImmutable && eventFiltersImmutable.toJS();

  const propertiesLoadedImmutable = yield select(selectors.selectPropertiesLoaded);
  const propertyViewportStart = yield select(selectors.selectPropertyViewportStart);
  const propertyViewportEnd = yield select(selectors.selectPropertyViewportEnd);

  const dateBucketIdsImmutable = yield select(selectors.selectDateBucketIds);
  const dateBucketIds = (dateBucketIdsImmutable && dateBucketIdsImmutable.toJS()) || [];

  // Ensure the bucket hasn't already been loaded
  const bucketsToLoad = dateBucketIds.filter(
    dateBucketId =>
      typeof propertiesLoadedImmutable.getIn([dateBucketId, String(propertyViewportStart)]) === 'undefined' ||
      typeof propertiesLoadedImmutable.getIn([dateBucketId, String(propertyViewportEnd)]) === 'undefined',
  );

  const indexesToLoad = bucketsToLoad
    .map(dateBucketId => {
      const propertiesLoadedBucket = propertiesLoadedImmutable.get(dateBucketId);

      const limit = propertyViewportEnd === 0 ? 10 : propertyViewportEnd - propertyViewportStart;
      const indexes = [...new Array(limit)].map((empty, index) => propertyViewportStart + index);
      const excludedIndexes = indexes.filter(
        index => !propertiesLoadedBucket || typeof propertiesLoadedBucket.get(String(index)) === 'undefined',
      );
      const loadStart = excludedIndexes[0];
      const indexesCount = excludedIndexes.length;

      return {
        dateBucketId,
        offset: loadStart,
        limit: indexesCount,
      };
    })
    .filter(({ limit }) => limit !== 0);

  log.info('bucketsToLoad + indexesToLoad', { bucketsToLoad, indexesToLoad });

  const calendarEventRequests = indexesToLoad.map(({ dateBucketId, limit, offset }) => ({
    dateBucketId,
    limit,
    offset,
    startDate: moment(dateBucketId.split('-')[0])
      .startOf('day')
      .toDate(),
    endDate: moment(dateBucketId.split('-')[1])
      .endOf('day')
      .toDate(),
  }));

  // Load each date bucket with its own request
  yield calendarEventRequests.map(({ startDate, endDate, dateBucketId, limit, offset }) => {
    if (limit % 10 !== 0) {
      log.warn('Limit is not a multiple of 10');
      return put(loadCalendarEventsRequest(startDate, endDate, offset, limit, dateBucketId, searchQuery, eventFilters));
    }

    const limitBreaks = limit / 10;

    return [...new Array(limitBreaks)].map((__, index) => {
      const currentOffset = offset + index * 10;
      const currentLimit = 10;
      return put(
        loadCalendarEventsRequest(
          startDate,
          endDate,
          currentOffset,
          currentLimit,
          dateBucketId,
          searchQuery,
          eventFilters,
        ),
      );
    });
  });

  const isCalendarRequests = calendarEventRequests.length > 0;
  const isTriggeredFromFilterChange = type === types.CALENDAR_SET_EVENT_FILTERS;
  if (isCalendarRequests && isTriggeredFromFilterChange) {
    yield delay(0);
    yield ensureDateInView();
  }
}

function* loadCalendarEvents({
  startDate,
  endDate,
  dateBucketId,
  eventFilters,
  hasFilters,
  searchQuery,
  requestId,
  offset,
  limit,
}) {
  try {
    const dateQuery = getPropertyDatesQuery(startDate, endDate);
    log.info(`Loading calendar events ${limit} properties`);

    const queryVariables = {
      timeZone: moment.tz.guess(),
      offset,
      limit,
      filters: (eventFilters || []).length === 0 ? null : eventFilters,
      eventStartDate: dateQuery.startDate,
      eventEndDate: dateQuery.endDate,
      propertySearch: searchQuery || null,
    };

    log.info('Calendar Query variables', queryVariables);

    const { error, data } = yield apolloClient.query({
      query: GetCalendarPropertyEvents,
      fetchPolicy: 'no-cache',
      variables: queryVariables,
    });

    const { calendarProperties: calendarPropertiesRoot } = data || {};
    const { info, result } = calendarPropertiesRoot || {};
    const { total } = info || {};

    yield put(
      error
        ? loadCalendarEventsFailure(error, requestId)
        : loadCalendarEventsSuccess(result, total, offset, limit, dateBucketId, requestId, hasFilters, searchQuery),
    );
  } catch (error) {
    log.error('Error loading calendar events', error);
    yield put(loadCalendarEventsFailure(error, requestId));
  }
}

function* resetCalendarReduxFormDataOnLogout() {
  yield put(initialize(CALENDAR_FILTER_FORM_NAME, {}));
  yield put(reset(CALENDAR_FILTER_FORM_NAME));
}

function* saga() {
  yield fork(takeEvery, types.GLOBAL_LOGOUT, resetCalendarReduxFormDataOnLogout);
  yield fork(takeEvery, types.CALENDAR_INIT, shouldLoadCalendarEvents);
  yield fork(takeEvery, types.CALENDAR_SET_DATES, shouldLoadCalendarEvents);
  yield fork(takeEvery, types.CALENDAR_SET_PROPERTY_VIEWPORT, shouldLoadCalendarEvents);
  yield fork(takeEvery, types.CALENDAR_SET_EVENT_FILTERS, shouldLoadCalendarEvents);
  yield fork(takeLatest, types.CALENDAR_SET_SEARCH_QUERY, function* delaySearchQueryLoad({ query }) {
    yield query ? delay(500) : delay(0);
    yield shouldLoadCalendarEvents();
    yield trackCalendarSearchQuery(query);
  });

  yield fork(takeEvery, types.CALENDAR_LOAD_EVENTS_REQUEST, loadCalendarEvents);
}

export default saga;
