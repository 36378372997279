"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MORE_THAN_ONE_IMAGE_CELL_HEIGHT = exports.ONE_IMAGE_CELL_HEIGHT = void 0;
var ONE_IMAGE_CELL_HEIGHT = 180; // in 'px'

exports.ONE_IMAGE_CELL_HEIGHT = ONE_IMAGE_CELL_HEIGHT;
var MORE_THAN_ONE_IMAGE_CELL_HEIGHT = 130; // in 'px'

exports.MORE_THAN_ONE_IMAGE_CELL_HEIGHT = MORE_THAN_ONE_IMAGE_CELL_HEIGHT;