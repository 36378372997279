import { roundNext15Min } from '@properly/common';
import { delay } from 'redux-saga';
import { put, fork, select, take, call, cancel, takeEvery } from 'redux-saga/effects';
import log from 'loglevel';
import path from 'ramda/src/path';
import lodashResult from 'lodash/result';
import keyBy from 'lodash/keyBy';
import extend from 'lodash/extend';
import moment from 'moment-timezone';
import { fixJobRequestStartTime } from '@properly/common/src';
import * as types from '../../../types';
import {
  loadDataSaga as loadDataSagaImport,
  setTodosData,
  setTodosReset,
  handleClickSaga as handleClickSagaImport,
  mergeEventsStore,
} from './TodosActions';
import { parseSkipEvent } from '../data';
import { getEventsTodos } from '../data.http.events';
import { selectTodosPagination, selectTodosEvents } from './TodosSelectors';
import { trackEventSkip } from '../../../actions/trackingEvents';
import { prefilledDurationNewJobRequest, prefilledJobRequestStartTime } from '../../../config';
import { handleEventChannelEvent } from '../../../sagas/global';
import { setGlobalLoadingState, updateCollectionEntryBatch, setModal } from '../../../actions/globalActions';

// bad import
import {
  openJobRequestDetails,
  setJobRequestDetails,
  editSaga,
  moreCleanersSaga,
} from '../jobDetails/state/JobDetailsActions';
import { setModalData } from '../calendar/state/CalendarActions';
import { selectCurrentUser } from '../../../selectors/globalSelector';

// Graphql API
import { cancelJobRequest } from '../../../graphql/api/jobRequest';
import { getSpecificJobrequest } from '../../../graphql/api/jobList';

const queue = [];

function* emptyQueue() {
  const cloned = queue.slice(0);
  if (cloned.length > 0) {
    log.info('flush - todo', cloned);
    queue.splice(0, queue.length);
    yield put(mergeEventsStore(keyBy(cloned, 'objectId')));
  }
}

function* intervalEmptyQueue() {
  while (true) {
    yield delay(1000);
    yield emptyQueue();
  }
}

function* loadJobRequestForModal(jobRequestId) {
  try {
    const res = yield call(getSpecificJobrequest, jobRequestId);
    yield put(
      updateCollectionEntryBatch(
        'jobRequests',
        {
          [res.jobId]: res,
        },
        true,
      ),
    );
    yield put(
      setJobRequestDetails({
        selectedJobRequestId: res.jobId,
        jobProgress: res.jobProgress,
        title: res.title,
        state: 0,
      }),
    );
  } catch (e) {
    log.error(loadJobRequestForModal, e);
  }
}

function* eventChannelStartSaga() {
  try {
    log.info('eventChannelStartSaga - start todo');
    const currentUser = yield select(selectCurrentUser());
    const myFork = yield fork(handleEventChannelEvent, {
      className: 'Event',
      listenOnArray: ['update', 'create'],
      queryModifierFunc: query => {
        query.containedIn('ownerRole', currentUser.actAsRole);
        return query;
      },
      handleEventGenerator: calendarEvent => {
        queue.push(calendarEvent);
      },
    });
    const intervalFlush = yield fork(intervalEmptyQueue);
    yield take([types.TODOS_RESET, types.GLOBAL_LOGOUT]);
    yield cancel(myFork);
    yield cancel(intervalFlush);
    log.info('eventChannelStartSaga - stop todo');
  } catch (e) {
    log.error('eventChannelStartSaga - todo', e);
  }
}

function* loadData(date, skip, limit) {
  try {
    const todos = yield call(getEventsTodos, date, skip, limit);

    return todos;
  } catch (e) {
    log.error('Loading todos error', e);
    return false;
  }
}

function* initSaga({ initOrDeinit, date }) {
  if (initOrDeinit) {
    // load first batch
    yield put(loadDataSagaImport(false, date));
    yield call(eventChannelStartSaga);
  } else {
    // reset
    yield put(setTodosReset());
  }
}

function* mergeWithInStoreData(data) {
  const inStoreEvents = yield select(selectTodosEvents());
  return extend({}, data, inStoreEvents?.toJS());
}

function* loadDataSaga({ paginate, date }) {
  const paginationData = yield select(selectTodosPagination);
  const finalDate = date || paginationData.get('date');

  if (!paginate) {
    yield put(setGlobalLoadingState('todos', 1));
  }

  const res = yield call(loadData, finalDate, paginationData.get('skip'), paginationData.get('limit'));

  if (res) {
    const newSkipVal = res.length + paginationData.get('skip');
    const hasMoreData = res.length !== 0;
    const resKeyed = keyBy(res, 'objectId');
    const merged = yield mergeWithInStoreData(resKeyed); // avoid duplicates

    yield put(
      setTodosData({
        events: merged,
        pagination: {
          date: finalDate,
          skip: newSkipVal,
          hasMoreData,
          limit: paginationData.get('limit'),
        },
      }),
    );

    if (!paginate) {
      yield put(setGlobalLoadingState('todos', 2));
    }
  } else if (!paginate) {
    yield put(setGlobalLoadingState('todos', 3));
  }
}

function* openNewJobRequestModal(property, time, event, eventId, preSetChecklist) {
  const getEventJobRequestSettings = path(['payload', 'jobRequestSettings']);
  const getEventReminderTitle = path(['payload', 'trigger', 'title']);
  const getEventReminderStartTime = path(['payload', 'trigger', 'startTime']);
  const getEventReminderEndTime = path(['payload', 'trigger', 'endTime']);

  const { time: timeSettings, offeredPrice, offeredPriceCurrency, message } = getEventJobRequestSettings(event) || {};

  // get the computed default start time
  const computedjobStartTime = fixJobRequestStartTime(time, prefilledJobRequestStartTime);

  const { endTime: endTimeSettings, startTime: startTimeSetting, duration: durationSetting, type } = timeSettings || {};

  const title = getEventReminderTitle(event);

  const startTime = startTimeSetting || computedjobStartTime || time || getEventReminderStartTime(event);

  const endTime =
    endTimeSettings ||
    moment(computedjobStartTime).add(durationSetting || prefilledDurationNewJobRequest, 'minutes') ||
    moment(time).add(durationSetting || prefilledDurationNewJobRequest, 'minutes') ||
    getEventReminderEndTime(event);

  yield put(
    setModal(true, 'jobrequest', {
      mode: 'normal',
      source: 'event',
      event,
      eventId,
      data: {
        message,
        price: offeredPrice,
        currency: offeredPriceCurrency,
        title,

        startTime: startTime && roundNext15Min(startTime),
        endTime: endTime && roundNext15Min(endTime),
        duration: durationSetting || prefilledDurationNewJobRequest,
        jobStartTimeType: type || 'fixed',

        propertyId: property,
        checklistId: preSetChecklist ? [preSetChecklist] : undefined,
      },
    }),
  );
}

function* handleClickSaga(action) {
  const eventId = lodashResult(action, ['data', 'objectId']);
  const propertyId = lodashResult(action, ['data', 'propertyId']);
  const triggerTime = lodashResult(action, ['data', 'triggerTime']);
  const jobSnapshotId = lodashResult(action, ['data', 'jobSnapshotId']);

  // open jobrequest details
  if (action.mode === 'clicktile') {
    yield put(openJobRequestDetails(action.data.jobSnapshotId));
  }

  // schedule job
  if (action.mode === 'schedule') {
    yield openNewJobRequestModal(propertyId, triggerTime, action.data, eventId, action.data.triggerJobId);
  }

  if (action.mode === 'skip') {
    yield put(
      setModalData({
        mode: 'skip',
        continue: handleClickSagaImport.bind(null, 'skipreal', action.data),
        restore: () => {},
      }),
    );
  }
  if (action.mode === 'skipreal') {
    try {
      yield call(parseSkipEvent, eventId); // access event obj
      trackEventSkip(eventId);
    } catch (e) {
      log.error('handleClickSaga - skipreal', e);
    }
  }

  if (action.mode === 'sendmore') {
    yield loadJobRequestForModal(jobSnapshotId);
    yield put(moreCleanersSaga(true));
  }
  if (action.mode === 'edit') {
    yield loadJobRequestForModal(jobSnapshotId);
    yield put(editSaga(true, { event: action.event }));
  }
  if (action.mode === 'cancel') {
    yield put(
      setModalData({
        mode: 'cancel',
        continue: handleClickSagaImport.bind(null, 'cancelreal', action.data),
        restore: () => {},
      }),
    );
  }
  if (action.mode === 'cancelreal') {
    try {
      yield call(cancelJobRequest, jobSnapshotId);
    } catch (e) {
      log.error('handleClickSaga - cancelreal', e);
    }
  }
  if (action.resolve) {
    action.resolve();
  }
}

function* saga() {
  yield fork(takeEvery, types.TODOS_INIT_SAGA, initSaga);
  yield fork(takeEvery, types.TODOS_LOAD_DATA, loadDataSaga);
  yield fork(takeEvery, types.TODOS_HANDLE_CLICK_SAGA, handleClickSaga);
}

export default saga;
