import { HAS_SEEN_ONBOARDING_VIDEO, DISABLE_SHOW_ONBOARDING_VIDEO } from '../../types';

export const setHasSeenOnBoardingVideo = () => ({
  type: HAS_SEEN_ONBOARDING_VIDEO,
});

export const disableShowOnBoardingVideo = status => ({
  type: DISABLE_SHOW_ONBOARDING_VIDEO,
  status,
});
