import React from 'react';
import PropTypes from 'prop-types';

import lodashResult from 'lodash/result';
import extend from 'lodash/extend';

class Snapshot extends React.Component {
  static propTypes = {
    data: PropTypes.shape({}),
    mapExternalToLocal: PropTypes.func.isRequired,
    hasDifference: PropTypes.func.isRequired,
    render: PropTypes.func.isRequired,
    getDefaultValues: PropTypes.func.isRequired,
    isSave: PropTypes.func.isRequired,
    flush: PropTypes.bool,
  };

  state = {
    data: {},
  };

  setLocalState(obj = {}, isMount) {
    let finalObj = this.props.mapExternalToLocal(obj);
    if (isMount && !this.props.isSave(finalObj)) {
      // only prefill defaultValues in create mode
      finalObj = extend({}, finalObj, this.props.getDefaultValues());
    }
    this.setState({
      data: finalObj,
    });
  }

  componentWillMount() {
    this.setLocalState(this.props.data, true);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setLocalState(nextProps.data);
    }
    if (nextProps.flush && !this.props.flush) {
      this.setLocalState(this.props.data); // flush to old state
    }
  }

  getter = key => lodashResult(this.state.data, [key]);

  get hasDiff() {
    return this.props.hasDifference(this.state.data, this.props.mapExternalToLocal(this.props.data));
  }

  setter = key => e => {
    let val = e;
    if (e.target) {
      val = e.target.value;
    }
    this.setState(state => ({
      data: {
        ...state.data,
        [key]: val,
      },
    }));
  };

  render() {
    return this.props.render(this.getter, this.setter, this.hasDiff, this.state.data);
  }
}

export default Snapshot;
