import Parse from 'parse';
import { fetchParseClassId } from './data.http';
import { requestHandler } from './data.requestHandler';
import { _mapParseUserToUser, _mapParseSubscriptionToSubscription, getValueFromKeyOrGetter } from './data.map';

export function refetchCurrentUser() {
  return requestHandler((resolve, reject) => {
    const currentUser = Parse.User.current();
    const currentUserSubscription = getValueFromKeyOrGetter(currentUser, 'subscription');
    const currentUserSubscriptionId = currentUserSubscription && currentUserSubscription.id;

    let myuser;

    if (currentUser) {
      const subscriptionFetch = currentUserSubscriptionId
        ? fetchParseClassId('Subscription', currentUserSubscriptionId)
        : Promise.resolve(); // eslint-disable-line.

      return fetchParseClassId('_User', currentUser.id)
        .then(latestUser => {
          myuser = latestUser;

          const latestUserSubscription = getValueFromKeyOrGetter(latestUser, 'subscription');
          const latestUserSubscriptionId = latestUserSubscription && latestUserSubscription.objectId;

          if (!latestUserSubscription) {
            return null;
          }

          if (latestUserSubscriptionId && latestUserSubscriptionId === currentUserSubscriptionId) {
            return subscriptionFetch;
          }

          return fetchParseClassId('Subscription', latestUserSubscriptionId);
        })
        .then(subscription => {
          const mappedUser = _mapParseUserToUser(myuser) || {};
          mappedUser.subscription = subscription ? _mapParseSubscriptionToSubscription(subscription) : null;

          return resolve(mappedUser);
        })
        .catch(reject);
    }

    return reject('No current user to fetch');
  });
}
