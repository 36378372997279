import { put, call, select, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import log from 'loglevel';
import { guardedFork } from '../../helper/saga';
import * as types from '../../types';
import { fetchBrandingConfig } from '../desktopApp/data';
import { setBrandingConfig } from './BrandingActions';
import { selectCurrentUserEmail } from '../../selectors/globalSelector';
import { ROUTES } from '../../paths';
import { getCustomBrandingConfig } from '../../graphql/api/customBranding';

function* brandingConfigSaga({ domainName: customBrandingDomain, utmSource }) {
  if (!customBrandingDomain && !utmSource) {
    // do not fetch branding config if customBrandingDomain and utmSource is not supplied. eg: app.getproperly.com
    return;
  }
  try {
    const config = yield call(fetchBrandingConfig, customBrandingDomain || utmSource);
    if (!config.apiError) {
      yield put(setBrandingConfig(config.data));
    }
  } catch (error) {
    log.info(`brandingConfigSaga not found ${error}`);
  }
}

export function* redirectToDownloadAppPageSaga() {
  const userEmail = yield select(selectCurrentUserEmail());
  yield put(push({ pathname: ROUTES.brandingMobileUpgrade(userEmail), state: { fromEntry: true } }));
}

export function* getCustomBrandingConfigSaga(customBrandingId) {
  try {
    const customBrandingConfig = yield call(getCustomBrandingConfig, customBrandingId);
    if (!customBrandingConfig.apiError) {
      yield put(setBrandingConfig(customBrandingConfig));
    }
  } catch (error) {
    log.error(`getCustomBrandingConfigSaga ${error}`);
  }
}

function* saga() {
  yield guardedFork(takeEvery, types.BRANDING_CONFIG_SAGA, brandingConfigSaga);
  yield guardedFork(takeEvery, types.BRANDING_REDIRECT_TO_DOWNLOAD_APP_PAGE, redirectToDownloadAppPageSaga);
}

export default saga;
