import React, { Component } from 'react';
import { ModalError } from '@properly/components';
import t from '@properly/localization';
import { connect } from 'react-redux';
import { selectGlobalModalResolved } from '../../../../selectors/globalSelector';
import { modals } from '../../../../dataConstants';
import { setModal } from '../../../../actions/globalActions';

class ContactErrorDeleteModal extends Component {
  close = () => this.props.setModal(false, modals.CONTACT_DELETE_MODAL);

  render() {
    return (
      <ModalError
        message1={t('contacts.delete_fail_contact_head')}
        message2={t('contacts.delete_fail_contact')}
        show={!!this.props.modal.isOpen}
        onClose={this.close}
      />
    );
  }
}

export default connect(
  state => ({
    modal: selectGlobalModalResolved(modals.CONTACT_DELETE_MODAL)(state),
  }),
  { setModal },
)(ContactErrorDeleteModal);
