import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';
import chunk from 'lodash/chunk';
import findIndex from 'lodash/findIndex';
import t, { detectLanguage } from '@properly/localization';
import { DividerLine } from '@properly/components';
import log from 'loglevel';
import { connect } from 'react-redux';
import * as selectors from '../state/ChecklistSelectors';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import { setTextTemplate, stepMutatorSaga } from '../state/ChecklistActions';
import { generateToken } from '../../data';
import ChecklistOverlay from '../../../../components/ChecklistOverlay/index';
import ChecklistHeader from '../../../../components/ChecklistHeader/index';
import ChecklistSidebarItem from '../../../../components/ChecklistSidebarItem/index';

class ChecklistTextTemplateContainer extends PureComponent {
  renderTextListsBig(textTemplates, onClickTile, getIndexFunc) {
    const cloneArray = map(textTemplates, item => ({
      ...item,
      steps: map(item.tasks, task => ({ note: task })), // to be able to reuse the component
    }));
    return map(chunk(cloneArray, 3), (row, iRow) => (
      <ChecklistOverlay.GridRow key={iRow} spacing="18">
        {map(row, (textTemplate, iTextTemplate) => {
          const myIndex = getIndexFunc(textTemplate);
          const isSelected = myIndex !== -1;
          return (
            <ChecklistOverlay.GridItem key={iTextTemplate} spacing="18" itemPerRow="3">
              <ChecklistSidebarItem
                data-key="text_document"
                color="grey"
                stepsSize="big"
                onClick={() => onClickTile(textTemplate)}
                title={textTemplate.title}
                steps={textTemplate.steps}
                noPadding
              >
                {!isSelected && <ChecklistOverlay.GridItemOverlay mode="canselect" />}
                {isSelected && <ChecklistOverlay.GridItemOverlay mode="num" number={myIndex + 1} />}
              </ChecklistSidebarItem>
            </ChecklistOverlay.GridItem>
          );
        })}
      </ChecklistOverlay.GridRow>
    ));
  }

  onClickTile = textTemplate => {
    const index = this.indexOf(textTemplate);
    const cloneArr = this.props.textTemplatesState.selected.slice(0);
    if (index !== -1) {
      cloneArr.splice(index, 1); // remove
    } else {
      cloneArr.push(textTemplate); // add
    }
    this.props.setTextTemplate({ selected: cloneArr });
  };

  indexOf = ele => findIndex(this.props.textTemplatesState.selected, { title: ele.title });

  onClose = () => {
    this.props.setTextTemplate({ isOpen: false, selected: [] });
  };

  onDone = () => {
    const { jobInstructionId, textTemplatesState: state } = this.props;
    const newTasks = map(state.selected, template => {
      const id = generateToken(10);
      const tasks = map(template.tasks, note => ({
        icon: 'other',
        note,
        objectId: generateToken(10),
      }));
      return {
        isVerificationNeeded: false,
        node: '',
        objectId: id,
        pictureUrl: '',
        room: null,
        section: '',
        tasks,
        title: template.title,
      };
    });
    if (state.do && state.do.mode && state.do.mode === 'setAfter') {
      log.info('texttemplate - onDone - setAfter', state.do.after, newTasks, jobInstructionId);
      this.props.stepMutatorSaga('setBetween', {
        steps: newTasks,
        index: state.do.after,
        jobInstructionId,
      });
    } else {
      log.info('texttemplate - onDone - addEnd', newTasks, jobInstructionId);
      this.props.stepMutatorSaga('addEnd', {
        steps: newTasks,
        jobInstructionId,
      });
    }
    this.props.setTextTemplate({ isOpen: false, selected: [], do: {} });
  };

  render() {
    const resolvedLang = detectLanguage();
    const textTemplates = this.props.parseConfig.templates ? this.props.parseConfig.templates[resolvedLang] : undefined;
    if (!textTemplates) return null;
    return (
      <ChecklistOverlay show={this.props.textTemplatesState.isOpen}>
        <ChecklistOverlay.Header>
          <ChecklistHeader
            type="multi"
            onClose={this.onClose}
            onDone={this.onDone}
            text={t('checklist.choose_txt_template')}
          />
          <DividerLine type={['bottom', 'light']} />
        </ChecklistOverlay.Header>
        <ChecklistOverlay.ContentWrap>
          <ChecklistOverlay.Content>
            <ChecklistOverlay.ContentInner>
              {this.renderTextListsBig(textTemplates, this.onClickTile, this.indexOf)}
            </ChecklistOverlay.ContentInner>
          </ChecklistOverlay.Content>
        </ChecklistOverlay.ContentWrap>
      </ChecklistOverlay>
    );
  }
}

ChecklistTextTemplateContainer.propTypes = {
  jobInstructionId: PropTypes.string.isRequired,
};

function mapStateToProps(state, props) {
  return {
    textTemplatesState: selectors.selectTextTemplates()(state, props),
    parseConfig: selectorsGlobal.selectConfig(state, props),
  };
}

export default connect(
  mapStateToProps,
  {
    setTextTemplate,
    stepMutatorSaga,
  },
)(ChecklistTextTemplateContainer);
