import { createSelector } from 'reselect';

export const selectBranding = state => state.branding;
export const selectConfig = createSelector(selectBranding, state => state.get('config'));
export const selectBranchIOLink = createSelector(selectBranding, state => state.get('branchIOLink'));

const selectOnboardingConfig = createSelector(selectConfig, state => state.get('onboardingConfig'));
export const selectHideConnectAccount = createSelector(selectOnboardingConfig, state =>
  state?.get('hideConnectAccount'),
);
export const selectAllowedConnections = createSelector(selectOnboardingConfig, state =>
  state?.get('allowedConnections'),
);
export const selectPartnerName = () => createSelector(selectConfig, state => state.get('partnerName'));
export const selectBrandingLogo = () => createSelector(selectConfig, state => state && state.get('logo'));
export const selectBrandingLogoCompact = () => createSelector(selectConfig, state => state.get('logo_compact'));
export const selectIsWebLoginDisabled = () => createSelector(selectConfig, state => state.get('isWebLoginDisabled'));
export const selectIsPartnerDomain = () => createSelector(selectConfig, state => state && state.get('isPartnerDomain'));
export const selectPartnerWebsiteUrl = () => createSelector(selectConfig, state => state.get('partnerWebsiteUrl'));
export const selectAds = () => createSelector(selectConfig, state => state.get('ads'));
export const selectPropertyPageAdFromAds = () => createSelector(selectAds(), ads => ads?.get('propertyPage'));
export const selectCalendarPageAdFromAds = () => createSelector(selectAds(), ads => ads?.get('calendarPage'));
export const selectChecklistLibraryAdFromAds = () =>
  createSelector(selectAds(), ads => ads?.get('checklistLibraryPage'));
export const selectTodosPageAdFromAds = () => createSelector(selectAds(), ads => ads?.get('todosPage'));
export const selectContactPageAdFromAds = () => createSelector(selectAds(), ads => ads?.get('cleanerPage'));
export const selectOnBoardingVideoUrl = () => createSelector(selectConfig, state => state.get('onBoardingVideoUrl'));
export const selectAdStyles = () => createSelector(selectConfig, config => config.get('style'));
export const selectOnBoardingVideoDisplayPaths = () =>
  createSelector(selectConfig, state => state.get('onBoardingVideoDisplayPaths'));
export const selectLandingPage = () => createSelector(selectConfig, state => state.get('landingPage'));
