"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  1: 'BookingStart',
  2: 'BookingEnd',
  3: 'ReminderJobRequest',
  4: 'JobRequest'
};
exports["default"] = _default;