import { createSelector } from 'reselect';

export const selectStripe = () => state => state.stripe;
export const selectStripeCustomer = () => createSelector(selectStripe(), stripe => stripe.get('stripeCustomer'));
export const selectStripeCustomerLoading = () =>
  createSelector(selectStripe(), stripe => stripe.get('stripeCustomerLoading'));
export const selectHostStripeStatus = () => createSelector(selectStripe(), state => state.get('hostStripeStatus'));
export const modalState = () => createSelector(selectStripe(), stripe => stripe.get('modal'));
export const intentState = () => createSelector(selectStripe(), stripe => stripe.get('intent'));
// bank account
export const selectDefaultSourceStripe = () =>
  createSelector(selectStripeCustomer(), stripe => stripe.get('defaultSource'));
// credit card
export const selectDefaultPaymentMethodStripe = () =>
  createSelector(selectStripeCustomer(), stripe => stripe.get('defaultPaymentMethod'));
