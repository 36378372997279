import { take, select, call, put, cancelled } from 'redux-saga/effects';
import { eventChannel, delay } from 'redux-saga';
import log from 'loglevel';

import indexOf from 'lodash/indexOf';
import * as types from '../types';
import { RELOAD_STATUS } from '../dataConstants';
import { selectPreloadStatus } from '../selectors/globalSelector';
import { liveQueryGenerator } from '../modules/desktopApp/data';
import { refetchCurrentUser } from '../modules/desktopApp/data.http.user';
import * as globalActions from '../actions/globalActions';

export function* waitForPreloadFinish(forceWait) {
  const currentStatus = yield select(selectPreloadStatus);
  if (forceWait || currentStatus === RELOAD_STATUS.NOTLOADED) {
    log.info('waitForPreloadFinish - start', { currentStatus, forceWait });

    yield take(action => action.type === types.GLOBAL_SET_PRELOAD_STATUS && action.val === RELOAD_STATUS.LOADED);

    log.info('waitForPreloadFinish - end', { currentStatus, forceWait });
  }
}

// 0 = maybe data not fully loaded, 1 = ok, 2 = not allowed
export function* canUserUseFeature(feature) {
  const resUser = yield select(state => state.currentUser);
  const isValid = resUser && resUser.user && resUser.user.subscription;
  const userSub = resUser.user.subscription;
  if (!isValid) {
    log.error('canUserUseFeature - no valid data');
    return 0; // if we dont have valid data then dont allow
  }
  log.info('canUserUseFeature', userSub);
  const findIndex = indexOf(userSub.features, feature);
  return findIndex !== -1 ? 1 : 2;
}

export function* refetchUser() {
  log.info('refetchUser - start');
  try {
    const resUser = yield refetchCurrentUser();
    log.info('refetchUser - user', resUser);

    if (resUser && !resUser.subscription) {
      throw new Error('Fetched user does not have a subscription object');
    }

    yield put(globalActions.updateCurrentUser(resUser));
    yield put({
      type: types.START_LISTEN_USER,
      id: resUser.objectId,
      subscriptionId: resUser.subscription.objectId,
    });
  } catch (e) {
    log.error('refetchUser', e);
  }
}

export function createEventChannel({ className, listenOnArray, queryModifierFunc }) {
  return eventChannel(emitter => {
    const cEventSubscription = liveQueryGenerator(className, emitter, listenOnArray, queryModifierFunc);
    return () => {
      try {
        cEventSubscription.unsubscribe();
      } catch (err) {
        // If an unsubscribe fails then it is not fatal
        log.warn('Could not unsubscribe from the parse live event channel', { err });
      }
    };
  });
}

export function* handleEventChannelEvent({ className, listenOnArray, queryModifierFunc, handleEventGenerator }) {
  const liveEventChannel = yield call(createEventChannel, { className, listenOnArray, queryModifierFunc });
  try {
    while (true) {
      const webEvent = yield take(liveEventChannel);
      yield handleEventGenerator(webEvent);
    }
  } finally {
    try {
      if (yield cancelled()) {
        liveEventChannel.close();
      }
    } catch (err) {
      log.error('Error canceling handleEventChannelEvent', err);
    }
  }
}

export function* pollChannel({
  pullFunc,
  args = {},
  handleEventGenerator,
  interval = 5000,
  timeout = 60000 * 5, // 5 minute timeout
}) {
  const startTime = new Date();
  try {
    while (true) {
      const result = yield call(pullFunc, args);
      const feedback = yield call(handleEventGenerator, { status: 0, result });
      if (feedback) {
        break;
      }
      if (new Date() - startTime > timeout) {
        throw new Error('timeout');
      }
      yield delay(interval);
    }
  } catch (e) {
    yield call(handleEventGenerator, { status: 1 });
  }
}
