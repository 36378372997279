import React from 'react';
import { LoadingSplash } from '@properly/components';

const withSuspense = (LazyComp, Loading) => {
  function WithSuspense(props) {
    return (
      <React.Suspense fallback={Loading ? <Loading /> : <LoadingSplash />}>
        <LazyComp {...props} />
      </React.Suspense>
    );
  }
  WithSuspense.displayName = `WithSuspense(${LazyComp.displayName || LazyComp.name || 'Component'})`;
  return WithSuspense;
};

export const lazyWithPreload = (factory, Loading) => {
  const Component = withSuspense(React.lazy(factory), Loading);
  Component.preload = factory;
  return Component;
};
