import React, { Component } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import map from 'lodash/map';
import { Large, Small, LoadingSplash } from '@properly/components';
import Scroll from 'react-scroll';
import t from '@properly/localization';
import classNames from 'classnames/bind';
import styles from '../components/checklistsStyles.module.css';
import ChecklistTextArea from '../../../../components/ChecklistTextArea/index';
import ChecklistBox from '../../../../components/ChecklistBox/index';
import NavContainer from '../../../../containers/NavContainer/index';
import { PageFlexOuter, PagePadding, PageFlexInner } from '../../../../components/PageElements/index';
import getDragWrapper from '../components/ChecklistDragWrapper';
import ChecklistSkillSidebar from './ChecklistSkillSidebar';
import DesktopAppContainer from '../../../../containers/DesktopAppContainer/index';
import ChecklistSkillList from './ChecklistSkillList';
import { MasterDetail, MasterDetailDetail, MasterDetailMaster } from '../../../../components/MasterDetail/index';

const cx = classNames.bind(styles);

class ChecklistsSkillContainer extends Component {
  constructor(props) {
    super(props);
    this.DragWrapper = getDragWrapper();
  }

  componentDidMount() {
    const { skillId, loadSkill, setActiveChecklist } = this.props;
    loadSkill(skillId);
    Scroll.Events.scrollEvent.register('end', to => {
      setActiveChecklist(to);
    });
  }

  componentDidUpdate(prevProps) {
    const { pathname, skillId, loadSkill } = this.props;
    if (prevProps.pathname !== pathname) {
      loadSkill(skillId);
    }
  }

  componentWillUnmount() {
    Scroll.Events.scrollEvent.remove('end');
  }

  onCloseView = () => {
    const { redirectUrl, push } = this.props;
    if (redirectUrl) {
      push(redirectUrl);
    }
  };

  renderSections = (steps, selectedSkill) => (
    <>
      {this.renderDescriptionBox(selectedSkill)}
      {map(steps, (step, index) => (
        <Scroll.Element key={step.id} name={step.id}>
          <div className={cx('checklist__box-wrap')} data-key="checklist-steps">
            <ChecklistBox>
              <ChecklistSkillList isReadOnly step={step} index={index} skillId={selectedSkill.skillId} />
            </ChecklistBox>
          </div>
          <Scroll.Link
            containerId="checklist-scroll-main"
            onSetActive={to => this.props.setActiveChecklist(to)}
            to={step.id}
            spy
          />
        </Scroll.Element>
      ))}
    </>
  );

  genNavItems = () => (
    <ul style={{ height: '100%', display: 'block' }}>
      <NavContainer.ClickItem onClick={this.onCloseView} side="left" data-key="closeChecklist">
        {t('checklist.close')}
      </NavContainer.ClickItem>
      {this.renderMiddleNav()}
    </ul>
  );

  renderTopMiddle(title) {
    return (
      <div className={cx('checklist__topalign', 'checklist__topalign--col')}>
        <span className={cx('checklist__topalign-title')}>{title}</span>
      </div>
    );
  }

  renderMiddleNav() {
    const { selectedSkill } = this.props;
    if (!selectedSkill) {
      return null;
    }
    return (
      <NavContainer.ClickItem data-key="checklist_title_btn" onClick={noop} side="middle">
        {this.renderTopMiddle(selectedSkill.title)}
      </NavContainer.ClickItem>
    );
  }

  renderWhat() {
    const { isLoading, isError, selectedSkill } = this.props;

    const selectedSteps = selectedSkill?.steps || [];

    if (isLoading) {
      return <LoadingSplash type="white" />;
    }

    if (isError) {
      return (
        <div className={cx('checklist__container--isError')}>
          <Large style={{ textAlign: 'center' }} type="bold red">
            Something went wrong while loading this skill
          </Large>
        </div>
      );
    }

    return (
      <MasterDetail>
        <MasterDetailMaster type="checklist">
          <ChecklistSkillSidebar selectedSkill={selectedSkill} isReadOnly />
        </MasterDetailMaster>
        <MasterDetailDetail>
          <PageFlexOuter>
            <PageFlexInner>
              <PagePadding id="checklist-scroll-main" type="absolute-scroll" variation="checklist">
                <div className={cx('checklist__maxwidth')}>{this.renderSections(selectedSteps, selectedSkill)}</div>
              </PagePadding>
            </PageFlexInner>
          </PageFlexOuter>
        </MasterDetailDetail>
      </MasterDetail>
    );
  }

  renderDescriptionBox(selectedSkill) {
    const { description } = selectedSkill || {};

    if (!description) {
      return null;
    }

    return (
      <div className={cx('checklist__box-wrap')}>
        <ChecklistBox>
          <div className={cx('checklist__topdes')}>
            <Small type="medium">{t('skills.skill_description')}</Small>
            <div className={cx('checklist__topdes-right')}>
              <ChecklistTextArea
                data-key="checklist_des_input"
                disabled
                defaultValue={description}
                placeholder={t('skills.skill')}
              />
            </div>
          </div>
        </ChecklistBox>
      </div>
    );
  }

  render() {
    const { DragWrapper } = this;
    return (
      <>
        <DragWrapper>
          <div data-key="checklist-container-modal">
            <DesktopAppContainer bgColor="grey" noMaxWidth canOverLayNav navItems={this.genNavItems()}>
              {this.renderWhat()}
            </DesktopAppContainer>
          </div>
        </DragWrapper>
      </>
    );
  }
}

ChecklistsSkillContainer.propTypes = {
  selectedSkill: PropTypes.shape({}).isRequired,
  redirectUrl: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  pathname: PropTypes.string,
  skillId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.shape({}),
  loadSkill: PropTypes.func.isRequired,
  setActiveChecklist: PropTypes.func.isRequired,
};

ChecklistsSkillContainer.defaultProps = {
  pathname: undefined,
  isLoading: false,
  isError: null,
};

export default ChecklistsSkillContainer;
