import gql, { disableFragmentWarnings } from 'graphql-tag';

disableFragmentWarnings();

export const JOB_REQUEST_QUERY = gql`
  query jobDetail($role: RoleType!, $languageCode: String, $id: ID!, $isHost: Boolean!) {
    Job(role: $role, id: $id, languageCode: $languageCode, isHost: $isHost) {
      ...propertyDetails

      progress {
        ...progressSummaryFields
      }

      checklists {
        ...checklistFields
      }

      cleaners {
        ...cleanerFields
      }

      status
      ownerRole

      job {
        ...jobFields
      }

      costs {
        ...costFields
      }

      sender {
        ...senderFields
      }

      booking {
        ...bookingFields
      }

      problems {
        ...problemFields
      }

      skillRequirements {
        ...skillRequirementsType
      }

      lastUpdatedAt
      permittedFeatures

      backwardCompatibility {
        jobRequestId
      }
      sourceProblems {
        problemIds
        sourceJobSnapshotId
      }
      stripePaymentDetails {
        id
        paymentDate
        paymentStatus
        paymentAmount
        paymentCurrency
      }
    }
  }

  fragment progressSummaryFields on progressSummaryFields {
    id
    actualStartTime
    actualEndTime
    startLocation {
      latitude
      longitude
    }
    endLocation {
      latitude
      longitude
    }
    completedTaskCount
    problemCount
    additionalReportCount
    verificationPictureCount
    bilableTime
    totalMileage
    totalCost
  }

  fragment senderFields on senderFields {
    id
    companyName
    userData {
      ...userFields
    }
  }

  fragment jobFields on jobFields {
    id
    title
    duration
    scheduledStartTime
    scheduledEndTime
    startTimeType
    totalTaskCount
    totalChecklistCount
    totalVerificationPictureCount
    offeredPrice
    offeredPriceCurrency
    note
    jobType
    isMonetizedJob
  }

  fragment checklistFields on checklistFields {
    id
    type
    title
    isOneOff
    sourceProblemId
    description
    pictureIdentifier
    totalTaskCount
    totalVerificationPictureCount
    steps {
      ...stepFields
    }

    doneTaskCount
    doneStepCount
    verificationPhotos {
      ...verificationPhotoFields
    }
    propertyIndependent
  }

  fragment stepFields on stepFields {
    id
    pictureIdentifier
    note
    room
    section
    title
    isComplete
    isVerificationRequired
    isVerificationComplete
    tasks {
      ...taskFields
    }

    incompleteTaskCount
  }

  fragment verificationPhotoFields on verificationPhotoFields {
    id
    stepTitle
    pictureIdentifier
    date
    stepId
  }

  fragment taskFields on taskFields {
    id
    note
    notePictureIdentifiers
  }

  fragment cleanerFields on cleanerFields {
    id
    status
    contactId
    note
    date
    companyName
    isSuggested
    userData {
      ...userFields
    }
    isPro
  }

  fragment costFields on costFields {
    date
    value
    type
    pictureIdentifiers
    reporterName
    note
  }

  fragment userFields on userFields {
    id
    firstName
    lastName
    profileId
    pictureIdentifier
    countryCode
    phoneNumber
    email
  }

  fragment bookingFields on bookingFields {
    id
    title
    partner
    guestName
    numberOfGuests
    arrivalDate
    departureDate
    nextArrivalDate
    tags
  }

  fragment problemFields on problemFields {
    id #stepId
    stepTitle
    checklistId
    checklistTitle
    note
    pictureIdentifiers
    severity
    date
    title
    status
    resolverName
    lastUpdatedAt
  }

  fragment skillRequirementsType on skillRequirementsType {
    dependency
    skillId
    title
  }

  fragment bedConfiguration on bedConfiguration {
    id
    type
    displayName
    quantity
  }

  fragment propertyDetailFields on propertyDetailFields {
    wifiPassword
    wifiName
    wifiInformation
    garbageInformation
    information
    parkingInformation
    accessInformation
    numberOfBathrooms
    numberOfBedrooms
    numberOfBeds
    bedConfigurations {
      ...bedConfiguration
    }
    tags #[String]
  }

  fragment propertyFields on propertyFields {
    id
    title
    type
    pictureIdentifier
    location {
      latitude
      longitude
    }
    timeZone
    city
    state
    country
    apartment
    street
    zip
    countryCode
    proMarketId
  }

  fragment propertyDetails on Job {
    property {
      ...propertyFields
      details {
        ...propertyDetailFields
      }
    }
  }
`;

export const QUERY_GET_PROGRESS_COUNT = gql`
  query jobProgressCount($role: RoleType!, $languageCode: String, $id: ID!, $isHost: Boolean!) {
    Job(role: $role, id: $id, languageCode: $languageCode, isHost: $isHost) {
      progress {
        verificationPictureCount
        completedTaskCount
      }
    }
  }
`;
