export default class Subscription {
  constructor(
    id,
    subscriptionProviderSubscriptionId,
    subscriptionProviderToken,
    currentPlanTitle,
    priceType,
    price,
    allowedJobsMonth,
    jobsUsedMonth,
    expiration,
    nextRenewal,
    lastRenewal,
    currentPlanId,
    features,
    annualSubscription,
    removePartnersTrialAccessBy,
  ) {
    this.objectId = id;
    this.subscriptionProviderSubscriptionId = subscriptionProviderSubscriptionId;
    this.subscriptionProviderToken = subscriptionProviderToken;
    this.currentPlanTitle = currentPlanTitle;
    this.priceType = priceType;
    this.price = price;
    this.allowedJobsMonth = allowedJobsMonth;
    this.jobsUsedMonth = jobsUsedMonth;
    this.expiration = expiration;
    this.nextRenewal = nextRenewal;
    this.lastRenewal = lastRenewal;
    this.currentPlanId = currentPlanId;
    this.features = features;
    this.annualSubscription = annualSubscription;
    this.removePartnersTrialAccessBy = removePartnersTrialAccessBy;
  }
}
