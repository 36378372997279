import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { pictureSizes, generatePictureUrl } from '@properly/common';
import classNames from 'classnames/bind';
import styles from './checklistsStyles.module.css';

const cx = classNames.bind(styles);

class ChecklistSkill extends Component {
  genImage(imageInner) {
    return {
      backgroundImage: `url(${generatePictureUrl(imageInner, pictureSizes.LARGE)})`,
    };
  }

  render() {
    const { image } = this.props;
    if (!image) {
      return null;
    }
    return (
      <div
        ref="image" // eslint-disable-line
        id="pin-image-wrap"
        className={cx('checklist__image', 'modal-container')}
      >
        <div className={cx('checklist__image-actual')} style={this.genImage(image)} />
      </div>
    );
  }
}

export default withRouter(ChecklistSkill);
