import { createStore, applyMiddleware, compose } from 'redux';
import * as Sentry from '@sentry/react';
import { routerMiddleware, CALL_HISTORY_METHOD, LOCATION_CHANGE } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import log from 'loglevel';
import { createLogger } from 'redux-logger';
import { getLogging } from '@properly/common';
import rootReducer from '../reducers';
import sagas from './sagas';
import { isApprovedAction } from '../helper/herbert';
import { interceptSaga, interceptModalSaga } from '../actions/globalActions';
import { GLOBAL_SET_MODAL, GLOBAL_LOGOUT } from '../types';
import { getAsyncInjectors } from '../helper/async';

export const sagaMiddleware = createSagaMiddleware({
  onError: error => {
    log.error(error);
  },
});

function routerInterceptor() {
  return store => next => action => {
    if (action.type === CALL_HISTORY_METHOD) {
      log.info('historyMethod', action);
    }
    if (action.type === LOCATION_CHANGE) {
      if (isApprovedAction(action)) return next(action);
      store.dispatch(interceptSaga(action));
      return undefined;
    }
    return next(action);
  };
}

function modalInterceptor() {
  return store => next => action => {
    if (action.type === GLOBAL_SET_MODAL && !action.isApproved) {
      store.dispatch(interceptModalSaga(action));
      return undefined;
    }
    return next(action);
  };
}

function graphqlLogoutReset(client) {
  return () => next => action => {
    if (action.type === GLOBAL_LOGOUT) {
      client.resetStore();
      return next(action);
    }
    return next(action);
  };
}

const logger = createLogger({
  predicate: () => true,
  collapsed: () => true,

  stateTransformer: state => {
    const newState = {};
    const stateObj = state;

    Object.keys(stateObj).forEach(i => {
      const currentState = stateObj[i];
      if (currentState.toJS) {
        newState[i] = currentState.toJS();
      } else {
        newState[i] = currentState;
      }
    });

    return newState;
  },
});

export default function configureStore(initialState, history, graphqlClient) {
  const middleware = [
    thunk,
    sagaMiddleware,
    modalInterceptor(),
    routerInterceptor(),
    routerMiddleware(history),
    graphqlLogoutReset(graphqlClient),
  ];

  if (getLogging() > 1) {
    middleware.push(logger);
  }

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options
  });

  /* eslint-disable */
  const store = createStore(
    rootReducer(),
    initialState,
    compose(
      applyMiddleware(...middleware),
      sentryReduxEnhancer,
      typeof window === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f,
    ),
  );
  /* eslint-enable */

  store.runSaga = sagaMiddleware.run;
  store.asyncReducers = {};
  store.asyncSagas = {};

  const { injectReducer, injectSagas } = getAsyncInjectors(store, rootReducer);
  store.injectReducer = injectReducer;
  store.injectSagas = injectSagas;

  sagaMiddleware.run(sagas);

  return store;
}
