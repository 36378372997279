import log from 'loglevel';
import { graphql } from 'react-apollo';
import { client as apolloClient } from '../../config/apollo';
import { QUERY_GET_USER_FEATURES, QUERY_IS_FEATURE_ACTION_ALLOWED_FOR_USER } from '../queries/feature';

// we remove this temporary flag FEATURE_MARKETPLACE_USER_HOST , which was added to distinguish between performance pay and marketplace
// in future if we want to enable such distinction we can use this flag
// export const FEATURE_MARKETPLACE_USER_HOST = 'marketplace_user_host';

/**
 * Check whether any of the supplied featureNames are enabled for the user
 * @param {*} featureNames
 * @returns {boolean}
 *
 */
export const isAnyFeatureEnabledForUser = async featureNames =>
  graphql(QUERY_GET_USER_FEATURES, {
    props: ({ data }) => {
      const enabled = data
        .filter(feature => feature.enabled)
        .reduce((acc, current) => acc || featureNames.includes(current.name), false);
      return enabled;
    },
  });

export const getUserFeatures = async () => {
  try {
    const userFeatures = await apolloClient.query({
      query: QUERY_GET_USER_FEATURES,
    });
    return userFeatures.data.getUserFeatures;
  } catch (error) {
    log.warn('[ERROR] cannot get user features', error);
    error.apiError = true;
    return error;
  }
};

export const isFeatureActionAllowedForUser = async (featureName, actionName, propertyId) => {
  try {
    const isFeatureActionAllowedResponse = await apolloClient.query({
      query: QUERY_IS_FEATURE_ACTION_ALLOWED_FOR_USER,
      variables: {
        propertyId,
        featureName,
        actionName,
      },
    });
    return isFeatureActionAllowedResponse.data.isFeatureActionAllowedForUser;
  } catch (error) {
    log.error(error);
    error.apiError = true;
    return error;
  }
};

export const isFeatureActionAllowedForUserAsProps = async (featureName, actionName, propertyId) => {
  graphql(QUERY_IS_FEATURE_ACTION_ALLOWED_FOR_USER, {
    options: () => ({
      variables: {
        propertyId,
        featureName,
        actionName,
      },
    }),
    props: ({ data }) => ({ [`${featureName}__${actionName}`]: data.isFeatureActionAllowedForUser }),
  });
};
