import { push } from 'react-router-redux';
import contains from 'ramda/src/contains';
import log from 'loglevel';
import * as types from '../../../../types/index';
import { ROUTES } from '../../../../paths';

// TODO: - TECHNICAL DEBT - will change in a refactoring next sprint
// TODO: This will depend on the refactor of the job details modal so we can specify this fn as a prop
// TODO: This will depend on the refactor of the job details modal so we can specify this fn as a prop
// TODO: This will depend on the refactor of the job details modal so we can specify this fn as a prop
// TODO: This will depend on the refactor of the job details modal so we can specify this fn as a prop
// TODO: This will depend on the refactor of the job details modal so we can specify this fn as a prop
const isFromCalendar = () => {
  const documentUrl = document.location.href;
  return contains('calendar', documentUrl);
};
const isFromContacts = () => {
  const documentUrl = document.location.href;
  return contains('contacts', documentUrl);
};
const isFromTodos = () => {
  const documentUrl = document.location.href;
  return contains('todos', documentUrl);
};
const isFromReports = () => {
  const documentUrl = document.location.href;
  return contains('reports', documentUrl);
};
const getReportId = () => {
  const documentUrl = document.location.href;
  const id = documentUrl.split('/')[5];

  return (isFromReports() && !contains('?_k', id) && id) || '';
};
const isFromContactsAccepted = () => {
  const documentUrl = document.location.href;
  return isFromContacts() && contains('/accepted', documentUrl);
};
const isFromContactsAll = () => {
  const documentUrl = document.location.href;
  return isFromContacts() && contains('/all', documentUrl);
};
const getContactId = () => {
  const documentUrl = document.location.href;
  return (isFromContacts() && documentUrl.split('/')[5]) || '';
};

function getBookingDetailsLink(propertyId, bookingId) {
  if (isFromCalendar()) {
    return ROUTES.bookingPageFromCalendar(propertyId, bookingId);
  }
  if (isFromReports()) {
    return ROUTES.bookingPageFromReports(getReportId(), propertyId, bookingId);
  }
  if (isFromTodos()) {
    return ROUTES.bookingPageFromTodos(propertyId, bookingId);
  }
  if (isFromContactsAccepted()) {
    return ROUTES.bookingPageFromContacts(getContactId(), 'accepted', propertyId, bookingId);
  }
  if (isFromContactsAll()) {
    return ROUTES.bookingPageFromContacts(getContactId(), 'all', propertyId, bookingId);
  }

  return ROUTES.bookingPageFromProperty(propertyId, bookingId);
}

export function goToContact(contactId) {
  return push(ROUTES.contactPage(contactId));
}

export function showBookingDetails(propertyId, bookingId) {
  const link = getBookingDetailsLink(propertyId, bookingId);
  log.info('showBookingDetails link', propertyId, bookingId, link);

  return push(link);
}

export function openJobRequestDetails(jobRequestId, isCleanerRequest, backwardCompatibilityJobReqId) {
  return {
    type: types.JOB_DETAILS_OPEN_SAGA,
    jobRequestId,
    isCleanerRequest,
    backwardCompatibilityJobReqId,
  };
}

export function closeJobRequestDetails() {
  return {
    type: types.JOB_DETAILS_CLOSE_SAGA,
  };
}

export function costSaga(noDelay, meta) {
  return {
    type: types.JOB_DETAILS_COST_SAGA,
    noDelay,
    meta,
  };
}

export function costCloseSaga() {
  return {
    type: types.JOB_DETAILS_COST_CLOSE_SAGA,
  };
}

export function addCostSaga(meta) {
  return {
    type: types.JOB_DETAILS_ADD_COST_SAGA,
    meta,
  };
}

export function addCostPicturesSaga(meta) {
  return {
    type: types.JOB_DETAILS_ADD_COST_PICTURE_SAGA,
    meta,
  };
}

export function editSaga(noDelay, meta) {
  return {
    type: types.JOB_DETAILS_EDIT_SAGA,
    noDelay,
    meta,
  };
}

export function editCloseSaga() {
  return {
    type: types.JOB_DETAILS_EDIT_CLOSE_SAGA,
  };
}

export function editContinueSaga() {
  return {
    type: types.JOB_DETAILS_EDIT_CONTINUE_SAGA,
  };
}

export function moreCleanersSaga(noDelay) {
  return {
    type: types.JOB_DETAILS_MORE_CLEANER_SAGA,
    noDelay,
  };
}

export function cancelJobRequestSaga(jobRequestId) {
  return {
    type: types.JOB_DETAILS_CANCEL_SAGA,
    jobRequestId,
  };
}

export function setJobRequestDetails(changeSet) {
  return {
    type: types.JOB_DETAILS_SET,
    changeSet,
  };
}

export function scheduleProblemsJob() {
  return {
    type: types.JOB_DETAILS_SCHEDULE_PROBLEMS_SAGA,
  };
}

export function setSourceJobProblems(data) {
  return {
    type: types.JOB_DETAILS_SET_SOURCE_JOB_PROBLEMS,
    data,
  };
}

export function setResolvedProblemResponse(data) {
  return {
    type: types.JOB_DETAILS_SET_RESOLVED_PROBLEM,
    data,
  };
}

export function setStripePaymentDetails(data) {
  return {
    type: types.JOB_DETAILS_SET_STRIPE_PAYMENT_DETAILS,
    data,
  };
}

export function setTempMilesImages(data) {
  return {
    type: types.JOB_DETAILS_SET_TEMP_MILES_IMAGES,
    data,
  };
}

export function setTempDurationImages(data) {
  return {
    type: types.JOB_DETAILS_SET_TEMP_DURATION_IMAGES,
    data,
  };
}

export function setTempCostImages(data) {
  return {
    type: types.JOB_DETAILS_SET_TEMP_COST_IMAGES,
    data,
  };
}

export function setCostType(data) {
  return {
    type: types.JOB_DETAILS_SET_COST_TYPE,
    data,
  };
}

export function setChecklistsVerificationPhotos(data) {
  return {
    type: types.JOB_DETAILS_SET_CHECKLISTS_VERIFICATION_PHOTOS,
    data,
  };
}

export function openSourceJob(sourceJobSnapshotId) {
  return {
    type: types.JOB_DETAILS_OPEN_SOURCE_JOB,
    sourceJobSnapshotId,
  };
}
export function getComments(payload) {
  return {
    type: types.GET_COMMENTS,
    payload,
  };
}
export function setComments(payload) {
  return {
    type: types.SET_COMMENTS,
    payload,
  };
}
export function setCommentsLoader(payload) {
  return {
    type: types.SET_COMMENT_LOADER,
    payload,
  };
}
export function addComments(payload) {
  return {
    type: types.ADD_COMMENTS,
    payload,
  };
}

export function handleClickSaga(event, meta) {
  return {
    type: types.JOB_DETAILS_HANDLECLICK,
    event,
    meta,
  };
}
