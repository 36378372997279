import StripePaymentMethodCard from '../../model/stripePaymentMethodCard';
import StripePaymentMethodBankAccount from '../../model/stripePaymentMethodBankAccount';
import StripeCustomer from '../../model/stripeCustomer';
import StripeSetupIntent from '../../model/stripeSetupIntent';

export function StripePaymentMethodCardModelMapper(stripePaymentMethodCard) {
  return new StripePaymentMethodCard(
    stripePaymentMethodCard.id,
    stripePaymentMethodCard.card.brand,
    stripePaymentMethodCard.card.country,
    stripePaymentMethodCard.card.exp_month,
    stripePaymentMethodCard.card.exp_year,
    stripePaymentMethodCard.card.funding,
    stripePaymentMethodCard.card.last4,
  );
}

export function StripePaymentMethodBankAccountModelMapper(stripePaymentMethodBankAccount) {
  return new StripePaymentMethodBankAccount(
    stripePaymentMethodBankAccount.id,
    stripePaymentMethodBankAccount.country,
    stripePaymentMethodBankAccount.currency,
    stripePaymentMethodBankAccount.routing_number,
    stripePaymentMethodBankAccount.account_holder_name,
    stripePaymentMethodBankAccount.account_holder_type,
    stripePaymentMethodBankAccount.bank_name,
    stripePaymentMethodBankAccount.last4,
  );
}

export function stripeCustomerModelMapper(stripeCustomer) {
  const paymentMethodCards = () =>
    stripeCustomer.cards ? stripeCustomer.cards.map(card => new StripePaymentMethodCardModelMapper(card)) : null;

  const paymentMethodBankAccounts = () =>
    stripeCustomer.bankAccounts
      ? stripeCustomer.bankAccounts.map(bankAccount => new StripePaymentMethodBankAccountModelMapper(bankAccount))
      : null;

  return new StripeCustomer(
    stripeCustomer.id,
    stripeCustomer.description,
    stripeCustomer.email,
    stripeCustomer.defaultSource,
    stripeCustomer.defaultPaymentMethod,
    paymentMethodCards(),
    paymentMethodBankAccounts(),
  );
}

export function stripeSetupIntentModelMapper(stripeSetupIntent) {
  return new StripeSetupIntent(stripeSetupIntent.id, stripeSetupIntent.clientSecret);
}
