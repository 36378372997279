import { fromJS } from 'immutable';
import extend from 'lodash/extend';
import * as types from '../../../../types/index';

const initState = fromJS({
  isPaywallModalOpen: false,

  openSection: {
    // key: { meta }
  },
  jobRequestData: {
    propertyId: undefined,
    proMarketProperty: undefined,
    checklistId: [],
    skills: [],
    startTime: undefined,
    endTime: undefined,
    currency: 'USD',
    price: 0,
    cleaners: {},
    message: undefined,
    isDefault: false,
    time: '600',
    duration: '60',
    jobStartTimeType: 'fixed',
    jobType: '',
    tasks: [],
    tempTaskImages: [],
    taskTitle: '',
    taskDescription: '',
  },
  checklistViewType: 'properties',
  communityCleaners: [],
  hostStripeStatus: false,
  startupData: {
    // prevJobRequestId: '',
    // data: {}
    // mode: normal|sendmore|edit
  },
});

export default function JobRequestReducer(state = initState, action = {}) {
  switch (action.type) {
    case types.GLOBAL_LOGOUT: // on logout reset
      return initState;

    case types.JOB_REQUEST_RESET:
      return initState;

    case types.JOB_REQUEST_SET_OPEN_SECTION:
      return state.setIn(
        // just allow one open section currently
        ['openSection'],
        fromJS(
          action.key
            ? {
                [action.key]: extend({}, action.meta, { id: action.key }) || {
                  id: action.key,
                },
              }
            : {},
        ),
      );

    case types.JOB_REQUEST_SELECT_CHECKLIST_TYPE:
      return state.set('checklistViewType', action.checklistViewType);

    case types.JOB_REQUEST_SET_DATA:
      return state.setIn(['jobRequestData'], fromJS(action.data));

    case types.JOB_REQUEST_UNSET_CLEANERS:
      return state.setIn(['jobRequestData', 'cleaners'], fromJS(action.cleaners));

    case types.JOB_REQUEST_SET_COMMUNITY:
      return state.setIn(['communityCleaners'], fromJS(action.data));

    case types.JOB_REQUEST_SET_STARTUPDATA:
      return state.setIn(
        // just allow one open section currently
        ['startupData'],
        fromJS(action.data),
      );

    case types.JOB_REQUEST_OPEN_PAYWALL_MODAL:
      return state.set('isPaywallModalOpen', true).set('seenPaywallModal', true);

    case types.JOB_REQUEST_CLOSE_PAYWALL_MODAL:
      return state.set('isPaywallModalOpen', false);

    case types.JOB_REQUEST_SET_HOST_STRIPE_STATUS:
      return state.set('hostStripeStatus', action.status);

    default:
      return state;
  }
}
