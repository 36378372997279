import { delay } from 'redux-saga';
import log from 'loglevel';
import { replace } from 'react-router-redux';
import extend from 'lodash/extend';
import indexOf from 'lodash/indexOf';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import { put, fork, select, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { shutdownIntercom } from '@properly/common';
import {
  error as errorCode,
  getConfigSync,
  connectedAccountsMobileRedList,
  featureFlag,
  connectedAccounts as ConnectedAccounts,
} from '@properly/config';
import * as types from '../../../../types/index';
import * as globalActions from '../../../../actions/globalActions';
import {
  getConnectedAccountRequest,
  getConnectedAccountSuccess,
  getConnectedAccountFailure,
  disconnectConnectedAccountSuccess,
  disconnectConnectedAccountFailure,
  syncConnectedAccountSuccess,
  syncConnectedAccountFailure,
  goToLogin,
  snapShotSetUser,
  setUpdateUserState,
  setPasswordStore,
  setState,
  goToAccountConntections,
  goToAccountConnectionsMobile,
  setWarningModal,
  accountImportSuccess,
  updateTmpUser,
} from './AccountActions';
import {
  getRealConnectedAccounts,
  createConnectedAccountsSubscriptionChannel,
  disconnectConnectedAccount,
  syncConnectedAccount,
} from './AccountApi';
import {
  updateParseUser,
  parseChangeUserPassword,
  sendPasswordLink,
  requestPhoneVerificationCode,
  redeemPhoneVerificationCode,
  disconnectPartner,
  parseLogoutUser,
  oAuthConnectBooking,
  oAuthConnectPropertyPhoto,
  oAuthConnectPropertyList,
  oAuthConnectProfile,
  uploadImage,
} from '../../data';
import { setHardRefreshTimeStamp } from '../../../../helper/hardRefresh';
import { openPricingModalSaga } from '../../pricing/PricingActions';
import { PAID_FEATURES, mediaUploadLoadingKey } from '../../../../dataConstants';
import { ROUTES } from '../../../../paths';
import {
  trackPartnerDisconnect,
  trackPartnerConnectError,
  trackPartnerConnectSuccess,
  trackPartnerConnectStart,
  trackPartnerImport,
} from '../../../../actions/trackingEvents';
import { refetchUser, canUserUseFeature } from '../../../../sagas/global';
import { getToken } from '../../../../config/apollo';
import { selectConnectedAccounts } from './AccountSelectors';
import { mobileExternalInterface } from '../../../../helper/mobileExternalInterface';
import { selectIsFeatureFlagEnabledForUser } from '../../settings/state/SettingsSelectors';

const config = getConfigSync();

const redirectToExternalBrowserForMobile = ({ partnerId, partner, onboardingMobile, reconnectId = false }) => {
  const { sendNotification, methodTypes } = mobileExternalInterface;
  const webappUrl = new RegExp('^//localhost').test(config.APP_WEB_LINK)
    ? String(config.APP_WEB_LINK).replace('//', 'http://')
    : String(config.APP_WEB_LINK).replace('//', 'https://');

  const queryParams = `?onboarding=${onboardingMobile}&redirectPartner=${partnerId}&connectDirect=true&reconnectId=${reconnectId}&redirectPartnerLabel=${partner}`;

  const queryParamsEncoded = window.btoa(queryParams);

  const redirectUrlGenerator = () =>
    `${webappUrl}/#/loginas/${getToken()}/account-mobile/connections-mobile?redirectParams=${queryParamsEncoded}`;

  sendNotification(methodTypes.OPEN_EXTERNAL_BROWSER, partner, redirectUrlGenerator());
};

let connectedAccountsSubscriptionChannel;

function* logout() {
  shutdownIntercom(); // kill intercom
  setHardRefreshTimeStamp();
  try {
    yield put(globalActions.setGlobalLoadingState('logout', 1));
    yield call(parseLogoutUser);
  } catch (e) {} // eslint-disable-line
  yield put(globalActions.globalLogout());
  yield put({
    type: types.LOGOUT_SUCCESS_USER,
  });
  const isLoginOverride = yield select(state => state.loginSignUpReducers.loginOverride);
  if (!isLoginOverride) {
    yield put(goToLogin());
  }
}

function* uploadProfilePicSaga(action) {
  try {
    yield put(setState('imageFile', action.image));
    const response = yield call(uploadImage, action.image);
    yield put(setState('imageFile', null));
    const pictureUrlObj = {
      pictureUrl: response?.pictureUrl,
    };
    yield put(updateTmpUser(pictureUrlObj));
    yield call(updateParseUser, pictureUrlObj);
  } catch (error) {
    log.error('uploadProfilePicSaga - ', error);
    yield put(setState('imageFile', null));
    yield put(globalActions.setGlobalLoadingState(mediaUploadLoadingKey, 3));
  }
}

function* snapshot() {
  yield refetchUser(); // refetch before snapshot
  const currentUser = yield select(state => state.currentUser);
  log.info('snapshot user', currentUser);
  yield put(snapShotSetUser(currentUser.user));
}

function* updateUser(action) {
  try {
    yield put(setUpdateUserState(1));
    const userJSON = extend({}, omit(action.user, ['notificationSettings']));
    if (!userJSON.phoneRegionalNumber) {
      userJSON.phoneCountryCode = '';
      userJSON.phoneRegionalNumber = '';
    }
    const clonedUserJSON = extend({}, userJSON);
    Object.keys(clonedUserJSON).forEach(key => {
      clonedUserJSON[key] = typeof clonedUserJSON[key] === 'string' ? clonedUserJSON[key]?.trim() : clonedUserJSON[key];
    });
    const res = yield call(updateParseUser, clonedUserJSON);
    yield fork(refetchUser);
    yield put(setUpdateUserState(0));
    log.info('updateUser', res);
    if (!res.phoneVerified && res.phoneRegionalNumber) {
      try {
        yield call(requestPhoneVerificationCode);
        yield put(setState('phoneVerify', 1, true));
      } catch (e) {} // eslint-disable-line
    }
  } catch (e) {
    log.error('updateUser', e);
    if (e.properlyErrorMsg === errorCode.PHONE_NOT_UNIQUE) {
      yield put(setUpdateUserState(2.1));
      return;
    }
    yield put(setUpdateUserState(2));
  }
}

function* changePw(action) {
  try {
    yield put(setState('updatePw', 1, true));
    try {
      yield call(parseChangeUserPassword, action.oldPassword, action.newPassword);
    } catch (e) {
      if (!(e && e.code && e.code === 107)) {
        // invalid response problem
        throw e;
      }
    }
    yield put(
      setPasswordStore({
        // reset
        oldPassword: '',
        newPassword1: '',
        newPassword2: '',
      }),
    );
    yield put(setState('updatePw', 3, true));
    yield delay(5000); // show message for 5 sec.
    yield put(setState('updatePw', 0, true));
  } catch (e) {
    log.error('changePw', e);
    yield put(setState('updatePw', 2, true));
  }
}

function* sendPw() {
  try {
    yield put(setState('sendPw', 2, true));
    yield call(sendPasswordLink);
    yield put(setState('sendPw', 4, true));
    yield delay(5000); // show message for 5 sec.
    yield put(setState('sendPw', 0, true));
  } catch (e) {
    log.error('sendPw', e);
    yield put(setState('sendPw', 3, true));
  }
}

function* phoneVerifySaga(action) {
  try {
    yield put(setState('phoneVerify', 2, true));
    yield call(redeemPhoneVerificationCode, action.code);
    yield put(setState('phoneVerify', 0, true));
  } catch (e) {
    log.error('phoneVerify', e);
    yield put(setState('phoneVerify', 3, true));
  }
}

function* syncProperties(action) {
  try {
    yield put(action.updateHandler({ step: 'getproperties' }));
    const res2 = yield call(oAuthConnectPropertyList, action.partnerId, action.userId);
    trackPartnerImport('propertyList');
    log.info('oauth - 2', res2);
    let i = 0;
    const array = res2.slice(); // clone
    yield put(action.updateHandler({ step: 'syncproperty', stat: [0, array.length] }));
    while (i < array.length) {
      const item = array[i];
      yield call(oAuthConnectPropertyPhoto, item.id);
      trackPartnerImport('propertyPhoto');
      i += 1;
      yield put(
        action.updateHandler({
          step: 'syncproperty',
          stat: [i + 0, array.length],
        }),
      );
      log.info('oauth - loop', i);
    }
  } catch (e) {
    log.error('syncStuff - properties', e);
    yield put(action.updateHandler({ error: 3 }));
    trackPartnerConnectError(); // error
    return;
  }
  try {
    yield put(action.updateHandler({ step: 'booking' }));
    yield call(oAuthConnectBooking);
    trackPartnerImport('booking');
  } catch (e) {
    log.error('syncStuff - booking', e);
    yield put(action.updateHandler({ error: 4 }));
    trackPartnerConnectError(); // error
    return;
  }
  yield put(action.updateHandler({ done: true }));
  yield delay(1000);
  yield put(accountImportSuccess());
  trackPartnerConnectSuccess(); // success
}

function* oauth(action) {
  if (!action.partnerId || !action.code) {
    // oauth fail
    yield put(action.updateHandler({ error: 1 }));
    trackPartnerConnectError();
    return;
  }
  try {
    trackPartnerConnectStart(action.partnerId); // important
    yield put(action.updateHandler({ step: 'connect' }));
    // const currentUser = yield select((state) => state.currentUser);
    const res = yield call(oAuthConnectProfile, action.partnerId, action.code);
    trackPartnerImport('profile');

    // Update connected accounts
    yield put(getConnectedAccountRequest());

    log.info('oauth', res);
    const newAction = cloneDeep(action);
    newAction.userId = res.userId;
    yield syncProperties(newAction);
  } catch (e) {
    if (e.properlyErrorMsg === 'CanNotConnectPartnerUser') {
      yield put(action.updateHandler({ error: 5 }));
    } else {
      yield put(action.updateHandler({ error: 2 }));
    }
    log.info('oauth - error', e);
    trackPartnerConnectError(); // error
  }
}

function* closeAndBack() {
  yield put(setState('oauth', {}));
  yield put(replace(ROUTES.settingsConnections));
  yield put(globalActions.refetchUserSaga(true));
}

function* redirectToProviderSaga(action) {
  const {
    oAuthLink,
    partnerTitle,
    connectedAccount,
    loginWarningBlacklist = [],
    mobile,
    onboardingMobile,
    connectionSettingId,
  } = action.data;

  if (!connectedAccount) {
    log.error('AccountSagas redirectToProviderSaga - no connectedAccount');
    return;
  }
  if (connectedAccount.proRequired) {
    const res = yield canUserUseFeature(PAID_FEATURES.pms);
    if (res === 0) return; // just do nothing
    if (res === 2) {
      log.info('AccountSagas redirectToProviderSaga - Upgrade to pro');
      yield put(
        openPricingModalSaga('default', {
          defaultKind: 'upgradeplan',
        }),
      );
      return;
    }
  }

  if (mobile && connectedAccountsMobileRedList.includes(connectedAccount.id) && mobileExternalInterface.doesExist()) {
    log.info('[AccountSagas.redirectToProviderSaga] - redirecting to external browser');
    redirectToExternalBrowserForMobile({
      partnerId: connectedAccount.id,
      partner: connectedAccount.label,
      onboardingMobile,
      reconnectId: connectionSettingId,
    });
    return;
  }

  const isFirstConnect = connectedAccount?.connectionSettings?.length === 0;
  const isBlackListed = (partnerId, blacklist) => indexOf(blacklist, partnerId) !== -1;
  // TODO: remove guesty when migration is complete
  if (
    isFirstConnect ||
    isBlackListed(connectedAccount.id, loginWarningBlacklist) ||
    connectedAccount.id === ConnectedAccounts.GUESTY
  ) {
    // if not connected accounts red-listed redirect to provider
    log.info('AccountSagas redirectToProviderSaga - Is first connect');
    window.location.href = oAuthLink;
    return;
  }

  yield put(
    setWarningModal({
      isOpen: true,
      meta: {
        url: oAuthLink,
        modal: 1,
        partnerTitle,
      },
    }),
  );
}

function* redirectToProviderLegacySaga(action) {
  const { goal, partnerTitle, connectedAccount, loginWarningBlacklist = [] } = action.data;

  if (!connectedAccount) {
    log.error('AccountSagas redirectToProviderLegacySaga - no parnterdata');
    return;
  }

  if (connectedAccount.proRequired) {
    const res = yield canUserUseFeature(PAID_FEATURES.pms);
    if (res === 0) return; // just do nothing
    if (res === 2) {
      log.info('AccountSagas redirectToProviderLegacySaga - Upgrade to pro');
      yield put(
        openPricingModalSaga(
          'default',
          {
            defaultKind: 'upgradeplan',
          },
          'PMS',
        ),
      );
      return;
    }
  }
  const isFirstConnect = connectedAccount?.connections?.length === 0;
  const isBlackListed = (partnerId, blacklist) => indexOf(blacklist, partnerId) !== -1;

  if (isFirstConnect || isBlackListed(connectedAccount.id, loginWarningBlacklist)) {
    log.info('AccountSagas redirectToProviderLegacySaga - Is first connect');
    window.location.href = goal;
    return;
  }

  yield put(
    setWarningModal({
      isOpen: true,
      meta: {
        url: goal,
        modal: 1,
        partnerTitle,
      },
    }),
  );
}

function* connectDirectToPartnerSaga({ redirectPartnerId, reconnectId }) {
  log.info(`Redirecting to partner ${redirectPartnerId}`);
  trackPartnerConnectStart(redirectPartnerId);

  const connectedAccounts = yield select(selectConnectedAccounts);
  let partnerConnectedOauthLink = connectedAccounts.find(connectedAccount => connectedAccount.id === redirectPartnerId)
    ?.oAuthLink;

  if (reconnectId && reconnectId !== 'false') {
    // if reconnectId is present then we use this oauth link which will be used to reconnect
    partnerConnectedOauthLink = `${partnerConnectedOauthLink}&redirectPath=/account-mobile/connections-mobile/${redirectPartnerId}/${reconnectId}&connectionSettingsId=${reconnectId}`;
  }
  if (partnerConnectedOauthLink) {
    window.location.href = partnerConnectedOauthLink;
  }
}

function* redirectToMobileSaga({
  status,
  externalMobileBrowserConnected,
  onboardingMobile,
  connectionSuccess,
  delayedRedirect,
}) {
  if (delayedRedirect) {
    yield delay(3000);
  }

  if (externalMobileBrowserConnected) {
    const params = [
      'isNavReady=true',
      'role=host',
      `partnerConnected=${connectionSuccess.toString()}`,
      `onboarding=${onboardingMobile.toString()}`,
    ];
    let url = `${config.PROPERLY_DEEP_LINK_IOS_ANDROID_WEB}?${params.join('&')}`;

    if (mobileExternalInterface.isIOS()) {
      url = `${config.PROPERLY_DEEP_LINK_IOS_FORCE_OPEN_APP}?${params.join('&')}`;
    }

    window.location.href = url;
    return;
  }

  if (onboardingMobile && mobileExternalInterface.doesExist()) {
    mobileExternalInterface.sendNotification(status);
  }
}

function* subscriptionConnectedAccountsSaga(payload) {
  try {
    log.info('[subscriptionConnectedAccountsSaga] payload', payload);
    const { connectedAccounts, connectionSettingsCount } = payload;

    // subscription query errors may result in us not receiving any connected accounts
    if (connectedAccounts) {
      yield put(getConnectedAccountSuccess(connectedAccounts, connectionSettingsCount));
    }
  } catch (e) {
    log.error('[subscriptionConnectedAccountsSaga] saving connected accounts failed', e);
  }
}

function* leaveAccountConnectionsSaga() {
  if (connectedAccountsSubscriptionChannel) {
    yield connectedAccountsSubscriptionChannel.close();
  }
}

function* getConnectedAccountsSaga() {
  try {
    const { connectedAccounts, connectionSettingsCount } = yield getRealConnectedAccounts();
    log.info('getConnectedAccountsRequest connectedAccounts', {
      connectedAccounts,
      connectionSettingsCount,
    });

    yield put(getConnectedAccountSuccess(connectedAccounts, connectionSettingsCount));

    const isLiveConnectedAccountsFeatureFlagEnabled = yield select(
      selectIsFeatureFlagEnabledForUser(featureFlag.FEATURE_FLAG_LIVE_CONNECTED_ACCOUNTS),
    );

    if (isLiveConnectedAccountsFeatureFlagEnabled) {
      // set up our channel to listen for connected account changes via our GraphQL subscription
      const params = {};
      connectedAccountsSubscriptionChannel = yield call(createConnectedAccountsSubscriptionChannel, params);
      yield takeEvery(connectedAccountsSubscriptionChannel, subscriptionConnectedAccountsSaga);
    }
  } catch (error) {
    yield put(getConnectedAccountFailure(error));
  }
  yield delay(2000); // debounce the saga call
}

function* disconnectConnectedAccountSaga({ id }) {
  try {
    const disconnectedAccount = yield disconnectConnectedAccount(id);
    log.info('disconnectConnectedAccountsRequest disconnectedAccount', disconnectedAccount);

    yield put(disconnectConnectedAccountSuccess(id, disconnectedAccount));
    // Redirect back to account connections
    yield put(replace(ROUTES.settingsConnections));
  } catch (error) {
    yield put(disconnectConnectedAccountFailure(id, error));
  }
}

function* syncConnectedAccountSaga({ id, partner }) {
  try {
    const syncAccount = yield syncConnectedAccount(id, partner);
    log.info('syncConnectedAccountsRequest syncAccount', syncAccount);

    yield put(syncConnectedAccountSuccess(id, syncAccount));
  } catch (error) {
    yield put(syncConnectedAccountFailure(id, error));
  }
}

function* disconnect(action) {
  try {
    yield put(setState('disconnect', 1, true));

    const res = yield call(disconnectPartner, action.partner, action.partnerUserId);
    log.info('disconnect - res', res);

    yield put(globalActions.updateCurrentUser(res));

    yield put(setState('disconnect', 0, true));
    if (action.mobile) {
      yield put(goToAccountConnectionsMobile());
    } else {
      yield put(goToAccountConntections());
    }

    trackPartnerDisconnect();
  } catch (e) {
    log.error('disconnect', e);
    yield put(setState('disconnect', 2, true));
  }
}

function* saga() {
  yield fork(takeLatest, types.ACCOUNT_GET_CONNECTED_ACCOUNT_REQUEST, getConnectedAccountsSaga);
  yield fork(takeEvery, types.ACCOUNT_LEAVE_ACCOUNT_CONNECTIONS, leaveAccountConnectionsSaga);
  yield fork(takeEvery, types.ACCOUNT_DISCONNECT_CONNECTED_ACCOUNT_REQUEST, disconnectConnectedAccountSaga);
  yield fork(takeEvery, types.ACCOUNT_SYNC_CONNECTED_ACCOUNT_REQUEST, syncConnectedAccountSaga);
  yield fork(takeEvery, types.ACCOUNT_LOGOUT, logout);
  yield fork(takeEvery, types.ACCOUNT_UPDATE_USER_SAGA, updateUser);
  yield fork(takeEvery, types.ACCOUNT_CHANGE_PW_SAGA, changePw);
  yield fork(takeEvery, types.ACCOUNT_SEND_PW_SAGA, sendPw);
  yield fork(takeEvery, types.ACCOUNT_OAUTH_SAGA, oauth);
  yield fork(takeEvery, types.ACCOUNT_PHONE_VERIFY_SAGA, phoneVerifySaga);
  yield fork(takeEvery, types.ACCOUNT_SNAP_USER, snapshot);
  yield fork(takeEvery, types.ACCOUNT_REIMPORT, syncProperties);
  yield fork(takeEvery, types.ACCOUNT_CLOSE_MODAL_BACK_SAGA, closeAndBack);
  yield fork(takeEvery, types.ACCOUNT_DISCONNECT, disconnect);
  yield fork(takeEvery, types.ACCOUNT_REDIRECT_PROVIDER_SAGA, redirectToProviderSaga);
  yield fork(takeEvery, types.ACCOUNT_CONNECT_DIRECT_TO_PARTNER_SAGA, connectDirectToPartnerSaga);
  yield fork(takeLatest, types.ACCOUNT_REDIRECT_TO_MOBILE_SAGA, redirectToMobileSaga);
  yield fork(takeEvery, types.ACCOUNT_REDIRECT_PROVIDER_LEGACY_SAGA, redirectToProviderLegacySaga);
  yield fork(takeEvery, types.ACCOUNT_UPLOAD_PROFILE_PIC, uploadProfilePicSaga);
}

export default saga;
