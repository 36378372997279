import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const Sidebar = ({ children, isAbsolute }) => (
  <div
    className={cx('sidebar', {
      'sidebar--absolute': isAbsolute,
    })}
  >
    {children}
  </div>
);

Sidebar.List = ({ children, style }) => (
  <div className={cx('sidebar__list')} style={style}>
    {children}
  </div>
);

Sidebar.ListInner = ({ children, id }) => (
  <div id={id} className={cx('sidebar__list-inner')}>
    {children}
  </div>
);
Sidebar.ListInnerInner = ({ children, id, killOverflow, enableOverflow, flushRight }) => (
  <div
    id={id}
    className={cx('sidebar__list-inner-inner', {
      'sidebar__list-inner-inner--killoverflow': killOverflow,
      'sidebar__list-inner-inner--enableoverflow': enableOverflow,
      'sidebar__list-inner-inner--flushright': flushRight,
    })}
  >
    {children}
  </div>
);

Sidebar.NoRes = ({ children }) => <div className={cx('sidebar__no-result')}>{children}</div>;

Sidebar.Hr = () => <hr className={cx('sidebar__hr')} />;

Sidebar.propTypes = {
  children: PropTypes.node,
};

export default Sidebar;
