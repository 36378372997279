"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.find");

require("core-js/modules/es.array.slice");

require("core-js/modules/es.function.name");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initSentry = initSentry;

var Sentry = _interopRequireWildcard(require("@sentry/react"));

var _tracing = require("@sentry/tracing");

var _localization = require("@properly/localization");

var _loglevel = _interopRequireDefault(require("loglevel"));

var _config = require("./config.api");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

// eslint-disable-next-line import/prefer-default-export
function initSentry(app) {
  var _arguments = arguments;

  if (typeof Sentry !== 'undefined') {
    // Log errors to sentry
    (0, _config.getConfig)().then(function (_ref) {
      var _build_ = _ref._build_,
          _versions_ = _ref._versions_,
          _ref$app$toUpperCase = _ref[app.toUpperCase()],
          SENTRY_CONFIG = _ref$app$toUpperCase.SENTRY_CONFIG,
          SENTRY_FORCE_IN_DEVELOPMENT = _ref$app$toUpperCase.SENTRY_FORCE_IN_DEVELOPMENT;

      var currentVersion = _versions_.find(function (_ref2) {
        var name = _ref2.name;
        return name === "@properly/".concat(app);
      }) || {};

      _loglevel["default"].info('Enabling sentry with config', SENTRY_CONFIG, _build_.buildGitHash, _build_.buildGitCommit, currentVersion.version);

      var debug = process.env.NODE_ENV === 'development'; // At times we may want to capture errors in local development to sentry.id.
      // Setting SENTRY_FORCE_IN_DEVELOPMENT to true in the config matching the
      // backend being used will ensure errors from http://localhost:300X get relayed
      // to sentry.io.

      var getProperlyUrlRegex;

      if (SENTRY_FORCE_IN_DEVELOPMENT) {
        getProperlyUrlRegex = /(https?:\/\/)?((cdn|www)\.)?(localhost:3000|localhost:3001|localhost:3002|((app|app-staging|beta|app-beta|app-sandbox|app-develop|develop)\.)?getproperly\.com)/;
      } else {
        getProperlyUrlRegex = /(https?:\/\/)?((cdn|www)\.)?((app|app-staging|beta|app-beta|app-sandbox|app-develop|develop)\.)?getproperly\.com/;
      }

      Sentry.init({
        dsn: SENTRY_CONFIG,
        beforeSend: function beforeSend(event, hint) {
          // Ignore Google reCaptcha timeouts:
          // https://github.com/getsentry/sentry-javascript/issues/2514#issuecomment-603971338
          if (hint.originalException === 'Timeout') {
            return null;
          }

          return event;
        },
        debug: debug,
        release: currentVersion.version,
        allowUrls: [getProperlyUrlRegex],
        ignoreErrors: getIgnoreErrors(),
        denyUrls: getIgnoreUrls(SENTRY_FORCE_IN_DEVELOPMENT),
        tracesSampleRate: 0.25,
        integrations: [new _tracing.Integrations.BrowserTracing()]
      });
      Sentry.configureScope(function (scope) {
        scope.setTag('git_commit', _build_.buildGitHash);
        scope.setTag('versionTag', currentVersion.version);
        scope.setTag('language', (0, _localization.detectLanguage)());
      });
      var originalFactory = _loglevel["default"].methodFactory;

      _loglevel["default"].methodFactory = function (methodName, logLevel, loggerName) {
        var rawMethod = originalFactory(methodName, logLevel, loggerName);
        return function () {
          rawMethod.apply(null, Array.prototype.slice.call(_arguments, 0)); // eslint-disable-line

          if (methodName === 'error') {
            for (var _len = arguments.length, rest = new Array(_len), _key = 0; _key < _len; _key++) {
              rest[_key] = arguments[_key];
            }

            var errorString = rest[0] ? rest[0] : 'zero';
            if (rest[0] && rest[1] && rest[1].stack) errorString = "".concat(rest[0], " - ").concat(rest[1].stack);
            Sentry.captureMessage(errorString);
          }
        };
      };

      if (SENTRY_FORCE_IN_DEVELOPMENT) {
        Sentry.captureMessage('Sentry forced in development mode via SENTRY_FORCE_IN_DEVELOPMENT');
      }

      return true;
    })["catch"](function (err) {
      _loglevel["default"].error('Error initialising Sentry', err);

      throw err;
    });
  } else {
    _loglevel["default"].info('Sentry is not defined via import');
  }

  return true;
}

function getIgnoreUrls(SENTRY_FORCE_IN_DEVELOPMENT) {
  return [/js\.intercomcdn\.com/gi, /\/frame*\.[\d]*\.js/gm, // Facebook flakiness
  /graph\.facebook\.com/i, // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i, // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i, /static\.woopra\.com\/js\/woopra\.js/i, // Chrome extensions
  /extensions\//i, /^chrome:\/\//i, // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i, /metrics\.itunes\.apple\.com\.edgesuite\.net\//i].concat(SENTRY_FORCE_IN_DEVELOPMENT ? [] : [/localhost:3000\//i, // Localhost
  /localhost:3001\//i, // Localhost
  /localhost:3002\//i // Localhost
  ]);
}

function getIgnoreErrors() {
  return [// Random plugins/extensions
  'top.GLOBALS', // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification', 'canvas.contentDocument', 'MyApp_RemoveAllHighlights', 'http://tt.epicplay.com', "Can't find variable: ZiteReader", 'jigsaw is not defined', 'ComboSearch is not defined', 'http://loading.retry.widdit.com/', 'atomicFindClose', // Facebook borked
  'fb_xd_fragment', // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  'bmi_SafeAddOnload', 'EBCallBackMessageReceived', // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage' // Generic error code from errors outside the security sandbox
  ];
}