import React, { Component } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import memoize from 'lodash/memoize';
import withSizes from 'react-sizes';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Carousel, Icon } from '@properly/components';
import { getConfigSync } from '@properly/config';
import classNames from 'classnames/bind';
import styles from './index.module.css';
import * as selectorsGlobal from '../../selectors/globalSelector';
import { selectAdminMode } from '../../modules/desktopApp/settings/state/SettingsSelectors';
import { setSliderSaga } from '../../actions/globalActions';
import ThumbsUpBar from '../ThumbsUpBar';
import HasPermission from '../../hoc/HasPermission';
import { selectJobDetailsJS } from '../../modules/desktopApp/jobDetails/state/JobDetailsSelectors';

const config = getConfigSync();

const cx = classNames.bind(styles);

const MUIComponentStyles = {
  switchTrack: {
    backgroundColor: '#fff',
  },
  switchLabel: {
    color: '#fff',
  },
};

class ImageSlider extends Component {
  static propTypes = {
    setSliderSagaProps: PropTypes.func,
    isThumbsUpDownSlider: PropTypes.bool,
    isOpen: PropTypes.bool,
    images: PropTypes.arrayOf(PropTypes.shape({})),
    propertyId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    jobRequestId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    startingIndex: PropTypes.number,
    componentSize: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  };

  static defaultProps = {
    setSliderSagaProps: () => {},
    isThumbsUpDownSlider: false,
    isOpen: false,
    images: [],
    propertyId: '',
    jobRequestId: '',
    componentSize: {
      width: '1440',
      height: 'auto',
    },
    startingIndex: 0,
  };

  constructor(props) {
    super(props);
    this.replaceDomainSlashWithProtocol = String(config.APP_DASHBOARD_LINK).replace(
      '//',
      new RegExp('^//localhost').test(config.APP_DASHBOARD_LINK) ? 'http://' : 'https://',
    );
    this.state = {
      requestNextImage: false,
      requestPreviousImage: false,
      index: 0,
      downloadImage: false,
      rotation: 0,
      magnifyEnabled: false,
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    const { dashboardAdminMode } = this.props;
    if (dashboardAdminMode) {
      window.parent.postMessage(
        {
          action: 'getMagnifyImageEnabled',
          key: 'magnifyImageEnabled',
        },
        this.replaceDomainSlashWithProtocol,
      );
      window.addEventListener('message', this.messageHandler);
    }
  };

  messageHandler = event => {
    const { action, value } = event.data;
    if (action === 'returnData') {
      this.setState({ magnifyEnabled: value });
    }
  };

  componentWillMount = () => {
    const { dashboardAdminMode } = this.props;
    if (dashboardAdminMode) {
      window.addEventListener('keydown', this.handleKeyDown);
    }
  };

  componentWillReceiveProps(nextProps) {
    const { startingIndex } = this.props;
    if (nextProps.startingIndex !== startingIndex) {
      this.setState({ index: nextProps.startingIndex });
    }
  }

  componentWillUnmount = () => {
    const { dashboardAdminMode } = this.props;
    if (dashboardAdminMode) {
      window.removeEventListener('keydown', this.handleKeyDown);
      window.removeEventListener('message', this.messageHandler);
    }
  };

  close = () => {
    const { setSliderSagaProps } = this.props;

    // Reset the state
    this.setState({ index: 0, rotation: 0 });

    setSliderSagaProps(false, []);
  };

  downloadImageHandler = () => {
    this.setState(prevState => ({
      downloadImage: !prevState.downloadImage,
    }));
  };

  rotate = clockwise => {
    const { rotation } = this.state;
    let newRotation = clockwise ? rotation + 90 : rotation - 90;
    if (newRotation >= 360) {
      newRotation -= 360;
    }
    this.setState({
      rotation: newRotation,
    });
  };

  handleKeyDown = event => {
    if (event.altKey || event.ctrlKey) {
      switch (event.code) {
        case 'ArrowRight':
          this.setState({ requestNextImage: true });
          break;
        case 'ArrowLeft':
          this.setState({ requestPreviousImage: true });
          break;
        case 'KeyQ':
          this.close();
          break;
        default:
      }
    }
    return null;
  };

  handleOnIndexChange = activeStep => {
    const { images } = this.props;
    this.setState({ index: activeStep });
    if (images.length > 1) {
      this.setState({ rotation: 0 });
    }
  };

  handleMagnifyImageSwitchChange = () => {
    const { magnifyEnabled } = this.state;
    this.setState({ magnifyEnabled: !magnifyEnabled });
    window.parent.postMessage(
      {
        action: 'saveMagnifyImageEnabled',
        key: 'magnifyImageEnabled',
        value: !magnifyEnabled,
      },
      this.replaceDomainSlashWithProtocol,
    );
  };

  resetRequest = () => {
    this.setState({
      requestPreviousImage: false,
      requestNextImage: false,
    });
  };

  renderMain(hasThumbsBar, bar, images) {
    const {
      componentSize,
      startingIndex,
      propertyTitle,
      jobTitle,
      scheduledStartTime,
      jobRequestId,
      dashboardAdminMode,
      classes,
    } = this.props;
    const { requestNextImage, requestPreviousImage, downloadImage, rotation, magnifyEnabled } = this.state;

    return (
      <div className={cx('image-slider')}>
        <div className={cx('image-slider__inner')}>
          <div className={cx('image-slider__closebar-flex')}>
            <Icon.IcCloseSlider width="24px" byWidth onClick={this.close} />
            <div
              className={cx('image-slider__options', {
                'image-slider__options--no-download': !dashboardAdminMode && !jobRequestId,
                'image-slider__options--dashboard': dashboardAdminMode,
                'image-slider__options--dashboard--no-download': dashboardAdminMode && !jobRequestId,
              })}
            >
              {dashboardAdminMode ? (
                <FormControlLabel
                  classes={{ label: classes.switchLabel }}
                  control={
                    <Switch
                      checked={magnifyEnabled || false}
                      color="secondary"
                      classes={{ track: classes.switchTrack }}
                      onChange={this.handleMagnifyImageSwitchChange}
                    />
                  }
                  label="Magnify Image"
                />
              ) : null}
              <Icon.IcRotateLeft width="36px" byWidth onClick={() => this.rotate(false)} />
              <Icon.IcRotateRight width="36px" byWidth onClick={() => this.rotate(true)} />
              {jobRequestId && <Icon.IcDownloadImage width="36px" byWidth onClick={this.downloadImageHandler} />}
            </div>
          </div>
          <div className={cx('image-slider__slider')}>
            <Carousel
              images={images}
              onIndexChange={this.handleOnIndexChange}
              width={componentSize.width}
              height={componentSize.height}
              startingIndex={startingIndex}
              requestNextImage={requestNextImage}
              requestPreviousImage={requestPreviousImage}
              onRequestHandled={this.resetRequest}
              downloadImageHandler={this.downloadImageHandler}
              downloadImage={downloadImage}
              propertyTitle={propertyTitle}
              jobTitle={jobTitle}
              scheduledStartTime={scheduledStartTime}
              rotation={rotation}
              magnify={dashboardAdminMode && magnifyEnabled}
            />
          </div>
          <div>{hasThumbsBar && bar}</div>
        </div>
      </div>
    );
  }

  renderBar(canEditThumbsUpComments) {
    const { images, jobRequestId, componentSize } = this.props;
    const { index } = this.state;
    return (
      <div className={cx('image-slider__bar')} style={{ maxWidth: '1440px', width: componentSize.width }}>
        <ThumbsUpBar
          imageId={images[index].image}
          oldJobRequestId={jobRequestId}
          hasEditPermissions={canEditThumbsUpComments}
        />
      </div>
    );
  }

  render() {
    const { isThumbsUpDownSlider, isOpen, images, propertyId, jobRequestId } = this.props;

    if (!isOpen) return null;
    if (!isThumbsUpDownSlider) {
      return this.renderMain(false, null, images);
    }
    return (
      <HasPermission
        meta={{
          propertyId,
          jobRequestId,
        }}
        hasAccessFormatter={hasAccessRes => hasAccessRes}
        render={({ canViewThumbsUpComments, canEditThumbsUpComments }) => {
          if (!canViewThumbsUpComments) {
            return this.renderMain(false, null, images);
          }
          return this.renderMain(true, this.renderBar(canEditThumbsUpComments), images);
        }}
      />
    );
  }
}

function normalize(images) {
  return (images && images.toJS && images.toJS()) || images;
}

const memoizeImages = memoize(normalize);

function mapStateToProps(state, props) {
  const sliderState = selectorsGlobal.selectSlider()(state, props);
  const { selectedJobRequestId } = selectJobDetailsJS()(state, props);
  const jobDetails = selectorsGlobal.selectJobRequest(selectedJobRequestId)(state, props);

  const { propertyData: { title: propertyTitle } = {}, jobData: { title: jobTitle = 'Job' } = {}, scheduledStartTime } =
    jobDetails || {};

  const dashboardAdminMode = selectAdminMode(state, props);

  return {
    isOpen: sliderState.get('isOpen'),
    startingIndex: sliderState.get('index'),
    images: memoizeImages(sliderState.get('images')),
    isThumbsUpDownSlider: sliderState.getIn(['meta', 'isThumbsUpDownSlider'], false),
    jobRequestId: sliderState.getIn(['meta', 'jobRequestId'], false),
    propertyId: sliderState.getIn(['meta', 'propertyId'], false),
    dashboardAdminMode,
    propertyTitle,
    jobTitle,
    scheduledStartTime,
  };
}

const mapSizesToProps = ({ width, height }) => ({
  componentSize: {
    width,
    height,
  },
});

export default compose(
  withSizes(mapSizesToProps),
  connect(mapStateToProps, { setSliderSagaProps: setSliderSaga }),
  withStyles(MUIComponentStyles),
)(ImageSlider);
