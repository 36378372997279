import { graphql } from 'react-apollo';
import log from 'loglevel';
import { QUERY_IS_FEATURE_FLAG_ENABLED_FOR_USER, QUERY_GET_FEATURE_FLAGS_FOR_USER } from '../queries/featureFlagConfig';
import { client as apolloClient } from '../../config/apollo';

export const isFeatureFlagEnabledForUser = featureFlagName =>
  graphql(QUERY_IS_FEATURE_FLAG_ENABLED_FOR_USER, {
    options: () => ({
      variables: {
        featureName: featureFlagName,
      },
    }),
    props: ({ data }) => ({ isFeatureFlagEnabledForUser: data.isFeatureFlagEnabledForUser }),
  });

/**
 * Check whether any of the supplied featureFlagNames are enabled for the user
 * @param {*} featureFlagNames
 * @returns {boolean}
 *
 */
export const isAnyFeatureFlagEnabledForUser = featureFlagNames =>
  graphql(QUERY_GET_FEATURE_FLAGS_FOR_USER, {
    props: ({ data }) => {
      const enabled = data?.getFeatureFlagsForUser
        ?.filter(featureFlag => featureFlag.enabled)
        .reduce((acc, current) => acc || featureFlagNames.includes(current.name), false);
      return { isAnyFeatureFlagEnabledForUser: enabled };
    },
  });

export const getAllUserFeatureFlags = async () => {
  try {
    const userFeatureFlags = await apolloClient.query({
      query: QUERY_GET_FEATURE_FLAGS_FOR_USER,
      fetchPolicy: 'cache-first', // reduce the api calls with cache first policy
    });
    return userFeatureFlags.data.getFeatureFlagsForUser;
  } catch (error) {
    log.warn('[ERROR] cannot get user feature flags', error);
    error.apiError = true;
    return error;
  }
};
