import React, { PureComponent } from 'react';
import t from '@properly/localization';
import { connect } from 'react-redux';
import { Modal, SpaceTiny, Button, Icon } from '@properly/components';
import { openIntercomWithMsg } from '@properly/common';
import classNames from 'classnames/bind';
import styles from './pricingStyles.module.css';
import HeadBar from '../../../components/HeadBar';
import PricingPlansContainer from './PricingPlansContainer';
import * as selectors from './PricingSelectors';
import { closeModalSaga, openPricingModalSaga } from './PricingActions';
import * as selectorsGlobal from '../../../selectors/globalSelector';
import Sidebar from '../../../components/Sidebar';

const cx = classNames.bind(styles);

class PricingModals extends PureComponent {
  constructor(propsd) {
    super(propsd);
    this.messages = {
      upgradeplan: {
        line1: () => t('pricing.modalmsgs.upgrade_to_pro'),
        line2: () => {},
        upgrade: this.handleUpgrade,
      },
      jobrequestlimitalreadyreached: {
        line1: () => t('pricing.modalmsgs.job_already_reached'),
        line2: (meta, props) => {
          const subScriptionData = props.currentPlan.userData.subscription;
          return (
            <div>
              {t('pricing.modalmsgs.job_already_reached_l2', { num: subScriptionData.allowedJobsMonth })}
              <span style={{ fontWeight: 'bold' }}>
                {subScriptionData.currentPlanTitle} {t('pricing.modalmsgs.job_reached_l3')}
              </span>
              {t('pricing.modalmsgs.job_reached_l4')}
            </div>
          );
        },
        upgrade: this.handleUpgrade,
      },
      jobrequestlimitreached: {
        line1: (meta, props) => {
          const subScriptionData = props.currentPlan.userData.subscription;
          return t('pricing.modalmsgs.job_x_of_y', {
            x: subScriptionData.jobsUsedMonth,
            y: subScriptionData.allowedJobsMonth,
          });
        },
        line2: (meta, props) => {
          const subScriptionData = props.currentPlan.userData.subscription;
          return (
            <div>
              {t('pricing.modalmsgs.job_reached_l2', { num: subScriptionData.allowedJobsMonth })}
              <span style={{ fontWeight: 'bold' }}>
                {subScriptionData.currentPlanTitle} {t('pricing.modalmsgs.job_reached_l3')}
              </span>
              {t('pricing.modalmsgs.job_reached_l4')}
            </div>
          );
        },
        upgrade: this.handleUpgrade,
      },
      yousend: {
        line1: (meta, props) => {
          const subScriptionData = props.currentPlan.userData.subscription;
          return (
            <div>
              {t('pricing.modalmsgs.job_x_of_y', {
                x: subScriptionData.jobsUsedMonth,
                y: subScriptionData.allowedJobsMonth,
              })}
            </div>
          );
        },
        line2: (meta, props) => {
          const subScriptionData = props.currentPlan.userData.subscription;
          const diff = subScriptionData.allowedJobsMonth - subScriptionData.jobsUsedMonth;
          return (
            <div>
              {t('pricing.modalmsgs.job_x_of_y_l2', { num: diff })}
              <span style={{ fontWeight: 'bold' }}>
                {subScriptionData.currentPlanTitle} {t('pricing.modalmsgs.job_x_of_y_l3')}
              </span>
              {t('pricing.modalmsgs.job_x_of_y_l4')}
            </div>
          );
        },
        upgrade: this.handleUpgrade,
      },
    };
    this.dummy = {
      line1: () => {},
      line2: () => {},
      upgrade: () => {},
    };
  }

  default(show, meta, props) {
    const msgObj = this.messages[meta.defaultKind] || this.dummy;

    return (
      <Modal type="pricing" show={show} onClose={() => this.props.closeModalSaga('default')}>
        <div>
          <Modal.H1 center>{msgObj.line1(meta, props)}</Modal.H1>
          <Modal.Text center>{msgObj.line2(meta, props)}</Modal.Text>
          <Modal.Options center>
            {props.currentUser.isStandalone && (
              <Button types={['type-full-primary', 'size-medium']} onClick={msgObj.upgrade}>
                {t('pricing.upgrade')}
              </Button>
            )}
            {!props.currentUser.isStandalone && (
              <Modal.Text center>{t('permissions.ask_your_admin_for_help')}</Modal.Text>
            )}
          </Modal.Options>
        </div>
      </Modal>
    );
  }

  handleContact = () => {
    this.props.closeModalSaga('contact');
    openIntercomWithMsg(t('pricing.cancel_msg_intercom'));
  };

  handleUpgrade = () => {
    this.props.closeModalSaga('default');
    this.props.openPricingModalSaga('upgrade', {});
  };

  contactSupport(show) {
    const close = () => this.props.closeModalSaga('contact');
    return (
      <Modal show={show} onClose={close} height={200}>
        <div>
          <Modal.H1 center>{t('pricing.modalmsgs.cancel_sub')}</Modal.H1>
          <Modal.Text center>{t('pricing.modalmsgs.cancel_sub_l2')}</Modal.Text>
          <Modal.Options>
            <Modal.Cancel onClick={close}>{t('pricing.modalmsgs.cancel_sub_close')}</Modal.Cancel>
            <Modal.Ok onClick={this.handleContact}>{t('pricing.modalmsgs.cancel_sub_contact')}</Modal.Ok>
          </Modal.Options>
        </div>
      </Modal>
    );
  }

  pricingPlans(show) {
    const close = () => this.props.closeModalSaga('upgrade');
    return (
      <Modal width={950} height={800} show={show} onClose={close} block>
        <div>
          <HeadBar onClose={close} middleText={t('pricing.pricing_plans')} />
          <Sidebar.List style={{ height: 700 }}>
            <Sidebar.ListInner>
              <Sidebar.ListInnerInner>
                <PricingPlansContainer isInModal closeModal={close} />
              </Sidebar.ListInnerInner>
            </Sidebar.ListInner>
          </Sidebar.List>
        </div>
      </Modal>
    );
  }

  thankYou(show) {
    return (
      <Modal show={show} height={190} onClose={() => this.props.closeModalSaga('thankyou')}>
        <div>
          <div className={cx('pricing__thankyouhead')}>{t('pricing.thank_you')}</div>
          <SpaceTiny />
          <div className={cx('pricing__thankyoutxt')}>{t('pricing.account_upgraded')}</div>
          <Modal.Options center>
            <Icon.Bigcheck />
          </Modal.Options>
        </div>
      </Modal>
    );
  }

  get modalJS() {
    return this.props.modal?.toJS();
  }

  render() {
    if (!this.props.currentUser) return null;
    return (
      <div>
        {this.thankYou(this.modalJS.kind === 'thankyou')}
        {this.pricingPlans(this.modalJS.kind === 'upgrade')}
        {this.contactSupport(this.modalJS.kind === 'contact')}
        {this.default(this.modalJS.kind === 'default' && !!this.props.currentPlan, this.modalJS.meta, this.props)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    modal: selectors.selectPricingModal()(state),
    currentPlan: selectorsGlobal.selectUserPlanData(state),
    currentUser: selectorsGlobal.selectCurrentUser()(state),
  };
}

export default connect(mapStateToProps, {
  closeModalSaga,
  openPricingModalSaga,
})(PricingModals);
