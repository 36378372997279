import React, { Component } from 'react';
import log from 'loglevel';
import head from 'ramda/src/head';
import store from 'store';
import { Modal, Regular, XLarge, Input, Button, SpaceXLarge, HoverTriggeredTooltip } from '@properly/components';
import { getConfig, setOverrideEnvironment, resetOverrideEnvironment, getOverrideEnvironment } from '@properly/config';
import PonySvg from './pony.svg';
import SheepSvg from './sheep.svg';
import './index.module.css';

window.__enableEnvironment = () => store.set('properlyenvironmentenabled', true); // eslint-disable-line
export const getDebugMode = () => store.get('properlydebugmode') || false;

export default class Environment extends Component {
  state = { env: null, active: false };

  componentWillMount() {
    getConfig()
      .then(config =>
        this.setState({
          config,
          env: config && config.env && config.env.env,
          envName: config && config.ENVIRONMENT,
        }),
      )
      .then(() =>
        setTimeout(() => {
          log.info('Is production', this.isProduction());
        }),
      );
  }

  isProduction() {
    return (
      (this.state.env || 'production').toLowerCase().indexOf('prod') !== -1 ||
      (this.state.envName || 'production').toLowerCase().indexOf('prod') !== -1
    );
  }

  getDomainEnvironment() {
    const { config } = this.state;
    const subDomainEnv = head((config && config.env && config.env.subDomainEnv) || []) || {};
    return subDomainEnv.env;
  }

  isDomainEnvironment() {
    return this.state.env === this.getDomainEnvironment();
  }

  isDevelopmentEnabled() {
    return store.get('properlyenvironmentenabled');
  }

  isEnvironmentEnabled() {
    return !this.isProduction() || this.isDevelopmentEnabled();
  }

  getEnvironmentOptions() {
    const config = this.state.config || {};
    const environments = (config.env && config.env.all) || [];
    return environments.map(currentEnv => ({ value: currentEnv.env, title: currentEnv.env }));
  }

  setEnvironment(env) {
    setOverrideEnvironment(env);
    window.location.reload();
  }

  resetEnvironment = () => {
    resetOverrideEnvironment();
    window.location.reload();
  };

  getVersion() {
    try {
      const config = this.state.config || {};
      const versions = config._versions_ || []; // eslint-disable-line
      return versions.find(version => version && version.name === '@properly/desktop') || {};
    } catch (err) {
      log.error('Error trying to get version of application', err);
      return '???';
    }
  }

  getBuild() {
    const config = this.state.config || {};
    return config._build_ || {}; // eslint-disable-line
  }

  handleClick = e => {
    // Activate on a quadruple click
    const minCount = this.isDevelopmentEnabled() ? 1 : 4;
    if (e.detail >= minCount) {
      this.setState({ active: true });
    }
  };

  closeModal = () => {
    this.setState({ active: false });
  };

  setLogging(level) {
    store.set('properlylogging', Number.isNaN(Number(level)) ? 0 : Number(level));
  }

  getLogging() {
    return store.get('properlylogging') || 0;
  }

  setDebugMode(isActive) {
    store.set('properlydebugmode', !!isActive);
  }

  handleDebugToggle = () => {
    this.setDebugMode(!getDebugMode());
    window.location.reload();
  };

  isLoggingActive() {
    const loggingLevel = Number(this.getLogging() || 0);
    return !Number.isNaN(loggingLevel) && loggingLevel !== 0;
  }

  handleLoggingToggle(isActive, level = 1) {
    this.setLogging(isActive ? 0 : level);
    window.location.reload();
  }

  renderHeader() {
    const isLoggingActive = this.isLoggingActive();
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1 }}>
          {this.isDomainEnvironment() ? (
            <div>
              <XLarge>
                Environment: <strong>{this.state.envName}</strong>
              </XLarge>
              <br />
            </div>
          ) : (
            <div>
              <XLarge>
                Active Environment: <strong>{this.state.envName}</strong>
              </XLarge>
              <br />
              <XLarge>
                Actual Environment: <strong>{this.getDomainEnvironment()}</strong>
              </XLarge>
              <br />
            </div>
          )}
          <XLarge>
            Version: <strong>{this.getVersion().version}</strong>
          </XLarge>
          <br />
          <br />
          <XLarge>
            Last built: <strong>{this.getBuild().buildTimeFromNow}</strong>
          </XLarge>
          <br />
          <XLarge>
            Build date: <strong>{this.getBuild().buildTimestamp}</strong>
          </XLarge>
          <br />
          <br />
        </div>

        <div style={{ width: '220px' }}>
          <Button
            type="button"
            types={['size-large', 'size-full', isLoggingActive ? 'type-border-primary' : 'type-full-primary']}
            onDoubleClick={() => this.setLogging(2)}
            onClick={() => this.handleLoggingToggle(isLoggingActive)}
          >
            {isLoggingActive ? 'Disable Logging' : 'Enable Logging'}
          </Button>
          {!isLoggingActive && (
            <>
              <br />
              <Button
                type="button"
                types={['size-large', 'size-full', isLoggingActive ? 'type-border-primary' : 'type-full-primary']}
                onClick={() => this.handleLoggingToggle(isLoggingActive, 2)}
              >
                Enable redux logging
              </Button>
            </>
          )}
          <>
            <br />
            <Button
              type="button"
              types={['size-large', 'size-full', getDebugMode() ? 'type-border-primary' : 'type-full-primary']}
              onClick={this.handleDebugToggle}
            >
              {getDebugMode() ? 'Disable debug mode' : 'Enable debug mode'}
            </Button>
          </>

          <br />
          {getOverrideEnvironment() && (
            <>
              <Button
                type="button"
                types={['size-large', 'size-full', 'type-full-primary']}
                onClick={this.resetEnvironment}
              >
                Reset environment
              </Button>
              <br />
            </>
          )}

          <Regular>Set environment</Regular>
          <Input
            onChange={value => this.setEnvironment(value.target.value)}
            value={this.state.env}
            options={this.getEnvironmentOptions()}
            type="select"
          />
        </div>
      </div>
    );
  }

  renderConfig() {
    return (
      <div>
        <br />
        <XLarge>Config:</XLarge>
        <pre>{JSON.stringify(this.state.config, null, 2)}</pre>
      </div>
    );
  }

  render() {
    const isDomainEnvironment = this.isDomainEnvironment();

    const style = {
      position: 'absolute',
      fontWeight: 700,
      top: '56px',
      fontSize: '12px',
      color: isDomainEnvironment ? '#FF5722' : '#7E57C2',
      width: '64px',
      textAlign: 'center',
      overflow: 'hidden',
      cursor: 'default',
      zIndex: 1000,
    };

    return this.isEnvironmentEnabled() ? (
      <div className="environment-container">
        <HoverTriggeredTooltip
          title={this.getVersion().version}
          placement="right"
          onClick={this.handleClick}
          style={style}
        >
          {this.state.envName}
        </HoverTriggeredTooltip>

        <Modal
          height={600}
          width={800}
          show={this.state.active}
          block
          variableContentHeight
          onClose={this.closeModal}
          actionsRight={[{ label: 'Close', action: () => this.closeModal() }]}
        >
          <div style={{ padding: '24px' }}>
            {this.renderHeader()}
            {this.renderConfig()}
            <SpaceXLarge />
            <PonySvg />
            <SheepSvg />
            <SpaceXLarge />
          </div>
        </Modal>
      </div>
    ) : null;
  }
}
