import { put, fork, take, cancel, call, takeLatest } from 'redux-saga/effects';
import { reloadPage } from '@properly/common';
import log from 'loglevel';
import moment from 'moment-timezone';
import * as globalActions from '../../actions/globalActions';
import * as types from '../../types';
import { getHardRefreshTimeStamp, setHardRefreshTimeStamp } from '../../helper/hardRefresh';
import { guardedFork } from '../../helper/saga';
import { handleEventChannelEvent } from '../../sagas/global';
import { setDisableOnBoardingVideo } from '../../graphql/api/bookingComPilot';

function* putNewUserInStore(user) {
  yield put(globalActions.updateCurrentUser(user));
}

function* newUserSubscriptionInStore(subscription) {
  yield put(globalActions.updateCurrentUserSubscription(subscription));
}

function* runUserChangeChannel({ id, subscriptionId }) {
  try {
    const myFork = yield fork(handleEventChannelEvent, {
      className: '_User',
      listenOnArray: ['update'],
      queryModifierFunc: query => {
        query.equalTo('objectId', id);
        query.include('subscription');
        return query;
      },
      handleEventGenerator: putNewUserInStore,
    });
    const myForkSub = yield fork(handleEventChannelEvent, {
      className: 'Subscription',
      listenOnArray: ['update'],
      queryModifierFunc: query => {
        query.equalTo('objectId', subscriptionId);
        return query;
      },
      handleEventGenerator: newUserSubscriptionInStore,
    });
    yield take([types.STOP_LISTEN_USER, types.GLOBAL_LOGOUT]);
    yield cancel(myFork);
    yield cancel(myForkSub);
  } catch (e) {
    log.error('runUserChangeChannel', e);
  }
}

// Checks if the page needs to be refreshed based on the users maybe changed hard refresh time
// This is set by the backend on the user object in some scenarios and forces an update
function* pageRefreshSaga({ user }) {
  const timestampOfLastRefresh = parseInt(getHardRefreshTimeStamp(), 10);
  if (timestampOfLastRefresh) {
    if (timestampOfLastRefresh < user.lastHardRefreshTimeStamp) {
      setHardRefreshTimeStamp(user.lastHardRefreshTimeStamp);
      setTimeout(() => reloadPage(), 10000);
    }
  } else {
    setHardRefreshTimeStamp(user.lastHardRefreshTimeStamp || moment().unix());
  }
  yield null;
}

function* disableShowOnBoardingVideo({ status }) {
  try {
    const res = yield call(setDisableOnBoardingVideo, status);
    log.debug('disableShowOnBoardingVideo -> res : ', res);
  } catch (error) {
    log.error(`disableShowOnBoardingVideo error : ${error}`);
  }
}

export default function* saga() {
  yield guardedFork(takeLatest, types.START_LISTEN_USER, runUserChangeChannel);
  yield guardedFork(takeLatest, types.USER_UPDATE_CURRENT_USER, pageRefreshSaga);
  yield fork(takeLatest, types.DISABLE_SHOW_ONBOARDING_VIDEO, disableShowOnBoardingVideo);
}
