import * as types from '../../types';

export function createComment(data) {
  return {
    type: types.THUMBS_BAR_CREATE_COMMENT,
    data,
  };
}

export function createThumbs(data) {
  return {
    type: types.THUMBS_BAR_CREATE_THUMBS,
    data,
  };
}

export function deleteComment(photofeedback) {
  return {
    type: types.THUMBS_BAR_DELETE_COMMENT,
    photofeedback,
  };
}

export function deleteThumbs(photofeedback) {
  return {
    type: types.THUMBS_BAR_DELETE_THUMBS,
    photofeedback,
  };
}

export function updateComment(photofeedback) {
  return {
    type: types.THUMBS_BAR_UPDATE_COMMENT,
    photofeedback,
  };
}

export function updateThumbs(photofeedback) {
  return {
    type: types.THUMBS_BAR_UPDATE_THUMBS,
    photofeedback,
  };
}
