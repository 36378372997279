import { delay } from 'redux-saga';
import { put, fork, select, call, takeEvery } from 'redux-saga/effects';
import map from 'lodash/map';
import pick from 'lodash/pick';
import groupBy from 'lodash/groupBy';
import filter from 'lodash/filter';
import each from 'lodash/each';
import noop from 'lodash/noop';
import values from 'lodash/values';
import mapValues from 'lodash/mapValues';
import PromiseProps from 'promise-props';
import log from 'loglevel';
import { push } from 'react-router-redux';
import * as selectorsGlobal from '../../selectors/globalSelector';
import * as globalReducer from '../../reducers/globalReducer';
import * as globalActions from '../../actions/globalActions';
import * as types from '../../types';
import lunrIndex from './lunr';
import { approveRedirect } from '../../helper/herbert';
import { guardedFork } from '../../helper/saga';
import { getParseContact, getParseProperty } from './data';
import { ROUTES } from '../../paths';
import { RELOAD_STATUS } from '../../dataConstants';
// GraphQL API
import { getSpecificJobrequest } from '../../graphql/api/jobList';
import { getConnectedAccountRequest } from './account/state/AccountActions';
import { getUserFeatureFlagsSaga, getUserFeaturesSaga } from './settings/state/SettingsSagas';

function* preload() {
  const user = yield select(state => state.currentUser);

  if (!user.isLoggedIn) {
    log.info('preload - killed - user logged out');
    return;
  }
  const state = yield select(selectorsGlobal.selectGlobalPersistent());

  log.info('preload - state', state);

  // make feature flags call blocking to get the features first and not use fork
  yield call(getUserFeatureFlagsSaga);
  yield call(getUserFeaturesSaga);

  yield put(getConnectedAccountRequest());

  const instructions = globalReducer.validateState(state?.toJS(), globalReducer.onloadManifesto);
  log.info('preload - instructions', instructions);
  const blacklist = [globalReducer.JOB_REQUEST_COLLECTION, 'jobs', 'trigger', 'reports', 'skills'];
  const promises = globalReducer.genPromises(instructions, globalReducer.onloadManifesto, blacklist);
  const groupedRunCommands = groupBy(instructions, 'run');

  const isFullLoad = groupedRunCommands.all !== undefined; // check if we are partial load or not
  log.info('preload - isFullLoad', isFullLoad);

  try {
    const res = yield PromiseProps(promises);
    log.info('preload - res', res);
    const mappedData = mapValues(res, (item, keyInner) =>
      globalReducer.mapPromiseDataToReducerFormat(item, globalReducer.onloadManifesto[keyInner].indexBy),
    );
    yield put(globalActions.setPreloadData(mappedData));
    yield put(globalActions.setPreloadStatus(RELOAD_STATUS.LOADED));
  } catch (e) {
    log.error('preload - throws', e);
    yield put(push(approveRedirect({ pathname: ROUTES.error })));
  }
}

// TODO: put together with hoc/pageload
function* loadAndSet(promises) {
  const res = yield PromiseProps(promises);
  const mappedData = mapValues(res, item => globalReducer.mapPromiseDataToReducerFormat(item, undefined));
  yield put(globalActions.setPreloadData(mappedData));
}

// TODO: put together with hoc/pageload
function* loadDataEntry(mode, key, instructions) {
  const picked = pick(instructions, key);
  const isLoaded = filter(picked, obj => obj.run === 'part').length === 1;
  const promises = globalReducer.genPromises(picked, globalReducer.onloadManifesto, []);
  if (mode === 'cacheFresh') {
    if (!isLoaded) {
      log.info('loadData - fetch and resolve');
      yield loadAndSet(promises); // block
    } else {
      log.info('loadData - resolve and fetch');
      yield fork(loadAndSet, promises); // in background
    }
  }
}

// TODO: put together with hoc/pageload
function* loadData({ pro, dataLoaders }) {
  const { resolve, reject } = pro || { resolve: noop, reject: noop };
  const state = yield select(selectorsGlobal.selectGlobalPersistent());
  const instructions = globalReducer.validateState(state?.toJS(), globalReducer.onloadManifesto);
  try {
    let i = 0;
    while (i < dataLoaders.length) {
      const dataLoaderEntry = dataLoaders[i];
      yield loadDataEntry(dataLoaderEntry.mode, dataLoaderEntry.key, instructions);
      i += 1;
    }
    resolve();
  } catch (e) {
    log.error('loadData', e);
    reject();
  }
}

function* startPreloadSagaWithDelay({ fromWhere }) {
  yield delay(10000);
  yield put(globalActions.startPreloadSaga(fromWhere));
}

function updateSearchIndexForCollection(key, data) {
  const resolvedManifestoData = globalReducer.onloadManifesto[key];
  if (resolvedManifestoData && resolvedManifestoData.searchIndexDef) {
    try {
      const mappedData = map(values(data), resolvedManifestoData.addItemToSearch);
      lunrIndex.setIndex(key, resolvedManifestoData.searchIndexDef(mappedData));
    } catch (err) {
      log.error('Error trying to update search index collection', { key, data });
    }
  }
}

function* updateIndex({ collectionName, ...rest }) {
  log.info('updateIndex', collectionName, rest);
  const state = yield select(selectorsGlobal.selectGlobalPersistent());
  const stateJS = state?.toJS();

  // update all
  each(stateJS, (item, key) => updateSearchIndexForCollection(key, item.data));
}

function* updateInBackgroundSaga(action) {
  const { collectionName, isPagination, id } = action;
  try {
    if (collectionName === 'cleanerRequest') {
      const res = yield call(getSpecificJobrequest, id);
      yield put(globalActions.updateCollectionEntryBatch(collectionName, { [res.jobId]: res }, isPagination));
    }
    if (collectionName === 'jobRequests') {
      const res = yield call(getSpecificJobrequest, id);
      yield put(globalActions.updateCollectionEntryBatch(collectionName, { [res.jobId]: res }, isPagination));
    }
    if (collectionName === 'contacts') {
      const res = yield getParseContact(id);
      yield put(globalActions.updateCollectionEntry(collectionName, id, res));
    }
    if (collectionName === 'properties') {
      const res = yield getParseProperty(id);
      yield put(globalActions.updateCollectionEntry(collectionName, id, res));
    }
  } catch (e) {
    log.error('updateInBackgroundSaga', e);
  }
}

export default function* saga() {
  yield guardedFork(takeEvery, types.START_PRELOAD_SAGA, preload);
  yield guardedFork(
    takeEvery,
    [types.GLOBAL_SET_PRELOAD_DATA, types.UPDATE_COLLECTION_ENTRY_BATCH, types.UPDATE_COLLECTION_ENTRY],
    updateIndex,
  );
  yield guardedFork(takeEvery, types.START_PRELOAD_SAGA_WITH_DELAY, startPreloadSagaWithDelay);

  yield guardedFork(takeEvery, types.GLOBAL_UPDATE_IN_BACKGROUND, updateInBackgroundSaga);

  // TODO: dataFetch - find better id
  // TODO: dont have a generic "PAGE_LOAD" action and load more granular data "PROPERTY_LOAD_PROPERTY_REQUEST"
  yield guardedFork(takeEvery, 'PAGE_LOAD', loadData);
}
