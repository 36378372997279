import React from 'react';
import { Route, Redirect } from 'react-router';
import { isMobileTouch, setWindowTitle } from '@properly/common';
import log from 'loglevel';
import StripeSPContainer from './modules/desktopApp/stripeSP/containers/StripeSPContainer';

const initialLocation = window.location.hash;
const initialLocationStripeMobile = String(initialLocation).indexOf('stripe-mobile/connect') !== -1;
const initialLocationStripeConnect = String(initialLocation).indexOf('stripe/connect') !== -1;

const recordMobileViewedKey = 'PROPERLY_recordMobileConnectionsViewed';
const recordMobileConnectionsViewed = () => {
  try {
    window.localStorage.setItem(recordMobileViewedKey, 'true');
  } catch (err) {
    log.error(err);
  }
};

const hasViewedMobileConnections = () => {
  try {
    return (
      !!window.localStorage.getItem(recordMobileViewedKey) ||
      initialLocationStripeMobile ||
      initialLocationStripeConnect
    );
  } catch (err) {
    log.error(err);
    return true;
  }
};
const isMobileAppIFrameLoad = hasViewedMobileConnections() && isMobileTouch();
log.info('isMobileAppIFrameLoad', isMobileAppIFrameLoad);
// used redirect to differentiate between mobile and web connections
// so that we can use same component
export default () => (
  <>
    {isMobileAppIFrameLoad && (
      <>
        <Redirect path="stripe/connect" to="/stripe-mobile/connect" />
        <Route
          exact
          path="stripe-mobile/connect"
          component={props => {
            recordMobileConnectionsViewed();
            return <StripeSPContainer mobile {...props} />;
          }}
          onEnter={setWindowTitle('titles.connected_accounts')}
        />
      </>
    )}
  </>
);
