import { push } from 'react-router-redux';
import * as types from '../../../../types/index';
import { ROUTES } from '../../../../paths';

export function goToContact(id, activeSection) {
  return push(ROUTES.contactPage(id, activeSection));
}

export function goToContacts() {
  return push(ROUTES.contacts);
}

export function goToContactAll(id) {
  return push(ROUTES.contactPageAll(id));
}

export function updateContactTags(contactId, tags) {
  return {
    type: types.CONTACTS_UPDATE_TAGS,
    contactId,
    tags,
  };
}

export function updateModalData(data) {
  return {
    type: types.CONTACTS_SET_CONTACT_MODAL_DATA,
    data,
  };
}

export function resetModalData() {
  return {
    type: types.CONTACTS_RESET_CONTACT_MODAL_DATA,
  };
}

export function updateModalState(data, meta) {
  return {
    type: types.CONTACTS_SET_CONTACT_MODAL,
    data,
    meta,
  };
}

export function resendInviteSaga(contactId) {
  return {
    type: types.CONTACTS_RESEND_INVITE_SAGA,
    contactId,
  };
}

export function freshInviteSaga(inviteData) {
  return {
    type: types.CONTACTS_FRESH_INVITE_SAGA,
    inviteData,
  };
}

export function closeModalSaga() {
  return {
    type: types.CONTACTS_CLOSE_MODAL_SAGA,
  };
}

export function addContactSaga() {
  return {
    type: types.CONTACTS_ADD_CONTACT_SAGA,
  };
}

export function resendInvite(raw, objectId) {
  return updateModalState(
    {
      view: 6,
      isOpen: true,
    },
    {
      objectId,
    },
  );
}

export function inviteCleaner() {
  return updateModalState({
    view: 1,
    isOpen: true,
  });
}

export function hideError() {
  return {
    type: types.CONTACTS_HIDE_ERROR,
  };
}

export function resetError() {
  return {
    type: types.CONTACTS_RESET_ERROR,
  };
}

export function deleteContact(contactId) {
  return {
    type: types.CONTACTS_DELETE_CONTACT,
    contactId,
  };
}

export function getCompletedSkill(contactId) {
  return {
    type: types.CONTACT_GET_COMPLETED_SKILL_SAGA,
    contactId,
  };
}

export function saveCompletedSkillsToStore(contactId, allCompletedSkillsIds) {
  return {
    type: types.CONTACT_SAVE_COMPLETED_SKILLS_TO_STORE,
    contactId,
    allCompletedSkillsIds,
  };
}
