import React from 'react';
import PropTypes from 'prop-types';
import { Input, Icon } from '@properly/components';
import t from '@properly/localization';
import classNames from 'classnames/bind';
import styles from './checklistsStyles.module.css';

const cx = classNames.bind(styles);

const ChecklistTopBar = ({ title, onDelete, onDuplicate, onBlur, isReadOnly }) => (
  <div className={cx('checklist__topbar')}>
    <div className={cx('checklist__topbar-section')}>
      <Input
        isFirst
        isLast
        noBox
        disabled={isReadOnly}
        defaultValue={title}
        style={{ backgroundColor: '#FFF', fontSize: '16px', lineHeight: '24px', padding: '0' }}
        type="text"
        onBlur={onBlur}
        maxLength={40}
        placeholder={t('checklist.enter_title')}
        data-key="checklist_step_title"
      />
    </div>
    <div className={cx('checklist__topbar-section', 'checklist__topbar-section--right')}>
      {!isReadOnly && (
        <div data-key="checklist_step_duplicate_btn" className={cx('checklist__topbar-icon')}>
          <Icon.IcDuplicate2 onClick={onDuplicate} />
        </div>
      )}
      {!isReadOnly && (
        <div data-key="checklist_step_delete_btn" className={cx('checklist__topbar-icon')}>
          <Icon.IcTrash2 onClick={onDelete} />
        </div>
      )}
    </div>
  </div>
);

ChecklistTopBar.propTypes = {
  title: PropTypes.string,
  onDelete: PropTypes.func,
  onBlur: PropTypes.func,
  onDuplicate: PropTypes.func,
};

export default ChecklistTopBar;
