import filter from 'lodash/filter';
import forOwn from 'lodash/forOwn';
import lodashResult from 'lodash/result';

export default class Team {
  constructor(objectId, name, description, pictureUrl, adminEmails) {
    this.objectId = objectId;
    this.name = name;
    this.description = description;
    this.pictureUrl = pictureUrl;
    this.adminEmails = adminEmails;
  }

  get email() {
    const res = filter(this.adminEmails, emailObj => !!emailObj.active);
    return lodashResult(res[0], ['email']);
  }

  setData(obj) {
    this.getOwnProps(obj, this);
  }

  getData() {
    return this.getOwnProps(this);
  }

  getOwnProps(scope, onScope) {
    const obj = onScope || {};
    forOwn(scope, (value, key) => {
      obj[key] = value;
    });
    return obj;
  }

  toJSON() {
    const obj = {};
    obj.email = this.email;
    forOwn(this, (value, key) => {
      obj[key] = value;
    });
    return obj;
  }
}
