import React, { PureComponent } from 'react';
import t from '@properly/localization';
import { connect } from 'react-redux';
import concat from 'lodash/concat';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import { JobRequest, Button, LoadingSplash, JobRequestIcons } from '@properly/components';
import { formatMinHours } from '@properly/common';
import classNames from 'classnames/bind';
import styles from './reportsStyles.module.css';
import { paginate } from './ReportsActions';
import * as selectors from './ReportsSelectors';
import { mapJobRequestToColor } from '../../../helper/herbert';
import { mapReportsTableHeads } from './ReportsMapper';
import { openJobRequestDetails } from '../jobDetails/state/JobDetailsActions';
import {
  COLUMN_WIDTHS,
  DEFAULT_COLUMN_WIDTH,
  ORDER_COLUMNS,
  FETCH_X_JOBREQUEST_PER_LOAD,
  CLICKABLE,
} from './ReportsConstants';
import PaymentStatusChip from '../../../components/PaymentStatusChip';

const cx = classNames.bind(styles);

class ReportsTableContainer extends PureComponent {
  getColumnWidth(key) {
    if (COLUMN_WIDTHS[key]) return COLUMN_WIDTHS[key];
    return DEFAULT_COLUMN_WIDTH;
  }

  renderHeader(columns) {
    return map(columns, key => {
      const width = this.getColumnWidth(key);
      return (
        <th
          key={`header-${key}`}
          data-key={`header-${key}`}
          style={{ width, maxWidth: width, minWidth: width }}
          className={cx('report__table-cell')}
        >
          {mapReportsTableHeads(key)}
        </th>
      );
    });
  }

  rowRenderer = () =>
    this.reportDataOfCurrentReport.get('data').map((item, key) => (
      <tr key={`row-${item && item.get('jobrequestid')}`} data-key={`row-${item && item.get('jobrequestid')}`}>
        {map(this.headerSections, key2 => {
          const width = this.getColumnWidth(key2);
          const currentItem = this.reportDataOfCurrentReport.getIn(['data', key, key2]);
          return (
            <td
              key={`column-${item && item.get('jobrequestid')}-${key2}`}
              data-key={`column-${item && item.get('jobrequestid')}-${key2}`}
              style={{ width, maxWidth: width, minWidth: width }}
              onClick={this.handleCellClick({
                headerKey: key2,
                jobSnapshotId: item.get('jobsnapshotid'),
                jobRequestId: item.get('jobrequestid'),
              })}
              className={cx('report__table-cell', {
                'report__table-cell--cursor': this.canClickIt(key2),
              })}
            >
              {this.renderItem(currentItem, `${key}${key2}`, key2)}
            </td>
          );
        })}
      </tr>
    ));

  canClickIt(key) {
    return !!CLICKABLE[key];
  }

  renderColorTile(data, isCleaner) {
    return (
      <div className={cx('reports__textstackwrap')}>
        {map(data, jobRequest => {
          const jobRequestStatus = jobRequest.subStatus;
          const jobRequestIcons = (
            <JobRequestIcons
              isInProgress={jobRequest.isInProgress}
              isFinished={jobRequest.isFinished}
              hasProblem={jobRequestStatus.hasProblem}
              isIncomplete={jobRequestStatus.isIncomplete}
            />
          );

          const icons = !isCleaner ? jobRequestIcons : null;
          return (
            <div className={cx('reports__colortile')} key={`colortile-${jobRequest.jobId}`}>
              <JobRequest
                statusString={t(jobRequest.getStatusString(isCleaner))}
                icons={icons}
                status={mapJobRequestToColor(jobRequest, isCleaner)}
              />
            </div>
          );
        })}
      </div>
    );
  }

  handleCellClick = clickObj => () => {
    if (this.canClickIt(clickObj.headerKey)) {
      this.props.openJobRequestDetails(clickObj.jobSnapshotId, false, clickObj.jobRequestId);
    }
  };

  renderTextStack(data, isFlex, keyPrefix) {
    return (
      <div
        className={cx('reports__textstackwrap', {
          'reports__textstackwrap--flex': isFlex,
        })}
      >
        {map(data, (i, key) => (
          <div key={`textstack-${keyPrefix}${key}`}>{i}</div>
        ))}
      </div>
    );
  }

  renderPaymentStatus(status) {
    const emptyStatus = '-';
    return (
      <span className={cx('reports__cellitem')}>
        {status === emptyStatus ? emptyStatus : <PaymentStatusChip paymentStatus={status} />}
      </span>
    );
  }

  renderItemBase(content, key) {
    return (
      <span
        className={cx('reports__cellitem', {
          'reports__cellitem--primary': this.canClickIt(key),
        })}
      >
        {content}
      </span>
    );
  }

  renderItem(item, keyPrefix, key) {
    if (item && item.get) {
      switch (item.get('type')) {
        case 'colortile':
          return this.renderColorTile(item.get('data').toArray(), false);
        case 'colortilecleaner':
          return this.renderColorTile(item.get('data').toArray(), true);
        case 'textstack':
          return this.renderTextStack(item.get('data').toArray(), false, keyPrefix);
        case 'textstackflex':
          return this.renderTextStack(item.get('data').toArray(), true, keyPrefix);
        case 'paymentstatus':
          return this.renderPaymentStatus(item.get('data'));
        case 'duration':
          return this.renderItemBase(formatMinHours(item.get('data')), key);
        default:
      }
    }

    return this.renderItemBase(item, key);
  }

  get reportDataOfCurrentReport() {
    return this.props.allReportData.get(this.props.loadedReport);
  }

  get headerSections() {
    const selected = this.props.columnSectionData.get('selected').toJS();
    return reduce(
      ORDER_COLUMNS,
      (total, val) => {
        if (selected[val]) {
          total = concat(total, val);
        }
        return total;
      },
      [],
    );
  }

  getWholeTableWidth(sections) {
    return reduce(sections, (total, key) => total + this.getColumnWidth(key), 0);
  }

  renderTable() {
    const width = this.getWholeTableWidth(this.headerSections);
    return (
      <div className={cx('tablewrap')}>
        <table style={{ width }} className={cx('table')} cellSpacing="0" cellPadding="0">
          <thead className={cx('tablehead')}>
            <tr>{this.renderHeader(this.headerSections)}</tr>
          </thead>
          <tbody className={cx('reports__talbe-body')}>{this.rowRenderer()}</tbody>
        </table>
      </div>
    );
  }

  get canRenderTable() {
    return this.reportDataOfCurrentReport && !this.props.isLoading;
  }

  get needsPaginate() {
    if (!this.reportDataOfCurrentReport) return false;
    return this.reportDataOfCurrentReport.get('data').size === FETCH_X_JOBREQUEST_PER_LOAD;
  }

  render() {
    return (
      <div className={cx('reports__wrapbottom')}>
        <div className={cx('reports__wrapbottom-pre')}>
          <div className={cx('reports__wrapbottom-inner')}>
            <div
              className={cx('reports__spacer', {
                'reports__spacer--noline': this.canRenderTable,
              })}
            />
            {this.props.isLoading && <LoadingSplash static />}
            {this.canRenderTable && this.renderTable()}
            {this.canRenderTable && <div className={cx('tablewrap__afterbottom')} />}
            {this.canRenderTable && <div className={cx('tablewrap__afterright')} />}
          </div>
        </div>
        {this.canRenderTable && (
          <Button
            onClick={this.props.paginate}
            types={['type-flat-primary', 'size-medium', 'fontw-normal', 'fonts-12', 'width-flex']}
          >
            {t('reports.load_more')}
          </Button>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: selectors.selectReportsLoading()(state),
    columnSectionData: selectors.selectReportsSection('columns')(state),
    loadedReport: selectors.selectReportsLoadedReport()(state),
    allReportData: selectors.selectReportsData()(state),
  };
}

export default connect(mapStateToProps, {
  paginate,
  openJobRequestDetails,
})(ReportsTableContainer);
