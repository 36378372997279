import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { DragSource, DropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import ChecklistImageListItem from '../../../../components/ChecklistImageListItem/index';

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
      defaultValue: props.defaultValue,
      children: props.children,
      type: props.type,
    };
  },
  canDrag(props) {
    return !props.isReadOnly && !props.isEnter;
  },
};

const cardTarget = {
  canDrop(props, monitor) {
    const dragType = monitor.getItem().type;
    return dragType === props.type;
  },
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) {
      return;
    }

    props.moveCard(dragIndex, hoverIndex);

    monitor.getItem().index = hoverIndex; //eslint-disable-line
  },
};

class ChecklistDragTextItem extends PureComponent {
  componentDidMount() {
    // hide draglayer
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: false,
    });
  }

  render() {
    const {
      addOrUpdate,
      onDeleteTask,
      isEmpty,
      isEnter,
      connectDragSource,
      isOver,
      connectDropTarget,
      defaultValue,
      isDragging,
      dragDisable,
      isReadOnly,
    } = this.props;
    const preview = isDragging || isOver;
    return connectDropTarget(
      <div>
        {!preview && (
          <ChecklistImageListItem
            iconBg="white"
            isEmpty={isEmpty}
            isEnter={isEnter}
            isReadOnly={isReadOnly}
            connectDragSource={connectDragSource}
            onChange={addOrUpdate}
            onDelete={onDeleteTask}
            dragDisable={dragDisable}
            defaultValue={defaultValue}
            iconImage="other"
          />
        )}
        {preview && (
          <ChecklistImageListItem
            iconBg="white"
            isEmpty
            isDragging={isOver}
            onChange={addOrUpdate}
            onDelete={onDeleteTask}
            defaultValue={defaultValue}
            iconImage="other"
          />
        )}
      </div>,
    );
  }
}

ChecklistDragTextItem.propTypes = {
  connectDragSource: PropTypes.func,
  connectDropTarget: PropTypes.func,
  isDragging: PropTypes.bool,
  moveCard: PropTypes.func.isRequired, // eslint-disable-line
  id: PropTypes.any.isRequired, // eslint-disable-line
};

export default compose(
  DropTarget('TEXT', cardTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  })),
  DragSource('TEXT', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  })),
)(ChecklistDragTextItem);
