import store from 'store';
import log from 'loglevel';
import {
  buildIgnoreDeletedQuery,
  buildUpdatedAtGreaterThanOrEqualQuery,
  fetchParseClass,
  getBatchMappingWithMax,
} from './data.http';
import { requestHandler } from './data.requestHandler';
import { _mapParseContactToContact } from './data.map';

const PROPERLY_MAX_CONTACT_KEY = 'PROPERLY_MAX_PROPERTY_KEY';

const getMaxContactCount = () => store.get(PROPERLY_MAX_CONTACT_KEY) || 0;
const setMaxContactCount = max => {
  const roundedMax = Math.round(max / 50) * 50;
  store.set(PROPERLY_MAX_CONTACT_KEY, roundedMax);
};

export function getUserContacts(timestamp) {
  return requestHandler(() => {
    const query = timestamp
      ? { ...buildIgnoreDeletedQuery(), ...buildUpdatedAtGreaterThanOrEqualQuery(timestamp) }
      : { ...buildIgnoreDeletedQuery() };

    const getContacts = (limit = 10000, skip = 0) =>
      fetchParseClass('Contact', limit, query, `include=profile&skip=${skip || 0}`).then(results =>
        (results || []).map(contact => _mapParseContactToContact(contact)),
      );

    // This could be a little smarter and portion the concurrencies based on the previous size of contacts seen.
    // Works well for people with contacts up to 800x
    const mapping = getBatchMappingWithMax(getMaxContactCount());

    return Promise.all(mapping.map(({ limit, skip } = {}) => getContacts(limit, skip)))
      .then(contactsLists => contactsLists.reduce((acc, list) => acc.concat(list), []))
      .then(results => {
        const resultCount = results.length;
        log.info('Contacts result count', resultCount);

        setMaxContactCount(resultCount);

        return results;
      });
  });
}
