import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import t from '@properly/localization';
import { scrollbarSize } from '@properly/common';
import { PropertyGallery, LoadingSpinner } from '@properly/components';
import filter from 'ramda/src/filter';
import { jobRequestLoadPhoto } from './actions';
import {
  selectLoadingState,
  selectLoadingStateMeta,
  selectJobRequestsWithCleaners,
} from '../../selectors/globalSelector';
import { loadingKey } from './saga';
import {
  mapJobRequestToPropertyPhotos,
  getPropertyJobRequestPhotosColumns,
  getPropertyJobRequestPhotosChecklists,
  mapJobRequestToOtherReportPhotos,
  mapJobRequestToProblemReportPhotos,
} from './mapping';

class PropertyJobPhotos extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
    onClickTile: PropTypes.func,
    isTileSelected: PropTypes.func,
    propertyId: PropTypes.string.isRequired,
    isLoadingFull: PropTypes.bool.isRequired,
    jobRequestLoadPhoto: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: [],
    onClickTile: () => {},
    isTileSelected: () => false,
  };

  state = {
    checklistIdFilter: '',
  };

  componentDidMount() {
    this.load(this.page);
  }

  get page() {
    return this.props.page !== undefined ? this.props.page : 0;
  }

  get data() {
    return this.props.filter ? filter(this.props.filter, this.props.data) : this.props.data;
  }

  get loading() {
    const { data, isLoading, isLoadingPartial, isLoaded, isLoadingFull } = this.props;

    if (isLoadingPartial) {
      return false;
    }

    return !isLoaded || isLoadingFull || (isLoading && (!data || (data && data.length === 0)));
  }

  getPropertyData(jobRequests, selectedChecklistId) {
    const { problemReportsOnly, otherReportsOnly } = this.props;

    if (problemReportsOnly) {
      const rows = mapJobRequestToProblemReportPhotos(jobRequests, selectedChecklistId);
      const columnsCount = rows.reduce(
        (acc, current) => (current.photos && current.photos.length > acc ? current.photos.length : acc),
        0,
      );

      return {
        columns: [...new Array(columnsCount + 1)].map(() => ({})),
        rows,
      };
    }

    if (otherReportsOnly) {
      const rows = mapJobRequestToOtherReportPhotos(jobRequests, selectedChecklistId);
      const columnsCount = rows.reduce(
        (acc, current) => (current.photos && current.photos.length > acc ? current.photos.length : acc),
        0,
      );

      return {
        columns: [...new Array(columnsCount + 1)].map(() => ({})),
        rows,
      };
    }

    const columns = [{ problemReportColumn: true, title: t('job_request.problems.problem_reports') }].concat(
      getPropertyJobRequestPhotosColumns(jobRequests, selectedChecklistId),
    );

    return {
      columns,
      rows: mapJobRequestToPropertyPhotos(jobRequests, selectedChecklistId).map(({ photos, problems, ...rest }) =>
        // We need to ensure that the order of the photos aligns with our columns using the original photo id
        ({
          photos: columns.map(({ problemReportColumn, originalPhoto, title }) => {
            const photo = problemReportColumn
              ? problems[0]
              : (photos || []).find(
                  ({ originalPhoto: currentOriginalPhoto, title: stepTitle }) =>
                    currentOriginalPhoto === originalPhoto && stepTitle === title,
                ) || {};
            return photo;
          }),
          ...rest,
        }),
      ),
    };
  }

  handleChecklistChange = value => {
    this.setState({ checklistIdFilter: value });
  };

  load = (page, pagination) => {
    this.props.jobRequestLoadPhoto({
      propertyId: this.props.propertyId,
      page,
      pagination,
    });
  };

  loadMore = () => {
    this.load(this.page + 1, true);
  };

  render() {
    const { noHeaderRow, data: jobRequests, onClickTile, isTileSelected } = this.props;
    const selectedChecklistId = this.state.checklistIdFilter;
    const checklistOptions = getPropertyJobRequestPhotosChecklists(jobRequests);
    const { rows, columns } = this.getPropertyData(jobRequests, selectedChecklistId);

    const hasPhotos = rows.length > 0;
    const columnCount = columns.length;
    const rowCount = rows.length;

    if (this.loading && !hasPhotos) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: 'calc(100% - 103px)',
            position: 'absolute',
          }}
        >
          <LoadingSpinner />
        </div>
      );
    }

    const scrollbarHeight = scrollbarSize();
    const rowHeight = 110;
    const headerHeight = 30;
    const calculateHeight = height => {
      const baseHeight = height - headerHeight - scrollbarHeight;
      return noHeaderRow ? baseHeight : baseHeight - rowHeight;
    };

    return (
      <AutoSizer disableWidth>
        {({ height }) => (
          <PropertyGallery
            isPhotoSelected={isTileSelected}
            onPhotoClick={onClickTile}
            disableHeader={noHeaderRow}
            checklistOptions={checklistOptions}
            selectedChecklistId={selectedChecklistId}
            onChecklistChange={this.handleChecklistChange}
            columnCount={columnCount}
            rowCount={rowCount}
            propertyPhotoColumns={columns}
            propertyPhotoRows={rows}
            height={calculateHeight(height)}
            hasPhotos={hasPhotos}
          />
        )}
      </AutoSizer>
    );
  }
}

function mapStateToProps(state, props) {
  const loading = selectLoadingState(loadingKey)(state);

  return {
    ...loading,
    data: selectJobRequestsWithCleaners()(state, { ...props, contactId: props.propertyId }),
    page: selectLoadingStateMeta(loadingKey, 'page')(state),
    hasMore: selectLoadingStateMeta(loadingKey, 'hasMore')(state),
  };
}

export default compose(
  connect(mapStateToProps, {
    jobRequestLoadPhoto,
  }),
)(PropertyJobPhotos);
