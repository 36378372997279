"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generatePictureUrl = generatePictureUrl;
exports.genImageBg = genImageBg;
exports.genImage = genImage;
exports.downloadImageFromUrl = exports.imageUploadLimit = exports.pictureSizes = void 0;

var _localization = _interopRequireDefault(require("@properly/localization"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const CLOUDINARY_ID = 'dnsuxxqz1';
const pictureSizes = {
  PROFILE_PICTURE: 't_profileMedium',
  MEDIUM: 't_medium',
  PROPERTY_PICTURE: 't_mediumMedium',
  LARGE: 't_largeLarge',
  SMALL_LARGE: 't_smallLarge',
  SMALL: 't_small',
  ORIGINAL: ''
}; // Base 2 (1024 bytes)

exports.pictureSizes = pictureSizes;
const imageUploadLimit = {
  maxSize: 20971520,
  // 20MB
  minSize: 10240 // 10KB

};
exports.imageUploadLimit = imageUploadLimit;

function generatePictureUrl(picture, size, format = 'jpg') {
  const sizeUrl = size ? `${size},` : '';
  const extension = format ? `.${format}` : '';
  return picture && `https://res.cloudinary.com/${CLOUDINARY_ID}/image/upload/${sizeUrl}f_auto/app/${picture}${extension}`;
}

function genImageBg(image, size) {
  const imageUrl = generatePictureUrl(image, size);
  return imageUrl && {
    backgroundImage: `url(${imageUrl})`
  } || {};
}

function genImage(imageId) {
  if (!imageId) return '';
  return generatePictureUrl(imageId, pictureSizes.PROFILE_PICTURE);
}

const downloadImageFromUrl = async (urlToDownload, fileNameToSaveAs) => {
  try {
    const toDataURL = async url => {
      const response = await fetch(url);
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    }; // we use download attribute from html anchor tag to download image


    const downloadImgElement = document.createElement('a');
    downloadImgElement.href = await toDataURL(urlToDownload);
    downloadImgElement.download = fileNameToSaveAs;
    document.body.appendChild(downloadImgElement);
    downloadImgElement.click();
    document.body.removeChild(downloadImgElement);
  } catch (e) {
    // eslint-disable-next-line no-alert
    alert((0, _localization.default)('signup.error.cloud_code_error'));
    throw e;
  }
};

exports.downloadImageFromUrl = downloadImageFromUrl;