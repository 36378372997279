import { getTitleFromJobRequest } from '@properly/common';
import t from '@properly/localization';
import moment from 'moment-timezone';
import flatten from 'lodash/flatten';
import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import groupBy from 'ramda/src/groupBy';
import filter from 'ramda/src/filter';
import map from 'ramda/src/map';
import head from 'ramda/src/head';
import uniq from 'ramda/src/uniq';
import uniqBy from 'ramda/src/uniqBy';
import path from 'ramda/src/path';
import valuesIn from 'ramda/src/valuesIn';

const byJobId = groupBy(({ jobId }) => jobId);
const byOriginalPhoto = groupBy(({ originalPhoto, title, room }) => `${title || room || 'Other'}-${originalPhoto}`);

function getAllSteps(jobRequest) {
  const { checklists } = jobRequest || {};
  const steps = flatten(map(checklist => checklist.steps, checklists || []));

  return steps || [];
}

function mapJobRequestToJobProgressSteps(jobRequests) {
  return flatten(
    (jobRequests || []).map(jobRequest => {
      const { jobProgress, ...rest } = jobRequest || {};
      const jobTitle = getTitleFromJobRequest(jobRequest);
      return getAllSteps(jobRequest).map(step => ({
        ...step,
        jobProgress,
        jobRequest: rest,
        jobTitle,
      }));
    }),
  );
}

function mapJobRequestToVerificationPhotos(jobRequests, checklistIdFilter) {
  const allJobProgressSteps = [];
  mapJobRequestToJobProgressSteps(jobRequests).map(({ room, title, pictureIdentifier, id: stepId, jobRequest }) => {
    const {
      scheduledStartTime,
      scheduledEndTime,
      jobData,
      connections,
      requestedCleanersData,
      checklists,
      problems,
      jobId,
      jobProgressId,
    } = jobRequest || {};

    const keyedRequestedCleanersData = keyBy(requestedCleanersData, 'id');

    const cleaner = keyedRequestedCleanersData && keyedRequestedCleanersData[connections?.cleanerId];
    checklists.forEach(checklist => {
      const filteredSteps = checklist.verificationPhotos
        .filter(verificationPhoto => verificationPhoto.stepId === stepId)
        .map(verificationPhoto => ({
          photoID: `${title || room || 'Other'}-${pictureIdentifier}`,
          verificationPhoto,
          image: verificationPhoto.pictureIdentifier,
          originalPhoto: pictureIdentifier,
          scheduledStartTime,
          scheduledEndTime,
          title: title || 'Other',
          room,
          jobTitle: jobData.title,
          jobProgressId,
          checklistId: checklist.id,
          cleaner,
          problems,
          jobId,
        }));
      allJobProgressSteps.push(...filteredSteps);
    });

    return null;
  });

  const flattenedSteps = flatten(allJobProgressSteps);

  return checklistIdFilter
    ? filter(({ checklistId }) => checklistId === checklistIdFilter, flattenedSteps)
    : flattenedSteps;
}

function mapJobRequestToProblemReports(jobRequests) {
  const allJobProgressSteps = (jobRequests || []).map(jobRequest => {
    const { scheduledStartTime, jobProgress, scheduledEndTime, connections, requestedCleanersData, problems, jobId } =
      jobRequest || {};

    const jobTitle = getTitleFromJobRequest(jobRequest);

    const keyedRequestedCleanersData = keyBy(requestedCleanersData, 'id');

    const cleaner = keyedRequestedCleanersData && keyedRequestedCleanersData[connections?.cleanerId];

    return flatten(
      problems.map(({ note, pictureIdentifiers, severity }) =>
        pictureIdentifiers.map(pictureUrl => ({
          note,
          image: pictureUrl,
          severity,
          scheduledStartTime,
          scheduledEndTime,
          jobProgressId: jobProgress.id,
          cleaner,
          jobTitle,
          jobId,
        })),
      ),
    );
  });

  return flatten(allJobProgressSteps);
}

function mapJobRequestToOtherReports(jobRequests) {
  const allJobProgressSteps = (jobRequests || []).map(jobRequest => {
    const {
      scheduledStartTime,
      scheduledEndTime,
      jobProgress,
      connections,
      requestedCleanersData,
      jobId,
      additionalInformationReport,
    } = jobRequest || {};

    const jobTitle = getTitleFromJobRequest(jobRequest);

    const reports = map(
      oneAdditionalInformationReport => oneAdditionalInformationReport.report,
      additionalInformationReport || [],
    );

    const keyedRequestedCleanersData = keyBy(requestedCleanersData, 'id');

    const cleaner = keyedRequestedCleanersData && keyedRequestedCleanersData[connections?.cleanerId];

    return flatten(
      reports.map(report =>
        report.map(({ pictureIdentifier, note }) => ({
          note,
          image: pictureIdentifier,
          scheduledStartTime,
          scheduledEndTime,
          jobProgressId: jobProgress.id,
          cleaner,
          jobTitle,
          jobId,
        })),
      ),
    );
  });

  return flatten(allJobProgressSteps);
}

export function getPropertyJobRequestPhotosChecklists(jobRequests) {
  // TODO: filter these based on checklists that actually have verification photos
  const checklistTitles = flatten(
    (jobRequests || []).map(({ checklists }) =>
      (checklists || []).map(checklist => ({ value: checklist.id, title: checklist.title })),
    ),
  )
    .filter(({ title }) => !!title)
    .sort((a, b) => (a.title < b.title ? -1 : 1));

  return [{ value: '', title: t('checklist.all_checklist') }].concat(uniqBy(path(['value']), checklistTitles));
}

export function getPropertyJobRequestPhotosColumns(jobRequests, checklistIdFilter) {
  const steps = mapJobRequestToVerificationPhotos(jobRequests, checklistIdFilter);
  const groupedByOriginalPhoto = byOriginalPhoto(steps);
  const firstItems = map(head, groupedByOriginalPhoto);

  const columns = map(
    ({ title, room, originalPhoto, checklistId }) => ({
      title,
      checklistId,
      room,
      originalPhoto,
    }),
    firstItems,
  );

  return valuesIn(columns).sort((a, b) => (a.title < b.title ? -1 : 1));
}

export function mapJobRequestToPropertyPhotos(jobRequests, checklistIdFilter) {
  const flatVerificationPhotos = mapJobRequestToVerificationPhotos(jobRequests, checklistIdFilter);
  const verificationPhotosGroupedByStepId = byJobId(flatVerificationPhotos);

  const propertyPhotos = map(stepVerificationPhotos => {
    const jobProgressKeys = ['jobProgressId', 'cleaner', 'jobTitle', 'scheduledStartTime', 'scheduledEndTime'];
    const stepOmitKeys = [...jobProgressKeys, 'problems'];

    const jobProgressProperties = pick(head(stepVerificationPhotos), jobProgressKeys);

    const verificationPhotos = map(currentStep => omit(currentStep, stepOmitKeys), stepVerificationPhotos);

    const transformProblems = ({ problems }) =>
      map(
        ({ pictureIdentifiers, ...rest }) => ({
          image: head(pictureIdentifiers),
          pictureUrl: head(pictureIdentifiers),
          ...rest,
        }),
        problems || [],
      );
    const stepProblems = uniq(
      filter(({ pictureUrl }) => !!pictureUrl, flatten(map(transformProblems, stepVerificationPhotos))),
    );

    const actualDurationMinutes = moment(jobProgressProperties.scheduledEndTime).diff(
      jobProgressProperties.scheduledStartTime,
      'm',
    );

    return {
      photos: verificationPhotos,
      ...jobProgressProperties,
      actualDurationMinutes,
      problems: stepProblems,
    };
  }, verificationPhotosGroupedByStepId);

  return Object.keys(propertyPhotos || {})
    .map(key => propertyPhotos[key])
    .sort((a, b) => (a.scheduledStartTime < b.scheduledStartTime ? 1 : -1));
}

export function mapJobRequestToProblemReportPhotos(jobRequests) {
  const flatProblemReportPhotos = mapJobRequestToProblemReports(jobRequests);
  const groupedByJob = byJobId(flatProblemReportPhotos);

  return Object.keys(groupedByJob || {})
    .map(key => groupedByJob[key])
    .sort((a, b) => (a.scheduledStartTime < b.scheduledStartTime ? 1 : -1))
    .map(reports => {
      const jobProgressKeys = ['jobProgressId', 'cleaner', 'jobTitle', 'scheduledStartTime', 'scheduledEndTime'];
      const stepOmitKeys = [...jobProgressKeys];

      const jobProgressProperties = pick(head(reports), jobProgressKeys);
      const photos = map(currentStep => omit(currentStep, stepOmitKeys), reports);

      return { ...jobProgressProperties, photos };
    });
}

export function mapJobRequestToOtherReportPhotos(jobRequests) {
  const flatOtherReportPhotos = mapJobRequestToOtherReports(jobRequests);
  const groupedByJob = byJobId(flatOtherReportPhotos);

  return Object.keys(groupedByJob || {})
    .map(key => groupedByJob[key])
    .sort((a, b) => (a.scheduledStartTime < b.scheduledStartTime ? 1 : -1))
    .map(reports => {
      const jobProgressKeys = ['jobProgressId', 'cleaner', 'jobTitle', 'scheduledStartTime', 'scheduledEndTime'];
      const stepOmitKeys = [...jobProgressKeys];

      const jobProgressProperties = pick(head(reports), jobProgressKeys);
      const photos = map(currentStep => omit(currentStep, stepOmitKeys), reports);

      return { ...jobProgressProperties, photos };
    });
}
