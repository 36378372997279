"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.borderColor = exports.colorPrimary = exports.colorGreyLight = exports.colorMoccasin = exports.colorGreen = exports.colorGreenLight = exports.colorLightGrey = exports.colorGrey = exports.colorBlack = exports.colorOrange = void 0;
var colorOrange = '#ff5722';
exports.colorOrange = colorOrange;
var colorBlack = '#000';
exports.colorBlack = colorBlack;
var colorGrey = '#868f9a';
exports.colorGrey = colorGrey;
var colorLightGrey = '#ececec';
exports.colorLightGrey = colorLightGrey;
var colorGreenLight = '#e8f7ed';
exports.colorGreenLight = colorGreenLight;
var colorGreen = '#32d17d';
exports.colorGreen = colorGreen;
var colorMoccasin = '#ffa726';
exports.colorMoccasin = colorMoccasin;
var colorGreyLight = '#c2ccd6';
exports.colorGreyLight = colorGreyLight;
var colorPrimary = '#36ACB2';
exports.colorPrimary = colorPrimary;
var borderColor = 'rgba(29,39,48,0.12)';
exports.borderColor = borderColor;