"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapCalendarEventToColor = mapCalendarEventToColor;
exports.mapCalendarEventToLetter = mapCalendarEventToLetter;
exports.getCalendarChangeButtonText = getCalendarChangeButtonText;
exports.mapCalendarEventToLabel = mapCalendarEventToLabel;
exports.matchCalendarBookingEvents = matchCalendarBookingEvents;
exports.getCalendarBookingByDays = getCalendarBookingByDays;
exports.getCalendarBookingDays = exports.getCalendarButton = exports.BUTTON_MODES = void 0;

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

var _loglevel = _interopRequireDefault(require("loglevel"));

var _config = require("@properly/config");

var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));

var _isObject = _interopRequireDefault(require("lodash/isObject"));

var _orderBy = _interopRequireDefault(require("lodash/orderBy"));

var _each = _interopRequireDefault(require("lodash/each"));

var _memoize = _interopRequireDefault(require("lodash/memoize"));

var _map = _interopRequireDefault(require("lodash/map"));

var _localization = _interopRequireDefault(require("@properly/localization"));

var _calendarEvent = require("../calendarEvent");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function mapCalendarEventToColor(calendarEvent = {}) {
  const isDelayed = (0, _calendarEvent.isCalendarEventDelayed)(calendarEvent);

  if (isDelayed) {
    return 'delayed';
  }

  switch ((0, _calendarEvent.getCalendarEventJobRequestStatus)(calendarEvent)) {
    case _config.jobRequestStatus.StateCreated:
      return 'pending';

    case _config.jobRequestStatus.StateAccepted:
      return 'accepted';

    case _config.jobRequestStatus.StateCancelled:
    case _config.jobRequestStatus.StateCleanerCancelled:
    case _config.jobRequestStatus.StateDeclined:
      return 'cancelled';

    case _config.jobRequestStatus.StateStarted:
    case _config.jobRequestStatus.StatePaused:
      return 'progress';

    case _config.jobRequestStatus.StateFinished:
      return 'finished';

    default:
      _loglevel.default.warn('Unknown color for calendar event', calendarEvent);

      return 'accepted';
  }
}

function mapCalendarEventToLetter(calendarEvent = {}) {
  const label = mapCalendarEventToLabel(calendarEvent);

  switch (label) {
    case 'jobstatus.delayed':
      return 'D';

    case 'jobstatus.seen':
      return 'V';

    case 'jobstatus.accepted':
      return 'A';

    case 'jobstatus.pending':
      return 'P';

    case 'jobstatus.cancelled':
      return 'C';

    case 'jobstatus.declined':
      return 'D';

    case 'jobstatus.started':
      return 'IP';

    case 'jobstatus.completed':
      return 'F';

    default:
      _loglevel.default.warn('Unknown mapping for calendar event label', calendarEvent, label);

      return '';
  }
}

const BUTTON_MODES = {
  edit: 'edit',
  sendmore: 'sendmore',
  cancel: 'cancel',
  schedule: 'schedule'
};
exports.BUTTON_MODES = BUTTON_MODES;
const getCalendarButton = (0, _memoize.default)((type, status, eventStatus) => {
  const matchButtonMode = (x, y, z) => type === x && status === y && eventStatus === z; // Pending


  if (matchButtonMode(4, 1, 1)) return BUTTON_MODES.edit;
  if (matchButtonMode(3, 1, 1)) return BUTTON_MODES.edit;
  if (matchButtonMode(3, 1, 2)) return BUTTON_MODES.edit;
  if (matchButtonMode(3, 1, 3)) return BUTTON_MODES.cancel; // Declined Job

  if (matchButtonMode(4, 4, 1)) return BUTTON_MODES.sendmore;
  if (matchButtonMode(3, 4, 1)) return BUTTON_MODES.sendmore;
  if (matchButtonMode(3, 4, 2)) return BUTTON_MODES.edit;
  if (matchButtonMode(3, 4, 3)) return BUTTON_MODES.cancel; // Cancelled By Cleaner

  if (matchButtonMode(4, 8, 1)) return BUTTON_MODES.edit;
  if (matchButtonMode(3, 8, 1)) return BUTTON_MODES.edit;
  if (matchButtonMode(3, 8, 2)) return BUTTON_MODES.edit;
  if (matchButtonMode(3, 8, 3)) return BUTTON_MODES.cancel; // Cancelled By Host

  if (matchButtonMode(3, 3, 2)) return BUTTON_MODES.edit;
  if (matchButtonMode(3, 3, 3)) return BUTTON_MODES.cancel; // Cancelled By Host

  if (matchButtonMode(3, 2, 2)) return BUTTON_MODES.edit;
  if (matchButtonMode(3, 2, 3)) return BUTTON_MODES.cancel;
  if (status === 3 || status === 8) return BUTTON_MODES.edit;
  return 'schedule';
}, (type, status, eventStatus) => `${type}-${status}-${eventStatus}`);
exports.getCalendarButton = getCalendarButton;

function getCalendarChangeButtonText(statusRaw, eventStatusRaw) {
  let changedTitle = false;
  if (statusRaw === _config.jobRequestStatus.StateDeclined && eventStatusRaw === 1) changedTitle = (0, _localization.default)('calendartile.modes.sendmore');
  if (statusRaw === _config.jobRequestStatus.StateCleanerCancelled && eventStatusRaw === 1) changedTitle = (0, _localization.default)('calendartile.modes.sendnew');
  if (statusRaw === _config.jobRequestStatus.StateCancelled && eventStatusRaw === 1) changedTitle = (0, _localization.default)('calendartile.modes.sendnew');
  return changedTitle;
}

function mapCalendarEventToLabel(calendarEvent = {}) {
  const isDelayed = (0, _calendarEvent.isCalendarEventDelayed)(calendarEvent);

  if (isDelayed) {
    return 'jobstatus.delayed';
  }

  const status = (0, _calendarEvent.getCalendarEventJobRequestStatus)(calendarEvent);
  const isCreated = status === _config.jobRequestStatus.StateCreated;

  if (isCreated && (0, _calendarEvent.isCalendarEventSeen)(calendarEvent)) {
    return 'jobstatus.seen';
  }

  switch (status) {
    case _config.jobRequestStatus.StateCreated:
      return 'jobstatus.pending';

    case _config.jobRequestStatus.StateAccepted:
      return 'jobstatus.accepted';

    case _config.jobRequestStatus.StateCancelled:
    case _config.jobRequestStatus.StateCleanerCancelled:
      return 'jobstatus.cancelled';

    case _config.jobRequestStatus.StateDeclined:
      return 'jobstatus.declined';

    case _config.jobRequestStatus.StateStarted:
    case _config.jobRequestStatus.StatePaused:
      return 'jobstatus.started';

    case _config.jobRequestStatus.StateFinished:
      return 'jobstatus.completed';

    default:
      _loglevel.default.warn('Unknown label for calendar event', calendarEvent);

      return null;
  }
}

function findBookingSpace(lineIndex, dates, bookingLineResult, newDoc) {
  let success = 0;
  const MAX_BOOKING_DEPTH = 20;

  if (lineIndex > MAX_BOOKING_DEPTH) {
    return;
  }

  const currentLine = bookingLineResult[lineIndex] = bookingLineResult[lineIndex] || {}; // eslint-disable-line

  const getClone = () => bookingLineResult[lineIndex] ? (0, _cloneDeep.default)(currentLine) : currentLine;

  let clone;
  const bookingDays = Object.keys(newDoc.obj);

  const isSameDayBooking = (calendarEvent, day) => (0, _calendarEvent.isCalendarEventBookingArrivalLocalDay)(currentLine[day], day) && (0, _calendarEvent.isCalendarEventBookingDepartureLocalDay)(calendarEvent, day) || (0, _calendarEvent.isCalendarEventBookingArrivalLocalDay)(calendarEvent, day) && (0, _calendarEvent.isCalendarEventBookingDepartureLocalDay)(currentLine[day], day);

  for (let i = 0; i < bookingDays.length; i++) {
    const currentDay = bookingDays[i];
    const currentItem = newDoc.obj[currentDay];

    if (currentItem) {
      if (!currentLine[currentDay]) {
        clone = clone || getClone(); // empty date

        clone[currentDay] = currentItem;
        success += 1;
      } else if (currentLine[currentDay] && (0, _isObject.default)(currentLine[currentDay]) && !Array.isArray(currentLine[currentDay]) && isSameDayBooking(currentItem, currentDay)) {
        clone = clone || getClone();
        const {
          booking: existingBooking
        } = clone[currentDay] || {};
        const {
          booking: currentBooking
        } = currentItem || {};
        const {
          arrivalLocalDay,
          departureLocalDay
        } = currentBooking || {};
        const {
          departureLocalDay: existingDeparture
        } = existingBooking || {}; // Make sure that this is not a booking where the arrival and departure are the same day
        // Unless the existing item finishes on this day

        if (existingDeparture !== arrivalLocalDay && arrivalLocalDay === departureLocalDay) {
          break;
        }

        clone[currentDay] = [clone[currentDay], currentItem];
        success += 1;
      } else {
        // Continue to the next line
        break;
      }
    }
  }

  if (success === newDoc.matches) {
    bookingLineResult[lineIndex] = clone || getClone(); // eslint-disable-line

    return;
  }

  findBookingSpace(lineIndex + 1, dates, bookingLineResult, newDoc);
}

const getCalendarBookingDays = (0, _memoize.default)((dates, bookingTimeZone) => dates.map(date => {
  const unixEnd = _momentTimezone.default.tz(date, bookingTimeZone).endOf('day').unix();

  return {
    date,
    unixEnd
  };
}), (dates, bookingTimeZone) => (dates || []).join('-') + bookingTimeZone);
exports.getCalendarBookingDays = getCalendarBookingDays;

function matchCalendarBookingEvents(calendarEventBookings, dates, bookingTimeZone) {
  const bookingLineResult = [];
  const daysAndUnix = getCalendarBookingDays(dates, bookingTimeZone);
  const orderedBookings = (0, _orderBy.default)(calendarEventBookings || [], calendarEventBooking => calendarEventBooking.booking.bookingDurationDays, ['desc']);
  const daysArray = orderedBookings.map(calendarEvent => getCalendarBookingByDays(calendarEvent, daysAndUnix));
  (0, _each.default)(daysArray, item => findBookingSpace(0, dates, bookingLineResult, item));
  return bookingLineResult;
}

function getCalendarBookingByDays(calendarEvent, dates) {
  const departureUnix = (0, _calendarEvent.getCalendarEventDepartureUnix)(calendarEvent);
  const arrivalUnix = (0, _calendarEvent.getCalendarEventArrivalUnix)(calendarEvent);
  const arrivalLocalDay = (0, _calendarEvent.getCalendarEventBookingArrivalLocalDay)(calendarEvent);
  const departureLocalDay = (0, _calendarEvent.getCalendarEventBookingDepartureLocalDay)(calendarEvent);
  let matches = 0;
  const dateMatches = [];
  const obj = {};
  const array = (0, _map.default)(dates, ({
    date,
    unixEnd
  }) => {
    const isOnStartOrEndDay = arrivalLocalDay === date || departureLocalDay === date;
    const isWithinBookingRange = departureUnix > unixEnd && arrivalUnix < unixEnd;

    if (isOnStartOrEndDay || isWithinBookingRange) {
      matches += 1;
      dateMatches.push(date);
      obj[date] = calendarEvent;
      return [date, calendarEvent];
    }

    obj[date] = null;
    return ['no', null];
  });
  return {
    array,
    obj,
    matches,
    dateMatches
  };
}