import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import t from '@properly/localization';
import { connect } from 'react-redux';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { Icon } from '@properly/components';
import classNames from 'classnames/bind';
import styles from '../components/settingsStyles.module.css';
import Sidebar from '../../../../components/Sidebar';
import TopLabel from '../../../../components/TopLabel';
import { MasterDetailCell } from '../../../../components/MasterDetail';
import { goToSettingDetail } from '../state/SettingsActions';
import { selectHasConnectionIssue } from '../../account/state/AccountSelectors';

const cx = classNames.bind(styles);

class SettingsSidebarContainer extends PureComponent {
  static propTypes = {
    settings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    permittedSections: PropTypes.arrayOf(PropTypes.string).isRequired,
    activeRoute: PropTypes.string.isRequired,
    navigateToSettingDetail: PropTypes.func.isRequired,
    hasConnectionIssue: PropTypes.bool,
  };

  static defaultProps = {
    hasConnectionIssue: false,
  };

  renderTabs() {
    const { settings, permittedSections, activeRoute, navigateToSettingDetail, hasConnectionIssue } = this.props;

    const filteredSettings = filter(settings, item => permittedSections.includes(item.key));

    return map(filteredSettings, item => (
      <div key={item.key} className={cx('settings-sidebar')}>
        <MasterDetailCell
          type="settings"
          data-key={item.key}
          onClick={() => item.enabled && navigateToSettingDetail(item.route)}
          active={item.routes.includes(activeRoute)}
          title={item.title}
          disabled={!item.enabled}
        />
        <div className={cx('settings-sidebar__icon')}>
          {hasConnectionIssue && item.showConnectionIssue && <Icon.IcError1 />}
        </div>
      </div>
    ));
  }

  render() {
    return (
      <Sidebar>
        <TopLabel data-key="button_settings" label={t('titles.settings')} disabled />
        <Sidebar.Hr />
        <Sidebar.List>
          <Sidebar.ListInner>
            <Sidebar.ListInnerInner flushRight>{this.renderTabs()}</Sidebar.ListInnerInner>
          </Sidebar.ListInner>
        </Sidebar.List>
      </Sidebar>
    );
  }
}

function mapStateToProps(state) {
  return {
    hasConnectionIssue: selectHasConnectionIssue(state),
  };
}

export default connect(mapStateToProps, { navigateToSettingDetail: goToSettingDetail })(SettingsSidebarContainer);
