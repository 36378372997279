"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  FEATURE_FLAG_LIVE_COMMENTS: 'featureFlagLiveComments',
  FEATURE_FLAG_LIVE_CONNECTED_ACCOUNTS: 'featureFlagLiveConnectedAccounts',
  FEATURE_FLAG_MARKETPLACE: 'marketplace',
  FEATURE_FLAG_LIVE_VERIFICATION_PHOTOS: 'featureFlagLiveVerificationPhotos'
};
exports["default"] = _default;