import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { preLoginSaga } from '../../actions/globalActions';

class Config extends React.PureComponent {
  componentDidMount() {
    this.props.preLoginSaga();
  }

  render() {
    return null;
  }
}

export default compose(
  connect(
    null,
    {
      preLoginSaga,
    },
  ),
)(Config);
