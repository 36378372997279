import { getConfig } from '@properly/config';
import { injectScript } from '@properly/common';
import log from 'loglevel';
import noop from 'lodash/noop';

const gtag = window.gtag || noop;

const getTrackingIdFromConfig = trackingId => getConfig().then(config => config[trackingId]);

const initializeGtag = (googleAnalyticsTrackingId, googleAdwordsTrackingId) => {
  log.info('initializeGtag', { googleAnalyticsTrackingId, googleAdwordsTrackingId });

  gtag('config', googleAnalyticsTrackingId, { send_page_view: false });
  gtag('config', googleAdwordsTrackingId);
};

const injectGoogleAnalyticsScript = (googleAnalyticsTrackingId, googleAdwordsTrackingId) => {
  injectScript(`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsTrackingId}`)
    .then(() => {
      log.info('Successfully loaded gtm script');
      initializeGtag(googleAnalyticsTrackingId, googleAdwordsTrackingId);
    })
    .catch(error => {
      log.warn(error);
    });
};

getConfig().then(config => {
  const googleAnalyticsTrackingId = config.GOOGLE_ANALYTICS_TRACKING_ID;
  const googleAdwordsTrackingId = config.GOOGLE_ADWORDS_TRACKING_ID;
  if (googleAnalyticsTrackingId && googleAdwordsTrackingId) {
    injectGoogleAnalyticsScript(googleAnalyticsTrackingId, googleAdwordsTrackingId);
  } else {
    log.warn('Google Analytics or Adwords tracking Id is not found on config', config);
  }
});

export const sendEventToGoogleAnalytics = (category, action = '', label = '') => {
  getTrackingIdFromConfig('GOOGLE_ANALYTICS_TRACKING_ID').then(trackingId => {
    gtag('event', 'Click', {
      send_to: trackingId,
      event_category: category,
      event_action: action,
      event_label: label,
    });
  });
};

export const sendPageToGoogleAnalytics = path => {
  getTrackingIdFromConfig('GOOGLE_ANALYTICS_TRACKING_ID').then(trackingId => {
    gtag('config', trackingId, {
      page_path: path,
    });
  });
};

export const sendEventToGoogleAdwords = eventName => {
  getTrackingIdFromConfig(eventName).then(trackingId => {
    gtag('event', 'conversion', { send_to: trackingId });
  });
};
