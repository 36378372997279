import React from 'react';
import { Route, IndexRedirect, IndexRoute, Redirect } from 'react-router';
import log from 'loglevel';
import { isMobileTouch, setWindowTitle } from '@properly/common';
import AccountContainer from './modules/desktopApp/account/containers/AccountContainer';
import AccountOauthDirectContainer from './modules/desktopApp/account/containers/AccountOauthDirectContainer';
import AccountProfileContainer from './modules/desktopApp/account/containers/AccountProfileContainer';
import AccountConnectionsLegacyContainer from './modules/desktopApp/account/containers/AccountConnectionsLegacyContainer';
import AccountContainerEdit from './modules/desktopApp/account/containers/AccountContainerEdit';
import AccountConnectionsAdaptorContainer from './modules/desktopApp/account/containers/AccountConnectionsAdaptorContainer';
import AccountConnectionsAdaptorEditContainer from './modules/desktopApp/account/containers/AccountConnectionsAdaptorEditContainer';
import AccountNotificationsContainer from './modules/desktopApp/account/containers/AccountNotificationsContainer';
import AccountPasswordContainer from './modules/desktopApp/account/containers/AccountPasswordContainer';
import AccountReceiptsContainer from './modules/desktopApp/account/containers/AccountReceiptsContainer';
import AccountSubscriptionContainer from './modules/desktopApp/account/containers/AccountSubscriptionContainer';
import { getUrlParams } from './helper/herbert';

const AccountMobileWrapper = ({ children, ...rest }) => (
  <AccountContainer disableWrapper {...rest}>
    <div style={{ padding: 16 }}>{children}</div>
  </AccountContainer>
);

// If we load this page directly and are on a small device with a touch screen we will assume we are on a mobile device redirecting back to this
// We need to do this as there is no other robust way to detect mobile devices and our redirect method is not yet implemented by all the adaptors
// We can add an extra layer to this by ensuring that the mobile connections page has been viewed before (which should only be true for mobile browsers)

const initialLocation = window.location.hash;
const initialLocationLoadOnAccountsMobile = String(initialLocation).indexOf('account-mobile/connections-mobile') !== -1;

const recordMobileViewedKey = 'PROPERLY_recordMobileConnectionsViewed';
const recordMobileOnboardingViewedKey = 'PROPERLY_recordMobileOnboardingViewed';
const recordMobileOpenedExternalBrowserKey = 'PROPERLY_recordMobileOpenedExternalBrowserKey';

/** Store related functions  */
const recordKeyToStore = key => {
  try {
    localStorage.setItem(key, 'true');
  } catch (err) {
    log.error(err);
  }
};

const retrieveKeyFromStore = key => {
  try {
    if (!key) {
      return false;
    }

    return !!localStorage.getItem(key);
  } catch (err) {
    log.error(err);
    return false;
  }
};

const removeKeyFromStore = key => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    log.error(err);
  }
};

/** preprocessor for routes  */
const preprocessStore = (props, mobile) => {
  const queryParam = props.location?.query;
  const redirectParams = queryParam?.redirectParams;

  let decodedParamsTokenized;
  if (redirectParams) {
    try {
      const decodedParams = window.atob(redirectParams);
      decodedParamsTokenized = getUrlParams(decodedParams);
    } catch {
      log.error('Could not decode redirectParam');
    }
  }

  const redirectPartnerId = decodedParamsTokenized?.redirectPartner;
  const connectDirectAccount = decodedParamsTokenized?.connectDirect;
  const redirectPartnerLabel = decodedParamsTokenized?.redirectPartnerLabel;
  const reconnectId = decodedParamsTokenized?.reconnectId;

  if (mobile) {
    const isSettingsMobile = queryParam?.settings;
    const isOnboardingMobile = queryParam?.onboarding || decodedParamsTokenized?.onboarding;

    // set to store
    recordKeyToStore(recordMobileViewedKey);
    if (isOnboardingMobile === 'true') {
      recordKeyToStore(recordMobileOnboardingViewedKey);
    }
    if (connectDirectAccount) {
      recordKeyToStore(recordMobileOpenedExternalBrowserKey);
    }

    // remove key from store if below conditions are met
    if ((isSettingsMobile === 'true' && !isOnboardingMobile) || isOnboardingMobile === 'false') {
      removeKeyFromStore(recordMobileOnboardingViewedKey);
    }
  }

  // return processed values
  return { redirectPartnerId, redirectPartnerLabel, connectDirectAccount, reconnectId };
};

const preprocessStoreMobile = props => preprocessStore(props, true);

/** Functions Export  */
export const isMobileAppIFrameLoad = () =>
  (retrieveKeyFromStore(recordMobileViewedKey) || initialLocationLoadOnAccountsMobile) && isMobileTouch();

export default () => (
  <>
    <Route
      path="account-mobile"
      component={props => (
        <AccountMobileWrapper onboardingMobile={retrieveKeyFromStore(recordMobileOnboardingViewedKey)} {...props} />
      )}
    >
      <IndexRedirect to="connections-mobile" />
      <Route
        path="connections-mobile"
        component={props => {
          const { redirectPartnerId, connectDirectAccount, redirectPartnerLabel, reconnectId } = preprocessStoreMobile(
            props,
          );
          if (redirectPartnerId && connectDirectAccount && redirectPartnerLabel) {
            return (
              <AccountOauthDirectContainer
                mobile
                reconnectId={reconnectId}
                redirectPartnerId={redirectPartnerId}
                connectDirectPartner={connectDirectAccount}
                redirectPartnerLabel={redirectPartnerLabel}
                {...props}
              />
            );
          }
          return (
            <AccountConnectionsAdaptorContainer
              connectDirectPartner={connectDirectAccount}
              redirectPartnerId={redirectPartnerId}
              externalMobileBrowserConnected={retrieveKeyFromStore(recordMobileOpenedExternalBrowserKey)}
              mobile
              onboardingMobile={retrieveKeyFromStore(recordMobileOnboardingViewedKey)}
              editRoute="account-mobile/connections-mobile"
              {...props}
            />
          );
        }}
        onEnter={setWindowTitle('titles.connected_accounts')}
      />
      <Route
        path="connections-mobile/:partner/:id"
        component={props => (
          <AccountConnectionsAdaptorEditContainer
            externalMobileBrowserConnected={retrieveKeyFromStore(recordMobileOpenedExternalBrowserKey)}
            mobile
            onboardingMobile={retrieveKeyFromStore(recordMobileOnboardingViewedKey)}
            {...props}
          />
        )}
        onEnter={setWindowTitle('titles.edit_connection')}
      />
    </Route>

    <Route
      path="account/connect-direct-oauth"
      component={props => {
        const { redirectPartnerId, connectDirectAccount, redirectPartnerLabel } = preprocessStore(props);

        return (
          <AccountOauthDirectContainer
            redirectPartnerId={redirectPartnerId}
            connectDirectPartner={connectDirectAccount}
            redirectPartnerLabel={redirectPartnerLabel}
          />
        );
      }}
      onEnter={setWindowTitle('titles.connected_accounts')}
    />
    <Route path="account" component={AccountContainer}>
      {isMobileAppIFrameLoad() && <Redirect path="connections/" to="/account-mobile/connections-mobile" />}
      {isMobileAppIFrameLoad() && <Redirect path="connections" to="/account-mobile/connections-mobile" />}
      {isMobileAppIFrameLoad() && <Redirect path="oauth/" to="/account-mobile/connections-mobile" />}
      {isMobileAppIFrameLoad() && <Redirect path="oauth" to="/account-mobile/connections-mobile" />}

      <IndexRoute component={AccountProfileContainer} onEnter={setWindowTitle('titles.profile')} />
      <Redirect path="oauth" to="/settings/connections" />
      <Redirect path="connections" to="/settings/connections" />
      <Redirect path="connections/" to="/settings/connections" />
      <>
        <Route
          path="connections-legacy"
          component={AccountConnectionsLegacyContainer}
          onEnter={setWindowTitle('titles.connected_accounts')}
        />
        <Route
          path="connections-legacy/:partner/:id"
          component={AccountContainerEdit}
          onEnter={setWindowTitle('titles.edit_connection')}
        />
      </>

      <Route
        path="notifications"
        component={AccountNotificationsContainer}
        onEnter={setWindowTitle('titles.notifications')}
      />
      <Route path="password" component={AccountPasswordContainer} onEnter={setWindowTitle('titles.password')} />
      <Route path="receipts" component={AccountReceiptsContainer} onEnter={setWindowTitle('titles.receipts')} />
      <Route
        path="subscription"
        component={AccountSubscriptionContainer}
        onEnter={setWindowTitle('titles.subscription')}
      />
    </Route>
  </>
);
