import trim from 'lodash/trim';
import each from 'lodash/each';
import lodashResult from 'lodash/result';

export function genAddress(formData) {
  const fieldsOfInterest = ['apt', 'city', 'state', 'street', 'zip', 'country'];
  const defaultAddressObj = {
    apt: '',
    city: '',
    country: '',
    state: '',
    street: '',
    zip: '',
  };
  const res = { ...defaultAddressObj };
  each(fieldsOfInterest, key => {
    if (formData[key]) {
      res[key] = formData[key];
    }
  });
  res.AddressArray = [`${res.street}`, `${res.city} ${res.state} ${res.zip}`, `${res.country}`];
  return res;
}

function genHasUserTitle(changeSet) {
  const titleMatchesAddress = trim(changeSet.title) === trim(changeSet.street);
  return { hasUserTitle: !titleMatchesAddress };
}

export function mapFormDataToClassData(formData, storedProperty) {
  return {
    objectId: formData.objectId,
    title: formData.title,
    location: { latitude: formData.lat, longitude: formData.lng },
    timeZone: formData.timeZoneId,
    countryCode: formData.countryCode,
    address: genAddress(formData),
    numOfBedrooms: formData.numOfBedrooms,
    numOfBeds: formData.numOfBeds,
    numOfBathrooms: formData.numOfBathrooms,
    type: formData.propertyType,
    ...genHasUserTitle(formData, storedProperty),
  };
}

export function mapClassDataToFormData(classData) {
  return {
    objectId: classData.objectId,
    title: classData.title,
    lat: lodashResult(classData, ['location', 'latitude']),
    lng: lodashResult(classData, ['location', 'longitude']),
    numOfBedrooms: classData.numOfBedrooms || 0,
    numOfBeds: classData.numOfBeds || 0,
    numOfBathrooms: classData.numOfBathrooms || 0,
    propertyType: classData.type,
    street: lodashResult(classData, ['address', 'street']),
    city: lodashResult(classData, ['address', 'city']),
    state: lodashResult(classData, ['address', 'state']),
    zip: lodashResult(classData, ['address', 'zip']),
    apt: lodashResult(classData, ['address', 'apt']),
    country: lodashResult(classData, ['address', 'country']),
  };
}
