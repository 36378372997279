import { Map } from 'immutable';
import { compose, onlyUpdateForKeys } from 'recompose';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import each from 'lodash/each';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import { updateParseProperty } from '../../data';
import { trackPropertyEditDetails } from '../../../../actions/trackingEvents';

import { updateProperty } from '../state/PropertyActions';

class PropertyInputDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { data: Map(), status: undefined };
  }

  // to make form always controlled by react
  defaulter(val) {
    return val || '';
  }

  getter = key => this.defaulter(this.state.data.get(key));

  getterStore = (key, obj = this.props) => this.defaulter(obj.property[key]);

  updateLocalStore(prev, next) {
    each(next.watch, i => {
      this.setter(i, this.getterStore(i, next));
    });
  }

  match(a, b) {
    return a === b;
  }

  hasChanged() {
    let res = false;
    each(this.props.watch, i => {
      if (!this.match(this.getter(i), this.getterStore(i))) {
        res = true;
        return false;
      }
      return true;
    });
    return res;
  }

  componentDidMount() {
    this.updateLocalStore(undefined, this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateLocalStore(this.props, nextProps);
  }

  onCancel = () => {
    each(this.props.watch, i => {
      this.setter(i, this.getterStore(i, this.props));
    });
  };

  genCanSaveString(status, isChanged) {
    switch (status) {
      case 'saved':
      case 'error':
      case 'loading':
        return status;
      default:
    }
    if (isChanged) {
      return 'changed';
    }
    return null;
  }

  genMap() {
    const map = {};
    each(this.props.watch, i => {
      map[i] = this.getter(i);
    });
    return map;
  }

  onSave = () => {
    this.setState({ status: 'loading' });
    updateParseProperty(this.props.editPropertyId, this.genMap())
      .then(updatedProperty => {
        this.props.updateProperty(updatedProperty.objectId, updatedProperty);
        this.setState({ status: 'saved' });
        trackPropertyEditDetails(this.props.editPropertyId);
        setTimeout(() => {
          this.setState({ status: undefined });
        }, 2000);
      })
      .catch(() => {
        this.setState({ status: 'error' });
      });
  };

  setter(key, val) {
    this.setState(state => ({ data: state.data.set(key, val) }));
  }

  onChange = field => e => {
    this.setter(field, e.target.value);
  };

  render() {
    const changed = this.hasChanged();
    return this.props.content(
      this.onChange,
      this.onCancel,
      this.onSave,
      changed,
      this.genCanSaveString(this.state.status, changed),
      this.getter,
    );
  }
}

PropertyInputDetailContainer.propTypes = {
  // cannot be changed after mount
  content: PropTypes.func.isRequired,
  // cannot be changed after mount
  editPropertyId: PropTypes.string.isRequired,
  // cannot be changed after mount
  watch: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function mapStateToProps(state, props) {
  return {
    property: selectorsGlobal.selectProperty(props.editPropertyId)(state, props),
  };
}

export default compose(
  connect(
    mapStateToProps,
    {
      updateProperty,
    },
  ),
  onlyUpdateForKeys(['property']),
)(PropertyInputDetailContainer);
