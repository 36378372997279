import forOwn from 'lodash/forOwn';

export default class JobInstructions {
  // jobInstructions === checklist steps
  constructor(objectId, steps, role, user) {
    this.objectId = objectId;
    this.steps = steps || [];
    this.role = role;
    this.user = user;
  }

  getOwnProps(scope, onScope) {
    const obj = onScope || {};
    forOwn(scope, (value, key) => {
      obj[key] = value;
      if (key === 'steps' && !value) {
        obj[key] = [];
      }
    });
    return obj;
  }

  setData(obj) {
    this.getOwnProps(obj, this);
  }

  getData() {
    return this.getOwnProps(this);
  }
}
