import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import { PopoverDefault, Icon } from '@properly/components';

import isArray from 'lodash/isArray';
import trim from 'lodash/trim';
import each from 'lodash/each';
import noop from 'lodash/noop';
import { compose } from 'recompose';
import classNames from 'classnames/bind';
import styles from './index.module.css';
import { ThumbsUpBubble, ThumbsUpPopoverContent } from '../../components/ThumbsUpBarElements';
import {
  selectPhotoGroupedByImageId,
  selectLoadingStateMeta,
  selectCurrentUserId,
} from '../../selectors/globalSelector';
import { selectAdminMode } from '../../modules/desktopApp/settings/state/SettingsSelectors';
import { mapUpDownToIcon } from '../../helper/herbert';
import pageLoad from '../../hoc/PageLoad';
import { createComment, deleteComment, updateComment, updateThumbs, createThumbs, deleteThumbs } from './actions';
import PhotoFeedback from '../../model/photoFeedback';
import { loadingKey } from './constants';

const cx = classNames.bind(styles);

function mapStoreCommentToLocalComment(comment) {
  return {
    comment: comment.comment,
    createDate: comment.updatedAt,
    version: comment.version,
    sender: comment.sender,
    firstName: comment.sender.firstName,
    lastName: comment.sender.lastName,
    timeZone: comment.propertyTimeZone,

    // other state
    isNew: false,
    isEdit: false,
    isViewed: comment.viewed,
  };
}
function mapLocalCommentToStoreComment(comment) {
  return {
    comment: comment.comment,
    updatedAt: comment.createDate,
    version: comment.version,
    sender: comment.sender,
    propertyTimeZone: comment.timeZone,
  };
}
function mapStoreThumbsToLocalThumbs(thumbs) {
  return {
    thumbs: thumbs.thumbs,
    createDate: thumbs.updatedAt,
    version: thumbs.version,
  };
}
function mapLocalThumbsToStoreThumbs(thumbs) {
  return {
    thumbs: thumbs.thumbs,
    updatedAt: thumbs.createDate,
    version: thumbs.version,
  };
}

const mapperDefs = {
  comment: {
    propsKey: 'comment',
    mapper: mapStoreCommentToLocalComment,
    backMapper: mapLocalCommentToStoreComment,
    path: props => `${props.imageId}-comment`,
    default: {
      comment: '',
      createDate: undefined,
      sender: {},
      firstName: '',
      lastName: '',
      isNew: true,
      isEdit: false,
      isViewed: false,
    },
  },
  thumbs: {
    propsKey: 'thumbs',
    mapper: mapStoreThumbsToLocalThumbs,
    backMapper: mapLocalThumbsToStoreThumbs,
    path: props => `${props.imageId}-thumbs`,
    default: {
      thumbs: undefined,
      createDate: undefined,
    },
  },
};

function mapCommentToMode(comment) {
  if (comment.isNew) return 'new';
  if (comment.isEdit) return 'edit';
  return 'view';
}

function validateName(firstName = '', lastName = '') {
  const merged = `${firstName} ${lastName}`;
  if (!trim(merged)) return '';
  return merged;
}

class ThumbsUpBar extends React.PureComponent {
  static propTypes = {
    oldJobRequestId: PropTypes.string.isRequired,
    imageId: PropTypes.string.isRequired,
    hasEditPermissions: PropTypes.bool.isRequired,
    errorId: PropTypes.string,
    error: PropTypes.string,
    updateComment: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    createComment: PropTypes.func.isRequired,
    createThumbs: PropTypes.func.isRequired,
    updateThumbs: PropTypes.func.isRequired,
    hasComment: PropTypes.bool.isRequired,
    hasUpOrDown: PropTypes.bool.isRequired,
    thumbs: PropTypes.instanceOf(PhotoFeedback),
    comment: PropTypes.instanceOf(PhotoFeedback),
  };

  state = {
    store: fromJS({}),
    mapper: mapperDefs,
  };

  bubbleStyle = { height: '32px', width: '32px', iconSize: '32px' };

  setEntry(key, value) {
    const finalKey = isArray(key) ? key : [key];
    this.setState(state => ({
      ...state,
      store: state.store.setIn(finalKey, fromJS(value)),
    }));
  }

  setEntryByType(type, key = [], value) {
    this.validateMapper(type);
    const def = this.state.mapper[type];
    this.setEntry([def.path(this.props), ...key], value);
  }

  canEdit(type) {
    const { dashboardAdminMode } = this.props;

    this.validateMapper(type);
    const def = this.state.mapper[type];
    if (dashboardAdminMode) {
      // give access to ri admin for edit and update
      return true;
    }
    if (this.props[def.propsKey] && this.props.hasEditPermissions) {
      return this.props[def.propsKey].senderUserId === this.props.currentUserId;
    }
    return this.props.hasEditPermissions;
  }

  setEntryComment(key = [], value) {
    this.setEntryByType('comment', key, value);
  }

  setEntryThumbs(key = [], value) {
    this.setEntryByType('thumbs', key, value);
  }

  componentWillMount() {
    this.updateStore(this.props);
  }

  updateStore(props) {
    each(this.state.mapper, mapperDef => {
      if (props[mapperDef.propsKey]) {
        this.setEntry(mapperDef.path(props), mapperDef.mapper(props[mapperDef.propsKey]));
      } else if (!this.state.store.get(mapperDef.path(props))) {
        this.setEntry(mapperDef.path(props), mapperDef.default);
      }
      // delete case
      if (!props[mapperDef.propsKey] && this.props[mapperDef.propsKey]) {
        this.setEntryByType(mapperDef.propsKey, [], this.state.mapper[mapperDef.propsKey].default);
      }
    });
  }

  validateMapper(type) {
    if (!this.state.mapper[type]) {
      throw new Error('mapper not defined');
    }
  }

  getValue(type, key) {
    this.validateMapper(type);
    const base = [this.state.mapper[type].path(this.props)];
    if (key) base.push(key);
    return this.state.store.getIn(base);
  }

  isEntityInReduxStore(type) {
    this.validateMapper(type);
    return !!this.props[this.state.mapper[type].propsKey];
  }

  commentsViewable() {
    return this.isEntityInReduxStore('comment') || this.canEdit('comment');
  }

  getValueComment(key) {
    return this.getValue('comment', key);
  }

  getValueThumbs(key) {
    return this.getValue('thumbs', key);
  }

  componentWillReceiveProps(nextProps) {
    this.updateStore(nextProps);
  }

  extendWithIdent(obj) {
    return {
      ...obj,
      pictureUrl: this.props.imageId,
      jobRequestId: this.props.oldJobRequestId,
    };
  }

  onCreateComment = () => {
    this.props.createComment(
      this.extendWithIdent({
        ...this.getValueComment().toJS(),
      }),
    );
    this.setEntryComment(['isNew'], false);
  };

  onChangeComment = e => {
    this.setEntryComment(['comment'], e.target.value);
  };

  onSetEditModeComment = () => {
    this.setEntryComment(['isEdit'], true);
  };

  onUpdateComment = () => {
    this.setEntryComment(['isEdit'], false);
    this.props.updateComment(
      this.props.comment.extend(
        this.state.mapper.comment.backMapper({
          ...this.getValueComment().toJS(),
        }),
      ),
    );
  };

  onDeleteComment = () => {
    this.setEntryComment([], this.state.mapper.comment.default);
    this.props.deleteComment(this.props.comment);
  };

  handleThumbsVote = vote => () => {
    // remove
    if (this.getValueThumbs('thumbs') === vote) {
      this.setEntryThumbs([], this.state.mapper.thumbs.default);
      if (this.props.thumbs) {
        this.props.deleteThumbs(this.props.thumbs);
      }
      return;
    }

    // update
    if (
      this.props.thumbs &&
      this.getValueThumbs('thumbs') // must exist in local state (proof that it was not deleted)
    ) {
      this.setEntryThumbs(['thumbs'], vote); // set new val
      this.props.updateThumbs(
        this.props.thumbs.extend(
          this.state.mapper.thumbs.backMapper({
            ...this.getValueThumbs().toJS(),
            thumbs: vote,
          }),
        ),
      );
      // create
    } else {
      this.setEntryThumbs(['thumbs'], vote); // set new val
      this.props.createThumbs(
        this.extendWithIdent({
          ...this.getValueThumbs().toJS(),
          thumbs: vote,
        }),
      );
    }
  };

  get getError() {
    if (this.props.comment && this.props.comment.uniId === this.props.erroredId) {
      return this.props.error;
    }
    return false;
  }

  renderThumb(which, clickable, key) {
    return (
      <ThumbsUpBubble
        key={key}
        spaceLeft
        onClick={clickable && this.handleThumbsVote(which)}
        isActive={this.getValueThumbs('thumbs') === which}
        {...this.bubbleStyle}
        icon={mapUpDownToIcon(which)}
      />
    );
  }

  renderThumbs(canEdit) {
    // render only the selected
    const currentThumb = this.getValueThumbs('thumbs');
    if (!canEdit && currentThumb) {
      return this.renderThumb(currentThumb, false, 3);
    }
    // render both thumbs
    if (canEdit) {
      return [this.renderThumb('up', true, 1), this.renderThumb('down', true, 2)];
    }
    return null;
  }

  renderComments() {
    return (
      <PopoverDefault
        popupId="comment"
        arrowPosition={{
          vertical: 'bottom',
        }}
        popoverContent={() => (
          <ThumbsUpPopoverContent
            name={validateName(this.getValueComment('firstName'), this.getValueComment('lastName'))}
            error={this.getError}
            isViewed={this.getValueComment('isViewed')}
            date={this.getValueComment('createDate')}
            timeZone={this.getValueComment('timeZone')}
            mode={mapCommentToMode(this.getValueComment().toJS())}
            onChange={this.onChangeComment}
            onDelete={this.onDeleteComment}
            onDone={this.onUpdateComment}
            onSend={this.onCreateComment}
            onEdit={this.onSetEditModeComment}
            hasEditPermissions={this.canEdit('comment')}
            value={this.getValueComment('comment')}
          />
        )}
        label="Excerpt"
        showLabel="Show"
        hideLabel="Hide"
      >
        <span>
          <ThumbsUpBubble
            onClick={noop}
            isActive={!this.getValueComment('isNew')}
            {...this.bubbleStyle}
            icon={Icon.IcComment2}
          />
        </span>
      </PopoverDefault>
    );
  }

  render() {
    const { dashboardAdminMode } = this.props;
    return (
      <div className={cx('thumbsupbar')}>
        <div className={cx('thumbsupbar__inner')}>
          <div
            className={cx('thumbsupbar__pos', 'thumbsupbar__pos--flex', {
              'thumbsbar__comment--right': !!dashboardAdminMode,
            })}
          >
            {this.commentsViewable() && this.renderComments()}
          </div>
          <div className={cx('thumbsupbar__pos', 'thumbsupbar__pos--right', 'thumbsupbar__pos--flex')}>
            {this.renderThumbs(this.canEdit('thumbs'))}
          </div>
        </div>
      </div>
    );
  }
}

// eslint-disable-next-line
const sagaImport = done => done(require('./saga.js').default);

export default compose(
  pageLoad({
    sagas: [{ key: 'thumbsUpBar', import: sagaImport }],
    reducers: [],
    dataLoaders: [],
  }),
  connect(
    (state, props) => {
      const mappedData = selectPhotoGroupedByImageId()(state, props);
      const dashboardAdminMode = selectAdminMode(state, props);
      return {
        currentUserId: selectCurrentUserId()(state),
        hasComment: mappedData.hasComment,
        hasUpOrDown: mappedData.hasUpOrDown,
        thumbs: mappedData.thumbs,
        comment: mappedData.comment,
        erroredId: selectLoadingStateMeta(loadingKey, 'id')(state),
        error: selectLoadingStateMeta(loadingKey, 'error')(state),
        dashboardAdminMode,
      };
    },
    {
      createComment,
      deleteComment,
      updateComment,
      updateThumbs,
      createThumbs,
      deleteThumbs,
    },
  ),
)(ThumbsUpBar);
