import gql from 'graphql-tag';

export const QUERY_GET_STRIPE_STATUS = gql`
  query hostStripeStatus {
    hostStripeStatus {
      userId
      active
      defaultPaymentMethodExists
    }
  }
`;

export const QUERY_GET_STRIPE_CUSTOMER = gql`
  query getStripeCustomerQuery {
    stripeCustomer {
      id
      description
      email
      defaultSource
      defaultPaymentMethod
      cards
      bankAccounts
    }
  }
`;
