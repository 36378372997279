import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import t from '@properly/localization';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { featureFlag } from '@properly/config';
import classNames from 'classnames/bind';
import HasPermission from '../../../../hoc/HasPermission';
import SettingsSidebarContainer from './SettingsSidebarContainer';
import { selectIsAnyFeatureFlagEnabledForUser } from '../state/SettingsSelectors';
import { MasterDetail, MasterDetailMaster, MasterDetailDetail } from '../../../../components/MasterDetail';
import { PagePadding, PageFlexOuter, PageFlexInner, PageTopBar } from '../../../../components/PageElements';
import styles from '../components/settingsStyles.module.css';
import MainWrapper from '../../../../containers/MainWrapper';
import { ROUTES } from '../../../../paths';
import { redirectToDownloadAppPage } from '../../../branding/BrandingActions';
import { selectIsPartnerDomain } from '../../../branding/BrandingSelector';
import { selectIfProMarketPropertyExists } from '../../../../selectors/globalSelector';

const SETTINGS_CONNECTEDACCOUNTS = 'settings.connect_accounts';
const SETTINGS_COMPANY = 'settings.company';
const SETTINGS_PARTNERS = 'settings.partner';
const SETTINGS_PAYMENT_METHODS = 'settings.payment_methods';
const SETTINGS_IOT_DEVICES = 'settings.iot_devices';
const SETTINGS_SUBSCRIPTION = 'settings.subscription';
const SETTINGS_RECEIPTS = 'settings.receipts';

const settingsData = props => [
  {
    key: SETTINGS_CONNECTEDACCOUNTS,
    title: t(SETTINGS_CONNECTEDACCOUNTS),
    route: ROUTES.settingsConnections,
    routes: [ROUTES.settingsConnections],
    showConnectionIssue: true,
    enabled: true,
  },
  {
    key: SETTINGS_COMPANY,
    title: t(SETTINGS_COMPANY),
    route: ROUTES.settingsCompany,
    routes: [ROUTES.settingsCompany],
    enabled: true,
  },
  {
    key: SETTINGS_PARTNERS,
    title: t(SETTINGS_PARTNERS),
    route: ROUTES.settingsPartner,
    routes: [ROUTES.settingsPartner, ROUTES.settingsPartnerShared],
    enabled: true,
  },
  {
    key: SETTINGS_PAYMENT_METHODS,
    title: t(SETTINGS_PAYMENT_METHODS),
    route: ROUTES.settingsPaymentMethods,
    routes: [ROUTES.settingsPaymentMethods],
    enabled: props.useMarketplace,
  },
  {
    key: SETTINGS_IOT_DEVICES,
    title: t(SETTINGS_IOT_DEVICES),
    route: ROUTES.settingsIotDevices,
    routes: [ROUTES.settingsIotDevices],
    enabled: true,
  },
  {
    key: SETTINGS_SUBSCRIPTION,
    title: t(SETTINGS_SUBSCRIPTION),
    route: ROUTES.settingsSubscription,
    routes: [ROUTES.settingsSubscription],
    enabled: true,
  },
  {
    key: SETTINGS_RECEIPTS,
    title: t(SETTINGS_RECEIPTS),
    route: ROUTES.settingsReceipts,
    routes: [ROUTES.settingsReceipts],
    enabled: true,
  },
];

const cx = classNames.bind(styles);
class SettingsContainer extends PureComponent {
  static contextTypes = {
    router: PropTypes.shape({}).isRequired,
  };

  static propTypes = {
    loading: PropTypes.bool,
    location: PropTypes.shape({}).isRequired,
    children: PropTypes.node.isRequired,
    disableWrapper: PropTypes.bool,
    useMarketplace: PropTypes.bool,
    hideMobilePopover: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
    disableWrapper: false,
    useMarketplace: false,
    hideMobilePopover: false,
  };

  componentDidMount() {
    const {
      isPartnerDomain,
      location: { action },
    } = this.props;
    if (isPartnerDomain && (action === 'REPLACE' || action === 'POP')) {
      this.props.redirectToDownloadAppPage();
    }
  }

  mapAccess(canSeeFullNav, canSeeCompany, canSeePartner) {
    let permittedSections = [];

    if (canSeeFullNav) {
      permittedSections = [
        SETTINGS_CONNECTEDACCOUNTS,
        SETTINGS_PAYMENT_METHODS,
        SETTINGS_IOT_DEVICES,
        SETTINGS_SUBSCRIPTION,
        SETTINGS_RECEIPTS,
      ];

      if (canSeeCompany) {
        permittedSections.push(SETTINGS_COMPANY);
      }

      if (canSeePartner) {
        permittedSections.push(SETTINGS_PARTNERS);
      }
    }

    return { permittedSections };
  }

  render() {
    const { loading, disableWrapper, location, hideMobilePopover, children } = this.props;
    const settings = settingsData(this.props);
    return (
      <MainWrapper
        overWriteLoading={loading}
        currentPage="settings"
        hideMobilePopover={hideMobilePopover}
        disableWrapper={disableWrapper}
      >
        <div className={cx('settings__bg')}>
          {disableWrapper && !loading && children}
          {!disableWrapper && (
            <MasterDetail>
              <MasterDetailMaster>
                <HasPermission
                  hasAccessFormatter={hasAccessRes => ({
                    canSeeFullNav: hasAccessRes.canSeeFullNav,
                    settingsUandP: hasAccessRes.settingsUandP,
                  })}
                  render={accessObj => {
                    const { permittedSections } = this.mapAccess(
                      accessObj.canSeeFullNav,
                      accessObj.settingsUandP.companyModule,
                      accessObj.settingsUandP.partnerModule,
                    );
                    return (
                      <SettingsSidebarContainer
                        settings={settings}
                        permittedSections={permittedSections}
                        activeRoute={location.pathname}
                      />
                    );
                  }}
                />
              </MasterDetailMaster>
              <MasterDetailDetail grey>
                <PageFlexOuter>
                  <PageTopBar />
                  <PageFlexInner>
                    <PagePadding variation="property" type="absolute-scroll">
                      {!loading && children}
                    </PagePadding>
                  </PageFlexInner>
                </PageFlexOuter>
              </MasterDetailDetail>
            </MasterDetail>
          )}
        </div>
      </MainWrapper>
    );
  }
}

const mapStateToProps = (state, props) => {
  // marketplace
  const isAnyMarketplaceFeatureFlagEnabledForUser = selectIsAnyFeatureFlagEnabledForUser([
    featureFlag.FEATURE_FLAG_MARKETPLACE,
  ])(state, props);
  // we remove this temporary flag FEATURE_MARKETPLACE_USER_HOST , which was added to distinguish between performance pay and marketplace
  // in future if we want to enable such distinction we can use this flag
  // const isAnyMarketplaceFeatureEnabledForUser = selectIsAnyFeatureEnabledForUser([FEATURE_MARKETPLACE_USER_HOST])(
  //   state,
  //   props,
  // );
  const isProMarketPropertyExists = selectIfProMarketPropertyExists()(state);

  const useMarketplace = isAnyMarketplaceFeatureFlagEnabledForUser && isProMarketPropertyExists;

  return {
    useMarketplace,
    isPartnerDomain: selectIsPartnerDomain()(state),
  };
};

export default compose(
  connect(mapStateToProps, {
    redirectToDownloadAppPage,
  }),
)(SettingsContainer);
