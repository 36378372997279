import forOwn from 'lodash/forOwn';
import lodashResult from 'lodash/result';
import { changeHelper } from '../helper/herbert';

export default class PhotoFeedback {
  constructor(
    id,
    version,
    jobRequestId,
    pictureUrl,
    type,
    viewed,
    comment,
    thumbs,
    sender,
    updatedAt,
    propertyTimeZone,
    clientId,
  ) {
    this.id = id;
    this.version = version;
    this.jobRequestId = jobRequestId;
    this.pictureUrl = pictureUrl;
    this.type = type;
    this.viewed = viewed;
    this.comment = comment;
    this.thumbs = thumbs;
    this.sender = sender;
    // sender
    //   {
    //     userId: String,
    //     firstName: String,
    //     lastName: String
    // }
    this.propertyTimeZone = propertyTimeZone;
    this.updatedAt = updatedAt;
    this.clientId = clientId;
  }

  setData(obj) {
    this.getOwnProps(obj, this);
  }

  extend(obj) {
    return changeHelper(PhotoFeedback, this, obj);
  }

  getData() {
    return this.getOwnProps(this);
  }

  getOwnProps(scope, onScope) {
    const obj = onScope || {};
    forOwn(scope, (value, key) => {
      obj[key] = value;
    });
    return obj;
  }

  get senderUserId() {
    return lodashResult(this.sender, ['userId']);
  }

  get uniId() {
    return `${this.jobRequestId}-${this.pictureUrl}-${this.id}`;
  }

  get picUid() {
    return `${this.jobRequestId}-${this.pictureUrl}`;
  }

  get isComment() {
    return this.type === 'comment';
  }

  get isThumbs() {
    return this.type === 'thumbs';
  }

  get isViewed() {
    return this.viewed === true;
  }

  get isThumbsUp() {
    return this.type === 'thumbs' && this.thumbs === 'up';
  }

  get isThumbsDown() {
    return this.type === 'thumbs' && this.thumbs === 'down';
  }
}
