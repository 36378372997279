import log from 'loglevel';
import locale from 'browser-locale';
import { client as apolloClient } from '../../config/apollo';
import { QUERY_GET_CUSTOM_BRANDING, QUERY_GET_CUSTOM_BRANDING_LANDING_PAGE } from '../queries/customBranding';

export const getCustomBrandingConfig = async id => {
  const { name } = getCustomBrandingConfig;
  try {
    const customBranding = await apolloClient.query({
      query: QUERY_GET_CUSTOM_BRANDING,
      variables: {
        id,
        languageCode: locale(),
        device: 'WEB',
      },
      fetchPolicy: 'no-cache',
    });
    return customBranding.data.CustomBranding;
  } catch (error) {
    log.error(name, error);
    error.apiError = true;
    return error;
  }
};

export const getCustomBrandindLandingPage = async id => {
  try {
    const customBrandindLandingPage = await apolloClient.query({
      query: QUERY_GET_CUSTOM_BRANDING_LANDING_PAGE,
      variables: {
        id,
        languageCode: locale(),
        device: 'WEB',
      },
      fetchPolicy: 'no-cache',
    });
    return customBrandindLandingPage.data.CustomBranding;
  } catch (error) {
    log.error('getOnBoardingLandingPage - error : ', error);
    return error;
  }
};
