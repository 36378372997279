import { fork, put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import keyBy from 'lodash/keyBy';
import log from 'loglevel';
import {
  CHECKLIST_LOAD_INDEPENDENT_CHECKLISTS_REQUEST,
  CHECKLIST_LOAD_SINGLE_CHECKLIST_REQUEST,
  CHECKLIST_LOAD_PUBLISHED_CHECKLISTS_REQUEST,
  CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_REQUEST,
  CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_SUCCESS,
  CHECKLIST_REMOVE_CHECKLIST_FROM_LIBRARY_REQUEST,
  CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_REQUEST,
  CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_SUCCESS,
  SKILLS_LOAD_REQUEST,
  SKILL_LOAD_REQUEST,
} from './library.constants';
import {
  loadIndependentChecklistsSuccess,
  loadIndependentChecklistsFailure,
  loadPublishedChecklistsSuccess,
  loadPublishedChecklistsFailure,
  loadChecklistFailure,
  loadChecklistSuccess,
  cloneChecklistIntoPropertiesFailure,
  cloneChecklistIntoPropertiesSuccess,
  removeChecklistFromLibrarySuccess,
  removeChecklistFromLibraryFailure,
  createNewChecklistInLibrarySuccess,
  createNewChecklistInLibraryFailure,
  loadSkillsSuccess,
  loadSkillsFailure,
  loadSkillFailure,
  loadSkillSuccess,
  setSkillsAccess,
} from './library.actions';
import {
  getChecklists,
  getPublishedChecklists,
  getPublishedChecklistInstructions,
  getPublishedChecklist,
  cloneChecklistIntoProperties,
  removeChecklistFromLibrary,
  createNewChecklistInLibrary,
} from './library.api';
import { getIndependentChecklist, getIndependentChecklistInstructions } from '../../data.http.checklists';
import { updateCollectionEntryBatch } from '../../../../actions/globalActions';
import { ROUTES } from '../../../../paths';
import { getSkill, getSkills } from '../../../../graphql/api/skills';
import { canUserUseFeature } from '../../../../sagas/global';
import { PAID_FEATURES } from '../../../../dataConstants';

function* loadIndependentChecklistContent() {
  try {
    const checklists = yield getChecklists();
    yield put(loadIndependentChecklistsSuccess(checklists));

    yield addChecklistsToGlobalState(checklists);
    yield addChecklistInstructionsToGlobalState(checklists.map(checklist => checklist.jobInstructions));
  } catch (err) {
    log.error('Error loading loadIndependentChecklistContent', err);
    yield put(loadIndependentChecklistsFailure(err));
  }
}

function* loadPublishedChecklistContent() {
  try {
    const checklists = yield getPublishedChecklists();
    yield put(loadPublishedChecklistsSuccess(checklists));

    yield addChecklistsToGlobalState(checklists);
    yield addChecklistInstructionsToGlobalState(checklists.map(checklist => checklist.jobInstructions));
  } catch (err) {
    log.error('Error loading loadPublishedContent', err);
    yield put(loadPublishedChecklistsFailure(err));
  }
}

function* loadPublished({ checklistId, checklistInstructionsId, published }) {
  try {
    const [checklist, checklistInstructions] = yield published
      ? [getPublishedChecklist(checklistId), getPublishedChecklistInstructions(checklistInstructionsId)]
      : [getIndependentChecklist(checklistId), getIndependentChecklistInstructions(checklistInstructionsId)];

    yield put(loadChecklistSuccess(checklistId, checklistInstructionsId, checklist, checklistInstructions, published));

    yield addChecklistsToGlobalState([checklist]);
    yield addChecklistInstructionsToGlobalState([checklistInstructions]);
  } catch (err) {
    log.error('Error loading loadPublished', err);
    yield put(loadChecklistFailure(err));
  }
}

function* cloneChecklistIntoPropertiesRequest({ propertyIds, checklistId }) {
  try {
    yield cloneChecklistIntoProperties(propertyIds, checklistId);
    yield put(cloneChecklistIntoPropertiesSuccess(checklistId));
  } catch (err) {
    yield put(cloneChecklistIntoPropertiesFailure(checklistId, err));
  }
}

function* redirectOnChecklistSuccess() {
  yield put(push(ROUTES.library));
}

function* removeChecklistFromLibraryRequest({ checklistId }) {
  try {
    yield removeChecklistFromLibrary(checklistId);
    yield put(removeChecklistFromLibrarySuccess(checklistId));
    // TODO: Do we need to remove from the global state?
  } catch (error) {
    yield put(removeChecklistFromLibraryFailure(checklistId, error));
  }
}

function* createNewChecklistInLibraryRequest() {
  try {
    const checklist = yield createNewChecklistInLibrary();
    const { objectId: checklistId, jobInstructions: checklistInstructions } = checklist || {};
    const { objectId: checklistInstructionsId } = checklistInstructions || {};
    yield put(
      createNewChecklistInLibrarySuccess(checklistId, checklistInstructionsId, checklist, checklistInstructions),
    );
    yield addChecklistsToGlobalState([checklist]);
    yield addChecklistInstructionsToGlobalState([checklistInstructions]);
  } catch (error) {
    yield put(createNewChecklistInLibraryFailure(error));
  }
}

function* redirectOnNewChecklistSuccess({ checklistId, checklistInstructionsId }) {
  yield put(push(ROUTES.libraryChecklist(checklistId, checklistInstructionsId)));
}

function* addChecklistsToGlobalState(checklists) {
  // TODO: do we also need to add job instructions?
  // For now we will also integrate our checklists them with the global state
  yield put(updateCollectionEntryBatch('jobs', keyBy(checklists, 'objectId'), true));
}

function* addChecklistInstructionsToGlobalState(checklistInstructions) {
  yield put(updateCollectionEntryBatch('jobInstructions', keyBy(checklistInstructions, 'objectId'), true));
}

function* addSkillsToGlobalState(skills) {
  yield put(updateCollectionEntryBatch('skills', keyBy(skills, 'skillId')));
}

function* fetchSkillsSaga() {
  try {
    const res = yield canUserUseFeature(PAID_FEATURES.skillsLibrary);
    if (res === 2) {
      yield put(setSkillsAccess(false));
      return;
    }
    let data;
    let skills = [];
    let offset = 0;
    const limit = 100;
    do {
      data = yield getSkills(limit, offset);
      if (data.apiError) {
        throw data;
      }
      skills = skills.concat(data);
      offset += limit;
    } while (data.length === limit);

    yield put(loadSkillsSuccess(skills));
    yield addSkillsToGlobalState(skills);
  } catch (error) {
    log.error(`[fetchSkillsSaga] ${error}`);
    yield put(loadSkillsFailure(error));
  }
}

function* fetchSpecificSkillSaga({ skillId }) {
  try {
    const skill = yield getSkill(skillId);
    if (skill.apiError) {
      throw skill;
    }
    yield put(loadSkillSuccess(skillId, skill));
    yield addSkillsToGlobalState([skill]);
  } catch (error) {
    log.error(`[fetchSpecificSkillSaga] ${error}`);
    yield put(loadSkillFailure(error));
  }
}

export default function* saga() {
  yield fork(takeLatest, CHECKLIST_LOAD_INDEPENDENT_CHECKLISTS_REQUEST, loadIndependentChecklistContent);
  yield fork(takeLatest, CHECKLIST_LOAD_PUBLISHED_CHECKLISTS_REQUEST, loadPublishedChecklistContent);
  yield fork(takeLatest, CHECKLIST_LOAD_SINGLE_CHECKLIST_REQUEST, loadPublished);
  yield fork(takeLatest, CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_REQUEST, cloneChecklistIntoPropertiesRequest);
  yield fork(takeLatest, CHECKLIST_CLONE_CHECKLIST_INTO_PROPERTIES_SUCCESS, redirectOnChecklistSuccess);
  yield fork(takeLatest, CHECKLIST_REMOVE_CHECKLIST_FROM_LIBRARY_REQUEST, removeChecklistFromLibraryRequest);
  yield fork(takeLatest, CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_REQUEST, createNewChecklistInLibraryRequest);
  yield fork(takeLatest, CHECKLIST_CREATE_NEW_CHECKLIST_IN_LIBRARY_SUCCESS, redirectOnNewChecklistSuccess);
  yield fork(takeLatest, SKILLS_LOAD_REQUEST, fetchSkillsSaga);
  yield fork(takeLatest, SKILL_LOAD_REQUEST, fetchSpecificSkillSaga);
}
