import React, { PureComponent } from 'react';
import t from '@properly/localization';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Icon } from '@properly/components';
import log from 'loglevel';
import lodashResult from 'lodash/result';
import each from 'lodash/each';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import classNames from 'classnames/bind';
import styles from './pricingStyles.module.css';
import { getNotificationStorage, setNotificationStorage } from '../../../helper/notification';
import { openPricingModalSaga } from './PricingActions';
import { getTeamsSubscriptionInfo } from '../data.http.team';
import { selectHasInvitedPartners } from '../../../selectors/globalSelector';

const cx = classNames.bind(styles);

class PricingCalendarNotificationContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.messages = [
      {
        id: 'exceed',
        closeable: true,
        meta: {},
        text: subscription => (
          <div>
            {t('pricing.exceed_limit', {
              plan: subscription.currentPlanTitle,
            })}{' '}
            <span onClick={this.handleLimit}>{t('pricing.exceed_limit2')}</span> {t('pricing.exceed_limit3')}
          </div>
        ),
        showIf: (subscription, user) =>
          subscription.allowedJobsMonth > 0 &&
          subscription.jobsUsedMonth >= subscription.allowedJobsMonth &&
          user.isStandalone,
      },
      {
        id: 'expire',
        closeable: true,
        meta: {},
        text: subscription => (
          <div>
            {t('pricing.trail_expire', {
              days: this.getDays(subscription.expiration),
            })}{' '}
            <span onClick={this.handleExpire}>{t('pricing.trail_expire2')}</span> {t('pricing.trail_expire3')}
          </div>
        ),
        showIf: (subscription, user) =>
          subscription.currentPlanId === 'trial' &&
          this.getDays(subscription.expiration) <= 7 &&
          this.getDays(subscription.expiration) >= 0 &&
          user.isStandaloneUser,
      },
      {
        id: 'expired',
        closeable: true,
        meta: {},
        text: subscription => (
          <div>
            {t('pricing.trail_expired', {
              days: this.getDays(subscription.expiration),
            })}{' '}
            <span onClick={this.handleExpire}>{t('pricing.trail_expired2')}</span> {t('pricing.trail_expired3')}
          </div>
        ),
        showIf: (subscription, user) =>
          subscription.currentPlanId === 'trial' && this.getDays(subscription.expiration) < 0 && user.isStandaloneUser,
      },
      {
        id: 'expired',
        closeable: false,
        meta: {},
        text: subscription => (
          <div>
            {t('pricing.expired_partner', {
              days: this.getDays(subscription.removePartnersTrialAccessBy),
              btn: <span onClick={this.handleExpire}>{t('pricing.upgrade_now')}</span>,
            })}
          </div>
        ),
        showIf: (subscription, user, meta, propsImport) =>
          subscription.removePartnersTrialAccessBy && user.isStandaloneUser && propsImport.hasInvitedPartners,
      },
      {
        id: 'expired_admin',
        closeable: true,
        repeatable: true,
        meta: {},
        text: (subscription, meta) => (
          <div>
            {t('pricing.expired_partner_admin', {
              name: lodashResult(meta, ['userData', 'fullName']),
              days: this.getDays(meta.removePartnersTrialAccessBy),
            })}{' '}
          </div>
        ),
        showIf: (subscription, user, meta) => meta.removePartnersTrialAccessBy,
      },
    ];
    this.state = { subscriptions: undefined };
  }

  getDays = expiration => moment(expiration).diff(moment(), 'days');

  handleExpire = () => {
    this.props.openPricingModalSaga('upgrade', {});
  };

  handleLimit = () => {
    this.props.openPricingModalSaga('upgrade', {});
  };

  renderMessage({ onClose, txt, id }) {
    return (
      <div key={id} className={cx('pricing__messagered')}>
        {onClose && <Icon.IcCloseWhite2 onClick={onClose(id)} className={cx('pricing__message-close')} />}
        <div className={cx('pricing__message-txt')}>{txt}</div>
      </div>
    );
  }

  renderMessages(messages) {
    return <div className={cx('pricing__messagered-wrap')}>{map(messages, msg => this.renderMessage(msg))}</div>;
  }

  get enhancedMessages() {
    return reduce(
      this.messages,
      (acc, item) => {
        if (item.repeatable) {
          if (this.state.subscriptions) {
            each(this.state.subscriptions, subscriptionData => {
              acc.push({
                ...item,
                id: `${item.id}-${subscriptionData.userId}`,
                meta: {
                  ...item.meta,
                  userData: subscriptionData.userData,
                  removePartnersTrialAccessBy: subscriptionData.removePartnersTrialAccessBy,
                },
              });
            });
          }
        }
        if (!item.repeatable) {
          acc.push(item);
        }
        return acc;
      },
      [],
    );
  }

  get subscription() {
    if (!this.props.currentUser.user) return undefined;
    return this.props.currentUser.user.subscription;
  }

  get showAbleMessages() {
    const storage = getNotificationStorage();
    const hasItem = id => storage && storage[id];

    return reduce(
      this.enhancedMessages,
      (total, item) => {
        if (item.showIf(this.subscription, this.props.currentUser.user, item.meta, this.props) && !hasItem(item.id)) {
          return [
            ...total,
            {
              ...item,
              txt: item.text(this.subscription, item.meta),
              onClose: item.closeable && this.handleClose,
            },
          ];
        }
        return total;
      },
      [],
    );
  }

  handleClose = id => () => {
    setNotificationStorage({ [id]: true });
    this.forceUpdate();
  };

  componentDidMount() {
    getTeamsSubscriptionInfo()
      .then(res => {
        if (!this.unmounted) {
          this.setState({ subscriptions: res });
        }
      })
      .catch(error => log.error('Error while getting team subscription info', error));
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  render() {
    if (!this.subscription || this.showAbleMessages.length === 0) {
      return null;
    }

    return this.renderMessages(this.showAbleMessages);
  }
}

function mapStateToProps() {
  return state => ({
    currentUser: state.currentUser,
    hasInvitedPartners: selectHasInvitedPartners(state),
  });
}

export default connect(
  mapStateToProps,
  {
    openPricingModalSaga,
  },
)(PricingCalendarNotificationContainer);
