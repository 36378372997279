import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import ImageRender from './imageRender';

const styles = theme => ({
  senderCard: {
    alignSelf: 'flex-end',
    width: '90%',
    border: '1px solid #ececec',
    marginTop: '10px',
    borderRadius: '5px',
    boxShadow: 'none',
  },
  receiverCard: {
    alignSelf: 'flex-start',
    width: '90%',
    backgroundColor: '#ececec',
    marginTop: '10px',
    borderRadius: '5px',
    boxShadow: 'none',
  },
  floatRight: {
    fontSize: theme.typography.pxToRem(10),
    marginTop: '5px',
    float: 'right',
  },
  floatLeft: {
    fontSize: theme.typography.pxToRem(10),
    marginTop: '5px',
    width: '38%',
    float: 'left',
  },
  title: {
    fontSize: 14,
  },
  commentFontSize: {
    fontSize: theme.typography.pxToRem(15),
    wordWrap: 'break-word',
  },
  content: {
    padding: '10px 10px 24px',
  },
});

function CommentCard(props) {
  const capitalizeFirstLetter = text => text.charAt(0).toUpperCase() + text.slice(1);
  const { classes, comment, cssStyle } = props;
  const showImageDiv = e => e && <ImageRender image={comment.pictureIdentifier} />;
  return (
    <div className={cssStyle === 'senderCard' ? classes.senderCard : classes.receiverCard}>
      <div className={classes.content}>
        {showImageDiv(comment.pictureIdentifier)}
        <div className={classes.commentFontSize}>{comment.comment}</div>
        <div className={classes.floatLeft}>
          {capitalizeFirstLetter(comment.senderFirstname)} {capitalizeFirstLetter(comment.senderLastname)}
        </div>
        <div className={classes.floatRight}>{moment(comment.time).format('ddd, MMM Do, h:mm a')}</div>
      </div>
    </div>
  );
}

export default withStyles(styles)(CommentCard);

CommentCard.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  comment: PropTypes.shape({}).isRequired,
  cssStyle: PropTypes.string.isRequired,
};
