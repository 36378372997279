import { createSelector } from 'reselect';

const toJS = state => state && state.toJS();

export const selectChecklists = () => state => state.checklists;
export const selectChecklistsJS = () => state => state.checklists.toJS();

export const selectJobInstructions = () => createSelector(selectChecklists(), state => state.get('jobInstructions'));

export const selectJobInstruction = id =>
  createSelector(selectChecklists(), state => state.getIn(['jobInstructions', id]));

export const selectCloneChecklist = createSelector(selectChecklists(), state => state.getIn(['cloneChecklist']));
export const selectCloneChecklistJS = createSelector(selectCloneChecklist, toJS);

export const selectCurrentJobId = () => createSelector(selectChecklists(), state => state.getIn(['currentJobId']));

export const selectCurrentState = () => createSelector(selectChecklists(), state => state.get('currentState'));
export const selectLastTimeUpdate = () => createSelector(selectChecklists(), state => state.get('lastTimeUpdate'));
export const selectTileOverview = () => createSelector(selectChecklists(), state => state.get('tileOverview'));
export const selectLoadingStates = () => createSelector(selectChecklists(), state => state.get('loadingStates'));
export const selectUploadState = () => createSelector(selectChecklists(), state => state.get('uploadView').toJS());
export const selectTextTemplates = () =>
  createSelector(selectChecklists(), state => state.get('textTemplatesView').toJS());
export const selectActiveChecklist = () => createSelector(selectChecklists(), state => state.get('activeChecklist'));
export const selectModalData = () => createSelector(selectChecklists(), state => state.get('modalChecklist'));
