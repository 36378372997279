import React from 'react';
import t from '@properly/localization';
import classNames from 'classnames/bind';
import { SpaceXLarge, Input } from '@properly/components';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const JobPriceFormFixed = ({ children, finalPrice, setPrice, offeredPriceLimit, parseNumber, finalCurrency }) => (
  <>
    <SpaceXLarge />
    <div className={cx('jobprice__centerflex')}>
      <div>
        <Input
          currency={finalCurrency}
          value={finalPrice !== 0 ? finalPrice : ''}
          autoFocus
          data-key="jobrequestsend_price"
          placeholder={t('job_request.enter_price')}
          styleOverwrite={{ backgroundColor: 'white' }}
          onKeyDown={e => e.key === '.' && e.preventDefault()}
          onChange={e => {
            if (e.target.value <= offeredPriceLimit) return parseNumber(e.target.value, setPrice(e));
            return null;
          }}
          isFirst
          isLast
          type="price"
        />

        {children}
      </div>
    </div>
  </>
);

export default JobPriceFormFixed;
