import React, { Component } from 'react';
import { connect } from 'react-redux';
import log from 'loglevel';
import { LoadingSplash } from '@properly/components';
import { shutdownIntercom } from '@properly/common';
import { loginUserWithToken, loginSetOverride } from './LoginSignUpActions';
import { setTracking } from '../../actions/trackingService';
import { setDashBoardAdminDetails } from '../../actions/globalActions';
import { selectAdminMode } from '../desktopApp/settings/state/SettingsSelectors';

class LoginAsComponent extends Component {
  componentDidMount() {
    shutdownIntercom(true);
    setTracking(false);

    log.info('LoginAsComponent', this.props);

    const { params, isEntry, location, dashboardAdminMode } = this.props;

    const { query, search } = location || {};
    const { id, redirectRoute, splat } = params || {};
    const { sessionToken } = query || {};

    const token = id || sessionToken;

    const isValidToken = !!token && token !== 'undefined' && token !== 'null';

    if (!isEntry && isValidToken) {
      // set dashboard admin user and session token to state
      if (dashboardAdminMode) {
        const { setDashBoardAdminDetails } = this.props; // eslint-disable-line no-shadow
        setDashBoardAdminDetails(query.auser, query.asession);
      }
      const redirect = splat ? redirectRoute + splat : redirectRoute;
      const redirectWithQuery = search && redirect ? redirect + search : redirect;
      this.props.loginUserWithToken(token, redirectWithQuery);
    }
  }

  componentWillUnmount() {
    this.props.loginSetOverride(false);
  }

  render() {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <LoadingSplash />
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const dashboardAdminMode = selectAdminMode(state, props);
  return { dashboardAdminMode };
}
export default connect(mapStateToProps, { loginUserWithToken, setDashBoardAdminDetails, loginSetOverride })(
  LoginAsComponent,
);
