import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { reCaptcha, error as errord } from '@properly/config';
import t from '@properly/localization';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'recompose';
import filter from 'lodash/filter';
import extend from 'lodash/extend';
import { Input, Button, SpaceTiny, SpaceSmall, LoadingSpinner, WithReCaptcha } from '@properly/components';
import {
  isValidEmail,
  isValidPhone,
  isValidPassword,
  countryPhoneOptions,
  splitCountryCode,
  getCurrentCountryByIp,
  countryToDialCode,
} from '@properly/common';
import styles from './index.module.css';
import * as selectorsGlobal from '../../selectors/globalSelector';
import { TodosTextEle } from '../../components/TodosElements';
import { selectIsPartnerDomain } from '../../modules/branding/BrandingSelector';

const { RECAPTCHA_VERSION_V2 } = reCaptcha;
const cx = classNames.bind(styles);

function validateState(state, props) {
  const { isPartnerDomain = false } = props;
  const errors = {};
  const remoteErrors = state.errors || [];
  const allFields = ['email', 'password', 'firstName', 'lastName', ...(isPartnerDomain ? ['phoneRegionalNumber'] : [])];

  if (remoteErrors.includes(errord.UNKNOWN)) {
    extend(errors, allFields);
    errors._error = 'signup.error.cloud_code_error'; // eslint-disable-line
    return errors;
  }
  if (remoteErrors.includes(errord.FAILED_RECAPTCHA_VERIFICATION)) {
    errors._error = 'signup.error.failed_recaptcha_verification'; // eslint-disable-line
    return errors;
  }

  if (remoteErrors.includes(errord.PHONE_NOT_UNIQUE)) {
    errors.phoneRegionalNumber = true;
    errors._error = 'signup.error.phone_registered'; // eslint-disable-line
    return errors;
  }

  if (remoteErrors.includes(errord.EMAIL_NOT_UNIQUE)) {
    errors.email = true;
    errors._error = 'signup.error.email_registered'; // eslint-disable-line
    return errors;
  }

  if (!isValidPassword(state.password)) {
    errors.password = true;
    errors._error = 'newpassword.password_short'; // eslint-disable-line
  }

  if (!state.firstName?.trim()) {
    errors.firstName = true;
    errors._error = 'signup.error.please_fill_all_fields'; // eslint-disable-line
  }

  if (!state.lastName?.trim()) {
    errors.lastName = true;
    errors._error = 'signup.error.please_fill_all_fields'; // eslint-disable-line
  }

  if (!state.email?.trim()) {
    errors.email = true;
    errors._error = 'signup.error.please_fill_all_fields'; // eslint-disable-line
  }

  if (!state.password) {
    errors.password = true;
    errors._error = 'signup.error.please_fill_all_fields'; // eslint-disable-line
  }

  if (state?.email?.length > 0 && !isValidEmail(state.email)) {
    errors.email = true;
    errors._error = 'signup.error.email_invalid'; // eslint-disable-line
    return errors;
  }

  if (state?.phoneRegionalNumber?.length > 0 && !isValidPhone(state.phoneRegionalNumber) && isPartnerDomain) {
    errors.phoneRegionalNumber = true;
    errors._error = 'signup.error.phone_invalid'; // eslint-disable-line
    return errors;
  }

  if (filter(errors, val => !!val).length > 3) {
    errors._error = 'signup.error.please_fill_all_fields'; // eslint-disable-line
  }
  return errors;
}

class SignupForm extends Component {
  static propsTypes = {
    preSetEmail: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    config: PropTypes.shape({}).isRequired,
    openLink: PropTypes.func.isRequired,
    reCaptchaToken: PropTypes.string,
    reCaptchaVersion: PropTypes.string,
    reference: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    hostSignUpErrorReset: PropTypes.func.isRequired,
  };

  state = {
    dialCode: '',
    submitDisabled: false,
  };

  componentWillMount() {
    this.checkPreSet(this.props);
    this.mounted = true;
    this.getDefaultDialCode();
  }

  componentWillReceiveProps(nextProps) {
    this.checkPreSet(nextProps);

    const { reCaptchaToken, hostSignUpErrorReset, email, phoneRegionalNumber, remoteErrors = [] } = this.props;
    // prettier-ignore
    const {
      reCaptchaToken: nextReCaptchaToken,
      reCaptchaVersion: nextReCaptchaVersion,
      email: nextEmail,
      phoneRegionalNumber: nextPhoneRegionalNumber
    } = nextProps;

    if (remoteErrors.includes(errord.EMAIL_NOT_UNIQUE)) {
      if (nextEmail !== email) {
        hostSignUpErrorReset();
      }
    }

    if (remoteErrors.includes(errord.PHONE_NOT_UNIQUE)) {
      if (nextPhoneRegionalNumber !== phoneRegionalNumber) {
        hostSignUpErrorReset();
      }
    }

    if (remoteErrors.includes(errord.FAILED_RECAPTCHA_VERIFICATION)) {
      if (nextReCaptchaVersion === RECAPTCHA_VERSION_V2) {
        if (nextReCaptchaToken !== reCaptchaToken) {
          // The user has manually generated a reCaptcha v2 token
          // by clicking the checkbox, so we can reset the previous error
          hostSignUpErrorReset();
        }
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  checkPreSet = props => {
    const { autofill, untouch, emailIsSet } = this.props;
    if (props.reCaptchaToken) {
      autofill('reCaptchaToken', props.reCaptchaToken);
    }
    if (props.reCaptchaVersion) {
      autofill('reCaptchaVersion', props.reCaptchaVersion);
    }
    if (props.preSetEmail && !emailIsSet) {
      autofill('email', props.preSetEmail);
      untouch();
    }
    const remoteErrors = props.errors || [];
    autofill('errors', remoteErrors);
  };

  openLink = key => () => {
    const { config, openLink } = this.props;
    const url = config[key];
    openLink(url);
  };

  getDefaultDialCode = () =>
    getCurrentCountryByIp().then(country => {
      if (this.mounted) {
        this.setState(state => ({
          ...state,
          dialCode: countryToDialCode(country, null),
        }));
      }

      return true;
    });

  handleDialCodeChange = e =>
    this.setState({
      dialCode: e.target.value,
    });

  renderTosMsg() {
    return t('signup.form.tos_confirmation', {
      button: t('signup.form.cta'),
      tos: (
        <TodosTextEle
          componentClass="span"
          modifier={['txt--l7', 'color--primary', 'text--left', 'link--clickable']}
          onClick={this.openLink('tosUrl')}
        >
          {t('signup.form.tos')}
        </TodosTextEle>
      ),
      privacy: (
        <TodosTextEle
          componentClass="span"
          modifier={['txt--l7', 'color--primary', 'text--left', 'link--clickable']}
          onClick={this.openLink('privacyPolicyUrl')}
        >
          {t('signup.form.privacy')}
        </TodosTextEle>
      ),
    });
  }

  renderField = ({ input, type, placeholder, meta: { error, autofilled }, isPhoneNumber, ...more }) => (
    <Input
      isFirst
      isLast
      data-key={more['data-key']}
      disabled={autofilled}
      error={
        (error && this.props.submitHasFailed) ||
        (error && this.props.submitFailed) ||
        (error && this.props.submitSucceeded)
      }
      placeholder={t(placeholder)}
      type={type}
      isPhoneNumber={isPhoneNumber}
      hover={isPhoneNumber}
      twoSplit={more.twoSplit}
      styleOverwrite={more.styleOverwrite}
      preventNegativeValues={isPhoneNumber}
      {...input}
    />
  );

  renderPhoneCountryCodeField = ({ input, ...custom }) => {
    const { value, ...omitValue } = input;
    return (
      <Input
        twoSplit="leftsmall"
        type="select"
        options={countryPhoneOptions}
        transformSelectTitle={splitCountryCode}
        isFirst
        styleOverwrite={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
        value={custom.dialCodeValue}
        isPhoneNumber
        {...omitValue}
      />
    );
  };

  render() {
    const {
      error,
      submitHasFailed,
      submitFailed,
      submitSucceeded,
      handleSubmit,
      isPartnerDomain,
      reference,
    } = this.props;
    const { dialCode, submitDisabled } = this.state;

    return (
      <form onSubmit={handleSubmit}>
        <Field type="hidden" component="input" name="reCaptchaToken" data-key="reCaptchaToken" />
        <Field type="hidden" component="input" name="reCaptchaVersion" data-key="reCaptchaVersion" />
        <Field
          placeholder="signup.placeholder.first_name"
          type="text"
          component={this.renderField}
          name="firstName"
          data-key="firstName"
        />
        <SpaceTiny />
        <Field
          placeholder="signup.placeholder.last_name"
          type="text"
          component={this.renderField}
          name="lastName"
          data-key="lastName"
        />
        <SpaceTiny />
        {isPartnerDomain && (
          <>
            <Input.TwoSplit type="bigger">
              <Field
                type="select"
                name="phoneCountryCode"
                component={this.renderPhoneCountryCodeField}
                dialCodeValue={dialCode}
                onChange={this.handleDialCodeChange}
              />
              <Field
                twoSplit="rightbig"
                type="text"
                placeholder="signup.placeholder.phone"
                name="phoneRegionalNumber"
                component={this.renderField}
                styleOverwrite={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                isPhoneNumber
              />
            </Input.TwoSplit>
            <SpaceTiny />
          </>
        )}
        <Field
          placeholder="signup.placeholder.email"
          type="text"
          component={this.renderField}
          name="email"
          data-key="email"
        />
        <SpaceTiny />
        <Field
          placeholder="signup.placeholder.password"
          type="password"
          component={this.renderField}
          name="password"
          data-key="password"
        />
        {((error && submitHasFailed) || (error && submitFailed) || (error && submitSucceeded)) && (
          <Input.ErrorMsg>{t(error)}</Input.ErrorMsg>
        )}
        <SpaceSmall />
        {/* Google reCaptcha v2 fallback when Google reCaptcha v3 fails */}
        {reference && (
          <>
            <div className={cx('recaptcha')} ref={reference} />
            <SpaceSmall />
          </>
        )}
        <Button
          type="submit"
          disabled={submitDisabled}
          onClick={e => {
            this.setState({ submitDisabled: true });
            setTimeout(() => this.setState({ submitDisabled: false }), 1500);
            return handleSubmit(e);
          }}
          data-key="signup_submit"
          types={
            submitDisabled
              ? ['type-full-primary', 'size-large', 'width-flex', 'zindex-11']
              : ['type-full-primary', 'width-flex', 'size-large']
          }
        >
          {!submitDisabled && t('signup.create_account')}
          {submitDisabled && <LoadingSpinner type="white" />}
        </Button>

        <SpaceSmall />
        <SpaceSmall />

        <TodosTextEle modifier={['txt--l7', 'color--black', 'text--left']}>{this.renderTosMsg()}</TodosTextEle>
      </form>
    );
  }
}

const selector = formValueSelector('signup');
const enhance = compose(
  connect(
    state => ({
      email: selector(state, 'email'),
      phoneRegionalNumber: selector(state, 'phoneRegionalNumber'),
      emailIsSet: !!selector(state, 'email'),
      remoteErrors: selector(state, 'errors'),
      config: selectorsGlobal.selectConfig(state),
      isPartnerDomain: selectIsPartnerDomain()(state),
    }),
    {},
  ),
  reduxForm({
    form: 'signup',
    validate: validateState,
    destroyOnUnmount: false,
  }),
);

export default WithReCaptcha(enhance(SignupForm));
