import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import curry from 'lodash/curry';
import lodashResult from 'lodash/result';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import noop from 'lodash/noop';
import t from '@properly/localization';
import { Modal, SpaceFlexi, Button } from '@properly/components';
import { setModal } from '../../../actions/globalActions';
import {
  selectGlobalModalResolved,
  selectPropertiesFiltered,
  selectCurrentUser,
  selectLoadingState,
} from '../../../selectors/globalSelector';
import { handlePermissionActionSaga } from './PermssionsActions';
import { JobRequestSpacerWrap, JobRequestPropertyList } from '../../../components/JobRequestElements';
import { TodosTextEle } from '../../../components/TodosElements';
import Sidebar from '../../../components/Sidebar';
import { propertyShowAble } from '../../../helper/herbert';
import { updateActionLoadingKey } from './PermissionsConstants';

const name = 'permissions_leavepropertyexternal';

class PermissionLeaveModal extends PureComponent {
  constructor(props) {
    super(props);
    this.curryedHandle = curry(this.props.handlePermissionActionSaga, 3);
  }

  close = nameInner => () => this.props.setModal(false, nameInner, {});

  run = (nameFunc, meta) => e => {
    this.curryedHandle(nameFunc)(meta)(e);
  };

  get teamId() {
    return lodashResult(this.props.data, ['meta', 'teamId']);
  }

  render() {
    const filteredProperties = filter(this.props.properties?.toJS(), item => {
      const matches = this.props.currentUser.getTeamFromRole(item.ownerRole).teamId === this.teamId;
      return propertyShowAble(item) && matches;
    });
    const ordered = orderBy(filteredProperties, property => property.getSearchString(), ['asc']);

    const heightBox = 190;
    const isOpen = !!this.props.data.isOpen;

    return (
      <Modal width={650} height={470} onClose={this.close(name)} show={isOpen}>
        <div>
          <JobRequestSpacerWrap
            left="24px"
            top="20px"
            bottom="20px"
            right="24px"
            isFlex
            style={{ flexDirection: 'column' }}
          >
            <TodosTextEle modifier={['txt--l3m', 'color--black']}>{t('permissions.leave_property_h1')}</TodosTextEle>
            <SpaceFlexi type="vertical" size="12px" />
            <TodosTextEle modifier={['txt--l5', 'color--black']}>{t('permissions.leave_property_info')}</TodosTextEle>
            <SpaceFlexi type="vertical" size="30px" />
            <Sidebar.List style={{ minHeight: `${heightBox}px` }}>
              <Sidebar.ListInner>
                <Sidebar.ListInnerInner>
                  <JobRequestPropertyList
                    height={192}
                    width={532}
                    properties={ordered}
                    handleClick={noop}
                    isActiveFunc={() => false}
                    isHoverableFunc={() => false}
                    hasClickHoleFunc={() => false}
                    isClickableFunc={() => true}
                  />
                </Sidebar.ListInnerInner>
              </Sidebar.ListInner>
            </Sidebar.List>
            <Modal.Options>
              <Button
                onClick={this.run('leave_property_external', this.props.data.meta, name)}
                isLoading={this.props.loadingRemoveColl}
                disabled={this.props.loadingRemoveColl}
                types={['type-full-primary', 'size-medium']}
                styleOverwrite={{ marginRight: '8px' }}
              >
                {t('permissions.leave')}
              </Button>
              <Button
                disabled={this.props.loadingRemoveColl}
                onClick={this.close(name)}
                types={['type-border-primary', 'size-medium']}
              >
                {t('properties.cancel')}
              </Button>
            </Modal.Options>
          </JobRequestSpacerWrap>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps() {
  const memo = selectGlobalModalResolved(name);
  const memo2 = selectPropertiesFiltered();
  return (state, props) => ({
    loadingRemoveColl: selectLoadingState(updateActionLoadingKey)(state).isLoadingFull,
    data: memo(state, props),
    properties: memo2(state, props),
    currentUser: selectCurrentUser()(state, props),
  });
}

export default connect(mapStateToProps, {
  setModal,
  handlePermissionActionSaga,
})(PermissionLeaveModal);
