import { getConfigSync } from '@properly/config';

const config = getConfigSync();
const { DESKTOP } = config || {};
const { INTERCOM_APP_ID } = DESKTOP || {};

function addIntercom(id = INTERCOM_APP_ID) {
  // Ensure we only ever call this a single time
  if (window.intercomSettings) {
    return;
  }

  const smallScreen = window.innerWidth <= 768;

  const intercomSettings = {
    app_id: id,
    vertical_padding: 0,
    hide_default_launcher: smallScreen,
    properly_app_id: 'desktop',
  };

  window.intercomSettings = intercomSettings;

  /* eslint-disable */

  const w = window;
  const ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', intercomSettings);
  } else {
    const d = document;
    var i = function() {
      i.c(arguments);
    };
    i.q = [];
    i.c = function(args) {
      i.q.push(args);
    };
    w.Intercom = i;
    function l() {
      const s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = `https://widget.intercom.io/widget/${id}`;
      const x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    }
    if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
}

export const runintercom = () => {
  addIntercom(INTERCOM_APP_ID);
};
