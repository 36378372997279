import forOwn from 'lodash/forOwn';

export default class Job {
  // job == checklist
  constructor(
    objectId,
    property,
    title,
    numOfSteps,
    numOfTasks,
    jobInstructionId,
    pictureUrl,
    totalVerificationCount,
    userId,
    jobDescription,
    generalTasks,
    deleted,
    weight,
    role,
    createdAt,
    defaultSettings,
  ) {
    this.objectId = objectId;
    this.defaultProperty = property;
    this.title = title;
    this.totalStepCount = numOfSteps;
    this.totalTaskCount = numOfTasks;
    this.jobInstructionId = jobInstructionId;
    this.pictureUrl = pictureUrl;
    this.totalVerificationCount = totalVerificationCount;
    this.user = userId;
    this.jobDescription = jobDescription;
    this.generalTasks = generalTasks;
    this.deleted = deleted;
    this.weight = weight;
    this.role = role;
    this.createdAt = createdAt;
    this.defaultSettings = defaultSettings;
  }

  getOwnProps(scope, onScope) {
    const obj = onScope || {};
    forOwn(scope, (value, key) => {
      obj[key] = value;
    });
    return obj;
  }

  setData(obj) {
    this.getOwnProps(obj, this);
  }

  getData() {
    return this.getOwnProps(this);
  }

  getId() {
    return this.objectId;
  }

  getTitle() {
    return this.title;
  }
}
