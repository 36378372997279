import { fork } from 'redux-saga/effects';
import desktopSaga from '../modules/desktopApp/sagas';
import loginSaga from '../modules/loginSignUp/sagas';
import configSaga from '../containers/Config/saga';
import jobCleanerRequestListSaga from '../containers/JobCleanerRequestList/saga';
import propertyJobPhotosSaga from '../containers/PropertyJobPhotos/saga';
import paymentInterceptSagas from '../containers/PaymentInterceptWizard/PaymentInterceptSagas';
import brandingSaga from '../modules/branding/sagas';
import bookingcomSaga from '../modules/onboarding/bookingcom/state/BookingComSagas';

function* saga() {
  yield fork(desktopSaga);
  yield fork(loginSaga);

  yield fork(configSaga);
  yield fork(jobCleanerRequestListSaga);
  yield fork(propertyJobPhotosSaga);
  yield fork(paymentInterceptSagas);
  yield fork(brandingSaga);
  yield fork(bookingcomSaga);
}

export default saga;
