import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import keys from 'lodash/keys';
import isString from 'lodash/isString';
import classNames from 'classnames/bind';
import t from '@properly/localization';
import { Modal, Input, Button, LoadingSplash, Title3, Regular, SpaceBase } from '@properly/components';
import { splitCountryCode, countryPhoneOptions, isValidEmail, isValidPhone } from '@properly/common';
import * as selectors from '../state/ContactSelectors';
import styles from './contactStyles.module.css';
import {
  updateModalData,
  resendInviteSaga,
  updateModalState,
  freshInviteSaga,
  addContactSaga,
  closeModalSaga,
} from '../state/ContactActions';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import ContactEmailPhoneWrap from './ContactEmailPhoneWrap';
import { trackInviteStart } from '../../../../actions/trackingEvents';

const cx = classNames.bind(styles);

class ContactModalContainer extends Component {
  constructor(props) {
    super(props);
    this.updateFromEvent = this.updateFromEvent.bind(this);
  }

  updateFromEvent(field) {
    return e => {
      this.props.updateModalData({
        [field]: e.target.value,
      });
    };
  }

  updateEmpty(field) {
    this.props.updateModalData({
      [field]: '',
    });
  }

  isOpen(prevProps, props) {
    const prevPropsJS = prevProps.modalState.toJS();
    const propsJS = props.modalState.toJS();
    if (propsJS.isOpen && !prevPropsJS.isOpen && propsJS.view === 1) {
      trackInviteStart();
    }
  }

  componentDidUpdate(prevProps) {
    this.isOpen(prevProps, this.props);
  }

  get modalState() {
    return this.props.modalState.toJS();
  }

  get modalData() {
    return this.props.modalData.toJS();
  }

  view5() {
    return (
      <div className={cx('contacts__modal-loading')}>
        <LoadingSplash static />
      </div>
    );
  }

  view4() {
    return (
      <div>
        <Modal.H1 type="">
          <Title3 type="bold">{t('contacts.congrats')}</Title3>
        </Modal.H1>
        <Modal.Text>
          <Regular>
            {t('contacts.send_invitation_success_exist', {
              name: this.modalData.firstName,
            })}
          </Regular>
        </Modal.Text>
        <SpaceBase />
        <div className={cx('contact__modal-footer')}>
          <div className={cx('contact__dots')} />
          <Button
            data-key="invitation_success_exist_ok"
            types={['type-full-primary', 'size-large', 'miwidth-80']}
            onClick={this.props.closeModalSaga}
          >
            {t('contacts.send_invitation_success_ok')}
          </Button>
        </div>
      </div>
    );
  }

  view3() {
    return (
      <div>
        <Modal.H1 type="">
          <Title3 type="bold">{t('contacts.congrats')}</Title3>
        </Modal.H1>
        <Modal.Text>
          <Regular>
            {t('contacts.send_invitation_success_l1', {
              name: this.modalData.firstName,
            })}
          </Regular>
        </Modal.Text>
        <Modal.Text>
          <Regular>{t('contacts.send_invitation_success_l2')}</Regular>
        </Modal.Text>
        <SpaceBase />
        <div className={cx('contact__modal-footer')}>
          <div className={cx('contact__dots')} />
          <Button
            data-key="invitation_success_ok"
            onClick={this.props.closeModalSaga}
            types={['type-full-primary', 'size-large', 'miwidth-80']}
          >
            {t('contacts.send_invitation_success_ok')}
          </Button>
        </div>
      </div>
    );
  }

  view2Errors(data, showErrors) {
    const errors = {};
    if (!data.firstName) errors.firstName = t('contacts.please_first_name');
    if (!data.lastName) errors.lastName = t('contacts.please_last_name');
    return {
      isValid: keys(errors).length === 0,
      errors: showErrors ? errors : {},
    };
  }

  view2() {
    const { errors, isValid } = this.view2Errors(this.modalData, this.modalState.showErrors);
    const trySubmit = valid => {
      if (valid) {
        this.props.addContactSaga();
      } else {
        this.props.updateModalState({ showErrors: true });
      }
    };
    return (
      <div>
        <Modal.H1 type="">
          <Title3 type="bold">{t('contacts.invite_cleaner_modal')}</Title3>
        </Modal.H1>
        <Modal.Text>
          <Regular>{t('contacts.enter_first_last')}</Regular>
        </Modal.Text>
        <SpaceBase />
        <Input
          data-key="email_first_name"
          isFirst
          type="text"
          placeholder={t('contacts.first_name')}
          error={errors.firstName}
          value={this.modalData.firstName}
          onChange={this.updateFromEvent('firstName')}
        />
        <Input
          data-key="email_last_name"
          isLast
          type="text"
          placeholder={t('contacts.last_name')}
          error={errors.lastName}
          value={this.modalData.lastName}
          onChange={this.updateFromEvent('lastName')}
        />
        <Input.ErrorMsg>{errors.lastName || errors.firstName}</Input.ErrorMsg>
        <SpaceBase />
        {this.modalState.hasError && <div className={cx('contact__modal-error')}>{t('contacts.error_request')}</div>}
        <div className={cx('contact__modal-footer')}>
          <div className={cx('contact__dots')} />
          <Button
            data-key="contact_invite"
            fakeDisable={!isValid}
            types={['type-full-primary', 'size-large', 'miwidth-80']}
            onClick={() => trySubmit(isValid)}
          >
            {t('contacts.invite')}
          </Button>
        </div>
      </div>
    );
  }

  view6Errors(data, showErrors) {
    const errors = {};
    if (!data.firstName) errors.firstName = true;
    if (!data.lastName) errors.lastName = true;
    if (data.phoneNumber && !isValidPhone(data.phoneNumber)) {
      errors.phoneNumber = true;
    }
    if (data.email && !isValidEmail(data.email)) errors.email = true;
    if (!data.email && !data.phoneNumber) {
      errors.email = t('contacts.please_enter_at_least');
    }

    return {
      isValid: keys(errors).length === 0,
      errors: showErrors ? errors : {},
    };
  }

  view6() {
    const { errors, isValid } = this.view6Errors(this.modalData, this.modalState.showErrors);
    const emailArgs = isString(errors.email) ? { showErrorMsg: true, error: errors.email } : {};
    const trySubmit = valid => {
      if (valid) {
        this.props.resendInviteSaga(this.props.contactData.objectId);
      } else {
        this.props.updateModalState({ showErrors: true });
      }
    };
    return (
      <div>
        <Modal.H1 type="">
          <Title3 type="bold">{t('contacts.resent_invite_modal')}</Title3>
        </Modal.H1>
        <Modal.Text>
          <Regular>{t('contacts.resent_invite_info')}</Regular>
        </Modal.Text>
        <SpaceBase />
        <Input
          data-key="resend_first_name"
          isFirst
          type="text"
          placeholder={t('contacts.first_name')}
          error={errors.firstName}
          value={this.modalData.firstName}
          onChange={this.updateFromEvent('firstName')}
        />
        <Input
          data-key="resend_last_name"
          isLast
          type="text"
          placeholder={t('contacts.last_name')}
          error={errors.lastName}
          value={this.modalData.lastName}
          onChange={this.updateFromEvent('lastName')}
        />
        <SpaceBase />
        <Input.TwoSplit type="bigger">
          <Input
            data-key="resend_phone_country_code"
            twoSplit="leftsmall"
            type="select"
            style={{ color: '#36ACB2' }}
            options={countryPhoneOptions}
            onChange={this.updateFromEvent('phoneCountryCode')}
            transformSelectTitle={splitCountryCode}
            error={false}
            isFirst
            styleOverwrite={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            value={this.modalData.phoneCountryCode}
          />
          <Input
            data-key="resend_phone_number"
            twoSplit="rightbig"
            type="text"
            onChange={this.updateFromEvent('phoneNumber')}
            placeholder={t('contacts.phone')}
            error={errors.phoneNumber}
            isFirst
            styleOverwrite={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            value={this.modalData.phoneNumber}
          />
        </Input.TwoSplit>
        <Input
          data-key="resend_email"
          isLast
          type="text"
          placeholder={t('contacts.email')}
          {...emailArgs}
          value={this.modalData.email}
          onChange={this.updateFromEvent('email')}
        />
        <SpaceBase />
        {this.modalState.hasError && <div className={cx('contact__modal-error')}>{t('contacts.error_request')}</div>}
        <div className={cx('contact__modal-footer')}>
          <div className={cx('contact__dots')} />
          <Button
            data-key="resend_invite_button"
            fakeDisable={!isValid}
            types={['type-full-primary', 'size-large', 'miwidth-80']}
            onClick={() => trySubmit(isValid)}
          >
            {t('contacts.invite')}
          </Button>
        </div>
      </div>
    );
  }

  view1Errors(data, showErrors) {
    const errors = {};
    if (!isValidEmail(data.email) && !data.phoneNumber) errors.email = true;
    if (!isValidPhone(data.phoneNumber) && !data.email) {
      errors.phoneNumber = true;
    }

    return {
      isValid: keys(errors).length === 0,
      errors: showErrors ? errors : {},
    };
  }

  switchContext(func, val) {
    this.updateEmpty('email');
    this.updateEmpty('phone');
    func(val);
  }

  view1() {
    const { errors, isValid } = this.view1Errors(this.modalData, this.modalState.showErrors);
    const trySubmit = (valid, isEmail) => {
      const inviteData = isEmail
        ? { email: this.modalData.email, type: 'email' }
        : {
            phoneCountryCode: this.modalData.phoneCountryCode,
            phoneRegionalNumber: this.modalData.phoneNumber,
            type: 'phone',
          };

      if (valid) {
        this.props.freshInviteSaga(inviteData);
      } else {
        this.props.updateModalState({ showErrors: true });
      }
    };

    return (
      <div>
        <Modal.H1 type="">
          <Title3 type="bold">{t('contacts.invite_cleaner_modal')}</Title3>
        </Modal.H1>
        <ContactEmailPhoneWrap
          render={(mode, setMode) => {
            const isEmail = mode === 'email';
            return (
              <div>
                <Modal.Text>
                  <Regular>{isEmail ? t('contacts.enter_byemail') : t('contacts.enter_byphone')}</Regular>
                </Modal.Text>
                <SpaceBase />
                {mode === 'phone' && (
                  <Input.TwoSplit type="bigger">
                    <Input
                      twoSplit="leftsmall"
                      type="select"
                      style={{ color: '#36ACB2' }}
                      options={countryPhoneOptions}
                      onChange={this.updateFromEvent('phoneCountryCode')}
                      transformSelectTitle={splitCountryCode}
                      error={false}
                      isFirst
                      isLast
                      styleOverwrite={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                      value={this.modalData.phoneCountryCode}
                    />
                    <Input
                      data-key="invite_with_phone"
                      twoSplit="rightbig"
                      type="text"
                      onChange={this.updateFromEvent('phoneNumber')}
                      placeholder={t('contacts.phone')}
                      error={errors.phoneNumber}
                      isFirst
                      isLast
                      styleOverwrite={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                      value={this.modalData.phoneNumber}
                    />
                  </Input.TwoSplit>
                )}
                {mode === 'email' && (
                  <Input
                    data-key="invite_with_email"
                    isLast
                    isFirst
                    type="text"
                    placeholder={t('contacts.email')}
                    error={errors.email}
                    value={this.modalData.email}
                    onChange={this.updateFromEvent('email')}
                  />
                )}
                <div className={cx('contact__modal-btncenter')}>
                  <Button
                    data-key="email_instead"
                    onClick={() => this.switchContext(setMode, isEmail ? 'phone' : 'email')}
                    types={['type-flat-grey', 'size-small', 'fontw-normal']}
                  >
                    {isEmail ? t('contacts.use_phone_instead') : t('contacts.use_email_instead')}
                  </Button>
                </div>

                {this.modalState.hasError && (
                  <div className={cx('contact__modal-error')}>
                    <SpaceBase />
                    {t('contacts.error_request')}
                  </div>
                )}
                <div className={cx('contact__modal-footer')}>
                  <div className={cx('contact__dots')} />
                  <Button
                    data-key="email_Next"
                    fakeDisable={!isValid}
                    types={['type-full-primary', 'size-large', 'miwidth-80']}
                    onClick={() => trySubmit(isValid, isEmail)}
                  >
                    {t('contacts.next')}
                  </Button>
                </div>
              </div>
            );
          }}
        />
      </div>
    );
  }

  renderView(id) {
    if (id === 1) return this.view1();
    if (id === 2) return this.view2();
    if (id === 3) return this.view3();
    if (id === 4) return this.view4();
    if (id === 5) return this.view5();
    if (id === 6) return this.view6();
    return null;
  }

  render() {
    return (
      <Modal
        height="auto"
        message1={t('properties.loading_fail_property_head')}
        message2={t('properties.loading_fail_property')}
        show={this.modalState.isOpen}
        onClose={this.props.closeModalSaga}
      >
        {this.renderView(this.modalState.view)}
      </Modal>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    contactData: selectorsGlobal.selectContact(props.activeContactId)(state, props),
    modalState: selectors.modalState()(state, props),
    modalData: selectors.modalData()(state, props),
  };
}

ContactModalContainer.propTypes = {
  activeContactId: PropTypes.string, // eslint-disable-line
};

export default connect(mapStateToProps, {
  updateModalData,
  resendInviteSaga,
  updateModalState,
  freshInviteSaga,
  addContactSaga,
  closeModalSaga,
})(ContactModalContainer);
