import forOwn from 'lodash/forOwn';
import lodashResult from 'lodash/result';
import { getInitials } from '../helper/herbert';

export default class Profile {
  constructor(
    objectId,
    userData,
    numOfJobRequestsDone30,
    numOfHosts,
    signedUpDate,
    numOfJobRequestsDone,
    propertyPhotos,
    offeredServices,
    badges,
    responseTime,
    numOfProperties,
    listSuggestedCleaner,
    stripeEnabled,
    features,
  ) {
    this.objectId = objectId;
    this.userId = userData ? userData.getId() : undefined;
    this.userData = userData;
    this.numOfJobRequestsDone30 = numOfJobRequestsDone30;
    this.numOfHosts = numOfHosts;
    this.signedUpDate = signedUpDate;
    this.numOfJobRequestsDone = numOfJobRequestsDone;
    this.propertyPhotos = propertyPhotos;
    this.offeredServices = offeredServices;
    this.badges = badges;
    this.responseTime = responseTime;
    this.numOfProperties = numOfProperties || 0;
    this.listSuggestedCleaner = listSuggestedCleaner;
    this.stripeEnabled = stripeEnabled;
    this.features = features;
  }

  setData(obj) {
    this.getOwnProps(obj, this);
  }

  getData() {
    return this.getOwnProps(this);
  }

  getOwnProps(scope, onScope) {
    const obj = onScope || {};
    forOwn(scope, (value, key) => {
      obj[key] = value;
    });
    return obj;
  }

  getId() {
    return this.objectId;
  }

  getProStatus() {
    return this.listSuggestedCleaner && !this.marketplaceBlacklisted && !this.marketplaceSuspended;
  }

  getUserData() {
    return this.userData;
  }

  get userInitials() {
    return getInitials(lodashResult(this, ['userData', 'firstName']), lodashResult(this, ['userData', 'lastName']));
  }

  get userPicture() {
    return lodashResult(this, ['userData', 'pictureUrl']);
  }
}
