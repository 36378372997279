import { fromJS, Map } from 'immutable';
import {
  PROPERTY_DELETE_PROPERTY_SUCCESS,
  PROPERTY_DELETE_PROPERTY_REQUEST,
  PROPERTY_DELETE_PROPERTY_FAILURE,
  PROPERTY_DELETE_PROPERTY_CANCEL_ERROR,
  PROPERTY_SET_TMP_PROPERTY,
  PROPERTY_UPDATE_TMP_PROPERTY,
  GLOBAL_LOGOUT,
  PROPERTY_UPDATE_PROPERTY_REQUEST_STATE,
  PROPERTY_SET_CLONE_PROPERTY,
  PROPERTY_SET_FETCHSTATE_CHECKLIST,
  PROPERTY_LOAD_PROPERTIES_FAILURE,
  PROPERTY_SET_ICAL_DATA,
  PROPERTY_SET_INIT_ICAL_DATA,
  PROPERTY_EVENTS_SET_KEY,
  PROPERTY_SET_EVENTS_META,
  PROPERTY_SET_EDIT_EVENT_DATA,
  PROPERTY_SET_CLONE_PROPERTY_SELECT,
} from '../../../../types/index';
import { changeHelper } from '../../../../helper/herbert';
import Property from '../../../../model/property';
import Trigger from '../../../../model/trigger';
import { trackPropertyDelete } from '../../../../actions/trackingEvents';

const icalDefault = {
  isLoadingAccounts: true,
  accounts: {},
};

const eventDataDefault = {
  meta: {
    state: 0,
    deleteState: 0,
    savingState: 'changed',
  },
  editEvent: undefined,
};

const initState = fromJS({
  properties: {},
  propertiesLoaded: false,
  propertiesTmp: {},
  propertiesStates: {},
  loading: false,
  error: 0, // need to save 3 states
  loadingJobRequests: false,
  errorJobRequests: false,
  loadingDeleteProperty: false,
  errorDeleteProperty: false,
  errorDeletePropertyMessage: null,
  jobRequests: [],
  propertyPaginationStateCL: 0,

  cloneProperty: {
    hideMsg: false,
    isOpen: false,
    selected: [],
    mode: 0,
  },
  eventData: eventDataDefault,
  icalData: icalDefault,

  propertyBlueprint: changeHelper(Property, null, {
    title: '',
    objectId: 'new',
    address: {
      AddressArray: ['', '', ''],
      apt: '',
      city: '',
      country: '',
      state: '',
      street: '',
      zip: '',
    },
    numOfBedrooms: 0,
    numOfBeds: 0,
    numOfBathrooms: 0,
  }),
});

function update(state, action, field) {
  const p = state.getIn([field, action.propertyId]);
  return state.setIn([field, action.propertyId], changeHelper(Property, p, action.changeSet));
}

export default function PropertyReducers(state = initState, action = {}) {
  switch (action.type) {
    case GLOBAL_LOGOUT: // on logout reset
      return initState;

    case PROPERTY_SET_TMP_PROPERTY: {
      return state.setIn(['propertiesTmp', action.propertyId], action.val);
    }
    case PROPERTY_SET_CLONE_PROPERTY:
      return state.updateIn(['cloneProperty'], data => {
        const merged = data.mergeDeep(fromJS(action.val));
        return fromJS({
          ...merged.toJS(),
        });
      });
    case PROPERTY_SET_CLONE_PROPERTY_SELECT:
      return state.setIn(['cloneProperty', 'selected'], action.val);
    case PROPERTY_LOAD_PROPERTIES_FAILURE:
      return state.set('loading', false).set('error', 1);
    case PROPERTY_SET_ICAL_DATA:
      return state.mergeIn(['icalData'], fromJS(action.val));
    case PROPERTY_SET_INIT_ICAL_DATA:
      return state.set('icalData', fromJS(icalDefault));
    case PROPERTY_SET_EDIT_EVENT_DATA:
      return state.updateIn(['eventData', 'editEvent'], old => changeHelper(Trigger, old, action.changeSet));
    case PROPERTY_EVENTS_SET_KEY:
      return state.setIn(['eventData', action.key], fromJS(action.val));
    case PROPERTY_SET_EVENTS_META:
      return state.mergeIn(['eventData', 'meta'], fromJS(action.meta));
    case PROPERTY_SET_FETCHSTATE_CHECKLIST:
      return state.setIn(['propertyPaginationStateCL'], action.val);

    case PROPERTY_DELETE_PROPERTY_REQUEST:
      return state.set('errorDeleteProperty', false).set('loadingDeleteProperty', true);
    case PROPERTY_DELETE_PROPERTY_FAILURE:
      return state
        .set('errorDeleteProperty', true)
        .set('errorDeletePropertyMessage', action.message)
        .set('loadingDeleteProperty', false);
    case PROPERTY_DELETE_PROPERTY_CANCEL_ERROR:
      return state.set('errorDeleteProperty', false);
    case PROPERTY_DELETE_PROPERTY_SUCCESS: {
      const updatedState = update(state, action, 'properties');
      trackPropertyDelete(action.propertyId);
      return updatedState.set('errorDeleteProperty', false).set('loadingDeleteProperty', false);
    }

    case PROPERTY_UPDATE_PROPERTY_REQUEST_STATE: {
      const prev = state.getIn(['propertiesStates', action.propertyId]);
      if (Map.isMap(prev)) {
        return state.setIn(['propertiesStates', action.propertyId, action.key], { ...prev, ...action.changeset });
      }
      // first key added
      return state.setIn(['propertiesStates', action.propertyId], fromJS({ [action.key]: { ...action.changeset } }));
    }

    case PROPERTY_UPDATE_TMP_PROPERTY:
      return update(state, action, 'propertiesTmp');
    default:
      return state;
  }
}
