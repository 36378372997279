import { delay } from 'redux-saga';
import { put, fork, select, call, takeEvery } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import log from 'loglevel';
import t from '@properly/localization';
import { openIntercomWithMsg } from '@properly/common';
import lodashResult from 'lodash/result';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import reduce from 'lodash/reduce';
import pick from 'lodash/pick';
import indexOf from 'lodash/indexOf';
import * as types from '../../../types';
import {
  addTeamMember,
  editTeam,
  editTeamMember,
  getSharedWithMe,
  leaveCollaboration,
  removeCollaborator,
} from '../data';
import { getCollaborators, getTeams } from '../data.http.team';
import {
  setGlobalLoadingState,
  setModal,
  updateCollectionEntryBatch,
  updateCollectionEntry,
  goTo as goToImport,
} from '../../../actions/globalActions';
import {
  initLoadingKey,
  teamLoadingKey,
  updateActionLoadingKey,
  modalModes,
  modalLoadingSubmitKey,
  modalLoadingKey,
  modalLoadingNextKey,
  permissionMapTrueKey,
} from './PermissionsConstants';
import {
  setListData,
  mergeListData,
  setSubmitData,
  resetSubmitData,
  handlePermissionActionSaga as handlePermissionActionSagaImport,
} from './PermssionsActions';
import {
  selectSubmitData,
  selectFirstTeam,
  selectPermissonModalData,
  selectModalData,
  doesEmailExist,
  selectActiveModalSection,
  selectAllowedTypeSectionsInModeContext,
  selectRootDomains,
  getRootDomainOfEmail,
} from './permissionSelector';
import { changeHelper, findMatchNonDisabled } from '../../../helper/herbert';
import Collaborator from '../../../model/collaborator';
import { guardPermissions } from './permissionguard';
import { ROUTES } from '../../../paths';
import { findContactGraphql } from '../../../graphql/api/contacts';

const preGoSection = {
  permissions: handlePrePermissions,
  property: handlePreProperty,
  type: handlePreType,
};

const postGoSection = {
  email: handleEmailNext,
  email_company: handleEmailNext,
};

const postCloseModal = {
  [modalModes.invite_company]: handlePostCloseModalInviteCompany,
  [modalModes.invite_partner]: handlePostCloseModalInviteCompany,
};

function* handlePreType({ mode, submitData }) {
  const currentValue = submitData.raw.getIn(['type', 'type']);
  if (mode === modalModes.invite_partner && !currentValue) {
    const val = yield select(selectAllowedTypeSectionsInModeContext);
    const firstEle = lodashResult(val, [0]);
    if (firstEle) {
      yield put(
        handlePermissionActionSagaImport('changeSubmitType', {
          value: fromJS({ type: firstEle }),
        }),
      );
    }
  }
}
function* handlePostCloseModalInviteCompany() {
  yield put(setModal(true, 'permissions_company_success', {}));
}

function* initPermissionsSaga() {
  try {
    yield put(setGlobalLoadingState(initLoadingKey, 1));
    const [collaborators, teams, sharedWithMe] = yield [getCollaborators(), getTeams(), getSharedWithMe()];
    yield put(setListData('sharedWithMe', sharedWithMe));
    yield put(updateCollectionEntryBatch('collaborators', keyBy(collaborators, 'userId'), false));
    yield put(updateCollectionEntryBatch('teams', keyBy(teams, 'objectId'), false));
    yield put(setGlobalLoadingState(initLoadingKey, 2));
  } catch (e) {
    log.error('initPermissionsSaga', e);
    yield put(setGlobalLoadingState(initLoadingKey, 3));
  }
}

function guardProperty(imm) {
  if (imm.get('type') === 'option1') {
    return imm.set('properties', fromJS({}));
  }
  return imm.update('properties', val => val.filter(valInner => valInner));
}

function mapLocalToBackend(localData, teamIdInput, coll) {
  const allProperties = localData.properties.properties;
  const properties = Object.keys(allProperties).filter(key => !!allProperties[key]);
  const rights =
    localData.permissions.type === 'option1' ? {} : mapPermissionToBackend(localData.permissions.permissions);
  const teamICreate = localData.collType.type === 'option1' && teamIdInput;
  const { email } = localData.email;
  return {
    properties: properties.length > 0 ? properties : undefined,
    teamId: localData.email.teamId ? localData.email.teamId : teamICreate,
    userId: localData.email.userId,
    rights,
    email,
    partnerType: Collaborator.mapLocalCollTypeToBackend(localData.type.type),
    enabled: lodashResult(coll, ['enabled']),
  };
}

function mapPermissionToBackend(permissions) {
  return mapValues(permissionMapTrueKey, (val, key) => {
    if (permissions[key]) {
      return true;
    }
    return false;
  });
}

function mapBackendToLocal(backendData, permissionMap) {
  const { email } = backendData;
  const partnerType = Collaborator.mapBackendCollTypeToLocal(backendData.partnerType);

  const filteredPermissionMap = Object.keys(permissionMap).filter(key => !!permissionMap[key]);

  return {
    email: {
      email,
      userId: backendData.userId,
      teamId: backendData.teamId,
      backendEmail: backendData.email,
      error: undefined,
    },
    type: {
      type: partnerType,
      backendType: partnerType,
    },
    collType: {
      type: backendData.internal ? 'option1' : 'option2',
      disabled: [],
    },
    permissions: {
      type: backendData.hasAllRights ? 'option1' : 'option2',
      permissions: pick(backendData.rights || {}, filteredPermissionMap),
      disabled: [],
    },
    properties: {
      type: backendData.hasAllRightsProperties ? 'option1' : 'option2',
      properties: reduce(
        backendData.properties,
        (acc, item) => {
          acc[item] = true;
          return acc;
        },
        {},
      ),
    },
  };
}

function* editTeamSaga(data) {
  log.info('editTeamSaga', data);
  const res = yield call(editTeam, data);
  yield put(updateCollectionEntry('teams', res.team.objectId, res.team));
  return res;
}

// ok
function* runHandleSubmit({ submitData, firstTeam, mode, coll }, func) {
  yield put(setGlobalLoadingState(modalLoadingSubmitKey, 1));
  try {
    const mappedData = mapLocalToBackend(submitData.js, lodashResult(firstTeam, ['objectId']), coll);
    log.info('runHandleSubmit', mappedData);
    const res = yield call(func, mappedData);
    yield put(updateCollectionEntry('collaborators', res.userId, res));
    yield put(setGlobalLoadingState(modalLoadingSubmitKey, 0));
    yield put(setModal(false, 'permissionmodal', {})); // close modal
    const postHook = postCloseModal[mode];
    if (postHook) {
      yield delay(500);
      yield postHook();
    }
  } catch (e) {
    log.error('runHandleSubmit - edit coll', e);
    yield put(setGlobalLoadingState(modalLoadingSubmitKey, 3));
  }
}

// ok
function* handleSubmit({ submitData, firstTeam, mode, coll }) {
  if (mode === modalModes.invite || mode === modalModes.invite_company || mode === modalModes.invite_partner) {
    yield runHandleSubmit({ submitData, firstTeam, mode, coll }, addTeamMember);
  }
  if (
    mode === modalModes.edit_partner ||
    mode === modalModes.resend_partner ||
    mode === modalModes.edit_company ||
    mode === modalModes.resend_company
  ) {
    yield runHandleSubmit({ submitData, firstTeam, mode, coll }, editTeamMember);
  }
}

function mapError(error) {
  const stringifiedError = JSON.stringify(error);
  if (/(.*)Email is blacklisted.(.*)/.test(stringifiedError)) {
    return 4;
  }
  if (/(.*)RootDomain already used.(.*)/.test(stringifiedError)) {
    return 5;
  }
  return 3;
}

function* runHandleTeamUpdate({ meta }) {
  try {
    yield put(setGlobalLoadingState(teamLoadingKey, 1));
    const res = yield editTeamSaga(meta);
    yield put(setGlobalLoadingState(teamLoadingKey, 0));
    yield delay(500);
    yield put(setModal(false, 'permissions_company', {}));
    if (res.emailSend) {
      yield delay(300);
      yield put(setModal(true, 'permissions_company_verifyinfo', { email: meta.email }));
    }
  } catch (e) {
    const mappedError = mapError(e);
    if (mappedError === 3) {
      log.error('handlePermissionActionSaga - createteam', e);
    }
    yield put(setGlobalLoadingState(teamLoadingKey, mappedError));
  }
}

function* handleTeamUpdate(action, meta) {
  if (action === 'createteam') {
    yield runHandleTeamUpdate({ meta }, t('permissions.company_created'));
  }
  if (action === 'saveteam') {
    yield runHandleTeamUpdate({ meta }, t('permissions.team_saved'));
  }
}

function* handleEnableDisable(enableDisable, meta) {
  const text = enableDisable ? 'permissions.enabled_col' : 'permissions.disabled_col';
  try {
    yield put(setGlobalLoadingState(updateActionLoadingKey, 1));
    const res = yield call(editTeamMember, {
      userId: meta.data.userId,
      teamId: meta.data.teamId,
      rights: meta.data.rights,
      properties: meta.data.properties,
      email: meta.data.email,
      enabled: enableDisable,
    });
    yield put(updateCollectionEntry('collaborators', res.userId, res));
    yield put(setGlobalLoadingState(updateActionLoadingKey, 0));
    yield put(
      handlePermissionActionSagaImport('showinfomessage', {
        text,
      }),
    );
  } catch (e) {
    log.error('handleEnableDisable', e);
    yield put(setGlobalLoadingState(updateActionLoadingKey, 0));
  }
}

function* setEmailError(submitData, val) {
  yield put(
    setSubmitData({
      email: submitData.raw.get('email').set('error', val),
    }),
  );
}

function* handleEmailNext({ submitData, mode, sectionId }) {
  let result = false;
  try {
    const submitEmail = submitData.raw.getIn(['email', 'email']);
    const backendEmail = submitData.raw.getIn(['email', 'backendEmail']);
    const sameEMailAsBackend = backendEmail && backendEmail === submitEmail;
    const emailExists = yield select(doesEmailExist(submitEmail));

    yield put(setGlobalLoadingState(modalLoadingNextKey, 1));
    const res = yield call(findContactGraphql, {
      email: submitEmail,
      type: 'email',
    });
    if (sectionId === 'email') {
      if (
        !sameEMailAsBackend &&
        emailExists &&
        (mode === modalModes.invite || mode === modalModes.resend_partner || mode === modalModes.invite_partner)
      ) {
        yield setEmailError(submitData, 3);
      } else if (!sameEMailAsBackend && res.userId && !res.standaloneUser) {
        yield setEmailError(submitData, 2);
      } else {
        result = true;
      }
    } else if (sectionId === 'email_company') {
      const rootDomains = yield select(selectRootDomains());
      if (!sameEMailAsBackend && res.userId) {
        yield setEmailError(submitData, 2); // already signed up
      } else if (indexOf(rootDomains, getRootDomainOfEmail(submitEmail)) === -1) {
        yield setEmailError(submitData, 4);
      } else {
        result = true; // already signed up
      }
    }
  } catch (e) {
    log.error('topbarnext', e);
    yield setEmailError(submitData, 1);
  } finally {
    yield put(setGlobalLoadingState(modalLoadingNextKey, 0));
  }
  return result; // fail
}

function* handlePrePermissions({ submitData, mode }) {
  const currentValue = submitData.raw.getIn(['permissions', 'type']);
  let nextValue = submitData.raw.get('permissions');
  const computedData = yield select(selectModalData());
  if (mode === modalModes.invite || mode === modalModes.invite_company || mode === modalModes.invite_partner) {
    if (!currentValue) {
      nextValue = nextValue.set('type', 'option2');
    }
  }
  if (
    nextValue.get('type') === 'option1' &&
    indexOf(computedData.sectionsById.permissions.sectionDisabledSection, 'option1') !== -1
  ) {
    nextValue = nextValue.set('type', 'option2');
  }
  nextValue = nextValue.set(
    'permissions',
    guardPermissions(
      nextValue.get('permissions'),
      nextValue.get('permissions'),
      computedData.sectionBlackListenPermissions,
    ),
  );
  yield put(
    setSubmitData({
      permissions: nextValue,
    }),
  );
  return true;
}

function* handlePreProperty({ submitData, mode }) {
  if (mode === modalModes.invite || mode === modalModes.invite_company || mode === modalModes.invite_partner) {
    // TODO: refactor
    const currentValue = submitData.raw.get(['properties', 'type']);
    let nextValue = submitData.raw.get('properties');
    if (!currentValue) {
      nextValue = nextValue.set('type', 'option1');
    }
    yield put(
      setSubmitData({
        properties: nextValue,
      }),
    );
  }
  return true;
}

function* hooks({ currentSectionId, nextSectionId, runPost, runPre }) {
  const submitData = yield select(selectSubmitData());
  const modalData = yield select(selectPermissonModalData());
  const mode = modalData.getIn(['mode']);

  const preLoadFunc = runPre && preGoSection[nextSectionId];
  const postLoadFunc = runPost && postGoSection[currentSectionId];
  let res = true;
  if (postLoadFunc) {
    res = yield postLoadFunc({
      submitData,
      mode,
      sectionId: currentSectionId,
    });
  }
  if (preLoadFunc) {
    yield preLoadFunc({
      submitData,
      mode,
      sectionId: nextSectionId,
    });
  }
  return res;
}

function* goTo(currentIndex, array, limitFunc, modFunc, source) {
  log.info('goTo', { currentIndex, array, limitFunc, modFunc, source });
  const isNext = source === 'next';
  const currentActiveSection = yield select(selectActiveModalSection());
  const matchedSection = findMatchNonDisabled(modFunc(currentIndex), limitFunc, modFunc, array);
  if (matchedSection) {
    const res = yield hooks({
      currentSectionId: currentActiveSection,
      nextSectionId: matchedSection.sectionId,
      runPost: isNext,
      runPre: true,
    });

    if (res) {
      yield put(setListData('activeSection', matchedSection.sectionId));
    } else {
      log.info('goTo - postLoadFunc returned false');
    }
  } else {
    log.error(`goTo - section not found currentIndex:${currentIndex}, source: ${source}`);
  }
}

function* handlePermissionActionSaga({ action, meta }) {
  const submitData = yield select(selectSubmitData());
  const firstTeam = yield select(selectFirstTeam());
  const modalData = yield select(selectPermissonModalData());
  const computedData = yield select(selectModalData());
  log.info('handlePermissionActionSaga', {
    submitData,
    firstTeam,
    modalData,
    computedData,
  });
  const mode = modalData.getIn(['mode']);
  const modalColl = modalData.getIn(['meta', 'data']);
  if (action === 'createteam' || action === 'saveteam') {
    yield handleTeamUpdate(action, meta);
  }
  if (action === 'showinfomessage') {
    yield put(setListData('infomessage', meta));
    yield delay(5000);
    yield put(setListData('infomessage', {}));
  }
  if (action === 'hideinfomessage') {
    yield put(setListData('infomessage', {}));
  }
  if (action === 'invite') {
    yield put(setModal(true, 'permissionmodal', { mode: modalModes.invite }));
  }
  if (action === 'invite_company') {
    yield put(
      setModal(true, 'permissionmodal', {
        mode: modalModes.invite_company,
        intercept: true,
      }),
    );
  }
  if (action === 'invite_partner') {
    yield put(setModal(true, 'permissionmodal', { mode: modalModes.invite_partner }));
  }
  if (action === 'startTrial') {
    openIntercomWithMsg('');
  }
  if (action === 'doUpgrade') {
    yield put(
      goToImport({
        search: '?open=modal',
        pathname: ROUTES.settingsSubscription,
      }),
    );
  }
  if (action === 'edit_col') {
    const editMode = meta.renderType === 'internal' ? modalModes.edit_company : modalModes.edit_partner;
    yield put(setModal(true, 'permissionmodal', { mode: editMode, meta }));
  }
  if (action === 'resend_col') {
    const internal = meta.renderType === 'internal';
    const resendMode = internal ? modalModes.resend_company : modalModes.resend_partner;
    yield put(
      setModal(true, 'permissionmodal', {
        mode: resendMode,
        meta,
        intercept: internal,
      }),
    );
  }
  if (action === 'open_create_company') {
    yield put(setModal(true, 'permissions_company', {}));
  }
  if (action === 'open_edit_company') {
    yield put(setModal(true, 'permissions_company', {}));
  }
  if (action === 'changeSubmitEmail') {
    yield put(
      setSubmitData({
        email: meta.value,
      }),
    );
  }
  if (action === 'changeSubmitColl') {
    yield put(
      setSubmitData({
        collType: fromJS({ type: meta.value }),
      }),
    );
  }
  if (action === 'changeSubmitType') {
    yield put(
      setSubmitData({
        type: meta.value,
      }),
    );
  }
  if (action === 'changeSubmitPermission') {
    yield put(
      setSubmitData({
        permissions: fromJS({
          ...submitData.raw.get('permissions')?.toJS(),
          ...meta.value?.toJS(),
          permissions: guardPermissions(
            submitData.raw.getIn(['permissions', 'permissions']),
            meta.value.get('permissions'),
          ),
        }),
      }),
    );
  }
  if (action === 'changeSubmitProperty') {
    yield put(
      setSubmitData({
        properties: guardProperty(meta.value),
      }),
    );
  }
  if (action === 'topbarnext') {
    if (meta.sectionNextMode === 'next') {
      yield goTo(
        meta.sectionIndex,
        computedData.sections,
        index => index > 10,
        index => index + 1,
        'next',
      );
    }
    if (meta.sectionNextMode === 'send') {
      yield handleSubmit({
        submitData,
        firstTeam,
        mode,
        coll: modalColl,
      });
    }
  }
  if (action === 'sidebarclicksection') {
    // disable sidebar click just allow one way flow
  }
  if (action === 'handlesubmit') {
    // eslint-disable-next-line
    alert('not implemented');
  }
  if (action === 'leave_property') {
    yield put(setGlobalLoadingState(updateActionLoadingKey, 1));
    try {
      yield call(leaveCollaboration, meta.data.teamId);
      yield put(
        mergeListData('sharedWithMe', {
          [meta.data.ownerRole]: { ...meta.data, enabled: false },
        }),
      );
    } catch (e) {
      log.error('leave_property', e);
    }
    yield put(setGlobalLoadingState(updateActionLoadingKey, 0));
  }
  if (action === 'pre_leave_property_external') {
    yield put(setModal(true, 'permissions_leavepropertyexternal', { ...meta }));
  }
  if (action === 'leave_property_external') {
    yield put(setGlobalLoadingState(updateActionLoadingKey, 1));
    try {
      yield call(leaveCollaboration, meta.teamId);
    } catch (e) {
      log.error('leave_property', e);
    }
    yield put(setModal(false, 'permissions_leavepropertyexternal', {}));
    yield put(setGlobalLoadingState(updateActionLoadingKey, 0));
    yield put(goToImport(ROUTES.properties));
  }
  if (action === 'pre_revoke_col') {
    yield put(setModal(true, 'permissions_cancelaccess', { ...meta }));
  }
  if (action === 'topbarback') {
    yield goTo(
      meta.sectionIndex,
      computedData.sections,
      index => index < 0,
      index => index - 1,
      'back',
    );
  }
  if (action === 'revoke_col') {
    try {
      yield put(setGlobalLoadingState(updateActionLoadingKey, 1));
      yield call(removeCollaborator, meta.data.userId, meta.data.teamId);
      yield put(
        updateCollectionEntry(
          'collaborators',
          meta.data.userId,
          changeHelper(Collaborator, meta.data, { enabled: false }),
        ),
      );
      yield put(setGlobalLoadingState(updateActionLoadingKey, 0));
      yield put(
        handlePermissionActionSagaImport('showinfomessage', {
          text: 'permissions.col_remove_success',
        }),
      );
    } catch (e) {
      log.error('revoke_col', e);
      yield put(setGlobalLoadingState(updateActionLoadingKey, 0));
    }
  }
  if (action === 'disable_col') {
    yield handleEnableDisable(false, meta);
  }
  if (action === 'enable_col') {
    yield handleEnableDisable(true, meta);
  }
  if (action === 'view_property') {
    yield put(setModal(true, 'permissionmodal', { mode: modalModes.property, meta }));
  }
}

function* mountModal({ mode, meta }) {
  yield put(
    setListData(
      'modalMeta',
      fromJS({
        mode,
        meta,
      }),
    ),
  );
  // const modalData = yield select(selectGlobalModalResolved('permissionmodal'));
  let activeSection = 'email';

  // set active section
  if (mode === modalModes.property) {
    activeSection = 'seeper';
    yield put(
      setSubmitData({
        view: meta.data,
      }),
    );
  } else if (mode === modalModes.edit_company) {
    yield put(setSubmitData(fromJS(mapBackendToLocal(meta.data, permissionMapTrueKey))));
    activeSection = 'permissions';
  } else if (mode === modalModes.resend_company) {
    activeSection = 'email_company';
    yield put(setSubmitData(fromJS(mapBackendToLocal(meta.data, permissionMapTrueKey, true))));
  } else if (mode === modalModes.resend_partner) {
    activeSection = 'type';
    yield put(setSubmitData(fromJS(mapBackendToLocal(meta.data, permissionMapTrueKey))));
  } else if (mode === modalModes.edit_partner) {
    activeSection = 'type';
    yield put(setSubmitData(fromJS(mapBackendToLocal(meta.data, permissionMapTrueKey))));
  } else if (mode === modalModes.invite_company) {
    activeSection = 'email_company';
    yield put(handlePermissionActionSagaImport('changeSubmitColl', { value: 'option1' }));
  } else if (mode === modalModes.invite_partner) {
    activeSection = 'type';
    yield put(handlePermissionActionSagaImport('changeSubmitColl', { value: 'option2' }));
    yield hooks({
      currentSectionId: undefined,
      nextSectionId: activeSection,
      runPre: true,
    });
  }
  yield put(setListData('activeSection', activeSection));
}

function* unmountModal() {
  yield put(
    setListData(
      'modalMeta',
      fromJS({
        mode: undefined,
        meta: {},
      }),
    ),
  );
  yield put(resetSubmitData());
  yield put(setGlobalLoadingState(modalLoadingKey, 0));
  yield put(setGlobalLoadingState(modalLoadingNextKey, 0));
  yield put(setGlobalLoadingState(modalLoadingSubmitKey, 0));
}

function* initModalSaga({ meta: { mode, startupData } }) {
  if (mode === 'mount') {
    yield mountModal(startupData);
  }
  if (mode === 'unmount') {
    yield unmountModal(startupData);
  }
}

function matchCompanyModal(action) {
  return action.type === types.GLOBAL_SET_MODAL && !action.value && action.id === 'permissions_company';
}

function* unMountCompanyModal() {
  yield put(setGlobalLoadingState(teamLoadingKey, 0));
}

function* saga() {
  yield fork(takeEvery, types.PERMISSION_INIT, initPermissionsSaga);
  yield fork(takeEvery, types.PERMISSION_HANDLE_ACTION, handlePermissionActionSaga);
  yield fork(takeEvery, types.PERMISSION_INIT_MODAL, initModalSaga);
  yield fork(takeEvery, matchCompanyModal, unMountCompanyModal);
}

export default saga;
