import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import t from '@properly/localization';
import {
  TabBar,
  Button,
  MoreLess,
  Title1,
  Large,
  Regular,
  Small,
  SpaceTiny,
  SpaceSmall,
  SpaceBase,
  SpaceMedium,
  SpaceFlexi,
  ProfilePicture,
  PopoverDefault,
  ButtonList,
  Icon,
  BrandingAds,
} from '@properly/components';
import { cleanerStatus as CleanerStatus, featureFlag } from '@properly/config';
import lodashResult from 'lodash/result';
import map from 'lodash/map';
import moment from 'moment-timezone';
import { PageFlexOuter, PageTopBar, PagePadding, PageFlexInner } from '../../../../components/PageElements/index';
import TopLabel from '../../../../components/TopLabel/index';
import * as selectors from '../state/ContactSelectors';
import styles from './contactStyles.module.css';
import { getInitials } from '../../../../helper/herbert';
import {
  deleteContact,
  goToContactAll,
  goToContact,
  hideError,
  resendInvite,
  getCompletedSkill,
} from '../state/ContactActions';
import { ROUTES } from '../../../../paths';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import { updateInBackgroundSaga, setSliderSaga, setModal } from '../../../../actions/globalActions';
import { openJobRequestDetails } from '../../jobDetails/state/JobDetailsActions';
import ContactImageGrid from '../../../../components/ContactImageGrid/index';
import { prefilledDurationNewJobRequest } from '../../../../config';
import ErrorComponent from '../../../../components/ErrorComponent/index';
import HasPermission from '../../../../hoc/HasPermission/index';
import ContactErrorDeleteModal from './ContactErrorDeleteModal';
import { JobRequestTypicalTime, JobRequestBadges, reduceBadges } from '../../../../components/JobRequestElements/index';
import JobCleanerRequestList from '../../../../containers/JobCleanerRequestList/index';
// eslint-disable-next-line
import ContactTagContainer from './ContactTagContainer';
import {
  selectPartnerWebsiteUrl,
  selectIsPartnerDomain,
  selectAdStyles,
  selectContactPageAdFromAds,
} from '../../../branding/BrandingSelector';
import { selectIsFeatureFlagEnabledForUser } from '../../settings/state/SettingsSelectors';
import ProBadge from '../../../../components/ProBadge';

const cx = classNames.bind(styles);

function filterOnlyUpcoming(currentDate, contactData) {
  return item => {
    const { scheduledEndTime, propertyData, requestedCleaners } = item || {};
    const { timeZone } = propertyData || {};

    const cleanerStatus = item.getCleanerStatusByContactId(requestedCleaners, contactData.contactId);

    const upcomingStatus = [
      CleanerStatus.Pending,
      CleanerStatus.Accepted,
      CleanerStatus.InProgress,
      CleanerStatus.PendingChanges,
      CleanerStatus.Viewed,
    ];

    return (
      upcomingStatus.indexOf(cleanerStatus) !== -1 &&
      moment(scheduledEndTime)
        .tz(timeZone)
        .isAfter(currentDate)
    );
  };
}

class ContactDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMore: {},
    };
  }

  get nameString() {
    return `${this.userData.firstName} ${this.userData.lastName}`;
  }

  get emailString() {
    return this.userData.email ? this.userData.email : null;
  }

  get phoneString() {
    return this.userData.phone ? this.userData.phone : null;
  }

  get profilePic() {
    return this.userData.pictureUrl;
  }

  get profile() {
    const {
      contactData: { profile },
    } = this.props;

    return profile || {};
  }

  get userData() {
    const { contactData } = this.props;
    if (!contactData) return {};
    return contactData.userData;
  }

  get initialsUser() {
    return getInitials(this.userData.firstName, this.userData.lastName);
  }

  get isShowAble() {
    const { contactData } = this.props;
    return !(!contactData || contactData.deleted);
  }

  get hasBio() {
    return this.userData.bio;
  }

  get hasImages() {
    return this.profile.propertyPhotos && this.profile.propertyPhotos.length !== 0;
  }

  get skillTitles() {
    const { contactCompletedSkills } = this.props;
    if (!contactCompletedSkills) {
      return [];
    }

    return contactCompletedSkills.map(({ title }) => title || t('skills.no_skill'));
  }

  get hasBioOrImagesOrSkills() {
    return this.hasBio || this.hasImages || this.skillTitles.length > 0;
  }

  setOpenMore(id) {
    this.setState(prevState => {
      const val = prevState.openMore[id] === undefined ? false : prevState.openMore[id];
      const res = {
        openMore: {
          ...prevState.openMore,
          [id]: !val,
        },
      };
      return res;
    });
  }

  clickedDelete() {
    const { contactData } = this.props;
    const r = window.confirm(t('contacts.really_delete_contact')); // eslint-disable-line no-alert

    if (r === true) {
      this.props.deleteContact(contactData.objectId);
    }
  }

  get routeToSection() {
    const path = this.props.location.pathname;
    const { id } = this.props.params;

    if (path === ROUTES.contactPage(id)) return 2;
    if (path === ROUTES.contactPageAll(id)) return 1;

    return undefined;
  }

  openJobDetails = jobRequest => {
    const backwardCompatibilityObj = jobRequest.backwardCompatibility;
    if (!backwardCompatibilityObj || backwardCompatibilityObj === null) {
      this.props.openJobRequestDetails(jobRequest.jobId, true);
    } else {
      this.props.openJobRequestDetails(jobRequest.jobId, false, backwardCompatibilityObj.jobRequestId);
    }
  };

  renderSection(section, userId) {
    if (!section || !userId) return null;
    const upcomingActive = section === 2;
    const allActive = section === 1;

    return (
      <div>
        <TabBar>
          <TabBar.Item onClick={() => this.props.goToContact(this.props.params.id)} active={upcomingActive}>
            {t('contacts.upcoming_jobs')}
          </TabBar.Item>
          <TabBar.Item onClick={() => this.props.goToContactAll(this.props.params.id)} active={allActive}>
            {t('contacts.all_jobs')}
          </TabBar.Item>
        </TabBar>
        <JobCleanerRequestList
          mode="contact"
          ascending={upcomingActive}
          contactId={this.props.contactData.contactId}
          onlyUpcoming={upcomingActive}
          onClick={this.openJobDetails}
          filter={upcomingActive && filterOnlyUpcoming(moment().startOf('day'), this.props.contactData)}
        />
      </div>
    );
  }

  handleOpenSlider(images) {
    return index => {
      const mappedImages = map(images, image => ({
        image,
      }));
      this.props.setSliderSaga(true, mappedImages, index);
    };
  }

  sendJobRequestModal = () => {
    // Note: if we have lost our timezone here then our start and end times will be off if the user is not in the same timezone
    // Find a way to fetch property timezone at this point

    const cleaner = lodashResult(this.props.contactData, ['userData', 'objectId']);
    this.props.setModal(true, 'jobrequest', {
      mode: 'normal',
      source: 'contact',
      data: {
        cleaners: [cleaner],
        startTime: moment()
          .add(2, 'day')
          .hours(11)
          .minutes(0)
          .seconds(0), // eslint-disable-line
        endTime: moment()
          .add(2, 'day')
          .hours(11)
          .minutes(0)
          .seconds(0), // eslint-disable-line
        duration: prefilledDurationNewJobRequest,
        jobStartTimeType: 'fixed',
      },
    });
  };

  popWrap(input) {
    return (
      <PopoverDefault
        popoverKey="editContact"
        width={200}
        hasArrow
        arrowPosition={{
          horizontal: 'right',
          vertical: 'top',
        }}
        popoverContent={PopoverState => (
          <ButtonList
            closePopover={PopoverState.closePopup}
            items={[
              {
                'data-key': 'delete_contact',
                name: t('contacts.delete_contact'),
                onClick: () => this.clickedDelete(),
              },
              ...(!this.userData.signedUp
                ? [
                    {
                      'data-key': 'delete_contact',
                      name: t('contacts.resent_invite'),
                      onClick: () => this.props.resendInvite(null, this.props.contactData.objectId),
                    },
                  ]
                : []),
            ]}
          />
        )}
      >
        {input}
      </PopoverDefault>
    );
  }

  renderMoreInfo() {
    const onlyContact = this.userData.signedUp;

    return (
      this.hasBioOrImagesOrSkills && (
        <div>
          <MoreLess
            open={this.state.openMore[this.props.contactData.objectId]}
            onClick={() => this.setOpenMore(this.props.contactData.objectId)}
          >
            <div className={cx('contact__maxwidth')}>
              {this.hasBio && (
                <div>
                  <div>
                    <Small type="grey">{`${t('contacts.about_user')}:`}</Small>
                  </div>
                  <div>
                    <Regular>{this.userData.bio}</Regular>
                  </div>
                  <SpaceBase />
                </div>
              )}
              {onlyContact && this.skillTitles.length > 0 && (
                <div>
                  <Small type="grey">{`${t('skills.skills')}:`}</Small>
                  <SpaceBase />
                  {this.skillTitles.map(title => (
                    <Regular key={title} type="reseth1">
                      <div style={{ display: 'inline-flex', maxWidth: 'fit-content' }}>
                        {title}
                        <span style={{ marginLeft: '10px' }}>
                          <Icon.Ic16PxCheck height="20x" width="20px" byHeight />
                        </span>
                      </div>
                      <SpaceSmall />
                    </Regular>
                  ))}
                </div>
              )}

              {this.hasImages && (
                <div>
                  <div>
                    <Small type="grey">
                      {t('contacts.properties_cleaned', {
                        name: this.userData.firstName,
                      })}
                    </Small>
                  </div>
                  <SpaceSmall />
                  <div>
                    <ContactImageGrid
                      onClick={this.handleOpenSlider(this.profile.propertyPhotos)}
                      images={this.profile.propertyPhotos}
                    />
                  </div>
                </div>
              )}
            </div>
          </MoreLess>
        </div>
      )
    );
  }

  renderTop() {
    const showNotInvitedMessage = !this.userData.signedUp;
    const greyClass = showNotInvitedMessage ? { type: 'grey' } : {};
    const badgeArray = reduceBadges(
      lodashResult(this.profile, ['badges']),
      lodashResult(this.props.config, ['badgeOrder']),
      'big',
    );
    const { contactData, contactPageAd, adStyles, isPartnerDomain, partnerWebsiteUrl, canShowProBadge } = this.props;

    return (
      <div className={cx('contact__top-info')}>
        <div className={cx('contact__profile-pic')}>
          <ProfilePicture type="xx-large-circle" text={this.initialsUser} picture={this.profilePic} />
        </div>
        <div className={cx('contact__profile-main')}>
          <div className="contact__top-info">
            <Title1
              component="div"
              type="nomargin"
              style={{
                wordBreak: 'break-word',
                paddingRight: '24px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {this.nameString}
                {canShowProBadge && contactData.listSuggestedCleaner && (
                  <div style={{ marginLeft: '8px' }}>
                    <ProBadge modalRequired />
                  </div>
                )}
              </div>
            </Title1>
          </div>
          <SpaceTiny />
          {showNotInvitedMessage && (
            <div>
              <div className={cx('contact__notinvited')}>
                <Small>
                  {t('contacts.not_invited_yet', {
                    name: this.userData.firstName,
                  })}
                </Small>
              </div>
              <HasPermission
                hasAccessFormatter={hasAccessRes => hasAccessRes.canSendJobsGlobal}
                renderWithPermission={() => (
                  <span
                    data-key="resend_invite"
                    onClick={() => this.props.resendInvite(null, contactData.objectId)}
                    className={cx('contact__resent')}
                  >
                    {t('contacts.resent_invite_main')}
                  </span>
                )}
              />
              <SpaceBase />
            </div>
          )}
          <div>{!contactData.listSuggestedCleaner && <Large {...greyClass}>{this.emailString}</Large>}</div>
          <div>{!contactData.listSuggestedCleaner && <Large {...greyClass}>{this.phoneString}</Large>}</div>
          <SpaceSmall />
          <JobRequestTypicalTime size="big" responseTime={lodashResult(this.profile, ['responseTime'])} />
          <SpaceFlexi type="vertical" size="15px" />
          <JobRequestBadges size="big" badges={badgeArray} />
          <SpaceMedium />
        </div>
        <div className={cx('contact__send-job')}>
          {isPartnerDomain && (
            <BrandingAds
              brandingAd={contactPageAd}
              partnerWebsiteUrl={partnerWebsiteUrl}
              adStyles={adStyles}
              typeOneAd
            />
          )}
          <div style={{ alignSelf: 'flex-end', flexShrink: 0 }}>
            <Button
              data-key="button_send_job_contact"
              onClick={this.sendJobRequestModal}
              types={['type-full-primary', 'size-large']}
            >
              {t('properties.send_job_request')}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderWhat() {
    if (!this.isShowAble) {
      return <ErrorComponent is404 headline={t('error.404error')} subHeadline={t('error.looks_like_smth_wrong')} />;
    }

    return (
      <div>
        <div className={cx('contact__top')}>
          <div>{this.renderTop()}</div>
          {this.renderMoreInfo()}
        </div>

        {this.renderSection(this.routeToSection, this.props.params.id)}
      </div>
    );
  }

  onlyAccepted(props) {
    return ROUTES.contactPage(props.params.id) === props.location.pathname;
  }

  componentDidMount() {
    // eslint-disable-next-line no-shadow
    const { getCompletedSkill, params: { id } = {}, updateInBackgroundSaga } = this.props;
    updateInBackgroundSaga('contacts', id);
    getCompletedSkill(id);
  }

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line no-shadow
    const { getCompletedSkill, params: { id } = {} } = this.props;

    if (id !== prevProps.params.id) {
      getCompletedSkill(id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.params.id !== this.props.params.id) {
      this.props.updateInBackgroundSaga('contacts', nextProps.params.id);
    }
  }

  get isNotMe() {
    if (this.props.currentUser.user && this.props.contactData.userData.email === this.props.currentUser.user.email) {
      return false;
    }
    return true;
  }

  render() {
    const { children } = this.props;
    return (
      <>
        <PageFlexOuter grey>
          <PageTopBar>
            <div style={{ position: 'relative' }} id="topbar1">
              {this.isShowAble && this.isNotMe && (
                <HasPermission
                  hasAccessFormatter={hasAccessRes => hasAccessRes.canSendJobsGlobal}
                  render={hasAccess => (
                    <TopLabel
                      right
                      extraPadding
                      isEmpty={!hasAccess}
                      disabled={!hasAccess}
                      data-key="button_popover_service_provider"
                      label={t('properties.edit')}
                      wrap={input => this.popWrap(input)}
                    />
                  )}
                />
              )}
            </div>
          </PageTopBar>
          <PageFlexInner>
            <PagePadding variation="property" type="absolute-scroll">
              {this.renderWhat()}
            </PagePadding>
          </PageFlexInner>
          <ContactErrorDeleteModal />
        </PageFlexOuter>
        {children}
      </>
    );
  }
}

ContactDetailContainer.propTypes = {
  contactPageAd: PropTypes.shape({}),
  adStyles: PropTypes.shape({}),
  isPartnerDomain: PropTypes.bool,
  partnerWebsiteUrl: PropTypes.string,
};

ContactDetailContainer.defaultProps = {
  contactPageAd: {},
  adStyles: {},
  isPartnerDomain: false,
  partnerWebsiteUrl: '',
};

function mapStateToProps(state, props) {
  const isProMarketPropertyExists = selectorsGlobal.selectIfProMarketPropertyExists()(state);
  const isMarketPlaceFeatureFlagEnabled = selectIsFeatureFlagEnabledForUser(featureFlag.FEATURE_FLAG_MARKETPLACE)(
    state,
    props,
  );

  return {
    currentUser: state.currentUser,
    contactData: selectorsGlobal.selectContact(props.params.id)(state, props),
    paginationState: selectors.selectPaginationState()(state, props),
    config: selectorsGlobal.selectConfig(state, props),
    contactPageAd: selectContactPageAdFromAds()(state) && selectContactPageAdFromAds()(state).toJS(),
    adStyles: selectAdStyles()(state)?.toJS(),
    isPartnerDomain: selectIsPartnerDomain()(state),
    partnerWebsiteUrl: selectPartnerWebsiteUrl()(state),
    contactCompletedSkills: selectors.selectContactCompletedSkills(props.params.id)(state, props),
    canShowProBadge: isMarketPlaceFeatureFlagEnabled && isProMarketPropertyExists,
  };
}

export default connect(mapStateToProps, {
  deleteContact,
  goToContactAll,
  goToContact,
  hideError,
  resendInvite,
  openJobRequestDetails,
  setSliderSaga,
  updateInBackgroundSaga,
  setModal,
  getCompletedSkill,
})(ContactDetailContainer);
