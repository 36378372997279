import React from 'react';
import PropTypes from 'prop-types';
import values from 'lodash/values';
import { SpaceSmall } from '@properly/components';
import t from '@properly/localization';
import InfoMessage from '../../../../components/InfoMessage/index';
import {
  JobRequestPriceInfo,
  JobRequestPopover,
  JobRequestTextMain,
  JobRequestTextSub,
  JobRequestContentWrap,
  JobRequestSpacerWrap,
  JobRequestPrice,
  JobRequestCenterFlex,
} from '../../../../components/JobRequestElements/index';
import { parseNumber } from '../../../../helper/herbert';
import JobPriceFormFixed from '../../../../components/JobRequestPriceFormFixed';

function Price({
  finalCurrency,
  finalPrice,
  finalCleaners,
  handleClickSaga,
  infoTextMsg,
  finalProperty,
  isMarketPlaceFeatureFlagEnabled,
  offeredPriceLimit,
}) {
  const setPrice = e => val => handleClickSaga(e, { action: 'setprice', value: val });

  const isProCleanerSelected = cleaners => values(cleaners).some(cleaner => cleaner?.getProStatus?.());
  const renderPriceInfo = cleaners => {
    if (!(isMarketPlaceFeatureFlagEnabled && finalProperty?.proMarketProperty && isProCleanerSelected(cleaners))) {
      return (
        <JobRequestPriceInfo isSmall>
          <JobRequestTextMain>
            <InfoMessage text={t('job_request.price_info')} isPrompt isSmall color="bg-jobrequest" />
          </JobRequestTextMain>
        </JobRequestPriceInfo>
      );
    }

    // We've got at least as one pro cleaner -- present the offered price popover.
    return (
      <JobRequestPriceInfo isSmall>
        <JobRequestTextMain>
          <JobRequestPopover
            popoverContent={t('job_request.offered_price_info_popover')}
            width={400}
            hasPadding
            hasArrow
            arrowPosition={{
              horizontal: 'center',
              vertical: 'top',
            }}
          >
            <InfoMessage text={t('job_request.price_info')} isPrompt isSmall icon="warning" color="bg-jobrequest" />
          </JobRequestPopover>
        </JobRequestTextMain>
      </JobRequestPriceInfo>
    );
  };

  const renderJobPriceForm = () => (
    <JobRequestPrice>
      <JobRequestCenterFlex>
        <JobPriceFormFixed
          parseNumber={parseNumber}
          finalCurrency={finalCurrency}
          finalPrice={finalPrice}
          offeredPriceLimit={offeredPriceLimit}
          setPrice={setPrice}
        />
        {renderPriceInfo(finalCleaners)}
      </JobRequestCenterFlex>
    </JobRequestPrice>
  );

  return (
    <JobRequestContentWrap id="job-request-price-form">
      <JobRequestSpacerWrap left="24px" top="12px" bottom="12px" right="24px" flexGrow isRelative>
        {renderJobPriceForm()}
        <SpaceSmall />
      </JobRequestSpacerWrap>
      <InfoMessage text={infoTextMsg} isPrompt isVcenter isHcenter icon="light" color="white" />
    </JobRequestContentWrap>
  );
}

Price.propTypes = {
  finalCurrency: PropTypes.string,
  finalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  finalCleaners: PropTypes.shape({}),
  handleClickSaga: PropTypes.func.isRequired,
  infoTextMsg: PropTypes.string.isRequired,
  isMarketPlaceFeatureFlagEnabled: PropTypes.bool,
};

Price.defaultProps = {
  finalCurrency: '',
  finalPrice: '',
  finalCleaners: {},
  isMarketPlaceFeatureFlagEnabled: false,
};

Price.content = ({ finalCurrency, finalPrice }) => (
  <div>
    <JobRequestTextMain>{finalCurrency}</JobRequestTextMain>
    <JobRequestTextSub>{finalPrice}</JobRequestTextSub>
  </div>
);

Price.content.displayName = 'PriceContent';

Price.content.propTypes = {
  finalCurrency: PropTypes.string,
  finalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  finalCleaners: PropTypes.shape({}),
};

Price.content.defaultProps = {
  finalCurrency: '',
  finalPrice: '',
  finalCleaners: {},
};

export default Price;
