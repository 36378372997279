import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { DragSource, DropTarget } from 'react-dnd';
import classNames from 'classnames/bind';
import styles from './checklistsStyles.module.css';

const cx = classNames.bind(styles);

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
      children: props.children,
    };
  },
  canDrag: props => !props.isReadOnly,
};

const cardTarget = {
  hover(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) {
      return;
    }

    props.moveCard(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex; //eslint-disable-line
  },
};

class ChecklistField extends PureComponent {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
    moveCard: PropTypes.func.isRequired, // eslint-disable-line
    id: PropTypes.any.isRequired, // eslint-disable-line
  };

  render() {
    const { connectDragSource, connectDropTarget, children, isDragging, isOver } = this.props;
    const preview = isDragging || isOver;
    return connectDragSource(
      connectDropTarget(
        <div>
          {!preview && children}
          {preview && (
            <div
              className={cx('checklist__placeholder', {
                indicate: isOver,
              })}
            />
          )}
        </div>,
      ),
    );
  }
}

export default compose(
  DropTarget('CARD', cardTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  })),
  DragSource('CARD', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  })),
)(ChecklistField);
