import { push } from 'react-router-redux';
import * as types from '../types';
import { ROUTES } from '../paths';

export function setPreloadData(data) {
  return {
    type: types.GLOBAL_SET_PRELOAD_DATA,
    data,
  };
}

export function goUpgrade() {
  return push({ pathname: ROUTES.settingsSubscription });
}

export function goCheckoutUpgrade(planId) {
  return push({ pathname: ROUTES.checkout, query: { planId, mode: 'upgrade' } });
}

export function goCheckoutUpdate() {
  return push({ pathname: ROUTES.checkout, query: { mode: 'update' } });
}

export function globalLogout() {
  return {
    type: types.GLOBAL_LOGOUT,
  };
}

export function updateCurrentUser(user) {
  return {
    type: types.USER_UPDATE_CURRENT_USER,
    user,
  };
}

export function updateCurrentUserSubscription(sub) {
  return {
    type: types.USER_UPDATE_CURRENT_USER_SUBSCRIPTION,
    sub,
  };
}

export function setParseConfig(val) {
  return {
    type: types.SET_PARSE_CONFIG,
    val,
  };
}

export function setGeneralConfig(val) {
  return {
    type: types.SET_GENERAL_CONFIG,
    val,
  };
}

export function preLoginSuccess() {
  return {
    type: types.GLOBAL_PRE_LOGIN_SUCCESS,
  };
}

export function startPreloadSaga(fromWhere) {
  return {
    type: types.START_PRELOAD_SAGA,
    fromWhere,
  };
}

export function startPreloadSagaWithDelay(fromWhere) {
  return {
    type: types.START_PRELOAD_SAGA_WITH_DELAY,
    fromWhere,
  };
}

export function setErrorModal(collectionName, action, location) {
  return {
    type: types.SET_ERROR_MODAL,
    collectionName,
    action,
    location,
  };
}

export function updateCollectionEntry(collectionName, id, changeSet) {
  return {
    type: types.UPDATE_COLLECTION_ENTRY,
    collectionName,
    id,
    changeSet,
  };
}

export function setSearchQuery(index, val) {
  return {
    type: types.GLOBAL_SET_GLOBAL_SEARCH_QUERY,
    val,
    index,
  };
}

export function updateInBackgroundSaga(collectionName, id, isPagination) {
  return {
    type: types.GLOBAL_UPDATE_IN_BACKGROUND,
    id,
    isPagination,
    collectionName,
  };
}

export function setSliderSaga(open, images, index, meta) {
  return {
    type: types.GLOBAL_SET_SLIDER_SAGA,
    open,
    images,
    index,
    meta,
  };
}

export function setSliderData(changeSet) {
  return {
    type: types.SET_SLIDER_STATE,
    changeSet,
  };
}

export function updateCollectionEntryBatch(collectionName, batchChange, isPagination) {
  return {
    type: types.UPDATE_COLLECTION_ENTRY_BATCH,
    collectionName,
    batchChange,
    isPagination,
  };
}

export function filterCollectionEntryBatch(collectionName, filter) {
  return {
    type: types.FILTER_COLLECTION_ENTRY_BATCH,
    collectionName,
    filter,
  };
}

export function goToProperty(id) {
  return push(ROUTES.propertyPage(id));
}

export function goTo(path) {
  return push(path);
}

export function preLoginSaga() {
  return {
    type: types.GLOBAL_PRE_LOGIN_SAGA,
  };
}

export function foundCurrentCountryByIp(currentCountry) {
  return {
    type: types.SETTINGS_COUNTRY_SUCCESS,
    currentCountry,
  };
}

export function setPreloadStatus(val) {
  return {
    type: types.GLOBAL_SET_PRELOAD_STATUS,
    val,
  };
}

export function refetchUserSaga() {
  return {
    type: types.GLOBAL_USER_REFETCH_SAGA,
  };
}

export function interceptSaga(originalAction) {
  return {
    type: types.GLOBAL_INTERCEPT_SAGA,
    originalAction,
  };
}

export function interceptModalSaga(originalAction) {
  return {
    type: types.GLOBAL_INTERCEPT_MODAL_SAGA,
    originalAction,
  };
}

export function setGlobalLoadingState(key, value, meta) {
  return {
    type: types.SET_GLOBAL_LOADING_STATE,
    key,
    value,
    meta,
  };
}

export function setModal(value, id, meta) {
  return {
    type: types.GLOBAL_SET_MODAL,
    value,
    id,
    meta,
  };
}

export function setDashBoardAdminDetails(adminName, adminSession) {
  return {
    type: types.SET_DASHBOARD_ADMIN_DETAILS,
    adminName,
    adminSession,
  };
}

export function setUserEmailAutoPopulate(email) {
  return {
    type: types.SET_USER_EMAIL_TO_AUTO_POPULATE,
    email,
  };
}

export function resetUserEmailAutoPopulate() {
  return {
    type: types.RESET_USER_EMAIL_TO_AUTO_POPULATE,
  };
}
