"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCleanerInitials = getCleanerInitials;
exports.getCleanerFullName = getCleanerFullName;
exports.getCleanerInfoString = getCleanerInfoString;

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

var _localization = _interopRequireDefault(require("@properly/localization"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getCleanerInitials(firstName, lastName) {
  const getFirstLetter = name => String(name).slice(0, 1).toUpperCase();

  if (firstName && lastName) {
    return getFirstLetter(firstName) + getFirstLetter(lastName);
  }

  return '';
}

function getCleanerFullName(firstName = '', lastName = '', isSameUser = false) {
  return `${firstName} ${lastName} ${isSameUser ? '(me)' : ''}`.trim();
}

function getCleanerInfoString({
  signedUp,
  signedUpDate,
  lastJobSent
} = {}) {
  const formatDate = date => (0, _momentTimezone.default)(date).format('M/D/YY');

  let infoString;
  let color;

  if (signedUp) {
    infoString = (0, _localization.default)('contacts.contact_joined', {
      when: formatDate(signedUpDate)
    });
    color = 'inherit';
  }

  if (lastJobSent) {
    infoString = (0, _localization.default)('contacts.last_job_sent', {
      when: formatDate(lastJobSent)
    });
    color = 'inherit';
  }

  if (!signedUp) {
    infoString = (0, _localization.default)('contacts.invite_pending');
    color = '#36ACB2';
  }

  return {
    value: infoString,
    color
  };
}