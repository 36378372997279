import forOwn from 'lodash/forOwn';

export default class Report {
  constructor(id, title, filter, createdAt, updatedAt, user, deleted) {
    this.objectId = id;
    this.title = title;
    this.filter = filter;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.user = user;
    this.deleted = deleted;
  }

  setData(obj) {
    this.getOwnProps(obj, this);
  }

  getData() {
    return this.getOwnProps(this);
  }

  getOwnProps(scope, onScope) {
    const obj = onScope || {};
    forOwn(scope, (value, key) => {
      obj[key] = value;
    });
    return obj;
  }
}
