import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import t from '@properly/localization';
import { Small, Icon, Pony } from '@properly/components';
import styles from './index.module.css';

const cx = classNames.bind(styles);

function TopBarLoginSignup(props) {
  const { logo: Logo, centerLogo } = props;

  const logoIcon =
    typeof Logo !== 'string' ? (
      Logo && (
        <Icon>
          <Icon.LogoFull width="123px" height="44px" byWidth />
        </Icon>
      )
    ) : (
      <Icon.LogoFull byWidth src={Logo} />
    );
  return (
    <header className={cx('topbar')}>
      <div className={cx('topbar__logo', { 'topbar__logo-center': centerLogo })}>
        <Pony>{logoIcon}</Pony>
      </div>
      <div
        data-key={`toast-${props.showToast ? 'is-shown' : 'is-not-shown'}`}
        className={cx('toast', 'success', { 'toast-is-shown': props.showToast })}
      >
        <Small>{props.message}</Small>
      </div>
      <div>
        {props.showSignUp && (
          <div className={cx('topbar__text')}>
            <Small type="grey">
              {t('signup.no_account')}{' '}
              <span onClick={props.onClickCreate} className={cx('topbar__link')}>
                {t('signup.form.cta', {})}
              </span>
            </Small>
          </div>
        )}
        {props.showLogin && (
          <Small type="grey">
            {t('signup.have_account')}{' '}
            <span onClick={props.onClickLogin} className={cx('topbar__link')}>
              {t('signup.login', {})}
            </span>
          </Small>
        )}
        {(!props.showSignUp || !props.showLogin) && <div style={{ width: 138 }} />}
      </div>
    </header>
  );
}

TopBarLoginSignup.propTypes = {
  message: PropTypes.string,
  logo: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.string, PropTypes.bool]),
  onClickCreate: PropTypes.func,
  onClickLogin: PropTypes.func,
  showToast: PropTypes.bool,
  showSignUp: PropTypes.bool,
  showLogin: PropTypes.bool,
  centerLogo: PropTypes.bool,
};

export default TopBarLoginSignup;
