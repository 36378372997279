import Contact from '../../model/contact';
import UserData from '../../model/userData';

export function userDataModelMapper(userData) {
  return new UserData(
    userData.objectId,
    userData.firstName,
    userData.lastName,
    userData.shortName,
    userData.email,
    userData.phone,
    userData.pictureUrl,
    userData.bio,
    userData.signedUp,
    userData.signedUpDate,
    userData.phoneCountryCode,
    userData.phoneRegionalNumber,
  );
}

export function contactModelMapper(contact) {
  const hasGet = contact && !!contact.get;
  const get = key => (hasGet ? contact.get(key) : contact[key]);

  return new Contact(
    contact.id || contact.objectId,
    get('ownerId'),
    get('contactId'),
    get('userData') ? userDataModelMapper(get('userData')) : null,
    get('lastJobSent'),
    get('deleted'),
    get('isSuggested'),
  );
}
