import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import t from '@properly/localization';
import { PropertyImage, SpaceFlexi, Input, Button, Icon, HoverTriggeredTooltip } from '@properly/components';
import { pictureSizes, generatePictureUrl } from '@properly/common';
import classNames from 'classnames/bind';
import styles from './permissionStyles.css';
import UploadArea from '../../../components/UploadArea';
import { uploadImage } from '../data';
import CheckoutLabel from '../../../components/CheckoutLabel';
import { TodosTextEle } from '../../../components/TodosElements';
import { mediaUploadLoadingKey } from '../../../dataConstants';

const cx = classNames.bind(styles);

function mapToErrorMsg(code) {
  switch (code) {
    default:
    case 3:
      return 'permissions.error.unknown';
    case 4:
      return 'permissions.error.email_blacklisted';
    case 5:
      return 'permissions.error.email_domain_used';
  }
}

class PermissionTeamBoxNew extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({}),
    isLoading: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    isSave: PropTypes.bool.isRequired,
    createFunc: PropTypes.func.isRequired,
    getter: PropTypes.func.isRequired,
    setter: PropTypes.func.isRequired,
    saveFunc: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired,
  };

  maxLengthDes = 200;

  state = {
    file: undefined,
  };

  getImageState(file, userPic) {
    if (file) return { text: '', state: 'loading', image: file.preview };
    if (userPic) {
      return {
        text: t('account.change_image'),
        state: 'set',
        image: generatePictureUrl(userPic, pictureSizes.PROFILE_PICTURE),
      };
    }
    return { state: 'dropable', image: '' };
  }

  resetImage = () => {
    this.setState(state => ({ ...state, file: undefined }));
  };

  setImage = data => {
    if (data && data[0]) {
      this.setState({ file: data[0] });
      uploadImage(data[0])
        .then(res => {
          this.props.setter('pictureUrl')(res.pictureUrl);
          this.resetImage();
        })
        .catch(() => {
          this.resetImage();
          this.props.setGlobalLoadingState(mediaUploadLoadingKey, 3);
        });
    }
  };

  get des() {
    return this.props.getter('description') || '';
  }

  desChangeProxy = e => {
    if (e.target.value.length > this.maxLengthDes) return;
    this.props.setter('description')(e);
  };

  render() {
    const imageState = this.getImageState(this.state.file, this.props.getter('pictureUrl'));
    const { imageUploadErrored, setGlobalLoadingState: setGlobalLoadingAction } = this.props;
    return (
      <div style={{ padding: '20px 33px' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className={cx('permissions__centerbtn2')}>
            <PropertyImage type={['image--company']} image={imageState.image}>
              <UploadArea
                type="permission"
                onDropAccepted={this.setImage}
                state={imageState.state}
                imageUploadErrored={imageUploadErrored}
                setGlobalLoadingAction={setGlobalLoadingAction}
                text={
                  <div style={{ textAlign: 'center' }}>
                    <TodosTextEle modifier={['txt--l7m', 'color--white']}>
                      {t('permissions.upload_company_logo')}
                    </TodosTextEle>
                    <SpaceFlexi type="vertical" size="3px" />
                    <TodosTextEle modifier={['txt--l7', 'color--white']}>{t('permissions.upload_size')}</TodosTextEle>
                  </div>
                }
              />
            </PropertyImage>
          </div>
          <SpaceFlexi type="vertical" size="28px" />
          <div>
            <CheckoutLabel label={t('permissions.company_name')} isRequired />
            <Input
              isFirst
              isLast
              styleOverwrite={{ backgroundColor: '#FFF' }}
              style={{
                fontSize: '14px',
                lineHeight: '18px',
              }}
              onChange={this.props.setter('name')}
              value={this.props.getter('name') || ''}
              placeholder={t('permissions.untitled_team')}
              type="text"
            />
            <SpaceFlexi type="vertical" size="28px" />
            <CheckoutLabel
              label={t('permissions.your_company_email')}
              isRequired
              icon={
                <HoverTriggeredTooltip title={t('permissions.modal.admin_email_tooltip')}>
                  <span style={{ margin: '0 2px', display: 'block' }}>
                    <Icon.IcInfocvv />
                  </span>
                </HoverTriggeredTooltip>
              }
            />
            <Input
              isFirst
              isLast
              styleOverwrite={{ backgroundColor: '#FFF' }}
              style={{
                fontSize: '14px',
                lineHeight: '18px',
              }}
              error={!!this.props.error}
              onChange={this.props.setter('email')}
              value={this.props.getter('email') || ''}
              type="text"
            />
            <SpaceFlexi type="vertical" size="28px">
              {this.props.error && <Input.ErrorMsg>{t(mapToErrorMsg(this.props.error))}</Input.ErrorMsg>}
            </SpaceFlexi>
            <CheckoutLabel label={t('permissions.company_profile')} />
            <Input
              isFirst
              isLast
              styleOverwrite={{ backgroundColor: '#FFF', height: '100px' }}
              style={{
                fontSize: '14px',
                lineHeight: '18px',
              }}
              onChange={this.desChangeProxy}
              value={this.des}
              placeholder={t('permissions.des_placeholder')}
              type="textarea"
            />
            <SpaceFlexi type="vertical" size="3px" />
            <TodosTextEle modifier={['txt--l7', 'color--grey']}>
              {t('permissions.remaining_chars', {
                count: this.maxLengthDes - this.des.length,
              })}
            </TodosTextEle>
          </div>
          <SpaceFlexi type="vertical" size="18px" />
          <div className={cx('permissions__centerbtn2')}>
            {!this.props.isSave && (
              <Button
                types={['type-full-primary-job', 'size-large']}
                disabled={!this.props.isValid || this.props.isLoading}
                onClick={this.props.createFunc(this.props.data)}
                isLoading={this.props.isLoading}
              >
                {t('permissions.create_team')}
              </Button>
            )}
            {this.props.isSave && (
              <Button
                disabled={!this.props.isValid || this.props.isLoading}
                types={['type-full-primary', 'size-large']}
                onClick={this.props.saveFunc(this.props.data)}
                isLoading={this.props.isLoading}
              >
                {t('permissions.update')}
              </Button>
            )}
          </div>
          <SpaceFlexi type="vertical" size="6px" />
          <div style={{ textAlign: 'center' }}>
            <TodosTextEle modifier={['txt--l7', 'color--grey']}>{t('permissions.company_note')}</TodosTextEle>
          </div>
        </div>
      </div>
    );
  }
}

export default PermissionTeamBoxNew;
