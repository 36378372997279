import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Icon, ModalError } from '@properly/components';
import { imageUploadLimit } from '@properly/common';
import classNames from 'classnames/bind';
import styles from './index.module.css';
import { mediaUploadLoadingKey } from '../../dataConstants';

const { minSize, maxSize } = imageUploadLimit;

const cx = classNames.bind(styles);

const UploadArea = ({ onDropAccepted, state, text, type, imageUploadErrored, setGlobalLoadingAction }) => {
  const [showModal, setShowModal] = useState(false);
  // eslint-disable-next-line
  const onDropRejected = () => {
    setShowModal(true);
  };

  const handleOnCloseErrorModal = () => {
    setGlobalLoadingAction(mediaUploadLoadingKey);
  };

  return (
    <>
      <div
        className={cx('droparea', {
          'droparea--hidden': state === 'hidden',
        })}
      >
        {state !== 'loading' && (
          <Dropzone
            multiple={false}
            maxSize={maxSize}
            minSize={minSize}
            style={{ width: '100%', height: '100%', borderRadius: 'inherit' }}
            accept="image/*"
            onDropAccepted={onDropAccepted}
            onDropRejected={onDropRejected}
          >
            <div
              className={cx('droparea__inner', 'droparea__inner--cursor', {
                'droparea__inner--set': state === 'set',
                'droparea__inner--small': type === 'small',
                'droparea__inner--permission': type === 'permission',
                'droparea__inner--property': type === 'property',
              })}
            >
              <div className={cx('droparea__icon')}>
                <Icon.IcNoPhoto color="#fff" />
              </div>
              <div className={cx('droparea__txt')}>{text}</div>
            </div>
          </Dropzone>
        )}
        {state === 'loading' && <div className={cx('droparea__inner', 'droparea__inner--light')} />}
      </div>

      {/* Modal to show for image size limit validation or if upload fails */}
      <ModalError.ImageUpload
        onCloseLimitError={() => setShowModal(false)}
        showLimitError={showModal}
        showUploadError={imageUploadErrored}
        onCloseUploadError={handleOnCloseErrorModal}
      />
    </>
  );
};

UploadArea.IconOverlay = ({ isSet, text }) => (
  <div
    className={cx('droparea__inner', 'droparea__inner--cursor', {
      'droparea__inner--set': isSet,
      'droparea__inner--small': isSet,
    })}
  >
    {!isSet && (
      <div className={cx('droparea__icon')}>
        {/* <img role="presentation" src={Icon} /> */}
        <Icon.IcNoPhoto color="#fff" />
      </div>
    )}
    {isSet || <div className={cx('droparea__txt')}>{text}</div>}
  </div>
);

UploadArea.propTypes = {
  onDropAccepted: PropTypes.func.isRequired,
  setGlobalLoadingAction: PropTypes.func.isRequired,
  imageUploadErrored: PropTypes.bool.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
  state: PropTypes.oneOf(['loading', 'dropable', 'hidden', 'set']),
};

export default UploadArea;
