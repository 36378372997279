import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const TopLabel = ({
  onClick,
  label,
  icon: TopLabelIcon,
  wrap,
  right,
  extraPadding,
  onlyTxtCursor,
  paddingRight,
  noPadding,
  disabled,
  black,
  wrapKey,
  center,
  isEmpty,
  ...more
}) => {
  let finalLabel = <div data-key={wrapKey}>{label}</div>;
  if (wrap && !disabled) {
    finalLabel = wrap(finalLabel);
  }
  return (
    <div
      onClick={!disabled ? onClick : noop}
      className={cx('top-label', {
        'top-label--right': right,
        'top-label--center': center,
        'top-label--padding': extraPadding,
        'top-label--onlytxtcursor': onlyTxtCursor,
        'top-label--paddingright': paddingRight,
        'top-label--nopadding': noPadding,
        'top-label--disabled': disabled,
        'top-label--black': black,
      })}
    >
      {!isEmpty && (
        <span
          data-key={more['data-key']}
          className={cx({
            'top-label__txt': onlyTxtCursor,
          })}
        >
          {finalLabel}
        </span>
      )}
      {TopLabelIcon && !isEmpty && (
        <div className={cx('top-label__icon')}>
          <TopLabelIcon width="24px" byWidth />
        </div>
      )}
    </div>
  );
};

TopLabel.Wrap = props => (
  <div className={cx('top-label', 'top-label__wrap', 'top-label--padding')}>{props.children}</div>
);

TopLabel.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  extraPadding: PropTypes.bool,
  right: PropTypes.bool,
  paddingRight: PropTypes.bool,
  noPadding: PropTypes.bool,
  icon: PropTypes.func,
  wrap: PropTypes.func,
};

export default TopLabel;
