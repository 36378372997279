import React, { Component } from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';

class RerenderEveryX extends Component {
  componentDidMount() {
    const that = this;
    this.timeoutHandler = setInterval(() => {
      that.forceUpdate();
    }, this.props.seconds * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timeoutHandler);
  }

  render() {
    return this.props.content() || null;
  }
}

RerenderEveryX.propTypes = {
  seconds: PropTypes.string.isRequired,
  content: PropTypes.func.isRequired,
};

export default RerenderEveryX;
