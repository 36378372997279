import gql from 'graphql-tag';

export const GetPropertyTags = gql`
  {
    propertyTags {
      label
      value
    }
  }
`;

export const GetPropertyICalConnections = gql`
  query ICalAdaptorSettingsQuery($propertyId: String) {
    ICalAdaptorSettings(propertyId: $propertyId) {
      id
      title
      link
      createdAt
      updatedAt
      lastSync
      targetPropertyId
      targetAccountId
    }
  }
`;

export const CreatePropertyICalConnection = gql`
  mutation ICalAdaptorSettingsCreateMutation($setting: NewICalAdaptorSettingInput) {
    CreateICalAdaptorSetting(setting: $setting) {
      id
      title
      link
      createdAt
      updatedAt
      lastSync
      targetPropertyId
      targetAccountId
    }
  }
`;

export const DeletePropertyICalConnection = gql`
  mutation ICalAdaptorSettingsDeleteMutation($settingId: ID!) {
    DeleteICalAdaptorSetting(settingId: $settingId) {
      id
      title
      link
      createdAt
      updatedAt
      lastSync
      targetPropertyId
      targetAccountId
    }
  }
`;

export const MergePropertiesQuery = gql`
  mutation MergePropertiesMutation($propertyIds: [String]!) {
    MergeProperties(propertyIds: $propertyIds) {
      id
      createdAt
      updatedAt
    }
  }
`;
