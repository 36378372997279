import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectStripe } from 'react-stripe-elements';
import log from 'loglevel';
import { withStyles } from '@material-ui/core/styles';
import { getConfig } from '@properly/config';
import { SquareSelect, Wizard } from '@properly/components';
import t from '@properly/localization';
import colors from '@properly/components/lib/common/styles/colors.module.css';
import { connect } from 'react-redux';
import StepsAch from './stepsAch';
import StepsCard from './stepsCard';
import StepsBankAccount from './stepsBankAccount';
import {
  stripeSetupIntent,
  addStripeCustomerPaymentMethod,
  addStripeCustomerSource,
  addPlaidStripeCustomerSource,
  resetModalState,
  resetIntentState,
} from './paymentInterceptActions';
import * as selectors from './paymentInterceptSelectors';
import { selectConfig } from '../../selectors/globalSelector';

const MODAL_STATE_LOADING = 1; // eslint-disable-line no-unused-vars
const MODAL_STATE_SUCCESS = 2;
const MODAL_STATE_ERROR = 3;

const STEP_0 = 0;
const STEP_1 = 1;
const STEP_2_SUCCESS_OR_FAIL = 2; // eslint-disable-line no-unused-vars

const styles = () => ({
  label: {
    fontSize: '14px',
  },
  link: {
    color: colors['color-primary'],
    textDecoration: 'none',
    '&:hover': {
      color: colors['color-primary-hover'],
    },
  },
  stripeElement: {
    border: 0,
    outline: 'none',
    width: '100%',
    padding: '12px 16px',
    color: colors['color-black'],
  },
  cardErrors: {
    color: colors['color-orange'],
    minHeight: '14px',
    lineHeight: 1,
  },
});

class WizardWithContent extends Component {
  static propTypes = {
    classes: PropTypes.shape({}),
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    textAlign: PropTypes.string,
    showProgress: PropTypes.bool,
    useMarketplace: PropTypes.bool.isRequired,
    useAch: PropTypes.bool,
    useBankAccounts: PropTypes.bool,
    useCards: PropTypes.bool,
  };

  static defaultProps = {
    classes: {},
    onClose: () => {},
    onFinish: () => {},
    textAlign: undefined,
    showProgress: false,
    useAch: false,
    useBankAccounts: false,
    useCards: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      stripe: null,
      activeStep: 0,
      nextEnabled: false,
      error: false,
      steps: [],
      name: '',
      plaidPublicToken: '',
      routingNumber: '',
      accountNumber: '',
      accountHolderName: '',
      accountHolderType: 'individual',
      setAsDefault: this.forceSetAsDefault,
      stepAfterLoading: undefined,
      cardNumberElement: undefined,
    };
  }

  componentWillMount() {
    this.setState(prevState => ({
      steps: this.setStepContent(prevState),
    }));
  }

  componentDidUpdate() {
    const { loading } = this.state;

    if (loading) {
      this.checkModalState();
    }
  }

  componentWillUnmount() {
    const { resetModalState, resetIntentState } = this.props; // eslint-disable-line no-shadow

    resetModalState();
    resetIntentState();
  }

  get forceSetAsDefault() {
    const { defaultPaymentMethod, cards, bankAccounts } = this.stripeCustomer;
    const noCards = !(cards && cards.length);
    const noBankAccounts = !(bankAccounts && bankAccounts.length);

    // If we don't yet have a default payment method,
    // ensure the one we create will be set as default
    return !defaultPaymentMethod || (noCards && noBankAccounts);
  }

  get stripeCustomer() {
    const { stripeCustomer } = this.props;
    return stripeCustomer?.toJS();
  }

  get modalState() {
    const { modalState } = this.props;
    return modalState?.toJS();
  }

  get intentState() {
    const { intentState } = this.props;
    return intentState?.toJS();
  }

  onChangeName = name => {
    if (!name) {
      return this.setState(prevState => ({
        steps: this.setStepContent(
          { ...prevState, name },
          { message: t('job_request.wizard.name_invalid'), elementType: 'accountName' },
        ),
      }));
    }

    return this.setState(prevState => ({
      name,
      steps: this.setStepContent({ ...prevState, name }),
    }));
  };

  onPlaidSuccess = (plaidPublicToken, metadata) => {
    // determine the account id
    const { account, accounts } = metadata;
    const accountToUse = account.id ? account : accounts.length && accounts[0];
    const plaidAccountId = accountToUse.id;
    log.info('onPlaidSuccess - plaidPublicToken', plaidPublicToken);
    log.info('onPlaidSuccess - account', accountToUse);

    const { activeStep } = this.state;

    return this.setState(
      () => ({
        plaidPublicToken,
        plaidAccountId,
      }),
      () => this.onStep(activeStep, activeStep + 1),
    );
  };

  onPlaidExit = (error, metadata) => {
    // eslint-disable-line no-unused-vars
    // The user exited the Link flow.
    if (error != null) {
      // The user encountered a Plaid API error prior to exiting.
      return this.setState(prevState => ({
        steps: this.setStepContent(prevState, error),
        loading: false,
      }));
    }
    // metadata contains information about the institution
    // that the user selected and the most recent API request IDs.
    // Storing this information can be helpful for support.
    log.info('onPlaidExit - metadata', metadata);

    return this.onClose();
  };

  onChangeRoutingNumber = routingNumber => {
    if (!routingNumber) {
      return this.setState(prevState => ({
        steps: this.setStepContent(
          { ...prevState, routingNumber },
          { message: t('job_request.wizard.routing_number_invalid'), elementType: 'accountRoutingNumber' },
        ),
      }));
    }

    return this.setState(prevState => ({
      routingNumber,
      steps: this.setStepContent({ ...prevState, routingNumber }),
    }));
  };

  onChangeAccountNumber = accountNumber => {
    if (!accountNumber) {
      return this.setState(prevState => ({
        steps: this.setStepContent(
          { ...prevState, accountNumber },
          { message: t('job_request.wizard.account_number_invalid'), elementType: 'accountNumber' },
        ),
      }));
    }

    return this.setState(prevState => ({
      accountNumber,
      steps: this.setStepContent({ ...prevState, accountNumber }),
    }));
  };

  onChangeAccountHolderName = accountHolderName => {
    if (!accountHolderName) {
      return this.setState(prevState => ({
        steps: this.setStepContent(
          { ...prevState, accountHolderName },
          { message: t('job_request.wizard.account_holder_name_invalid'), elementType: 'accountHolderName' },
        ),
      }));
    }

    return this.setState(prevState => ({
      accountHolderName,
      steps: this.setStepContent({ ...prevState, accountHolderName }),
    }));
  };

  onChangeAccountHolderType = accountHolderType =>
    this.setState(prevState => ({
      accountHolderType,
      steps: this.setStepContent({ ...prevState, accountHolderType }),
    }));

  onChangeStripeElement = e => {
    e.on('change', el => {
      const { elementType } = el;

      if (elementType === 'cardNumberElement') {
        this.setState({ cardNumberElement: el });
      }

      // NOTE: stripe element validation can only be done by calling the createToken method.
      // Although StripeElement exposes a value property, it's only populated by Stripe
      // for non-sensitive values, like postalCode.
      // Hence, for cardNumber, cardExpiry and cardCvc, we can't access the value
      // and store it in local state.
      if (el.error) {
        const { message } = el.error;
        return this.setState(prevState => ({
          steps: this.setStepContent(prevState, { message, elementType }),
        }));
      }

      return this.setState(prevState => ({
        steps: this.setStepContent(prevState),
      }));
    });
  };

  onChangeSetAsDefault = () =>
    !this.forceSetAsDefault &&
    this.setState(prevState => ({
      setAsDefault: !prevState.setAsDefault,
      steps: this.setStepContent({ ...prevState, setAsDefault: !prevState.setAsDefault }),
    }));

  setStepContent = (prevState, error) => {
    const { useAch, useBankAccounts, useCards } = this.props;
    let steps;

    if (useCards) {
      steps = StepsCard(
        this.props,
        prevState,
        error,
        this.onChangeName,
        this.onChangeStripeElement,
        this.onChangeSetAsDefault,
        this.forceSetAsDefault,
      );
    }

    if (useBankAccounts) {
      steps = StepsBankAccount(
        this.props,
        prevState,
        error,
        this.onChangeRoutingNumber,
        this.onChangeAccountNumber,
        this.onChangeAccountHolderName,
        this.onChangeAccountHolderType,
        this.onChangeSetAsDefault,
        this.forceSetAsDefault,
      );
    }

    if (useAch) {
      steps = StepsAch(this.props, prevState, error, this.onPlaidSuccess, this.onPlaidExit);
    }

    return steps;
  };

  onStep = (wizardActiveStep, wizardNextStep) => {
    const { useMarketplace } = this.props;
    const { steps } = this.state;
    const { onStepHandler } = steps[wizardActiveStep];

    if (!onStepHandler) {
      return this.setState(prevState => ({
        activeStep: wizardNextStep,
        error: false,
        steps: this.setStepContent(prevState),
      }));
    }

    const handleStep = async () => {
      const {
        stripeSetupIntent, // eslint-disable-line no-shadow
        addStripeCustomerPaymentMethod, // eslint-disable-line no-shadow
        addStripeCustomerSource, // eslint-disable-line no-shadow
        addPlaidStripeCustomerSource, // eslint-disable-line no-shadow
      } = this.props;
      const { setAsDefault } = this.state;
      const result = await onStepHandler(this.props, this.state, this.intentState);
      const { stepNumber, setupIntent, plaidPublicToken, plaidAccountId, token, error } = result;

      if (error) {
        return this.setState(prevState => ({
          steps: this.setStepContent(prevState, error),
          loading: false,
        }));
      }

      if (useMarketplace) {
        switch (stepNumber) {
          case STEP_0: // agree terms
            stripeSetupIntent();
            break;

          case STEP_1: // enter casrd details
            // ach bank account using plaid
            if (plaidPublicToken) {
              addPlaidStripeCustomerSource(plaidPublicToken, plaidAccountId);
            }

            // bank account
            if (token) {
              addStripeCustomerSource(token);
            }

            // credit card
            if (setupIntent && setupIntent.payment_method) {
              addStripeCustomerPaymentMethod(setupIntent.payment_method, setAsDefault);
            }

            break;

          default:
            break;
        }
      }

      return this.setState({ stepAfterLoading: wizardNextStep });
    };

    return this.setState(
      () => ({
        loading: true,
      }),
      handleStep,
    );
  };

  checkModalState = () => {
    const { stepAfterLoading } = this.state;
    const { resetModalState } = this.props; // eslint-disable-line no-shadow

    switch (this.modalState.view) {
      case MODAL_STATE_SUCCESS:
        resetModalState();

        this.setState({
          activeStep: stepAfterLoading,
          loading: false,
        });
        break;

      case MODAL_STATE_ERROR:
        resetModalState();

        this.setState(prevState => ({
          activeStep: stepAfterLoading,
          steps: this.setStepContent(prevState, this.modalState.error),
          loading: false,
          error: this.modalState.error,
        }));
        break;

      default:
        break;
    }
  };

  onFinish = () => {
    const { onFinish } = this.props;

    onFinish();
  };

  isLastStep = () => {
    const { steps, activeStep } = this.state;
    const isLastStep = activeStep < steps.length - 1;

    return isLastStep;
  };

  onClose = () => {
    const { onClose } = this.props;
    const isLastStep = this.isLastStep();

    onClose(isLastStep);
  };

  onError = () => {
    // We want to return to the first step.
    const { activeStep } = this.state;

    if (activeStep === 0) {
      return;
    }

    this.setState(prevState => ({
      activeStep: 0,
      error: false,
      steps: this.setStepContent(prevState),
    }));
  };

  onReset = () => {
    this.setState(prevState => ({
      activeStep: 0,
      error: false,
      steps: this.setStepContent(prevState),
    }));
  };

  actionsLeft = () => {
    const { activeStep, steps } = this.state;
    const step = steps[activeStep];

    return step?.showActionsLeft ? this.renderTosCheckbox() : null;
  };

  getTosLink = async e => {
    e.preventDefault();
    const config = await getConfig();
    window.open(config.TOS_LINK, '_blank');
  };

  renderTosCheckbox = () => {
    const { classes } = this.props;
    const { nextEnabled } = this.state;
    const label = (
      <span className={classes.label}>
        {t('job_request.wizard.agree_terms_and_conditions', {
          termsOfService: (
            <span className={`${classes.link} text-l6m`} onClick={this.getTosLink}>
              {t('job_request.wizard.terms_of_service')}
            </span>
          ),
        })}
      </span>
    );

    return (
      <SquareSelect.Base
        small
        label={label}
        onChange={() => {
          this.setState({
            nextEnabled: !nextEnabled,
          });
        }}
        selected={nextEnabled}
      />
    );
  };

  render() {
    const { textAlign, showProgress } = this.props;
    const { error, nextEnabled, activeStep, steps, loading } = this.state;
    const step = steps[activeStep];

    return (
      <Wizard
        steps={steps}
        activeStep={activeStep}
        showProgress={showProgress}
        showNextButton={!error}
        showFinishButton={!error}
        showErrorButton={!!error}
        nextLabel={step?.nextLabel}
        nextEnabled={nextEnabled}
        onStep={this.onStep}
        onFinish={this.onFinish}
        onError={this.onError}
        onReset={this.onReset}
        onClose={this.onClose}
        actionsLeft={this.actionsLeft()}
        textAlign={textAlign}
        style={{ minHeight: '250px', flex: 1 }}
        loading={loading}
      />
    );
  }
}

const WizardWithContentAndStyles = injectStripe(withStyles(styles)(WizardWithContent));

WizardWithContent.propTypes = {
  stripeSetupIntent: PropTypes.func.isRequired,
  addStripeCustomerPaymentMethod: PropTypes.func.isRequired,
  addStripeCustomerSource: PropTypes.func.isRequired,
  addPlaidStripeCustomerSource: PropTypes.func.isRequired,
  modalState: PropTypes.shape({}).isRequired,
  resetModalState: PropTypes.func.isRequired,
  intentState: PropTypes.shape({}).isRequired,
  resetIntentState: PropTypes.func.isRequired,
  stripeCustomer: PropTypes.shape({}).isRequired,
};

function mapStateToProps(state, props) {
  return {
    config: selectConfig(state, props),
    modalState: selectors.modalState()(state, props),
    intentState: selectors.intentState()(state, props),
    stripeCustomer: selectors.selectStripeCustomer()(state, props),
  };
}

export default connect(mapStateToProps, {
  stripeSetupIntent,
  addStripeCustomerPaymentMethod,
  addStripeCustomerSource,
  addPlaidStripeCustomerSource,
  resetModalState,
  resetIntentState,
})(WizardWithContentAndStyles);
