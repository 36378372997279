import React, { PureComponent } from 'react';
import { JobTimeFormSimple } from '@properly/components';
import { addMinToDate, formatMinHours } from '@properly/common';
import log from 'loglevel';
import moment from 'moment-timezone';
import InfoMessage from '../../../../components/InfoMessage/index';
import Sidebar from '../../../../components/Sidebar/index';

import {
  JobRequestTimePreviewNew,
  JobRequestContentWrap,
  JobRequestSpacerWrap,
} from '../../../../components/JobRequestElements/index';
import { sendJobRequestModes } from '../../../../dataConstants';

class JobRequestSectionTime extends PureComponent {
  handleChangeStartTime = val => {
    if (!moment.isMoment(val)) {
      val = moment(val).utc();
    }
    this.props.handleClickSaga(false, {
      action: 'changeStartTime',
      startTime: moment(this.props.finalStartTime)
        .clone()
        .dayOfYear(val.dayOfYear())
        .year(val.year()),
    });
  };

  handleChangeStartTimeHour = min => {
    this.props.handleClickSaga(false, {
      action: 'changeStartTime',
      startTime: addMinToDate(this.props.finalStartTime, min),
    });
  };

  handleChangeJobStartTimeType = type => {
    this.props.handleClickSaga(false, {
      action: 'changeJobStartTimeType',
      jobStartTimeType: type,
    });
  };

  handleChangeEndTime = val => {
    if (!moment.isMoment(val)) {
      val = moment(val);
    }
    this.props.handleClickSaga(false, {
      action: 'changeEndTime',
      endTime: moment(this.props.finalEndTime)
        .clone()
        .dayOfYear(val.dayOfYear())
        .year(val.year()),
    });
  };

  handleChangeEndTimeHour = min => {
    this.props.handleClickSaga(false, {
      action: 'changeEndTime',
      endTime: addMinToDate(this.props.finalEndTime, min),
    });
  };

  handleChangeDuration = e => {
    this.props.handleClickSaga(false, {
      action: 'changeDuration',
      duration: e,
    });
  };

  canSelectDate = date => {
    let timeex = moment(date).isAfter(new Date());
    const dateFormat = 'Y-MM-DD';
    const selectedTime = moment(date).format(dateFormat);
    if (this.props.resolvedProperty) {
      let end = moment().tz(this.props.resolvedProperty.getTimeZone());
      end = moment(end).format(dateFormat);
      timeex = moment(selectedTime, [dateFormat]).isAfter(end) || moment(selectedTime, [dateFormat]).isSame(end, 'day');
    }
    if (this.props.finalMode === sendJobRequestModes.normal) {
      // only check in default mode
      return timeex;
    }
    return true;
  };

  render() {
    const {
      finalMode,
      finalStartTime,
      finalEndTime,
      finalDuration,
      infoTextMsg,
      jobStartTimeType = 'fixed',
      resolvedProperty,
    } = this.props;

    if (!finalStartTime) {
      log.warn('No finalStartTime passed to JobRequestSectionTime', this.props);
    }

    const minutes = finalStartTime && finalStartTime.get('hour') * 60 + finalStartTime.get('minute');
    const endMinutes = finalEndTime && moment(finalEndTime).get('hour') * 60 + moment(finalEndTime).get('minute');

    return (
      <JobRequestContentWrap id="job-request-time-form">
        <Sidebar.List>
          <Sidebar.ListInner>
            <Sidebar.ListInnerInner>
              <JobRequestSpacerWrap left="24px" top="12px" bottom="12px" right="24px" flexGrow>
                <JobTimeFormSimple
                  timezone={(resolvedProperty && resolvedProperty.timeZone) || null}
                  jobStartTimeType={jobStartTimeType}
                  jobStartDate={finalStartTime}
                  jobStartTime={minutes}
                  scheduledStartDate={finalStartTime}
                  scheduledEndDate={finalEndTime}
                  scheduledStartTime={minutes}
                  scheduledEndTime={endMinutes}
                  duration={`${finalDuration}`}
                  changeDate={this.handleChangeStartTime}
                  changeTime={e => this.handleChangeStartTimeHour(e, 15)}
                  changeDuration={this.handleChangeDuration}
                  onChangeJobStartTimeType={this.handleChangeJobStartTimeType}
                  changeScheduledStartDate={this.handleChangeStartTime}
                  changeScheduledEndDate={this.handleChangeEndTime}
                  changeScheduledStartTime={e => this.handleChangeStartTimeHour(e, 15)}
                  changeScheduledEndTime={e => this.handleChangeEndTimeHour(e, 15)}
                  isPrefill={finalMode === sendJobRequestModes.prefill}
                />
              </JobRequestSpacerWrap>
            </Sidebar.ListInnerInner>
          </Sidebar.ListInner>
        </Sidebar.List>
        <InfoMessage text={infoTextMsg} isPrompt isVcenter isHcenter icon="light" color="white" />
      </JobRequestContentWrap>
    );
  }
}

JobRequestSectionTime.content = ({ finalDuration, finalStartTime, finalEndTime, jobStartTimeType, finalMode }) => (
  <JobRequestTimePreviewNew
    hideDate={finalMode === sendJobRequestModes.prefill}
    duration={formatMinHours(finalDuration)}
    startTime={moment(finalStartTime).format('LT')}
    startDate={moment(finalStartTime).format('L')}
    endTime={moment(finalEndTime).format('LT')}
    endDate={moment(finalEndTime).format('L')}
    jobStartTimeType={jobStartTimeType === 'fixed' || jobStartTimeType === 'REGULAR' ? 'Fixed' : 'Flexible'}
  />
);

export default JobRequestSectionTime;
