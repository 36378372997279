import React from 'react';
import { ModalError } from '@properly/components';
import t from '@properly/localization';
import { error as adaptorError } from '@properly/config';
import classNames from 'classnames/bind';
import styles from './accountStyles.module.css';

const cx = classNames.bind(styles);

const getMappedErrorMessage = (errorCode, currentPartner) => {
  switch (errorCode) {
    case adaptorError.ACCOUNT_ALREADY_CONNECTED:
      return t('adaptor.account_already_connected', { partner: currentPartner });
    case adaptorError.ACCOUNT_UNAVAILABLE_RECONNECT:
      return t('adaptor.account_unavailable_reconnect', { partner: currentPartner });
    case adaptorError.DIFFERENT_ACCOUNT_RECONNECT:
      return t('adaptor.different_account_reconnect', { partner: currentPartner });
    case adaptorError.FATAL_ERROR:
    case adaptorError.SERVICE_FAILED:
    case adaptorError.NOT_IMPLEMENTED:
      return t('account.error_profile_import');
    default:
      return t('account.connection_fail');
  }
};

export default ({
  errorCode,
  connectionSuccess,
  connectionFailed,
  externalMobileBrowserConnected,
  handleForceRedirect,
  dismissConnectionSuccess,
  dismissConnectionError,
  syncError,
  disconnectError,
  dismissDisconnectError,
  reConnectionSuccess,
  dismissReConnectError,
  dismissSuccessfulConnectModal,
  currentPartner,
}) => {
  if (connectionSuccess) {
    return (
      <ModalError
        message1={t('account.connection_success_head')}
        message2={
          externalMobileBrowserConnected
            ? t('account.connection_external_browser_success', {
                click: (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    className={cx('account__connectionlink--deepLink')}
                    onClick={e => handleForceRedirect(connectionSuccess, e)}
                    rel="noopener noreferrer"
                    href="#"
                  >
                    {t('account.connection_click_here')}
                  </a>
                ),
              })
            : ''
        }
        show
        onClose={() => dismissConnectionSuccess()}
        noPrompt={!!externalMobileBrowserConnected}
      />
    );
  }

  if (connectionFailed) {
    const message = getMappedErrorMessage(errorCode, currentPartner);
    return (
      <ModalError
        message1={t('account.connection_fail_head')}
        message2={message}
        show
        onClose={e => {
          if (externalMobileBrowserConnected) {
            return handleForceRedirect(!connectionFailed, e);
          }

          return dismissConnectionError();
        }}
        isReconnect
      />
    );
  }

  if (!!syncError || !!disconnectError) {
    return (
      <ModalError
        message1={t('account.disconnect_fail_head')}
        message2={t('account.disconnect_fail')}
        show
        onClose={() => dismissDisconnectError()}
      />
    );
  }

  if (reConnectionSuccess) {
    return (
      <ModalError
        message1={t('account.reconnect_successful_head')}
        message2={
          externalMobileBrowserConnected
            ? t('account.connection_external_browser_success', {
                click: (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    className={cx('account__connectionlink--deepLink')}
                    onClick={e => handleForceRedirect(reConnectionSuccess, e)}
                    rel="noopener noreferrer"
                    href="#"
                  >
                    {t('account.connection_click_here')}
                  </a>
                ),
              })
            : ''
        }
        show
        onClose={() => dismissSuccessfulConnectModal()}
        noPrompt={!!externalMobileBrowserConnected}
      />
    );
  }

  if (!reConnectionSuccess && reConnectionSuccess !== undefined) {
    const message = getMappedErrorMessage(errorCode, currentPartner);
    return (
      <ModalError
        message1={t('account.reconnect_fail_head')}
        message2={message}
        show
        onClose={e => {
          if (externalMobileBrowserConnected) {
            return handleForceRedirect(!reConnectionSuccess, e);
          }

          return dismissReConnectError();
        }}
        isReconnect
      />
    );
  }

  return '';
};
