"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  AIRBNB: 'airbnb',
  ORBIRENTAL: 'orbirental',
  BOOKINGSYNC: 'bookingSync',
  ESCAPIA: 'escapia',
  V12: 'v12',
  GUESTY: 'guesty',
  RENTALS_UNITED: 'rentalsUnited',
  NEWBOOK: 'newbook',
  STREAMLINE: 'streamline',
  MYVR: 'myvr',
  SUPERCONTROL: 'supercontrol',
  BOOKINGCOM: 'bookingcom'
};
exports["default"] = _default;