"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makeCancelable = void 0;

const makeCancelable = promise => {
  let hasCanceled_ = false; // eslint-disable-line

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(val => hasCanceled_ ? reject({
      isCanceled: true
    }) : resolve(val), // eslint-disable-line
    error => hasCanceled_ ? reject({
      isCanceled: true
    }) : reject(error) // eslint-disable-line
    );
  });
  return {
    promise: wrappedPromise,

    cancel() {
      hasCanceled_ = true;
    }

  };
};

exports.makeCancelable = makeCancelable;