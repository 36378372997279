import React, { useLayoutEffect } from 'react';
import { LibraryHeaders, SkillItem, Regular, LoadingSplash } from '@properly/components';
import { skillStatus } from '@properly/config';
import moment from 'moment-timezone';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import groupBy from 'lodash/groupBy';
import Grid from '@material-ui/core/Grid';
import { prefilledDurationNewJobRequest } from '../../../../config';
import { setModal } from '../../../../actions/globalActions';
import { LibraryNoSkills } from '../components/LibraryNoSkills';
import {
  selectFilteredSkillsArray,
  selectSkillsAccess,
  selectSkillSearchQuery,
  selectSkillsError,
  selectSkillsLoading,
} from '../state/library.selectors';
import { loadSkillsRequest, updateLibrarySearchQuery } from '../state/library.actions';
import { LibrarySkillsSearch } from '../components/LibrarySkillsSearch';
import { ROUTES } from '../../../../paths';
import LibraryNoSkillsAccess from './LibraryNoSkillsAccess';

const Skills = ({ letter, skills, onSend, openSkill }) =>
  (skills || []).map((skill, index) => (
    <Grid container key={skill.skillId} wrap="nowrap" alignItems="center">
      <Grid item style={{ width: '24px' }}>
        <Regular type="grey">{index === 0 ? letter : ''}</Regular>
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        <SkillItem canSend onSend={() => onSend(skill.skillId)} {...skill} onClick={() => openSkill(skill.skillId)} />
      </Grid>
    </Grid>
  ));

const LibrarySkillsContainer = ({
  orderedLetters,
  skillsGroupedByLetter,
  sendSkillAsJobRequest,
  hasSkills,
  searchQuery,
  loadSkills,
  loading,
  error,
  updateSearchQuery,
  openSkill,
  hasSkillsAccess,
}) => {
  useLayoutEffect(() => {
    loadSkills();
  }, [loadSkills]);

  if (!hasSkillsAccess) {
    return <LibraryNoSkillsAccess />;
  }

  const handleSendJobRequest = skillId => sendSkillAsJobRequest(skillId);

  const showLoader = loading && !hasSkills;
  const showSkills = (!showLoader && !error) || hasSkills;
  const showNoSkills = !loading && !error && !hasSkills;

  return (
    <>
      {showLoader ? <LoadingSplash /> : null}
      {showSkills ? <LibrarySkillsSearch searchQuery={searchQuery} updateSearchQuery={updateSearchQuery} /> : null}
      {showSkills ? (
        <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
          <div style={{ marginLeft: '24px' }}>
            <LibraryHeaders type="skill" />
          </div>
          {(orderedLetters || []).map(letter => (
            <Skills
              key={letter}
              letter={letter}
              skills={skillsGroupedByLetter[letter]}
              onSend={handleSendJobRequest}
              openSkill={openSkill}
            />
          ))}
        </div>
      ) : null}
      {showNoSkills ? <LibraryNoSkills searchQuery={searchQuery} /> : null}
    </>
  );
};
const mapStateToProps = state => {
  const skills = selectFilteredSkillsArray(state);
  const skillsGroupedByLetter = groupBy(skills, skill =>
    String(skill?.title || '?')
      .substr(0, 1)
      .toUpperCase(),
  );

  const orderedLetters = Object.keys(skillsGroupedByLetter).sort();

  const getTitle = skill => skill?.title?.toUpperCase();
  const sortInnerGroupedSkills = (a, b) => (getTitle(a) < getTitle(b) ? -1 : 1);

  const hasSkills = skills?.length > 0;

  const searchQuery = selectSkillSearchQuery(state);

  return {
    orderedLetters,
    skillsGroupedByLetter: orderedLetters.reduce(
      (acc, currentLetter) => ({
        ...acc,
        [currentLetter]: skillsGroupedByLetter[currentLetter].sort(sortInnerGroupedSkills),
      }),
      {},
    ),
    hasSkills,
    searchQuery,
    loading: selectSkillsLoading(state),
    error: selectSkillsError(state),
    hasSkillsAccess: selectSkillsAccess(state),
  };
};

function openJobRequestModalAction(skillId) {
  return setModal(true, 'jobrequest', {
    mode: 'normal',
    source: 'new',
    data: {
      skills: [{ skillId, dependency: skillStatus.DEPENDENCY_STATUS.REQUIRED }],
      startTime: moment()
        .add(2, 'day')
        .hours(10)
        .minutes(0)
        .seconds(0), // eslint-disable-line
      endTime: moment()
        .add(2, 'day')
        .hours(11)
        .minutes(0)
        .seconds(0), // eslint-disable-line
      duration: prefilledDurationNewJobRequest,
      jobStartTimeType: 'fixed',
    },
  });
}

function mapDispatchToProps(dispatch) {
  return {
    sendSkillAsJobRequest: skillId => {
      dispatch(openJobRequestModalAction(skillId));
    },
    loadSkills: () => dispatch(loadSkillsRequest()),
    updateSearchQuery: (query, isSkill) => dispatch(updateLibrarySearchQuery(query, isSkill)),
    openSkill: skillId => dispatch(push(ROUTES.librarySkill(skillId))),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LibrarySkillsContainer);
