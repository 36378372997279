import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import t from '@properly/localization';
import { SpaceMedium, Regular, Button } from '@properly/components';
import { bindActionCreators } from 'redux';
import { loginGateSaga, loginSuccess } from './LoginSignUpActions';
import { trackToggleMode } from '../../actions/trackingEvents';
import { ROLE } from '../../dataConstants';
import AppStoreBar from '../../containers/AppStoreBar';
import { toggleRole } from '../desktopApp/data';
import { _mapParseUserToUser } from '../desktopApp/data.map';
import SendLinkToPhoneContainer from './SendLinkToPhoneContainer';
import LoginWrapContainer from './LoginWrapContainer';
import { ROUTES } from '../../paths';

const HostSignUpDownloadComponent = ({
  dispatch,
  loginSuccess: dispatchLoginSuccess,
  loginGateSaga: dispatchLoginGateSaga,
}) => {
  const handleSuccess = () => {
    dispatch(push(ROUTES.signUpComplete));
  };

  // new stuff
  const handleToggle = e => {
    e.preventDefault();
    toggleRole(ROLE.host)
      .then(user => dispatchLoginSuccess(_mapParseUserToUser(user), false))
      .then(() => {
        trackToggleMode(ROLE.host);
        dispatchLoginGateSaga('login');
      });
  };

  return (
    <LoginWrapContainer center withSubHeadline title={t('signup.download.title', {})}>
      <Regular>{t('signup.download.description', {})}</Regular>
      <SpaceMedium />
      <SendLinkToPhoneContainer onSuccess={handleSuccess} />
      <SpaceMedium />
      <Button onClick={e => handleToggle(e)} types={['type-flat-black', 'width-flex', 'size-medium', 'fontw-normal']}>
        {t('signup.download.use_as_host')}
      </Button>
      <SpaceMedium />
      <AppStoreBar />
    </LoginWrapContainer>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ loginGateSaga, loginSuccess }, dispatch),
    dispatch,
  };
}

export default connect(null, mapDispatchToProps)(HostSignUpDownloadComponent);
