import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PropertyImage, Icon, ButtonList, PopoverDefault } from '@properly/components';
import t from '@properly/localization';
import { withContentRect } from 'react-measure';
import filter from 'lodash/filter';
import noop from 'lodash/noop';
import lodashResult from 'lodash/result';
import {
  TodosElementMain,
  TodosElementMainInner,
  TodosElementSection,
  TodosTextEle,
  TodosElementMainMain,
  TodosElementIconPos,
} from '../../../components/TodosElements';
import Collaborator from '../../../model/collaborator';

class PermissionCollItem extends PureComponent {
  static propTypes = {
    handleAction: PropTypes.func.isRequired,
    data: PropTypes.shape({}).isRequired,
    renderType: PropTypes.string,
  };

  get inputMapped() {
    const input = this.props.data;
    if (input instanceof Collaborator) {
      return {
        pictureUrl: input.pictureUrl,
        enabled: input.enabled,
        type: input.type,
        isPending: input.isPending,
        email: input.email,
        hasAllRights: input.hasAllRights,
        fullName: input.fullName,
        hasAllRightsProperties: input.hasAllRightsProperties,
        propertyCount: input.propertyCount,
      };
    }
    return {
      pictureUrl: lodashResult(input, ['userData', 'pictureUrl']),
      enabled: true,
      type: 'shared',
      isPending: !lodashResult(input, ['userData', 'signedUp']),
      email: lodashResult(input, ['userData', 'email']),
      hasAllRights: !!lodashResult(input, ['rights', 'allRights']),
      fullName: lodashResult(input, ['userData', 'fullNameCompany']),
      hasAllRightsProperties: !!lodashResult(input, ['rights', 'seeAllProperties']),
      propertyCount: input.properties && input.properties.length ? input.properties.length : 0,
    };
  }

  permissionActionOptions = [
    {
      key: 'edit',
      'data-key': 'PermissionCollItem_edit',
      name: t('permissions.edit'),
      onClick: event =>
        this.props.handleAction(
          'edit_col',
          {
            data: this.props.data,
            renderType: this.props.renderType,
          },
          event,
        ),
    },
    {
      key: 'revoke',
      'data-key': 'PermissionCollItem_revoke',
      name: t('permissions.revoke'),
      onClick: event =>
        this.props.handleAction(
          'pre_revoke_col',
          {
            data: this.props.data,
          },
          event,
        ),
    },
    {
      key: 'resend',
      'data-key': 'PermissionCollItem_resend',
      name: t('permissions.resend'),
      onClick: event =>
        this.props.handleAction(
          'resend_col',
          {
            data: this.props.data,
            renderType: this.props.renderType,
          },
          event,
        ),
    },
    {
      key: 'disable',
      'data-key': 'PermissionCollItem_disable',
      name: t('permissions.disable'),
      onClick: event =>
        this.props.handleAction(
          'disable_col',
          {
            data: this.props.data,
          },
          event,
        ),
    },
    {
      key: 'enable',
      'data-key': 'PermissionCollItem_enable',
      name: t('permissions.enable'),
      onClick: event =>
        this.props.handleAction(
          'enable_col',
          {
            data: this.props.data,
          },
          event,
        ),
    },
    {
      key: 'view',
      'data-key': 'PermissionCollItem_view',
      name: t('permissions.view'),
      onClick: event =>
        this.props.handleAction(
          'view_property',
          {
            data: this.props.data,
          },
          event,
        ),
    },
  ];

  filterAvailableOptions(availableOptions) {
    return filter(this.permissionActionOptions, permissionActionOption =>
      availableOptions.includes(permissionActionOption.key),
    );
  }

  mapTypeToPopoverItems(item) {
    if (item.type === 'internal') {
      if (item.enabled) {
        if (item.isPending) {
          return this.filterAvailableOptions(['resend', 'disable']);
        }
        return this.filterAvailableOptions(['edit', 'disable']);
      }
      return this.filterAvailableOptions(['enable']);
    }
    if (item.type === 'external') {
      if (item.isPending) {
        return this.filterAvailableOptions(['resend', 'revoke']);
      }
      return this.filterAvailableOptions(['edit', 'revoke']);
    }
    if (item.type === 'shared') {
      return this.filterAvailableOptions(['view', 'leave']);
    }
    return [];
  }

  calcWidth() {
    const { width } = this.props.contentRect.client;
    if (width < 930) {
      return {
        first: 50,
        second: 122,
        third: 122,
        fourth: 122,
        padding: { paddingRight: 30 },
      };
    }
    return {
      first: 136,
      second: 202,
      third: 202,
      fourth: 202,
      padding: { paddingRight: 10 },
    };
  }

  render() {
    const data = this.inputMapped;
    const { dashboardAdminMode, properlyRIpartnerEmails } = this.props;
    const popoverElements = closePopover => (
      <ButtonList closePopover={closePopover} items={this.mapTypeToPopoverItems(data)} />
    );

    const mod = data.enabled ? ['whitebg'] : ['greybg'];
    const widthObj = this.calcWidth();

    return (
      <div ref={this.props.measureRef}>
        <TodosElementMain modifier={mod} onClick={noop}>
          <TodosElementMainInner>
            <TodosElementMainMain>
              <TodosElementSection width={widthObj.first} extraStyles={{ position: 'relative', ...widthObj.padding }}>
                <PropertyImage.Small pic={data.pictureUrl} />
                {data.type === 'shared' && (
                  <TodosElementIconPos type="permission">
                    <Icon.IcShare height="24px" width="24px" byWidth />
                  </TodosElementIconPos>
                )}
              </TodosElementSection>
              <TodosElementSection width={widthObj.second} extraStyles={{ ...widthObj.padding }}>
                <TodosTextEle modifier={['txt--l7m', 'color--black']}>{t('permissions.user')}</TodosTextEle>
                {!data.isPending && (
                  <div>
                    <TodosTextEle modifier={['txt--l7', 'color--black', 'mtop-6', 'helper--cuttxt']}>
                      {data.fullName}
                    </TodosTextEle>
                    <TodosTextEle modifier={['txt--l7', 'color--grey', 'mtop-6', 'helper--cuttxt']}>
                      {data.email}
                    </TodosTextEle>
                  </div>
                )}
                {data.isPending && (
                  <div>
                    <TodosTextEle modifier={['txt--l7', 'color--black', 'mtop-6', 'helper--cuttxt']}>
                      {data.email}
                    </TodosTextEle>
                    <TodosTextEle modifier={['txt--l7', 'color--grey', 'mtop-6']}>
                      {t('permissions.pending')}
                    </TodosTextEle>
                  </div>
                )}
              </TodosElementSection>
              <TodosElementSection width={widthObj.third} extraStyles={{ ...widthObj.padding }}>
                <TodosTextEle modifier={['txt--l7m', 'color--black']}>{t('permissions.rights')}</TodosTextEle>
                <TodosTextEle modifier={['txt--l7', 'color--black', 'mtop-6']}>
                  {data.hasAllRights ? t('permissions.modal.full_access') : t('permissions.modal.specific_access')}
                </TodosTextEle>
              </TodosElementSection>
              <TodosElementSection width={widthObj.fourth} extraStyles={{ ...widthObj.padding }}>
                <TodosTextEle modifier={['txt--l7m', 'color--black']}>{t('permissions.properties')}</TodosTextEle>
                <TodosTextEle modifier={['txt--l7', 'color--black', 'mtop-6']}>
                  {data.hasAllRightsProperties
                    ? t('permissions.modal.full_access')
                    : t('permissions.modal.specific_access')}
                </TodosTextEle>
                {!data.hasAllRightsProperties && (
                  <TodosTextEle modifier={['txt--l7', 'color--grey', 'mtop-6']}>
                    {t('permissions.property', { context: data.propertyCount })}
                  </TodosTextEle>
                )}
              </TodosElementSection>
            </TodosElementMainMain>
            {(!properlyRIpartnerEmails?.includes(data.email) || dashboardAdminMode) && ( // material conditional
              <TodosElementSection width="50px" noPadding extraStyles={{ display: 'flex', alignItems: 'center' }}>
                {popoverElements.length > 0 && (
                  <PopoverDefault
                    popoverContent={PopoverStates => popoverElements(PopoverStates.closePopup)}
                    hasArrow
                    width={150}
                  >
                    <div>
                      <Icon.IcMorePrimary byWidth height="24px" width="24px" />
                    </div>
                  </PopoverDefault>
                )}
              </TodosElementSection>
            )}
          </TodosElementMainInner>
        </TodosElementMain>
      </div>
    );
  }
}

export default withContentRect('client')(PermissionCollItem);
