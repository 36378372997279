import { fromJS } from 'immutable';

import omit from 'lodash/omit';
import * as types from '../../../../types/index';
import JobInstructions from '../../../../model/jobInstructions';
import { changeHelper } from '../../../../helper/herbert';

const initState = fromJS({
  jobInstructions: {
    steps: [],
  },
  currentState: 0,
  currentJobInstructionId: undefined,
  currentJobId: undefined,
  activeChecklist: undefined,
  lastTimeUpdate: undefined,
  loadingStates: {
    // [id]: val // 0=nothing, 1=loading
  },
  cloneChecklist: {
    isOpen: false,
    selected: {},
    checklistTitle: '',
    mode: 0,
    selectedChecklistId: undefined,
  },
  modalChecklist: {
    isOpen: false,
    meta: {
      // checklistId: ''
      // jobInstructionId: ''
    },
  },
  tileOverview: {
    isOpen: false,
    mode: 0,
    selected: {},
  },
  textTemplatesView: {
    isOpen: false,
    selected: [],
    do: {},
  },
  uploadView: {
    isOpen: false,
    uploading: {},
    selected: [],
    do: {},
  },
});

export default function ContactReducer(state = initState, action = {}) {
  switch (action.type) {
    case types.GLOBAL_LOGOUT: // on logout reset
      return initState;
    case types.CHECKLIST_UPDATE_JOBINSTRUCTION:
      return state.updateIn(['jobInstructions', action.id], oldData =>
        changeHelper(JobInstructions, oldData, action.data),
      );
    case types.CHECKLIST_SET_STATE:
      return state.setIn(['currentState'], action.val);
    case types.CHECKLIST_SET_MODAL:
      return state.mergeIn(['modalChecklist'], fromJS(action.obj));
    case types.CHECKLIST_UPDATE_LAST_UPDATE_TIME:
      return state.setIn(['lastTimeUpdate'], action.time);
    case types.CHECKLIST_SET_CURRENT_JOBINS_ID:
      return state.setIn(['currentJobInstructionId'], action.val);
    case types.CHECKLIST_SET_LOADING_STATE:
      return state.setIn(['loadingStates', action.id], action.val);
    case types.CHECKLIST_SET_CURRENT_JOB_ID:
      return state.setIn(['currentJobId'], action.val);
    case types.CHECKLIST_SET_TEXTTEMPLATE:
      return state.mergeIn(['textTemplatesView'], action.val);
    case types.CHECKLIST_SET_CLONE_CHECKLIST:
      return state.updateIn(['cloneChecklist'], data => {
        const checklistData = action.val || {};
        const { selected } = checklistData;
        const merged = data.mergeDeep(fromJS(action.val));
        const selectedState = selected ? { selected } : {};

        return fromJS({
          ...merged.toJS(),
          ...selectedState,
        });
      });
    case types.CHECKLIST_SET_UPLOADSTATE:
      return state.updateIn(['uploadView'], data => {
        const merged = data.merge(fromJS(omit(action.val, ['uploading'])));
        const mergedUploading = merged.mergeIn(['uploading'], fromJS(action.val.uploading || {})).get('uploading');
        const filtered = mergedUploading.filter(item => !!item);
        return fromJS({
          ...merged.toJS(),
          uploading: filtered,
        });
      });
    case types.CHECKLIST_SET_TILEVIEW:
      return state.updateIn(['tileOverview'], data => {
        const merged = data.mergeDeep(fromJS(action.val));
        let finalRes = {};
        if (merged.get('selected') !== 'empty') {
          finalRes = merged.get('selected').filter(item => item);
        }
        return fromJS({
          ...merged.toJS(),
          selected: finalRes,
        });
      });
    case types.CHECKLIST_SET_ACTIVE:
      return state.setIn(['activeChecklist'], action.val);
    default:
      return state;
  }
}
