import React from 'react';
import filterReactDomProps from 'filter-react-dom-props';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

export const PagePadding = ({ type, variation, id, children }) => (
  <div
    className={cx('page-padding', type, {
      'page-padding--checklist': variation === 'checklist',
      'page-padding--property': variation === 'property',
      'page-padding--permission': variation === 'permission',
      'page-padding--subscription': variation === 'subscription',
    })}
    id={id}
  >
    {children}
  </div>
);

export const PageFlexOuter = ({ grey, children }) => {
  const color = grey ? 'rgba(242, 245, 246, 0.5)' : 'transparent';
  const style = { background: color };
  return (
    <div style={style} className={cx('page-flex-outer')}>
      {children}
    </div>
  );
};

export const PageTopBar = ({ children, ...more }) => (
  <div className={cx('page-top-bar')} {...filterReactDomProps(more)}>
    {children}
  </div>
);

export const PageFlexInner = ({ children, maxWidth }) => (
  <div
    className={cx('page-flex-inner', {
      'page-flex-inner--maxwidth': maxWidth,
    })}
  >
    {children}
  </div>
);
