import React from 'react';
import classNames from 'classnames/bind';
import styles from './index.module.css';

const cx = classNames.bind(styles);

const AccountBgWrap = ({ children }) => <div className={cx('account__bg', 'account__bg-relative')}>{children}</div>;

const AccountHeadSection = ({ children }) => <div className={cx('account__section-head')}>{children}</div>;

export { AccountBgWrap, AccountHeadSection };
