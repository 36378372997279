import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, mapProps, flattenProp } from 'recompose';
import t from '@properly/localization';
import { isPropertyOwner } from '@properly/common';
import { SpaceFlexi, SearchSelect, SpacedHeadline } from '@properly/components';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import pick from 'lodash/pick';
import { mapToMainState } from './permissionhelper';
import {
  JobRequestSpacerWrap,
  JobRequestTextMain,
  JobRequestPropertyList,
  JobRequestPermissionBox,
} from '../../../components/JobRequestElements';
import { handlePermissionActionSaga } from './PermssionsActions';
import { collSearchKey } from './PermissionsConstants';
import { setSearchQuery } from '../../../actions/globalActions';
import { propertyShowAble } from '../../../helper/herbert';

const spacing = '2px';
class PropertySection extends PureComponent {
  static propTypes = {
    sectionValue: PropTypes.shape({}),
  };

  selectOption = val => () => {
    this.props.handlePermissionActionSaga('changeSubmitProperty', {
      value: this.props.sectionValue.set('type', val),
    });
  };

  handleClick = property => () => {
    const path = ['properties', property.objectId];
    const isSelected = !this.props.sectionValue.getIn(path);
    this.props.handlePermissionActionSaga('changeSubmitProperty', {
      value: this.props.sectionValue.setIn(path, isSelected),
    });
  };

  isSelected = val => this.props.sectionValue.get('type') === val;

  genOptions = val => ({
    selected: this.isSelected(val),
    onClick: this.selectOption(val),
    disabled: false,
  });

  isPropertyActive(property) {
    return !!this.props.sectionValue.getIn(['properties', property.objectId]);
  }

  get selectedItems() {
    return this.props.sectionValue.get('properties')?.toJS();
  }

  isAllSelected() {
    const selectedProperties = this.selectedItems;
    const currentProperties = this.getCurrentProperties();

    const currentSelectedProperties = currentProperties.filter(
      currentProperty => !selectedProperties[currentProperty.objectId],
    );

    return currentProperties.length > 0 && currentSelectedProperties.length === 0;
  }

  handleSelectAll() {
    const isAllSelected = this.isAllSelected();
    const currentProperties = this.getCurrentProperties();

    const updateSelectedProperties = currentProperties.reduce(
      (acc, current) => acc.setIn(['properties', current.objectId], !isAllSelected),
      this.props.sectionValue,
    );

    this.props.handlePermissionActionSaga('changeSubmitProperty', { value: updateSelectedProperties });
  }

  getCurrentProperties() {
    const { searchDataProperties } = this.props;

    const filteredProperties = filter(searchDataProperties.result, propertyShowAble);
    return orderBy(filteredProperties, property => property.getSearchString(), ['asc']);
  }

  render() {
    const { searchDataProperties, filteredProperties } = this.props;

    const currentProperties = this.getCurrentProperties();
    const option1 = this.genOptions('option1');
    const option2 = this.genOptions('option2');

    const selectedProperties = this.selectedItems;

    const selectedCount = Object.keys(selectedProperties).length;
    const totalCount = filteredProperties.toArray().length;
    const queryCount = currentProperties.length;

    return (
      <JobRequestSpacerWrap left="24px" top="20px" bottom="20px" right="24px" flexGrow>
        <SpacedHeadline size="small">{t('permissions.modal.property_full_access')}</SpacedHeadline>
        <JobRequestPermissionBox {...option1}>
          <div>
            <JobRequestTextMain>{t('permissions.modal.property_full_access_info')}</JobRequestTextMain>
            <SpaceFlexi type="vertical" size={spacing} />
            <JobRequestTextMain type="sub">{t('permissions.modal.property_full_access_info_l2')}</JobRequestTextMain>
          </div>
        </JobRequestPermissionBox>
        <SpacedHeadline size="small">{t('permissions.modal.property_specific_access')}</SpacedHeadline>
        <JobRequestPermissionBox {...option2}>
          <div>
            <JobRequestTextMain>{t('permissions.modal.property_specific_access_info')}</JobRequestTextMain>
            <SpaceFlexi type="vertical" size={spacing} />
            <JobRequestTextMain type="sub">
              {t('permissions.modal.property_specific_access_info_l2')}
            </JobRequestTextMain>
          </div>
        </JobRequestPermissionBox>
        {option2.selected && (
          <div>
            <SpacedHeadline size="small">{t('permissions.modal.my_properties')}</SpacedHeadline>
            <div>
              <SearchSelect
                selectedCount={selectedCount}
                queryCount={queryCount}
                totalCount={totalCount}
                placeholder={t('job_request.search_property')}
                value={searchDataProperties.searchQuery}
                onChange={value => this.props.setSearchQuery(collSearchKey, value)}
                isSelected={this.isAllSelected()}
                onSelect={() => this.handleSelectAll()}
              />
            </div>
            <SpaceFlexi type="vertical" size="6px" />
            <JobRequestPropertyList
              height={204}
              width={540}
              properties={currentProperties}
              handleClick={this.handleClick}
              isActiveFunc={property => this.isPropertyActive(property)}
              isClickableFunc={property =>
                isPropertyOwner(property.ownerRole, this.props.currentUser) && !property.sample
              }
            />
          </div>
        )}
      </JobRequestSpacerWrap>
    );
  }
}

export default compose(
  connect(
    mapToMainState,
    { handlePermissionActionSaga, setSearchQuery },
  ),
  mapProps(props =>
    pick(props, [
      'activeSectionData',
      'handlePermissionActionSaga',
      'searchDataProperties',
      'setSearchQuery',
      'filteredProperties',
      'currentUser',
    ]),
  ),
  flattenProp('activeSectionData'),
)(PropertySection);
