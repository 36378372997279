import { createSelector } from 'reselect';
import lunr from 'lunr';
import lodashResult from 'lodash/result';
import map from 'lodash/map';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import concat from 'lodash/concat';
import groupBy from 'lodash/groupBy';
import filter from 'lodash/filter';
import values from 'lodash/values';
import each from 'lodash/each';
import extend from 'lodash/extend';
import { isPropertyOwner } from '@properly/common';
import log from 'loglevel';
import { filterPropertySidebar, sortProperties } from '../helper/herbert';
import lunrIndex from '../modules/desktopApp/lunr';
import hasAccess, { canSendPropertyJobs } from '../hoc/HasPermission/hasAccess';

export const selectGlobal = () => state => state.globalReducer;
export const selectGlobalPersistent = () => state => state.globalReducer.get('persistent');

export const selectRedirectLocation = () =>
  createSelector(selectGlobal(), globalReducer => globalReducer.getIn(['persistent', 'redirectLocation']));

export const selectCurrentUserId = () => state => lodashResult(state.currentUser, ['user', 'objectId']);
export const selectCurrentUserEmail = () => state => lodashResult(state.currentUser, ['user', 'email']);
export const selectCurrentUserName = () => state => lodashResult(state.currentUser, ['user', 'firstName']);
export const selectCurrentUserTeams = () => state => lodashResult(state.currentUser, ['user', 'teams']);
export const selectCurrentUserLoggedIn = () => state => lodashResult(state.currentUser, ['isLoggedIn']);
export const selectCurrentUserLoggingOut = () => state => lodashResult(state.currentUser, ['isLoggingOut']);
export const selectDisableOnBoardingVideo = () => state =>
  lodashResult(state.currentUser, ['user', 'disableOnBoardingVideo']);
export const selectUserEmailAutoPopulate = () => state => lodashResult(state.currentUser, ['userEmail']);

export const selectHasSeenOnBoardingVideo = () => state => lodashResult(state.currentUser, ['hasSeenOnBoardingVideo']);

export const selectCurrentPath = () => state => lodashResult(state.routing, ['locationBeforeTransitions', 'pathname']);

// persistent stuff
export const selectProperties = () =>
  createSelector(selectGlobal(), globalReducer => globalReducer.getIn(['persistent', 'properties', 'data']));
export const selectCurrentUser = () =>
  createSelector(
    state => state.currentUser,
    currentUser => lodashResult(currentUser, ['user']),
  );

export const selectCurrentUserSubscription = createSelector(selectCurrentUser(), currentUser =>
  lodashResult(currentUser, ['subscription']),
);

export const selectPropertiesFiltered = () =>
  createSelector(selectProperties(), properties =>
    properties.filter(property => !property.deleted && !property.sample && !property.mergedTo && !property.toBeDeleted),
  );
export const selectOwnerProperties = () =>
  createSelector(selectProperties(), selectCurrentUser(), (properties, user) =>
    properties.filter(
      property =>
        !property.deleted &&
        isPropertyOwner(property.ownerRole, user) &&
        !property.mergedTo &&
        !property.toBeDeleted &&
        !property.sample,
    ),
  );

export const selectPropertiesByAllowedSendJobs = () =>
  createSelector(selectProperties(), selectCurrentUser(), (properties, user) =>
    properties.filter(property => canSendPropertyJobs({ propertyOwner: property.ownerRole, user })),
  );

export const selectIfProMarketPropertyExists = () =>
  createSelector(selectPropertiesByAllowedSendJobs(), properties =>
    Object.values(properties?.toJS())?.some(property => !!property.proMarketProperty),
  );

export const selectReports = () =>
  createSelector(selectGlobal(), globalReducer => globalReducer.getIn(['persistent', 'reports', 'data']));
export const selectCollaborators = () =>
  createSelector(selectGlobal(), globalReducer => globalReducer.getIn(['persistent', 'collaborators', 'data']));
export const selectTeams = () =>
  createSelector(selectGlobal(), globalReducer => globalReducer.getIn(['persistent', 'teams', 'data']));
export const selectJobs = () => state => state.globalReducer.getIn(['persistent', 'jobs', 'data']);
export const selectSkills = () => state => state.globalReducer.getIn(['persistent', 'skills', 'data']);

export const selectTrigger = () =>
  createSelector(selectGlobal(), globalReducer => globalReducer.getIn(['persistent', 'trigger', 'data']));

export const selectPlans = () => state => state.globalReducer.getIn(['persistent', 'plans', 'data']);

export const selectJobsByProperty = propertyId => state =>
  state.globalReducer.getIn(['persistent', 'jobs', 'data']).filter(item => item.defaultProperty === propertyId);

export const selectContacts = () => state => state.globalReducer.getIn(['persistent', 'contacts', 'data']);

export const selectEntryDataByCollectionName = (collectionName, id) => state =>
  state.globalReducer.getIn(['persistent', collectionName, 'data', id]);

export const selectJobRequests = () =>
  createSelector(selectGlobal(), globalReducer => globalReducer.getIn(['persistent', 'jobRequests', 'data']));

export const selectCleanerRequests = () =>
  createSelector(selectGlobal(), globalReducer => globalReducer.getIn(['persistent', 'cleanerRequest', 'data']));

export const selectJobRequestSend = () => state => state.jobRequestSend;

export const selectJobRequestData = () =>
  createSelector(selectJobRequestSend(), jobRequestSend => jobRequestSend.getIn(['jobRequestData']));

export const selectGlobalHasAccess = () => createSelector(selectCurrentUser(), user => hasAccess({ user }));

function mapFeatureToPage(features) {
  return {
    companyPage: !!features.companyModule,
    partnerPage: !!features.partnerModule,
    reportsPage: !!features.reportPage,
  };
}

export const selectCanRenderPage = createSelector(selectGlobalHasAccess(), hasAccessInner => {
  const access = hasAccessInner.settingsUandP;
  const companyModule = lodashResult(access, 'companyModule', false);
  const partnerModule = lodashResult(access, 'partnerModule', false);
  const reportPage = lodashResult(hasAccessInner, 'canSeeReportsIconSidebar', false);
  return mapFeatureToPage({
    companyModule,
    partnerModule,
    reportPage,
  });
});

export const selectUserPlanData = createSelector(
  state => state.currentUser,
  userData => {
    const isValid = userData && userData.user && userData.user.subscription && userData.user.subscription.currentPlanId;
    if (!isValid) {
      log.info('selectUserPlanData - invalid 1');
      return false;
    }
    return {
      userData: userData.user,
    };
  },
);

export const selectJobRequestsWithCleaners = () =>
  createSelector(
    selectJobRequests(),
    selectContacts(),
    (state, props) => props.contactId,
    (jobRequests, contacts, id) =>
      reduce(
        jobRequests?.toJS(),
        (acc, jobRequest) => {
          if (jobRequest.propertyId === id) {
            acc.push(jobRequest);
          }

          return acc;
        },
        [],
      ),
  );

export const selectJobRequestWithCleaners = id =>
  createSelector(selectJobRequests(), selectContacts(), (jobRequests, contacts) => {
    const contactsJS = contacts.toJS();
    const jobRequest = jobRequests?.get(id);
    if (!jobRequest) return undefined;
    const mappedCleaners = map(jobRequest.requestedCleaners, cleaner => ({
      ...cleaner,
      contact: contactsJS[cleaner.contactId],
    }));

    return extend(jobRequest, { requestedCleaners: mappedCleaners });
  });
export const selectProperty = id => createSelector(selectProperties(), properties => properties?.get(id));
export const selectJobRequest = id => createSelector(selectJobRequests(), jobRequests => jobRequests?.get(id));

export const selectMoreReducer = state => state.moreReducer;
export const selectConfig = createSelector(selectMoreReducer, more => more.getIn(['parseConfig']));
export const selectGeneralConfig = state => state.moreReducer.getIn(['generalConfig']);
export const selectContact = id => createSelector(selectContacts(), contacts => contacts.get(id));

export const selectContactMemorized = () =>
  createSelector(
    selectContacts(),
    (__, props) => props.id,
    (contacts, id) => contacts.get(id),
  );

export const selectPropertyMemorized = () =>
  createSelector(
    selectProperties(),
    (__, props) => props.id,
    (properties, id) => properties.get(id),
  );

export const jobRequestsOfContact = contactId =>
  createSelector(selectJobRequests(), jobRequests =>
    jobRequests.filter(contactRequest => {
      const res = find(contactRequest.requestedCleaners, {
        contactId,
      });
      return res !== undefined;
    }),
  );

export const selectFilteredCollaborators = () =>
  createSelector(selectCollaborators(), selectCurrentUser(), (coll, user) => {
    const data = {};
    const collJS = coll.toJS();
    data.internal = filter(collJS, val => val.type === 'internal' && val.userId !== user.objectId);
    data.external = filter(collJS, val => val.type === 'external' && val.userId !== user.objectId && val.enabled);
    return data;
  });

export const selectHasInvitedPartners = createSelector(selectFilteredCollaborators(), data => data.external.length > 0);

export const selectCollGroupedByType = createSelector(selectFilteredCollaborators(), data => {
  const filteredCollaborators = filter(concat(data.internal, data.external), val => val.type === 'external');
  return groupBy(filteredCollaborators, item => item.collType);
});

export const selectGlobalModal = id => state => state.moreReducer.getIn(['modals', id]);
export const selectGlobalModalResolved = id =>
  createSelector(selectGlobalModal(id), data => {
    const jsData = data ? data.toJS() : undefined;
    return {
      raw: data,
      js: jsData,
      isOpen: lodashResult(jsData, ['value']),
      meta: lodashResult(jsData, ['meta']),
    };
  });

// state stuff
export const selectPreloadState = () => state => state.moreReducer.getIn(['preloadState']);
export const selectPreloadStatus = state => state.moreReducer.getIn(['preloadStatus']);

// search
export const selectSearchQuery = index => state => state.moreReducer.getIn(['searchQueries', index]);

// error
export const selectErrorModal = () => state => state.moreReducer.getIn(['errorModal']);

// slider
export const selectSlider = () => state => state.moreReducer.getIn(['sliderState']);

function setInit(objIn, key, val) {
  const obj = objIn || {};
  obj[key] = val;
  return obj;
}

function mapCollsToProperties(colls) {
  const allKey = 'all';
  return reduce(
    colls,
    (acc, coll) => {
      const user = coll.userData || {};
      const { userId } = coll;
      const allProperties = lodashResult(coll, ['rights', 'seeAllProperties']);
      if (!coll.internal) {
        if (coll.properties) {
          each(coll.properties, id => {
            acc[id] = setInit(acc[id], userId, user);
          });
        }
        if (allProperties) {
          acc[allKey] = setInit(acc[allKey], userId, user);
        }
      }
      return acc;
    },
    {},
  );
}

export const selectPropertyCollaborators = () =>
  createSelector(selectCollaborators(), colls => mapCollsToProperties(colls.toJS()));

export const selectLoadingState = where =>
  createSelector(
    state => state.moreReducer.getIn(['loadingStates', where]),
    loadingState => {
      const val = loadingState && loadingState.get('value');
      return {
        isLoading: !val || val === 1,
        isLoadingFull: val === 1,
        isLoaded: val === 2,
        isErrored: val === 3,
        isLoadingPartial: val === -1,
        errorRaw: val > 2 ? val : false,
        isUntouched: val === undefined,
      };
    },
  );

export const selectJobLoadingState = () => state =>
  state.moreReducer.getIn(['loadingStates', 'jobrequest_init', 'value']);

export const selectPhotoFeedback = () => state => state.globalReducer.getIn(['persistent', 'photofeedback', 'data']);
export const selectPhotoFeedbackById = id => state => selectPhotoFeedback()(state).get(id);

export const selectJobRequestChecklists = jobRequestId =>
  createSelector(selectJobRequest(jobRequestId), jobRequest => jobRequest?.checklists);

export const selectVericationPhotosOfJobRequest = jobRequestId =>
  createSelector(selectJobRequestChecklists(jobRequestId), checklists =>
    checklists?.map(checklist => checklist?.verificationPhotos),
  );

export const selectPhotoFeedbackByJobRequest = () =>
  createSelector(
    selectPhotoFeedback(),
    (_, props) => props.oldJobRequestId,
    (photofeedbacks, jobRequestId) => photofeedbacks.filter(i => i.jobRequestId === jobRequestId),
  );

export const selectPhotoFeedbackByImageId = () =>
  createSelector(
    selectPhotoFeedbackByJobRequest(),
    (state, props) => props.imageId,
    (photofeedbacks, imageId) => photofeedbacks.filter(i => i.pictureUrl === imageId),
  );

export const selectPhotoGroupedByImageId = () =>
  createSelector(selectPhotoFeedbackByImageId(), photofeedbacks => {
    const groupedByType = groupBy(photofeedbacks.toJS(), i => i.type);
    const firstComment = lodashResult(groupedByType, ['comment', 0]);
    const firstThumbs = lodashResult(groupedByType, ['thumbs', 0]);
    return {
      hasComment: !!firstComment,
      hasUpOrDown: !!firstThumbs,
      comment: firstComment,
      thumbs: firstThumbs,
    };
  });

export const selectLoadingStateMeta = (where, hash) => state =>
  state.moreReducer.getIn(['loadingStates', where, 'meta', hash]);

// const modifyQuery = query => String(query || '').toLowerCase();
const modifyQuery = query => String(query || '');

const searchIndex = (index, query) => {
  const modifiedQuery = modifyQuery(query);

  const result = index.query(q => {
    q.term(lunr.tokenizer(modifiedQuery), { usePipeline: true, boost: 100 });
    q.term(lunr.tokenizer(modifiedQuery), {
      usePipeline: false,
      wildcard: lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING, // eslint-disable-line
      boost: 20,
    });
    q.term(lunr.tokenizer(modifiedQuery), {
      usePipeline: false,
      editDistance: 1,
    });
  });
  // get the element at position( 2/3 * totalResults) and use it as pivot to get its score
  // calculate the pivot value , if its more than 50 then use it other wise use default
  const pivot = Math.floor(0.666 * result.length);
  // Note: this needs to be a little smarter - we dont want to filter out results that should exist
  const maxScore = Math.max((result[50] && pivot > 50 && result[pivot].score) || 0, 0.7);

  return result.filter(({ score }) => score >= maxScore);
};

export const selectSearch = (searchIndexName, selectorCollection) =>
  createSelector(selectorCollection(), collection => {
    const index = lunrIndex.getIndex(searchIndexName);
    const collectionJS = collection.toJS();
    return searchQuery => {
      if (!searchQuery) {
        return values(collectionJS);
      }
      const results = searchIndex(index, searchQuery);
      return results.map(result => collectionJS[result.ref]);
    };
  });

export const selectPropertiesSearch = createSelector(selectSearch('properties', selectProperties), search => query =>
  sortProperties(filterPropertySidebar(search(query))),
);
export const selectContactsSearch = selectSearch('contacts', selectContacts);

function mapSearchRes(propertiesObj, searchRes) {
  const filteredProperties = filter(searchRes, item => propertiesObj[item.ref]);
  return map(filteredProperties, item => propertiesObj[item.ref]);
}

export const selectSearchData = (searchIndexName, searchQueryName, selectorCollection) =>
  createSelector(selectorCollection(), selectSearchQuery(searchQueryName), (collection, searchQuery) => {
    const needsOrder = !searchQuery || searchQuery === '';
    const jsData = collection.toJS();

    const result = needsOrder
      ? values(jsData)
      : mapSearchRes(jsData, searchIndex(lunrIndex.getIndex(searchIndexName), searchQuery));

    return {
      result,
      searchQuery,
    };
  });
