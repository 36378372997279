import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CalendarList, BrandingAds } from '@properly/components';
import { connect } from 'react-redux';
import * as selectors from '../state/CalendarSelectors';
import { goToProperty } from '../../../../actions/globalActions';
import CalendarListItemContainer from './CalendarListItemContainer';
import NoProperties from '../../../../components/NoProperties';
import { goNewProperty } from '../../property/state/PropertyActions';
import { setSearchQuery } from '../state/CalendarActions';
import {
  selectPartnerWebsiteUrl,
  selectIsPartnerDomain,
  selectAdStyles,
  selectCalendarPageAdFromAds,
} from '../../../branding/BrandingSelector';

class CalendarListContainer extends Component {
  static propTypes = {
    hasVerticalScroll: PropTypes.bool,
    onPropertiesRendered: PropTypes.func,
    onHasVerticalScrollChange: PropTypes.func,
    calendarPageAd: PropTypes.shape({}),
    adStyles: PropTypes.shape({}),
    isPartnerDomain: PropTypes.bool,
    partnerWebsiteUrl: PropTypes.string,
  };

  static defaultProps = {
    hasVerticalScroll: false,
    onPropertiesRendered: () => {},
    onHasVerticalScrollChange: () => {},
    calendarPageAd: {},
    adStyles: {},
    isPartnerDomain: false,
    partnerWebsiteUrl: '',
  };

  state = {
    expand: {},
  };

  handleExpand = (index, size) => {
    const { expand } = this.state;
    this.setState({ expand: { ...expand, [index]: size } });
  };

  renderRow = (key, index, style, width) => (
    <div key={key} style={{ ...style, overflow: 'hidden' }}>
      <CalendarListItemContainer
        expandSize={this.state.expand[index]}
        onExpand={this.handleExpand}
        width={width}
        index={index}
        onBookingClick={this.props.handleBookingClick}
      />
    </div>
  );

  renderNoProperties = () => {
    const { totalProperties, totalCurrentPropertiesCount } = this.props;

    // If we have properties but none are visible (filters etc)
    if (totalProperties !== 0 && totalCurrentPropertiesCount === 0) {
      return <NoProperties type="noresults" />;
    }

    return totalCurrentPropertiesCount === 0 ? (
      <NoProperties type="noproperties" onClick={this.props.goNewProperty} />
    ) : (
      <NoProperties type="notselected" />
    );
  };

  render() {
    const {
      onPropertiesRendered,
      totalCurrentPropertiesCount,
      onHasVerticalScrollChange,
      hasVerticalScroll,
      calendarPageAd,
      adStyles,
      isPartnerDomain,
      partnerWebsiteUrl,
    } = this.props;
    return (
      <>
        <CalendarList
          onHasVerticalScrollChange={onHasVerticalScrollChange}
          hasVerticalScroll={hasVerticalScroll}
          expandSize={this.state.expand}
          onPropertiesRendered={onPropertiesRendered}
          totalPropertiesCount={totalCurrentPropertiesCount}
          renderRow={this.renderRow}
          renderNoProperties={this.renderNoProperties}
        />
        {isPartnerDomain && (
          <BrandingAds
            brandingAd={calendarPageAd}
            partnerWebsiteUrl={partnerWebsiteUrl}
            adStyles={adStyles}
            typeTwoAd
          />
        )}
      </>
    );
  }
}

function mapStateToProps() {
  return state => ({
    totalCurrentPropertiesCount: selectors.selectCurrentPropertyTotal(state),
    calendarPageAd: selectCalendarPageAdFromAds()(state)?.toJS(),
    partnerWebsiteUrl: selectPartnerWebsiteUrl()(state),
    adStyles: selectAdStyles()(state)?.toJS(),
    isPartnerDomain: selectIsPartnerDomain()(state),
  });
}

const mapDispatchToProps = {
  goNewProperty,
  goToProperty,
  setSearchQuery,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarListContainer);
