import { createSelector } from 'reselect';
import reduce from 'lodash/reduce';
import concat from 'lodash/concat';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import toPairs from 'lodash/toPairs';
import fromPairs from 'lodash/fromPairs';
import uniq from 'lodash/uniq';
import filter from 'lodash/filter';
import values from 'lodash/values';
import log from 'loglevel';
import {
  modalSections,
  modalLoadingKey,
  modalLoadingSubmitKey,
  modalModes,
  modalLoadingNextKey,
} from './PermissionsConstants';
import {
  selectLoadingState,
  selectTeams,
  selectCurrentUserTeams,
  selectCurrentUser,
  selectGlobalHasAccess,
  selectFilteredCollaborators,
  selectCollGroupedByType,
} from '../../../selectors/globalSelector';
import { findMatchNonDisabled } from '../../../helper/herbert';

export function getRootDomainOfEmail(email) {
  return email.split('@')[1];
}

export const selectPermission = () => state => state.permission;
export const selectFirstTeam = () => createSelector(selectTeams(), teams => values(teams.toJS())[0]);
export const selectRootDomains = () =>
  createSelector(selectFirstTeam(), team => {
    if (!team) return [];
    return map(team.adminEmails, emailObj => getRootDomainOfEmail(emailObj.email));
  });
export const selectInfoMessage = () => state => state.permission.getIn(['listdata', 'infomessage']);
export const selectSharedWithMe = () => state => state.permission.getIn(['listdata', 'sharedWithMe']);
export const selectPermissonModalData = () => state => state.permission.getIn(['listdata', 'modalMeta']);
export const selectSubmitDataRaw = () => state => state.permission.getIn(['submitData']);
export const selectPermissonModalMode = () => state => state.permission.getIn(['listdata', 'modalMeta', 'mode']);
export const selectSubmitDataTypeBackend = () => state => state.permission.getIn(['submitData', 'type', 'backendType']);

// modal
export const selectActiveModalSection = () => state => state.permission.getIn(['listdata', 'activeSection']);

export const selectSubmitData = () =>
  createSelector(selectSubmitDataRaw(), data => ({
    raw: data,
    js: data?.toJS(),
  }));

function mapBoolToText(val) {
  return val ? 'enabled' : 'disabled';
}

function findTabFromSections(data) {
  const tabs = [];
  if (data.internal.enabled || data.internal.disabled) {
    tabs.push('internal');
  }
  if (data.external.length > 0) {
    tabs.push('external');
  }
  if (data.shared.length > 0) {
    tabs.push('shared');
  }
  return tabs;
}

function matchCounterPart(part) {
  switch (part) {
    case 'maxCountOwnerAccess':
      return 'owneraccess';
    case 'maxCountSetupSupport':
      return 'setupsupport';
    case 'maxCountServiceProviderAccess':
      return 'spaccess';
    default:
      return undefined;
  }
}

export const selectAllowedTypeSections = createSelector(
  selectCollGroupedByType,
  selectGlobalHasAccess(),
  (groupedColls, hasAccess) => {
    log.info('selectAllowedTypeSections - input', { groupedColls, hasAccess });
    const access = hasAccess.settingsUandP;
    const res = reduce(
      ['maxCountServiceProviderAccess', 'maxCountOwnerAccess', 'maxCountSetupSupport'],
      (acc, val) => {
        const localName = matchCounterPart(val);
        const permissionsAllowed = access[val] || 0;
        const size = groupedColls[localName] ? groupedColls[localName].length : 0;
        if (permissionsAllowed === -1 || permissionsAllowed > size) {
          acc.push(localName);
        }
        return acc;
      },
      [],
    );
    log.info('selectAllowedTypeSections - res', res);
    return res;
  },
);

export const selectAllowedTypeSectionsInModeContext = createSelector(
  selectAllowedTypeSections,
  selectPermissonModalMode(),
  selectSubmitDataTypeBackend(),
  (allowedTypes, mode, backendType) => {
    if ((mode === modalModes.resend_partner || mode === modalModes.edit_partner) && backendType) {
      return uniq([...allowedTypes, backendType]);
    }
    return allowedTypes;
  },
);

export const selectTabData = () =>
  createSelector(
    selectFilteredCollaborators(),
    selectCurrentUserTeams(),
    selectSharedWithMe(),
    selectCurrentUser(),
    (collFiltered, teams, shared, user) => {
      const data = {};

      const dataInternal = fromPairs(
        sortBy(
          toPairs(
            groupBy(orderBy(collFiltered.internal, ['createdAt'], ['desc']), collItem =>
              mapBoolToText(collItem.enabled),
            ),
          ),
          array => array[0] === 'disabled',
        ),
      );

      data.internal = dataInternal;
      data.external = collFiltered.external;
      data.shared = filter(
        shared?.toJS(),
        (val, key) => !user.isDefaultRole(key) && !user.isPersonalRole(key) && val.enabled,
      );
      const foundTabs = findTabFromSections(data);
      // eslint-disable-next-line
      data.suggestedTab = foundTabs[0];
      data.tabs = foundTabs;
      data.lengthInternal = concat(data.internal.enabled || [], data.internal.disabled || []).length;
      return data;
    },
  );

export const doesEmailExist = email => {
  const dataPre = selectTabData();
  return state => {
    const data = dataPre(state);
    const all = concat(data.internal.enabled || [], data.internal.disabled || [], data.external);
    const match = filter(all, { email });
    return match.length > 0;
  };
};

function mapModeToSections(mode) {
  switch (mode) {
    case modalModes.resend_company:
    case modalModes.invite_company:
      return ['email_company', 'permissions', 'property'];
    case modalModes.resend_partner:
    case modalModes.invite_partner:
      return ['type', 'email', 'permissions', 'property'];
    case modalModes.invite:
    case modalModes.resend:
    case modalModes.edit_partner:
      return ['type', 'email', 'permissions', 'property'];
    case modalModes.edit_company:
      return ['email', 'permissions', 'property'];
    case modalModes.property:
      return ['seeper'];
    default:
      return [];
  }
}

function mapModeToDisabledSections(mode) {
  switch (mode) {
    case modalModes.edit:
      return { email: true, collType: true };
    case modalModes.edit_company:
    case modalModes.edit_partner:
      return { email: true };
    case modalModes.resend:
      return { collType: true };
    case modalModes.invite:
    default:
      return {};
  }
}

function mapModeToTitle(mode) {
  switch (mode) {
    case modalModes.resend_partner:
    case modalModes.resend_company:
      return 'permissions.modal.resend_invitation';
    case modalModes.edit_partner:
    case modalModes.edit_company:
    case modalModes.invite:
    case modalModes.invite_company:
    case modalModes.invite_partner:
      return 'permissions.modal.invite_sum';
    case modalModes.property:
      return 'permissions.modal.permissions';
    default:
      return 'no title';
  }
}

function genBlacklistedPermissions(isInternal, type) {
  if (isInternal) return ['seeCleanerNames'];
  if (type === 'setupsupport' || type === 'owneraccess') return ['sendJobs', 'doJob'];
  return undefined;
}

const empty = [];
function genDisabledSection(sectionId, type) {
  switch (sectionId) {
    case 'permissions': {
      if (type === 'setupsupport' || type === 'owneraccess') {
        return ['option1'];
      }
      return empty;
    }
    default:
      return empty;
  }
}

function genNextTxt(isLast) {
  if (isLast) return 'permissions.modal.send';
  return 'permissions.modal.next';
}

function genNextMode(isLast) {
  if (isLast) return 'send';
  return 'next';
}

function genDisableNext(sectionIsValid, allSectionsAreValid, isLastElement) {
  if (!sectionIsValid) return true;
  if (!allSectionsAreValid && isLastElement) return true;
  return false;
}

export const selectModalData = () =>
  createSelector(
    selectActiveModalSection(),
    selectLoadingState(modalLoadingKey),
    selectLoadingState(modalLoadingSubmitKey),
    selectLoadingState(modalLoadingNextKey),
    selectPermissonModalMode(),
    selectSubmitData(),
    (activeSection, loadingDataModal, loadingDataModalSubmit, loadingDataModalNext, modalMode, submitData) => {
      const mode = modalMode;
      const mappedSections = mapModeToSections(mode);
      const disabledSections = mapModeToDisabledSections(mode);
      const collPartnerType = submitData.raw.getIn(['type', 'type']);
      const isSpa =
        collPartnerType === 'spaccess' &&
        (mode === modalModes.edit_partner || mode === modalModes.invite_partner || mode === modalModes.resend_partner);
      const isInternal = submitData.raw.getIn(['collType', 'type']) === 'option1';

      const sections = reduce(
        mappedSections,
        (acc, id, index) => {
          const staticData = modalSections[id];
          const sectionValueData = staticData.mapToData(submitData);
          const sectionIsValid = staticData.isValid(sectionValueData, isSpa);
          const isLastElement = index === mappedSections.length - 1;
          const isFirstElement = index === 0;
          const prev = findMatchNonDisabled(
            index - 1,
            i => i < 0,
            i => i - 1,
            acc.sectionsArray,
          );
          if (sectionIsValid) {
            acc.validSections.push(staticData.sectionId);
          }
          const isAllValid = acc.validSections.join(',') === mappedSections.join(',');
          const section = {
            sectionId: staticData.sectionId,
            sectionTitle: staticData.sectionTitle,
            sectionTopBarTitle: staticData.topBarTitle ? staticData.topBarTitle : staticData.sectionTitle,
            isActive: staticData.neverActive ? false : activeSection === staticData.sectionId,
            isDisabled: !!disabledSections[staticData.sectionId],
            sectionValue: sectionValueData,
            sectionMods: staticData.sectionMods,
            sectionCanNext: mode !== modalModes.property,
            sectionDisableNext: genDisableNext(sectionIsValid, isAllValid, isLastElement),
            sectionIsEmpty: staticData.isEmpty(sectionValueData),
            sectionDisabledSection: genDisabledSection(staticData.sectionId, collPartnerType),
            sectionNextText: genNextTxt(isLastElement),
            sectionNextMode: genNextMode(isLastElement),
            sectionMainComponent: staticData.content,
            // infoText: staticData.infoMessageBase,
            sectionIndex: index,
            sectionCanBack: !isFirstElement && prev,
            sectionBackText: 'permissions.modal.back',
          };
          acc.sectionsById[staticData.sectionId] = section; // eslint-disable-line
          acc.sectionsArray.push(section);
          return acc;
        },
        {
          sectionsArray: [],
          sectionsById: {},
          validSections: [],
        },
      );

      return {
        modalTitle: mapModeToTitle(mode),
        sections: sections.sectionsArray,
        sectionsById: sections.sectionsById,
        submitDisabled: true,
        hideSubmitButton: true,
        sectionBlackListenPermissions: genBlacklistedPermissions(isInternal, collPartnerType),
        submitText: 'permissions.modal.send_invitation',
        submitIsLoading: loadingDataModalSubmit.isLoadingFull,
        submitIsError: loadingDataModalSubmit.isErrored,
        nextIsLoading: loadingDataModalNext.isLoadingFull,
        isModalLoading: loadingDataModal.isLoadingFull,
        activeSectionData: sections.sectionsById[activeSection],
        activeSectionId: activeSection,
        isInternalColl: isInternal,
      };
    },
  );
