import React, { Component } from 'react';
import { ProfilePicture } from '@properly/components';
import classNames from 'classnames/bind';
import styles from './index.module.css';
import { TodosTextEle } from '../TodosElements';
import ProBadge from '../ProBadge';

const cx = classNames.bind(styles);

export const MasterDetail = ({ type, noMaxWidth, children }) => (
  <div
    className={cx('master-detail', type, {
      'master-detail--nomaxwidth': noMaxWidth,
    })}
  >
    {children}
  </div>
);

export const MasterDetailMaster = ({ children, type }) => (
  <div
    className={cx('master-detail-master', {
      'master-detail-master--checklist': type === 'checklist',
    })}
  >
    {children}
  </div>
);

export const MasterDetailDetail = ({ grey, children }) => {
  const color = grey ? 'rgb(242, 245, 246, 0.5)' : 'transparent';
  const style = { background: color };

  return (
    <div style={style} className={cx('master-detail-detail')}>
      {children}
    </div>
  );
};

function mapSubtitleColor(color) {
  switch (color) {
    case 'orange':
      return 'color--orange';
    default:
      return 'color--grey';
  }
}

export class MasterDetailCell extends Component {
  content = (type, hasIcons, subTitleColor) => () => {
    if (type === 'property' || type === 'contact') {
      return this.contentProperty(hasIcons, subTitleColor);
    }
    const dataKey = type === 'jobdetails' && 'job-details-service-provider-name';
    return (
      <div>
        <div className={cx('master-detail-cell__title')} data-key={dataKey}>
          {this.props.title}
        </div>
        <div className={cx('master-detail-cell__pre-subtitle')}>{this.props.pretitle}</div>
        <div
          className={cx('master-detail-cell__subTitle', {
            'master-detail-cell__subTitle--primary': this.props.subTitleColor,
          })}
        >
          {this.props.subTitle}
        </div>
      </div>
    );
  };

  contentProperty(hasIcons, subTitleColor) {
    const { title, pretitle, subTitle, proMarketProperty } = this.props;
    return (
      <div className={cx({ 'master-detail-cell__content-property': hasIcons })}>
        <TodosTextEle componentClass="div" modifier={['txt--l6', 'color--black', 'helper--cuttxt']}>
          <div style={{ display: 'flex' }}>
            {title}
            {proMarketProperty && (
              <div style={{ marginLeft: '2px', marginTop: '-2px' }}>
                <ProBadge small />
              </div>
            )}
          </div>
        </TodosTextEle>

        <TodosTextEle componentClass="div" modifier={['txt--l7', 'color--black', 'helper--cuttxt']}>
          {pretitle}
        </TodosTextEle>

        <TodosTextEle componentClass="div" modifier={['txt--l7', mapSubtitleColor(subTitleColor), 'helper--cuttxt']}>
          {subTitle}
        </TodosTextEle>
      </div>
    );
  }

  pic() {
    return (
      <div
        className={cx('master-detail-cell__pic', {
          'master-detail-cell__pic--right': this.props.picRight,
        })}
      >
        {this.props.svg ? (
          <div className={cx('master-detail-cell__inline-svg-composed', 'master-detail-cell__inline-svg')}>
            {this.props.svg}
          </div>
        ) : (
          <>
            <ProfilePicture picture={this.props.picture.picture} text={this.props.picture.text} type="community" />
            {this.props.showProBadge && (
              <div className={cx('master-detail-cell__properlypro')}>
                <ProBadge small />
              </div>
            )}
          </>
        )}
      </div>
    );
  }

  renderSwitch(bool, a, b) {
    return bool ? a.call(this) : b.call(this);
  }

  render() {
    const contentRender = this.content(this.props.type, this.props.picture, this.props.subTitleColor);
    return (
      <div
        style={this.props.style}
        onClick={this.props.onClick}
        data-key={this.props['data-key']}
        className={cx('master-detail-cell', {
          'master-detail-cell--active': this.props.active,
          'master-detail-cell--hover': !this.props.noHover,
          'master-detail-cell--jobrequest': this.props.type === 'jobrequest',
          'master-detail-cell--contact': this.props.type === 'contact',
          'master-detail-cell--property': this.props.type === 'property',
          'master-detail-cell--reports': this.props.type === 'reports',
          'master-detail-cell--settings': this.props.type === 'settings',
          'master-detail-cell--status': this.props.type === 'status',
          'master-detail-cell--community': this.props.type === 'community',
          'master-detail-cell--btn': this.props.hasButton,
          'master-detail-cell--jobdetails': this.props.type === 'jobdetails',
          'master-detail-cell--activeBlue': this.props.activeBlue,
          'master-detail-cell--noborderb': this.props.noBorderBottom,
          'master-detail-cell--disabled': this.props.disabled,
        })}
      >
        {this.props.picture && (
          <div
            className={cx('master-detail-cell__flex', {
              'master-detail-cell__flex--spacebetween': this.props.picRight,
            })}
          >
            {this.renderSwitch(this.props.picRight, contentRender, this.pic)}
            {this.renderSwitch(this.props.picRight, this.pic, contentRender)}
          </div>
        )}
        {!this.props.picture && contentRender()}
        {this.props.hasButton && <div className={cx('master-detail-cell__btn')}>{this.props.children}</div>}
        {this.props.note && <div className={cx('master-detail-cell__note')}>{this.props.note}</div>}
      </div>
    );
  }
}
