import log from 'loglevel';
import locale from 'browser-locale';
import { client } from '../../config/apollo';
import JobRequestGraphql from '../../model/jobRequestGraphql';
import CleanerRequestGraphql from '../../model/cleanerRequestGraphql';
import { JOB_REQUESTS_QUERY, GET_PROMARKETPLACE_JOB_COUNT_QUERY } from '../queries/jobList';
import { JOB_REQUEST_QUERY } from '../queries/jobDetails';

export const getGQLJobRequestsForProperty = (propertyId, role, offset, limit) => {
  log.info('In the JOB_REQ GQL for property', propertyId);
  return client
    .query({
      query: JOB_REQUESTS_QUERY,
      variables: {
        role,
        propertyId,
        offset,
        limit,
      },
      fetchPolicy: 'no-cache',
    })
    .then(({ data, errors }) => {
      if (errors) {
        log.error('Client job request query error');
        throw errors;
      }

      // mapped gql data  to new model
      const rawJobRequests = [];
      if (role === 'HOST') {
        data.JobList.forEach(value => {
          const temp = new JobRequestGraphql(value);
          rawJobRequests.push(temp);
        });
      }

      return rawJobRequests;
    });
};

export const getGQLJobRequestsForCleaners = (cleanerId, role, offset, limit, upcomingJobs) => {
  log.info('In the JOB_REQ GQL for cleaners', cleanerId, role);
  return client
    .query({
      query: JOB_REQUESTS_QUERY,
      variables: {
        role,
        offset,
        cleanerId,
        limit,
        upcomingJobs,
      },
      fetchPolicy: 'no-cache',
    })
    .then(({ data, errors }) => {
      if (errors) {
        log.error('Client job request query error');
        throw errors;
      }

      // mapped gql data  to new model
      const rawJobRequests = [];

      data.JobList.forEach(value => {
        const temp = new CleanerRequestGraphql(value, cleanerId);
        rawJobRequests.push(temp);
      });
      return rawJobRequests;
    });
};

export const getSpecificJobrequest = async id => {
  const role = 'HOST';

  return client
    .query({
      query: JOB_REQUEST_QUERY,
      variables: { role, languageCode: locale(), id, isHost: true },
      fetchPolicy: 'no-cache',
    })
    .then(({ data, errors }) => {
      if (errors) {
        log.error('Client specific job request query error');
        throw errors;
      }

      // mapped gql data  to new model
      const mappeddata = new JobRequestGraphql(data.Job);

      return mappeddata;
    });
};

export const getProMarketplaceJobCount = async () => {
  const roleType = 'HOST';

  try {
    const getProMarketplaceJobCountRes = await client.query({
      query: GET_PROMARKETPLACE_JOB_COUNT_QUERY,
      variables: {
        languageCode: locale(),
        roleType,
      },
      fetchPolicy: 'no-cache',
    });
    return getProMarketplaceJobCountRes?.data?.GetActiveProMarketplaceJobCount;
  } catch (error) {
    log.error(`[getProMarketplaceJobCount] ${error}`);
    error.apiError = true;
    return error;
  }
};
