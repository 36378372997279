export default class stripePaymentMethodBankAccount {
  constructor(id, country, currency, routingNumber, accountHolderName, accountHolderType, bankName, last4) {
    this.id = id;
    this.country = country;
    this.currency = currency;
    this.routingNumber = routingNumber;
    this.accountHolderName = accountHolderName;
    this.accountHolderType = accountHolderType;
    this.bankName = bankName;
    this.last4 = last4;
  }
}
