import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import extend from 'lodash/extend';
import { SpaceTiny, SpaceBase, SpaceSmallMid, MoreLess } from '@properly/components';
import classNames from 'classnames/bind';
import styles from './reportsStyles.module.css';
import ReportsSelectBox from './ReportsSelectBox';
import { reportsSaveSaga, reportsDeleteSaga, reportsLoadReportSaga, paginate } from './ReportsActions';
import * as selectors from './ReportsSelectors';
import ReportsTableContainer from './ReportsTableContainer';
import ChecklistOverlay from '../../../components/ChecklistOverlay';

const cx = classNames.bind(styles);

class ReportsDetailContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { open: true };
    this.isSmallScreen = window.innerWidth < 1200;
  }

  toggle(what) {
    this.setState(state => extend(state, { [what]: !state[what] }));
  }

  load(prev, now) {
    if (prev.activeId !== now.activeId) {
      this.props.reportsLoadReportSaga(now.activeId);
      this.setState({ open: true });
    }
  }

  componentDidMount() {
    this.load({ activeId: 'init' }, this.props);
  }

  componentDidUpdate(prevProps) {
    this.load(prevProps, this.props);
  }

  renderTop() {
    const spaceTotal = 45;
    const styleCol = { padding: '0 22.5px' };
    const alignLeft = this.isSmallScreen ? 'right' : undefined;
    return (
      <div>
        <ChecklistOverlay.GridRow spacing={spaceTotal}>
          <ChecklistOverlay.GridItem spacing={spaceTotal} itemPerRow={3} style={styleCol}>
            <ReportsSelectBox.Title />
          </ChecklistOverlay.GridItem>
          <ChecklistOverlay.GridItem spacing={spaceTotal} itemPerRow={3} style={styleCol}>
            <ReportsSelectBox.Column />
          </ChecklistOverlay.GridItem>
          <ChecklistOverlay.GridItem spacing={spaceTotal} itemPerRow={3} style={styleCol} />
        </ChecklistOverlay.GridRow>
        <ChecklistOverlay.GridRow spacing={spaceTotal}>
          <ChecklistOverlay.GridItem spacing={spaceTotal} itemPerRow={1} style={styleCol}>
            <div className={cx('reports__spacer', 'reports__spacer--default')} />
          </ChecklistOverlay.GridItem>
        </ChecklistOverlay.GridRow>
        <SpaceTiny />
        <ChecklistOverlay.GridRow spacing={spaceTotal}>
          <ChecklistOverlay.GridItem spacing={spaceTotal} itemPerRow={3} style={styleCol}>
            <ReportsSelectBox.Date />
          </ChecklistOverlay.GridItem>
          <ChecklistOverlay.GridItem spacing={spaceTotal} itemPerRow={3} style={styleCol}>
            <ReportsSelectBox.Property />
          </ChecklistOverlay.GridItem>
          <ChecklistOverlay.GridItem spacing={spaceTotal} itemPerRow={3} style={styleCol}>
            <ReportsSelectBox.Service align={alignLeft} />
          </ChecklistOverlay.GridItem>
        </ChecklistOverlay.GridRow>
        <SpaceBase />
        <ChecklistOverlay.GridRow spacing={spaceTotal}>
          <ChecklistOverlay.GridItem spacing={spaceTotal} itemPerRow={3} style={styleCol}>
            <ReportsSelectBox.Status />
          </ChecklistOverlay.GridItem>
          <ChecklistOverlay.GridItem spacing={spaceTotal} itemPerRow={3} style={styleCol}>
            <ReportsSelectBox.Problem />
          </ChecklistOverlay.GridItem>
          <ChecklistOverlay.GridItem spacing={spaceTotal} itemPerRow={3} style={styleCol}>
            <ReportsSelectBox.JobTypes />
          </ChecklistOverlay.GridItem>
          <ChecklistOverlay.GridItem spacing={spaceTotal} itemPerRow={3} style={styleCol} />
        </ChecklistOverlay.GridRow>
      </div>
    );
  }

  toggleOpen = () => {
    this.setState(s => ({ open: !s.open }));
  };

  wrapOrNot(bool, content) {
    if (!bool) return content;
    return (
      <MoreLess open={this.state.open} onClick={this.toggleOpen}>
        {content}
        <SpaceSmallMid />
      </MoreLess>
    );
  }

  render() {
    const { children, loadedReport } = this.props;

    if (!loadedReport) {
      return null;
    }

    return (
      <div className={cx('reports__wraptopbottom')}>
        <div className={cx('reports__topmaxwidth')}>{this.wrapOrNot(this.isSmallScreen, this.renderTop())}</div>
        <ReportsTableContainer />
        {children}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    loadedReport: selectors.selectReportsLoadedReport()(state),
    activeId: props.params.id,
  };
}

export default connect(
  mapStateToProps,
  {
    reportsSaveSaga,
    reportsDeleteSaga,
    reportsLoadReportSaga,
    paginate,
  },
)(ReportsDetailContainer);
