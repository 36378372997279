import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Button, Title3, Large } from '@properly/components';
import t from '@properly/localization';
import { openPricingModalSaga } from '../../pricing/PricingActions';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const LibraryNoSkillsAccess = ({ dispatchOpenPricingModal }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Title3>{t('skills.no_access_header')}</Title3>
      <Large>{t('skills.no_access_description')}</Large>
      <Button
        types={['size-large', 'type-full-primary']}
        styleOverwrite={{ marginTop: '20px' }}
        onClick={() => dispatchOpenPricingModal('upgrade', {})}
      >
        {t('skills.upgrade_plan')}
      </Button>
    </div>
  );
};

export default connect(null, { dispatchOpenPricingModal: openPricingModalSaga })(LibraryNoSkillsAccess);
