import React from 'react'; // eslint-disable-line
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { selectJobInstructions } from '../../checklists/state/ChecklistSelectors';
import { selectJobs } from '../../../../selectors/globalSelector';
import { loadChecklistRequest } from '../state/library.actions';
import { selectChecklistByPropId, selectChecklistLoadingByPropId } from '../state/library.selectors';

const mapStateToProps = (state, props) => {
  const checklist = selectChecklistByPropId(state, props);
  const jobInstructions = checklist && checklist.jobInstructions;
  const selectedJobInstruction = selectJobInstructions()(state).toJS()[props.jobInstructionId] || jobInstructions;
  const selectedChecklist = selectJobs()(state).toJS()[props.checklistId] || checklist;
  return {
    isLoading: selectChecklistLoadingByPropId(state, props),
    readOnly: !!props.published,
    selectedJobInstruction,
    selectedChecklist,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  push: route => dispatch(push(route)),
  locationHitChecklistDetail: (checklistId, checklistInstructionsId) =>
    dispatch(loadChecklistRequest(checklistInstructionsId, checklistId, !!props.published)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);
