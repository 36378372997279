"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  Booking: 1,
  Changed: 2,
  Canceled: 3,
  1: 'Booking',
  2: 'Changed',
  3: 'Canceled'
};
exports["default"] = _default;