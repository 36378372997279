import React from 'react';
import { Route, IndexRedirect, Redirect } from 'react-router';
import log from 'loglevel';
import { isMobileTouch, setWindowTitle } from '@properly/common';
import CanRenderPage from './hoc/CanRenderPage';
import SettingsContainer from './modules/desktopApp/settings/containers/SettingsContainer';
import AccountConnectionsAdaptorContainer from './modules/desktopApp/account/containers/AccountConnectionsAdaptorContainer';
import AccountConnectionsAdaptorEditContainer from './modules/desktopApp/account/containers/AccountConnectionsAdaptorEditContainer';
import AccountReceiptsContainer from './modules/desktopApp/account/containers/AccountReceiptsContainer';
import AccountSubscriptionContainer from './modules/desktopApp/account/containers/AccountSubscriptionContainer';
import PermissionsContainerCompany from './modules/desktopApp/permissions/PermissionsContainerCompany';
import PermissionsContainerPartner from './modules/desktopApp/permissions/PermissionsContainerPartner';
import SettingsPaymentMethodsContainer from './modules/desktopApp/settings/containers/SettingsPaymentMethodsContainer';
import SettingsIotDevicesContainer from './modules/desktopApp/settings/containers/SettingsIotDevicesContainer';
import { isMobileAppIFrameLoad } from './routes.account';

const SettingsMobileWrapper = ({ children, classes, ...rest }) => (
  <SettingsContainer disableWrapper hideMobilePopover {...rest}>
    <div style={{ padding: 16 }}>{children}</div>
  </SettingsContainer>
);

// If we load this page directly and are on a small device with a touch screen we will assume we are on a mobile device redirecting back to this
// We need to do this as there is no other robust way to detect mobile devices and our redirect method is not yet implemented by all the adaptors
// We can add an extra layer to this by ensuring that the mobile connections page has been viewed before (which should only be true for mobile browsers)

const initialLocation = window.location.hash;
const initialLocationLoadOnSettingsMobile =
  String(initialLocation).indexOf('settings-mobile/payment-methods-mobile') !== -1;
const initialLocationLoadOnSettings =
  String(initialLocation).indexOf('settings/payment-methods') !== -1 || initialLocationLoadOnSettingsMobile;

const recordMobileViewedKey = 'PROPERLY_recordMobilePaymentMethodsViewed';
const recordMobilePaymentMethodsViewed = () => {
  try {
    localStorage.setItem(recordMobileViewedKey, 'true');
  } catch (err) {
    log.error(err);
  }
};

const hasViewedMobileConnections = () => {
  try {
    return !!localStorage.getItem(recordMobileViewedKey) || initialLocationLoadOnSettingsMobile;
  } catch (err) {
    log.error(err);
    return true;
  }
};

const isMobileAppIFrameLoadForSettings =
  hasViewedMobileConnections() && isMobileTouch() && initialLocationLoadOnSettings;

const SettingsRoutes = () => (
  <>
    <Route path="settings-mobile" component={SettingsMobileWrapper}>
      <IndexRedirect to="payment-methods-mobile" />
      <Route
        path="payment-methods-mobile"
        component={props => {
          recordMobilePaymentMethodsViewed();
          return <SettingsPaymentMethodsContainer mobile {...props} />;
        }}
        onEnter={setWindowTitle('titles.payment_methods')}
      />
    </Route>
    <Route path="settings" component={SettingsContainer} onEnter={setWindowTitle('titles.settings')}>
      {isMobileAppIFrameLoadForSettings && (
        <Redirect path="payment-methods/" to="/settings-mobile/payment-methods-mobile" />
      )}
      {isMobileAppIFrameLoadForSettings && (
        <Redirect path="payment-methods" to="/settings-mobile/payment-methods-mobile" />
      )}
      {isMobileAppIFrameLoadForSettings && <Redirect path="oauth/" to="/settings-mobile/payment-methods-mobile" />}
      {isMobileAppIFrameLoadForSettings && <Redirect path="oauth" to="/settings-mobile/payment-methods-mobile" />}
      {isMobileAppIFrameLoad() && (
        <Redirect path="connections/:partner/:id" to="/account-mobile/connections-mobile/:partner/:id" />
      )}
      {isMobileAppIFrameLoad() && <Redirect path="connections" to="/account-mobile/connections-mobile" />}

      <Route
        path="connections"
        component={AccountConnectionsAdaptorContainer}
        onEnter={setWindowTitle('titles.connected_accounts')}
      />
      <Route
        path="connections/:partner/:id"
        component={AccountConnectionsAdaptorEditContainer}
        onEnter={setWindowTitle('titles.edit_connection')}
      />
      <Route
        path="company"
        component={CanRenderPage('companyPage', PermissionsContainerCompany)}
        onEnter={setWindowTitle('account.company')}
      />
      <Route
        path="partner"
        component={CanRenderPage('partnerPage', PermissionsContainerPartner)}
        onEnter={setWindowTitle('account.partner')}
      />
      <Route
        path="partner/shared"
        component={PermissionsContainerPartner}
        onEnter={setWindowTitle('permissions.properties_shared')}
      />
      <Route
        path="payment-methods"
        component={props => <SettingsPaymentMethodsContainer {...props} />}
        onEnter={setWindowTitle('titles.payment_methods')}
      />
      <Route
        path="iot-devices"
        component={props => <SettingsIotDevicesContainer {...props} />}
        onEnter={setWindowTitle('titles.iot_devices')}
      />
      <Route
        path="subscription"
        component={AccountSubscriptionContainer}
        onEnter={setWindowTitle('titles.subscription')}
      />
      <Route path="receipts" component={AccountReceiptsContainer} onEnter={setWindowTitle('titles.receipts')} />
    </Route>
  </>
);

export default SettingsRoutes;
