import { fromJS } from 'immutable';
import md5 from 'blueimp-md5';
import each from 'lodash/each';
import reduce from 'lodash/reduce';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import indexOf from 'lodash/indexOf';
import lunr from 'lunr';
import Property from '../model/property';
import Contact from '../model/contact';
import Job from '../model/job';
import Report from '../model/report';
import Trigger from '../model/trigger';
import Collaborator from '../model/collaborator';
import Team from '../model/team';
import PhotoFeedback from '../model/photoFeedback';
import { getParseJobs, getParseReports } from '../modules/desktopApp/data';
import { getCollaborators } from '../modules/desktopApp/data.http.team';
import { getUserProperties } from '../modules/desktopApp/data.http.properties';
import { getUserContacts } from '../modules/desktopApp/data.http.contacts';
import {
  _mapParsePropertyToProperty,
  _mapParseContactToContact,
  _mapParseJobToJob,
  _mapParseCollaboratorTCollaborator,
  _mapParseReportToReport,
  _mapParseTriggerToTrigger,
  _mapParseTeamToTeam,
  _mapParsePhotoFeedbackToPhotoFeedback,
} from '../modules/desktopApp/data.map';
import * as types from '../types';
import { changeHelper } from '../helper/herbert';

/*
  jsClass: Javascript class
 */
function hashClass(jsClass) {
  return md5(jsClass.toString());
}

export function validateState(state, manifesto) {
  return reduce(
    manifesto,
    (total, itemManifesto, key) => {
      const resolvedItem = state[key];
      const hash = hashClass(itemManifesto.jsClass) + hashClass(itemManifesto.mapper);
      if (
        !resolvedItem || // not exist
        resolvedItem.lastfetch === null || // never fetched
        resolvedItem.objectHash !== hash // objecthash does not match
      ) {
        total[key] = { run: 'all', objectHash: hash }; // eslint-disable-line
        return total;
      }
      if (resolvedItem.lastfetch !== null) {
        total[key] = { run: 'part', timestamp: resolvedItem.lastfetch, objectHash: hash }; // eslint-disable-line
        return total;
      }
      return total;
    },
    {},
  );
}

function wrapPromise(promise, meta) {
  return promise.then(data => ({
    meta,
    data,
  }));
}

export function genPromises(instructions, manifesto, blacklist) {
  return reduce(
    instructions,
    (acc, itemInstruction, key) => {
      if (indexOf(blacklist, key) !== -1) return acc; // dont process blacklisted
      if (itemInstruction.run === 'all') {
        acc[key] = wrapPromise(manifesto[key].all(), {
          objectHash: itemInstruction.objectHash,
          lastfetch: new Date().getTime(),
        });
      }
      if (itemInstruction.run === 'part') {
        acc[key] = wrapPromise(manifesto[key].partial(itemInstruction.timestamp), {
          lastfetch: new Date().getTime(),
        });
      }
      return acc;
    },
    {},
  );
}

export function mapPromiseDataToReducerFormat(obj, key = 'objectId') {
  return {
    data: keyBy(obj.data, key),
    ...obj.meta,
  };
}

export const JOB_REQUEST_COLLECTION = 'jobRequests';

export const onloadManifesto = {
  properties: {
    jsClass: Property,
    mapper: _mapParsePropertyToProperty,
    all: () => getUserProperties(),
    partial: lastFetch => getUserProperties(lastFetch),
    searchIndexDef: items =>
      lunr(function build() {
        this.field('title', { boost: 10 });
        this.field('city');
        this.field('apt');
        this.field('country');
        this.field('state');
        this.field('street');
        this.ref('objectId');
        this.field('zip');

        each(items, item => this.add(item));
      }),
    addItemToSearch: orginalObj => ({
      ...orginalObj.address,
      ...orginalObj,
    }),
  },
  contacts: {
    jsClass: Contact,
    mapper: _mapParseContactToContact,
    all: () => getUserContacts(),
    partial: lastFetch => getUserContacts(lastFetch),
    searchIndexDef: items =>
      lunr(function build() {
        this.field('firstName', { boost: 10 });
        this.field('lastName');
        this.field('email');
        this.field('phone');
        this.ref('objectId');

        each(items, item => this.add(item));
      }),
    addItemToSearch: orginalObj => ({
      ...orginalObj.userData,
      objectId: orginalObj.objectId,
    }),
  },
  [JOB_REQUEST_COLLECTION]: {
    jsClass: '',
    mapper: () => {},
    all: () => {},
    partial: () => {},
  },
  jobs: {
    jsClass: Job,
    mapper: _mapParseJobToJob,
    all: () => getParseJobs(),
    partial: lastFetch => getParseJobs(lastFetch),
    searchIndexDef: items =>
      lunr(function build() {
        this.field('title', { boost: 10 });
        this.ref('objectId');

        each(items, item => this.add(item));
      }),
    addItemToSearch: orginalObj => ({
      title: orginalObj.title,
      objectId: orginalObj.objectId,
    }),
  },
  trigger: {
    jsClass: Trigger,
    mapper: _mapParseTriggerToTrigger,
    all: () => Promise.resolve([]),
    partial: () => Promise.resolve([]),
  },
  reports: {
    jsClass: Report,
    mapper: _mapParseReportToReport,
    all: () => getParseReports(),
    partial: lastFetch => getParseReports(lastFetch),
    searchIndexDef: items =>
      lunr(function build() {
        this.field('title', { boost: 10 });
        this.ref('objectId');

        each(items, item => this.add(item));
      }),
    addItemToSearch: orginalObj => ({
      title: orginalObj.title,
      objectId: orginalObj.objectId,
    }),
  },
  collaborators: {
    indexBy: 'userId',
    jsClass: Collaborator,
    mapper: _mapParseCollaboratorTCollaborator,
    all: () => getCollaborators(),
    partial: lastFetch => getCollaborators(lastFetch),
  },
  teams: {
    jsClass: Team,
    mapper: _mapParseTeamToTeam,
    all: () => Promise.resolve([]),
    partial: () => Promise.resolve([]),
  },
  photofeedback: {
    jsClass: PhotoFeedback,
    mapper: _mapParsePhotoFeedbackToPhotoFeedback,
    all: () => Promise.resolve([]),
    partial: () => Promise.resolve([]),
  },
  skills: {
    jsClass: '',
    mapper: () => {},
    all: () => {},
    partial: () => {},
    searchIndexDef: items =>
      lunr(function build() {
        this.field('title', { boost: 10 });
        this.ref('skillId');

        each(items, item => this.add(item));
      }),
    addItemToSearch: originalObj => ({
      title: originalObj.title,
      skillId: originalObj.skillId,
    }),
  },
};

const pData = mapValues(onloadManifesto, () => ({
  lastfetch: null, // null | timestamp
  objectHash: '',
  data: {},
  redirectLocation: {},
}));

export const initState = fromJS({
  persistent: pData,
});

export default function globalReducer(state = initState, action = {}) {
  switch (action.type) {
    case types.GLOBAL_SET_PRELOAD_DATA:
      return state.mergeDeepIn(['persistent'], fromJS(action.data));
    case types.GLOBAL_LOGOUT:
      return initState;
    case types.UPDATE_COLLECTION_ENTRY: {
      const path = ['persistent', action.collectionName, 'data', action.id];
      if (!action.changeSet) return state.deleteIn(path);
      if (action.collectionName === JOB_REQUEST_COLLECTION) {
        return state.updateIn(path, () => action.changeSet);
      }
      return state.updateIn(path, old =>
        changeHelper(onloadManifesto[action.collectionName].jsClass, old, action.changeSet),
      );
    }
    case types.FILTER_COLLECTION_ENTRY_BATCH: {
      return state.updateIn(['persistent', action.collectionName, 'data'], val => val.filter(action.filter));
    }
    case types.UPDATE_COLLECTION_ENTRY_BATCH:
      if (action.isPagination) {
        return state.mergeIn(['persistent', action.collectionName, 'data'], fromJS(action.batchChange));
      }
      return state.setIn(['persistent', action.collectionName, 'data'], fromJS(action.batchChange));
    default:
      return state;
  }
}
