import { put, select, call, takeEvery } from 'redux-saga/effects';
import lodashResult from 'lodash/result';
import indexOf from 'lodash/indexOf';
import isUndefined from 'lodash/isUndefined';
import { isMobileTouch, setTrackingCookie } from '@properly/common';
import { isLocalOrDevelopOrStagingSync, getConfigSync } from '@properly/config';
import log from 'loglevel';
import { push, replace, LOCATION_CHANGE } from 'react-router-redux';
import * as selectorsGlobal from '../../selectors/globalSelector';
import * as globalActions from '../../actions/globalActions';
import * as types from '../../types';
import { approveRedirect, isApprovedAction } from '../../helper/herbert';
import { guardedFork } from '../../helper/saga';
import { setContextSentry } from './data';
import { trackPageChange } from '../../actions/trackingEvents';
import { ROUTES } from '../../paths';
import { RELOAD_STATUS, PAID_FEATURES, sendJobRequestModes } from '../../dataConstants';
import { waitForPreloadFinish, canUserUseFeature, refetchUser } from '../../sagas/global';
import { leaveHookSaga } from './reports/ReportsActions';
import { openPricingModalSaga as openPricingModalSagaImport } from './pricing/PricingActions';
import { selectFirstTeam } from './permissions/permissionSelector';
import { selectIsPartnerDomain } from '../branding/BrandingSelector';
import { redirectToDownloadAppPageSaga, getCustomBrandingConfigSaga } from '../branding/sagas';
import { loginSetOverride } from '../loginSignUp/LoginSignUpActions';

// eslint-disable-next-line
function* trackLocationChangeSaga(action) {
  if (isApprovedAction(action)) {
    trackPageChange(action.payload.pathname);
  }
}

function* passThrough(action, routeAction) {
  log.info('passThrough', action, routeAction);
  yield put({
    ...action.originalAction,
    payload: {
      ...routeAction,
      action: routeAction.action || 'PUSH',
    },
    isApproved: true,
  });
}

function matchAction(action, name) {
  switch (name) {
    case 'sendjobrequestopen':
      return types.GLOBAL_SET_MODAL && action.value && action.id === 'jobrequest';
    case 'companyinvite':
      return types.GLOBAL_SET_MODAL && action.value && action.id === 'permissionmodal' && action.meta.intercept;
    default:
      return false;
  }
}

function* propertyWasDeleted(propertyId) {
  const properties = yield select(selectorsGlobal.selectProperties());
  const propertyOfJobRequest = properties.get(propertyId);
  return !propertyOfJobRequest || propertyOfJobRequest.deleted;
}

function* interceptModalSaga({ originalAction }) {
  const mode = lodashResult(originalAction, ['meta', 'mode']);
  const propertyId = lodashResult(originalAction, ['meta', 'data', 'propertyId']);
  if (matchAction(originalAction, 'sendjobrequestopen')) {
    if (
      indexOf(
        [
          sendJobRequestModes.normal,
          sendJobRequestModes.edit,
          sendJobRequestModes.sendmore,
          sendJobRequestModes.prefill,
        ],
        mode,
      ) !== -1 &&
      propertyId
    ) {
      const wasDeleted = yield propertyWasDeleted(propertyId);
      if (wasDeleted) {
        yield put(globalActions.setModal(true, 'warningdeletedproperty'));
        return;
      }
    }
    yield put({ ...originalAction, isApproved: true });
  } else if (matchAction(originalAction, 'companyinvite')) {
    const firstTeam = yield select(selectFirstTeam());
    if (firstTeam && !firstTeam.email) {
      yield put(globalActions.setModal(true, 'permissions_warningcompanyemailmissing'));
      return;
    }
    yield put({ ...originalAction, isApproved: true });
  } else {
    yield put({ ...originalAction, isApproved: true });
  }
}

function isValidRedirect(urlString, query) {
  if (urlString && urlString.length > 1 && !urlString.includes('login')) {
    if (!query.loggedOut) {
      return true;
    }
  }
  return false;
}

function* interceptSagaUnauthenticated(action) {
  const routeAction = action.originalAction.payload;
  const routePath = routeAction.pathname;
  const queryObject = routeAction.query;
  switch (routePath) {
    case ROUTES.bookingcomPublic:
      yield passThrough(action, routeAction);
      return;
    case ROUTES.importAccounts:
    case ROUTES.hostDownload:
    case ROUTES.signUpComplete:
      // TODO: properly logout (do we need a request?)
      yield put(push(approveRedirect({ pathname: ROUTES.signup })));
      return;
    case ROUTES.login:
    case ROUTES.checkout:
    case ROUTES.error:
    case ROUTES.newPassword:
    case ROUTES.resetPassword:
      yield passThrough(action, routeAction);
      return;
    case ROUTES.signup: {
      yield passThrough(action, routeAction);
      return;
    }
    case ROUTES.main: {
      if (isMobileTouch()) {
        const config = getConfigSync();
        yield call(setTrackingCookie);
        const hostName = window.location.hostname;
        const appWebUrl = String(config.APP_WEB_LINK).replace('//', '');
        const domain = !hostName.includes(appWebUrl) ? hostName : null;
        const { utm_source: utmSource } = queryObject;
        if (domain || utmSource) {
          yield put(push(approveRedirect({ pathname: ROUTES.signup })));
          return;
        }
        yield put(push(approveRedirect({ pathname: ROUTES.login })));
        return;
      }
      yield passThrough(action, routeAction);
      return;
    }
    case ROUTES.mobileSubscription:
    case ROUTES.mobileUpgrade:
      yield put(push(approveRedirect({ pathname: ROUTES.login })));
      return;
    default:
  }

  const location = routeAction;
  const redirectUrl = location.pathname + location.search;

  if (isValidRedirect(redirectUrl, queryObject)) {
    yield put(
      replace(approveRedirect({ pathname: ROUTES.login, search: `?redirectUrl=${encodeURIComponent(redirectUrl)}` })),
    );
  } else {
    yield put(replace(approveRedirect({ pathname: ROUTES.login })));
  }
}

function* interceptSaga(action) {
  const routeAction = action.originalAction.payload;
  const routePath = routeAction.pathname;
  const routing = yield select(state => state.routing);
  const user = yield select(state => state.currentUser);
  const hasNoHistoryOrFromEntry =
    !routing || !routing.locationBeforeTransitions || lodashResult(routeAction, 'state.fromEntry');
  const hasNoHistory = !routing || !routing.locationBeforeTransitions;
  const prevPath = lodashResult(routing, 'locationBeforeTransitions.pathname');
  let accessedFeature;

  log.info('pricingInterceptSaga start', routePath);

  // allow login through backend
  if (/^\/loginas/.test(routePath)) {
    yield put(loginSetOverride(true));
    yield passThrough(action, routeAction);
    return;
  }

  // if not logged in
  if (!user.isLoggedIn) {
    yield interceptSagaUnauthenticated(action);
    return;
  }

  // refetch user to get latest subscription
  if (!lodashResult(user, ['user', 'subscription', 'currentPlanId']) || hasNoHistory) {
    log.info('pricingInterceptSaga - refetch');
    yield put(globalActions.setPreloadStatus(RELOAD_STATUS.NOTLOADED));
    // If we do not wait for this user to be fetched then we can run into a race condition with the subscription redirect
    // This is the original implementation from 5 months ago, and changing this probably introduced a rare race condition
    // That we can see in the e2e tests
    yield call(refetchUser);
  }

  setContextSentry(user.user.objectId); // so we know the user id in sentry.io

  // if user not is a host
  // TODO: ensure they are not accessing accounts route
  // TODO: ensure they are not accessing accounts route
  // TODO: ensure they are not accessing accounts route
  // TODO: ensure they are not accessing accounts route
  // TODO: ensure they are not accessing accounts route
  // TODO: ensure they are not accessing accounts route
  // TODO: ensure they are not accessing accounts route

  // Over Ride Route Path for cleaner or host access
  let isNotOverrideRoute = true;
  if (
    /^\/?stripe-mobile\/connect/.test(routePath) ||
    /^\/?stripe\/connect/.test(routePath) ||
    /^\/?settings-mobile\/payment-methods-mobile/.test(routePath)
  ) {
    isNotOverrideRoute = false;
  }

  const userIsCleaner = !lodashResult(user, 'user.isHost');
  const isNotDownloadRoute = routePath !== ROUTES.hostDownload;
  const shouldRedirectToHostDownload = userIsCleaner && isNotDownloadRoute && isNotOverrideRoute;
  if (shouldRedirectToHostDownload) {
    log.info('User is not a host redirecting to download', routePath);
    yield put(push({ pathname: ROUTES.hostDownload }));
    return;
  }

  // check if is a page reload or not
  if (!/^\/demo/.test(routePath)) {
    const currentStatus = yield select(selectorsGlobal.selectPreloadStatus);
    if (currentStatus === RELOAD_STATUS.NOTLOADED) {
      log.info('Waiting for page to finish loading');
      yield put(globalActions.startPreloadSaga('intercept'));
      yield call(waitForPreloadFinish, true);
    }
  }

  switch (routePath) {
    case ROUTES.main:
    case ROUTES.signup:
    case ROUTES.login: {
      if (isMobileTouch()) {
        yield put(push({ pathname: ROUTES.mobileSubscription, state: { fromEntry: true } }));
        return;
      }

      const isPartnerDomain = yield select(selectIsPartnerDomain());
      if (isPartnerDomain) {
        yield call(redirectToDownloadAppPageSaga);
        return;
      }

      yield put(push({ pathname: ROUTES.landingPage, state: { fromEntry: true } }));
      return;
    }
    default:
  }

  // reports
  if (/^\/reports/.test(routePath)) {
    accessedFeature = PAID_FEATURES.reports;
  }

  // webapp
  if (
    /^\/calendar/.test(routePath) ||
    /^\/properties/.test(routePath) ||
    /^\/todos/.test(routePath) ||
    /^\/checklists/.test(routePath) ||
    /^\/contacts/.test(routePath)
  ) {
    accessedFeature = PAID_FEATURES.webapp;
  }

  const customBrandingId = user.user && user.user.customBrandingId;

  // refetch custom branding config for ads on these pages on page refresh
  if (
    (/^\/calendar/.test(routePath) ||
      /^\/properties/.test(routePath) ||
      /^\/todos/.test(routePath) ||
      /^\/library/.test(routePath) ||
      /^\/contacts/.test(routePath) ||
      /^\/settings/.test(routePath)) &&
    customBrandingId
  ) {
    yield call(getCustomBrandingConfigSaga, customBrandingId);
  }

  // what to do next
  log.info('pricingInterceptSaga', { routing, accessedFeature, hasNoHistoryOrFromEntry });

  if (!isUndefined(accessedFeature)) {
    const res = yield canUserUseFeature(accessedFeature);
    const notAllowedAndNoHistory = res === 2 && hasNoHistoryOrFromEntry;

    const shouldRedirectToAccountSubscription = res === 0 || notAllowedAndNoHistory;

    if (shouldRedirectToAccountSubscription) {
      log.warn('User not allowed access to feature', { accessedFeature, res });

      if (isLocalOrDevelopOrStagingSync()) {
        // We are showing this modal ONLY in development
        // eslint-disable-next-line no-alert
        alert(
          '⚠️ This is a scary redirect ' +
            'it should ensure that the user has successfully loaded or ' +
            'has finished loading and errored before carrying on with the redirect ⚠️',
        );
      }

      if (!notAllowedAndNoHistory) log.error('pricingInterceptSaga - data missing');
      // go account scubscrption page
      yield put(push({ pathname: ROUTES.settingsSubscription }));
      return;
    }
    if (res === 2) {
      // go back and show modal

      yield put(
        openPricingModalSagaImport(
          'default',
          {
            defaultKind: 'upgradeplan',
          },
          'reports',
        ),
      );
      return;
    }
  }

  if (prevPath && /^\/reports\//.test(prevPath) && !lodashResult(routeAction, 'state.fromReports')) {
    log.info('interceptSaga - reports leave hook');
    yield put(leaveHookSaga(routePath));
    return;
  }

  // pass through
  yield passThrough(action, routeAction);
}

export default function* saga() {
  yield guardedFork(takeEvery, LOCATION_CHANGE, trackLocationChangeSaga);
  yield guardedFork(takeEvery, types.GLOBAL_INTERCEPT_SAGA, interceptSaga);
  yield guardedFork(takeEvery, types.GLOBAL_USER_REFETCH_SAGA, refetchUser);
  yield guardedFork(takeEvery, types.GLOBAL_INTERCEPT_MODAL_SAGA, interceptModalSaga);
}
