import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import t from '@properly/localization';
import log from 'loglevel';
import {
  displayStreetWithApt,
  displayCityStateZip,
  isSharingProperty,
  isSharedProperty,
  pictureSizes,
  generatePictureUrl,
} from '@properly/common';
import {
  PropertyImage,
  PropertyInformation,
  Modal,
  TabBar,
  SpaceSmall,
  SpaceTiny,
  SpaceMicro,
  Title1,
  XLarge,
  Large,
  Button,
  LoadingSplash,
  PopoverDefault,
  ButtonList,
  BrandingAds,
} from '@properly/components';
import { featureFlag } from '@properly/config';
import moment from 'moment-timezone';
import { compose, onlyUpdateForKeys } from 'recompose';
import pick from 'lodash/pick';
import filter from 'lodash/filter';
import lodashResult from 'lodash/result';
import TopLabel from '../../../../components/TopLabel';
import UploadArea from '../../../../components/UploadArea';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import PropertyCcleaners from './PropertyCcleaners';
import { ROUTENAMES } from '../../../../paths';
import { prefilledDurationNewJobRequest } from '../../../../config';
import { PageFlexOuter, PageTopBar, PagePadding, PageFlexInner } from '../../../../components/PageElements';
import { openJobRequestDetails } from '../../jobDetails/state/JobDetailsActions';
import ErrorComponent from '../../../../components/ErrorComponent';
import PropertyDetailDetailContainer from './PropertyDetailDetailContainer';
import PropertyDetailChecklistsContainer from './PropertyDetailChecklistsContainer';
import { setUploadState } from '../../checklists/state/ChecklistActions';
import PropertyDetailTriggerContainer from './PropertyDetailTriggerContainer';
import { setModal, setSliderSaga } from '../../../../actions/globalActions';
import HasPermission from '../../../../hoc/HasPermission';
import SharedWith from '../../../../containers/SharedWith';
import PropertyTagContainer from './PropertyTagContainer';
import {
  openPropertyGallery,
  goToProperty,
  goToPropertyDetails,
  updateProperty,
  deletePropertyRequest,
  goToPropertyEdit,
  goToPropertyGallery,
  goToPropertyChecklists,
  setCloneProperty,
  noMergeSaga,
  goToPropertyConnections,
  goToPropertyTrigger,
} from '../state/PropertyActions';
import PropertyDetailConnectionsContainer from './PropertyDetailConnectionsContainer';
import styles from './propertyStyles.module.css';
import { TodosTextEle } from '../../../../components/TodosElements/index';
import JobCleanerRequestList from '../../../../containers/JobCleanerRequestList';
import PropertyMessages from './PropertyMessages';

// bad import
import { handlePermissionActionSaga } from '../../permissions/PermssionsActions';
import {
  selectPartnerWebsiteUrl,
  selectIsPartnerDomain,
  selectAdStyles,
  selectPropertyPageAdFromAds,
} from '../../../branding/BrandingSelector';
import ProBadge from '../../../../components/ProBadge';
import { selectIsFeatureFlagEnabledForUser } from '../../settings/state/SettingsSelectors';

const cx = classNames.bind(styles);

class PropertyDetailContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { ccOpen: false };
  }

  componentDidMount() {
    const {
      params: { jobRequestId },
      openJobRequestDetails, // eslint-disable-line no-shadow
    } = this.props;

    if (jobRequestId) {
      openJobRequestDetails(jobRequestId, false);
    }
  }

  clickedDelete = () => {
    const r = window.confirm(t('properties.really_delete_property')); // eslint-disable-line
    if (r === true) {
      this.props.deletePropertyRequest(this.propertyData.objectId);
    }
  };

  clickedLeave = () => {
    this.props.handlePermissionActionSaga('pre_leave_property_external', {
      teamId: lodashResult(this.props.currentUser.getTeamFromRole(this.propertyData.ownerRole), 'teamId'),
    });
  };

  clickedEdit = () => {
    this.props.goToPropertyEdit(this.propertyData.objectId);
  };

  clickedGallery = () => {
    this.props.goToPropertyGallery(this.propertyData.objectId);
  };

  clickedNoMerge = id => {
    this.props.noMergeSaga(id);
  };

  propertyActionOptions = [
    {
      key: 'edit',
      name: t('properties.edit_property'),
      onClick: this.clickedEdit,
    },
    {
      key: 'delete',
      name: t('properties.delete_property'),
      onClick: this.clickedDelete,
    },
    {
      key: 'removecoll',
      name: t('properties.leave_collaboration'),
      onClick: this.clickedLeave,
    },
  ];

  popoverContent = (closePopup, visibleSections) => (
    <ButtonList
      closePopover={closePopup}
      items={filter(this.propertyActionOptions, propertyActionOption =>
        visibleSections.includes(propertyActionOption.key),
      )}
    />
  );

  popWrap = (ele, visibleSections) => input => (
    <PopoverDefault
      popoverKey="editProperty"
      width={200}
      hasArrow
      arrowPosition={{
        horizontal: 'right',
        vertical: 'top',
      }}
      popoverContent={PopoverStates => this.popoverContent(PopoverStates.closePopup, visibleSections)}
    >
      {input}
    </PopoverDefault>
  );

  openJobDetails = jobRequest => {
    const backwardCompatibilityObj = jobRequest.backwardCompatibility;
    if (!backwardCompatibilityObj || backwardCompatibilityObj === null) {
      this.props.openJobRequestDetails(jobRequest.jobId, false);
    } else {
      this.props.openJobRequestDetails(jobRequest.jobId, false, backwardCompatibilityObj.jobRequestId);
    }
  };

  renderSection(section) {
    return (
      <div>
        <TabBar>
          <TabBar.Item
            data-key="button_section_jobrequests"
            onClick={() => this.props.goToProperty(this.props.params.id)}
            active={section === 2}
          >
            {t('properties.job_requests')}
          </TabBar.Item>
          <TabBar.Item
            data-key="button_section_checklists"
            onClick={() => this.props.goToPropertyChecklists(this.props.params.id)}
            active={section === 3}
          >
            {t('properties.checklists')}
          </TabBar.Item>
          <TabBar.Item
            data-key="button_section_propertydetails"
            onClick={() => this.props.goToPropertyDetails(this.props.params.id)}
            active={section === 1}
          >
            {t('properties.property_details')}
          </TabBar.Item>
          <HasPermission
            meta={{ propertyId: this.propertyData.objectId }}
            hasAccessFormatter={hasAccessRes => hasAccessRes.canSendJobs}
            renderWithPermission={() => (
              <TabBar.Item
                data-key="button_section_propertyconnections"
                onClick={() => this.props.goToPropertyConnections(this.props.params.id)}
                active={section === 4}
              >
                {t('properties.property_connections')}
              </TabBar.Item>
            )}
          />
          <HasPermission
            meta={{ propertyId: this.propertyData.objectId }}
            hasAccessFormatter={hasAccessRes => hasAccessRes.canSendJobs}
            renderWithPermission={() => (
              <TabBar.Item onClick={() => this.props.goToPropertyTrigger(this.props.params.id)} active={section === 5}>
                {t('properties.automation')}
              </TabBar.Item>
            )}
          />
        </TabBar>
        {section === 1 && (
          <PropertyDetailDetailContainer
            openPictures={this.openPictures}
            propertyId={this.props.params.id}
            otherAttributes={this.props.propertyData.otherAttributes}
          />
        )}
        {section === 2 && (
          <JobCleanerRequestList mode="property" contactId={this.props.params.id} onClick={this.openJobDetails} />
        )}
        {section === 3 && (
          <PropertyDetailChecklistsContainer propertyId={this.props.params.id} propertyData={this.propertyData} />
        )}
        {section === 4 && <PropertyDetailConnectionsContainer property={this.props.params.id} />}
        {section === 5 && (
          <PropertyDetailTriggerContainer
            property={this.props.params.id}
            {...pick(this.props, ['location'])}
            propertyData={this.propertyData}
          />
        )}
      </div>
    );
  }

  genImage(imageId) {
    if (!imageId) return '';
    return generatePictureUrl(imageId, pictureSizes.PROPERTY_PICTURE);
  }

  openSendJobRequestModal = () => {
    const { propertyData } = this.props;

    const { objectId, timeZone } = propertyData || {};

    if (!objectId || !timeZone) {
      log.warn('Attempting to openSendJobRequestModal with no property objectId or timeZone', {
        propertyData,
        objectId,
        timeZone,
      });
    }

    // Note: if we have lost our timezone here then our start and end times will be off if the user is not in the same timezone
    const currentPropertyTime = (timeZone && moment().tz(timeZone)) || moment();

    const startTime = currentPropertyTime
      .clone()
      .add(2, 'day')
      .hours(11)
      .minutes(0)
      .seconds(0);

    const endTime = currentPropertyTime
      .clone()
      .add(2, 'day')
      .hours(11)
      .minutes(0)
      .seconds(0);

    this.props.setModal(true, 'jobrequest', {
      mode: 'normal',
      source: 'property',
      data: {
        propertyId: objectId,
        startTime,
        endTime,
        duration: prefilledDurationNewJobRequest,
        jobStartTimeType: 'fixed',
      },
    });
  };

  get activeSection() {
    const { routeName } = this.props;

    if (routeName === ROUTENAMES.propertydetails) return 1;
    if (routeName === ROUTENAMES.propertychecklists) return 3;
    if (routeName === ROUTENAMES.propertyconnections) return 4;
    if (routeName === ROUTENAMES.propertyevents) return 5;

    return 2;
  }

  get hasError() {
    return !this.propertyData;
  }

  get showAble() {
    return !this.hasError && this.propertyData;
  }

  get isDeleted() {
    return this.propertyData && this.propertyData.deleted;
  }

  get propertyData() {
    return this.props.propertyData;
  }

  setCC = val => () => {
    this.setState({ ccOpen: val });
  };

  openPictures = pictures => {
    const mappedPictures = (pictures || []).map(image => ({ image }));
    this.props.setSliderSaga(true, mappedPictures);
  };

  renderPropertyInformation(property) {
    return (
      <div>
        <SpaceSmall />
        <PropertyInformation hideLabel {...property} />
      </div>
    );
  }

  renderShareText(isSharedByProperty, isSharedWithProperty, propertyData) {
    if (isSharedByProperty) {
      return (
        <div>
          <SpaceTiny />
          <TodosTextEle modifier={['txt--l7', 'color--no-result']}>
            {t('permissions.shared_by')}: {propertyData.ownerName}
          </TodosTextEle>
        </div>
      );
    }
    if (isSharedWithProperty) {
      return (
        <div>
          <SpaceTiny />
          <TodosTextEle modifier={['txt--l7', 'color--no-result']}>
            <SharedWith
              componentClass="span"
              preText={`${t('permissions.shared_with')}:`}
              propertyId={propertyData.objectId}
            />
          </TodosTextEle>
        </div>
      );
    }
    return null;
  }

  renderContent() {
    const { propertyData } = this;
    const {
      isPartnerDomain,
      propertyPageAd,
      adStyles,
      partnerWebsiteUrl,
      isMarketPlaceFeatureFlagEnabled,
    } = this.props;
    const isSharedWithProperty = isSharingProperty(propertyData.objectId, this.props.collData);
    const isSharedByProperty = isSharedProperty(propertyData.ownerRole, this.props.currentUser);

    const { objectId: propertyId, proMarketProperty } = propertyData || {};

    const propertyCountry = propertyData.address && propertyData.address.country;
    return (
      <div className={cx('property-detail-header')}>
        <HasPermission
          meta={{ propertyId }}
          hasAccessFormatter={hasAccessRes => hasAccessRes.canEditThisProperty && hasAccessRes.isStandalone}
          renderWithPermission={() => (
            <div>
              <PropertyMessages
                clickEdit={this.clickedEdit}
                clickShowMerge={this.showMergeDialog}
                clickNoMerge={this.clickedNoMerge}
                propertyId={propertyId}
              />
            </div>
          )}
        />

        <div style={{ display: 'flex' }}>
          <HasPermission
            meta={{ propertyId }}
            hasAccessFormatter={hasAccessRes => hasAccessRes.canEditDeleteProperty}
            render={hasAccess => (
              <div
                className={cx('property-detail-picture__container')}
                onClick={hasAccess ? this.clickedGallery : undefined}
              >
                <div className={cx('property-detail-picture')}>
                  <div className={cx('property-detail-picture__inner')}>
                    <PropertyImage.Circle size="120" image={this.genImage(propertyData.pictureUrl)}>
                      <UploadArea.IconOverlay text={t('properties.select_image')} isSet={!!propertyData.pictureUrl} />
                    </PropertyImage.Circle>
                  </div>
                </div>

                {hasAccess && <Button types={['type-flat-primary']}>{t('properties.view_gallery')}</Button>}
              </div>
            )}
          />

          <div className={cx('property-detail-address')}>
            <Title1
              type="nomargin"
              style={{
                wordBreak: 'break-word',
                paddingRight: '24px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {propertyData.title}
                {isMarketPlaceFeatureFlagEnabled && proMarketProperty && (
                  <div style={{ marginLeft: '8px' }}>
                    <ProBadge modalRequired propertyType />
                  </div>
                )}
              </div>
            </Title1>

            <SpaceMicro />
            <div>
              <XLarge type="blue-grey">{displayStreetWithApt(propertyData.address)}</XLarge>
            </div>
            <SpaceMicro />
            <div>
              <XLarge type="blue-grey">{displayCityStateZip(propertyData.address)}</XLarge>
            </div>
            <SpaceMicro />
            <div>
              <Large type="grey">{propertyCountry}</Large>
            </div>

            <PropertyTagContainer propertyId={this.propertyData.objectId} />

            {this.renderPropertyInformation(propertyData)}

            {this.renderShareText(isSharedByProperty, isSharedWithProperty, propertyData)}
          </div>
          <div className={cx('branding-ad-send-job')}>
            {isPartnerDomain && (
              <BrandingAds
                brandingAd={propertyPageAd}
                partnerWebsiteUrl={partnerWebsiteUrl}
                adStyles={adStyles}
                typeOneAd
              />
            )}
            <div style={{ flexShrink: 0, alignSelf: 'flex-end' }}>
              <HasPermission
                meta={{ propertyId }}
                hasAccessFormatter={hasAccessRes => hasAccessRes.canSendJobs}
                renderWithPermission={() => (
                  <div>
                    <Button
                      data-key="button_send_job_property"
                      onClick={this.openSendJobRequestModal}
                      types={['type-full-primary', 'width-flex', 'size-large']}
                    >
                      {t('properties.send_job_request')}
                    </Button>
                    <div
                      data-key="button_see_community_service_providers"
                      className={cx('property__cctxt')}
                      onClick={this.setCC(true)}
                    >
                      {t('properties.see_cc_btn')}
                    </div>
                  </div>
                )}
              />
              <Modal
                type="jobdetail"
                style={{ zIndex: '100 !important' }}
                onClose={this.setCC(false)}
                show={this.state.ccOpen}
              >
                <div style={{ width: '100%' }}>
                  <PropertyCcleaners onClose={this.setCC(false)} property={propertyData} />
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderInner(activeSection) {
    return (
      <div>
        {this.renderContent()}
        {this.renderSection(activeSection)}
      </div>
    );
  }

  showMergeDialog = concatted => {
    this.props.setCloneProperty({
      isOpen: true,
      selected: concatted,
    });
  };

  renderError() {
    return <ErrorComponent is404 headline={t('error.404error')} subHeadline={t('error.looks_like_smth_wrong')} />;
  }

  getPropoverSections(canEdit, isShared) {
    let sections = [];
    if (canEdit) {
      sections = [...sections, 'edit', 'delete'];
    }
    if (isShared) {
      sections = [...sections, 'removecoll'];
    }
    return sections;
  }

  render() {
    const { children } = this.props;
    return (
      <>
        <PageFlexOuter grey>
          <PageTopBar>
            <HasPermission
              meta={{ propertyId: lodashResult(this.propertyData, ['objectId']) }}
              hasAccessFormatter={hasAccessRes => ({
                access: hasAccessRes.canEditDeleteProperty,
                isShared: !hasAccessRes.isOwnerProperty,
              })}
              render={obj => {
                const arr = this.getPropoverSections(obj.access, obj.isShared);
                if (arr.length === 0) return null;
                return (
                  <div style={{ position: 'relative' }} id="topbar2">
                    {this.showAble && !this.isDeleted && (
                      <TopLabel
                        data-key="button_edit_property"
                        right
                        onlyTxtCursor
                        extraPadding
                        wrap={this.popWrap(() => document.getElementById('topbar2'), arr)}
                        label={t('properties.edit')}
                      />
                    )}
                  </div>
                );
              }}
            />
          </PageTopBar>
          <PageFlexInner>
            <PagePadding variation="property" type="absolute-scroll">
              {this.showAble && !this.hasError && !this.isDeleted && this.renderInner(this.activeSection)}
              {!this.showAble && !this.hasError && <LoadingSplash />}
              {!this.showAble && this.hasError && this.renderError()}
            </PagePadding>
          </PageFlexInner>
        </PageFlexOuter>
        {children}
      </>
    );
  }
}

PropertyDetailContainer.propTypes = {
  isPartnerDomain: PropTypes.bool,
  propertyPageAd: PropTypes.shape({}),
  adStyles: PropTypes.shape({}),
};

PropertyDetailContainer.defaultProps = {
  isPartnerDomain: false,
  propertyPageAd: {},
  adStyles: {},
};

function mapStateToProps(state, props) {
  const currentUser = selectorsGlobal.selectCurrentUser();
  const collData = selectorsGlobal.selectPropertyCollaborators();
  const propertyData = selectorsGlobal.selectPropertyMemorized();
  const isPartnerDomain = selectIsPartnerDomain();
  const propertyPageAd = selectPropertyPageAdFromAds();
  const adStyles = selectAdStyles();
  const partnerWebsiteUrl = selectPartnerWebsiteUrl();
  const isMarketPlaceFeatureFlagEnabled = selectIsFeatureFlagEnabledForUser(featureFlag.FEATURE_FLAG_MARKETPLACE)(
    state,
    props,
  );
  return {
    currentUser: currentUser(state, props),
    propertyData: propertyData(state, { id: props.params.id }),
    routeName: props.params.propertyDetailView,
    collData: collData(state, props),
    isPartnerDomain: isPartnerDomain(state, props),
    propertyPageAd: propertyPageAd(state, props) && propertyPageAd(state, props)?.toJS(),
    adStyles: adStyles(state, props)?.toJS(),
    partnerWebsiteUrl: partnerWebsiteUrl(state, props),
    isMarketPlaceFeatureFlagEnabled,
  };
}

export default compose(
  connect(mapStateToProps, {
    openPropertyGallery,
    goToProperty,
    goToPropertyDetails,
    updateProperty,
    deletePropertyRequest,
    goToPropertyEdit,
    goToPropertyGallery,
    setSliderSaga,
    openJobRequestDetails,
    goToPropertyChecklists,
    setCloneProperty,
    noMergeSaga,
    goToPropertyConnections,
    setUploadState,
    goToPropertyTrigger,
    setModal,
    handlePermissionActionSaga,
  }),
  onlyUpdateForKeys(['currentUser', 'children', 'propertyData', 'routeName', 'collData']),
)(PropertyDetailContainer);
