import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DynamicFieldInput, SpaceLarge, SpaceMedium } from '@properly/components';
import PropertyDetailContainerBlock from '../../../../containers/PropertyDetailContainerBlock/index';
import HasPermission from '../../../../hoc/HasPermission';

class PropertyDetailDetailContainer extends PureComponent {
  render() {
    const { otherAttributes, openPictures, propertyId } = this.props;
    const hasOtherAttributes = otherAttributes && otherAttributes.length > 0;

    const containerStyle = hasOtherAttributes ? { display: 'flex', flexDirection: 'row' } : {};
    const columnStyle = hasOtherAttributes ? { width: '50%' } : {};

    return (
      <div>
        <SpaceMedium />
        <div style={containerStyle}>
          <div style={columnStyle}>
            <HasPermission
              meta={{ propertyId }}
              hasAccessFormatter={hasAccessRes => hasAccessRes.canEditDeleteProperty}
              render={hasPermission => (
                <PropertyDetailContainerBlock
                  canEdit={hasPermission}
                  isSingleColumn={hasOtherAttributes}
                  propertyId={propertyId}
                />
              )}
            />
          </div>
          <div style={{ ...columnStyle, margin: '0 12px' }}>
            <DynamicFieldInput openPictures={openPictures} otherAttributes={otherAttributes} />
          </div>
          <SpaceLarge />
        </div>
      </div>
    );
  }
}

PropertyDetailDetailContainer.propTypes = {
  openPictures: PropTypes.func.isRequired,
  propertyId: PropTypes.string.isRequired,
  otherAttributes: PropTypes.arrayOf(PropTypes.shape({})),
};

PropertyDetailDetailContainer.defaultProps = {
  otherAttributes: [],
};

export default PropertyDetailDetailContainer;
