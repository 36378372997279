import React from 'react';
import t from '@properly/localization';
import { SpaceFlexi, Input, Icon, HoverTriggeredTooltip } from '@properly/components';
import moment from 'moment-timezone';
import classNames from 'classnames/bind';
import styles from './index.module.css';
import { TodosTextEle } from '../TodosElements';

const cx = classNames.bind(styles);

const colorInactive = '#6B757F';
const colorActive = '#FF4B0A';

export const ThumbsUpBubble = ({ icon: ThumbsBubbleIcon, spaceLeft, isActive, height, width, iconSize, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    style={{ height, width, marginLeft: spaceLeft || '8px' }}
    className={cx('thumbsupbubble', {
      clickable: onClick,
    })}
  >
    <ThumbsBubbleIcon byWidth width={iconSize} height={iconSize} color={isActive ? colorActive : colorInactive} />
  </button>
);

function modeToSwitches(mode, hasEditPermissions) {
  if (mode === 'new') {
    return {
      canSend: true,
      canDone: false,
      canDelete: false,
      canEdit: false,
    };
  }
  if (mode === 'edit') {
    return {
      canSend: false,
      canDone: true,
      canDelete: false,
      canEdit: false,
    };
  }
  if (mode === 'view') {
    return {
      canSend: false,
      canDone: false,
      canDelete: hasEditPermissions,
      canEdit: hasEditPermissions,
    };
  }
  return {};
}

function mapIconToSettings(icon) {
  switch (icon) {
    case 'delete':
      return {
        txt: t('slider.delete_comment'),
        icon: Icon.IcTrash2,
      };
    case 'edit':
      return {
        txt: t('slider.edit_comment'),
        icon: Icon.IcEditGrey,
      };
    default:
      return { txt: '', src: '' };
  }
}

const ActionIcon = ({ onClick, icon }) => {
  const { txt, icon: ActionIconComponent } = mapIconToSettings(icon);
  return (
    <div className={cx('thumbsupcontent__topicon')} onClick={onClick}>
      <HoverTriggeredTooltip title={txt}>
        <ActionIconComponent />
      </HoverTriggeredTooltip>
    </div>
  );
};

function genFooter(mode, isViewed, maxLength, valLength, date, timeZone) {
  if (mode === 'view') {
    let str = date
      ? moment(date)
          .tz(timeZone)
          .format('LT, ddd DD MMM')
      : '';
    if (isViewed) str += ` • ${t('slider.viewed')}`;
    return str;
  }
  return maxLength - valLength;
}

function mapActionToTxtAndHandler(canSend, onSend, canDone, onDone, value) {
  if (canSend && value.trim()) {
    return {
      btnHandler: onSend,
      btnTxt: t('permissions.modal.send'),
    };
  }
  if (canDone && value.trim()) {
    return {
      btnHandler: onDone,
      btnTxt: t('checklist.done'),
    };
  }
  return {};
}

export const ThumbsUpPopoverContent = ({
  maxLength = 500,
  value = '',
  isViewed,
  date,
  timeZone = 'America/Los_Angeles',
  onChange,
  onDelete,
  onDone,
  onSend,
  onEdit,
  hasEditPermissions,
  error,
  name,
  mode = 'new', // new|edit|view
}) => {
  const { canSend, canDone, canEdit, canDelete } = modeToSwitches(mode, hasEditPermissions);
  const { btnHandler, btnTxt } = mapActionToTxtAndHandler(canSend, onSend, canDone, onDone, value);

  const nameStyle = {
    maxWidth: '160px',
  };
  return (
    <div className={cx('thumbsupcontent')}>
      <div className={cx('thumbsupcontent__top')}>
        <TodosTextEle modifier={['txt--l5m', 'color--black', 'helper--cuttxt']} styleOverwrite={nameStyle}>
          {name || t('slider.comment')}
        </TodosTextEle>
        <div className={cx('thumbsupcontent__topicons')}>
          {canDelete && <ActionIcon onClick={onEdit} icon="edit" />}
          {canEdit && <ActionIcon onClick={onDelete} icon="delete" />}
        </div>
      </div>
      <SpaceFlexi type="vertical" size="15px" />
      <Input
        isFirst
        isLast
        disabled={!(canSend || canDone)}
        maxLength={maxLength}
        styleOverwrite={{ backgroundColor: '#FFF', height: '154px' }}
        style={{
          fontSize: '14px',
          lineHeight: '18px',
        }}
        onChange={onChange}
        value={value}
        placeholder={t('slider.write_a_comment')}
        autoFocus
        type="textarea"
      />
      {error && <Input.ErrorMsg>{error}</Input.ErrorMsg>}
      <SpaceFlexi type="vertical" size="15px" />
      <div className={cx('thumbsupcontent__nav')}>
        <TodosTextEle modifier={['txt--l7', 'color--grey']}>
          {genFooter(mode, isViewed, maxLength, value.length, date, timeZone)}
        </TodosTextEle>
        <TodosTextEle modifier={['txt--l5m', 'color--primary', 'helper--clickable']} onClick={btnHandler}>
          {btnTxt}
        </TodosTextEle>
      </div>
    </div>
  );
};
