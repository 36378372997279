export default class StripeCustomer {
  constructor(id, description, email, defaultSource, defaultPaymentMethod, cards, bankAccounts) {
    this.stripeId = id;
    this.stripeDescription = description;
    this.stripeEmail = email;
    this.defaultSource = defaultSource;
    this.defaultPaymentMethod = defaultPaymentMethod;
    this.cards = cards;
    this.bankAccounts = bankAccounts;
  }
}
