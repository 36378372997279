"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  StateCreated: 1,
  StateAccepted: 2,
  StateCancelled: 3,
  StateDeclined: 4,
  StateStarted: 5,
  StatePaused: 6,
  StateFinished: 7,
  StateCleanerCancelled: 8,
  StatePendingChanges: 9,
  StateViewed: 10,
  1: 'StateCreated',
  2: 'StateAccepted',
  3: 'StateCancelled',
  4: 'StateDeclined',
  5: 'StateStarted',
  6: 'StatePaused',
  7: 'StateFinished',
  8: 'StateCleanerCancelled',
  9: 'StatePendingChanges',
  10: 'StateViewed'
};
exports["default"] = _default;