import log from 'loglevel';
import locale from 'browser-locale';
import { eventChannel } from 'redux-saga';
import { client } from '../../config/apollo';
import { GET_COMMENTS_QUERY } from '../queries/comments';
import { MUTATION_ADD_COMMENT } from '../mutations/comments';
import { SUBSCRIPTION_LISTEN_FOR_COMMENTS } from '../subscriptions/comments';
import { generateToken } from '../../modules/desktopApp/data';

const getMetadata = (name, comment) => ({
  name,
  userRole: 'HOST',
  date: new Date().toISOString(),
  propertyId: '',
  jobSnapshotId: comment.payload.jobSnapshotId,
  languageCode: locale(), // browser local language
});
const getCommentInput = job => {
  const { message, pictureIdentifier } = job;

  return {
    message,
    pictureIdentifier,
  };
};

export const getGQLComments = async (jobSnapshotId, userRole, offset, limit, updatedAfter) => {
  const getCommentsQuery = client
    .query({
      query: GET_COMMENTS_QUERY,
      variables: {
        jobSnapshotId,
        userRole,
        updatedAfter,
        offset,
        limit,
      },
      fetchPolicy: 'no-cache',
    })
    .then(({ data, errors }) => {
      if (errors) {
        log.error('Client get comment query error');
        throw errors;
      }
      return data;
    });
  return getCommentsQuery;
};

export const addComment = async comment => {
  try {
    const addCommentMutation = await client.mutate({
      mutation: MUTATION_ADD_COMMENT,
      variables: {
        input: {
          commentMetaDataInput: getMetadata('ADD_COMMENT', comment),
          commentInput: getCommentInput(comment.payload),
          clientMutationId: generateToken(10),
        },
      },
      fetchPolicy: 'no-cache',
    });

    return addCommentMutation.data;
  } catch (error) {
    log.error(`[addComment] ${error}`);
    return error;
  }
};

export const createCommentsSubscriptionChannel = params =>
  eventChannel(emitter => {
    const observable = client.subscribe({
      query: SUBSCRIPTION_LISTEN_FOR_COMMENTS,
      variables: params,
      fetchPolicy: 'no-cache',
    });

    const subscription = observable.subscribe({
      next(payload) {
        log.info('[createCommentsSubscriptionChannel] client payload from listenForComments', payload);
        const {
          message,
          pictureIdentifier,
          createdAt,
          commentId,
          sender: {
            userData: { id, firstName, lastName },
          },
        } = payload.data.listenForComments;

        const commentData = {
          comment: message,
          pictureIdentifier,
          time: createdAt,
          commentId,
          senderId: id,
          senderFirstname: firstName,
          senderLastname: lastName,
        };

        emitter(commentData);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  });
