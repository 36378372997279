import React from 'react';
import { DividerLine, Micro, SpaceSmall } from '@properly/components';
import { connect } from 'react-redux';
import t from '@properly/localization';
import classNames from 'classnames/bind';
import styles from './index.module.css';
import IconAndroid from '../../images/app_badge_android.png';
import IconIos from '../../images/app_badge_ios.png';
import { goToAppStore } from '../../modules/desktopApp/data';

const cx = classNames.bind(styles);

const AppStoreBar = ({ branchIOLink }) => (
  <div className={cx('signup-download-buttons')}>
    <DividerLine types={['top', 'dark']} />
    <div>
      <Micro>{t('signup.download.app_store_description', {})}</Micro>
    </div>
    <SpaceSmall />
    <button
      style={{ border: 'none', cursor: 'pointer', background: 'none' }}
      type="button"
      onClick={() => goToAppStore('iOS', branchIOLink)}
    >
      <img alt="Apple App Store" className={cx('app-badge')} src={IconIos} />
    </button>
    <button
      style={{ border: 'none', cursor: 'pointer', background: 'none' }}
      type="button"
      onClick={() => goToAppStore('android', branchIOLink)}
    >
      <img alt="Google Play Store" className={cx('app-badge')} src={IconAndroid} />
    </button>
  </div>
);

export default connect(
  null,
  null,
)(AppStoreBar);
