import { fromJS } from 'immutable';
import * as types from '../../../../types/index';

const initState = fromJS({
  contacts: {},
  contactPaginationState: {
    dateIterator: 1,
    state: 0,
  },
  modal: {
    view: 1,
    hasError: false,
    isOpen: false,
    showErrors: false,
  },
  modalData: {
    phoneCountryCode: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
  },
  contactCompletedSkills: {},
});

export default function ContactReducer(state = initState, action = {}) {
  switch (action.type) {
    case types.GLOBAL_LOGOUT: // on logout reset
      return initState;
    case types.CONTACTS_DETAIL_PAGE_SAGA: {
      return state.updateIn(['contactPaginationState'], item => {
        if (!action.isPagination) {
          return initState.get('contactPaginationState');
        }
        const jsItem = item.toJS();
        return fromJS({
          ...jsItem,
          dateIterator: jsItem.dateIterator + 2,
        });
      });
    }
    case types.CONTACTS_SET_FETCHSTATE_CR:
      return state.setIn(['contactPaginationState', 'state'], action.val);
    case types.CONTACTS_SET_CONTACT_MODAL:
      return state.mergeDeepIn(['modal'], fromJS(action.data));
    case types.CONTACTS_SET_CONTACT_MODAL_DATA:
      return state.mergeDeepIn(['modalData'], fromJS(action.data));
    case types.CONTACTS_RESET_CONTACT_MODAL_DATA:
      return state.setIn(['modalData'], initState.get('modalData'));
    case types.CONTACT_SAVE_COMPLETED_SKILLS_TO_STORE:
      return state.setIn(['contactCompletedSkills', action.contactId], action.allCompletedSkillsIds);
    default:
      return state;
  }
}
