"use strict";

const detectLanguage = require('./detectLanguage');

const offeredServices = {
  en: [{
    home_cleaning: 'Home Cleaning'
  }, {
    carpet_cleaning: 'Carpet Cleaning'
  }, {
    key_exchange: 'Key Exchange'
  }, {
    meet_greet: 'Meet & Greet'
  }, {
    concierge: 'Concierge'
  }, {
    gardening: 'Gardening'
  }, {
    maintenance: 'Maintenance'
  }, {
    pest_control: 'Pest Control'
  }, {
    pool_service: 'Pool Service'
  }],
  it: [{
    home_cleaning: 'Pulizia della casa'
  }, {
    carpet_cleaning: 'Pulizia del tappeto'
  }, {
    key_exchange: 'Consegna delle chiavi'
  }, {
    meet_greet: 'Meet & Greet'
  }, {
    concierge: 'Concierge'
  }, {
    gardening: 'Giardinaggio'
  }, {
    maintenance: 'Manutenzione'
  }, {
    pest_control: 'Disinfestazione'
  }, {
    pool_service: 'Servizio piscina'
  }],
  de: [{
    home_cleaning: 'Hausreinigung'
  }, {
    carpet_cleaning: 'Teppichreinigung'
  }, {
    key_exchange: 'Schlüsselübergabe'
  }, {
    meet_greet: 'Begrüßung'
  }, {
    concierge: 'Concierge'
  }, {
    gardening: 'Gärtner'
  }, {
    maintenance: 'Hausmeister'
  }, {
    pest_control: 'Kammerjäger'
  }, {
    pool_service: 'Pool-Service'
  }]
};

module.exports = function mapOfferedServicesTranslations(serviceId) {
  const language = detectLanguage();
  const languageServices = offeredServices[language] || offeredServices.en;
  const mapping = (languageServices || []).find(service => service[serviceId]);
  return mapping && mapping[serviceId] || String(serviceId || '').replace(/_/gi, ' ');
};