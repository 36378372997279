"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;

var styles = function styles(theme) {
  return {
    inputRoot: {
      flexGrow: 1,
      margin: '12px 0'
    },
    tagRoot: {
      flexGrow: 1,
      margin: '23px 12px'
    },
    inputContainer: {
      borderRadius: '4px'
    },
    chip: {
      margin: theme.spacing(0.25)
    }
  };
};

exports.styles = styles;