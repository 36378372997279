import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import t from '@properly/localization';
import { connect } from 'react-redux';
import { selectPropertyCollaborators } from '../../selectors/globalSelector';

class SharedWith extends PureComponent {
  static propTypes = {
    propertyId: PropTypes.string.isRequired,
    collData: PropTypes.shape({}).isRequired,
    preText: PropTypes.string,
    maxVisibleNames: PropTypes.number,
    componentClass: PropTypes.oneOfType([PropTypes.string]),
  };

  static defaultProps = {
    componentClass: 'strong',
  };

  mapUserToString(userDataArray) {
    return map(userDataArray, userData => {
      if (userData.fullName) return userData.fullName;
      return `${userData.email} (${t('permissions.pending')})`;
    });
  }

  getUsersOfProperty(obj, propertyId) {
    let array = [];
    if (obj[propertyId]) {
      array = [...array, ...this.mapUserToString(obj[propertyId])];
    }
    if (obj.all) {
      array = [...array, ...this.mapUserToString(obj.all)];
    }
    return array;
  }

  calc(users) {
    if (this.props.maxVisibleNames) {
      const first = users.slice(0, this.props.maxVisibleNames);
      const second = users.slice(this.props.maxVisibleNames, users.length);
      return {
        array: first,
        plus: second.length > 0 && `+${second.length}`,
      };
    }
    return { array: users };
  }

  render() {
    const users = this.getUsersOfProperty(this.props.collData, this.props.propertyId);
    const { preText, componentClass: Component } = this.props;
    const calced = this.calc(users);
    return (
      <span>
        {preText && <span>{preText} </span>}
        {map(calced.array, (name, i) => (
          <Component key={name}>
            {name}
            {i !== calced.array.length - 1 && ', '}
          </Component>
        ))}
        {calced.plus && <span> {calced.plus}</span>}
      </span>
    );
  }
}

function mapStateToProps() {
  const memo = selectPropertyCollaborators();
  return state => ({
    collData: memo(state),
  });
}

export default connect(
  mapStateToProps,
  {},
)(SharedWith);
