import forOwn from 'lodash/forOwn';
import lodashResult from 'lodash/result';

export default class Collaborator {
  constructor(enabled, internal, properties, rights, userData, userId, teamId, createdAt, partnerType) {
    this.enabled = enabled;
    this.internal = internal;
    this.properties = properties;
    this.setDoJobRight = rights;
    this.rights = rights;
    this.userData = userData;
    this.userId = userId;
    this.teamId = teamId;
    this.createdAt = createdAt;
    this.partnerType = partnerType;
  }

  set setDoJobRight(rights) {
    // TODO: Remove this setter after backend sets doJob right to all users,teams
    // this unorthodox permission set should this should cover us until then
    if (!(typeof rights?.doJob === 'boolean') && this.rights) {
      this.rights.doJob = rights?.sendJobs;
    }
  }

  get collType() {
    return Collaborator.mapBackendCollTypeToLocal(this.partnerType);
  }

  static mapBackendCollTypeToLocal(val) {
    switch (val) {
      case 1:
        return 'setupsupport';
      case 2:
        return 'owneraccess';
      case 3:
        return 'spaccess';
      default:
        return undefined;
    }
  }

  static mapLocalCollTypeToBackend(val) {
    switch (val) {
      case 'setupsupport':
        return 1;
      case 'owneraccess':
        return 2;
      case 'spaccess':
        return 3;
      default:
        return undefined;
    }
  }

  setData(obj) {
    this.getOwnProps(obj, this);
  }

  getData() {
    return this.getOwnProps(this);
  }

  getOwnProps(scope, onScope) {
    const obj = onScope || {};
    forOwn(scope, (value, key) => {
      obj[key] = value;
    });
    return obj;
  }

  get type() {
    return this.internal ? 'internal' : 'external';
  }

  get fullName() {
    return lodashResult(this.userData, ['fullName']);
  }

  get email() {
    return lodashResult(this.userData, ['email']);
  }

  get pictureUrl() {
    return lodashResult(this.userData, ['pictureUrl']);
  }

  get isPending() {
    return !lodashResult(this.userData, ['signedUp']);
  }

  get hasAllRights() {
    return !!lodashResult(this.rights, ['allRights']);
  }

  get hasAllRightsProperties() {
    return !!lodashResult(this.rights, ['seeAllProperties']);
  }

  get propertyCount() {
    return this.properties && this.properties.length ? this.properties.length : 0;
  }
}
