"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const log = require('loglevel');

const mappingLangFn = availableLanguages => availableLanguages.reduce((all, lang) => {
  // TODO replace with native lazy import when possible
  try {
    const currentLanguage = require(`./languages/${lang}.json`); // eslint-disable-line


    return _objectSpread(_objectSpread({}, all), {}, {
      [lang]: currentLanguage
    });
  } catch (err) {
    log.error('Error loading locale', lang);
    log.error(err);
  }

  return all;
}, {});

module.exports = require('./localization')({
  mappingLangFn
});
module.exports.getPlansHighlights = require('./translations.plans.highlights');
module.exports.mapServiceIdToLabel = require('./translations.offeredServices');