import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import t from '@properly/localization';
import { LoadingSplash, LoadingSpinner } from '@properly/components';
import { shutdownIntercom } from '@properly/common';
import classNames from 'classnames/bind';
import MainWrapper from '../../../../containers/MainWrapper';
import { getConnectedAccountRequest, connectDirectToPartnerSaga } from '../state/AccountActions';
import { selectConnectedAccounts } from '../state/AccountSelectors';
import TopBarLoginSignup from '../../../../components/TopBarLoginSignup';
import { selectGeneralConfig } from '../../../../selectors/globalSelector';
import styles from '../components/accountStyles.module.css';

const cx = classNames.bind(styles);

class AccountOauthDirectContainer extends Component {
  componentDidMount() {
    const { getConnectedAccount, mobile } = this.props;

    if (mobile) {
      shutdownIntercom(true);
    }

    getConnectedAccount();
  }

  componentDidUpdate() {
    const {
      connectedAccounts,
      connectDirectPartner,
      redirectPartnerId,
      reconnectId,
      connectDirectToPartnerSaga, // eslint-disable-line no-shadow
    } = this.props;

    if (connectedAccounts?.length && connectDirectPartner && redirectPartnerId) {
      connectDirectToPartnerSaga(redirectPartnerId, reconnectId);
    }
  }

  renderMobile = () => {
    const { redirectPartnerLabel } = this.props;

    return (
      <div className={cx('account__bg-white', 'account-oauth-direct-container__wrapper')}>
        <MainWrapper hidePricewarning hideMobilePopover disableWrapper currentPage="account" disablePreloadLoader>
          <div className={cx('account-oauth-direct-container__center')}>
            <div className={cx('account-oauth-direct-container__loadcontainer')}>
              <LoadingSpinner />
              <div className={cx('account-oauth-direct-container__loadtext')}>
                {t('account.connection_redirect', {
                  partner: redirectPartnerLabel,
                })}
              </div>
            </div>
          </div>
        </MainWrapper>
      </div>
    );
  };

  renderDesktop = () => {
    const { generalConfig, redirectPartnerLabel } = this.props;
    const { logoLogin } = generalConfig;

    return (
      <MainWrapper hidePricewarning hideMobilePopover disableWrapper currentPage="account" disablePreloadLoader>
        <TopBarLoginSignup logo={logoLogin} />
        <div className={cx('account-oauth-direct-container__loading')}>
          <LoadingSplash
            customMessage={t('account.connection_redirect', {
              partner: redirectPartnerLabel,
            })}
          />
        </div>
      </MainWrapper>
    );
  };

  render() {
    const { mobile } = this.props;
    if (mobile) {
      return this.renderMobile();
    }
    return this.renderDesktop();
  }
}

AccountOauthDirectContainer.propTypes = {
  children: PropTypes.node,
  disableWrapper: PropTypes.bool,
};

function mapStateToProps(state, props) {
  return {
    connectedAccounts: selectConnectedAccounts(state, props),
    generalConfig: selectGeneralConfig(state),
  };
}

export default connect(mapStateToProps, {
  getConnectedAccount: getConnectedAccountRequest,
  connectDirectToPartnerSaga,
})(AccountOauthDirectContainer);
