import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqBy from 'lodash/uniqBy';
import { Icon, LoadingSplash, SpaceXLarge, ModalError } from '@properly/components';
import { imageUploadLimit } from '@properly/common';
import t from '@properly/localization';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { colorOrange } from '@properly/components/lib/common/styles/colors';
import SvgIcon from '@material-ui/core/SvgIcon';
import CommentCard from '../CommentCard';
import './index.css';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: '7px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
    color: colorOrange,
    marginLeft: '10px',
    fontWeight: '420',
    lineHeight: 1.5,
  },

  expansionPanel: {
    margin: '7px auto',
    border: '1px solid rgba(29,39,48,0.12)',
    boxShadow: 'none',
  },
  expansionPanelSummary: {
    padding: '0 15px',
  },
  expansionPanelDetails: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '400px',
    overflowY: 'auto',
  },
  bootstrapRoot: {
    width: '85%',
    float: 'left',
  },
  padding0: {
    padding: '0',
  },
  width100: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    borderTop: '1px solid rgba(29,39,48,0.12)',
  },
  button: {
    width: '15%',
    float: 'right',
    padding: '0',
  },
  icons: {
    position: 'relative',
    top: '0.4rem',
  },
  panel: {
    boxShadow: 'none',
    border: `1px solid rgba(29,39,48,0.12)`,
  },
  panelButton: {
    padding: '0 15px',
  },
  commentTextArea: {
    fontFamily: '"Graphik Web", Helvetica, Arial, sans-serif',
    paddingTop: '8px',
    paddingLeft: '10px',
    width: '85%',
    height: '38px',
    border: 0,
    fontSize: '.875rem',
    lineHeight: '1.3em',
    resize: 'none',
  },
  noCommentsRender: {
    textAlign: 'center',
    color: 'rgba(29,39,48,0.12)',
  },
});

function CommentExpansionPanel({
  comments,
  jobRequestId,
  currentUser,
  loader,
  getComments,
  commentDetailsPanel,
  classes,
  onSendComment,
  onUploadTaskPic,
  handleCommentPanelExpanded,
}) {
  const [showModal, setShowModal] = useState(false);
  const [commentText, setCommentText] = useState('');

  const commentImageUpload = React.useRef(null);

  const commentCardDelegator = comment => {
    const typebox = {
      receiverCard: 'receiverCard',
      senderCard: 'senderCard',
    };

    if (!comments.length) {
      return <p>{t('jobdetails.no_comments')}</p>;
    }

    if (comment.senderId !== currentUser.objectId) {
      return <CommentCard cssStyle={typebox.receiverCard} key={comment.commentId} comment={comment} />;
    }

    return (
      <CommentCard
        cssStyle={typebox.senderCard}
        jobRequestId={jobRequestId}
        key={comment.commentId}
        comment={comment}
      />
    );
  };

  const commentCardsRender = () => {
    if (comments.length !== 0) {
      return (
        <>
          {uniqBy(comments, 'commentId').map(comment => commentCardDelegator(comment))}
          {/* partial loader */}
          {loader && <LoadingSplash static />}
        </>
      );
    }

    // full loader
    if (loader) {
      return (
        <>
          <SpaceXLarge />
          <LoadingSplash static />
          <SpaceXLarge />
        </>
      );
    }

    return <div className={classes.noCommentsRender}>{t('jobdetails.no_comments')}</div>;
  };

  const handleImageInputChange = e => {
    e.preventDefault();
    const reader = new FileReader();

    const file = e.target.files[0];
    if (!file) {
      return;
    }

    const { size } = file;
    const { minSize, maxSize } = imageUploadLimit;
    if (size < minSize || size > maxSize) {
      setShowModal(true);
      return;
    }
    e.target.value = '';
    reader.onloadend = () => file;
    reader.readAsDataURL(file);
    onUploadTaskPic(file);
  };

  const handleFileUploadClick = () => commentImageUpload.current?.click();

  const handleCommentTextState = e => setCommentText(e.target.value);

  const handleSendComment = e => {
    // check for enter key press or mouse click
    if (e.key === 'Enter' || e.type === 'click') {
      if (commentText.trim()) {
        onSendComment(commentText);
      }
      setCommentText('');
      e.preventDefault();
    }
  };

  const handleExpansionPanel = (_, isExpanded) => {
    handleCommentPanelExpanded(isExpanded);
    if (isExpanded) {
      getComments();
    }
  };

  return (
    <div className={classes.root}>
      <Accordion className={classes.panel} elevation={0} onChange={handleExpansionPanel}>
        <AccordionSummary
          expandIcon={<Icon.IcExpandMore3 width="24px" byWidth />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.panelButton}
        >
          <SvgIcon color="disabled">
            <path d="M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM18 14H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z" />
          </SvgIcon>
          <div className={classes.heading}>{t('jobdetails.comments')}</div>
        </AccordionSummary>

        <AccordionDetails ref={commentDetailsPanel} className={classes.expansionPanelDetails}>
          {commentCardsRender()}
        </AccordionDetails>

        <AccordionDetails className={classes.padding0}>
          <div className={classes.width100}>
            <textarea
              placeholder={t('jobdetails.comments_type_here')}
              type="text"
              className={classNames(classes.commentTextArea, 'comment')}
              onChange={handleCommentTextState}
              onKeyPress={handleSendComment}
              value={commentText}
            />

            <div className={classes.button}>
              <div className={classes.icons}>
                {!commentText?.trim() ? (
                  <Icon.IcCameraSolid onClick={handleFileUploadClick} />
                ) : (
                  <Icon.IcSendMessage onClick={handleSendComment} />
                )}

                <input
                  type="file"
                  ref={commentImageUpload}
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={handleImageInputChange}
                />
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      {/* Show modal for image size limit message */}
      <ModalError.ImageUpload onCloseLimitError={() => setShowModal(false)} showLimitError={showModal} />
    </div>
  );
}

export default withStyles(styles)(CommentExpansionPanel);

CommentExpansionPanel.propTypes = {
  jobRequestId: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}),
  onUploadTaskPic: PropTypes.func.isRequired,
  onSendComment: PropTypes.func.isRequired,
  loader: PropTypes.bool,
  getComments: PropTypes.func.isRequired,
  commentDetailsPanel: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({})),
};
CommentExpansionPanel.defaultProps = {
  loader: false,
  classes: {},
  comments: [],
};
