import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DividerLine, Title3, Regular, SpaceBase, SpaceXLarge } from '@properly/components';
import { shutdownIntercom } from '@properly/common';
import t from '@properly/localization';
import * as selectorsGlobal from '../../../../selectors/globalSelector';
import { setModal } from '../../../../actions/globalActions';
import { PagePadding } from '../../../../components/PageElements';
import AccountProviderSection from '../../../../components/AccountProviderSection';
import { AccountHeadSection } from '../../../../components/AccountElements';
import SettingsAlexaModalContainer, { MODAL_ALEXA_NAME } from '../components/SettingsAlexaModal';

const amazonEchoPartner = {
  text: 'Amazon Echo',
  color_hover: '#ef575d',
  color: '#ef575d',
  icon: 'https://i.imgur.com/Tcglez2.png',
  icon_white: 'https://i.imgur.com/Tcglez2.png',
  id: 'amazon',
  linkToOAuth: '',
  link: '',
  mobile: true,
  primaryPartner: false,
  redirect: false,
  proRequired: false,
  enabled: true,
};

class SettingsIotDevicesContainer extends Component {
  componentWillMount = () => {
    const { mobile } = this.props;

    if (mobile) {
      shutdownIntercom(true);
    }
  };

  openAlexaModel = () => this.props.dispatch(setModal(true, MODAL_ALEXA_NAME, {}));

  render() {
    const { mobile } = this.props;

    const Wrapper = ({ children }) =>
      mobile ? <>{children}</> : <PagePadding type="absolute-scroll">{children}</PagePadding>;

    return (
      <Wrapper>
        {!mobile && (
          <>
            <AccountHeadSection>
              <Title3 type="nomargin">{t('settings.iot_devices')}</Title3>
              <DividerLine type={['bottom']} />
            </AccountHeadSection>
            <SpaceBase />
          </>
        )}
        <div style={{ maxWidth: 900 }}>
          <Regular type="greyloading">{t('settings.iot_devices_txt')}</Regular>
        </div>
        <SpaceXLarge />
        <div style={{ marginBottom: 60 }}>
          {!mobile && (
            <AccountProviderSection.Box
              isLast
              isFirst
              isConnect
              {...amazonEchoPartner}
              onConnect={this.openAlexaModel}
            />
          )}
        </div>
        <SettingsAlexaModalContainer />
      </Wrapper>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    config: selectorsGlobal.selectConfig(state, props),
  };
}

export default connect(mapStateToProps, dispatch => ({
  dispatch,
}))(SettingsIotDevicesContainer);
